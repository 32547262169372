import {
  ACTION_TYPES,
  LOCATION_TYPES,
  MESSAGE_TYPES,
  NotificationActionTypes,
  NotificationState,
} from './types'

import SystemStateInterface from 'store/types/SystemStateInterface'

export * from './types'
export * from './actions'

const initState: NotificationState = {
  message: '',
  type: MESSAGE_TYPES.DEFAULT,
  isOpen: false,
  isPersistent: false,
  location: LOCATION_TYPES.DEFAULT_LOCATION,
}

export default function notifications(
  state = initState,
  action: NotificationActionTypes
): NotificationState {
  const { type, payload } = action

  switch (type) {
    case ACTION_TYPES.SHOW_NOTIFICATION:
      return { ...state, ...payload, isOpen: true }
    case ACTION_TYPES.CLEAR_NOTIFICATION:
      return { ...state, isOpen: false }
    case ACTION_TYPES.CLEAR_NON_PERSISTENT_NOTIFICATION:
      return state.isPersistent ? state : { ...state, isOpen: false }
    default:
      return state
  }
}

export const notificationStateSelector = (state: SystemStateInterface) => state.notifications
