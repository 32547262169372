import { ADMIN_ROUTE } from 'utils/routes'
import Link from 'components/core/Link'
import React from 'react'

export const SuperUserRedirect = () => {
  return (
    <div style={{ marginTop: 300, textAlign: 'center', width: '100%' }}>
      <p style={{ fontSize: 40 }}>:(</p>
      <p>
        You are currently logged in under the "super" tenant, which means{' '}
        <b>you only have access to the admin page.&nbsp;</b>
        To navigate to the admin page, <Link to={ADMIN_ROUTE.buildUrl()}>Click Here</Link>
      </p>
    </div>
  )
}
