import BoxWhiskerSkeleton from './BoxWhiskerSkeleton'
import PropTypes from 'prop-types'
import React from 'react'

const BoxWhiskerPlotLoader = ({ className, height = '100%', isAnimated = false, count = 10 }) => {
  return (
    <div
      data-testid="box-whisker-plot-loader"
      className={className}
      style={{ height, display: 'flex' }}
    >
      {[...Array(count)].map((_, i) => (
        <BoxWhiskerSkeleton key={i} isAnimated={isAnimated} animationDelay={`${i * 50}ms`} />
      ))}
    </div>
  )
}

BoxWhiskerPlotLoader.propTypes = {
  isAnimated: PropTypes.bool,
}

export default BoxWhiskerPlotLoader
