import { call, put, takeEvery } from 'redux-saga/effects'

import client from 'utils/api/client'
/**
 * Created by diegocanales on 4/1/18.
 */
import { createAction } from 'redux-actions'
import get from 'lodash/get'
import logger from 'utils/logger'

// ------------------------------------
// Constants
// ------------------------------------
export const GET_VESSEL_PATH_LOAD = 'api/get/VESSEL_PATH_LOAD'
export const GET_VESSEL_PATH_LOAD_SUCCESS = 'api/get/VESSEL_PATH_LOAD_SUCCESS'
export const GET_VESSEL_PATH_LOAD_FAILURE = 'api/get/GET_VESSEL_PATH_LOAD_FAILURE'

export const GET_VESSEL_PATH_REDUCER_KEY = 'vessel_path'

// ------------------------------------
// Actions
// ------------------------------------
export const getVesselPath = createAction(
  GET_VESSEL_PATH_LOAD,
  (lloydsCode, startTime, endTime) => ({
    lloydsCode,
    startTime,
    endTime,
  })
)

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_VESSEL_PATH_LOAD]: (state, { payload }) => {
    return { ...state, [payload.lloydsCode]: { loading: true } }
  },
  [GET_VESSEL_PATH_LOAD_FAILURE]: (state, { payload }) => {
    return { ...state, [payload.lloydsCode]: { loading: false } }
  },
  [GET_VESSEL_PATH_LOAD_SUCCESS]: (state, { payload }) => {
    return { ...state, [payload.lloydsCode]: { path: payload.data, loading: false } }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
export function getVesselPathReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

// ------------------------------------
// Selectors
// ------------------------------------
export const vesselPathFromState = (state, key) =>
  get(state[GET_VESSEL_PATH_REDUCER_KEY], `${key}.path`, [])
export const allVesselPathsSelector = (state, key) => state[GET_VESSEL_PATH_REDUCER_KEY]
export const vesselPathLoadingFromState = (state, key) =>
  get(state[GET_VESSEL_PATH_REDUCER_KEY], `${key}.loading`, false)

// ------------------------------------
// Saga
// ------------------------------------
function* getVesselPathAsync(action) {
  const { lloydsCode, startTime, endTime } = action.payload
  try {
    const params = {
      lloyds_code: lloydsCode,
      start_time: startTime,
      end_time: endTime,
    }
    const response = yield call(client.get, 'vessels/trace', { params })
    yield put({ type: GET_VESSEL_PATH_LOAD_SUCCESS, payload: { lloydsCode, data: response.data } })
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: GET_VESSEL_PATH_LOAD_FAILURE, payload: { lloydsCode } })
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

export function* watchGetVesselPath() {
  yield takeEvery(GET_VESSEL_PATH_LOAD, getVesselPathAsync)
}
