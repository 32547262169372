import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import {
  TIMETYPE_ACTUAL,
  TIMETYPE_PREDICTION,
  getTimelineMilestoneDisplayText,
} from 'utils/milestone/getTimelineMilestoneDisplayText'

import AvailableForDeliveryLabel from 'components/AvailableForDeliveryLabel'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import MessageWithPopover from 'components/Timeline/TimelineSegment/MessageWithPopover'
import OutdatedPredictionDisclaimer from 'components/OutdatedPredictionDisclaimer'
import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  statusLabel: {
    marginRight: theme.spacing(2),
  },
  milestoneLabel: {
    color: theme.palette.grey[600],
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
  },
  dateTime: {
    color: theme.palette.grey[900],
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
  },
  milestoneList: {
    color: theme.palette.grey[600],
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    margin: 0,
    paddingLeft: theme.spacing(2),

    '& > li': {
      marginBottom: 2,
      marginRight: theme.spacing(1),
      position: 'relative',
      zIndex: 1,
    },
  },
  highlightedContainer: {
    borderRadius: theme.shape.borderRadius,
    padding: '2px 4px 2px 0',
  },
  highlightedMilestone: {
    background: theme.palette.custom['grey25'],
  },
  highlightedAvailableForDelivery: {
    background: theme.palette.blue[25],
  },
  highlightedPrediction: {
    background: theme.palette.purple[100],
  },
  hasHighlight: {
    // Extends the background color under the bullet
    '&:before': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
      content: '""',
      position: 'absolute',
      height: '100%',
      width: 25,
      left: -18,
      top: 0,
      zIndex: -1,
    },
  },
  hasHighlightedMilestone: {
    '&:before': {
      background: theme.palette.custom['grey25'],
    },
  },
  hasAvailableForDelivery: {
    '&:before': {
      background: theme.palette.blue[25],
    },
  },
  hasHighlightedPrediction: {
    '&:before': {
      background: theme.palette.purple[100],
    },
  },
  hasCount: {
    borderRadius: theme.shape.borderRadius,
    padding: 2,
  },
  count: {
    color: theme.palette.blue[600],
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'nowrap',
  },
  noBullet: {
    listStyle: 'none',
    marginLeft: -18,
  },
}))

interface Props {
  classes?: { [key: string]: string }
  isPublicShare?: boolean
  milestones: GroupedMilestoneInterface[]
  tripsCount: number | null
}

const TimelineSegment = (props: Props) => {
  const classes = useStyles(props)
  const { isPublicShare = false, milestones, tripsCount } = props

  const milestonesHaveOutdatedPrediction = milestones.some(
    (milestone: GroupedMilestoneInterface) => milestone.hasOutdatedPrediction
  )

  return (
    <ul className={classes.milestoneList} data-testid="timeline-segement__milestone-list">
      {milestones?.map((milestone: GroupedMilestoneInterface, idx: number) => {
        const { timelineTracedTime, timelinePredictedTime } = milestone
        // we only display milestones if they have one of these times
        if (!timelineTracedTime && !timelinePredictedTime) {
          return null
        }
        const milestoneAction = milestone.action
        const stage = milestone.stopSegment?.tripSegment?.stage
        const facilityType = milestone.stopSegment?.facilityType
        const availableForDeliveryDate = milestone.availableForDelivery

        const predictedType = getTimelineMilestoneDisplayText({
          type: TIMETYPE_PREDICTION,
          stage,
          action: milestoneAction,
          equipmentActivity: milestone.stopSegment.equipmentActivity,
          equipmentCategory: milestone.onEquipment?.category,
          facilityType,
        })

        const actualType = getTimelineMilestoneDisplayText({
          type: TIMETYPE_ACTUAL,
          stage,
          action: milestoneAction,
          equipmentActivity: milestone.stopSegment.equipmentActivity,
          equipmentCategory: milestone.onEquipment?.category,
          facilityType,
        })

        // These control when we show container counts and messaging
        const showLatestMilestoneCounts = milestone.isLastTracedAndIncomplete
        const showPreviousMilestoneCounts = milestone.isPreviousAndIncomplete

        return (
          <React.Fragment key={`milestone-${idx}`}>
            {timelineTracedTime && (
              <li
                /**
                 * `hasHighlight` extends the background color under the bullet
                 * `hasCount` adds padding and border-radius for when there is a bg color added
                 * `hasHighlightedMilestone` & `hasAvailableForDelivery` set the correct bg color (only) for under the bullet
                 */
                className={classnames({
                  [classes.hasHighlight]: showLatestMilestoneCounts || availableForDeliveryDate,
                  [classes.hasCount]: showLatestMilestoneCounts,
                  [classes.hasHighlightedMilestone]: showLatestMilestoneCounts,
                  [classes.hasAvailableForDelivery]:
                    !showLatestMilestoneCounts && availableForDeliveryDate,
                })}
              >
                <Box
                  display="inline-table"
                  className={classnames({
                    [classes.highlightedContainer]:
                      showLatestMilestoneCounts || availableForDeliveryDate,
                    [classes.highlightedMilestone]: showLatestMilestoneCounts,
                    [classes.highlightedAvailableForDelivery]:
                      !showLatestMilestoneCounts && availableForDeliveryDate,
                  })}
                >
                  <Typography
                    component="span"
                    className={classes.milestoneLabel}
                  >{`${actualType}${' '}`}</Typography>
                  <Typography component="span" className={classes.dateTime}>
                    {timelineTracedTime}
                  </Typography>
                  {showLatestMilestoneCounts || showPreviousMilestoneCounts ? (
                    <>
                      <Box component="span" pl={0.5} className={classes.count}>
                        ({milestone.actualTrips?.length} of {tripsCount})
                      </Box>
                      {showLatestMilestoneCounts && milestone.incompleteTrips && (
                        <MessageWithPopover
                          action={milestone.action}
                          milestoneCount={milestone.actualTrips.length}
                          incompleteTrips={milestone.incompleteTrips ?? null}
                          isPublicShare={isPublicShare}
                          tripsCount={tripsCount as number}
                        />
                      )}
                    </>
                  ) : (
                    // Lives _inside_ this `<li>` if it exists and we aren't showing counts
                    <AvailableForDeliveryLabel
                      availableForDeliveryDate={availableForDeliveryDate}
                      withBackground={false}
                    />
                  )}
                </Box>
              </li>
            )}
            {/* Is it's own `<li>` if it exists and we _are_ showing counts */}
            {showLatestMilestoneCounts && availableForDeliveryDate && (
              <li className={classes.noBullet}>
                <AvailableForDeliveryLabel availableForDeliveryDate={availableForDeliveryDate} />
              </li>
            )}
            {!timelineTracedTime && timelinePredictedTime && (
              <li
                /**
                 * `hasHighlight` extends the background color under the bullet
                 * `highlightedPrediction` sets the correct bg color (only) for under the bullet
                 */
                className={classnames({
                  [classes.hasHighlight]: timelinePredictedTime,
                  [classes.hasHighlightedPrediction]: timelinePredictedTime,
                })}
              >
                <Box
                  display="inline-table"
                  className={classnames(
                    classes.highlightedContainer,
                    classes.highlightedPrediction
                  )}
                >
                  <Typography component="div" className={classes.milestoneLabel}>
                    {`${predictedType}${' '}`}
                    <Typography component="span" className={classes.dateTime}>
                      {timelinePredictedTime}
                    </Typography>
                  </Typography>
                </Box>
              </li>
            )}
          </React.Fragment>
        )
      })}
      {milestonesHaveOutdatedPrediction && (
        <li className={classes.noBullet}>
          <Box mt={0.5}>
            <OutdatedPredictionDisclaimer className={classes.statusLabel} />
          </Box>
        </li>
      )}
    </ul>
  )
}

export default TimelineSegment
