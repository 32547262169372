import { ExternalId } from 'store/journeyDetails/interfaces'
export const JOURNEY_DETAILS_REDUCER_KEY = 'journeyDetails'

export const types = {
  FETCH_JOURNEY_DETAILS_START: `${JOURNEY_DETAILS_REDUCER_KEY}/FETCH_JOURNEY_DETAILS_START`,
  FETCH_JOURNEY_DETAILS_SUCCESS: `${JOURNEY_DETAILS_REDUCER_KEY}/FETCH_JOURNEY_DETAILS_SUCCESS`,
  FETCH_JOURNEY_DETAILS_FAILURE: `${JOURNEY_DETAILS_REDUCER_KEY}/FETCH_JOURNEY_DETAILS_FAILURE`,
}

export const fetchJourneyDetails = (payload: ExternalId) => ({
  type: types.FETCH_JOURNEY_DETAILS_START,
  payload,
})
