import * as Yup from 'yup'

import { TextField, makeStyles } from '@material-ui/core'

import AuthActions from 'components/AuthActions'
import Button from 'components/core/Button'
import PasswordTextField from 'components/PasswordTextField'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormik } from 'formik'
import validationSchema from 'components/PasswordTextField/validationSchema'

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(50),
    },
  },
}))

ResetPasswordConfirmForm.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

function ResetPasswordConfirmForm({ email, onSubmit }) {
  const classes = useStyles()
  const { errors, handleBlur, handleChange, handleSubmit, touched, values } = useFormik({
    initialValues: {
      email: email,
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .label('Email')
        .email()
        // eslint-disable-next-line no-template-curly-in-string
        .required('${path} is required'),
      password: validationSchema,
    }),
    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        disabled
        error={errors.email && touched.email}
        helperText={errors.email}
        label="Email"
        margin="normal"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        variant="filled"
      />
      <PasswordTextField
        error={errors.password && touched.password}
        helperText={errors.password}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.password}
      />
      <AuthActions>
        <Button type="submit" color="primary" variant="contained">
          Submit
        </Button>
      </AuthActions>
    </form>
  )
}

export default ResetPasswordConfirmForm
