export const PENDING = 'Pending Data'
export const DELIVERED = 'Delivered'
export const UNKNOWN = 'Unknown Status'
export const DISCHARGED = 'Discharged'
export const IN_TRANSIT = 'In Transit'

const StatusLabel = {
  [PENDING]: PENDING,
  [DELIVERED]: DELIVERED,
  [UNKNOWN]: UNKNOWN,
  [DISCHARGED]: DISCHARGED,
  [IN_TRANSIT]: IN_TRANSIT,
} as const

export type StatusLabelType = typeof StatusLabel[keyof typeof StatusLabel]

export default StatusLabel
