import { Paper, makeStyles } from '@material-ui/core'

import P44LogoFullColor from 'assets/P44LogoFullColor'
import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  authDialog: {
    paddingTop: theme.spacing(6),
    paddingLeft: 36,
    paddingBottom: theme.spacing(6),
    paddingRight: 36,
    textAlign: 'center',
    width: theme.spacing(54), // (360 + 36*2)/8
    borderRadius: 14,
  },
  p44Logo: {
    height: 42,
    width: 120,
  },
}))

interface Props {
  className?: string
  children: React.ReactNode
}

const AuthDialog = (props: Props) => {
  const { className, children } = props
  const classes = useStyles()

  return (
    <Paper className={cx([classes.authDialog, className])} elevation={10}>
      <P44LogoFullColor className={classes.p44Logo} />
      {children}
    </Paper>
  )
}

export default AuthDialog
