import { ALERTS_DRAWER_KEY as DRAWER_KEY, drawersSelector } from 'store/drawers'
import { Typography, withStyles } from '@material-ui/core'
import { aggregatedAlertsCountSelector, newAlertsCountSelector, selectAlert } from 'store/alerts'
import { alertConfigsLoadingSelector, alertConfigsSelector } from 'store/api/alertConfigs'

import { ALERTS_ROUTE } from 'utils/routes'
import AlertDetail from './AlertDetail'
import AlertsDrawer from './AlertsDrawer'
import AlertsOverview from './AlertsOverview'
import Link from 'components/core/Link'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { fromQueryString } from 'utils/urlBuilder'
import get from 'lodash/get'
import logger from 'utils/logger'

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  main: {
    overflow: 'scroll',
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  overlay: {
    backgroundColor: theme.palette.common.lightBlack,
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    zIndex: 300,
    pointerEvents: 'none',
    display: 'none',
  },
  summaryTitle: {
    fontSize: 20,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  divider: {
    height: 30,
    width: 1,
    borderLeft: '1px solid #66717e',
  },
})

class AlertsPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { emailUid, alertConfigID } = this.props
    if (emailUid) {
      logger.notify('Alerts Page Email Open', { emailUid, alertConfigID })
    }
  }

  handleGoToAllAlerts = () => {
    this.props.history.push({ pathname: this.props.location.pathname })
  }

  getRecentAlertsCountText = () => {
    const { newAlertsCount, totalCount, latest, emailUid } = this.props
    if (latest) {
      if (newAlertsCount === 0) return 'No recent alerts' // so text renders as No recent alerts
      return `${newAlertsCount} recent alerts`
    } else if (emailUid) {
      return `${totalCount} new alerts`
    }
  }

  getHeader = () => {
    const {
      classes,
      alertConfigs,
      alertConfigID,
      newAlertsCount,
      latest,
      emailUid,
      alertConfigsLoading,
    } = this.props
    if (alertConfigsLoading) {
      return <div style={{ height: 55 }} />
    }
    if (alertConfigs.length > 0) {
      if (latest || emailUid) {
        return (
          <div className={classes.header}>
            <Typography variant="body2" className={classes.summaryTitle} style={{ fontSize: 22 }}>
              {this.getRecentAlertsCountText()}
            </Typography>
            <div className={classes.divider} />
            <Link
              onClick={() =>
                logger.notify('Go To All Alerts Click', {
                  emailUid,
                  alertConfigID,
                })
              }
              to={ALERTS_ROUTE.buildUrl()}
              style={{
                fontSize: 14,
                marginLeft: 16,
              }}
            >
              Go to all alerts
            </Link>
          </div>
        )
      }
      return (
        <div className={classes.header}>
          <Typography variant="body2" className={classes.summaryTitle}>
            All alerts
          </Typography>
          {newAlertsCount > 0 && (
            <React.Fragment>
              <div className={classes.divider} />
              <Link
                to={{ pathname: ALERTS_ROUTE.buildUrl(), search: '?latest=true' }}
                style={{
                  fontSize: 14,
                  marginLeft: 16,
                }}
              >
                {newAlertsCount} new alerts
              </Link>
            </React.Fragment>
          )}
        </div>
      )
    }
  }

  render() {
    const { classes, isOpen, alertConfigID } = this.props

    return (
      <div className={classes.container}>
        <AlertsDrawer />
        <div className={classes.main}>
          <div className={classes.overlay} style={{ display: isOpen ? 'block' : 'none' }} />
          {alertConfigID ? (
            <AlertDetail />
          ) : (
            <div>
              {this.getHeader()}
              <AlertsOverview />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  selectAlert,
}

const mapStateToProps = (state, ownProps) => {
  const alertConfigs = alertConfigsSelector(state)
  const alertConfigsLoading = alertConfigsLoadingSelector(state)

  const query = fromQueryString(ownProps.location.search)
  const alertConfigID = ownProps.match.params.id
  const latest = get(query, 'latest', null)
  const emailUid = get(query, 'emailUID', null)

  return {
    isOpen: drawersSelector(state)[DRAWER_KEY],
    alertConfigID,
    latest,
    emailUid,
    alertConfigs,
    alertConfigsLoading,
    newAlertsCount: newAlertsCountSelector(state),
    totalCount: aggregatedAlertsCountSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertsPage))
