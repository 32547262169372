import { IconButton, withStyles } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'

const CloseButton = ({ classes, onClick, ...rest }) => {
  return (
    <span className={classes.root}>
      <IconButton onClick={onClick} {...rest}>
        <CloseIcon />
      </IconButton>
    </span>
  )
}

CloseButton.propTypes = {
  onClose: PropTypes.func,
}

CloseButton.defaultProps = {
  onClose: () => {},
  classes: {},
}

const styles = () => ({
  root: {
    alignSelf: 'flex-end',
  },
})

export default withStyles(styles)(CloseButton)
