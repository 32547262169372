import { call, put, takeLatest } from 'redux-saga/effects'

import PublicFeatureFlags from 'store/publicFeatureFlags/models'
import api from 'store/publicFeatureFlags/api'
import logger from 'utils/logger'
import types from 'store/publicFeatureFlags/actions'

export function* loadPublicFeatureFlagsAsync() {
  try {
    const res = yield call(api.getPublicFeatureFlags)
    const data = (res?.data && PublicFeatureFlags.of(res.data)) || {}
    yield put({ type: types.PUBLIC_FEATURE_FLAGS_SUCCESS, payload: data })
  } catch (err) {
    yield put({ type: types.PUBLIC_FEATURE_FLAGS_FAIL, payload: { error: err.message } })
    logger.captureAPIException(err)
  }
}

export function* watchLoadPublicFeatureFlags() {
  yield takeLatest(types.PUBLIC_FEATURE_FLAGS_LOAD, loadPublicFeatureFlagsAsync)
}
