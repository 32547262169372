import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'

export const types = {
  IMPERSONATE_LOAD: `${AUTH_REDUCER_KEY}/impersonate/IMPERSONATE_LOAD`,
  IMPERSONATE_SUCCESS: `${AUTH_REDUCER_KEY}/impersonate/IMPERSONATE_SUCCESS`,
  IMPERSONATE_FAILURE: `${AUTH_REDUCER_KEY}/impersonate/IMPERSONATE_FAILURE`,
  IMPERSONATION_STOPPED: `${AUTH_REDUCER_KEY}/impersonate/IMPERSONATE_STOPPED`,
}

export const impersonateUser = createAction(types.IMPERSONATE_LOAD, (email, tenant) => ({
  email,
  tenant,
}))

export const impersonationStopped = createAction(types.IMPERSONATION_STOPPED)
