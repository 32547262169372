import { Box, Theme, makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'

import Button from 'components/core/Button'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import PopoverText from 'components/PopoverText'
import Reaction from 'components/ProductFeedback/Reaction'
import logger from 'utils/logger'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    alignItems: 'center',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    fontStyle: 'italic',
    lineHeight: 1,
    textAlign: 'left',
  },
  icon: {
    fontSize: 16,
    marginRight: 2,
  },
  reactionRoot: {
    display: 'flex',
  },
  reactionButtonGroup: {
    marginLeft: theme.spacing(1),
  },
  feedbackPromptText: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

interface Props {
  className?: string
}

const OutdatedPredictionDisclaimer = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerListAnchorRef = useRef(null)
  const handleClose = () => setAnchorEl(null)
  const handleClick = () => {
    logger.notify('Outdated Prediction popup click')
    setAnchorEl(containerListAnchorRef.current)
  }
  const classes = useStyles()

  return (
    <>
      <Button
        variant="text"
        className={classes.button}
        onClick={handleClick}
        ref={containerListAnchorRef}
      >
        <HelpOutlineIcon classes={{ root: classes.icon }} fontSize="small" />
        <Box>Why is this outdated?</Box>
      </Button>
      {anchorEl && (
        <PopoverText
          anchorEl={anchorEl}
          bodyContent={
            <>
              Our prediction algorithm relies on several data sources, including the latest Ocean
              Carrier updates. We’ll update this milestone with a revised prediction or an actual
              time as soon as we get new data.
              <Box mt={3} mb={0.5}>
                Typically this is resolved within 20 hours.
              </Box>
            </>
          }
          footerContent={
            <Box display="flex" justifyContent="center">
              <Reaction
                classes={{
                  buttonGroup: classes.reactionButtonGroup,
                  reactionPrompt: classes.reactionRoot,
                  feedbackPromptText: classes.feedbackPromptText,
                }}
                componentUnderFeedback="Outdated Prediction"
                feedbackPromptText="Was this helpful?"
                loggerLabel="Outdated Prediction Reaction"
              />
            </Box>
          }
          handleClose={handleClose}
          headerContent={<>Outdated Prediction</>}
        />
      )}
    </>
  )
}

export default React.memo(OutdatedPredictionDisclaimer)
