import jsonLogicUtils from 'utils/jsonLogic/utils'

/**
 * Handles date strings that can be either an integer or regular date. If an integer it returns
 * a JsonLogic date dict, otherwise it returns the date string passed in.
 * @param dateString string integer (`"3"`) or date (`"2021-03-17"`)
 */
const getDateValue = (dateString: string | number) => {
  if (!isNaN(dateString as number)) {
    return jsonLogicUtils.integerDaysToTimeDelta(dateString as string)
  }

  // Must be a regular date string: `"2021-03-17"`
  return dateString
}

export default getDateValue
