import { Divider, Typography } from '@material-ui/core'

import React from 'react'

const TodayDivider = () => (
  <span data-testid="comments__today-line">
    <Divider />
    <Typography align="left" variant="caption" color="textSecondary">
      Today
    </Typography>
  </span>
)

export default TodayDivider
