import { CommonInterface, ContainerInterface } from 'store/associatedReferences/interfaces'
import {
  ROLLUP_CONTAINER,
  ROLLUP_LINE_ITEM,
  getRefType,
  getRefTypeLabel,
  getShipmentDetailPath,
} from 'utils/rollups'
import { TableRow, Typography, makeStyles } from '@material-ui/core'

import { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import { BREADCRUMB_PROGRESS } from 'components/ReferencesTable'
import Link from 'components/core/Link'
import React from 'react'
import TableCell from 'components/ReferencesTable/TableCell'
import TableCellText from 'components/ReferencesTable/TableCellText'
import TextListWithExpand from 'components/TextListWithExpand'
import classnames from 'classnames'
import logger from 'utils/logger'

const useStyles = makeStyles(theme => ({
  columnText: {
    display: 'flex',
  },
  verticalList: {
    display: 'flex',
    flexDirection: 'column',
  },
  blockList: {
    display: 'block',
  },
  expandButton: {
    marginLeft: -30,
  },
  subtext: {},
  subtextLabel: {
    whiteSpace: 'nowrap',
  },
}))

interface Props {
  classes?: { [key: string]: string }
  container: ContainerInterface
  containerTripId?: string | null
  getBreadcrumbIndex: (refType: string) => number
  refType: ApiRefTypeType
  totalContainerTrips: number
}

const BookingContainerList = (props: Props) => {
  const classes = useStyles(props)
  const { container, containerTripId, getBreadcrumbIndex, refType, totalContainerTrips } = props

  if (!container) {
    return null
  }
  const { containerTrips, lineItems, orders = [] } = container
  const breadcrumbIndex = getBreadcrumbIndex(refType)

  const ContainerLink = (trip: CommonInterface) => (
    <TableCellText
      active={containerTripId === trip.number}
      key={trip.number}
      onClick={() => {
        logger.notify(`Reference Link Click`, {
          entityTypeFrom: refType,
          entityTypeTo: ROLLUP_CONTAINER,
        })
      }}
      href={
        containerTripId !== trip.number
          ? getShipmentDetailPath(ROLLUP_CONTAINER, trip.number, trip.externalId)
          : undefined
      }
    >
      {trip.number}
    </TableCellText>
  )

  return (
    <TableRow>
      <TableCell disableBorder>{/* Placeholder for Icon */}</TableCell>
      <TableCell disableBorder indent>
        <TableCellText
          bold={
            breadcrumbIndex > BREADCRUMB_PROGRESS.STEP_FOUR &&
            breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX
          }
          color={
            breadcrumbIndex > BREADCRUMB_PROGRESS.STEP_FOUR &&
            breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX
              ? 'textPrimary'
              : 'textSecondary'
          }
        >
          Containers{' '}
          <Typography color="inherit" variant="caption">
            <i>({`${containerTrips.length} of ${totalContainerTrips}`})</i>
          </Typography>
        </TableCellText>
        <span className={classes.columnText}>
          {lineItems.length > 0 && (
            <>
              <TableCellText
                classes={{ root: classnames(classes.subtext, classes.subtextLabel) }}
                color="textSecondary"
                italic
                variant="caption"
              >
                Line Items:
              </TableCellText>
              <TableCellText
                className={classes.verticalList}
                classes={{ root: classes.subtext }}
                color="textSecondary"
                horizontalPadding={false}
                italic
                variant="caption"
              >
                {lineItems?.map((lineItem: CommonInterface) => (
                  <Link
                    className={classes.blockList}
                    key={lineItem.number}
                    onClick={() => {
                      logger.notify(`Reference Link Click`, {
                        entityTypeFrom: refType,
                        entityTypeTo: ROLLUP_LINE_ITEM,
                      })
                    }}
                    to={getShipmentDetailPath(
                      ROLLUP_LINE_ITEM,
                      lineItem.number,
                      lineItem.externalId
                    )}
                  >
                    {lineItem.number}
                  </Link>
                ))}
              </TableCellText>
            </>
          )}
        </span>
        {/* This is only if there are no `lineItems` */}
        {lineItems.length === 0 &&
          orders.length > 0 &&
          orders.map(order => (
            <span key={order.number} className={classes.columnText}>
              <TableCellText
                classes={{ root: classes.subtext }}
                color="textSecondary"
                italic
                variant="caption"
              >
                {getRefTypeLabel(order.type)}:
              </TableCellText>
              <TableCellText
                className={classes.verticalList}
                classes={{ root: classes.subtext }}
                color="textSecondary"
                horizontalPadding={false}
                italic
                variant="caption"
              >
                <Link
                  className={classes.blockList}
                  to={getShipmentDetailPath(getRefType(order.type), order.number, order.externalId)}
                >
                  {order.number}
                </Link>
              </TableCellText>
            </span>
          ))}
      </TableCell>
      <TableCell disableBorder>
        <TextListWithExpand
          items={containerTrips}
          classes={{ expandButton: classes.expandButton }}
          collapseAfterCountOf={10}
          renderOutput={(item: CommonInterface) => ContainerLink(item)}
          onClickHandler={(isExpanded: boolean) => {
            logger.notify(
              `${
                isExpanded ? 'Collapse' : 'Show all'
              } Associated References Transportation Containers`
            )
          }}
        />
      </TableCell>
    </TableRow>
  )
}

export default BookingContainerList
