export default theme => ({
  container: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: 'white',
    height: '100%',
  },
  shipmentSummary: {
    color: theme.palette.blue[800],
    fontSize: 18,
  },
  tableControlWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(1),
    height: 70,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  tabsWrapper: {
    marginTop: theme.spacing(2),
    boxShadow: `inset 0 -1px ${theme.palette.grey[500]}`,
  },
  toggleDrawerButton: {
    marginRight: theme.spacing(1),
  },
  titleWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  main: {
    width: '100%',
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: theme.spacing(3),
    position: 'relative',
  },
  header: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerBottom: {
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
  editIcon: {
    height: 46,
    width: 46,
    color: 'white',
    float: 'right',
    marginTop: -1 * theme.spacing(1) * 2,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: '#66717e',
    borderRadius: 23,
    cursor: 'pointer',
  },
  nameEditIcon: {
    fontSize: 18,
    color: theme.palette.grey[800],
  },
  editNameWrapper: {
    display: 'flex',
    marginLeft: theme.spacing(1),
  },
  saveNameButton: {
    backgroundColor: theme.palette.blue[800],
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  saveNameButtonLabel: {
    color: 'white',
    textTransform: 'none',
  },
  cancelButtonLabel: {
    textTransform: 'none',
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 40,
  },
  paginatorBottom: {
    width: '100%',
    maxWidth: 'none',
    paddingBottom: theme.spacing(4),
  },
  titleText: {
    maxWidth: 450,
    whiteSpace: 'nowrap',
    color: theme.palette.blue[800],
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 18,
  },
  titlePrompt: {
    color: theme.palette.grey[200],
    fontSize: 18,
  },
  tab: {
    fontSize: 14,
  },
  tabIndicator: {
    height: 4,
  },
  tabDisabled: {
    color: `${theme.palette.grey[200]} !important`,
  },
  selectExhaustiveButton: {
    textTransform: 'none',
    color: theme.palette.blue[400],
    marginLeft: 10,
    marginBottom: 2,
  },
  buttonGroup: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(3),
  },
  buttonGroupButton: {
    color: '#00558B',
    fontWeight: theme.typography.fontWeightLight,
  },
  downloadIcon: {
    marginBottom: -7,
  },
  startIcon: {
    marginRight: 0,
  },
  progressIcon: {
    marginRight: 8,
  },
})
