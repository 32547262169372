import { Dialog, DialogContent, DialogTitle, TextField, withStyles } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import AdminButton from './AdminButton'
import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { addTenant } from 'store/auth/tenant/actions'
import classnames from 'classnames'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {},
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  contentRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogPaper: {
    minWidth: 700,
    minHeight: 500,
  },
  confirm: {
    color: 'red',
    fontSize: 20,
  },
  addButton: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.brand.color,
  },
  addLabel: {
    color: 'white',
  },
  disabled: {
    backgroundColor: 'grey',
  },
})

class AddTenant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      newTenantName: '',
      newTenantDisplayName: '',
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  handleCancel = () => {
    this.setState({
      open: false,
    })
  }

  openDialog = () => {
    this.setState({
      open: true,
    })
  }

  handleTextFieldChange = e => {
    this.setState({
      newTenantName: e.target.value,
    })
  }

  handleDisplayNameTextFieldChange = e => {
    this.setState({ newTenantDisplayName: e.target.value })
  }

  handleAddTenant = () => {
    this.props.addTenant(this.state.newTenantName, this.state.newTenantDisplayName)
  }

  render() {
    const { open, newTenantName, newTenantDisplayName } = this.state
    const { classes } = this.props
    const addClassnames = classnames(classes.addButton, {
      [classes.disabled]: !newTenantName || !newTenantDisplayName,
    })
    return (
      <div className={classes.root}>
        <AdminButton onClick={this.openDialog} text={'Add Tenant'} icon={<AddIcon />} />
        <Dialog open={open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>
            Add Tenant
            <CloseIcon className={classes.closeIcon} onClick={this.handleCancel} />
          </DialogTitle>
          <DialogContent className={classes.contentRoot}>
            <div>
              <div>
                <TextField
                  value={newTenantName}
                  onChange={this.handleTextFieldChange}
                  label="Tenant Name"
                  placeholder="e.g. gapac"
                />
              </div>
              <div>
                <TextField
                  value={newTenantDisplayName}
                  onChange={this.handleDisplayNameTextFieldChange}
                  placeholder="e.g. Georgia-Pacific"
                  label="Tenant Display Name"
                />
              </div>
              <div>
                <Button
                  disabled={!newTenantName || !newTenantDisplayName}
                  classes={{ root: addClassnames, label: classes.addLabel }}
                  onClick={this.handleAddTenant}
                >
                  Add Tenant
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const mapDispatchToProps = {
  addTenant,
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddTenant))
