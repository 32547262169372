import { NO_DATA, formatters, handleFileDownload, uniqueCarrierNames } from '../utils'
import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableRow } from 'components/core/SimpleTableDeprecated'
import {
  isRoutesMetricsLoadingSelector,
  routeGroupsWithMetricsSelector,
  routeIdsSelector,
  selectedRouteIdsSelector,
  sortStateSelector,
} from 'store/planning'
import { updateSelectedRouteIds, updateSortState } from 'store/planning/actions'

import BaseCell from './BaseCell'
import CenteredLayout from 'components/layouts/CenteredLayout'
import Checkbox from 'components/core/Checkbox'
import ExpandCell from './ExpandCell'
import ExpandableCell from './ExpandableCell'
import ExportMenu from 'components/core/ConfigurableTable/TableControls/ExportMenu'
import LocationInfo from './LocationInfo'
import MultipleLocationInfo from './MultipleLocationInfo'
import { PERFORMANCE_SECTION_HEIGHT } from '../index'
import Paginator from 'components/core/Paginator'
import PropTypes from 'prop-types'
import ScrollShadow from 'components/ScrollShadow'
import SortableTableHeader from 'components/SortableTableHeader'
import Sticky from 'components/Sticky'
import TableSkeleton from 'components/TableSkeleton'
import TruncatedListCell from './TruncatedListCell'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { formatDecimal } from 'utils/strUtils'
import get from 'lodash/get'
import logger from 'utils/logger'
import { tableDataSort } from 'utils/tableUtils'
import tracker from 'utils/logger/tracker'
import { withStyles } from '@material-ui/core'

const columnWidthStyles = (theme, cellTag = 'td') => ({
  [`& ${cellTag}:nth-child(1)`]: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 40,
  },
  [`& ${cellTag}:nth-child(2)`]: {
    width: 170,
  },
  [`& ${cellTag}:nth-child(3)`]: {
    width: 170,
  },
  [`& ${cellTag}:nth-child(4)`]: {
    width: 170,
  },
  [`& ${cellTag}:nth-child(5)`]: {
    width: 76,
  },
  [`& ${cellTag}:nth-child(6)`]: {
    width: 76,
  },
  [`& ${cellTag}:nth-child(7)`]: {
    width: 144,
  },
  [`& ${cellTag}:nth-child(8)`]: {
    width: 72,
  },
  [`& ${cellTag}:nth-child(9)`]: {
    width: 100,
  },
  [`& ${cellTag}:nth-child(10)`]: {
    width: 140,
  },
})

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[50],
  },
  smallWidthCol: {
    width: '1%', // fit content to cell
  },
  rowsWrapper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[25],
  },
  groupRow: {
    backgroundColor: theme.palette.common.white,
    ...columnWidthStyles(theme),
  },
  routeRow: {
    height: '100%',
    backgroundColor: theme.palette.grey[50],
    ...columnWidthStyles(theme),
  },
  singleRouteRow: {
    backgroundColor: theme.palette.common.white,
    ...columnWidthStyles(theme),
  },
  headerWrapper: {
    backgroundColor: theme.palette.white,
    paddingTop: 0, // for sticky table
  },
  headerRow: columnWidthStyles(theme, 'th'),
  paginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 21,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  pagination: {
    paddingRight: theme.spacing(4),
  },
  numSelectedText: {
    color: theme.palette.blue[800],
    fontSize: 18,
  },
  tableHeader: {
    width: `calc( 100% - ${theme.spacing(2)}px)`,
    minWidth: 960,
    tableLayout: 'fixed',
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
  },
  routeGroup: {
    width: '100%',
    minWidth: 960,
    tableLayout: 'fixed',
    margin: '12px 0px',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    position: 'relative',
    '&:first-child': {
      margin: '8px 0',
    },
    '&:after': {
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: theme.shape.borderRadius,
      bottom: 0,
      boxShadow: `0 1px 2px 0 ${theme.palette.grey[950]}`,
      content: "''",
      display: 'block',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
    },
    '& td:first-child': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    '& td:last-child': {
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
  route: {
    margin: 20,
    padding: 10,
  },
  routeCountLabel: {
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'nowrap',
    color: '#00558B',
  },
  expandoTableCell: {
    verticalAlign: 'top',
  },
  checkboxTableCell: {
    paddingTop: theme.spacing(),
  },
  expandCellContents: {
    alignItems: 'flex-start',
  },
  expandButton: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    height: theme.spacing(4),
    marginTop: 0,
    minWidth: theme.spacing(3),
    padding: 0,
    width: theme.spacing(4),
  },
  emptyStateMessage: {
    color: theme.palette.grey[500],
    fontSize: 20,
    padding: '40px 0',
  },
  groupRowLocation: {
    color: theme.palette.black,
    fontWeight: theme.typography.fontWeightBold,
  },
  routeRowLocation: {
    color: theme.palette.black,
    fontSize: 17,
  },
  routeRowLocationCode: {
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
  },
  routesTableLoader: {
    minHeight: 300,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionDivider: {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    height: 36,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
})

const SelectAllRoutesCheckbox = connect(
  state => ({
    routeIds: routeIdsSelector(state),
    selectedRouteIds: selectedRouteIdsSelector(state),
  }),
  { updateSelectedRouteIds }
)(({ routeIds, selectedRouteIds, updateSelectedRouteIds }) => {
  const noneAreChecked = !Boolean(selectedRouteIds.size)
  const allAreChecked = Boolean(selectedRouteIds.size) && routeIds.length === selectedRouteIds.size
  return (
    <Checkbox
      checked={allAreChecked}
      color="primary"
      indeterminate={!allAreChecked && !noneAreChecked}
      onChange={e => updateSelectedRouteIds({ routeIds, checked: e.target.checked })}
    />
  )
})

const RouteGroupRow = connect(state => ({ selectedRouteIds: selectedRouteIdsSelector(state) }), {
  updateSelectedRouteIds,
})(
  ({ classes, isExpanded, handleExpand, routeGroup, selectedRouteIds, updateSelectedRouteIds }) => {
    const { destLocation, metrics, originLocation, routes } = routeGroup
    const [isHovered, setIsHovered] = useState(false)

    const durationMetric = metrics.duration || {}
    const rollMetric = metrics.roll || {}

    const expandoLabel = `${routes.length} Routes`
    const numChildRoutesSelected = routes.filter(route => selectedRouteIds.has(route.routeId))
      .length
    const isChecked = numChildRoutesSelected === routes.length

    return (
      <TableRow
        hover={true}
        key={`route-group-row-${routeGroup.id}`}
        className={classes.groupRow}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <BaseCell className={classes.checkboxTableCell}>
          <Checkbox
            inputProps={{
              'data-testid': 'routes-table__row-select',
            }}
            checked={isChecked}
            color="primary"
            indeterminate={numChildRoutesSelected > 0 && !isChecked}
            onChange={e => {
              const checked = e.target.checked
              tracker.planning.userSelectsTableRow(checked, 'group')
              updateSelectedRouteIds({
                routeIds: routeGroup.routes.map(route => route.routeId),
                checked,
              })
            }}
          />
        </BaseCell>
        <BaseCell>
          <LocationInfo
            classes={{
              locationName: classes.routeRowLocation,
              locationCode: classes.routeRowLocationCode,
            }}
            location={originLocation}
            rollMetric={rollMetric}
          />
        </BaseCell>
        <BaseCell>
          <MultipleLocationInfo
            locations={routeGroup.waypointLocations}
            rollup={true}
            isRowHovered={isHovered}
          />
        </BaseCell>
        <BaseCell>
          <LocationInfo
            classes={{
              locationName: classes.routeRowLocation,
              locationCode: classes.routeRowLocationCode,
            }}
            location={destLocation}
          />
        </BaseCell>
        <BaseCell>{formatDecimal(durationMetric.metricsMedian, 1, NO_DATA)}</BaseCell>
        <BaseCell>{formatters.containerCount(durationMetric.count)}</BaseCell>
        <BaseCell>
          <TruncatedListCell
            items={uniqueCarrierNames(routeGroup.carriers)}
            isRowHovered={isHovered}
          />
        </BaseCell>
        <BaseCell>{formatDecimal(durationMetric.variability)}</BaseCell>
        <BaseCell>
          {formatters.range(durationMetric.lowerQuartile, durationMetric.upperQuartile)}
        </BaseCell>
        <ExpandCell
          onClick={handleExpand}
          label={expandoLabel}
          isExpanded={isExpanded}
          className={classes.tableCell}
          classes={{
            cellContents: classes.expandCellContents,
            button: classes.expandButton,
          }}
        />
      </TableRow>
    )
  }
)

const RouteRow = connect(
  state => ({
    selectedRouteIds: selectedRouteIdsSelector(state),
  }),
  { updateSelectedRouteIds }
)(({ classes, isExpandable, isExpanded, route, selectedRouteIds, updateSelectedRouteIds }) => {
  const { carrier, destLocation, metrics, originLocation, routeId, waypointLocations } = route
  const durationMetric = metrics.duration || {}
  const rollMetric = metrics.roll || {}

  const [isHovered, setIsHovered] = useState(false)

  const columns = [
    {
      component: (
        <Checkbox
          checked={selectedRouteIds.has(routeId)}
          color="primary"
          onChange={e => {
            const checked = e.target.checked
            tracker.planning.userSelectsTableRow(checked, 'route')
            updateSelectedRouteIds({
              routeIds: [routeId],
              checked,
            })
          }}
        />
      ),
    },
    {
      component: (
        <LocationInfo
          classes={{
            locationName: classnames({
              [classes.groupRowLocation]: isExpandable,
              [classes.routeRowLocation]: !isExpandable,
            }),
            locationCode: classnames({
              [classes.routeRowLocationCode]: !isExpandable,
            }),
          }}
          location={originLocation}
          rollMetric={rollMetric}
        />
      ),
    },
    {
      component: <MultipleLocationInfo locations={waypointLocations} isRowHovered={isHovered} />,
    },
    {
      component: (
        <LocationInfo
          classes={{
            locationName: classnames({
              [classes.groupRowLocation]: isExpandable,
              [classes.routeRowLocation]: !isExpandable,
            }),
            locationCode: classnames({
              [classes.routeRowLocationCode]: !isExpandable,
            }),
          }}
          location={destLocation}
        />
      ),
    },
    {
      component: formatDecimal(durationMetric.metricsMedian, 1, NO_DATA),
    },
    { component: formatters.containerCount(durationMetric.count) },
    { component: get(carrier, 'abbreviation') },
    { component: formatDecimal(durationMetric.variability) },
    {
      component: formatters.range(durationMetric.lowerQuartile, durationMetric.upperQuartile),
    },
  ]

  return (
    <TableRow
      hover={true}
      key={`route-row-${routeId}`}
      className={isExpandable ? classes.routeRow : classes.singleRouteRow}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {columns.map((column, idx) => {
        if (isExpandable) {
          return (
            <ExpandableCell
              key={idx}
              isExpanded={isExpanded}
              colSpan={idx === columns.length - 1 ? 2 : 1}
            >
              {column.component}
            </ExpandableCell>
          )
        } else {
          return <BaseCell key={idx}>{column.component}</BaseCell>
        }
      })}
      {!isExpandable && (
        <BaseCell className={classes.expandoTableCell}>
          <div className={classes.routeCountLabel}>1 Route</div>
        </BaseCell>
      )}
    </TableRow>
  )
})

const RouteGroup = ({ classes, routeGroup }) => {
  const { routes } = routeGroup
  const [isExpanded, setIsExpanded] = useState(false)

  const isExpandable = routes.length > 1

  return (
    <Table className={classes.routeGroup}>
      <TableBody data-testid={`routes-table-body-${routeGroup.id}`}>
        {(() => {
          if (isExpandable) {
            return (
              <>
                <RouteGroupRow
                  classes={classes}
                  routeGroup={routeGroup}
                  isExpanded={isExpanded}
                  handleExpand={() => {
                    const expanded = !isExpanded
                    setIsExpanded(expanded)
                    tracker.planning.userExpandsTableRow(expanded)
                  }}
                />
                {isExpanded &&
                  routes.map(route => (
                    <RouteRow
                      key={route.routeId}
                      route={route}
                      classes={classes}
                      isExpandable={true}
                      isExpanded={isExpanded}
                    />
                  ))}
              </>
            )
          } else {
            // Display only the route row because there is only one in the group
            return <RouteRow route={routes[0]} classes={classes} isExpandable={false} />
          }
        })()}
      </TableBody>
    </Table>
  )
}

const PAGE_SIZE = 15
const getDurationMetric = (item, name, defaultValue) =>
  get(item, `metrics.duration.${name}`, defaultValue)

function RoutesTable({
  classes,
  containerRef,
  tableData,
  selectedRouteIds,
  isRoutesMetricsLoading,
  sortState,
  updateSortState,
}) {
  const [tableState, setTableState] = useState({ rows: undefined, page: 0 })
  const [headerIsStickied, setHeaderIsStickied] = useState(false)

  useEffect(() => {
    if (tableData !== undefined) {
      handleSort(sortState.orderBy, sortState.order, tableData)
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  useEffect(() => {
    const el = containerRef.current
    if (el.scrollTop > PERFORMANCE_SECTION_HEIGHT) {
      el.scrollTo({ top: PERFORMANCE_SECTION_HEIGHT, behavior: 'smooth' })
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState])

  let columns = [
    {
      id: 'checkbox',
      label: <SelectAllRoutesCheckbox />,
      sortDisabled: true,
    },
    {
      id: 'from',
      label: 'From',
      dataAccessor: item => get(item, 'originLocation.name'),
    },
    {
      id: 'via',
      label: 'Via',
      sortDisabled: true,
    },
    {
      id: 'to',
      label: 'To',
      dataAccessor: item => get(item, 'destLocation.name'),
    },
    {
      id: 'transit_time',
      label: <span>Transit Time Median (days)</span>,
      dataAccessor: item => getDurationMetric(item, 'metricsMedian', 0),
    },
    {
      id: 'container_count',
      label: <span>Container Count</span>,
      dataAccessor: item => getDurationMetric(item, 'count', 0),
    },
    {
      id: 'carriers',
      label: 'Carriers',
      dataAccessor: item => {
        if (item.carriers) {
          // for route groups
          return uniqueCarrierNames(item.carriers).join('-')
        }
        if (item.carrier) {
          return get(item, 'carrier.abbreviation')
        }
      },
    },
    {
      id: 'variability',
      label: <span>Variability (days)</span>,
      dataAccessor: item => getDurationMetric(item, 'variability', 0),
    },
    {
      id: 'interquartile_range',
      label: <span>Interquartile Range (days)</span>,
      dataAccessor: item => getDurationMetric(item, 'variability', 0),
    },
    {
      id: 'expand',
      label: '',
      sortDisabled: true,
    },
  ]
  columns = columns.map(c => ({ ...c, style: { borderBottom: 'none' } }))

  const handleSort = (dataKey, direction, data) => {
    const sortedRows = tableDataSort({
      dataKey,
      direction,
      data,
      columns,
      orderByProperty: 'routes',
    })
    setTableState({ rows: sortedRows, page: 0 })
    updateSortState({ orderBy: dataKey, order: direction })
  }

  const startIdx = tableState.page * PAGE_SIZE

  return (
    <div className={classes.root} data-testid={'planning__routes-table'}>
      {isRoutesMetricsLoading || tableState.rows === undefined ? (
        <CenteredLayout className={classes.routesTableLoader}>
          <TableSkeleton rowCount={5} />
        </CenteredLayout>
      ) : (
        <>
          <Sticky className={classes.headerWrapper} onStick={setHeaderIsStickied}>
            <div className={classes.paginationWrapper}>
              <span className={classes.numSelectedText}>
                {selectedRouteIds.size} Route
                {selectedRouteIds.size !== 1 ? 's' : ''} selected
              </span>
              <div className={classes.actionButtons}>
                {selectedRouteIds.size > 0 && (
                  <>
                    <ExportMenu
                      tooltipText="Download selected items"
                      variant="outlinedAlternateDark"
                      onDownloadClick={fileType => {
                        logger.notify('Planning Download', { fileType })
                        handleFileDownload(fileType, tableState.rows, selectedRouteIds)
                      }}
                    />
                    <div className={classes.actionDivider} />
                  </>
                )}
                <Paginator
                  variant={'horizontal'}
                  currentPage={tableState.page}
                  totalPages={Math.ceil(tableState.rows.length / PAGE_SIZE)}
                  limit={PAGE_SIZE}
                  totalCount={tableState.rows.length}
                  onPrevClick={() => {
                    setTableState({ ...tableState, page: tableState.page - 1 })
                    tracker.planning.userPaginatesTable()
                  }}
                  onNextClick={() => {
                    setTableState({ ...tableState, page: tableState.page + 1 })
                    tracker.planning.userPaginatesTable()
                  }}
                  extraClasses={classes.pagination}
                />
              </div>
            </div>
            <Table className={classes.tableHeader}>
              <SortableTableHeader
                classes={{ row: classes.headerRow }}
                cols={columns}
                defaultOrderBy={sortState.orderBy}
                defaultOrder={sortState.order}
                onRequestSort={(dataKey, direction) =>
                  handleSort(dataKey, direction, tableState.rows)
                }
              />
            </Table>
            <ScrollShadow visible={headerIsStickied} />
          </Sticky>
          {tableState.rows.length === 0 ? (
            <TableSkeleton
              isAnimated={false}
              message={
                !isRoutesMetricsLoading && tableState.rows.length === 0 && 'No results found'
              }
              rowCount={3}
            />
          ) : (
            <div className={classes.rowsWrapper}>
              {tableState.rows.slice(startIdx, startIdx + PAGE_SIZE).map(routeGroup => (
                <RouteGroup
                  key={`routeGroup-${routeGroup.id}`}
                  classes={classes}
                  routeGroup={routeGroup}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

RoutesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  containerRef: PropTypes.shape({ component: PropTypes.instanceOf(React.Component) }),
  tableData: PropTypes.array,
}

const mapDispatchToProps = { updateSortState }

const mapStateToProps = state => {
  return {
    tableData: routeGroupsWithMetricsSelector(state),
    sortState: sortStateSelector(state),
    selectedRouteIds: selectedRouteIdsSelector(state),
    isRoutesMetricsLoading: isRoutesMetricsLoadingSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoutesTable))
