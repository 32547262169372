import { COMMENTS_REDUCER_KEY, types } from './actions'

import { handleActions } from 'redux-actions'

export { COMMENTS_REDUCER_KEY }

export const initialState = {
  comments: {
    isLoading: false,
    isFailed: false,
    data: [],
  },
  summaries: {
    isLoading: false,
    isFailed: false,
    data: {},
  },
  editorIsEnabled: false,
  editorInitialContent: null,
}

export const commentsStateSelector = state => state[COMMENTS_REDUCER_KEY].comments
export const commentSummariesStateSelector = state => state[COMMENTS_REDUCER_KEY].summaries

export const editorIsEnabledSelector = state => state[COMMENTS_REDUCER_KEY].editorIsEnabled
export const editorInitialContentSelector = state =>
  state[COMMENTS_REDUCER_KEY].editorInitialContent

export default handleActions(
  {
    [types.FETCH_COMMENTS_START]: state => ({
      ...state,
      comments: {
        ...state.comments,
        isLoading: true,
        isFailed: false,
        data: [],
      },
    }),
    [types.FETCH_COMMENTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      comments: {
        ...state.comments,
        isLoading: false,
        isFailed: false,
        data: payload,
      },
    }),
    [types.FETCH_COMMENTS_ERROR]: state => ({
      ...state,
      comments: {
        ...state.comments,
        isLoading: false,
        isFailed: true,
        data: [],
      },
    }),
    [types.FETCH_COMMENT_SUMMARIES_START]: state => ({
      ...state,
      summaries: {
        ...state.summaries,
        isLoading: true,
        isFailed: false,
      },
    }),
    [types.FETCH_COMMENT_SUMMARIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      summaries: {
        ...state.summaries,
        isLoading: false,
        isFailed: false,
        data: { ...state.summaries.data, ...payload },
      },
    }),
    [types.FETCH_COMMENT_SUMMARIES_ERROR]: state => ({
      ...state,
      summaries: {
        ...state.summaries,
        isLoading: false,
        isFailed: true,
      },
    }),
    [types.SET_EDITOR_IS_ENABLED]: (state, { payload }) => ({
      ...state,
      editorIsEnabled: payload,
    }),
    [types.SET_EDITOR_INITIAL_CONTENT]: (state, { payload }) => ({
      ...state,
      editorInitialContent: payload,
    }),
  },
  initialState
)
