import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Popover,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { Component, Fragment } from 'react'

import AddIcon from '@material-ui/icons/Add'
import Button from 'components/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'
import DefaultFilters from 'components/Filtering/DefaultFilters'
import PropTypes from 'prop-types'
import { RefsIcon } from 'components/icons/FilterIcons'
import SearchIcon from '@material-ui/icons/Search'
import filter from 'lodash/filter'
import { findDOMNode } from 'react-dom'
import isEmpty from 'lodash/isEmpty'
import styles from './styles'

class FilterOptions extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    showImportButton: PropTypes.bool,
    showDefaultFilters: PropTypes.bool,
    toggleImportDialog: PropTypes.func,
    // filters: PropTypes.array.isRequired,
    filterGroups: PropTypes.array.isRequired,
    filterConfigs: PropTypes.array.isRequired,
    addFilter: PropTypes.func.isRequired,
    activeFilter: PropTypes.object,
    setActiveFilter: PropTypes.func.isRequired,
    unsetActiveFilter: PropTypes.func.isRequired,
    customization: PropTypes.object,
  }

  static defaultProps = {
    toggleImportDialog: () => {},
  }

  constructor(props) {
    super(props)
    this.anchorEl = React.createRef()
    this.state = {
      searchText: '',
      open: false,
    }
  }

  componentDidMount() {
    if (this.anchorEl) {
      this.setState({
        anchorEl: findDOMNode(this.anchorEl.current),
      })
    }
  }

  hidePopover = () => {
    this.setState({
      open: false,
      searchText: '',
    })
  }

  showPopover = () => {
    this.setState({
      open: true,
    })
  }

  handleOptionClick = filter => () => {
    this.hidePopover()
    this.props.setActiveFilter(filter)
  }

  handleImportClick = e => {
    e.preventDefault()
    this.hidePopover()
    this.props.toggleImportDialog()
  }

  renderListSubheader(group) {
    const { classes } = this.props

    return (
      <ListSubheader className={classes.subheader}>
        <div className={classes.subheaderTextContainer}>
          {group.icon && <group.icon className={classes.subheaderIcon} />}
          <span className={classes.subheaderTitle}>{group.name}</span>
        </div>
        <Typography variant="body2" className={classes.subheaderDescription}>
          {group.description}
        </Typography>
      </ListSubheader>
    )
  }

  renderLineItems(categorizedFilters) {
    const { classes } = this.props
    const { searchText } = this.state

    const displayableFilters = filter(categorizedFilters, filter => {
      const matchesSearchText = filter.title.toLowerCase().includes(searchText.toLowerCase())
      const notRollupFilter = filter.filterData.type !== 'dialog'

      return notRollupFilter && matchesSearchText
    })

    if (isEmpty(displayableFilters)) return null

    const filterListItems = displayableFilters.map(filter => {
      return (
        <ListItem
          dense
          margin="dense"
          key={`filter-option-${filter.name}`}
          button
          onClick={this.handleOptionClick(filter)}
        >
          <ListItemText primary={filter.title} classes={{ primary: classes.listItemText }} />
        </ListItem>
      )
    })

    return filterListItems
  }

  render() {
    const {
      classes,
      filterGroups,
      showImportButton,
      showDefaultFilters,
      addFilter,
      filterConfigs,
    } = this.props
    const { open, searchText, anchorEl } = this.state
    const searchEmpty = searchText === ''
    return (
      <div className={classes.root}>
        <Button
          size="medium"
          className={classes.addButton}
          ref={this.anchorEl}
          color="primary"
          onClick={this.showPopover}
          variant="outlined"
        >
          <AddIcon className={classes.addIcon} />
          Add a filter
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onExit={this.log}
          elevation={3}
          classes={{ paper: classes.filterMenuPopover }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={this.hidePopover} // only fires on 'esc' keydown
        >
          <div className={classes.container} data-testid="filtering__filter-options">
            <div className={classes.header}>
              <Typography variant="h6" component="span">
                Filters
              </Typography>
              <span>
                <IconButton onClick={this.hidePopover}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </span>
            </div>

            <div className={classes.filterLookup}>
              <div className={classes.lookupControl}>
                <TextField
                  className={classes.searchInput}
                  margin="dense"
                  name="filter_query"
                  value={searchText}
                  autoFocus
                  onChange={e => this.setState({ searchText: e.target.value })}
                  placeholder="Search filters"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={searchEmpty}>
                          {searchEmpty ? (
                            <SearchIcon fontSize="small" />
                          ) : (
                            <CancelIcon
                              onClick={() => this.setState({ searchText: '' })}
                              fontSize="small"
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            {showImportButton && (
              <div className={classes.importButtonContainer}>
                <ListSubheader className={classes.subheader}>
                  <div className={classes.subheaderTextContainer}>
                    <RefsIcon />
                    <span className={classes.subheaderTitle}>References</span>
                  </div>
                </ListSubheader>
                <ListItem dense margin="dense" button onClick={this.handleImportClick}>
                  <ListItemText
                    primary="Filter by Specific References"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              </div>
            )}

            {showDefaultFilters && (
              <DefaultFilters
                addFilter={addFilter}
                filterText={this.state.searchText}
                onClick={this.hidePopover}
              />
            )}

            <List className={classes.list}>
              {filterGroups.map(category => {
                const categorizedFilters = filter(filterConfigs, filter => {
                  return filter.filterData.group === category.name
                })

                return (
                  <Fragment key={`option-group-subheader-${category.name}`}>
                    {this.renderListSubheader(category)}
                    {this.renderLineItems(categorizedFilters)}
                  </Fragment>
                )
              })}
            </List>
          </div>
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles)(FilterOptions)
