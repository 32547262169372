import { Box, Theme, makeStyles } from '@material-ui/core'

import CardOverlay from 'components/core/CardOverlay'
import React from 'react'
import Skeleton from 'components/Skeleton'
import classnames from 'classnames'

const border = (theme: Theme) => `1px solid ${theme.palette.grey[100]}`

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  row: {
    minWidth: 925,
    padding: 16,
  },
  detailSection: {
    borderRadius: theme.shape.borderRadius,
    flex: 1,
    border: border(theme),
  },
  borderRight: {
    borderRight: border(theme),
    marginRight: 20,
  },
  leftSide: {},
  rightSide: {},
  upperDetailSection: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: 20,

    '& > div': {
      alignItems: 'center',
      paddingBottom: 2,
      paddingTop: 2,
    },
  },
  lowerDetailSection: {
    display: 'flex',
    paddingLeft: 20,
  },
  leftSection: {
    width: '10%',
  },
  centerSection: {
    width: '20%',
  },
  rightSection: {
    width: '25%',
  },
  upperLeft: {
    marginRight: 40,
  },
  lowerLeft: {
    marginRight: 20,
  },
  rightOne: {
    alignSelf: 'center',
    marginRight: 20,
  },
  gutter: {
    marginTop: `${theme.spacing(0.5)}px !important`,
    marginBottom: `${theme.spacing(0.5)}px !important`,
  },
}))

interface Props {
  isAnimated: boolean
  showExpandedView?: boolean
}

/**
 * `showExpandedView` includes a checkbox and an additional cell, currently used for board Timeline View
 */
function SkeletonRow(props: Props) {
  const { isAnimated, showExpandedView = false } = props
  const classes = useStyles()

  const detailSectionHeight = 16
  return (
    <Box className={classes.row} display="inline-flex" flexDirection="row">
      {showExpandedView && (
        <Box mr={2}>
          <Skeleton
            borderRadius="small"
            height={16}
            isAnimated={isAnimated}
            width={16}
            gutter={false}
          />
        </Box>
      )}
      <Box mr={2.5} className={classnames(classes.detailSection, classes.leftSide)}>
        <Box className={classes.upperDetailSection}>
          {showExpandedView && (
            <Box className={classes.borderRight}>
              <Skeleton
                className={classnames(classes.upperLeft, classes.gutter)}
                isAnimated={isAnimated}
                width="40px"
                height={detailSectionHeight}
              />
              <Skeleton
                className={classnames(classes.lowerLeft, classes.gutter)}
                isAnimated={isAnimated}
                width="60px"
                height={detailSectionHeight}
              />
            </Box>
          )}
          <Box className={classes.borderRight}>
            <Skeleton
              className={classnames(classes.upperLeft, classes.gutter)}
              isAnimated={isAnimated}
              width="60px"
              height={detailSectionHeight}
            />
            <Skeleton
              className={classnames(classes.lowerLeft, classes.gutter)}
              isAnimated={isAnimated}
              width="80px"
              height={detailSectionHeight}
            />
          </Box>
          <Box pr={2.5}>
            <Skeleton
              className={classes.gutter}
              isAnimated={isAnimated}
              width="80px"
              height={detailSectionHeight}
            />
            <Skeleton
              className={classes.gutter}
              isAnimated={isAnimated}
              width="60px"
              height={detailSectionHeight}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classnames(classes.detailSection, classes.rightSide)}>
        <Box className={classes.upperDetailSection}>
          <Box className={classes.borderRight} display="flex">
            <Skeleton
              className={classnames(classes.rightOne, classes.gutter)}
              gutter={false}
              isAnimated={isAnimated}
              width={140}
              height={24}
            />
            <Box>
              <Skeleton
                className={classnames(classes.upperLeft, classes.gutter)}
                isAnimated={isAnimated}
                width="80px"
                height={detailSectionHeight}
              />
              <Skeleton
                className={classnames(classes.lowerLeft, classes.gutter)}
                isAnimated={isAnimated}
                width="120px"
                height={detailSectionHeight}
              />
            </Box>
          </Box>
          <Box display="flex">
            <Box mr={5}>
              <Skeleton
                className={classes.gutter}
                isAnimated={isAnimated}
                width="100px"
                height={detailSectionHeight}
              />
              <Skeleton
                className={classes.gutter}
                isAnimated={isAnimated}
                width="120px"
                height={detailSectionHeight}
              />
            </Box>
            <Box mr={2.5}>
              <Skeleton
                className={classes.gutter}
                isAnimated={isAnimated}
                width="140px"
                height={detailSectionHeight}
              />
              <Skeleton
                className={classes.gutter}
                isAnimated={isAnimated}
                width="140px"
                height={detailSectionHeight}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

interface TimelineHeaderSkeletonProps {
  cardOverlayClasses?: Record<string, string>
  isAnimated?: boolean
  message?: string | React.ReactNode
  rowCount?: number
  showExpandedView?: boolean
}

export default function TimelineHeaderSkeleton(props: TimelineHeaderSkeletonProps) {
  const {
    cardOverlayClasses = {},
    isAnimated = true,
    message = '',
    rowCount = 1,
    showExpandedView = false,
  } = props
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box>
        {[...Array(rowCount)].map((_, i) => (
          <SkeletonRow isAnimated={isAnimated} key={i} showExpandedView={showExpandedView} />
        ))}
      </Box>
      {message && (
        <CardOverlay classes={cardOverlayClasses}>
          <>{message}</>
        </CardOverlay>
      )}
    </Box>
  )
}
