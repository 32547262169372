import FilterDrawer, { FilterOptionsEnum } from 'components/FilterDrawer'
import { Grid, withStyles } from '@material-ui/core'

import CMPaper from 'components/CMPaper'
import ContainerStatusScorecards from './ContainerStatusScorecards'
import ContainerTripPerformanceChart from './ContainerTripPerformanceChart'
import { DASHBOARD_DRAWER_KEY } from 'store/drawers'
import { DASHBOARD_FILTER_KEY } from 'store/filterGroups/actions'
import DwellChart from './DwellChart'
import React from 'react'
import RollsOverTimeChart from './RollsOverTimeChart'
import TopLanesByVolumeChart from './TopLanesByVolumeChart'
import VariableRoutesChart from './VariableRoutesChart'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { drawersSelector } from 'store/drawers'
import merge from 'lodash/merge'
import { muiBreakpointLg } from './utils/muiBreakpointOverrides'
import tracker from 'utils/logger/tracker'
import { useHighchartsReflow } from 'utils/hooks'

const styles = theme => {
  theme = merge(theme, muiBreakpointLg)
  return {
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.grey[25],
      flexGrow: 1,
      padding: 30,
      overflow: 'auto',
    },
    grid: {
      maxWidth: 2200,
    },
  }
}

export const GridItem = withStyles(() => ({
  gridItem: {
    padding: 25,
    minHeight: 300,
    height: '100%',
  },
}))(({ children, classes, breakpoints = {} }) => {
  return (
    <Grid item={true} {...breakpoints}>
      <CMPaper className={classes.gridItem}>{children}</CMPaper>
    </Grid>
  )
})

export const PlainGridItem = ({ children, breakpoints = {} }) => {
  return (
    <Grid item={true} {...breakpoints}>
      {children}
    </Grid>
  )
}

const UserDashboardPage = ({ classes, drawerState }) => {
  // Reflow the charts when the filter drawer is opened / closed.
  useHighchartsReflow([drawerState])

  return (
    <>
      <FilterDrawer
        drawerKey={DASHBOARD_DRAWER_KEY}
        filterKey={DASHBOARD_FILTER_KEY}
        tracker={tracker.dashboard}
        availableFilterOptions={[
          FilterOptionsEnum.ORIGIN,
          FilterOptionsEnum.DESTINATION,
          FilterOptionsEnum.OPERATOR,
        ]}
      />
      <div className={classes.root} data-testid="user-dashboard">
        <Grid container={true} spacing={3} className={classes.grid}>
          <GridItem breakpoints={{ xs: 12, lg: 8 }}>
            <ContainerTripPerformanceChart />
          </GridItem>
          <PlainGridItem breakpoints={{ xs: 12, lg: 4 }}>
            <ContainerStatusScorecards />
          </PlainGridItem>
          <GridItem breakpoints={{ xs: 12, lg: 6 }}>
            <TopLanesByVolumeChart />
          </GridItem>
          <GridItem breakpoints={{ xs: 12, lg: 6 }}>
            <VariableRoutesChart />
          </GridItem>
          <GridItem breakpoints={{ xs: 12, lg: 6 }}>
            <RollsOverTimeChart />
          </GridItem>
          <GridItem breakpoints={{ xs: 12, lg: 6 }}>
            <DwellChart />
          </GridItem>
        </Grid>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  drawerState: drawersSelector(state)[DASHBOARD_DRAWER_KEY],
})

export default compose(connect(mapStateToProps), withStyles(styles))(UserDashboardPage)
