import { Box, Typography, makeStyles } from '@material-ui/core'

import CommentsLinkButton from 'components/CommentsLinkButton'
import ContainerCell from 'components/EntityPageHeader/ContainerCell'
import { EntityPageHeaderInterface } from 'store/models/EntityPageHeader'
import InternalUserComponent from 'components/InternalUserComponent'
import Link from 'components/core/Link'
import NotProvided from 'components/NotProvided'
import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  overviewRoot: {},
  row: {},
  cell: {},
  muted: {},
  noData: {},
  flexColumn: {},
  italic: {},
  item: {},
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  overviewContainer: {
    minWidth: 255,
  },
  refNumber: {
    fontWeight: 600,
  },
}))

const CONTAINER_LIST_COLUMN_COUNT = 20

interface Props {
  isBoard?: boolean
  classes?: Record<string, string>
  className?: string
  commentIconLink?: string | null
  entityLink?: string
  externalId: string
  isContainerPage: boolean
  isPublicShare?: boolean
  pageHeaderData?: EntityPageHeaderInterface
  refTypeLabel?: string
}

/**
 * Left side of the `EntityPageHeader` component
 */
export const Overview = (props: Props) => {
  const classes = useStyles(props)
  const { entityLink, isBoard = false, isPublicShare = false, pageHeaderData } = props
  const { carriers, containerNumber, equipment, trips = [] } = pageHeaderData ?? {}
  const { commentIconLink, externalId, isContainerPage = false, refTypeLabel } = props
  const containerType = equipment?.type?.description

  /**
   * We split the trips array into an array of arrays, each with a length of `CONTAINER_LIST_COLUMN_COUNT` so
   * we can display columns of container ids in the popover: `[ [123, 445], [556, 777] ]`. If we have fewer than
   * that we just get `[ [123, 445] ]`.
   */
  let containerListColumns = []
  const containerTrips = [...trips]
  while (containerTrips.length) {
    containerListColumns.push(containerTrips.splice(0, CONTAINER_LIST_COLUMN_COUNT))
  }

  return (
    <Box
      className={classnames(classes.item, classes.overviewContainer, classes.overviewRoot)}
      data-testid="page-header__overview"
      display="flex"
      flexDirection={isBoard ? 'row' : 'column'}
    >
      {/* Top row with ref type and number */}
      <Box
        className={classnames(classes.row, classes.cell, classes.flexColumn, {
          [classes.borderRight]: isBoard,
        })}
      >
        <Typography variant="body2" className={classes.muted}>
          {refTypeLabel}
        </Typography>
        <Typography variant={isBoard ? 'body1' : 'h6'} className={isBoard ? '' : classes.refNumber}>
          {!isBoard || (isBoard && !entityLink) ? (
            <>{containerNumber}</>
          ) : (
            <Link to={entityLink}>{containerNumber}</Link>
          )}
          {!isPublicShare && (
            <InternalUserComponent>
              <CommentsLinkButton
                iconButtonProps={{ size: 'small' }}
                link={commentIconLink}
                externalId={externalId}
              />
            </InternalUserComponent>
          )}
        </Typography>
      </Box>
      {/* Second row with carrier and containers */}
      <Box display="flex" flexDirection="row" flexWrap="nowrap" flexGrow="1">
        <Box className={classnames(classes.cell, classes.flexColumn, classes.borderRight)}>
          <Typography variant="body2" className={classes.muted}>
            Carrier
          </Typography>
          {carriers?.length !== 0 ? (
            <Typography variant="body2">{carriers?.join(', ')}</Typography>
          ) : (
            <NotProvided />
          )}
        </Box>
        <Box className={classnames(classes.cell, classes.flexColumn)} flexGrow="1">
          {/* The container page displays the container type */}
          {isContainerPage ? (
            <>
              <Typography variant="body2" className={classes.muted}>
                Type
              </Typography>
              {containerType ? (
                <Typography variant="body2">{containerType}</Typography>
              ) : (
                <NotProvided />
              )}
            </>
          ) : (
            <ContainerCell
              classes={{ muted: classes.muted }}
              isPublicShare={isPublicShare}
              refTypeLabel={refTypeLabel}
              trips={pageHeaderData?.trips ?? []}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Overview
