import {
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import colors from 'components/core/Colors'

export const EmailIcon = props => (
  <svg width={20} height={17} {...props}>
    <title>{'Path 3'}</title>
    <path
      d="M0 7.755v-1.3h15V17H0V7.755zm13.54 0H1.46l6.04 3.123 6.04-3.123zM1.364 15.754h12.272V9.137L7.5 12.31 1.364 9.137v6.617zM17.42 2.353L14.7 5.066 14 4.358l2.716-2.71L15.068 0h4v4l-1.647-1.647z"
      fill={colors.grey[600]}
      fillRule="nonzero"
    />
  </svg>
)

const styles = theme => ({
  wrapper: {
    minWidth: 250,
  },
  badge: {
    backgroundColor: theme.palette.blue[800],
    minWidth: 14,
    marginTop: -4,
    border: '1px solid white',
  },
  sharedWith: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    fontWeight: theme.typography.fontWeightRegular,
  },
  listItem: {},
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  backupBadge: {
    backgroundColor: 'red',
    width: 12,
    height: 12,
    top: -4,
    right: -5,
  },
  emailIcon: {
    marginTop: -6,
  },
  iconButton: {
    height: 42,
    width: 42,
  },
  iconLabel: {
    width: 30,
  },
})

class EmailDropDown extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    emails: PropTypes.array,
  }
  static defaultProps = {
    emails: [],
  }
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { classes, emails } = this.props
    return (
      <>
        <Tooltip title={'View email recipients'}>
          <IconButton
            onClick={this.handleClick}
            classes={{ root: classes.iconButton, label: classes.iconLabel }}
          >
            <EmailIcon className={classes.emailIcon} />
          </IconButton>
        </Tooltip>
        <Popover
          classes={{
            paper: classes.wrapper,
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handleClose}
        >
          <Typography variant="body2" className={classes.sharedWith}>
            You emailed this shared link to:
          </Typography>
          <List className={classes.list}>
            {emails.map((item, idx) => {
              return (
                <ListItem dense key={`email-item-${idx}`} className={classes.listItem}>
                  <Typography variant="body2">{item}</Typography>
                </ListItem>
              )
            })}
          </List>
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(EmailDropDown)
