import { Box, ListItem, Paper, Theme, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { fetchPublicShare, updatePublicShareSelectedShipment } from 'store/publicShare/actions'
import { publicShareSelectedShipmentSelector, publicShareSelector } from 'store/publicShare'
import { useDispatch, useSelector } from 'react-redux'

import CenteredLayout from 'components/layouts/CenteredLayout'
import EntityPageHeader from 'components/EntityPageHeader'
import ErrorLayout from 'components/layouts/ErrorLayout'
import Loader from 'components/Loader'
import SharedLinksDrawer from 'pages/PublicSharedPage/SharedLinksDrawer'
import TimelineView from 'pages/EntityPage/EntityPageTabs/TimelineView'
import { TransportDetailInterface } from 'store/models/TransportDetail/interfaces'
import logger from 'utils/logger'
import { useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingTop: theme.spacing(4),
    width: '100%',
  },
  loader: {
    marginTop: '300px',
  },
  container: {
    backgroundColor: theme.palette.grey[100],
    minHeight: '100%',
  },
  sharedContainer: {
    display: 'flex',
    height: '100%',
  },
  contentWrapper: {
    marginLeft: 280,
    width: '100%',
  },
  listItemSelected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[900],
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.grey[900],
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  listItem: {
    ...theme.typography.body2,
    color: theme.palette.white,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  disclaimer: {
    marginTop: theme.spacing(1),
  },
  updatedWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  // Mimics what's done on the new entity page tabs
  fauxTabContentWrapper: {
    background: theme.palette.grey[100],
    borderTop: `2px solid ${theme.palette.grey[300]}`,
    boxShadow: `0 50vh 0 50vh ${theme.palette.grey[100]}`,
    marginTop: -14,
    height: '100%',
  },
  errorHeader: {
    color: theme.palette.blue[800],
    fontWeight: theme.typography.fontWeightMedium,
  },
  errorHeaderText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

interface MatchParams {
  token: string
}

interface Props {
  classes?: Record<string, string>
}

const PublicSharedPage = (props: Props) => {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const match = useRouteMatch<MatchParams>()
  const publicShare = useSelector(publicShareSelector)
  const selectedShipment = useSelector(publicShareSelectedShipmentSelector)
  const shipmentData = publicShare?.data

  useEffect(() => {
    dispatch(fetchPublicShare(match.params.token))
  }, [dispatch, match])

  useEffect(() => {
    const shipmentIdsAsStr =
      shipmentData &&
      shipmentData.map((shipment: TransportDetailInterface) => shipment.self.id).join('-')
    if (shipmentIdsAsStr) {
      logger.notify('Share Page Load Success', {
        ids: shipmentIdsAsStr,
        token: match.params.token,
      })
    }
  }, [match, shipmentData])

  if (publicShare.isLoading || (!publicShare.isFailed && !shipmentData)) {
    return (
      <Box className={classes.container}>
        <CenteredLayout>
          <Loader className={classes.loader} />
        </CenteredLayout>
      </Box>
    )
  }

  if (publicShare.isFailed || (shipmentData && shipmentData.length === 0)) {
    logger.notify('Shared Shipments Page Load Failure', { token: match.params.token })
    return (
      <ErrorLayout
        headerText={
          <Typography variant="h5" color="primary" className={classes.errorHeaderText}>
            Sorry, this page is not available.
          </Typography>
        }
        body={
          <>
            <Typography variant="subtitle1" className={classes.errorHeader}>
              Why did this happen?
            </Typography>
            <Box my={2}>
              <Typography variant="body1">
                The URL was entered incorrectly or has expired. Contact the original sender to
                ensure it's a valid link.
              </Typography>
            </Box>
            <Typography variant="body1">
              There was a connection or server issue. Please refresh the page or try again later.
            </Typography>
          </>
        }
      />
    )
  }

  const currentShipment = selectedShipment ?? shipmentData[0]
  const pageHeader = currentShipment?.pageHeader
  const shipmentLinks = shipmentData?.map((shipment: TransportDetailInterface) => {
    return (
      <ListItem
        button
        className={classes.listItem}
        key={shipment.self.id}
        onClick={() => {
          logger.notify('Share Page navigation Shared Link click')
          dispatch(updatePublicShareSelectedShipment(shipment))
        }}
        selected={shipment.self.id === currentShipment.self.id}
        classes={{ selected: classes.listItemSelected }}
      >
        {shipment.self.number}
      </ListItem>
    )
  })

  return (
    <Box className={classes.container}>
      <Box className={classes.sharedContainer}>
        <SharedLinksDrawer items={shipmentLinks} />
        {currentShipment && (
          <Box className={classes.contentWrapper}>
            <Paper>
              <Box px={6} className={classes.header}>
                <Box display="flex" flexWrap="nowrap" flexDirection="row">
                  <Box>
                    {pageHeader && (
                      <EntityPageHeader
                        externalId={currentShipment?.self.externalId}
                        isContainerPage={false}
                        isPublicShare={true}
                        pageHeaderData={pageHeader}
                        refTypeLabel={pageHeader?.refTypeLabel}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box pt={3} px={6} pb={12} className={classes.fauxTabContentWrapper}>
                <TimelineView isPublicShare={true} transportDetail={currentShipment} />
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PublicSharedPage
