import { MAIN, POST_MAIN, PRE_MAIN } from './definitions'

import { GroupedByUnlocodeMilestoneInterface } from './interfaces'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'

/**
 * Filters and groups milestones by stage. To be added to a stage a milestone:
 *    - must have one of the three timestamps: planned, predicted or traced
 *    - must have a `locationZone`
 *    - must have a `stopSegment.tripSegment.stage`
 * @param milestones array of milestone objects returned from API
 * @returns object with milestones put into stage buckets: { preMain: [], main: [], postMain: []}
 */
const groupMilestonesByStage = (milestones: GroupedMilestoneInterface[]) => {
  const groupedByStageMilestones: GroupedByUnlocodeMilestoneInterface = {
    [PRE_MAIN]: [],
    [MAIN]: [],
    [POST_MAIN]: [],
  }

  milestones?.forEach((milestone: GroupedMilestoneInterface) => {
    const stage = milestone.stopSegment?.tripSegment?.stage as keyof typeof groupedByStageMilestones
    const hasTime: boolean = Boolean(
      milestone.plannedTime || milestone.predictedTime || milestone.tracedTime
    )
    const hasLocationZone: boolean = Boolean(milestone.locationZone)

    if (hasLocationZone && hasTime && groupedByStageMilestones[stage]) {
      groupedByStageMilestones[stage].push(milestone)
    }
  })

  return groupedByStageMilestones
}

export default groupMilestonesByStage
