import CARRIER_ABBREVIATIONS from 'utils/definitions/carrierAbbreviations'
import isNil from 'lodash/isNil'
import round from 'lodash/round'

export const NA_STRING = 'N/A'

export function toTitleCase(str) {
  if (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
  return ''
}

/**
 * Pluralizes a word conditionally
 * @param {string} word The word to pluralize
 * @param {boolean} conditional A functional test that if `true` will pluralize `word`
 * @example `pluralize('dog', dog.count > 1)`
 */
export const pluralizeWord = (word, conditional = true) => {
  if (!word.trim()?.length) {
    return ''
  }
  return conditional ? word + 's' : word
}

export const formatVesselLabel = label => {
  return formatCarrierLabel(label)
}

export const formatCarrierLabel = label => {
  if (label) {
    let labelChunks = label.split(' ')
    let modifiedChunks = labelChunks.map(chunk => {
      if (CARRIER_ABBREVIATIONS.indexOf(chunk.toUpperCase()) === -1) {
        // Not a carrier abbreviation
        return chunk.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
      }
      return chunk.toUpperCase()
    })
    return modifiedChunks.join(' ')
  }
}

export const formatLocationLabel = label => {
  if (label)
    return label.replace(/[\w']*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export const formatMinimalDecimals = (val, maxPlaces = 2) => round(val, maxPlaces).toString()

export const formatDecimal = (num, maxPlaces = 2, isEmptyResponse = 'N/A') =>
  isNil(num) ? isEmptyResponse : formatMinimalDecimals(num, maxPlaces)
export const formatPercentage = (num, maxPlaces = 2, isEmptyResponse = 'N/A') =>
  isNil(num) ? isEmptyResponse : formatMinimalDecimals(num * 100, maxPlaces) + '%'

export const validatePasswordStrength = pwd => {
  // Regex Explanation
  // https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
  // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
  // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
  // (?=.*[0-9])	The string must contain at least 1 numeric character
  // (?=.[!@#\$%\^&])	The string must contain at least one special character,
  // but we are escaping reserved RegEx characters to avoid conflict
  // (?=.{8,})	The string must be eight characters or longer
  const pwdRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*~=+_)(-])(?=.{8,})')
  return pwdRegex.test(pwd)
}

export const validatePasswordWithUsername = (pwd, username) => {
  return !pwd.includes(username) && !username.includes(pwd)
}

/**
 * - Capitalizes first letter of each word in city name
 * - Leaves state/country as all caps _if_ abbreviation (3 letters or less), otherwise capitalizes first letter
 * - If `str` has more than 1 comma we process everything up to the last comma as the _location_ and
 *    capitalize that, then append the state/country as usual
 *  `LONG BEACH T PIER, CA` => `Long Beach T Pier, CA`
 *  `LONG BEACH T PIER, CALIFORNIA` => `Long Beach T Pier, California`
 *  `'HO CHI MINH, VICT, VIETNAM'` => `Ho Chi Minh, Vict, Vietnam`
 * @param string str, location
 * @returns string
 */
export const formatLocation = str => {
  if (!str) {
    return null
  }

  const capitalize = str =>
    str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  const location = str.split(',')

  // We're taking whatever comes after the last comma as country/state
  const countryState = location[location.length - 1].trim()

  // AB remains AB, ITALY becomes Italy
  if (countryState && countryState.length > 3) {
    return `${capitalize(str)}`
  }

  // 1 or 0 commas, we capitalize the first index
  let locationStr = capitalize(location[0])

  // More than 1 comman we grab everything up to the last comma and then strip off
  // any trailing spaces or commas. This allows us to still append the country/state below
  // So `HO CHI MINH, VICT, VIETNAM` becomes `Ho Chi Minh, Vict`
  if (location.length > 1) {
    locationStr = capitalize(str)
      .slice(0, -countryState.length - 1)
      .replace(/[,\s]+$/, '')
  }

  // Finally, we append the properly capitalized country/state
  return `${locationStr}, ${countryState.toUpperCase()}`
}
