import { call, put, takeLatest } from 'redux-saga/effects'
import { createAction, handleActions } from 'redux-actions'

import client from 'utils/api/client'
import logger from 'utils/logger'

const ORDER_UPLOAD_START = 'app/orderUpload/ORDER_UPLOAD_START'
const ORDER_UPLOAD_SUCCESS = 'app/orderUpload/ORDER_UPLOAD_SUCCESS'
const ORDER_UPLOAD_ERROR = 'app/orderUpload/ORDER_UPLOAD_ERROR'

export const uploadOrders = createAction(ORDER_UPLOAD_START, (file, rawData) => ({
  file,
  rawData,
}))

export const uploadOrdersLoadingSelector = state => state.orderUpload.loading
export const uploadOrdersErrorSelector = state => state.orderUpload.error
export const uploadOrdersSuccessSelector = state => state.orderUpload.success

const initialState = {
  loading: false,
  error: false,
  success: false,
}

const convertExcelToCsv = binaryData => {
  return new Promise(resolve => {
    // this is dynamically imported because it is very large. the dynamic import will cause webpack to break this
    // into a separate chunk, making it so that people other than CX never need to even load this > 1 MB library. see
    // https://reactjs.org/docs/code-splitting.html#import for more information
    import('xlsx').then(XLSX => {
      const workbook = XLSX.read(binaryData, {
        type: 'binary',
      })

      const firstSheetName = workbook.SheetNames[0]
      /* Get worksheet */
      const worksheet = workbook.Sheets[firstSheetName]
      const csv = XLSX.utils.sheet_to_csv(worksheet)
      resolve(csv)
    })
  })
}

const parseFile = (file, data) => {
  return new Promise(resolve => {
    const extension = file.name.split('.').pop().toLowerCase()
    if (extension === 'xlsx') {
      convertExcelToCsv(data).then(convertedCsv => {
        resolve(convertedCsv)
      })
    } else {
      resolve(data)
    }
  })
}

function delay(millisec) {
  return new Promise(resolve => setTimeout(resolve, millisec))
}

export function* uploadOrdersAsync({ payload }) {
  try {
    const { file, rawData } = payload
    yield delay(200) // TODO -  because we are potentially parsing big files, we need to put a small delay
    // to give the UI a chance to rerender before we start parsing

    const parsedFile = yield parseFile(file, rawData)
    const data = {
      shipment_file_contents: parsedFile,
    }
    yield call(client.post, '/shipments/csv_import', data)
    yield put({ type: ORDER_UPLOAD_SUCCESS })
  } catch (err) {
    logger.captureAPIException(err)
    logger.localLog(err, 'error')
    yield put({ type: ORDER_UPLOAD_ERROR })
  }
}

export function* watchUploadOrders() {
  yield takeLatest(ORDER_UPLOAD_START, uploadOrdersAsync)
}

export default handleActions(
  {
    [ORDER_UPLOAD_START]: (state, payload) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [ORDER_UPLOAD_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [ORDER_UPLOAD_ERROR]: (state, payload) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState
)
