import React, { useState } from 'react'
import { Tooltip, Typography, withStyles } from '@material-ui/core'

import Badge from 'components/Badge'
import Button from 'components/core/Button'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import logger from 'utils/logger'

const ConditionalWrapper = ({ condition, ifWrapper, elseWrapper, children }) =>
  condition ? ifWrapper(children) : elseWrapper(children)

export const SideNavLink = ({
  badgeProps,
  children,
  classes,
  className,
  isDisabled = false,
  icon,
  id,
  linkProps,
  onClick,
  text,
  tooltipProps,
  selected,
  childMenuOpen,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const wrapperProps = {
    className: classnames(
      classes.root,
      classes.link,
      {
        [classes.selected]: selected && !isDisabled,
        [classes.selectedDisabled]: selected && isDisabled,
      },
      className
    ),
    onClick: e => {
      logger.notify(`Side Nav - ${id}`)
      setIsTooltipOpen(false)
      onClick(e)
    },
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }
  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  if (childMenuOpen && isTooltipOpen) {
    closeTooltip()
  }

  const augmentedTooltipProps = {
    ...tooltipProps,
    onClose: closeTooltip,
    open: isTooltipOpen,
    onMouseEnter: openTooltip,
    onMouseLeave: closeTooltip,
  }

  linkProps = isDisabled ? { ...linkProps, classes: { root: classes.disabled } } : linkProps

  return (
    <Tooltip {...augmentedTooltipProps} className={isDisabled ? classes.disabled : ''}>
      <span className={classes.tooltipWrapper}>
        <ConditionalWrapper
          condition={Boolean(linkProps)}
          ifWrapper={children => (
            <Button {...wrapperProps} component={Link} {...linkProps}>
              {children}
            </Button>
          )}
          elseWrapper={children => <Button {...wrapperProps}>{children}</Button>}
        >
          <span className={classes.sidebarLinkContent}>
            <Badge color="error" {...badgeProps}>
              <span>{icon}</span>
            </Badge>
            {text && (
              <Typography color="inherit" className={classnames(classes.title)} variant="caption">
                {text}
              </Typography>
            )}
            {children}
          </span>
        </ConditionalWrapper>
      </span>
    </Tooltip>
  )
}

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[300],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: 0,
    width: '100%',
    fill: theme.palette.grey[300],

    '&:hover': {
      fill: theme.palette.common.white,
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.grey[200],
    },
  },
  tooltipWrapper: {
    width: '100%',
  },
  selected: {
    fill: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[200],
  },
  sidebarLinkContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    textTransform: 'capitalize',
    marginTop: theme.spacing(1),
  },
  link: {
    width: '100%',
    textDecoration: 'none',
  },
  disabled: {
    color: theme.palette.text.primary,
    '& svg': {
      fill: theme.palette.text.primary,
    },
    '&:hover': {
      color: theme.palette.grey[600],

      '& svg': {
        fill: theme.palette.grey[600],
      },
    },
  },
  selectedDisabled: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[600],
    '& svg': {
      fill: theme.palette.grey[600],
    },
  },
})

SideNavLink.defaultProps = {
  text: undefined,
  onClick: () => {},
  tooltipProps: {
    title: '', // Keeps tooltip hidden
  },
  badgeProps: {
    content: '',
    invisible: true,
  },
}

SideNavLink.propTypes = {
  isDisabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  badgeProps: PropTypes.shape({
    content: PropTypes.string,
    invisible: PropTypes.bool.isRequired,
  }),
  linkProps: PropTypes.shape({
    to: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
  }),
  onClick: PropTypes.func,
  icon: PropTypes.node,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltipProps: PropTypes.shape({
    title: PropTypes.string,
    placement: PropTypes.string,
  }),
  childMenuOpen: PropTypes.bool,
}

export default withStyles(styles)(SideNavLink)
