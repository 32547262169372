export default function parseChipValues(string) {
  if (!/\S/.test(string)) return []

  // split on newline, semicolon, or comma
  const splitValues = string.split(/[\n;,\s]/)
  // Strip out strings consisting of spaces
  const filteredValues = splitValues.filter(string => string.replace(/\s/g, '').length)
  // remove spaces
  const trimmedValues = filteredValues.map(item => item.trim())

  return trimmedValues
}
