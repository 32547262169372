import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { cancelAction, confirmAction } from 'store/confirmation'

import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: theme.spacing(60),
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  dialogTitle: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  title: {
    display: 'inline-block',
    flexGrow: '1',
    margin: 'auto',
  },
  content: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  contentText: {
    whiteSpace: 'pre-line',
  },
  actions: {
    paddingTop: 0,
  },
}))

export const Confirmation = ({
  cancelAction,
  confirmAction,
  confirmText,
  isDestructive,
  isOpen,
  message,
  title,
}) => {
  const classes = useStyles()
  const handleClose = () => {
    cancelAction()
  }

  const handleConfirm = () => {
    confirmAction()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="confirmation-dialog"
      open={isOpen}
      classes={{ paper: classes.root }}
      data-testid="confirmation-dialog"
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        <IconButton onClick={handleClose} data-testid="confirmation-dialog__cancel">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText
          component={Typography}
          variant="body1"
          color="textPrimary"
          className={classes.contentText}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {isDestructive ? (
          <Button
            variant="contained"
            onClick={handleConfirm}
            className={classes.errorButton}
            data-testid="confirmation-dialog__confirm"
          >
            {confirmText}
          </Button>
        ) : (
          <Button variant="text" onClick={handleConfirm} data-testid="confirmation-dialog__confirm">
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

Confirmation.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  isDestructive: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
}

Confirmation.defaultProps = {
  cancelAction: noop,
  confirmAction: noop,
  confirmText: 'Confirm',
  isDestructive: false,
  isOpen: false,
  message: '',
  title: '',
}

const mapStateToProps = state => ({ ...state.confirmation })

const mapDispatchToProps = {
  confirmAction,
  cancelAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation)
