import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'

export const WaypointType = {
  DIRECT: 'Direct',
  MULTIPLE: 'Multiple Ports',
}

export const getWaypoints = (locations, isRollup) => {
  const uniqLocations = uniqBy(flatten(locations), 'unlocode')

  if (isEmpty(uniqLocations)) {
    return [{ name: WaypointType.DIRECT }]
  }

  if (!isRollup) {
    return uniqLocations
  }

  const waypoints = []
  if (locations.some(subLocations => isEmpty(subLocations))) {
    waypoints.push({ name: WaypointType.DIRECT })
  }
  if (uniqLocations.length > 1) {
    waypoints.push({ name: WaypointType.MULTIPLE })
  } else {
    waypoints.push(uniqLocations[0])
  }

  return waypoints
}
