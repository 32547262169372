import { CheckboxProps, Theme, makeStyles } from '@material-ui/core'

import CheckedIcon from '@material-ui/icons/CheckBox'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { default as MUICheckbox } from '@material-ui/core/Checkbox'
import React from 'react'
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

/**
 * A custom checkbox component that sits on top of the MUI `Checkbox` component.
 * The interface extends MUI's interface, so we only include types here that are not native to MUI's `Checkbox`.
 *
 * Those things that are native, which here include things like `color`, `checked`, `onChange`, are handled in
 * the component with `...checkboxProps`.
 *
 * This can be confusing because spreading props _is_ confusing and generally we don't want to do it. This is really
 * a result of use using our own component that sits on another component, because we need to still support the
 * MUI API. The only way to avoid spreading props here would be to add every prop supported by MUI. The good news is,
 * since we are extending MUI's interface, if you try to pass a prop that is not supported TypeScript will complain.
 */
interface Props extends CheckboxProps {
  indeterminate?: boolean
  inputProps?: { [key: string]: string }
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onUncheck?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onIndeterminate?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = (props: Props) => {
  const classes = useStyles(props)
  const {
    color,
    checked,
    indeterminate = false,
    onCheck,
    onUncheck,
    onIndeterminate,
    ...checkboxProps // Use this for MUI Checkbox props, ala `disabled`, etc.
  } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (indeterminate) {
      // If a subset have been selected, then deselect them
      onIndeterminate && onIndeterminate(e)
    } else if (checked) {
      onCheck && onCheck(e)
    } else if (!checked) {
      onUncheck && onUncheck(e)
    }
  }

  return (
    <MUICheckbox
      classes={{ root: classes.root }}
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<IndeterminateCheckBoxIcon />}
      onChange={handleChange}
      checked={checked}
      indeterminate={indeterminate}
      color={color}
      {...checkboxProps}
    />
  )
}

export default Checkbox
