import PropTypes from 'prop-types'
import React from 'react'
import UserMenu from './UserMenu'
import { connect } from 'react-redux'
import { isSuperuserFromState } from 'store/auth/user/selectors'

const SuperUserMenu = ({ isSuperuser, ...rest }) => (isSuperuser ? <UserMenu {...rest} /> : null)

SuperUserMenu.propTypes = {
  isSuperuser: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isSuperuser: isSuperuserFromState(state),
})

export default connect(mapStateToProps)(SuperUserMenu)
