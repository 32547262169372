import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'
import { closeModal, types } from 'store/portalAlerts/actions'

import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import api from 'store/portalAlerts/api'
import getPayloads from './utils/getPayloads.js'
import logger from 'utils/logger'

export function getPortalAlertNotificationMessage(postPayload, putPayload, deletePayload) {
  if (!Array.isArray(postPayload) || !Array.isArray(putPayload) || !Array.isArray(deletePayload)) {
    const plural = false
    logger.error('Passed invalid input in getPortalAlertNotificationMessage()')
    return NOTIFICATION_MESSAGES.portalAlertsPut({ plural })
  }

  const postCount = postPayload.length
  const putCount = putPayload.length
  const deleteCount = deletePayload.length

  // if all we did is post, that's a create
  if (postCount > 0 && putCount === 0 && deleteCount === 0) {
    const plural = postCount > 1
    return NOTIFICATION_MESSAGES.portalAlertsPost({ plural })
  }

  // if we did any puts, that counts as a generic "update"
  if (putCount > 0) {
    // if we did multiple operations overall, treat that as plural, since multiple things were mutated
    const plural = postCount + putCount + deleteCount > 1
    return NOTIFICATION_MESSAGES.portalAlertsPut({ plural })
  }

  // if all we did was delete, that counts as "no longer alerting"
  if (postCount === 0 && putCount === 0 && deleteCount > 0) {
    return NOTIFICATION_MESSAGES.portalAlertsDelete
  }

  // by default, just return a generic 'updated' message. this will happen if e.g. you have a single alert selection
  // and change the number of days on that alert selection only
  const plural = postCount > 1 || putCount > 1 || deleteCount > 1
  return NOTIFICATION_MESSAGES.portalAlertsPut({ plural })
}

export function* submitForm({ payload }) {
  try {
    const { postPayload, deletePayload, putPayload } = getPayloads(payload)
    const { rollup, notifFrequency } = payload
    let { notifTimezone, notifTime } = payload

    // add default values so that the alert service doesn't error out when querying
    if (!notifTimezone) {
      notifTimezone = 'UTC'
    }
    if (!notifTime) {
      notifTime = '16:00'
    }

    // We don't really need an `if...else` here but in helps convey they are either/or
    if (postPayload.length) {
      yield call(api.postPortalAlertConfig, {
        alert_configs: postPayload,
        notif_time: notifTime,
        notif_timezone: notifTimezone,
        notif_frequency: notifFrequency,
        ref_type: rollup,
      })
    } else if (putPayload.length) {
      yield call(api.putPortalAlertConfig, {
        alert_configs: putPayload,
        notif_time: notifTime,
        notif_timezone: notifTimezone,
        notif_frequency: notifFrequency,
        ref_type: rollup,
      })
    }

    if (deletePayload.length > 0) {
      yield call(api.deletePortalAlertConfig, { alert_config_ids: deletePayload })
    }

    yield put({ type: types.SUBMIT_PORTAL_ALERTS_SUCCESS })
    yield put(closeModal())

    /**
     * Notify User of Success
     */
    const message = getPortalAlertNotificationMessage(postPayload, putPayload, deletePayload)
    yield put(showNotification(message, { type: MESSAGE_TYPES.SUCCESS }))
  } catch (error) {
    logger.error(error)
    logger.captureAPIException(error)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.portalAlertsError, { type: MESSAGE_TYPES.ERROR })
    )
    yield put({ type: types.SUBMIT_PORTAL_ALERTS_ERROR, payload: error })
  }
}

export const sagas = [takeLatest(types.SUBMIT_PORTAL_ALERTS_START, submitForm)]
