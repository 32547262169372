import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  createFilterGroup: ({ name, dateRange, filters, groupBy, source }) => {
    return api._call('post', '/filter_groups', {
      name,
      params: { dateRange, filters, groupBy },
      source,
    })
  },
  fetchFilterGroups: ({ source }) => {
    return api._call('get', `/filter_groups`, { source })
  },
  deleteFilterGroup: id => {
    return api._call('delete', '/filter_groups', { id: id })
  },
  updateFilterGroup: filterGroup => {
    return api._call('put', '/filter_groups', filterGroup)
  },
}

export default api
