import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from 'components/Skeleton'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}))

SplineSkeleton.propTypes = {
  width: PropTypes.number,
  lineHeight: PropTypes.number,
  isAnimated: PropTypes.bool,
  animationDelay: PropTypes.string,
}

SplineSkeleton.defaultProps = {
  lineHeight: 2,
  isAnimated: true,
}

function SplineSkeleton({ width, lineHeight, isAnimated }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Skeleton height={lineHeight} isAnimated={isAnimated} width={width} gutter={false} />
    </div>
  )
}

export default SplineSkeleton
