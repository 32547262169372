import { DOWNLOADS_ROUTE, REQUEST_INVITATION_ROUTE } from 'utils/routes'

import { CONSECUTIVE_FAILED_LOGINS_SHOW_BLOCKED } from 'store/auth/constants'
import { Link } from 'react-router-dom'
import React from 'react'
import { Typography } from '@material-ui/core'
import logger from 'utils/logger'

const MESSAGES = {
  alertDeleted: alertName => `"${alertName}" was deleted.`,
  alertEmailError: 'Error sending alert email',
  alertEmailSuccess: 'Successfully sent alert email',
  alertSaved: 'Your alert was saved.',
  alertUpdated: 'Your alert was updated.',
  createCommentError: 'Creating the comment failed.',
  createFilterError: 'Creating the saved filter failed.',
  createFilterSuccess: 'Saved filter created successfully!',
  createFilterWithAlertSuccess: 'Successfully created saved filter with alert!',
  createPortalUserAllError: `Creating the portal user(s) failed.`,
  createPortalUserError: `Creating the following portal user(s) failed: `,
  createPortalUserSuccess: `Successfully created the portal user(s).`,
  filterCopyError: 'Error copying to clipboard',
  filterCopySuccess: 'Copied to clipboard',
  highlightedValueCopy: 'Copied to clipboard',
  deleteCommentError: 'Deleting the comment failed.',
  deleteFilterError: filterName => `Sorry, we couldn't delete "${filterName}." Please try again.`,
  deleteFilterSuccess: filterName => `"${filterName}" was deleted.`,
  deleteFilterWithAlertSuccess: filterName =>
    `"${filterName}" and its associated alert were deleted.`,
  deletePortalUserError: `Deleting the portal user failed.`,
  deletePortalUserSuccess: `The portal user was deleted.`,
  feedbackSubmitted: 'Success! Your feedback was submitted.',
  ie11Warning: (
    <span>
      <Typography gutterBottom variant="h6">
        Unsupported Browser
      </Typography>
      <Typography gutterBottom>
        You will no longer be able to use Internet Explorer for project44 starting{' '}
        <b>January 1, 2021</b>.
      </Typography>
    </span>
  ),
  deleteDownloadSuccess: fileName => `"${fileName}" was deleted.`,
  deleteDownloadFailure: fileName => `Sorry, we couldn't delete "${fileName}". Please try again.`,
  exportError: 'We had trouble initiating your download',
  exportSuccess: () => (
    <span>
      <p>Preparing your file. Once complete, you will be emailed a link to download your file.</p>
      <p>
        Downloaded files are also available in the{' '}
        <Link
          to={DOWNLOADS_ROUTE.buildUrl()}
          onClick={() => logger.notify('Download Manager Toast Click')}
        >
          Download Manager
        </Link>
        .
      </p>
    </span>
  ),
  genericEmailSent:
    'Instructions will be sent if an account matches the provided email. Please check your Inbox, Spam, and ' +
    'Junk folders.',
  genericEmailFailure: 'Error sending email. Please try again or contact support@clearmetal.com.',
  impersonateUserError: 'Failed to impersonate user',
  impersonateUserSuccess: email => `Successfully impersonating user ${email}`,
  importReferencesCopySuccess: 'Chips copied to clipboard',
  importReferencesCopyFailure: 'An error occurred while copying chips to clipboard',
  invalidUsername:
    "We couldn't find an account with that email. Contact your administrator or support@clearmetal.com.",
  inviteUserError: 'Error inviting users',
  inviteUserSuccess: (email, tenant) => `Successfully invited ${email} to ${tenant}`,
  loginActivationError: (
    <>
      Your account has not been activated. Please check your Inbox, Spam, and Junk folders for a
      registration email or{' '}
      <Link to={REQUEST_INVITATION_ROUTE.buildUrl()}>Request a Registration Link Here</Link>.
    </>
  ),
  loginCredentialsError: 'Invalid username or password. Please try again.',
  multipleConsecutiveFailedLogins:
    `Invalid username or password. We limit failed login attempts to ${CONSECUTIVE_FAILED_LOGINS_SHOW_BLOCKED} ` +
    'before locking your account. If you think there’s an issue with your password, try resetting it.',
  maxConsecutiveFailedLogins:
    'Too many failed attempts. For your security, we’ve locked your account. Please try again in one hour. ' +
    'If you think there’s an issue with your password, try resetting it.',
  oauthStatusError: 'An internal error has occurred. Please contact support@clearmetal.com.',
  profileRetrieveError: 'Error retrieving profile data.',
  profileSaveError: 'Error saving profile.',
  profileSaveSuccess: 'Changes saved.',
  passwordResetEmailError: 'Error initiating password reset. Please contact support@clearmetal.com',
  passwordResetEmailSuccess: 'Successfully sent reset password email. Please check your inbox.',
  passwordResetError: 'Error resetting password. Please contact support@clearmetal.com',
  passwordResetSuccess: 'Successfully reset password.',
  portalAlertsPost: ({ plural }) => (
    <>
      Success! Your {plural ? 'alerts have' : 'alert has'} been created.
      <p>
        You will receive one email per day, per alert, for any shipments that match your alert
        criteria.
      </p>
    </>
  ),
  portalAlertsPut: ({ plural }) => (
    <>
      Saved! Your {plural ? 'alerts have' : 'alert has'} been updated.
      <p>
        You will receive one email per day, per alert, for any shipments that match your alert
        criteria.
      </p>
    </>
  ),
  portalAlertsDelete: <>Updated! You will no longer receive shipment alerts.</>,
  portalAlertsError:
    'Sorry, we had a problem saving the alert. Please refresh your browser and try again.',
  sessionExpired: 'Your session has expired.  Please login again.',
  shareCopy: 'Copied link to clipboard.',
  shareEmailError: 'Error sending email.',
  shareEmailSuccess: 'Email sent.',
  shareFetchError: 'Error fetching shared shipments.',
  sharedLinkCopy: 'Link copied to clipboard.',
  shareLinkError: 'Error creating link.',
  shareUpdateError: 'Error updating share.',
  shareRevokeError: shareName => `Sorry, we couldn't revoke "${shareName}." Please try again.`,
  shareRevokeSuccess: shareName => `"${shareName}" revoked successfully.`,
  shareUpdateSuccess: 'Successfully updated share.',
  shipmentsFetchError:
    'Sorry, we had a problem fetching those items. Please refresh your browser and try again.',
  tenantizedUrlCopy: 'Tenantized URL copied to clipboard',
  tenantCustomizationSuccess: 'Customization updated successfully.',
  tenantAddError: 'Failed to add tenant',
  tenantAddSuccess: 'Successfully added tenant',
  sourceAddError: 'Failed to add source',
  sourceAddSuccess: 'Successfully added source',
  tenantSwitchError: 'Failed to switch tenant',
  tenantSwitchSucccess: 'Successfully switched tenant',
  tenantSwitchPasswordError:
    'Please enter a password. If you are using a password manager, you may need to copy and paste it.',
  tenantLoadError: 'Failed to load tenants',
  updateCommentError: 'Updating the comment failed.',
  updateFilterError: 'Updating the saved filter failed.',
  updateFilterSuccess: 'Saved filter updated successfully!',
  updateFilterWithAlertSuccess: 'Successfully updated saved filter with alert!',
}

export default MESSAGES
