import './ApiDocsPage.css'

import { Loader } from 'components/Loader/Loader'
import PropTypes from 'prop-types'
import React from 'react'
import client from 'utils/api/client'
import createTheme from 'utils/createTheme'
import get from 'lodash/get'
import lazyImport from 'utils/lazyImport'
import lazyLoadWithSuspense from 'utils/lazyLoadWithSuspense'
import { withRouter } from 'react-router'

const getRedoc = lazyImport(() => import('redoc'), 'RedocStandalone')
// supply the same top margin as the first loader fetching the spec so that both loading sequences appear similar
const LazyRedoc = lazyLoadWithSuspense(getRedoc, { marginTop: 300 })

const P44_BLUE = '#00558B'
const PANEL = '#2e3e55'

const theme = createTheme()

const apiDocsTheme = {
  colors: {
    primary: {
      main: P44_BLUE,
    },
  },
  rightPanel: {
    backgroundColor: PANEL,
    width: '40%',
    textColor: '#ffffff',
  },
  typography: {
    fontSize: theme.typography.fontSize,
    lineHeight: '1.2em',
    fontWeightRegular: theme.typography.fontWeightRegular,
    fontWeightBold: theme.typography.fontWeightBold,
    fontWeightLight: theme.typography.fontWeightLight,
    fontFamily: theme.typography.fontFamily,
    smoothing: 'antialiased',
    optimizeSpeed: true,
    headings: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.6em',
    },
  },
  schema: {
    linesColor: theme.palette.grey[300],
  },
}

const specEndpoints = {
  v1: '/v1/openapi.json',
  v2: '/v2/openapi.json',
}

class ApiDocs extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    defaultSpecEndpoint: PropTypes.string,
  }

  static defaultProps = {
    defaultSpecEndpoint: specEndpoints.v1,
  }

  state = {
    isLoaded: false,
    res: null,
  }

  async UNSAFE_componentWillMount() {
    const specVersion = get(this.props, 'match.params.version')
    const specEndpoint = get(specEndpoints, specVersion, this.props.defaultSpecEndpoint)
    const res = await client.get(specEndpoint)
    this.setState({
      spec: res.data,
    })
  }

  render() {
    const { isLoaded, spec } = this.state
    return (
      <div style={{ width: '100%' }}>
        {/* We have a two-step loading process: loading the spec and loading the redoc component. We only want to
        show one loader a time. */}
        {!isLoaded && !spec && <Loader style={{ marginTop: 300 }} />}
        {spec && (
          <LazyRedoc
            spec={spec}
            options={{ hideLoading: true, theme: apiDocsTheme, hideDownloadButton: true }}
            onLoaded={() => {
              if (!this.state.isLoaded) this.setState({ isLoaded: true })
            }}
          />
        )}
      </div>
    )
  }
}

export default withRouter(ApiDocs)
