import { ExternalId } from 'store/journeyDetails/interfaces'
import { httpMethods } from 'utils/api/client'
import humps from 'humps'

type HttpMethod = 'get' | 'put'

const api = {
  _call: (method: HttpMethod, endpoint: string, params?: any) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchTransportDetail: (externalId?: ExternalId) => {
    return api._call('get', `/internal/v1/trips/${externalId}/transport_detail`)
  },
}

export default api
