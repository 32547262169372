import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'
export const types = {
  CHECK_TOKEN_LOAD: `${AUTH_REDUCER_KEY}/register/CHECK_TOKEN_LOAD`,
  CHECK_TOKEN_SUCCESS: `${AUTH_REDUCER_KEY}/register/CHECK_TOKEN_SUCCESS`,
  CHECK_TOKEN_FAILURE: `${AUTH_REDUCER_KEY}/register/CHECK_TOKEN_FAILURE`,
}

export const checkToken = createAction(types.CHECK_TOKEN_LOAD, (token, email) => ({
  token,
  email,
}))
