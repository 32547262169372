import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeEvery } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import api from '../api'
import logger from 'utils/logger'
import { resetOauthStatus } from 'store/oauthStatus/actions'
import { setAuthStage } from 'store/auth/login/actions'
import { types } from '../actions'

export function* logoutAsync() {
  try {
    // reset oauth status so that you will not be immediately redirected to SSO prompt due to saved SSO data
    yield put(resetOauthStatus())
    yield put(setAuthStage('email'))
    yield call(api.doLogout, '/logout')
    yield put({ type: types.LOGOUT_SUCCESS })
    logger.notify('Logged Out')
  } catch (e) {
    yield put({ type: types.LOGOUT_FAILURE })
    yield put(
      showNotification(MESSAGES.sessionExpired, {
        type: MESSAGE_TYPES.WARNING,
      })
    )
    logger.notify('Logout Failed', { error: e.message })
    logger.localLog(e, 'error')
  }
  logger.reset()
}

export function* watchLogout() {
  yield takeEvery(types.LOGOUT_LOAD, logoutAsync)
}
