import { createAction } from 'redux-actions'

export const SUPPORT_REDUCER_KEY = 'support'

export const types = {
  UPDATE_UNREAD_MESSAGE_COUNT: `${SUPPORT_REDUCER_KEY}/UPDATE_UNREAD_MESSAGE_COUNT`,
  OPEN_SUPPORT_CHAT: `${SUPPORT_REDUCER_KEY}/OPEN_SUPPORT_CHAT`,
  CLOSE_SUPPORT_CHAT: `${SUPPORT_REDUCER_KEY}/CLOSE_SUPPORT_CHAT`,
}

export const updateUnreadMessageCount = createAction(types.UPDATE_UNREAD_MESSAGE_COUNT)
export const openSupportChat = createAction(types.OPEN_SUPPORT_CHAT)
export const closeSupportChat = createAction(types.CLOSE_SUPPORT_CHAT)
