import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  postPortalAlertConfig: payload => {
    return api._call('post', '/portal/alert_config', payload)
  },
  putPortalAlertConfig: payload => {
    return api._call('put', '/portal/alert_config', payload)
  },
  deletePortalAlertConfig: payload => {
    return api._call('delete', '/portal/alert_config', payload)
  },
  getPortalAlertConfig: () => {
    return api._call('get', '/portal/alert_config')
  },
}

export default api
