import tinycolor from 'tinycolor2'

// Theme is passed from material-ui withStyles
export default theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  toolbar: {
    backgroundColor: tinycolor(theme.brand.color).setAlpha(0.3).toRgbString(),
    flex: 1,
    display: 'flex',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helperText: {
    fontSize: 12,
  },
  leftCenter: {
    marginLeft: 37,
    marginBottom: 5,
    marginTop: 0,
  },
  center: {
    textAlign: 'center',
  },
  submitBtn: {
    color: '#66717E',
    border: '1px solid #66717E',
    width: '80%',
    marginTop: theme.spacing(4),
    '&:hover': {
      backgroundColor: '#66717E',
      color: theme.palette.common.white,
    },
    margin: 'auto',
  },
  warning: {
    color: 'orange',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: 13,
  },
})
