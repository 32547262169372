import 'react-sticky-table/dist/react-sticky-table.css'

import { Row, StickyTable } from 'react-sticky-table'
import { deselectShipments, selectShipments } from 'store/board/actions'
import {
  deselectedIdsSelector,
  selectIsExhaustiveSelector,
  selectedIdsSelector,
  shipmentsForCurrentPageSelector,
  shipmentsLoadingFromState,
} from 'store/board/selectors'
import {
  getSortableColumns,
  getVisibleColumns,
  rollupSelector,
  sortStateSelector,
} from 'store/boardUtils/selectors'

import BlankSlate from 'pages/BoardPage/BlankSlate'
import ConfigurableCell from 'components/core/ConfigurableTable/CellTypes/ConfigurableCell'
import ConfigurableHeaderCell from 'components/core/ConfigurableTable/HeaderCells/ConfigurableHeaderCell'
import ContextMenu from './ContextMenu'
import ExhaustiveSelector from 'pages/BoardPage/TableView/ExhaustiveSelector'
import PropTypes from 'prop-types'
import React from 'react'
import SelectionCell from 'components/core/ConfigurableTable/CellTypes/SelectionCell'
import TableSkeleton from 'components/TableSkeleton/index'
import { compose } from 'redux'
import concat from 'lodash/concat'
import { connect } from 'react-redux'
import cx from 'classnames'
import get from 'lodash/get'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import reject from 'lodash/reject'
import { setSortState } from 'store/boardUtils/actions'
import { showNotification } from 'store/notifications'
import styles from './styles'
import { withStyles } from '@material-ui/core'

const RESET_HEIGHT_TIMEOUT = 1

const COLUMN_STICKY_COUNT = 3
const ROW_STICKY_COUNT = 1

export class TableView extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    deselectShipments: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    rows: PropTypes.array.isRequired,
    selectShipments: PropTypes.func.isRequired,
    selectedIds: PropTypes.array,
    setSortState: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    visibleColumns: PropTypes.array.isRequired,
    selectIsExhaustive: PropTypes.bool.isRequired,
  }

  state = {
    hoveredIdx: null,
    contextAnchor: null,
    contextRow: null,
    contextCol: null,
    expandedRows: [],
    recalculateHeight: false,
    clientX: null,
    clientY: null,
  }

  handleMouseEnter = idx => () => {
    this.setState({
      hoveredIdx: idx,
    })
  }
  handleMouseLeave = idx => () => {
    this.setState({
      hoveredIdx: idx,
    })
  }

  handleContextOpen = (row, col) => e => {
    e.preventDefault()
    this.setState({
      contextAnchor: e.currentTarget,
      contextRow: row,
      contextCol: col,
      clientX: e.clientX,
      clientY: e.clientY,
    })
  }

  handleContextClose = e => {
    this.setState({ contextAnchor: null, clientX: null, clientY: null })
  }

  getColumnStickyCount = () => {
    //NOTE: the sticky table component breaks if StickyCount > 0 and there is no data
    const { rows, visibleColumns } = this.props
    const stickyCount = COLUMN_STICKY_COUNT
    const isDataPresent = rows.length > 0

    // Ensure there is at least one non-sticky column that would be displayed.  Otherwise
    // the table will throw an error when rendering
    const sufficientCols = visibleColumns.length > stickyCount - 1
    return isDataPresent && sufficientCols ? stickyCount : 0
  }

  getRowStickyCount = () => {
    //NOTE: the sticky table component breaks if StickyCount > 0 and there is no data
    const { rows } = this.props

    const isDataPresent = rows.length > 0

    return isDataPresent ? ROW_STICKY_COUNT : 0
  }

  /**
   * react-sticky-table works by manually calculated row height, and then setting the height
   * parameter on the first cell of either the sticky, or non-sticky half of the table.  This is
   * the only way to force a recalculation of this height.  We temporarily set height to 100%,
   * then we unset height: 100%.  When we do that, react-sticky-table does a recalculation of the
   * actual height
   * @param rowIdx
   */
  recalculateRowHeight = rowIdx => {
    this.setState(
      {
        recalculateHeight: rowIdx,
      },
      () => {
        setTimeout(() => {
          this.setState({
            recalculateHeight: false,
          })
        }, RESET_HEIGHT_TIMEOUT)
      }
    )
  }

  renderCols = (row, rowIdx) => {
    /**
     * Loop through visible columns
     * */

    const { classes, visibleColumns, rollup, isExternalUser } = this.props

    const columnStickyCount = this.getColumnStickyCount()

    //the sticky table component breaks if StickyCount > 0 and there is no data
    let dataRows = []

    visibleColumns.forEach((col, colIdx) => {
      const colKey = get(col, 'key')

      // subtract 2 because we have to account for the hard coded SelectionCell
      const isLastStickyCol = colIdx === columnStickyCount - 2
      const isFirstNonSticky = colIdx === columnStickyCount - 1

      const isLastCol = colIdx === visibleColumns.length - 1

      dataRows.push(
        <ConfigurableCell
          onContextMenu={isExternalUser ? null : this.handleContextOpen(row, col)}
          className={cx({
            [classes.lightBorderRight]: isLastStickyCol,
            [classes.borderRight]: isLastCol,
            [classes.dynamicHeight]: isFirstNonSticky && this.state.recalculateHeight === rowIdx,
          })}
          key={`table-item-${colKey}-${rowIdx}`}
          row={row}
          column={col}
          rollup={rollup}
          isRowExpanded={this.isRowExpanded(rowIdx)}
          isRowHovered={this.isRowHovered(rowIdx)}
          onRowExpand={() => {
            this.setState({
              expandedRows: concat(this.state.expandedRows, [rowIdx]),
            })
          }}
          onRowCollapse={() => {
            this.setState(
              {
                expandedRows: reject(this.state.expandedRows, item => item === rowIdx),
              },
              () => {
                this.recalculateRowHeight(rowIdx)
              }
            )
          }}
        />
      )
    })
    return dataRows
  }

  isRowHovered = rowIdx => {
    return this.state.hoveredIdx === rowIdx
  }

  renderHeaderCols = () => {
    /**
     * Loop through visible columns
     * */

    const { classes, visibleColumns, rollup, setSortState, sortState, sortableCols } = this.props

    let headerCols = []

    visibleColumns.forEach((col, colIdx) => {
      const key = get(col, 'key')

      const isLastCol = colIdx === visibleColumns.length - 1

      headerCols.push(
        <ConfigurableHeaderCell
          key={`header-${key}`}
          className={cx({ [classes.borderRight]: isLastCol })}
          column={col}
          rollup={rollup}
          setSortState={setSortState}
          sortState={sortState}
          sortableCols={sortableCols}
        />
      )
    })
    return headerCols
  }

  isRowExpanded = rowIdx => {
    return this.state.expandedRows.includes(rowIdx)
  }

  render() {
    const {
      classes,
      deselectedIds,
      deselectShipments,
      isLoading,
      rollup,
      rows,
      selectedIds,
      selectIsExhaustive,
      selectShipments,
      visibleColumns,
    } = this.props
    const { contextAnchor, contextRow } = this.state

    const isDataPresent = rows.length > 0

    const columnStickyCount = this.getColumnStickyCount()
    const rowStickyCount = this.getRowStickyCount()

    return (
      <React.Fragment>
        <div
          className={cx(classes.root, {
            [classes.rootNoScrolling]: !isDataPresent,
          })}
        >
          <div
            className={cx({
              [classes.headerWrapperLoaded]: isDataPresent,
              [classes.headerWrapperLoading]: isLoading,
              [classes.headerWrapper]: !isLoading,
            })}
            onMouseLeave={() => {
              this.setState({ hoveredIdx: null }) // clear hovered state when user navigates away from table
            }}
            data-testid="board-page__table-view"
          >
            <StickyTable
              stickyColumnCount={columnStickyCount}
              stickyHeaderCount={rowStickyCount}
              onScroll={this.handleScroll}
            >
              {/* render Header row*/}
              <Row className={classes.headerRow} data-testid="shipments-table__header-row">
                <ExhaustiveSelector type="table" />
                {this.renderHeaderCols()}
              </Row>

              {/* render remaining rows */}
              {rows.map((row, rowIdx) => {
                const rowId = get(row, 'ref_id')
                const isRowSelected =
                  (selectIsExhaustive && !deselectedIds.includes(rowId)) ||
                  selectedIds.includes(rowId)
                return (
                  <Row
                    key={`table-row-${rowIdx}`}
                    onMouseLeave={this.handleMouseLeave(rowIdx)}
                    onMouseEnter={this.handleMouseEnter(rowIdx)}
                    className={cx(classes.row, {
                      [classes.borderTopRow]: rowIdx > 0,
                      [classes.lastRow]: rowIdx === rows.length - 1,
                      [classes.hoveredRow]: this.isRowHovered(rowIdx),
                      [classes.selectedRow]: isRowSelected,
                    })}
                    data-testid="table-view__row"
                  >
                    <SelectionCell
                      className={cx({
                        [classes.dynamicHeight]: this.state.recalculateHeight === rowIdx,
                      })}
                      handleCheck={() => {
                        selectShipments([row])
                      }}
                      handleUncheck={() => {
                        deselectShipments([row])
                      }}
                      isSelected={isRowSelected}
                    />
                    {this.renderCols(row, rowIdx)}
                  </Row>
                )
              })}
            </StickyTable>
            <ContextMenu
              contextAnchor={contextAnchor}
              contextRow={contextRow}
              handleContextClose={this.handleContextClose}
              clientX={this.state.clientX || 0}
              clientY={this.state.clientY || 0}
              visibleColumns={visibleColumns}
              rollup={rollup}
            />
          </div>

          {isLoading && !isDataPresent && <TableSkeleton isAnimated={true} rowCount={15} />}
          {!isLoading && !isDataPresent && <BlankSlate type="table" />}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    deselectedIds: deselectedIdsSelector(state),
    isExternalUser: isExternalUserSelector(state),
    isLoading: shipmentsLoadingFromState(state),
    rollup: rollupSelector(state),
    rows: shipmentsForCurrentPageSelector(state),
    selectedIds: selectedIdsSelector(state),
    selectIsExhaustive: selectIsExhaustiveSelector(state),
    sortableCols: getSortableColumns(state),
    sortState: sortStateSelector(state),
    visibleColumns: getVisibleColumns(state),
  }
}

const mapDispatchToProps = {
  deselectShipments,
  showNotification,
  setSortState,
  selectShipments,
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TableView)
