import { Checkbox, CircularProgress, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { autocompleteInputSelector, autocompleteValueSelector } from 'store/autocomplete/selectors'
import { setAutocompleteInput, setAutocompleteValue } from 'store/autocomplete/actions'
import { useDispatch, useSelector } from 'react-redux'

import AutocompleteBase from './AutocompleteBase'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import humps from 'humps'

Autocomplete.propTypes = {
  className: PropTypes.string,
  fetchDataAction: PropTypes.func.isRequired,
  fetchDataSelector: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    filterData: PropTypes.shape({
      helperText: PropTypes.string,
      isCustom: PropTypes.bool,
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,
  freeSolo: PropTypes.bool,
  autocompleteKey: PropTypes.string.isRequired,
  onAutocompleteChange: PropTypes.func,
}

/**
 *
 * @param autocompleteKey The state key for this autocomplte: `autocomplete[autocompleteKey]`
 * @param filter Metadata, see `propTypes` for shape
 * @param fetchDataAction - an action creator that kicks off requesting data
 * @param fetchDataSelector - a selector for getting autocomplete options
 * @param onAutocompleteChange - called on every change so the parent can act
 */
function Autocomplete({
  autocompleteKey,
  filter,
  className,
  fetchDataAction,
  fetchDataSelector,
  freeSolo = true,
  onAutocompleteChange = () => {},
}) {
  const dispatch = useDispatch()
  const filterInput = useSelector(state =>
    autocompleteInputSelector(state, filter, autocompleteKey)
  )
  const filterValue = useSelector(state =>
    autocompleteValueSelector(state, filter, autocompleteKey)
  )
  const options = useSelector(fetchDataSelector)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(fetchDataAction(filterInput))
    setLoading(false)
    // if you add fetchDataAction here to try to remove the eslint-disable-next-line,
    // it will cause inefficient behavior with every custom suggest endpoint being
    // called any time any of the other fields changes since fetchDataAction changes
    // every render. since there is an array of filters in the portal AddUsers screen,
    // it is not convenient to make a memoized callback with useCallback().
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterInput, setLoading])

  function handleValueChange(event, filterValue) {
    // Doing this here because for now this is the first source of autocomplete filter values
    const transformedValue = humps.camelizeKeys(filterValue)
    dispatch(setAutocompleteValue({ value: transformedValue, filter, autocompleteKey }))
    onAutocompleteChange({ value: transformedValue, filter })
  }

  function handleInputChange(event, filterInput, reason) {
    if (reason === 'input' || reason === 'clear') {
      dispatch(setAutocompleteInput({ value: filterInput, filter, autocompleteKey }))
    }
  }

  function handleGetOptionLabel(option) {
    return option.label
  }

  function handleGetOptionSelected(option, value) {
    return option.label === value.label
  }

  const helperText = get(filter, 'filterData.helperText', null)

  return (
    <AutocompleteBase
      data-testid="autocomplete-base"
      id={`autcomplete-${filter.name}`}
      className={className}
      key={`autcomplete-${filter.name}`}
      multiple
      disableCloseOnSelect
      freeSolo={freeSolo}
      /**
       * @onBlur
       * Clear input on blur
       */
      onBlur={() => dispatch(setAutocompleteInput({ value: '', filter, autocompleteKey }))}
      onChange={handleValueChange}
      value={filterValue}
      onInputChange={handleInputChange}
      inputValue={filterInput}
      getOptionLabel={handleGetOptionLabel}
      getOptionSelected={handleGetOptionSelected}
      loading={loading}
      options={options || []}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={filter.title}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox color="primary" checked={selected} />
          <div>{option.label}</div>
        </>
      )}
    />
  )
}

export default Autocomplete
