import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchComments: ({ referencedEntityExternalId }) => {
    return api._call('get', '/comments', { entityExternalId: referencedEntityExternalId })
  },
  createComment: ({ referencedEntityExternalId, content }) => {
    return api._call('post', `/comments`, { entityExternalId: referencedEntityExternalId, content })
  },
  updateComment: ({ commentId, content }) => {
    return api._call('put', '/comments', { externalId: commentId, content })
  },
  deleteComment: ({ commentId }) => {
    return api._call('delete', '/comments', { externalId: commentId })
  },
  fetchCommentSummaries: ({ entityExternalIds }) => {
    return api._call('get', '/comments/summary', { entityExternalIds })
  },
}

export default api
