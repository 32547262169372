import humps from 'humps'

export class UserInfo {
  constructor(
    customization,
    dateJoined,
    email,
    externalId,
    featureFlags,
    firstName,
    isExternalUser,
    isSuperuser,
    lastName,
    location,
    status,
    team,
    tenant,
    title,
    userHash,
    accountType
  ) {
    this.customization = customization
    this.dateJoined = dateJoined
    this.email = email
    this.externalId = externalId
    this.featureFlags = featureFlags
    this.firstName = firstName
    this.isExternalUser = isExternalUser
    this.isSuperuser = isSuperuser
    this.lastName = lastName
    this.location = location
    this.status = status
    this.team = team
    this.tenant = tenant
    this.title = title
    this.userHash = userHash
    this.accountType = accountType
  }

  static of(payload) {
    const {
      customization,
      dateJoined,
      email,
      externalId,
      firstName,
      isExternalUser,
      isSuperuser,
      lastName,
      location,
      status,
      team,
      tenant,
      title,
      userHash,
      accountType,
    } = humps.camelizeKeys(payload)
    // We want this property camelized but not its contents, which are hyphenated.
    // `humps` has a `decamelizeKeys` method we can use to undo `camelizeKeys`, and
    // you can provide a supply a `separator`suh as a hyphen, but for a string like
    // `entityComments202001` it can't know how to hyphenate the numbers, so it becomes
    // `entity-comments202001` when we need `entity-comments-2020-01`. So here we
    // manually camelcase `featureFlags` but leave its value, an array, as is.
    const featureFlags = payload.feature_flags
    return new UserInfo(
      customization && CustomerTags.of(customization),
      dateJoined,
      email,
      externalId,
      // we want feature flag keys to leave these hyphenated
      featureFlags,
      firstName,
      isExternalUser,
      isSuperuser,
      lastName,
      location,
      status,
      team,
      tenant,
      title,
      userHash,
      accountType
    )
  }
}

export class AuthStatus {
  constructor(accountType, groups, subTenants, tenant, user, version) {
    this.accountType = accountType
    this.groups = groups
    this.subTenants = subTenants
    this.tenant = tenant
    this.user = user
    this.version = version
  }

  static of(payload) {
    const { account_type, groups, subTenants, tenant, user, version } = humps.camelizeKeys(payload)
    return new AuthStatus(account_type, groups, subTenants, tenant, user, version)
  }
}

export class CustomerTags {
  constructor(customerTags) {
    this.customerTags = customerTags
  }

  static of(payload) {
    const { customerTags } = humps.camelizeKeys(payload)

    return new CustomerTags(customerTags && customerTags.map(tag => CustomerTag.of(tag)))
  }
}

export class CustomerTag {
  constructor(key, title, type) {
    this.key = key
    this.title = title
    this.type = type
  }

  static of(payload) {
    const { key, title, type } = humps.camelizeKeys(payload)
    return new CustomerTag(key, title, type)
  }
}
