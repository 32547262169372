import {
  ALERTS_ROUTE,
  BOARD_ROUTE,
  DOWNLOADS_ROUTE,
  EXTERNAL_USER_PORTAL_ROUTE,
  HOME_ROUTE,
  PLANNING_ROUTE,
  SETTINGS_ROUTE,
  SHARES_ROUTE,
  VOC_URL,
} from 'utils/routes'
import {
  ClickAwayListener,
  Drawer,
  List,
  ListItemText,
  MenuItem,
  Popper,
  makeStyles,
} from '@material-ui/core'
import { PLANNING_FEATURE, PLANNING_PAYWALL_PAGE_FEATURE, VOC_LINK } from 'utils/featureFlags'
import React, { useEffect, useState } from 'react'
import { closeSupportChat, openSupportChat } from 'store/support/actions'
import {
  getNewAggregateAlertsCount,
  lastViewedSelector,
  newAlertsCountSelector,
} from 'store/alerts'
import { supportChatOpenSelector, unreadMessageCountSelector } from 'store/support'

import BellIcon from 'components/icons/deprecated/Bell'
import CancelIcon from '@material-ui/icons/Cancel'
import DownloadsIcon from 'components/icons/DownloadsIcon'
import FeatureFlagComponent from 'components/FeatureFlagComponent'
import InternalUserComponent from 'components/InternalUserComponent'
import Link from 'components/core/Link'
import P44Logo from 'assets/p44Logo_circle_white.svg'
import PlanningIcon from 'components/icons/PlanningIcon'
import ProfileCircle from './ProfileCircle'
import SearchDrawer from './SearchDrawer'
import SearchIcon from '@material-ui/icons/Search'
import ShareIcon from 'components/icons/deprecated/ShareIcon'
import ShipmentsIcon from 'components/icons/ShipmentsIcon'
import SideNavLink from '../SideNavLink'
import SuperUserMenu from '../SuperUserMenu/index'
import SupportIcon from 'components/icons/Support'
import SwitchTenantConfirmation from 'pages/Admin/AdminHomePage/SwitchTenantConfirmation'
import VOCIcon from 'components/icons/VOCIcon'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import { isImpersonatingSelector } from 'store/auth/impersonate/selectors'
import logger from 'utils/logger'
import { logout } from 'store/auth/logout/actions'
import { userFeatureFlagsSelector } from 'store/auth/user/selectors'
import { userFromState } from 'store/auth/user/selectors'
import { withRouter } from 'react-router-dom'

const THRESHOLD = 99
const SUPER_TENANT = 'super'
export const SIDE_NAV_WIDTH = 100

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: SIDE_NAV_WIDTH,
    backgroundColor: '#07456B', // p44 change
    color: theme.palette.grey[500],
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    height: 45,
  },
  label: {
    color: theme.palette.grey[800],
    textTransform: 'uppercase',
  },
  icon: {
    fill: 'inherit',
    fontSize: 30,
  },
  divider: {
    width: '80%',
    border: `1px solid ${theme.palette.grey[900]}`,
    margin: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  menu: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[8],
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.common.white,
  },
}))

const SideNav = ({
  featureFlags,
  getNewAggregateAlertsCount,
  lastViewed,
  location,
  logout,
  newAlertsCount,
  unreadSupportMessages,
  isExternalUser,
  isSupportChatOpen,
  openSupportChat,
  closeSupportChat,
  user,
  isImpersonating,
}) => {
  const classes = useStyles()
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false)
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null)
  const [showSuperSwitch, setShowSuperSwitch] = useState(false)
  const handleClose = () => setSettingsAnchorEl(null)
  const handleOpen = event => setSettingsAnchorEl(event.currentTarget)
  const settingsMenuOpen = Boolean(settingsAnchorEl)

  useEffect(() => {
    getNewAggregateAlertsCount()
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDrawerClose = () => {
    setSearchDrawerOpen(true)
  }

  const getBadgeText = () => {
    if (newAlertsCount > THRESHOLD) {
      return `${THRESHOLD}+`
    }
    return newAlertsCount.toString()
  }

  const isInvisible = newAlertsCount === 0 || lastViewed === null

  const boardUrl = (() => {
    if (isExternalUser) {
      return EXTERNAL_USER_PORTAL_ROUTE.buildUrl()
    } else {
      return BOARD_ROUTE.buildUrl()
    }
  })()

  const logo = <img alt="project44" className={classes.logo} src={P44Logo} />
  const currentUrl = location.pathname

  return (
    <>
      <div data-testid="navigation">
        <ClickAwayListener onClickAway={handleClose}>
          <Drawer
            anchor={'left'}
            classes={{
              paper: classes.drawerPaper,
            }}
            elevation={0}
            variant={'permanent'}
          >
            <InternalUserComponent
              alternateComponent={
                <div style={{ padding: 16 }} data-testid="side-nav__home">
                  {logo}
                </div>
              }
            >
              <SideNavLink
                id={'home'}
                icon={logo}
                onClick={handleClose}
                linkProps={{ to: HOME_ROUTE.buildUrl() }}
                selected={currentUrl === HOME_ROUTE.buildUrl()}
                tooltipProps={{
                  title: 'Home',
                  placement: 'right',
                  'data-testid': 'side-nav__home',
                }}
              />
            </InternalUserComponent>
            <SideNavLink
              id={isExternalUser ? 'portal-board' : 'board'}
              icon={<ShipmentsIcon className={classes.icon} />}
              linkProps={{ to: boardUrl }}
              selected={currentUrl === boardUrl}
              text={'Shipments'}
              onClick={handleClose}
              tooltipProps={{ title: '', 'data-testid': 'side-nav__shipments' }}
            />

            <InternalUserComponent
              alternateComponent={
                <>
                  <SideNavLink
                    id={'portal-search'}
                    icon={<SearchIcon className={classes.icon} />}
                    onClick={event => {
                      handleClose(event)
                      handleDrawerClose(event)
                    }}
                    tooltipProps={{
                      title: 'Search',
                      placement: 'right',
                      'data-testid': 'side-nav__search',
                    }}
                  />
                  <span className={classes.flexGrow} />
                </>
              }
            >
              <FeatureFlagComponent flag={PLANNING_FEATURE}>
                <SideNavLink
                  isDisabled={featureFlags[PLANNING_PAYWALL_PAGE_FEATURE]}
                  id={'Planning'}
                  icon={<PlanningIcon className={classes.icon} />}
                  linkProps={{ to: PLANNING_ROUTE.buildUrl() }}
                  onClick={handleClose}
                  selected={currentUrl === PLANNING_ROUTE.buildUrl()}
                  text={'Planning'}
                  tooltipProps={{ title: '', 'data-testid': 'side-nav__planning' }}
                />
              </FeatureFlagComponent>
              <hr className={classes.divider} />
              <FeatureFlagComponent flag={VOC_LINK}>
                <SideNavLink
                  id={'VOC'}
                  icon={<VOCIcon className={classes.icon} />}
                  linkProps={{
                    onClick: () => {
                      window.location.href = VOC_URL
                    },
                  }}
                  onClick={handleClose}
                  text={'VOC'}
                  tooltipProps={{
                    title: 'Back to Visibility Operations Center',
                    placement: 'right',
                    'data-testid': 'side-nav__voc',
                  }}
                />
                <hr className={classes.divider} />
              </FeatureFlagComponent>
              <SideNavLink
                id={'search'}
                icon={<SearchIcon className={classes.icon} />}
                onClick={event => {
                  handleClose(event)
                  handleDrawerClose(event)
                }}
                tooltipProps={{
                  title: 'Search',
                  placement: 'right',
                  'data-testid': 'side-nav__search',
                }}
              />
            </InternalUserComponent>

            <InternalUserComponent>
              <span className={classes.flexGrow} />
              <SideNavLink
                id={'alerts'}
                onClick={handleClose}
                badgeProps={{ content: getBadgeText(), invisible: isInvisible }}
                icon={<BellIcon className={classes.icon} />}
                linkProps={{ to: ALERTS_ROUTE.buildUrl() }}
                selected={currentUrl === ALERTS_ROUTE.buildUrl()}
                tooltipProps={{
                  title: 'Alerts',
                  placement: 'right',
                  'data-testid': 'side-nav__alerts',
                }}
              />
            </InternalUserComponent>
            <SideNavLink
              id={'downloads'}
              icon={<DownloadsIcon className={classes.icon} />}
              linkProps={{ to: DOWNLOADS_ROUTE.buildUrl() }}
              selected={currentUrl === DOWNLOADS_ROUTE.buildUrl()}
              tooltipProps={{
                title: 'Downloads',
                placement: 'right',
                'data-testid': 'side-nav__downloads',
              }}
              onClick={handleClose}
            />
            <InternalUserComponent>
              <SideNavLink
                id={'share'}
                icon={<ShareIcon className={classes.icon} />}
                linkProps={{ to: SHARES_ROUTE.buildUrl() }}
                selected={currentUrl === SHARES_ROUTE.buildUrl()}
                tooltipProps={{
                  title: 'Shares',
                  placement: 'right',
                  'data-testid': 'side-nav__shares',
                }}
                onClick={handleClose}
              />
              <hr className={classes.divider} />
            </InternalUserComponent>

            <SideNavLink
              id={isExternalUser ? 'portal-settings' : 'settings'}
              onClick={event => {
                closeSupportChat()
                settingsMenuOpen ? handleClose(event) : handleOpen(event)
              }}
              icon={<ProfileCircle className={classes.icon} />}
              selected={currentUrl === SETTINGS_ROUTE.buildUrl()}
              childMenuOpen={settingsMenuOpen}
              tooltipProps={{
                title: 'Settings',
                placement: 'right',
                'data-testid': 'side-nav__settings',
              }}
            />
            <Popper
              anchorEl={settingsAnchorEl}
              placement="right"
              open={settingsMenuOpen}
              className={classes.menu}
              data-testid="sideNav__settings-menu"
            >
              <List>
                <InternalUserComponent>
                  <MenuItem
                    key="profile"
                    component={Link}
                    to={SETTINGS_ROUTE.buildUrl()}
                    onClick={event => {
                      logger.notify('Side Nav Popover - /settings')
                      handleClose(event)
                    }}
                  >
                    <ListItemText primary="Profile" />
                  </MenuItem>
                </InternalUserComponent>
                <MenuItem
                  key="signOut"
                  onClick={() => {
                    const msg = isExternalUser
                      ? 'Side Nav Popover - Portal /logout'
                      : 'Side Nav Popover - /logout'
                    logger.notify(msg)
                    logout()
                  }}
                >
                  <ListItemText primary="Sign Out" />
                </MenuItem>
                <SuperUserMenu handleClose={handleClose} setShowSuperSwitch={setShowSuperSwitch} />
                <SwitchTenantConfirmation
                  email={user.email}
                  tenant={SUPER_TENANT}
                  open={showSuperSwitch}
                  onClose={() => {
                    setShowSuperSwitch(false)
                  }}
                />
              </List>
            </Popper>

            <hr className={classes.divider} />

            <SideNavLink
              id={isExternalUser ? 'portal-support' : 'support'}
              badgeProps={{
                content: unreadSupportMessages.toString(),
                invisible: unreadSupportMessages === 0,
              }}
              onClick={event => {
                if (isImpersonating) {
                  return window.alert('You cannot use Support because you are impersonating a user')
                }
                if (isSupportChatOpen) {
                  closeSupportChat(event)
                } else {
                  handleClose(event)
                  openSupportChat(event)
                }
              }}
              icon={
                isSupportChatOpen ? (
                  <CancelIcon className={classes.icon} />
                ) : (
                  <SupportIcon className={classes.icon} />
                )
              }
              tooltipProps={{ title: '', 'data-testid': 'side-nav__support' }}
              text={'Support'}
            />
          </Drawer>
        </ClickAwayListener>
      </div>
      <SearchDrawer onClose={() => setSearchDrawerOpen(false)} open={searchDrawerOpen} />
    </>
  )
}

const mapDispatchToProps = {
  logout,
  getNewAggregateAlertsCount,
  openSupportChat,
  closeSupportChat,
}

const mapStateToProps = state => ({
  unreadSupportMessages: unreadMessageCountSelector(state),
  isSupportChatOpen: supportChatOpenSelector(state),
  newAlertsCount: newAlertsCountSelector(state),
  lastViewed: lastViewedSelector(state),
  user: userFromState(state),
  isExternalUser: isExternalUserSelector(state),
  isImpersonating: isImpersonatingSelector(state),
  featureFlags: userFeatureFlagsSelector(state),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SideNav)
