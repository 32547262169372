import { PayloadInterface } from 'store/associatedReferences/interfaces'
import { httpMethods } from 'utils/api/client'
import humps from 'humps'
import { translatedRefType } from 'utils/rollups'

type HttpMethod = 'get' | 'put'

const api = {
  _call: (method: HttpMethod, endpoint: string, params?: any) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchAssociatedReferences: (payload: PayloadInterface) => {
    const { externalId, refType } = payload
    // Temporary handling of `container_number` => `trips`, otherwise it just returns what was passed
    const refTypeUrlSegment = translatedRefType(refType)
    return api._call('get', `/internal/v1/${refTypeUrlSegment}/${externalId}/associated_references`)
  },
}

export default api
