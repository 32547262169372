import { BOARD_LOADING_EXPERIMENTS, JSON_LOGIC } from 'utils/featureFlags'
import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import {
  ROLLUPS,
  getPortalUserRollups,
  getRollupEntityParam,
  translatedRefType,
} from 'utils/rollups'
import { all, call, put, select } from 'redux-saga/effects'
import { getShipments, getTripTimelines } from 'store/board/actions'
import { setInitialRollup, setRollup } from 'store/boardUtils/actions'
import {
  filtersSelector as shipmentFiltersSelector,
  rollupSelector as shipmentRefTypeSelector,
  sortStateSelector,
  transportationStatusSelector,
} from 'store/boardUtils/selectors'

import ApiRefType from 'store/models/definitions/ApiRefType'
import { LegacyShipmentsModel } from './modelsLegacy'
import { MAX_NUM_SHIPMENT_ROWS } from 'store/board'
import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import { REF_TYPE_PARAM_NAME } from 'utils/querystring'
import { TransportationStatusOptions } from 'store/boardUtils'
import client from 'utils/api/client'
import { types as commentTypes } from 'store/comments/actions'
import { deselectedIdsSelector } from 'store/board/selectors'
import difference from 'lodash/difference'
import { filtersSelector } from 'store/boardUtils/selectors'
import get from 'lodash/get'
import { getParamsFromFilters } from 'utils/filterUtils'
import { getQueryStringValue } from 'utils/urlBuilder'
import humps from 'humps'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import { loadedPagesSelector } from 'store/board/selectors'
import logger from 'utils/logger'
import toJsonLogic from 'utils/jsonLogic/board'
import { types } from 'store/board/actions'
import { userFeatureFlagsSelector } from 'store/auth/user/selectors'

/**
 * Shapes/translates payload into correct format for requests
 * @param payload: object of type
 * {
 *  ids: list of selected ids (used?)
 *  transportationStatus: string
 *  sortParams: object, { sort_param, direction }
 *  refType: string, selected entity ref type
 *  filters: object of key:value (filter:value)
 *  page: 0 indexed offset into the results (for pagination)
 * }
 * @returns object: {
 *  limit,
 *  offset,
 *  is_active,
 *  ref_type,
 *  sort: 'column:direction',
 *  filterName: value (repeated for each filter)
 * }
 */
const getParamsFromPayload = payload => {
  try {
    const ids = get(payload, 'ids', '')
    const refType = get(payload, 'refType')
    let filters = get(payload, 'filters', {})
    const rawSortParams = get(payload, 'sortParams', {})
    const transportationStatus = get(payload, 'transportationStatus', null)
    const page = parseInt(get(payload, 'page', 0))

    let params = {
      limit: MAX_NUM_SHIPMENT_ROWS,
      offset: page * MAX_NUM_SHIPMENT_ROWS,
    }

    if (transportationStatus === TransportationStatusOptions.VIEW_ACTIVE_ONLY) {
      params = { ...params, is_active: true }
    } else if (transportationStatus === TransportationStatusOptions.VIEW_IDLE_AND_COMPLETED) {
      params = { ...params, is_active: false }
    }
    // omit passing the parameter to the API entirely if null or TransportationStatusOptions.VIEW_ALL

    const sort = structureSortParams(rawSortParams)

    if (refType && ids !== '') {
      params[getRollupEntityParam(refType)] = ids
    }

    if (refType) {
      params.ref_type = refType
    }

    if (filters) {
      params = { ...params, ...filters, ...sort }
    }

    return params
  } catch (e) {
    logger.captureAppException(e)
  }
}

/**
 * Formats `payload.sortParams` into correct format
 * @param sortParams: array of objects of type {direction: 'desc', sort_param: 'pred_delay'}
 * @returns object
 */
export function structureSortParams(sortParams) {
  try {
    if (Object.keys(sortParams).length === 0) return {}

    const value = `${sortParams.sort_param}:${sortParams.direction}`
    return { sort: value }
  } catch (e) {
    logger.captureAppException(e)
  }
}

/**
 * Gets shipments
 * @param actionPayload: object of type:
 *  {
 *  type: action,
 *  payload: {
 *    ids: CSV,
 *    transportationStatus: string,
 *    sortParams: object,
 *    refType: string,
 *    filters: object,
 *    page: number,
 *  }
 * }
 *
 * @returns {Object}
 */
export function* getShipmentsAsync({ payload }) {
  try {
    let params = getParamsFromPayload(payload)
    const page = parseInt(get(payload, 'page', 0))
    const featureFlags = yield select(userFeatureFlagsSelector)
    const useJsonLogic = featureFlags[JSON_LOGIC]
    const useBoardLoadingExperiments = featureFlags[BOARD_LOADING_EXPERIMENTS]
    let data
    let externalIds = []
    let entityIds = []

    const getExternalIds = data => {
      return Array.isArray(data) ? data.map(s => s.externalId).filter(id => id) : []
    }

    if (useJsonLogic) {
      const jsonLogicFilters = toJsonLogic(params)
      // Temporary handling of `container_number` => `trips`, otherwise it just returns what was passed
      const refTypeUrlSegment = translatedRefType(ApiRefType[params.ref_type])
      const response = yield call(
        client.post,
        `/internal/v1/${refTypeUrlSegment}/filtered_references`,
        jsonLogicFilters
      )
      entityIds = response.data.data.map(data => data.id)
      externalIds = response.data.data.map(data => data.external_id)
    } else {
      const response = yield call(client.post, '/shipments/ids/list', params)
      entityIds = response.data
    }

    // only call /shipments/list if there is data to query it with. currently, calling /shipments/list
    // with an empty entity_ids list will cause the filter to not be applied, which will make it a query for
    // all data of that ref type, which is not what we want here.
    if (entityIds.length > 0) {
      let accumulatedResponse = []

      // Experimenting with calling the endpoints with <= 2 `id`s per call
      if (useBoardLoadingExperiments) {
        const idArrays = []
        // 1. Get an array of `id` arrays, each with <= 2 `id`s: [[1,2], [3,4], [5]]
        while (entityIds.length > 0) {
          idArrays.push(entityIds.splice(0, 2))
        }
        // 2. Map over all `id` arrays and run all requests in parallel
        const responses = yield all(
          idArrays.map(idGroup =>
            call(client.post, '/internal/v1/backwards_compat/shipments/list', {
              entity_ids: idGroup,
              ref_type: params.ref_type,
            })
          )
        )
        // 3. Extract the data from each `response`
        responses.forEach(response => {
          accumulatedResponse = [...accumulatedResponse, ...response.data]
        })

        // 4. Everything here is the same as the `else` logic below
        data = accumulatedResponse?.map(item => LegacyShipmentsModel.of(item))
        if (!useJsonLogic) {
          const camelData = humps.camelizeKeys(data)
          externalIds = getExternalIds(camelData)
        }
      } else {
        const response = yield call(client.post, '/internal/v1/backwards_compat/shipments/list', {
          entity_ids: entityIds,
          ref_type: params.ref_type,
        })

        data = response?.data.map(item => LegacyShipmentsModel.of(item))
        if (!useJsonLogic) {
          const camelData = humps.camelizeKeys(data)
          externalIds = getExternalIds(camelData)
        }
      }
    } else {
      data = []
      externalIds = []
    }

    yield put({
      type: types.GET_SHIPMENTS_LOAD_SUCCESS,
      payload: { data, page },
    })

    const isExternalUser = yield select(isExternalUserSelector)
    if (!isExternalUser) {
      yield put({ type: commentTypes.FETCH_COMMENT_SUMMARIES_START, externalIds })
    }
  } catch (e) {
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.shipmentsFetchError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

/**
 * Gets shipments count
 * @param obj: object of type:
 *  {
 *  type: action,
 *  payload: {
 *    ids: CSV,
 *    transportationStatus: string,
 *    sortParams: object,
 *    refType: string,
 *    filters: object,
 *    page: number,
 *  }
 * }
 *
 * @returns {Object}
 */
export function* getShipmentsCountAsync(obj) {
  try {
    const payload = obj.payload
    const params = getParamsFromPayload(payload)
    const featureFlags = yield select(userFeatureFlagsSelector)
    const useJsonLogic = featureFlags[JSON_LOGIC]
    let count

    delete params.sort

    if (useJsonLogic) {
      delete params.limit
      delete params.offset
      const jsonLogicFilters = toJsonLogic(params)
      // Temporary handling of `container_number` => `trips`, otherwise it just returns what was passed
      const refTypeUrlSegment = translatedRefType(ApiRefType[params.ref_type])

      const response = yield call(
        client.post,
        `/internal/v1/${refTypeUrlSegment}/filtered_references_count`,
        jsonLogicFilters
      )
      count = response.data.count ?? 0
    } else {
      const response = yield call(client.post, '/shipments/count', params)
      count = response?.data?.count ?? 0
    }

    yield put({
      type: types.GET_SHIPMENTS_COUNT_SUCCESS,
      payload: { count },
    })
  } catch (e) {
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.shipmentsFetchError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

/**
 * Gets count, called by `getInitialRefTypeAsync` on page load
 * @param refType: string, selected entity ref type
 * @returns int
 */
function* getCount(refType) {
  try {
    const isExternalUser = yield select(isExternalUserSelector)
    const filters = yield select(shipmentFiltersSelector)
    const sortState = yield select(sortStateSelector)
    const transportationStatus = yield select(transportationStatusSelector)
    const featureFlags = yield select(userFeatureFlagsSelector)
    const useJsonLogic = featureFlags[JSON_LOGIC]
    let count

    const params = getParamsFromPayload({
      filters,
      transportationStatus,
      sortState,
      page: 0,
      refType,
    })

    if (isExternalUser) {
      delete params.is_active
    }

    delete params.sort

    if (useJsonLogic) {
      delete params.limit
      delete params.offset
      const jsonLogicFilters = toJsonLogic(params)
      // Temporary handling of `container_number` => `trips`, otherwise it just returns what was passed
      const refTypeUrlSegment = translatedRefType(ApiRefType[params.ref_type])

      const response = yield call(
        client.post,
        `/internal/v1/${refTypeUrlSegment}/filtered_references_count`,
        jsonLogicFilters
      )
      count = response?.data?.count ?? 0
    } else {
      const response = yield call(client.post, '/shipments/count', params)
      count = response?.data?.count ?? 0
    }
    return count
  } catch (e) {
    logger.captureAPIException(e)
  }
}

/**
 * Set the initial ref type. Uses query string param if present, otherwise sets ref_type to the first ref type that has data
 * TODO: Add tenant customization to be more specific
 */
export function* getInitialRefTypeAsync() {
  try {
    const rollups = ROLLUPS

    for (let i = 0; i < rollups.length; i++) {
      let entityType = rollups[i]
      const count = yield call(getCount, entityType)
      if (count > 0) {
        entityType = rollups[i]
        yield put(setInitialRollup(entityType))
        break
      }
    }
  } catch (e) {
    logger.captureAppException(e)
  }
}

/*
 * Used for the portal board to only show tabs with populated entities.
 */
export function* getPortalRefTypeCountsSaga() {
  try {
    const payload = {}
    let refTypeHasBeenSet = false
    // this code is somewhat similar to getRollupsToRender() in BoardPage, but this loop below will
    // trigger a render as soon as we have a valid ref type to set, which should help with responsiveness due to not
    // waiting for all the getCount calls to finish first.

    // Use the query string ref type if present
    let querystringEntityType = getQueryStringValue(REF_TYPE_PARAM_NAME)
    if (querystringEntityType && ROLLUPS.includes(querystringEntityType)) {
      yield put(setRollup(querystringEntityType))
      refTypeHasBeenSet = true
    }
    const refTypes = getPortalUserRollups()
    for (let i = 0; i < refTypes.length; i++) {
      const refType = refTypes[i]
      const count = yield call(getCount, refType)
      if (count > 0 && !refTypeHasBeenSet) {
        yield put(setRollup(refType))
        refTypeHasBeenSet = true
      }
      payload[refType] = count
    }
    yield put({ type: types.GET_REF_TYPE_COUNTS_SUCCESS, payload })
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

/**
 * Called on board tab changes and pagination, includes a check for "caching", which we do
 * by indexing page data results ala `data: { 0: [...page 1 data], 1: [...page 2 data] }.
 * This is used for `board.data` and `board.tripTimelines` because
 * they need to be synced on id's.
 */
export function* setPageAsync(action) {
  try {
    const filters = yield select(shipmentFiltersSelector)
    const sortState = yield select(sortStateSelector)
    const transportationStatus = yield select(transportationStatusSelector)
    const pages = yield select(loadedPagesSelector)
    const page = action.payload
    // If we have this page cached don't trigger new loads
    if (pages.includes(page)) return
    let params = getParamsFromFilters(filters)
    let refType = yield select(shipmentRefTypeSelector)
    yield put(getShipments('', refType, params, page, sortState, transportationStatus))
    yield put(getTripTimelines('', refType, params, page, sortState, transportationStatus))
  } catch (e) {
    logger.captureAppException(e)
  }
}

/**
 * "Exhaustive" for our purposes is a user checking the "Select All" checkbox, period.
 * If they then deselect individual rows we are still "exhaustive" but here will filter
 * out those rows. If a user instead selected each row, that is not "exhaustive".
 */
export function* getRefIdsForSelectIsExhaustive({ refType }) {
  // When `selectIsExhaustive` is true we need to get the ref ids via an API call.
  const filters = yield select(filtersSelector)
  const sorter = yield select(sortStateSelector)
  const transportationStatus = yield select(transportationStatusSelector)
  const deselectedIds = yield select(deselectedIdsSelector)
  const featureFlags = yield select(userFeatureFlagsSelector)
  const useJsonLogic = featureFlags[JSON_LOGIC]
  let filteredIds = []
  let entityIds = []

  const params = {
    ref_type: refType,
    ...getParamsFromFilters(filters),
    ...structureSortParams(sorter),
  }

  // Three possibilities: `true`, `false`, no param
  if (transportationStatus === TransportationStatusOptions.VIEW_ACTIVE_ONLY) {
    params.is_active = true
  } else if (transportationStatus === TransportationStatusOptions.VIEW_IDLE_AND_COMPLETED) {
    params.is_active = false
  }

  if (useJsonLogic) {
    const jsonLogicFilters = toJsonLogic(params)
    // Temporary handling of `container_number` => `trips`, otherwise it just returns what was passed
    const refTypeUrlSegment = translatedRefType(ApiRefType[params.ref_type])
    const response = yield call(
      client.post,
      `/internal/v1/${refTypeUrlSegment}/filtered_references`,
      jsonLogicFilters
    )
    entityIds = response.data.data.map(data => data.id)
  } else {
    const response = yield call(client.get, '/shipments/ids/list', { params })
    entityIds = response.data
  }

  filteredIds = difference(entityIds, deselectedIds)

  return filteredIds
}
