import { createAction } from 'redux-actions'

export const EQUIPMENT_AUTOCOMPLETE_KEY = 'equipment'
export const PORTAL_USERS_AUTOCOMPLETE_KEY = 'portalUsers'
export const AUTOCOMPLETE_KEY = 'autocomplete'

export const types = {
  SET_AUTOCOMPLETE_VALUE: `${AUTOCOMPLETE_KEY}/SET_AUTOCOMPLETE_VALUE`,
  SET_AUTOCOMPLETE_INPUT: `${AUTOCOMPLETE_KEY}/SET_AUTOCOMPLETE_INPUT`,
  RESET_AUTOCOMPLETE_FILTER: `${AUTOCOMPLETE_KEY}/RESET_AUTOCOMPLETE_FILTER`,
  CLEAR_AUTOCOMPLETE_FILTERS: `${AUTOCOMPLETE_KEY}/CLEAR_AUTOCOMPLETE_FILTERS`,
}

export const setAutocompleteValue = createAction(types.SET_AUTOCOMPLETE_VALUE)
export const setAutocompleteInput = createAction(types.SET_AUTOCOMPLETE_INPUT)
export const resetAutocompleteFilter = createAction(types.RESET_AUTOCOMPLETE_FILTER)
export const clearAutocompleteFilters = createAction(types.CLEAR_AUTOCOMPLETE_FILTERS)
