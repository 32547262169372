import './PageLayout.css'

import { Theme, makeStyles } from '@material-ui/core'

import AuthLayout from 'components/layouts/AuthLayout'
import ErrorBoundary from 'components/ErrorBoundary'
import React from 'react'
import SideNav from './SideNav'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
  content: {
    flex: 1,
    display: 'flex',
    height: '100%',
    marginLeft: 100,
  },
}))

interface Props {
  classes: { [key: string]: string }
  children: React.ReactElement[]
}

const PageLayout = (props: Props) => {
  const classes = useStyles(props)
  const { children } = props

  return (
    <AuthLayout>
      <div className={classes.container}>
        <SideNav />
        <div className={classes.content} data-testid="page-layout__app-container">
          <ErrorBoundary variant="h4">{children}</ErrorBoundary>
        </div>
      </div>
    </AuthLayout>
  )
}

export default PageLayout
