import { MilestoneInterface } from 'store/models/Milestone'
import { getDateDiff } from 'utils/dateUtils'

/**
 * Gets the predicted delay by diffing `predictedTime` and `baselinePlannedTime` of `lastExpectedUnloadMilestone` or `dischargeLocationMilestone`
 * @param milestone Milestone
 * @returns number date | null
 */
const getPlannedTimeDelay = (milestone: MilestoneInterface | null): number | null => {
  const dischargeAtDestPrediction = milestone?.predictedTime ?? null ?? ''
  const dischargeAtDestBaseline = milestone?.baselinePlannedTime ?? ''

  return getDateDiff(dischargeAtDestPrediction, dischargeAtDestBaseline, 'days')
}

export default getPlannedTimeDelay
