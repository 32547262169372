import { closeModal, openModal } from 'store/portalAlerts/actions'
import {
  currentViewSelector,
  getDefaultAndCustomizedFilters,
  getVisibleColumns,
  isImportDialogOpen,
  rollupSelector,
  viewTypeSelector,
} from 'store/boardUtils/selectors'
import { getInitialRefType, getRefTypeCounts } from 'store/board/actions'
import {
  refTypeCountsSelector,
  selectIsExhaustiveSelector,
  selectedShipments,
  shipmentsForCurrentPageSelector,
  shipmentsSelectedCountSelector,
  shipmentsTotalCountSelector,
} from 'store/board/selectors'

import BoardPage from './BoardPage'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { downloadLoadingStatusSelector } from 'store/export'
import { fromQueryString } from 'utils/urlBuilder'
import { initiateExport } from 'store/export'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import { modalOpenSelector } from 'store/portalAlerts/selectors'
import { openShareModal } from 'store/share/actions'
import shipmentsReducer from 'store/boardUtils'
import structureShipment from 'utils/shipmentUtils/structureShipment'
import { toggleImportDialog } from 'store/boardUtils/actions'
import { userFeatureFlagsSelector } from 'store/auth/user/selectors'

const mapDispatchToProps = {
  getInitialRefType,
  getRefTypeCounts,
  initiateExport,
  portalAlertsCloseModal: closeModal,
  portalAlertsOpenModal: openModal,
  setFiltersFromURL: shipmentsReducer.actions.setFiltersFromURL,
  toggleImportDialog,
  openShareModal,
}

function structureAllShipments(rawShipments) {
  let shipments = []
  for (let i = 0; i < rawShipments.length; i++) {
    let rawShipment = rawShipments[i]
    let shipment = structureShipment(rawShipment)
    shipments.push(shipment)
  }
  return shipments
}

const shipmentSelector = state => shipmentsForCurrentPageSelector(state)
const structureShipmentsSelector = createSelector(shipmentSelector, structureAllShipments)

const mapStateToProps = (state, ownProps) => {
  const query = fromQueryString(ownProps.location.search)
  return {
    columnKeys: getVisibleColumns(state).map(c => c.key),
    currentView: currentViewSelector(state),
    downloadIsLoading: downloadLoadingStatusSelector(state),
    featureFlags: userFeatureFlagsSelector(state),
    filterConfigs: getDefaultAndCustomizedFilters(state),
    isExternalUser: isExternalUserSelector(state),
    isImportDialogOpen: isImportDialogOpen(state),
    portalAlertsModalOpen: modalOpenSelector(state),
    queryParams: query,
    refTypeCounts: refTypeCountsSelector(state),
    rollup: rollupSelector(state),
    rows: shipmentsForCurrentPageSelector(state),
    selectedCount: shipmentsSelectedCountSelector(state),
    selectedShipments: selectedShipments(state),
    selectIsExhaustive: selectIsExhaustiveSelector(state),
    shipments: structureShipmentsSelector(state),
    totalCount: shipmentsTotalCountSelector(state),
    viewType: viewTypeSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BoardPage)
