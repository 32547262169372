import { all, takeLatest } from 'redux-saga/effects'
import {
  getInitialRefTypeAsync,
  getPortalRefTypeCountsSaga,
  getShipmentsAsync,
  getShipmentsCountAsync,
  setPageAsync,
} from './api'

import { BOARD_FILTER_KEY } from 'store/filterGroups/actions'
import { allFilterGroupEvents } from 'store/filterGroups/sagas'
import { reloadDataAsync } from 'store/boardUtils/sagas/api'
import { types } from 'store/board/actions'

export function* watchBoardGetShipments() {
  // TODO: Jacob - Ensure Ref is properly passed to getShipmentsAsync and getShipmentsCountAsync
  const events = [types.GET_SHIPMENTS_LOAD]
  yield all([takeLatest(events, getShipmentsAsync), takeLatest(events, getShipmentsCountAsync)])
}

export function* watchBoardGetInitialRefType() {
  yield takeLatest(types.GET_INITIAL_REF_TYPE, getInitialRefTypeAsync)
  yield takeLatest(types.GET_REF_TYPE_COUNTS_LOAD, getPortalRefTypeCountsSaga)
}

export function* watchBoardSetPage() {
  const events = [types.SET_PAGE]
  yield takeLatest(events, setPageAsync)
}

export function* watchToRefetchEquipment() {
  // Any updates to the filters requires refetching the routes and their metrics.
  const filterEvents = allFilterGroupEvents[BOARD_FILTER_KEY]
  yield takeLatest(filterEvents, reloadDataAsync)
}

export const sagas = [
  watchBoardGetShipments,
  watchBoardGetInitialRefType,
  watchBoardSetPage,
  watchToRefetchEquipment,
]
