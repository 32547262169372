import React, { useState } from 'react'
import { TextField, Typography, makeStyles } from '@material-ui/core'

import AuthActions from 'components/AuthActions'
import Button from 'components/core/Button'
import { HOME_ROUTE } from 'utils/routes'
import Link from 'components/core/Link'
import { Loader } from 'components/Loader/Loader'
import PropTypes from 'prop-types'
import { isValidEmail } from 'utils/validate'

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: theme.spacing(45),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  loader: {
    margin: 'auto',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
}))

const ResetPasswordForm = ({ loading, onSubmit, onBackToLogin }) => {
  const classes = useStyles()
  const [email, setEmail] = useState(null)
  const [emailError, setEmailError] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()
    let containsErrors = false

    if (!isValidEmail(email)) {
      containsErrors = true
      setEmailError('Please enter a valid email address')
    }

    if (!containsErrors) onSubmit(email)
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TextField
        onChange={event => {
          setEmail(event.target.value)
          setEmailError(null)
        }}
        value={email || ''}
        variant="filled"
        name="email"
        label="Email"
        error={Boolean(emailError)}
        helperText={emailError}
        fullWidth
        InputProps={{
          type: 'email',
        }}
      />
      <AuthActions className={classes.formActions}>
        {loading ? (
          <Loader className={classes.loader} />
        ) : (
          <>
            <Typography variant="subtitle2">
              <Link to={HOME_ROUTE.buildUrl()} onClick={onBackToLogin}>
                Back to login
              </Link>
            </Typography>
            <Button color="primary" variant="contained" id="reset-password" type="submit">
              Reset Password
            </Button>
          </>
        )}
      </AuthActions>
    </form>
  )
}

ResetPasswordForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onBackToLogin: PropTypes.func.isRequired,
}

export default ResetPasswordForm
