import { createAction } from 'redux-actions'

export const PORTAL_ALERTS_REDUCER_KEY = 'portalAlerts'

export const types = {
  SUBMIT_PORTAL_ALERTS_ERROR: `${PORTAL_ALERTS_REDUCER_KEY}/SUBMIT_PORTAL_ALERTS_ERROR`,
  SUBMIT_PORTAL_ALERTS_START: `${PORTAL_ALERTS_REDUCER_KEY}/SUBMIT_PORTAL_ALERTS_START`,
  SUBMIT_PORTAL_ALERTS_SUCCESS: `${PORTAL_ALERTS_REDUCER_KEY}/SUBMIT_PORTAL_ALERTS_SUCCESS`,
  PORTAL_ALERTS_MODAL_OPEN: `${PORTAL_ALERTS_REDUCER_KEY}/PORTAL_ALERTS_MODAL_OPEN`,
  PORTAL_ALERTS_MODAL_CLOSE: `${PORTAL_ALERTS_REDUCER_KEY}/PORTAL_ALERTS_MODAL_CLOSE`,
}

export const submitForm = createAction(types.SUBMIT_PORTAL_ALERTS_START)
export const openModal = createAction(types.PORTAL_ALERTS_MODAL_OPEN)
export const closeModal = createAction(types.PORTAL_ALERTS_MODAL_CLOSE)
