import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { getPortalUserRollups, getRollupLabel } from 'utils/rollups'

import React from 'react'
import { UserAlertsInterface } from 'store/portalAlerts/interfaces'
import { refTypeCountsSelector } from 'store/board/selectors'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialogRoot: {
    padding: 0,
  },
  dialogActions: {
    margin: `${theme.spacing(4)}px -${theme.spacing(6)}px 0 -${theme.spacing(6)}px`,
    borderTop: `1px solid ${theme.palette.grey[100]}`,

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(6),
    },
  },
  filterTextfield: {
    marginLeft: theme.spacing(4),
    width: theme.spacing(27),
  },
  formControl: {
    flexBasis: 0,
    flexGrow: 1,
    minWidth: theme.spacing(25),
  },
  legendStep: {
    color: theme.palette.black,
  },
  narrowInput: {
    minWidth: theme.spacing(18),
    marginRight: theme.spacing(2),
  },
  labelCopy: {
    ...theme.typography.body1,
    color: theme.palette.grey[600],
    zIndex: 1, // without this the text is hidden when dev tools is open??
    fontWeight: theme.typography.fontWeightMedium,

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  italicsMarginAndGrey: {
    marginBottom: theme.spacing(1),
    ...theme.typography.body2,
    // TODO is there a palette.text object for this?
    color: theme.palette.grey[500],
    fontStyle: 'italic',
  },
  checkBoxes: {
    marginRight: theme.spacing(6),
  },
  note: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    width: theme.spacing(25),
    border: `2px solid ${theme.palette.warning.light}`,
  },
  selectWithoutLabel: {
    paddingTop: 18,
  },
}))

interface Props {
  values: UserAlertsInterface
  classes?: Record<string, string>
  errors: Record<string, any>
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void
}

const AlertFormStepTwo = (props: Props) => {
  const { errors, handleChange, values } = props
  const classes = useStyles(props)
  const ROLLUPS = getPortalUserRollups()
  const shipmentsBoardDataRefCounts = useSelector(refTypeCountsSelector)
  return (
    <>
      <FormLabel className={classes.labelCopy} component="legend">
        <span className={classes.legendStep}>Step 2.</span> Select which reference to be notified
        about
        <Box mt={1}>
          <Typography variant="body2" className={classes.italicsMarginAndGrey} component="div">
            You can only choose one type of reference for all alerts you set up. Changing that
            reference will change all your alerts.
          </Typography>
        </Box>
      </FormLabel>
      <FormControl className={classes.formControl} variant="filled">
        <InputLabel id="rollupType">References</InputLabel>
        <Select
          labelId="rollupType"
          onChange={handleChange}
          value={values.rollup}
          name="rollup"
          data-testid="portal-alert-modal__ref-type-selector"
        >
          {ROLLUPS.reduce((result: any, rollup: any) => {
            if (shipmentsBoardDataRefCounts[rollup] > 0) {
              return [
                ...result,
                {
                  label: getRollupLabel(rollup),
                  value: rollup,
                },
              ]
            } else {
              return result
            }
          }, []).map((option: Record<string, string>, index: number) => {
            return (
              <MenuItem value={option.value} key={index}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
        <FormHelperText>{errors.rollup && errors.rollup}</FormHelperText>
      </FormControl>
    </>
  )
}

export default AlertFormStepTwo
