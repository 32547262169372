import * as React from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const styles = theme => ({
  revokeBttn: {
    color: '#f44336',
  },
  durationLabel: {
    marginTop: theme.spacing(1),
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
  },
})

class ArchiveButton extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onArchive: PropTypes.func,
    children: PropTypes.string,
  }

  static defaultProps = {
    onArchive: () => {},
  }

  render() {
    const { item, classes, children, onArchive } = this.props

    let content = null

    if (!item.active) {
      let action = item.expired ? 'Expired' : 'Revoked'
      const timeAgo = moment(item.deletedAt || item.expiresAt).fromNow()
      content = (
        <Grid item>
          <Typography variant="caption" align="right">
            {`${action} ${timeAgo}`}
          </Typography>
        </Grid>
      )
    }
    if (item.active) {
      const expiresAt = moment(item.expiresAt)
      content = (
        <Grid item>
          <Button
            variant="text"
            className={classes.revokeBttn}
            size="small"
            onClick={e => {
              e.preventDefault()
              onArchive()
            }}
            data-testid={`revoke-share-${item.guid}`}
          >
            {children}
          </Button>
          <Typography variant="caption" className={classes.durationLabel} align="right">
            {moment().to(expiresAt, true)} left
          </Typography>
        </Grid>
      )
    }

    return (
      <Grid item xs={2} container className={classes.switchContainer} justify="flex-end">
        {content}
      </Grid>
    )
  }
}

export default withStyles(styles)(ArchiveButton)
