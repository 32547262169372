import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  clearViewAsync,
  createViewAsync,
  deleteViewAsync,
  fetchViewsAsync,
  importReferencesAsync,
  reloadDataAsync,
  selectViewAsync,
  setFilteredListAsync,
  setInitialRollupAsync,
  setRollupAsync,
  updateViewAsync,
} from 'store/boardUtils/sagas/api'

import { filterEnhancedReducer } from 'store/boardUtils'
import { types } from 'store/boardUtils/actions'

export function* watchFetchViews() {
  const events = [types.FETCH_VIEWS_START]
  yield takeLatest(events, fetchViewsAsync)
}

export function* watchSelectView() {
  const events = [types.SELECT_VIEW_START]
  yield takeLatest(events, selectViewAsync)
}

export function* watchClearView() {
  const events = [types.CLEAR_VIEW_START]
  yield takeLatest(events, clearViewAsync)
}

export function* watchCreateView() {
  const events = [types.CREATE_VIEW_START]
  yield takeEvery(events, createViewAsync)
}

export function* watchUpdateView() {
  const events = [types.UPDATE_VIEW_START]
  yield takeEvery(events, updateViewAsync)
}

export function* watchDeleteView() {
  const events = [types.DELETE_VIEW_START]
  yield takeEvery(events, deleteViewAsync)
}

// TODO: Why is this here? Can we not utilize state updates to trigger resets?
export function* watchShipmentReloadData() {
  const events = [
    filterEnhancedReducer.actionTypes.ADD_FILTER,
    filterEnhancedReducer.actionTypes.REMOVE_FILTER,
    filterEnhancedReducer.actionTypes.CLEAR_ALL_FILTERS,
    // either SET_FILTERS or types.SELECT_VIEW_SUCCESS should be here but not both.
    // if both are here, redundant /shipments/list XHRs will occur.
    // see #171062475.
    filterEnhancedReducer.actionTypes.SET_FILTERS,
    types.SET_ROLLUP_SUCCESS,
    types.SET_INITIAL_ROLLUP_SUCCESS,
    types.SET_SORT_STATE,
    types.TRANSPORTATION_STATUS,
    filterEnhancedReducer.actionTypes.SET_FILTERS_FROM_URL,
  ]
  yield all([takeLatest(events, reloadDataAsync)])
}

export function* watchImportReferences() {
  const events = [types.IMPORT_REFERENCES]
  yield takeLatest(events, importReferencesAsync)
}

export function* watchSetRollup() {
  const events = [types.SET_ROLLUP_START]
  yield takeEvery(events, setRollupAsync)
}

export function* watchInitialSetRollup() {
  const events = [types.SET_INITIAL_ROLLUP_START]
  yield takeEvery(events, setInitialRollupAsync)
}

export function* watchSetFilteredList() {
  const events = [types.SET_FILTERED_LIST_START]
  yield takeLatest(events, setFilteredListAsync)
}

export const sagas = [
  watchFetchViews,
  watchSelectView,
  watchClearView,
  watchCreateView,
  watchUpdateView,
  watchDeleteView,
  watchSetFilteredList,
  watchShipmentReloadData,
  watchImportReferences,
  watchInitialSetRollup,
  watchSetRollup,
]
