import { AuthSubtitle, AuthTitle } from 'components/AuthTitle'
import React, { useEffect } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import {
  isTokenValidFromState,
  tokenValidationLoadingFromState,
} from 'store/auth/tokenCheck/selectors'

import AuthDialog from 'components/DeprecatedAuthDialog'
import Button from 'components/core/Button'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { Loader } from 'components/Loader/Loader'
import { PASSWORD_RESET_ROUTE } from 'utils/routes'
import PropTypes from 'prop-types'
import ResetPasswordConfirmForm from './ResetPasswordConfirmForm'
import { checkToken } from 'store/auth/tokenCheck/actions'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { resetPassword } from 'store/auth/passwordResetConfirmation/actions'
import { resetPasswordConfirmationLoadingFromState } from 'store/auth/passwordResetConfirmation/selectors'

const useStyles = makeStyles(theme => ({
  expiredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '100%',
    paddingBottom: '25%',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
}))

ResetPasswordConfirm.propTypes = {
  checkToken: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  resetPasswordLoading: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  tokenCheckLoading: PropTypes.bool.isRequired,
  validToken: PropTypes.bool.isRequired,
}

function ResetPasswordConfirm({
  checkToken,
  email,
  resetPassword,
  resetPasswordLoading,
  token,
  tokenCheckLoading,
  validToken,
}) {
  const classes = useStyles()

  useEffect(() => {
    checkToken(token, email)
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePasswordReset = ({ password }) => {
    resetPassword(email, password, token)
  }

  if (tokenCheckLoading || resetPasswordLoading) {
    return (
      <CenteredLayout>
        <Loader />
      </CenteredLayout>
    )
  }

  // To access the PasswordReset form, this conditional must be false.
  // See route file for the most up to date path.
  if (!validToken) {
    return (
      <CenteredLayout className={classes.expiredContainer}>
        <Typography variant="h1">498</Typography>
        <Typography variant="h2">Password reset link expired</Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.marginTop}
          href={PASSWORD_RESET_ROUTE.buildUrl()}
        >
          Generate password reset link
        </Button>
      </CenteredLayout>
    )
  }

  return (
    <CenteredLayout>
      <AuthDialog>
        <AuthTitle>Reset Password</AuthTitle>
        <AuthSubtitle>Enter your new password</AuthSubtitle>
        <ResetPasswordConfirmForm email={email} onSubmit={handlePasswordReset} />
      </AuthDialog>
    </CenteredLayout>
  )
}

const mapDispatchToProps = {
  resetPassword,
  checkToken,
}

const mapStateToProps = (state, ownProps) => {
  const email = get(ownProps, 'match.params.email')
  const token = get(ownProps, 'match.params.token')
  return {
    resetPasswordLoading: resetPasswordConfirmationLoadingFromState(state),
    tokenCheckLoading: tokenValidationLoadingFromState(state),
    validToken: isTokenValidFromState(state),
    email,
    token,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm)
