import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const OpenInNewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <rect fill="none" width="24" height="24" />
      </g>
      <g>
        <path
          d="M20.9,2h-7.4c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h4.8l-8.4,8.4l0,0c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.6,0.5,1.1,1.1,1.1
		c0.3,0,0.6-0.1,0.8-0.3l0,0l8.4-8.4v4.8c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1V3.1C22,2.5,21.5,2,20.9,2z"
        />
        <path
          d="M20.9,15.3c-0.3,0-0.6,0.1-0.8,0.3l0,0c-0.2,0.2-0.3,0.5-0.3,0.8v3.4H4.2V4.2h3.4c0.6,0,1.1-0.5,1.1-1.1S8.2,2,7.6,2H3.1
		C2.5,2,2,2.5,2,3.1v17.8C2,21.5,2.5,22,3.1,22h17.8c0.3,0,0.6-0.1,0.8-0.3l0,0c0.2-0.2,0.3-0.5,0.3-0.8v-4.5
		C22,15.8,21.5,15.3,20.9,15.3z"
        />
      </g>
    </SvgIcon>
  )
}

export default OpenInNewIcon
