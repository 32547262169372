import { makeStyles, withStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import TableSkeleton from 'components/TableSkeleton/index'
import classnames from 'classnames'

const styles = theme => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
    textAlign: 'center',
  },
  iconWrapper: {
    display: 'block',
  },
  icon: {
    width: 150,
    height: 'auto',
  },
})

const tableCardOverlayStyles = makeStyles(theme => ({
  root: {
    // so that the message will be top-aligned even when we have 15 rows
    top: '10%',
    // so that the CardOverlay does not get displayed over modal dialogs
    zIndex: 'initial',
  },
}))

const BlankSlate = ({ classes, type, className = '' }) => {
  const containerClasses = classnames(classes.container, className)
  const message = (
    <>
      No results found.
      <br />
      Please adjust your filter criteria.
    </>
  )

  return (
    <div className={containerClasses}>
      <TableSkeleton
        isAnimated={false}
        message={message}
        rowCount={15}
        cardOverlayClasses={tableCardOverlayStyles()}
      />
    </div>
  )
}

BlankSlate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BlankSlate)
