import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { formatVesselLabel } from 'utils/strUtils'
import get from 'lodash/get'
import moment from 'moment'
import { withStyles } from '@material-ui/core'

const FULL_DATE_FORMAT = 'D MMM YYYY h:mma'

const styles = theme => ({
  popupContent: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(2),
  },
  popupRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    height: 18,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  popupLocationText: {
    fontSize: 12,
    letterSpacing: 0.2,
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  locationWrapper: {
    whiteSpace: 'nowrap',
  },
  locationTitle: {
    color: theme.palette.grey[500],
  },
  popupHeader: {
    letterSpacing: 0.2,
    marginBottom: theme.spacing(1),
  },
  popupContainerWrapper: {
    minHeight: 110,
    whiteSpacing: 'nowrap',
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    paddingLeft: theme.spacing(1),
    maxHeight: 100,
    overflow: 'scroll',
  },
  title: {
    letterSpacing: 0.2,
    fontSize: 14,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  containerHeaderText: {
    textAlign: 'center',
    color: theme.palette.grey[300],
  },
  closeIcon: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
    width: 19,
    height: 19,
    marginLeft: -4,
  },
})

class VesselPopupDetail extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    popupData: PropTypes.object.isRequired,
    popupEquipmentType: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  }

  getLastUpdate(popupData) {
    let lastUpdate = moment(get(popupData, 'vesselLoc.timestamp'))
    if (lastUpdate.isValid()) {
      lastUpdate = lastUpdate.utc().format(FULL_DATE_FORMAT) + ' UTC'
    } else {
      lastUpdate = 'N/A'
    }
    return lastUpdate
  }

  render() {
    const { classes, popupData, onClose, popupEquipmentType } = this.props
    const lat = get(popupData, 'vesselLoc.lat')
    const lon = get(popupData, 'vesselLoc.lon')
    let lastUpdate = this.getLastUpdate(popupData)
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{formatVesselLabel(popupData.name)}</div>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.popupContent}>
          <div className={classes.locationWrapper}>
            {popupEquipmentType !== 'rail' && (
              <div className={classes.popupLocationText}>
                <span className={classes.locationTitle}>Lloyds Code: </span>
                {popupData.lloydsCode}
              </div>
            )}
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Latitude: </span>
              {lat}
            </div>
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Longitude: </span>
              {lon}
            </div>
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Last position update: </span>
              {lastUpdate}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(VesselPopupDetail)
