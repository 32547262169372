export const NOTIFICATION_KEY = 'notifications'

export enum ACTION_TYPES {
  SHOW_NOTIFICATION = 'notification/SHOW_NOTIFICATION',
  CLEAR_NOTIFICATION = 'notification/CLEAR_NOTIFICATION',
  CLEAR_NON_PERSISTENT_NOTIFICATION = 'notification/CLEAR_NON_PERSISTENT_NOTIFICATION',
}

export enum MESSAGE_TYPES {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export enum LOCATION_TYPES {
  TOP = 'TOP',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  // Locations Design desires snackbars to appear
  DEFAULT_LOCATION = TOP,
}

export interface ShowNotificationActionOptions {
  type?: MESSAGE_TYPES
  isPersistent?: boolean
  location?: LOCATION_TYPES
}

interface ShowNotificationActionPayload extends ShowNotificationActionOptions {
  message: string
}

export interface ShowNotificationAction {
  type: ACTION_TYPES.SHOW_NOTIFICATION
  payload: ShowNotificationActionPayload
}

export interface ClearNotificationAction {
  type: ACTION_TYPES.CLEAR_NOTIFICATION
  payload?: {}
}

export interface ClearNonPersistentNotificationAction {
  type: ACTION_TYPES.CLEAR_NON_PERSISTENT_NOTIFICATION
  payload?: {}
}

export interface NotificationState {
  message: string
  type: MESSAGE_TYPES
  isOpen: boolean
  isPersistent: boolean
  location: LOCATION_TYPES
}

export type NotificationActionTypes =
  | ShowNotificationAction
  | ClearNotificationAction
  | ClearNonPersistentNotificationAction
