import React, { useEffect } from 'react'

import { CHART_TYPE_BAR } from 'components/ChartLoader/utils'
import ChartLoader from 'components/ChartLoader'
import HighChart from 'components/HighChart'
import { connect } from 'react-redux'
import { fetchRollsOverTime } from 'store/userDashboard/actions'
import get from 'lodash/get'
import { getHighChartDefaults } from '../utils/highCharts'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import { rollsOverTimeSelector } from 'store/userDashboard'
import { tickLabelForMetric } from 'store/planning/utils/DateRange'
import { withStyles } from '@material-ui/core'

function RollsOverTimeChart({ rollsOverTimeState, fetchData, theme }) {
  useEffect(() => {
    fetchData()
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoading, isFailed, data } = rollsOverTimeState
  const dataReceived = data !== undefined
  const hasData = !isEmpty(data) && data.totalContainers > 0

  if (isLoading || !dataReceived || !hasData) {
    return (
      <ChartLoader
        isLoading={isLoading}
        dataReceived={dataReceived}
        isFailed={isFailed}
        hasData={hasData}
        chartType={CHART_TYPE_BAR}
      />
    )
  }

  const xAxis = data.rolls.map(m => tickLabelForMetric(m))
  const series = data.rolls.map(obj => obj.rolledCount || 0)
  const totalContainers = get(data, 'totalContainers', 0)
  const totalRolls = get(data, 'totalRolls', 0)

  const highChartDefaultOptions = getHighChartDefaults(theme)

  const highChartOptions = {
    chart: {
      marginTop: 120,
      type: 'areaspline',
    },
    title: {
      text: 'Rolls by Container Volume',
    },
    legend: {
      align: 'right',
      floating: true,
      itemMarginTop: 5,
      itemStyle: {
        cursor: 'default',
      },
      layout: 'vertical',
      symbolRadius: 0,
      title: {
        style: {
          color: theme.palette.grey[800],
          fontSize: '14px',
          fontWeight: 400,
        },
        text: 'Total Volume',
      },
      verticalAlign: 'top',
      x: -170,
      y: 20,
      labelFormatter: function () {
        return `
          <div style="font-size: 14px; font-weight: 400;">
            ${this.name}: <b>${this.y.toLocaleString('en')}</b>
          </div>
        `
      },
    },
    xAxis: {
      categories: xAxis,
      title: {
        text: 'Duration',
      },
    },
    yAxis: {
      gridLineColor: theme.palette.datavis.grey[200],
      gridLineWidth: 1,
      title: {
        text: 'Rolls',
      },
    },
    tooltip: {
      backgroundColor: theme.palette.white,
      formatter: function () {
        return `<b>${this.point.options.y.toLocaleString('en')}</b>`
      },
      shared: false,
      useHTML: true,
      valueDecimals: 0,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
      series: {
        point: {
          events: {
            legendItemClick: () => false,
          },
        },
      },
    },
    series: [
      {
        color: theme.palette.datavis.blue[700],
        fillColor: theme.palette.datavis.blue[730],
        data: series,
        name: 'Rolls',
        type: 'area',
        showInLegend: false,
        marker: {
          fillColor: theme.palette.datavis.blue[700],
          lineWidth: 1,
          states: {
            hover: {
              radiusPlus: 0,
              lineWidthPlus: 0,
              halo: {
                size: 1,
                attributes: {
                  'stroke-width': 2,
                  stroke: theme.palette.grey[800],
                  opacity: 0.2,
                },
              },
            },
          },
        },
      },
      {
        center: ['92%', -75],
        data: [
          {
            name: 'Analyzed Containers',
            y: totalContainers,
            color: theme.palette.datavis.purple[300],
          },
          {
            name: 'Rolls',
            y: totalRolls,
            color: theme.palette.datavis.blue[700],
          },
        ],
        dataLabels: {
          enabled: false,
        },
        innerSize: '70%',
        name: 'Total Volume',
        type: 'pie',
        size: 80,
        showInLegend: true,
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
        },
      },
    ],
  }

  return (
    <div data-testid="user-dashboard__rolls-over-time">
      <HighChart options={merge({}, highChartDefaultOptions, highChartOptions)} />
    </div>
  )
}

const mapStateToProps = state => ({
  rollsOverTimeState: rollsOverTimeSelector(state),
})

const mapDispatchToProps = {
  fetchData: fetchRollsOverTime,
}

const styledRollsOverTimeChart = withStyles({}, { withTheme: true })(RollsOverTimeChart)
export { styledRollsOverTimeChart as RollsOverTimeChart }

export default connect(mapStateToProps, mapDispatchToProps)(styledRollsOverTimeChart)
