import {
  AssociatedReferencesInterface,
  CommonInterface,
  OrderInterface,
  TransportationInterface,
} from 'store/associatedReferences/interfaces'

import humps from 'humps'

export class AssociatedReferences {
  constructor({ orders, transportation, shipments }: AssociatedReferencesInterface) {
    Object.assign(this, { orders, transportation, shipments })
  }

  static of(payload: AssociatedReferencesInterface) {
    const { orders, transportation, shipments } = humps.camelizeKeys(
      payload
    ) as AssociatedReferencesInterface

    // Handle any transformations here
    return new AssociatedReferences({
      orders: orders && orders.map(order => OrderItem.of(order) as OrderInterface),
      transportation:
        transportation &&
        transportation.map(transport => Transportation.of(transport) as TransportationInterface),
      shipments:
        shipments && shipments.map(shipment => CommonObject.of(shipment) as CommonInterface),
    })
  }
}

export class Transportation {
  constructor({
    bols,
    booking,
    containers,
    lineItems,
    orders,
    totalContainerTrips,
  }: TransportationInterface) {
    Object.assign(this, { bols, booking, containers, lineItems, orders, totalContainerTrips })
  }

  static of(payload: TransportationInterface) {
    const {
      bols,
      booking,
      containers,
      lineItems,
      orders,
      totalContainerTrips,
    } = humps.camelizeKeys(payload) as TransportationInterface

    // Handle any transformations here
    return new Transportation({
      bols: bols && bols.map(bol => CommonObject.of(bol) as CommonInterface),
      booking: booking && (CommonObject.of(booking) as CommonInterface),
      containers,
      lineItems:
        lineItems && lineItems.map(lineItem => CommonObject.of(lineItem) as CommonInterface),
      orders: orders && orders.map(order => CommonObject.of(order) as CommonInterface),
      totalContainerTrips,
    })
  }
}

export class OrderItem {
  constructor({ lineItems, order, totalLineItems }: OrderInterface) {
    Object.assign(this, { lineItems, order, totalLineItems })
  }

  static of(payload: OrderInterface) {
    const { lineItems, order, totalLineItems } = humps.camelizeKeys(payload) as OrderInterface

    // Handle any transformations here
    return new OrderItem({
      lineItems,
      order: order && (CommonObject.of(order) as CommonInterface),
      totalLineItems,
    })
  }
}

export class CommonObject {
  constructor({ externalId, id, number, type }: CommonInterface) {
    Object.assign(this, { externalId, id, number, type })
  }

  static of(payload: CommonInterface) {
    const { externalId, id, number, type } = humps.camelizeKeys(payload) as CommonInterface

    // Handle any transformations here
    return new CommonObject({ externalId, id, number, type })
  }
}
