import BoxPlot from 'components/HighChart'
import BoxWhiskerPlotLoader from 'components/ChartLoader/BoxWhiskerPlotLoader'
import CardOverlay from 'components/core/CardOverlay'
import { Metric } from 'store/planning/utils/Metric'
import PropTypes from 'prop-types'
import React from 'react'
import { formatDecimal } from 'utils/strUtils'
import { formatters } from '../utils'
import mean from 'lodash/mean'
import { routeGraphMode } from 'store/planning/utils/RouteComparison'
import { tickLabelForMetric } from 'store/planning/utils/DateRange'
import tracker from 'utils/logger/tracker'
import { withStyles } from '@material-ui/core'

const averageNonNullValue = (data, metric) => {
  return mean(data.map(d => d[metric]).filter(m => m !== undefined))
}

const AggregatedRoutePerformanceGraph = ({ data, height, theme, isLoading, error }) => {
  /**
   * Early returning here prevents following prevents
   * various errors later, as data is not available.
   */

  if (isLoading || error) {
    return (
      <>
        <BoxWhiskerPlotLoader height={height} isAnimated={isLoading} />
        {error && <CardOverlay>{error}</CardOverlay>}
      </>
    )
  }

  // Get the average value of the non-null values for each metric.
  // We use greyed-out boxes for date ranges where there is no data,
  // and we use these averages to place the box.
  const averageMin = averageNonNullValue(data, 'metricsMin')
  const averageLowerQuartile = averageNonNullValue(data, 'lowerQuartile')
  const averageUpperQuartile = averageNonNullValue(data, 'upperQuartile')
  const averageMax = averageNonNullValue(data, 'metricsMax')

  const boxData = []
  const lineData = []
  const xAxis = []
  const variabilities = []
  const emptyMetricIndexes = []

  data.forEach((d, i) => {
    const isEmptyMetric = !Metric.isValid(d)
    if (isEmptyMetric) {
      emptyMetricIndexes.push(i)
    }
    boxData.push(
      isEmptyMetric
        ? {
            low: averageMin,
            q1: averageLowerQuartile,
            median: null,
            q3: averageUpperQuartile,
            high: averageMax,
            fillColor: theme.palette.datavis.grey[200],
            color: theme.palette.datavis.grey[300],
            medianColor: theme.palette.datavis.grey[300],
            stemColor: theme.palette.datavis.grey[400],
            whiskerColor: theme.palette.datavis.grey[400],
          }
        : [d.metricsMin, d.lowerQuartile, d.metricsMedian, d.upperQuartile, d.metricsMax]
    )
    lineData.push(d.metricsMedian === undefined ? null : d.metricsMedian)
    xAxis.push(tickLabelForMetric(d))
    variabilities.push(d.variability === undefined ? null : d.variability)
  })

  const buildTooltip = ({ index, median, q1, q3, variability }) => {
    if (emptyMetricIndexes.includes(index)) {
      return 'No Data'
    }

    const metricStyle = `
      font-weight: ${theme.typography.fontWeightBold};
      font-size: ${theme.typography.subtitle1.fontSize};
      letter-spacing: ${theme.typography.subtitle1.letterSpacing};
      line-height: 1.33;
      display: block;
      `
    const titleStyle = `
      font-weight: ${theme.typography.subtitle2.fontWeight};
      font-size: ${theme.typography.subtitle2.fontSize};
      letter-spacing: ${theme.typography.subtitle2.letterSpacing};
      line-height: 1.33;
      color: ${theme.palette.text.secondary};
      display: block;
      `
    const breakStyle = `
      margin-top: .3em;
    `

    return `
      <span style="${metricStyle}">${formatDecimal(median)} Days</span>
      <span style="${titleStyle}">Transit Time Median</span>

      <div style="${breakStyle}" />

      <span style="${metricStyle}">${formatDecimal(variability)} Days</span>
      <span style="${titleStyle}">Variability</span>

      <div style="${breakStyle}" />

      <span style="${metricStyle}">${formatters.range(q1, q3)} Days</span>
      <span style="${titleStyle}">Interquartile Range</span>
    `
  }

  return (
    <BoxPlot
      options={{
        title: {
          text: 'Placeholder',
          style: { visibility: 'hidden' },
        },
        chart: {
          type: 'boxplot',
          height,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: xAxis,
          title: {
            text: 'Duration',
            style: {
              fontSize: theme.typography.body2.fontSize,
            },
          },
        },
        yAxis: {
          title: {
            text: 'Days',
            style: {
              fontSize: theme.typography.body2.fontSize,
            },
          },
          min: 0,
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            const { median, index, q1, q3 } = this.point
            const { userOptions } = this.series

            return buildTooltip({
              index,
              median,
              q1,
              q3,
              variability: userOptions.variabilities[index],
            })
          },
        },
        series: [
          {
            data: boxData,
            variabilities,
            point: {
              events: {
                mouseOver: () => {
                  tracker.planning.userHoversGraphPoint(routeGraphMode.AGGREGATED)
                },
              },
            },
          },
          {
            type: 'spline',
            // Disable tooltips + other mouse events
            enableMouseTracking: false,
            data: lineData,
          },
        ],
      }}
    />
  )
}

AggregatedRoutePerformanceGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      lowerQuartile: PropTypes.number,
      metricsMax: PropTypes.number,
      metricsMean: PropTypes.number,
      metricsMedian: PropTypes.number,
      metricsMin: PropTypes.number,
      metricsStddev: PropTypes.number,
      routeId: PropTypes.any,
      upperQuartile: PropTypes.number,
      percentile5: PropTypes.number,
      percentile95: PropTypes.number,
      variability: PropTypes.number,
    })
  ),
  height: PropTypes.number,
  isLoading: PropTypes.bool,
}

AggregatedRoutePerformanceGraph.defaultProps = {
  height: 300,
}

export default withStyles({}, { withTheme: true })(AggregatedRoutePerformanceGraph)
