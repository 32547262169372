import React from 'react'
import { SvgIcon } from '@material-ui/core'

const TruckIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 66 32">
      <g fill="none" fillRule="evenodd">
        <rect width="66" height="32" />
        <g fill="currentColor" transform="translate(4 4)">
          <path d="M45,7.46069873e-14 L45,4 L48.8644951,4 C49.3331937,4 49.7390071,4.32553199 49.8406821,4.78306954 L51,10 L55,10 C56.1045695,10 57,10.8954305 57,12 L57,18 L58,18 L58,21 L55.475085,21.0007642 C55.4915618,20.8360316 55.5,20.668977 55.5,20.5 C55.5,17.7386784 53.2630642,15.5 50.5007119,15.5 C47.7426314,15.5 45.5,17.7415266 45.5,20.5 C45.5,20.6689836 45.508388,20.8360098 45.5247703,21.0006854 L37.475085,21.0007642 C37.4915618,20.8360316 37.5,20.668977 37.5,20.5 C37.5,19.5891933 37.2566272,18.7352488 36.8313016,17.9997485 L43,18 L43,7.46069873e-14 L45,7.46069873e-14 Z" />
          <path
            fillRule="nonzero"
            d="M50.5004984 17C48.5698419 17 47 18.5690686 47 20.5 47 22.4329251 48.5678485 24 50.5004984 24 52.4291613 24 54 22.4289376 54 20.5 54 18.5670749 52.434145 17 50.5004984 17zM32.5004984 17C30.5698419 17 29 18.5690686 29 20.5 29 22.4329251 30.5678485 24 32.5004984 24 34.4291613 24 36 22.4289376 36 20.5 36 18.5670749 34.434145 17 32.5004984 17zM3.50049836 17C1.56984195 17 0 18.5690686 0 20.5 0 22.4329251 1.5678485 24 3.50049836 24 5.42916133 24 7 22.4289376 7 20.5 7 18.5670749 5.43414495 17 3.50049836 17zM12.5004984 17C10.5698419 17 9 18.5690686 9 20.5 9 22.4329251 10.5678485 24 12.5004984 24 14.4291613 24 16 22.4289376 16 20.5 16 18.5670749 14.434145 17 12.5004984 17z"
          />
          <path d="M2,0 L2,1 L38,1 L38,0 L40,0 L40,15 L0,15 L0,0 L2,0 Z M8,3 L4,3 L4,12 L8,12 L8,3 Z M15,3 L11,3 L11,12 L15,12 L15,3 Z M22,3 L18,3 L18,12 L22,12 L22,3 Z M29,3 L25,3 L25,12 L29,12 L29,3 Z M36,3 L32,3 L32,12 L36,12 L36,3 Z" />
        </g>
      </g>
    </SvgIcon>
  )
}

export default TruckIcon
