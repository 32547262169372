import { Chip, CircularProgress, MenuItem, Paper, TextField, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

import { CancelToken } from 'axios'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import axios from 'axios'
import classnames from 'classnames'
import client from 'utils/api/client'
import colors from 'components/core/Colors'
import cx from 'classnames'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import logger from 'utils/logger'

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  input: {
    width: 'auto',
    flexGrow: 1,
  },
  suggestionsWrapper: {
    position: 'relative',
  },
  suggestions: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    maxHeight: 200,
    overflow: 'auto',
    width: '88%',
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  chipLabel: {},
  inputRoot: {
    flexWrap: 'wrap',
    backgroundColor: theme.palette.white,
  },
  hasSelections: {
    backgroundColor: theme.palette.grey[200],
  },

  deleteIcon: {
    height: 20,
  },
  suggestionsLoading: {
    textAlign: 'center',
    height: 200,
    paddingTop: 70,
  },
  inputUnderline: {
    '&:before': {
      // this border will be shown during hover state
      borderBottom: `2px solid transparent !important`,
    },
    '&:after': {
      borderBottom: `2px solid transparent`,
    },
  },
})

const renderChips = (classes, multiValues, onDelete, transformChipFn) => {
  return (
    <React.Fragment>
      {multiValues.map((item, idx) => {
        let label = get(item, 'label')
        if (transformChipFn && typeof transformChipFn === 'function') {
          label = label ? transformChipFn(label) : ''
        }
        return (
          <Chip
            key={idx}
            variant="outlined"
            label={label}
            onDelete={() => {
              onDelete(item, idx)
            }}
            classes={{
              root: classes.chip,
              label: classes.chipLabel,
              deleteIcon: classes.deleteIcon,
            }}
          />
        )
      })}
    </React.Fragment>
  )
}

function defaultRenderInput(inputProps, transformChipFn) {
  const {
    autoFocus,
    classes,
    error,
    inputClassName,
    isLoading,
    isMulti,
    multiValues,
    onChipDelete,
    onKeyDown,
    onKeyUp,
    placeholder,
    selectedItem,
    suggestions,
    value,
    ...other
  } = inputProps

  return (
    <TextField
      ref="input"
      autoFocus={autoFocus}
      className={classes.textField}
      error={Boolean(error)}
      value={value}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      InputProps={{
        classes: {
          input: classes.input,
          root: cx(classes.inputRoot, { [classes.hasSelections]: !isEmpty(multiValues) }),
          underline: classes.inputUnderline,
        },
        placeholder,
        startAdornment: renderChips(classes, multiValues, onChipDelete, transformChipFn),
        ...other,
      }}
    />
  )
}

function defaultRenderSuggestion(params) {
  const { suggestion, index, itemProps, theme, highlightedIndex, selectedItem } = params
  const isHighlighted = highlightedIndex === index
  const isSelected = selectedItem === suggestion

  return (
    <MenuItem
      {...itemProps}
      key={`filter-suggestion-${suggestion.label}-${index}`}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular,
      }}
    >
      {suggestion.label}
    </MenuItem>
  )
}

function renderSuggestionsContainer(options) {
  const { containerProps, classes, children, isLoading } = options
  return (
    <div className={classes.suggestionsWrapper}>
      <Paper
        {...containerProps}
        className={classnames(classes.suggestions, {
          [classes.suggestionsLoading]: isLoading,
        })}
        square
        elevation={2}
      >
        {isLoading ? <CircularProgress style={{ color: colors.grey[700] }} /> : children}
      </Paper>
    </div>
  )
}

const defaultItemToString = item => (isString(item) ? item : get(item, 'label', ''))
const defaultItemValue = item => get(item, 'value', '')

class AutocompleteChipInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: props.defaultValue,
      isLoading: false,
      multiValues: props.defaultValues,
      suggestions: [],
    }
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    defaultValues: PropTypes.array,
    error: PropTypes.bool, // error that can be passed to textfield
    getItemValue: PropTypes.func,
    id: PropTypes.string,
    inputClassName: PropTypes.string,
    isMulti: PropTypes.bool,
    isUnique: PropTypes.bool,
    itemToString: PropTypes.func,
    lookupPath: PropTypes.string.isRequired,
    multiValues: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    renderInput: PropTypes.func,
    renderSuggestion: PropTypes.func,
    transformChipFn: PropTypes.func,
    theme: PropTypes.object.isRequired,
  }

  static defaultProps = {
    defaultValue: '',
    defaultValues: [],
    getItemValue: defaultItemValue,
    inputClassName: '',
    isMulti: true,
    isUnique: false,
    itemToString: defaultItemToString,
    multiValues: [],
    renderInput: defaultRenderInput,
    renderSuggestion: defaultRenderSuggestion,
  }

  _cancelRequest = null

  debouncedInputHandler = debounce(async (inputValue, stateAndHelpers) => {
    // When input value changes, clear downshift's selectedItem. Otherwise, when input loses focus, our this.itemToString
    // function will override the inputValue because 'item' won't be null
    try {
      if (!inputValue) {
        return this.setState({ suggestions: [] })
      }
      if (stateAndHelpers.selectedItem) {
        stateAndHelpers.setState({
          selectedItem: null,
        })
      }

      const { lookupPath } = this.props
      this.setState({ isLoading: true, selectedItem: null })
      if (this._cancelRequest) {
        this._cancelRequest()
      }

      const cancelToken = new CancelToken(c => {
        this._cancelRequest = c
      })
      let params = { q: inputValue }
      if (this.props.suggestType) params.suggest_type = this.props.suggestType
      const res = await client.get(lookupPath, { params, cancelToken })

      this.setState({ suggestions: res.data })
    } catch (err) {
      // We're only going to log these as errors if they aren't cancelled XHR requests
      if (!axios.isCancel(err)) {
        logger.captureAPIException(err)
        logger.localLog('err', err)
      }
    } finally {
      this.setState({ isLoading: false })
    }
  }, 300)

  handleInputValueChange = (inputValue, stateAndHelpers) => {
    this.setState({
      inputValue,
    })
    this.debouncedInputHandler(inputValue, stateAndHelpers)
  }

  onChipDelete = (item, idx) => {
    const updatedMultiValues = this.state.multiValues.slice()
    updatedMultiValues.splice(idx, 1)
    this.setState({
      multiValues: updatedMultiValues,
    })
    this.props.onSelect(null, updatedMultiValues)
  }

  onKeyDown = e => {
    if (e.keyCode === 8) {
      if (this.props.isMulti && this.state.inputValue === '' && this.state.multiValues.length > 0) {
        const updatedMultiValues = this.state.multiValues.slice()
        updatedMultiValues.pop() // remove last item
        this.setState({
          multiValues: updatedMultiValues,
        })
        this.props.onSelect(null, updatedMultiValues)
      }
    }
  }

  /**
   * See 169828340. We're using `scrollIntoView` to try and make sure the autocomplete dropdown is visible.
   * The timeout is because there is a latency before the dropdown appears. Experimental.
   */
  onKeyUp = e => {
    const el = e.currentTarget
    const scroll = () => {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
    setTimeout(scroll, 300)
  }

  renderBody = renderProps => {
    const { suggestions, isLoading, multiValues } = this.state
    const {
      classes,
      error,
      id,
      inputClassName,
      isMulti,
      placeholder,
      renderInput,
      renderSuggestion,
      theme,
      transformChipFn,
    } = this.props
    const { getInputProps, getItemProps, isOpen, selectedItem, highlightedIndex } = renderProps

    const getSuggestionContent = () => {
      return this.state.suggestions.map((suggestion, index) =>
        renderSuggestion({
          suggestion,
          index,
          theme,
          itemProps: getItemProps({ item: suggestion }),
          highlightedIndex,
          selectedItem,
          isMulti,
        })
      )
    }

    const inputProps = getInputProps({
      classes,
      error,
      id,
      inputClassName,
      isLoading,
      isMulti,
      multiValues,
      onChipDelete: this.onChipDelete,
      onKeyDown: this.onKeyDown,
      onKeyUp: this.onKeyUp,
      placeholder,
      selectedItem,
      suggestions,
    })
    const input = renderInput(inputProps, transformChipFn)

    return (
      <div className={classes.container}>
        {input}
        {isOpen
          ? renderSuggestionsContainer({
              classes,
              children: getSuggestionContent(),
              isLoading,
            })
          : null}
      </div>
    )
  }

  arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false
    }

    for (let i = 0; i < arr1.length; i++) {
      const arr1Element = arr1[i]
      const arr2Element = arr2[i]
      if (JSON.stringify(arr1Element) !== JSON.stringify(arr2Element)) return false // compare objs
    }
    return true
  }

  componentDidUpdate = prevProps => {
    // if we receive a new single input value, update input to reflect that new default value
    if (
      // make sure we have a new defaultValue
      prevProps.defaultValue !== this.props.defaultValue &&
      // make sure the new defaultValue doesnt match the current value
      (this.props.defaultValue !== this.props.getItemValue(this.state.selectedItem) ||
        (this.props.defaultValue === '' && !this.state.selectedItem))
    ) {
      this.setState({
        inputValue: this.props.defaultValue,
      })
    }

    // if we receive new multiple values, update input to reflect that new default value
    const defaultsDiffer = !this.arraysEqual(prevProps.defaultValues, this.props.defaultValues)
    const arraysDiffer = !this.arraysEqual(this.state.multiValues, this.props.defaultValues)
    if (defaultsDiffer && arraysDiffer) {
      this.setState({
        multiValues: this.props.defaultValues,
      })
    }
  }

  handleSelect = item => {
    const { isMulti, isUnique, onSelect } = this.props
    if (item) {
      const updatedMultiValues = this.state.multiValues.slice()
      let inputValue = this.itemToString(item)
      if (isMulti) {
        if (!isUnique || !find(updatedMultiValues, item)) {
          updatedMultiValues.push(item)
        }
        inputValue = ''
      }

      this.setState({
        inputValue,
        multiValues: updatedMultiValues,
        selectedItem: item,
      })
      onSelect(item, updatedMultiValues)
    }
  }

  itemToString = item => {
    if (!item) return this.state.inputValue
    return this.props.itemToString(item)
  }

  render() {
    return (
      <Downshift
        render={this.renderBody}
        inputValue={this.state.inputValue}
        defaultInputValue={this.props.defaultValue}
        onInputValueChange={this.handleInputValueChange}
        itemToString={this.itemToString}
        onSelect={this.handleSelect}
      />
    )
  }
}

export default withStyles(styles, { withTheme: true })(AutocompleteChipInput)
