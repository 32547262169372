import {
  ACTION_TYPES,
  ClearNonPersistentNotificationAction,
  ClearNotificationAction,
  ShowNotificationAction,
  ShowNotificationActionOptions,
} from 'store/notifications/types'

export function showNotification(
  message: string,
  options?: ShowNotificationActionOptions
): ShowNotificationAction {
  return {
    type: ACTION_TYPES.SHOW_NOTIFICATION,
    payload: { message, ...options },
  }
}

export function clearNotification(payload?: {}): ClearNotificationAction {
  return {
    type: ACTION_TYPES.CLEAR_NOTIFICATION,
    payload,
  }
}

export function clearNonPersistentNotification(payload?: {}): ClearNonPersistentNotificationAction {
  return {
    type: ACTION_TYPES.CLEAR_NON_PERSISTENT_NOTIFICATION,
    payload,
  }
}
