import MESSAGES from 'store/notifications/messages'
import { ReactElement } from 'react'

const oauthRedirectErrorMessages: Record<string, string | ReactElement> = {
  invalid_request: 'Invalid request. Please try again or contact support@clearmetal.com',
  access_denied: 'Access was denied. Please try again and grant access to the project44 app',
  unauthorized_client: 'Internal error while authorizing. Please contact support@clearmetal.com',
  unsupported_response_type:
    'Internal error while authorizing. Please contact support@clearmetal.com',
  invalid_scope: 'Internal error while authorizing. Please contact support@clearmetal.com',
  server_error:
    'There was an error communicating with the authentication server. Please try again or contact support@clearmetal.com',
  temporarily_unavailable:
    'There was an error communicating with the authentication server. Please try again or contact support@clearmetal.com',
  unknown_error: 'Internal error while authorizing. Please contact support@clearmetal.com',
  invalid_parameters:
    'Error while authorizing. Please try again. If you continue to have difficulties logging in, contact support@clearmetal.com',
  third_party_error:
    'There was an error communicating with the authentication server. Please try again or contact support@clearmetal.com',
  hosted_domain_mismatch:
    'The hosted domain received from the authentication server does not match the domain of your email. Please choose a different email or contact support@clearmetal.com',
  email_mismatch:
    'The email you authorized with does not match the email you entered into project44. Please choose a different email or contact support@clearmetal.com',
  third_party_email_not_verified:
    'You have not verified this email account with your authorization provider. Please verify your email account, then try again',
  invalid_username: 'Invalid username. Please try again.',
  unactivated_user: MESSAGES.loginActivationError,
}

export default oauthRedirectErrorMessages
