import { Collapse, TableCell, makeStyles } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}))

const collapseComponent = props => <div className={props.className}>{props.children}</div>

const CollapsibleCell = ({ children, collapseIn, ...rest }) => {
  const classes = useStyles()

  return (
    <TableCell classes={{ root: classes.root }} {...rest}>
      <Collapse in={collapseIn} timeout="auto" component={collapseComponent} unmountOnExit>
        {children}
      </Collapse>
    </TableCell>
  )
}

export default CollapsibleCell
