import { MenuItem, MenuList, Paper, Popover, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import DownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: 6,
  },
  paper: {
    maxHeight: 230,
    overflow: 'scroll',
  },
  button: {
    border: '1px solid #aaaaaa',
    padding: '0px 16px',
  },
  buttonLabel: {
    textTransform: 'none',
    pointerEvents: 'none',
    color: '#65717d',
  },
  menuItem: {
    fontSize: 14,
    height: theme.spacing(2),
  },
})

const ZONES = [
  'America/Los_Angeles',
  'America/Chicago',
  'America/Los_Angeles',
  'America/New_York',
  'America/Montevideo',
  'Asia/Shanghai',
  'Japan',
  'Europe/London',
  'Europe/Madrid',
  'Europe/Zurich',
  'UTC',
]

export const UNIQUE_ZONES = [...new Set(ZONES)]

class TimezonePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue,
      open: false,
      anchorEl: null,
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    defaultValue: PropTypes.string,
  }

  static defaultProps = {
    onSelect: () => {},
    defaultValue: 'UTC',
  }

  handleClose = event => {
    this.setState({ open: false })
  }

  handleOpen = event => {
    this.setState({
      open: true,
      anchorEl: event.target,
    })
  }

  handleSelect = value => () => {
    this.setState(
      {
        value,
        open: false,
      },
      () => {
        this.props.onSelect(value)
      }
    )
  }

  render() {
    const { classes } = this.props
    const { open, value, anchorEl } = this.state

    return (
      <div className={classes.root}>
        <Button
          classes={{ label: classes.buttonLabel, root: classes.button }}
          onClick={this.handleOpen}
        >
          {value}
          <DownIcon />
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorReference={'anchorEl'}
          onClose={this.handleClose}
          anchorPosition={{ top: 60, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper className={classes.paper} elevation={2}>
            <MenuList>
              {ZONES.sort().map(zone => {
                return (
                  <MenuItem
                    key={zone}
                    onClick={this.handleSelect(zone)}
                    className={classes.menuItem}
                  >
                    {zone}
                  </MenuItem>
                )
              })}
            </MenuList>
          </Paper>
        </Popover>
      </div>
    )
  }
}

TimezonePicker.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TimezonePicker)
