import { DATE_FORMAT, getDateFromUtcString } from 'utils/dateUtils'

import humps from 'humps'

export interface MilestoneStatusInterface {
  statusType: string
  tracedTime: string | null
  predictedTime: string | null
  plannedTime: string | null
}

export default class MilestoneStatus {
  constructor({ statusType, tracedTime, predictedTime, plannedTime }: MilestoneStatusInterface) {
    Object.assign(this, { statusType, tracedTime, predictedTime, plannedTime })
  }

  static of(payload: MilestoneStatusInterface) {
    const { statusType, tracedTime, predictedTime, plannedTime } = humps.camelizeKeys(
      payload
    ) as MilestoneStatusInterface
    return new MilestoneStatus({
      statusType,
      tracedTime:
        tracedTime &&
        getDateFromUtcString({
          dateString: tracedTime,
          format: DATE_FORMAT,
        }),
      predictedTime:
        predictedTime &&
        getDateFromUtcString({
          dateString: predictedTime,
          format: DATE_FORMAT,
        }),
      plannedTime:
        plannedTime &&
        getDateFromUtcString({
          dateString: plannedTime,
          format: DATE_FORMAT,
        }),
    })
  }
}
