import { getIsCommentsDrawerOpen, openCommentsDrawer } from 'utils/routes/comments'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { commentSummariesStateSelector } from 'store/comments'
import get from 'lodash/get'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useOpenCommentsDrawerIfComments = externalId => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  const commentSummariesState = useSelector(commentSummariesStateSelector)
  const summaries = get(commentSummariesState, 'data')
  const commentCount = get(summaries, `${externalId}.count`)
  const isCommentsDrawerOpen = getIsCommentsDrawerOpen(match)

  useEffect(() => {
    if (Boolean(commentCount) && !isCommentsDrawerOpen) {
      openCommentsDrawer({ history, location })
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => (externalId = null)
  }, [commentCount])
}
