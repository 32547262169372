import { CircularProgress, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'

import Button from 'components/core/Button'
import DownloadIcon from 'components/icons/DownloadIcon'
import ExportMenuPopover from './ExportMenuPopover'
import PropTypes from 'prop-types'

const ExportMenu = function ({
  className = '',
  disabled,
  tooltipText,
  isLoading,
  onDownloadClick,
  isIconOnlyButton = true,
  buttonText = '',
  ...buttonProps
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenuClose = () => setAnchorEl(null)
  const handleMenuOpen = e => setAnchorEl(e.currentTarget)

  const { startIcon: StartIcon, variant } = buttonProps

  const buttonStartIcon = () => {
    if (isIconOnlyButton) {
      return ''
    }

    if (isLoading) {
      return <CircularProgress size={12} />
    }
    return StartIcon ? <StartIcon /> : <DownloadIcon />
  }

  const buttonElement = (
    <Button
      onClick={handleMenuOpen}
      className={className}
      disabled={disabled}
      variant={variant || 'text'}
      startIcon={buttonStartIcon()}
      {...buttonProps}
      data-testid="exportMenu"
    >
      {!isIconOnlyButton ? <span>{buttonText}</span> : <DownloadIcon />}
    </Button>
  )
  return (
    <>
      {tooltipText ? (
        <Tooltip title={tooltipText}>
          <span>{buttonElement}</span>
        </Tooltip>
      ) : (
        buttonElement
      )}
      {anchorEl && (
        <ExportMenuPopover
          anchorEl={anchorEl}
          onDownloadClick={onDownloadClick}
          handleMenuClose={handleMenuClose}
        />
      )}
    </>
  )
}
ExportMenu.propTypes = {
  onClick: PropTypes.func,
  onDownloadClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
}

export default ExportMenu
