import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import { ROLLUP_BOLS, ROLLUP_BOOKINGS } from 'utils/rollups'

import ContainerCell from 'components/EntityPageHeader/ContainerCell'
import { EntityPageHeaderInterface } from 'store/models/EntityPageHeader'
import React from 'react'
import RefNumberList from 'components/Timeline/TimelineHeader/RefNumberList'
import Status from 'components/EntityPageHeader/Status'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-body2': {
      lineHeight: 1.4,
    },
    '& .MuiTypography-h6': {
      lineHeight: 1.3,
    },
  },
  item: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
  cell: {
    minHeight: 54,
    padding: '2px 21px 2px 16px',
  },
  overviewCell: {},
  details: {
    marginLeft: 0,
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  muted: {
    color: theme.palette.grey[600],
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  activityCell: {
    paddingLeft: 21,
    paddingRight: 0,
  },
  prediction: {
    margin: '3px 0',
  },
  dischargeCell: {
    paddingRight: theme.spacing(1),
  },
}))

interface Props {
  headerData: EntityPageHeaderInterface
  isBookingPage?: boolean
  isPublicShare?: boolean
  timelinesCount: number
}

const TimelineHeader = (props: Props) => {
  const classes = useStyles(props)
  const { headerData, isBookingPage = false, isPublicShare = false, timelinesCount } = props

  if (isEmpty(headerData)) {
    return null
  }

  return (
    <Box
      pt={3}
      mb={7}
      className={classes.root}
      display="flex"
      flexDirection="row"
      data-testid="timeline__timeline-header"
    >
      <Box className={classes.item} display="flex" flexDirection="row">
        <Box className={classnames(classes.cell, classes.flexColumn, classes.borderRight)}>
          <Typography variant="body2" className={classes.muted}>
            {isBookingPage ? 'Bills of Lading' : 'Booking'}
          </Typography>
          {!isBookingPage && (
            <RefNumberList
              isPublicShare={isPublicShare}
              rollup={ROLLUP_BOOKINGS}
              refNumbers={headerData.bookings}
            />
          )}

          {isBookingPage && <RefNumberList rollup={ROLLUP_BOLS} refNumbers={headerData.bols} />}
        </Box>
        <Box className={classnames(classes.cell, classes.flexColumn)} flexGrow="1">
          <ContainerCell
            classes={{ muted: classes.muted, cell: classes.cell }}
            isPublicShare={isPublicShare}
            source="timeline"
            trips={headerData.trips ?? []}
          />
        </Box>
      </Box>

      {timelinesCount > 1 && (
        <Box ml={2} className={classes.item} display="flex" flexDirection="row">
          <Status
            isSubheader={true}
            pageHeaderData={headerData}
            classes={{
              activityCell: classes.activityCell,
              cell: classes.cell,
              details: classes.details,
              dischargeCell: classes.dischargeCell,
              flexColumn: classes.flexColumn,
              muted: classes.muted,
              prediction: classes.prediction,
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default TimelineHeader
