import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const RefreshIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} {...props}>
    <g>
      <path fill="none" d="M0,0h24v24H0V0z" />
    </g>
    <path
      d="M19.5,3.2c-0.6,0-1,0.4-1,1v2.5c-1.6-1.9-3.9-3-6.5-3c-4.7,0-8.5,3.8-8.5,8.5c0,4.7,3.8,8.5,8.5,8.5c2.7,0,5.1-1.3,6.7-3.3
	c0.2-0.2,0.3-0.4,0.3-0.7c0-0.6-0.4-1-1-1c-0.3,0-0.6,0.1-0.8,0.4c-1.2,1.6-3.1,2.6-5.2,2.6c-3.6,0-6.5-2.9-6.5-6.5S8.4,5.8,12,5.8
	c2.5,0,4.7,1.4,5.7,3.5h-4.2c-0.6,0-1,0.4-1,1s0.4,1,1,1h6c0.6,0,1-0.4,1-1v-6C20.5,3.7,20.1,3.2,19.5,3.2z"
    />
  </SvgIcon>
)

export default RefreshIcon
