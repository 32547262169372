import tinycolor from 'tinycolor2'

// Theme is passed from material-ui withStyles
export default theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  range: {
    marginTop: 30,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toolbar: {
    backgroundColor: tinycolor(theme.brand.color).setAlpha(0.3).toRgbString(),
    color: 'black',
    flex: 1,
    display: 'flex',
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white,
    flex: 1,
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  form: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitBtn: {
    color: '#66717E',
    border: '1px solid #66717E',
    width: '80%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#66717E',
      color: theme.palette.common.white,
    },
    margin: 'auto',
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectionRow: {
    display: 'flex',
    alignItems: 'center',
  },
  dateSelectionTitle: {
    color: theme.palette.grey.A700,
    cursor: 'pointer',
  },
  dateContainer: {
    marginTop: theme.spacing(5),
  },
  warning: {
    color: 'orange',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  dateWrapper: {
    margin: '10px 0 10px 20px',
    textAlign: 'center',
    width: 235,
  },
  helperText: {
    fontSize: 12,
  },
})
