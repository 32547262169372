import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ShipmentsIcon = ({ ...props }) => (
  <SvgIcon viewBox={'0 0 35 34'} {...props}>
    <g fillRule="nonzero">
      <path d="M4.847 11.362a.548.548 0 01-.403-.176c-.42-.457-4.1-4.528-4.1-6.81C.428 2 2.418.115 4.845.115c2.428 0 4.418 1.885 4.5 4.261 0 2.282-3.679 6.353-4.099 6.81a.546.546 0 01-.398.176zm0-10.334c-1.886.002-3.415 1.5-3.417 3.348 0 1.416 2.188 4.23 3.417 5.645 1.23-1.416 3.418-4.23 3.418-5.645-.003-1.848-1.531-3.346-3.418-3.348z" />
      <path d="M27.837 30.918a.867.867 0 01-.64-.28c-.667-.725-6.509-7.19-6.509-10.812-.089-2.558 1.253-4.96 3.5-6.265a7.276 7.276 0 017.29 0c2.247 1.305 3.589 3.707 3.5 6.265 0 3.622-5.842 10.085-6.51 10.812a.867.867 0 01-.63.28zm-.153-17.335c-3.313.003-5.998 2.67-6 5.958 0 2.519 3.843 7.523 6 10.042 2.157-2.52 6-7.525 6-10.042-.003-3.289-2.687-5.955-6-5.958z" />
      <path d="M27.673 22a2.5 2.5 0 112.5-2.5 2.51 2.51 0 01-2.5 2.5zM9.641 14.154a.98.98 0 01-.11 0 18.577 18.577 0 00-2.037-.146.853.853 0 01-.862-.844c0-.466.386-.844.862-.844.753.01 1.506.064 2.253.16a.85.85 0 01.757.891.854.854 0 01-.865.791l.002-.008zM21.148 33.68a52.304 52.304 0 01-4.583-.236.85.85 0 01-.77-.926.858.858 0 01.946-.754c1.321.134 2.812.203 4.425.227.476 0 .862.378.862.844a.853.853 0 01-.862.844h-.018zm-8.926-1.062a.878.878 0 01-.267-.04c-2.334-.745-3.62-1.894-3.821-3.418a.836.836 0 01.324-.794.876.876 0 01.866-.109c.287.12.485.384.518.688.108.812.998 1.504 2.645 2.03a.843.843 0 01.587.935.857.857 0 01-.854.712l.002-.004zm-1.342-6.587a.862.862 0 01-.794-.506.831.831 0 01.16-.914c.247-.264.52-.535.818-.812a25.464 25.464 0 002.224-2.356.87.87 0 011.494.21.83.83 0 01-.15.845 27.245 27.245 0 01-2.373 2.517c-.276.255-.525.504-.746.744a.87.87 0 01-.633.272zm5.13-7.102a.871.871 0 01-.653-.294.834.834 0 01-.2-.676c.022-.144.034-.289.035-.434 0-.936-.54-1.678-1.657-2.275a.836.836 0 01-.342-1.147.871.871 0 011.17-.334c2.108 1.128 2.55 2.666 2.55 3.756 0 .23-.019.461-.054.69a.856.856 0 01-.85.72v-.006zM25.69 33.59a.853.853 0 01-.846-.824.851.851 0 01.804-.862 53.29 53.29 0 002.113-.134.869.869 0 01.781.355.83.83 0 01.077.842.861.861 0 01-.705.485s-.845.075-2.181.138h-.043z" />
    </g>
  </SvgIcon>
)

export default ShipmentsIcon
