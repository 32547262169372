import 'mapbox-gl/dist/mapbox-gl.css'

import ReactMapGL, { Marker, Popup } from 'react-map-gl'

import Dimensions from 'react-dimensions'
import { LocationMarker } from 'components/Maps/Markers'
import LocationPopupDetail from './LocationPopupDetail'
import NavigationControlOverride from 'components/NavigationControlOverride'
import PropTypes from 'prop-types'
import React from 'react'
import VesselLocation from 'components/Maps/VesselLocation'
import VesselPopupDetail from './VesselPopupDetails'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { extractVesselTrips } from 'utils/map'
import findLast from 'lodash/findLast'
import get from 'lodash/get'
import logger from 'utils/logger'
import uniqBy from 'lodash/uniqBy'
import { withStyles } from '@material-ui/core'

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
}

const styles = theme => ({
  '@global': {
    '.mapboxgl-popup-content': {
      padding: '0px !important',
      borderRadius: `${theme.spacing(1)}px !important`,
    },
    '.mapboxgl-ctrl.mapboxgl-ctrl-attrib': {
      display: 'none',
    },
    '.mapboxgl-popup': {
      zIndex: '11 !important', // make sure it shows above container marker
    },
  },
})

class JourneyMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewport: {
        width: this.props.width,
        height: this.props.height,
        latitude: this.props.latitude,
        longitude: this.props.longitude,
        zoom: this.props.zoom,
      },
      locationPopupData: null,
      loaded: false,
      map: null,
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    transportDetail: PropTypes.object.isRequired,
  }

  static defaultProps = {
    height: 600,
    latitude: 0,
    longitude: 0,
    zoom: 1,
  }

  _updateViewport = viewport => {
    this.setState({ viewport })
  }

  setLocationPopup = popupData => () => {
    this.setState({
      locationPopupData: popupData,
      vesselPopupData: null,
    })
  }

  renderLocations = () => {
    const { locations } = this.props
    let markers = []
    let index = 0
    for (let locName in locations) {
      const location = locations[locName]
      if (location.lon && location.lat) {
        markers.push(
          <Marker key={`marker-${index}`} longitude={location.lon} latitude={location.lat}>
            <LocationMarker size={20} onClick={this.setLocationPopup({ location })} />
          </Marker>
        )
      }
      index += 1
    }
    return markers
  }

  resetPopupData = () => {
    this.setState({
      locationPopupData: null,
      vesselPopupData: null,
    })
  }

  renderPopup = () => {
    const { locationPopupData, vesselPopupData } = this.state
    if (!locationPopupData && !vesselPopupData) return null
    if (locationPopupData && vesselPopupData) {
      logger.captureAppException('Vessel and location rendered simulatenously!')
      return
    }
    const popupData = locationPopupData ? locationPopupData : vesselPopupData
    const PopupComponent = locationPopupData ? LocationPopupDetail : VesselPopupDetail
    const location = get(popupData, 'location')
    return (
      <Popup
        tipSize={5}
        anchor="top"
        offsetTop={10}
        longitude={location.lon}
        latitude={location.lat}
        closeButton={false} // don't render close button
        onClose={this.resetPopupData}
      >
        <PopupComponent popupData={popupData} onClose={this.resetPopupData} />
      </Popup>
    )
  }

  vesselSelected = vesselInfo => {
    this.setState({
      vesselPopupData: vesselInfo,
      locationPopupData: null,
    })
  }

  onLoad = () => {
    const map = this.refs.map ? this.refs.map.getMap() : null
    this.setState({
      map,
      loaded: true,
    })
  }

  render() {
    const { vesselTrips } = this.props
    const { map, loaded, viewport } = this.state
    return (
      <div data-testid="entity-page__journey-map">
        <ReactMapGL
          id={'map'}
          ref={'map'}
          {...viewport}
          worldCopyJump={true}
          width={this.props.containerWidth} // this prop is set by react-dimensions
          height={600}
          scrollZoom={false}
          onLoad={this.onLoad}
          mapStyle={'mapbox://styles/diegoclearmetal/cjeao3qom7ek82so1vv1z02ch'}
          onViewportChange={viewport => this.setState({ viewport })}
          mapboxApiAccessToken={
            'pk.eyJ1IjoiZGllZ29jbGVhcm1ldGFsIiwiYSI6ImNpeXV0bWp1ajAwMmMycXBkcGNqMzRxZ2IifQ.Hyc_GrjLpduxKQd4RdDZHw'
          }
        >
          {this.renderLocations()}
          {this.renderPopup()}
          <VesselLocation
            updateViewport={this._updateViewport}
            vesselTrips={vesselTrips}
            onSelect={this.vesselSelected}
            viewport={viewport}
            map={map}
            mapNode={this.refs.map}
            loaded={loaded}
          />
          <div className="nav" style={navStyle}>
            {/* use NavigationControlOverride or else the zoom controls won't show +/- icons! */}
            <NavigationControlOverride
              showCompass={false}
              onViewportChange={this._updateViewport}
            />
          </div>
        </ReactMapGL>
      </div>
    )
  }
}

function locationData(transportDetail) {
  let newLocations = {}
  const timelines = transportDetail?.timelines ?? []

  for (let i = 0; i < timelines.length; i++) {
    const milestones = timelines[i]?.milestones
    const lastTraced = findLast(milestones, ms => ms.tracedTime)
    const locationZone = lastTraced?.locationZone
    const isMoving = lastTraced?.action === 'depart'
    if (locationZone && !isMoving) {
      newLocations[locationZone?.name] = locationZone
    }
  }
  return newLocations
}

const createVesselTrips = transportDetail => {
  let vesselTrips = []
  const timelines = transportDetail?.timelines ?? []

  for (let i = 0; i < timelines.length; i++) {
    const milestones = timelines[i]?.milestones

    const newVesselTrips = extractVesselTrips(milestones)
    vesselTrips = vesselTrips.concat(newVesselTrips)
  }
  return uniqBy(vesselTrips, item => item.lloydsCode)
}

const transportDetailSelector = (state, props) => props.transportDetail

const locationsDataSelector = createSelector(transportDetailSelector, locationData)

const vesselTripSelector = createSelector(transportDetailSelector, createVesselTrips)

export default connect((state, props) => {
  const locations = locationsDataSelector(state, props)
  const vesselTrips = vesselTripSelector(state, props)
  return {
    locations,
    vesselTrips,
  }
}, {})(Dimensions({ elementResize: true })(withStyles(styles)(JourneyMap)))
