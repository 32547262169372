import {
  CircularProgress,
  List,
  ListItem,
  Popover,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import Button from 'components/core/Button'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import get from 'lodash/get'

const MIN_WIDTH = 130

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    height: 36,
  },
  selectedLabel: {
    fontSize: 14,
    color: theme.palette.grey[500],
    paddingLeft: '0.5em',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  button: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[500],
    minHeight: 'initial',
    height: 34,
    minWidth: MIN_WIDTH,
  },
  labelWrapper: {
    display: 'flex',
    marginTop: -1,
    color: theme.palette.grey[500],
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  listItem: {
    minWidth: MIN_WIDTH,
  },
  title: {
    marginBottom: 2,
    letterSpacing: 0.1,
    fontSize: 14,
  },
  progress: {
    color: theme.palette.grey[500],
    height: '20px !important',
    width: '20px !important',
  },
}))

interface ItemInterface {
  name: string
  value: string
}

interface Props {
  classes?: Record<string, string>
  defaultIdx: number
  defaultSelected: string | null
  handleOpen?: () => {}
  handleSelection: (item: ItemInterface, idx: number) => {}
  items: ItemInterface[]
  labelKey: string
  loading: boolean
  title: string | null
  'data-testid': string
}

function Dropdown(props: Props) {
  const {
    defaultIdx = -1,
    defaultSelected = null,
    handleOpen,
    handleSelection,
    items = [],
    labelKey = 'name',
    loading = false,
    title = null,
  } = props
  const classes = useStyles(props)
  const [selectedIdx, setSelectedIdx] = useState(defaultIdx)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  function handleClick(event: React.SyntheticEvent) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleEnter() {
    if (handleOpen) {
      handleOpen()
    }
  }

  function handleItemClick(item: ItemInterface, idx: number) {
    return () => {
      setSelectedIdx(idx)
      handleClose()
      handleSelection(item, idx)
    }
  }

  function renderTitle() {
    if (items.length === 0 && loading) {
      return <CircularProgress className={classes.progress} />
    }
    const selected = getValue(items, selectedIdx)
    return (
      <Typography variant="body2" className={classes.selectedLabel} title={selected}>
        {selected}
      </Typography>
    )
  }

  function getValue(items: ItemInterface[], idx: number) {
    const selected = get(items, `[${idx}].${labelKey}`, defaultSelected)
    return selected || 'Not selected'
  }

  // Allows for updating the selected item by simple passing a new `defaultIdx` prop.
  useEffect(() => {
    setSelectedIdx(defaultIdx)
  }, [defaultIdx])

  return (
    <div className={classes.root} data-testid={props['data-testid']}>
      {title && (
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      )}
      <div className={classes.container}>
        <Button className={classes.button} onClick={handleClick}>
          <div className={classes.labelWrapper}>
            {renderTitle()}
            <DownIcon />
          </div>
        </Button>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onEnter={handleEnter}
        onClose={handleClose}
      >
        <List>
          {items.map((item: ItemInterface, idx: number) => {
            return (
              <ListItem
                button
                dense
                onClick={handleItemClick(item, idx)}
                className={classes.listItem}
                key={`sort-item-${idx}`}
              >
                <Typography variant="body2">{getValue(items, idx)}</Typography>
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </div>
  )
}

export default Dropdown
