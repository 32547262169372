import { TripTimelineItem } from 'utils/sharedObjs'

const getSnapshot = routes => {
  return routes.map(route => {
    const lastIdx = route.latestTracedIdx
    if (lastIdx !== null && lastIdx < route.milestones.length) {
      let mostRecentLeg = route.milestones[lastIdx]
      // if (mostRecentLeg && mostRecentLeg.type === 'vessel') {
      //   mostRecentLeg = getLastLocationFromLegs(route.milestones, lastIdx)
      // }
      return mostRecentLeg
    }
    return new TripTimelineItem()
  })
}

export default getSnapshot
