import React, { useEffect } from 'react'

import DashboardScorecard from '../DashboardScorecard'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchTotalContainerCountStatus } from 'store/userDashboard/actions'
import { formatPercentage } from 'utils/strUtils'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import { muiBreakpointLg } from '../utils/muiBreakpointOverrides'
import { totalContainerCountStatusSelector } from 'store/userDashboard'
import { withStyles } from '@material-ui/core'

const styles = theme => {
  theme = merge(theme, muiBreakpointLg)
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
      },
    },
  }
}

const ContainerStatusScorecards = ({ classes, fetchData, totalContainerCountStatus, theme }) => {
  useEffect(() => {
    fetchData()
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoading, isFailed, data } = totalContainerCountStatus

  const scorecardConfig = {
    delayed: {
      color: theme.palette.datavis.orange[400],
      left: {
        label: 'Delayed Containers',
      },
      right: {
        label: 'Container Count',
      },
    },
    onTime: {
      color: theme.palette.datavis.green[600],
      left: {
        label: 'On Schedule Containers',
      },
      right: {
        label: 'Container Count',
      },
    },
    early: {
      color: theme.palette.datavis.yellow[500],
      left: {
        label: 'Early Containers',
      },
      right: {
        label: 'Container Count',
      },
    },
  }

  const constructScorecardData = () => {
    return ['delayed', 'onTime', 'early'].map(type => {
      const leftValue =
        isEmpty(data) || isNaN(data[type].percentage)
          ? formatPercentage(0, 1)
          : formatPercentage(data[type].percentage, 1)
      const rightValue = isEmpty(data) ? 0 : data[type].count.toLocaleString()
      const color = isEmpty(data) ? theme.palette.grey[100] : scorecardConfig[type].color
      return {
        ...scorecardConfig[type],
        key: type,
        left: {
          ...scorecardConfig[type].left,
          value: leftValue,
        },
        right: {
          ...scorecardConfig[type].right,
          value: rightValue,
        },
        color,
      }
    })
  }

  return (
    <div className={classes.container} data-testid="user-dashboard__container-status-scorecards">
      {constructScorecardData().map(props => (
        <DashboardScorecard {...props} isLoading={isLoading} isFailed={isFailed} />
      ))}
    </div>
  )
}

const CountType = PropTypes.shape({
  count: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
})

ContainerStatusScorecards.propTypes = {
  classes: PropTypes.object,
  totalContainerCountStatus: PropTypes.shape({
    data: PropTypes.shape({
      delayed: CountType,
      early: CountType,
      onTime: CountType,
    }),
    isLoading: PropTypes.bool,
    isFailed: PropTypes.bool,
  }),
}

const mapStateToProps = state => ({
  totalContainerCountStatus: totalContainerCountStatusSelector(state),
})

const mapDispatchToProps = {
  fetchData: fetchTotalContainerCountStatus,
}

const styledContainerStatusScorecards = withStyles(styles, { withTheme: true })(
  ContainerStatusScorecards
)
export { styledContainerStatusScorecards as ContainerStatusScorecards }
export default connect(mapStateToProps, mapDispatchToProps)(styledContainerStatusScorecards)
