import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'

import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      '2px 3px 5px -4px rgba(0,0,0,0.2), 2px 3px 6px -2px rgba(0,0,0,0.14), 2px 2px 9px -10px rgba(0,0,0,0.12)',
  },
  table: {},
  lastRowNoBorder: {
    '& tr:last-child td': {
      borderBottom: 0,
    },
  },
  tableHeaderRow: {
    ...theme.typography.body2,
    background: theme.palette.custom['grey25'],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    fontWeight: theme.typography.fontWeightMedium,
  },
  tableFooterRow: {},
  columnHeader: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

interface Props {
  classes?: { [key: string]: string }
  columnHeaderData?: React.ReactNode[]
  rowData: React.ReactNode[][]
  tableFooterRow?: React.ReactNode
  tableHeaderRow?: React.ReactNode
}

/**
 * In order to keep this table as simple and flexible as possible it has minimal styling and mostly just loops
 * provided data to populate the table. You can pass any kind of data but the power comes from passing in
 * `ReactElement`s, which means you can use any markup and styling/classnames you want when calling. For now
 * the component does not check if you provide an equal number of column headers and row column data, but the
 * table will not look correct if you don't. It's an easy check to add if necessary.
 * @prop columnHeaderData optional array of values to be displayed as column headers
 * @prop rowData required array of arrays of row data.
 *  So two rows of data for table with three columns: `[ [1,2,3], [4,5,6] ]`
 * @prop tableFooterRow optional, not part of the table but the full-width "footer" that sits below the table
 * @prop tableHeaderRow optional, not part of the table but the full-width "header" that sits above the table
 */
const BasicTable = (props: Props) => {
  const classes = useStyles(props)
  const { columnHeaderData = [], tableFooterRow, tableHeaderRow, rowData = [] } = props

  if (!rowData || rowData.length === 0) {
    return null
  }

  return (
    <Box mb={3} className={classes.root}>
      {tableHeaderRow && (
        <Box px={4} py={2} className={classes.tableHeaderRow}>
          {tableHeaderRow}
        </Box>
      )}
      <Box px={2}>
        <TableContainer>
          <Table
            className={classnames(classes.table, {
              [classes.lastRowNoBorder]: !tableFooterRow,
            })}
          >
            <TableHead>
              <TableRow>
                {columnHeaderData.length &&
                  columnHeaderData.map((header: React.ReactNode, idx: number) => {
                    return (
                      <TableCell key={`column${idx}`} component="th">
                        <Typography className={classes.columnHeader} variant="body2">
                          {header}
                        </Typography>
                      </TableCell>
                    )
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.map((row: any, idx: number) => (
                <TableRow key={`data-row${idx}`}>
                  {row.map((cell: any, idx: number) => {
                    return <TableCell key={`cell-${idx}`}>{cell ?? ''}</TableCell>
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {tableFooterRow && (
        <Box px={4} py={2} className={classes.tableFooterRow}>
          {tableFooterRow}
        </Box>
      )}
    </Box>
  )
}

export default BasicTable
