import 'react-select/dist/react-select.css'

import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import client from 'utils/api/client'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  container: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    height: 200,
    width: 200,
  },
  chip: {
    margin: theme.spacing(1),
  },
  smallVal: {
    fontSize: 10,
    marginLeft: 3,
  },
  '@global': {
    '.Select-value': {
      color: 'white !important',
      borderRadius: '5px !important',
      border: 'none !important',
    },
    '.Select-value-icon': {
      paddingBottom: '1px !important',
      borderRight: '1px solid rgb(250, 250, 250) !important',
    },
    '.Select-value-icon:hover': {
      color: 'white !important',
    },
    '.Select--multi .Select-value': {
      backgroundColor: '#ebedf8 !important',
      color: `${theme.palette.grey[800]} !important`,
      letterSpacing: 0.1,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: 13,
    },
    '.Select--multi .Select-multi-value-wrapper': {
      maxHeight: '30vh',
      overflowY: 'auto',
      width: '100%',
    },
    '.Select-option': {
      letterSpacing: 0.1,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: 13,
    },
    '.Select-option.is-selected': {
      backgroundColor: 'rgb(230, 230, 230)',
    },
    '.Select-placeholder': {
      fontSize: 14,
    },
  },
})

class MultiSelectAutocomplete extends React.Component {
  displayName = 'MultiSelectAutocomplete'
  static propTypes = {
    classes: PropTypes.object.isRequired,
    lookupPath: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    maxHeight: PropTypes.number,
    showValueInOptions: PropTypes.bool,
    formatOption: PropTypes.func,
    defaultValue: PropTypes.array,
  }

  static defaultProps = {
    placeholder: 'Search',
    showValueInOptions: false,
    defaultValue: [],
    formatOption: option => option,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue,
    }
  }

  onChange = value => {
    this.setState({ value }, () => {
      this.props.onChange(value)
    })
  }

  handleInputValueChange = (inputValue, callback) => {
    const { lookupPath, suggestType } = this.props
    client
      .get(`${lookupPath}?suggest_type=${suggestType}&q=${inputValue}`)
      .then(function (response) {
        callback(null, { options: response.data })
      })
      .catch(function (error) {
        callback(error, null)
      })
  }

  renderOption = option => {
    const { showValueInOptions, classes, formatOption } = this.props
    let label = option.label
    if (label) {
      label = formatOption(label)
    } else {
      label = 'N/A'
    }

    if (showValueInOptions) {
      return (
        <div>
          {label} <span className={classes.smallVal}>[{option.value}]</span>
        </div>
      )
    } else {
      return <div>{label}</div>
    }
  }

  renderValue = option => {
    const { formatOption } = this.props
    let label = option.label
    if (label) {
      label = formatOption(label)
    } else {
      label = option.value
    }

    return <div>{label}</div>
  }

  render() {
    const { value } = this.state
    const { classes, placeholder } = this.props
    return (
      <div className={classes.container}>
        <Select.Async
          multi
          value={value}
          onChange={this.onChange}
          valueKey="label"
          labelKey="label"
          optionRenderer={this.renderOption}
          valueRenderer={this.renderValue}
          loadOptions={this.handleInputValueChange}
          placeholder={placeholder}
          filterOptions={options => options}
          menuContainerStyle={{ maxHeight: this.props.maxHeight }}
          menuStyle={{ maxHeight: this.props.maxHeight }}
        />
      </div>
    )
  }
}

export default withStyles(styles)(MultiSelectAutocomplete)
