import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'

import { LOGIN_ROUTE } from 'utils/routes'
import MESSAGES from 'store/notifications/messages'
import api from '../api'
import browserHistory from 'utils/history'
import logger from 'utils/logger'
import { types } from '../actions'

export function* passwordResetEmailAsync(action) {
  try {
    const email = action.payload

    yield call(api.resetPassword, { email })
    yield put({ type: types.PASSWORD_RESET_EMAIL_SUCCESS })
    yield put(
      showNotification(MESSAGES.passwordResetEmailSuccess, {
        type: MESSAGE_TYPES.SUCCESS,
      })
    )
    yield browserHistory.push(LOGIN_ROUTE.buildUrl())
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error resetting password: ${e}`, 'error')
    yield put(
      showNotification(MESSAGES.passwordResetEmailError, {
        type: MESSAGE_TYPES.WARNING,
      })
    )
  }
}

export function* watchPasswordResetEmail() {
  yield takeLatest(types.PASSWORD_RESET_EMAIL_LOAD, passwordResetEmailAsync)
}
