import { call, put, takeLatest } from 'redux-saga/effects'

import { AssociatedReferences } from 'store/associatedReferences/models'
import { PayloadInterface } from 'store/associatedReferences/interfaces'
import api from 'store/associatedReferences/api'
import logger from 'utils/logger'
import { types } from 'store/associatedReferences/actions'

interface FetchActionInterface {
  type: 'associatedReferences/FETCH_ASSOCIATED_REFERENCES_START'
  payload: PayloadInterface
}

/**
 * Retrieves associated references based on `refType` and `externalId`.
 * @param action object
 * { type: FETCH_ASSOCIATED_REFERENCES_SUCCESS, payload: { refType: <refType> externalId: <ExternalId> }}
 */
export function* fetchAssociatedReferencesSaga(action: FetchActionInterface) {
  try {
    const res = yield call(api.fetchAssociatedReferences, action.payload)
    const data = res && res.data && AssociatedReferences.of(res.data)
    yield put({ type: types.FETCH_ASSOCIATED_REFERENCES_SUCCESS, payload: data })
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.FETCH_ASSOCIATED_REFERENCES_FAILURE, payload: { error: err.message } })
  }
}

export const sagas = [
  takeLatest(types.FETCH_ASSOCIATED_REFERENCES_START, fetchAssociatedReferencesSaga),
]
