import { PublicFeatureFlagsInterface } from '../interfaces'
import humps from 'humps'

export default class PublicFeatureFlags {
  constructor({ ssoLogin }: PublicFeatureFlagsInterface) {
    Object.assign(this, {
      ssoLogin,
    })
  }

  static of(payload: object) {
    const { ssoLogin } = humps.camelizeKeys(payload) as PublicFeatureFlagsInterface

    return new PublicFeatureFlags({
      ssoLogin,
    })
  }
}
