import humps from 'humps'

export interface ExceptionInterface {
  exceptionType: string
  occurred: string
  occurredAt: string | null
}

export default class Exception {
  constructor({ exceptionType, occurred, occurredAt }: ExceptionInterface) {
    Object.assign(this, { exceptionType, occurred, occurredAt })
  }

  static of(payload: object) {
    const { exceptionType, occurred, occurredAt } = humps.camelizeKeys(
      payload
    ) as ExceptionInterface
    return new Exception({ exceptionType, occurred, occurredAt })
  }
}
