import { ALERT_CONFIG_SAVE_SUCCESS, saveAlert } from 'store/api/alertConfigs'
import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { REF_TYPE_PARAM_NAME, TRANSPORTATION_STATUS_PARAM_NAME } from 'utils/querystring'
import { ROLLUP_CONTAINER, ROLLUP_LINE_ITEM, getFirstRollup } from 'utils/rollups'
import { TransportationStatusOptions, filterEnhancedReducer } from 'store/boardUtils'
import { all, call, put, putResolve, select, take } from 'redux-saga/effects'
import { convertFieldsToFilters, getParamsFromFilters } from 'utils/filterUtils'
import {
  currentViewSelector,
  filtersSelector,
  getDefaultAndCustomizedFilters,
  rollupSelector,
  sortStateSelector,
  transportationStatusSelector,
  viewsSelector,
} from 'store/boardUtils/selectors'
import {
  fetchViews,
  selectView,
  setRollup,
  setTransportationStatus,
  toggleImportDialog,
  types,
} from 'store/boardUtils/actions'
import { getShipments, resetShipmentApiState } from 'store/board/actions'

import { BOARD_ROUTE } from 'utils/routes'
import CONFIRM_MESSAGES from 'store/confirmation/messages'
import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import browserHistory from 'utils/history'
import client from 'utils/api/client'
import { confirmSaga } from 'store/confirmation'
import { defaultShipmentFilters } from 'store/boardUtils/utils/shipmentFilters'
import { encodeFilters } from 'store/filterFactory'
import find from 'lodash/find'
import get from 'lodash/get'
import { getQueryStringValue } from 'utils/urlBuilder'
import logger from 'utils/logger'
import { setQueryStringParam } from 'utils/urlBuilder'

export function* fetchViewsAsync() {
  try {
    const res = yield call(client.get, '/shipment_view')
    yield put({ type: types.FETCH_VIEWS_SUCCESS, payload: res.data })
  } catch (err) {
    logger.captureAPIException(err)
    yield put(showNotification(err.message, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* selectViewAsync({ payload }) {
  const fields = get(payload, 'fields', [])
  const customAndDefaults = yield select(getDefaultAndCustomizedFilters)
  const filters = convertFieldsToFilters(fields, customAndDefaults)

  setQueryStringParam(REF_TYPE_PARAM_NAME, payload.rollup)
  yield put(setRollup(payload.rollup))
  yield put(filterEnhancedReducer.actions.setFilters(filters))
  yield put({ type: types.SELECT_VIEW_SUCCESS, payload })
}

export function* clearViewAsync({ payload }) {
  const rollup = yield select(rollupSelector)
  yield put({ type: types.CLEAR_VIEW_SUCCESS, payload: null })
  yield put(filterEnhancedReducer.actions.clearAllFilters())
  const filters = {}
  yield put(getShipments('', rollup || ROLLUP_LINE_ITEM, filters, 0, true))
}

export function* createViewAsync({ payload }) {
  try {
    const { name, filters, createAlert } = payload
    const rollup = yield select(rollupSelector)
    let fields = []
    filters.forEach(f => {
      let field = {
        key: f.name,
        value: f.value,
        url_params: f.filterConfig.filterData.getUrlParam(f.value),
      }
      fields.push(field)
    })

    const res = yield call(client.post, '/shipment_view', {
      name,
      rollup,
      fields,
    })

    if (createAlert) {
      const id = get(res.data, 'id')
      const alertConfig = {
        name,
        shipmentViewId: id,
        payload: { rollup },
      }
      const notify = false
      const navigate = false
      yield put(saveAlert(alertConfig, notify, navigate))
      yield take(ALERT_CONFIG_SAVE_SUCCESS) // wait for saga to finish saving container
      yield put(
        showNotification(NOTIFICATION_MESSAGES.createFilterWithAlertSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
    } else {
      yield put(
        showNotification(NOTIFICATION_MESSAGES.createFilterSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
    }
    yield put({ type: types.CREATE_VIEW_SUCCESS })

    // reload views (because alert_config_ids will get populated by backend)
    yield put(fetchViews())
    yield take(types.FETCH_VIEWS_SUCCESS) // wait for saga to finish fetching views

    const updatedViews = yield select(viewsSelector)
    const matchingView = find(updatedViews, view => view.id === get(res.data, 'id'))
    yield put(selectView(matchingView))
  } catch (err) {
    logger.captureAPIException(err)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.createFilterError, { type: MESSAGE_TYPES.ERROR })
    )
  }
}

export function* updateViewAsync({ payload }) {
  try {
    const { name, filters, id, createAlert, reselectViewAfterUpdate } = payload
    const rollup = yield select(rollupSelector)
    let fields = []
    filters.forEach(f => {
      let field = {
        key: f.name,
        value: f.value,
        url_params: f.filterConfig.filterData.getUrlParam(f.value),
      }
      fields.push(field)
    })
    const res = yield call(client.put, '/shipment_view', {
      name,
      rollup,
      id,
      fields,
    })
    if (createAlert) {
      const id = get(res.data, 'id')
      const alertConfig = {
        name,
        shipmentViewId: id,
        payload: { rollup },
      }
      const notify = false
      const navigate = false
      yield put(saveAlert(alertConfig, notify, navigate))
      yield take(ALERT_CONFIG_SAVE_SUCCESS) // wait for saga to finish saving container
      yield put(
        showNotification(NOTIFICATION_MESSAGES.updateFilterWithAlertSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
    } else {
      yield put(
        showNotification(NOTIFICATION_MESSAGES.updateFilterSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
    }
    yield put({ type: types.UPDATE_VIEW_SUCCESS })

    // reload views (because alert_config_ids will get populated by backend)
    yield put(fetchViews())
    yield take(types.FETCH_VIEWS_SUCCESS) // wait for saga to finish fetching views

    if (reselectViewAfterUpdate) {
      const updatedViews = yield select(viewsSelector)
      const matchingView = find(updatedViews, view => view.id === get(res.data, 'id'))
      yield put(selectView(matchingView))
    }
  } catch (err) {
    logger.captureAPIException(err)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.updateFilterError, { type: MESSAGE_TYPES.ERROR })
    )
  }
}

export function* deleteViewAsync({ payload }) {
  try {
    const currentView = yield select(currentViewSelector)
    const allViews = yield select(viewsSelector)
    const matchingView = find(allViews, view => view.id === payload.id)
    const alertConfigIds = get(matchingView, 'alert_config_ids', [])
    const confirmationConfig =
      alertConfigIds.length > 0
        ? CONFIRM_MESSAGES.deleteFilterWithAlert(payload.name)
        : CONFIRM_MESSAGES.deleteFilter(payload.name)
    // Show confirmation dialog before performing delete operation
    const confirmed = yield call(confirmSaga, confirmationConfig)
    if (!confirmed) {
      return
    }
    const params = {
      shipment_view_id: payload.id,
    }

    const rollup = getFirstRollup()

    yield call(client.delete, '/shipment_view', { params })
    yield put({ type: types.DELETE_VIEW_SUCCESS, payload })
    if (currentView && currentView.id === payload) {
      yield put(getShipments('', rollup, {}, 0, true))
    }
    const deleteText =
      alertConfigIds.length > 0
        ? NOTIFICATION_MESSAGES.deleteFilterWithAlertSuccess(payload.name)
        : NOTIFICATION_MESSAGES.deleteFilterSuccess(payload.name)
    yield put(showNotification(deleteText, { type: MESSAGE_TYPES.INFO }))
  } catch (err) {
    const message = NOTIFICATION_MESSAGES.deleteFilterError(payload.name)
    yield put(showNotification(message, { type: MESSAGE_TYPES.ERROR }))
    logger.captureAPIException(err)
    logger.localLog(err, 'error')
  }
}

export function* reloadDataAsync() {
  const sortState = yield select(sortStateSelector)
  const transportationStatus = yield select(transportationStatusSelector)
  const filters = yield select(filtersSelector)
  const params = getParamsFromFilters(filters)
  const rollup = yield select(rollupSelector)
  yield putResolve(resetShipmentApiState())
  // Tip: you can comment out the following line to keep the table permanently in loading, empty state which is helpful
  // for debugging/developing/discussing loading behavior
  yield put(getShipments('', rollup, params, 0, sortState, transportationStatus))
}

export function* importReferencesAsync({ payload }) {
  try {
    const { items, rollup } = payload
    const filterConfig = find(defaultShipmentFilters, ['name', rollup])
    // Users can select a ref type in the import dialog that is different than what we are
    // currently on, so we need to update the query string as well as set state
    setQueryStringParam(REF_TYPE_PARAM_NAME, rollup)
    yield put(setRollup(rollup))
    yield put(filterEnhancedReducer.actions.addFilter(filterConfig, items))
    yield put(toggleImportDialog())
  } catch (err) {
    logger.captureAPIException(err)
    logger.localLog(err, 'error')
  }
}

export function* setInitialRollupAsync({ payload }) {
  try {
    const querystringEntityType = getQueryStringValue(REF_TYPE_PARAM_NAME)
    const entityType = querystringEntityType ?? payload
    yield put({ type: types.SET_INITIAL_ROLLUP_SUCCESS, payload: entityType })
  } catch (err) {
    logger.captureAPIException(err)
    logger.localLog(err, 'error')
  }
}

export function* setRollupAsync({ payload }) {
  try {
    yield put({ type: types.SET_ROLLUP_SUCCESS, payload })
  } catch (err) {
    logger.captureAPIException(err)
    logger.localLog(err, 'error')
  }
}

/**
 * Used to navigate to the board with a query string for `filters`, `ref_type` and `transportation_status` to handle the "View as filtered list" link on entity pages (`ContainerTable` component). The reason for this is to avoid all the actions that needed to be dispatched when called `history.push` directly in the component, which was causing rendering churn due to action side effects. Also, this solves the previous behavior where ref type, transportation status and filters were each pushed onto the query string one at a time, meaning you had to use the back button three times to undo all of them; here, it is a single push and so the back button works as expected.
 * @param payload object { containerFilter, containerNumbers}
 */
export function* setFilteredListAsync({ payload }) {
  try {
    const boardUrl = BOARD_ROUTE.buildUrl()
    // Grab what we need from the payload and pass as an array to get back encoded filters
    const name = payload.containerFilter.name
    const value = payload.containerNumbers
    const encoded = encodeFilters([
      {
        name,
        value,
      },
    ])
    // Construct the url. This will handle setting the rollup, setting transportation status,
    // and `filters` in the querystring.
    const url = `${boardUrl}?${REF_TYPE_PARAM_NAME}=${ROLLUP_CONTAINER}&${TRANSPORTATION_STATUS_PARAM_NAME}=${TransportationStatusOptions.VIEW_ALL.toLowerCase()}&filters=${encoded}`
    yield browserHistory.push(url)
    // Update state so when RESET_SHIPMENTS kicks off it will pull the correct values for API requests
    yield all([
      put(setRollup(ROLLUP_CONTAINER)),
      put(setTransportationStatus(TransportationStatusOptions.VIEW_ALL)),
    ])
    // No reducer needed for success, emitting to help with debugging, etc.
    yield put({ type: types.SET_FILTERED_LIST_SUCCESS })
  } catch (err) {
    logger.captureAPIException(err)
    logger.localLog(err, 'error')
  }
}
