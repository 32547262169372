import { createAction, handleActions } from 'redux-actions'
import { put, race, take } from 'redux-saga/effects'

// Action Types
export const SHOW_CONFIRMATION = 'confirmation/SHOW_CONFIRMATION'
export const HIDE_CONFIRMATION = 'confirmation/HIDE_CONFIRMATION'
export const CONFIRMATION_YES = 'confirmation/CONFIRMATION_YES'
export const CONFIRMATION_NO = 'confirmation/CONFIRMATION_NO'

// Action Creators
export const showConfirmation = createAction(SHOW_CONFIRMATION, props => props)

export const confirmAction = createAction(CONFIRMATION_YES)
export const cancelAction = createAction(CONFIRMATION_NO)

export const hideConfirmation = createAction(HIDE_CONFIRMATION)

// Initial State
const initialState = {
  isOpen: false,
  isDestructive: false,
  confirmText: 'Confirm',
  message: '',
  title: '',
}

// Reducer
export default handleActions(
  {
    [SHOW_CONFIRMATION]: (state, { type, payload }) => {
      return { ...state, isOpen: true, ...payload }
    },
    [HIDE_CONFIRMATION]: () => initialState,
  },
  initialState
)

// Operations

export function* confirmSaga(props) {
  yield put(showConfirmation(props))

  const { yes } = yield race({
    yes: take(CONFIRMATION_YES),
    no: take(CONFIRMATION_NO),
  })

  yield put(hideConfirmation())

  return !!yes
}
