import * as Yup from 'yup'

import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { MESSAGE_TYPES, showNotification } from 'store/notifications/index'
import { ROLLUPS, getRollupLabel } from 'utils/rollups'
import React, { useEffect, useRef, useState } from 'react'
import { defaultImportDialogValuesSelector, rollupSelector } from 'store/boardUtils/selectors'
import { useDispatch, useSelector } from 'react-redux'

import { API_DELIMITER } from 'utils/filterUtils'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CancelIcon from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import ContentCopyIcon from 'components/icons/ContentCopyIcon'
import DoneIcon from '@material-ui/icons/Done'
import MESSAGES from 'store/notifications/messages'
import PropTypes from 'prop-types'
import { ROLLUP_LINE_ITEM } from 'utils/rollups/index'
import client from 'utils/api/client'
import copy from 'copy-to-clipboard'
import cx from 'classnames'
import humps from 'humps'
import { importReferences } from 'store/boardUtils/actions'
import isEqual from 'lodash/isEqual'
import logger from 'utils/logger'
import parseChipValues from './utils/parseChipValues'
import { useFormik } from 'formik'

const FORM_ID = 'importReferences'
const MAX_CHIP_COUNT = 500

const useStyles = makeStyles(theme => ({
  autocompleteRoot: {
    maxHeight: 400,
    overflow: 'auto',
  },
  dialogPaper: {
    maxWidth: 800,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: {
    display: 'flex',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  header: {
    display: 'inline-block',
    flexGrow: '1',
    margin: 'auto',
  },
  rollupField: {
    minWidth: theme.spacing(25),
    marginBottom: theme.spacing(3),
  },
  chipError: {
    color: theme.palette.red[400],
  },
}))

ImportReferencesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

function ImportReferencesDialog({ isOpen, onClose }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [clickedChipIndex, setClickedChipIndex] = useState(null)
  const [invalidChips, setInvalidChips] = useState([])
  const [validatingChips, setValidatingChips] = useState(false)
  // `appliedFilterRollup` is set when clicking on an applied filter in the drawer
  const { rollup: appliedFilterRollup, items: defaultChips } = useSelector(
    defaultImportDialogValuesSelector
  )
  // Get the rollup currently selected on the board page
  const selectedBoardRollup = useSelector(rollupSelector)
  const textfieldScrollRef = useRef(null)
  // Cache the `refNumbers` API param so we can compare. `useRef` changes do not trigger a render
  const apiRefNumbersCache = useRef(null)

  const formatLineItemChip = string => (string.indexOf('-') < 0 ? `${string}-1` : string)

  const initialValues = {
    chips: defaultChips,
    lineItemChips: defaultChips.map(chip => formatLineItemChip(chip)),
    rollup: appliedFilterRollup || selectedBoardRollup,
    input: '',
  }

  const {
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    setValues,
    values,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      rollup: Yup.string().label('Reference').required(),
      chips: Yup.array()
        .label('Chip')
        .test('validate-chips', function (chips) {
          setValidatingChips(true)
          const rollup = this.parent.rollup // Parent references most up to date form value

          const chipsUnderValidation =
            rollup === ROLLUP_LINE_ITEM ? this.parent.lineItemChips : chips

          if (chipsUnderValidation.length === 0) {
            setValidatingChips(false)
            return true
          }

          // Prevent API from being hit with more than 500
          if (chipsUnderValidation.length > 500) {
            setValidatingChips(false)
            const errorMessage = `You are trying to import ${chipsUnderValidation.length} but our limit is ${MAX_CHIP_COUNT}`

            dispatch(showNotification(errorMessage, { type: MESSAGE_TYPES.ERROR }))
            // basically a reset but we want to keep the selected rollup
            setValues({
              ...values,
              chips: [],
              lineItemChips: [],
              input: '',
            })
            return false
          }

          // Remove dupes for API calls
          const uniqueVals = [...new Set(chipsUnderValidation)]

          // Create the API param
          const refNumbers = uniqueVals.join(API_DELIMITER)

          // Compare our cached `apiRefNumbersCache` with `refNumbers` so we don't needlessly call the API
          if (isEqual(apiRefNumbersCache.current, refNumbers)) {
            setValidatingChips(false)
            // If we're stopping here we need to keep any errors we were showing. I can't find a way to do that
            // other than creating them again. I was hoping we could just `return errors` but no.
            if (errors.chips) {
              return this.createError({
                message: errors.chips,
                path: 'chips',
              })
            }
            return true
          }

          return client
            .post(
              '/shipments/validate',
              humps.decamelizeKeys({
                refType: rollup,
                refNumbers: refNumbers,
              })
            )
            .then(res => {
              // Update our cache
              apiRefNumbersCache.current = refNumbers
              const data = humps.camelizeKeys(res.data)
              const invalidChips = data.refs
                .filter(ref => ref.isValid === false)
                .map(val => val.refNumber)
              const isValid = invalidChips.length === 0
              setInvalidChips(invalidChips)
              setValidatingChips(false)
              return isValid
                ? true
                : this.createError({
                    message:
                      "We're unable to find the items in red. Remove them to continue, or try entering them in again.",
                    path: 'chips',
                  })
            })
            .catch(error => {
              logger.captureAPIException(error)
              logger.localLog(`Error calling API endpoint: ${error}`, 'error')
              setInvalidChips([...this.parent.lineItemChips, ...chips])
              setValidatingChips(false)
              return this.createError({
                message: `Server Error: ${error.message}`,
                path: 'chips',
              })
            })
        }),
    }),
    onSubmit: values => {
      dispatch(
        importReferences({
          rollup: values.rollup,
          items: values.rollup === ROLLUP_LINE_ITEM ? values.lineItemChips : values.chips,
        })
      )
      resetForm(initialValues)
    },
  })

  // Clear cache on reftype change
  useEffect(() => {
    apiRefNumbersCache.current = null
  }, [values.rollup])

  // A hooks version of `componentDidUpdate` so we can scroll the textfield on update
  const didUpdateRef = useRef(false)
  useEffect(() => {
    if (didUpdateRef.current && textfieldScrollRef && textfieldScrollRef.current) {
      textfieldScrollRef.current.scrollIntoView(false)
    } else {
      didUpdateRef.current = true
    }
  })

  useEffect(() => {
    resetForm({
      ...values,
      chips: defaultChips,
      lineItemChips: defaultChips.map(chip => formatLineItemChip(chip)),
      rollup: appliedFilterRollup || selectedBoardRollup,
    })
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultChips, appliedFilterRollup, selectedBoardRollup])

  const rollupLabel = getRollupLabel(values.rollup)
    ? getRollupLabel(values.rollup).toLowerCase()
    : 'items'

  const addChips = string => {
    if (!string) {
      return
    }
    // @todo `string` can be an object or a plain string, probably not ideal
    const chipValue = string.label || string

    setValues({
      ...values,
      chips: [...values.chips, ...parseChipValues(chipValue)],
      lineItemChips: [
        ...values.lineItemChips,
        ...parseChipValues(chipValue).map(chip => formatLineItemChip(chip)),
      ],
      input: '',
    })
  }

  const handleModalClose = () => {
    setInvalidChips([])
    apiRefNumbersCache.current = null
    onClose()
    resetForm(initialValues)
  }

  const getChipConfig = chips => {
    const getIsValid = chip => !invalidChips.includes(chip)

    const getIcon = chip => {
      const valid = getIsValid(chip)
      if (validatingChips) {
        return (
          <Avatar>
            <CircularProgress />
          </Avatar>
        )
      } else if (valid) {
        return (
          <Avatar>
            <DoneIcon />
          </Avatar>
        )
      } else {
        return null
      }
    }

    return chips.map(chip => ({
      label: chip,
      valid: getIsValid(chip),
      icon: getIcon(chip),
    }))
  }

  const resetValues = () => setValues({ ...values, chips: [], lineItemChips: [] })

  const autocompleteOnChange = (event, newValue, reason, details) => {
    // Backspace
    if (reason === 'remove-option') {
      return newValue.length > 0
        ? newValue.forEach(val => {
            const chipValue = details.option.label

            return setValues({
              ...values,
              chips: values.chips.filter(chip => chip !== chipValue),
              lineItemChips: values.chips.filter(chip => chip !== chipValue),
            })
          })
        : setValues({ ...values, chips: [], lineItemChips: [] })
    }

    // Otherwise assume we are adding a chip
    return newValue.length > 0
      ? newValue.forEach(val => {
          addChips(val)
        })
      : resetValues()
  }

  const hasValidChips =
    values.rollup === ROLLUP_LINE_ITEM
      ? values.lineItemChips.some(chip => !invalidChips.includes(chip))
      : values.chips.some(chip => !invalidChips.includes(chip))

  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      onClose={handleModalClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="share-dialog-title" className={classes.dialogTitle} disableTypography>
        <Typography className={classes.header} variant="h6">
          Filter by Specific References
        </Typography>
        <IconButton onClick={handleModalClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={handleSubmit}>
          <DialogContentText>
            Select the types of references you wish to filter on:
          </DialogContentText>
          <FormControl className={classes.rollupField} variant="filled">
            <InputLabel id="rollupSelect">References</InputLabel>
            <Select
              labelId="rollupSelect"
              onChange={handleChange}
              value={values.rollup}
              name="rollup"
            >
              {ROLLUPS.map(rollup => (
                <MenuItem key={`rollup-choice-${rollup}`} value={rollup}>
                  {getRollupLabel(rollup)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogContentText>
            {`Add your ${rollupLabel} to the field below. You can enter multiple numbers in at one time, but they must be comma, space, newline, or semicolon separated.`}
          </DialogContentText>
          <Autocomplete
            classes={{ root: classes.autocompleteRoot }}
            disablePortal
            multiple
            freeSolo
            id="tags-outlined"
            closeIcon={<CancelIcon />}
            clearText={'Clear Field'}
            options={[]}
            inputValue={values.input}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'input') {
                setValues({ ...values, input: newInputValue })
              }
            }}
            getOptionLabel={option => option.title || option}
            value={
              values.rollup === ROLLUP_LINE_ITEM
                ? getChipConfig(values.lineItemChips)
                : getChipConfig(values.chips)
            }
            onChange={autocompleteOnChange}
            renderTags={(props, getTagProps) =>
              props.map((config, index) => {
                return (
                  <Chip
                    {...getTagProps({})}
                    variant="outlined"
                    classes={{ root: cx({ [classes.chipError]: !config.valid }) }}
                    key={index}
                    onClick={() => {
                      const clickedChip =
                        values.rollup === ROLLUP_LINE_ITEM
                          ? values.lineItemChips[index]
                          : values.chips[index]

                      // One click to-edit-if-invalid or Edit on second click if valid
                      if (invalidChips.includes(clickedChip) || clickedChipIndex === index) {
                        setValues({
                          ...values,
                          chips: values.chips.filter((_, idx) => idx !== index),
                          lineItemChips: values.lineItemChips.filter((_, idx) => idx !== index),
                          input: clickedChip,
                        })
                      } else {
                        setClickedChipIndex(index)
                      }
                    }}
                    onDelete={e => {
                      setValues({
                        ...values,
                        chips: values.chips.filter((_, idx) => idx !== index),
                        lineItemChips: values.chips.filter((_, idx) => idx !== index),
                      })
                      setInvalidChips(invalidChips.filter(chip => chip !== values.chips[index]))
                    }}
                    label={config.label}
                    avatar={config.icon}
                  />
                )
              })
            }
            renderInput={params => {
              params.inputProps.onKeyUp = function (event) {
                const enteredVal = values.input
                const triggerChars = [',', ' ', ';']
                // @todo not handling the `paste` event (right mouse click + paste)
                // The first test is based on keyup being one of our trigger chars
                // The second test examines the value for one of our trigger chars, good for pasting values
                if (
                  triggerChars.includes(event.key) ||
                  triggerChars.some(char => enteredVal.includes(char))
                ) {
                  event.preventDefault()
                  event.stopPropagation()
                  if (enteredVal.length > 0) {
                    addChips(enteredVal)
                  }
                } else {
                  return event
                }
              }

              return (
                <TextField
                  ref={textfieldScrollRef}
                  {...params}
                  error={Boolean(errors.chips)}
                  helperText={errors.chips}
                  onBlur={event => {
                    addChips(event.target.value)
                  }}
                  variant="filled"
                  label={`Input your ${rollupLabel} here...`}
                  fullWidth
                />
              )
            }}
          />
        </form>
      </DialogContent>
      <DialogActions className={cx({ [classes.dialogActions]: values.chips.length > 0 })}>
        {values.chips.length > 0 && (
          <Button
            type="button"
            onClick={() => {
              const chipsToCopy =
                values.rollup === ROLLUP_LINE_ITEM ? values.lineItemChips : values.chips

              const res = copy(chipsToCopy.join(', '))
              res
                ? dispatch(showNotification(MESSAGES.importReferencesCopySuccess))
                : dispatch(
                    showNotification(MESSAGES.importReferencesCopyFailure, {
                      type: MESSAGE_TYPES.ERROR,
                    })
                  )
            }}
            startIcon={<ContentCopyIcon />}
          >
            Copy to Clipboard
          </Button>
        )}

        {invalidChips.length > 0 ? (
          <Button
            color="primary"
            variant="contained"
            onClick={event => {
              event.preventDefault()
              setInvalidChips([])
              apiRefNumbersCache.current = null
              setErrors({})
              setValues({
                ...values,
                chips: values.chips.filter(chip => !invalidChips.includes(chip)),
                lineItemChips: values.lineItemChips.filter(chip => !invalidChips.includes(chip)),
              })
            }}
          >
            Remove Invalid Items
          </Button>
        ) : (
          <Button
            disabled={isSubmitting || !hasValidChips || validatingChips}
            color="primary"
            variant="contained"
            type="submit"
            form={FORM_ID}
          >
            {validatingChips ? 'Validating...' : isSubmitting ? 'Applying Filter' : 'Apply Filter'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

// Rendering this entire component seems to introduce large amount of latency
// Memo appears to be the functional React equivalent to a Pure class component
export default React.memo(ImportReferencesDialog)
