import { BOX_WHISKER_PLOT, CHART_TYPE_BAR, CHART_TYPE_COLUMN } from 'components/ChartLoader/utils'

import BarChartLoader from 'components/ChartLoader/BarChartLoader'
import BoxWhiskerPlotLoader from 'components/ChartLoader/BoxWhiskerPlotLoader'
import CardOverlay from 'components/core/CardOverlay'
import CenteredLayout from 'components/layouts/CenteredLayout'
import ColumnChartLoader from 'components/ChartLoader/ColumnChartLoader'
import PropTypes from 'prop-types'
import React from 'react'

/**
 *
 * @param {object} props
 *  {
 *    isLoading (bool):  `true`|`false (default)`,
 *    dataReceived (bool):  `true`|`false (default)`, no data received yet
 *    isFailed (bool): `true`|`false (default)`,
 *    chartType (string): `bar` (default) or `column`,
 *    hasData (bool):  `true`|`false` (default), data received but empty,
 *  }
 */
BarChartLoader.propTypes = {
  chartType: PropTypes.oneOf([CHART_TYPE_BAR, BOX_WHISKER_PLOT, CHART_TYPE_COLUMN]),
  Component: PropTypes.elementType,
  dataReceived: PropTypes.bool,
  isFailed: PropTypes.bool,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
}

const Loading = ({
  chartType = CHART_TYPE_BAR,
  Component = BarChartLoader,
  dataReceived = false,
  hasData = false,
  isFailed = false,
  isLoading = false,
  message,
}) => {
  if (chartType === BOX_WHISKER_PLOT) Component = BoxWhiskerPlotLoader
  if (chartType === CHART_TYPE_BAR) Component = BarChartLoader
  if (chartType === CHART_TYPE_COLUMN) Component = ColumnChartLoader

  if (isFailed) {
    return (
      <CenteredLayout>
        <Component isAnimated={false} />
        <CardOverlay>{message ? message : <>Oops, something went wrong.</>}</CardOverlay>
      </CenteredLayout>
    )
  } else if (isLoading || !dataReceived) {
    return (
      <CenteredLayout>
        <Component isAnimated={true} />
      </CenteredLayout>
    )
  } else if (dataReceived && !hasData) {
    return (
      <CenteredLayout>
        <Component isAnimated={false} />
        {!hasData && (
          <CardOverlay>
            {message ? (
              message
            ) : (
              <>
                No results found.
                <br />
                Please adjust your filter criteria.
              </>
            )}
          </CardOverlay>
        )}
      </CenteredLayout>
    )
  }
}

export default Loading
