export default theme => ({
  container: {
    backgroundColor: theme.palette.grey[300],
    padding: 0,
  },
  item: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'space-between',

    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    '&:selected': {
      backgroundColor: theme.palette.grey[50],
    },
    '&:focus': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  hidden: {
    color: theme.palette.grey[500],
  },
  sortableList: {
    '&:selected': {
      cursor: 'grabbing',
    },
    zIndex: theme.zIndex.tooltip,
    boxShadow: theme.shadows[2],
  },
  hoverableIcon: {
    visibility: 'hidden',
  },
  listItem: {
    padding: 0,
    '&:hover $hoverableIcon': {
      visibility: 'inherit',
    },
  },
  listItemIcon: {
    marginRight: 0,
  },
})
