/* global __LOCAL_DEV__ */
/* eslint-disable no-console */

// User Tracking / Event Logging Utility
// Wraps functionality from Segment, Intercom, GA, and other sources/destinations
// Using this wrapper allows us to consolidate all event logging calls
// and make broader changes to event tracking with more confidence.

/**
 * import logger from 'utils/logger'
 * Quick overview to methods available:
 * - `logger.notify(name, metadata)`: Log a basic event w/ metadata
 * - `logger.pageChange()`: Triggers 'update' method on Intercom, signaling a route change
 * - `logger.captureAPIException(err)`: logs to Sentry, typically used in `catch` blocks in sagas
 * - `logger.captureAppException(err)`: logs to Sentry, more generic exception logger
 * - `logger.reset()`: called on logout to reset analytics and Intercom
 * - `logger.groupLog(groupName = '', isEnd = false)`: allows use of `console.group` and `console.groupEnd`
 * - `logger.allEnvLog(logStr = '', type = 'log')`: alias for `console[type]` that will log in all environments
 * - `logger.localLog(logStr = '', type = 'log')`: alias for `console[type]` that will log only in dev environments.
 *    Alias:`logger.log(logStr = '', type = 'log')`
 */

import * as Sentry from '@sentry/browser'

import get from 'lodash/get'

export class EventLogger {
  isImpersonating = false
  logToConsole = false

  constructor() {
    this.warn = function (msg) {
      this.localLog(msg, 'warn')
    }

    this.error = function (msg) {
      this.localLog(msg, 'error')
    }

    // just an alias to make this more like console log
    this.log = this.localLog
  }

  toggleImpersonation = function toggleImpersonation() {
    this.isImpersonating = !this.isImpersonating
  }

  toggleExplictLogging = function toggleExplictLogging() {
    this.logToConsole = !this.logToConsole
  }

  identify = function identify(id, traits, user_hash) {
    if (this.isImpersonating) return
    window.analytics.identify(id, traits, {
      integrations: {
        Intercom: {
          user_hash,
        },
      },
    })
    window.analytics.debug(__LOCAL_DEV__)
  }

  // Log a basic event w/ metadata
  notify = function notify(name, metadata) {
    if (this.isImpersonating) return
    if (this.logToConsole) {
      console.groupCollapsed(`%c ${name}`, 'background: #FCF6B1; color: #2D1E2F')
      console.log(JSON.stringify(metadata, null, 2))
      console.groupEnd()
    }
    if (metadata !== undefined) {
      if (typeof metadata !== 'object') {
        throw new Error(
          `Invalid logger.notify metadata.  Must be of type object not ${typeof metadata}`
        )
      }
      if ('id' in metadata) {
        this.warn("Shouldn't be setting `id` in metadata.")
      }
    }
    window.analytics.track(name, metadata)
  }

  // Triggers 'update' method on Intercom, signaling a route change
  pageChange = function pageChange() {
    if (this.isImpersonating) return
    window.analytics.page()
    window.Intercom('update')
  }

  captureAPIException = function captureAPIException(err) {
    Sentry.addBreadcrumb({
      message: 'Api error',
      category: 'api',
      data: {
        dataStr: JSON.stringify(get(err, 'response.data')),
        status: get(err, 'response.status'),
        statusText: get(err, 'response.statusText'),
      },
    })
    Sentry.captureException(err)
  }

  // generic version of above
  captureAppException = function captureAppException(err) {
    Sentry.addBreadcrumb({
      message: 'Application error',
      category: 'application',
      data: {
        dataStr: JSON.stringify(get(err, 'response.data')),
        status: get(err, 'response.status'),
        statusText: get(err, 'response.statusText'),
      },
    })
    Sentry.captureException(err)
  }

  reset = function reset() {
    if (this.isImpersonating) return
    window.analytics.reset()
    window.Intercom('shutdown')
  }

  /**
   * Allows use of `console.group` and `console.groupEnd`
   * @param string groupName
   * @param boolean isEnd
   * @returns
   */
  groupLog = function groupLog(groupName = '', isEnd = false) {
    return isEnd ? console.groupEnd(groupName) : console.group(groupName)
  }

  /**
   * Logs to _all_ environments, useful when we want to see data for testing in prod.
   * `console.localLog('blah', 'error')`
   * `console.localLog('bleh', 'info')
   * @param logStr
   * @param type any valid `console` method, defaults to "log"
   * @aliased as `log`: `logger.log`
   */
  allEnvLog = function allEnvLog(logStr, type = 'log') {
    type = window.console && window.console[type] ? type : 'log'
    if (type === 'dir') {
      return console.dir(logStr)
    } else {
      console[type](logStr)
    }
  }

  /**
   * Only log when in local development mode. Allows us to use any `console` method:
   * `console.localLog('blah', 'error')`
   * `console.localLog('bleh', 'info')
   * @param logStr
   * @param type any valid `console` method, defaults to "log"
   * @aliased as `log`: `logger.log`
   */
  localLog = function localLog(logStr, type = 'log') {
    type = window.console && window.console[type] ? type : 'log'
    if (__LOCAL_DEV__)
      if (type === 'dir') {
        return console.dir(logStr)
      } else {
        console[type](logStr)
      }
  }
}

const logger = new EventLogger()
export default logger
