export default theme => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
  showActiveContent: {
    display: 'flex',
    alignItems: 'center',
    margin: `5px 0 20px 9px`,
  },
  showActiveDropdown: {
    marginRight: 5,
  },
  showActiveButton: {
    width: 235,
    background: theme.palette.white,
  },
  showActiveIcon: {
    color: theme.palette.grey[500],
  },
})
