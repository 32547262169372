import {
  ACTION_ARRIVE,
  ACTION_LOAD,
  ACTION_UNLOAD,
  ACTIVITY_STUFFING,
  AVAILABLE_FOR_DELVERY,
  AVAILABLE_FOR_DELVERY_LABEL,
  CATEGORY_VESSEL,
  POST_MAIN,
  PRE_MAIN,
  PROGRESS_COMPLETED,
  TIMESTAMP_ACTUAL,
  TIMESTAMP_CARRIER_ESTIMATE_ARRIVE,
  TIMESTAMP_CARRIER_ESTIMATE_LOAD,
  TIMESTAMP_CARRIER_ESTIMATE_UNLOAD,
  TIMESTAMP_LABEL_ACTUAL,
  TIMESTAMP_LABEL_CARRIER_ESTIMATE,
  TIMESTAMP_LABEL_PLANNED,
  TIMESTAMP_LABEL_PLANNED_NO_ACTUAL,
  TIMESTAMP_LABEL_PREDICTED,
  TIMESTAMP_PLANNED,
  TIMESTAMP_PLANNED_NO_ACTUAL,
  TIMESTAMP_PREDICTED,
} from 'utils/milestone/MilestoneGroups/definitions'
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useState } from 'react'
import { formatCarrierLabel, toTitleCase } from 'utils/strUtils'

import { BLUE25 } from 'components/StatusLabel/labelColors'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import NotProvided from 'components/NotProvided'
import OceanIcon from 'components/icons/OceanIcon'
import OutdatedPredictionDisclaimer from 'components/OutdatedPredictionDisclaimer'
import RailIcon from 'components/icons/RailIcon'
import RolledStatusLabel from 'components/RolledStatusLabel'
import StatusIcon from 'pages/ContainerPage/JourneyDetails/JourneyDetailRow/StatusIcon'
import StatusLabel from 'components/StatusLabel'
import TimeBlock from 'pages/ContainerPage/JourneyDetails/JourneyDetailRow/TimeBlock'
import TruckIcon from 'components/icons/TruckIcon'
import classnames from 'classnames'
import getFullMilestoneName from 'utils/shipmentUtils/getFullMilestoneName'
import getMilestoneDisplayText from 'utils/milestone/getMilestoneDisplayText'
import hasRoll from 'utils/milestone/MilestoneGroups/hasRoll'
import some from 'lodash/some'

const LINE_HEIGHT = 1.4

const useStyles = makeStyles((theme: Theme) => ({
  milestoneTable: {
    borderCollapse: 'separate',
    boxShadow:
      '2px 3px 5px -4px rgba(0,0,0,0.2), 2px 3px 6px -2px rgba(0,0,0,0.14), 2px 2px 9px -10px rgba(0,0,0,0.12)',
    '& td': {
      borderBottom: `1px solid theme.palette.grey[300]`,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      verticalAlign: 'top',

      '&:first-child': {
        borderLeft: `1px solid theme.palette.grey[300]`,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
      '&:last-child': {
        borderRight: `1px solid theme.palette.grey[300]`,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  },
  hasSibling: {
    '& td': {
      '&:first-child': {
        borderBottomLeftRadius: 0,
      },
      '&:last-child': {
        borderBottomRightRadius: 0,
      },
    },
  },
  table: {
    borderCollapse: 'separate',
    tableLayout: 'fixed',
    '& td': {
      background: theme.palette.white,
      paddingBottom: 20,
      paddingTop: 20,
    },
  },
  collapsible: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  highlightedContainer: {
    background: theme.palette.purple[100],
    borderRadius: theme.shape.borderRadius,
    display: 'inline-block',
  },
  root: {},
  vehicleIconContainer: {
    width: 65,
  },
  vehicleCell: {
    '& div': {
      display: 'inline-block',
      verticalAlign: 'inherit',
    },
  },
  vehicleInfo: {
    width: 'calc(100% - 65px)',
  },
  // 14px medium grey[900]
  labelOne: {
    ...theme.typography.body2,
    color: theme.palette.grey[900],
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  // 14px regular grey[900]
  labelOneRegular: {
    ...theme.typography.body2,
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: LINE_HEIGHT,
  },
  // 12px regular grey[500]
  labelTwoRegular: {
    ...theme.typography.caption,
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: LINE_HEIGHT,
  },
  // 12px regular grey[600]
  labelThree: {
    ...theme.typography.caption,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: LINE_HEIGHT,
  },
  highlight: {
    background: theme.palette.yellow[250],
    borderRadius: theme.shape.borderRadius,
    margin: -3,
    padding: 3,
  },
  hasExpand: {
    position: 'relative',
    '& > button': {
      position: 'absolute',
      left: -34,
      top: 20,
    },
  },
  vehicleIcon: {
    color: theme.palette.grey[500],
    height: 'auto',
    width: 45,
  },
  infoIcon: {
    color: theme.palette.grey[600],
    height: 16,
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'text-top',
    width: 16,
  },
  tooltip: {
    fontSize: 13,
    opacity: 1,
    textTransform: 'none',
  },
}))

interface Props {
  classes?: { [key: string]: string }
  expanded?: boolean
  groupIsExpanded: boolean
  isLastTraced: boolean
  milestone: GroupedMilestoneInterface
  milestoneCount: number
  milestoneIndex: number
}

const JourneyDetailRow = (props: Props) => {
  const classes = useStyles(props)
  const { groupIsExpanded, isLastTraced = false, milestone, milestoneCount, milestoneIndex } = props
  const [timeFieldIsExpanded, setTimeFieldIsExpanded] = useState(false)

  const {
    action,
    exceptions,
    onEquipment,
    baselinePlannedTime,
    locationZone,
    plannedTime,
    plannedTimeDelay,
    predictedTime,
    predictedTimeDelay,
    stopSegment,
    statuses,
    tracedTime,
    tracedTimeDelay,
  } = milestone

  // A lot of effort to figure out if we want to add a tooltip to the Initital Carrier Estimate
  const carrierEstimateMilestoneType = () => {
    if (
      action === ACTION_LOAD &&
      onEquipment?.category === CATEGORY_VESSEL &&
      stopSegment.tripSegment.stage === PRE_MAIN
    ) {
      return TIMESTAMP_CARRIER_ESTIMATE_LOAD
    }
    if (
      action === ACTION_UNLOAD &&
      onEquipment?.category === CATEGORY_VESSEL &&
      stopSegment.tripSegment.stage === POST_MAIN
    ) {
      return TIMESTAMP_CARRIER_ESTIMATE_UNLOAD
    }
    if (
      action === ACTION_ARRIVE &&
      stopSegment.equipmentActivity === ACTIVITY_STUFFING &&
      stopSegment.tripSegment.stage === POST_MAIN
    ) {
      return TIMESTAMP_CARRIER_ESTIMATE_ARRIVE
    }
  }

  const carrierEstimateTimestampType = carrierEstimateMilestoneType()
  const equipmentActivity = stopSegment?.equipmentActivity
  const equipmentCategory = onEquipment?.category
  const stage = stopSegment?.tripSegment?.stage
  const vehicleCategory = onEquipment?.category
  const equipmentName = toTitleCase(onEquipment?.name)
  const equipmentNumber = onEquipment?.number
  const facilityType = stopSegment.facilityType

  /**
   * We're doing a two stage check for the milestone name here.
   * First we call `getMilestoneDisplayText`, which is specific to Journey Details. If we get a name returned we use it.
   * Second, if we don't get a name above we use the legacy `getFullMilestoneName` function.
   */
  let milestoneName = getMilestoneDisplayText({
    stage,
    action,
    equipmentActivity,
    equipmentCategory,
    facilityType,
  })

  milestoneName =
    milestoneName ?? getFullMilestoneName(stage, action, vehicleCategory, facilityType)

  const isReadyForDelivery =
    statuses && some(statuses, s => s.statusType === AVAILABLE_FOR_DELVERY && s.tracedTime)
  const isComplete = Boolean(tracedTime)
  const timezone = locationZone?.timezone || ''

  return (
    <TableRow className={classnames(classes.root)}>
      <TableCell colSpan={6} className={classes.collapsible}>
        <Collapse in={groupIsExpanded} timeout="auto" unmountOnExit>
          <Table className={classes.table}>
            <TableBody
              className={classnames(classes.milestoneTable, {
                [classes.hasSibling]: milestoneCount > 1 && milestoneIndex < milestoneCount - 1,
              })}
            >
              <TableRow>
                <TableCell />
                <TableCell>
                  {isComplete && <StatusIcon iconType={toTitleCase(PROGRESS_COMPLETED)} />}
                </TableCell>
                <TableCell>
                  <Typography
                    className={classnames(classes.labelOne, {
                      [classes.highlight]: isLastTraced,
                    })}
                    component="span"
                  >
                    {milestoneName}
                  </Typography>
                  <Typography className={classes.labelTwoRegular} paragraph={false}>
                    {toTitleCase(facilityType)}
                  </Typography>

                  {hasRoll(exceptions) && (
                    <Box mt={1}>
                      <RolledStatusLabel short={true} />
                    </Box>
                  )}

                  {isReadyForDelivery && (
                    <Box mt={1}>
                      <StatusLabel
                        short={true}
                        variant={BLUE25}
                        label={AVAILABLE_FOR_DELVERY_LABEL}
                      />
                    </Box>
                  )}
                </TableCell>
                <TableCell className={classes.hasExpand}>
                  {/* 
                    The logic here is:
                    if we have traced (actual) time
                      display in order (if present)
                      traced/actual
                      if we have planned or predicted
                        create a collapsible section
                          if we have planned
                            planned
                          if we have predicted
                            predicted
                        end collapsible
                    else
                      if planned
                        planned
                      if predicted
                        predicted
                   */}
                  {tracedTime ? (
                    <>
                      {(predictedTime || plannedTime) && (
                        <Button
                          variant="text"
                          onClick={() => setTimeFieldIsExpanded(!timeFieldIsExpanded)}
                        >
                          {timeFieldIsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Button>
                      )}
                      <TimeBlock
                        classes={{
                          label: classes.labelThree,
                          dateTime: classnames(classes.labelOne),
                          timezone: classes.labelTwoRegular,
                        }}
                        dateTime={tracedTime}
                        delay={tracedTimeDelay}
                        label={TIMESTAMP_LABEL_ACTUAL}
                        delayType={TIMESTAMP_ACTUAL}
                        timezone={timezone}
                      />

                      {(plannedTime || predictedTime) && (
                        <Collapse in={timeFieldIsExpanded} timeout="auto" unmountOnExit>
                          {plannedTime && (
                            <Box mt={tracedTime ? 2 : 0}>
                              <TimeBlock
                                classes={{
                                  label: classes.labelThree,
                                  dateTime: classnames(classes.labelOne),
                                  timezone: classes.labelTwoRegular,
                                }}
                                dateTime={plannedTime}
                                delay={plannedTimeDelay}
                                label={TIMESTAMP_LABEL_PLANNED}
                                delayType={TIMESTAMP_PLANNED}
                                timezone={timezone}
                              />
                            </Box>
                          )}
                          {predictedTime && (
                            <Box p={1} mt={2} className={classes.highlightedContainer}>
                              <TimeBlock
                                classes={{
                                  label: classes.labelThree,
                                  dateTime: classnames(classes.labelOne),
                                  timezone: classes.labelTwoRegular,
                                }}
                                dateTime={predictedTime}
                                delay={predictedTimeDelay}
                                label={TIMESTAMP_LABEL_PREDICTED}
                                delayType={TIMESTAMP_PREDICTED}
                                timezone={timezone}
                              />
                            </Box>
                          )}
                        </Collapse>
                      )}
                    </>
                  ) : (
                    <>
                      {plannedTime && (
                        <Box>
                          <TimeBlock
                            classes={{
                              label: classes.labelThree,
                              dateTime: classnames(classes.labelOne),
                              timezone: classes.labelTwoRegular,
                            }}
                            dateTime={plannedTime}
                            delay={plannedTimeDelay}
                            label={TIMESTAMP_LABEL_PLANNED_NO_ACTUAL}
                            delayType={TIMESTAMP_PLANNED_NO_ACTUAL}
                            timezone={timezone}
                          />
                        </Box>
                      )}
                      {predictedTime && (
                        <Box
                          p={1}
                          mt={plannedTime ? 2 : 0}
                          className={classes.highlightedContainer}
                        >
                          <TimeBlock
                            classes={{
                              label: classes.labelThree,
                              dateTime: classnames(classes.labelOne),
                              timezone: classes.labelTwoRegular,
                            }}
                            dateTime={predictedTime}
                            delay={predictedTimeDelay}
                            label={TIMESTAMP_LABEL_PREDICTED}
                            delayType={TIMESTAMP_PREDICTED}
                            timezone={timezone}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {milestone.hasOutdatedPrediction && (
                    <Box mt={0.5}>
                      <OutdatedPredictionDisclaimer />
                    </Box>
                  )}
                </TableCell>
                <TableCell>
                  {baselinePlannedTime ? (
                    <>
                      <TimeBlock
                        classes={{
                          label: classes.labelThree,
                          dateTime: classnames(classes.labelOne),
                          timezone: classes.labelTwoRegular,
                        }}
                        dateTime={baselinePlannedTime}
                        delay={null}
                        label={TIMESTAMP_LABEL_CARRIER_ESTIMATE}
                        timezone={timezone}
                        estimateType={carrierEstimateTimestampType}
                      />
                    </>
                  ) : (
                    <NotProvided />
                  )}
                </TableCell>
                <TableCell className={classes.vehicleCell}>
                  <Box className={classes.vehicleIconContainer}>
                    {vehicleCategory === 'train' && (
                      <RailIcon width={40} className={classes.vehicleIcon} />
                    )}
                    {vehicleCategory === 'truck' && (
                      <TruckIcon width={40} className={classes.vehicleIcon} />
                    )}
                    {vehicleCategory === 'vessel' && (
                      <OceanIcon width={45} height={30} className={classes.vehicleIcon} />
                    )}
                  </Box>
                  <Box className={classes.vehicleInfo}>
                    {equipmentName && (
                      <Typography className={classes.labelOneRegular} paragraph={false}>
                        {formatCarrierLabel(equipmentName)}
                      </Typography>
                    )}
                    {equipmentNumber && (
                      <Typography className={classes.labelOneRegular} paragraph={false}>
                        {equipmentNumber}
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default React.memo(JourneyDetailRow)
