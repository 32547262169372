import { call, put, takeEvery } from 'redux-saga/effects'

import api from 'store/auth/tokenCheck/api'
import logger from 'utils/logger'
import { types } from 'store/auth/tokenCheck/actions'

export function* tokenCheckAsync(action) {
  try {
    const { token, email } = action.payload
    yield call(api.validateInvite, { token, email })
    yield put({ type: types.CHECK_TOKEN_SUCCESS })
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: types.CHECK_TOKEN_FAILURE })
    logger.localLog(`Error checking token: ${e}`, 'error')
  }
}

export function* watchTokenCheck() {
  yield takeEvery(types.CHECK_TOKEN_LOAD, tokenCheckAsync)
}
