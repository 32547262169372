import autocomplete, { AUTOCOMPLETE_KEY } from 'store/autocomplete'
import comments, { COMMENTS_REDUCER_KEY } from 'store/comments'
import downloads, { DOWNLOADS_REDUCER_KEY } from 'store/downloads'
import filterGroups, { FILTER_GROUPS_KEY } from 'store/filterGroups'
import planning, { PLANNING_REDUCER_KEY } from 'store/planning'
import publicShare, { PUBLIC_SHARE_REDUCER_KEY } from 'store/publicShare'
import share, { SHARE_REDUCER_KEY } from 'store/share'
import suggest, { SUGGEST_KEY } from 'store/suggest'
import support, { SUPPORT_REDUCER_KEY } from 'store/support'
import userDashboard, { USER_DASHBOARD_KEY } from 'store/userDashboard'

import { ASSOCIATED_REFERENCES_KEY } from 'store/associatedReferences/actions'
import { BOARD_REDUCER_KEY } from 'store/board/actions'
import { JOURNEY_DETAILS_REDUCER_KEY } from 'store/journeyDetails/actions'
import { PORTAL_ALERTS_REDUCER_KEY } from 'store/portalAlerts/actions'
import { PORTAL_USERS_REDUCER_KEY } from 'store/portalUsers/actions'
import { TRANSPORT_DETAIL_REDUCER_KEY } from 'store/transportDetail/actions'
import { TRIP_GROUPS_REDUCER_KEY } from 'store/tripGroups/actions'
import alerts from 'store/alerts'
import { apiReducers } from 'store/api'
import associatedReferences from 'store/associatedReferences'
import { authReducers } from 'store/auth'
import board from 'store/board'
import boardUtils from 'store/boardUtils'
import { combineReducers } from 'redux'
import confirmation from 'store/confirmation'
import customization from 'store/customization'
import defaultFilters from 'store/defaultFilters'
import drawers from 'store/drawers'
import { default as exportReducer } from 'store/export'
import featureFlags from 'store/featureFlags'
import journeyDetails from 'store/journeyDetails'
import notifications from 'store/notifications'
import orderUpload from 'store/orderUpload'
import portalAlerts from 'store/portalAlerts'
import portalUsers from 'store/portalUsers'
import profile from 'store/profile'
import topLanes from 'store/topLanes'
import transportDetail from 'store/transportDetail'
import tripGroups from 'store/tripGroups'

export const makeRootReducer = asyncReducers => {
  const reducers = {
    ...apiReducers,
    ...asyncReducers,
    ...authReducers,
    [ASSOCIATED_REFERENCES_KEY]: associatedReferences,
    [PLANNING_REDUCER_KEY]: planning,
    [BOARD_REDUCER_KEY]: board,
    [USER_DASHBOARD_KEY]: userDashboard,
    [SUPPORT_REDUCER_KEY]: support,
    [FILTER_GROUPS_KEY]: filterGroups,
    [AUTOCOMPLETE_KEY]: autocomplete,
    [SUGGEST_KEY]: suggest,
    [COMMENTS_REDUCER_KEY]: comments,
    [PORTAL_USERS_REDUCER_KEY]: portalUsers,
    [PORTAL_ALERTS_REDUCER_KEY]: portalAlerts,
    [SHARE_REDUCER_KEY]: share,
    [PUBLIC_SHARE_REDUCER_KEY]: publicShare,
    [DOWNLOADS_REDUCER_KEY]: downloads,
    [JOURNEY_DETAILS_REDUCER_KEY]: journeyDetails,
    [TRANSPORT_DETAIL_REDUCER_KEY]: transportDetail,
    [TRIP_GROUPS_REDUCER_KEY]: tripGroups,
    alerts,
    autocomplete,
    confirmation,
    customization,
    drawers,
    defaultFilters,
    export: exportReducer,
    featureFlags,
    notifications,
    orderUpload,
    profile,
    boardUtils: boardUtils.reducer,
    topLanes,
  }

  return combineReducers(reducers)
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
