import React from 'react'
import { SvgIcon } from '@material-ui/core'

const BellIcon = ({ ...restProps }) => (
  <SvgIcon viewBox={'0 0 24 29'} {...restProps}>
    <path
      d="M22.637 24.857H1.99a1.22 1.22 0 01-1.197-.646 1.288 1.288 0 01.106-1.383l3.106-5.045a4.094 4.094 0 00.487-1.638l.205-7.606c.032-.771.128-1.538.286-2.293.031-.134.751-3.108 4.905-3.984.227-1.19 1.243-2.05 2.425-2.05 1.182 0 2.198.86 2.425 2.05 4.158.869 4.878 3.843 4.906 3.984.157.753.253 1.52.286 2.29l.204 7.606c.046.575.213 1.133.487 1.637l3.106 5.046c.302.398.344.942.106 1.383a1.22 1.22 0 01-1.196.645v.004zM2.212 23.408h20.202l-2.986-4.847a5.46 5.46 0 01-.705-2.372l-.205-7.607a12.73 12.73 0 00-.25-2.003c-.022-.09-.643-2.427-4.331-2.974l-.689-.101.096-.76a.602.602 0 000-.08 1.067 1.067 0 00-1.041-.89c-.511 0-.949.374-1.042.89a.606.606 0 000 .08l.096.76-.689.101c-3.74.555-4.327 2.952-4.33 2.974-.136.66-.22 1.33-.25 2.003l-.205 7.607a5.46 5.46 0 01-.706 2.372l-2.965 4.847zm6.248 2.354c.254 1.5 1.945 2.655 3.992 2.655 2.047 0 3.738-1.16 3.992-2.655H8.46z"
      fillRule="nonzero"
    />
  </SvgIcon>
)

export default BellIcon
