import { IconButton, InputAdornment, OutlinedInput, Tooltip, makeStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import ContentCopyIcon from 'components/icons/ContentCopyIcon'
import LinkIcon from '@material-ui/icons/Link'
import PropTypes from 'prop-types'
import React from 'react'
import copy from 'copy-to-clipboard'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles(theme => ({
  positionStart: {
    marginRight: '3px',
  },
  inputRoot: {
    paddingLeft: '3px',
  },
}))

const GenerateToken = ({
  token,
  generateToken,
  className,
  inputClassName,
  inputContainerClassName,
  buttonContainerClassName,
  onCopiedToClipboard,
  inputRef,
}) => {
  const showButton = isEmpty(token)
  const classes = useStyles()

  return (
    <div
      className={cx(
        classes.container,
        className,
        showButton ? buttonContainerClassName : inputContainerClassName
      )}
    >
      {showButton ? (
        <Button
          size="large"
          onClick={e => {
            e.preventDefault()
            generateToken()
          }}
          startIcon={<LinkIcon />}
          data-testid="share-form__create-link"
        >
          Create a link to share
        </Button>
      ) : (
        <OutlinedInput
          id="generate-token"
          className={inputClassName}
          classes={{
            root: classes.inputRoot,
          }}
          inputRef={inputRef}
          value={token}
          onClick={e => {
            if (e.target && e.target.select) {
              e.target.select()
            }
          }}
          margin="dense"
          fullWidth
          readOnly
          startAdornment={
            <InputAdornment
              classes={{
                root: classes.positionStart,
              }}
              position="start"
            >
              <Tooltip title="Copy" placement="top">
                <IconButton
                  onClick={() => {
                    copy(token)
                    onCopiedToClipboard()
                  }}
                  className={classes.iconButton}
                  aria-label="Copy to Clipboard"
                >
                  <ContentCopyIcon className={classes.copyIcon} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      )}
    </div>
  )
}

GenerateToken.propTypes = {
  token: PropTypes.string.isRequired,
  generateToken: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired, // Applied to top level DOM element
  inputClassName: PropTypes.string.isRequired, // Used to Style Input Field directly
  inputContainerClassName: PropTypes.string.isRequired, // Used to Style Input Field Container directly
  buttonContainerClassName: PropTypes.string.isRequired, // Used to Style Button Container directly
  onCopiedToClipboard: PropTypes.func.isRequired,
}

GenerateToken.defaultProps = {
  token: null,
  className: '',
  inputClassName: '',
  inputContainerClassName: '',
  buttonContainerClassName: '',
}

export default GenerateToken
