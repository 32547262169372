export const routeGraphMode = {
  AGGREGATED: 'aggregated',
  COMPARISON: 'comparison',
}

export const ROUTE_COMPARE_LIMIT = 10

export const routeColors = theme => [
  theme.palette.datavis.blue[400],
  theme.palette.datavis.purple[500],
  theme.palette.datavis.yellow[400],
  theme.palette.datavis.green[500],
  theme.palette.datavis.pink[400],
  theme.palette.datavis.green[300],
  theme.palette.datavis.blue[500],
  theme.palette.datavis.yellow[300],
  theme.palette.datavis.orange[500],
  theme.palette.datavis.purple[800],
]

export const highlightHoveredRoute = (chart, hoveredRouteId) => {
  if (!chart || !chart.series) {
    return
  }
  chart.series.forEach(s => {
    if (s.options.id === hoveredRouteId) {
      s.setState('normal')
    } else {
      s.setState('inactive')
    }
  })
}

export const clearHoveredRoute = chart => {
  if (!chart || !chart.series) {
    return
  }
  chart.series.forEach(s => {
    s.setState('normal')
  })
}

export const days = m => (m === 1 ? 'day' : 'days')
