import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import some from 'lodash/some'

/**
 * Determines if any milestone in an array of milestones has a truthy `tracedTime`
 * @param milestones array
 * @return boolean
 */
export const anyMilestoneIsTraced = (milestones: GroupedMilestoneInterface[]) => {
  return some(milestones, m => m?.tracedTime)
}

export default anyMilestoneIsTraced
