// Theme is passed from material-ui withStyles
export default theme => ({
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '30vh',
    overflow: 'auto',
  },
  list: {
    margin: `${theme.spacing(2)}px 0`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  saveButton: {
    height: 65,
    borderRadius: 0,
    backgroundColor: theme.brand.color,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.brand.color,
    },
  },
  saveButtonText: {
    color: theme.palette.fullWhite,
  },
})
