import LaneAutocomplete from './LaneAutocomplete'
import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'

const carrierLabel = item => item.label
const carrierValue = item => get(item, 'data.scac_code', '')

const CarrierAutocomplete = props => (
  <LaneAutocomplete
    getLabel={carrierLabel}
    getValue={carrierValue}
    valueDescription="SCAC Code"
    {...props}
  />
)

CarrierAutocomplete.propTypes = {
  className: PropTypes.string,
  defaultValues: PropTypes.array,
  error: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  lookupPath: PropTypes.string.isRequired,
  suggestType: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string,
  transformChipFn: PropTypes.func,
}

export default CarrierAutocomplete
