// Required to initialize before importing any date components
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { CssBaseline, MuiThemeProvider } from '@material-ui/core'

import { Provider } from 'react-redux'
import React from 'react'
import Routes from 'components/Routes'
import createTheme from 'utils/createTheme'

const theme = createTheme()

interface IAppProps {
  /**
   * TODO: Add types to createStore, SystemState, etc.
   * See Docs: https://redux.js.org/recipes/usage-with-typescript#type-checking-actions--action-creators
   * See Example: https://www.freecodecamp.org/news/how-to-use-redux-in-your-react-typescript-app/#setting-up
   *
   * TODO: Probably just initialize Store in App rather than src/index to consolidate configurations
   */
  store: any
}

function App(props: IAppProps) {
  const { store } = props

  return (
    <Provider store={store}>
      <div style={{ height: '100%', width: '100%' }} className="cmApp" data-testid="app-root">
        <MuiThemeProvider theme={theme}>
          {/**
           * CssBaseLine must be a child of MuiThemeProvider to
           * access customizations
           */}
          <CssBaseline />
          <Routes />
        </MuiThemeProvider>
      </div>
    </Provider>
  )
}

export default App
