import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ExternalId } from 'store/journeyDetails/interfaces'
import { TransportDetail } from 'store/models/TransportDetail'
import api from 'store/journeyDetails/api'
import { types as commentTypes } from 'store/comments/actions'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import { types } from 'store/journeyDetails/actions'

export function* fetchTransportDetailAsync(action: { type: string; payload?: ExternalId }) {
  try {
    const res = yield call(api.fetchTransportDetail, action.payload)
    const data = (res?.data && TransportDetail.of(res.data)) || {}
    yield put({ type: types.FETCH_JOURNEY_DETAILS_SUCCESS, payload: data })

    const isExternalUser = yield select(isExternalUserSelector)
    if (!isExternalUser) {
      const externalIds = action.payload
      yield put({ type: commentTypes.FETCH_COMMENT_SUMMARIES_START, externalIds })
    }
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.FETCH_JOURNEY_DETAILS_FAILURE, payload: { error: err.message } })
  }
}

export function* watchFetchJourneyDetails() {
  yield takeLatest(types.FETCH_JOURNEY_DETAILS_START, fetchTransportDetailAsync)
}
