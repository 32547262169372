import { Box, makeStyles } from '@material-ui/core'

import { EntityPageHeaderInterface } from 'store/models/EntityPageHeader'
import Overview from './Overview'
import React from 'react'
import Skeleton from 'components/EntityPageHeader/Skeleton'
import Status from './Status'

// All classes defined here are used by both children
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-body2': {
      lineHeight: 1.4,
    },
    '& .MuiTypography-h6': {
      lineHeight: 1.3,
    },
    marginBottom: 48,
  },
  item: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      '2px 3px 5px -4px rgba(0,0,0,0.2), 2px 3px 6px -2px rgba(0,0,0,0.14), 2px 2px 9px -10px rgba(0,0,0,0.12)',
  },
  row: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  cell: {
    // The value that guarantees both left and right have the same height with minimum content.
    // After that `flex` ensures they match heights.
    minHeight: 58,
    padding: theme.spacing(0.25, 2),
  },
  muted: {
    color: theme.palette.grey[600],
  },
  noData: {
    color: theme.palette.grey[500],
  },
  pageHeader: {
    minWidth: 950,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  italic: {
    fontStyle: 'italic',
  },
  overviewContainer: {},
}))

interface Props {
  classes?: { [key: string]: string }
  className?: string
  commentIconLink?: string | null
  entityLink?: string
  externalId: string
  isBoard?: boolean
  isContainerPage?: boolean
  isLoading?: boolean
  isPublicShare?: boolean
  pageHeaderData?: EntityPageHeaderInterface
  refTypeLabel?: string
}

/**
 * The flex layout in this and the two child components is relatively complex so be careful when modifying.
 * All of the flex attributes are set in `Box` components except `classes.flexColumn` because that is used
 * in multiple places.
 */
export const EntityPageHeader = (props: Props) => {
  const classes = useStyles(props)
  const { pageHeaderData } = props
  const {
    isBoard = false,
    commentIconLink,
    entityLink,
    externalId,
    isContainerPage = false,
    isLoading = false,
    isPublicShare = false,
    refTypeLabel,
  } = props

  return isLoading ? (
    <Skeleton isAnimated={true} />
  ) : (
    <Box data-testid="page-header" className={classes.root}>
      <Box className={classes.pageHeader} display="flex" flexDirection="row" flexWrap="nowrap">
        <Overview
          classes={{
            overviewContainer: classes.overviewContainer,
            root: classes.root,
            row: classes.row,
            cell: classes.cell,
            muted: classes.muted,
            noData: classes.noData,
            flexColumn: classes.flexColumn,
            italic: classes.italic,
            item: classes.item,
          }}
          commentIconLink={commentIconLink}
          entityLink={entityLink}
          externalId={externalId}
          isBoard={isBoard}
          isContainerPage={isContainerPage}
          isPublicShare={isPublicShare}
          pageHeaderData={pageHeaderData}
          refTypeLabel={refTypeLabel}
        />

        {pageHeaderData && (
          <Status
            classes={{
              root: classes.root,
              row: classes.row,
              cell: classes.cell,
              muted: classes.muted,
              noData: classes.noData,
              flexColumn: classes.flexColumn,
              italic: classes.italic,
              item: classes.item,
            }}
            isBoard={isBoard}
            isContainerPage={isContainerPage}
            isPublicShare={isPublicShare}
            pageHeaderData={pageHeaderData}
          />
        )}
      </Box>
    </Box>
  )
}

export default EntityPageHeader
