import { call, put, takeLatest } from 'redux-saga/effects'

import client from 'utils/api/client'
/**
 * Created by diegocanales on 5/29/17.
 */
import { createAction } from 'redux-actions'
import logger from 'utils/logger'

// ------------------------------------
// Constants
// ------------------------------------
const TRIGGER_ALERT_LOAD = 'api/alerts_trigger/TRIGGER_ALERT_LOAD'
const TRIGGER_ALERT_ERROR = 'api/alerts_trigger/TRIGGER_ALERT_ERROR'
const TRIGGER_ALERT_SUCCESS = 'api/alerts_trigger/TRIGGER_ALERT_SUCCESS'

export const TRIGGER_ALERT_REDUCER_KEY = 'trigger_alerts'

// ------------------------------------
// Actions
// ------------------------------------
export const triggerAlerts = createAction(TRIGGER_ALERT_LOAD, (tenant, email) => ({
  tenant,
  email,
}))

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TRIGGER_ALERT_LOAD]: (state, action) => {
    return { ...state, isLoading: true }
  },
  [TRIGGER_ALERT_ERROR]: (state, action) => {
    return { ...state, error: true, isLoading: false }
  },
  [TRIGGER_ALERT_SUCCESS]: (state, action) => {
    return { ...state, isLoading: false }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  error: false,
  isLoading: false,
}

export function triggerAlertReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Selectors
// ------------------------------------

export const triggerAlertErrorSelector = state => state[TRIGGER_ALERT_REDUCER_KEY].error
export const triggerAlertLoadingSelector = state => state[TRIGGER_ALERT_REDUCER_KEY].isLoading

// ------------------------------------
// Saga
// ------------------------------------
function* triggerAlertsAsync(action) {
  try {
    const { tenant, email } = action.payload
    let data = {}
    if (tenant) data['tenant'] = tenant
    if (email) data['email'] = email
    const response = yield call(client.post, '/alerts/trigger', data)
    yield put({ type: TRIGGER_ALERT_SUCCESS, payload: response.data })
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: TRIGGER_ALERT_ERROR })
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

export function* watchTriggerAlertsLoad() {
  yield takeLatest(TRIGGER_ALERT_LOAD, triggerAlertsAsync)
}
