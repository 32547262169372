import CustomField, { CustomFieldInterface } from 'store/models/CustomField'

import { CustomFieldsInterface } from 'store/customFields/interfaces'
import humps from 'humps'

export default class CustomFields {
  constructor(customFields: CustomFieldsInterface) {
    Object.assign(this, customFields)
  }

  static of(payload: object) {
    const { customFields } = humps.camelizeKeys(payload) as CustomFieldsInterface

    return new CustomFields({
      customFields:
        customFields &&
        customFields.map(
          (customField: CustomFieldInterface) => CustomField.of(customField) as CustomFieldInterface
        ),
    })
  }
}
