import { MenuItem, Typography, withStyles } from '@material-ui/core'

import AutocompleteChipInput from 'components/core/ChipInput/AutocompleteChipInput'
import PropTypes from 'prop-types'
import React from 'react'
import isString from 'lodash/isString'
import styles from './styles'

class LaneAutocomplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasFocus: false,
    }
    this.inputRef = React.createRef()
  }
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    defaultValues: PropTypes.array,
    error: PropTypes.string,
    getLabel: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    lookupPath: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    title: PropTypes.string,
    transformChipFn: PropTypes.func,
    valueDescription: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: '',
    defaultValues: [],
    error: null,
  }

  handleSelect = (itemSelected, allValues) => {
    this.props.handleSelect(allValues)
  }

  render() {
    const {
      className,
      classes,
      defaultValues,
      error,
      getLabel,
      getValue,
      lookupPath,
      suggestType,
      placeholder,
      title,
      transformChipFn = null,
      valueDescription,
    } = this.props
    const { container, inputRoot, chipLabel, chip } = classes
    return (
      <div className={className}>
        {title && (
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
        )}
        <AutocompleteChipInput
          classes={{ chip, container, inputRoot, chipLabel }}
          defaultValues={defaultValues}
          error={Boolean(error)}
          inputRef={this.inputRef}
          isUnique={true}
          itemToString={itemToString(getLabel, getValue)}
          lookupPath={lookupPath}
          suggestType={suggestType}
          onSelect={this.handleSelect}
          placeholder={error || placeholder}
          renderSuggestion={renderSuggestion(getLabel, getValue, valueDescription)(classes)}
          transformChipFn={transformChipFn}
        />
      </div>
    )
  }
}

LaneAutocomplete = withStyles(styles)(LaneAutocomplete)

const itemToString = (getLabel, getValue) => item => {
  if (!item) return ''
  const label = getLabel(item)
  const value = getValue(item)
  return isString(item) ? item : `${label} [${value}]`
}

const renderSuggestion = (getLabel, getValue, valueDescription) => classes => params => {
  const { suggestion, index, itemProps, highlightedIndex } = params
  const isHighlighted = highlightedIndex === index
  return (
    <MenuItem
      {...itemProps}
      key={`filter-suggestion-${suggestion.label}-${index}`}
      selected={isHighlighted}
      component="div"
      className={classes.menuItem}
    >
      <div>
        <div>{getLabel(suggestion)}</div>
        <div className={classes.suggestionValue}>
          {valueDescription}: {getValue(suggestion)}
        </div>
      </div>
    </MenuItem>
  )
}

export default LaneAutocomplete
