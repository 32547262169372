import { BoardColumnInterface, BoardRowInterface } from 'store/board/interfaces'

import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ArrowUp from '@material-ui/icons/KeyboardArrowUp'
import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import Button from '@material-ui/core/Button'
import ItemsList from './ItemsList'
import NotProvided from 'components/NotProvided'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.palette.grey[800],
    fontSize: 22,
  },
  expandButton: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    height: theme.spacing(4),
    marginTop: 0,
    minWidth: theme.spacing(3),
    padding: 0,
    width: theme.spacing(4),
  },
}))

const MAX_ITEMS_TO_BE_DISPLAYED = 2

interface Props {
  className?: string
  collapseTimeout?: number
  column: BoardColumnInterface
  isRowExpanded: boolean
  isRowHovered: boolean
  onContextMenu: () => void
  onRowCollapse: () => void
  onRowExpand: () => void
  rollup: string
  row: BoardRowInterface
}

const ListCell = (props: Props) => {
  const {
    className,
    collapseTimeout = 0,
    column,
    isRowExpanded,
    isRowHovered,
    onContextMenu,
    onRowCollapse,
    onRowExpand,
    rollup,
    row,
  } = props

  const classes = useStyles()

  const handleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation() // so the row doesn't receive a click
    onRowExpand()
  }

  const handleCollapse = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation() // so the row doesn't receive a click
    onRowCollapse()
  }

  const renderExpandOrCollapse = () => {
    const values = getValues()
    if (isRowHovered && values.length > MAX_ITEMS_TO_BE_DISPLAYED) {
      let icon
      let onClick
      if (isRowExpanded) {
        icon = <ArrowUp className={classes.icon} />
        onClick = handleCollapse
      } else {
        icon = <ArrowDown className={classes.icon} />
        onClick = handleExpand
      }
      return (
        <Button className={classes.expandButton} onClick={onClick}>
          {icon}
        </Button>
      )
    }
    return null
  }

  const getValues = () => {
    const valueArray = column.getCellValue(row)
    // Strip falsy values like `[""]`
    return valueArray.length ? valueArray.filter((val: string) => val !== '') : []
  }
  const valuesArray = getValues()

  return (
    <BaseCell
      className={className}
      column={column}
      onContextMenu={onContextMenu}
      rollup={rollup}
      row={row}
    >
      <div className={classes.root}>
        {valuesArray.length > 0 ? (
          <>
            <ItemsList
              getUrl={column.getUrl as any}
              isRowExpanded={isRowExpanded}
              items={valuesArray}
              maxItemsToBeDisplayed={MAX_ITEMS_TO_BE_DISPLAYED}
              timeout={collapseTimeout}
              title={column.title}
            />
            {renderExpandOrCollapse()}
          </>
        ) : (
          <NotProvided text="Not Provided" />
        )}
      </div>
    </BaseCell>
  )
}

export default React.memo(ListCell)
