import React, { useEffect } from 'react'
import { formatLocationLabel, formatMinimalDecimals } from 'utils/strUtils'

import { CHART_TYPE_BAR } from 'components/ChartLoader/utils'
import ChartLoader from 'components/ChartLoader'
import HighChart from 'components/HighChart'
import { getHighChartDefaults } from '../utils/highCharts'
import { getWaypoints } from 'utils/waypoints'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import { withStyles } from '@material-ui/core'

export function BaseRouteBarChart({
  axisTitle,
  carrierLabelFormatter,
  color,
  data,
  dataKey,
  dataLabelFormatter,
  fetchData,
  isFailed,
  isLoading,
  tooltipMetrics,
  theme,
  title,
  titleHidden,
}) {
  useEffect(() => {
    fetchData()
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataReceived = data !== undefined
  const hasData = !isEmpty(data)

  if (isLoading || !dataReceived || !hasData) {
    return (
      <ChartLoader
        isLoading={isLoading}
        dataReceived={dataReceived}
        isFailed={isFailed}
        hasData={hasData}
        chartType={CHART_TYPE_BAR}
      />
    )
  }

  tooltipMetrics = [
    ...tooltipMetrics,
    { key: 'count', label: 'Volume', unit: 'containers', formatter: y => y.toLocaleString() },
    {
      key: 'metricsMedian',
      label: 'Median Transit Time',
      formatter: y => formatMinimalDecimals(y, 1),
      unit: 'days',
    },
  ]

  const highChartDefaultOptions = getHighChartDefaults(theme)

  const highChartOptions = {
    chart: {
      type: 'bar',
    },
    legend: { enabled: false },
    title: {
      text: title,
      style: {
        visibility: titleHidden ? 'hidden' : 'visible',
      },
    },
    plotOptions: {
      series: {
        pointWidth: 40,
        dataLabels: {
          enabled: true,
          align: 'right',
          color: theme.palette.white,
          x: -10,
          formatter: function () {
            return dataLabelFormatter(this.y)
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const route = this.x
        const rowStyle = `padding-top: 5px;`
        return `
          ${buildRouteLabel(route, theme, true)}
          <div style="font-size: 14px; margin-top: 10px;">
            ${buildTooltipMetrics(tooltipMetrics, route, rowStyle)}
            <div style="${rowStyle}">Carrier: <b>${carrierLabelFormatter(route)}</b></div>
          </div>
        `
      },
    },
    xAxis: {
      categories: data,
      labels: {
        formatter: function () {
          return buildRouteLabel(data[this.pos], theme, false, true)
        },
        align: 'left',
        reserveSpace: true,
      },
    },
    yAxis: {
      title: {
        text: axisTitle,
      },
      labels: {
        enabled: false,
      },
    },
    series: [
      {
        data: data.map(d => d.metrics[dataKey]),
        color,
      },
    ],
  }

  return <HighChart options={merge({}, highChartDefaultOptions, highChartOptions)} />
}

const buildTooltipMetrics = (tooltipMetrics, route, rowStyle) => {
  let html = ''
  tooltipMetrics.forEach(metric => {
    html += `
      <div style="${rowStyle}">
        ${metric.label}: <b>${metric.formatter(route.metrics[metric.key])} ${metric.unit || ''}</b>
      </div>
    `
  })
  return html
}

const buildRouteLabel = (route, theme, showWaypoints = false, breakAfterTo = false) => {
  const origName = formatLocationLabel(route.originLocation.name)
  const destName = formatLocationLabel(route.destLocation.name)

  const locStyle = `color: ${theme.palette.grey[800]};`
  const viaStyle = `color: ${theme.palette.grey[500]}; font-style: italic;`

  let waypointsHtml = ''
  if (showWaypoints) {
    const isRouteGroup = Boolean(route.routes)
    const waypoints = getWaypoints(route.waypointLocations, isRouteGroup)
    const waypointNames = waypoints.map(way =>
      way.unlocode ? formatLocationLabel(way.name) : way.name
    )

    if (!isRouteGroup) {
      waypointNames.forEach((waypointName, idx) => {
        waypointsHtml += `<span style="${viaStyle}"> via ${waypointName}</span>`
        if (idx < waypoints.length - 1) {
          waypointsHtml += ', '
        }
      })
    } else {
      waypointsHtml = `
          <span style="${viaStyle}"> via ${waypointNames.join(' + ')}</span>
        `
    }
  }

  let toSpan = `<span style="${viaStyle}">to</span>`
  if (breakAfterTo) {
    toSpan += `<br />`
  }

  return `
    <span style="font-size: 14px;">
      <span style="${locStyle}">${origName}</span>
      ${toSpan}
      <span style="${locStyle}">${destName}</span>
      ${waypointsHtml}
    </span>
  `
}

export default withStyles({}, { withTheme: true })(BaseRouteBarChart)
