import LaneAggregatedAutocomplete from './LaneAggregatedAutocomplete'
import PropTypes from 'prop-types'
import React from 'react'
import { formatLocationLabel } from 'utils/strUtils'
import get from 'lodash/get'

const locationLabel = item => formatLocationLabel(item.label)
const locationValue = item => {
  const loc_type = get(item, 'data.type')
  if (loc_type === 'zone') {
    return get(item, 'data.unlocode', '')
  } else if (loc_type === 'country') {
    return get(item, 'data.country_code', '')
  } else {
    throw new Error(`Invalid location type ${loc_type}`)
  }
}
const getValueDescription = item => {
  const loc_type = get(item, 'data.type')
  if (loc_type === 'zone') {
    return 'Unlocode'
  } else if (loc_type === 'country') {
    return 'Country Code'
  } else {
    throw new Error(`Invalid location type ${loc_type}`)
  }
}

const LocationAutocomplete = props => (
  <LaneAggregatedAutocomplete
    getLabel={locationLabel}
    getValue={locationValue}
    getValueDescription={getValueDescription}
    {...props}
  />
)

LocationAutocomplete.propTypes = {
  className: PropTypes.string,
  defaultValues: PropTypes.array,
  error: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  lookupPath: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string,
  transformChipFn: PropTypes.func,
}

export default LocationAutocomplete
