import React from 'react'
import { TableCell } from 'components/core/SimpleTableDeprecated'
import cx from 'classnames'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  tableCell: {
    border: 'none',
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    verticalAlign: 'top',
    wordBreak: 'break-word',
    '&:last-child': {
      paddingRight: theme.spacing(1),
    },
  },
})

const BaseCell = ({ children, className = '', classes, ...restProps }) => (
  <TableCell className={cx(className, classes.tableCell)} {...restProps}>
    {children}
  </TableCell>
)

export default withStyles(styles)(BaseCell)
