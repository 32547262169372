// Theme is passed from material-ui withStyles
export default theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  toolbar: {
    backgroundColor: theme.palette.common.lightBlack,
    flex: 1,
    display: 'flex',
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[500],
    letterSpacing: 0.2,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  formGroupRoot: {
    paddingLeft: 20,
    paddingRight: 20,
    maxHeight: 200,
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  submitBtn: {
    color: '#66717E',
    border: '1px solid #66717E',
    width: '90%',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#66717E',
      color: theme.palette.common.white,
    },
    margin: 'auto',
  },
  warning: {
    color: 'orange',
    textAlign: 'center',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  label: {
    fontSize: 11,
    marginTop: 2,
  },
  formControl: {
    marginTop: -14,
  },
  category: {
    width: '100%',
    color: theme.palette.grey[500],
    fontSize: 15,
    marginBottom: theme.spacing(1),
    letterSpacing: 0.4,
  },
  divider: {
    margin: 'auto',
    width: '95%',
  },
  autocompleteWrapper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  dateWrapper: {
    marginBottom: theme.spacing(2),
  },
  milestoneWrapper: {
    margin: theme.spacing(1),
    overflowY: 'auto',
    maxHeight: 220,
    border: '1px solid grey',
    backgroundColor: 'rgb(243, 243, 243)',
    padding: theme.spacing(1),
  },
})
