import { useEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'

/*
 * We hide the autocomplete menu item created by TinyMCE and insert our own in its
 * place via ReactDOM.createPortal.
 *
 * https://reactjs.org/docs/portals.html
 */
export default function MentionsPortal({ user, children }) {
  const ref = useRef(null)
  const [element, setElement] = useState(null)

  // Set up an interval that continuously looks for Tiny's autocomplete
  // menu item for this user so that we can render our custom menu item.
  useEffect(() => {
    const interval = setInterval(() => {
      // The menu item for this user is a div with the user's email as the title;
      // Tiny sets the "name" from the mentions_fetch success callback as the title
      // of the div.
      const menuItem = document.querySelector(
        `div.tox-collection__item[title="${user.email}"][role="menuitem"]`
      )
      if (menuItem && menuItem !== ref.current) {
        // Keep track of the element on a ref so that
        // we can tell when we need to re-render.
        ref.current = menuItem
        setElement(menuItem)
      }
    }, 10)
    return () => clearInterval(interval)
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (element) {
    return ReactDOM.createPortal(children, element)
  }
  return null
}
