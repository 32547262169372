export default theme => ({
  wrapper: {
    height: '100%',
    marginTop: 10,
    width: '90%',
  },
  // shared styles for bars, both bar chart and column chart
  bar: {
    backgroundColor: theme.palette.grey[100],
    background: `linear-gradient(to right, ${theme.palette.grey[100]} 8%, #f8f8f8 18%, ${theme.palette.grey[100]} 33%)`,
    backgroundSize: '800px 104px',
    position: 'relative',
    display: 'flex',
  },
  animatedBar: {
    animationDuration: '3s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: `$barChartShimmer`,
    animationTimingFunction: 'linear',
  },
  staticBar: {
    background: theme.palette.grey[100],
  },
  // bar area containers
  barChart: {
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  },
  columnChart: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 80px)',
    justifyContent: 'space-between',
    minHeight: 330,
    padding: '20px 40px 40px',
  },
  // styles specific to each bar or column
  graphBar: {
    height: 40,
    margin: '0 0 20px 0',
  },
  graphColumn: {
    height: '100%',
    width: 50,
  },
  // chart title "bar"
  barChartTitle: {
    marginBottom: 40,
    height: 20,
    width: '40%',
  },
  // used for all scorecard "bar"s
  scorecardBar: {
    height: 20,
    margin: 0,
  },
  singleBar: {
    height: 20,
    margin: 0,
  },
  '@keyframes barChartShimmer': {
    '0%': {
      backgroundPosition: '-800px 0',
    },
    '100%': {
      backgroundPosition: '800px 0',
    },
  },
})
