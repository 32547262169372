import { Typography, withStyles } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import momenttz from 'moment-timezone'

const TIME_FORMAT = 'HH:mm ss'
const DATE_FORMAT = 'D MMM YYYY'

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    minWidth: 200,
  },
  popupContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  popupRow: {
    display: 'flex',
    marginTop: 4,
    borderBottom: '1px solid rgb(230, 230, 230)',
    height: 18,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  popupLocationText: {
    fontSize: 12,
    letterSpacing: 0.2,
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  locationWrapper: {
    whiteSpace: 'nowrap',
  },
  locationTitle: {
    color: theme.palette.grey[300],
  },
  locationDescription: {
    color: theme.palette.grey[300],
    fontSize: 12,
    marginTop: -10,
    marginBottom: theme.spacing(1),
  },
  popupHeader: {
    letterSpacing: 0.2,
    marginBottom: theme.spacing(1),
  },
  title: {
    letterSpacing: 0.2,
    fontSize: 14,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  eventsWrapper: {
    minHeight: 110,
    height: 110, // Necessary for IE to center vertically
    whiteSpace: 'nowrap',
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  containerEventText: {
    fontSize: 12,
    color: 'white',
  },
  eventText: {
    color: theme.palette.grey[500],
    fontSize: 12,
  },
  closeIcon: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
    width: 19,
    height: 19,
    marginLeft: -4,
  },
})

class LocationPopupDetail extends React.Component {
  state = {
    localTime: null,
    localDate: null,
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    popupData: PropTypes.object.isRequired,
  }

  tick = () => {
    const { popupData } = this.props
    const localTime = momenttz.tz(get(popupData, 'location.timezone')).format(TIME_FORMAT)
    this.setState({
      localTime,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(nextProps, 'popupData.location.timezone') !==
      get(this.props, 'popupData.location.timezone')
    ) {
      const { popupData } = nextProps
      const localDate = momenttz.tz(get(popupData, 'location.timezone')).format(DATE_FORMAT)
      this.setState({
        localDate,
      })
    }
  }

  componentDidMount() {
    const { popupData } = this.props
    const localTime = momenttz.tz(get(popupData, 'location.timezone')).format(TIME_FORMAT)
    const localDate = momenttz.tz(get(popupData, 'location.timezone')).format(DATE_FORMAT)
    this.setState(
      {
        localTime,
        localDate,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000)
      }
    )
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  getLocationDescription = () => {
    const { popupData } = this.props
    const markerType = get(popupData, 'markerType')
    const rawDescription = get(popupData, 'type')
    if (markerType === 'load') return 'Origin port'
    if (rawDescription === 'discharge_port') return 'Discharge port'
    if (rawDescription === 'transshipment_port') return 'Transshipment port'
    if (rawDescription === 'inland_port' && markerType === 'delivery') return 'Final location'
  }

  render() {
    const { classes, popupData, onClose } = this.props
    const { localTime, localDate } = this.state

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{get(popupData, 'location.name')}</div>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.popupContent}>
          <div className={classes.locationWrapper}>
            <Typography variant="body2" className={classes.locationDescription}>
              {this.getLocationDescription()}
            </Typography>
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Unlocode: </span>
              {get(popupData, 'location.unlocode')}
            </div>
            {localDate && (
              <div className={classes.popupLocationText}>
                <span className={classes.locationTitle}>Local Date: </span>
                {localDate}
              </div>
            )}
            {localTime && (
              <div className={classes.popupLocationText}>
                <span className={classes.locationTitle}>Local Time: </span>
                {localTime}
              </div>
            )}
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Latitude: </span>
              {get(popupData, 'location.lat')}
            </div>
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Longitude: </span>
              {get(popupData, 'location.lon')}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(LocationPopupDetail)
