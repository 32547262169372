export const CUSTOM_FIELDS_REDUCER_KEY = 'customFields'

export const types = {
  FETCH_CUSTOM_FIELDS_START: `${CUSTOM_FIELDS_REDUCER_KEY}/FETCH_CUSTOM_FIELDS_START`,
  FETCH_CUSTOM_FIELDS_SUCCESS: `${CUSTOM_FIELDS_REDUCER_KEY}/FETCH_CUSTOM_FIELDS_SUCCESS`,
  FETCH_CUSTOM_FIELDS_FAILURE: `${CUSTOM_FIELDS_REDUCER_KEY}/FETCH_CUSTOM_FIELDS_FAILURE`,
  FETCH_CUSTOM_FIELDS_RESET: `${CUSTOM_FIELDS_REDUCER_KEY}/FETCH_CUSTOM_FIELDS_RESET`,
}

export const fetchCustomFields = (entityType: string, externalId: string) => ({
  type: types.FETCH_CUSTOM_FIELDS_START,
  entityType,
  externalId,
})

export const resetCustomFields = () => ({
  type: types.FETCH_CUSTOM_FIELDS_RESET,
})
