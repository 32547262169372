/**
 * Example:
 * nullRanges([null, 1, null, 2, null]) => [{ start: 1, end: 3 }]
 * @param data A list of numbers (where the numbers can be null).
 * @returns The ranges of null values (not including the head or tail nulls).
 */
export const nullRanges = data => {
  const filledIndexes = data
    .map((d, i) => (d !== null ? i : undefined))
    .filter(i => i !== undefined)

  const ranges = []
  for (let i = 0; i < filledIndexes.length - 1; i++) {
    const curIdx = filledIndexes[i]
    const nextIdx = filledIndexes[i + 1]

    if (nextIdx - curIdx > 1) {
      ranges.push({ start: curIdx, end: nextIdx })
    }
  }

  return ranges
}

/**
 * Finds the ranges in `data` that need to be filled in with gray lines.
 * https://api.highcharts.com/highcharts/plotOptions.series.zones
 * @param data A list of numbers (where the numbers can be null).
 * @param nullColor The color to use for the null data points and line.
 * @returns A list of HighCharts zones.
 */
export const nullZones = (data, nullColor) => {
  const ranges = nullRanges(data)

  const zones = []
  ranges.forEach(rng => {
    // Increment by 0.01 so the dot itself isn't turned grey.
    zones.push({ value: rng.start + 0.01 })
    zones.push({ value: rng.end, color: nullColor, dashStyle: 'dash' })
  })

  return zones
}
