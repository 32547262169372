import {
  AUTOCOMPLETE_KEY,
  EQUIPMENT_AUTOCOMPLETE_KEY,
  PORTAL_USERS_AUTOCOMPLETE_KEY,
  types,
} from './actions'

import omit from 'lodash/omit'

export { AUTOCOMPLETE_KEY }

export const initialState = {
  [EQUIPMENT_AUTOCOMPLETE_KEY]: {
    filterValues: {},
    filterInputs: {},
  },
  [PORTAL_USERS_AUTOCOMPLETE_KEY]: {
    filterValues: {},
    filterInputs: {},
  },
}

const ACTION_HANDLERS = {
  [types.SET_AUTOCOMPLETE_VALUE]: (state, { payload }) => {
    const { autocompleteKey, filter, value } = payload
    return {
      ...state,
      [autocompleteKey]: {
        ...state[autocompleteKey],
        filterValues: { ...state[autocompleteKey].filterValues, [filter.name]: value },
      },
    }
  },
  [types.SET_AUTOCOMPLETE_INPUT]: (state, { payload }) => {
    const { autocompleteKey, filter, value } = payload
    return {
      ...state,
      [autocompleteKey]: {
        ...state[autocompleteKey],
        filterInputs: { ...state[autocompleteKey].filterInputs, [filter.name]: value },
      },
    }
  },
  [types.RESET_AUTOCOMPLETE_FILTER]: (state, { payload }) => {
    const { autocompleteKey, name } = payload

    return {
      ...state,
      [autocompleteKey]: {
        ...state[autocompleteKey],
        filterInputs: { ...omit(state[autocompleteKey].filterInputs, name) },
        filterValues: { ...omit(state[autocompleteKey].filterValues, name) },
      },
    }
  },
  [types.CLEAR_AUTOCOMPLETE_FILTERS]: (state, { payload: autocompleteKey }) => ({
    ...state,
    [autocompleteKey]: initialState[autocompleteKey],
  }),
}

export function autocompleteReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default autocompleteReducer
