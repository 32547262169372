import { BoardColumnInterface, BoardRowInterface } from 'store/board/interfaces'

import DelayCell from './DelayCell'
import DualCell from './DualCell'
import IDCell from './IDCell'
import ListCell from './ListCell'
import React from 'react'
import StandardCell from './StandardCell'
import { cellTypes } from 'store/board/utils/columns'

interface Props {
  className?: string
  column: BoardColumnInterface
  isRowExpanded: boolean
  isRowHovered: boolean
  onContextMenu: () => void
  onRowCollapse: () => {}
  onRowExpand: () => {}
  rollup: string
  row: BoardRowInterface
}

const ConfigurableCell = (props: Props) => {
  const {
    className,
    column,
    isRowExpanded,
    isRowHovered,
    onContextMenu,
    onRowCollapse,
    onRowExpand,
    rollup,
    row,
  } = props
  const dataType = column?.data_type ?? cellTypes.STANDARD

  if (dataType === cellTypes.STANDARD) {
    return (
      <StandardCell
        className={className}
        column={column}
        onContextMenu={onContextMenu}
        rollup={rollup}
        row={row}
      />
    )
  } else if (dataType === cellTypes.ID) {
    return (
      <IDCell
        className={className}
        column={column}
        onContextMenu={onContextMenu}
        rollup={rollup}
        row={row}
      />
    )
  } else if (dataType === cellTypes.DELAY) {
    return (
      <DelayCell
        className={className}
        column={column}
        isRowExpanded={isRowExpanded}
        onContextMenu={onContextMenu}
        onRowCollapse={onRowCollapse}
        onRowExpand={onRowExpand}
        rollup={rollup}
        row={row}
      />
    )
  } else if (dataType === cellTypes.DUAL) {
    return (
      <DualCell
        className={className}
        column={column}
        onContextMenu={onContextMenu}
        rollup={rollup}
        row={row}
      />
    )
  } else if (dataType === cellTypes.LIST) {
    return (
      <ListCell
        className={className}
        column={column}
        isRowExpanded={isRowExpanded}
        isRowHovered={isRowHovered}
        onContextMenu={onContextMenu}
        onRowCollapse={onRowCollapse}
        onRowExpand={onRowExpand}
        rollup={rollup}
        row={row}
      />
    )
  }
  return <></>
}

export default React.memo(ConfigurableCell)
