import { Alert, AlertConfig } from 'utils/alertObjs'

import get from 'lodash/get'

/**
 * take in raw api alert obj and normalize
 * @param config
 * @returns {{orderNumbers: *, name: *, id: *, threshold: *, frequency: number}}
 */
export function structureAlertConfig(config) {
  const name = get(config, 'name')
  const id = get(config, 'id')
  const payload = get(config, 'payload')
  const notifTime = get(config, 'notif_time')
  const notifTimezone = get(config, 'notif_timezone')
  return new AlertConfig(name, id, payload, notifTime, notifTimezone)
}

export function structureAlert(alert) {
  const configID = get(alert, 'alert_config_id')
  const alertedAt = get(alert, 'alerted_at')
  const alertedTripIds = get(alert, 'trip_alert_ids', [])
  const allTripIds = get(alert, 'all_trip_ids', [])
  const payload = get(alert, 'payload')
  const reason = get(alert, 'reason')
  const reference = get(alert, 'ref_number')
  const referenceType = get(alert, 'ref_type')
  const resolvedAt = get(alert, 'resolved_at')
  const shipTo = get(alert, 'ship_to')
  const externalId = get(alert, 'external_id')
  return new Alert(
    configID,
    alertedAt,
    alertedTripIds,
    allTripIds,
    payload,
    reason,
    reference,
    referenceType,
    resolvedAt,
    shipTo,
    externalId
  )
}
