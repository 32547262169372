import {
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarContent,
  SnackbarProps,
  makeStyles,
} from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import { MESSAGE_TYPES } from 'store/notifications'
import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  messageText: {
    maxWidth: theme.spacing(50),
    '& a': {
      color: 'inherit',
    },
    '& a.externalMessageLink': {
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  success: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  info: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
  messageIcon: {
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

// Named CMSnackbarProps due to linter failing on import syntax `{ SnackbarProps as MuiSnackbarProps }`
interface CMSnackbarProps extends SnackbarProps {
  isPersistent: boolean
  type: MESSAGE_TYPES
  onClose: () => {}
}

function Snackbar(props: CMSnackbarProps) {
  const classes = useStyles()
  const {
    anchorOrigin,
    autoHideDuration = 10000,
    isPersistent = false,
    message,
    onClose,
    open,
    type = MESSAGE_TYPES.INFO,
  } = props

  const variantIcon = {
    [MESSAGE_TYPES.SUCCESS]: CheckCircleIcon,
    [MESSAGE_TYPES.WARNING]: WarningIcon,
    [MESSAGE_TYPES.ERROR]: ErrorIcon,
    [MESSAGE_TYPES.INFO]: InfoIcon,
    [MESSAGE_TYPES.DEFAULT]: InfoIcon,
  }
  const MessageIcon = variantIcon[type]

  function getClassName(type: string) {
    switch (type) {
      case MESSAGE_TYPES.DEFAULT:
        return classes.info
      case MESSAGE_TYPES.INFO:
        return classes.info
      case MESSAGE_TYPES.ERROR:
        return classes.error
      case MESSAGE_TYPES.SUCCESS:
        return classes.success
      case MESSAGE_TYPES.WARNING:
        return classes.warning
      default:
        return classes.info
    }
  }

  const effectiveAutoHideDuration = isPersistent ? null : autoHideDuration

  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={effectiveAutoHideDuration}
      ClickAwayListenerProps={
        isPersistent
          ? {
              disableReactTree: false,
              mouseEvent: false,
              touchEvent: false,
            }
          : {}
      }
      onClose={onClose}
      data-testid="notifications"
    >
      <SnackbarContent
        className={getClassName(type)}
        classes={{ root: classes.root }}
        aria-describedby="client-snackbar"
        message={
          <span id="message-id" className={classes.message}>
            <MessageIcon className={classes.messageIcon} />
            <span id="flash" className={classes.messageText}>
              {message}
            </span>
          </span>
        }
        action={
          <IconButton key="close" aria-label="Close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </MuiSnackbar>
  )
}

export default Snackbar
