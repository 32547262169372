const hasHour = (value: string) => {
  return value && value.indexOf(':00') !== -1
}

const isValidHour = (value: string) => {
  const hourNumber = parseInt(value)
  return !isNaN(hourNumber) && hourNumber >= 0 && hourNumber <= 23
}

export const isValidInputString = (value: string) => {
  const numbersAndColonOnly = value.replace(/[^:0-9]/g, '')
  const timeParts = numbersAndColonOnly.split(':')
  if (timeParts.length === 2) {
    const hourNumberStr = timeParts[0]
    const hourMarker = timeParts[1]
    const isValidHourNumber = isValidHour(hourNumberStr)
    return hourMarker === '00' && hourNumberStr.length <= 2 && isValidHourNumber
  }
  return false
}

/**
 * This logic is meant to implement the following rules:
 * 1. only allow editing of number hour and forbid illegal inputs
 * 2. if string is not of the form "<hour>:00", append ':00' to the end
 * 3. allow the hour number to be deleted entirely and empty the entire field when that happens
 */
export const getEffectiveTimeInputString = (newValue: string, oldValue: string) => {
  // If field was cleared out, empty the field (so we can tab through the field and delete it)
  if (!newValue) {
    return ''
  }

  // This is all we will actually consider as valid input, discarding anything besides 0-9 and our appended :00
  const numbersAndColonOnly = newValue.replace(/[^:0-9]/g, '')
  const newValueHasHour = hasHour(numbersAndColonOnly)
  const oldValueHasHour = hasHour(oldValue)

  // Only allow input if it matches the input rules above. if it is not valid, don't allow it
  if (!newValueHasHour && (!oldValueHasHour || numbersAndColonOnly.length === 1)) {
    const numbersOnly = newValue.replace(/[^0-9]/g, '')
    const isValidHourNumber = isValidHour(numbersOnly)
    if (isValidHourNumber) {
      return numbersOnly + ':00'
    }
  } else if (newValue === ':00') {
    // Empty out the input if the last input hour digit has been deleted
    return ''
  } else {
    // only allow <hour>:00 where parseInt(hour) is in [0, 1, ..., 23] inclusive
    const timeParts = numbersAndColonOnly.split(':')
    if (timeParts.length === 2) {
      if (isValidInputString(numbersAndColonOnly)) {
        return numbersAndColonOnly
      }
    }
  }
  // Return nothing, so the input will be rejected
}
