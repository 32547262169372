import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'

import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import { PortalUser } from './models'
import api from 'store/portalUsers/api'
import { confirmSaga } from 'store/confirmation'
import logger from 'utils/logger'
import { setSubmitting } from 'store/portalUsers/actions'
import { types } from 'store/portalUsers/actions'

export function* fetchPortalUsersSaga() {
  try {
    const response = yield call(api.fetchPortalUsers)
    const portalUsers = response && response.data.map(user => PortalUser.of(user))
    yield put({ type: types.FETCH_PORTAL_USERS_SUCCESS, payload: portalUsers })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_PORTAL_USERS_ERROR })
  }
}

export function* deletePortalUserSaga({ payload }) {
  try {
    const confirmed = yield call(confirmSaga, CONFIRMATION_MESSAGES.deletePortalUser)

    if (!confirmed) {
      return
    }

    yield call(api.deletePortalUser, { externalId: payload })
    yield put({ type: types.DELETE_PORTAL_USER_SUCCESS })
    yield put(
      showNotification(NOTIFICATION_MESSAGES.deletePortalUserSuccess, {
        type: MESSAGE_TYPES.SUCCESS,
      })
    )
    yield call(fetchPortalUsersSaga)
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.deletePortalUserError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

/**
 * @todo Need to decide how to handle multiple email addresses. The API expects one user/email at a
 * time. Since all users/emails submitted will share permissions we take in an array of emails and
 * will need to loop over them. The quesation is how to do that knowing each can return a different
 * HTTP response code, errors, etc.
 * @param payload object
 * {
 *  emails: [string,] - note plural and array, to handle adding multiple emails with same permissions
 *  fields: [[{key: string, urlParams: string, value: string},],],
 *  savedShipmentViewIds: [string,]
 * }
 */

export function* createPortalUsersSaga({ payload }) {
  try {
    const { emails = [], fields, savedShipmentViewIds } = payload

    yield put(setSubmitting(true))
    const errorEmails = []

    for (let email of emails) {
      try {
        yield call(api.createPortalUser, {
          email,
          fields,
          savedShipmentViewIds,
        })
      } catch (e) {
        logger.error(e)
        logger.captureAPIException(e)
        errorEmails.push(email)
      }
    }

    // all successful
    if (errorEmails.length === 0) {
      yield put({ type: types.CREATE_PORTAL_USERS_SUCCESS })
      yield put(
        showNotification(NOTIFICATION_MESSAGES.createPortalUserSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
      // we'll show the email(s) that failed? Not much they can do with it.
    } else {
      yield put(
        showNotification(
          `${NOTIFICATION_MESSAGES.createPortalUserError} ${errorEmails.join(', ')}`,
          {
            type: MESSAGE_TYPES.ERROR,
          }
        )
      )
    }
    yield put({ type: types.FETCH_PORTAL_USERS_START })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.createPortalUserAllError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }

  yield put(setSubmitting(false))
}

export const sagas = [
  takeLatest(types.FETCH_PORTAL_USERS_START, fetchPortalUsersSaga),
  takeLatest(types.DELETE_PORTAL_USER_START, deletePortalUserSaga),
  takeLatest(types.CREATE_PORTAL_USERS_START, createPortalUsersSaga),
]
