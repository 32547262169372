// Util to convert from API response of a filter group
// to the frontend representation of a filter group
import PropTypes from 'prop-types'
import get from 'lodash/get'
export const RouteFilterGroup = {
  of: (attrs = {}) => ({
    id: attrs.id,
    name: attrs.name,
    source: attrs.source,
    params: {
      dateRange: get(attrs, 'params.dateRange', {}),
      filters: get(attrs, 'params.filters', []),
      groupBy: get(attrs, 'params.groupBy', []),
    },
  }),
}

export const RouteFilterGroupType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  params: PropTypes.shape({
    dateRange: PropTypes.object,
    filters: PropTypes.array,
    groupBy: PropTypes.arrayOf(PropTypes.string),
  }),
})
