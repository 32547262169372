export const styles = theme => ({
  root: {
    // the margin and calc are so we don't cover the boxshadow on the right of the drawer */
    marginRight: 4,
    width: 'calc( 100% - 4px)',
    backgroundColor: theme.palette.grey[50],
    padding: `0 0 10px 0px`,
    position: 'absolute',
    bottom: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: '5%',
    marginRight: '5%',
  },
})
