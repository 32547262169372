import { LIST_VIEW, TABLE_VIEW, getDefaultColumnHidden } from 'store/boardUtils'
import {
  currentPageSelector,
  shipmentsSelectedCountSelector,
  shipmentsTotalCountSelector,
  shipmentsTotalPagesSelector,
} from 'store/board/selectors'
import {
  getFormattedColumnsSortedByDefault,
  getHideableColumns,
  getSortableColumns,
  rollupColumnHiddenSelector,
  rollupSelector,
  sortStateSelector,
  viewTypeSelector,
} from 'store/boardUtils/selectors'
import { setSortState, toggleColumns, updateColumnOrder } from 'store/boardUtils/actions'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import ColumnToggle from 'components/core/ConfigurableTable/TableControls/ColumnToggle'
import ExhaustiveSelector from 'pages/BoardPage/TableView/ExhaustiveSelector'
import InternalUserComponent from 'components/InternalUserComponent'
import { MAX_NUM_SHIPMENT_ROWS } from 'store/board'
import Paginator from 'components/core/Paginator'
import PropTypes from 'prop-types'
import React from 'react'
import SelectedSummary from './SelectedSummary'
import SortChooser from 'components/core/ConfigurableTable/TableControls/SortChooser'
import TransportationStatusDropdown from 'components/TransportationStatusDropdown'
import classnames from 'classnames'
import logger from 'utils/logger'
import { makeStyles } from '@material-ui/core/styles'
import { setPage } from 'store/board/actions'

const useStyles = makeStyles(theme => ({
  sortChooser: {
    marginRight: theme.spacing(3),
    marginBottom: 8,
  },
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 12,
  },
  control: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  selector: {
    marginBottom: theme.spacing(1),
  },
  shareButton: {
    marginBottom: 8,
    marginRight: theme.spacing(2),
  },
  exportButton: {
    marginBottom: 8,
  },
  paginator: {
    marginBottom: theme.spacing(1),
  },
  spacingRight: {
    marginRight: theme.spacing(3),
  },
  divider: {
    height: theme.spacing(5),
    borderLeft: `1px solid ${theme.palette.grey[50]}`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  transportationStatusDropdown: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    margin: `0 ${theme.spacing(1)}px 0`,
    paddingRight: theme.spacing(1.5),
  },
}))

const TableControls = function ({ className }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentPage = useSelector(currentPageSelector)
  const defaultColumnOrder = useSelector(getFormattedColumnsSortedByDefault)
  const defaultHiddenColumns = useSelector(getDefaultColumnHidden)
  const hiddenColumns = useSelector(rollupColumnHiddenSelector)
  const hideableColumns = useSelector(getHideableColumns)
  const rollup = useSelector(rollupSelector)
  const selectedCount = useSelector(shipmentsSelectedCountSelector)
  const sortableCols = useSelector(getSortableColumns)
  const sortState = useSelector(sortStateSelector)
  const totalCount = useSelector(shipmentsTotalCountSelector)
  const totalPages = useSelector(shipmentsTotalPagesSelector)
  const viewType = useSelector(viewTypeSelector)

  const formattedColumns = hideableColumns.map(({ key, title }) => ({
    key,
    title,
  }))

  const handleSetSortState = newState => {
    dispatch(setSortState(newState))
    logger.notify('Shipment Board Sort', newState)
  }

  const handlePageNext = () => {
    const nextPage = currentPage + 1
    logger.notify('View Shipments Page', { viewType, page: nextPage })
    dispatch(setPage(nextPage))
  }

  const handlePagePrev = () => {
    const prevPage = currentPage - 1
    logger.notify('View Shipments Page', { viewType, page: prevPage })
    dispatch(setPage(prevPage))
  }

  return (
    <>
      <Box display="flex">
        {viewType === LIST_VIEW && <ExhaustiveSelector type="timeline" />}

        <SelectedSummary
          classes={classes}
          rollup={rollup}
          totalCount={totalCount}
          selectedCount={selectedCount}
        />
      </Box>
      <div className={classnames(className, classes.controlsWrapper)}>
        {viewType === LIST_VIEW && (
          <SortChooser
            setSortState={handleSetSortState}
            sortState={sortState}
            sortableCols={sortableCols}
            className={classes.sortChooser}
            rollup={rollup}
          />
        )}
        <InternalUserComponent
          alternateComponent={
            <TransportationStatusDropdown
              classes={{ root: classes.transportationStatusDropdown }}
              data-testid="table-controls__transportation-status"
            />
          }
        >
          {null}
        </InternalUserComponent>

        {viewType === TABLE_VIEW && (
          <ColumnToggle
            className={classes.control}
            columns={formattedColumns}
            hiddenColumns={hiddenColumns}
            rollup={rollup}
            onSubmit={payload => {
              dispatch(toggleColumns(payload))
              dispatch(updateColumnOrder(payload))
            }}
            defaultColumnOrder={defaultColumnOrder}
            defaultHiddenColumns={defaultHiddenColumns}
          />
        )}
        <Paginator
          variant={'horizontal'}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={MAX_NUM_SHIPMENT_ROWS}
          totalCount={totalCount}
          onPrevClick={handlePagePrev}
          onNextClick={handlePageNext}
          extraClasses={classnames(classes.paginator, {
            [classes.spacingRight]: viewType === LIST_VIEW,
          })}
        />
      </div>
    </>
  )
}

TableControls.propTypes = {
  className: PropTypes.string,
}
export default TableControls
