import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import shuffle from 'lodash/shuffle'
import styles from '../styles'
import { withStyles } from '@material-ui/core'

const ColumnChartLoader = ({ classes, isAnimated = false }) => {
  const height = 100
  const staggerBy = 20
  return (
    <div className={classes.wrapper} data-testid="column-chart-loader">
      <div
        className={classnames(classes.bar, classes.graphBar, classes.barChartTitle, {
          [classes.animatedBar]: isAnimated,
          [classes.staticBar]: !isAnimated,
        })}
      />
      <div className={classes.columnChart}>
        {shuffle([0, 1, 2, 3, 4]).map((val, i) => {
          const offset = height - val * staggerBy
          const animationDelay = `${i * 290}ms`
          return (
            <div
              key={`column-${i}`}
              style={{
                height: `${offset}%`,
                animationDelay,
              }}
              className={classnames(classes.bar, classes.graphColumn, {
                [classes.animatedBar]: isAnimated,
                [classes.staticBar]: !isAnimated,
              })}
            />
          )
        })}
      </div>
    </div>
  )
}

ColumnChartLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  isAnimated: PropTypes.bool,
}

export default withStyles(styles, { withTheme: true })(ColumnChartLoader)
