import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import groupMilestonesByStage from './groupMilestonesByStage'
import groupMilestonesForUi from './groupMilestonesForUi'
import logger from 'utils/logger'
import sortMilestonesByUnlocode from './sortMilestonesByUnlocode'

/**
 * Transforms API milestones into grouped-by-stage milestones for the UI
 * @param milestones array of trip milestones
 * @returns object of UI-grouped milestones
 */
export const getGroupedMilestones = (milestones: GroupedMilestoneInterface[]) => {
  const groupedByStageMilestones = groupMilestonesByStage(milestones)
  const sortedByLocation = sortMilestonesByUnlocode(groupedByStageMilestones)
  const uiGroups = groupMilestonesForUi(sortedByLocation)

  // Keeping here for now to help with debugging logic
  logger.groupLog('Milestones')
  logger.groupLog('Raw milestones')
  logger.allEnvLog(milestones)
  logger.groupLog('Raw milestones', true)
  logger.groupLog('Grouped milestones')
  logger.allEnvLog(uiGroups)
  logger.groupLog('Grouped milestones', true)
  logger.groupLog('Milestones', true)
  return uiGroups
}

export default getGroupedMilestones
