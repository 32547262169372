import {
  ADMIN_ROUTE,
  ALERTS_ROUTE,
  ALERT_DETAIL_ROUTE,
  AUTH_BASE_PREFIX,
  BOARD_ROUTE,
  BOL_ROUTE,
  BOOKING_ROUTE,
  CONTAINER_ROUTE,
  DEPRECATED_BOL_ROUTE,
  DEPRECATED_BOOKING_ROUTE,
  DEPRECATED_CONTAINER_ROUTE,
  DEPRECATED_ORDER_ITEM_ROUTE,
  DEPRECATED_PURCHASE_ORDER_ITEM_ROUTE,
  DEPRECATED_PURCHASE_ORDER_ROUTE,
  DEPRECATED_SALES_ORDER_ITEM_ROUTE,
  DEPRECATED_SALES_ORDER_ROUTE,
  DEPRECATED_SHIPMENT_ROUTE,
  DEPRECATED_STOCK_TRANSFER_ORDER_ITEM_ROUTE,
  DEPRECATED_STOCK_TRANSFER_ORDER_ROUTE,
  DOCS_ROUTE,
  DOWNLOADS_ROUTE,
  EXTERNAL_USER_PORTAL_ROUTE,
  FOUR_OH_FOUR_ROUTE,
  HOME_ROUTE,
  LAB_ROUTE,
  LANES_ROUTE,
  LOGIN_ROUTE,
  ORDER_ITEM_ROUTE,
  PASSWORD_RESET_CONFIRM_ROUTE,
  PASSWORD_RESET_ROUTE,
  PLANNING_ROUTE,
  PORTAL_SETTINGS_ROUTE,
  PUBLIC_SHARE_ROUTE,
  PURCHASE_ORDER_ITEM_ROUTE,
  PURCHASE_ORDER_ROUTE,
  REGISTER_ROUTE,
  REQUEST_INVITATION_ROUTE,
  SALES_ORDER_ITEM_ROUTE,
  SALES_ORDER_ROUTE,
  SETTINGS_ROUTE,
  SHARES_ROUTE,
  SHIPMENT_ROUTE,
  SSO_CHOOSE_ROUTE,
  SSO_EMAIL_ROUTE,
  SSO_PASSWORD_ROUTE,
  STOCK_TRANSFER_ORDER_ITEM_ROUTE,
  STOCK_TRANSFER_ORDER_ROUTE,
  UPLOAD_ROUTE,
} from 'utils/routes'
import {
  BolPage,
  BookingPage,
  LineItemPage,
  PurchaseOrderPage,
  SalesOrderPage,
  ShipmentPage,
  StockTransferOrderPage,
} from 'pages/EntityPage/rollups'
import {
  PLANNING_FEATURE,
  PLANNING_PAYWALL_PAGE_FEATURE,
  USER_DASHBOARD_FEATURE,
} from 'utils/featureFlags'
import React, { useEffect } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { fromQueryString, toQueryString } from 'utils/urlBuilder'
import { setAuthStage, setLoginErrorMessage } from 'store/auth/login/actions'
import { useDispatch, useSelector } from 'react-redux'
import { userIsAdmin, userIsAuthenticated } from './utils'

import AdminPage from 'pages/Admin/AdminHomePage'
import AlertsPage from 'pages/AlertsPage'
import ApiDocsPage from 'pages/ApiDocsPage'
import BaseLayout from 'components/layouts/BaseLayout'
import BoardPage from 'pages/BoardPage'
import ContainerPage from 'pages/ContainerPage'
import CoreLayout from 'components/layouts/PageLayout'
import DownloadPage from 'pages/DownloadPage'
import EmailPage from 'pages/FlaggedLogin/LoginPage/EmailPage'
import FeatureFlagComponent from 'components/FeatureFlagComponent'
import FlaggedLogin from 'pages/FlaggedLogin'
import InternalUserComponent from 'components/InternalUserComponent'
import LabPage from 'pages/LabPage'
import NotFoundPage from 'pages/NotFoundPage'
import OrderUploadPage from 'pages/OrderUploadPage'
import PasswordLoginPage from 'pages/FlaggedLogin/PasswordLoginPage'
import PaywallPage from 'pages/PaywallPage'
import PlanningPage from 'pages/PlanningPage'
import PortalSettingsPage from 'pages/PortalSettingsPage'
import PublicSharedPage from 'pages/PublicSharedPage'
import RegistrationPage from 'pages/RegistrationPage'
import RequestInvitationPage from 'pages/FlaggedLogin/LoginPage/RequestInvitationPage'
import ResetPasswordConfirmPage from 'pages/ResetPasswordConfirmPage'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import SSOLoginPage from 'pages/FlaggedLogin/LoginPage/SSOLoginPage'
import SettingsPage from 'pages/SettingsPage'
import SharedLinksPage from 'pages/SharedLinksPage'
import { TENANT_PARAM } from 'components/layouts/AuthLayout'
import UserDashboardPage from 'pages/UserDashboardPage'
import client from 'utils/api/client'
import get from 'lodash/get'
import history from 'utils/history'
import logger from 'utils/logger'
import { resetOauthStatus } from 'store/oauthStatus/actions'
import { showInternetExplorerDeprecationWarning } from 'store/auth/user/actions'
import { userFromState } from 'store/auth/user/selectors'

const withBaseLayout = WrappedComponent => {
  return props => {
    return (
      <BaseLayout>
        <WrappedComponent {...props} />
      </BaseLayout>
    )
  }
}

const userIsInternal = WrappedComponent => props => (
  <InternalUserComponent alternateComponent={<Redirect to={FOUR_OH_FOUR_ROUTE.buildUrl()} />}>
    <WrappedComponent {...props} />
  </InternalUserComponent>
)

const genericEntityRedirect = (redirectRoute, apiRefType) => props => {
  const number = props.match.params.id
  // make sure to not drop extensions or search parameters when redirecting
  // see alert_email_share_modal.spec.js for an example of this occurring
  const extension = props.match.params.extension
  const search = props.location.search || ''
  const query = fromQueryString(search)
  const urlTenant = get(query, TENANT_PARAM, null)
  const user = useSelector(userFromState)
  const switchingTenant = user.tenant && urlTenant && urlTenant !== user.tenant
  if (!switchingTenant) {
    client
      .get(`/internal/v1/${apiRefType}/external_id?number=${number}`)
      .then(response => {
        const externalId = response.data.external_id
        const newUrl = redirectRoute.buildUrl({ number, externalId, extension }) + search
        history.replace(newUrl)
      })
      .catch(e => {
        logger.error(e)
        history.replace(FOUR_OH_FOUR_ROUTE.buildUrl())
      })
  }
  return null
}

const genericOrderEntityRedirect = (redirectRoute, orderType) => props => {
  const number = props.match.params.id
  const extension = props.match.params.extension
  const search = props.location.search || ''
  const query = fromQueryString(search)
  const urlTenant = get(query, TENANT_PARAM, null)
  const user = useSelector(userFromState)
  const switchingTenant = user.tenant && urlTenant && urlTenant !== user.tenant
  if (!switchingTenant) {
    client
      .get(`/internal/v1/orders/external_id?number=${number}&order_type=${orderType}`)
      .then(response => {
        const externalId = response.data.external_id
        const newUrl = redirectRoute.buildUrl({ number, externalId, extension }) + search
        history.replace(newUrl)
      })
      .catch(e => {
        logger.error(e)
        history.replace(FOUR_OH_FOUR_ROUTE.buildUrl())
      })
  }
  return null
}

const orderItemEntityRedirect = (redirectRoute, orderType) => props => {
  const orderNumber = props.match.params.orderId
  const itemNumber = props.match.params.itemId
  const backendItemNumber = `${orderNumber}-${itemNumber}`
  const extension = props.match.params.extension
  const search = props.location.search || ''
  let url = `/internal/v1/line_items/external_id?number=${backendItemNumber}`
  if (orderType) {
    url += `&order_type=${orderType}`
  }
  const query = fromQueryString(search)
  const urlTenant = get(query, TENANT_PARAM, null)
  const user = useSelector(userFromState)
  const switchingTenant = user.tenant && urlTenant && urlTenant !== user.tenant
  if (!switchingTenant) {
    client
      .get(url)
      .then(response => {
        const externalId = response.data.external_id
        const newUrl =
          redirectRoute.buildUrl({ orderNumber, itemNumber, externalId, extension }) + search
        history.replace(newUrl)
      })
      .catch(e => {
        logger.error(e)
        history.replace(FOUR_OH_FOUR_ROUTE.buildUrl())
      })
  }
  return null
}

// This is necessary to prevent remounting of the main component
// see: https://github.com/mjrussell/redux-auth-wrapper/issues/224
const HomePageComponent = props => (
  <InternalUserComponent
    alternateComponent={<Redirect to={EXTERNAL_USER_PORTAL_ROUTE.buildUrl()} />}
  >
    <FeatureFlagComponent
      flag={USER_DASHBOARD_FEATURE}
      alternateComponent={<Redirect to={BOARD_ROUTE.buildUrl()} />}
    >
      <UserDashboardPage />
    </FeatureFlagComponent>
  </InternalUserComponent>
)

const LabPageComponent = userIsAdmin(userIsInternal(LabPage))
const DocsPageComponent = userIsInternal(withBaseLayout(ApiDocsPage))
const NotFoundPageComponent = withBaseLayout(NotFoundPage)
// this is currently the only component that actually requires an external user
const ExternalUserPageComponent = props => {
  return (
    <InternalUserComponent alternateComponent={<BoardPage {...props} />}>
      <Redirect to={FOUR_OH_FOUR_ROUTE.buildUrl()} />
    </InternalUserComponent>
  )
}

const PortalSettingsPageComponent = userIsAdmin(userIsInternal(PortalSettingsPage))
const AdminPageComponent = userIsAdmin(userIsInternal(AdminPage))
const SettingsPageComponent = userIsInternal(SettingsPage)
const OrderUploadPageComponent = userIsAdmin(userIsInternal(OrderUploadPage))
const BoardPageComponent = userIsInternal(BoardPage)
const AlertPageComponent = userIsInternal(AlertsPage)
const SharedPageComponent = userIsInternal(SharedLinksPage)

const PlanningPageComponent = userIsInternal(() => {
  return (
    <FeatureFlagComponent
      flag={PLANNING_FEATURE}
      alternateComponent={<Redirect to={FOUR_OH_FOUR_ROUTE.buildUrl()} />}
    >
      <FeatureFlagComponent
        flag={PLANNING_PAYWALL_PAGE_FEATURE}
        alternateComponent={<PlanningPage />}
      >
        <PaywallPage />
      </FeatureFlagComponent>
    </FeatureFlagComponent>
  )
})

const AuthenticatedPages = userIsAuthenticated(() => {
  return (
    <CoreLayout>
      <Switch>
        {/* Redirects */}
        <Route
          path={'/download/file/:fileId'}
          exact={true}
          component={({ match }) => {
            const { fileId } = match.params
            const to = {
              pathname: DOWNLOADS_ROUTE.buildUrl(),
              search: toQueryString({ fileId }),
            }
            return <Redirect to={to} />
          }}
        />

        {/* Routes */}
        <Route path={DOCS_ROUTE.path} exact={true} component={DocsPageComponent} />
        <Route path={LAB_ROUTE.path} exact={true} component={LabPageComponent} />
        <Route
          path={EXTERNAL_USER_PORTAL_ROUTE.path}
          exact={true}
          component={ExternalUserPageComponent}
        />
        <Route path={HOME_ROUTE.path} exact={true} component={HomePageComponent} />
        <Route path={ADMIN_ROUTE.path} exact={true} component={AdminPageComponent} />
        <Route path={SETTINGS_ROUTE.path} exact={true} component={SettingsPageComponent} />
        <Route path={UPLOAD_ROUTE.path} exact={true} component={OrderUploadPageComponent} />
        <Route
          path={LANES_ROUTE.path}
          exact={true}
          component={() => <Redirect to={PLANNING_ROUTE.path} />}
        />
        <Route path={PLANNING_ROUTE.path} exact={true} component={PlanningPageComponent} />
        <Route path={BOARD_ROUTE.path} exact={true} component={BoardPageComponent} />
        <Route
          path={PORTAL_SETTINGS_ROUTE.path}
          exact={true}
          component={PortalSettingsPageComponent}
        />

        {/* ENTITY PAGES */}
        <Route
          path={DEPRECATED_BOOKING_ROUTE.path}
          exact={true}
          component={genericEntityRedirect(BOOKING_ROUTE, 'bookings')}
        />
        <Route path={BOOKING_ROUTE.path} exact={true} component={BookingPage} />

        <Route
          path={DEPRECATED_BOL_ROUTE.path}
          exact={true}
          component={genericEntityRedirect(BOL_ROUTE, 'bols')}
        />
        <Route path={BOL_ROUTE.path} exact={true} component={BolPage} />

        {/* Order item _must_ come before order. */}
        <Route
          path={DEPRECATED_ORDER_ITEM_ROUTE.path}
          exact={true}
          component={orderItemEntityRedirect(ORDER_ITEM_ROUTE, null)}
        />
        <Route path={ORDER_ITEM_ROUTE.path} exact={true} component={LineItemPage} />

        <Route
          path={DEPRECATED_SALES_ORDER_ITEM_ROUTE.path}
          exact={true}
          component={orderItemEntityRedirect(SALES_ORDER_ITEM_ROUTE, 'sales')}
        />
        <Route path={SALES_ORDER_ITEM_ROUTE.path} exact={true} component={LineItemPage} />

        <Route
          path={DEPRECATED_PURCHASE_ORDER_ITEM_ROUTE.path}
          exact={true}
          component={orderItemEntityRedirect(PURCHASE_ORDER_ITEM_ROUTE, 'purchase')}
        />
        <Route path={PURCHASE_ORDER_ITEM_ROUTE.path} exact={true} component={LineItemPage} />

        <Route
          path={DEPRECATED_STOCK_TRANSFER_ORDER_ITEM_ROUTE.path}
          exact={true}
          component={orderItemEntityRedirect(STOCK_TRANSFER_ORDER_ITEM_ROUTE, 'stock_transfer')}
        />
        <Route path={STOCK_TRANSFER_ORDER_ITEM_ROUTE.path} exact={true} component={LineItemPage} />

        <Route
          path={DEPRECATED_SALES_ORDER_ROUTE.path}
          exact={true}
          component={genericOrderEntityRedirect(SALES_ORDER_ROUTE, 'sales')}
        />
        <Route path={SALES_ORDER_ROUTE.path} exact={true} component={SalesOrderPage} />

        <Route
          path={DEPRECATED_PURCHASE_ORDER_ROUTE.path}
          exact={true}
          component={genericOrderEntityRedirect(PURCHASE_ORDER_ROUTE, 'purchase')}
        />
        <Route path={PURCHASE_ORDER_ROUTE.path} exact={true} component={PurchaseOrderPage} />

        <Route
          path={DEPRECATED_STOCK_TRANSFER_ORDER_ROUTE.path}
          exact={true}
          component={genericOrderEntityRedirect(STOCK_TRANSFER_ORDER_ROUTE, 'stock_transfer')}
        />
        <Route
          path={STOCK_TRANSFER_ORDER_ROUTE.path}
          exact={true}
          component={StockTransferOrderPage}
        />

        <Route
          path={DEPRECATED_SHIPMENT_ROUTE.path}
          exact={true}
          component={genericEntityRedirect(SHIPMENT_ROUTE, 'shipments')}
        />
        <Route path={SHIPMENT_ROUTE.path} exact={true} component={ShipmentPage} />

        <Route
          path={DEPRECATED_CONTAINER_ROUTE.path}
          exact={true}
          component={props => {
            const { id, externalId, extension } = props.match.params
            const to = {
              pathname: CONTAINER_ROUTE.buildUrl({ number: id, externalId, extension }),
              search: props.location.search,
            }
            return <Redirect to={to} />
          }}
        />
        <Route path={CONTAINER_ROUTE.path} exact={true} component={ContainerPage} />

        {/* END ENTITY PAGES */}

        <Route path={ALERTS_ROUTE.path} exact={true} component={AlertPageComponent} />
        <Route path={ALERT_DETAIL_ROUTE.path} exact={true} component={AlertPageComponent} />
        <Route path={SHARES_ROUTE.path} exact={true} component={SharedPageComponent} />
        <Route path={DOWNLOADS_ROUTE.path} exact={true} component={DownloadPage} />
        <Route
          component={({ location }) => {
            logger.notify('404 Route', { path: location.pathname })
            return <Redirect to={FOUR_OH_FOUR_ROUTE.path} />
          }}
        />
      </Switch>
    </CoreLayout>
  )
})

function Routes() {
  const dispatch = useDispatch()

  // listen for back button being pressed
  useEffect(() => {
    history.listen((location, action) => {
      // You changed the page to: /auth/sso/email/ with action 'POP'
      if (location.pathname.indexOf(AUTH_BASE_PREFIX) === -1) {
        return
      }
      // back button to email. substring matching used to handle extensions
      if (SSO_EMAIL_ROUTE.path.includes(location.pathname) && action === 'POP') {
        dispatch(resetOauthStatus())
        dispatch(setLoginErrorMessage(null))
        dispatch(setAuthStage('email'))
      } else if (SSO_CHOOSE_ROUTE.path.includes(location.pathname) && action === 'POP') {
        dispatch(setAuthStage('sso'))
        dispatch(setLoginErrorMessage(null))
      } else if (SSO_PASSWORD_ROUTE.path.includes(location.pathname)) {
        dispatch(setAuthStage('password'))
        dispatch(setLoginErrorMessage(null))
      }
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(showInternetExplorerDeprecationWarning())
  }, [dispatch])

  return (
    <Router history={history}>
      <Switch>
        <Route path={LOGIN_ROUTE.path} exact={true} component={withBaseLayout(FlaggedLogin)} />
        <Route path={SSO_CHOOSE_ROUTE.path} exact={true} component={withBaseLayout(SSOLoginPage)} />
        <Route path={SSO_EMAIL_ROUTE.path} exact={true} component={withBaseLayout(EmailPage)} />
        <Route
          path={REQUEST_INVITATION_ROUTE.path}
          exact={true}
          component={withBaseLayout(RequestInvitationPage)}
        />
        <Route
          path={SSO_PASSWORD_ROUTE.path}
          exact={true}
          component={withBaseLayout(PasswordLoginPage)}
        />
        <Route
          path={PASSWORD_RESET_ROUTE.path}
          exact={true}
          component={withBaseLayout(ResetPasswordPage)}
        />
        <Route
          path={REGISTER_ROUTE.path}
          exact={true}
          component={withBaseLayout(RegistrationPage)}
        />
        <Route
          path={PASSWORD_RESET_CONFIRM_ROUTE.path}
          exact={true}
          component={withBaseLayout(ResetPasswordConfirmPage)}
        />
        <Route path={PUBLIC_SHARE_ROUTE.path} exact={true} component={PublicSharedPage} />
        <Route path={FOUR_OH_FOUR_ROUTE.path} exact={true} component={NotFoundPageComponent} />
        <Route component={AuthenticatedPages} />
      </Switch>
    </Router>
  )
}

export default Routes
