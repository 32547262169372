import React from 'react'
import { SvgIcon } from '@material-ui/core'

const OceanIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 66 32">
      <g fill="none" fillRule="evenodd">
        <rect width="66" height="32" />
        <g fill="currentColor" transform="translate(4 4)">
          <path d="M10,14 L12,14 L13.333,18 L48,18 L48,14 L58,14 L52.6428571,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,10 L2,10 L2,14 L3.999,14 L4,7 L4,7 L4,4 L8,4 L8,0 L10,0 L10,4 L12,4 L10,8 L10,14 Z" />
          <polygon fillRule="nonzero" points="24 6 24 10 16 10 16 6" />
          <polygon fillRule="nonzero" points="26 6 34 6 34 10 26 10" />
          <polygon fillRule="nonzero" points="36 6 44 6 44 10 36 10" />
          <polygon fillRule="nonzero" points="16 12 24 12 24 16 16 16" />
          <polygon fillRule="nonzero" points="26 12 34 12 34 16 26 16" />
          <polygon fillRule="nonzero" points="36 12 44 12 44 16 36 16" />
        </g>
      </g>
    </SvgIcon>
  )
}

export default OceanIcon
