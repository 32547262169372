import { API_DELIMITER } from 'utils/filterUtils'
import browserHistory from 'utils/history'
import fromPairs from 'lodash/fromPairs'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

const defaultArgs = {
  protocol: `${window.location.protocol}//`,
  host: window.location.host,
  pathname: '',
  query: {},
}

/**
 * Builds a full URL using `args`
 * @param {object} args | defaultArgs Configuration object
 * @returns string
 *
 * Example:
 * buildUrl({
 *   protocol: 'https://,
 *   host: 'app.clearmetal.com,
 *   pathname: '/share',
 *   query: {
 *     foo: 'bar',
 *     bar: ['foo', 'bar', 'biz']
 *   }
 *  });
 * // -> returns `https://app.clearmetal.com/share?foo=bar&bar=foo||bar||biz`
 */
export default function buildUrl(args = defaultArgs) {
  const { protocol, host, pathname, query } = { ...defaultArgs, ...args }
  return protocol + host + pathname + toQueryString(query)
}

/**
 * Takes an object and converts it to a query string
 * @param {oject} query Object to be converted to query string
 * @returns string
 *
 * Example:
 * toQueryString({
 *   foo: 'bar',
 *   bar: ['foo', 'bar', 'biz']
 * })
 *
 * // returns -> '?foo=bar&bar=foo||bar||biz'
 */
export function toQueryString(query) {
  if (isEmpty(query)) return ''
  const params = []

  for (const key in query) {
    const value = query[key]
    params.push(toParamString(key, value))
  }

  return `?${params.join('&')}`
}

/**
 * Converts a query string back to object form
 * @param {string} search Query string
 * @returns object
 * `foo=bar` => { foo: 'bar' }
 */
export function fromQueryString(search) {
  const params = new URLSearchParams(search)
  const pairs = []
  for (const p of params) {
    pairs.push(p)
  }
  return fromPairs(pairs)
}

/**
 * Converts a param and value to `param=value` or, if `value` is an array, `param=value1|value2``
 * @param {string} param The param name
 * @param {*} value The param value
 * @returns string
 */
export function toParamString(param, value = '') {
  // Allow empty `value` but not `param`
  if (!param) {
    return ''
  }
  if (isArray(value)) return `${param}=${value.join(API_DELIMITER)}`
  if (isString(value)) return `${param}=${value}`
}

/**
 * Gets the current query string params: `?foo=bar
 * @returns string
 */
export function getQueryString() {
  return browserHistory.location?.search || ''
}

/**
 * Updates or adds a querystring param and pushes it to history
 * @param {string} param The param name
 * @param {string} value The param value
 * @returns undefined
 *
 * Example:
 * Before: `https://app.clearmetal.com`
 * `updateQuerySTringParam('foo', 'bar')
 * After: `https://app.clearmetal.com?foo=bar`
 */
export function setQueryStringParam(param, value) {
  if (!param) {
    return
  }
  const { pathname } = browserHistory.location
  const queryString = getQueryString()
  const queryStringToObject = fromQueryString(queryString)
  const url =
    pathname +
    toQueryString({
      ...queryStringToObject,
      [param]: value,
    })
  browserHistory.push(url)
}

/**
 * Removes a query string param if it exists
 * @param {string} param Param to delete
 * @returns undefined
 */
export function removeQueryStringParam(param) {
  const { pathname } = browserHistory.location
  const queryString = getQueryString()
  const queryStringToObject = fromQueryString(queryString)
  delete queryStringToObject[param]
  const url = pathname + toQueryString(queryStringToObject)
  browserHistory.push(url)
}

/**
 * Gets the value of a query string param.
 * Note: use `hooks/useQuery` instead if you need this in a component.
 * @param {string} param Param name to get value for
 * @returnss string | undefined
 */
export function getQueryStringValue(param) {
  const queryString = getQueryString()
  const queryStringToObject = fromQueryString(queryString)
  return queryStringToObject[param]
}
