// Theme is passed from material-ui withStyles
export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  filterMenuPopover: {
    maxHeight: 650,
    pointerEvents: 'initial',
    marginLeft: 100,
  },
  importButtonContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  importButton: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    width: '87%',
    textTransform: 'none',
    border: `1px solid ${theme.brand.color}`,
    color: theme.brand.color,
    '&:hover': {
      backgroundColor: theme.brand.color,
      color: '#fff',
    },
  },
  filterLookup: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  lookupControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  searchInput: {
    flex: 1,
  },
  list: {
    paddingBottom: theme.spacing(3),
  },
  subheader: {
    color: theme.brand.color,
    letterSpacing: 0.2,
    backgroundColor: '#fff',
    width: '100%',
  },
  subheaderIcon: {
    color: theme.brand.color,
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  subheaderTextContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  subheaderTitle: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
  },
  subheaderDescription: {
    fontSize: '.7rem',
    marginTop: -12,
    paddingLeft: 27,
    color: theme.brand.color,
  },
  addButton: {
    textTransform: 'none',
    display: 'flex',
    color: theme.palette.common.lightBlack,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addIcon: {
    marginRight: theme.spacing(0.5),
    width: 23,
    height: 23,
  },
  listItemText: {
    color: theme.palette.grey[800],
    whiteSpace: 'nowrap',
    fontSize: 14,
    marginLeft: 27,
  },
})
