import { GroupLabelInterface } from './interfaces'

// API `stopSegment.tripSegment.stage`s
export const PRE_MAIN = 'pre_main'
export const MAIN = 'main'
export const POST_MAIN = 'post_main'

// All `stopSegment.tripSegment.stage`s
export const STAGES = [PRE_MAIN, MAIN, POST_MAIN]

// UI groups
export const EMPTY_DISPATCH = 'emptyDispatch'
export const PRE_CARRIAGE = 'preCarriage'
export const PORT_OF_LOAD = 'portOfLoad'
export const TRANSSHIPMENT = 'transshipment'
export const PORT_OF_DISCHARGE = 'portOfDischarge'
export const ON_CARRIAGE = 'onCarriage'
export const EMPTY_RETURN = 'emptyReturn'

export const UI_GROUPS = [
  EMPTY_DISPATCH,
  PRE_CARRIAGE,
  PORT_OF_LOAD,
  TRANSSHIPMENT,
  PORT_OF_DISCHARGE,
  ON_CARRIAGE,
  EMPTY_RETURN,
]

// Ui grouping labels displayed in UI
export const GROUP_LABELS: GroupLabelInterface = {
  [EMPTY_DISPATCH]: 'Empty Dispatch',
  [PRE_CARRIAGE]: 'Pre-Carriage',
  [PORT_OF_LOAD]: 'Port of Load',
  [TRANSSHIPMENT]: 'Transshipment',
  [PORT_OF_DISCHARGE]: 'Port of Discharge',
  [ON_CARRIAGE]: 'On-Carriage',
  [EMPTY_RETURN]: 'Empty Return',
}

// <stage>:[UI groups in this stage]
export const STAGE_GROUPS: { [key: string]: Array<string> } = {
  [PRE_MAIN]: [EMPTY_DISPATCH, PRE_CARRIAGE, PORT_OF_LOAD],
  [MAIN]: [TRANSSHIPMENT],
  [POST_MAIN]: [PORT_OF_DISCHARGE, ON_CARRIAGE, EMPTY_RETURN],
}

export const TIMELINE_STAGE_GROUPS: { [key: string]: Array<string> } = {
  [PRE_MAIN]: [PRE_CARRIAGE, PORT_OF_LOAD],
  [MAIN]: [TRANSSHIPMENT],
  [POST_MAIN]: [PORT_OF_DISCHARGE, ON_CARRIAGE],
}

// UI groups that should display status labels, at group and milestone level
export const GROUPS_WITH_STATUSES = [PORT_OF_LOAD, TRANSSHIPMENT, PORT_OF_DISCHARGE, ON_CARRIAGE]

// `milestone.action
export const ACTION_UNLOAD = 'unload'
export const ACTION_DEPART = 'depart'
export const ACTION_LOAD = 'load'
export const ACTION_ARRIVE = 'arrive'

// `milestone.stopSegment.equipmentActivity`
export const ACTIVITY_DISPATCH_RETURN = 'dispatch_return'
export const ACTIVITY_STUFFING = 'stuffing_emptying'

// milestone.onEquipment.category
export const CATEGORY_VESSEL = 'vessel'

export const PROGRESS_COMPLETED = 'Completed'
export const PROGRESS_IN_TRANSIT = 'In-transit'
export const PROGRESS_IN_PROGRESS = 'In Progress'

export const AVAILABLE_FOR_DELVERY = 'available_for_load'
export const AVAILABLE_FOR_DELVERY_LABEL = 'Available for Delivery'
export const EXCEPTION_ROLLED = 'rolled'
// We get these back as string boolean enums. ;-/
export const EXCEPTION_OCCURRED = 'true'
export const EXCEPTION_ROLLED_LABEL = 'Rolled'

// group statuss
export const STATUS_DELAY = 'Delay'
export const STATUS_EARLY = 'Early'
export const STATUS_PREDICTED = 'Predicted'
export const STATUS_ACTUAL = 'Actual'

// types of times: traced, planned, predicted
export const TIMESTAMP_ACTUAL = 'actual'
export const TIMESTAMP_PREDICTED = 'predicted'
export const TIMESTAMP_CARRIER_ESTIMATE = 'estimate'
export const TIMESTAMP_CARRIER_ESTIMATE_LOAD = 'load'
export const TIMESTAMP_CARRIER_ESTIMATE_UNLOAD = 'unload'
export const TIMESTAMP_CARRIER_ESTIMATE_ARRIVE = 'arrive'
export const TIMESTAMP_PLANNED = 'planned'
export const TIMESTAMP_PLANNED_NO_ACTUAL = 'planned-no-actual'

export const TIMESTAMP_LABEL_ACTUAL = 'Actual'
export const TIMESTAMP_LABEL_PLANNED_NO_ACTUAL = 'Latest Carrier Estimate'
export const TIMESTAMP_LABEL_PLANNED = 'Last Carrier Estimate'
export const TIMESTAMP_LABEL_PREDICTED = 'project44 Predicts'
export const TIMESTAMP_LABEL_CARRIER_ESTIMATE = 'Initial Carrier Estimate'

export const TIMESTAMP_TYPES = [
  TIMESTAMP_ACTUAL,
  TIMESTAMP_PREDICTED,
  TIMESTAMP_CARRIER_ESTIMATE_LOAD,
  TIMESTAMP_CARRIER_ESTIMATE_UNLOAD,
  TIMESTAMP_CARRIER_ESTIMATE_ARRIVE,
  TIMESTAMP_PLANNED,
  TIMESTAMP_PLANNED_NO_ACTUAL,
]

export const TIMESTAMP_MESSAGES: { [key: string]: { title: string | null; message: string } } = {
  [TIMESTAMP_ACTUAL]: {
    title: 'Actual Delay or Early Timing',
    message: `Days Delayed or Early times are based on the difference between the Carrier's Actual time and the Initial Carrier Estimate.`,
  },
  [TIMESTAMP_PREDICTED]: {
    title: 'Predicted Delays or Early Estimates',
    message: `Estimated days Delayed or Early times are based on the difference between the project44 Prediction and the Initial Carrier Estimate.`,
  },
  [TIMESTAMP_PLANNED_NO_ACTUAL]: {
    title: 'Latest Delays or Early Estimates',
    message:
      'Delays are based on the difference between the Latest Carrier Estimate and the Initial Carrier Estimate.',
  },
  [TIMESTAMP_PLANNED]: {
    title: 'Last Delay or Early Estimate',
    message:
      'Delays are based on the difference between the Latest Carrier Estimate and the Initial Carrier Estimate.',
  },
  [TIMESTAMP_CARRIER_ESTIMATE_LOAD]: {
    title: 'Initial Carrier Estimate',
    message:
      'This was the Carrier Estimate provided at the time the ocean carrier received it at the terminal.',
  },
  [TIMESTAMP_CARRIER_ESTIMATE_UNLOAD]: {
    title: 'Initial Carrier Estimate',
    message: 'This was the Carrier Estimate provided at the time of load at origin.',
  },
  [TIMESTAMP_CARRIER_ESTIMATE_ARRIVE]: {
    title: 'Initial Carrier Estimate',
    message: 'This was the Carrier Estimate provided at the time of discharge at destination.',
  },
}

// Sets the threshold for when to display delay text in UI.
export const DELAY_DAYS_TO_SHOW = 2
