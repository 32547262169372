import { httpMethods } from 'utils/api/client'
import humps from 'humps'

type HttpMethod = 'get' | 'put'

const api = {
  _call: (method: HttpMethod, endpoint: string, params?: any) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchTripGroups: (tripIds: string[]) => {
    const ids = tripIds?.join(',')
    return api._call('get', `/internal/v1/trips/trip_groups`, { ids })
  },
}

export default api
