import React from 'react'
import SplineSkeleton from './SplineSkeleton'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const SplineLoader = ({ className, isAnimated, count = 4 }) => {
  const classes = useStyles()

  return (
    <div className={cx(classes.root, className)}>
      {[...Array(count)].map((_, i) => (
        <SplineSkeleton key={i} isAnimated={isAnimated} />
      ))}
    </div>
  )
}

export default SplineLoader
