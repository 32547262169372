import AdminComponent from 'components/AdminComponent'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[500],
  },
}))

const AdminTripId = function ({ tripId }) {
  const classes = useStyles()
  return tripId ? (
    <AdminComponent>
      <span className={classes.root}>Trip ID: {tripId}</span>
    </AdminComponent>
  ) : null
}

export default AdminTripId
