import EquipmentCategory from 'store/models/definitions/EquipmentCategory'
import MilestoneAction from 'store/models/definitions/MilestoneAction'
import { MilestoneInterface } from 'store/models/Milestone'
import { VesselTrip } from 'utils/sharedObjs'
import { getLocalizedNow } from 'utils/dateUtils'

export const extractVesselTrips = (milestones: MilestoneInterface[]) => {
  let vesselTrips = []
  // purposefully stop at milestones.length - 2, because we need two milestones - an arrive, and a depart - to
  // create a vessel, and we do a look ahead (i + 1) in this for loop
  for (let i = 0; i < milestones.length - 2; i++) {
    const ms = milestones[i]
    const isVesselStart =
      ms.action === MilestoneAction.depart && ms.onEquipment?.category === EquipmentCategory.vessel
    if (isVesselStart) {
      const startDate = ms.tracedTime ? ms.tracedTime : null
      const nextMs = milestones[i + 1]
      // double check that next milestone is an arrival and that the vessel has indeed departed
      if (nextMs.action === MilestoneAction.arrive && startDate) {
        const isLiveVessel = nextMs.tracedTime === null
        const vesselName = nextMs.onEquipment?.name
        const endDate = isLiveVessel ? getLocalizedNow() : nextMs.tracedTime
        const lloydsCode = nextMs.onEquipment?.lloydsCode
        const trip = new VesselTrip(startDate, endDate, lloydsCode, vesselName, isLiveVessel)
        vesselTrips.push(trip)
      }
    }
  }
  return vesselTrips
}
