import { getLocalizedMoment, getLocalizedNow } from 'utils/dateUtils'

import logger from 'utils/logger'

/**
 * Determines if a predicted time is outdated
 */
export const isPredictionOutdated = (predictedTime, timezone) => {
  try {
    const localizedPredictedTime = getLocalizedMoment(predictedTime, timezone)
    const localizedNow = getLocalizedNow(timezone)
    return localizedPredictedTime < localizedNow
  } catch (e) {
    logger.captureAppException(e)
    logger.localLog(`isPredictionOutdated: ${e}`, 'error')
    return false
  }
}

/**
 * Determines if a milestone in a segment is outdated. Used for board timeline view and entity page timelines.
 * @param {boolean} isTraced `milestone.traced`
 * @param {object} milestone The milestone to use for DateTime comparison to determine staleness
 * @param {integer} [segmentIndex] This segment's index in all segments
 * @param {(integer|undefined)} latestTracedIdx The index of the latest traced segment
 * @returns boolean
 *
 * Rules:
 * - milestones with `traced: true` are never stale
 * - the current segment cannot have a stale milestone if it comes _before_ the latest traced segment
 */
export const isPredictedSegmentMilestoneOutdated = (
  isTraced,
  milestone,
  segmentIndex,
  latestTracedIdx
) => {
  try {
    if (isTraced || !milestone || segmentIndex <= latestTracedIdx) {
      return false
    }

    const tz = milestone.tz
    const time = milestone.time

    // `moment` would handle these even if falsy, just being explicit here for clarity
    if (!tz || !time) {
      return false
    }

    return isPredictionOutdated(time, tz)
  } catch (e) {
    logger.captureAppException(e)
    logger.localLog(`isPredictedSegmentMilestoneOutdated: ${e}`, 'error')
    return false
  }
}

/**
 * Determines if a single milestone is outdated. Used for board table view.
 * @param {object} milestone The milestone to use for calculating staleness. This will typically be the `dischargeMilestone`
 * @returns boolean
 *
 * Rules:
 * - milestones with `tracedTime: true` are never stale
 * - milestones must have a `predictedTime` and `timezone` property
 */
export const isPredictedMilestoneOutdated = milestone => {
  try {
    if (!milestone || milestone.tracedTime || !milestone.predictedTime) {
      return false
    }

    const tz = milestone.locationZone?.timezone
    const time = milestone.predictedTime

    // `moment` would handle these even if falsy, just being explicit here for clarity
    if (!tz || !time) {
      return false
    }

    return isPredictionOutdated(time, tz)
  } catch (e) {
    logger.captureAppException(e)
    logger.localLog(`isPredictedMilestoneOutdated: ${e}`, 'error')
    return false
  }
}
