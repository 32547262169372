import { Theme, Tooltip, makeStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import CommentsToggleDrawerButton from 'components/CommentsToggleDrawerButton'
import HorizontalButtonGroupLite from 'components/core/HorizontalButtonGroupLite/index'
import InternalUserComponent from 'components/InternalUserComponent'
import { ROLLUPS } from 'utils/rollups'
import React from 'react'
import ShareIcon from 'components/icons/ShareIcon'
import ShareModal from 'components/ShareModal'
import logger from 'utils/logger'
import { openShareModal } from 'store/share/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  commentShare: {},
}))

interface Props {
  classes?: { [key: string]: string }
  externalId: string
  refIds: number[]
  shareModalRefType: typeof ROLLUPS[keyof typeof ROLLUPS]
}

const ShareComment = (props: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { externalId, refIds, shareModalRefType } = props

  return (
    <HorizontalButtonGroupLite className={classes.commentShare}>
      <InternalUserComponent>
        <CommentsToggleDrawerButton externalId={externalId} />
      </InternalUserComponent>
      <>
        <InternalUserComponent>
          <Tooltip title="Share">
            <span>
              <Button
                data-testid="trip-header--share-button"
                variant="outlinedAlternateDark"
                onClick={() => {
                  logger.notify('Entity Page Share Button Click', {
                    externalId,
                    shareModalRefType,
                  })
                  dispatch(openShareModal())
                }}
              >
                <ShareIcon color="primary" />
              </Button>
            </span>
          </Tooltip>
        </InternalUserComponent>
        <ShareModal refIds={refIds} shareModalRefType={shareModalRefType} />
      </>
    </HorizontalButtonGroupLite>
  )
}

export default ShareComment
