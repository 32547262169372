import Facility, { FacilityType } from '../definitions/Facility'

import { EquipmentActivityType } from '../definitions/EquipmentActivity'
import { TripStageType } from '../definitions/TripStage'
import humps from 'humps'

export interface SegmentInterface {
  equipmentActivity: EquipmentActivityType
  facilityType: FacilityType
  tripSegment: {
    stage: TripStageType
  }
}

export default class Segment {
  constructor({ equipmentActivity, facilityType, tripSegment }: SegmentInterface) {
    Object.assign(this, { equipmentActivity, facilityType, tripSegment })
  }

  static of(payload: object) {
    const { equipmentActivity, facilityType, tripSegment } = humps.camelizeKeys(
      payload
    ) as SegmentInterface
    return new Segment({
      equipmentActivity,
      // Just populate the Facility value here, it's what the UI needs
      facilityType: Facility[facilityType as keyof typeof Facility],
      tripSegment,
    })
  }
}
