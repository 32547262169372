import LocationInfo from './LocationInfo'
import LocationType from './types/LocationType'
import PropTypes from 'prop-types'
import React from 'react'
import TruncatedListCell from './TruncatedListCell'
import { getWaypoints } from 'utils/waypoints'

const MultipleLocationInfo = ({ locations, rollup, isRowHovered }) => {
  if (!Array.isArray(locations)) {
    return null
  }

  const waypoints = getWaypoints(locations, rollup)

  if (!rollup) {
    return (
      <TruncatedListCell
        items={waypoints.map(way =>
          way.unlocode ? <LocationInfo key={way.unlocode} location={way} /> : way.name
        )}
        isRowHovered={isRowHovered}
      />
    )
  }

  const items = waypoints.map(way =>
    way.unlocode ? <LocationInfo key={way.unlocode} location={way} /> : way.name
  )

  return <TruncatedListCell items={items} isRowHovered={isRowHovered} />
}

MultipleLocationInfo.propTypes = {
  classes: PropTypes.object,
  locations: PropTypes.oneOfType([
    PropTypes.arrayOf(LocationType),
    PropTypes.arrayOf(PropTypes.arrayOf(LocationType)),
  ]),
  rollup: PropTypes.bool,
}

export default MultipleLocationInfo
