export default theme => ({
  root: {
    // Handles table scrolling
    overflow: 'auto',
    borderRadius: 0,
    height: '100%',
    width: '100%',
    minWidth: 700,
  },
  rootNoScrolling: {
    overflowY: 'hidden',
  },
  loader: {
    textAlign: 'center',
  },
  selectedRow: {
    backgroundColor: theme.palette.grey[50],
  },
  dynamicHeight: {
    height: '100% !important',
  },
  borderTopRow: {
    // hack for getting a border top to show in "inset" mode
    boxShadow: `inset 0 1px ${theme.palette.grey[200]}`,
  },
  row: {
    // cursor: 'pointer'
  },
  lastRow: {
    // hack for getting a border bottom and top to show in "inset" mode
    boxShadow: `inset 0 -1px ${theme.palette.grey[200]}, inset 0 1px ${theme.palette.grey[200]}`,
  },
  headerRow: {
    // hack for getting a border bottom and top to show in "inset" mode
    boxShadow: `0 1px ${theme.palette.grey[200]}, inset 0 1px ${theme.palette.grey[200]} !important`,
  },
  lightBorderRight: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  hoveredRow: {
    backgroundColor: theme.palette.grey[50],
  },
  '@global': {
    '.sticky-table-column': {
      marginTop: 2,
    },
    '.sticky-table-x-wrapper': {
      marginTop: 2,
    },
    '.sticky-table-header-wrapper': {
      boxShadow: `0 2px 2px ${theme.palette.grey[200]}`,
    },
  },
  emptyCell: {
    // not at all obvious, but this height will dictate how tall the shipment table header row will be in px
    minHeight: 56,
    padding: 16,
  },
  headerWrapperLoading: {
    width: 'fit-content',
  },
  headerWrapper: {
    width: '100%',
  },
  headerWrapperLoaded: {
    height: '100%',
  },
})
