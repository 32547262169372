import { BLUE, GREEN, GREY, LABEL_COLORS } from 'components/StatusLabel/labelColors'

import { TransportStatus } from 'store/board/sagas/models'
import humps from 'humps'
import mapStatusByDelay from './mapStatusByDelay'
import merge from 'lodash/merge'

/**
 * Translates `status` to provide label text, color and details.
 * Legacy `tripStatus` is not translated but passed back merged with the default `status`
 * @param tripStatus, object, mixed:
 * - legacy, object of shape:
 * `{color, details, label, delay_status}`
 * - new: object of shape `TripStatus`:
 * `{delayStatus, transportStatus, timeDelta, latestStatusUpdate, isStale}`
 */
const mapTripStatus = tripStatus => {
  const { delayStatus, transportStatus, timeDelta, isStale } = humps.camelizeKeys(tripStatus) || {}

  let status = {
    color: LABEL_COLORS[GREY],
    label: 'N/A',
    details: null,
  }

  switch (transportStatus) {
    case TransportStatus.NO_DATA:
      status = {
        ...status,
        color: LABEL_COLORS[GREY],
        label: 'No Status',
        details: null,
      }
      break
    case TransportStatus.NOT_STARTED:
      status = {
        ...status,
        color: LABEL_COLORS[GREY],
        label: `Not Started`,
        details: null,
      }
      break
    case TransportStatus.IN_TRANSIT:
      status = {
        ...status,
        color: LABEL_COLORS[GREEN],
        label: 'In Transit',
        details: null,
      }
      status = mapStatusByDelay(status, delayStatus, timeDelta)
      break
    case TransportStatus.OUT_FOR_DELIVERY:
      status = {
        ...status,
        color: LABEL_COLORS[BLUE],
        label: `Out For Delivery`,
        details: null,
      }
      break
    case TransportStatus.DELIVERED:
      status = {
        ...status,
        color: LABEL_COLORS[BLUE],
        label: 'Delivered',
        details: null,
      }
      break
    default:
      // Should only apply to legacy. We merge the default `status` defined above
      // to the passed-in `tripStatus` just to make sure `color` and `label` have values
      return merge(status, tripStatus)
  }
  if (isStale) {
    status = {
      ...status,
      color: LABEL_COLORS[GREY],
      details: [...(status.details || []), 'No Recent Carrier Update'],
    }
  }
  return status
}

export default mapTripStatus
