import React, { ReactNode } from 'react'
import { Theme, makeStyles } from '@material-ui/core'

import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    position: 'absolute',
    marginLeft: 12,
    marginTop: -6,
    backgroundColor: '#DC3545',
    color: 'white',
    borderRadius: theme.shape.borderRadius,
    fontSize: 9,
    padding: '1px 3px',
  },
}))

type BadgeProps = {
  children: ReactNode
  content: ReactNode
  invisible?: boolean
  className?: string
}

const Badge = ({ children, content, invisible = false, className = '' }: BadgeProps) => {
  const classes = useStyles()
  const badgeClasses = classnames(classes.badge, className)

  return (
    <>
      {!invisible && <span className={badgeClasses}>{content}</span>}
      {children}
    </>
  )
}

export default Badge
