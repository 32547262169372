import { TOGGLE_DRAWER } from './actions'
import { handleActions } from 'redux-actions'

export const DRAWERS_REDUCER_KEY = 'drawers'
export const SHIPMENTS_DRAWER_KEY = 'shipments'
export const PLANNING_DRAWER_KEY = 'planning'
export const ALERTS_DRAWER_KEY = 'alerts'
export const DASHBOARD_DRAWER_KEY = 'dashboard'

// Note: SharedLinksDrawer uses Material Drawer directly
// So we will not maintain it's state here.
export const initialState = {
  [SHIPMENTS_DRAWER_KEY]: true,
  [PLANNING_DRAWER_KEY]: true,
  [ALERTS_DRAWER_KEY]: true,
  [DASHBOARD_DRAWER_KEY]: true,
}
// selectors
export const drawersSelector = state => state[DRAWERS_REDUCER_KEY]

// reducer
export default handleActions(
  {
    [TOGGLE_DRAWER]: (state, { payload: { drawer, isOpen } }) => ({
      ...state,
      [drawer]: isOpen,
    }),
  },
  initialState
)
