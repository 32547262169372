import React, { useState } from 'react'

import BaseRouteBarChart from '../BaseRouteBarChart'
import ChartToggleDropdown from 'components/ChartToggleDropdown'
import { connect } from 'react-redux'
import { dayHourMinuteLabel } from '../utils/highCharts'
import { fetchVariableRoutes } from 'store/userDashboard/actions'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import tracker from 'utils/logger/tracker'
import { variableRoutesSelector } from 'store/userDashboard'
import { withStyles } from '@material-ui/core'

function VariableRoutesChart({ variableRoutesState, fetchData, theme }) {
  const [mode, setMode] = useState('most')
  const { isLoading, isFailed } = variableRoutesState
  const colorMap = {
    most: theme.palette.datavis.purple[300],
    least: theme.palette.datavis.blue[700],
  }

  const dropdownOptions = [
    {
      label: 'Highest Route Variability',
      value: 'most',
    },
    { label: 'Lowest Route Variability', value: 'least' },
  ]

  const onSelectChart = e => {
    tracker.dashboard.userSwitchesChartMode(e.label)
    setMode(e.value)
  }

  const data = get(variableRoutesState.data, mode, null)

  return (
    <div data-testid={`user-dashboard__${mode}-variable-routes`}>
      {!isLoading && !isFailed && !isEmpty(data) && (
        <ChartToggleDropdown
          items={dropdownOptions}
          defaultIdx={0}
          labelKey={'label'}
          handleSelection={onSelectChart}
        />
      )}
      <BaseRouteBarChart
        axisTitle={'Variability'}
        carrierLabelFormatter={route => (route.carrier ? route.carrier.abbreviation : 'Unknown')}
        color={colorMap[mode]}
        data={data}
        dataKey={'variability'}
        dataLabelFormatter={dayHourMinuteLabel}
        fetchData={fetchData}
        isFailed={variableRoutesState.isFailed}
        isLoading={variableRoutesState.isLoading}
        tooltipMetrics={[
          {
            key: 'variability',
            label: 'Variability',
            formatter: y => dayHourMinuteLabel(y, true),
          },
        ]}
        title={mode === 'least' ? dropdownOptions[1].label : dropdownOptions[0].label}
        titleHidden={true}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  variableRoutesState: variableRoutesSelector(state),
})

const mapDispatchToProps = {
  fetchData: fetchVariableRoutes,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({}, { withTheme: true })(VariableRoutesChart))
