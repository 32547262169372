import { Popover, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import DateFilter from 'components/Filtering/Filters/DateFilter'
import LatestMilestoneFilter from 'components/Filtering/Filters/LatestMilestoneFilter'
import LocationFilter from 'components/Filtering/Filters/LocationFilter'
import MultiSelectFilter from 'components/Filtering/Filters/MultiSelectFilter'
import MultiTextFilter from 'components/Filtering/Filters/MultiTextFilter'
import PropTypes from 'prop-types'
import RangeFilter from 'components/Filtering/Filters/RangeFilter'
import TextFilter from 'components/Filtering/Filters/TextFilter'
import find from 'lodash/find'
import { findDOMNode } from 'react-dom'
import get from 'lodash/get'
import has from 'lodash/has'
import styles from './styles'

const FILTER_COMPONENTS = {
  text: TextFilter,
  daterange: DateFilter,
  range: RangeFilter,
  multiselect: MultiSelectFilter,
  multitext: MultiTextFilter,
  location: LocationFilter,
  latestMilestone: LatestMilestoneFilter,
}

class ActiveFilterComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    activeFilter: PropTypes.object,
    handleRequestClose: PropTypes.func.isRequired,
    addFilter: PropTypes.func.isRequired,
  }

  state = {
    popoverOpen: false,
    anchorEl: null,
  }

  componentDidMount() {
    if (this.anchorEl) {
      this.setState({
        popoverOpen: true,
        anchorEl: findDOMNode(this.anchorEl),
      })
    }
  }

  onSubmit = val => {
    this.props.addFilter(this.props.activeFilter, val)
  }

  render() {
    const { activeFilter, handleRequestClose, filters, classes } = this.props
    let value
    const filter = find(filters, f => f.name === activeFilter.name)
    if (filter) {
      value = get(filter, 'value')
    }

    const FilterComponent = FILTER_COMPONENTS[activeFilter.filterData.type]
    const { popoverOpen, anchorEl } = this.state

    return (
      <div>
        <div style={{ height: 0 }} ref={el => (this.anchorEl = el)} />
        <Popover
          modifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
          elevation={3}
          onClose={handleRequestClose} // only fires on 'esc' keydown
          open={popoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          classes={{ paper: classes.filterComponentPopover }}
        >
          <div data-testid="filtering__active-filter">
            <div className={classes.toolbar}>
              <Typography variant="body2" className={classes.title}>
                {has(activeFilter, 'activeTitle')
                  ? get(activeFilter, 'activeTitle')
                  : get(activeFilter, 'title')}
              </Typography>
              <CloseIcon className={classes.closeButton} onClick={handleRequestClose} />
            </div>
            <div className={classes.filterComponentContent}>
              <FilterComponent
                filterConfig={activeFilter}
                onSubmit={this.onSubmit}
                onRequestClose={handleRequestClose}
                value={value}
              />
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}
export default withStyles(styles)(ActiveFilterComponent)
