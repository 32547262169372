import { FacilityType } from 'store/board/sagas/models'

export const facilityTypeText = {
  [FacilityType.DOOR]: 'door',
  [FacilityType.RAIL_YARD]: 'ramp',
  [FacilityType.PORT]: 'port',
  [FacilityType.CONTAINER_YARD]: 'container yard',
}

/**
 * Returns the display text for facility types.
 *
 * @param facilityType {FacilityType}
 * @returns {string}
 */
const getFacilityTypeText = facilityType => facilityTypeText[facilityType.toLowerCase()] || ''

export default getFacilityTypeText
