import React from 'react'
import { SvgIcon } from '@material-ui/core'

const DEFAULT_WIDTH = 27
const DEFAULT_HEIGHT = 27

export const CommonFiltersIcon = props => (
  <svg width={19} height={19} {...props}>
    <g
      stroke="#00558B"
      strokeWidth={1.03}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.279 4.156h5.937c.656 0 1.188.532 1.188 1.188v11.875c0 .656-.532 1.187-1.188 1.187H6.53A1.188 1.188 0 0 1 5.34 17.22v-4.75M10.091 7.719h5.94M8.31 11.281h7.721M8.313 14.844h7.718M6.531 7.719V5.344l3.317-3.317A.84.84 0 0 0 9.254.594h-7.82A.84.84 0 0 0 .84 2.027l3.316 3.317v4.75l2.375-2.375z" />
    </g>
  </svg>
)

export const EntitiesIcon = ({ width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) => {
  return (
    <SvgIcon style={{ width: width, height: height }} viewBox={`0 -4 ${width} ${height}`}>
      <g
        id="For-Engineering"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Filter_components_new"
          transform="translate(-68.000000, -494.000000)"
          stroke="#00558B"
          strokeWidth="1.02857143"
        >
          <g id="layout-module" transform="translate(69.000000, 495.000000)">
            <rect id="Rectangle-path" x="0.5" y="0.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="6.5" y="0.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="12.5" y="0.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="0.5" y="6.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="6.5" y="6.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="12.5" y="6.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="0.5" y="12.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="6.5" y="12.498" width="3" height="3" rx="1.02857143" />
            <rect id="Rectangle-path" x="12.5" y="12.498" width="3" height="3" rx="1.02857143" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export const MilestonesIcon = ({ width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) => {
  return (
    <SvgIcon style={{ width: width, height: height }} viewBox={'0 -6 32 32'}>
      <g
        id="For-Engineering"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Filter_components_new"
          transform="translate(-70.000000, -660.000000)"
          stroke="#00558B"
          strokeWidth="0.947368421"
        >
          <g id="flag-plain-3" transform="translate(69.000000, 661.000000)">
            <path d="M1.47368421,16 L1.47368421,0.842105263" id="Shape" />
            <path
              d="M1.47368421,9.68919519 L3.50995087,9.08117017 C5.21637993,8.57109753 7.06642344,8.79950757 8.58705964,9.70800009 C10.0701807,10.5955282 11.8691782,10.8370894 13.5447298,10.3736935 L15.4731789,9.83838071 C15.7850688,9.75188057 16.0000773,9.47507849 16,9.16015074 L16,1.80931631 C15.9997862,1.58810925 15.8927697,1.37979447 15.7108974,1.24655847 C15.529025,1.11332248 15.2933363,1.07057842 15.0741895,1.13108634 L13.5447298,1.55545019 C11.8697159,2.01996392 10.0707523,1.779772 8.58705964,0.8935178 C7.06642344,-0.0149747166 5.21637993,-0.243384757 3.50995087,0.266687883 L1.47368421,0.874086072"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export const LocationsIcon = ({ width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) => {
  return (
    <SvgIcon style={{ width: width, height: height }} viewBox={'0 -6 32 32'}>
      <g
        stroke="#00558B"
        strokeWidth={1.125}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M17.438 6.75V3.487c0-.46-.28-.873-.708-1.044l-4.5-1.8a1.125 1.125 0 0 0-.835 0l-4.79 1.916a1.125 1.125 0 0 1-.835 0L1.334.784a.563.563 0 0 0-.772.526v10.78c0 .46.28.873.708 1.044l4.5 1.8c.268.107.567.107.835 0l2.161-.865M6.188 2.64v12.374M11.813.563v5.625M14.063 11.587c.155 0 .28.126.28.281M13.781 11.868c0-.155.126-.281.281-.281M14.063 12.15a.281.281 0 0 1-.282-.281M14.344 11.868a.281.281 0 0 1-.281.281" />
        <path d="M14.063 8.493a3.375 3.375 0 0 1 3.374 3.375c0 1.44-2.016 4.182-2.931 5.353a.562.562 0 0 1-.887 0c-.915-1.17-2.931-3.912-2.931-5.353a3.375 3.375 0 0 1 3.374-3.375z" />
      </g>
    </SvgIcon>
  )
}

export const PredictionsIcon = ({ width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) => {
  return (
    <SvgIcon style={{ width: width, height: height }} viewBox={'0 -6 32 32'}>
      <g
        stroke="#00558B"
        strokeWidth={1.091}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5.182 8.83l1.782 2.465c.137.2.365.322.61.329a.771.771 0 0 0 .631-.293l5.704-7.032" />
        <path d="M2.455 1.53c-.603 0-1.091.476-1.091 1.063v6.375c0 3.41 5.26 7.203 7.05 8.33.356.227.816.227 1.173 0 1.788-1.127 7.05-4.92 7.05-8.33V2.593c0-.282-.116-.552-.32-.751a1.106 1.106 0 0 0-.772-.311H2.455z" />
      </g>
    </SvgIcon>
  )
}

export const RefsIcon = ({ width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) => {
  return (
    <SvgIcon style={{ width: width, height: height }} viewBox={'0 -6 27 27'}>
      <g
        id="For-Engineering"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Filter_components_new"
          transform="translate(-69.000000, -382.000000)"
          stroke="#00558B"
        >
          <g id="desktop-monitor-download" transform="translate(69.000000, 382.000000)">
            <ellipse id="Oval" cx="11.5" cy="4.59" rx="4" ry="4.08" />
            <path d="M11.5,2.55 L11.5,6.63" id="Shape" />
            <path d="M11.5,6.63 L10,5.1" id="Shape" />
            <path d="M11.5,6.63 L13,5.1" id="Shape" />
            <polygon id="Shape" points="10.5 15.81 5.5 15.81 6 13.77 10 13.77" />
            <path d="M4,15.81 L12,15.81" id="Shape" />
            <path d="M0.5,11.73 L15.5,11.73" id="Shape" />
            <path
              d="M15.5,9.69 L15.5,11.73 C15.5,12.8566609 14.6045695,13.77 13.5,13.77 L2.5,13.77 C1.3954305,13.77 0.5,12.8566609 0.5,11.73 L0.5,4.59 C0.5,3.46333911 1.3954305,2.55 2.5,2.55 L5.5,2.55"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
