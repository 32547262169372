import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import api from 'store/requestInvitation/api'
import logger from 'utils/logger'
import types from 'store/requestInvitation/actions'

export function* loadRequestInvitationAsync(action: {
  type: string
  payload: { username: string }
}) {
  try {
    yield call(api.fetchRequestInvitation, action.payload.username)
    yield put(
      showNotification(MESSAGES.genericEmailSent, {
        type: MESSAGE_TYPES.INFO,
      })
    )
  } catch (err) {
    yield put(
      showNotification(MESSAGES.genericEmailFailure, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
    logger.captureAPIException(err)
  }
}

export function* watchLoadRequestInvitation() {
  yield takeLatest(types.REQUEST_INVITATION_START, loadRequestInvitationAsync)
}
