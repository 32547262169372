import ApiRefType, { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import { IconButton, TableRow, makeStyles } from '@material-ui/core'
import { ROLLUP_BOLS, ROLLUP_BOOKINGS, getShipmentDetailPath } from 'utils/rollups'
import React, { useState } from 'react'

import { BREADCRUMB_PROGRESS } from 'components/ReferencesTable'
import BookingContainerList from 'components/ReferencesTable/TransportationTable/BookingContainerList'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import TableCell from 'components/ReferencesTable/TableCell'
import TableCellText from 'components/ReferencesTable/TableCellText'
import { TransportationInterface } from 'store/associatedReferences/interfaces'
import logger from 'utils/logger'

const useStyles = makeStyles(theme => ({
  expansionCell: {
    width: 30, // the width of <IconButton size="small">
    height: 30, // the height of <IconButton size="small">
    padding: `${theme.spacing(0.5)}px 0 ${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
  },
  transportationTable: {
    // Forces maxWidth for the first column (caret)
    '& td:first-child': {
      maxWidth: 30,
    },
    // Divides the 2nd and 3rd columns in half
    '& td:not(:first-child)': {
      width: 'calc((100% / 2))',
    },
  },
  subtext: {},
}))

interface Props {
  classes?: { [key: string]: string }
  containerTripId?: string | null
  data: TransportationInterface
  getBreadcrumbIndex: (refType: string) => number
  index: number
  isActiveTable: boolean
  refType: ApiRefTypeType
}

const TransportationTable = (props: Props) => {
  const classes = useStyles(props)
  const { containerTripId, data, getBreadcrumbIndex, index, isActiveTable, refType } = props
  const [expanded, setExpanded] = useState(isActiveTable)

  if (!data) {
    return null
  }

  const handleExpand = (e: React.MouseEvent<HTMLElement>) => {
    logger.notify(`${expanded ? 'Collapse' : 'Expand'} Booking Reference`, {
      entityType: refType,
    })
    setExpanded(!expanded)
  }

  const { bols, booking, containers, totalContainerTrips } = data
  const breadcrumbIndex = getBreadcrumbIndex(refType)

  // Just goes through each `container` and adds up each `container.containerTrips.length` so we can display
  // `allContainersDisplayedCount` of <total containers>
  let allContainersDisplayedCount: number = 0
  containers.forEach(container => (allContainersDisplayedCount += container.containerTrips.length))

  return (
    <>
      <TableRow className={classes.transportationTable}>
        <TableCell disableBorder={index === 0} className={classes.expansionCell}>
          <IconButton size="small" onClick={handleExpand}>
            {expanded ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell disableBorder={index === 0}>
          <TableCellText
            bold={
              breadcrumbIndex > BREADCRUMB_PROGRESS.STEP_TWO &&
              breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX
            }
            color={
              breadcrumbIndex > BREADCRUMB_PROGRESS.STEP_TWO &&
              breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX
                ? 'textPrimary'
                : 'textSecondary'
            }
          >
            Booking
          </TableCellText>
          {containers.length > 1 && (
            <TableCellText
              classes={{ root: classes.subtext }}
              color="textSecondary"
              italic
              variant="caption"
            >
              {`${allContainersDisplayedCount} of ${totalContainerTrips} Containers shown`}
            </TableCellText>
          )}
        </TableCell>
        <TableCell disableBorder={index === 0}>
          {booking && (
            <TableCellText
              active={refType === ApiRefType.ocean_booking_carrier_reference}
              onClick={() => {
                logger.notify(`Reference Link Click`, {
                  entityTypeFrom: refType,
                  entityTypeTo: ROLLUP_BOOKINGS,
                })
              }}
              href={
                refType !== ApiRefType.ocean_booking_carrier_reference
                  ? getShipmentDetailPath(ROLLUP_BOOKINGS, booking.number, booking.externalId)
                  : undefined
              }
            >
              {booking.number}
            </TableCellText>
          )}
        </TableCell>
      </TableRow>
      {expanded ? (
        <>
          {bols.length > 0 && (
            <TableRow>
              <TableCell disableBorder>{/* Placeholder for Icon */}</TableCell>
              <TableCell disableBorder indent>
                <TableCellText
                  bold={
                    breadcrumbIndex > BREADCRUMB_PROGRESS.STEP_THREE &&
                    breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX
                  }
                  color={
                    breadcrumbIndex > BREADCRUMB_PROGRESS.STEP_THREE &&
                    breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX
                      ? 'textPrimary'
                      : 'textSecondary'
                  }
                >
                  Bills of Lading
                </TableCellText>
              </TableCell>
              <TableCell disableBorder>
                {bols.map((bol, idx) => (
                  <TableCellText
                    key={bol.number}
                    active={idx === 0 && refType === ApiRefType.bill_of_lading_number}
                    onClick={() => {
                      logger.notify(`Reference Link Click`, {
                        entityTypeFrom: refType,
                        entityTypeTo: ROLLUP_BOLS,
                      })
                    }}
                    href={
                      refType !== ApiRefType.bill_of_lading_number
                        ? getShipmentDetailPath(ROLLUP_BOLS, bol.number, bol.externalId)
                        : undefined
                    }
                  >
                    {bol.number}
                  </TableCellText>
                ))}
              </TableCell>
            </TableRow>
          )}
          {containers?.map((container, idx) => (
            <BookingContainerList
              classes={{ subtext: classes.subtext }}
              container={container}
              containerTripId={containerTripId}
              getBreadcrumbIndex={getBreadcrumbIndex}
              key={idx}
              refType={refType}
              totalContainerTrips={totalContainerTrips}
            />
          ))}
        </>
      ) : null}
    </>
  )
}

export default TransportationTable
