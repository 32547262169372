import findLast from 'lodash/findLast'
import get from 'lodash/get'
import { getLocalizedMoment } from 'utils/dateUtils'
import maxBy from 'lodash/maxBy'
import moment from 'moment-timezone'

const getLastUpdate = shipment => {
  const latestMilestones = []
  const timelines = get(shipment, 'timelines', [])
  timelines.forEach(timeline => {
    const milestones = get(timeline, 'milestones', [])
    const latestMs = findLast(milestones, item => get(item, 'time.traced') !== null)
    latestMilestones.push(get(latestMs, 'time'))
  })
  let maxTracedObj = maxBy(latestMilestones, item => moment(get(item, 'traced')))
  if (maxTracedObj) {
    const tracedTime = get(maxTracedObj, 'traced')
    const tz = get(maxTracedObj, 'timezone')
    return getLocalizedMoment(tracedTime, tz)
  } else {
    return null
  }
}

export default getLastUpdate
