import { InitialStateInterface, ProfileInterface } from './interfaces'

import { handleActions } from 'redux-actions'
import { types } from './actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: InitialStateInterface = {
  acceptedTerms: null,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  loading: false,
  location: undefined,
  team: undefined,
  tenant: undefined,
  title: undefined,
}

const profile = handleActions<InitialStateInterface, any>(
  {
    [types.FETCH_PROFILE_START]: (state: InitialStateInterface) => {
      return { ...state, loading: true }
    },
    [types.FETCH_PROFILE_SUCCESS]: (
      state: InitialStateInterface,
      action: { payload: ProfileInterface }
    ) => {
      const {
        acceptedTerms,
        firstName,
        lastName,
        title,
        team,
        location,
        email,
        tenant,
      } = action.payload
      return {
        ...state,
        acceptedTerms,
        email,
        firstName,
        lastName,
        loading: false,
        location,
        team,
        tenant,
        title,
      }
    },
    [types.FETCH_PROFILE_ERROR]: (state: InitialStateInterface) => {
      return { ...state, loading: false }
    },
    [types.UPDATE_PROFILE_START]: (state: InitialStateInterface) => {
      return { ...state, loading: true }
    },
    [types.UPDATE_PROFILE_SUCCESS]: (
      state: InitialStateInterface,
      action: { payload: ProfileInterface }
    ) => {
      return {
        ...state,
        acceptedTerms: action.payload.acceptedTerms,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        loading: false,
        location: action.payload.location,
        team: action.payload.team,
        tenant: action.payload.tenant,
        title: action.payload.title,
      }
    },
    [types.UPDATE_PROFILE_ERROR]: (state: InitialStateInterface) => {
      return { ...state, loading: false }
    },
  },
  initialState
)

export default profile
