import { Box, TextField, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { SYNTHETIC_EVENTS, getSubjectLine, getValidEmails } from './utils'
import { emailFromState, fullNameSelector } from 'store/auth/user/selectors'

import Button from 'components/core/Button'
import ChipInput from 'components/core/ChipInput'
import GenerateToken from './GenerateToken'
import PropTypes from 'prop-types'
import logger from 'utils/logger'
import { splitOnDelimiters } from './utils'
import { useFormHelper } from 'utils/hooks'
import { useSelector } from 'react-redux'
import validate from 'validate.js'

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  flatButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
  },
  tokenWrapper: {
    marginRight: theme.spacing(2),
  },
  tokenText: {
    marginTop: theme.spacing(1),
  },
}))

ShareForm.propTypes = {
  className: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  token: PropTypes.string,
  onTokenGenerate: PropTypes.func.isRequired,
  onCopySuccess: PropTypes.func.isRequired,
}

function ShareForm({ className, onCopySuccess, onSubmit, onTokenGenerate, token }) {
  const classes = useStyles()
  const inputRef = useRef(null)

  const currentUser = {
    name: useSelector(fullNameSelector),
    email: useSelector(emailFromState),
  }
  const [selectedChipId, setSelectedChipId] = useState(null)

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setTouched,
    setValues,
    touched,
    values,
  } = useFormHelper({
    initialValues: {
      body: '',
      subject: getSubjectLine(currentUser),
      chipConfigs: [],
      emailInput: '',
    },
    validate: values => {
      const { subject, chipConfigs } = values
      let errors = {}

      if (subject === '') {
        errors['subject'] = 'Required'
      }

      const validEmails = getValidEmails(chipConfigs)

      // Require valid chips
      // Ensure all emails are valid
      // debugger
      if (validEmails.length === 0 || validEmails.length !== chipConfigs.length) {
        errors['emailInput'] = 'Example: name@website.com, group@web.net'
      }

      return errors
    },
    onSubmit: ({ body, subject, chipConfigs }) => {
      onSubmit({
        body,
        subject,
        emails: chipConfigs.map(config => config.label),
      })
    },
  })

  useEffect(() => {
    // Focus and select the share link if we have one already
    if (inputRef.current && token) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [token])

  function getDeletedChipConfigs(chipIndex) {
    // Remove chip by index
    // Create an ordered copy
    let configCopy = values.chipConfigs.slice()
    // At index, remove one item
    configCopy.splice(chipIndex, 1)

    return configCopy
  }

  function handleEditChip(chipIndex, targetConfig) {
    setValues({
      ...values,
      emailInput: targetConfig.label,
      chipConfigs: getDeletedChipConfigs(chipIndex),
    })
  }

  function handleAddRequest(input) {
    const isValidEmailFormat = string =>
      validate({ from: string }, { from: { email: true } }) === undefined

    setValues({
      ...values,
      chipConfigs: [
        ...values.chipConfigs,
        ...splitOnDelimiters(input).map(string => ({
          label: string,
          valid: isValidEmailFormat(string),
        })),
      ],
      emailInput: '',
    })
  }

  function handleChipSelection(id) {
    const targetConfig = values.chipConfigs[id]
    const isSecondClick = selectedChipId === id
    const isSelectionInvalid = !targetConfig.valid
    if (isSelectionInvalid) {
      // One click to-edit-if-invalid
      handleEditChip(id, targetConfig)
    } else if (isSecondClick) {
      // Edit on second click if valid
      handleEditChip(id, targetConfig)
    } else {
      setSelectedChipId(id)
    }
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      <div className={classes.section}>
        <Typography variant="subtitle1">Recipients</Typography>
        <Typography variant="body2" color="textSecondary">
          <i>Enter one or more email addresses separated by a comma.</i>
        </Typography>
        <ChipInput
          margin="normal"
          chipConfigs={values.chipConfigs}
          error={errors.emailInput && touched.emailInput}
          helperText={
            errors.emailInput ? errors.emailInput : 'Example: name@website.com, group@web.net'
          }
          newChipSyntheticKeyCodes={SYNTHETIC_EVENTS}
          name="emailInput"
          onAddRequest={e => {
            e.preventDefault()
            handleAddRequest(e.target.value)
          }}
          onBlur={e => {
            handleAddRequest(e.target.value)
            // Do not validate on setTouch as validation is fired on setValues in handleAddRequest
            // If validation is fired twice, there's a validation race condition as the values being validated will be different
            setTouched({ ...touched, emailInput: true }, false)
          }}
          onChange={e => {
            const newInput = e.target.value
            // Only store non-delimeter input
            if (SYNTHETIC_EVENTS.includes(newInput)) {
              return
            } else {
              setValues({
                ...values,
                emailInput: newInput,
              })
            }
          }}
          onChipSelect={id => handleChipSelection(id)}
          onDeleteRequest={chipIndex => {
            setValues({
              ...values,
              chipConfigs: getDeletedChipConfigs(chipIndex),
            })
          }}
          placeholder={values.chipConfigs.length === 0 ? 'Email addresses *' : null}
          value={values.emailInput}
        />
      </div>
      <div className={classes.section}>
        <Typography variant="subtitle1">Email Message</Typography>
        <Typography variant="body2" color="textSecondary">
          <i>You can customize your email subject and provide an optional message below.</i>
        </Typography>
        <TextField
          margin="normal"
          error={errors.subject && touched.subject}
          helperText={errors.subject}
          InputLabelProps={{ required: true }}
          label="Email subject"
          name="subject"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.subject}
          variant="filled"
        />
        <TextField
          margin="normal"
          helperText={errors.body ? errors.body : 'Optional'}
          label="Custom email message"
          multiline
          name="body"
          onBlur={handleBlur}
          onChange={handleChange}
          rows="3"
          value={values.body}
          variant="filled"
        />
      </div>
      <div className={classes.actionWrapper}>
        <span className={classes.tokenWrapper}>
          <GenerateToken
            generateToken={onTokenGenerate}
            inputRef={inputRef}
            onCopiedToClipboard={() => {
              logger.notify('Share Modal - Copy Shared Link')
              onCopySuccess()
            }}
            token={token}
          />
          {token && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.tokenText}
              component="div"
            >
              <Box>Anyone with access to this link can view basic shipment information.</Box>
              <Box>It will automatically expire in 90 days unless it’s revoked in “Shares.”</Box>
            </Typography>
          )}
        </span>
        <span>
          <Button
            color="primary"
            disabled={isSubmitting || !isValid}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => logger.notify('Share Modal - Submit Button Click')}
          >
            Share
          </Button>
        </span>
      </div>
    </form>
  )
}

export default ShareForm
