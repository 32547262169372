import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { getRefTypeLabel, getShipmentDetailPath } from 'utils/rollups'

import DownloadIcon from 'components/icons/deprecated/Download'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import MESSAGES from 'store/notifications/messages'
import OpenInNew from '@material-ui/icons/OpenInNew'
import PropTypes from 'prop-types'
import React from 'react'
import ShareIcon from 'components/icons/Share'
import copy from 'copy-to-clipboard'
import exportMenuContents from 'components/core/ConfigurableTable/TableControls/ExportMenu/contents'
import { initiateExport } from 'store/export'
import { openShareModal } from 'store/share/actions'
import { showNotification } from 'store/notifications'
import { useDispatch } from 'react-redux'

const ContextMenu = function ({
  contextAnchor,
  contextRow,
  handleContextClose,
  clientX,
  rollup,
  clientY,
  visibleColumns,
}) {
  const dispatch = useDispatch()
  const externalId = contextRow?.externalId
  const windowSelection = window.getSelection().toString()

  const handleCopy = selection => {
    copy(selection)
    dispatch(showNotification(MESSAGES.highlightedValueCopy))
  }

  return (
    <Menu
      anchorPosition={{
        left: clientX,
        top: clientY,
      }}
      anchorReference="anchorPosition"
      open={Boolean(contextAnchor)}
      onClose={handleContextClose}
      keepMounted
      data-testid="context-menu"
    >
      {windowSelection.length > 0 && (
        <MenuItem
          dense
          onClick={e => {
            handleCopy(windowSelection)
            handleContextClose()
          }}
        >
          <ListItemIcon>
            <FileCopyOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Copy highlighted value</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        dense
        onClick={e => {
          handleContextClose()
          window.open(getShipmentDetailPath(rollup, contextRow?.id, externalId), '_blank')
        }}
      >
        <ListItemIcon>
          <OpenInNew />
        </ListItemIcon>
        <ListItemText>{`Open this ${getRefTypeLabel(rollup)} in a new tab`}</ListItemText>
      </MenuItem>
      <MenuItem
        dense
        onClick={() => {
          dispatch(openShareModal())
          handleContextClose()
        }}
      >
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText>Share row</ListItemText>
      </MenuItem>
      <MenuItem
        key={exportMenuContents.csvLabel}
        dense
        onClick={() => {
          handleContextClose()
          dispatch(
            initiateExport(
              [contextRow.ref_id],
              rollup,
              'csv',
              visibleColumns.map(column => column.key)
            )
          )
        }}
      >
        <ListItemIcon>
          <DownloadIcon color="secondary" />
        </ListItemIcon>
        <ListItemText>{exportMenuContents.csvLabel}</ListItemText>
      </MenuItem>
      <MenuItem
        key={exportMenuContents.excelLabel}
        dense
        onClick={() => {
          handleContextClose()
          dispatch(
            initiateExport(
              [contextRow.ref_id],
              rollup,
              'xlsx',
              visibleColumns.map(column => column.key)
            )
          )
        }}
      >
        <ListItemIcon>
          <DownloadIcon color="secondary" />
        </ListItemIcon>
        <ListItemText>{exportMenuContents.excelLabel}</ListItemText>
      </MenuItem>
    </Menu>
  )
}

ContextMenu.propTypes = {
  clientX: PropTypes.number.isRequired,
  clientY: PropTypes.number.isRequired,
  contextAnchor: PropTypes.object,
  contextRow: PropTypes.object,
  handleContextClose: PropTypes.func.isRequired,
  rollup: PropTypes.string.isRequired,
  visibleColumns: PropTypes.array.isRequired,
}

export default ContextMenu
