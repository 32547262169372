import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { ALERTS_ROUTE } from 'utils/routes'
import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Link from 'components/core/Link'
import PropTypes from 'prop-types'
import RadioChecked from '@material-ui/icons/RadioButtonChecked'
import RadioUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { SHIPMENTS_DRAWER_KEY } from 'store/drawers'
import SaveFormTextfield from './SaveFormTextfield'
import classnames from 'classnames'
import get from 'lodash/get'
import { getRollupLabel } from 'utils/rollups'
import { rollupSelector } from 'store/boardUtils/selectors'
import { useSelector } from 'react-redux'
import { viewsSelector } from 'store/boardUtils/selectors'

const MAX_ROW_COUNT = 1500
const NAME_EMPTY = 'Please provide a name for your saved filter.'
const NAME_EXISTS = 'This name already exists. Please try a different one.'

const useStyles = makeStyles(theme => ({
  container: {
    width: 500,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
  },
  alertHeader: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(3),
  },
  flex: {
    display: 'flex',
  },
  checkedIcon: {
    color: theme.palette.grey[500],
    width: 20,
    height: 20,
  },
  uncheckedIcon: {
    color: theme.palette.grey[500],
    width: 20,
    height: 20,
  },
  saveNewWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    cursor: 'pointer',
  },
  radioCheck: {
    color: `${theme.palette.grey[300]} !important`,
  },
  labelWrapped: {
    marginTop: `-${theme.spacing(2)}px`,
  },
}))

const SaveDialog = function ({
  createView,
  currentView,
  drawerKey,
  filters,
  isAlertSupported = true,
  isOpen,
  onRequestClose,
  totalCount,
  updateView,
}) {
  const classes = useStyles()
  const rollup = useSelector(rollupSelector)
  const savedViews = useSelector(viewsSelector)
  const alertConfigIds = get(currentView, 'alert_config_ids', [])
  const [inputValue, setInputValue] = useState('')
  const [warning, setWarning] = useState('')
  const [saveAsNewChecked, setSaveAsNewChecked] = useState(false)
  const [alertExists, setAlertExists] = useState(alertConfigIds.length > 0)
  const [createAlert, setCreateAlert] = useState(false)
  const currentViewName = get(currentView, 'name')
  const currentViewId = get(currentView, 'id')

  useEffect(() => {
    const configIds = alertConfigIds
    setAlertExists(configIds.length > 0)
  }, [currentView, alertConfigIds])

  const handleRadioChange = val => () => {
    if (val === 'update') {
      setSaveAsNewChecked(false)
      setAlertExists(alertConfigIds.length > 0)
      setWarning('')
    } else if (val === 'save') {
      setSaveAsNewChecked(true)
      setAlertExists(false)
      setWarning('')
    }
  }

  const allowUpdate = () => {
    return Boolean(currentViewId)
  }

  const updateInput = val => {
    setInputValue(val)
    setWarning('')
  }

  const handleCheckboxUpdate = () => {
    if (totalCount > MAX_ROW_COUNT) {
      alert(
        'Too many shipments match this saved filter.  ' +
          'If you want to create an alert, please add more filters to narrow the scope of your saved filter. '
      )
      return
    }
    // If user is saving (not updating) view, and there is already an alert associated with the view,
    // don't let them uncheck the box
    if (alertConfigIds.length === 0 || saveAsNewChecked) {
      setCreateAlert(!createAlert)
    }
  }

  const renderCreateAlertLabel = () => {
    if (alertExists) {
      const viewNameStr = currentViewName ? `for "${currentViewName}"` : ''
      return (
        <>
          <Typography>You already have an alert set name {viewNameStr} </Typography>
          <Typography variant="body2">
            To remove it, please visit <Link to={ALERTS_ROUTE.buildUrl()}>Alerts</Link>.
          </Typography>
        </>
      )
    } else {
      return <Typography>Yes, create an alert for this saved filter</Typography>
    }
  }

  const onSaveClick = () => {
    handleSave()
  }

  const renderNameCreationForm = () => {
    if (allowUpdate()) {
      return (
        <>
          <Box className={classes.flex}>
            <FormControlLabel
              control={
                <Radio
                  value={'update'}
                  onChange={handleRadioChange('update')}
                  checked={!saveAsNewChecked}
                  icon={<RadioUnchecked className={classes.uncheckedIcon} />}
                  checkedIcon={<RadioChecked className={classes.checkedIcon} />}
                />
              }
              label={
                <Typography variant="body1" className={classes.text}>
                  Update your current saved filter "{currentViewName}"
                </Typography>
              }
            />
          </Box>
          <Box mt={1}>
            <FormControlLabel
              control={
                <Radio
                  value={'save-new'}
                  checked={saveAsNewChecked}
                  onChange={handleRadioChange('save')}
                  icon={<RadioUnchecked className={classes.uncheckedIcon} />}
                  checkedIcon={<RadioChecked className={classes.checkedIcon} />}
                />
              }
              label={
                <Typography variant="body1" className={classes.text}>
                  Save as new
                </Typography>
              }
            />
            {saveAsNewChecked && (
              <SaveFormTextfield
                saveAsNewChecked={true}
                autoFocus={true}
                warning={warning}
                inputValue={inputValue}
                updateInput={updateInput}
                onEnter={onSaveClick}
              />
            )}
          </Box>
        </>
      )
    } else {
      return (
        <SaveFormTextfield
          autoFocus={true}
          warning={warning}
          inputValue={inputValue}
          updateInput={updateInput}
          onEnter={onSaveClick}
        />
      )
    }
  }

  // Planning and Dashboard use `state.filterGroups`. Currently the board does not,
  // it uses `state.boardUtils.views`.
  const viewNameExists = name => {
    if (drawerKey === SHIPMENTS_DRAWER_KEY) {
      return savedViews.map(view => view.name).includes(name)
    }
    return filters.map(view => view.name).includes(name)
  }

  const handleSave = () => {
    if (saveAsNewChecked || !allowUpdate()) {
      if (inputValue.length === 0) {
        return setWarning(NAME_EMPTY)
      }
      if (viewNameExists(inputValue)) {
        return setWarning(NAME_EXISTS)
      }
      createView(inputValue, filters, createAlert)
    } else {
      updateView(currentViewName, filters, currentViewId, createAlert)
    }
    handleRequestClose()
  }

  const handleRequestClose = () => {
    onRequestClose()
    setInputValue('')
    setWarning('')
    setSaveAsNewChecked(false)
    setCreateAlert(false)
  }

  return (
    <Dialog
      onClose={handleRequestClose}
      aria-labelledby="save-fiter-dialog"
      open={isOpen}
      classes={{ paper: classes.container }}
      data-testid="view-list__save-view__save-view-dialog"
    >
      <Box className={classes.header}>
        <DialogTitle>
          {!currentViewId ? `Save Your Filter` : `Update or Save Your Filter`}
        </DialogTitle>
        <span>
          <IconButton onClick={handleRequestClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </span>
      </Box>
      <DialogContent>
        <React.Fragment>
          {renderNameCreationForm()}
          {Boolean(isAlertSupported) &&
            (totalCount > MAX_ROW_COUNT ? (
              <Box pt={alertExists ? 0 : 1} pb={1}>
                <Typography className={classes.alertHeader} variant="body1">
                  Too many {getRollupLabel(rollup)} match this filter to create an alert
                </Typography>
                <Typography variant="body2">
                  Try adjusting your filters as we limit alerts to 1500 {getRollupLabel(rollup)}.
                  You can still save this filter without setting an alert.
                </Typography>
              </Box>
            ) : (
              <>
                <Box pt={alertExists ? 0 : 1} pb={1}>
                  <Typography className={classes.alertHeader} variant="body1">
                    Would you like to be notified?
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    <em>You will receive a daily email if the alert has been triggered</em>
                  </Typography>
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{ root: classnames({ [classes.labelWrapped]: alertExists }) }}
                      color="primary"
                      disabled={alertExists}
                      checked={createAlert || alertExists}
                      onChange={handleCheckboxUpdate}
                      value={'create-filter-alert'}
                    />
                  }
                  label={renderCreateAlertLabel()}
                  data-testid="create-filter-alert"
                />
              </>
            ))}
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSaveClick}
          color={'primary'}
          data-testid="view-list__save-view__save-button"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SaveDialog.propTypes = {
  classes: PropTypes.object,
  createView: PropTypes.func.isRequired,
  currentView: PropTypes.object,
  filters: PropTypes.array.isRequired,
  isAlertSupported: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  updateView: PropTypes.func.isRequired,
}

export default SaveDialog
