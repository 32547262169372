import {
  AVAILABLE_FOR_DELVERY_LABEL,
  GROUP_LABELS,
  PROGRESS_COMPLETED,
  PROGRESS_IN_PROGRESS,
  PROGRESS_IN_TRANSIT,
} from 'utils/milestone/MilestoneGroups/definitions'
import { BLUE25, GREY50, PURPLE100 } from 'components/StatusLabel/labelColors'
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { TIMESTAMP_ACTUAL, TIMESTAMP_PREDICTED } from 'utils/milestone/MilestoneGroups/definitions'

import DelayLabel from 'components/DelayLabel'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InProgressIcon from 'components/icons/InProgressIcon'
import InTransitIcon from 'components/icons/InTransitIcon'
import { LocationZoneInterface } from 'store/models/LocationZone'
import OutdatedPredictionDisclaimer from 'components/OutdatedPredictionDisclaimer'
import ProgressCompletedIcon from 'components/icons/ProgressCompletedIcon'
import React from 'react'
import RolledStatusLabel from 'components/RolledStatusLabel'
import StatusLabel from 'components/StatusLabel'
import UiGroupInterface from 'store/types/UiGroupInterface'
import classnames from 'classnames'
import logger from 'utils/logger'

const BORDER = '1px solid #c2cbcc'
const useStyles = makeStyles((theme: Theme) => ({
  groupRow: {
    boxShadow:
      '2px 3px 5px -4px rgba(0,0,0,0.2), 2px 3px 6px -2px rgba(0,0,0,0.14), 2px 2px 9px -10px rgba(0,0,0,0.12)',
    '& td': {
      background: theme.palette.custom['grey25'],
      borderBottom: BORDER,
      borderTop: BORDER,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&:first-child': {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: BORDER,
        borderTopLeftRadius: theme.shape.borderRadius,
      },
      '&:last-child': {
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: BORDER,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
  },
  groupRowExpanded: {
    '& td': {
      '&:first-child': {
        borderBottomLeftRadius: 0,
      },
      '&:last-child': {
        borderBottomRightRadius: 0,
      },
    },
  },
  groupName: {
    position: 'relative',
  },
  expandButton: {
    padding: 0,
  },
  expandCell: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  expandIcon: {
    color: theme.palette.grey[500],
    fontSize: 30,
  },
  root: {},
  statusIcon: {
    fontSize: 28,
    verticalAlign: 'middle',
  },
  stage: {
    color: theme.palette.grey[500],
    fontSize: 13,
    textTransform: 'uppercase',
  },
  location: {
    color: theme.palette.grey[500],
    lineHeight: 1.2,
  },
  completedLocation: {
    color: theme.palette.grey[500],
  },
  inTransitLocation: {
    color: theme.palette.grey[900],
  },
  inProgressLocation: {
    color: theme.palette.grey[900],
  },
  completedStage: {
    color: theme.palette.grey[500],
  },
  inTransitStage: {
    color: theme.palette.named.status.intransit,
  },
  inProgressStage: {
    color: theme.palette.orange[500],
  },
  statusLabel: {
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  statusLabelRootReset: {
    paddingBottom: 3,
    paddingTop: 3,
  },
  groupTitle: {
    minWidth: theme.spacing(40),
    position: 'relative',
    width: theme.spacing(40),
    // Faux border
    '&:after': {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      content: '""',
      display: 'block',
      height: 55,
      position: 'absolute',
      right: 0,
      top: -8,
    },
  },
  labelDelay: {
    color: theme.palette.orange[500],
  },
  labelDelayPredicted: {
    color: theme.palette.purple[600],
  },
}))

interface Props {
  classes?: { [key: string]: string }
  group: UiGroupInterface
  groupIsExpanded: boolean
  groupName: string
  locationZone: LocationZoneInterface | null
  setGroupIsExpanded: (isOpen: boolean) => void
}

const GroupHeaderRow = (props: Props) => {
  const classes = useStyles(props)
  const { group, groupIsExpanded, groupName, locationZone, setGroupIsExpanded } = props
  if (!group) {
    return null
  }

  const { groupProgress } = group
  const groupLabel: string = GROUP_LABELS[groupName]

  return (
    <>
      <TableRow
        className={classnames(classes.root, classes.groupRow, {
          [classes.groupRowExpanded]: groupIsExpanded,
        })}
      >
        <TableCell className={classes.expandCell}>
          <IconButton
            className={classes.expandButton}
            aria-label="expand row"
            size="small"
            onClick={() => {
              setGroupIsExpanded(!groupIsExpanded)
              logger.notify(
                groupIsExpanded
                  ? 'Container Journey Collapse Stage'
                  : 'Container Journey Expand Stage',
                { group: groupLabel }
              )
            }}
          >
            {groupIsExpanded ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          {groupProgress === PROGRESS_COMPLETED && (
            <ProgressCompletedIcon className={classes.statusIcon} />
          )}
          {groupProgress === PROGRESS_IN_TRANSIT && (
            <InTransitIcon className={classes.statusIcon} />
          )}
          {groupProgress === PROGRESS_IN_PROGRESS && (
            <InProgressIcon className={classes.statusIcon} />
          )}
        </TableCell>
        <TableCell colSpan={4} className={classes.groupName}>
          <Box display="flex" flexDirection="row">
            <Box className={classes.groupTitle} pr={2}>
              <Typography
                component="span"
                variant="subtitle2"
                className={classnames(classes.stage, {
                  [classes.completedStage]: groupProgress === PROGRESS_COMPLETED,
                  [classes.inTransitStage]: groupProgress === PROGRESS_IN_TRANSIT,
                  [classes.inProgressStage]: groupProgress === PROGRESS_IN_PROGRESS,
                })}
              >
                {groupLabel}
              </Typography>
              <Typography
                display="block"
                component="span"
                variant="subtitle1"
                className={classnames(classes.location, {
                  [classes.completedLocation]: groupProgress === PROGRESS_COMPLETED,
                  [classes.inTransitLocation]: groupProgress === PROGRESS_IN_TRANSIT,
                  [classes.inProgressLocation]: groupProgress === PROGRESS_IN_PROGRESS,
                })}
              >
                {locationZone?.name}
              </Typography>
            </Box>
            {!groupIsExpanded && (
              <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" pl={2}>
                {group.groupStatuses.hasRoll && !groupIsExpanded && (
                  <RolledStatusLabel className={classes.statusLabel} short={true} />
                )}
                {group.groupStatuses.predictedTimeDelays.length > 0 &&
                  group.groupStatuses.predictedTimeDelays.map((status, idx: number) => (
                    <StatusLabel
                      className={classes.statusLabel}
                      expandToContent={true}
                      key={idx}
                      label={
                        <>
                          {status.type}: {status.timestamp}{' '}
                          {status.delayText && (
                            <>
                              -{' '}
                              <span className={classes.labelDelayPredicted}>
                                {status.delayText}
                              </span>
                              <DelayLabel delayType={TIMESTAMP_PREDICTED} showIconOnly={true} />
                            </>
                          )}
                        </>
                      }
                      short={true}
                      variant={PURPLE100}
                    />
                  ))}
                {group.groupStatuses.tracedTimeDelays.length > 0 &&
                  group.groupStatuses.tracedTimeDelays.map((status, idx: number) => (
                    <StatusLabel
                      className={classes.statusLabel}
                      expandToContent={true}
                      key={idx}
                      label={
                        <>
                          {status.type}: {status.timestamp}{' '}
                          {status.delayText && (
                            <>
                              - <span className={classes.labelDelay}>{status.delayText}</span>
                              <DelayLabel delayType={TIMESTAMP_ACTUAL} showIconOnly={true} />
                            </>
                          )}
                        </>
                      }
                      short={true}
                      variant={GREY50}
                    />
                  ))}
                {group.groupStatuses.availableForDelivery && (
                  <StatusLabel short={true} variant={BLUE25} label={AVAILABLE_FOR_DELVERY_LABEL} />
                )}
                {group.hasOutdatedPrediction && (
                  <OutdatedPredictionDisclaimer className={classes.statusLabel} />
                )}
              </Box>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

export default GroupHeaderRow
