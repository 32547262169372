import { useFormik } from 'formik'

function useFormHelper(configurations) {
  const helpers = useFormik({
    /**
     * @param isInitialValid Sets initial isValid state
     * Useful for disabling submit button by default, as running validations on mount may provide async/sync issues.
     * https://github.com/formium/formik/issues/1950
     */
    isInitialValid: false,

    /**
     * By default, Formik will run validation methods as follows:
     *
     * After "change" events/methods (things that updatevalues)
     * - handleChange
     * - setFieldValue
     * - setValues
     *
     * After "blur" events/methods (things that update touched)
     * - handleBlur
     * - setTouched
     * - setFieldTouched
     *
     * Whenever submission is attempted
     * - handleSubmit
     * - submitForm
     */

    /**
     * @param validateOnBlur Triggers form validation on field blur.
     * Default `true` as of "formik": "2.1.4",
     */
    validateOnBlur: true,

    /**
     * @param validateOnChange Triggers form validation on value change.
     * Default `true` as of "formik": "2.1.4",
     */
    validateOnChange: true,
    ...configurations,
  })

  return helpers
}

export default useFormHelper
