import { createAction } from 'redux-actions'

// Action Types
export const TOGGLE_DRAWER = 'drawers/TOGGLE_DRAWER'

// Actions
export const toggleDrawer = createAction(TOGGLE_DRAWER, (drawer, isOpen) => ({
  drawer,
  isOpen,
}))
