import React from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: 'inline-flex',
    borderRadius: 4,

    // child content can either be a button, div wrappers, or span wrappers. modify as needed
    '& span:not(:last-child) button,& div:not(:last-child) button,& button:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
    },

    '& span:not(:first-child) button,& div:not(:first-child) button,& button:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    // override MUIButton-root min-width to mimic .MuiButtonGroup-grouped
    '& > span > button': {
      minWidth: theme.spacing(5),
    },
  },
}))

interface Props {
  className?: string
  children: React.ReactNode
}

/**
 * This is a specialized component created in order to work around limitations of Material UI's ButtonGroup.
 *
 * The particular use case this was created to address is having disabled buttons show tooltips, which will not work
 * with MUI's default ButtonGroup due to its styling/code assuming buttons will be direct children.
 *
 * See https://github.com/mui-org/material-ui/issues/21313 for an example of the issue this is in response to.
 *
 * If this limitation no longer exists in the future, it would be fine for this component to be replaced with the
 * regular MUI ButtonGroup.
 */
const HorizontalButtonGroupLite = (props: Props) => {
  const classes = useStyles(props)
  const { className, children, ...rest } = props
  const effectiveClassName = cx(classes.buttonGroup, className)
  return (
    <div className={effectiveClassName} {...rest}>
      {children}
    </div>
  )
}

export default HorizontalButtonGroupLite
