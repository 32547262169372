import { Box, Theme, Tooltip, Typography, makeStyles } from '@material-ui/core'
import {
  GROUP_LABELS,
  PROGRESS_COMPLETED,
  PROGRESS_IN_PROGRESS,
  PROGRESS_IN_TRANSIT,
} from 'utils/milestone/MilestoneGroups/definitions'
import { formatCarrierLabel, toTitleCase } from 'utils/strUtils'

import DestinationIcon from 'components/icons/DestinationIcon'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import InProgressIcon from 'components/icons/InProgressIcon'
import InTransitIcon from 'components/icons/InTransitIcon'
import InfoIcon from 'components/icons/InfoIcon'
import { LocationZoneInterface } from 'store/models/LocationZone'
import MilestoneList from 'components/Timeline/TimelineSegment/MilestoneList'
import OceanIcon from 'components/icons/OceanIcon'
import ProgressCompletedIcon from 'components/icons/ProgressCompletedIcon'
import ProgressPartialCompleteIcon from 'components/icons/ProgressPartialCompleteIcon'
import RailIcon from 'components/icons/RailIcon'
import React from 'react'
import TruckIcon from 'components/icons/TruckIcon'
import UiGroupInterface from 'store/types/UiGroupInterface'
import classnames from 'classnames'
import groupLastTracedMilestoneIndex from 'utils/milestone/groupLastTracedMilestoneIndex'
import some from 'lodash/some'

const useStyles = makeStyles((theme: Theme) => ({
  statusIcon: {
    fontSize: 25,
    verticalAlign: 'middle',
  },
  statusIconContainer: {
    background: theme.palette.white,
    display: 'inline-block',
  },
  stage: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
  },
  location: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.2,
  },
  colorInTransit: {
    color: theme.palette.teal[600],
  },
  colorInProgress: {
    color: theme.palette.orange[500],
  },
  colorUnknown: {
    color: theme.palette.grey[300],
  },
  group: {
    position: 'relative',
    zIndex: 1,

    // The line connecting segments
    '&:before': {
      borderTop: `2px solid ${theme.palette.grey[500]}`,
      content: '""',
      width: '100%',
      height: 1,
      display: 'block',
      position: 'absolute',
      top: 14,
      left: 25,
      zIndex: -1,
    },

    // No line on last segment
    '&:last-child:before': {
      display: 'none',
    },
  },
  groupIncomplete: {
    // Overrides above with a dotted line for incomplete segments
    '&:before': {
      borderTop: `2px dotted ${theme.palette.grey[300]}`,
    },
  },
  vehicleIcon: {
    color: theme.palette.grey[500],
    height: 'auto',
    width: 45,
  },
  vehicleName: {
    ...theme.typography.caption,
    color: theme.palette.grey[500],
    marginTop: -5,
  },
  vehicleContainer: {
    background: theme.palette.white,
    display: 'inline-block',
    paddingRight: 2,
    verticalAlign: 'text-top',
  },
  infoIcon: {
    color: theme.palette.grey[500],
    width: theme.spacing(2),
  },
}))

const MAX_GROUPS_BEFORE_TOOLTIP = 6

interface Props {
  classes?: { [key: string]: string }
  group: UiGroupInterface
  groupCount: number
  groupName: string
  isPublicShare?: boolean
  locationZone: LocationZoneInterface | null
  milestones: GroupedMilestoneInterface[]
  isFirstGroup: boolean
  tripsCount: number | null
}

const TimelineSegment = (props: Props) => {
  const classes = useStyles(props)
  const {
    group,
    groupCount,
    groupName,
    isPublicShare = false,
    locationZone,
    milestones,
    isFirstGroup,
    tripsCount,
  } = props
  const { groupProgress } = group
  const groupLabel: string = GROUP_LABELS[groupName]

  // If this returns NOT NULL we know this group has the "current" milestone. For our needs here that's all
  // we need to know to know for some conditional styling below.
  const lastTracedMilestoneIndex = groupLastTracedMilestoneIndex(group)
  // We don't show an icon if this is the first group/segment on the timeline
  const equipmentCategory = !isFirstGroup && milestones[0]?.onEquipment?.category
  const equipmentName = toTitleCase(milestones[0]?.onEquipment?.name)
  const hasLastTracedAndIncomplete = some(
    milestones,
    (milestone: GroupedMilestoneInterface) => milestone.isLastTracedAndIncomplete
  )
  const hasNoProgress =
    !hasLastTracedAndIncomplete &&
    groupProgress !== PROGRESS_COMPLETED &&
    groupProgress !== PROGRESS_IN_PROGRESS &&
    groupProgress !== PROGRESS_IN_TRANSIT

  const hideVesselNames = groupCount > MAX_GROUPS_BEFORE_TOOLTIP

  return (
    <>
      {equipmentCategory && (
        <Box
          className={classnames(classes.group, {
            [classes.groupIncomplete]:
              groupProgress !== PROGRESS_COMPLETED && groupProgress !== PROGRESS_IN_PROGRESS,
          })}
        >
          <Box>
            <Tooltip
              title={
                equipmentName && hideVesselNames
                  ? `Vessel: ${formatCarrierLabel(equipmentName)}`
                  : ''
              }
            >
              <span className={classes.vehicleContainer}>
                {equipmentCategory === 'train' && (
                  <RailIcon width={40} className={classes.vehicleIcon} />
                )}
                {equipmentCategory === 'truck' && (
                  <TruckIcon width={40} className={classes.vehicleIcon} />
                )}
                {equipmentCategory === 'vessel' && (
                  <OceanIcon width={45} className={classes.vehicleIcon} />
                )}
                {equipmentName && hideVesselNames && <InfoIcon className={classes.infoIcon} />}
              </span>
            </Tooltip>
            {equipmentName && !hideVesselNames && (
              <Typography className={classes.vehicleName} paragraph={false}>
                {formatCarrierLabel(equipmentName)}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      <Box
        className={classnames(classes.group, {
          [classes.groupIncomplete]: groupProgress !== PROGRESS_COMPLETED,
        })}
      >
        <Box className={classes.statusIconContainer}>
          {hasLastTracedAndIncomplete ? (
            <ProgressPartialCompleteIcon className={classnames(classes.statusIcon)} />
          ) : (
            <>
              {groupProgress === PROGRESS_COMPLETED && (
                <ProgressCompletedIcon className={classes.statusIcon} />
              )}
              {groupProgress === PROGRESS_IN_TRANSIT && (
                <InTransitIcon className={classes.statusIcon} />
              )}
              {groupProgress === PROGRESS_IN_PROGRESS && (
                <InProgressIcon
                  className={classnames(classes.statusIcon, classes.colorInProgress)}
                />
              )}
              {hasNoProgress && <DestinationIcon className={classes.statusIcon} />}
            </>
          )}
        </Box>
        <Box>
          <Box mb={1}>
            <Typography
              component="span"
              variant="caption"
              className={classnames(classes.stage, {
                [classes.colorInProgress]:
                  !hasLastTracedAndIncomplete &&
                  lastTracedMilestoneIndex !== null &&
                  groupProgress === PROGRESS_IN_PROGRESS,
                [classes.colorInTransit]: groupProgress === PROGRESS_IN_TRANSIT,
                [classes.colorUnknown]: hasNoProgress,
              })}
            >
              {groupLabel}
            </Typography>
            <Typography
              display="block"
              component="span"
              variant="caption"
              className={classes.location}
            >
              {locationZone?.name}
            </Typography>
          </Box>
        </Box>
        <Box>
          <MilestoneList
            isPublicShare={isPublicShare}
            milestones={milestones}
            tripsCount={tripsCount}
          />
        </Box>
      </Box>
    </>
  )
}

export default TimelineSegment
