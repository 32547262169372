import React from 'react'
import { getRefTypeLabel } from 'utils/rollups'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  unnamed: {
    textTransform: 'none',
    fontStyle: 'italic',
    color: theme.palette.grey[800],
  },
  named: {
    textTransform: 'none',
  },
})

function ShareGroupName({ item, classes }) {
  const refLabel = getRefTypeLabel(item.refType)
  if (item.name) {
    return <span className={classes.named}>{item.name}</span>
  } else if (item.refNumbers.length === 0) {
    return <span className={classes.unnamed}>Empty Group</span>
  } else if (item.refNumbers.length === 1) {
    return (
      <span className={classes.named}>
        {refLabel} #{item.refNumbers[0]}
      </span>
    )
  } else {
    return (
      <span className={classes.unnamed}>
        Unnamed Group ({item.refNumbers.length} {refLabel}s)
      </span>
    )
  }
}

export default withStyles(styles)(ShareGroupName)
