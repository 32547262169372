import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  resetPasswordConfirm: ({ email, new_password, pwd_token }) => {
    return api._call('post', `/user/public/reset_password_confirm`, {
      email,
      new_password,
      pwd_token,
    })
  },
}

export default api
