import {
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  LinearProgress,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core/'
import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import React, { useEffect, useState } from 'react'
import { blueGrey, deepOrange } from '@material-ui/core/colors'
import { useDispatch, useSelector } from 'react-redux'

import { ADMIN_ROUTE } from 'utils/routes'
import Button from 'components/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import Faq from './Faq'
import MapRoutes from './MapRoutes'
import Notifications from 'components/Notifications'
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined'
import client from 'utils/api/client'
import { currentTenantFromState } from 'store/auth/user/selectors'
import humps from 'humps'

const useStyles = makeStyles(theme => ({
  dialog: {
    minWidth: theme.spacing(50),
  },
  toolbar: {
    margin: theme.spacing(1),
  },
  formRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

/**
 * TODO's:
 *
 * Add CM Logo - Ideally included in a snapshot
 *     https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#symbol
 * Create snapshot functionality:
 *     https://docs.mapbox.com/ios/maps/examples/map-snapshotter/
 */

const LabPage = () => {
  const classes = useStyles()
  const currentTenant = useSelector(currentTenantFromState)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [helpOpen, setHelpOpen] = useState(true) // Purposely left open to be annoying
  const handleHelpOpen = () => setHelpOpen(true)
  const handleHelpClose = () => setHelpOpen(false)
  const handleFormClose = () => setEditOpen(false)
  const handleFormOpen = () => setEditOpen(true)

  const [circleColor, setCircleColor] = useState(blueGrey[50])
  const [circleOpacity, setCircleOpacity] = useState(0.3)
  const [circleRadius, setCircleRadius] = useState(1)
  const [dataSource, setDataSource] = useState(null)
  const [tripLimit, setTripLimit] = useState(70000)
  const [locationLimit, setLocationLimit] = useState(null)
  const [lineColor, setLineColor] = useState(deepOrange[200])
  const [lineOpacity, setLineOpacity] = useState(0.01)
  const [lineWidth, setLineWidth] = useState(1)
  const [renderWorldCopies, setRenderWorldCopies] = useState(true)
  const [showNavigation, setShowNavigation] = useState(true)

  const [styleUrl, setStyleUrl] = useState('mapbox://styles/jmoyle/ck6g3hfcz0aez1inrrt3tbb9i')

  const handleFormSubmit = event => {
    event.preventDefault()
    fetchDataSource(event.target.segmentLimit.value)
    handleFormClose()
  }

  const fetchDataSource = ({ tripLimit, locationLimit }) => {
    setLoading(true)
    client
      .get('/map_adventure/route_segments', {
        params: humps.decamelizeKeys({ tripLimit, locationLimit }),
      })
      .then(response => {
        const data = humps.camelizeKeys(response.data)
        setLoading(false)
        setDataSource({
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              ...data.locations.map(coordinates => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates,
                },
              })),
              ...data.routeSegments.map(coordinates => ({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates,
                },
              })),
            ],
          },
        })
      })
      .catch(error => {
        setLoading(false)
        dispatch(showNotification(error.message, { type: MESSAGE_TYPES.ERROR }))
      })
  }

  useEffect(() => {
    fetchDataSource({ tripLimit, locationLimit })
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div>{loading && <LinearProgress />}</div>
      <ButtonGroup color="default" variant="outlined" className={classes.toolbar}>
        <Button href={ADMIN_ROUTE.buildUrl()}>Admin Page</Button>
        <Button disabled>Tenant: {currentTenant}</Button>
        <Button startIcon={<EditIcon color="action" />} onClick={handleFormOpen}>
          Edit
        </Button>
        <Button
          onClick={handleHelpOpen}
          startIcon={<WarningOutlinedIcon color="error" />}
          endIcon={<WarningOutlinedIcon color="error" />}
        >
          Read Me
        </Button>
      </ButtonGroup>
      {editOpen && (
        <Dialog open={editOpen} onClose={handleFormClose} className={classes.dialog}>
          <DialogTitle>Edit</DialogTitle>
          <DialogContent>
            <form className={classes.formRoot} noValidate onSubmit={handleFormSubmit} id="editMap">
              <Typography variant="subtitle2">Map Controls</Typography>
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showNavigation}
                    onChange={e => setShowNavigation(e.target.checked)}
                    value={showNavigation}
                    name="showNavigation"
                    color="default"
                  />
                }
                label="Map Navigation"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={renderWorldCopies}
                    onChange={e => setRenderWorldCopies(e.target.checked)}
                    value={renderWorldCopies}
                    name="renderWorldCopies"
                    color="default"
                  />
                }
                label="Render World Copies"
              />
              <TextField
                variant="outlined"
                label="Map Style"
                name="mapStyle"
                fullWidth
                value={styleUrl}
                helperText={
                  <>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.mapbox.com/mapbox-gl-js/example/custom-style-id/"
                    >
                      Documentation
                    </Link>
                    {' | '}
                    URL from{' '}
                    <Link target="_blank" rel="noopener" href="https://studio.mapbox.com/">
                      Mapbox Studio
                    </Link>
                  </>
                }
                onChange={e => setStyleUrl(e.target.value)}
              />
              <Typography variant="subtitle2">Line Controls</Typography>
              <Divider />
              <TextField
                variant="outlined"
                label="Line Count"
                name="segmentLimit"
                helperText="Trip Segments | Empty all or comma-less int: e.g. 200000"
                value={tripLimit}
                onChange={e => setTripLimit(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Line Color"
                name="lineColor"
                helperText={
                  <>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/?q=custom%20style&size=n_20_n#paint-line-line-color"
                    >
                      Documentation
                    </Link>
                    {' | '}
                    Hexcode format
                  </>
                }
                value={lineColor}
                onChange={e => setLineColor(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Line Width"
                name="lineWidth"
                helperText={
                  <>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-line-line-width"
                    >
                      Documentation
                    </Link>
                    {' | '}
                    Units in pixels
                  </>
                }
                value={lineWidth}
                onChange={e => setLineWidth(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Line Opacity"
                name="lineOpacity"
                helperText={
                  <>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/?q=custom%20style&size=n_20_n#paint-line-line-opacity"
                    >
                      Documentation
                    </Link>
                    {' | '}
                    Between 0 and 1 inclusive - e.g. 0.05
                  </>
                }
                value={lineOpacity}
                onChange={e => setLineOpacity(e.target.value)}
              />
              <Typography variant="subtitle2">Circle Controls</Typography>
              <Divider />
              <TextField
                variant="outlined"
                label="Location Count"
                name="locationLimit"
                helperText="Trip stops | Empty for all or comma-less int: e.g. 200000"
                value={locationLimit}
                onChange={e => setLocationLimit(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Circle Color"
                name="circleColor"
                helperText={
                  <>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-circle-circle-colorhttps://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-circle-circle-color"
                    >
                      Documentation
                    </Link>
                    {' | '}
                    Hexcode format
                  </>
                }
                value={circleColor}
                onChange={e => setCircleColor(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Circle Radius"
                name="circleRadius"
                helperText={
                  <>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-circle-circle-radius"
                    >
                      Documentation
                    </Link>
                    {' | '}
                    Between 0 and 1 inclusive - e.g. 0.05
                  </>
                }
                value={circleRadius}
                onChange={e => setCircleRadius(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Circle Opacity"
                name="circleOpacity"
                helperText={
                  <>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-circle-circle-opacity"
                    >
                      Documentation
                    </Link>
                    {' | '}
                    Between 0 and 1 inclusive - e.g. 0.05
                  </>
                }
                value={circleOpacity}
                onChange={e => setCircleOpacity(e.target.value)}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFormClose}>Cancel</Button>
            <Button color="primary" type="submit" form="editMap">
              Apply Temporary Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={helpOpen} onClose={handleHelpClose}>
        <DialogTitle>Read Me</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Faq />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {dataSource && !editOpen && !loading && (
        <MapRoutes
          circleColor={circleColor}
          circleOpacity={circleOpacity}
          circleRadius={circleRadius}
          dataSource={dataSource}
          lineColor={lineColor}
          lineOpacity={lineOpacity}
          lineWidth={lineWidth}
          styleUrl={styleUrl}
          renderWorldCopies={renderWorldCopies}
          showNavigation={showNavigation}
        />
      )}
      <Notifications />
    </>
  )
}

export default LabPage
