import { ButtonGroup, List, ListItem, Popover, Typography, withStyles } from '@material-ui/core'

import ArrowDownIcon from 'components/icons/ArrowDownIcon'
import ArrowUpIcon from 'components/icons/ArrowUpIcon'
import Button from 'components/core/Button'
import PropTypes from 'prop-types'
import { ROLLUPS } from 'utils/rollups'
import React from 'react'
import find from 'lodash/find'
import get from 'lodash/get'

const styles = theme => ({
  button: {
    whiteSpace: 'nowrap',
  },
})
class SortChooser extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    rollup: PropTypes.oneOf(ROLLUPS),
    sortState: PropTypes.object.isRequired,
    sortableCols: PropTypes.array.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  updateSortStateDirection = () => {
    const { sortState, setSortState } = this.props
    const direction = get(sortState, 'direction')
    let newDirection = direction === 'desc' ? 'asc' : 'desc'
    let newState = { ...sortState, direction: newDirection }
    setSortState(newState)
    this.handleClose()
  }

  updateSortStateType = sortParam => () => {
    const { sortState, setSortState } = this.props
    let newState = { ...sortState, sort_param: sortParam }
    setSortState(newState)
    this.handleClose()
  }

  // Return title based on equipment page vs all others
  getSortDisplayName = sortItem => {
    return get(sortItem, 'title')
  }

  render() {
    const { classes, sortableCols, sortState, className } = this.props
    const { anchorEl } = this.state
    const sortParam = get(sortState, 'sort_param')
    const currCol = find(sortableCols, item => item.sort_param === sortParam)
    const desc = get(sortState, 'direction') === 'desc'

    return (
      <>
        <ButtonGroup className={className}>
          <Button
            variant="outlinedLightGrey"
            className={classes.button}
            onClick={this.handleClick}
            data-testid="sort-chooser__button"
          >
            Sort by: {this.getSortDisplayName(currCol)}
          </Button>
          <Button
            data-testid="sort-chooser__sort"
            variant="outlinedLightGrey"
            className={classes.button}
            onClick={this.updateSortStateDirection}
          >
            {desc ? <ArrowDownIcon color="action" /> : <ArrowUpIcon color="action" />}
          </Button>
        </ButtonGroup>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={this.handleClose}
        >
          <List data-testid="sort-chooser__list">
            {sortableCols.map((item, idx) => {
              const sortParam = get(item, 'sort_param')
              return (
                <ListItem
                  button
                  dense
                  onClick={this.updateSortStateType(sortParam)}
                  key={`sort-item-${idx}`}
                >
                  <Typography variant="body2">{this.getSortDisplayName(item)}</Typography>
                </ListItem>
              )
            })}
          </List>
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(SortChooser)
