import { Theme, Typography, makeStyles } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[500],
    fontStyle: 'italic',
  },
}))

export const NOT_PROVIDED_STRING = 'Not Provided Yet'

interface Props {
  classes?: Record<string, string>
  text?: string
}

const NotProvided = (props: Props) => {
  const classes = useStyles(props)
  const { text } = props

  return (
    <Typography variant="caption" className={classes.root}>
      {text ?? NOT_PROVIDED_STRING}
    </Typography>
  )
}

export default NotProvided
