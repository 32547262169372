import { Popover, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { configSelector, currentFilterSelector, unselectDefaultFilter } from 'store/defaultFilters'

import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import RangeFilter from '../Filters/RangeFilter'
import { connect } from 'react-redux'
import find from 'lodash/find'
import { findDOMNode } from 'react-dom'
import get from 'lodash/get'
import styles from './styles'

class ActiveDefaultFilter extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    filterConfigs: PropTypes.array.isRequired,
    activeDefaultFilter: PropTypes.object,
    addFilter: PropTypes.func.isRequired,
  }

  state = {
    popoverOpen: false,
    anchorEl: null,
  }

  UNSAFE_componentWillMount() {
    const requiresConfig = get(this.props.activeDefaultFilter, 'requiresConfiguration')
    if (!requiresConfig) {
      this.handleSubmit()
    }
  }
  componentDidMount() {
    if (this.anchorEl) {
      this.setState({
        popoverOpen: true,
        anchorEl: findDOMNode(this.anchorEl),
      })
    }
  }

  handleSubmit = configuration => {
    const { filterConfigs, addFilter, activeDefaultFilter, unselectDefaultFilter } = this.props
    unselectDefaultFilter()
    let configuredFilters = activeDefaultFilter.getKeyValues(configuration)
    configuredFilters.forEach(filter => {
      const matchingConfig = find(filterConfigs, col => col.name === filter.key)
      addFilter(matchingConfig, filter.value)
    })
  }

  render() {
    const { activeDefaultFilter, unselectDefaultFilter, classes } = this.props
    const name = get(activeDefaultFilter, 'name')
    const title = get(activeDefaultFilter, 'title')
    const { popoverOpen, anchorEl } = this.state
    return (
      <div>
        <div style={{ height: 0 }} ref={el => (this.anchorEl = el)} />
        <Popover
          className={classes.popover}
          elevation={1}
          onClose={unselectDefaultFilter} // only fires on 'esc' keydown
          open={popoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          classes={{ paper: classes.filterComponentPopover }}
        >
          <div data-testid="filtering__active-default-filter">
            <div className={classes.toolbar}>
              <Typography variant="body2" className={classes.title}>
                {name}
              </Typography>
              <CloseIcon className={classes.closeButton} onClick={unselectDefaultFilter} />
            </div>
            <div className={classes.filterComponentContent}>
              <Typography variant="body2" className={classes.filterTitle}>
                {title}
              </Typography>
              <RangeFilter
                classes={{ toolbar: classes.toolbar }}
                onSubmit={this.handleSubmit}
                onRequestClose={unselectDefaultFilter}
              />
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}

const mapDispatchToProps = {
  unselectDefaultFilter,
}

const mapStateToProps = state => {
  return {
    config: configSelector(state),
    activeDefaultFilter: currentFilterSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActiveDefaultFilter))
