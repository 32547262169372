import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  addSource: ({ name }) => {
    return api._call('post', `/integration/source`, { name })
  },
  addTenant: ({ display_name, name, parent_id, url_subdomain }) => {
    return api._call('post', `/tenant`, { display_name, name, parent_id, url_subdomain })
  },
  fetchTenantList: () => {
    return api._call('get', `/tenant/list`)
  },
  switchTenant: ({ email, tenant }) => {
    return api._call('post', `/user/switch_tenant`, { email, tenant })
  },
}

export default api
