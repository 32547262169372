export default theme => ({
  listItem: {
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.blue[800],
    },
    '&:focus': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.blue[800],
    },
  },
  listItemText: {
    paddingRight: 0,
  },
  alertIcon: {
    right: 60,
  },
  editIcon: {
    right: 30,
  },
  deleteIcon: {
    right: 0,
  },
  iconButton: {
    display: 'block',
    padding: 7,
    position: 'absolute',
  },
  icon: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.body1.fontSize,
  },
  actions: {
    position: 'absolute',
    right: 16,
    top: 0,
  },
  primaryText: {
    color: theme.palette.grey[500],
    display: 'block',
    fontSize: theme.typography.body2.fontSize,
    marginLeft: theme.spacing(0.5),
    maxWidth: 145,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  isActiveText: {
    color: theme.palette.blue[900],
  },
  textWithIcons: {
    padding: '5px 16px',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  isActive: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.blue[900],
  },
  editNameWrapper: {
    display: 'flex',
    padding: '5px 16px',
  },
  editField: {
    fontSize: theme.typography.body2.fontSize,
    width: 170, // IE
  },
  saveNameButton: {
    display: 'inline-flex',
    flexDirection: 'column',
    height: 30,
    marginLeft: 10,
    padding: '0 10px',
  },
})
