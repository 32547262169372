import React from 'react'

export const MILESTONES = {
  at_delivery_location: 'At final delivery location',
  available_for_delivery: 'Available for pickup',
  empty_dispatch: 'Empty container dispatch',
  empty_return: 'Empty container return',
  export_drayage_arrive: 'Export drayage arrive',
  export_drayage_depart: 'Export drayage depart',
  export_rail_arrive: 'Export rail arrive',
  export_rail_depart: 'Export rail depart',
  export_rail_load: 'Load on rail for export',
  export_rail_unload: 'Unload from rail',
  import_drayage_arrive: 'Import drayage arrive',
  import_drayage_depart: 'Import drayage depart',
  import_rail_arrive: 'Import rail arrive',
  import_rail_depart: 'Import rail depart',
  import_rail_load: 'Load on rail for import',
  import_rail_unload: 'Unload from rail',
  out_for_delivery: 'Out for delivery',
  receipt: 'Receipt by ocean carrier',
  transshipment_drayage_arrive: 'Transshipment drayage arrive',
  transshipment_drayage_depart: 'Transshipment drayage depart',
  vessel_arrive_destination: 'Vessel arrive at dest. port',
  vessel_arrive_transshipment: 'Vessel arrive at transshipment port',
  vessel_depart_origin: 'Vessel depart origin port',
  vessel_depart_transshipment: 'Vessel depart transshipment port',
  vessel_discharge_destination: 'Container discharge at dest. port',
  vessel_discharge_transshipment: 'Container discharge for transshipment',
  vessel_load_origin: 'Load on vessel at origin port',
  vessel_load_transshipment: 'Load on vessel for transshipment',
  generic_destination: 'Arrival',
}

export const PREDICTION_STATUS_STALE = 'Outdated Prediction: No Recent Carrier Update'
export const PREDICTION_STATUS_STALE_MESSAGE = (
  <span>We will not update this prediction until we receive an update from the carrier.</span>
)

export const PREDICTION_STATUS_UNKNOWN = 'Unknown Status'
export const PREDICTION_STATUS_UNKNOWN_MESSAGE = (
  <span>
    We expected the carrier to send an update for this milestone, but they haven't. If you're a new
    customer, this status may be resolved once we ingest all of your data.
  </span>
)

export const OUTDATED_ESTIMATES_DISCLAIMER = 'Outdated Estimates: No Recent Carrier Update'
export const UNKNOWN_LOCATION_DATE_MESSAGE = 'Unknown Location and Dates'
