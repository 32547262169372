import { Box, IconButton, Popover, Theme, makeStyles } from '@material-ui/core'
import { MESSAGE_TYPES, showNotification } from 'store/notifications'

import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import ContentCopyIcon from 'components/icons/ContentCopyIcon'
import MESSAGES from 'store/notifications/messages'
import React from 'react'
import copyToClipboard from 'copy-to-clipboard'
import { useDispatch } from 'react-redux'

const MAX_POPOVER_WIDTH = 65

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    '& .MuiBox-root:focus': {
      outline: 'none',
    },
    '& li:focus': {
      outline: 'none',
    },
  },
  popoverCloseButton: {
    padding: theme.spacing(1),
    position: 'absolute',
    right: 10,
    top: 5,
  },
  popoverFooter: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  popoverPaper: {
    boxShadow: theme.shadows[10],
    maxWidth: '90vw',
  },
  popoverListContainer: {
    maxWidth: 'calc(100% - 34px)',
  },
  popoverList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    gridAutoRows: 20,
    gridGap: 5,
    maxHeight: '60vh',
    minWidth: 150,
    overflowX: 'hidden',
    overflowY: 'auto',
    maxWidth: `${MAX_POPOVER_WIDTH}vw`,
  },
}))

interface Props {
  anchorEl: HTMLElement | null
  classes?: Record<string, string>
  clipboardData?: string | null
  copyAllOnClick?: () => void
  handleClose: () => void
  isPublicShare?: boolean
  listData: React.ReactElement[] | undefined
}

/**
 * A simple popover that lists items (for example, ref numbers) and that includes Copy All clipboard functionality.
 * @param anchorEl the HTML element the popover will align itself with
 * @param classes CSS class overrides, can be extended if needed
 * @param clipboardData optional, expects a comma-delimited string, i.e., `"123,456"
 * @param copyAllOnClick optional, if present this function will be called when Copy All is clicked
 * @param handleClose a function to close the popover. This needs to live in the parent because the parent usually
 *  will also need to close the popover
 * @param listData the JSX for the list itself, generally an array of JSX/Nodes
 * @returns
 */
const PopoverList = (props: Props) => {
  const classes = useStyles(props)
  const {
    anchorEl,
    clipboardData,
    copyAllOnClick,
    handleClose,
    isPublicShare = false,
    listData,
  } = props
  const dispatch = useDispatch()
  const listDataLength = listData?.length ?? 0

  const copyContainerListToClipboard = () => {
    if (clipboardData) {
      if (copyAllOnClick) {
        copyAllOnClick()
      }
      const res = copyToClipboard(clipboardData)
      res
        ? dispatch(showNotification(MESSAGES.filterCopySuccess))
        : dispatch(showNotification(MESSAGES.filterCopyError, { type: MESSAGE_TYPES.ERROR }))
    }
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={classes.popover}
      elevation={1}
      keepMounted
      onClose={handleClose}
      open={Boolean(anchorEl)}
      classes={{ paper: classes.popoverPaper }}
    >
      <IconButton className={classes.popoverCloseButton} onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <Box p={2} display="flex" flexBasis="column" className={classes.popoverListContainer}>
        <Box
          className={classes.popoverList}
          style={{
            width:
              listDataLength < MAX_POPOVER_WIDTH
                ? `${Math.ceil(listDataLength / 10) * 10 + 5}vw`
                : `${MAX_POPOVER_WIDTH}vw`,
          }}
        >
          {listData}
        </Box>
      </Box>
      {!isPublicShare && clipboardData && (
        <Box pt={1.5} pb={1.5} pl={2} className={classes.popoverFooter}>
          <Button
            variant="textLightDefault"
            size="small"
            onClick={copyContainerListToClipboard}
            startIcon={<ContentCopyIcon />}
          >
            Copy All
          </Button>
        </Box>
      )}
    </Popover>
  )
}

export default PopoverList
