import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { createAction, handleActions } from 'redux-actions'

import browserHistory from 'utils/history'
import client from 'utils/api/client'
import { fromQueryString } from 'utils/urlBuilder'
import get from 'lodash/get'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import moment from 'moment'
import store from 'store'

// Action Types
export const SELECT_ALERT = 'alerts/SELECT_ALERT'
export const AGGREGATE_ALERTS_LOAD = 'alerts/AGGREGATE_ALERTS_LOAD'
export const AGGREGATE_ALERTS_SUCCESS = 'alerts/AGGREGATE_ALERTS_SUCCESS'
export const AGGREGATE_ALERTS_COUNT_LOAD = 'alerts/AGGREGATE_ALERTS_COUNT_LOAD'
export const AGGREGATE_ALERTS_COUNT_SUCCESS = 'alerts/AGGREGATE_ALERTS_COUNT_SUCCESS'
export const SET_PAGE = 'alerts/SET_PAGE'
export const SET_STATE_FROM_URL_START = 'alerts/SET_STATE_FROM_URL_START'
export const SET_STATE_FROM_URL = 'alerts/SET_STATE_FROM_URL'
export const SET_RESOLVED_IDX = 'alerts/SET_RESOLVED_IDX'
export const SET_LAST_VIEWED = 'alerts/SET_LAST_VIEWED'
export const SET_NEW_AGGREGATE_ALERTS_COUNT = 'alerts/SET_NEW_AGGREGATE_ALERTS_COUNT'
export const NEW_AGGREGATE_ALERTS_COUNT_LOAD = 'alerts/NEW_AGGREGATE_ALERTS_COUNT_LOAD'
export const NEW_AGGREGATE_ALERTS_COUNT_SUCCESS = 'alerts/NEW_AGGREGATE_ALERTS_COUNT_SUCCESS'
export const SET_ALERT_SEARCH_VALUE = 'alerts/SET_ALERT_SEARCH_VALUE'

// Action Creators
export const setStateFromUrl = createAction(SET_STATE_FROM_URL_START, configId => configId)
export const setResolvedIdx = createAction(SET_RESOLVED_IDX, idx => idx)
export const selectAlert = createAction(SELECT_ALERT, alert => alert)
export const setPage = createAction(SET_PAGE, page => page)
export const setSearchTerm = createAction(SET_ALERT_SEARCH_VALUE, searchVal => searchVal)
export const setLastViewed = createAction(SET_LAST_VIEWED, lastViewed => lastViewed)
export const getAggregateAlerts = createAction(AGGREGATE_ALERTS_LOAD)
export const getAggregateAlertsCount = createAction(AGGREGATE_ALERTS_COUNT_LOAD)
export const getNewAggregateAlertsCount = createAction(NEW_AGGREGATE_ALERTS_COUNT_LOAD)
export const setNewAggregateAlertsCount = createAction(SET_NEW_AGGREGATE_ALERTS_COUNT)

export const ROW_LIMIT = 20
const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

// Selectors
export const isAlertDrawerExpandedSelector = state => state.alerts.isExpanded
export const allAlertsSelector = state => state.alerts
export const aggregatedAlertsSelector = state => state.alerts.data
export const aggregatedAlertsLoadingSelector = state => state.alerts.isLoading
export const aggregatedAlertsCountSelector = state => state.alerts.totalCount
export const aggregatedAlertsPagesSelector = state => state.alerts.totalPages
export const aggregatedAlertsCurrPageSelector = state => state.alerts.currentPage
export const resolvedIdxSelector = state => state.alerts.resolvedIdx
export const searchTermSelector = state => state.alerts.searchTerm
export const lastViewedSelector = state => {
  const lastViewed = state.alerts.lastViewed
  if (lastViewed && moment(lastViewed).isValid()) return moment(lastViewed).utc()
  return null
}
export const newAlertsCountSelector = state => state.alerts.newAlertsCount

const LAST_VIEWED_STORE_KEY = 'alerts_last_viewed'

// Initial State
const initState = {
  isExpanded: true,
  currentAlert: null,
  isLoading: false,
  searchTerm: '',
  data: [],
  totalCount: 0,
  newAlertsCount: 0,
  totalPages: 0,
  currentPage: 0,
  configId: null,
  resolvedIdx: 0,
  lastViewed: store.get(LAST_VIEWED_STORE_KEY, null),
  onlyLatest: false,
  emailUid: null,
}

export const RESOLVED_OPTIONS = [
  {
    name: 'All alerts',
    value: null,
  },
  {
    name: 'Unresolved alerts',
    value: false,
  },
  {
    name: 'Resolved alerts',
    value: true,
  },
]

function getParams(alertState) {
  const { configId, resolvedIdx, onlyLatest, lastViewed, emailUid, searchTerm } = alertState
  const resolved = get(RESOLVED_OPTIONS, `[${resolvedIdx}].value`, null)

  let params = {}
  if (resolved !== null) {
    params['is_resolved'] = resolved
  }
  if (configId !== null) {
    params['alert_config_id'] = configId
  }
  if (searchTerm !== null && searchTerm !== '') {
    params['ref_number'] = searchTerm
  }
  if (onlyLatest) {
    if (lastViewed && moment(lastViewed).isValid()) {
      params['alerted_after'] = moment(lastViewed).utc().format(API_DATE_FORMAT)
    }
  }
  if (emailUid) {
    params['email_tracking_uid'] = emailUid
  }
  return params
}

function* loadAlertsAsync(action) {
  try {
    const alertState = yield select(allAlertsSelector)
    const currPage = yield select(aggregatedAlertsCurrPageSelector)
    const offset = currPage * ROW_LIMIT
    const limit = ROW_LIMIT

    let params = getParams(alertState)
    params['limit'] = limit
    params['offset'] = offset

    const response = yield call(client.get, '/shipment_alerts/list', { params })
    yield put({ type: AGGREGATE_ALERTS_SUCCESS, payload: response.data })
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}
function* loadAlertsCountAsync(action) {
  try {
    const isExternalUser = yield select(isExternalUserSelector)
    if (isExternalUser) {
      return
    }

    const alertState = yield select(allAlertsSelector)
    let params = getParams(alertState)
    const response = yield call(client.get, '/shipment_alerts/count', { params })
    yield put({ type: AGGREGATE_ALERTS_COUNT_SUCCESS, payload: response.data })
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

function* loadNewAlertsCountAsync(action) {
  try {
    const isExternalUser = yield select(isExternalUserSelector)
    if (isExternalUser) {
      return
    }

    const alertState = yield select(allAlertsSelector)
    const lastViewed = yield select(lastViewedSelector)
    let params = getParams(alertState)
    if (lastViewed !== null) {
      params['alerted_after'] = lastViewed.format(API_DATE_FORMAT)
    }

    const response = yield call(client.get, '/shipment_alerts/count', { params })
    yield put({ type: NEW_AGGREGATE_ALERTS_COUNT_SUCCESS, payload: response.data })
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

function* reloadData() {
  yield put(getAggregateAlerts())
  yield put(getAggregateAlertsCount())
}

function* setStateFromURLAsync(action) {
  // Try to extract param from url.  If it's not there, default to what is in initial state
  const query = fromQueryString(browserHistory.location.search)
  const onlyLatest = get(query, 'latest', false)
  const emailUid = get(query, 'emailUID', false)

  let configId = action.payload
  if (configId) {
    configId = parseInt(configId)
  } else {
    configId = null
  }

  yield put({
    type: SET_STATE_FROM_URL,
    payload: {
      configId,
      onlyLatest,
      emailUid,
      currentPage: 0,
    },
  })
}

function* watchAlertsLoad() {
  yield all([
    takeLatest(SET_PAGE, reloadData),
    takeLatest(SET_RESOLVED_IDX, reloadData),
    takeLatest(SET_ALERT_SEARCH_VALUE, reloadData),
    takeLatest(SET_STATE_FROM_URL, reloadData),
    takeLatest(SET_STATE_FROM_URL_START, setStateFromURLAsync),
    takeLatest(AGGREGATE_ALERTS_LOAD, loadAlertsAsync),
    takeLatest(AGGREGATE_ALERTS_COUNT_LOAD, loadAlertsCountAsync),
    takeLatest(NEW_AGGREGATE_ALERTS_COUNT_LOAD, loadNewAlertsCountAsync),
  ])
}

function* logEventAsync(getEvent, { payload }) {
  const { eventType, metadata } = getEvent(payload)
  if (eventType) {
    yield logger.notify(eventType, metadata)
  }
}

const genericEvent = (eventName, key = null) => payload => {
  const value = key !== null ? payload[key] : payload
  return {
    eventType: eventName,
    metadata: {
      value,
    },
  }
}

function* watchLogEvents() {
  yield all([
    takeEvery(SET_PAGE, logEventAsync, genericEvent('Alerts Set Page')),
    takeEvery(SET_ALERT_SEARCH_VALUE, logEventAsync, genericEvent('Alerts Search')),
  ])
}

export const sagas = [watchAlertsLoad(), watchLogEvents()]

// Reducer
export default handleActions(
  {
    [SELECT_ALERT]: (state, { type, payload }) => {
      return { ...state, currentAlert: payload }
    },
    [AGGREGATE_ALERTS_LOAD]: (state, { type, payload }) => {
      return { ...state, isLoading: true }
    },
    [AGGREGATE_ALERTS_SUCCESS]: (state, { type, payload }) => {
      return { ...state, data: payload, isLoading: false }
    },
    [AGGREGATE_ALERTS_COUNT_SUCCESS]: (state, { type, payload }) => {
      const totalCount = get(payload, 'count', 0)
      const totalPages = parseInt(totalCount / ROW_LIMIT)
      return { ...state, totalPages, totalCount }
    },
    [NEW_AGGREGATE_ALERTS_COUNT_SUCCESS]: (state, { type, payload }) => {
      const newAlertsCount = get(payload, 'count', 0)
      return { ...state, newAlertsCount }
    },
    [SET_NEW_AGGREGATE_ALERTS_COUNT]: (state, { type, payload }) => {
      return { ...state, newAlertsCount: payload }
    },
    [SET_PAGE]: (state, { payload }) => {
      return { ...state, currentPage: payload }
    },
    [SET_ALERT_SEARCH_VALUE]: (state, { payload }) => {
      return { ...state, searchTerm: payload, currentPage: 0 }
    },
    [SET_RESOLVED_IDX]: (state, { payload }) => {
      return { ...state, resolvedIdx: payload }
    },
    [SET_LAST_VIEWED]: (state, { payload }) => {
      store.set(LAST_VIEWED_STORE_KEY, payload)
      return { ...state, lastViewed: payload }
    },
    [SET_STATE_FROM_URL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initState
)
