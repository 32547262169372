import React from 'react'
import { SvgIcon } from '@material-ui/core'

// exported so that aliased import in Storybook will not break
export const ShareIcon = ({ ...props }) => (
  <SvgIcon viewBox={'-2 0 29 29'} {...props}>
    <g>
      <path d="M22.4320652,16.9712313 C23.8396739,16.9712313 25,15.8481013 25,14.4856157 C25,13.12313 23.8396739,12 22.4320652,12 C21.0244565,12 19.8641304,13.12313 19.8641304,14.4856157 C19.8641304,14.7433832 19.9021739,14.9827388 19.9782609,15.2220944 L16.173913,17.8918297 C15.6222826,17.3762946 14.8804348,17.0448792 14.0434783,17.0448792 C12.3695652,17.0448792 11,18.3705409 11,19.990794 C11,21.6110472 12.3695652,22.9367089 14.0434783,22.9367089 C14.8804348,22.9367089 15.6222826,22.6052934 16.173913,22.0897583 L19.9592391,24.7779056 C19.8831522,25.0172612 19.8451087,25.2566168 19.8451087,25.5143843 C19.8451087,26.87687 21.0054348,28 22.4130435,28 C23.8206522,28 24.9809783,26.87687 24.9809783,25.5143843 C24.9809783,24.1518987 23.8206522,23.0287687 22.4130435,23.0287687 C21.7472826,23.0287687 21.138587,23.2865362 20.6820652,23.6915995 L16.8967391,21.0218642 C17.0108696,20.7088608 17.0869565,20.3590334 17.0869565,20.009206 C17.0869565,19.6593786 17.0108696,19.3095512 16.8967391,18.9965478 L20.6820652,16.3084005 C21.138587,16.7134638 21.7472826,16.9712313 22.4320652,16.9712313 Z M22.4320652,13.2888377 C23.1168478,13.2888377 23.6684783,13.8227848 23.6684783,14.4856157 C23.6684783,15.1484465 23.1168478,15.6823936 22.4320652,15.6823936 C21.7472826,15.6823936 21.1956522,15.1484465 21.1956522,14.4856157 C21.1956522,13.8227848 21.7472826,13.2888377 22.4320652,13.2888377 Z M14.0625,21.6478711 C13.111413,21.6478711 12.3505435,20.9113924 12.3505435,19.990794 C12.3505435,19.0701956 13.111413,18.3337169 14.0625,18.3337169 C15.013587,18.3337169 15.7744565,19.0701956 15.7744565,19.990794 C15.7744565,20.9113924 14.9945652,21.6478711 14.0625,21.6478711 Z M22.4320652,24.3360184 C23.1168478,24.3360184 23.6684783,24.8699655 23.6684783,25.5327963 C23.6684783,26.1956272 23.1168478,26.7295742 22.4320652,26.7295742 C21.7472826,26.7295742 21.1956522,26.1956272 21.1956522,25.5327963 C21.1956522,24.8699655 21.7472826,24.3360184 22.4320652,24.3360184 Z" />
      <path
        d="M10.8783098,25 C10.8812885,24.5837813 10.8527755,24.0009242 10.9279644,24.0009242 C12.7083178,24.0009242 15.378848,24.0009242 18.939555,24.0009242 C19.5220713,24.0009242 19.9948382,23.5329842 19.9948382,22.9564153 L19.9948382,2.06623741 C19.9948382,1.49071301 19.5220713,1.02172852 18.939555,1.02172852 L5.96273751,1.02172852 L0.999740642,5.93405385 C0.999740642,7.78642398 0.999740642,9.17570159 0.999740642,10.1018867 C0.999740642,10.2250622 0.345415485,10.1361668 -4.27201052e-11,10.1513004 L2.27373675e-13,5.725 L0.1533,5.35625 L5.3991,0.152083333 L5.7708,-3.55271368e-15 L18.9,-3.55271368e-15 C20.05815,-3.55271368e-15 21,0.934375 21,2.08333333 L21,22.9166667 C21,24.065625 20.05815,25 18.9,25 L10.8783098,25 Z"
        transform="matrix(-1 0 0 1 21 0)"
      />
    </g>
  </SvgIcon>
)

export default ShareIcon
