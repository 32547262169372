import { createAction, handleActions } from 'redux-actions'

import store from 'store'

// Action Types
const SET_ADMIN_FEATURES = 'featureFlags/SET_ADMIN_FEATURES'
const ENABLE_CONSOLE_LOGGING = 'featureFlags/ENABLE_CONSOLE_LOGGING'
const DISABLE_CONSOLE_LOGGING = 'featureFlags/DISABLE_CONSOLE_LOGGING'

export const REDUCER_KEY = 'featureFlags'

// Action Creators
export const setAdminFeatures = createAction(SET_ADMIN_FEATURES, offOrOn => offOrOn)
export const enableConsoleLogging = createAction(ENABLE_CONSOLE_LOGGING)
export const disableConsoleLogging = createAction(DISABLE_CONSOLE_LOGGING)

export const adminFeaturesSelector = state => state[REDUCER_KEY].adminFeatures
export const logToConsoleSelector = state => state[REDUCER_KEY].logToConsole

// Initial State
const initState = {
  adminFeatures: store.get('featureFlags.adminFeatures', true),
  logToConsole: store.get('featureFlags.logToConsole', false),
}

// Reducer
export default handleActions(
  {
    [SET_ADMIN_FEATURES]: (state, { payload }) => {
      store.set('featureFlags.adminFeatures', payload)
      return { ...state, adminFeatures: payload }
    },
    [ENABLE_CONSOLE_LOGGING]: state => {
      store.set('featureFlags.logToConsole', true)
      return { ...state, logToConsole: true }
    },
    [DISABLE_CONSOLE_LOGGING]: state => {
      store.set('featureFlags.logToConsole', false)
      return { ...state, logToConsole: false }
    },
  },
  initState
)
