import {
  BETWEEN,
  EQUAL,
  GREATER_THAN_OR_EQUAL,
  LESS_THAN_OR_EQUAL,
} from 'store/customShipmentFilters'

import ApiRefType from 'store/models/definitions/ApiRefType'
import { FilterInterface } from 'utils/jsonLogic/utils/getCustomTagJsonLogic'
import GenericObjectInterface from 'store/types/GenericObjectInterface'
import forEach from 'lodash/forEach'
import jsonLogicUtils from 'utils/jsonLogic/utils'
import { validOperatorsType } from 'utils/jsonLogic/utils/validOperators'

interface ConfigInterface {
  key: string
  operator: validOperatorsType
  orGroup?: string
  val: any
}

export const OPERATOR_WORDS_CONVERSION: { [key: string]: string } = {
  [EQUAL]: 'in',
  [GREATER_THAN_OR_EQUAL]: '>=',
  [LESS_THAN_OR_EQUAL]: '<=',
}

/**
 * Simple configs are "simple" because we can pass them to our existing `convertToJSONLogic` function, which is
 * what we do in `utils/jsonLogic/board`. They all take in a single argument and have the same config keys
 * (some values differ).
 */
const simpleConfigs: { [key: string]: (val: any) => ConfigInterface } = {
  bol_numbers: (val: string) => ({
    operator: 'in',
    key: 'bol.number',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  booking_numbers: (val: string) => ({
    operator: 'in',
    key: 'booking.number',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  container_numbers: (val: string) => ({
    operator: 'in',
    key: 'trip.equipment',
    val: { $containers: [['numbers', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  delivery_countries: (val: string) => ({
    operator: 'in',
    key: 'trip.delivery_milestone.zone',
    val: { $zones: [['country_codes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  delivery_locations: (val: string) => ({
    operator: 'in',
    key: 'trip.delivery_milestone.zone',
    val: { $zones: [['unlocodes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  discharge_countries: (val: string) => ({
    operator: 'in',
    key: 'trip.destination_unload_milestone.zone',
    val: { $zones: [['country_codes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  discharge_locations: (val: string) => ({
    operator: 'in',
    key: 'trip.destination_unload_milestone.zone',
    val: { $zones: [['unlocodes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  is_active: (val: boolean) => ({
    operator: '==',
    key: 'trip.is_active',
    val,
  }),
  latest_countries: (val: string) => ({
    operator: 'in',
    key: 'trip.latest_actual_milestone.zone',
    val: { $zones: [['country_codes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  latest_locations: (val: string) => ({
    operator: 'in',
    key: 'trip.latest_actual_milestone.zone',
    val: { $zones: [['unlocodes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  latest_milestones: (val: string) => ({
    operator: 'in',
    key: 'trip.latest_actual_milestone.milestone_type',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  line_item_numbers: (val: string) => ({
    operator: 'in',
    key: 'line_item.number',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  load_countries: (val: string) => ({
    operator: 'in',
    key: 'trip.origin_load_milestone.zone',
    val: { $zones: [['country_codes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  load_locations: (val: string) => ({
    operator: 'in',
    key: 'trip.origin_load_milestone.zone',
    val: { $zones: [['unlocodes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  max_latest_milestone_time: (val: number | string) => ({
    operator: '<',
    key: 'trip.latest_actual_milestone.actual_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  max_ocean_delay: (val: number) => ({
    operator: '<',
    key: 'trip.destination_unload_milestone.delay',
    val,
  }),
  max_pred_at_delivery: (val: number | string) => ({
    operator: '<',
    key: 'trip.delivery_milestone.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  max_pred_available: (val: number | string) => ({
    operator: '<',
    key: 'trip.available_for_pickup.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  max_pred_discharge: (val: number | string) => ({
    operator: '<',
    key: 'trip.destination_unload_milestone.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  max_pred_load: (val: number | string) => ({
    operator: '<',
    key: 'trip.origin_load_milestone.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  min_latest_milestone_time: (val: number | string) => ({
    operator: '>',
    key: 'trip.latest_actual_milestone.actual_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  min_ocean_delay: (val: number) => ({
    operator: '>',
    key: 'trip.destination_unload_milestone.delay',
    val,
  }),
  min_pred_at_delivery: (val: number | string) => ({
    operator: '>',
    key: 'trip.delivery_milestone.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  min_pred_available: (val: number | string) => ({
    operator: '>',
    key: 'trip.available_for_pickup.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  min_pred_discharge: (val: number | string) => ({
    operator: '>',
    key: 'trip.destination_unload_milestone.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  min_pred_load: (val: number | string) => ({
    operator: '>',
    key: 'trip.origin_load_milestone.predicted_time',
    val: jsonLogicUtils.getDateValue(val as string),
  }),
  ocean_carriers: (val: string) => ({
    operator: 'in',
    key: 'trip.carrier',
    val: { $carriers: [['scac_codes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  receipt_countries: (val: string) => ({
    operator: 'in',
    key: 'trip.receipt_milestone.zone',
    val: { $zones: [['country_codes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  receipt_locations: (val: string) => ({
    operator: 'in',
    key: 'trip.receipt_milestone.zone',
    val: { $zones: [['unlocodes', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
  ref_ids: (val: string) => ({
    operator: 'in',
    key: 'id',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  shipment_numbers: (val: string) => ({
    operator: 'in',
    key: 'shipment.number',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  tenants: (val: string) => ({
    operator: 'in',
    key: 'tenant',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  traced_milestones: (val: string) => ({
    operator: 'in',
    key: 'trip.actual_milestone_type',
    val: jsonLogicUtils.delimitedStringToArray(val),
  }),
  vessels: (val: string) => ({
    operator: 'in',
    key: 'trip.vehicle',
    val: { $vessels: [['ids', jsonLogicUtils.delimitedStringToArray(val)]] },
  }),
}

/**
 * Orders need special handling because of the three order types. The return config can then be processed by
 * `convertToJSONLogic`.
 * @param val filter value
 * @param orderType one of the order types accepted by the backend
 */
const orderNumbersConfig = (val: string, orderType: string) => {
  const config = (numbers: string) => {
    return [
      {
        key: 'order.type',
        operator: '==',
        val: orderType,
      },
      {
        key: 'order.number',
        operator: 'in',
        val: jsonLogicUtils.delimitedStringToArray(numbers),
      },
    ]
  }

  return config(val)
}

/**
 * Tags need to be converted into a JSONLogic `or` between `shipment.other`, `order.other`, and `line_item.other`.
 * These include custom tags, which can include text operators such as `eq`, `lte`, `gte` and `between`.
 * @param tags string or array : `"ship_to:eq:3M"` or `[ "ship_to:eq:3M", "mill:eq:ARC" ]
 * @return JsonLogic
 */
const tagsConfig = (tags: string) => {
  // Helper function to generate a tag object from the colon-ized string
  const getTagObj = (tag: string) => {
    const filterArray = tag.split(':')
    const value = filterArray.pop() as string
    const operator = filterArray.pop() as string
    const key = filterArray[0]
    return {
      key,
      operator,
      value,
    }
  }

  // `tags` can be string or array, if string convert to array
  const tagsArray = Array.isArray(tags) ? tags : [tags]
  const jsonLogic: GenericObjectInterface[] = []

  forEach(tagsArray, (tag: string) => {
    const tagObj = getTagObj(tag)

    // We need to handle `between` uniquely
    if (tagObj.operator === BETWEEN) {
      const betweenJsonLogic: GenericObjectInterface = jsonLogicUtils.legacyBetweenToJsonLogic(
        tagObj
      )
      jsonLogic.push(betweenJsonLogic)
    } else {
      // Convert text to symbol: `lte` to `<=`
      const operator = OPERATOR_WORDS_CONVERSION[tagObj.operator]
      // If we have `<=` or `>=` we are dealing with integers (string interges) or date strings, so call
      // `getDateValue`, otherwise we have a plain string that may be delimited.
      const isGteLte =
        tagObj.operator === GREATER_THAN_OR_EQUAL || tagObj.operator === LESS_THAN_OR_EQUAL
      const val = isGteLte
        ? jsonLogicUtils.getDateValue(tagObj.value as string)
        : jsonLogicUtils.delimitedStringToArray(tagObj.value)

      const filterConfig: FilterInterface[] = [
        {
          entityType: ApiRefType.shipment,
          filterRefType: 'shipment',
          key: `shipment.other.${tagObj.key}`,
          operator,
          value: val,
        },
        {
          entityType: ApiRefType.orders,
          filterRefType: 'order',
          key: `order.other.${tagObj.key}`,
          operator,
          value: val,
        },
        {
          entityType: ApiRefType.line_item_number,
          filterRefType: 'line_item',
          key: `line_item.other.${tagObj.key}`,
          operator,
          value: val,
        },
      ]
      const jsonLogicFilters = jsonLogicUtils.getCustomTagJsonLogic(filterConfig)
      jsonLogic.push(jsonLogicFilters)
    }
  })

  return jsonLogic
}

/**
 * Splits the legacy pipe-delimited unlocodes and maps over them to return a JSONLogic config object for each unlocode.
 * @param val
 */
const transshipmentLocationsConfig = (val: string) => {
  const transshipmentConfig = (unlocode: string) => ({
    key: 'trip.via_milestone1.zone',
    operator: 'in',
    val: { $zones: [['unlocodes', [unlocode]]] },
  })

  return jsonLogicUtils.delimitedStringToArray(val).map(v => transshipmentConfig(v))
}

export default {
  orderNumbersConfig,
  simpleConfigs,
  tagsConfig,
  transshipmentLocationsConfig,
}
