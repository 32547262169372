import { deselectAllShipments, selectAllShipments } from 'store/board/actions'
import { selectIsExhaustiveSelector, selectedIdsSelector } from 'store/board/selectors'
import { useDispatch, useSelector } from 'react-redux'

import BulkSelector from 'components/core/ConfigurableTable/TableControls/BulkSelector'
import React from 'react'
import SelectionHeader from 'components/core/ConfigurableTable/HeaderCells/SelectionHeaderCell'
import c from 'pages/BoardPage/content.json'
import isEmpty from 'lodash/isEmpty'
import logger from 'utils/logger'

const TYPES = ['timeline', 'table']

interface Props {
  type: typeof TYPES[keyof typeof TYPES]
}

const ExhaustiveSelector = function (props: Props) {
  const { type } = props
  const dispatch = useDispatch()
  const selectIsExhaustive = useSelector(selectIsExhaustiveSelector)
  const selectedIds = useSelector(selectedIdsSelector)

  if (!TYPES.includes(type as string)) {
    logger.captureAppException('Missing `type` prop')
    return <></>
  }

  const getTooltipText = () => {
    if (isCheckboxChecked()) {
      return c.tooltipDeselect
    }
    return c.tooltipSelectAll
  }

  const isCheckboxChecked = () => selectIsExhaustive
  const isCheckboxIndeterminate = () => !selectIsExhaustive && !isEmpty(selectedIds)
  const onCheckAll = () => dispatch(selectAllShipments())
  const onUncheckAll = () => dispatch(deselectAllShipments())

  return type === 'timeline' ? (
    <BulkSelector
      onCheck={onCheckAll}
      onUncheck={onUncheckAll}
      tooltipText={getTooltipText()}
      onIndeterminate={onCheckAll}
      checkboxChecked={isCheckboxChecked()}
      checkboxIndeterminate={isCheckboxIndeterminate()}
    />
  ) : (
    <SelectionHeader
      onCheck={onCheckAll}
      onUncheck={onUncheckAll}
      tooltipText={getTooltipText()}
      onIndeterminate={onCheckAll}
      checkboxChecked={isCheckboxChecked()}
      checkboxIndeterminate={isCheckboxIndeterminate()}
    />
  )
}

export default ExhaustiveSelector
