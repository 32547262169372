import { LIST_VIEW, TABLE_VIEW } from 'store/boardUtils'
import React, { useState } from 'react'
import { Tab, Tabs, Theme, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import logger from 'utils/logger'
import { setViewType } from 'store/boardUtils/actions'
import { viewTypeSelector } from 'store/boardUtils/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  tabs: {
    borderBottom: `3px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(2),
  },
}))

const TableViewTabs = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const viewType = useSelector(viewTypeSelector)
  const TABS = [TABLE_VIEW, LIST_VIEW]
  const [tabIndex, setTabIndex] = useState(TABS.indexOf(viewType) ?? 0)

  const handleTabChange = (event: React.ChangeEvent<{}>, selectedTabIndex: number) => {
    setTabIndex(selectedTabIndex)
    const selected = TABS[selectedTabIndex]
    logger.notify(`Shipments View As ${selected}`)
    dispatch(setViewType(selected))
  }

  return (
    <div className={classes.container} data-testid="board-page__view-tabs">
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: classes.tabs }}
      >
        <Tab label="Table View" data-testid="board__table-view-tab" />
        <Tab label="Timeline View" data-testid="board__timeline-view-tab" />
      </Tabs>
    </div>
  )
}

export default TableViewTabs
