import { AuthSubtitle, AuthTitle } from 'components/AuthTitle'
import React, { FormEvent } from 'react'
import { SSO_EMAIL_ROUTE, SSO_PASSWORD_ROUTE } from 'utils/routes'
import { setAuthStage, setLoginErrorMessage } from 'store/auth/login/actions'
import { useDispatch, useSelector } from 'react-redux'

import AuthDialog from 'components/AuthDialog'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { Redirect } from 'react-router-dom'
import SSOForm from './SSOForm'
import { authStageFromState } from 'store/auth/login/selectors'
import { makeStyles } from '@material-ui/core'
import { oauthStatusSelector } from 'store/oauthStatus/selectors'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  newAuthTitle: {
    paddingTop: theme.spacing(3),
  },
  newAuthSubtitle: {
    color: theme.palette.grey[500],
  },
  loginErrorMessage: {
    marginTop: theme.spacing(3),
  },
}))

const SSOLoginPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const oauthStatus = useSelector(oauthStatusSelector)
  const authStage = useSelector(authStageFromState)

  // don't need to check isLoading here since oath status is loaded on another page
  // if there is not OAuth data, we should just redirect
  if (!oauthStatus.data) {
    dispatch(setAuthStage('email'))
    return <Redirect to={SSO_EMAIL_ROUTE.buildUrl()} />
  }
  const onPasswordLoginChosen = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (authStage === 'sso' && oauthStatus.data) {
      // clear any errors from previous login attempts
      dispatch(setLoginErrorMessage(null))
      dispatch(setAuthStage('password'))
      history.push(SSO_PASSWORD_ROUTE.buildUrl())
    }
  }

  const onSsoLoginChosen = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // this will take the user actually outside the app
    window.location.href = oauthStatus.data!.requestUri!
  }
  return (
    <CenteredLayout className={classes.root}>
      <AuthDialog>
        <AuthTitle className={classes.newAuthTitle}>Sign In to Your Account</AuthTitle>
        <AuthSubtitle className={classes.newAuthSubtitle}>
          Your organization uses Single Sign-On (SSO).
        </AuthSubtitle>
        <SSOForm onSsoLogin={onSsoLoginChosen} onPasswordLogin={onPasswordLoginChosen} />
      </AuthDialog>
    </CenteredLayout>
  )
}

export default SSOLoginPage
