import { Tooltip, Typography, withStyles } from '@material-ui/core'

import ArrowUpward from '@material-ui/icons/ArrowUpward'
import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import InfoIcon from '@material-ui/icons/Info'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { compose } from 'redux'
import get from 'lodash/get'
import logger from 'utils/logger'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 13,
    letterSpacing: 1,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,
    width: '100%',
    whiteSpace: 'nowrap',
  },
  arrowIcon: {
    fontSize: 15,
    color: 'rgb(200, 200, 200)',
    marginLeft: theme.spacing(0.5),
  },
  activeTitle: {
    color: 'black',
  },
  upArrow: {
    transform: 'rotate(0deg)',
    transition: 'transform .15s linear',
  },
  downArrow: {
    transform: 'rotate(180deg)',
    transition: 'transform .15s linear',
  },
  activeArrow: {
    color: 'black',
  },
  infoIcon: {
    width: 16,
    marginLeft: theme.spacing(1),
    color: 'rgb(210, 213, 218)',
  },
  tooltip: {
    textTransform: 'none',
    opacity: 1,
  },
})

const DESC = 'desc'
const ASC = 'asc'
const BASE_HEADER_STYLE = { verticalAlign: 'middle', paddingTop: 8 }

class ConfigurableHeaderCell extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  updateSortState = () => {
    const { sortState, setSortState } = this.props

    const currSortParam = get(sortState, 'sort_param')
    const colSortParam = get(this.props, 'column.sort_param')
    const isColActive = colSortParam === currSortParam

    const currSortDir = get(sortState, 'direction')

    let direction = currSortDir
    let sortParam = currSortParam
    if (isColActive) {
      direction = currSortDir === DESC ? ASC : DESC
    } else {
      sortParam = colSortParam
      direction = ASC
    }

    let newState = { ...sortState, direction, sort_param: sortParam }
    setSortState(newState)
    logger.notify('Shipment Board Sort', newState)
  }

  renderDescription = () => {
    const { classes, column } = this.props
    const description = get(column, 'description', null)
    if (description !== null) {
      return (
        <Tooltip
          id="tooltip-top-start"
          title={description}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <InfoIcon className={classes.infoIcon} />
        </Tooltip>
      )
    }
  }

  renderTitle = () => {
    const { column, rollup } = this.props
    const getTitle = get(column, 'getTitle')
    return getTitle ? getTitle(rollup) : get(column, 'title')
  }

  render() {
    const { classes, sortState, setSortState, sortableCols, ...otherProps } = this.props
    const currSortParam = get(sortState, 'sort_param')
    const currSortDir = get(sortState, 'direction')

    const colSortParam = get(otherProps, 'column.sort_param')
    const isColActive = colSortParam === currSortParam
    const isDesc = currSortDir === DESC
    const isArrowDown = isColActive && isDesc
    if (colSortParam) {
      return (
        <BaseCell
          onClick={this.updateSortState}
          {...otherProps}
          style={{ cursor: 'pointer', ...BASE_HEADER_STYLE }}
        >
          <div className={classes.container}>
            <div className={classnames({ [classes.activeTitle]: isColActive })}>
              <Typography variant="body2" className={classes.title}>
                {this.renderTitle()}
              </Typography>
            </div>
            {this.renderDescription()}
            <ArrowUpward
              className={classnames(classes.arrowIcon, {
                [classes.activeArrow]: isColActive,
                [classes.downArrow]: isArrowDown,
                [classes.upArrow]: !isArrowDown,
              })}
            />
          </div>
        </BaseCell>
      )
    }
    return (
      <BaseCell {...otherProps} style={BASE_HEADER_STYLE}>
        <div className={classes.container}>
          <div className={classnames({ [classes.activeTitle]: isColActive })}>
            <Typography variant="body2" className={classes.title}>
              {this.renderTitle()}
            </Typography>
          </div>
          {this.renderDescription()}
        </div>
      </BaseCell>
    )
  }
}

export default compose(withStyles(styles))(ConfigurableHeaderCell)
