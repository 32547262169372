import GenericObjectInterface from 'store/types/GenericObjectInterface'
import { convertToJSONLogic } from 'utils/filterGroups'
import filterConfigs from './boardFilterConfigs'
import forEach from 'lodash/forEach'
import jsonLogicUtils from 'utils/jsonLogic/utils'
import logger from 'utils/logger'

interface JsonLogicInterface {
  filters: GenericObjectInterface
  limit?: string | number
  offset?: string | number
  sort?: {
    expression: {
      var: string
    }
    ascending: boolean
  }[]
}

/**
 * Takes in legacy filters and converts them to JsonLogic that is compatible with the `filtered_references` endpoint
 * @param object filters in legacy form
 * @returns JsonLogic
 */
const toJsonLogic = (filters: GenericObjectInterface) => {
  const simpleConfigs = []

  /**
   * 1. "Simple" configs take in a value and return a config. We collect all of these in the `simpleConfigs` array
   * and then convert them to JsonLogic
   **/
  // Loop over legacy filters
  forEach(filters, (val, key) => {
    if (key in filterConfigs.simpleConfigs) {
      // Check for `val` because, for example, `is_active` can be absent
      if (val !== null && typeof val !== 'undefined') {
        // Get back a config/object that can be converted to JsonLogic
        const config = filterConfigs.simpleConfigs[key](val)
        simpleConfigs.push(config)
      }
    }
  })

  // Only relevant if we have one of the three order types. If not, `getOrderType` returns `null`
  const orderType = jsonLogicUtils.getOrderTypeFromRefType(filters.ref_type)
  if (orderType) {
    simpleConfigs.push(orderType)
  }

  // Convert what we have so far to JsonLogic
  const jsonLogic: JsonLogicInterface = {
    filters: convertToJSONLogic(simpleConfigs),
  }

  /**
   * 2. Next we handle "complex" filters. These get pushed onto the existing `jsonLogic.filters.and`
   */

  if ('order_numbers' in filters) {
    const orderRefType =
      jsonLogicUtils.orderRefTypeToNew[
        filters.ref_type as keyof typeof jsonLogicUtils.orderRefTypeToNew
      ]
    const referenceNumbers = filters.order_numbers
    const config = filterConfigs.orderNumbersConfig(referenceNumbers, orderRefType)
    jsonLogic.filters.and?.push(convertToJSONLogic(config))
  }

  if ('transshipment_locations' in filters) {
    const config = filterConfigs.transshipmentLocationsConfig(filters.transshipment_locations)
    jsonLogic.filters.and?.push(convertToJSONLogic(config))
  }

  if ('tag[]' in filters) {
    const jsonLogicTags = filterConfigs.tagsConfig(filters['tag[]'])
    // We get back an array of JsonLogic objects that should each be added to our existing `and`
    jsonLogicTags?.map(config => jsonLogic.filters.and?.push(config))
  }

  /**
   * 3. These live outside of the `jsonLogic.filters.and` array so they are added as properties on the return object
   */
  if ('limit' in filters) {
    jsonLogic.limit = filters.limit
  }
  if ('offset' in filters) {
    jsonLogic.offset = filters.offset
  }
  // `sort` is JsonLogic but it is not bundled with the other filters
  if ('sort' in filters) {
    const sort = jsonLogicUtils.getSortJsonLogic(filters.sort)
    if (sort) {
      jsonLogic.sort = sort
    }
  }

  logger.groupLog('jsonlogic')
  logger.allEnvLog(`Legacy filters ${JSON.stringify(filters)}`)
  logger.allEnvLog(`jsonLogic ${JSON.stringify(jsonLogic)}`)
  logger.groupLog('jsonlogic', true)

  return jsonLogic
}

export default toJsonLogic
