import { createAction } from 'redux-actions'

export const BOARD_UTILS_REDUCER_KEY = 'boardUtils'

export const types = {
  CLEAR_VIEW_START: `${BOARD_UTILS_REDUCER_KEY}/CLEAR_VIEW_START`,
  CLEAR_VIEW_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/CLEAR_VIEW_SUCCESS`,
  CREATE_VIEW_START: `${BOARD_UTILS_REDUCER_KEY}/CREATE_VIEW_START`,
  CREATE_VIEW_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/CREATE_VIEW_SUCCESS`,
  DELETE_VIEW_START: `${BOARD_UTILS_REDUCER_KEY}/DELETE_VIEW_START`,
  DELETE_VIEW_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/DELETE_VIEW_SUCCESS`,
  EDIT_VIEW: `${BOARD_UTILS_REDUCER_KEY}/EDIT_VIEW`,
  FETCH_VIEWS_ERROR: `${BOARD_UTILS_REDUCER_KEY}/FETCH_VIEWS_ERROR`,
  FETCH_VIEWS_START: `${BOARD_UTILS_REDUCER_KEY}/FETCH_VIEWS_START`,
  FETCH_VIEWS_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/FETCH_VIEWS_SUCCESS`,
  IMPORT_REFERENCES: `${BOARD_UTILS_REDUCER_KEY}/IMPORT_REFERENCES`,
  RELOAD_SHIPMENTS: 'shipments/RELOAD_SHIPMENTS',
  RESET_DIALOG: `${BOARD_UTILS_REDUCER_KEY}/RESET_DIALOG`,
  SELECT_VIEW_START: `${BOARD_UTILS_REDUCER_KEY}/SELECT_VIEW_START`,
  SELECT_VIEW_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/SELECT_VIEW_SUCCESS`,
  SET_DATE_RANGE: `${BOARD_UTILS_REDUCER_KEY}/SET_DATE_RANGE`,
  SET_DATE_RANGE_MODE: `${BOARD_UTILS_REDUCER_KEY}/SET_DATE_RANGE_MODE`,
  SET_DELAY_BOUNDS: `${BOARD_UTILS_REDUCER_KEY}/SET_DELAY_BOUNDS`,
  SET_DIALOG: `${BOARD_UTILS_REDUCER_KEY}/SET_DIALOG`,
  SET_FILTERED_LIST_START: `${BOARD_UTILS_REDUCER_KEY}/SET_FILTERED_LIST_START`,
  SET_FILTERED_LIST_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/SET_FILTERED_LIST_SUCCESS`,
  SET_LATEST_MILESTONE_VALUES: `${BOARD_UTILS_REDUCER_KEY}/SET_LATEST_MILESTONE_VALUES`,
  SET_MODE: `${BOARD_UTILS_REDUCER_KEY}/SET_MODE`,
  SET_ROLLUP_START: `${BOARD_UTILS_REDUCER_KEY}/SET_ROLLUP_START`,
  SET_ROLLUP_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/SET_ROLLUP_SUCCESS`,
  SET_INITIAL_ROLLUP_START: `${BOARD_UTILS_REDUCER_KEY}/SET_INITIAL_ROLLUP_START`,
  SET_INITIAL_ROLLUP_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/SET_INITIAL_ROLLUP_SUCCESS`,
  SET_SORT_STATE: `${BOARD_UTILS_REDUCER_KEY}/SET_SORT_STATE`,
  SET_VIEW_TYPE: `${BOARD_UTILS_REDUCER_KEY}/SET_VIEW_TYPE`,
  TRANSPORTATION_STATUS: `${BOARD_UTILS_REDUCER_KEY}/TRANSPORTATION_STATUS`,
  TOGGLE_COLUMN: `${BOARD_UTILS_REDUCER_KEY}/TOGGLE_COLUMN`,
  TOGGLE_COLUMNS: `${BOARD_UTILS_REDUCER_KEY}/TOGGLE_COLUMNS`,
  TOGGLE_IMPORT_DIALOG: `${BOARD_UTILS_REDUCER_KEY}/SHIPMENT_IMPORT_DIALOG`,
  UPDATE_COLUMN_ORDER: `${BOARD_UTILS_REDUCER_KEY}/UPDATE_COLUMN_ORDER`,
  UPDATE_VIEW_START: `${BOARD_UTILS_REDUCER_KEY}/UPDATE_VIEW_START`,
  UPDATE_VIEW_SUCCESS: `${BOARD_UTILS_REDUCER_KEY}/UPDATE_VIEW_SUCCESS`,
}

export const clearView = createAction(types.CLEAR_VIEW_START)
export const deleteView = createAction(types.DELETE_VIEW_START, (id, name) => ({
  id,
  name,
}))
export const fetchViews = createAction(types.FETCH_VIEWS_START)
export const importReferences = createAction(types.IMPORT_REFERENCES)
export const reloadShipments = createAction(types.RELOAD_SHIPMENTS)
export const selectView = createAction(types.SELECT_VIEW_START)
export const setDateRangeMode = createAction(types.SET_DATE_RANGE_MODE, mode => mode)
export const setRollup = createAction(types.SET_ROLLUP_START)
export const setInitialRollup = createAction(types.SET_INITIAL_ROLLUP_START)
export const setTransportationStatus = createAction(types.TRANSPORTATION_STATUS)
export const setSortState = createAction(types.SET_SORT_STATE)
export const setViewType = createAction(types.SET_VIEW_TYPE)
export const toggleColumn = createAction(types.TOGGLE_COLUMN)
export const toggleColumns = createAction(types.TOGGLE_COLUMNS)
export const toggleImportDialog = createAction(types.TOGGLE_IMPORT_DIALOG)
export const updateColumnOrder = createAction(types.UPDATE_COLUMN_ORDER)

export const setFilteredList = payload => ({
  type: types.SET_FILTERED_LIST_START,
  payload,
})

export const createView = createAction(types.CREATE_VIEW_START, (name, filters, createAlert) => ({
  name,
  filters,
  createAlert,
}))

export const updateView = createAction(
  types.UPDATE_VIEW_START,
  (name, filters, id, createAlert, reselectViewAfterUpdate = true, rollup = null) => ({
    name,
    filters,
    id,
    createAlert,
    reselectViewAfterUpdate,
    rollup,
  })
)

export const setDateRange = createAction(types.SET_DATE_RANGE, (startDate, endDate) => ({
  startDate,
  endDate,
}))
