import { Typography, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import PropTypes from 'prop-types'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'

const styles = theme => ({
  wrapper: {
    width: 200,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    border: '1px solid #2e3e55',
    display: 'initial',
    minWidth: 170,
  },
  buttonLabel: {
    color: '#2e3e55',
    textTransform: 'none',
  },
})

class AdminButton extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.node,
    style: PropTypes.object,
  }

  static defaultProps = {
    icon: <SettingsIcon />,
    style: {},
  }

  render() {
    const { classes, icon, style, text } = this.props
    const iconCopy = React.cloneElement(icon, { style: { height: 26, color: '#2e3e55' } })
    return (
      <div className={classes.wrapper}>
        <Button
          onClick={this.props.onClick}
          classes={{ root: classes.button, label: classes.buttonLabel }}
          style={style}
        >
          <div>{iconCopy}</div>
          <Typography variant="body2" className={classes.buttonLabel}>
            {text}
          </Typography>
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(AdminButton)
