import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { activeShareSelector, shareModalIsVisibleSelector } from 'store/share'
import {
  clearActiveShare,
  closeShareModal,
  createShare,
  fetchActiveShare,
  sendShareEmail,
} from 'store/share/actions'
import { useDispatch, useSelector } from 'react-redux'

import CloseIcon from '@material-ui/icons/Close'
import Loader from 'components/Loader'
import MESSAGES from 'store/notifications/messages'
import { PUBLIC_SHARE_ROUTE } from 'utils/routes'
import { ROLLUPS } from 'utils/rollups'
import ShareForm from './ShareForm'
import buildUrl from 'utils/urlBuilder'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import logger from 'utils/logger'
import { selectIsExhaustiveSelector } from 'store/board/selectors'
import { showNotification } from 'store/notifications'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'inline-block',
    flexGrow: 1,
    margin: 'auto',
  },
  dialogModal: {
    maxWidth: '44rem',
    minHeight: '30rem',
  },
  dialogTitle: {
    display: 'flex',
  },
  loaderContainer: {
    height: '23rem',
    display: 'flex',
  },
}))

interface Props {
  shareModalRefType: typeof ROLLUPS[keyof typeof ROLLUPS]
  refIds: number[]
}

const ShareModal = (props: Props) => {
  const { refIds, shareModalRefType } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const activeShare = useSelector(activeShareSelector)
  const modalIsVisible = useSelector(shareModalIsVisibleSelector)
  const selectIsExhaustive = useSelector(selectIsExhaustiveSelector)

  const refIdsAsString = refIds.sort().join('--')

  useEffect(() => {
    if (!modalIsVisible) {
      dispatch(clearActiveShare())
    }
  }, [dispatch, modalIsVisible])

  useEffect(() => {
    // Attempt to find if there is already an existing share for the selected shipments.
    // We don't do this if the selection is exhaustive, as that would involve getting the ref IDs through an API call.
    if (modalIsVisible && !selectIsExhaustive) {
      dispatch(fetchActiveShare({ refType: shareModalRefType, refIds: refIdsAsString.split('--') }))
    }
    // Pass refIds, which is an array of integers, as a string to prevent infinite re-renders.
  }, [dispatch, modalIsVisible, selectIsExhaustive, shareModalRefType, refIdsAsString])

  const handleClose = () => {
    logger.notify('Share Modal - Close')
    dispatch(closeShareModal())
  }

  const handleCreateShare = () => dispatch(createShare({ refType: shareModalRefType, refIds }))

  const token = get(activeShare, 'data.guid', null)

  return (
    <Dialog
      aria-labelledby="share-dialog-title"
      aria-describedby="share-modal-description"
      open={modalIsVisible}
      classes={{ paper: classes.dialogModal }}
      onClose={handleClose}
      fullWidth={true}
      data-testid="share-shipment-modal"
    >
      <DialogTitle id="share-dialog-title" className={classes.dialogTitle} disableTypography>
        <Typography className={classes.header} variant="h6">
          Share Live Updates
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {activeShare.isLoading ? (
          <Loader className={classes.loaderContainer} />
        ) : (
          <>
            <DialogContentText component="div">
              <Box>
                You can share these updates with people inside or outside of your organization.
              </Box>
              <Box>Recipients will receive an email from you with a unique link to project44.</Box>
            </DialogContentText>
            <ShareForm
              onTokenGenerate={handleCreateShare}
              token={
                isEmpty(token) ? '' : buildUrl({ pathname: PUBLIC_SHARE_ROUTE.buildUrl({ token }) })
              }
              onCopySuccess={() => dispatch(showNotification(MESSAGES.shareCopy))}
              onSubmit={formPayload => {
                const { emails, subject, body } = formPayload
                dispatch(
                  sendShareEmail({
                    refType: shareModalRefType,
                    refIds,
                    emails,
                    subject,
                    body,
                  })
                )
              }}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ShareModal
