import { formatDecimal, formatLocationLabel, formatPercentage } from 'utils/strUtils'

import get from 'lodash/get'
import { getWaypoints } from 'utils/waypoints'
import isNil from 'lodash/isNil'
import moment from 'moment'
import uniq from 'lodash/uniq'

export const NO_DATA = 'no data'

export const formatters = {
  range: (from, to) => {
    return `${formatDecimal(from, 2, NO_DATA)} - ${formatDecimal(to, 2, NO_DATA)}`
  },
  containerCount: num => {
    return !isNil(num) ? num : NO_DATA
  },
}

export const uniqueCarrierNames = items =>
  uniq((items || []).filter(c => c).map(c => c.abbreviation)).filter(c => c)

const generateRow = (row, isRollup) => {
  const metrics = get(row, 'metrics.duration', {})
  const roll = get(row, 'metrics.roll', {})

  const waypoints = getWaypoints(row.waypointLocations, isRollup)
  const waypointText = waypoints
    .map(way => (way.unlocode ? formatLocationLabel(way.name) : way.name))
    .join('; ')

  return {
    isRollup: isRollup ? 'Lane Total (aggregated)' : '',
    numRoutes: isRollup ? get(row, 'routes', []).length.toString() : '',
    from: formatLocationLabel(get(row, 'originLocation.name', '')),
    fromUnlocode: get(row, 'originLocation.unlocode', ''),
    via: waypointText,
    to: formatLocationLabel(get(row, 'destLocation.name', '')),
    toUnlocode: get(row, 'destLocation.unlocode', ''),
    median: formatDecimal(metrics.metricsMedian, 2, ''),
    count: get(metrics, 'count', '').toString(),
    carrier: isRollup
      ? uniqueCarrierNames(get(row, 'carriers')).join(', ')
      : get(row, 'carrier.abbreviation', ''),
    variability: formatDecimal(metrics.variability, 2, ''),
    range: formatters.range(metrics.lowerQuartile, metrics.upperQuartile),
    rollRate: formatPercentage(roll.percentage, 2, ''),
    totalRolls: roll.percentage !== null ? roll.rolledCount.toString() : '',
  }
}

export const generateCsvData = (routeGroups, selectedRouteIds) => {
  const headers = [
    { label: '', key: 'isRollup' },
    { label: 'Num. Routes', key: 'numRoutes' },
    { label: 'From', key: 'from' },
    { label: 'From (UNLOCODE)', key: 'fromUnlocode' },
    { label: 'Via', key: 'via' },
    { label: 'To', key: 'to' },
    { label: 'To (UNLOCODE)', key: 'toUnlocode' },
    { label: 'Transit Time Median (Days)', key: 'median' },
    { label: 'Container Count', key: 'count' },
    { label: 'Carrier', key: 'carrier' },
    { label: 'Variability (Days)', key: 'variability' },
    { label: 'Interquartile Range (Days)', key: 'range' },
    { label: 'Roll Rate', key: 'rollRate' },
    { label: 'Total Rolls', key: 'totalRolls' },
  ]

  const data = []
  routeGroups.forEach(routeGroup => {
    if (routeGroup.routes.some(route => selectedRouteIds.has(route.routeId))) {
      data.push(generateRow(routeGroup, true))
      if (routeGroup.routes.length > 1) {
        routeGroup.routes.forEach(route => {
          if (selectedRouteIds.has(route.routeId)) {
            data.push(generateRow(route, false))
          }
        })
      }
    }
  })

  return [headers.map(h => h.label), ...data.map(d => headers.map(h => d[h.key]))]
}

export const handleFileDownload = async (fileType, rows, selectedRouteIds) => {
  const XLSX = await import('xlsx')
  const data = generateCsvData(rows, selectedRouteIds)
  const worksheet = XLSX.utils.aoa_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Planning')
  const fileName = `CM_planning_${moment().format('YYYYMMDD_hhmmss')}.${fileType}`
  XLSX.writeFile(workbook, fileName)
}
