import CMPaper from 'components/CMPaper'
import React from 'react'
import Skeleton from 'components/Skeleton'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  largeCol: {
    flexGrow: 3,
  },
  smallCol: {
    flexGrow: 1,
  },
  col: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

function ScoreCardSkeleton({ isAnimated }) {
  const classes = useStyles()
  return (
    <CMPaper className={classes.root}>
      <Skeleton className={cx(classes.largeCol, classes.col)} isAnimated={isAnimated} />
      <Skeleton className={cx(classes.smallCol, classes.col)} isAnimated={isAnimated} />
      <Skeleton className={cx(classes.smallCol, classes.col)} isAnimated={isAnimated} />
    </CMPaper>
  )
}

export default ScoreCardSkeleton
