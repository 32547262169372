import { SHIPMENTS_DRAWER_KEY as DRAWER_KEY, drawersSelector } from 'store/drawers'
import { Divider, withStyles } from '@material-ui/core'
import {
  clearView,
  createView,
  deleteView,
  fetchViews,
  selectView,
  updateView,
} from 'store/boardUtils/actions'
import { currentViewSelector, filtersSelector, viewsSelector } from 'store/boardUtils/selectors'

import { ALERT_DETAIL_ROUTE } from 'utils/routes'
import BoardFilters from './BoardFilters'
import Drawer from 'components/core/Drawer'
import DrawerFooter from 'components/DrawerFooter'
import PropTypes from 'prop-types'
import React from 'react'
import TransportationStatusDropdown from 'components/TransportationStatusDropdown'
import ViewActions from 'components/ViewList/ViewActions'
import ViewList from 'components/ViewList'
import { compose } from 'redux'
import { connect } from 'react-redux'
import first from 'lodash/first'
import get from 'lodash/get'
import { shipmentsTotalCountSelector } from 'store/board/selectors'
import styles from './styles'
import { toggleDrawer } from 'store/drawers/actions'
import { updateAlert } from 'store/api/alertConfigs'
import { withRouter } from 'react-router-dom'

class BoardDrawer extends React.Component {
  static propTypes = {
    clearFilters: PropTypes.func.isRequired,
    createView: PropTypes.func.isRequired,
    currentView: PropTypes.object,
    deleteView: PropTypes.func.isRequired,
    fetchViews: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    isOpen: PropTypes.bool,
    selectView: PropTypes.func.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    updateAlert: PropTypes.func.isRequired,
    updateView: PropTypes.func.isRequired,
    views: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.props.fetchViews()
  }

  componentDidCatch() {
    return
  }

  handleAlertClick = alertConfigIds => {
    if (alertConfigIds.length > 0) {
      const id = first(alertConfigIds)
      this.props.history.push(ALERT_DETAIL_ROUTE.buildUrl({ id }))
    }
  }

  handleUpdateView = (view, newName) => {
    // `filters` here refers to the individual filters, not a "saved filter" (or "view")
    const { updateAlert, updateView } = this.props
    const currName = get(this.props, 'currentView.name')
    const createAlert = false
    const id = view.id
    const reselectAfterUpdate = false // don't refresh anything on page, because new name is already present

    // Only perform update if user edited the name
    if (currName !== newName) {
      updateView(newName, [], id, createAlert, reselectAfterUpdate, view.rollup)

      // Updated associated alert configs if necessary
      const alertConfigIds = get(view, 'alert_config_ids', [])
      if (alertConfigIds.length > 0) {
        for (let i = 0; i < alertConfigIds.length; i++) {
          let alertConfigId = alertConfigIds[i]
          const notify = false
          const config = { id: alertConfigId, name: newName }
          updateAlert(config, notify)
        }
      }
    }
  }

  render() {
    const {
      classes,
      clearFilters,
      createView,
      currentView,
      deleteView,
      filters,
      isOpen,
      selectView,
      toggleDrawer,
      totalCount,
      updateView,
      views,
    } = this.props

    return (
      <Drawer isOpen={isOpen} onToggleDrawer={() => toggleDrawer(DRAWER_KEY, !isOpen)}>
        <ViewList
          views={views}
          selectView={selectView}
          deleteView={deleteView}
          currentView={currentView}
          handleAlertClick={this.handleAlertClick}
          handleUpdateView={this.handleUpdateView}
        />
        <div>
          <Divider variant="middle" className={classes.divider} />
          <TransportationStatusDropdown dataTestId="drawer__transportation-status" />
          <Divider variant="middle" />
        </div>

        <BoardFilters showImportButton={true} showDefaultFilters={true} />
        {(filters.length > 0 || currentView) && (
          <>
            <DrawerFooter>
              {/* Handles Clear Filters and Save/Update Filter */}
              <ViewActions
                drawerKey={DRAWER_KEY}
                clearFilters={clearFilters}
                createView={createView}
                currentView={currentView}
                filters={filters}
                totalCount={totalCount}
                updateView={updateView}
              />
            </DrawerFooter>
          </>
        )}
      </Drawer>
    )
  }
}

const mapDispatchToProps = {
  clearFilters: clearView,
  createView,
  deleteView,
  fetchViews,
  selectView,
  toggleDrawer,
  updateAlert,
  updateView,
}

const mapStateToProps = state => {
  return {
    currentView: currentViewSelector(state),
    filters: filtersSelector(state),
    isOpen: drawersSelector(state)[DRAWER_KEY],
    totalCount: shipmentsTotalCountSelector(state),
    views: viewsSelector(state),
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(BoardDrawer)
