import { Box, IconButton, Theme, Typography, makeStyles } from '@material-ui/core'
import MilestoneAction, { MilestoneActionType } from 'store/models/definitions/MilestoneAction'
import { ROLLUP_CONTAINER, getShipmentDetailPath } from 'utils/rollups'
import React, { useRef, useState } from 'react'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Link from 'components/core/Link'
import PopoverList from 'components/PopoverList'
import { TripInterface } from 'store/models/Trip'
import logger from 'utils/logger'

const useStyles = makeStyles((theme: Theme) => ({
  cell: {},
  muted: {},
  expandButton: {
    borderRadius: 0,
    height: 19,
    padding: 0,
    position: 'relative',
  },
  expandIcon: {
    color: theme.palette.grey[500],
    fontSize: 22,
  },
  containerListItem: {
    minWidth: 215,
    paddingBottom: 2,
    paddingTop: 2,
  },
  message: {
    color: theme.palette.blue[600],
    fontSize: 13,
  },
}))

interface Props {
  action: MilestoneActionType
  classes?: Record<string, string>
  incompleteTrips: TripInterface[] | null
  isPublicShare?: boolean
  milestoneCount: number
  tripsCount: number
}

const INCOMPLETE_COUNT_MESSAGE = {
  [MilestoneAction.arrive]: 'pending carrier update',
  [MilestoneAction.unload]: 'to be discharged',
  [MilestoneAction.load]: 'to be loaded',
  [MilestoneAction.depart]: 'pending carrier update',
} as const

const MessageWithPopver = (props: Props) => {
  const classes = useStyles(props)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerListAnchorRef = useRef(null)
  const handleClose = () => setAnchorEl(null)
  const handleClick = () => setAnchorEl(containerListAnchorRef.current)

  const { action, incompleteTrips = [], isPublicShare = false, milestoneCount, tripsCount } = props
  const message = INCOMPLETE_COUNT_MESSAGE[action]

  if (!message) {
    return null
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" onClick={handleClick}>
        <Box flexGrow="1">
          <Typography ref={containerListAnchorRef} className={classes.message}>
            {tripsCount - milestoneCount} {message}
          </Typography>
        </Box>
        <IconButton
          className={classes.expandButton}
          onClick={handleClick}
          id="containerListAnchorRef"
        >
          {Boolean(anchorEl) ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </IconButton>
      </Box>
      <PopoverList
        anchorEl={anchorEl}
        clipboardData={incompleteTrips?.map((trip: TripInterface) => trip.number).join(',')}
        handleClose={handleClose}
        isPublicShare={isPublicShare}
        listData={incompleteTrips?.map((trip: TripInterface, idx: number) => (
          <Box className={classes.containerListItem} key={`container-${idx}`}>
            {!isPublicShare ? (
              <Link
                to={getShipmentDetailPath(ROLLUP_CONTAINER, trip.id, trip.externalId)}
                onClick={() =>
                  logger.notify('Container List Click - Timeline', {
                    type: message,
                    refType: ROLLUP_CONTAINER,
                    externalId: trip.externalId,
                  })
                }
              >
                {trip.number}
              </Link>
            ) : (
              <>{trip.number}</>
            )}
          </Box>
        ))}
      />
    </Box>
  )
}

export default MessageWithPopver
