import { Location } from 'utils/shipmentObjs'
import { Vessel } from 'utils/sharedObjs'
import get from 'lodash/get'

const structureMilestoneDetails = (milestone, type) => {
  if (type === 'vessel') {
    return new Vessel(get(milestone, 'details.name'), get(milestone, 'details.lloyds_code'))
  } else {
    const name = get(milestone, 'details.name')
    const unlocode = get(milestone, 'details.unlocode')
    const timezone = get(milestone, 'details.timezone')
    const lat = get(milestone, 'details.lat')
    const lon = get(milestone, 'details.lon')
    return new Location(name, unlocode, timezone, lat, lon)
  }
}

export default structureMilestoneDetails
