import { Typography, makeStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import CenteredLayout from 'components/layouts/CenteredLayout'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: 'column',
    paddingBottom: '25%',
  },
  message: {
    marginTop: theme.spacing(3),
    color: theme.palette.grey[800],
  },
  homeBtn: {
    marginTop: theme.spacing(2),
    width: theme.spacing(16),
    boxShadow: 'none',
  },
}))

export default function NotFoundPage({ history }) {
  const classes = useStyles()

  const goHome = () => {
    history.push({
      pathname: '/',
    })
  }

  return (
    <CenteredLayout className={classes.container}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">Page Not Found</Typography>
      <Typography variant="h5" className={classes.message}>
        {"Are you sure you're looking in the right place?"}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.homeBtn}
        onClick={goHome}
      >
        HOME
      </Button>
    </CenteredLayout>
  )
}
