import humps from 'humps'

export class PortalUser {
  constructor(email, externalId, fields, firstName, lastName) {
    this.email = email
    this.externalId = externalId
    this.fields = fields || []
    this.firstName = firstName
    this.lastName = lastName
  }

  static of(payload) {
    const { email, externalId, fields, firstName, lastName } = humps.camelizeKeys(payload)
    return new PortalUser(
      email,
      externalId,
      fields && fields.map(field => Field.of(field)),
      firstName,
      lastName
    )
  }
}

export class Field {
  constructor(key, urlParams, values) {
    this.key = key
    this.urlParams = urlParams
    this.values = values
  }

  static of(payload) {
    const { key, urlParams, values } = humps.camelizeKeys(payload)
    return new Field(key, urlParams, values)
  }
}
