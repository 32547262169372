import { Theme, makeStyles } from '@material-ui/core'

import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))

interface Props {
  className: { [key: string]: string }
}

const TableToolbar = (props: Props) => {
  const classes = useStyles(props)
  const { className, ...rest } = props

  return <div className={cx([className, classes.root])} {...rest} />
}

export default TableToolbar
