/* global __CM_ENV__ */

import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import api from 'store/comments/api'
import { confirmSaga } from 'store/confirmation'
import humps from 'humps'
import { isClearMetalEmail } from 'store/comments/utils'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import keyBy from 'lodash/keyBy'
import logger from 'utils/logger'
import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import { types } from 'store/comments/actions'
import { userFromState } from 'store/auth/user/selectors'

const getResponseData = resp => humps.camelizeKeys(resp.data)

export function* fetchCommentsSaga({ payload }) {
  try {
    const comments = getResponseData(
      yield call(api.fetchComments, { referencedEntityExternalId: payload })
    )
    yield put({ type: types.FETCH_COMMENTS_SUCCESS, payload: comments })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_COMMENTS_ERROR })
  }
}

export function* createCommentSaga({ payload }) {
  const user = yield select(userFromState)
  if (__CM_ENV__ === 'prod' && isClearMetalEmail(user.email)) {
    // If this is production and is a clearmetal user, make sure
    // they know they are posting a comment that will show up to
    // our customers.
    const confirmed = yield call(confirmSaga, CONFIRMATION_MESSAGES.createComment(user.tenant))

    if (!confirmed) {
      return
    }
  }

  const { referencedEntityExternalId, content } = payload
  try {
    yield call(api.createComment, { referencedEntityExternalId, content })
    yield call(fetchCommentsSaga, { payload: referencedEntityExternalId })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.createCommentError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

export function* updateCommentSaga({ payload }) {
  const { referencedEntityExternalId, commentId, content } = payload
  try {
    yield call(api.updateComment, { commentId, content })
    yield call(fetchCommentsSaga, { payload: referencedEntityExternalId })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.updateCommentError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

export function* deleteCommentSaga({ payload }) {
  const confirmed = yield call(confirmSaga, CONFIRMATION_MESSAGES.deleteComment)
  if (!confirmed) {
    return
  }

  const { referencedEntityExternalId, commentId } = payload
  try {
    yield call(api.deleteComment, { commentId })
    yield call(fetchCommentsSaga, { payload: referencedEntityExternalId })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.deleteCommentError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

export function* fetchCommentSummariesSaga({ externalIds }) {
  if (externalIds.length === 0) {
    return
  }

  const isExternalUser = yield select(isExternalUserSelector)
  if (isExternalUser) {
    return
  }

  try {
    const summaries = getResponseData(
      yield call(api.fetchCommentSummaries, { entityExternalIds: externalIds })
    )
    // Translates the list of objects we receive into an object keyed by the entityExternalId.
    // [{ entityExternalId: 1, count: 1 }, { entityExternalId: 2, count: 2 }] => { 1: { count: 1 }, 2: { count: 2 } }
    const summaryMap = mapValues(keyBy(summaries, 'entityExternalId'), s =>
      omit(s, 'entityExternalId')
    )
    yield put({ type: types.FETCH_COMMENT_SUMMARIES_SUCCESS, payload: summaryMap })
  } catch (err) {
    logger.captureAPIException(err)
    logger.error(err)
    yield put({ type: types.FETCH_COMMENT_SUMMARIES_ERROR })
  }
}

export const sagas = [
  takeLatest(types.FETCH_COMMENTS_START, fetchCommentsSaga),
  takeLatest(types.FETCH_COMMENT_SUMMARIES_START, fetchCommentSummariesSaga),
  takeLatest(types.CREATE_COMMENT, createCommentSaga),
  takeLatest(types.UPDATE_COMMENT, updateCommentSaga),
  takeLatest(types.DELETE_COMMENT, deleteCommentSaga),
]
