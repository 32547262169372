import { call, put, takeLatest } from '@redux-saga/core/effects'

import { TransportDetail } from 'store/models/TransportDetail'
import api from 'store/publicShare/api'
import logger from 'utils/logger'
import { types } from 'store/publicShare/actions'

export function* fetchPublicShareSaga({ payload }) {
  // Fetches the share by guid when a user visits the public share page.
  try {
    const res = yield call(api.fetchPublicShare, { guid: payload, version: 'v1' })
    // Yes, it's in `res.data.data` :-|
    const data = (res?.data && res.data.data.map(shipment => TransportDetail.of(shipment))) || {}
    yield put({ type: types.FETCH_PUBLIC_SHARE_SUCCESS, payload: data })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_PUBLIC_SHARE_ERROR })
  }
}

export const sagas = [takeLatest(types.FETCH_PUBLIC_SHARE_START, fetchPublicShareSaga)]
