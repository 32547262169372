import { types } from 'store/auth/invite/actions'

export const INVITE_ACTION_HANDLERS = {
  [types.INVITE_LOAD]: (state, action) => ({
    ...state,
    invite: {
      ...state.invite,
      loading: true,
      result: null,
    },
  }),
  [types.INVITE_SUCCESS]: (state, { payload }) => ({
    ...state,
    invite: {
      ...state.invite,
      loading: false,
      result: payload,
    },
  }),
  [types.INVITE_FAILURE]: (state, { payload }) => ({
    ...state,
    invite: {
      ...state.invite,
      loading: false,
      result: payload,
    },
  }),
}
