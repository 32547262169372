import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import { ROLLUPS, ROLLUP_BOOKINGS, ROLLUP_CONTAINER, getShipmentDetailPath } from 'utils/rollups'

import BasicTable from 'components/BasicTable'
import Button from 'components/core/Button'
import Link from 'components/core/Link'
import NotProvided from 'components/NotProvided'
import OpenInNewIcon from 'components/icons/OpenInNewIcon'
import React from 'react'
import { TripGroupInterface } from 'store/models/TripGroup'
import { defaultShipmentFilters } from 'store/boardUtils/utils/shipmentFilters'
import find from 'lodash/find'
import logger from 'utils/logger'
import { setFilteredList } from 'store/boardUtils/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  tableColsWidth: {
    '& th:first-child': {
      width: '20%',
    },
    '& th:nth-child(2)': {
      width: '25%',
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

interface Props {
  tripGroups: TripGroupInterface[] | undefined
  refType: typeof ROLLUPS[keyof typeof ROLLUPS]
}

const ContainerTable = (props: Props) => {
  const classes = useStyles()
  const { refType, tripGroups } = props
  const dispatch = useDispatch()

  const filterOnContainers = (containerNumbers: string[]) => {
    const containerFilter = find(defaultShipmentFilters, col => col.name === 'container_number')
    logger.notify('View as filtered list', { entity: refType })
    // Dispatch an action that will assemble the filters URL and take the user to `/board`
    dispatch(setFilteredList({ containerFilter, containerNumbers }))
  }

  if (!tripGroups) {
    return null
  }

  return (
    <Box data-testid="entity-page__container-table">
      <Box>
        {tripGroups?.map((tripGroup: TripGroupInterface, idx: number) => (
          <BasicTable
            key={`table-${idx}`}
            classes={{ table: classes.tableColsWidth }}
            columnHeaderData={[
              <>Containers ({tripGroup.trips.length})</>,
              <>Current Milestone Time</>,
              <>Current Milestone Type</>,
            ]}
            rowData={tripGroup.trips?.map(group => [
              <Typography variant="body2">
                <Link
                  className={classes.bold}
                  key={group.trip.number}
                  to={getShipmentDetailPath(
                    ROLLUP_CONTAINER,
                    group.trip.number,
                    group.trip.externalId
                  )}
                >
                  {group.trip.number}
                </Link>
              </Typography>,
              <>
                {group.latestActualMilestone ? (
                  <>
                    <Typography variant="body2">
                      {group.latestActualMilestone?.tracedTime}
                    </Typography>
                    <Typography component="div" variant="caption" color="textSecondary">
                      {group.latestActualMilestone?.locationZone?.timezone}
                    </Typography>
                  </>
                ) : (
                  <NotProvided />
                )}
              </>,
              <>
                {group.currentMilestoneType ? (
                  <Typography variant="body2">{group.currentMilestoneType}</Typography>
                ) : (
                  <NotProvided />
                )}
              </>,
            ])}
            tableHeaderRow={
              <>
                Bookings ({tripGroup.bookings.length}){' '}
                <Box display="inline" pl={1}>
                  {tripGroup.bookings?.length > 0 &&
                    tripGroup.bookings
                      ?.map(booking => (
                        <Link
                          key={booking.number}
                          to={getShipmentDetailPath(
                            ROLLUP_BOOKINGS,
                            booking.number,
                            booking.externalId
                          )}
                        >
                          {booking.number}
                        </Link>
                      ))
                      .reduce((prev, curr) => (
                        <>
                          {prev}, {curr}
                        </>
                      ))}
                </Box>
              </>
            }
            tableFooterRow={
              <>
                <Button
                  onClick={() => {
                    const containerNumbers = tripGroup.trips?.map(trip => trip.trip.number)
                    filterOnContainers(containerNumbers)
                  }}
                  size="small"
                  variant="textLightDefault"
                  startIcon={<OpenInNewIcon />}
                >
                  View as Filtered List
                </Button>
              </>
            }
          />
        ))}
      </Box>
    </Box>
  )
}

export default ContainerTable
