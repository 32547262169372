import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'

export const types = {
  INVITE_LOAD: `${AUTH_REDUCER_KEY}/invite/INVITE_LOAD`,
  INVITE_SUCCESS: `${AUTH_REDUCER_KEY}/invite/INVITE_SUCCESS`,
  INVITE_FAILURE: `${AUTH_REDUCER_KEY}/invite/INVITE_FAILURE`,
}

export const inviteUsers = createAction(types.INVITE_LOAD, (tenant, emails) => ({ tenant, emails }))
