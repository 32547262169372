import { ListItem, ListItemText, ListSubheader, Typography, withStyles } from '@material-ui/core'
import { defaultFiltersSelector, selectIdx } from 'store/defaultFilters'

import { CommonFiltersIcon } from 'components/icons/FilterIcons'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styles from './styles'

class DefaultFilters extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    selectIdx: PropTypes.func.isRequired,
    filterText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: () => {},
  }

  handleClick = idx => () => {
    const { selectIdx, onClick } = this.props
    selectIdx(idx)
    onClick(idx) // trigger any side effects
  }

  renderDefaultFilters = () => {
    const { classes, defaultFilters, filterText } = this.props
    let filtersToRender = []
    defaultFilters.forEach((config, index) => {
      const shouldRender = config.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
      if (shouldRender) {
        filtersToRender.push(
          <ListItem
            dense
            margin="dense"
            key={`filter-option-${config.name}`}
            button
            onClick={this.handleClick(index)}
          >
            <ListItemText primary={config.name} classes={{ primary: classes.listItemText }} />
          </ListItem>
        )
      }
    })
    return filtersToRender
  }

  render() {
    const { classes } = this.props
    const filtersToRender = this.renderDefaultFilters()
    if (filtersToRender.length > 0) {
      return (
        <div>
          <ListSubheader className={classes.subheader}>
            <div className={classes.subheaderTextContainer}>
              <CommonFiltersIcon className={classes.subheaderIcon} />
              <span className={classes.subheaderTitle}>Common Filters</span>
            </div>
            <Typography variant="body2" className={classes.subheaderDescription}>
              Common events you can filter on
            </Typography>
          </ListSubheader>
          {filtersToRender}
        </div>
      )
    }
    return null
  }
}

const mapDispatchToProps = {
  selectIdx,
}

const mapStateToProps = state => {
  return {
    defaultFilters: defaultFiltersSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DefaultFilters))
