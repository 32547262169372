import TripGroup, { TripGroupInterface } from 'store/models/TripGroup'

import { TripGroupsInterface } from 'store/tripGroups/interfaces'
import humps from 'humps'

export default class TripGroups {
  constructor(tripGroups: TripGroupsInterface) {
    Object.assign(this, tripGroups)
  }

  static of(payload: object) {
    const { tripGroups } = humps.camelizeKeys(payload) as TripGroupsInterface

    return new TripGroups({
      tripGroups:
        tripGroups &&
        tripGroups.map(
          (tripGroup: TripGroupInterface) => TripGroup.of(tripGroup) as TripGroupInterface
        ),
    })
  }
}
