import Dropdown from 'components/Dropdown'
import React from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  container: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
  },
  selectedLabel: {
    ...theme.typography.h6,
    color: theme.palette.grey[800],
  },
  dropdownButton: {
    height: 44,
  },
})

function ChartToggleDropdown({ classes, items, labelKey, defaultIdx, handleSelection }) {
  return (
    <div className={classes.container}>
      <div className={classes.dropdown}>
        <Dropdown
          items={items}
          labelKey={labelKey}
          defaultIdx={defaultIdx}
          handleSelection={handleSelection}
          classes={{ selectedLabel: classes.selectedLabel, button: classes.dropdownButton }}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(ChartToggleDropdown)
