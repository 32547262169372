import { Shipment } from 'utils/shipmentObjs'
import get from 'lodash/get'
import getDeliveryStatus from './getDeliveryStatus'
import getETAStatuses from './getETAStatuses'
import getETDStatuses from './getETDStatuses'
import getLastUpdate from './getLastUpdate'
import getLatestStatus from './getLatestStatus'
import getSnapshot from './getSnapshot'
import getVesselTrips from './getVesselTrips'
import getVessels from './getVessels'
import structureDeliveryTimes from './structureDeliveryTimes'
import structureDepartureTimes from './structureDepartureTimes'
import structureDischargeDates from './structureDischargeDates'
import structureLocation from './structureLocation'
import structureOceanCarriers from './structureOceanCarriers'
import structureRoutes from './structureRoutes'

/**
 * Takes in "raw" shipment and transforms
 * @param array Shipment objects
 * @param shipmentId Shipment ID, optional
 * @returns array Transformed shipment objects
 */
const structureShipment = (shipment, shipmentId) => {
  const id = get(shipment, 'id', shipmentId)
  const tripSummary = shipment
  const shipmentBoard = get(shipment, 'shipmentBoard')
  const refType = get(shipment, 'ref_type')
  const refID = get(shipment, 'ref_id')
  const bols = get(shipment, 'references.bols', [])
  const containers = get(shipment, 'containers', [])
  const containerTripIds = get(shipment, 'ctr_trip_ids', [])
  const receiptLocation = structureLocation(get(shipment, 'receipt_location'))
  const loadLocation = structureLocation(get(shipment, 'load_location'))
  const dischargeLocation = structureLocation(get(shipment, 'discharge_location'))
  const deliveryLocation = structureLocation(get(shipment, 'delivery_location'))
  const destinationHaulage = get(shipment, 'haulage_type.destination')
  const originHaulage = get(shipment, 'haulage_type.origin')
  const oceanBookings = get(shipment, 'references.ocean_bookings', [])
  const orders = get(shipment, 'references.order_numbers', [])
  const salesOrders = get(shipment, 'references.sales_order_numbers', [])
  const purchaseOrders = get(shipment, 'references.purchase_order_numbers', [])
  const stockTransferOrders = get(shipment, 'references.stock_transfer_order_numbers', [])
  const lineItems = get(shipment, 'references.line_item_numbers', [])
  const carriers = structureOceanCarriers(get(shipment, 'ocean_carriers', []))
  const originDeparture = structureDepartureTimes(get(shipment, 'departure_time', {}))
  const destArrival = structureDeliveryTimes(get(shipment, 'delivery_time', {}))
  const routes = structureRoutes(get(shipment, 'timelines', []))
  const dischargeDates = structureDischargeDates(get(shipment, 'discharge_time', {}))
  const latestStatus = getLatestStatus(routes)
  const lastUpdate = getLastUpdate(shipment)
  const etaStatuses = getETAStatuses(destArrival)
  const etdStatuses = getETDStatuses(originDeparture)
  const deliveryStatus = getDeliveryStatus(routes, etaStatuses)
  const tags = get(shipment, 'custom_fields', [])
  const vessels = getVessels(routes)
  const snapshot = getSnapshot(routes)
  const vesselTrips = getVesselTrips(routes)
  const maxOceanDelay = get(shipment, 'max_ocean_delay')
  const status = get(shipment, 'status')
  // called in multiple places where either property might be present
  const isIdle = Boolean(get(shipment, 'isIdle') || get(shipment, 'is_idle'))
  const shipmentNumbers = get(shipment, 'references.shipment_numbers', [])
  const externalId = get(shipment, 'external_id')

  return new Shipment(
    id,
    refID,
    bols,
    containerTripIds,
    receiptLocation,
    loadLocation,
    dischargeLocation,
    deliveryLocation,
    originHaulage,
    destinationHaulage,
    oceanBookings,
    carriers,
    originDeparture,
    routes,
    tags,
    latestStatus,
    deliveryStatus,
    lastUpdate,
    etaStatuses,
    etdStatuses,
    vessels,
    snapshot,
    vesselTrips,
    orders,
    lineItems,
    maxOceanDelay,
    dischargeDates,
    status,
    isIdle,
    shipmentNumbers,
    externalId,
    tripSummary,
    salesOrders,
    purchaseOrders,
    stockTransferOrders,
    containers,
    refType,
    shipmentBoard
  )
}

export default structureShipment
