import { createAction } from 'redux-actions'

export const USER_DASHBOARD_KEY = 'userDashboard'

export const types = {
  FETCH_TOTAL_CONTAINER_COUNT_STATUS_START: `${USER_DASHBOARD_KEY}/FETCH_TOTAL_CONTAINER_COUNT_STATUS_START`,
  FETCH_TOTAL_CONTAINER_COUNT_STATUS_SUCCESS: `${USER_DASHBOARD_KEY}/FETCH_TOTAL_CONTAINER_COUNT_STATUS_SUCCESS`,
  FETCH_TOTAL_CONTAINER_COUNT_STATUS_ERROR: `${USER_DASHBOARD_KEY}/FETCH_TOTAL_CONTAINER_COUNT_STATUS_ERROR`,
  FETCH_TOP_LANES_BY_VOLUME_START: `${USER_DASHBOARD_KEY}/FETCH_TOP_LANES_BY_VOLUME_START`,
  FETCH_TOP_LANES_BY_VOLUME_SUCCESS: `${USER_DASHBOARD_KEY}/FETCH_TOP_LANES_BY_VOLUME_SUCCESS`,
  FETCH_TOP_LANES_BY_VOLUME_ERROR: `${USER_DASHBOARD_KEY}/FETCH_TOP_LANES_BY_VOLUME_ERROR`,
  FETCH_VARIABLE_ROUTES_START: `${USER_DASHBOARD_KEY}/FETCH_VARIABLE_ROUTES_START`,
  FETCH_VARIABLE_ROUTES_SUCCESS: `${USER_DASHBOARD_KEY}/FETCH_VARIABLE_ROUTES_SUCCESS`,
  FETCH_VARIABLE_ROUTES_ERROR: `${USER_DASHBOARD_KEY}/FETCH_VARIABLE_ROUTES_ERROR`,
  FETCH_CONTAINER_TRIP_PERFORMANCE_START: `${USER_DASHBOARD_KEY}/FETCH_CONTAINER_TRIP_PERFORMANCE_START`,
  FETCH_CONTAINER_TRIP_PERFORMANCE_SUCCESS: `${USER_DASHBOARD_KEY}/FETCH_CONTAINER_TRIP_PERFORMANCE_SUCCESS`,
  FETCH_CONTAINER_TRIP_PERFORMANCE_ERROR: `${USER_DASHBOARD_KEY}/FETCH_CONTAINER_TRIP_PERFORMANCE_ERROR`,
  FETCH_ROLLS_OVER_TIME_START: `${USER_DASHBOARD_KEY}/FETCH_ROLLS_OVER_TIME_START`,
  FETCH_ROLLS_OVER_TIME_SUCCESS: `${USER_DASHBOARD_KEY}/FETCH_ROLLS_OVER_TIME_SUCCESS`,
  FETCH_ROLLS_OVER_TIME_ERROR: `${USER_DASHBOARD_KEY}/FETCH_ROLLS_OVER_TIME_ERROR`,
  FETCH_DWELL_TIMES_START: `${USER_DASHBOARD_KEY}/FETCH_DWELL_TIMES_START`,
  FETCH_DWELL_TIMES_SUCCESS: `${USER_DASHBOARD_KEY}/FETCH_DWELL_TIMES_SUCCESS`,
  FETCH_DWELL_TIMES_ERROR: `${USER_DASHBOARD_KEY}/FETCH_DWELL_TIMES_ERROR`,
}

export const fetchTotalContainerCountStatus = createAction(
  types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_START
)
export const fetchTopLanesByVolume = createAction(types.FETCH_TOP_LANES_BY_VOLUME_START)
export const fetchVariableRoutes = createAction(types.FETCH_VARIABLE_ROUTES_START)
export const fetchContainerTripPerformance = createAction(
  types.FETCH_CONTAINER_TRIP_PERFORMANCE_START
)
export const fetchRollsOverTime = createAction(types.FETCH_ROLLS_OVER_TIME_START)
export const fetchDwellTimes = createAction(types.FETCH_DWELL_TIMES_START)
