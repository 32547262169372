import get from 'lodash/get'
import getAbbreviatedMilestoneDescription from './getAbbreviatedMilestoneDescription'

const getTracedMilestoneInfo = (rawSegment, milestones) => {
  const milestoneIdxs = get(rawSegment, 'milestone_indices', [])
  const milestonesForSeg = []
  for (let i = 0; i < milestoneIdxs.length; i++) {
    let idx = milestoneIdxs[i]
    const rawMilestone = milestones[idx]
    const type = get(rawMilestone, 'type')
    const time = get(rawMilestone, 'time.traced') // get traced time
    const tz = get(rawMilestone, 'time.timezone') // get traced time
    const description = getAbbreviatedMilestoneDescription(type)
    if (time !== null) {
      milestonesForSeg.push({ type, description, time, tz })
    }
  }
  return milestonesForSeg
}

export default getTracedMilestoneInfo
