export const AMBER = 'amber'
export const BLUE = 'blue'
export const BLUE25 = 'blue25'
export const GREEN = 'green'
export const GREY = 'grey'
export const GREY2 = 'grey2'
export const GREY50 = 'grey50'
export const MODE = 'mode' // used only for Primary Mode label
export const ORANGE = 'orange'
export const PURPLE100 = 'purple100'
export const RED = 'red'
export const TEAL = 'teal'
export const YELLOW = 'yellow'
export const YELLOW2 = 'yellow2'
export const YELLOW25 = 'yellow25'
export const STATUS_DELIVERED = 'statusDelivered'
export const STATUS_DISCHARGED = 'statusDischarged'
export const STATUS_INTRANSIT = 'statusIntransit'
export const STATUS_PENDING = 'statusPending'
export const STATUS_UNKNOWN = 'statusUnknown'

export const LABEL_COLORS = {
  [BLUE]: BLUE,
  [BLUE25]: BLUE25,
  [GREEN]: GREEN,
  [GREY]: GREY,
  [GREY2]: GREY2,
  [GREY50]: GREY50,
  [MODE]: MODE,
  [ORANGE]: ORANGE,
  [PURPLE100]: PURPLE100,
  [RED]: RED,
  [YELLOW]: YELLOW,
  [YELLOW2]: YELLOW2,
  [YELLOW25]: YELLOW25,
  [STATUS_DELIVERED]: STATUS_DELIVERED,
  [STATUS_DISCHARGED]: STATUS_DISCHARGED,
  [STATUS_INTRANSIT]: STATUS_INTRANSIT,
  [STATUS_PENDING]: STATUS_PENDING,
  [STATUS_UNKNOWN]: STATUS_UNKNOWN,
} as const

export const LABEL_COLOR_VARIANTS = [
  BLUE,
  BLUE25,
  GREEN,
  GREY2,
  GREY,
  GREY50,
  MODE,
  ORANGE,
  PURPLE100,
  RED,
  YELLOW,
  YELLOW2,
  YELLOW25,
  STATUS_DELIVERED,
  STATUS_DISCHARGED,
  STATUS_INTRANSIT,
  STATUS_PENDING,
  STATUS_UNKNOWN,
] as const

export type LabelColorVariants =
  | 'blue'
  | 'blue25'
  | 'green'
  | 'grey'
  | 'grey2'
  | 'grey50'
  | 'mode' // used only for Primary Mode label
  | 'orange'
  | 'purple100'
  | 'red'
  | 'yellow'
  | 'yellow2'
  | 'yellow25'
  | 'statusDelivered'
  | 'statusDischarged'
  | 'statusIntransit'
  | 'statusPending'
  | 'statusUnknown'
