export default theme => ({
  container: {
    width: '100%',
  },
  inputRoot: {
    padding: '5px 8px',
    fontSize: 12,
    width: '90%',
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    fontSize: 13,
  },
  suggestionValue: {
    fontSize: 11,
    color: '#8C8C8C',
  },
  textField: {
    width: '100%',
  },
  loader: {
    width: '20px !important',
    height: '20px !important',
    color: '#959595', // TODO: Add or replace with color object
  },
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: 13,
    color: theme.palette.grey[800],
  },
  chipLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  chip: {
    marginRight: 0,
    width: '100%',
  },
})
