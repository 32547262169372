import { CustomFieldsInterface } from 'store/customFields/interfaces'
import { CustomFieldsState } from 'store/customFields/interfaces'
import { handleActions } from 'redux-actions'
import { types } from 'store/customFields/actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: CustomFieldsState = {
  isFailed: false,
  isLoading: false,
  data: undefined,
}

const customFields = handleActions<CustomFieldsState, any>(
  {
    [types.FETCH_CUSTOM_FIELDS_START]: (state: CustomFieldsState) => {
      return { ...state, isFailed: false, isLoading: true }
    },
    [types.FETCH_CUSTOM_FIELDS_SUCCESS]: (
      state: CustomFieldsState,
      action: { payload: CustomFieldsInterface }
    ) => {
      return {
        ...state,
        data: action.payload,
        isFailed: false,
        isLoading: false,
      }
    },
    [types.FETCH_CUSTOM_FIELDS_FAILURE]: (state: CustomFieldsState) => {
      return { ...state, data: undefined, isFailed: true, isLoading: false }
    },
    [types.FETCH_CUSTOM_FIELDS_RESET]: (state: CustomFieldsState) => {
      return { ...state, data: undefined, isFailed: false, isLoading: false }
    },
  },
  initialState
)

export default customFields
