export const OAUTH_STATUS_REDUCER_KEY = 'oauthStatus'

export const types = {
  OAUTH_STATUS_LOAD: `${OAUTH_STATUS_REDUCER_KEY}/LOAD`,
  OAUTH_STATUS_SUCCESS: `${OAUTH_STATUS_REDUCER_KEY}/SUCCESS`,
  OAUTH_STATUS_FAIL: `${OAUTH_STATUS_REDUCER_KEY}/FAIL`,
  OAUTH_STATUS_RESET: `${OAUTH_STATUS_REDUCER_KEY}/RESET`,
}

export const loadOauthStatus = (username: string) => ({
  type: types.OAUTH_STATUS_LOAD,
  payload: { username },
})

export const resetOauthStatus = () => ({ type: types.OAUTH_STATUS_RESET })

export default types
