import tinycolor from 'tinycolor2'

// Theme is passed from material-ui withStyles
export default theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  toolbar: {
    backgroundColor: tinycolor(theme.brand.color).setAlpha(0.3).toRgbString(),
    flex: 1,
    display: 'flex',
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white,
    flex: 1,
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  form: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    width: '100%',
  },
  submitBtn: {
    color: '#66717E',
    border: '1px solid #66717E',
    width: '100%',
    marginTop: theme.spacing(4),
    '&:hover': {
      backgroundColor: '#66717E',
      color: theme.palette.common.white,
    },
  },
  searchWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
})
