import findLast from 'lodash/findLast'
import first from 'lodash/first'
import { formatVesselLabel } from 'utils/strUtils'
import get from 'lodash/get'

export const getLastTraced = legs => {
  // find last leg with a traced time
  return findLast(legs, leg => get(leg, 'fromTime.traced.min'))
}

export const formatLabel = label => {
  if (label)
    return label.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

const getLatestStatus = routes => {
  if (routes.length > 1) return '' // 'Trip has split'
  const route = first(routes)
  const lastLeg = getLastTraced(get(route, 'milestones'))
  const type = get(lastLeg, 'type', '')
  if (type === 'vessel') {
    return `On vessel ${formatVesselLabel(get(lastLeg, 'details.name', ''))}`
  } else if (type.indexOf('port') !== -1) {
    const legDetails = get(lastLeg, 'details')
    let location
    if (legDetails.name) {
      location = formatLabel(legDetails.name)
    } else if (legDetails.unlocode) {
      location = legDetails.unlocode
    } else {
      location = 'Unavailable'
    }
    return `Most recent location: ${location}`
  }
  return 'Latest status unavailable'
}

export default getLatestStatus
