import { Divider, Typography, makeStyles } from '@material-ui/core'

import Link from 'components/core/Link'
import PropTypes from 'prop-types'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import cx from 'classnames'
import logger from 'utils/logger'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: `-${theme.spacing(2)}px`,
    marginRight: `-${theme.spacing(2)}px`,
  },
  title: {
    fontSize: '1.13rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
  inheritFontSize: {
    fontSize: 'inherit',
  },
  searching: {
    marginTop: theme.spacing(1),
  },
  results: {
    marginTop: theme.spacing(1),
  },
  noResultsContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}))

function ResultTitle({ resultCount, showRecentSearches, dirty, anyPendingCalls }) {
  const classes = useStyles()

  if (showRecentSearches) {
    return (
      <>
        <Divider className={classes.divider} />
        <Typography className={classes.title} variant="h6" data-testid="autocomplete__result-title">
          Recent Searches
        </Typography>
      </>
    )
  } else if (anyPendingCalls) {
    return (
      <Typography
        className={cx([classes.title, classes.searching])}
        variant="h6"
        data-testid="autocomplete__result-title"
      >
        Searching...
      </Typography>
    )
  } else if (resultCount > 0) {
    return (
      <Typography
        className={cx([classes.title, classes.results])}
        variant="h6"
        data-testid="autocomplete__result-title"
      >
        {`${resultCount} results`}
      </Typography>
    )
  } else if (dirty && !anyPendingCalls) {
    return (
      <div className={cx([classes.noResultsContainer, classes.marginTop])}>
        <SearchIcon color="disabled" fontSize="large" />
        <Typography className={classes.title} variant="h6">
          No results found
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Please check the number you entered and try again.
          <br />
          If this is a valid ID, you can contact{' '}
          <Link
            className={classes.inheritFontSize}
            component="button"
            onClick={() => {
              logger.notify('User Initiated Chat', { initiatedFrom: 'autocomplete' })
              window.Intercom('show')
            }}
          >
            Support
          </Link>
          .
        </Typography>
      </div>
    )
  } else {
    return null
  }
}

ResultTitle.propTypes = {
  resultCount: PropTypes.number.isRequired,
  showRecentSearches: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  anyPendingCalls: PropTypes.bool.isRequired,
}

ResultTitle.defaultPropos = {
  isLoading: false,
  resultCount: 0,
  showRecentSearches: false,
  dirty: false,
  anyPendingCalls: false,
}

export default ResultTitle
