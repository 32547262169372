import { BoardColumnInterface, BoardRowInterface } from 'store/board/interfaces'
import { Theme, makeStyles } from '@material-ui/core'

import { Cell } from 'react-sticky-table'
import React from 'react'
import classnames from 'classnames'

const MIN_WIDTH = 150
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1) + 4,
    verticalAlign: 'top',
  },
}))

interface Props {
  children: React.ReactNode
  className?: string
  column?: BoardColumnInterface
  onClick?: () => void
  onContextMenu?: () => void
  rollup?: string
  row?: BoardRowInterface
  style?: object
}

const BaseCell = (props: Props) => {
  const { children, className, column, onClick, onContextMenu, rollup, row, style = {} } = props
  const classes = useStyles(props)
  const columnStyle = column?.style ?? {}
  const mergedStyle = { minWidth: MIN_WIDTH, ...columnStyle, ...style }

  return (
    <Cell
      column={column}
      className={classnames(classes.root, className)}
      onClick={onClick}
      onContextMenu={onContextMenu}
      rollup={rollup}
      row={row}
      style={mergedStyle}
    >
      {children}
    </Cell>
  )
}

export default React.memo(BaseCell)
