import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from 'components/Skeleton'
import { makeStyles } from '@material-ui/core'
import sample from 'lodash/sample'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

BoxWhiskerSkeleton.propTypes = {
  whiskerWidthPercentage: PropTypes.number,
  width: PropTypes.number,
  boxHeights: PropTypes.arrayOf(PropTypes.string),
  stemHeights: PropTypes.arrayOf(PropTypes.string),
  stemWidth: PropTypes.number,
  whiskerHeight: PropTypes.number,
  isAnimated: PropTypes.bool,
  animationDelay: PropTypes.string,
}

BoxWhiskerSkeleton.defaultProps = {
  whiskerWidthPercentage: 0.75,
  width: 20,
  boxHeights: ['5%', '10%', '15%', '25%', '35%'],
  stemHeights: ['5%', '10%', '15%', '25%'],
  stemWidth: 2,
  whiskerHeight: 2,
  isAnimated: true,
  animationDelay: '0ms',
}

function BoxWhiskerSkeleton({
  whiskerWidthPercentage,
  width,
  boxHeights,
  stemHeights,
  stemWidth,
  whiskerHeight,
  isAnimated,
  animationDelay,
}) {
  const classes = useStyles()
  const whiskerWidth = width * whiskerWidthPercentage

  return (
    <div className={classes.root}>
      <Skeleton
        gutter={false}
        animationDelay={animationDelay}
        height={whiskerHeight}
        isAnimated={isAnimated}
        width={whiskerWidth}
      />
      <Skeleton
        gutter={false}
        animationDelay={animationDelay}
        height={sample(stemHeights)}
        isAnimated={isAnimated}
        width={stemWidth}
      />
      <Skeleton
        gutter={false}
        animationDelay={animationDelay}
        borderRadius="small"
        height={sample(boxHeights)}
        isAnimated={isAnimated}
        width={width}
      />
      <Skeleton
        gutter={false}
        animationDelay={animationDelay}
        height={sample(stemHeights)}
        isAnimated={isAnimated}
        width={stemWidth}
      />
      <Skeleton
        gutter={false}
        animationDelay={animationDelay}
        height={whiskerHeight}
        isAnimated={isAnimated}
        width={whiskerWidth}
      />
    </div>
  )
}

export default BoxWhiskerSkeleton
