import flatten from 'lodash/flatten'
import { handleActions } from 'redux-actions'
import { types } from 'store/planning/actions'

export const PLANNING_REDUCER_KEY = 'planning'

export const initialState = {
  // Routes
  routeGroups: [],

  // Metrics
  routeMetrics: {},
  allRoutesMetrics: {},
  allRoutesAggregatedMetrics: {},
  routeComparisonMetrics: {},

  // routeGroups with metrics
  routeGroupsWithMetrics: undefined,

  // Loaders
  // We're loading the metrics for the route groups and routes.
  isRoutesMetricsLoading: false,

  // We're loading the metrics for all selected routes.
  isAllRoutesMetricsLoading: false,

  // We're loading the aggregated metrics for all selected routes.
  isAllRoutesMetricsAggregatedLoading: false,

  // We're loading the aggregated metrics for the individual selected routes.
  isRouteComparisonMetricsLoading: false,

  // Selected route ids
  selectedRouteIds: new Set(),

  sortState: { orderBy: 'container_count', order: 'desc' },
}

export const routeGroupSelector = state => state[PLANNING_REDUCER_KEY].routeGroups
export const isRoutesMetricsLoadingSelector = state =>
  state[PLANNING_REDUCER_KEY].isRoutesMetricsLoading
export const isAllRoutesMetricsLoadingSelector = state =>
  state[PLANNING_REDUCER_KEY].isAllRoutesMetricsLoading
export const isComparisonGraphMetricsLoadingSelector = state =>
  state[PLANNING_REDUCER_KEY].isRouteComparisonMetricsLoading
export const isAggregatedGraphMetricsLoadingSelector = state =>
  state[PLANNING_REDUCER_KEY].isAllRoutesMetricsAggregatedLoading
export const isRouteComparisonMetricsLoadingSelector = state =>
  state[PLANNING_REDUCER_KEY].isRouteComparisonMetricsLoading
export const routeMetricsSelector = state => state[PLANNING_REDUCER_KEY].routeMetrics
export const allRoutesMetricsSelector = state => state[PLANNING_REDUCER_KEY].allRoutesMetrics
export const allRoutesAggregatedMetricsSelector = state =>
  state[PLANNING_REDUCER_KEY].allRoutesAggregatedMetrics
export const routeComparisonMetricsSelector = state =>
  state[PLANNING_REDUCER_KEY].routeComparisonMetrics

export const flattenRouteGroupIds = routeGroups =>
  flatten(routeGroups.map(routeGroup => routeGroup.routes.map(route => route.routeId)))

export const routeIdsSelector = state => {
  const routeGroups = routeGroupSelector(state)
  if (!routeGroups) {
    return []
  }
  return flattenRouteGroupIds(routeGroups)
}
export const selectedRouteIdsSelector = state => state[PLANNING_REDUCER_KEY].selectedRouteIds
export const routeGroupsWithMetricsSelector = state =>
  state[PLANNING_REDUCER_KEY].routeGroupsWithMetrics

export const sortStateSelector = state => state[PLANNING_REDUCER_KEY].sortState

const reloadAllData = {
  isRoutesMetricsLoading: true,
  isAllRoutesMetricsLoading: true,
  isAllRoutesMetricsAggregatedLoading: true,
  isRouteComparisonMetricsLoading: true,
  routeGroups: [],
  routeGroupsWithMetrics: undefined,
  routeComparisonMetrics: {},
  allRoutesMetrics: {},
  allRoutesAggregatedMetrics: {},
  selectedRouteIds: new Set(),
}

export default handleActions(
  {
    [types.FETCH_ROUTES_START]: state => ({
      ...state,
      ...reloadAllData,
    }),
    [types.FETCH_ROUTES_SUCCESS]: (state, { payload }) => ({
      ...state,
      routeGroups: payload,
      selectedRouteIds: new Set(flattenRouteGroupIds(payload)),
    }),
    [types.FETCH_ROUTES_ERROR]: state => ({
      ...state,
      selectedRouteIds: new Set(),
    }),
    [types.FETCH_ROUTES_WITH_METRICS_SUCCESS]: (state, { payload }) => ({
      ...state,
      routeGroupsWithMetrics: payload.routeGroups,
      routeMetrics: payload.metrics,
      isRoutesMetricsLoading: false,
    }),
    [types.FETCH_ROUTES_WITH_METRICS_ERROR]: state => ({
      ...state,
      routeGroupsWithMetrics: undefined,
      routeMetrics: {},
      isRoutesMetricsLoading: false,
    }),
    [types.FETCH_ALL_ROUTE_METRICS_START]: state => ({
      ...state,
      isAllRoutesMetricsLoading: true,
    }),
    [types.FETCH_ALL_ROUTE_METRICS_SUCCESS]: (state, { payload }) => ({
      ...state,
      allRoutesMetrics: payload,
      isAllRoutesMetricsLoading: false,
    }),
    [types.FETCH_ALL_ROUTE_METRICS_ERROR]: state => ({
      ...state,
      isAllRoutesMetricsLoading: false,
    }),
    [types.FETCH_ALL_ROUTE_AGGREGATED_METRICS_START]: state => ({
      ...state,
      isAllRoutesMetricsAggregatedLoading: true,
    }),
    [types.FETCH_ALL_ROUTE_AGGREGATED_METRICS_SUCCESS]: (state, { payload }) => ({
      ...state,
      allRoutesAggregatedMetrics: payload,
      isAllRoutesMetricsAggregatedLoading: false,
    }),
    [types.FETCH_ALL_ROUTE_AGGREGATED_METRICS_ERROR]: state => ({
      ...state,
      isAllRoutesMetricsAggregatedLoading: false,
    }),
    [types.FETCH_ROUTE_METRICS_COMPARISON_START]: state => ({
      ...state,
      isRouteComparisonMetricsLoading: true,
    }),
    [types.FETCH_ROUTE_METRICS_COMPARISON_SUCCESS]: (state, { payload }) => ({
      ...state,
      routeComparisonMetrics: payload,
      isRouteComparisonMetricsLoading: false,
    }),
    [types.FETCH_ROUTE_METRICS_COMPARISON_ERROR]: state => ({
      ...state,
      isRouteComparisonMetricsLoading: false,
    }),
    [types.UPDATE_SELECTED_ROUTE_IDS]: (state, { payload }) => {
      const selectedRouteIds = new Set(state.selectedRouteIds)
      payload.routeIds.forEach(routeId => {
        if (payload.checked) {
          selectedRouteIds.add(routeId)
        } else {
          selectedRouteIds.delete(routeId)
        }
      })

      return { ...state, selectedRouteIds }
    },
    [types.UPDATE_SORT_STATE]: (state, { payload }) => ({
      ...state,
      sortState: payload,
    }),
  },
  initialState
)
