import { default as MUITableSortLabel, TableSortLabelProps } from '@material-ui/core/TableSortLabel'
import { Theme, makeStyles } from '@material-ui/core'

import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

const TableSortLabel = (props: TableSortLabelProps) => {
  const { className, ...rest } = props
  const classes = useStyles(props)

  return <MUITableSortLabel className={cx(className, classes.root)} classes={classes} {...rest} />
}

export default TableSortLabel
