import UiGroupsInterface from 'store/types/UiGroupsInterface'
import each from 'lodash/each'

/**
 * For each group adds a `subsequentGroupHasTracedMilestone` property if _any_ following group has a traced milestone.
 * To do this we reverse the groups and within each group reverse the locations. If we find a `hasTracedTime`
 * in any group all the following groups get `subsequentGroupHasTracedMilestone: true`. All others will get `subsequentGroupHasTracedMilestone: false`.
 * @param groupedMilestones
 */
export const setSubsequentGroupHasTracedMilestone = (groupedMilestones: UiGroupsInterface) => {
  let hasSubsequent = false
  // Reverse groups
  each(Object.keys(groupedMilestones).reverse(), group => {
    // Within each group reverse the locations (if more than one)
    each(Object.keys(groupedMilestones[group]).reverse(), location => {
      const groupLocation = groupedMilestones[group][location]
      groupedMilestones[group][location]['subsequentGroupHasTracedMilestone'] = hasSubsequent

      // Set for next looop. If already `true` leave it, otherwise update to this location's `hasTracedTime`
      hasSubsequent = hasSubsequent || groupLocation.hasTracedTime
    })
  })
}

export default setSubsequentGroupHasTracedMilestone
