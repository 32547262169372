import { ASSOCIATED_REFERENCES_KEY } from 'store/associatedReferences/actions'
import { AssociatedReferencesState } from 'store/associatedReferences/interfaces'

export const associatedReferencesSelector = (state: {
  [ASSOCIATED_REFERENCES_KEY]: AssociatedReferencesState
}) => state[ASSOCIATED_REFERENCES_KEY].data

export const associatedReferencesLoadingSelector = (state: {
  [ASSOCIATED_REFERENCES_KEY]: AssociatedReferencesState
}) => state[ASSOCIATED_REFERENCES_KEY].isLoading
