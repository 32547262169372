import { SUPPORT_REDUCER_KEY, types } from 'store/support/actions'

import { handleActions } from 'redux-actions'

export const unreadMessageCountSelector = state => state[SUPPORT_REDUCER_KEY].unreadMessageCount
export const supportChatOpenSelector = state => state[SUPPORT_REDUCER_KEY].supportChatOpen

export const initialState = {
  supportChatOpen: false,
  unreadMessageCount: 0,
}

export { SUPPORT_REDUCER_KEY }
export default handleActions(
  {
    [types.OPEN_SUPPORT_CHAT]: state => {
      // TODO: Seperate Intercom concern, store should not know implimentation
      // Ticket: #170408753
      window.Intercom('show')
      return {
        ...state,
        supportChatOpen: true,
      }
    },
    [types.CLOSE_SUPPORT_CHAT]: state => {
      // TODO: Seperate Intercom concern, store should not know implimentation
      // Ticket: #170408753
      window.Intercom('hide')
      return {
        ...state,
        supportChatOpen: false,
      }
    },
    [types.UPDATE_UNREAD_MESSAGE_COUNT]: (state, { payload }) => ({
      ...state,
      unreadMessageCount: payload,
    }),
  },
  initialState
)
