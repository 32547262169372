import ApiRefType, { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import {
  ORDER_REF_TYPES,
  ROLLUP_LINE_ITEM,
  getRefType,
  getRefTypeLabel,
  getShipmentDetailPath,
} from 'utils/rollups'

import { BREADCRUMB_PROGRESS } from 'components/ReferencesTable'
import { CommonInterface } from 'store/associatedReferences/interfaces'
import React from 'react'
import TableCell from 'components/ReferencesTable/TableCell'
import TableCellText from 'components/ReferencesTable/TableCellText'
import { TableRow } from '@material-ui/core'
import logger from 'utils/logger'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  orderTable: {
    '& td': {
      width: '50%',
    },
  },
  subtext: {},
}))

interface DataInterface {
  lineItems: CommonInterface[]
  order: CommonInterface
  totalLineItems: number
}

interface Props {
  classes?: { [key: string]: string }
  data: DataInterface
  getBreadcrumbIndex: (refType: string) => number
  index: number
  refType: ApiRefTypeType
}

const OrderTable = (props: Props) => {
  const classes = useStyles(props)
  const { data, getBreadcrumbIndex, index, refType } = props
  if (!data) {
    return null
  }
  const { lineItems, totalLineItems } = data
  const { externalId, number, type } = data.order
  const orderLabel = getRefTypeLabel(type)
  const orderType = getRefType(type)
  const breadcrumbIndex = getBreadcrumbIndex(refType)
  const API_ORDER_REFTYPES: ApiRefTypeType[] = [
    ApiRefType.purchase_order_number,
    ApiRefType.sales_order_number,
    ApiRefType.stock_transfer_order_number,
  ]

  return (
    <>
      <TableRow className={classes.orderTable}>
        <TableCell disableBorder={index === 0}>
          <TableCellText
            bold={breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX}
            color={
              breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX ? 'textPrimary' : 'textSecondary'
            }
          >
            {orderLabel}
          </TableCellText>
          {totalLineItems !== lineItems.length && (
            <TableCellText
              classes={{ root: classes.subtext }}
              color="textSecondary"
              italic
              variant="caption"
            >
              {`${lineItems.length} of ${totalLineItems} Line Items shown`}
            </TableCellText>
          )}
        </TableCell>
        <TableCell disableBorder={index === 0}>
          <TableCellText
            active={API_ORDER_REFTYPES.includes(refType) && ORDER_REF_TYPES.includes(type)}
            onClick={() => {
              logger.notify(`Reference Link Click`, {
                entityTypeFrom: refType,
                entityTypeTo: orderType,
              })
            }}
            href={
              !API_ORDER_REFTYPES.includes(refType) && !ORDER_REF_TYPES.includes(refType)
                ? getShipmentDetailPath(orderType, number, externalId)
                : undefined
            }
          >
            {number}
          </TableCellText>
        </TableCell>
      </TableRow>
      {totalLineItems > 0 &&
        lineItems.map((lineItem, idx) => {
          const { externalId, number } = lineItem
          return (
            <TableRow key={lineItem.number}>
              <TableCell disableBorder indent>
                <TableCellText
                  bold={
                    breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX &&
                    breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_ONE
                  }
                  color={
                    breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_SIX &&
                    breadcrumbIndex !== BREADCRUMB_PROGRESS.STEP_ONE
                      ? 'textPrimary'
                      : 'textSecondary'
                  }
                >
                  Line Item
                </TableCellText>
              </TableCell>
              <TableCell disableBorder>
                <TableCellText
                  active={refType === ApiRefType.line_item_number && idx === 0}
                  onClick={() => {
                    logger.notify(`Reference Link Click`, {
                      entityTypeFrom: refType,
                      entityTypeTo: ROLLUP_LINE_ITEM,
                    })
                  }}
                  href={
                    refType !== ApiRefType.line_item_number
                      ? getShipmentDetailPath(ROLLUP_LINE_ITEM, number, externalId)
                      : undefined
                  }
                >
                  {lineItem.number}
                </TableCellText>
              </TableCell>
            </TableRow>
          )
        })}
    </>
  )
}

export default OrderTable
