// Milestone Types
const STUFFING = 'Stuffing'
const PRE_CARRIAGE = 'Pre-carriage'
const PORT_OF_LOADING = 'Port of loading'
const TRANSSHIPMENT = 'Transshipment'
const PORT_OF_DISCHARGE = 'Port of discharge'
const ON_CARRIAGE = 'Post-carriage'
const DELIVERY = 'Delivery'

export const commonMilestones = [
  {
    label: 'Load on vessel at origin port',
    type: PORT_OF_LOADING,
    value: 'vessel_load_origin',
  },
  {
    label: 'Depart on vessel from origin port',
    type: PORT_OF_LOADING,
    value: 'vessel_depart_origin',
  },
  {
    label: 'Arrive on vessel at transshipment port',
    type: TRANSSHIPMENT,
    value: 'vessel_arrive_transshipment',
  },
  {
    label: 'Discharge from vessel at transshipment port',
    type: TRANSSHIPMENT,
    value: 'vessel_discharge_transshipment',
  },
  {
    label: 'Depart on vessel from transshipment port',
    type: TRANSSHIPMENT,
    value: 'vessel_depart_transshipment',
  },
  {
    label: 'Arrive on vessel at destination port',
    type: PORT_OF_DISCHARGE,
    value: 'vessel_arrive_destination',
  },
  {
    label: 'Discharge from vessel at destination port',
    type: PORT_OF_DISCHARGE,
    value: 'vessel_discharge_destination',
  },
  {
    label: 'Available for pickup',
    type: DELIVERY,
    value: 'available_for_delivery',
  },
]

export const milestones = [
  // { type: '', value: '', label: 'Any' },
  // Stuffing
  {
    label: 'Empty container dispatch',
    type: STUFFING,
    value: 'empty_dispatch',
  },
  {
    label: 'Receipt by ocean carrier',
    type: STUFFING,
    value: 'receipt',
  },
  // Pre-Carriage
  {
    label: 'Depart on drayage for export',
    type: PRE_CARRIAGE,
    value: 'export_drayage_depart',
  },
  {
    label: 'Arrive on drayage for export',
    type: PRE_CARRIAGE,
    value: 'export_drayage_arrive',
  },
  {
    label: 'Load on rail for export',
    type: PRE_CARRIAGE,
    value: 'export_rail_load',
  },
  {
    label: 'Depart on rail for export',
    type: PRE_CARRIAGE,
    value: 'export_rail_depart',
  },
  {
    label: 'Arrive on rail for export',
    type: PRE_CARRIAGE,
    value: 'export_rail_arrive',
  },
  {
    label: 'Unload from rail',
    type: PRE_CARRIAGE,
    value: 'export_rail_unload',
  },
  // Port of Loading
  {
    label: 'Load on vessel at origin port',
    type: PORT_OF_LOADING,
    value: 'vessel_load_origin',
  },
  {
    label: 'Depart on vessel from origin port',
    type: PORT_OF_LOADING,
    value: 'vessel_depart_origin',
  },
  // Transshipment
  {
    label: 'Arrive on vessel at transshipment port',
    type: TRANSSHIPMENT,
    value: 'vessel_arrive_transshipment',
  },
  {
    label: 'Discharge from vessel at transshipment port',
    type: TRANSSHIPMENT,
    value: 'vessel_discharge_transshipment',
  },
  {
    label: 'Depart on drayage from transshipment port',
    type: TRANSSHIPMENT,
    value: 'transshipment_drayage_depart',
  },
  {
    label: 'Arrive on drayage from transshipment port',
    type: TRANSSHIPMENT,
    value: 'transshipment_drayage_arrive',
  },
  {
    label: 'Load on vessel at transshipment port',
    type: TRANSSHIPMENT,
    value: 'vessel_load_transshipment',
  },
  {
    label: 'Depart on vessel from transshipment port',
    type: TRANSSHIPMENT,
    value: 'vessel_depart_transshipment',
  },
  // Port of Discharge
  {
    label: 'Arrive on vessel at destination port',
    type: PORT_OF_DISCHARGE,
    value: 'vessel_arrive_destination',
  },
  {
    label: 'Discharge from vessel at destination port',
    type: PORT_OF_DISCHARGE,
    value: 'vessel_discharge_destination',
  },
  // Post-carriage
  {
    label: 'Depart on drayage for import',
    type: ON_CARRIAGE,
    value: 'import_drayage_depart',
  },
  {
    label: 'Arrive on drayage for import',
    type: ON_CARRIAGE,
    value: 'import_drayage_arrive',
  },
  {
    label: 'Load on rail for import',
    type: ON_CARRIAGE,
    value: 'import_rail_load',
  },
  {
    label: 'Depart on rail for import',
    type: ON_CARRIAGE,
    value: 'import_rail_depart',
  },
  {
    label: 'Arrive on rail for import',
    type: ON_CARRIAGE,
    value: 'import_rail_arrive',
  },
  {
    label: 'Unload from rail',
    type: ON_CARRIAGE,
    value: 'import_rail_unload',
  },
  {
    label: 'Unloaded from barge',
    type: ON_CARRIAGE,
    value: 'import_barge_unload',
  },
  {
    label: 'Loaded from barge',
    type: ON_CARRIAGE,
    value: 'import_barge_load',
  },
  // Delivery
  {
    label: 'Available for pickup',
    type: DELIVERY,
    value: 'available_for_delivery',
  },
  {
    label: 'Out for delivery',
    type: DELIVERY,
    value: 'out_for_delivery',
  },
  {
    label: 'At final delivery location',
    type: DELIVERY,
    value: 'at_delivery_location',
  },
  {
    label: 'Empty container return',
    type: DELIVERY,
    value: 'empty_return',
  },
]

export const getMilestoneDescription = val => {
  for (let i = 0; i < milestones.length; i++) {
    let milestone = milestones[i]
    if (milestone.value === val) return milestone.label
  }
}

export const predictedMilestones = [
  {
    label: 'NEW PREDICTED VESSEL LOAD',
    value: 'vessel_load_origin',
    historicalValue: 'LOADED',
  },
  {
    label: 'NEW PREDICTED VESSEL DISCHARGE',
    value: 'vessel_discharge_destination',
    historicalValue: 'DISCHARGED',
  },
  {
    label: 'NEW PREDICTED AVAILABILITY',
    value: 'available_for_delivery',
    historicalValue: 'AVAILABLE',
  },
]
