/**
 * Takes _days_ as an integer and returns JSONLogic. For proper nesting the returned object is expected to replace
 * the integer in the filter config.
 * @param integerDays 
 * @returns object JSONLogic
 * @example
 * ```
 * // Filter config with integer
    const min_latest_milestone_time = val => ({
      operator: '>=',
      key: 'trip.latest_actual_milestone.actual_time',
      val: -8, // this integer needs to be replaces
    })

    // Using this function
    const min_latest_milestone_time = val => ({
      operator: '>=',
      key: 'trip.latest_actual_milestone.actual_time',
      val: integerDaysToTimeDelta(val), // this integer needs to be replaces
    })
  }
 */
const integerDaysToTimeDelta = (integerDays: string | number) => {
  if (isNaN(integerDays as number)) {
    return {}
  }
  return {
    '+': [
      {
        $utc_now: [],
      },
      {
        $timedelta: [['days', parseInt(integerDays as string)]],
      },
    ],
  }
}

export default integerDaysToTimeDelta
