import { Drawer, Typography, makeStyles } from '@material-ui/core'

import AutoComplete from './AutoComplete'
import CloseButton from './CloseButton'
import PropTypes from 'prop-types'
import React from 'react'
import { SIDEBAR_MAX_WIDTH } from 'components/core/Drawer'
import { SIDE_NAV_WIDTH } from 'components/layouts/PageLayout/SideNav'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: SIDE_NAV_WIDTH + SIDEBAR_MAX_WIDTH,
    backgroundColor: theme.palette.grey[50],
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}))

export const SearchDrawer = ({ onClose, open }) => {
  const classes = useStyles()

  return (
    // `disableEnforceFocus` allows focus to go to Intercom when open
    <Drawer
      onClose={onClose}
      disableEnforceFocus={true}
      data-testid="search-drawer"
      open={open}
      classes={{
        paper: classes.root,
      }}
    >
      <span className={classes.titleWrapper}>
        <Typography variant="h5" component="h2">
          Search
        </Typography>
        <CloseButton data-testid="search-drawer__close-button" onClick={onClose} />
      </span>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Search by Order, Line Item, Container, Bill of Lading, Booking, or Shipment.
      </Typography>
      <AutoComplete onSelect={onClose} onInputKeyDownEscape={onClose} />
    </Drawer>
  )
}

SearchDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SearchDrawer
