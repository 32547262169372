import { TripTimelineItem } from 'utils/sharedObjs'
import get from 'lodash/get'
import getPredictedMilestoneInfo from './getPredictedMilestoneInfo'
import getTracedMilestoneInfo from './getTracedMilestoneInfo'
import structureMilestoneDetails from './structureMilestoneDetails'
import structureTimeSummary from './structureTimeSummary'

const structureRouteSegment = (rawSegment, tripIds, milestones, segmentIndex) => {
  const code = get(rawSegment, 'type')
  const location = get(rawSegment, 'details.unlocode')
  const type = get(rawSegment, 'type')
  const details = structureMilestoneDetails(rawSegment, type)
  const fromTime = structureTimeSummary(get(rawSegment, 'from_time'))
  const untilTime = structureTimeSummary(get(rawSegment, 'until_time'))
  const tracedMilestones = getTracedMilestoneInfo(rawSegment, milestones)
  const predictedMilestones = getPredictedMilestoneInfo(rawSegment, milestones)
  const traced = Boolean(get(fromTime, 'traced.min') || get(untilTime, 'traced.min'))
  const containerTripIds = tripIds
  return new TripTimelineItem(
    code,
    location,
    type,
    details,
    fromTime,
    untilTime,
    containerTripIds,
    traced,
    tracedMilestones,
    predictedMilestones,
    segmentIndex
  )
}

export default structureRouteSegment
