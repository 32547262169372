import { Vessel } from 'utils/sharedObjs'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'

const getVessels = routes => {
  let vessels = []
  for (let i = 0; i < routes.length; i++) {
    let route = routes[i]
    const legs = get(route, 'milestones', [])
    legs.forEach(leg => {
      if (leg.type === 'vessel') {
        if (findIndex(vessels, vessel => vessel.name === get(leg, 'details.name')) === -1) {
          // vessel not in array
          vessels.push(new Vessel(get(leg, 'details.name'), get(leg, 'details.lloyds_code')))
        }
      }
    })
  }
  return vessels
}

export default getVessels
