import humps from 'humps'

/**
 * Converts a JS object to a CSS style string. This will not guard against invalid CSS prop values,
 * e.g. { padding: 3 } will convert to { padding: 3 }, not { padding: 3px }, so be sure to convert
 * to the correct value before using this function.
 *
 * @returns { String }
 */
export const objectToStyleString = style =>
  Object.entries(style)
    .reduce(
      (styleString, [propName, propValue]) =>
        `${styleString}${humps.decamelize(propName, { separator: '-' })}: ${propValue}; `,
      ''
    )
    .trim()

export const mentionStyle = theme => ({
  color: theme.palette.blue[400],
  fontWeight: theme.typography.fontWeightMedium,
})

export const PADDING_INLINE_START = '20px'

export const DATA_MENTION_ATTR = 'data-mention-user-id'

export const moveCursorToEndOfEditor = editor => {
  editor.selection.select(editor.getBody(), true)
  editor.selection.collapse(false)
}

export const userDisplayName = ({ email, firstName, lastName }) =>
  firstName && lastName ? `${firstName} ${lastName}` : email.split('@')[0]
