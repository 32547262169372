import { awaitingAuthCheckFromState, isSuperuserFromState } from 'store/auth/user/selectors'

import AuthLayout from 'components/layouts/AuthLayout'
import { LOGIN_ROUTE } from 'utils/routes'
import { Loader } from 'components/Loader/Loader'
import React from 'react'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { loggedInFromState } from 'store/auth/login/selectors'

const authenticatingComponent = props => {
  // AuthLayout will kick off the auth api call
  return (
    <AuthLayout>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Loader style={{ marginTop: 300 }} />
      </div>
    </AuthLayout>
  )
}

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: LOGIN_ROUTE.buildUrl(),
  authenticatedSelector: state => loggedInFromState(state),
  wrapperDisplayName: 'UserIsAuthenticated',
})

export const userIsAdmin = connectedRouterRedirect({
  redirectPath: LOGIN_ROUTE.buildUrl(),
  authenticatedSelector: state => isSuperuserFromState(state),
  authenticatingSelector: state => awaitingAuthCheckFromState(state),
  AuthenticatingComponent: authenticatingComponent,
  wrapperDisplayName: 'UserIsAdmin',
})
