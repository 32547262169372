import { createActions } from 'redux-actions'
import fromPairs from 'lodash/fromPairs'

export const FILTER_GROUPS_KEY = 'filterGroups'

export const DASHBOARD_FILTER_KEY = 'dashboard'
export const PLANNING_FILTER_KEY = 'planning'
export const BOARD_FILTER_KEY = 'board'
export const ALL_FILTER_KEYS = [DASHBOARD_FILTER_KEY, PLANNING_FILTER_KEY, BOARD_FILTER_KEY]

export const makeFilterGroupTypes = filterKey => ({
  APPLY_FILTER: `${FILTER_GROUPS_KEY}/${filterKey}/APPLY_FILTER`,
  CLEAR_FILTER_GROUP: `${FILTER_GROUPS_KEY}/${filterKey}/CLEAR_FILTER_GROUP`,
  CREATE_FILTER_GROUP: `${FILTER_GROUPS_KEY}/${filterKey}/CREATE_FILTER_GROUP`,
  CREATE_FILTER_GROUP_SUCCESS: `${FILTER_GROUPS_KEY}/${filterKey}/CREATE_FILTER_GROUP_SUCCESS`,
  DELETE_FILTER_GROUP: `${FILTER_GROUPS_KEY}/${filterKey}/DELETE_FILTER_GROUP`,
  FETCH_FILTER_GROUPS_START: `${FILTER_GROUPS_KEY}/${filterKey}/FETCH_FILTER_GROUPS_START`,
  FETCH_FILTER_GROUPS_SUCCESS: `${FILTER_GROUPS_KEY}/${filterKey}/FETCH_FILTER_GROUPS_SUCCESS`,
  REMOVE_FILTER: `${FILTER_GROUPS_KEY}/${filterKey}/REMOVE_FILTER`,
  SELECT_FILTER_GROUP: `${FILTER_GROUPS_KEY}/${filterKey}/SELECT_FILTER_GROUP`,
  UPDATE_FILTER_GROUP_NAME: `${FILTER_GROUPS_KEY}/${filterKey}/UPDATE_FILTER_GROUP_NAME`,
  UPDATE_FILTER_GROUP: `${FILTER_GROUPS_KEY}/${filterKey}/UPDATE_FILTER_GROUP`,
  UPDATE_DATE_RANGE: `${FILTER_GROUPS_KEY}/${filterKey}/UPDATE_DATE_RANGE`,
})

const actions = {
  APPLY_FILTER: (operator, key, val, orGroup) => ({ operator, key, val, orGroup }),
  REMOVE_FILTER: event => event,
  SELECT_FILTER_GROUP: event => event,
  CLEAR_FILTER_GROUP: event => event,
  CREATE_FILTER_GROUP: event => event,
  DELETE_FILTER_GROUP: (id, name) => ({ id, name }),
  UPDATE_FILTER_GROUP: event => event,
  UPDATE_FILTER_GROUP_NAME: event => event,
  FETCH_FILTER_GROUPS_START: event => event,
  UPDATE_DATE_RANGE: event => event,
  CREATE_FILTER_GROUP_SUCCESS: event => event,
}

export const filterGroupActions = createActions({
  [FILTER_GROUPS_KEY]: fromPairs(ALL_FILTER_KEYS.map(filterKey => [filterKey, actions])),
})
