import {
  API_DELIMITER,
  getDateDisplayVal,
  getDateParam,
  getLocationDisplayVal,
  getRangeDisplayVal,
  getRangeParam,
} from 'utils/filterUtils'
import { commonMilestones, milestones } from 'store/containerTableData/milestones'

import get from 'lodash/get'
import { toTitleCase } from 'utils/strUtils'

// Filter Groups
export const FILTER_GROUP_ENTITIES = 'Entities'
export const FILTER_GROUP_MILESTONES = 'Events'
export const FILTER_GROUP_PREDICTIONS = 'Predictions'
export const FILTER_GROUP_LOCATIONS = 'Locations'

// Table Column Configuration
export const defaultShipmentFilters = [
  {
    name: 'order_type',
    title: 'Order Type',
    filterData: {
      allowEdit: false,
      getUrlParam: val => ({ order_type: val }),
      getDisplayVal: val => `${toTitleCase(val)} Order`,
    },
  },
  {
    name: 'container_number',
    title: 'Container',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Container #',
      getUrlParam: val => ({ container_numbers: val.join(API_DELIMITER) }),
      getDisplayVal: val => val.join(', '),
      valueKey: 'label',
    },
  },
  {
    name: 'ocean_booking_carrier_reference',
    title: 'Booking',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Booking #',
      getUrlParam: val => ({ booking_numbers: val.join(API_DELIMITER) }),
      getDisplayVal: val => val.join(', '),
    },
  },
  {
    name: 'bill_of_lading_number',
    title: 'Bill of Lading',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Bill of Lading #',
      getUrlParam: val => ({ bol_numbers: val.join(API_DELIMITER) }),
      getDisplayVal: val => val.join(', '),
    },
  },
  {
    name: 'sales_order_number',
    title: 'Sales Order',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Sales Order #',
      // note we are using `order_numbers` here (should change to `ref_numbers` with upcoming API changes)
      getUrlParam: val => ({ order_numbers: val.join(API_DELIMITER) }),
      getDisplayVal: val => val.join(', '),
    },
  },
  {
    name: 'purchase_order_number',
    title: 'Purchase Order',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Purchase Order #',
      // note we are using `order_numbers` here (should change to `ref_numbers` with upcoming API changes)
      getUrlParam: val => ({ order_numbers: val.join(API_DELIMITER) }),
      getDisplayVal: val => val.join(', '),
    },
  },
  {
    name: 'stock_transfer_order_number',
    title: 'Stock Transfer Order',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Stock Transfer Order #',
      // note we are using `order_numbers` here (should change to `ref_numbers` with upcoming API changes)
      getUrlParam: val => ({ order_numbers: val.join(API_DELIMITER) }),
      getDisplayVal: val => val.join(', '),
    },
  },
  {
    name: 'line_item_number',
    title: 'Line Item',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Line Item #',
      getUrlParam: val => ({
        line_item_numbers: val.join(API_DELIMITER),
      }),
      getDisplayVal: val => val.join(', '),
    },
  },
  {
    name: 'shipment',
    title: 'Shipment',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'dialog',
      hintText: 'Shipment #',
      getUrlParam: val => ({
        shipment_numbers: val.join(API_DELIMITER),
      }),
      getDisplayVal: val => val.join(', '),
    },
  },
  {
    name: 'vessel',
    title: 'Vessel',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'multitext',
      hintText: 'Vessel',
      listHintText: 'Enter a list of vessels',
      getUrlParam: val => ({
        vessels: val.map(item => item.value).join(API_DELIMITER),
      }),
      getDisplayVal: val => val.map(item => item.label).join(', '),
      lookupPath: '/vessels/suggest',
    },
    disallowArbitrary: true,
  },
  {
    name: 'ocean_carrier',
    title: 'Ocean Carrier',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'multitext',
      hintText: 'Carrier',
      listHintText: 'Enter a list of carrier SCAC codes',
      getUrlParam: val => {
        let ocean_carriers = []
        val.forEach(item => {
          // check if data section exist since backend return carrier info in new schema
          if (get(item, 'data', null)) {
            ocean_carriers.push(item.data.scac_code)
          } else {
            ocean_carriers.push(item.value)
          }
        })

        return { ocean_carriers: ocean_carriers.join(API_DELIMITER) }
      },
      getDisplayVal: val => val.map(item => item.label).join(', '),
      suggestType: 'ocean_carrier',
      lookupPath: '/carriers/suggest',
    },
  },
  {
    name: 'tenant',
    title: 'Partner',
    filterData: {
      group: FILTER_GROUP_ENTITIES,
      type: 'multitext',
      hintText: 'Partner',
      listHintText: 'Partners',
      getUrlParam: val => ({
        tenants: val.map(item => item.value).join(API_DELIMITER),
      }),
      getDisplayVal: val => val.map(item => item.label).join(', '),
      lookupPath: '/tenants/suggest',
    },
  },
  {
    name: 'ocean_delay',
    title: 'Ocean Delay',
    filterData: {
      group: FILTER_GROUP_PREDICTIONS,
      type: 'range',
      getUrlParam: getRangeParam('min_ocean_delay', 'max_ocean_delay'),
      getDisplayVal: getRangeDisplayVal,
    },
  },
  {
    name: 'pred_load_time',
    title: 'Load at Origin',
    filterData: {
      group: FILTER_GROUP_PREDICTIONS,
      type: 'daterange',
      relativeTextQualifier: 'days from now',
      getUrlParam: getDateParam('min_pred_load', 'max_pred_load'),
      getDisplayVal: getDateDisplayVal('days from now'),
    },
  },
  {
    name: 'pred_discharge_time',
    title: 'Discharge at Dest.',
    filterData: {
      group: FILTER_GROUP_PREDICTIONS,
      type: 'daterange',
      relativeTextQualifier: 'days from now',
      getUrlParam: getDateParam('min_pred_discharge', 'max_pred_discharge'),
      getDisplayVal: getDateDisplayVal('days from now'),
    },
  },
  {
    name: 'pred_available_time',
    title: 'Available for Pickup',
    filterData: {
      group: FILTER_GROUP_PREDICTIONS,
      type: 'daterange',
      relativeTextQualifier: 'days from now',
      getUrlParam: getDateParam('min_pred_available', 'max_pred_available'),
      getDisplayVal: getDateDisplayVal('days from now'),
    },
  },
  {
    name: 'pred_at_delivery_time',
    title: 'At Delivery Location',
    filterData: {
      group: FILTER_GROUP_PREDICTIONS,
      type: 'daterange',
      relativeTextQualifier: 'days from now',
      getUrlParam: getDateParam('min_pred_at_delivery', 'max_pred_at_delivery'),
      getDisplayVal: getDateDisplayVal('days from now'),
    },
  },
  {
    name: 'latest_milestone_type',
    title: 'Current Milestone Type',
    activeTitle: 'Milestones',
    filterData: {
      group: FILTER_GROUP_MILESTONES,
      type: 'multiselect',
      options: milestones,
      commonOptions: commonMilestones,
      getUrlParam: val => ({
        latest_milestones: val.map(item => item.value).join(API_DELIMITER),
      }),
      getDisplayVal: val => val.map(item => item.label).join(', '),
    },
  },
  {
    name: 'latest_milestone_time',
    title: 'Current Milestone Time',
    filterData: {
      group: FILTER_GROUP_MILESTONES,
      type: 'daterange',
      relativeType: 'past',
      getUrlParam: getDateParam('min_latest_milestone_time', 'max_latest_milestone_time'),
      getDisplayVal: getDateDisplayVal('days ago'),
    },
  },
  {
    name: 'latest_milestone_location',
    title: 'Current Milestone Location',
    filterData: {
      group: FILTER_GROUP_MILESTONES,
      type: 'location',
      hintText: 'Location or UN/LOCODE',
      getUrlParam: val => {
        const joinedVals = val.value.map(item => item['value']).join(API_DELIMITER)
        return val.type === 'country'
          ? { latest_countries: joinedVals }
          : { latest_locations: joinedVals }
      },
      getDisplayVal: getLocationDisplayVal,
      cityPath: '/locations/suggest',
      countryPath: '/countries/suggest',
    },
  },
  {
    name: 'completed_milestones',
    title: 'Completed Milestones',
    activeTitle: 'Milestones',
    filterData: {
      group: FILTER_GROUP_MILESTONES,
      type: 'multiselect',
      options: milestones,
      commonOptions: commonMilestones,
      getUrlParam: val => ({
        traced_milestones: val.map(item => item.value).join(API_DELIMITER),
      }),
      getDisplayVal: val => val.map(item => item.label).join(', '),
    },
  },
  {
    name: 'receipt_location',
    title: 'Receipt Location',
    filterData: {
      group: FILTER_GROUP_LOCATIONS,
      type: 'location',
      hintText: 'Location or UN/LOCODE',
      getUrlParam: val => {
        const joinedVals = val.value.map(item => item['value']).join(API_DELIMITER)
        return val.type === 'country'
          ? { receipt_countries: joinedVals }
          : { receipt_locations: joinedVals }
      },
      getDisplayVal: getLocationDisplayVal,
      cityPath: '/locations/suggest',
      countryPath: '/countries/suggest',
    },
  },
  {
    name: 'load_location',
    title: 'Load Location',
    filterData: {
      group: FILTER_GROUP_LOCATIONS,
      type: 'location',
      hintText: 'Location or UN/LOCODE',
      getUrlParam: val => {
        const joinedVals = val.value.map(item => item['value']).join(API_DELIMITER)
        return val.type === 'country'
          ? { load_countries: joinedVals }
          : { load_locations: joinedVals }
      },
      getDisplayVal: getLocationDisplayVal,
      cityPath: '/locations/suggest',
      countryPath: '/countries/suggest',
    },
  },
  {
    name: 'transshipment_locations',
    title: 'Transshipment Locations',
    filterData: {
      group: FILTER_GROUP_LOCATIONS,
      type: 'multitext',
      hintText: 'Enter a list of UN/LOCODES',
      getUrlParam: val => ({
        transshipment_locations: val.map(item => item.value).join(API_DELIMITER),
      }),
      getDisplayVal: val => val.map(item => item.label).join(', '),
      lookupPath: '/locations/suggest',
    },
  },
  {
    name: 'discharge_location',
    title: 'Discharge Location',
    filterData: {
      group: FILTER_GROUP_LOCATIONS,
      type: 'location',
      hintText: 'Location or UN/LOCODE',
      getUrlParam: val => {
        const joinedVals = val.value.map(item => item['value']).join(API_DELIMITER)
        return val.type === 'country'
          ? { discharge_countries: joinedVals }
          : { discharge_locations: joinedVals }
      },
      getDisplayVal: getLocationDisplayVal,
      cityPath: '/locations/suggest',
      countryPath: '/countries/suggest',
    },
  },
  {
    name: 'delivery_location',
    title: 'Delivery Location',
    filterData: {
      group: FILTER_GROUP_LOCATIONS,
      type: 'location',
      hintText: 'Location or UN/LOCODE',
      getUrlParam: val => {
        const joinedVals = val.value.map(item => item['value']).join(API_DELIMITER)
        return val.type === 'country'
          ? { delivery_countries: joinedVals }
          : { delivery_locations: joinedVals }
      },
      getDisplayVal: getLocationDisplayVal,
      cityPath: '/locations/suggest',
      countryPath: '/countries/suggest',
    },
  },
]
