import { OAuthStatusInterface, OAuthStatusState } from './interfaces'

import { handleActions } from 'redux-actions'
import types from './actions'

const initialState: OAuthStatusState = {
  data: undefined,
  isLoading: false,
  isFailed: false,
}

const oauthStatus = handleActions<OAuthStatusState, any>(
  {
    [types.OAUTH_STATUS_LOAD]: (state: OAuthStatusState) => {
      return { ...state, isLoading: true }
    },
    [types.OAUTH_STATUS_FAIL]: (state: OAuthStatusState) => {
      return { ...state, isLoading: false, isFailed: true }
    },
    [types.OAUTH_STATUS_SUCCESS]: (
      state: OAuthStatusState,
      action: { payload: OAuthStatusInterface }
    ) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isFailed: false,
      }
    },
    [types.OAUTH_STATUS_RESET]: (state: OAuthStatusState) => {
      return {
        ...state,
        ...initialState,
      }
    },
  },
  initialState
)

export default oauthStatus
