import { Typography, withStyles } from '@material-ui/core'

import LocationType from './types/LocationType'
import { NO_DATA } from '../utils'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { formatLocationLabel } from 'utils/strUtils'
import { formatPercentage } from 'utils/strUtils'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

/* TO BE REPLACED BY core/Colors after PR #726 */
const LOCATION_CODE_GRAY = '#93999F'

const styles = theme => ({
  locationName: {},
  locationCode: {
    fontWeight: theme.typography.fontWeightLight,
    color: LOCATION_CODE_GRAY,
    marginBottom: 3,
  },
  rollMetricBadge: {
    background: theme.palette.yellow[300],
    padding: '4px 3px',
    flexBasis: 42,
    marginRight: 5,
    textAlign: 'center',
    borderRadius: 2,
  },
  rollMetricZeroBadge: {
    background: theme.palette.grey[200],
  },
  rollMetric: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.3,
  },
  rollMetricInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  rollMetricLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.4,
  },
  rollMetricValue: {
    color: theme.palette.grey[500],
    lineHeight: 1.4,
  },
  rollEmptyValue: {
    marginTop: 5,
  },
})

const LocationInfo = withStyles(styles)(({ classes, location, rollMetric = {} }) => {
  if (!Boolean(location)) {
    return null
  }

  const renderRollMetric = () => {
    // No data at all means we don't want to show anything (likely Via or To locations)
    if (isEmpty(rollMetric)) {
      return null
    }

    const hasNoRollsData = isNil(rollMetric.notRolledCount) || isNil(rollMetric.percentage)

    return (
      <div className={classes.rollMetric}>
        {!hasNoRollsData ? (
          <>
            <div
              className={classnames({
                [classes.rollMetricBadge]: true,
                [classes.rollMetricZeroBadge]: !rollMetric.rolledCount,
              })}
            >
              {formatPercentage(rollMetric.percentage, 1)}
            </div>
            <div className={classes.rollMetricInfo}>
              <Typography component="span" className={classes.rollMetricLabel} variant="caption">
                Roll Rate
              </Typography>
              <Typography component="span" className={classes.rollMetricValue} variant="caption">
                {rollMetric.rolledCount || 0} Total Rolls
              </Typography>
            </div>
          </>
        ) : (
          <Typography
            component="span"
            className={classnames([classes.rollMetricValue, classes.rollEmptyValue])}
            variant="caption"
          >
            No Rolls Data
          </Typography>
        )}
      </div>
    )
  }

  return (
    <>
      <Typography
        className={classes.locationName}
        component="span"
        display="block"
        data-testid="location.name"
        variant="body2"
      >
        {formatLocationLabel(location.name || NO_DATA)}
      </Typography>
      <Typography
        component="span"
        display="block"
        data-testid="location.code"
        className={classes.locationCode}
        variant="body2"
      >
        {location.unlocode || NO_DATA}
      </Typography>
      {renderRollMetric(rollMetric)}
    </>
  )
})

LocationInfo.propTypes = {
  location: LocationType,
  classes: PropTypes.object,
  rollMetric: PropTypes.object,
}

export default LocationInfo
