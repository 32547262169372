import EquipmentCategory from 'store/models/definitions/EquipmentCategory'
import MilestoneAction from 'store/models/definitions/MilestoneAction'
import { MilestoneInterface } from 'store/models/Milestone'
import TripStage from 'store/models/definitions/TripStage'
import last from 'lodash/last'

const getOriginMilestone = (milestones: MilestoneInterface[] = []) =>
  last(
    milestones.filter(
      m =>
        m.action === MilestoneAction.load &&
        m.stopSegment?.tripSegment?.stage === TripStage.pre_main &&
        m.onEquipment?.category === EquipmentCategory.vessel
    )
  )

export default getOriginMilestone
