import { Box, Theme, makeStyles } from '@material-ui/core'

import PopoverText from 'components/PopoverText'
import React from 'react'
import Reaction from 'components/ProductFeedback/Reaction'

const useStyles = makeStyles((theme: Theme) => ({
  reactionRoot: {
    display: 'flex',
  },
  reactionButtonGroup: {
    marginLeft: theme.spacing(1),
  },
  feedbackPromptText: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

interface Props {
  anchorEl: HTMLElement | null
  handleClose: () => void
}

const UnknownStatusPopover = (props: Props) => {
  const classes = useStyles(props)
  const { anchorEl, handleClose } = props

  return (
    <PopoverText
      anchorEl={anchorEl}
      bodyContent={
        <>
          We expected the carrier to send an update for the next milestone, but they haven’t.
          <Box mt={3} mb={0.5}>
            The event may not have occurred yet or the carrier forgot to report it. This label will
            go away when we receive a carrier update.
          </Box>
          <Box mt={3} mb={0.5}>
            If you’re a new customer, this may be resolved once we ingest all of your data.
          </Box>
        </>
      }
      footerContent={
        <Box display="flex" justifyContent="center">
          <Reaction
            classes={{
              buttonGroup: classes.reactionButtonGroup,
              reactionPrompt: classes.reactionRoot,
              feedbackPromptText: classes.feedbackPromptText,
            }}
            componentUnderFeedback="Outdated Prediction"
            feedbackPromptText="Was this helpful?"
            loggerLabel="Outdated Prediction Reaction"
          />
        </Box>
      }
      handleClose={handleClose}
      headerContent={<>Unknown Status</>}
    />
  )
}

export default UnknownStatusPopover
