import { Typography, withStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'

const styles = theme => ({
  filter: {
    display: 'flex',
    border: '2px solid rgb(180, 184, 189)',
    backgroundColor: 'white',
    padding: 12,
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
    borderRadius: theme.spacing(0.5),
    marginBottom: 10,
  },
  filterText: {
    maxHeight: 120,
    overflow: 'auto',
    display: 'block',
    fontSize: theme.typography.pxToRem(13),
    textAlign: 'left',
  },
  filterTextWrapper: {
    alignSelf: 'flex-start',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  filterName: {
    letterSpacing: 0.2,
    color: theme.palette.grey[300],
    fontSize: 12,
  },
  displayVal: {
    color: '#393c3e',
  },
})

class Filter extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    displayData: PropTypes.object.isRequired,
  }

  render() {
    const { displayData, classes } = this.props
    const getDisplayVal = get(displayData, 'filterConfig.filterData.getDisplayVal', val => val)
    const displayVal = getDisplayVal(displayData.value)
    const title = get(displayData, 'filterConfig.title')
    return (
      <div key={`filter-item-${String(displayData.name)}`} className={classes.filter}>
        <div className={classes.filterTextWrapper}>
          <div className={classes.filterText}>
            <Typography variant="body1" className={classes.filterName}>
              {title}
            </Typography>
            <Typography variant="caption" className={classes.displayVal}>
              {displayVal}
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Filter)
