import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeEvery } from 'redux-saga/effects'

import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import MESSAGES from 'store/notifications/messages'
import client from 'utils/api/client'
import { createAction } from 'redux-actions'
import logger from 'utils/logger'

export const ALERT_EMAIL_SHARE_BTTN_QUERY_SOURCE = 'alert-email__share-bttn'

// ------------------------------------
// Constants
// ------------------------------------
const ALERT_EMAIL_LOAD = 'auth/invite/ALERT_EMAIL_LOAD'
export const ALERT_EMAIL_SUCCESS = 'auth/invite/ALERT_EMAIL_SUCCESS'
export const ALERT_EMAIL_ERROR = 'auth/invite/ALERT_EMAIL_ERROR'

// ------------------------------------
// Actions
// ------------------------------------
export const sendAlertEmail = createAction(
  ALERT_EMAIL_LOAD,
  (tenant, email, forceAll = false, forceUnscheduled = false) => ({
    tenant,
    email,
    forceAll,
    forceUnscheduled,
  })
)

// ------------------------------------
// Action Handlers
// ------------------------------------

export const ALERT_EMAIL_ACTION_HANDLERS = {
  [ALERT_EMAIL_LOAD]: (state, action) => ({
    ...state,
    alertEmail: {
      ...state.invite,
      loading: true,
    },
  }),
  [ALERT_EMAIL_SUCCESS]: (state, action) => ({
    ...state,
    alertEmail: {
      ...state.invite,
      error: false,
      loading: false,
    },
  }),
  [ALERT_EMAIL_ERROR]: (state, action) => ({
    ...state,
    alertEmail: {
      ...state.invite,
      error: true,
      loading: false,
    },
  }),
}

// ------------------------------------
// Selectors
// ------------------------------------

export const alertEmailLoadingFromState = state => state[AUTH_REDUCER_KEY].alertEmail.loading
export const alertEmailErrorFromState = state => state[AUTH_REDUCER_KEY].alertEmail.error

// ------------------------------------
// Saga
// ------------------------------------
export function* alertEmailAsync(action) {
  try {
    const { tenant, email, forceAll, forceUnscheduled } = action.payload
    yield call(client.post, '/alerts/notify', {
      tenant,
      email,
      force_all: forceAll,
      force_unscheduled: forceUnscheduled,
    })
    yield put({ type: ALERT_EMAIL_SUCCESS })
    yield put(showNotification(MESSAGES.alertEmailSuccess, { type: MESSAGE_TYPES.SUCCESS }))
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: ALERT_EMAIL_ERROR })
    yield put(showNotification(MESSAGES.alertEmailError, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* watchAlertEmail() {
  yield takeEvery(ALERT_EMAIL_LOAD, alertEmailAsync)
}
