import React from 'react'
import { SvgIcon } from '@material-ui/core'

const VOCIcon = ({ ...props }) => (
  <SvgIcon viewBox={'0 0 32 32'} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4H4v9.01h2V6h7V4Zm16.49 9.12-9-5a1 1 0 0 0-1 0l-9 5A1 1 0 0 0 10 14v10a1 1 0 0 0 .52.87l9 5A1 1 0 0 0 20 30a1.05 1.05 0 0 0 .49-.13l9-5A1 1 0 0 0 30 24V14a1 1 0 0 0-.51-.88ZM19 27.3l-7-3.89v-7.72l7 3.89v7.72Zm1-9.45L13.06 14 20 10.14 26.94 14 20 17.85Zm8 5.56-7 3.89v-7.72l7-3.89v7.72Z"
    />
  </SvgIcon>
)

export default VOCIcon
