import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'

export const types = {
  PASSWORD_RESET_CONFIRMATION_LOAD: `${AUTH_REDUCER_KEY}/password_reset/PASSWORD_RESET_CONFIRMATION_LOAD`,
  PASSWORD_RESET_CONFIRMATION_SUCCESS: `${AUTH_REDUCER_KEY}/password_reset/PASSWORD_RESET_CONFIRMATION_SUCCESS`,
}

export const resetPassword = createAction(
  types.PASSWORD_RESET_CONFIRMATION_LOAD,
  (email, password, pwdToken) => ({
    email,
    password,
    pwdToken,
  })
)
