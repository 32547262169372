import Button from '@material-ui/core/Button'
import DetailRadio from 'components/DetailRadio'
import DetailRadioItem from 'components/DetailRadio/DetailRadioItem'
import MultiSelectAutocomplete from 'components/MultiSelectAutocomplete'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

class MultiTextFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      warning: '',
      value: props.value || [],
      inputValue: props.value ? props.value.map(v => v.label).join(',') : '',
    }
  }

  handleSubmit = e => {
    const { onSubmit, onRequestClose } = this.props
    e.preventDefault()
    if (this.state.value.length > 0) {
      onSubmit(this.state.value)
      onRequestClose()
    } else {
      this.setState({
        warning: 'Please select an item',
      })
    }
  }

  handleChange = value => {
    this.setState({
      value,
    })
  }

  handleListChange = e => {
    const values = e.target.value.split(/[\n;,]/) // split on newline, semicolon, or comma
    const validVals = values.filter(item => item.replace(/\s/g, '').length) // Strip out strings consisting of spaces
    const trimmedValues = validVals.map(item => item.trim()) // remove spaces
    this.setState({
      value: trimmedValues.map(item => ({ label: item, value: item })),
      inputValue: e.target.value,
    })
  }

  render() {
    const { filterConfig, classes } = this.props
    const { inputValue, warning } = this.state
    const placeholder = get(filterConfig, 'filterData.hintText')
    const listPlaceholder = get(filterConfig, 'filterData.listHintText', placeholder)
    const helperText = get(
      filterConfig,
      'filterData.helperText',
      'Lists can be comma, semicolon, or line separated.'
    )
    const lookupPath = get(filterConfig, 'filterData.lookupPath')
    const suggestType = get(filterConfig, 'filterData.suggestType')
    let content = (
      <React.Fragment>
        <DetailRadio>
          <DetailRadioItem label={<span className={classes.label}>Search</span>} value={'search'}>
            <div className={classes.searchWrapper}>
              <MultiSelectAutocomplete
                lookupPath={lookupPath}
                suggestType={suggestType}
                onChange={this.handleChange}
                placeholder={placeholder}
                defaultValue={this.state.value}
              />
            </div>
          </DetailRadioItem>
          {!filterConfig.disallowArbitrary && (
            <DetailRadioItem
              label={<span className={classes.label}>Input a list</span>}
              value={'list'}
            >
              <div className={classes.leftCenter}>
                <TextField
                  onChange={this.handleListChange}
                  multiline
                  rows={3}
                  rowsMax={3}
                  value={inputValue}
                  className={classes.listTextField}
                  type="text"
                  placeholder={listPlaceholder}
                  helperText={helperText}
                  name={filterConfig.name}
                  InputProps={{
                    classes: {
                      underline: classes.noUnderline,
                    },
                  }}
                  required
                />
              </div>
            </DetailRadioItem>
          )}
        </DetailRadio>
      </React.Fragment>
    )
    if (!lookupPath) {
      content = (
        <div className={classes.container}>
          <TextField
            onChange={this.handleListChange}
            multiline
            rows={3}
            rowsMax={3}
            value={inputValue}
            className={classes.listTextField}
            type="text"
            placeholder={listPlaceholder}
            helperText={helperText}
            name={filterConfig.name}
            InputProps={{
              classes: {
                underline: classes.noUnderline,
              },
            }}
            required
          />
        </div>
      )
    }
    return (
      <div className={classes.container}>
        {content}
        {warning && (
          <Typography variant="body2" className={classes.warning}>
            {this.state.warning}
          </Typography>
        )}
        <Button className={classes.submitBtn} onClick={this.handleSubmit}>
          Apply
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(MultiTextFilter)
