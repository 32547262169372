import { types } from './actions'

export const LOGOUT_ACTION_HANDLERS = {
  [types.LOGOUT_LOAD]: (state, action) => ({
    ...state,
    login: {
      ...state.login,
      loading: true,
    },
  }),
  [types.LOGOUT_SUCCESS]: (state, action) => ({
    ...state,
    login: {
      ...state.login,
      loggedIn: false,
      loading: false,
    },
    impersonate: {
      isImpersonating: false,
    },
  }),
  [types.LOGOUT_FAILURE]: (state, action) => ({
    ...state,
    login: {
      ...state.login,
      loggedIn: false, // still log them out on failure
      loading: false,
    },
  }),
}
