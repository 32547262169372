import { MenuItem, Paper, TextField, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

import { CancelToken } from 'axios'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import client from 'utils/api/client'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isString from 'lodash/isString'
import logger from 'utils/logger'

const styles = theme => ({
  container: {
    position: 'relative',
  },
  textField: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  suggestionsWrapper: {
    position: 'relative',
  },
  info: {
    width: '100%',
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoMessage: {
    maxWidth: '80%',
    margin: '0 auto',
  },
  suggestions: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    maxHeight: 200,
    overflow: 'auto',
    width: '100%',
    flex: 1,
  },
})

function defaultRenderInput(inputProps) {
  const { classes, autoFocus, value, ref, ...other } = inputProps
  return (
    <TextField
      ref="input"
      autoFocus={autoFocus}
      className={classes.textField}
      value={value}
      inputRef={ref}
      InputProps={{
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  )
}

function defaultRenderSuggestion(params) {
  const { suggestion, index, itemProps, theme, highlightedIndex, selectedItem } = params
  const isHighlighted = highlightedIndex === index
  const isSelected = selectedItem === suggestion

  return (
    <MenuItem
      {...itemProps}
      key={`filter-suggestion-${suggestion.label}-${index}`}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular,
      }}
    >
      {suggestion.label}
    </MenuItem>
  )
}

function renderSuggestionsContainer(options) {
  const { containerProps, classes, children } = options
  return (
    <div className={classes.suggestionsWrapper}>
      <Paper {...containerProps} className={classes.suggestions} square>
        {children}
      </Paper>
    </div>
  )
}

const defaultItemToString = item => (isString(item) ? item : get(item, 'label', ''))
const defaultItemValue = item => get(item, 'value', '')

class AsyncAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
      isLoading: false,
      error: null,
      inputValue: props.defaultValue,
    }
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    lookupPath: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    renderSuggestion: PropTypes.func,
    renderInput: PropTypes.func,
    itemToString: PropTypes.func,
    getItemValue: PropTypes.func,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    renderSuggestion: defaultRenderSuggestion,
    renderInput: defaultRenderInput,
    itemToString: defaultItemToString,
    getItemValue: defaultItemValue,
    defaultValue: '',
  }

  _cancelRequest = null

  debouncedInputHandler = debounce(async (inputValue, stateAndHelpers) => {
    // When input value changes, clear downshift's selectedItem. Otherwise, when input loses focus, our this.itemToString
    // function will override the inputValue because 'item' won't be null
    if (stateAndHelpers.selectedItem) {
      stateAndHelpers.setState({
        selectedItem: null,
      })
    }

    const { lookupPath, suggestType } = this.props
    try {
      this.setState({ isLoading: true, selectedItem: null })
      if (this._cancelRequest) {
        this._cancelRequest()
      }

      const cancelToken = new CancelToken(c => {
        this._cancelRequest = c
      })
      const params = { q: inputValue }
      if (suggestType) params.suggest_type = suggestType
      const res = await client.get(lookupPath, { params, cancelToken })
      this.setState({ suggestions: res.data })
    } catch (err) {
      logger.captureAPIException(err)
      logger.localLog(err, 'error')
    } finally {
      this.setState({ isLoading: false })
    }
  }, 300)

  handleInputValueChange = (inputValue, stateAndHelpers) => {
    this.setState({
      inputValue,
      error: null,
    })
    this.debouncedInputHandler(inputValue, stateAndHelpers)
  }

  renderBody = renderProps => {
    const { isLoading, error } = this.state
    const { classes, theme, placeholder, id, renderSuggestion, renderInput } = this.props
    const { getInputProps, getItemProps, isOpen, selectedItem, highlightedIndex } = renderProps

    const getSuggestionContent = () => {
      if (error) {
        return (
          <div className={classes.info}>
            <Typography align="center" className={classes.infoMessage} variant="body2">
              There was an error fetching suggestions. Please try again.
            </Typography>
          </div>
        )
      }

      return this.state.suggestions.map((suggestion, index) =>
        renderSuggestion({
          suggestion,
          index,
          theme,
          itemProps: getItemProps({ item: suggestion }),
          highlightedIndex,
          selectedItem,
        })
      )
    }

    const inputProps = getInputProps({ classes, selectedItem, isLoading, placeholder, id })
    const input = renderInput(inputProps)

    return (
      <div className={classes.container}>
        {input}
        {isOpen
          ? renderSuggestionsContainer({
              classes,
              children: getSuggestionContent(),
            })
          : null}
      </div>
    )
  }

  componentDidUpdate = prevProps => {
    // if we receive a new default value, update input to reflect that new default value
    if (
      prevProps.defaultValue !== this.props.defaultValue &&
      (this.props.defaultValue !== this.props.getItemValue(this.state.selectedItem) ||
        (this.props.defaultValue === '' && !this.state.selectedItem))
    ) {
      this.setState({
        inputValue: this.props.defaultValue,
      })
    }
  }

  handleSelect = item => {
    if (item) {
      this.setState({
        inputValue: this.itemToString(item),
        selectedItem: item,
      })
      this.props.onChange(item)
    }
  }

  itemToString = item => {
    if (!item) return this.state.inputValue
    return this.props.itemToString(item)
  }

  render() {
    return (
      <Downshift
        render={this.renderBody}
        inputValue={this.state.inputValue}
        defaultInputValue={this.props.defaultValue}
        onInputValueChange={this.handleInputValueChange}
        itemToString={this.itemToString}
        onSelect={this.handleSelect}
      />
    )
  }
}

export default withStyles(styles, { withTheme: true })(AsyncAutocomplete)
