import { PORTAL_USERS_REDUCER_KEY } from 'store/portalUsers/actions'

export const portalUsersSelector = state => state[PORTAL_USERS_REDUCER_KEY].users
export const portalAddUsersFormSelector = state => state[PORTAL_USERS_REDUCER_KEY].form
export const portalFilterInputOptionsSelector = state =>
  state[PORTAL_USERS_REDUCER_KEY].filterInputOptions

export function filterValueSelector(state, filter) {
  const values = state[PORTAL_USERS_REDUCER_KEY].form.filterValues
  return values[filter.name] ? values[filter.name] : []
}
