import { Tooltip, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import ColumnEditor from 'components/ColumnEditor'
import ColumnSelectorIcon from 'components/icons/ColumnSelectorIcon'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import styles from './styles'

class ColumnToggle extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isExternalUser: PropTypes.bool.isRequired,
  }

  state = {
    open: false,
  }

  logEditorToggle = () => {
    const { rollup, isExternalUser } = this.props
    const { open } = this.state

    const msg = `${rollup} | ${isExternalUser ? 'Portal ' : ''}Column Editor Toggled`
    logger.notify(msg, { open: !open })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.logEditorToggle()
  }

  handleOpen = () => {
    this.setState({ open: true })
    this.logEditorToggle()
  }

  render() {
    const { classes, className, onSubmit, isExternalUser, ...rest } = this.props
    const { open } = this.state

    return (
      <div className={className}>
        <Tooltip title="Configure Table Columns">
          <Button className={classes.button} variant="outlinedLightGrey" onClick={this.handleOpen}>
            <ColumnSelectorIcon color="action" />
          </Button>
        </Tooltip>
        <ColumnEditor
          open={open}
          onClose={this.handleClose}
          onSubmit={payload => {
            onSubmit(payload)
            this.handleClose()
          }}
          isExternalUser={isExternalUser}
          {...rest}
        />
      </div>
    )
  }
}

export default compose(
  connect(state => ({ isExternalUser: isExternalUserSelector(state) })),
  withStyles(styles)
)(ColumnToggle)
