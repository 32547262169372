import { Box, Theme, makeStyles } from '@material-ui/core'

import CardOverlay from 'components/core/CardOverlay'
import React from 'react'
import Skeleton from 'components/Skeleton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  contentWrapper: {
    background: theme.palette.grey[100],
    boxShadow: `0 50vh 0 50vh ${theme.palette.grey[100]}`,
    height: '100%',
  },
  row: {
    background: theme.palette.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.shape.borderRadius,
  },
  text: {
    flexDirection: 'column',
  },
  smallGutter: {
    marginBottom: 4,
    marginTop: 4,
  },
  borderCell: {
    alignItems: 'center',
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    flex: '0 0 370px',
    paddingLeft: theme.spacing(1.5),
  },
}))

interface Props {
  isAnimated: boolean
}

function SkeletonRow(props: Props) {
  const { isAnimated } = props
  const classes = useStyles()

  const lowerCircleSize = 24

  return (
    <Box className={classes.contentWrapper} pt={3} px={6}>
      <Box className={classes.row}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box ml={8} className={classes.borderCell} display="flex">
            <Skeleton
              borderRadius="circular"
              height={lowerCircleSize}
              isAnimated={isAnimated}
              width={lowerCircleSize}
            />
            <Box className={classes.text} ml={1.5} py={0.75}>
              <Skeleton
                isAnimated={isAnimated}
                className={classes.smallGutter}
                gutter={false}
                height={16}
                width="120px"
              />
              <Skeleton
                isAnimated={isAnimated}
                className={classes.smallGutter}
                gutter={false}
                height={16}
                width="200px"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

interface JourneyDetailsProps {
  cardOverlayClasses?: Record<string, any>
  classes?: Record<string, string>
  isAnimated: boolean
  message?: string | React.ReactElement<any, string>
  rowCount?: number
}

export default function JourneyDetails(props: JourneyDetailsProps) {
  const { message = '', rowCount = 3, isAnimated = true, cardOverlayClasses = {} } = props
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <div>
        {[...Array(rowCount)].map((_, i) => (
          <SkeletonRow isAnimated={isAnimated} key={i} />
        ))}
      </div>
      {message && (
        <CardOverlay classes={cardOverlayClasses}>
          <>{message}</>
        </CardOverlay>
      )}
    </div>
  )
}
