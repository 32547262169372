import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const P44LogoFullColor = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="47 46 663 230">
      <g>
        <g>
          <path
            d="M81.86,111.33h8.05c13.95,0,20.92,6.82,20.92,20.46v71.51c0,13.49-6.98,20.24-20.92,20.24h-8.05
			c-7.51,0-12.26-2.99-14.26-8.97v40.7H46.91V111.33H67.6v8.97C69.59,114.32,74.34,111.33,81.86,111.33z M90.14,200.08v-65.3
			c0-2.76-0.46-4.56-1.38-5.41c-0.92-0.84-2.76-1.26-5.52-1.26h-8.05c-2.76,0-4.72,0.61-5.87,1.84c-1.14,1.23-1.72,3.3-1.72,6.21
			v62.54c0,2.9,0.58,4.98,1.72,6.21c1.15,1.23,3.11,1.84,5.87,1.84h8.05c2.76,0,4.6-0.42,5.52-1.26
			C89.68,204.65,90.14,202.84,90.14,200.08z"
          />
        </g>
        <g>
          <path
            d="M159.34,111.1h4.6v17.94h-8.28c-9.65,0-14.48,5.67-14.48,17.02v77.49h-20.69V111.33h20.69v13.8
			c1.23-3.99,3.41-7.32,6.55-10C150.87,112.44,154.74,111.1,159.34,111.1z"
          />
        </g>
        <g>
          <path
            d="M192.91,111.33h24.6c14.26,0,21.38,6.82,21.38,20.46v71.51c0,13.49-7.13,20.24-21.38,20.24h-24.6
			c-14.1,0-21.15-6.75-21.15-20.24v-71.51C171.76,118.15,178.8,111.33,192.91,111.33z M218.43,200.31v-65.76
			c0-2.76-0.46-4.6-1.38-5.52c-0.92-0.92-2.76-1.38-5.52-1.38h-12.65c-2.76,0-4.56,0.46-5.4,1.38c-0.85,0.92-1.27,2.76-1.27,5.52
			v65.76c0,2.76,0.42,4.6,1.27,5.52c0.84,0.92,2.64,1.38,5.4,1.38h12.65c2.76,0,4.6-0.46,5.52-1.38
			C217.97,204.91,218.43,203.07,218.43,200.31z"
          />
        </g>
        <g>
          <path
            d="M249.47,232.28V111.33h20.69v123.7c0,13.48-7.12,20.24-21.38,20.24h-10.35v-16.32h4.14c2.76,0,4.6-0.46,5.52-1.39
			C249.01,236.64,249.47,234.87,249.47,232.28z M249.01,89.95V69.48h21.61v20.47H249.01z"
          />
        </g>
        <g>
          <path
            d="M302.12,111.33h22.53c14.41,0,21.61,6.82,21.61,20.46v42.08h-45.3v26.67c0,2.61,0.46,4.37,1.38,5.29
			c0.92,0.92,2.76,1.38,5.52,1.38h10.58c2.76,0,4.6-0.46,5.52-1.38c0.92-0.92,1.38-2.76,1.38-5.52v-9.19h20.24v12.18
			c0,13.49-7.13,20.24-21.38,20.24h-22.07c-14.26,0-21.38-6.75-21.38-20.24v-71.51C280.74,118.15,287.86,111.33,302.12,111.33z
			 M300.97,158.7h24.83v-24.37c0-2.61-0.43-4.37-1.27-5.29c-0.85-0.92-2.64-1.38-5.4-1.38h-11.27c-2.76,0-4.6,0.46-5.52,1.38
			c-0.92,0.92-1.38,2.68-1.38,5.29V158.7z"
          />
        </g>
        <g>
          <path
            d="M421.68,146.74h-20.46v-11.96c0-2.76-0.46-4.56-1.38-5.41c-0.92-0.84-2.76-1.26-5.52-1.26h-9.88
			c-2.76,0-4.6,0.42-5.52,1.26c-0.92,0.85-1.38,2.65-1.38,5.41v65.3c0,2.76,0.46,4.57,1.38,5.41c0.92,0.84,2.76,1.26,5.52,1.26h9.88
			c2.76,0,4.6-0.42,5.52-1.26c0.92-0.84,1.38-2.65,1.38-5.41v-11.95h20.46v15.17c0,13.49-7.21,20.24-21.61,20.24h-21.85
			c-14.25,0-21.38-6.75-21.38-20.24v-71.51c0-13.64,7.13-20.46,21.38-20.46h21.85c14.41,0,21.61,6.82,21.61,20.46V146.74z"
          />
        </g>
        <g>
          <path
            d="M477.25,128.11h-15.41v71.05c0,2.75,0.42,4.56,1.27,5.4c0.84,0.84,2.64,1.26,5.41,1.26h8.74v17.71h-14.71
			c-14.41,0-21.61-6.75-21.61-20.24v-75.19h-10.58v-16.79h10.58V92.25h20.92v19.08h15.41V128.11z"
          />
        </g>
        <path
          fill="#00558B"
          d="M516.33,239.96c43.83,43.82,114.93,44.16,159.18,1.01c0.35-0.34,0.7-0.67,1.05-1.01
		c21.4-21.41,33.2-49.86,33.18-80.12c0.01-30.26-11.78-58.71-33.18-80.11v-0.01c-21.4-21.4-49.85-33.19-80.11-33.19
		c-30.27,0-58.73,11.79-80.13,33.19c-44.16,44.17-44.16,116.04,0,160.23l0,0"
        />
        <polygon
          fill="#FFFFFF"
          points="578.74,140.92 558.19,140.92 558.19,172.14 527.95,172.14 559.18,92.13 538.16,92.13 507.62,171.87 
		507.62,191.4 558.19,191.4 558.19,223.65 578.74,223.65 578.74,191.4 591.97,191.4 591.97,172.14 578.74,172.14 	"
        />
        <polygon
          fill="#FFFFFF"
          points="670.06,140.92 649.5,140.92 649.5,172.14 619.26,172.14 650.51,92.13 629.49,92.13 598.96,171.87 
		598.96,191.4 649.5,191.4 649.5,223.65 670.06,223.65 670.06,191.4 683.3,191.4 683.3,172.14 670.06,172.14 	"
        />
      </g>
    </SvgIcon>
  )
}

export default P44LogoFullColor
