import { Checkbox, List, ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'

import DragHandleIcon from 'components/icons/DragHandle'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles'

const DragHandle = sortableHandle(() => <DragHandleIcon />)

const SortableItem = sortableElement(({ children, ...rest }) => (
  <ListItem {...rest}>{children}</ListItem>
))

const SortableList = sortableContainer(({ children, dense }) => (
  <List dense={dense}>{children}</List>
))

class DraggableMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
      })
    ).isRequired,
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),
    onToggle: PropTypes.func,
    onSortEnd: PropTypes.func,
  }

  static defaultProps = {
    columns: [],
    hiddenColumns: [],
  }

  render() {
    const { classes, columns, onToggle, onSortEnd, hiddenColumns } = this.props

    return (
      <SortableList
        className={classes.container}
        helperClass={classes.sortableList}
        onSortEnd={onSortEnd}
        useDragHandle
        dense
      >
        {columns.map((column, index) => {
          const hidden = hiddenColumns.includes(column.title)

          return (
            <SortableItem
              onClick={e => {
                onToggle(column)
              }}
              button
              className={classes.listItem}
              classes={{ root: classes.item }}
              key={column.key}
              index={index}
            >
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                {<Checkbox color="primary" aria-label="Toggle visibility" checked={!hidden} />}
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classnames({ [classes.hidden]: hidden }) }}
                primary={column.title}
              />
              <DragHandle />
            </SortableItem>
          )
        })}
      </SortableList>
    )
  }
}

export default withStyles(styles)(DraggableMenu)
