import React, { useState } from 'react'

import Button from 'components/core/Button'
import PropTypes from 'prop-types'
import SaveViewDialog from './SaveViewDialog'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  buttonContainer: {
    display: 'inline-block',
  },
  button: {
    display: 'flex',
    fontSize: 16,
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    borderColor: theme.palette.grey[200],
    textTransform: 'none',
  },
})

const SaveView = ({
  classes,
  createView,
  currentView,
  drawerKey,
  filters,
  isAlertSupported,
  totalCount,
  updateView,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const text = Boolean(get(currentView, 'id')) ? 'Update Filters' : 'Save Filter'
  const disabled = Boolean(get(currentView, 'id')) && filters.length === 0

  return (
    <>
      <div className={classes.buttonContainer} data-testid="view-list__save-view">
        <Button
          fullWidth
          className={classes.button}
          color="primary"
          onClick={openModal}
          size="small"
          variant="outlined"
          disabled={disabled}
        >
          {text}
        </Button>
      </div>
      <SaveViewDialog
        createView={createView}
        currentView={currentView}
        drawerKey={drawerKey}
        filters={filters}
        isAlertSupported={isAlertSupported}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        totalCount={totalCount}
        updateView={updateView}
      />
    </>
  )
}

SaveView.propTypes = {
  classes: PropTypes.object.isRequired,
  createView: PropTypes.func.isRequired,
  currentView: PropTypes.object,
  drawerKey: PropTypes.string,
  filters: PropTypes.array.isRequired,
  isAlertSupported: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  updateView: PropTypes.func.isRequired,
}

SaveView.defaultProps = {
  isAlertSupported: true,
}
export default withStyles(styles)(SaveView)
