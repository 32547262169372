import { call, put, takeLatest } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import OAuthStatus from 'store/oauthStatus/models'
import { ReactElement } from 'react'
import api from 'store/oauthStatus/api'
import get from 'lodash/get'
import logger from 'utils/logger'
import { setLoginErrorMessage } from 'store/auth/login/actions'
import types from 'store/oauthStatus/actions'

export function* loadOauthStatusAsync(action: { type: string; payload: { username: string } }) {
  try {
    const res = yield call(api.getOauthStatus, action.payload.username)
    const data = (res?.data && OAuthStatus.of(res.data)) || {}
    yield put({ type: types.OAUTH_STATUS_SUCCESS, payload: data })
    yield put(setLoginErrorMessage(null))
  } catch (err) {
    logger.captureAPIException(err)

    const status = get(err, 'response.status')
    let msg: string | ReactElement = MESSAGES.oauthStatusError

    if (status === 401) {
      msg = MESSAGES.invalidUsername
    } else if (status === 403) {
      msg = MESSAGES.loginActivationError
    } else if (status === 429) {
      msg = MESSAGES.maxConsecutiveFailedLogins
    }

    yield put(setLoginErrorMessage(msg))
    yield put({ type: types.OAUTH_STATUS_FAIL, payload: { error: err.message } })
  }
}

export function* watchLoadOauthStatus() {
  yield takeLatest(types.OAUTH_STATUS_LOAD, loadOauthStatusAsync)
}
