export default theme => ({
  container: {
    marginTop: 12,
  },
  emptyMessage: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.body2.fontSize,
    margin: '1rem 16px',
  },
  expansionDetailRoot: {
    padding: 0,
  },
  expansionRoot: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingBottom: 12,
  },
  expansionWrapper: {
    maxHeight: '35vh',
    width: '100%',
    overflowX: 'hidden',
  },
  expansionSummaryContent: {
    margin: '0 0 6px 0 !important',
  },
  expansionSummaryRoot: {
    minHeight: '30px !important',
    paddingLeft: 14,
    margin: 0,
  },
  collapseList: {
    marginTop: -10,
  },
  collapseHeading: {
    fontSize: theme.typography.body2.fontSize,
  },
  collapseButton: {
    color: theme.palette.grey[500],
    minHeight: 30, // needed for rows w/o icons to match min-height of rows with icons
    paddingBottom: 0,
    paddingRight: 25,
    paddingTop: 0,
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.blue[900],
    },
  },
})
