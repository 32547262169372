import AdminHomePage from './AdminHomePage'
import { connect } from 'react-redux'
import { emailFromState } from 'store/auth/user/selectors'
import { switchTenant } from 'store/auth/tenant/actions'

const mapDispatchToProps = {
  switchTenant,
}

const mapStateToProps = state => {
  return {
    email: emailFromState(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHomePage)
