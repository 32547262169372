import { call, put, takeLatest } from 'redux-saga/effects'

import CustomFields from 'store/customFields/models'
import api from 'store/customFields/api'
import logger from 'utils/logger'
import { types } from 'store/customFields/actions'

export function* fetchCustomFieldsAsync(action: {
  type: string
  entityType: string
  externalId: string
}) {
  try {
    const res = yield call(api.fetchCustomFields, action.entityType, action.externalId)
    const data = (res?.data && CustomFields.of({ customFields: res.data.data })) || {}
    yield put({ type: types.FETCH_CUSTOM_FIELDS_SUCCESS, payload: data })
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.FETCH_CUSTOM_FIELDS_FAILURE, payload: { error: err.message } })
  }
}

export function* watchFetchCustomFields() {
  yield takeLatest(types.FETCH_CUSTOM_FIELDS_START, fetchCustomFieldsAsync)
}
