import { getFormattedMomentDateTime, getLocalizedMoment } from 'utils/dateUtils'

import humps from 'humps'

const DATE_FORMAT = 'DD MMM YYYY'

export const TripStage = {
  PRE_MAIN: 'pre_main',
  MAIN: 'main',
  POST_MAIN: 'post_main',
}

export const TripContext = {
  IMPORT: 'import',
  EXPORT: 'export',
}

export const MilestoneAction = {
  ARRIVE: 'arrive',
  UNLOAD: 'unload',
  LOAD: 'load',
  DEPART: 'depart',
}

// TODO: Milestone status is TBD at the moment.
export const MilestoneStatus = {
  AT_DELIVERY_LOCATION: 'at_delivery_location',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  AVAILABLE: 'available',
  EMPTY_RETURN: 'empty_return',
  EMPTY_DISPATCH: 'empty_dispatch',
}

export const EquipmentActivity = {
  DISPATCH_RETURN: 'dispatch_return',
  STUFFING_EMPTYING: 'stuffing_emptying',
  TRANSPORT: 'transport',
}

export const EquipmentCategory = {
  //vehicle: 'vehicle'
  //non_powered: 'non_powered'
  CONTAINER: 'multimodal_container',
  TRAIN: 'train',
  VESSEL: 'vessel',
  BARGE: 'barge',
  TRUCK: 'truck',
  PLANE: 'plane',
  CHASSIS: 'chassis',
  PALLET: 'pallet',
  RAILCAR: 'railcar',
  TRAILER: 'trailer',
  UNMAPPED: 'unmapped_equipment',
}

export const TransportationMode = {
  WATER: 'water',
  BARGE: 'barge',
  RAIL: 'rail',
  ROAD: 'road',
  AIR: 'air',
}

export const FacilityType = {
  CONTAINER_YARD: 'cy',
  DOOR: 'door',
  RAIL_YARD: 'ramp',
  PORT: 'port',
}

export class Milestone {
  constructor(
    action,
    equipment,
    location,
    locationZone,
    milestoneType,
    onEquipment,
    plannedTime,
    predictedTime,
    stopSegment,
    stopType,
    tracedTime,
    firstPlannedTime
  ) {
    this.action = action
    this.equipment = equipment
    this.location = location
    this.milestoneType = milestoneType
    this.onEquipment = onEquipment
    this.plannedTime = plannedTime
    this.predictedTime = predictedTime
    this.stopSegment = stopSegment
    this.stopType = stopType
    this.timezone = locationZone?.timezone
    this.tracedTime = tracedTime
    this.firstPlannedTime = firstPlannedTime
    // computed
    const momentDateTime = getLocalizedMoment(tracedTime, locationZone?.timezone)
    this.formattedCurrentMilestone = getFormattedMomentDateTime(momentDateTime, DATE_FORMAT)
  }

  static of(payload) {
    const {
      action,
      equipment,
      location,
      locationZone,
      milestoneType,
      onEquipment,
      plannedTime,
      predictedTime,
      stopSegment,
      stopType,
      tracedTime,
      firstPlannedTime,
    } = humps.camelizeKeys(payload)
    return new Milestone(
      action,
      equipment && Equipment.of(equipment),
      location && Location.of(location),
      locationZone,
      milestoneType,
      onEquipment && Equipment.of(onEquipment),
      plannedTime,
      predictedTime,
      stopSegment && StopSegment.of(stopSegment),
      stopType,
      tracedTime,
      firstPlannedTime
    )
  }

  toState() {
    return {
      ...this,
      action: this.action && this.action.toState(),
      equipment: this.equipment && this.equipment.toState(),
      location: this.location && this.location.toState(),
      milestoneType: this.milestoneType && this.milestoneType.toState(),
      onEquipment: this.onEquipment && this.onEquipment.toState(),
      plannedTime: this.plannedTime && this.plannedTime.toISOString(),
      predictedTime: this.predictedTime && this.predictedTime.toISOString(),
      stopSegment: this.stopSegment && this.stopSegment.toState(),
      stopType: this.stopType && this.stopType.toState(),
      timezone: this.timezone && this.timezone.toState(),
      tracedTime: this.tracedTime && this.tracedTime.toISOString(),
      firstPlannedTime: this.firstPlannedTime && this.firstPlannedTime.toISOString(),
    }
  }
}

export class Equipment {
  constructor(id, name, number, type, category) {
    this.id = id
    this.name = name
    this.number = number
    this.type = type
    this.category = category
  }

  static of({ id, name, number, type, category }) {
    return new Equipment(id, name, number, type, category)
  }

  toState() {
    return {
      ...this,
    }
  }
}

export const DelayStatus = {
  NO_DATA: 'NO_DATA',
  EARLY: 'EARLY',
  ON_SCHEDULE: 'ON_SCHEDULE',
  DELAYED: 'DELAYED',
  MAJOR_DELAY: 'MAJOR_DELAY',
}

export const TransportStatus = {
  NO_DATA: 'NO_DATA',
  NOT_STARTED: 'NOT_STARTED',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED',
}

export class TripStatus {
  constructor(delayStatus, transportStatus, timeDelta, latestStatusUpdate, isStale) {
    this.delayStatus = delayStatus
    this.transportStatus = transportStatus
    this.timeDelta = timeDelta
    this.latestStatusUpdate = latestStatusUpdate
    this.isStale = isStale
  }

  static of(payload) {
    const {
      delayStatus,
      transportStatus,
      timeDelta,
      latestStatusUpdate,
      isStale,
    } = humps.camelizeKeys(payload)
    return new TripStatus(delayStatus, transportStatus, timeDelta, latestStatusUpdate, isStale)
  }

  toState() {
    return {
      ...this,
    }
  }
}

export class EquipmentType {
  constructor(name, description) {
    this.name = name
    this.description = description
  }

  static of(payload) {
    const { name, description } = humps.camelizeKeys(payload)
    return EquipmentType(name, description)
  }

  toState() {
    return {
      ...this,
    }
  }
}

export class Location {
  constructor(id, name, unlocode, lat, lon) {
    this.id = id
    this.name = name
    this.unlocode = unlocode
    this.lat = lat
    this.lon = lon
  }

  static of(payload) {
    const { id, name, unlocode, lat, lon } = humps.camelizeKeys(payload)
    return new Location(id, name, unlocode, lat, lon)
  }

  toState() {
    return {
      ...this,
    }
  }
}

export class Carrier {
  constructor(id, name, scac) {
    this.id = id
    this.name = name
    this.scac = scac
  }

  static of(payload) {
    const { id, name, scac } = humps.camelizeKeys(payload)
    return new Carrier(id, name, scac)
  }

  toState() {
    return {
      ...this,
    }
  }
}

export class StopSegment {
  constructor(equipmentActivity, facilityType, tripSegment) {
    this.equipmentActivity = equipmentActivity
    this.facilityType = facilityType
    this.tripSegment = tripSegment
  }

  static of(payload) {
    const { equipmentActivity, facilityType, tripSegment } = humps.camelizeKeys(payload)
    return new StopSegment(equipmentActivity, facilityType, tripSegment)
  }

  toState() {
    return {
      ...this,
    }
  }
}

export class TripReference {
  constructor(id, equipmentNumber, externalId) {
    this.id = id
    this.equipmentNumber = equipmentNumber
    this.externalId = externalId
  }

  static of(payload) {
    const { id, equipmentNumber, externalId } = humps.camelizeKeys(payload)
    return new TripReference(id, equipmentNumber, externalId)
  }

  toState() {
    return {
      ...this,
    }
  }
}

export class TripReferencePage {
  constructor(data, totalRecords, limit, offset) {
    this.data = data
    this.totalRecords = totalRecords
    this.limit = limit
    this.offset = offset
  }

  static of(payload) {
    const { data, totalRecords, limit, offset } = humps.camelizeKeys(payload)
    return new TripReferencePage(
      data && data.map(d => TripReference.of(d)),
      totalRecords,
      limit,
      offset
    )
  }

  toState() {
    return {
      ...this,
      data: this.data && this.data.map(x => x.toState()),
    }
  }
}

export class TripSummary {
  constructor(
    id,
    status,
    equipment,
    carrier,
    latestMilestone,
    receiptMilestone,
    loadMilestone,
    transshipmentMilestones,
    dischargeMilestone,
    deliveryMilestone,
    vehicles,
    externalId
  ) {
    this.id = id
    this.status = status
    this.equipment = equipment
    this.carrier = carrier
    this.latestMilestone = latestMilestone
    this.receiptMilestone = receiptMilestone
    this.loadMilestone = loadMilestone
    this.transshipmentMilestones = transshipmentMilestones
    this.dischargeMilestone = dischargeMilestone
    this.deliveryMilestone = deliveryMilestone
    this.vehicles = vehicles
    this.externalId = externalId
    // @todo temporarily adding legacy key, this will go away
    this.external_id = externalId
  }

  static of(payload) {
    const {
      id,
      status,
      equipment,
      carrier,
      latestMilestone,
      receiptMilestone,
      loadMilestone,
      transshipmentMilestones,
      dischargeMilestone,
      deliveryMilestone,
      vehicles,
      externalId,
    } = humps.camelizeKeys(payload)
    return new TripSummary(
      id,
      status && TripStatus.of(status),
      equipment && Equipment.of(equipment),
      carrier && Carrier.of(carrier),
      latestMilestone && Milestone.of(latestMilestone),
      receiptMilestone && Milestone.of(receiptMilestone),
      loadMilestone && Milestone.of(loadMilestone),
      transshipmentMilestones && transshipmentMilestones.map(tm => Milestone.of(tm)),
      dischargeMilestone && Milestone.of(dischargeMilestone),
      deliveryMilestone && Milestone.of(deliveryMilestone),
      vehicles && vehicles.map(v => Equipment.of(v)),
      externalId
    )
  }

  toState() {
    return {
      ...this,
      status: this.status && this.status.toState(),
      equipment: this.equipment && this.equipment.toState(),
      carrier: this.carrier && this.carrier.toState(),
      latestMilestone: this.latestMilestone && this.latestMilestone.toState(),
      receiptMilestone: this.receiptMilestone && this.receiptMilestone.toState(),
      loadMilestone: this.loadMilestone && this.loadMilestone.toState(),
      transshipmentMilestones:
        this.transshipmentMilestones && this.transshipmentMilestones.map(tm => tm.toState()),
      dischargeMilestone: this.dischargeMilestone && this.dischargeMilestone.toState(),
      deliveryMilestone: this.deliveryMilestone && this.deliveryMilestone.toState(),
      vehicles: this.vehicles && this.vehicles.map(v => v.toState()),
      externalId: this.externalId,
    }
  }
}

/**
 * Model for Timelines
 */
export class TripTimeline {
  constructor(attr) {
    // TODO: implementation pending design/product analysis of timelines
    this.attr = attr
  }

  static of(attr) {
    return new TripTimeline(attr)
  }

  toState() {
    return this.attr
  }
}

export class TripDetail {
  constructor(carrier, equipment, externalId, id, milestones, tenants) {
    this.carrier = carrier
    this.equipment = equipment
    this.externalId = externalId
    this.id = id
    this.milestones = milestones
    this.tenants = tenants
  }

  static of(payload) {
    const { carrier, equipment, externalId, id, milestones, tenants } = humps.camelizeKeys(payload)
    return new TripDetail(
      carrier && Carrier.of(carrier),
      equipment && Equipment.of(equipment),
      externalId,
      id,
      milestones && milestones.map(m => Milestone.of(m)),
      tenants
    )
  }

  toState() {
    return {
      ...this,
      carrier: this.carrier && this.carrier.toState(),
      equipment: this.equipment && this.equipment.toState(),
      externalId: this.externalId,
      id: this.id,
      milestones: this.milestones && this.milestones.map(m => m.toState()),
      tenants: this.tenants,
    }
  }
}

export class TripInfo {
  constructor(
    carrier,
    equipment,
    externalId,
    latestMilestone,
    deliveryMilestone,
    dischargeMilestone,
    status,
    tripId
  ) {
    this.carrier = carrier
    this.equipment = equipment
    this.externalId = externalId
    this.latestMilestone = latestMilestone
    this.deliveryMilestone = deliveryMilestone
    this.dischargeMilestone = dischargeMilestone
    this.status = status
    this.tripId = tripId
  }

  static of(payload) {
    const {
      carrier,
      equipment,
      externalId,
      latestMilestone,
      deliveryMilestone,
      dischargeMilestone,
      status,
      tripId,
    } = humps.camelizeKeys(payload)
    return new TripInfo(
      carrier && Carrier.of(carrier),
      equipment && Equipment.of(equipment),
      externalId,
      latestMilestone && Milestone.of(latestMilestone),
      deliveryMilestone && Milestone.of(deliveryMilestone),
      dischargeMilestone && Milestone.of(dischargeMilestone),
      status,
      tripId
    )
  }

  toState() {
    return {
      ...this,
      carrier: this.carrier && this.carrier.toState(),
      equipment: this.equipment && this.equipment.toState(),
      externalId: this.externalId,
      latestMilestone: this.latestMilestone && this.latestMilestone.toState(),
      status: this.status,
      tripId: this.tripId,
    }
  }
}
