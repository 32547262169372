export default theme => ({
  dialogTitle: {
    display: 'flex',
  },
  dialogModal: {
    maxWidth: 550,
    maxHeight: '90%', // without this, IE does not trigger overflow handling on contents
  },
  header: {
    display: 'inline-block',
    flexGrow: '1',
    margin: 'auto',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  hide: {
    visibility: 'hidden',
  },
  submitButton: {
    width: theme.spacing(12),
  },
  border: {
    paddingTop: theme.spacing(3),
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
  },
  additionalContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
})
