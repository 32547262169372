import { Box, Tooltip, withStyles } from '@material-ui/core'

import Checkbox from 'components/core/Checkbox'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
  button: {
    minWidth: 40,
  },
  checkbox: {
    margin: theme.spacing(0.25, 2, 0, 1),
    padding: 0,
  },
})

class BulkSelector extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    onCheck: PropTypes.func,
    onUncheck: PropTypes.func,
    onIndeterminate: PropTypes.func,
    checkboxChecked: PropTypes.bool,
    checkboxIndeterminate: PropTypes.bool,
    tooltipText: PropTypes.string,
  }
  static defaultProps = {
    className: '',
  }

  render() {
    const {
      classes,
      className,
      onCheck,
      onUncheck,
      onIndeterminate,
      checkboxChecked,
      checkboxIndeterminate,
      tooltipText,
    } = this.props

    return (
      <Box className={className} data-testid="table-controls__bulk-selector">
        <Tooltip title={tooltipText}>
          <span>
            <Checkbox
              classes={{ root: classes.checkbox }}
              onCheck={onCheck}
              onUncheck={onUncheck}
              onIndeterminate={onIndeterminate}
              checked={checkboxChecked}
              indeterminate={checkboxIndeterminate}
              color="primary"
            />
          </span>
        </Tooltip>
      </Box>
    )
  }
}

export default withStyles(styles)(BulkSelector)
