import React, { Suspense } from 'react'

import Loader from 'components/Loader'

const lazyLoadWithSuspense = (WrappedComponent, style = {}) => {
  return props => {
    return (
      <Suspense fallback={<Loader style={style} />}>
        <WrappedComponent {...props} />
      </Suspense>
    )
  }
}

export default lazyLoadWithSuspense
