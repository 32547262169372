import { createAction } from 'redux-actions'

export const PORTAL_USERS_REDUCER_KEY = 'portalUsers'

export const types = {
  FETCH_PORTAL_USERS_START: `${PORTAL_USERS_REDUCER_KEY}/FETCH_PORTAL_USERS_START`,
  FETCH_PORTAL_USERS_SUCCESS: `${PORTAL_USERS_REDUCER_KEY}/FETCH_PORTAL_USERS_SUCCESS`,
  FETCH_PORTAL_USERS_ERROR: `${PORTAL_USERS_REDUCER_KEY}/FETCH_PORTAL_USERS_ERROR`,
  DELETE_PORTAL_USER_START: `${PORTAL_USERS_REDUCER_KEY}/DELETE_PORTAL_USER_START`,
  DELETE_PORTAL_USER_SUCCESS: `${PORTAL_USERS_REDUCER_KEY}/DELETE_PORTAL_USER_SUCCESS`,
  CREATE_PORTAL_USERS_START: `${PORTAL_USERS_REDUCER_KEY}/CREATE_PORTAL_USERS_START`,
  CREATE_PORTAL_USERS_SUCCESS: `${PORTAL_USERS_REDUCER_KEY}/CREATE_PORTAL_USERS_SUCCESS`,
  SET_SUBMITTING: `${PORTAL_USERS_REDUCER_KEY}/SET_SUBMITTING`,
  SET_EMAIL_INPUT: `${PORTAL_USERS_REDUCER_KEY}/SET_EMAIL_INPUT`,
  SET_EMAIL_VALUE: `${PORTAL_USERS_REDUCER_KEY}/SET_EMAIL_VALUE`,
  SET_SELECTED_CHIP_ID: `${PORTAL_USERS_REDUCER_KEY}/SET_SELECTED_CHIP_ID`,
  RESET_FORM: `${PORTAL_USERS_REDUCER_KEY}/RESET_FORM`,
  SET_FILTER_INPUT_OPTIONS: `${PORTAL_USERS_REDUCER_KEY}/SET_FILTER_INPUT_OPTIONS`,
  SET_FILTER_VALUE: `${PORTAL_USERS_REDUCER_KEY}/SET_FILTER_VALUE`,
}

export const fetchPortalUsers = createAction(types.FETCH_PORTAL_USERS_START)
export const deletePortalUser = createAction(types.DELETE_PORTAL_USER_START)

export const setSubmitting = createAction(types.SET_SUBMITTING, payload => payload)
export const resetForm = createAction(types.RESET_FORM)

export const setEmailInput = createAction(types.SET_EMAIL_INPUT, emailInput => emailInput)
export const setEmailValue = createAction(types.SET_EMAIL_VALUE, emailValues => emailValues)
export const setSelectedChipId = createAction(
  types.SET_SELECTED_CHIP_ID,
  selectedChipId => selectedChipId
)

export const setFilterInputOptions = createAction(
  types.SET_FILTER_INPUT_OPTIONS,
  payload => payload
)
export const setFilterValue = createAction(types.SET_FILTER_VALUE, payload => payload)

// Notice the plural `emails`, because we can get multiple emails that share everything else
export const createPortalUsers = createAction(
  types.CREATE_PORTAL_USERS_START,
  ({ emails, fields, savedShipmentViewIds }) => ({
    emails,
    fields,
    savedShipmentViewIds,
  })
)
