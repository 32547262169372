export const EMPTY_DISPATCH = 'emptyDispatch'
export const PRE_CARRIAGE = 'preCarriage'
export const PORT_OF_LOAD = 'portOfLoad'
export const TRANSSHIPMENT = 'transshipment'
export const PORT_OF_DISCHARGE = 'portOfDischarge'
export const ON_CARRIAGE = 'onCarriage'
export const EMPTY_RETURN = 'emptyReturn'

const StageGroup = {
  [EMPTY_DISPATCH]: EMPTY_DISPATCH,
  [PRE_CARRIAGE]: PRE_CARRIAGE,
  [PORT_OF_LOAD]: PORT_OF_LOAD,
  [TRANSSHIPMENT]: TRANSSHIPMENT,
  [PORT_OF_DISCHARGE]: PORT_OF_DISCHARGE,
  [ON_CARRIAGE]: ON_CARRIAGE,
  [EMPTY_RETURN]: EMPTY_RETURN,
} as const

export type StageGroupType = typeof StageGroup[keyof typeof StageGroup]

export default StageGroup
