import { createAction } from 'redux-actions'

const PLANNING_REDUCER_KEY = 'planning'

export const types = {
  FETCH_ROUTES_ERROR: `${PLANNING_REDUCER_KEY}/FETCH_ROUTES_ERROR`,
  FETCH_ROUTES_START: `${PLANNING_REDUCER_KEY}/FETCH_ROUTES_START`,
  FETCH_ROUTES_SUCCESS: `${PLANNING_REDUCER_KEY}/FETCH_ROUTES_SUCCESS`,
  FETCH_ROUTES_WITH_METRICS_ERROR: `${PLANNING_REDUCER_KEY}/FETCH_ROUTES_WITH_METRICS_ERROR`,
  FETCH_ROUTES_WITH_METRICS_START: `${PLANNING_REDUCER_KEY}/FETCH_ROUTES_WITH_METRICS_START`,
  FETCH_ROUTES_WITH_METRICS_SUCCESS: `${PLANNING_REDUCER_KEY}/FETCH_ROUTES_WITH_METRICS_SUCCESS`,
  FETCH_ALL_ROUTE_AGGREGATED_METRICS_ERROR: `${PLANNING_REDUCER_KEY}/FETCH_ALL_ROUTE_AGGREGATED_METRICS_ERROR`,
  FETCH_ALL_ROUTE_AGGREGATED_METRICS_START: `${PLANNING_REDUCER_KEY}/FETCH_ALL_ROUTE_AGGREGATED_METRICS_START`,
  FETCH_ALL_ROUTE_AGGREGATED_METRICS_SUCCESS: `${PLANNING_REDUCER_KEY}/FETCH_ALL_ROUTE_AGGREGATED_METRICS_SUCCESS`,
  FETCH_ALL_ROUTE_METRICS_ERROR: `${PLANNING_REDUCER_KEY}/FETCH_ALL_ROUTE_METRICS_ERROR`,
  FETCH_ALL_ROUTE_METRICS_START: `${PLANNING_REDUCER_KEY}/FETCH_ALL_ROUTE_METRICS_START`,
  FETCH_ALL_ROUTE_METRICS_SUCCESS: `${PLANNING_REDUCER_KEY}/FETCH_ALL_ROUTE_METRICS_SUCCESS`,
  FETCH_ROUTE_METRICS_COMPARISON_ERROR: `${PLANNING_REDUCER_KEY}/FETCH_ROUTE_METRICS_COMPARISON_ERROR`,
  FETCH_ROUTE_METRICS_COMPARISON_START: `${PLANNING_REDUCER_KEY}/FETCH_ROUTE_METRICS_COMPARISON_START`,
  FETCH_ROUTE_METRICS_COMPARISON_SUCCESS: `${PLANNING_REDUCER_KEY}/FETCH_ROUTE_METRICS_COMPARISON_SUCCESS`,
  UPDATE_SELECTED_ROUTE_IDS: `${PLANNING_REDUCER_KEY}/UPDATE_SELECTED_ROUTE_IDS`,
  UPDATE_SORT_STATE: `${PLANNING_REDUCER_KEY}/UPDATE_SORT_STATE`,
}

export const getRoutes = createAction(types.FETCH_ROUTES_START)
export const updateSelectedRouteIds = createAction(types.UPDATE_SELECTED_ROUTE_IDS)
export const updateSortState = createAction(types.UPDATE_SORT_STATE)
