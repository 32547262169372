import React from 'react'
import { withStyles } from '@material-ui/core/'

const styles = theme => ({
  mapContainer: {
    position: 'absolute',
    top: theme.spacing(7),
    right: 0,
    left: 0,
    bottom: 0,
  },
})

const mapboxGlAccessToken =
  'pk.eyJ1IjoiZGllZ29jbGVhcm1ldGFsIiwiYSI6ImNpeXV0bWp1ajAwMmMycXBkcGNqMzRxZ2IifQ.Hyc_GrjLpduxKQd4RdDZHw'

class MapRoutes extends React.Component {
  componentDidMount() {
    const {
      circleColor,
      circleOpacity,
      circleRadius,
      dataSource,
      lineColor,
      lineOpacity,
      lineWidth,
      styleUrl,
      showNavigation,
      renderWorldCopies,
    } = this.props

    // dynamically load because mapbox-gl is very large
    import('mapbox-gl').then(mapboxgl => {
      this.map = new mapboxgl.Map({
        attributionControl: false,
        container: this.mapContainer,
        style: styleUrl,
        center: [13.8867, 20.1262],
        zoom: 2,
        // we pass the access token here rather than setting it on the module because the mapboxgl module object
        // is actually read only and writing mapboxgl.accessToken = ... will throw a TypeError
        accessToken: mapboxGlAccessToken,
      })
      this.map.on('load', () => {
        this.map.setRenderWorldCopies(renderWorldCopies)
        if (showNavigation) this.map.addControl(new mapboxgl.NavigationControl())
        this.map.resize()
        this.map.addSource('routes', dataSource)
        // Draw Lines
        this.map.addLayer({
          id: 'routeSegment',
          type: 'line',
          source: 'routes',
          paint: {
            'line-width': parseInt(lineWidth),
            'line-color': lineColor,
            'line-opacity': parseFloat(lineOpacity),
          },
          filter: ['==', '$type', 'LineString'],
        })
        // Draw Points
        this.map.addLayer({
          id: 'routePoint',
          type: 'circle',
          source: 'routes',
          paint: {
            'circle-radius': parseInt(circleRadius),
            'circle-color': circleColor,
            'circle-opacity': parseFloat(circleOpacity),
          },
          filter: ['==', '$type', 'Point'],
        })
      })
    })
  }

  render() {
    const { classes } = this.props
    return <div ref={el => (this.mapContainer = el)} className={classes.mapContainer} />
  }
}

export default withStyles(styles)(MapRoutes)
