import {
  ROW_LIMIT,
  aggregatedAlertsCountSelector,
  aggregatedAlertsCurrPageSelector,
  aggregatedAlertsLoadingSelector,
  aggregatedAlertsPagesSelector,
  aggregatedAlertsSelector,
  resolvedIdxSelector,
  setLastViewed,
  setNewAggregateAlertsCount,
  setPage,
  setResolvedIdx,
  setStateFromUrl,
} from 'store/alerts'
import { Typography, withStyles } from '@material-ui/core'
import { structureAlert, structureAlertConfig } from 'utils/alertUtils'

import AlertOverview from './AlertOverview'
import AlertSearch from './AlertSearch'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { Loader } from 'components/Loader/Loader'
import NotificationIcon from '@material-ui/icons/NotificationsActive'
import Paginator from 'components/core/Paginator'
import PropTypes from 'prop-types'
import React from 'react'
import { alertConfigsSelector } from 'store/api/alertConfigs'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import { fromQueryString } from 'utils/urlBuilder'
import get from 'lodash/get'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

const DATE_FORMAT = 'D MMM YYYY'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  alertDateTitle: {
    marginLeft: theme.spacing(3),
    color: '#939eab',
  },
  notificationIcon: {
    width: 15,
    height: 15,
    color: '#939eab',
    marginBottom: -2,
    marginRight: 5,
  },
  noAlerts: {
    textAlign: 'center',
    marginTop: 100,
  },
  noAlertsText: {
    fontSize: 30,
    color: '#06d6a7',
  },
  checkIcon: {
    color: '#06d6a7',
    width: 45,
    height: 45,
    marginBottom: -10,
    marginLeft: theme.spacing(1),
  },
  loader: {
    marginTop: 200,
  },
  center: {
    textAlign: 'center',
  },
  getStartedWrapper: {
    marginTop: 130,
    textAlign: 'center',
  },
  createAlertButton: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.brand.color,
    width: 178,
  },
  createAlertButtonLabel: {
    color: 'white',
    textTransform: 'none',
    fontSize: 17,
  },
  alertDescription: {
    color: '#7f8285',
    fontSize: 16,
  },
  noAlertsYetText: {
    color: '#393c3e',
    fontSize: 23,
    fontWeight: theme.typography.fontWeightRegular,
  },
  createAlertImg: {
    height: 200,
  },
  noAlertsSubtext: {
    fontSize: 14,
    color: '#7f8285',
  },
  paginateWrapper: {
    marginTop: -3 * theme.spacing(1), // so it doesn't take up vertical space
    marginBottom: -3 * theme.spacing(1), // so it doesn't take up vertical space
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(5),
  },
  dropdown: {
    marginRight: theme.spacing(3),
    marginBottom: 3,
  },
})

class AlertsOverview extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.setStateFromUrl(this.props.match.params.id)

    // Reset new alerts count so that badge disappears
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  componentWillUnmount() {
    this.resetNewAlertVars()
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  resetNewAlertVars = () => {
    this.props.setNewAggregateAlertsCount(0)
    this.props.setLastViewed(moment().utc())
  }

  handleBeforeUnload = () => {
    this.resetNewAlertVars()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const thisQuery = fromQueryString(this.props.location.search)
    const nextQuery = fromQueryString(nextProps.location.search)
    const thisAlertConfigID = this.props.match.params.id
    const nextAlertConfigID = nextProps.match.params.id
    if (
      nextAlertConfigID !== thisAlertConfigID ||
      get(nextQuery, 'latest') !== get(thisQuery, 'latest') ||
      get(nextQuery, 'emailUID') !== get(thisQuery, 'emailUID')
    ) {
      this.props.setStateFromUrl(nextProps.match.params.id)
    }
  }

  handlePageNext = () => {
    const { setPage, currentPage } = this.props
    setPage(currentPage + 1)
  }

  handlePagePrev = () => {
    const { setPage, currentPage } = this.props
    setPage(currentPage - 1)
  }

  getBody = () => {
    const { classes, sortedAlerts, sortedKeys, loading } = this.props
    if (loading) {
      return (
        <div className={classes.center}>
          <Loader className={classes.loader} />
        </div>
      )
    }

    if (sortedKeys.length === 0 && !loading) {
      return (
        <div className={classes.noAlerts}>
          <Typography variant="body2" className={classes.noAlertsText}>
            No new alerts
            <CheckCircle className={classes.checkIcon} />
          </Typography>
          <Typography variant="body2" className={classes.noAlertsSubtext}>
            Looks like there are no alerts matching this configuration. When an alert fires, you'll
            see it here.
          </Typography>
        </div>
      )
    }

    return sortedKeys.map((formattedDate, index) => {
      const alertsByDate = sortedAlerts[formattedDate]
      return (
        <div key={`alert-overview-${index}`}>
          <Typography variant="body2" className={classes.alertDateTitle}>
            <NotificationIcon className={classes.notificationIcon} />
            Alerts triggered {formattedDate}
          </Typography>
          {alertsByDate.map((alertTuple, alertIndex) => {
            const { alert, config } = alertTuple
            return <AlertOverview alert={alert} config={config} key={`alert-${alertIndex}`} />
          })}
        </div>
      )
    })
  }

  handleResolvedChange = (item, idx) => {
    this.props.setResolvedIdx(idx)
  }

  render() {
    const { classes, loading, alertConfigs, totalCount, totalPages, currentPage } = this.props

    if (alertConfigs.length === 0 && !loading) {
      return (
        <div className={classes.getStartedWrapper}>
          <Typography variant="body2" className={classes.noAlertsYetText}>
            Looks like your workspace doesn't have any alerts...yet
          </Typography>
          <Typography variant="body2" className={classes.alertDescription}>
            You can create an alert from your shipments board. When you save a view, you'll be given
            the option to be alerted on changes to your view.
          </Typography>
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <div className={classes.paginateWrapper}>
          <AlertSearch />
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            limit={ROW_LIMIT}
            totalCount={totalCount}
            onPrevClick={this.handlePagePrev}
            onNextClick={this.handlePageNext}
          />
        </div>
        <div>{this.getBody()}</div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setStateFromUrl,
  setPage,
  setResolvedIdx,
  setLastViewed,
  setNewAggregateAlertsCount,
}

function compareMoments(a, b) {
  return a.isBefore(b) ? 1 : -1
}

function structureAlerts(rawAlerts, alertConfigs) {
  let sortedAlerts = {}
  let keys = []
  for (let i = 0; i < rawAlerts.length; i++) {
    let rawAlert = rawAlerts[i]
    let alert = structureAlert(rawAlert)
    const rawConfig = find(alertConfigs, config => config.id === alert.configID)
    const config = structureAlertConfig(rawConfig)
    const date = moment.parseZone(alert.alertedAt)
    const formattedDate = date.format(DATE_FORMAT)
    if (!(formattedDate in sortedAlerts)) {
      sortedAlerts[formattedDate] = []
      keys.push(date)
    }
    sortedAlerts[formattedDate].push({ alert, config, date })
  }

  keys = keys.sort(compareMoments)
  let sortedKeys = keys.map(item => item.format(DATE_FORMAT))

  return { sortedAlerts, sortedKeys }
}

const alertsByDateSelector = createSelector(
  aggregatedAlertsSelector,
  alertConfigsSelector,
  structureAlerts
)

const mapStateToProps = state => {
  const alertConfigs = alertConfigsSelector(state)
  const { sortedAlerts, sortedKeys } = alertsByDateSelector(state)
  const loading = aggregatedAlertsLoadingSelector(state)

  return {
    sortedAlerts,
    sortedKeys,
    loading,
    alertConfigs,
    totalCount: aggregatedAlertsCountSelector(state),
    totalPages: aggregatedAlertsPagesSelector(state),
    currentPage: aggregatedAlertsCurrPageSelector(state),
    resolvedIdx: resolvedIdxSelector(state),
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AlertsOverview)
