import { Box, Theme, makeStyles } from '@material-ui/core'
import {
  EMPTY_DISPATCH,
  EMPTY_RETURN,
  TIMELINE_STAGE_GROUPS,
} from 'utils/milestone/MilestoneGroups/definitions'
import UiGroupInterface, { UiGroupCollectionInterface } from 'store/types/UiGroupInterface'

import React from 'react'
import TimelineSegment from 'components/Timeline/TimelineSegment'
import TripStage from 'store/models/definitions/TripStage'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

const useStyles = makeStyles((theme: Theme) => ({
  timeline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',

    '& > div': {
      flex: 1,
    },
    // Fix the last segment width so that they always line up when multiple timelines
    '& > div:last-child': {
      flex: '0 0 235px',
    },
  },
}))

interface Props {
  groupedMilestones: UiGroupCollectionInterface
  isPublicShare?: boolean
  tripsCount: number | null
}

const Timeline = (props: Props) => {
  const classes = useStyles()
  const { groupedMilestones, isPublicShare = false, tripsCount } = props

  // For the count we don't use empty dispatch or return
  const groupedMilestonesCopy = { ...groupedMilestones }
  const groupCountMilestones = omit(groupedMilestonesCopy, [EMPTY_DISPATCH, EMPTY_RETURN])

  // We need to know the first group with data. The timeline does not include empty dispatch or return
  const groupKeys = groupedMilestones
    ? Object.keys(groupedMilestones).filter(
        group => group !== EMPTY_DISPATCH && group !== EMPTY_RETURN
      )
    : {}
  const firstGroupWithData = find(groupKeys, key => !isEmpty(groupCountMilestones[key]))

  // Gets the total group count, used on the timeline to show/hide vehicle names and info icon
  const groupCount = flatMap(groupCountMilestones).reduce(
    (acc: number, val: UiGroupInterface) => acc + Object.keys(val).length,
    0
  )

  return (
    <Box className={classes.timeline} data-testid="timeline">
      {
        /**
         * We're looping four times here:
         * 1. Stages - pre-main, main, post-main
         * 2. UI Groups: Empty Dispatch, Pre-Carriage, etc. These are arrays of arrays `[ [unlocode] ]
         * 3. Unlocode groups - each unique location for each UI Group (there can be multiples of UI Groups).
         * 4. Milestones for each Unlocode group
         */
        // Stages
        Object.keys(TripStage).map(stage => {
          // UI Groups
          return TIMELINE_STAGE_GROUPS[stage]?.map(
            group =>
              // Unlocode groups
              groupedMilestones &&
              groupedMilestones[group] &&
              Object.values(groupedMilestones[group]).map(
                (unlocodeGroup: UiGroupInterface, idx) => {
                  // Milestones for this unlocode group
                  const locationMilestones = unlocodeGroup.milestones
                  return (
                    locationMilestones.length > 0 && (
                      <React.Fragment key={`unlocode-${idx}`}>
                        <TimelineSegment
                          group={unlocodeGroup}
                          groupCount={groupCount}
                          groupName={group}
                          isPublicShare={isPublicShare}
                          locationZone={
                            locationMilestones ? locationMilestones[0].locationZone : null
                          }
                          milestones={locationMilestones}
                          isFirstGroup={group === firstGroupWithData}
                          tripsCount={tripsCount}
                        />
                      </React.Fragment>
                    )
                  )
                }
              )
          )
        })
      }
    </Box>
  )
}

export default React.memo(Timeline)
