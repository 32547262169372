export const REQUEST_INVITATION_REDUCER_KEY = 'requestInvitation'

export const types = {
  REQUEST_INVITATION_START: `${REQUEST_INVITATION_REDUCER_KEY}/START`,
}

export const fetchRequestInvitation = (username: string) => ({
  type: types.REQUEST_INVITATION_START,
  payload: { username },
})

export default types
