import Button from 'components/core/Button'
import ChevronLeftIcon from 'components/icons/ChevronLeftIcon'
import ChevronRightIcon from 'components/icons/ChevronRightIcon'
import HorizontalButtonGroupLite from 'components/core/HorizontalButtonGroupLite/index'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from '@material-ui/core'

const PaginatorControls = ({ onNextClick, onPrevClick, currentPage, totalPages, hidden }) => {
  const isLeftBtnDisabled = currentPage === 0
  const isRightBtnDisabled = currentPage === totalPages - 1 || totalPages === 0
  if (hidden) return null
  return (
    <div>
      <HorizontalButtonGroupLite>
        <Tooltip id="page-prev" enterDelay={500} title="Previous Page" placement="bottom">
          <span data-testid="paginator__previous-button">
            <Button
              variant="outlinedLightGrey"
              disabled={isLeftBtnDisabled}
              onClick={() => onPrevClick()}
            >
              <ChevronLeftIcon />
            </Button>
          </span>
        </Tooltip>
        <Tooltip id="page-next" enterDelay={500} title="Next Page" placement="bottom">
          <span data-testid="paginator__next-button">
            <Button
              variant="outlinedLightGrey"
              disabled={isRightBtnDisabled}
              onClick={() => onNextClick()}
            >
              <ChevronRightIcon />
            </Button>
          </span>
        </Tooltip>
      </HorizontalButtonGroupLite>
    </div>
  )
}

PaginatorControls.propTypes = {
  classes: PropTypes.object,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  hidden: PropTypes.bool,
}
export default PaginatorControls
