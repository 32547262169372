import { USER_DASHBOARD_KEY, types } from 'store/userDashboard/actions'

import { handleActions } from 'redux-actions'

export { USER_DASHBOARD_KEY }
export const initialState = {
  topLanesByVolume: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
  variableRoutes: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
  totalContainerCountStatus: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
  containerTripPerformance: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
  rollsOverTime: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
  dwellTimes: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
}

export const totalContainerCountStatusSelector = state =>
  state[USER_DASHBOARD_KEY].totalContainerCountStatus
export const topLanesByVolumeSelector = state => state[USER_DASHBOARD_KEY].topLanesByVolume
export const variableRoutesSelector = state => state[USER_DASHBOARD_KEY].variableRoutes
export const containerTripPerformanceSelector = state =>
  state[USER_DASHBOARD_KEY].containerTripPerformance
export const rollsOverTimeSelector = state => state[USER_DASHBOARD_KEY].rollsOverTime
export const dwellTimesSelector = state => state[USER_DASHBOARD_KEY].dwellTimes

export default handleActions(
  {
    [types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_START]: state => ({
      ...state,
      totalContainerCountStatus: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_SUCCESS]: (state, { payload }) => ({
      ...state,
      totalContainerCountStatus: {
        isLoading: false,
        isFailed: false,
        data: payload,
      },
    }),
    [types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_ERROR]: state => ({
      ...state,
      totalContainerCountStatus: {
        isLoading: false,
        isFailed: true,
        data: undefined,
      },
    }),
    [types.FETCH_TOP_LANES_BY_VOLUME_START]: state => ({
      ...state,
      topLanesByVolume: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_TOP_LANES_BY_VOLUME_SUCCESS]: (state, { payload }) => ({
      ...state,
      topLanesByVolume: {
        isLoading: false,
        isFailed: false,
        data: payload,
      },
    }),
    [types.FETCH_TOP_LANES_BY_VOLUME_ERROR]: state => ({
      ...state,
      topLanesByVolume: {
        isLoading: false,
        isFailed: true,
        data: undefined,
      },
    }),
    [types.FETCH_VARIABLE_ROUTES_START]: state => ({
      ...state,
      variableRoutes: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_VARIABLE_ROUTES_SUCCESS]: (state, { payload }) => ({
      ...state,
      variableRoutes: {
        isLoading: false,
        isFailed: false,
        data: payload,
      },
    }),
    [types.FETCH_VARIABLE_ROUTES_ERROR]: state => ({
      ...state,
      variableRoutes: {
        isLoading: false,
        isFailed: true,
        data: undefined,
      },
    }),
    [types.FETCH_CONTAINER_TRIP_PERFORMANCE_START]: state => ({
      ...state,
      containerTripPerformance: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_CONTAINER_TRIP_PERFORMANCE_SUCCESS]: (state, { payload }) => ({
      ...state,
      containerTripPerformance: {
        isLoading: false,
        isFailed: false,
        data: payload,
      },
    }),
    [types.FETCH_CONTAINER_TRIP_PERFORMANCE_ERROR]: state => ({
      ...state,
      containerTripPerformance: {
        isLoading: false,
        isFailed: true,
        data: undefined,
      },
    }),
    [types.FETCH_ROLLS_OVER_TIME_START]: state => ({
      ...state,
      rollsOverTime: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_ROLLS_OVER_TIME_SUCCESS]: (state, { payload }) => ({
      ...state,
      rollsOverTime: {
        isLoading: false,
        isFailed: false,
        data: payload,
      },
    }),
    [types.FETCH_ROLLS_OVER_TIME_ERROR]: state => ({
      ...state,
      rollsOverTime: {
        isLoading: false,
        isFailed: true,
        data: undefined,
      },
    }),
    [types.FETCH_DWELL_TIMES_START]: state => ({
      ...state,
      dwellTimes: {
        isLoading: true,
        isFailed: false,
        data: undefined,
      },
    }),
    [types.FETCH_DWELL_TIMES_SUCCESS]: (state, { payload }) => ({
      ...state,
      dwellTimes: {
        isLoading: false,
        isFailed: false,
        data: payload,
      },
    }),
    [types.FETCH_DWELL_TIMES_ERROR]: state => ({
      ...state,
      dwellTimes: {
        isLoading: false,
        isFailed: true,
        data: undefined,
      },
    }),
  },
  initialState
)
