import { types } from 'store/auth/tokenCheck/actions'

export const TOKEN_CHECK_ACTION_HANDLERS = {
  [types.CHECK_TOKEN_LOAD]: (state, action) => ({
    ...state,
    tokenCheck: {
      ...state.tokenCheck,
      loading: true,
    },
  }),
  [types.CHECK_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    tokenCheck: {
      ...state.tokenCheck,
      loading: false,
      valid: true,
    },
  }),
  [types.CHECK_TOKEN_FAILURE]: (state, action) => ({
    ...state,
    tokenCheck: {
      ...state.tokenCheck,
      loading: false,
      valid: false,
    },
  }),
}
