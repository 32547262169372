import 'moment-fquarter'

import find from 'lodash/find'
import moment from 'moment'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const START_OF_TIME = '2017-01-01'

// Not a fan of this, but it does allow the `DATE_RANGES` object to change
// and these can easily be updated. Of course, easy to get out of sync as well.
export const CUSTOM_DATE_RANGE_LABEL = 'Custom'
export const CUSTOM_DATE_RANGE_ID = 'custom'
export const DEFAULT_DATE_RANGE_ID = 'last_6_months'

export const aggregations = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
}

//
// ─── TIME HELPERS ───────────────────────────────────────────────────────────────
//

const formatRange = ({ startDate, endDate }) => {
  if (startDate.month() === endDate.month()) {
    return `${startDate.format('D')}-${endDate.format('D')} ${moment(startDate).format(
      'MMM'
    )}, ${endDate.year()}`
  } else if (startDate.year() === endDate.year()) {
    return `${startDate.format('D MMM')} - ${endDate.format('D MMM')}, ${startDate.year()}`
  } else {
    return `${startDate.format('D MMM, YYYY')} - ${endDate.format('D MMM, YYYY')}`
  }
}

const isWholeDay = ({ startDate, endDate }) => {
  const daysBetween = moment(endDate).diff(moment(startDate), 'days')
  return daysBetween === 1
}

const isWholeMonth = ({ startDate, endDate }) => {
  const monthsBetween = moment(endDate).diff(moment(startDate), 'months')
  return monthsBetween === 1 && startDate.date() === 1
}

const isWholeYear = ({ startDate, endDate }) => {
  const yearsBetween = moment(endDate).diff(moment(startDate), 'years')
  return yearsBetween === 1 && startDate.month() === 0 && startDate.date() === 1
}

/**
 * This is more of set/get in one. The goal is to have a single source for a date range object.
 * The caller can pass in any of the params and will get back an object that follows the proper shape.
 * If called with no params it returns a "default" object.
 * @param id If not supplied we ignore the other params and return the default
 * @param startDate The startDate if a custom date range
 * @param endDate The endDate if a custom date range
 */
export const getDateRangeObject = ({ id = null, startDate = null, endDate = null } = {}) => {
  // If it's a custom range and we have everything we need, return the custom date object
  if (id === CUSTOM_DATE_RANGE_ID && startDate && endDate) {
    const customDateRange = find(DATE_RANGES, range => range.id === CUSTOM_DATE_RANGE_ID)

    if (customDateRange) {
      return {
        ...customDateRange,
        startDate,
        endDate,
      }
    }
  }

  // If we got an `id` get the range and return
  const dateRangeById = find(DATE_RANGES, range => range.id === id)

  if (dateRangeById && id !== CUSTOM_DATE_RANGE_ID) {
    return {
      ...dateRangeById,
      startDate: null,
      endDate: null,
    }
  }

  // Otherwise, return the default as a fallback, likely better than an error?
  const defaultDateRange = find(DATE_RANGES, range => range.id === DEFAULT_DATE_RANGE_ID)

  return {
    ...defaultDateRange,
    startDate: null,
    endDate: null,
  }
}

/**
 * Finds a date range by `id` and returns that object. If not found, calls `getDateRangeObject()`
 * which returns a default.
 * @param id The `id` to look for
 */
export const getDateRangeById = id => {
  const idx = find(DATE_RANGES, range => range.id === id)
  return idx ? idx : getDateRangeObject()
}

/**
 * Gets the `DateRange` array `index`. Useful when populating a dropdown and supplying the
 * default or selected index.
 * @param id `DateRange` `id`
 */
export const getDateRangeIndexById = id => {
  const index = DATE_RANGES.map(range => range.id).indexOf(id)
  return index !== -1 ? index : null
}

export const getPopulatedDateRange = dateRange => {
  let startTime, endTime

  // If not custom we need to get the start/end dates based on now
  if (dateRange.id !== CUSTOM_DATE_RANGE_ID) {
    const dateRangeById = getDateRangeById(dateRange.id)
    startTime = dateRangeById.getStartDate()
    endTime = dateRangeById.getEndDate()
  } else {
    // Otherwise we'll use those stored
    startTime = dateRange.startDate
    endTime = dateRange.endDate
  }

  // Get a date range
  const dateRangeObject = getDateRangeObject({
    id: dateRange.id,
    startDate: startTime,
    endDate: endTime,
  })

  // `startTime` and `endTime` are expected by the caller. We should standardize
  // but currently there are ~30 uses of these so leaving that as an @todo
  return {
    ...dateRangeObject,
    startTime,
    endTime,
    aggregation: aggregationForRange({ startDate: startTime, endDate: endTime }),
    aggregationLength: dateRange.id === CUSTOM_DATE_RANGE_ID ? 1 : undefined,
  }
}

export const tickLabelForMetric = metric => {
  const startDate = moment(metric.startDate)
  const endDate = moment(metric.endDate)

  if (isWholeYear({ startDate, endDate })) {
    return moment(startDate).format('YYYY')
  } else if (isWholeMonth({ startDate, endDate })) {
    return moment(startDate).format('MMM YYYY')
  } else if (isWholeDay({ startDate, endDate })) {
    return moment(startDate).format('D MMM, YYYY')
  } else {
    return formatRange({ startDate, endDate })
  }
}

export const aggregationForRange = ({ startDate, endDate }) => {
  const numDaysBetween = moment(endDate).diff(moment(startDate), 'days')
  const numMonthsBetween = moment(endDate).diff(moment(startDate), 'months')

  if (numDaysBetween <= 13) {
    return aggregations.DAY
  } else if (numMonthsBetween <= 2) {
    return aggregations.WEEK
  } else if (numMonthsBetween <= 24) {
    return aggregations.MONTH
  } else {
    return aggregations.YEAR
  }
}

const today = () => moment().format(DATE_FORMAT)

const endOfWeek = () => moment().endOf('isoWeek').format(DATE_FORMAT)
const endOfMonth = () => moment().endOf('month').format(DATE_FORMAT)
const endOfYear = () => moment().endOf('year').format(DATE_FORMAT)

// Subtract one since we want to count the current `unit` as one.
const ago = (number, unit, startOf) =>
  moment()
    .subtract(number - 1, unit)
    .startOf(startOf)
    .format(DATE_FORMAT)

const last = unit =>
  unit === 'quarter'
    ? moment(moment().fquarter().start).format(DATE_FORMAT)
    : moment().startOf(unit).format(DATE_FORMAT)

//
// ─── RANGE CONFIGS ──────────────────────────────────────────────────────────────
//

export const DATE_RANGES = [
  // The first element in this array is the default.
  {
    id: 'last_4_weeks',
    label: 'Last 4 Weeks',
    getEndDate: () => endOfWeek(),
    getStartDate: () => ago(4, 'isoWeeks', 'isoWeek'),
  },
  {
    id: 'last_6_weeks',
    label: 'Last 6 Weeks',
    getEndDate: () => endOfWeek(),
    getStartDate: () => ago(6, 'isoWeeks', 'isoWeek'),
  },
  {
    id: 'last_8_weeks',
    label: 'Last 8 Weeks',
    getEndDate: () => endOfWeek(),
    getStartDate: () => ago(8, 'isoWeeks', 'isoWeek'),
  },
  {
    id: 'last_6_months',
    label: 'Last 6 Months',
    getEndDate: () => endOfMonth(),
    getStartDate: () => ago(6, 'months', 'month'),
  },
  {
    id: 'last_12_months',
    label: 'Last 12 Months',
    getEndDate: () => endOfMonth(),
    getStartDate: () => ago(12, 'months', 'month'),
  },
  {
    id: 'all_time',
    label: 'All time',
    getEndDate: () => endOfYear(),
    getStartDate: () => moment(START_OF_TIME).format(DATE_FORMAT),
  },
  {
    id: 'year_to_date',
    label: 'Year to date',
    getEndDate: () => today(),
    getStartDate: () => last('years'),
  },
  {
    id: 'quarter_to_date',
    label: 'Quarter to date',
    getEndDate: () => today(),
    getStartDate: () => last('quarter'),
  },
  {
    id: 'month_to_date',
    label: 'Month to date',
    getEndDate: () => today(),
    getStartDate: () => last('month'),
  },
  {
    id: 'custom',
    label: 'Custom',
  },
]

export const DEFAULT_SELECTED_DATE_RANGE = getDateRangeObject({
  id: DEFAULT_DATE_RANGE_ID,
  startDate: null,
  endDate: null,
})
