const EquipmentCategory = {
  multimodal_container: 'multimodal_container',
  train: 'train',
  vessel: 'vessel',
  barge: 'barge',
  truck: 'truck',
  plane: 'plane',
  chassis: 'chassis',
  pallet: 'pallet',
  railcar: 'railcar',
  trailer: 'trailer',
  unmapped_equipment: 'unmapped_equipment',
  'any value': 'any value',
} as const

export type EquipmentCategoryType = typeof EquipmentCategory[keyof typeof EquipmentCategory]

export default EquipmentCategory
