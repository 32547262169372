import alignRoute from './alignRoute'
import cloneDeep from 'lodash/cloneDeep'

const alignRoutes = routes => {
  // merge routes and right them to route.mergedMilestones
  for (let i = 0; i < routes.length; i++) {
    let route = routes[i]
    route.mergedMilestones = route.milestones
    const routesCopy = cloneDeep(routes)
    routesCopy.splice(i, 1)
    alignRoute(route, routesCopy)
  }
}

export default alignRoutes
