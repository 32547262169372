import React from 'react'
import { SvgIcon } from '@material-ui/core'
import colors from 'components/core/Colors'

export const TimezoneIcon = ({
  width = 50,
  height = 30,
  marginLeft = -16,
  color = colors.grey[600],
  className = '',
}) => {
  return (
    <SvgIcon style={{ width, height, marginLeft }} viewBox={'-10 0 24 24'} className={className}>
      <g id="Shipment/Alert-Wireframe" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="modal_design"
          transform="translate(-126.000000, -170.000000)"
          stroke="#65717D"
          strokeWidth="1.3125"
        >
          <g id="timezone" transform="translate(127.000000, 171.000000)">
            <circle id="Oval" cx="13.65625" cy="13.65625" r="4.75" />
            <polyline id="Shape" points="15.75575 13.65625 13.65625 13.65625 13.65625 11.5575417" />
            <path
              d="M8.30775,18.3270833 C3.73747285,17.7094912 0.394455143,13.7054446 0.602570022,9.09832607 C0.810684899,4.49120751 4.5010958,0.804878277 9.10844183,0.601861906 C13.7157879,0.398845537 17.7161325,3.7462922 18.3286667,8.31725"
              id="Shape"
            />
            <path
              d="M7.35379167,18.1457917 C6.14966667,16.3787917 5.34375,13.1677917 5.34375,9.5 C5.34375,5.83220833 6.14966667,2.622 7.35379167,0.854208333"
              id="Shape"
            />
            <path d="M0.613541667,8.90625 L8.15258333,8.90625" id="Shape" />
            <path d="M2.37420833,4.15625 L16.625,4.15625" id="Shape" />
            <path d="M1.62133333,13.65625 L6.53125,13.65625" id="Shape" />
            <path
              d="M11.6462083,0.854208333 C12.6664336,2.57815767 13.288536,4.50829814 13.4670417,6.50354167"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export const TimeIcon = ({
  width = 50,
  height = 30,
  marginLeft = -16,
  color = colors.grey[600],
  className = '',
}) => {
  return (
    <SvgIcon style={{ width, height, marginLeft }} viewBox={'-10 0 24 24'} className={className}>
      <g
        id="Shipment/Alert-Wireframe"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="modal_design"
          transform="translate(-126.000000, -98.000000)"
          stroke="#65717D"
          strokeWidth="1.3125"
        >
          <g id="time-clock-circle" transform="translate(127.000000, 99.000000)">
            <circle id="Oval" cx="9.5" cy="9.5" r="9.06818182" />
            <path d="M9.5,9.5 L9.5,6.26136364" id="Shape" />
            <path d="M9.5,9.5 L13.5478636,13.5487273" id="Shape" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
