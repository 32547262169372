import { createAction } from 'redux-actions'

export const DOWNLOADS_REDUCER_KEY = 'downloads'

// Action Types
export const types = {
  FETCH_DOWNLOAD_START: `${DOWNLOADS_REDUCER_KEY}/FETCH_DOWNLOAD_START`,
  FETCH_DOWNLOAD_SUCCESS: `${DOWNLOADS_REDUCER_KEY}/FETCH_DOWNLOAD_SUCCESS`,
  FETCH_DOWNLOAD_FAILURE: `${DOWNLOADS_REDUCER_KEY}/FETCH_DOWNLOAD_FAILURE`,
  DELETE_DOWNLOAD_REQUEST: `${DOWNLOADS_REDUCER_KEY}/DELETE_DOWNLOAD_REQUEST`,
  DELETE_DOWNLOAD_START: `${DOWNLOADS_REDUCER_KEY}/DELETE_DOWNLOAD_START`,
  DELETE_DOWNLOAD_SUCCESS: `${DOWNLOADS_REDUCER_KEY}/DELETE_DOWNLOAD_SUCCESS`,
  DELETE_DOWNLOAD_FAILURE: `${DOWNLOADS_REDUCER_KEY}/DELETE_DOWNLOAD_FAILURE`,
}

// Actions
export const requestDeleteDownload = createAction(types.DELETE_DOWNLOAD_REQUEST)
export const deleteDownload = createAction(types.DELETE_DOWNLOAD_START)
export const fetchDownloads = createAction(types.FETCH_DOWNLOAD_START, args => ({
  disableLoading: false,
  ...args,
}))
