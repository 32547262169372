import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { ALERT_DETAIL_ROUTE } from 'utils/routes'
import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import MESSAGES from 'store/notifications/messages'
import browserHistory from 'utils/history'
import client from 'utils/api/client'
import { confirmSaga } from 'store/confirmation'
import { createAction } from 'redux-actions'
import first from 'lodash/first'
import get from 'lodash/get'
import logger from 'utils/logger'
import { selectAlert } from 'store/alerts'

// ------------------------------------
// Constants
// ------------------------------------
const ALERT_CONFIGS_LOAD = 'api/alert_configs/ALERT_CONFIGS_LOAD'
const ALERT_CONFIGS_LOAD_SUCCESS = 'api/alert_configs/ALERT_CONFIGS_LOAD_SUCCESS'
const ALERT_CONFIG_DELETE = 'api/alert_configs/ALERT_CONFIG_DELETE'
const ALERT_CONFIG_DELETE_SUCCESS = 'api/alert_configs/ALERT_CONFIG_DELETE_SUCCESS'
const ALERT_CONFIG_SAVE = 'api/alert_configs/ALERT_CONFIG_SAVE'
export const ALERT_CONFIG_SAVE_SUCCESS = 'api/alert_configs/ALERT_CONFIG_SAVE_SUCCESS'
const ALERT_CONFIG_UPDATE = 'api/alert_configs/ALERT_CONFIG_UPDATE'
const ALERT_CONFIG_UPDATE_SUCCESS = 'api/alert_configs/ALERT_CONFIG_UPDATE_SUCCESS'

export const ALERTS_REDUCER_KEY = 'alert_configs'

// ------------------------------------
// Actions
// ------------------------------------
export const getAlerts = createAction(ALERT_CONFIGS_LOAD)
export const deleteAlert = createAction(ALERT_CONFIG_DELETE, (id, name) => ({ id, name }))
export const saveAlert = createAction(
  ALERT_CONFIG_SAVE,
  (config, notify = true, navigate = true, notifTime = null, notifTimezone = null) => {
    return {
      config,
      notify,
      navigate,
      notifTime,
      notifTimezone,
    }
  }
)
export const updateAlert = createAction(ALERT_CONFIG_UPDATE, (config, notify = true) => ({
  config,
  notify,
}))

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ALERT_CONFIGS_LOAD]: (state, action) => {
    const loading = { ...state.loading, get: true }
    return { ...state, loading }
  },
  [ALERT_CONFIG_UPDATE]: (state, action) => {
    const loading = { ...state.loading, update: true }
    return { ...state, loading }
  },
  [ALERT_CONFIG_DELETE]: (state, action) => {
    const loading = { ...state.loading, delete: true }
    return { ...state, loading }
  },
  [ALERT_CONFIG_SAVE]: (state, action) => {
    const loading = { ...state.loading, save: true }
    return { ...state, loading }
  },
  [ALERT_CONFIGS_LOAD_SUCCESS]: (state, action) => {
    const loading = { ...state.loading, get: false }
    return { ...state, alertConfigs: action.payload, loading }
  },
  [ALERT_CONFIG_DELETE_SUCCESS]: (state, action) => {
    const loading = { ...state.loading, delete: false }
    return { ...state, loading }
  },
  [ALERT_CONFIG_UPDATE_SUCCESS]: (state, action) => {
    const loading = { ...state.loading, update: false }
    return { ...state, loading }
  },
  [ALERT_CONFIG_SAVE_SUCCESS]: (state, action) => {
    const loading = { ...state.loading, save: false }
    return { ...state, loading }
  },
}

const initialState = {
  loading: {
    get: false,
    delete: false,
    save: false,
    update: false,
  },
  alertConfigs: [],
}
// ------------------------------------
// Reducer
// ------------------------------------
export function alertConfigsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Selectors
// ------------------------------------

export const alertConfigsSelector = state => state[ALERTS_REDUCER_KEY].alertConfigs
export const alertConfigsLoadingSelector = state => get(state[ALERTS_REDUCER_KEY], 'loading.get')
export const deleteAlertConfigLoading = state => get(state[ALERTS_REDUCER_KEY], 'loading.delete')
export const saveAlertConfigLoading = state => get(state[ALERTS_REDUCER_KEY], 'loading.save')
export const updateAlertConfigLoading = state => get(state[ALERTS_REDUCER_KEY], 'loading.update')

// ------------------------------------
// Saga
// ------------------------------------

function* getAlertConfigsAsync(action) {
  try {
    const response = yield call(client.get, '/shipment_alerts/config')
    yield put({ type: ALERT_CONFIGS_LOAD_SUCCESS, payload: response.data })
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

function getNotifSettings(config) {
  if (config) {
    if ('notif_time' in config && 'notif_timezone' in config) {
      const { notif_time, notif_timezone } = config
      return { notif_time, notif_timezone }
    }
  }
  return {
    notif_time: null,
    notif_timezone: null,
  }
}

function* saveAlertConfigAsync(action) {
  try {
    let { config, notify, navigate, notifTime, notifTimezone } = action.payload
    const name = get(config, 'name')
    const shipmentViewId = get(config, 'shipmentViewId')
    const payload = get(config, 'payload')

    const configs = yield select(alertConfigsSelector)
    const firstConfig = first(configs)
    if (!notifTime && !notifTimezone) {
      const { notifTimeFromSettings, notifTimezoneFromSettings } = getNotifSettings(firstConfig)
      if (notifTimeFromSettings) {
        notifTime = notifTimeFromSettings
      }
      if (notifTimezoneFromSettings) {
        notifTimezone = notifTimezoneFromSettings
      }
    }

    let data = {
      name,
      shipment_view_id: shipmentViewId,
      payload,
    }

    if (notifTime) data['notif_time'] = notifTime
    if (notifTimezone) data['notif_timezone'] = notifTimezone

    const response = yield call(client.post, '/shipment_alerts/config', data)
    const id = get(response.data, 'id')
    yield call(getAlertConfigsAsync, { payload: {} }) // reload alerts
    if (navigate) yield browserHistory.push(ALERT_DETAIL_ROUTE.buildUrl({ id }))
    yield put({ type: ALERT_CONFIG_SAVE_SUCCESS, payload })
    logger.notify('Alert Config Save', { name })
    if (notify) yield put(showNotification(MESSAGES.alertSaved, { type: MESSAGE_TYPES.SUCCESS }))
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

function* updateAlertConfigAsync(action) {
  try {
    const { config, notify } = action.payload

    const id = get(config, 'id')
    const name = get(config, 'name')
    const payload = get(config, 'payload')
    const notifTime = get(config, 'notifTime')
    const notifTimezone = get(config, 'notifTimezone')
    let data = {
      id,
    }
    if (name) data['name'] = name
    if (payload) data['payload'] = payload
    if (notifTime) data['notif_time'] = notifTime
    if (notifTimezone) data['notif_timezone'] = notifTimezone

    yield call(client.put, '/shipment_alerts/config', data)
    yield put({ type: ALERT_CONFIG_UPDATE_SUCCESS, payload })

    yield call(getAlertConfigsAsync, { payload: {} }) // reload alerts
    logger.notify('Alert Config Update', { name })
    yield put(selectAlert(config))
    if (notify) yield put(showNotification(MESSAGES.alertUpdated, { type: MESSAGE_TYPES.SUCCESS }))
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

function* deleteAlertConfigAsync(action) {
  try {
    const confirmed = yield call(
      confirmSaga,
      CONFIRMATION_MESSAGES.deleteAlertConfig(action.payload.name)
    )
    if (!confirmed) {
      return
    }

    // for some reason, axios.delete makes you specify the data key in the payload
    yield call(client.delete, '/shipment_alerts/config', { data: { id: action.payload.id } })
    yield put({ type: ALERT_CONFIG_DELETE_SUCCESS, payload: action.payload })

    yield call(getAlertConfigsAsync, { payload: {} }) // reload alerts
    logger.notify('Alert Config Delete', { alertId: action.payload })
    const message = MESSAGES.alertDeleted(action.payload.name)
    yield put(showNotification(message, { type: MESSAGE_TYPES.INFO }))

    /*refetch alerts*/
    yield put(getAlerts())
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

export function* watchGetAlertsConfigs() {
  yield all([
    takeLatest(ALERT_CONFIGS_LOAD, getAlertConfigsAsync),
    takeEvery(ALERT_CONFIG_DELETE, deleteAlertConfigAsync),
    takeEvery(ALERT_CONFIG_SAVE, saveAlertConfigAsync),
    takeEvery(ALERT_CONFIG_UPDATE, updateAlertConfigAsync),
  ])
}
