import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'

export const types = {
  SHOW_IE_DEPRECATION_WARNING: `${AUTH_REDUCER_KEY}/user/SHOW_IE_DEPRECATION_WARNING`,
  USER_LOAD: `${AUTH_REDUCER_KEY}/user/USER_LOAD`,
  USER_SUCCESS: `${AUTH_REDUCER_KEY}/user/USER_SUCCESS`,
  USER_FAILURE: `${AUTH_REDUCER_KEY}/user/USER_FAILURE`,
  VALIDATE_JWT_LOAD: `${AUTH_REDUCER_KEY}/user/VALIDATE_JWT_LOAD`,
  VALIDATE_JWT_SUCCESS: `${AUTH_REDUCER_KEY}/user/VALIDATE_JWT_SUCCESS`,
  VALIDATE_JWT_FAILURE: `${AUTH_REDUCER_KEY}/user/VALIDATE_JWT_FAILURE`,
}

export const getUserInfo = createAction(types.USER_LOAD)
export const validateJWT = createAction(types.VALIDATE_JWT_LOAD)
export const showInternetExplorerDeprecationWarning = createAction(
  types.SHOW_IE_DEPRECATION_WARNING
)
