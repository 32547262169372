import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { ReactElement } from 'react'
import { createAction } from 'redux-actions'

export const types = {
  LOGIN_LOAD: `${AUTH_REDUCER_KEY}/login/LOGIN_LOAD`,
  LOGIN_SUCCESS: `${AUTH_REDUCER_KEY}/login/LOGIN_SUCCESS`,
  LOGIN_FAILURE: `${AUTH_REDUCER_KEY}/login/LOGIN_FAILURE`,
  SET_LOGIN_ERROR_MESSAGE: `${AUTH_REDUCER_KEY}/login/SET_LOGIN_ERROR_MESSAGE`,
  INCREMENT_CONSECUTIVE_FAILED_LOGINS: `${AUTH_REDUCER_KEY}/login/INCREMENT_CONSECUTIVE_FAILED_LOGINS`,
  RESET_CONSECUTIVE_FAILED_LOGINS: `${AUTH_REDUCER_KEY}/login/RESET_CONSECUTIVE_FAILED_LOGINS`,
  SET_INTENDED_USERNAME: `${AUTH_REDUCER_KEY}/login/SET_INTENDED_USERNAME`,
  SET_AUTH_STAGE: `${AUTH_REDUCER_KEY}/login/SET_AUTH_STAGE`,
}

export const login = createAction(
  types.LOGIN_LOAD,
  (username: string, password: string, redirect = '/', forceReload = false) => ({
    username,
    password,
    redirect,
    forceReload,
  })
)

export const setLoginErrorMessage = (message: string | ReactElement | null) => ({
  type: types.SET_LOGIN_ERROR_MESSAGE,
  payload: { message },
})

export const incrementConsecutiveFailedLogins = () => ({
  type: types.INCREMENT_CONSECUTIVE_FAILED_LOGINS,
})

export const setIntendedUsername = (intendedUsername: string) => ({
  type: types.SET_INTENDED_USERNAME,
  payload: { intendedUsername },
})

export const setAuthStage = (authStage: string) => ({
  type: types.SET_AUTH_STAGE,
  payload: { authStage },
})
