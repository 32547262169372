import { all, put, takeLatest } from 'redux-saga/effects'
import {
  fetchAggregatedMetricsForAllSelectedRoutes,
  fetchMetricsForAllSelectedRoutes,
  fetchMetricsForRouteGroups,
  fetchRouteComparisonMetricsOnSelectRouteId,
  fetchRoutes,
} from './routesSagas'

import { PLANNING_FILTER_KEY } from 'store/filterGroups/actions'
import { allFilterGroupEvents } from 'store/filterGroups/sagas'
import { types } from 'store/planning/actions'

function* watchToFetchRoutes() {
  yield takeLatest(types.FETCH_ROUTES_START, fetchRoutes)
}

function* triggerFetchRoutes() {
  yield put({ type: types.FETCH_ROUTES_START })
}

function* watchToRefetchRoutes() {
  // Any updates to the filters requires refetching the routes and their metrics.
  const filterEvents = allFilterGroupEvents[PLANNING_FILTER_KEY]
  yield takeLatest(filterEvents, triggerFetchRoutes)
}

function* watchToFetchMetrics() {
  const events = [types.FETCH_ROUTES_SUCCESS]
  yield all([
    takeLatest(events, fetchMetricsForRouteGroups),
    takeLatest(events, fetchAggregatedMetricsForAllSelectedRoutes),
    takeLatest(events, fetchMetricsForAllSelectedRoutes),
    takeLatest(events, fetchRouteComparisonMetricsOnSelectRouteId),
  ])
}

function* watchToRefetchMetrics() {
  const events = [types.UPDATE_SELECTED_ROUTE_IDS]
  yield all([
    takeLatest(events, fetchAggregatedMetricsForAllSelectedRoutes),
    takeLatest(events, fetchMetricsForAllSelectedRoutes),
    takeLatest(events, fetchRouteComparisonMetricsOnSelectRouteId),
  ])
}

export {
  watchToFetchRoutes,
  watchToRefetchRoutes,
  watchToFetchMetrics,
  watchToRefetchMetrics,
  triggerFetchRoutes,
}

export const sagas = [
  watchToFetchRoutes(),
  watchToRefetchRoutes(),
  watchToFetchMetrics(),
  watchToRefetchMetrics(),
]
