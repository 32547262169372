import { Paper, Typography, withStyles } from '@material-ui/core'
import { getRefTypeLabel, getShipmentDetailPath } from 'utils/rollups'

import { ALERT_DETAIL_ROUTE } from 'utils/routes'
import AdminComponent from 'components/AdminComponent'
import Link from 'components/core/Link'
import NotificationIcon from '@material-ui/icons/NotificationsActive'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { lastViewedSelector } from 'store/alerts'
import logger from 'utils/logger'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

const READABLE_DATE_FORMAT = 'D MMM YYYY'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  reason: {
    fontSize: 12,
    color: theme.palette.grey[600],
  },
  overview: {
    flex: 1,
  },
  orderTitleWrapper: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    color: '#4a4a4a',
  },
  filterButton: {
    position: 'absolute',
    right: 0,
    top: -12,
    padding: 0,
    minHeight: 20,
    textTransform: 'none',
  },
  etaWrapper: {
    flex: 1,
  },
  struckAtTranshipment: {
    height: 24,
    marginRight: theme.spacing(4),
  },
  triggeredByTitle: {
    fontSize: 12,
    color: theme.palette.grey[300],
  },
  threshold: {
    color: '#4a4a4a',
  },
  etaText: {
    fontSize: 12,
    color: theme.palette.grey[600],
  },
  predictedText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    color: '#4a4a4a',
  },
  progressWrapper: {
    width: 200,
    textAlign: 'center',
  },
  noETAText: {
    width: 200,
    textAlign: 'center',
    marginLeft: 50,
    color: theme.palette.grey[600],
    fontSize: 14,
  },
  arrived: {
    color: 'green',
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  defaultIcon: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(2),
    width: 18,
    color: theme.palette.grey[600],
  },
  containersAffectedText: {
    fontSize: 12,
    lineHeight: '12px',
    color: theme.palette.grey[600],
  },
  shipToText: {
    marginTop: 6,
    fontSize: 12,
    lineHeight: '12px',
    color: theme.palette.grey[600],
  },
  detailsText: {
    flex: 1,
    marginTop: 1,
    marginLeft: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  circularProgress: {
    color: '#68768c',
  },
  resolvedText: {
    fontSize: 12,
    lineHeight: '12px',
    marginTop: 6,
    color: theme.palette.grey[600],
  },
})

class DailyAlertOverview extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  getAlertImg = () => {
    const { classes } = this.props
    let iconStyle = {}
    if (this.isNew()) iconStyle['color'] = '#DC3545'
    return <NotificationIcon className={classes.defaultIcon} style={iconStyle} />
  }

  renderContainers = () => {
    const { classes, alert } = this.props
    const allTripIds = get(alert, 'allTripIds', [])
    const alertedTripIds = get(alert, 'alertedTripIds', [])
    const shipTo = get(alert, 'shipTo', null)
    const resolved = get(alert, 'resolvedAt', null)
    let style = {}
    if (shipTo) style['marginBottom'] = -14

    return (
      <div className={classes.detailsText} style={style}>
        <Typography variant="body2" className={classes.containersAffectedText}>
          {alertedTripIds.length} out of {allTripIds.length} containers affected
        </Typography>
        {shipTo && (
          <Typography variant="body2" className={classes.shipToText}>
            Ship to: {shipTo}
          </Typography>
        )}
        {resolved !== null && (
          <AdminComponent>
            <Typography variant="body2" className={classes.resolvedText}>
              Resolved: {moment(resolved).format(READABLE_DATE_FORMAT)}
            </Typography>
          </AdminComponent>
        )}
      </div>
    )
  }

  isNew = () => {
    const { lastViewed, alert } = this.props
    const alertedAt = get(alert, 'alertedAt', null)
    if (alertedAt && moment(alertedAt).isValid()) {
      if (lastViewed !== null) {
        return moment(alertedAt).utc() > lastViewed
      }
    }
    return false
  }

  render() {
    const { classes, alert, config } = this.props
    const refTypeLabel = getRefTypeLabel(alert.referenceType) || ''
    let paperStyle = {}

    if (this.isNew()) paperStyle['border'] = '2px solid #00558B'

    return (
      <Paper className={classes.paper} style={paperStyle} elevation={3}>
        <div className={classes.flex}>
          {this.getAlertImg()}
          <div className={classes.overview}>
            <Typography variant="body2" className={classes.orderTitleWrapper}>
              {refTypeLabel}{' '}
              <Link
                className={classes.orderTitle}
                to={getShipmentDetailPath(alert.referenceType, alert.reference, alert.externalId)}
                onClick={() =>
                  logger.notify('Alert Click Detail', {
                    val: alert.reference,
                    alertConfigID: get(alert, 'configID'),
                  })
                }
              >
                {alert.reference}
              </Link>
            </Typography>
            <Typography variant="body2" className={classes.reason}>
              {alert.reason}
            </Typography>
          </div>
          {this.renderContainers()}
          <div className={classes.etaWrapper}>
            <Typography variant="body2" className={classes.triggeredByTitle}>
              Triggered by alert:{' '}
              <Link to={ALERT_DETAIL_ROUTE.buildUrl({ id: config.id })}>{config.name}</Link>
            </Typography>
          </div>
        </div>
      </Paper>
    )
  }
}

const mapDispatchToProps = {}

const mapStateToProps = state => {
  return {
    lastViewed: lastViewedSelector(state),
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DailyAlertOverview)
