import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  makeStyles,
} from '@material-ui/core'
import {
  PROGRESS_IN_PROGRESS,
  PROGRESS_IN_TRANSIT,
  STAGE_GROUPS,
} from 'utils/milestone/MilestoneGroups/definitions'
import React, { useState } from 'react'
import {
  journeyDetailsLoadingSelector,
  journeyDetailsSelector,
} from 'store/journeyDetails/selectors'

import Button from 'components/core/Button'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import JourneyDetailRow from 'pages/ContainerPage/JourneyDetails/JourneyDetailRow'
import TripStage from 'store/models/definitions/TripStage'
import UiGroupInterface from 'store/types/UiGroupInterface'
import logger from 'utils/logger'
import { useSelector } from 'react-redux'

export const ROLL_LABEL_TOOLTIP_TEXT =
  'The container loaded onto a different vessel than the one planned. Consequently, the ETA at the destination was pushed by more than 4 days.'

export const LEFT_RIGHT_CELL_PADDING = 4
const TABLE_MIN_WIDTH = 970

const useStyles = makeStyles((theme: Theme) => ({
  // `root` is also passed to `JourneyDetailRow` to control both table columns here
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    // Defines all column widths, guarantees that the nested milestone tables line up with the main headers
    '& td, & th': {
      '&:nth-child(1)': { width: '50px', padding: 0, textAlign: 'center' }, // collapse icon
      '&:nth-child(2)': { width: '50px', paddingLeft: 0, paddingRight: 0, textAlign: 'center' }, // status icon
      '&:nth-child(3)': { width: '31%', paddingLeft: 0, paddingRight: 30 }, // padding makes room for expand caret (when present)
      '&:nth-child(4)': { width: '23%', paddingLeft: 5, paddingRight: 5 }, // latest time update
      '&:nth-child(5)': { width: '23%', paddingLeft: 5, paddingRight: 5 }, // planned time
      '&:nth-child(6)': { width: '23%', paddingLeft: 5, paddingRight: 5 }, // vessel/vehicle
    },
  },
  container: {
    minWidth: TABLE_MIN_WIDTH,
    paddingBottom: 100,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  tableHeader: {
    '& th': {
      color: theme.palette.grey[500],
      fontSize: 13,
      paddingBottom: 0,
    },
  },
  table: {
    borderCollapse: 'separate',
    minWidth: 972,
    tableLayout: 'fixed',
  },
  expandButton: {
    paddingLeft: 6,
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 1,
  },
  expandIcon: {
    ...theme.typography.caption,
    color: theme.palette.grey[600],
    fontSize: 20,
  },
  tableContainer: {
    position: 'relative',
  },
}))

const JourneyDetails = () => {
  const classes = useStyles()
  const [allGroupsExpanded, setAllGroupsExpanded] = useState<boolean>(false)
  const journeyDetails = useSelector(journeyDetailsSelector)
  const journeyDetailsLoading = useSelector(journeyDetailsLoadingSelector)

  if (journeyDetailsLoading || !journeyDetails) {
    return null
  }

  const groupedMilestones =
    (journeyDetails.groupedMilestones && journeyDetails.groupedMilestones[0]) ?? []

  if (!groupedMilestones) {
    return null
  }

  /**
   * Determines if a group is expanded initially. Expanded if:
   * - all groups are expanded (they clicked the Expand All button)
   * - OR `groupProgress` is in-transit or in-progress
   * - OR the group has the last traced milestone
   * @param unlocodeGroup the current group object
   */

  const isGroupExpanded = (unlocodeGroup: UiGroupInterface): boolean =>
    allGroupsExpanded ||
    unlocodeGroup.groupProgress === PROGRESS_IN_TRANSIT ||
    unlocodeGroup.groupProgress === PROGRESS_IN_PROGRESS ||
    (unlocodeGroup.hasTracedTime && !unlocodeGroup.subsequentGroupHasTracedMilestone)

  return (
    <Box mt={0} className={classes.container}>
      <TableContainer className={classes.tableContainer}>
        <Button
          className={classes.expandButton}
          size="small"
          variant="outlinedLightGrey"
          onClick={() => {
            setAllGroupsExpanded(!allGroupsExpanded)
            logger.notify(
              allGroupsExpanded ? 'Container Journey Collapse All' : 'Container Journey Expand All'
            )
          }}
        >
          {allGroupsExpanded ? (
            <>
              <ExpandLessIcon className={classes.expandIcon} />
              Collapse
            </>
          ) : (
            <>
              <ExpandMoreIcon className={classes.expandIcon} />
              Expand All
            </>
          )}
        </Button>
        <Table className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.root}>
              {/* collapse icon */}
              <TableCell />
              {/* progress icon */}
              <TableCell />
              {/* status/milestone name */}
              <TableCell />
              <TableCell>Latest Time Update</TableCell>
              <TableCell>Initial Plan Time</TableCell>
              <TableCell>Vessel/Vehicle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              /**
               * We're looping four times here:
               * 1. Stages - pre-main, main, post-main
               * 2. UI Groups: Empty Dispatch, Pre-Carriage, etc. These are arrays of arrays `[ [unlocode] ]
               * 3. Unlocode groups - each unique location for each UI Group (there can be multiples of UI Groups).
               * 4. Milestones for each Unlocode group
               */
              // Stages
              Object.keys(TripStage).map(stage => {
                // UI Groups
                return STAGE_GROUPS[stage]?.map(
                  group =>
                    // Unlocode groups
                    groupedMilestones[group] &&
                    Object.values(groupedMilestones[group] as UiGroupInterface).map(
                      (unlocodeGroup: UiGroupInterface, idx) => {
                        // Milestones for this unlocode group
                        const locationMilestones = unlocodeGroup.milestones
                        return (
                          locationMilestones.length > 0 && (
                            <React.Fragment key={`unlocode-${idx}`}>
                              <JourneyDetailRow
                                allGroupsExpanded={allGroupsExpanded}
                                group={unlocodeGroup}
                                groupName={group}
                                locationZone={
                                  locationMilestones ? locationMilestones[0].locationZone : null
                                }
                                key={`detail-row-${idx}`}
                                classes={{ root: classes.root }}
                                expanded={isGroupExpanded(unlocodeGroup)}
                              />
                            </React.Fragment>
                          )
                        )
                      }
                    )
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default React.memo(JourneyDetails)
