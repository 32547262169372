import React from 'react'
import { SvgIcon } from '@material-ui/core'

const RailIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 66 32">
      <g fill="none" fillRule="evenodd">
        <rect width="66" height="32" />
        <path
          fill="currentColor"
          d="M58 17L58 19 52.5 19C53.8807119 19 55 20.1192881 55 21.5 55 22.8807119 53.8807119 24 52.5 24 51.1192881 24 50 22.8807119 50 21.5 50 21.3289699 50.0171744 21.1619511 50.0498926 21.000574L46.9501074 21.000574C46.9828256 21.1619511 47 21.3289699 47 21.5 47 22.8807119 45.8807119 24 44.5 24 43.1192881 24 42 22.8807119 42 21.5 42 20.1192881 43.1192881 19 44.5 19L44.5 19 40 19 38.5333333 21 19.4666667 21 18 19 13.5 19C14.8807119 19 16 20.1192881 16 21.5 16 22.8807119 14.8807119 24 13.5 24 12.1192881 24 11 22.8807119 11 21.5 11 21.3289699 11.0171744 21.1619511 11.0498926 21.000574L7.9501074 21.000574C7.98282562 21.1619511 8 21.3289699 8 21.5 8 22.8807119 6.88071187 24 5.5 24 4.11928813 24 3 22.8807119 3 21.5 3 20.1192881 4.11928813 19 5.5 19L5.5 19 0 19 0 17 58 17zM7 0L7 1 50.999 1 51 0 53 0 52.999 1 53 1 53 15 5 15 5 0 7 0zM13.5 3L9.5 3 9.5 12 13.5 12 13.5 3zM20.5 3L16.5 3 16.5 12 20.5 12 20.5 3zM27.5 3L23.5 3 23.5 12 27.5 12 27.5 3zM34.5 3L30.5 3 30.5 12 34.5 12 34.5 3zM41.5 3L37.5 3 37.5 12 41.5 12 41.5 3zM48.5 3L44.5 3 44.5 12 48.5 12 48.5 3z"
          transform="translate(4 4)"
        />
      </g>
    </SvgIcon>
  )
}

export default RailIcon
