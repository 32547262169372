import '../assets/Alerts.css'

import { InputAdornment, TextField, withStyles } from '@material-ui/core'
import { searchTermSelector, setSearchTerm } from 'store/alerts'

import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(6),
    marginBottom: 4,
  },
  textField: {
    marginRight: theme.spacing(1),
  },
  inputRoot: {
    width: 200,
  },
  closeIcon: {
    height: 17,
    width: 17,
    cursor: 'pointer',
    color: theme.palette.grey[800],
  },
})

class AlertsSearch extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.currentValue,
      error: '',
      placeholder: 'Search for a shipment',
    }
  }

  handleValueChange = e => {
    this.setState({
      value: e.target.value,
    })
  }

  handleSearch = () => {
    this.props.setSearchTerm(this.state.value)
  }

  handleClear = () => {
    const { currentValue } = this.props
    this.setState({
      value: '',
    })
    if (currentValue) {
      this.props.setSearchTerm('')
    }
  }

  handleTextFieldKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        this.handleSearch()
        break
      default:
        break
    }
  }

  render() {
    const { classes } = this.props
    const { placeholder, value } = this.state
    return (
      <div className={classes.container}>
        <TextField
          placeholder={placeholder}
          className={classes.textField}
          onChange={this.handleValueChange}
          onKeyDown={this.handleTextFieldKeyDown}
          value={value}
          InputProps={{
            classes: {
              root: classes.inputRoot,
            },
            endAdornment: value && (
              <InputAdornment position="end">
                <CloseIcon className={classes.closeIcon} onClick={this.handleClear} />
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" color="primary" onClick={this.handleSearch}>
          Search
        </Button>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setSearchTerm,
}

const mapStateToProps = state => {
  return {
    currentValue: searchTermSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertsSearch))
