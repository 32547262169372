import { call, put, select, takeEvery } from 'redux-saga/effects'
import { incrementForm, types } from 'store/auth/register/actions'

import { REGISTRATION_TYPEFORM } from 'utils/featureFlags'
import api from 'store/auth/register/api'
import browserHistory from 'utils/history'
import logger from 'utils/logger'
import { userFeatureFlagsSelector } from 'store/auth/user/selectors'
import { userInfoAsync } from 'store/auth/user/sagas'

export function* registerAsync(action) {
  try {
    const { username, password, pwdToken, firstName, lastName } = action.payload
    yield call(api.activateUser, {
      username,
      pwdToken,
      firstName,
      lastName,
      password,
    })
    yield call(api.login, { username, password })
    yield call(userInfoAsync)

    yield put({ type: types.REGISTER_SUCCESS })

    const featureFlags = yield select(userFeatureFlagsSelector)
    if (featureFlags[REGISTRATION_TYPEFORM]) {
      yield put(incrementForm())
    } else {
      yield browserHistory.push('/')
    }
  } catch (e) {
    logger.captureAPIException(e)
  }
}

export function* watchRegister() {
  yield takeEvery(types.REGISTER_LOAD, registerAsync)
}
