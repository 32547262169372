import { convertToJSONLogic } from 'utils/filterGroups'
import jsonLogicUtils from 'utils/jsonLogic/utils'

export interface TagInterface {
  key: string
  operator: string
  value: string
}

/**
 * For legacy `tags[]` that specify `between` (`"crd_port_date:between:4||9"`) this function converts it
 * to JsonLogic using `>=` and `<=`.
 * @param tag config object
 * @returns JsonLogic
 */
const legacyBetweenToJsonLogic = (tag: TagInterface) => {
  const valArray = jsonLogicUtils.delimitedStringToArray(tag.value)
  if (valArray.length !== 2) {
    return {}
  }

  const lowerVal = valArray[0]
  const upperVal = valArray[1]

  const arr = ['shipment', 'order', 'line_item']
  const betweenConfig: any = arr.map(item =>
    convertToJSONLogic([
      {
        key: `${item}.other.${tag.key}`,
        operator: '>=',
        val: jsonLogicUtils.getDateValue(lowerVal),
      },
      {
        key: `${item}.other.${tag.key}`,
        operator: '<=',
        val: jsonLogicUtils.getDateValue(upperVal),
      },
    ])
  )

  return {
    or: betweenConfig,
  }
}

export default legacyBetweenToJsonLogic
