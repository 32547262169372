import UiGroupInterface from 'store/types/UiGroupInterface'
import filter from 'lodash/filter'
import last from 'lodash/last'

/**
 * Gets the index of the last traced milestone in a UI group.
 * We use this in `GroupMilestoneRow` to highlight (yellow bg) the name of the last traced milestone.
 * We also use it in the timeline to determine which segment/group is the current one.
 */
export const groupLastTracedMilestoneIndex = (group: UiGroupInterface): number | null => {
  if (!group) {
    return null
  }

  const milestones = group.milestones || []
  let lastTracedMilestoneIndex: number | null = null
  // If this group has a traced time and no _subsequent_ group has a traced time
  // we know this group of milestones has the last traced
  if (group.hasTracedTime && !group.subsequentGroupHasTracedMilestone) {
    // Filter to only milestones with `tracedTime` and then grab the last one
    const m: any = last(filter(milestones, m => m.tracedTime))
    lastTracedMilestoneIndex = milestones.indexOf(m)
  }

  return lastTracedMilestoneIndex
}

export default groupLastTracedMilestoneIndex
