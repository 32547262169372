import React from 'react'
import { SvgIcon } from '@material-ui/core'

const PlanningIcon = ({ ...props }) => (
  <SvgIcon viewBox={'0 0 32 33'} {...props}>
    <path
      d="M16 .583c8.828 0 16 7.172 16 16s-7.172 16-16 16-16-7.172-16-16 7.172-16 16-16zm.727 1.546v6.818h5.148c-.307-1.041-.674-2-1.09-2.841-1.125-2.267-2.553-3.639-4.058-3.977zm-1.454 0c-1.505.338-2.933 1.71-4.057 3.977-.417.84-.784 1.8-1.091 2.84h5.148V2.13zm4.932.534c.71.77 1.338 1.714 1.875 2.795.515 1.04.955 2.216 1.306 3.489h5a14.55 14.55 0 00-8.181-6.284zm-8.41 0a14.55 14.55 0 00-8.181 6.284h5A19.683 19.683 0 019.92 5.458c.537-1.081 1.166-2.025 1.875-2.795zM29.17 10.4H23.74c.358 1.691.57 3.53.613 5.455h6.17A14.452 14.452 0 0029.17 10.4zm-6.92 0h-5.523v5.455h6.17a28.138 28.138 0 00-.647-5.455zm-6.977 0H9.75a28.138 28.138 0 00-.648 5.455h6.17V10.4zm-7.012 0H2.83a14.452 14.452 0 00-1.353 5.455h6.17c.044-1.924.256-3.764.614-5.455zm22.262 6.91h-6.17a29.515 29.515 0 01-.614 5.454h5.431a14.503 14.503 0 001.353-5.455zm-7.625 0h-6.17v5.454h5.522c.374-1.67.602-3.51.648-5.455zm-7.625 0h-6.17c.045 1.944.273 3.785.647 5.454h5.523V17.31zm-7.625 0h-6.17c.095 1.941.57 3.786 1.352 5.454H8.26a29.515 29.515 0 01-.613-5.455zm20.738 6.909h-5a19.613 19.613 0 01-1.306 3.477c-.537 1.081-1.166 2.046-1.875 2.818a14.568 14.568 0 008.181-6.295zm-6.511 0h-5.148v6.829c1.505-.338 2.933-1.722 4.057-3.989a17.58 17.58 0 001.091-2.84zm-6.602 0h-5.148a17.58 17.58 0 001.09 2.84c1.125 2.267 2.553 3.65 4.058 3.989v-6.83zm-6.66 0h-5a14.568 14.568 0 008.182 6.295c-.71-.772-1.338-1.737-1.875-2.818a19.613 19.613 0 01-1.306-3.477z"
      fillRule="nonzero"
    />
  </SvgIcon>
)

export default PlanningIcon
