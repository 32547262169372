import { DefaultMarker, DeliveryMarker, LoadMarker } from 'components/Maps/Markers'
import { Marker, Popup } from 'react-map-gl'

import LocationDetail from 'components/TripMap/LocationDetail'
import React from 'react'
import VesselPopupDetail from 'components/TripMap/VesselPopupDetails'
import get from 'lodash/get'

const UNSUPPORTED_LOCATION_TYPES = ['truck', 'train', 'barge']

export const distance = (lat1, lon1, lat2, lon2) => {
  let radlat1 = (Math.PI * lat1) / 180
  let radlat2 = (Math.PI * lat2) / 180
  let theta = lon1 - lon2
  let radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  return dist
}

export const getFurthestPoints = (allPoints, viewport) => {
  let maxDist = 0
  let bounds = []
  for (let i = 0; i < allPoints.length; i++) {
    let pair = allPoints[i]
    for (let j = 0; j < allPoints.length; j++) {
      let pair2 = allPoints[j]
      if (i !== j) {
        let newDist = distance(pair[1], pair[0], pair2[1], pair2[0])
        if (newDist > maxDist) {
          bounds = [pair, pair2]
          maxDist = newDist
        }
      }
    }
  }
  return bounds
}

export const renderMarker = (item, children, index, setCurrMarker) => {
  // This is a necessary hack to fix the fact that react-map-gl only renders markers on one part of the map at
  // a time. This adds two additional markers at +/- 360 (because the map is actually three earths)
  const location = get(item, 'location')
  const firstCopyItem = {
    ...item,
    ...{ popupLocation: { lat: location.lat + 360, lon: location.lon + 360 } },
  }
  const secondCopyItem = {
    ...item,
    ...{ popupLocation: { lat: location.lat - 360, lon: location.lon - 360 } },
  }
  const firstCopyMarker = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, { onClick: setCurrMarker(firstCopyItem) })
  })
  const secondCopyMarker = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, { onClick: setCurrMarker(secondCopyItem) })
  })
  return (
    <React.Fragment key={`all-markers-${index}`}>
      <Marker key={`marker-${index}`} longitude={location.lon} latitude={location.lat}>
        {children}
      </Marker>
      <Marker
        key={`marker-copy-0-${index}`}
        longitude={location.lon + 360}
        latitude={location.lat + 360}
      >
        {firstCopyMarker}
      </Marker>
      <Marker
        key={`marker-copy-1-${index}`}
        longitude={location.lon - 360}
        latitude={location.lat - 360}
      >
        {secondCopyMarker}
      </Marker>
    </React.Fragment>
  )
}

export const renderLocations = (locations, setCurrMarker) => {
  return locations.map((item, index) => {
    const location = get(item, 'location')

    if (UNSUPPORTED_LOCATION_TYPES.indexOf(item.type) !== -1) return null

    // Don't render invalid lat/lons
    if (location.lat === null || location.lon === null) return null
    let marker = <DefaultMarker size={10} onClick={setCurrMarker(item)} />

    if (item.markerType === 'load') {
      marker = <LoadMarker size={28} onClick={setCurrMarker(item)} detailText={'1/1'} />
    } else if (item.markerType === 'delivery') {
      marker = <DeliveryMarker onClick={setCurrMarker(item)} />
    }
    return renderMarker(item, marker, index, setCurrMarker)
  })
}

export const renderPopup = ({ onCloseHandler, popupData, classes, hidePopover }) => {
  return (
    popupData && (
      <Popup
        tipSize={5}
        anchor="top"
        offsetTop={10}
        longitude={get(popupData, 'popupLocation.lon')}
        latitude={get(popupData, 'popupLocation.lat')}
        className={classes.popup}
        closeButton={false}
        onClose={() => onCloseHandler(null)}
      >
        <LocationDetail popupData={popupData} onClose={hidePopover} />
      </Popup>
    )
  )
}

export const renderVesselPopup = ({
  vesselPopupData,
  hidePopover,
  popupEquipmentType = 'vessel',
}) => {
  if (!vesselPopupData) return null
  const location = get(vesselPopupData, 'location')
  return (
    <Popup
      tipSize={5}
      anchor="top"
      offsetTop={10}
      longitude={location.lon}
      latitude={location.lat}
      closeButton={false}
      onClose={hidePopover}
    >
      <VesselPopupDetail
        popupData={vesselPopupData}
        onClose={hidePopover}
        popupEquipmentType={popupEquipmentType}
      />
    </Popup>
  )
}
