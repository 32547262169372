import React, { useEffect } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { fetchProfile, submitProfile } from '../../store/profile/actions'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from '../../components/Loader/Loader'
import UserForm from './UserForm'
import { profileFromState } from '../../store/profile/selectors'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(10),
    width: '100%',
  },
  header: {
    marginBottom: theme.spacing(4),
  },
}))

const Settings = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const profile = useSelector(profileFromState)

  useEffect(() => {
    dispatch(fetchProfile())
  }, [dispatch])

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h5" color="textPrimary">
        Profile
      </Typography>
      {profile?.loading ? (
        <Loader style={{ marginTop: 100 }} />
      ) : (
        <UserForm user={profile} onSubmit={submitProfile} />
      )}
    </div>
  )
}

export default Settings
