import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ChevronRightIcon = props => {
  return (
    <SvgIcon {...props}>
      <polygon
        points="7.768 10.378 3.379 5.979 7.768 1.58 6.417 .229 .667 5.979 6.417 11.729"
        transform="rotate(-180 8 9)"
      />
    </SvgIcon>
  )
}

export default ChevronRightIcon
