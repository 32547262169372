import BaseRouteBarChart from '../BaseRouteBarChart'
import React from 'react'
import { connect } from 'react-redux'
import { fetchTopLanesByVolume } from 'store/userDashboard/actions'
import { topLanesByVolumeSelector } from 'store/userDashboard'
import { withStyles } from '@material-ui/core'

function TopLanesByVolumeChart({ topLanesByVolumeState, fetchData, theme }) {
  return (
    <div data-testid={`user-dashboard__top-lanes-by-volume`}>
      <BaseRouteBarChart
        axisTitle={'Volume (# of Containers)'}
        carrierLabelFormatter={group => group.carriers.map(c => c.abbreviation).join(', ')}
        color={theme.palette.datavis.blue[600]}
        data={topLanesByVolumeState.data}
        dataKey={'count'}
        dataLabelFormatter={y => y.toLocaleString()}
        fetchData={fetchData}
        isFailed={topLanesByVolumeState.isFailed}
        isLoading={topLanesByVolumeState.isLoading}
        tooltipMetrics={[]}
        title={'Top Lanes by Volume'}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  topLanesByVolumeState: topLanesByVolumeSelector(state),
})

const mapDispatchToProps = {
  fetchData: fetchTopLanesByVolume,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({}, { withTheme: true })(TopLanesByVolumeChart))
