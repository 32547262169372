import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  makeStyles,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IncompleteVessels from './IncompleteVessels'
import LatestUpdate from './LatestUpdate'
import PropTypes from 'prop-types'
import React from 'react'
import UnavailableVessels from './UnavailableVessels'
import WarningIcon from '@material-ui/icons/Warning'
import tinycolor from 'tinycolor2'

const useStyles = makeStyles(theme => ({
  container: {
    float: 'right',
    clear: 'both',
    position: 'relative',
    backgroundColor: tinycolor(theme.palette.blue[400]).setAlpha(0.9).toRgbString(),
    maxWidth: 300,
  },
  panel: {
    backgroundColor: 'transparent',
    minHeight: 0,
    padding: theme.spacing(0, 2),

    '&.Mui-expanded': {
      margin: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  icon: {
    color: theme.palette.grey[50],
    marginRight: theme.spacing(1),
  },
  details: {
    display: 'inherit',
    padding: 0,

    '&.Mui-expanded': {
      margin: 0,
    },
  },
  heading: {
    color: theme.palette.grey[50],
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.1,
  },
  expandIcon: {
    color: theme.palette.grey[50],
  },
  summaryRoot: {
    margin: 0,
    padding: 0,

    '&.Mui-expanded': {
      padding: 0,
      minHeight: 0,
    },
  },
  summaryContent: {
    alignItems: 'center',
  },
  expanded: {
    '&.Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
  },
}))

export default function VesselNotification({ vesselsWithMissingData, invalidVessels, currVessel }) {
  const classes = useStyles()

  const renderNotifications = () => {
    let numRemarks = vesselsWithMissingData.length
    numRemarks += invalidVessels.length > 0 ? 1 : 0 // if there are any invalidVessels, add a single remark
    let remarkText = numRemarks > 1 ? 'remarks' : 'remark'

    return numRemarks > 0 ? (
      <ExpansionPanel classes={{ root: classes.panel, expanded: classes.expanded }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expanded: classes.expanded,
          }}
        >
          <WarningIcon className={classes.icon} />
          <Typography variant="body2" className={classes.heading}>
            {numRemarks} {remarkText}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <IncompleteVessels classes={classes} vesselsWithMissingData={vesselsWithMissingData} />
          <UnavailableVessels classes={classes} invalidVessels={invalidVessels} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ) : null
  }

  return (
    <div className={classes.container}>
      <LatestUpdate classes={classes} currVessel={currVessel} />
      {renderNotifications()}
    </div>
  )
}

VesselNotification.propTypes = {
  vesselsWithMissingData: PropTypes.array.isRequired,
  invalidVessels: PropTypes.array.isRequired,
  currVessel: PropTypes.object.isRequired,
}

VesselNotification.defaultProps = {
  vesselsWithMissingData: [],
  invalidVessels: [],
  currVessel: {},
}
