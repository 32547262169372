import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CenteredLayout from 'components/layouts/CenteredLayout'
import DeprecatedLoginPage from './DeprecatedLoginPage'
import { Loader } from 'components/Loader/Loader'
import LoginPage from './LoginPage'
import { PublicFeatureFlagsState } from 'store/publicFeatureFlags/interfaces'
import { RouteComponentProps } from 'react-router-dom'
import { loadPublicFeatureFlags } from 'store/publicFeatureFlags/actions'
import { makeStyles } from '@material-ui/core'
import { publicFeatureFlagsSelector } from 'store/publicFeatureFlags/selectors'
import { useLocation } from 'react-router'
import { useQuery } from 'utils/hooks'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
}))

// https://stackoverflow.com/a/48138690
interface MatchParams {
  extension?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const FlaggedLogin = (props: Props) => {
  const { match } = props
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()

  // used for testing. see comment in login_page.spec.js
  const showDeprecated = query.get('deprecated')

  useEffect(() => {
    dispatch(loadPublicFeatureFlags())
  }, [dispatch])

  // This whole branch will go away and this component will be combined with LoginPage
  // The call to /public_feature_flags will also go away since it will not be needed

  const publicFeatureFlags: PublicFeatureFlagsState = useSelector(publicFeatureFlagsSelector)

  // show the loader until public feature flags have loaded to avoid rapidly switching between deprecated and current UI
  if (
    (publicFeatureFlags.data === undefined || publicFeatureFlags.isLoading) &&
    !publicFeatureFlags.isFailed
  ) {
    return (
      <CenteredLayout className={classes.root}>
        <Loader />
      </CenteredLayout>
    )
  } else if (
    (publicFeatureFlags.data?.ssoLogin && showDeprecated !== 'true') ||
    showDeprecated === 'false'
  ) {
    return <LoginPage match={match} history={props.history} location={props.location} />
  } else {
    return <DeprecatedLoginPage match={match} location={location} />
  }
}

export default FlaggedLogin
