import {
  AssociatedReferencesInterface,
  AssociatedReferencesState,
} from 'store/associatedReferences/interfaces'

import { handleActions } from 'redux-actions'
import { types } from 'store/associatedReferences/actions'

const initialState: AssociatedReferencesState = {
  isLoading: false,
  isFailed: false,
  data: undefined,
}

const associatedReferences = handleActions<AssociatedReferencesState, any>(
  {
    [types.FETCH_ASSOCIATED_REFERENCES_START]: (state: AssociatedReferencesState) => ({
      ...state,
      isFailed: false,
      isLoading: true,
    }),
    [types.FETCH_ASSOCIATED_REFERENCES_SUCCESS]: (
      state: AssociatedReferencesState,
      action: { payload: AssociatedReferencesInterface }
    ) => ({
      ...state,
      data: action.payload,
      isFailed: false,
      isLoading: false,
    }),
    [types.FETCH_ASSOCIATED_REFERENCES_FAILURE]: (state: AssociatedReferencesState) => ({
      ...state,
      isFailed: true,
      isLoading: false,
    }),
    [types.FETCH_ASSOCIATED_REFERENCES_RESET_START]: (state: AssociatedReferencesState) => {
      return { ...state, ...initialState }
    },
  },
  initialState
)

export default associatedReferences
