import { ALERT_EMAIL_ACTION_HANDLERS } from 'store/auth/alertEmail'
import { CONFIGURE_USERS_ACTION_HANDLERS } from 'store/auth/configureUsers'
import { IMPERSONATE_ACTION_HANDLERS } from 'store/auth/impersonate'
import { INVITE_ACTION_HANDLERS } from 'store/auth/invite'
import { LOGIN_ACTION_HANDLERS } from 'store/auth/login'
import { LOGOUT_ACTION_HANDLERS } from 'store/auth/logout'
import { PASSWORD_RESET_CONFIRMATION_ACTION_HANDLERS } from 'store/auth/passwordResetConfirmation'
import { PASSWORD_RESET_EMAIL_ACTION_HANDLERS } from 'store/auth/passwordResetEmail'
import { REGISTRATION_ACTION_HANDLERS } from 'store/auth/register'
import { TENANT_ACTION_HANDLERS } from 'store/auth/tenant'
import { TOKEN_CHECK_ACTION_HANDLERS } from 'store/auth/tokenCheck'
import { USER_ACTION_HANDLERS } from 'store/auth/user'
import hasCookie from 'utils/hasCookie'
import store from 'store'

export const initialState = {
  login: {
    // can't read the access/refresh token by design since it's HTTP only
    // if we have a csrf_refresh_token cookie, we should have an HttpOnly refresh token that can obtain a new access
    // token if needed
    loggedIn: hasCookie('csrf_refresh_token'),
    loading: false,
    errorMessage: null,
    consecutiveFailedLogins: store.get('consecutiveFailedLogins') || 0,
    intendedUsername: null,
    authStage: 'email',
  },
  impersonate: {
    loading: false,
    isImpersonating: Boolean(store.get('isImpersonating')),
  },
  user: {
    info: {},
    awaitingAuthCheck: true,
    loading: false,
  },
  allUsers: {
    data: [],
    loading: false,
  },
  jwt: {
    valid: false,
    loading: false,
  },
  tokenCheck: {
    // loading: true to hide flashing behavior. Spinner is used to block UI
    loading: true,
    valid: false,
  },
  tenant: {
    loading: false,
    id: '',
    all: [],
  },
  invite: {
    loading: false,
    result: null,
  },
  passwordResetEmail: {
    loading: false,
  },
  passwordResetConfirm: {
    loading: false,
  },
  alertEmail: {
    loading: false,
    error: false,
  },
  registration: {
    formStep: 0,
  },
}

export function authReducer(state = initialState, action) {
  const actionHandlers = {
    ...LOGIN_ACTION_HANDLERS,
    ...LOGOUT_ACTION_HANDLERS,
    ...REGISTRATION_ACTION_HANDLERS,
    ...TOKEN_CHECK_ACTION_HANDLERS,
    ...TENANT_ACTION_HANDLERS,
    ...INVITE_ACTION_HANDLERS,
    ...USER_ACTION_HANDLERS,
    ...PASSWORD_RESET_EMAIL_ACTION_HANDLERS,
    ...PASSWORD_RESET_CONFIRMATION_ACTION_HANDLERS,
    ...IMPERSONATE_ACTION_HANDLERS,
    ...ALERT_EMAIL_ACTION_HANDLERS,
    ...CONFIGURE_USERS_ACTION_HANDLERS,
  }
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}
