import { Paper, Typography, makeStyles } from '@material-ui/core'
import React, { FormEvent } from 'react'

import Button from 'components/core/Button'
import { oauthStatusSelector } from 'store/oauthStatus/selectors'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    // consistent width makes variable-length error text display consistently
    width: theme.spacing(45),
  },
  wideSubmitButton: {
    width: '100%',
  },
  orText: {
    color: theme.palette.grey[500],
    paddingTop: theme.spacing(3),
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    // line on side headings, based on https://codepen.io/trys/pen/vjRXLW/
    '&:before': {
      // changing this to `content: ''` will break the styling
      // https://stackoverflow.com/a/66233926
      content: '""',
      borderTop: '2px solid',
      borderColor: theme.palette.grey[300],
      margin: '0 8px 0 0',
      flex: '1 0 8px',
    },
    '&:after': {
      content: '""',
      borderTop: '2px solid',
      borderColor: theme.palette.grey[300],
      margin: '0 0 0 8px',
      flex: '1 0 8px',
    },
  },
}))

interface Props {
  onSsoLogin: (e: FormEvent<HTMLFormElement>) => void
  onPasswordLogin: (e: FormEvent<HTMLFormElement>) => void
}

const SSOForm = (props: Props) => {
  const classes = useStyles()
  const { onSsoLogin, onPasswordLogin } = props
  const oauthStatus = useSelector(oauthStatusSelector)
  const canUsePasswordLogin = oauthStatus.data?.canUsePasswordLogin
  return (
    <>
      <form id="sso-login-form" className={classes.form} onSubmit={onSsoLogin}>
        <Button
          className={classes.wideSubmitButton}
          data-testid="sso-login-form--submit"
          color="primary"
          variant="contained"
          id="submit-sso-login"
          type="submit"
          size="large"
        >
          Sign In with Single Sign-On
        </Button>
      </form>
      {canUsePasswordLogin && (
        <>
          <Typography variant="subtitle1" className={classes.orText}>
            or
          </Typography>
          <form id="password-login-form" className={classes.form} onSubmit={onPasswordLogin}>
            <Paper variant="outlined">
              <Button
                data-testid="password-login-form--submit"
                color="primary"
                variant="outlinedLightPrimary"
                id="submit-password-login"
                type="submit"
                size="medium"
              >
                Sign In with Password
              </Button>
            </Paper>
          </form>
        </>
      )}
    </>
  )
}

export default SSOForm
