import { IconButton, Tooltip } from '@material-ui/core'

import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import Link from 'components/core/Link'
import PropTypes from 'prop-types'
import React from 'react'
import { commentSummariesStateSelector } from 'store/comments'
import get from 'lodash/get'
import logger from 'utils/logger'
import { pathWithComments } from 'utils/routes/comments'
import { useSelector } from 'react-redux'

export const LOGGER_MESSAGE_CARD = 'Board Card Comments Icon Click'
export const LOGGER_MESSAGE_TABLE = 'Board Table Comments Icon Click'
export const LOGGER_SEARCH_RESULT = 'Search Result Comments Icon Click'

const LOGGER_MESSAGES = [LOGGER_MESSAGE_CARD, LOGGER_MESSAGE_TABLE, LOGGER_SEARCH_RESULT]

const CommentsLinkButton = function ({
  externalId,
  link,
  iconButtonProps = {},
  iconProps = {},
  loggerMessage = null,
  onClick = () => undefined,
}) {
  const commentSummariesState = useSelector(commentSummariesStateSelector)
  const summaries = get(commentSummariesState, 'data')
  const commentCount = get(summaries, `${externalId}.count`)
  const handleClick = event => {
    loggerMessage && logger.notify(loggerMessage, { externalId })
    onClick(event)
  }

  if (!Boolean(commentCount)) {
    return null
  }

  return (
    <Tooltip title="Comments">
      <IconButton
        component={Link}
        data-testid="board__comments-icon-link"
        onClick={handleClick}
        to={pathWithComments(link)}
        {...iconButtonProps}
      >
        <ChatOutlinedIcon fontSize="inherit" color="primary" {...iconProps} />
      </IconButton>
    </Tooltip>
  )
}

CommentsLinkButton.propTypes = {
  externalId: PropTypes.string,
  iconButtonProps: PropTypes.shape({
    'data-testid': PropTypes.string,
    to: PropTypes.string,
  }),
  iconProps: PropTypes.shape({
    fontSize: PropTypes.string,
  }),
  link: PropTypes.string,
  loggerMessage: PropTypes.oneOf(LOGGER_MESSAGES),
  onClick: PropTypes.func,
}

export default CommentsLinkButton
