import Button from '@material-ui/core/Button'
import DetailRadio from 'components/DetailRadio'
import DetailRadioItem from 'components/DetailRadio/DetailRadioItem'
import MultiSelectAutocomplete from 'components/MultiSelectAutocomplete'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

const MODE_CITY = 'city'
const MODE_COUNTRY = 'country'

const MODES = [MODE_CITY, MODE_COUNTRY]

class LocationFilter extends React.Component {
  constructor(props) {
    super(props)
    const type = get(props, 'value.type', MODE_CITY)
    const value = get(props, 'value.value', [])
    this.state = {
      warning: '',
      type,
      value,
    }
  }

  handleSubmit = e => {
    const { onSubmit, onRequestClose } = this.props
    const { type, value } = this.state
    e.preventDefault()

    if (value.length > 0) {
      onSubmit({ type, value })
      onRequestClose()
    } else {
      this.setState({
        warning: 'Please select an item',
      })
    }
  }

  handleChange = type => value => {
    this.setState({
      value,
      type,
    })
  }

  formatOption = label => {
    return label.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  handleRadioChange = type => {
    this.setState({ type, value: [] })
  }

  render() {
    const { filterConfig, classes } = this.props
    const { warning, type, value } = this.state
    const cityPath = get(filterConfig, 'filterData.cityPath')
    const countryPath = get(filterConfig, 'filterData.countryPath')

    return (
      <div className={classes.container}>
        <DetailRadio defaultSelectedIndex={indexOf(MODES, type)} onChange={this.handleRadioChange}>
          <DetailRadioItem label={<span className={classes.label}>City</span>} value={MODE_CITY}>
            <div className={classes.searchWrapper}>
              <MultiSelectAutocomplete
                defaultValue={value}
                lookupPath={cityPath}
                showValueInOptions
                formatOption={this.formatOption}
                onChange={this.handleChange('city')}
                placeholder={'Location or UN/LOCODE'}
              />
            </div>
          </DetailRadioItem>
          <DetailRadioItem
            label={<span className={classes.label}>Country</span>}
            value={MODE_COUNTRY}
          >
            <div className={classes.searchWrapper}>
              <MultiSelectAutocomplete
                defaultValue={value}
                lookupPath={countryPath}
                showValueInOptions
                onChange={this.handleChange('country')}
                placeholder={'Country or Country Code'}
              />
            </div>
          </DetailRadioItem>
        </DetailRadio>

        {warning && (
          <Typography variant="body2" className={classes.warning}>
            {this.state.warning}
          </Typography>
        )}
        <Button className={classes.submitBtn} onClick={this.handleSubmit}>
          Apply
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(LocationFilter)
