import { TRANSPORT_DETAIL_REDUCER_KEY } from 'store/transportDetail/actions'
import { TransportDetailState } from 'store/transportDetail/interfaces'

export const transportDetailSelector = (state: {
  [TRANSPORT_DETAIL_REDUCER_KEY]: TransportDetailState
}) => state[TRANSPORT_DETAIL_REDUCER_KEY].data

export const transportDetailLoadingSelector = (state: {
  [TRANSPORT_DETAIL_REDUCER_KEY]: TransportDetailState
}) => state[TRANSPORT_DETAIL_REDUCER_KEY].isLoading
