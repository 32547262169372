import get from 'lodash/get'

export class Vessel {
  constructor(name, lloydsCode, id) {
    this.name = name
    this.lloydsCode = lloydsCode
    this.id = id
  }
}

export class TimeRange {
  constructor(min, max) {
    this.min = min
    this.max = max
  }
}

export class TimeSummary {
  constructor(time, traced, planned, predicted, oldPlanned, timezone = null) {
    this.time = time
    this.traced = traced
    this.planned = planned
    this.predicted = predicted
    this.oldPlanned = oldPlanned
    this.timezone = timezone
  }
}

export class VesselTrip {
  constructor(startDate, endDate, lloydsCode, name, live, containerTripIds, id) {
    this.startDate = startDate
    this.endDate = endDate
    this.lloydsCode = lloydsCode
    this.live = live
    this.name = name
    this.containerTripIds = containerTripIds
  }
}

export class TripTimelineItem {
  constructor(
    code,
    location,
    type,
    details,
    fromTime,
    untilTime,
    containerTripIds,
    traced,
    tracedMilestones,
    predictedMilestones,
    segmentIndex
  ) {
    this.code = code
    this.location = location
    this.type = type
    this.details = details
    this.fromTime = fromTime
    this.untilTime = untilTime
    this.containerTripIds = containerTripIds
    this.traced = traced
    this.tracedMilestones = tracedMilestones
    this.predictedMilestones = predictedMilestones
    this.segmentIndex = segmentIndex
    this.id = null
    if (this.details) {
      this.id = this.type + get(this.details, 'name', '')
    }
  }
}

export class TripTimeline {
  constructor(milestones, containerTripIds, latestTracedIdx, references) {
    this.milestones = milestones
    this.containerTripIds = containerTripIds
    this.latestTracedIdx = latestTracedIdx
    this.references = references
    if (this.latestTracedIdx !== null && this.latestTracedIdx < this.milestones.length) {
      this.latestTracedMilestone = this.milestones[this.latestTracedIdx]
    }
  }
}
