import 'mapbox-gl/dist/mapbox-gl.css'

import { getFurthestPoints, renderLocations, renderPopup, renderVesselPopup } from './Utils'

import CenteredLayout from 'components/layouts/CenteredLayout'
import Dimensions from 'react-dimensions'
import Loader from 'components/Loader'
import NavigationControlOverride from 'components/NavigationControlOverride'
import PropTypes from 'prop-types'
import React from 'react'
import ReactMapGL from 'react-map-gl'
import TripStage from 'store/models/definitions/TripStage'
import VesselLocation from 'components/Maps/VesselLocation'
import WebMercatorViewport from 'viewport-mercator-project'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { extractVesselTrips } from 'utils/map'
import find from 'lodash/find'
import { findDOMNode } from 'react-dom'
import findLast from 'lodash/findLast'
import get from 'lodash/get'
import { getVesselPath } from 'store/api/vesselPath'
import { journeyDetailsLoadingSelector } from 'store/journeyDetails/selectors'
import logger from 'utils/logger'
import tinycolor from 'tinycolor2'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core'

const MAX_ZOOM = 2.2

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
}

const styles = theme => ({
  containerMarker: {
    zIndex: 10,
  },
  '@global': {
    '.mapboxgl-popup-content': {
      padding: '0px !important',
      borderRadius: `${theme.spacing(1)}px !important`,
    },
    '.mapboxgl-ctrl.mapboxgl-ctrl-attrib': {
      display: 'none',
    },
    '.mapboxgl-popup': {
      zIndex: '11 !important', // make sure it shows above container marker
    },
  },
  invalidText: {
    fontSize: 12,
    color: theme.palette.grey[100],
  },
  invalidSummary: {
    fontSize: 12,
    color: theme.palette.grey[100],
  },
  invalidDataContainer: {
    float: 'right',
    clear: 'both',
    position: 'relative',
    padding: '10px',
    backgroundColor: tinycolor('#f59948').setAlpha(0.9).toRgbString(),
    marginTop: theme.spacing(1),
    width: 270,
  },
})

class TripMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewport: {
        latitude: this.props.latitude,
        longitude: this.props.longitude,
        zoom: this.props.zoom,
        pitch: 0,
        bearing: 0,
      },
      popupData: null,
      vesselPopupData: null,
      popupEquipmentType: null,
      loaded: false,
      map: null,
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    height: 600,
    latitude: 0,
    longitude: 0,
    zoom: 1,
    mapStyle: 'mapbox://styles/diegoclearmetal/cjeao3qom7ek82so1vv1z02ch',
    VehicleLocationNode: VesselLocation,
  }

  setCurrMarker = location => () => {
    this.setState({
      popupData: location,
      vesselPopupData: null,
      popupEquipmentType: null,
    })
  }

  hidePopover = () => {
    this.setState({
      popupData: null,
      vesselPopupData: null,
      popupEquipmentType: null,
    })
  }

  updateViewport = viewport => {
    this.setState({ viewport })
  }

  onLoad = () => {
    const map = this.refs.map ? this.refs.map.getMap() : null
    this.setState({
      map,
      loaded: true,
    })
  }

  vesselSelected = (vesselInfo, equipmentType) => {
    this.setState({
      vesselPopupData: vesselInfo,
      popupData: null,
      popupEquipmentType: equipmentType,
    })
  }

  componentDidMount() {
    // Fit map to markers
    try {
      const map = findDOMNode(this.refs.map)
      if (map) {
        const mapEl = map.getBoundingClientRect()
        const data = this.props.locations
        const viewport = new WebMercatorViewport({ width: mapEl.width, height: mapEl.height })
        let bounds = data.reduce((accumulator, item) => {
          const lon = get(item, 'location.lon')
          const lat = get(item, 'location.lat')
          if (lon && lat) {
            accumulator.push([Number(lon), Number(lat)])
          }
          return accumulator
        }, [])
        const furthest = getFurthestPoints(bounds, viewport)
        const newBounds = viewport.fitBounds(furthest, { padding: 70 })

        const newViewport = Object.assign({}, this.state.viewport, {
          zoom: Math.min(newBounds.zoom, MAX_ZOOM),
          longitude: newBounds.longitude,
          latitude: newBounds.latitude,
        })
        this.setState({
          viewport: newViewport,
        })
      }
    } catch (e) {
      logger.captureAPIException(e)
      logger.localLog('error setting map bounds', e)
    }
  }

  render() {
    const { classes, isLoading, locations, mapStyle, VehicleLocationNode, vesselTrips } = this.props
    const { map, loaded, viewport, popupData, vesselPopupData, popupEquipmentType } = this.state
    if (isLoading) {
      return (
        <CenteredLayout>
          <Loader style={{ marginTop: 100 }} />
        </CenteredLayout>
      )
    }
    return (
      <div data-testid="container-details__trip-map">
        <ReactMapGL
          id={'map'}
          ref={'map'}
          onLoad={this.onLoad}
          {...viewport}
          width={this.props.containerWidth} // this prop is set by react-dimensions
          height={600}
          scrollZoom={false}
          mapStyle={mapStyle}
          onViewportChange={viewport => this.setState({ viewport })}
          mapboxApiAccessToken={
            'pk.eyJ1IjoiZGllZ29jbGVhcm1ldGFsIiwiYSI6ImNpeXV0bWp1ajAwMmMycXBkcGNqMzRxZ2IifQ.Hyc_GrjLpduxKQd4RdDZHw'
          }
        >
          {renderLocations(locations, this.setCurrMarker)}
          {renderPopup({
            onCloseHandler: this.setCurrMarker,
            popupData,
            classes,
            hidePopover: this.hidePopover,
          })}
          {renderVesselPopup({
            vesselPopupData,
            hidePopover: this.hidePopover,
            popupEquipmentType,
          })}
          <VehicleLocationNode
            updateViewport={this.updateViewport}
            vesselTrips={vesselTrips}
            onSelect={this.vesselSelected}
            viewport={viewport}
            map={map}
            mapNode={this.refs.map}
            focused={true}
            maxZoom={1.5}
            loaded={loaded}
          />
          <div style={navStyle}>
            {/* use NavigationControlOverride or else the zoom controls won't show +/- icons! */}
            <NavigationControlOverride showCompass={false} onViewportChange={this.updateViewport} />
          </div>
        </ReactMapGL>
      </div>
    )
  }
}

const createVesselTrips = journeyDetails => {
  const milestones = journeyDetails.timelines[0]?.milestones ?? []
  return extractVesselTrips(milestones)
}

/**
 * Add first location and have it render a "load" icon.  Add last location and have it render a "delivery" icon.
 * Then add remaining locations with normal circle icons.
 * @param journeyDetails
 * @returns {Array}
 */
const createLocations = journeyDetails => {
  const milestones = journeyDetails.timelines[0]?.milestones ?? []
  let locations = {}

  const firstLocation = find(
    milestones,
    item => item.stopSegment?.tripSegment?.stage === TripStage.pre_main && item.locationZone
  )?.locationZone

  if (firstLocation) {
    locations[firstLocation.name] = {
      markerType: 'load',
      location: firstLocation,
      popupLocation: firstLocation,
    }
  }

  const lastLocation = findLast(
    milestones,
    item => item.stopSegment?.tripSegment?.stage === TripStage.post_main && item.locationZone
  )?.locationZone

  if (lastLocation) {
    locations[lastLocation.name] = {
      markerType: 'delivery',
      location: lastLocation,
      popupLocation: lastLocation,
    }
  }

  for (let i = 0; i < milestones.length; i++) {
    const ms = milestones[i]

    const location = ms.locationZone

    // don't override any existing locations
    if (location && !(location.name in locations) && location.lat && location.lon) {
      locations[location.name] = {
        markerType: null,
        location,
        popupLocation: location,
      }
    }
  }
  return values(locations)
}

const journeyDetailSelector = (state, props) => props.journeyDetails

const locationSelector = createSelector(journeyDetailSelector, createLocations)
const vesselTripsSelector = createSelector(journeyDetailSelector, createVesselTrips)

const mapStateToProps = (state, props) => {
  const vesselTrips = vesselTripsSelector(state, props)
  const locations = locationSelector(state, props)
  const isLoading = journeyDetailsLoadingSelector(state)
  return {
    locations,
    vesselTrips,
    isLoading,
  }
}

const mapDispatchToProps = {
  getVesselPath,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dimensions({ elementResize: false })(withStyles(styles)(TripMap)))
