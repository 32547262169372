import React, { useEffect, useState } from 'react'

import { CHART_TYPE_BAR } from 'components/ChartLoader/utils'
import ChartLoader from 'components/ChartLoader'
import ChartToggleDropdown from 'components/ChartToggleDropdown'
import { TopAverageDwellTimes } from './TopAverageDwellTimes'
import { TopVolumeDwellTimes } from './TopVolumeDwellTimes'
import { connect } from 'react-redux'
import { dwellTimesSelector } from 'store/userDashboard'
import { fetchDwellTimes } from 'store/userDashboard/actions'
import isEmpty from 'lodash/isEmpty'
import tracker from 'utils/logger/tracker'
import { withStyles } from '@material-ui/core'

function DwellChart({ dwellTimesState, fetchData, theme }) {
  useEffect(() => {
    fetchData()
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [mode, setMode] = useState('volume')
  const { isLoading, isFailed, data } = dwellTimesState
  const dataReceived = data !== undefined
  const hasData = !isEmpty(data)

  if (isLoading || !dataReceived || !hasData) {
    return (
      <ChartLoader
        isLoading={isLoading}
        dataReceived={dataReceived}
        isFailed={isFailed}
        hasData={hasData}
        chartType={CHART_TYPE_BAR}
      />
    )
  }

  const dropdownOptions = [
    { label: 'Top Dwell Times by Volume', value: 'volume' },
    { label: 'Top Average Dwell Times', value: 'average' },
  ]

  const topAverageChart = <TopAverageDwellTimes data={data.topAverage || []} theme={theme} />
  const topVolumeChart = <TopVolumeDwellTimes data={data.topVolume || []} theme={theme} />

  const onSelectChart = e => {
    tracker.dashboard.userSwitchesChartMode(e.label)
    setMode(e.value)
  }

  return (
    <>
      {!isLoading && !isFailed && (
        <ChartToggleDropdown
          items={dropdownOptions}
          defaultIdx={0}
          labelKey={'label'}
          handleSelection={onSelectChart}
        />
      )}
      {mode === 'volume' ? topVolumeChart : topAverageChart}
    </>
  )
}

const mapStateToProps = state => ({ dwellTimesState: dwellTimesSelector(state) })
const mapDispatchToProps = { fetchData: fetchDwellTimes }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({}, { withTheme: true })(DwellChart))
