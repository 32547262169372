export const PUBLIC_FEATURE_FLAGS_REDUCER_KEY = 'publicFeatureFlags'

const types = {
  PUBLIC_FEATURE_FLAGS_LOAD: `${PUBLIC_FEATURE_FLAGS_REDUCER_KEY}/LOAD`,
  PUBLIC_FEATURE_FLAGS_SUCCESS: `${PUBLIC_FEATURE_FLAGS_REDUCER_KEY}/SUCCESS`,
  PUBLIC_FEATURE_FLAGS_FAIL: `${PUBLIC_FEATURE_FLAGS_REDUCER_KEY}/FAIL`,
}

export const loadPublicFeatureFlags = () => ({ type: types.PUBLIC_FEATURE_FLAGS_LOAD })

export default types
