import { ALERT_EMAIL_SHARE_BTTN_QUERY_SOURCE } from 'store/auth/alertEmail'
import { fromQueryString } from 'utils/urlBuilder'
import logger from 'utils/logger'
import { openShareModal } from 'store/share/actions'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/*
 * A hook that logs when someone has navigated to an entity page from an email.
 * We add a `source` query param to URLs from various email templates that link
 * to entity pages. For example: https://app.sparkpost.com/templates/edit/alert/published/content
 *
 * This hook also handles opening the share modal if a user clicks on the "Share" button
 * in the alert email, which sets the source query param to `ALERT_EMAIL_SHARE_BTTN_QUERY_SOURCE`.
 */
export const useEmailActions = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const query = fromQueryString(location.search)
    if (query.source) {
      logger.notify('Entity Email Open', { source: query.source })
    }
    if (query.source === ALERT_EMAIL_SHARE_BTTN_QUERY_SOURCE) {
      dispatch(openShareModal())
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])
}
