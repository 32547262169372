import KeyValueTable from 'components/KeyValueTable'
import React from 'react'
import TextListWithExpand from 'components/TextListWithExpand'
import { customFieldsSelector } from 'store/customFields/selectors'
import find from 'lodash/find'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core'
import { parseCustomFieldDate } from 'utils/dateUtils'
import { useSelector } from 'react-redux'

const READABLE_DATE_FORMAT = 'D MMM YYYY'
const DATE_TYPE = 'datetime'

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    marginLeft: 0,
    marginRight: theme.spacing(3),
  },
  expandButton: {
    marginLeft: -37,
  },
}))

type Props = {
  customization?: Record<string, []>
}

type CustomerTags = {
  key: string
  title: string
}[]

const AdditionalInfoTable = (props: Props) => {
  const classes = useStyles()
  const customFields = useSelector(customFieldsSelector)
  const { customization } = props

  const getRows = () => {
    const customerTags: CustomerTags = get(customization, 'customerTags', [])

    return customerTags.reduce((res: Record<string, string>, tag) => {
      const key = tag.key
      const matchingCustomField = find(customFields.data?.customFields, item => item.key === key)
      let values
      let type

      if (matchingCustomField) {
        values = get(matchingCustomField, 'values')
        type = get(matchingCustomField, 'type')
      }
      if (!values) return res

      if (type === DATE_TYPE && values) {
        values = parseCustomFieldDate(values, READABLE_DATE_FORMAT)
      } else {
        values = values.join(', ')
      }

      res[tag.title] = values
      return res
    }, {})
  }

  const rows = getRows()

  let rowsWithModals: Record<string, JSX.Element> = {}
  Object.keys(rows).forEach(key => {
    rowsWithModals[key] = (
      <>
        <TextListWithExpand
          items={rows[key].split(', ')}
          classes={{ expandButton: classes.expandButton }}
          collapseAfterCountOf={10}
        />
      </>
    )
  })

  if (Object.keys(rows).length) {
    return (
      <div className={classes.tableWrapper} data-testid="additional-info-table">
        <KeyValueTable rows={rowsWithModals} customStyle={{ whiteSpace: 'normal' }} />
      </div>
    )
  } else {
    return null
  }
}

export default AdditionalInfoTable
