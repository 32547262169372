// Theme is passed from material-ui withStyles
export default theme => ({
  subheader: {
    color: theme.brand.color,
    letterSpacing: 0.2,
    backgroundColor: '#fff',
    width: '100%',
  },
  subheaderDescription: {
    fontSize: '.7rem',
    marginTop: -12,
    paddingLeft: 27,
    color: theme.brand.color,
  },
  subheaderTitle: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
  },
  listItemText: {
    color: theme.palette.grey[800],
    whiteSpace: 'nowrap',
    fontSize: 14,
    marginLeft: 27,
  },
  subheaderIcon: {
    color: theme.brand.color,
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginBottom: -6,
  },
})
