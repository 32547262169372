import { SvgIcon, SvgIconProps, Theme, makeStyles } from '@material-ui/core'

import React from 'react'

// Default color, most likely to be used this way most or all the time, easy to override.
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.orange[500],
  },
}))

const InProgressIcon = (props: SvgIconProps) => {
  const classes = useStyles(props)
  return (
    <SvgIcon classes={{ root: classes.root }} {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width="24" height="24" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M11.8833789,3.99327227 C11.3860402,3.93550716 11,3.51283584 11,3 C11,2.44771525 11.4477153,2 12,2 L12,2 C17.52,2 22,6.48 22,12 C22,17.4264407 17.6705774,21.8478368 12.2702648,21.9961558 L12,22 L11.8833789,21.9932723 C11.3860402,21.9355072 11,21.5128358 11,21 C11,20.4477153 11.4477153,20 12,20 L12,20 C16.42,20 20,16.42 20,12 C20,7.58 16.42,4 12,4 Z"
        />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M12,7 L12,7 C11.45,7 11,7.45 11,8 L11,11.59 C11,12.12 11.21,12.63 11.59,13 L14.59,16 C14.98,16.39 15.61,16.39 16,16 L16,16 C16.39,15.61 16.39,14.98 16,14.59 L13,11.59 L13,8 C13,7.45 12.55,7 12,7 Z"
        />
        <circle cx="3" cy="12" r="1" fill="currentColor" />
        <circle cx="4.218" cy="7.478" r="1" fill="currentColor" />
        <circle cx="7.443" cy="4.233" r="1" fill="currentColor" />
        <circle cx="4.218" cy="16.522" r="1" fill="currentColor" />
        <circle cx="7.444" cy="19.766" r="1" fill="currentColor" />
      </g>
    </SvgIcon>
  )
}

export default InProgressIcon
