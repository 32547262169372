import React, { useEffect } from 'react'

import { CHART_TYPE_COLUMN } from 'components/ChartLoader/utils'
import ChartLoader from 'components/ChartLoader'
import HighChart from 'components/HighChart'
import { connect } from 'react-redux'
import { containerTripPerformanceSelector } from 'store/userDashboard'
import { fetchContainerTripPerformance } from 'store/userDashboard/actions'
import { getHighChartDefaults } from '../utils/highCharts'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import { tickLabelForMetric } from 'store/planning/utils/DateRange'
import { withStyles } from '@material-ui/core'

function ContainerTripPerformanceChart({ containerTripPerformanceState, fetchData, theme }) {
  useEffect(() => {
    fetchData()
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoading, isFailed, data } = containerTripPerformanceState
  const dataReceived = data !== undefined
  const hasData = !isEmpty(data)

  if (isLoading || !dataReceived || !hasData) {
    return (
      <ChartLoader
        isLoading={isLoading}
        dataReceived={dataReceived}
        isFailed={isFailed}
        hasData={hasData}
        chartType={CHART_TYPE_COLUMN}
      />
    )
  }

  let xAxis = []
  let overallTotal = 0
  let series = {}

  if (!isEmpty(data)) {
    xAxis = data.delayed.map(m => tickLabelForMetric(m))

    // Populate `series` and `overallTotal`
    Object.keys(data).map(
      key =>
        (series[key] = data[key].map(obj => {
          overallTotal += obj.count || 0
          return obj.count || 0
        }))
    )
  }

  const highChartDefaultOptions = getHighChartDefaults(theme)

  const highChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      useHTML: true,
      text: `Completed Container Trip Performance: <span style="white-space: nowrap">${overallTotal.toLocaleString()} total</span>`,
    },
    xAxis: {
      categories: xAxis,
      title: {
        text: 'Duration',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Container Count',
      },
      stackLabels: {
        enabled: false,
      },
    },
    legend: { enabled: false },
    tooltip: {
      formatter: function () {
        const rowStyle = `padding-top: 5px;`
        return `
          <div style="font-size: 14px;">
            <div style="${rowStyle}">${
          this.series.name
        }: <b>${this.point.options.y.toLocaleString()}</b></div>
            <div style="${rowStyle}">Total: <b>${this.point.stackTotal.toLocaleString()}</b></div>
          </div>
        `
      },
    },
    plotOptions: {
      column: {
        pointWidth: 50,
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Delayed',
        data: series.delayed || [],
        color: theme.palette.datavis.orange[400],
      },
      {
        name: 'On Schedule',
        data: series.onTime || [],
        color: theme.palette.datavis.green[600],
      },
      {
        name: 'Early',
        data: series.early || [],
        color: theme.palette.datavis.yellow[500],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 700,
          },
          chartOptions: {
            plotOptions: {
              column: {
                pointWidth: 30,
                stacking: 'normal',
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 520,
          },
          chartOptions: {
            plotOptions: {
              column: {
                pointWidth: 10,
                stacking: 'normal',
              },
            },
          },
        },
      ],
    },
  }

  return (
    <div data-testid="user-dashboard__container-trip-performance">
      <HighChart options={merge({}, highChartDefaultOptions, highChartOptions)} />
    </div>
  )
}

const mapStateToProps = state => ({
  containerTripPerformanceState: containerTripPerformanceSelector(state),
})

const mapDispatchToProps = {
  fetchData: fetchContainerTripPerformance,
}

const styledContainerTripPerformanceChart = withStyles(
  {},
  { withTheme: true }
)(ContainerTripPerformanceChart)
export { styledContainerTripPerformanceChart as ContainerTripPerformanceChart }

export default connect(mapStateToProps, mapDispatchToProps)(styledContainerTripPerformanceChart)
