import { createAction } from 'redux-actions'
export const COMMENTS_REDUCER_KEY = 'comments'

export const types = {
  FETCH_COMMENTS_START: `${COMMENTS_REDUCER_KEY}/FETCH_COMMENTS_START`,
  FETCH_COMMENTS_SUCCESS: `${COMMENTS_REDUCER_KEY}/FETCH_COMMENTS_SUCCESS`,
  FETCH_COMMENTS_ERROR: `${COMMENTS_REDUCER_KEY}/FETCH_COMMENTS_ERROR`,
  FETCH_COMMENT_SUMMARIES_START: `${COMMENTS_REDUCER_KEY}/FETCH_COMMENT_SUMMARIES_START`,
  FETCH_COMMENT_SUMMARIES_SUCCESS: `${COMMENTS_REDUCER_KEY}/FETCH_COMMENT_SUMMARIES_SUCCESS`,
  FETCH_COMMENT_SUMMARIES_ERROR: `${COMMENTS_REDUCER_KEY}/FETCH_COMMENT_SUMMARIES_ERROR`,
  CREATE_COMMENT: `${COMMENTS_REDUCER_KEY}/CREATE_COMMENT`,
  UPDATE_COMMENT: `${COMMENTS_REDUCER_KEY}/UPDATE_COMMENT`,
  DELETE_COMMENT: `${COMMENTS_REDUCER_KEY}/DELETE_COMMENT`,
  SET_EDITOR_IS_ENABLED: `${COMMENTS_REDUCER_KEY}/SET_EDITOR_IS_ENABLED`,
  SET_EDITOR_INITIAL_CONTENT: `${COMMENTS_REDUCER_KEY}/SET_EDITOR_INITIAL_CONTENT`,
}

export const fetchComments = createAction(types.FETCH_COMMENTS_START)

export const fetchCommentSummaries = (externalIds = []) => ({
  type: types.FETCH_COMMENT_SUMMARIES_START,
  externalIds,
})

export const createComment = createAction(types.CREATE_COMMENT)
export const updateComment = createAction(types.UPDATE_COMMENT)
export const deleteComment = createAction(types.DELETE_COMMENT)

export const setEditorIsEnabled = createAction(types.SET_EDITOR_IS_ENABLED)
export const setEditorInitialContent = createAction(types.SET_EDITOR_INITIAL_CONTENT)
