import {
  DELIVERED,
  DISCHARGED,
  IN_TRANSIT,
  PENDING,
  UNKNOWN,
} from 'store/models/definitions/StatusLabel'
import {
  LabelColorVariants,
  STATUS_DELIVERED,
  STATUS_DISCHARGED,
  STATUS_INTRANSIT,
  STATUS_PENDING,
  STATUS_UNKNOWN,
} from 'components/StatusLabel/labelColors'

import { StatusLabelType } from 'store/models/definitions/StatusLabel'

/**
 * Gets a status label variant (color)
 * @param statusLabel
 * @returns LABEL_COLORS
 */
const statusLabelVariant = (statusLabel: StatusLabelType): LabelColorVariants => {
  switch (statusLabel) {
    case DELIVERED:
      return STATUS_DELIVERED
    case DISCHARGED:
      return STATUS_DISCHARGED
    case IN_TRANSIT:
      return STATUS_INTRANSIT
    case PENDING:
      return STATUS_PENDING
    case UNKNOWN:
    default:
      return STATUS_UNKNOWN
  }
}

export default statusLabelVariant
