import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'

export const types = {
  LOGOUT_LOAD: `${AUTH_REDUCER_KEY}/logout/LOGOUT_LOAD`,
  LOGOUT_SUCCESS: `${AUTH_REDUCER_KEY}/logout/LOGOUT_SUCCESS`,
  LOGOUT_FAILURE: `${AUTH_REDUCER_KEY}/logout/LOGOUT_FAILURE`,
}

export const logout = createAction(types.LOGOUT_LOAD)
