import { emailFromState, fullNameSelector } from 'store/auth/user/selectors'

import Proptypes from 'prop-types'
import React from 'react'
import { SvgIcon } from '@material-ui/core'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export const ProfileCircle = ({ className, content, dispatch, ...rest }) => (
  <SvgIcon style={{ fontSize: '29px' }} viewBox={'0 0 100 100'} {...rest}>
    <circle cx="50" cy="50" r="50" />
    <text
      x="50%"
      y="50%"
      fill="black"
      textAnchor="middle"
      dy=".3em"
      fontSize="1.7em"
      fontWeight="bold"
    >
      {!isEmpty(content) && content[0].toUpperCase()}
    </text>
  </SvgIcon>
)

ProfileCircle.defaultProps = {
  content: '',
}

ProfileCircle.propTypes = {
  content: Proptypes.string.isRequired,
}

const mapStateToProps = state => {
  return {
    content: fullNameSelector(state)
      ? get(fullNameSelector(state), 0)
      : get(emailFromState(state), 0),
  }
}

export default connect(mapStateToProps, null)(ProfileCircle)
