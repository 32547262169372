import React, { useEffect, useState } from 'react'
import { TableHead, TableRow, TableSortLabel, makeStyles } from '@material-ui/core'

import TableCell from 'components/core/SimpleTableDeprecated/TableCell'

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: '#fff',
    position: 'sticky', // this does not work in IE11 but we are dropping IE11 support
    top: 0,
    // without this zIndex, buttons contained in the table overlay
    // the header https://github.com/mui-org/material-ui/issues/6625
    zIndex: 10,
    color: theme.palette.grey[500],
    opacity: 0.79,
    lineHeight: '0.875rem',
  },
  row: {
    // this should be filled in by the caller and can be used to set
    // things like widths on the header cells etc
  },

  tableSortLabelRoot: {
    /**
     * Design requested we update the default color styles for TableSortLabel via theme override
     * but wants legacy tables to have the default styling.
     *  */
    '&:focus': {
      color: theme.palette.text.secondary,
    },
    '&:hover': {
      color: theme.palette.text.secondary,
      '& $icon': {
        opacity: 0.5,
      },
    },
    '&$active': {
      color: theme.palette.text.primary,
      '&& $icon': {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
    },
  },
  tableSortLabelActive: {
    /**
     * Design requested we update the default color styles for TableSortLabel via theme override
     * but wants legacy tables to have the default styling.
     *  */
    color: theme.palette.text.primary,
    '&& $icon': {
      opacity: 1,
      color: theme.palette.text.secondary,
    },
  },
  tableSortLabelIcon: {
    /**
     * Design requested we update the default color styles for TableSortLabel via theme override
     * but wants legacy tables to have the default styling.
     *  */
    opacity: 0,
    color: theme.palette.text.secondary,
  },
}))

interface SortableTableHeaderProps {
  onRequestSort: (property: string, order: string) => void
  classes: { [key: string]: string }
  cols: {
    sortDisabled: boolean | 'true' // TODO: Make boolean only
    id: string
    numeric: boolean
    sortKey: string
    label: string
    style: object
  }[]
  defaultOrder?: 'asc' | 'desc'
  defaultOrderBy?: string
}

function SortableTableHeader(props: SortableTableHeaderProps) {
  const { cols, defaultOrder = 'desc', defaultOrderBy = null, onRequestSort } = props
  const classes = useStyles(props)
  const [order, setOrder] = useState(defaultOrder)
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  useEffect(() => setOrder(defaultOrder), [defaultOrder])
  useEffect(() => setOrderBy(defaultOrderBy), [defaultOrderBy])

  function createSortHandler(property: string) {
    return () => {
      const orderOpposite = order === 'asc' ? 'desc' : 'asc'
      const newOrder = property === orderBy ? orderOpposite : 'desc'
      setOrderBy(property)
      setOrder(newOrder)
      onRequestSort(property, newOrder)
    }
  }

  return (
    <TableHead data-testid="sortableTableHeader">
      <TableRow className={classes.row}>
        {cols.map(col => {
          if (Boolean(col.sortDisabled)) {
            return (
              <TableCell
                key={col.id}
                align={col.numeric ? 'right' : 'inherit'}
                className={classes.head}
                style={col.style}
              >
                {col.label}
              </TableCell>
            )
          } else {
            const sortKey = col.sortKey || col.id
            return (
              <TableCell
                key={col.id}
                align={col.numeric ? 'right' : 'inherit'}
                sortDirection={orderBy === sortKey ? order : false}
                className={classes.head}
                style={col.style}
              >
                <TableSortLabel
                  classes={{
                    root: classes.tableSortLabelRoot,
                    active: classes.tableSortLabelActive,
                    icon: classes.tableSortLabelIcon,
                  }}
                  active={orderBy === sortKey}
                  direction={order}
                  onClick={createSortHandler(sortKey)}
                  data-testid={`sortable-table-header__sort-by-${sortKey}`}
                >
                  {col.label}
                </TableSortLabel>
              </TableCell>
            )
          }
        })}
      </TableRow>
    </TableHead>
  )
}

export default SortableTableHeader
