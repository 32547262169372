import CMPaper from 'components/CMPaper'
import PropTypes from 'prop-types'
import React from 'react'
import SingleBarLoader from 'components/ChartLoader/SingleBarLoader'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  container: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderLeftStyle: 'solid',
    borderLeftWidth: 13,
    color: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 93,
    padding: '0 8%',
    margin: 0,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 170,
      padding: '15px 0',
      textAlign: 'center',
      width: '30%',
    },
  },
  left: {
    flex: 1,
    flexBasis: '60%',

    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
      paddingBottom: 30,
    },
  },
  right: {
    flexBasis: '40%',
    paddingLeft: '5%',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: 28,
    lineHeight: `33px`,
    display: 'inline-block',
    textAlign: 'right',
  },
})

const DashboardScorecard = ({ classes, color, left, right, isLoading, theme }) => {
  color = isLoading ? theme.palette.grey[100] : color
  return (
    <CMPaper className={classes.container} style={Boolean(color) && { borderLeftColor: color }}>
      <div className={classes.left}>
        <span style={{ color: color }} className={classes.value}>
          {isLoading ? (
            <SingleBarLoader width={60} isAnimated={true} />
          ) : (
            `${left.value === '0%' ? '--%' : left.value}`
          )}
        </span>
        <br />
        <span className={classes.label}>
          {isLoading ? <SingleBarLoader width={100} isAnimated={true} /> : left.label}
        </span>
      </div>
      <div className={classes.right}>
        <span style={{ color: color }} className={classes.value}>
          {isLoading ? (
            <SingleBarLoader width={60} isAnimated={true} />
          ) : (
            `${parseInt(right.value, 10) === 0 ? '--' : right.value}`
          )}
        </span>
        <br />
        <span className={classes.label}>
          {isLoading ? <SingleBarLoader width={100} isAnimated={true} /> : right.label}
        </span>
      </div>
    </CMPaper>
  )
}

const InfoType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

DashboardScorecard.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  left: InfoType.isRequired,
  right: InfoType.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(DashboardScorecard)
