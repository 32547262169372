import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createSelector } from 'reselect'
import get from 'lodash/get'

export const fullNameSelector = state => {
  const { firstName, lastName } = state[AUTH_REDUCER_KEY].user.info

  // Require both first and last name for a response
  if (firstName && lastName) {
    return firstName + ' ' + lastName
  }

  return null
}

export const isExternalUserSelector = state =>
  get(state[AUTH_REDUCER_KEY].user.info, 'isExternalUser', false)

export const userFromState = state => state[AUTH_REDUCER_KEY].user.info

export const emailFromState = state => get(state[AUTH_REDUCER_KEY].user.info, 'email')

export const currentTenantFromState = state => get(state[AUTH_REDUCER_KEY].user.info, 'tenant')

export const isSuperuserFromState = state =>
  get(state[AUTH_REDUCER_KEY].user.info, 'isSuperuser', false)

export const awaitingAuthCheckFromState = state => state[AUTH_REDUCER_KEY].user.awaitingAuthCheck

export const userLoadingFromState = state => state[AUTH_REDUCER_KEY].user.loading

export const customizationSelector = state =>
  get(state[AUTH_REDUCER_KEY].user.info, 'customization', {})

export const getCustomerTags = state =>
  get(state[AUTH_REDUCER_KEY].user, 'info.customization.customerTags', [])

export const isUserApiLoading = createSelector(
  awaitingAuthCheckFromState,
  userLoadingFromState,
  (authLoading, userLoading) => authLoading || userLoading
)

export const userFeatureFlagsSelector = state =>
  get(state[AUTH_REDUCER_KEY].user.info, 'featureFlags', {})
