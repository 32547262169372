import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  alertEmailErrorFromState,
  alertEmailLoadingFromState,
  sendAlertEmail,
} from 'store/auth/alertEmail'
import { currentTenantFromState, emailFromState } from 'store/auth/user/selectors'

import AdminButton from './AdminButton'
import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import ListTenants from './ListTenants'
import { Loader } from 'components/Loader/Loader'
import PropTypes from 'prop-types'
import React from 'react'
import SendIcon from '@material-ui/icons/Send'
import { connect } from 'react-redux'
import { listTenants } from 'store/auth/tenant/actions'
import { tenantListFromState } from 'store/auth/tenant/selectors'

const styles = theme => ({
  root: {},
  content: {
    display: 'flex',
  },
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  dialogPaper: {
    minWidth: 800,
    minHeight: 500,
  },
  currentTenant: {
    fontSize: 14,
    color: theme.brand.color,
  },
  actionButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.brand.color,
  },
  actionButtonLabel: {
    color: 'white',
    textTransform: 'none',
  },
  flex: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 400,
  },
  error: {
    color: 'red',
    marginTop: theme.spacing(1),
  },
  errorText: {
    fontSize: 15,
    color: 'red',
  },
})

class NotifyUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState(props)
  }

  getInitialState = props => {
    return {
      open: false,
      complete: false,
      tenant: props.currentTenant,
      email: props.currentEmail,
      errors: [],
      forceType: 'new',
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  handleCancel = () => {
    this.setState({
      open: false,
      tenant: null,
    })
  }

  openDialog = () => {
    this.setState({
      open: true,
    })
  }

  handleAllUsersChange = () => {
    this.setState({
      email: '',
    })
  }

  handleTextFieldChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  handleNotify = () => {
    const { tenant, email, forceType } = this.state
    const forceAll = forceType === 'all'
    const forceUnscheduled = forceType === 'new'
    let errors = []

    // Force a selection
    if (!tenant) {
      errors.push('Please select either a specific tenant, or check all tenants')
    }

    // Force a selection
    if (email === '') {
      errors.push("Please input a user's email")
    }
    if (errors.length === 0) {
      this.setState({
        complete: true,
      })
      this.props.sendAlertEmail(tenant, email, forceAll, forceUnscheduled)
    } else {
      this.setState({
        errors,
      })
    }
  }

  handleClose = () => {
    this.setState(this.getInitialState(this.props))
  }

  getTriggerText = () => {
    const { tenant, email } = this.state
    let tenantText = ''
    if (tenant) tenantText = `Tenant: ${tenant}`

    let userText = ''
    if (email) userText = `User: ${email}`

    let finalText = 'Click to trigger'
    if (tenantText !== '' || userText !== '') finalText += ` [${tenantText} ${userText}]`
    return finalText
  }

  handleTenantClick = tenant => {
    this.setState({
      tenant,
    })
  }

  getBody = () => {
    const { errors, email, complete } = this.state
    const { classes, currentTenant, isLoading, error } = this.props

    if (isLoading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }

    if (complete) {
      if (!error) {
        return <Typography variant="body2">Successful trigger</Typography>
      } else {
        return (
          <Typography variant="body2" className={classes.errorText}>
            Trigger error
          </Typography>
        )
      }
    }

    return (
      <React.Fragment>
        <div className={classes.content}>
          <div className={classes.col}>
            <Typography variant="body2" className={classes.currentTenant}>
              Choose a tenant (your current tenant: {currentTenant})
            </Typography>
            <ListTenants tenantClick={this.handleTenantClick} defaultSelected={currentTenant} />
          </div>
          <div className={classes.col}>
            <Typography variant="body2" className={classes.currentTenant}>
              Email
            </Typography>
            <TextField value={email} onChange={this.handleTextFieldChange} label={"User's email"} />
          </div>
          <div />
        </div>
        <RadioGroup value={this.state.forceType} onChange={this.handleForceTypeChange}>
          <FormControlLabel value="new" control={<Radio />} label="Only send new alerts" />
          <FormControlLabel
            value="all"
            control={<Radio />}
            label="Force all historical alerts to be sent"
          />
        </RadioGroup>
        <div>
          {errors.map(error => {
            return (
              <Typography variant="body2" className={classes.error}>
                {error}
              </Typography>
            )
          })}
        </div>
      </React.Fragment>
    )
  }

  handleForceTypeChange = event => {
    this.setState({ forceType: event.target.value })
  }

  getActions = () => {
    const { complete } = this.state
    const { classes } = this.props
    if (complete) {
      return (
        <Button
          onClick={this.handleClose}
          classes={{ root: classes.actionButton, label: classes.actionButtonLabel }}
        >
          Close
        </Button>
      )
    }
    return (
      <React.Fragment>
        <Typography variant="body2">{this.getTriggerText()}</Typography>
        <Button
          onClick={this.handleNotify}
          classes={{ root: classes.actionButton, label: classes.actionButtonLabel }}
        >
          Trigger
        </Button>
      </React.Fragment>
    )
  }

  render() {
    const { open } = this.state
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <AdminButton onClick={this.openDialog} text={'Send Alert Email'} icon={<SendIcon />} />
        <Dialog open={open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>
            Send Alert Email
            <CloseIcon className={classes.closeIcon} onClick={this.handleCancel} />
          </DialogTitle>
          <DialogContent>{this.getBody()}</DialogContent>
          <DialogActions>{this.getActions()}</DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapDispatchToProps = {
  listTenants,
  sendAlertEmail,
}

const mapStateToProps = state => {
  return {
    tenants: tenantListFromState(state),
    isLoading: alertEmailLoadingFromState(state),
    error: alertEmailErrorFromState(state),
    currentTenant: currentTenantFromState(state),
    currentEmail: emailFromState(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotifyUser))
