import { Typography, withStyles } from '@material-ui/core'

import Filter from './Filter'
import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import logger from 'utils/logger'

const styles = theme => ({
  container: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
  },
  filterContentWrapper: {
    flex: 1,
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: 'white',
  },
  header: {
    fontSize: 14,
    margin: theme.spacing(0.5),
  },
  listWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  list: {
    margin: `${theme.spacing(2)} 0`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
})

class FilterList extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
  }

  componentDidMount() {
    const { filters } = this.props
    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      const title = get(filter, 'filterConfig.title', null)
      if (title === null) {
        logger.captureAppException(
          `Unknown filter ${filter.key} ${filter.value} (likely caused by db migration issue)`
        )
      }
    }
  }

  render() {
    const { filters, classes } = this.props

    const filterComponents = filters.map(filter => {
      return <Filter key={filter.name} displayData={filter} />
    })

    if (filterComponents.length > 0) {
      return (
        <div className={classes.container}>
          <div className={classes.filterContentWrapper}>
            <Typography variant="body2" className={classes.header}>
              An alert will be fired for any shipments that meet these filter criteria:
            </Typography>
            <div className={classes.listWrapper}>{filterComponents}</div>
          </div>
        </div>
      )
    }
    return null
  }
}

export default withStyles(styles)(FilterList)
