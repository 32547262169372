import find from 'lodash/find'
import get from 'lodash/get'
import logger from 'utils/logger'
import moment from 'moment'
import store from 'store'
import toNumber from 'lodash/toNumber'

const DATE_FORMAT = 'YYYY-MM-DD'
const READABLE_DATE_FORMAT = 'D MMM YYYY'
// Predefined Range Constants
const THIS_WEEK = 'This Week'
const LAST_SEVEN = 'Last 7 Days'
const LAST_THIRTY = 'Last 30 Days'
const CUSTOM = 'Custom'

export const PICKER_MODES = { THIS_WEEK, LAST_SEVEN, LAST_THIRTY, CUSTOM }

export const API_DELIMITER = '||'

const isImpersonating = Boolean(store.get('isImpersonating'))

// URL Param Builders

export function getParamsFromFilters(filters) {
  let params = {}

  filters.forEach(f => {
    const filterData = get(f, 'filterConfig.filterData')
    const filterParams = filterData ? filterData.getUrlParam(f.value) : {}
    for (let key in filterParams) {
      const newVal = filterParams[key]

      // if key exists, then convert values to array and append the new value.  Else, add key normally
      if (key in params) {
        const existingVal = params[key]
        if (!Array.isArray(existingVal)) {
          params[key] = [existingVal]
        }
        params[key].push(newVal)
      } else {
        params[key] = newVal
      }
    }
  })
  return params
}

export const getDateParam = (param1, param2) => val => {
  let { startDate, endDate, type } = val
  type = type || 'absolute' //default to absolute
  if (type === 'absolute') {
    if (startDate === endDate) {
      endDate = moment(endDate).add(1, 'days')
    }
    if (moment(startDate).isValid()) startDate = moment(startDate).format(DATE_FORMAT)
    if (moment(endDate).isValid()) endDate = moment(endDate).format(DATE_FORMAT)
  } else if (type === 'relative') {
    if (startDate !== null) {
      startDate = toNumber(startDate)
    }
    if (endDate !== null) {
      endDate = toNumber(endDate)
    }
  }

  let params = {}
  if (startDate !== null) params[param1] = startDate // need to do explicit null check in case startDate or endDate == 0
  if (endDate !== null) params[param2] = endDate
  return params
}

export const getRangeParam = (minName, maxName) => ({ lowerBound, upperBound }) => {
  let params = {}
  if (lowerBound !== null) params[minName] = lowerBound
  if (upperBound !== null) params[maxName] = upperBound
  return params
}

// Display Value Getters

export const getDateDisplayVal = textQualifier => val => {
  let { startDate, endDate, type, relativeType } = val
  type = type || 'absolute' //default to absolute
  relativeType = relativeType || 'future' //default to absolute
  let endingText = ''

  if (type === 'absolute') {
    if (startDate !== null) startDate = moment(startDate).format(READABLE_DATE_FORMAT)
    if (endDate !== null) endDate = moment(endDate).format(READABLE_DATE_FORMAT)
  } else if (type === 'relative') {
    endingText = textQualifier
  }
  if (startDate !== null && endDate !== null) {
    if (type === 'absolute') {
      if (startDate === endDate) return `On ${startDate}`
      else return `Between ${startDate} and ${endDate} ${endingText}`
    } else {
      let dates = [Math.abs(startDate), Math.abs(endDate)]
      dates.sort((a, b) => a - b)
      return `Between ${dates[0]} and ${dates[1]} ${endingText}`
    }
  } else if (startDate !== null) {
    if (type === 'relative') {
      if (relativeType === 'past') return `Less than ${Math.abs(startDate)} ${endingText}`
      else return `More than ${startDate} ${endingText}`
    }
    if (type === 'absolute') return `After ${startDate} ${endingText}`
  } else if (endDate !== null) {
    if (type === 'relative') {
      if (relativeType === 'past') return `More than ${Math.abs(endDate)} ${endingText}`
      else return `Less than ${endDate} ${endingText}`
    }
    if (type === 'absolute') return `Before ${endDate} ${endingText}`
  }
}

export const getLocationDisplayVal = val =>
  val.value
    .map(item => {
      if (item['label']) return item['label']
      return item['value']
    })
    .join(', ')

export const getPredictedDateVal = (item, type) => {
  const milestone = get(item, `planned.${type}.[0]`)
  const timestamp = get(milestone, 'time.predicted')
  const timezone = get(milestone, 'time.timezone')
  let errorText = undefined
  if (!timestamp) {
    const traced = get(item, 'traced', [])
    let tracedMilestone = find(traced, m => m.type === type)
    if (tracedMilestone) errorText = 'Milestone complete'
    // else errorText = 'N/A'
  }
  return {
    timestamp,
    timezone,
    errorText,
  }
}

export const getLatestMilestoneDisplayVal = val => {
  let displayVal = ''
  if (Object.keys(val.range).length) {
    displayVal += `Between ${moment(val.range.startDate).format(READABLE_DATE_FORMAT)} and ${moment(
      val.range.endDate
    ).format(READABLE_DATE_FORMAT)};`
  }
  if (val.locations.length) {
    displayVal += ' Occurred in ' + val.locations.map(item => item.value).join(', ') + ';'
  }
  if (val.selected.length) {
    displayVal += ` [${val.selected.map(item => item.label).join(', ')}]`
  }
  return displayVal
}

export const getRangeDisplayVal = ({ lowerBound, upperBound }) => {
  if (lowerBound == null && upperBound !== null) {
    return `Less than ${upperBound} ${parseInt(upperBound) === 1 ? 'day' : 'days'}`
  } else if (upperBound === null && lowerBound !== null) {
    return `Greater than ${lowerBound} ${parseInt(lowerBound) === 1 ? 'day' : 'days'}`
  } else {
    return `Between ${lowerBound} and ${upperBound} days`
  }
}

export const convertFieldsToFilters = (fields, filterConfigs) => {
  return fields.map(filterField => {
    let filterConfig = find(filterConfigs, col => col.name === filterField.key)

    // Tenant should have all fields pre-configured, log if a configuration is missing
    // We want to ignore this if the user is impersonating, see #175404463.
    if (!filterConfig && !isImpersonating) {
      const errorMessage = `Configuration Mismatch: ${filterField.key} provided but not configured for tenant - likely caused by db migration issue`

      logger.localLog(errorMessage, 'error')
      logger.captureAppException(
        new Error(errorMessage, {
          fields,
          filterConfigs,
        })
      )
    }

    return {
      name: filterField.key,
      value: filterField.value,
      filterConfig,
    }
  })
}
