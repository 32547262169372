import ApiRefType, { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import { Table, TableBody, Theme, Typography, makeStyles } from '@material-ui/core'

import { AssociatedReferencesInterface } from 'store/associatedReferences/interfaces'
import OrderTable from 'components/ReferencesTable/OrderTable'
import React from 'react'
import ShipmentTable from 'components/ReferencesTable/ShipmentTable'
import TransportationTable from 'components/ReferencesTable/TransportationTable'
import cx from 'classnames'

export const BREADCRUMB_PROGRESS = {
  STEP_ONE: 1,
  STEP_TWO: 2,
  STEP_THREE: 3,
  STEP_FOUR: 4,
  STEP_FIVE: 5,
  STEP_SIX: 6,
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 900,
  },
  threeColumn: {
    maxWidth: '100vw',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    paddingBottom: theme.spacing(1),
  },
  tableWrapper: {
    background: theme.palette.custom['grey25'],
  },
  tableWrapperLeft: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
    borderTopLeftRadius: `${theme.shape.borderRadius}px`,
    boxShadow: 'inset -7px 0 9px -7px rgba(0,0,0,0.1)',
  },
  tableWrapperRight: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: 'none',
    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
    boxShadow: 'inset 7px 0 9px -7px rgba(0,0,0,0.1)',
  },
  tableWrapperNoShadow: {
    boxShadow: 'none',
  },
  activeTable: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
  },
  titleText: {
    ...theme.typography.h6,
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(1),
  },
  noContent: {
    alignItems: 'center',
  },
  noContentText: {
    margin: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  offsetLeft: {
    flex: 1,
    paddingLeft: 50,
  },
  columnText: {
    display: 'flex',
  },
  verticalList: {
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    '& td': {
      verticalAlign: 'top',
    },
  },
  subtext: {
    marginTop: `-${theme.spacing(1)}px`,
    display: 'block',
  },
  shipmentTable: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: `${theme.shape.borderRadius}px`,
    minWidth: 200,
    '& td': {
      padding: `0 ${theme.spacing(2)}px`,
    },
    '& tr:first-child td': {
      paddingTop: theme.spacing(1),
    },
    '& tr:last-child td': {
      paddingBottom: theme.spacing(1),
    },
  },
}))

interface Props {
  containerTripId?: string | null
  data: AssociatedReferencesInterface
  refType: ApiRefTypeType
}

const ReferencesTable = (props: Props) => {
  const { containerTripId, data, refType } = props
  const { orders, shipments, transportation } = data
  const classes = useStyles()

  /**
   * Returns a number used for breadcrumbs, which here just means we bold and change color on all section
   * headers up to and including the current section (sections being Order, Line Item, Booking, BOL, Container).
   * Shipment page returns -1 because we don't want any active breadcrumbs in that case.
   * @param refType string. Current page ref type
   */
  const getBreadcrumbIndex = (refType: string): number => {
    const index: { [key: string]: number } = {
      [ApiRefType.sales_order_number]: BREADCRUMB_PROGRESS.STEP_ONE,
      [ApiRefType.purchase_order_number]: BREADCRUMB_PROGRESS.STEP_ONE,
      [ApiRefType.stock_transfer_order_number]: BREADCRUMB_PROGRESS.STEP_ONE,
      [ApiRefType.line_item_number]: BREADCRUMB_PROGRESS.STEP_TWO,
      [ApiRefType.ocean_booking_carrier_reference]: BREADCRUMB_PROGRESS.STEP_THREE,
      [ApiRefType.bill_of_lading_number]: BREADCRUMB_PROGRESS.STEP_FOUR,
      [ApiRefType.container_number]: BREADCRUMB_PROGRESS.STEP_FIVE,
      [ApiRefType.shipment]: BREADCRUMB_PROGRESS.STEP_SIX,
    }

    // `0` means we're on one of the three order-type pages
    return index[refType] ?? 0
  }

  const activeTable = {
    orders:
      getBreadcrumbIndex(refType) === BREADCRUMB_PROGRESS.STEP_ONE ||
      getBreadcrumbIndex(refType) === BREADCRUMB_PROGRESS.STEP_TWO,
    transportation:
      getBreadcrumbIndex(refType) === BREADCRUMB_PROGRESS.STEP_THREE ||
      getBreadcrumbIndex(refType) === BREADCRUMB_PROGRESS.STEP_FOUR ||
      getBreadcrumbIndex(refType) === BREADCRUMB_PROGRESS.STEP_FIVE,
    shipment: refType === ApiRefType.shipment,
  }

  return (
    <div
      className={cx(classes.container, {
        [classes.threeColumn]: shipments?.length > 0,
      })}
      data-testid="references-table"
    >
      <div className={classes.row}>
        <div className={classes.column}>
          <Typography variant="h6" className={classes.titleText}>
            Order
          </Typography>
        </div>
        <div className={classes.column}>
          <Typography variant="h6" className={classes.titleText}>
            Transportation
          </Typography>
        </div>
        {shipments?.length > 0 && (
          <div className={cx(classes.column, classes.offsetLeft)}>
            <Typography variant="h6" className={classes.titleText}>
              Shipments
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.row}>
        <div
          className={cx(classes.column, classes.tableWrapperLeft, classes.tableWrapper, {
            [classes.activeTable]: activeTable.orders,
            [classes.tableWrapperNoShadow]: refType === ApiRefType.shipment,
          })}
        >
          {orders.length === 0 ? (
            <Typography className={classes.noContentText} align="center" color="textSecondary">
              There are no associated
              <br />
              order references
            </Typography>
          ) : (
            <div>
              <Table className={classes.table} data-testid="references-table__orders">
                <TableBody>
                  {orders.map((order: any, idx: number) => (
                    <OrderTable
                      classes={{ subtext: classes.subtext }}
                      data={order}
                      getBreadcrumbIndex={getBreadcrumbIndex}
                      index={idx}
                      key={idx}
                      refType={refType}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
        <div
          className={cx(classes.column, classes.tableWrapperRight, classes.tableWrapper, {
            [classes.activeTable]: activeTable.transportation,
            [classes.tableWrapperNoShadow]: refType === ApiRefType.shipment,
          })}
        >
          <div>
            <Table className={classes.table} data-testid="references-table__transportation">
              <TableBody>
                {transportation?.map((transport: any, idx: number) => (
                  <TransportationTable
                    classes={{ subtext: classes.subtext }}
                    containerTripId={containerTripId}
                    data={transport}
                    getBreadcrumbIndex={getBreadcrumbIndex}
                    index={idx}
                    isActiveTable={activeTable.transportation}
                    key={idx}
                    refType={refType}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        {shipments?.length > 0 && (
          <div className={cx(classes.column, classes.offsetLeft)}>
            <div
              className={cx(classes.shipmentTable, classes.tableWrapper, {
                [classes.activeTable]: activeTable.shipment,
              })}
            >
              <Table data-testid="references-table__shipments">
                <TableBody>
                  {shipments?.map((shipment: any, idx: number) => (
                    <ShipmentTable
                      key={shipment.number}
                      index={idx}
                      data={shipment}
                      refType={refType}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ReferencesTable
