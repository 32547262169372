import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import React from 'react'
import { UserAlertsInterface } from 'store/portalAlerts/interfaces'
import classnames from 'classnames'
import get from 'lodash/get'
import isString from 'lodash/isString'

const useStyles = makeStyles(theme => ({
  filterTextfield: {
    marginLeft: theme.spacing(4),
    width: theme.spacing(27),
  },
  filterTextfieldNarrow: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(15),
  },
  legendStep: {
    color: theme.palette.black,
  },
  labelCopy: {
    ...theme.typography.body1,
    color: theme.palette.grey[600],
    zIndex: 1, // without this the text is hidden when dev tools is open??
    fontWeight: theme.typography.fontWeightMedium,
  },
  italicsMarginAndGrey: {
    ...theme.typography.body2,
    color: theme.palette.grey[500],
    fontStyle: 'italic',
  },
  narrowInput: {
    minWidth: theme.spacing(18),
    marginRight: theme.spacing(2),
  },
  selectWithoutLabel: {
    paddingTop: 18,
  },
  formControl: {
    flexBasis: 0,
    flexGrow: 1,
    minWidth: theme.spacing(25),
  },
  select: {
    maxWidth: theme.spacing(18),
    minWidth: theme.spacing(18),
  },
  checkBoxes: {
    marginRight: theme.spacing(6),
  },
  betweenText: {
    alignSelf: 'flex-start',
    marginTop: `${theme.spacing(2)}px`,
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  values: UserAlertsInterface
  classes?: Record<string, string>
  errors: Record<string, any>
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void
  touched: Record<string, any>
}

const AlertFormStepOne = (props: Props) => {
  const { errors, handleChange, touched, values } = props
  const classes = useStyles(props)
  return (
    <Box className={classnames(classes.formControl, classes.checkBoxes)}>
      <Box display="flex" flexDirection="column">
        <FormLabel className={classes.labelCopy} component="legend">
          <span className={classes.legendStep}>Step 1.</span> Get alerted when any of the following
          occurs:
          <Box mt={1}>
            <Typography variant="body2" className={classes.italicsMarginAndGrey} component="div">
              Select at least one item below to activate Alerting.
            </Typography>
          </Box>
        </FormLabel>
        {Object.keys(values.alerts).map((alertKey, index) => {
          const alert = values.alerts[alertKey]

          return (
            <React.Fragment key={index}>
              {alert.group?.name === 'detention' && alert.group.addHeading && (
                <Box mt={3}>
                  <Typography variant="body1">
                    <strong>Detention:</strong> Containers not received within allotted time
                  </Typography>
                </Box>
              )}
              {alert.group?.name === 'demurrage' && alert.group.addHeading && (
                <Box mt={3}>
                  <Typography variant="body1">
                    <strong>Demurrage:</strong> Containers dwelling over the allotted time
                  </Typography>
                </Box>
              )}
              <FormControlLabel
                label={alert.label}
                control={
                  <Checkbox
                    checked={alert.checked}
                    color="primary"
                    name={`alerts[${alertKey}].checked`}
                    onChange={handleChange}
                  ></Checkbox>
                }
              />
              {alert.checked && alert.inputValue !== undefined && (
                <TextField
                  autoFocus
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // Limit input to numbers >= 1
                    e.target.value = Math.max(1, parseInt(e.target.value)).toString()
                  }}
                  className={classes.filterTextfield}
                  variant="filled"
                  size="small"
                  label={alert.inputLabel}
                  type="number"
                  name={`alerts[${alertKey}].inputValue`}
                  value={alert.inputValue}
                  error={Boolean(
                    get(errors, `alerts[${alertKey}].inputValue`, false) &&
                      get(touched, `alerts[${alertKey}].inputValue`, false)
                  )}
                  helperText={get(errors, `alerts[${alertKey}].inputValue`)}
                  onChange={handleChange}
                />
              )}
              {alert.checked && alert.group && (
                <Box display="flex" flexDirection="row" flexWrap="nowrap" ml={4}>
                  <FormControl
                    className={classnames(classes.formControl, classes.select)}
                    variant="filled"
                  >
                    <InputLabel>Timing</InputLabel>
                    <Select
                      labelId="timingValue"
                      onChange={handleChange}
                      name={`alerts[${alertKey}].timingValue`}
                      value={alert.timingValue || ''}
                    >
                      <MenuItem value="more" key="qualifier-more">
                        More than
                      </MenuItem>
                      <MenuItem value="less" key="qualifier-less">
                        Less than
                      </MenuItem>
                      <MenuItem value="between" key="qualifier-between">
                        Between
                      </MenuItem>
                    </Select>
                    <FormHelperText error={touched.timingValue}>
                      {get(errors, `alerts[${alertKey}].timingValue`)}
                    </FormHelperText>
                  </FormControl>
                  <TextField
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      // Limit input to numbers >= 1
                      e.target.value = Math.max(1, parseInt(e.target.value)).toString()
                    }}
                    className={classes.filterTextfieldNarrow}
                    variant="filled"
                    label={alert.inputLabel}
                    type="number"
                    name={`alerts[${alertKey}].minDaysValue`}
                    value={alert.minDaysValue}
                    error={Boolean(
                      get(errors, `alerts[${alertKey}].minDaysValue`, false) &&
                        get(touched, `alerts[${alertKey}].minDaysValue`, false)
                    )}
                    helperText={get(errors, `alerts[${alertKey}].minDaysValue`)}
                    onChange={handleChange}
                  />
                  {alert.timingValue === 'between' && (
                    <>
                      <Typography className={classes.betweenText} component="span">
                        and
                      </Typography>
                      <TextField
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // Limit input to numbers >= 1
                          e.target.value = Math.max(1, parseInt(e.target.value)).toString()
                        }}
                        className={classes.filterTextfieldNarrow}
                        variant="filled"
                        label={alert.inputLabel}
                        type="number"
                        name={`alerts[${alertKey}].maxDaysValue`}
                        value={alert.maxDaysValue}
                        error={Boolean(
                          get(errors, `alerts[${alertKey}].maxDaysValue`, false) &&
                            get(touched, `alerts[${alertKey}].maxDaysValue`, false)
                        )}
                        helperText={get(errors, `alerts[${alertKey}].maxDaysValue`)}
                        onChange={handleChange}
                      />
                    </>
                  )}
                </Box>
              )}
            </React.Fragment>
          )
        })}
        {errors.alerts && isString(errors.alerts) && (
          <FormHelperText>{errors.alerts}</FormHelperText>
        )}
      </Box>
    </Box>
  )
}

export default AlertFormStepOne
