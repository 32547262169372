import { useLocation } from 'react-router-dom'

/**
 * Parses the querystring for easy searching for a param
 * Usage:
 * const query = useQuery()
 * const name = query.get('name')
 */
export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}
