export class Status {
  constructor(label, color, details) {
    this.label = label
    this.color = color
    this.details = details
  }
}
export class Location {
  constructor(name, unlocode, timezone, lat, lon) {
    this.name = name
    this.unlocode = unlocode
    this.timezone = timezone
    this.lat = lat
    this.lon = lon
  }
}

export class ETA {
  constructor(traced, planned, predicted, originalPlanned) {
    this.traced = traced
    this.planned = planned
    this.predicted = predicted
    this.originalPlanned = originalPlanned
  }
}

export class Carrier {
  constructor(name, scacCode) {
    this.name = name
    this.scacCode = scacCode
  }
}

export class ETAStatus {
  constructor(originalETA, updatedETA, predictedETA, traced) {
    this.originalETA = originalETA
    this.updatedETA = updatedETA
    this.predictedETA = predictedETA
    this.traced = traced
  }
}

export class ETDStatus {
  constructor(originalETD, updatedETD, predictedETD, traced) {
    this.originalETD = originalETD
    this.updatedETD = updatedETD
    this.predictedETD = predictedETD
    this.traced = traced
  }
}

export class Route {
  constructor(milestones, containerTripIds, latestTracedIdx) {
    this.milestones = milestones
    this.containerTripIds = containerTripIds
    this.latestTracedIdx = latestTracedIdx
    this.latestTracedMilestone = null
    if (this.latestTracedIdx !== null && this.latestTracedIdx < this.milestones.length) {
      this.latestTracedMilestone = this.milestones[this.latestTracedIdx]
    }
  }
}

export class Shipment {
  constructor(
    id,
    refID,
    bols,
    containerTripIds,
    receiptLocation,
    loadLocation,
    dischargeLocation,
    deliveryLocation,
    originHaulageType,
    destinationHaulageType,
    oceanBookings,
    carriers,
    originDeparture,
    routes,
    tags,
    latestStatus,
    deliveryStatus,
    lastUpdate,
    etaStatus,
    etdStatus,
    vessels,
    snapshot,
    vesselTrips,
    orders,
    lineItems,
    maxOceanDelay,
    dischargeDates,
    status,
    isIdle,
    shipmentNumbers,
    externalId,
    tripSummary,
    salesOrders,
    purchaseOrders,
    stockTransferOrders,
    containers,
    refType,
    shipmentBoard
  ) {
    this.id = id
    this.ref_id = refID // TODO - KEEP THIS SNAKE CASE...THIS IS A CRAZY CAUSED BY THE FACT THAT OUR SHIPMENT LIST ITEM USES STRUCTURED SHIPMENTS WHILE OUR TABLE USES UNSTRUCTURED SHIPMENTS.  THE CHECKBOX SELECTION PROCESS RELIES ON ref_id, SO WE NEED TO POPULATED IT HERE
    this.bols = bols
    this.containers = containers
    this.containerTripIds = containerTripIds
    this.receiptLocation = receiptLocation
    this.loadLocation = loadLocation
    this.dischargeLocation = dischargeLocation
    this.deliveryLocation = deliveryLocation
    this.originHaulageType = originHaulageType
    this.destinationHaulageType = destinationHaulageType
    this.oceanBookings = oceanBookings
    this.carriers = carriers
    this.originDeparture = originDeparture
    this.routes = routes
    this.tags = tags
    this.latestStatus = latestStatus
    this.deliveryStatus = deliveryStatus
    this.lastUpdate = lastUpdate
    this.etaStatus = etaStatus
    this.etdStatus = etdStatus
    this.vessels = vessels
    this.snapshot = snapshot
    this.vesselTrips = vesselTrips
    this.orders = orders
    this.lineItems = lineItems
    this.maxOceanDelay = maxOceanDelay
    this.dischargeDates = dischargeDates
    this.status = status
    this.isIdle = isIdle
    this.shipments = shipmentNumbers
    this.externalId = externalId
    this.tripSummary = tripSummary
    this.salesOrders = salesOrders
    this.purchaseOrders = purchaseOrders
    this.stockTransferOrders = stockTransferOrders
    this.refType = refType
    this.shipmentBoard = shipmentBoard
  }
}
