import colors, { datavisColors, namedColors } from 'components/core/Colors'

import { createMuiTheme } from '@material-ui/core'
import tinycolor from 'tinycolor2'

export default function createTheme() {
  /**
   * Seperating palette, shape, and brand generation to eagerly merge config with defaults.
   * This provides easier access to automatically calculated semantic rules such as palette.action.disabled
   */
  const { palette, shape, brand } = createMuiTheme({
    palette: {
      ...colors,
      datavis: {
        ...datavisColors,
      },
      named: {
        ...namedColors,
      },
      primary: {
        light: '#ED8C5C',
        main: '#00558B',
        dark: '#9D471B',
      },
      secondary: {
        light: '#7FACDE',
        main: colors.blue[400],
        dark: '#3B638F',
      },
      error: {
        light: '#DB4243',
        main: colors.red[400],
        dark: '#930D0E',
      },
      warning: {
        light: '#FFE74C',
        main: colors.yellow[400],
        dark: '#BF8600',
      },
      info: {
        light: '#5EA3FF',
        main: '#0075D6',
        dark: '#004AA4',
      },
      success: {
        light: '#56BA3D',
        main: '#188900',
        dark: '#005A00',
      },
      background: {
        paper: colors.white,
        default: colors.white,
      },
    },
    shape: {
      borderRadiusSmall: 2,
      borderRadius: 4,
      borderRadiusLarge: 7,
      borderRadiusCircular: '50%',
    },
    brand: {
      color: '#00558B',
      grey: colors.grey[600],
      border: colors.grey[300],
      lightDivider: colors.grey[950],
    },
  })

  // this is a separate createMuiTheme() call so that typography.fontWeightBold can be referenced below
  const { typography } = createMuiTheme({
    typography: {
      /* Font Size:
       * Needs to change for localization:
       *   14px for Chinese symbols
       *   12px for Japanese
       */
      fontSize: 14,
      fontWeightBold: 700,
      fontWeightLight: 300,
      fontWeightMedium: 500,
      fontWeightRegular: 400,
      h1: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 300,
        fontSize: '6rem',
        lineHeight: 1,
        letterSpacing: '-0.01562em',
      },
      h2: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 300,
        fontSize: '3.75rem',
        lineHeight: 1,
        letterSpacing: '-0.00833em',
      },
      h3: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '3rem',
        lineHeight: 1.04,
        letterSpacing: '0em',
      },
      h4: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '2.125rem',
        lineHeight: 1.17,
        letterSpacing: '0.00735em',
      },
      h5: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: 1.33,
        letterSpacing: '0em',
      },
      h6: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
      },
      subtitle1: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.75,
        letterSpacing: '0.00938em',
      },
      subtitle2: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.57,
        letterSpacing: '0.00714em',
      },
      body1: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
      },
      body2: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.5,
        letterSpacing: '0.01071em',
      },
      button: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textTransform: 'none',
      },
      caption: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
      },
      overline: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 2.66,
        letterSpacing: '0.08333em',
        textTransform: 'uppercase',
      },
    },
  })

  return createMuiTheme({
    palette,
    shape,
    brand,
    typography,
    overrides: {
      MuiDialogActions: {
        root: {
          padding: '1em',
        },
      },
      MuiChip: {
        root: {
          alignItems: 'start',
          backgroundColor: palette.common.white,
          color: palette.grey[600],
          height: 'auto',
          lineHeight: 1.5,
          fontSize: 14,
          '& .MuiChip-avatar': {
            background: 'transparent',
            margin: `3px -6px 0 4px`,
          },
          '& .MuiCircularProgress-root': {
            color: palette.grey[300],
            height: `15px !important`,
            width: `15px !important`,
          },
          '& .MuiSvgIcon-root': {
            color: palette.grey[300],
          },
        },
        outlined: {
          border: `1px solid ${palette.grey[300]}`,
          backgroundColor: palette.common.white,
        },
        clickable: {
          '&:hover': {
            backgroundColor: palette.grey[200],
          },
          '&:focus': {
            backgroundColor: `${palette.common.white} !important`,
          },
        },
        deleteIcon: {
          width: 30,
          margin: `5px 5px 0 -2px`,
        },
        label: {
          height: 'auto',
          overflow: 'auto',
          padding: '6px 12px',
          whiteSpace: 'normal',
          textOverflow: 'initial',
          width: '100%',
        },
      },
      MuiInputLabel: {
        filled: {
          '&$shrink': {
            // @transform
            // This keeps Chips within Autocomplete from getting too close to the label
            transform: 'translate(12px, 8px) scale(0.75)',
          },
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: palette.grey[50],
          '&:hover': {
            backgroundColor: palette.grey[200],
          },
          '&$focused': {
            backgroundColor: palette.grey[50],
          },
        },
        underline: {
          '&:after': {
            borderBottom: `2px solid ${palette.grey[500]}`,
          },
        },
      },
      MuiToggleButton: {
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
      MuiButton: {
        root: {
          minWidth: 40,
          '&$disabled': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        startIcon: {
          marginRight: 4,
        },
        // these correspond to the default/dark versions of buttons. see
        // src/components/core/Button/index.js for light versions
        contained: {
          // Button Colors > Default: Grey 600
          backgroundColor: palette.grey[600],
          color: palette.common.white,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: tinycolor(palette.grey[600]).darken(12).toString(),
          },
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: tinycolor(palette.primary.main).darken(12).toString(),
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: tinycolor(palette.secondary.main).darken(12).toString(),
          },
        },
        text: {
          color: palette.grey[600],
          '&:hover': {
            backgroundColor: tinycolor(palette.grey[600]).setAlpha(0.08).toRgbString(),
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: tinycolor(palette.primary.main).setAlpha(0.08).toRgbString(),
          },
        },
        textSecondary: {
          '&:hover': {
            backgroundColor: tinycolor(palette.secondary.main).setAlpha(0.08).toRgbString(),
          },
        },
        // we have to manually specify color and border color due to overriding default styles. This can be avoided by specifying grey defaults within palette: https://material-ui.com/customization/default-theme/#default-theme
        outlined: {
          color: palette.grey[600],
          borderColor: palette.grey[600],
          '&:hover': {
            color: palette.grey[600],
            borderColor: palette.grey[600],
            backgroundColor: tinycolor(palette.grey[600]).setAlpha(0.08).toRgbString(),
          },
          '&$disabled': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
        outlinedPrimary: {
          color: palette.primary.main,
          borderColor: palette.primary.main,
          '&:hover': {
            color: palette.primary.main,
            borderColor: palette.primary.main,
            backgroundColor: tinycolor(palette.primary.main).setAlpha(0.08).toRgbString(),
          },
        },
        outlinedSecondary: {
          color: palette.secondary.main,
          borderColor: palette.secondary.main,
          '&:hover': {
            color: palette.secondary.main,
            borderColor: palette.secondary.main,
            backgroundColor: tinycolor(palette.secondary.main).setAlpha(0.08).toRgbString(),
          },
          '&$disabled': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
        sizeSmall: {
          paddingLeft: 12,
          paddingRight: 12,
        },
        sizeLarge: {
          paddingLeft: 20,
          paddingRight: 20,
          fontSize: 16,
        },
      },
      MuiIconButton: {
        sizeSmall: {
          padding: 6,
        },
      },
      MuiLink: {
        root: {
          color: palette.secondary.main,
        },
      },
      MuiOutlinedInput: {
        root: {
          '& $notchedOutline': {
            borderColor: palette.grey[200],
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: palette.grey[500],
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              borderColor: palette.grey[500],
            },
          },
          '&$focused $notchedOutline': {
            borderColor: palette.grey[500],
          },
        },
      },
      MuiFormHelperText: {
        root: {
          color: palette.text.secondary,
          '&$error': {
            color: palette.text.primary,
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: palette.grey[600],
          '&$focused': {
            color: palette.grey[600],
          },
          '&$error': {
            color: palette.grey[600],
          },
        },
      },
      MuiListItemText: {
        root: {
          marginBottom: 0,
          marginTop: 0,
        },
      },
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor: palette.grey[50],
          },
          '&:selected': {
            backgroundColor: palette.grey[50],
          },
          '&:focus': {
            backgroundColor: palette.grey[50],
          },
        },
      },
      MuiTab: {
        root: {
          color: palette.grey[500],
          borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0px 0px`,
          '&:hover': {
            boxShadow: `inset 0 -2px ${palette.grey[500]}`,
            backgroundColor: palette.grey[50],
          },
          '&.Mui-selected': {
            color: palette.primary.main,
          },
          '&$disabled': {
            color: palette.action.disabled,
          },
        },
        textColorInherit: {
          '&$disabled': {
            opacity: 1.0,
          },
        },
      },
      MuiTableSortLabel: {
        root: {
          opacity: 1,
          '&:focus': {
            color: palette.text.primary,
          },
          '&:hover': {
            color: palette.text.primary,
            opacity: 1,
            '& $icon': {
              opacity: 1,
            },
          },
          '&$active': {
            color: palette.text.primary,
            '&& $icon': {
              opacity: 1,
              color: palette.text.primary,
            },
          },
        },
        icon: {
          opacity: 1,
          color: palette.action.disabled,
        },
      },
      MuiTableCell: {
        root: {
          fontSize: '0.8125rem',
          letterSpacing: 0,
        },
      },
      MuiTooltip: {
        tooltip: {
          '& a': {
            color: palette.white,
            textDecoration: 'none',
            fontWeight: typography.fontWeightBold,
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
      MuiDrawer: {
        root: {
          transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
        paper: {
          backgroundColor: palette.grey[50],
          boxShadow: 'inset -5px 0 4px -5px rgba(0,0,0,0.25)',
          borderRight: 'none',
          top: 0,
          height: '100%',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: palette.grey[950],
        },
      },
    },
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  })
}
