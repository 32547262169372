import { AuthSubtitle, AuthTitle } from 'components/AuthTitle'

import AuthDialog from 'components/AuthDialog'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { LOGIN_ROUTE } from 'utils/routes'
import React from 'react'
import RequestInvitationForm from './RequestInvitationForm'
import { fetchRequestInvitation } from 'store/requestInvitation/actions'
import { makeStyles } from '@material-ui/core'
import { setLoginErrorMessage } from 'store/auth/login/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  newAuthTitle: {
    paddingTop: theme.spacing(3),
  },
  newAuthSubtitle: {
    color: theme.palette.grey[500],
  },
}))

interface Props {
  authTitle?: string
  authSubtitle?: string
  submitButtonTitle?: string
}

const RequestInvitationPage = (props: Props) => {
  const { authTitle, authSubtitle, submitButtonTitle } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const onSubmitEmail = (params: { email: string }) => {
    dispatch(fetchRequestInvitation(params.email))
    dispatch(setLoginErrorMessage(null))
    history.push(LOGIN_ROUTE.buildUrl())
  }

  const classes = useStyles()
  return (
    <CenteredLayout className={classes.root}>
      <AuthDialog>
        <AuthTitle className={classes.newAuthTitle}>
          {authTitle || 'Request Registration Link'}
        </AuthTitle>
        <AuthSubtitle className={classes.newAuthSubtitle}>
          {authSubtitle ||
            "If you didn't receive a link or need a new one, enter your email and click Request Link."}
        </AuthSubtitle>
        <RequestInvitationForm onSubmit={onSubmitEmail} submitButtonTitle={submitButtonTitle} />
      </AuthDialog>
    </CenteredLayout>
  )
}

export default RequestInvitationPage
