// Length just uses given Yup validation
export const MINIMUM_PASSWORD_LENGTH = 8
export const minLength = {
  charLength: MINIMUM_PASSWORD_LENGTH,
  errorMessage: `Must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`,
  helperText: `Must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`,
}

export const lowercase = {
  errorMessage: 'Must contain at least one lowercase letter',
  helperText: 'Must contain at least one lowercase letter (ex: a, b, c, d)',
  testName: 'has-lowercase',
  valid: string => new Promise(resolve => resolve(/[a-z]/.test(string))),
}

export const uppercase = {
  errorMessage: 'Must contain at least one uppercase letter',
  helperText: 'Must contain at least one uppercase letter (ex: A, B, C, D)',
  testName: 'has-uppercase',
  valid: string => new Promise(resolve => resolve(/[A-Z]/.test(string))),
}

export const numeric = {
  errorMessage: 'Must contain at least one number',
  helperText: 'Must contain at least one number (ex: 1, 2, 3, 4)',
  testName: 'has-numeric',
  valid: string => new Promise(resolve => resolve(/[0-9]/.test(string))),
}

export const specialChar = {
  errorMessage: 'Must contain at least one special character',
  helperText: 'Must contain at least one special character (ex: !, #, $, %)',
  testName: 'has-special-character',
  valid: string => new Promise(resolve => resolve(/[\W+]/.test(string))),
}

export const passwordValidations = [minLength, lowercase, uppercase, numeric, specialChar]
