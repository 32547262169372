// See `components/core/Button/Button.stories.notes.md` for implementation notes
import {
  closeCommentsDrawer,
  getIsCommentsDrawerOpen,
  openCommentsDrawer,
} from 'utils/routes/comments'

import Button from 'components/core/Button'
import CommentIcon from 'components/icons/CommentIcon'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from '@material-ui/core'
import logger from 'utils/logger'
import { withRouter } from 'react-router-dom'

const LOGGER_MESSAGE_DRAWER_OPEN = 'Comments Drawer Button Open'
const LOGGER_MESSAGE_DRAWER_CLOSE = 'Comments Drawer Close'

const CommentsToggleDrawerButton = function ({ externalId, match, history, location }) {
  const handleDrawerOpenClose = () => {
    if (getIsCommentsDrawerOpen(match)) {
      logger.notify(LOGGER_MESSAGE_DRAWER_CLOSE, { externalId })
      closeCommentsDrawer({ history, location })
    } else {
      logger.notify(LOGGER_MESSAGE_DRAWER_OPEN, { externalId })
      openCommentsDrawer({ history, location })
    }
  }

  return (
    <Tooltip title="Comments">
      <span>
        <Button
          variant="outlinedAlternateDark"
          onClick={handleDrawerOpenClose}
          data-testid={'entity-comments-drawer-button'}
        >
          <CommentIcon color="primary" />
        </Button>
      </span>
    </Tooltip>
  )
}

CommentsToggleDrawerButton.propTypes = {
  externalId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default withRouter(CommentsToggleDrawerButton)
