import { Collapse, withStyles } from '@material-ui/core'
import React, { useLayoutEffect, useState } from 'react'

import BaseCell from './BaseCell'
import cx from 'classnames'

const styles = theme => ({
  expandableCell: {
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: `0.5px solid ${theme.palette.grey[500]}`,
  },
  expandableCellContentWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  hiddenBorder: { border: 'none' },
})

const ExpandableCell = withStyles(styles)(({ classes, children, isExpanded, ...restProps }) => {
  /*
  We maintain an internal showing / not showing state here, which is updated immediately with useLayoutEffect
  to trigger the open animation.
   */
  const [showing, setShowing] = useState(false)
  useLayoutEffect(() => {
    if (isExpanded) {
      setShowing(true)
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <BaseCell
      className={cx(classes.expandableCell, { [classes.hiddenBorder]: !showing })}
      {...restProps}
    >
      <Collapse in={showing} timeout={'auto'} unmountOnExit={true}>
        <div className={classes.expandableCellContentWrapper}>{children}</div>
      </Collapse>
    </BaseCell>
  )
})

export default ExpandableCell
