import React, { useEffect, useRef, useState } from 'react'

import { Tooltip } from '@material-ui/core'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core'

const makeImportant = styleObj => {
  const importantObj = {}
  Object.keys(styleObj).forEach(key => {
    importantObj[key] = styleObj[key] + ' !important'
  })
  return importantObj
}

const useStyles = makeStyles(theme => ({
  root: makeImportant({
    padding: '8px',
    fontFamily: theme.typography.fontFamily,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  body2: makeImportant(theme.typography.body2),
  caption: makeImportant(theme.typography.caption),
  strong: makeImportant({ fontWeight: theme.typography.fontWeightBold }),
  ellipsis: makeImportant({ overflow: 'hidden', textOverflow: 'ellipsis' }),
}))

export default function MentionsMenuItem({ user, query }) {
  const classes = useStyles()
  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const [isMenuItemTruncated, setIsMenuItemTruncated] = useState(false)

  useEffect(() => {
    if (nameRef.current && nameRef.current.offsetWidth < nameRef.current.scrollWidth) {
      setIsMenuItemTruncated(true)
    }
    if (emailRef.current && emailRef.current.offsetWidth < emailRef.current.scrollWidth) {
      setIsMenuItemTruncated(true)
    }
  }, [nameRef, emailRef])

  const userHasFullName = Boolean(user.firstName && user.lastName)

  const makeHighlightSpan = name => {
    if (name.toLowerCase().startsWith(query.toLowerCase())) {
      return (
        <span>
          <span className={classes.strong}>{name.slice(0, query.length)}</span>
          <span>{name.slice(query.length, name.length)}</span>
        </span>
      )
    }
    return <span>{name}</span>
  }

  const tooltipTitle = isMenuItemTruncated ? (
    userHasFullName ? (
      <>
        <div>
          {user.firstName} {user.lastName}
        </div>
        <div>{user.email}</div>
      </>
    ) : (
      user.email
    )
  ) : null

  return (
    <ConditionalTooltip title={tooltipTitle} placement="left">
      <div className={classes.root}>
        {userHasFullName && (
          <div className={cx(classes.body2, classes.ellipsis)} ref={nameRef}>
            {makeHighlightSpan(user.firstName)} {makeHighlightSpan(user.lastName)}
          </div>
        )}
        <div
          ref={emailRef}
          className={cx(
            { [classes.body2]: !userHasFullName, [classes.caption]: userHasFullName },
            classes.ellipsis
          )}
        >
          {makeHighlightSpan(user.email)}
        </div>
      </div>
    </ConditionalTooltip>
  )
}

const ConditionalTooltip = ({ title, placement, children }) =>
  title ? (
    <Tooltip title={title} placement={placement}>
      {children}
    </Tooltip>
  ) : (
    children
  )
