import groupBy from 'lodash/groupBy'

export const constructORLogic = filters => {
  if (!filters || !Array.isArray(filters)) {
    return {}
  }
  return {
    or: filters.map(({ operator, key, val }) => ({
      [operator]: [{ var: key }, val],
    })),
  }
}

export const convertToJSONLogic = filters => {
  if (!filters || !Array.isArray(filters)) {
    return {}
  }
  let groupedFilters = []

  // We will group by a list of filters based on the orGroup field in the each filter object. If we want to
  // make two filters have OR logics, we should set the orGroup field of those filters to the same. So that after
  // we group by , we can construct OR logic around those filters.
  // If the filter doesn't have orGroup field defined, we will construct AND logic around those filters
  Object.entries(groupBy(filters, item => item.orGroup)).forEach(([groupName, subFiltersList]) => {
    if (subFiltersList.length > 1 && groupName !== 'undefined') {
      groupedFilters.push(constructORLogic(subFiltersList))
    } else
      subFiltersList.forEach(filter =>
        groupedFilters.push({ [filter.operator]: [{ var: filter.key }, filter.val] })
      )
  })

  return { and: groupedFilters }
}
