import { BAR_POINT_WIDTH, makeTooltip } from './utils'
import { getHighChartAxisTitleDefaults, getHighChartDefaults } from '../utils/highCharts'

import HighChart from 'components/HighChart'
import React from 'react'
import { formatLocationLabel } from 'utils/strUtils'
import merge from 'lodash/merge'

export function TopVolumeDwellTimes({ data, theme }) {
  const categories = data.map(s => formatLocationLabel(s.endLocation.name))
  const dwells = data.map(s => s.metrics.metricsMean)
  const volumes = data.map(s => s.metrics.count)

  const highChartDefaultOptions = getHighChartDefaults(theme)
  const highchartOptions = {
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: 'Top Dwell Times by Volume',
      style: { visibility: 'hidden' },
    },
    plotOptions: {
      series: {
        pointWidth: BAR_POINT_WIDTH,
      },
    },
    xAxis: [
      {
        categories,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        title: {
          text: 'Containers',
          ...getHighChartAxisTitleDefaults(theme),
        },
      },
      {
        title: {
          text: 'Average Dwell Time (days)',
          ...getHighChartAxisTitleDefaults(theme),
        },
        opposite: true,
      },
    ],
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      floating: true,
      borderWidth: 1,
      borderColor: theme.palette.black,
      backgroundColor: theme.palette.white,
      itemMarginTop: 5,
      itemMarginBottom: 5,
      itemStyle: {
        ...theme.typography.body2,
      },
      reversed: true,
      x: -50,
    },
    tooltip: {
      formatter: function () {
        return makeTooltip(data[this.points[0].point.index])
      },
      shared: true,
      borderColor: theme.palette.black,
    },
    series: [
      {
        name: 'Dwell Time',
        type: 'column',
        yAxis: 1,
        data: dwells,
        color: theme.palette.datavis.blue[500],
      },
      {
        name: 'Containers',
        type: 'spline',
        data: volumes,
        color: theme.palette.black,
      },
    ],
  }

  return (
    <div data-testid="user-dashboard__top-volume-dwell-times">
      <HighChart options={merge({}, highChartDefaultOptions, highchartOptions)} />
    </div>
  )
}
