import humps from 'humps'

export interface CustomFieldInterface {
  key: string
  type: string
  values: string[]
}

export default class CustomField {
  constructor({ key, type, values }: CustomFieldInterface) {
    Object.assign(this, { key, type, values })
  }

  static of(payload: Object) {
    const { key, type, values } = humps.camelizeKeys(payload) as CustomFieldInterface
    return new CustomField({ key, type, values })
  }
}
