const MilestoneAction = {
  unload: 'unload',
  depart: 'depart',
  load: 'load',
  arrive: 'arrive',
} as const

export type MilestoneActionType = typeof MilestoneAction[keyof typeof MilestoneAction]

export default MilestoneAction
