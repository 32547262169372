import { sagas as alertsSagas } from 'store/alerts'
import { sagas as associatedReferencesSagas } from 'store/associatedReferences/sagas'
import { sagas as boardSagas } from 'store/board/sagas'
import { sagas as boardUtilsSagas } from 'store/boardUtils/sagas'
import { sagas as commentsSagas } from 'store/comments/sagas'
import { sagas as defaultFilterSagas } from 'store/defaultFilters'
import { sagas as downloadsSagas } from 'store/downloads/sagas'
import { sagas as exportSagas } from 'store/export'
import { sagas as filterGroupSagas } from 'store/filterGroups/sagas'
import { sagas as planningSagas } from 'store/planning/sagas'
import { sagas as portalAlertsSagas } from 'store/portalAlerts/sagas'
import { sagas as portalUsersSagas } from 'store/portalUsers/sagas'
import { sagas as publicShareSagas } from 'store/publicShare/sagas'
import { sagas as sharingSagas } from 'store/share/sagas'
import { sagas as suggestSagas } from 'store/suggest/sagas'
import { sagas as topLanesSagas } from 'store/topLanes/sagas'
import { sagas as userDashboardSagas } from 'store/userDashboard/sagas'

export const storeSagas = [
  ...alertsSagas,
  ...associatedReferencesSagas,
  ...boardSagas,
  ...boardUtilsSagas,
  ...commentsSagas,
  ...defaultFilterSagas,
  ...downloadsSagas,
  ...exportSagas,
  ...filterGroupSagas,
  ...planningSagas,
  ...portalAlertsSagas,
  ...portalUsersSagas,
  ...publicShareSagas,
  ...sharingSagas,
  ...suggestSagas,
  ...topLanesSagas,
  ...userDashboardSagas,
]
