import Button from 'components/core/Button'
import PropTypes from 'prop-types'
import React from 'react'
import SaveView from 'components/ViewList/SaveView'
import styles from './styles'
import { withStyles } from '@material-ui/core'

export function ViewActions(props) {
  const {
    classes,
    clearFilters,
    createView,
    currentView,
    drawerKey,
    filters,
    isAlertSupported,
    totalCount,
    updateView,
  } = props
  return (
    <div className={classes.filterActions} data-testid="view-list__view-actions">
      <Button
        onClick={() => clearFilters()}
        className={classes.clearFiltersBtn}
        size="small"
        color="primary"
        variant="text"
        data-testid="view-list__view-actions__clear-button"
      >
        Clear Filters
      </Button>
      <SaveView
        drawerKey={drawerKey}
        createView={createView}
        currentView={currentView}
        filters={filters}
        totalCount={totalCount}
        updateView={updateView}
        isAlertSupported={isAlertSupported}
      />
    </div>
  )
}

ViewActions.propTypes = {
  classes: PropTypes.object.isRequired,
  clearFilters: PropTypes.func.isRequired,
  createView: PropTypes.func.isRequired,
  currentView: PropTypes.object,
  drawerKey: PropTypes.string,
  filters: PropTypes.array.isRequired,
  isAlertSupported: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  updateView: PropTypes.func.isRequired,
}

export default withStyles(styles)(ViewActions)
