import { GroupedByUnlocodeMilestoneInterface } from './interfaces'
import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'

/**
 * Sorts grouped-by-stage object and sorts the milestones in each by location (`unlocode`).
 * @param groupedByStageMilestones object with stage buckets: { preMain: [], main: [], postMain: []}
 * @returns same object shape but with milestones in each bucket sorted by location.
 */
export const sortMilestonesByUnlocode = (
  groupedByStageMilestones: GroupedByUnlocodeMilestoneInterface
) => {
  forEach(groupedByStageMilestones, group => {
    sortBy(group, milestone => milestone.locationZone?.unlocode)
  })

  return groupedByStageMilestones
}

export default sortMilestonesByUnlocode
