import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { closeSupportChat, openSupportChat } from 'store/support/actions'
import { useDispatch, useSelector } from 'react-redux'

import BackgroundImage from 'assets/planning-background.png'
import Link from 'components/core/Link'
import PaywallDialogImage from 'assets/planning-hero-2x.png'
import React from 'react'
import { isImpersonatingSelector } from 'store/auth/impersonate/selectors'
import { supportChatOpenSelector } from 'store/support'

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    position: 'fixed',
    maxHeight: '140vh',
    // also consider maxWidth: 100%
  },
  dialog: {
    width: 600,
  },
  dialogImage: {
    maxWidth: '100%',
  },
  dialogTitle: {
    paddingBottom: 0,
    textAlign: 'center',
  },
  dialogActions: {
    paddingBottom: theme.spacing(3),
    justifyContent: 'center',
  },
  dialogContent: {
    paddingTop: 0,
  },
  list: {
    color: theme.palette.grey[600],
    marginTop: 0,
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  backdropRoot: {
    left: 100,
  },
}))

interface Props {
  classes?: { [key: string]: string }
}

const PaywallPage = (props: Props) => {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const isSupportChatOpen = useSelector<boolean>(supportChatOpenSelector)
  const isImpersonating = useSelector<boolean>(isImpersonatingSelector)

  return (
    <Box data-testid="paywall-page">
      <img alt="" className={classes.background} src={BackgroundImage} />

      <Dialog
        BackdropProps={{
          classes: {
            root: classes.backdropRoot,
          },
        }}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // We have to do this here because MUI inlines the `zIndex` at 1300
        style={{ zIndex: 1 }}
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          <Typography variant="body2" color="textSecondary">
            This product is not included in your organization’s subscription.
          </Typography>
          <Box mt={1}>
            <Typography variant="h5" color="textPrimary">
              Upgrade to Use CDX Transport Planning
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <img alt="" className={classes.dialogImage} src={PaywallDialogImage} />
          <Box>
            <ul className={classes.list}>
              <li>
                <Typography component="span" variant="body1" color="textPrimary">
                  Real-time KPIs including transit times, schedule reliability, and roll rates.
                </Typography>
              </li>
              <li>
                <Typography component="span" variant="body1" color="textPrimary">
                  Analytics and data visualizations to track carriers’ performance on individual
                  lanes as well as comparing routes.
                </Typography>
              </li>
              <li>
                <Typography component="span" variant="body1" color="textPrimary">
                  Access to Lead-Time information to inform customer-facing promise dates so you can
                  drive higher OTD (on-time delivery).
                </Typography>
              </li>
            </ul>
          </Box>

          <Box>
            <Typography variant="body1" color="textPrimary">
              Contact{' '}
              <Link
                component="a"
                data-testid="paywall-page__support-link"
                href="#"
                onClick={(e?: React.MouseEvent<HTMLElement>) => {
                  e?.preventDefault()
                  if (isImpersonating) {
                    return window.alert(
                      'You cannot use Support because you are impersonating a user'
                    )
                  }
                  if (isSupportChatOpen) {
                    dispatch(closeSupportChat())
                  } else {
                    dispatch(openSupportChat())
                  }
                }}
              >
                project44 Support
              </Link>{' '}
              to upgrade now.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default PaywallPage
