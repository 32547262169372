import { Box, Typography, makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'

import { CONTAINER_ROUTE } from 'utils/routes'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Link from 'components/core/Link'
import NotProvided from 'components/NotProvided'
import PopoverList from 'components/PopoverList'
import { TripInterface } from 'store/models/Trip'
import logger from 'utils/logger'
import { pluralizeWord } from 'utils/strUtils'

const useStyles = makeStyles(theme => ({
  cell: {},
  muted: {},
  expandButton: {
    borderRadius: 0,
    height: 19,
    padding: 0,
    position: 'relative',
  },
  expandIcon: {
    color: theme.palette.grey[500],
    fontSize: 22,
  },
  popover: {
    '& .MuiBox-root:focus': {
      outline: 'none',
    },
    '& li:focus': {
      outline: 'none',
    },
  },
  containerListItem: {
    minWidth: 215,
    paddingBottom: 2,
    paddingTop: 2,
  },
  popoverHover: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)', // needs to match MUI's IconButton hover bg
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
    },
  },
}))

interface Props {
  classes?: Record<string, string>
  isPublicShare?: boolean
  refTypeLabel?: string
  source?: 'page' | 'timeline'
  trips: TripInterface[]
}

/**
 * Left side of the `EntityPageHeader` component
 */
export const ContainerCell = (props: Props) => {
  const classes = useStyles(props)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerListAnchorRef = useRef(null)
  const { isPublicShare = false, refTypeLabel = '', source = 'page', trips } = props
  const handleClose = () => {
    logger.notify('Container List Close Popover')
    setAnchorEl(null)
  }
  const handleClick = () => {
    logger.notify(`Container List Click - ${source === 'page' ? 'Header' : 'Subheader'}`)
    setAnchorEl(containerListAnchorRef.current)
  }

  return (
    <>
      {/* All other entity pages display container numbers */}
      <Typography variant="body2" className={classes.muted}>
        {pluralizeWord('Container', trips.length !== 1)}
      </Typography>
      {/* If one trip, no popover, just the container number */}
      {trips.length === 1 ? (
        isPublicShare ? (
          <>{trips[0].number}</>
        ) : (
          <Link
            to={CONTAINER_ROUTE.buildUrl({
              number: trips[0].number,
              externalId: trips[0].externalId,
            })}
          >
            {trips[0].number}
          </Link>
        )
      ) : (
        <Box>
          {/* Multiple container numbers show count and have a popover */}
          <Box
            display="flex"
            flexDirection="row"
            className={classes.popoverHover}
            onClick={handleClick}
          >
            <Box flexGrow="1">
              {trips.length > 0 ? (
                <Typography variant="body2" ref={containerListAnchorRef}>
                  {trips.length} Total
                </Typography>
              ) : (
                <NotProvided />
              )}
            </Box>
            {trips.length > 0 && (
              <Box className={classes.expandButton} id="containerListAnchorRef">
                {Boolean(anchorEl) ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </Box>
            )}
          </Box>

          <PopoverList
            anchorEl={anchorEl}
            clipboardData={trips.map((trip: TripInterface) => trip.number).join(',')}
            copyAllOnClick={() => logger.notify(`${refTypeLabel} Container List Copy All Click`)}
            handleClose={handleClose}
            isPublicShare={isPublicShare}
            listData={trips.map((trip: TripInterface, idx: number) => (
              <Box className={classes.containerListItem} key={`container-number-${idx}`}>
                {!isPublicShare ? (
                  <Link
                    onClick={() =>
                      logger.notify(`${refTypeLabel} Container List Container ID Click`)
                    }
                    to={CONTAINER_ROUTE.buildUrl({
                      number: trip.number,
                      externalId: trip.externalId,
                    })}
                  >
                    {trip.number}
                  </Link>
                ) : (
                  <>{trip.number}</>
                )}
              </Box>
            ))}
          />
        </Box>
      )}
    </>
  )
}

export default ContainerCell
