import {
  BOL_ROUTE,
  BOOKING_ROUTE,
  CONTAINER_ROUTE,
  DEPRECATED_BOL_ROUTE,
  DEPRECATED_BOOKING_ROUTE,
  DEPRECATED_ORDER_ITEM_ROUTE,
  DEPRECATED_PURCHASE_ORDER_ROUTE,
  DEPRECATED_SALES_ORDER_ROUTE,
  DEPRECATED_SHIPMENT_ROUTE,
  DEPRECATED_STOCK_TRANSFER_ORDER_ROUTE,
  FOUR_OH_FOUR_ROUTE,
  ORDER_ITEM_ROUTE,
  PURCHASE_ORDER_ROUTE,
  SALES_ORDER_ROUTE,
  SHIPMENT_ROUTE,
  STOCK_TRANSFER_ORDER_ROUTE,
} from 'utils/routes'

import get from 'lodash/get'
import humps from 'humps'
import logger from 'utils/logger'

// Ideal as of https://github.com/clearmetal/ingestion/pull/1749
export const ROLLUP_BOOKING_NUMBERS = 'booking_numbers'
export const ROLLUP_LINE_ITEMS = 'line_item_numbers'
export const ROLLUP_SALES_ORDER_NUMBERS = 'sales_order_numbers'
export const ROLLUP_PURCHASE_ORDER_NUMBERS = 'purchase_order_numbers'
export const ROLLUP_STOCK_TRANSFER_ORDER_NUMBERS = 'stock_transfer_order_numbers'

export const ROLLUP_BOL_NUMBERS = 'bol_numbers'
export const ROLLUP_SHIPMENT_NUMBERS = 'shipment_numbers'

export const ROLLUP_LINE_ITEM = 'line_item_number'
export const ROLLUP_SALES_ORDERS = 'sales_order_number'
export const ROLLUP_PURCHASE_ORDERS = 'purchase_order_number'
export const ROLLUP_STOCK_TRANSFER_ORDERS = 'stock_transfer_order_number'
export const ROLLUP_BOOKINGS = 'ocean_booking_carrier_reference'
export const ROLLUP_BOLS = 'bill_of_lading_number'
export const ROLLUP_CONTAINER = 'container_number'
export const ROLLUP_SHIPMENT = 'shipment'

export const PRIMARY_MODE_RAIL = 'rail'
export const PRIMARY_MODE_OCEAN = 'ocean'

export const PRIMARY_MODES = {
  [PRIMARY_MODE_RAIL]: 'rail',
  [PRIMARY_MODE_OCEAN]: 'ocean',
}

export const ROLLUPS = [
  ROLLUP_SALES_ORDERS,
  ROLLUP_PURCHASE_ORDERS,
  ROLLUP_STOCK_TRANSFER_ORDERS,
  ROLLUP_LINE_ITEM,
  ROLLUP_BOOKINGS,
  ROLLUP_BOLS,
  ROLLUP_CONTAINER,
  ROLLUP_SHIPMENT,
]

export const ROLLUPS_PORTAL = [
  ROLLUP_BOOKINGS,
  ROLLUP_SALES_ORDERS,
  ROLLUP_PURCHASE_ORDERS,
  ROLLUP_STOCK_TRANSFER_ORDERS,
  ROLLUP_BOLS,
  ROLLUP_CONTAINER,
  ROLLUP_SHIPMENT,
]

export const ORDER_REF_TYPES = [
  ROLLUP_SALES_ORDERS,
  ROLLUP_PURCHASE_ORDERS,
  ROLLUP_STOCK_TRANSFER_ORDERS,
]

export const PORTAL_USER_EXCLUDED_REF_TYPES = [ROLLUP_LINE_ITEM]

// TODO - this is super confusing.  need to simplify
const REF_TYPES = {
  [ROLLUP_LINE_ITEM]: 'line_item_number',
  [ROLLUP_SALES_ORDERS]: 'sales_order_number',
  [ROLLUP_PURCHASE_ORDERS]: 'purchase_order_number',
  [ROLLUP_STOCK_TRANSFER_ORDERS]: 'stock_transfer_order_number',
  [ROLLUP_BOOKINGS]: 'ocean_booking_carrier_reference',
  [ROLLUP_BOLS]: 'bill_of_lading_number',
  [ROLLUP_CONTAINER]: 'container_number',
  [ROLLUP_SHIPMENT]: 'shipment',
}

const ROLLUP_ENTITY_PARAMS = {
  [ROLLUP_LINE_ITEM]: 'line_item_numbers',
  [ROLLUP_SALES_ORDERS]: 'sales_order_numbers',
  [ROLLUP_PURCHASE_ORDERS]: 'purchase_order_numbers',
  [ROLLUP_STOCK_TRANSFER_ORDERS]: 'stock_transfer_order_numbers',
  [ROLLUP_BOOKINGS]: 'booking_numbers',
  [ROLLUP_BOLS]: 'bol_numbers',
}

const ROLLUP_LABELS = {
  [ROLLUP_LINE_ITEM]: 'Line Items',
  [ROLLUP_SALES_ORDERS]: 'Sales Orders',
  [ROLLUP_PURCHASE_ORDERS]: 'Purchase Orders',
  [ROLLUP_STOCK_TRANSFER_ORDERS]: 'Stock Transfer Orders',
  [ROLLUP_BOOKINGS]: 'Bookings',
  [ROLLUP_BOLS]: 'Bills of Lading',
  [ROLLUP_CONTAINER]: 'Containers',
  [ROLLUP_SHIPMENT]: 'Shipments',
}

export const REF_TYPE_LABELS_ABBREVIATED = {
  [ROLLUP_STOCK_TRANSFER_ORDERS]: 'STO',
}

const REF_TYPE_LABELS = {
  [ROLLUP_LINE_ITEMS]: 'Line Item',
  [ROLLUP_LINE_ITEM]: 'Line Item',
  [ROLLUP_SALES_ORDERS]: 'Sales Order',
  [ROLLUP_SALES_ORDER_NUMBERS]: 'Sales Order',
  [ROLLUP_PURCHASE_ORDERS]: 'Purchase Order',
  [ROLLUP_PURCHASE_ORDER_NUMBERS]: 'Purchase Order',
  [ROLLUP_STOCK_TRANSFER_ORDERS]: 'Stock Transfer Order',
  [ROLLUP_STOCK_TRANSFER_ORDER_NUMBERS]: 'Stock Transfer Order',
  [ROLLUP_BOOKINGS]: 'Booking',
  [ROLLUP_BOOKING_NUMBERS]: 'Booking',
  [ROLLUP_BOLS]: 'Bill of Lading',
  [ROLLUP_BOL_NUMBERS]: 'Bill of Lading',
  [ROLLUP_CONTAINER]: 'Container',
  [ROLLUP_SHIPMENT_NUMBERS]: 'Shipment',
  [ROLLUP_SHIPMENT]: 'Shipment',
}

export const CONTAINER_API_REF_TYPE = 'trips'

/**
 * Currently, the app uses `container_number` for the container ref type but the API wants `trips`.
 * This is a temporary hack that feels like the least offensive. We will be standardizing all
 * entity types with the backend soon and this will go away.
 */
export const translatedRefType = refType =>
  refType === ROLLUP_CONTAINER ? CONTAINER_API_REF_TYPE : refType

/**
 * @todo this is fine as long as legacy and the newer APIs have the same first index
 */
export function getFirstRollup() {
  return ROLLUPS[0]
}

export function getPortalUserRollups() {
  return ROLLUPS_PORTAL
}

export function getRefTypeLabel(rollup) {
  return REF_TYPE_LABELS[rollup]
}

export function getRefType(rollup) {
  return REF_TYPES[rollup]
}

export function getRollupEntityParam(rollup) {
  return ROLLUP_ENTITY_PARAMS[rollup]
}

export function getRollupLabel(rollup) {
  return ROLLUP_LABELS[rollup]
}

export function parseOrderItem(id) {
  const lastHyphenIdx = id.lastIndexOf('-')
  return [id.slice(0, lastHyphenIdx), id.slice(lastHyphenIdx + 1, id.length)]
}

/**
 *
 * @param type Rollup
 * @param id entity id
 * @param externalId optional, container.externalId, default: `""`, currently used/required only by `CONTAINER_ROUTE`
 */
export function getShipmentDetailPath(type, id, externalId = '') {
  // uses of the deprecated routes will come from the following sources:
  // - DeprecatedAssociatedReferencesTable component
  // - ContainerHeaderRow component
  // - getShipmentDetailPath() in src/store/board/utils/columns/index.js that are used on the board
  // - Cypress tests that manually load a given url (such as alert_email_share_modal.spec.js) where stating
  // an external ID is inconvenient as long as external IDs are set randomly
  // - existing bookmarks/links by users (including references in Pivoal Tracker stories, etc)
  switch (type) {
    case ROLLUP_SALES_ORDERS:
      if (externalId) {
        return SALES_ORDER_ROUTE.buildUrl({ number: id, externalId })
      } else {
        return DEPRECATED_SALES_ORDER_ROUTE.buildUrl({ id })
      }
    case ROLLUP_PURCHASE_ORDERS:
      if (externalId) {
        return PURCHASE_ORDER_ROUTE.buildUrl({ number: id, externalId })
      } else {
        return DEPRECATED_PURCHASE_ORDER_ROUTE.buildUrl({ id })
      }
    case ROLLUP_STOCK_TRANSFER_ORDERS:
      if (externalId) {
        return STOCK_TRANSFER_ORDER_ROUTE.buildUrl({ number: id, externalId })
      } else {
        return DEPRECATED_STOCK_TRANSFER_ORDER_ROUTE.buildUrl({ id })
      }
    case ROLLUP_BOOKINGS:
      if (externalId) {
        return BOOKING_ROUTE.buildUrl({ number: id, externalId })
      } else {
        return DEPRECATED_BOOKING_ROUTE.buildUrl({ id })
      }
    case ROLLUP_BOLS:
      if (externalId) {
        return BOL_ROUTE.buildUrl({ number: id, externalId })
      } else {
        return DEPRECATED_BOL_ROUTE.buildUrl({ id })
      }
    case ROLLUP_LINE_ITEM:
      const [orderNumber, itemNumber] = parseOrderItem(id)
      if (externalId) {
        return ORDER_ITEM_ROUTE.buildUrl({ orderNumber, itemNumber, externalId })
      } else {
        return DEPRECATED_ORDER_ITEM_ROUTE.buildUrl({ orderId: orderNumber, itemId: itemNumber })
      }
    case ROLLUP_CONTAINER:
      return CONTAINER_ROUTE.buildUrl({ number: id, externalId })
    case ROLLUP_SHIPMENT:
      if (externalId) {
        return SHIPMENT_ROUTE.buildUrl({ number: id, externalId })
      } else {
        return DEPRECATED_SHIPMENT_ROUTE.buildUrl({ id })
      }
    default:
      logger.captureAppException(`Unrecognized rollup type: ${type}`)
      // @todo what's the best thing to do by default?
      return FOUR_OH_FOUR_ROUTE.buildUrl()
  }
}

export function combineGlobalAndTimelineReferences(shipment, key) {
  // the timeline references will currently have the decamelized key
  const decamelizedKey = humps.decamelize(key)
  let combinedReferences = get(shipment, key, [])
  get(shipment, 'routes', []).forEach(route => {
    const routeReferences = get(route, `references.${decamelizedKey}`, [])
    combinedReferences = combinedReferences.concat(routeReferences)
  })
  return [...new Set(combinedReferences)]
}
