import { AuthStatus, UserInfo } from './models'
import { MESSAGE_TYPES, showNotification } from 'store/notifications/index'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import api from '../api'
import { isIE11 } from 'utils/browsers'
import logger from 'utils/logger'
import { types } from '../actions'
import { updateSubTenants } from 'store/auth/tenant/actions'

export function* userInfoAsync() {
  try {
    const response = yield call(api.fetchUserInfo)
    const data = response && response.data && UserInfo.of(response.data)
    yield put({ type: types.USER_SUCCESS, payload: data })
    logger.notify('Fetched User Info', data)
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: types.USER_FAILURE })
    logger.notify('Fetch User Info Failed', { error: e.message })
  }
}

export function* showInternetExplorerDeprecationWarning() {
  if (isIE11()) {
    yield put(
      showNotification(MESSAGES.ie11Warning, {
        type: MESSAGE_TYPES.WARNING,
        isPersistent: true,
      })
    )
  }
}

export function* validateJWTAsync() {
  try {
    const response = yield call(api.fetchAuthStatus, '/auth/status')
    const data = response && response.data && AuthStatus.of(response.data)
    yield put({ type: types.VALIDATE_JWT_SUCCESS, payload: data })
    yield put(updateSubTenants(data))
    logger.notify('Auth Validation Succeeded')
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: types.VALIDATE_JWT_FAILURE })
    logger.notify('Auth Validation Failed', { error: e.message })
    logger.error('Error validating user authentication credentials.')
  }
}

export function* watchUserInfo() {
  yield all([
    takeEvery(types.USER_LOAD, userInfoAsync),
    takeEvery(types.VALIDATE_JWT_LOAD, validateJWTAsync),
    takeEvery(types.SHOW_IE_DEPRECATION_WARNING, showInternetExplorerDeprecationWarning),
  ])
}
