import { Collapse, Divider, Grid, IconButton, Tooltip, withStyles } from '@material-ui/core'
import React, { useState } from 'react'

import ArchiveButton from './ArchiveButton'
import Button from 'components/core/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DisabledIcon from '@material-ui/icons/DesktopAccessDisabled'
import EmailDropDown from './EmailDropDown'
import EnabledIcon from '@material-ui/icons/DesktopWindows'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Link from 'components/core/Link'
import LinkIcon from '@material-ui/icons/Link'
import { PUBLIC_SHARE_ROUTE } from 'utils/routes'
import PropTypes from 'prop-types'
import RenameShareButton from './RenameShareButton'
import ShareGroupName from 'components/ShareGroupName'
import ShowIcon from '@material-ui/icons/RemoveRedEye'
import buildUrl from 'utils/urlBuilder'
import { compose } from 'redux'
import get from 'lodash/get'
import { getShipmentDetailPath } from 'utils/rollups'
import logger from 'utils/logger'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  collapseInnerWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(9),
  },
  iconWrapper: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    fontSize: 18,
    verticalAlign: 'middle',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    fontSize: 18,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  singleLink: {
    padding: theme.spacing(1),
  },
})

const ShareListItem = ({ item, classes, onRevoke, onCopiedLink }) => {
  const [expanded, setExpanded] = useState(false)
  const emails = get(item, 'emails', [])
  const refNumbers = get(item, 'refNumbers', [])
  const externalIds = get(item, 'externalIds')
  const emailSent = emails.length > 0 && emails[0]

  return (
    <>
      <Divider variant="inset" className={classes.divider} />
      <Grid className={classes.root} container spacing={3} justify="space-between">
        <Grid item xs={8}>
          <span className={classes.iconWrapper}>
            {item.active ? (
              <EnabledIcon fontSize="inherit" />
            ) : (
              <DisabledIcon color="disabled" fontSize="inherit" />
            )}
          </span>

          <span>
            {refNumbers.length === 1 ? (
              <Link
                className={classes.singleLink}
                to={getShipmentDetailPath(item.refType, refNumbers[0], externalIds[0])}
                onClick={() =>
                  logger.notify('Shipment View Details', {
                    ref_type: item.ref_type,
                    id: refNumbers[0],
                    href: getShipmentDetailPath(item.refType, refNumbers[0], externalIds[0]),
                  })
                }
              >
                <ShareGroupName item={item} />
              </Link>
            ) : (
              <Button variant="text" onClick={() => setExpanded(!expanded)}>
                <ShareGroupName item={item} />
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            )}

            {item.active && (
              <React.Fragment>
                <Tooltip title="Preview Shareable Link">
                  <IconButton
                    className={classes.iconButton}
                    href={PUBLIC_SHARE_ROUTE.buildUrl({ token: item.guid })}
                  >
                    <ShowIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Copy Shareable Link">
                  <CopyToClipboard
                    text={buildUrl({ pathname: PUBLIC_SHARE_ROUTE.buildUrl({ token: item.guid }) })}
                    onCopy={onCopiedLink}
                  >
                    <IconButton className={classes.iconButton}>
                      <LinkIcon fontSize="inherit" />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
                <RenameShareButton item={item} />
                {emailSent && <EmailDropDown emails={emails} />}
              </React.Fragment>
            )}
          </span>

          {refNumbers.length > 0 && (
            <Collapse classes={{ wrapperInner: classes.collapseInnerWrapper }} in={expanded}>
              {
                refNumbers
                  .map((refNumber, i) => (
                    <Link
                      key={refNumber}
                      to={getShipmentDetailPath(item.refType, refNumber, externalIds[i])}
                    >
                      {refNumber}
                    </Link>
                  ))
                  .reduce((prev, curr) => [prev, ',\u00a0', curr]) // Inserts a comma and space except for last item
              }
            </Collapse>
          )}
        </Grid>

        <ArchiveButton item={item} onArchive={onRevoke}>
          Revoke Access
        </ArchiveButton>
      </Grid>
    </>
  )
}

ShareListItem.propTypes = {
  item: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onRevoke: PropTypes.func,
  onCopiedLink: PropTypes.func,
}

ShareListItem.defaultProps = {
  onRevoke: () => {},
  onCopiedLink: () => {},
}

export default compose(withRouter, withStyles(styles))(ShareListItem)
