import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import styles from '../styles'
import { withStyles } from '@material-ui/core'

const SingleBarLoader = ({ classes, isAnimated = false, width = 100 }) => {
  return (
    <div>
      <div
        data-testid="single-bar-loader"
        style={{ width }}
        className={classnames(classes.bar, classes.graphBar, classes.singleBar, {
          [classes.animatedBar]: isAnimated,
          [classes.staticBar]: !isAnimated,
        })}
      />
    </div>
  )
}

SingleBarLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  isAnimated: PropTypes.bool,
  width: PropTypes.number,
}

export default withStyles(styles, { withTheme: true })(SingleBarLoader)
