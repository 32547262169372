import {
  apiConstants,
  getDemurrageDestinationPortPayload,
  getDemurrageOriginPortPayload,
  getDetentionDestinationPortPayload,
  getDetentionOriginPortPayload,
  getOceanDelayPayload,
  getStuckInTransshipmentPayload,
  getVesselArrivedAtDestinationPayload,
  getVesselHasDepartedOriginPayload,
  getVesselWillArriveAtDestinationPayload,
  portalAlertNames,
} from 'pages/PortalAlertModal/utils'

import { PortalAlertsInterface } from 'store/portalAlerts/interfaces'
import find from 'lodash/find'
import get from 'lodash/get'
import getUserAlerts from 'pages/PortalAlertModal/PortalAlertForm/utils/getUserAlerts'

/**
 * Configurations for each alert field. This gets called in `PortalAlertForm`
 * @param alerts API response data
 * @param selectedEntityType
 * @returns
 * The configurations per field here are somewhat vague because this file was originally created to handle five
 * simple fields. Now, with detention and demurrage, things are a bit more complex. I think a more extensible and
 * understandable pattern might be to have a single config file per alert field.
 * All configs return the same top level keys:
 * `rollup`: the API response `refType` (`entityType`) || the `selectedEntityType` passed in
 * `notifFrequency`, `notifTime`, `noticeTimezone` from the API response
 * `alerts[alertKey]` - configs per alert field. These vary by the alert field since some fields are simply a checkbox,
 *    others have a single additional field, and others have up to three additional fields
 *  See inline comments below for additional context.
 */
const getInitialValues = (alerts: PortalAlertsInterface, selectedEntityType: string) => {
  const {
    detentionToOriginPortAlert,
    detentionFromDestinationPortAlert,
    demurrageAtOriginPortAlert,
    demurrageAtDestinationPortAlert,
    detentionToOriginPortKey,
    detentionFromDestinationPortKey,
    demurrageAtOriginPortKey,
    demurrageAtDestinationPortKey,
    oceanDelayAlert,
    oceanDelayKey,
    stuckInTransshipmentAlert,
    stuckInTransshipmentKey,
    vesselArrivedAtDestinationAlert,
    vesselArrivedAtDestinationKey,
    vesselHasDepartedOriginAlert,
    vesselHasDepartedOriginKey,
    vesselWillArriveAtDestinationAlert,
    vesselWillArriveAtDestinationKey,
  } = getUserAlerts(alerts.alertConfigs)

  return {
    // These fields are singular
    rollup: alerts.refType || selectedEntityType,
    notifFrequency: alerts.notifFrequency,
    notifTime: alerts.notifTime,
    notifTimezone: alerts.notifTimezone,
    // Set initial values by `alerts[key]`.
    alerts: {
      [vesselHasDepartedOriginKey]: {
        alertId: get(vesselHasDepartedOriginAlert, 'alertConfigId', null),
        // `group` is a cheap way to allow us to add subheaders in the UI, see the detention and demurrage keys below
        group: null,
        checked: vesselHasDepartedOriginAlert !== null,
        label: 'Vessel has departed Origin Port',
        // These methods get called in `getPayloads`, which builds `POST`, `PUT` and/or `DELETE` objects for the saga
        getApiPayload: getVesselHasDepartedOriginPayload,
      },
      [stuckInTransshipmentKey]: {
        alertId: get(stuckInTransshipmentAlert, 'alertConfigId', null),
        group: null,
        checked: stuckInTransshipmentAlert !== null,
        label: portalAlertNames.STUCK_IN_TRANSSHIPMENT,
        getApiPayload: getStuckInTransshipmentPayload,
        inputLabel: '# of days',
        /**
         * Most fields do something similar, confirm this field is checked and then inspect the `fields` in that
         * alert to find a specific `key` and then grab a value on it. In other words, the object being
         * inspected here looks like this. This applies generally to all value retrievals below.
         * ```
         * {
         *  key: apiConstants.LATEST_MILESTONE_TIME,
         *  urlParams: { maxLatestMilestoneTime: endDate },
         *  value: {
         *    startDate: null,
         *    relativeType: 'past',
         *    endDate, // this is the `value.endDate` value being sought
         *    type: 'relative',
         *  },
         *}
         */
        inputValue:
          stuckInTransshipmentAlert !== null
            ? Math.abs(
                get(
                  find(
                    stuckInTransshipmentAlert.fields,
                    f => f.key === apiConstants.LATEST_MILESTONE_TIME
                  ),
                  'value.endDate',
                  0
                )
              )
            : '',
      },
      [oceanDelayKey]: {
        alertId: get(oceanDelayAlert, 'alertConfigId', null),
        group: null,
        checked: oceanDelayAlert !== null,
        label: 'Predicted Delay to Destination Port',
        getApiPayload: getOceanDelayPayload,
        inputLabel: '# of days',
        inputValue:
          oceanDelayAlert !== null
            ? Math.abs(
                get(
                  find(oceanDelayAlert.fields, f => f.key === apiConstants.OCEAN_DELAY),
                  'value.lowerBound',
                  0
                )
              )
            : '',
      },
      [vesselWillArriveAtDestinationKey]: {
        alertId: get(vesselWillArriveAtDestinationAlert, 'alertConfigId', null),
        group: null,
        checked: vesselWillArriveAtDestinationAlert !== null,
        label: 'Vessel will Arrive at Destination Port',
        getApiPayload: getVesselWillArriveAtDestinationPayload,
        inputLabel: '# of days',
        inputValue:
          vesselWillArriveAtDestinationAlert !== null
            ? Math.abs(
                get(
                  find(
                    vesselWillArriveAtDestinationAlert.fields,
                    f => f.key === apiConstants.PRED_DISCHARGE_TIME
                  ),
                  'value.endDate',
                  0
                )
              )
            : '',
      },
      [vesselArrivedAtDestinationKey]: {
        alertId: get(vesselArrivedAtDestinationAlert, 'alertConfigId', null),
        group: null,
        checked: vesselArrivedAtDestinationAlert !== null,
        label: 'Vessel Arrived at Destination Port',
        getApiPayload: getVesselArrivedAtDestinationPayload,
      },
      [detentionToOriginPortKey]: {
        alertId: get(detentionToOriginPortAlert, 'alertConfigId', null),
        // All this does is allow us to loop over all alert fields and when this is encountered, treat this as a
        // section in the UI.
        group: {
          name: 'detention',
          addHeading: true,
        },
        timingValue:
          detentionToOriginPortAlert !== null
            ? get(
                find(
                  detentionToOriginPortAlert.fields,
                  f => f.key === apiConstants.MIN_ORIGIN_DETENTION_DAYS
                ),
                'value.timingValue',
                ''
              )
            : '',
        checked: detentionToOriginPortAlert !== null,
        inputLabel: '# of days',
        label: 'To the Origin Port',
        getApiPayload: getDetentionOriginPortPayload,
        minDaysValue:
          detentionToOriginPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    detentionToOriginPortAlert.fields,
                    f => f.key === apiConstants.MIN_ORIGIN_DETENTION_DAYS
                  ),
                  `value.minOriginDetentionDays`,
                  0
                )
              )
            : '',
        maxDaysValue:
          detentionToOriginPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    detentionToOriginPortAlert.fields,
                    f => f.key === apiConstants.MAX_ORIGIN_DETENTION_DAYS
                  ),
                  `value.maxOriginDetentionDays`,
                  0
                )
              )
            : '',
      },
      // Detention/Demurrage have three inputs: `timingValue`, `minDaysValue`, `maxDaysValue`
      [detentionFromDestinationPortKey]: {
        alertId: get(detentionFromDestinationPortAlert, 'alertConfigId', null),
        group: {
          name: 'detention',
          addHeading: false,
        },
        timingValue:
          detentionFromDestinationPortAlert !== null
            ? get(
                find(
                  detentionFromDestinationPortAlert.fields,
                  f => f.key === apiConstants.MIN_DESTINATION_DETENTION_DAYS
                ),
                'value.timingValue',
                ''
              )
            : '',
        checked: detentionFromDestinationPortAlert !== null,
        inputLabel: '# of days',
        label: 'From the Destination Port',
        getApiPayload: getDetentionDestinationPortPayload,
        minDaysValue:
          detentionFromDestinationPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    detentionFromDestinationPortAlert.fields,
                    f => f.key === apiConstants.MIN_DESTINATION_DETENTION_DAYS
                  ),
                  `value.minDestinationDetentionDays`,
                  0
                )
              )
            : '',
        maxDaysValue:
          detentionFromDestinationPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    detentionFromDestinationPortAlert.fields,
                    f => f.key === apiConstants.MAX_DESTINATION_DETENTION_DAYS
                  ),
                  `value.maxDestinationDetentionDays`,
                  0
                )
              )
            : '',
      },
      [demurrageAtOriginPortKey]: {
        alertId: get(demurrageAtOriginPortAlert, 'alertConfigId', null),
        group: {
          name: 'demurrage',
          addHeading: true,
        },
        timingValue:
          demurrageAtOriginPortAlert !== null
            ? get(
                find(
                  demurrageAtOriginPortAlert.fields,
                  f => f.key === apiConstants.MIN_ORIGIN_DEMURRAGE_DAYS
                ),
                'value.timingValue',
                ''
              )
            : '',
        checked: demurrageAtOriginPortAlert !== null,
        inputLabel: '# of days',
        label: 'At Origin Port',
        getApiPayload: getDemurrageOriginPortPayload,
        minDaysValue:
          demurrageAtOriginPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    demurrageAtOriginPortAlert.fields,
                    f => f.key === apiConstants.MIN_ORIGIN_DEMURRAGE_DAYS
                  ),
                  `value.minOriginDemurrageDays`,
                  0
                )
              )
            : '',
        maxDaysValue:
          demurrageAtOriginPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    demurrageAtOriginPortAlert.fields,
                    f => f.key === apiConstants.MAX_ORIGIN_DEMURRAGE_DAYS
                  ),
                  `value.maxOriginDemurrageDays`,
                  0
                )
              )
            : '',
      },
      [demurrageAtDestinationPortKey]: {
        alertId: get(demurrageAtDestinationPortAlert, 'alertConfigId', null),
        group: {
          name: 'demurrage',
          addHeading: false,
        },
        timingValue:
          demurrageAtDestinationPortAlert !== null
            ? get(
                find(
                  demurrageAtDestinationPortAlert.fields,
                  f => f.key === apiConstants.MIN_DESTINATION_DEMURRAGE_DAYS
                ),
                'value.timingValue',
                ''
              )
            : '',
        checked: demurrageAtDestinationPortAlert !== null,
        inputLabel: '# of days',
        label: 'At Destination Port',
        getApiPayload: getDemurrageDestinationPortPayload,
        minDaysValue:
          demurrageAtDestinationPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    demurrageAtDestinationPortAlert.fields,
                    f => f.key === apiConstants.MIN_DESTINATION_DEMURRAGE_DAYS
                  ),
                  `value.minDestinationDemurrageDays`,
                  0
                )
              )
            : '',
        maxDaysValue:
          demurrageAtDestinationPortAlert !== null
            ? Math.abs(
                get(
                  find(
                    demurrageAtDestinationPortAlert.fields,
                    f => f.key === apiConstants.MAX_DESTINATION_DEMURRAGE_DAYS
                  ),
                  `value.maxDestinationDemurrageDays`,
                  0
                )
              )
            : '',
      },
    },
  }
}

export default getInitialValues
