import { TripTimelineItem } from 'utils/sharedObjs'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'

export const fakeMilestone = () => {
  let fakeMilestone = new TripTimelineItem()
  fakeMilestone.fake = true
  return fakeMilestone
}

export const numLocationMilestones = route => {
  const locationOnlyMilestones = filter(route.milestones, milestone => milestone.type !== 'vessel')
  return locationOnlyMilestones.length
}

const mergeRoutes = (route1, route2) => {
  const routeMilestones = route1.milestones
  const otherMilestones = route2.milestones
  let updatedMilestones = []
  let added = 0
  for (let i = 0; i < routeMilestones.length; i++) {
    const milestone = routeMilestones[i]
    const desiredIdx = findIndex(
      otherMilestones,
      otherMilestone => otherMilestone.id === milestone.id
    )
    const idxDiff = desiredIdx - (i + added)
    for (let j = 0; j < idxDiff; j++) {
      updatedMilestones.push(fakeMilestone())
      added += 1
    }
    updatedMilestones.push(milestone)
  }
  const routeLocMilestones = numLocationMilestones(route1)
  const otherLocMilestones = numLocationMilestones(route2)

  const lenDiff = otherLocMilestones - routeLocMilestones - added

  for (let i = 0; i < lenDiff; i++) {
    updatedMilestones.push(fakeMilestone())
  }

  route1.mergedMilestones = updatedMilestones
}

export default mergeRoutes
