import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeEvery } from 'redux-saga/effects'

import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import MESSAGES from 'store/notifications/messages'
import api from 'store/auth/invite/api'
import { confirmSaga } from 'store/confirmation'
import get from 'lodash/get'
import logger from 'utils/logger'
import { types } from 'store/auth/invite/actions'

export function* inviteAsync(action) {
  let successfulInvites = []
  const { tenant, emails } = action.payload
  try {
    if (!Array.isArray(emails)) {
      return new Error('emails param should be an array')
    }
    for (let i = 0; i < emails.length; i++) {
      let email = emails[i]
      let result
      try {
        result = yield call(api.invite, { tenant, email })
      } catch (e) {
        const status = get(e, 'response.data.error.status')
        const code = get(e, 'response.data.error.internal_code')
        if (status === 422 && code === 'NEW_EMAIL_DOMAIN_WARNING') {
          const confirm = yield call(confirmSaga, {
            ...CONFIRMATION_MESSAGES.tenantInviteEmailInconsistent({ email, tenant }),
          })
          if (confirm) {
            result = yield call(api.invite, { tenant, email, force: true })
          } else {
            yield put({ type: types.INVITE_FAILURE, payload: successfulInvites })
            return
          }
        } else {
          throw e
        }
      }

      if (result.status === 200) {
        successfulInvites.push(email)
      }
    }
    yield put({ type: types.INVITE_SUCCESS, payload: successfulInvites })
    const emailsAsStr = emails.join(', ')
    yield put(
      showNotification(MESSAGES.inviteUserSuccess(emailsAsStr, tenant), {
        type: MESSAGE_TYPES.SUCCESS,
      })
    )
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error logging in: ${e}`, 'error')
    yield put({ type: types.INVITE_FAILURE, payload: successfulInvites }) // report any successful invites
    yield put(showNotification(MESSAGES.inviteUserError, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* watchInvite() {
  yield takeEvery(types.INVITE_LOAD, inviteAsync)
}
