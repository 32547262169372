import {
  ClickAwayListener,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'

import AlertIcon from '@material-ui/icons/Notifications'
import Button from 'components/core/Button'
import ClearIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import get from 'lodash/get'
import styles from './styles'

class ViewListItem extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleAlertClick: PropTypes.func.isRequired,
    handleUpdateView: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    viewAlertIds: PropTypes.array,
    viewName: PropTypes.string.isRequired,
  }

  static defaultProps = {
    viewAlertIds: [],
    isActive: false,
  }

  state = {
    isHovering: false,
    isEditMode: false,
    showEdit: false,
    editedViewName: get(this.props, 'viewName'),
    nameExistsError: null,
  }

  toggleHover = value => () => {
    this.setState({ isHovering: value })
  }

  turnOnEditMode = e => {
    e.stopPropagation()
    this.setState({
      isEditMode: true,
      showEdit: false,
      nameExistsError: false,
    })
  }

  turnOffEditMode = e => {
    this.setState({
      isEditMode: false,
      editedViewName: get(this.props, 'viewName'),
    })
  }

  handleChange = e => {
    this.setState({
      editedViewName: e.target.value,
      nameExistsError: false,
    })
  }

  handleFormSubmit = event => {
    event.preventDefault()
    const { viewName, viewNameExists } = this.props

    // If they have changed the name to an existing saved filter group name show an error
    if (viewName !== this.state.editedViewName && viewNameExists(this.state.editedViewName)) {
      return this.setState({
        nameExistsError: true,
      })
    }

    // Otherwise update even if they leave the existing name so they always get the success toast
    const { handleUpdateView } = this.props
    handleUpdateView(this.state.editedViewName)
    this.setState({
      isEditMode: false,
      nameExistsError: false,
    })
  }

  renderViewName = isActive => {
    const { classes, handleAlertClick, onDelete, onSelect, viewAlertIds } = this.props
    const { editedViewName, isEditMode, isHovering, nameExistsError, showEdit } = this.state
    const textClasses = classnames(classes.primaryText, {
      [classes.isActiveText]: isActive || isHovering,
    })
    if (isEditMode) {
      return (
        <ClickAwayListener onClickAway={this.turnOffEditMode}>
          <form
            className={classes.editNameWrapper}
            data-testid="view-list__view-list-item--edit-mode"
            onSubmit={this.handleFormSubmit}
          >
            <TextField
              InputProps={{
                classes: {
                  input: classes.editField,
                },
              }}
              value={editedViewName}
              onChange={this.handleChange}
              autoFocus
              helperText={nameExistsError && 'This name already exists.'}
              error={Boolean(nameExistsError)}
            />
            <Button
              color="primary"
              variant="text"
              type="submit"
              classes={{ root: classes.saveNameButton, label: classes.saveNameButtonLabel }}
            >
              Save
            </Button>
          </form>
        </ClickAwayListener>
      )
    }

    return (
      <div
        className={classes.textWithIcons}
        onMouseEnter={() => {
          this.setState({ showEdit: true })
        }}
        onMouseLeave={() => {
          this.setState({ showEdit: false })
        }}
        onClick={onSelect}
        data-testid="view-list__view-list-item"
        title={editedViewName}
      >
        <Typography variant="body2" className={textClasses}>
          {editedViewName}
        </Typography>
        <div className={classes.actions}>
          {viewAlertIds.length > 0 && (
            <Tooltip title={'View associated alert'} placement={'top'}>
              <IconButton
                onClick={handleAlertClick}
                className={`${classes.iconButton} ${classes.alertIcon}`}
              >
                <AlertIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
          {showEdit && (
            <>
              <Tooltip title={'Change saved filter name'}>
                <IconButton
                  onClick={this.turnOnEditMode}
                  className={`${classes.iconButton} ${classes.editIcon}`}
                  data-testid="view-list-item__edit"
                >
                  <EditIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete saved filter'}>
                <IconButton
                  onClick={onDelete}
                  className={`${classes.iconButton} ${classes.deleteIcon}`}
                  data-testid="view-list-item__delete"
                >
                  <ClearIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { classes, onClick, isActive } = this.props
    return (
      <ListItem
        button
        dense
        onClick={onClick}
        onMouseEnter={this.toggleHover(true)}
        onMouseLeave={this.toggleHover(false)}
        className={classnames(classes.listItem, { [classes.isActive]: isActive })}
      >
        <ListItemText
          primary={this.renderViewName(isActive)}
          className={classes.listItemText}
          data-testid={isActive && 'view-list-item__active'}
        />
      </ListItem>
    )
  }
}

export default withStyles(styles)(ViewListItem)
