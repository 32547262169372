import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

export const RouteGroup = {
  of: routeGroup => ({
    ...routeGroup,
    id: routeGroup.routeIds.join('-'),
    carriers: sortBy(
      uniqBy(
        routeGroup.routes.map(route => route.carrier).filter(c => c),
        'scac'
      ),
      'name'
    ),
    waypointLocations: routeGroup.routes.map(route => route.waypointLocations),
  }),
}
