import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { deletePortalUser, fetchPortalUsers } from 'store/portalUsers/actions'
import { useDispatch, useSelector } from 'react-redux'

import CenteredLayout from 'components/layouts/CenteredLayout'
import ExpandCell from 'pages/PlanningPage/RoutesTable/ExpandCell'
import ExpandableCell from './ExpandableCell'
import Loader from 'components/Loader'
import SortableTableHeader from 'components/SortableTableHeader'
import { portalUsersSelector } from 'store/portalUsers/selectors'
import pull from 'lodash/pull'
import { tableDataSort } from 'utils/tableUtils'
import { toTitleCase } from 'utils/strUtils'

const NO_USERS_MESSAGE = 'No portal users have been added'

// For now setting the first seven columns to a min width to avoid a poor layout.
// MUI tables scroll horizontally so that's our version of "responsive" here for now.
const columnWidthStyles = (theme, cellTag = 'td') => ({
  [`& ${cellTag}:nth-child(-n+4)`]: {
    minWidth: 150,
  },
})

export const columns = [
  { id: 'email', label: 'Email', numeric: false, sortDisabled: false },
  { id: 'firstName', label: 'First Name', numeric: false, sortDisabled: false },
  { id: 'lastName', label: 'Last Name', numeric: false, sortDisabled: false },
  { id: 'customFields', label: 'Custom Fields', numeric: false, sortDisabled: true },
  { id: 'toggle', label: '', numeric: false, sortDisabled: true },
  { id: 'action', label: '', numeric: false, sortDisabled: true },
]

// we show this many, if there are more we hide them and add the expand icon
export const VISIBLE_ITEMS_COUNT = 3
export const EMPTY_CELL_TEXT = '--'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(3),
  },
  emptyStateMessage: {
    color: theme.palette.grey[500],
    fontSize: 20,
    padding: '40px 0',
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(2),
  },
  headerRow: {
    ...columnWidthStyles(theme, 'th'),
  },
  tableRow: {
    verticalAlign: 'top',
  },
  buttonText: {
    color: theme.palette.red[400],
    fontWeight: theme.typography.fontWeightRegular,
  },
  expandCellContents: {
    alignItems: 'flex-start',
  },
  expandButton: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    height: theme.spacing(4),
    marginTop: 0,
    minWidth: theme.spacing(3),
    padding: 0,
    width: theme.spacing(4),
  },
  tableCell: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingTop: theme.spacing(1),
  },
  buttonCell: {
    paddingTop: theme.spacing(1),
  },
}))

export default function ManageUsers() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const portalUsers = useSelector(portalUsersSelector)
  const [tableState, setTableState] = useState({ rows: portalUsers ? portalUsers.data : undefined })
  // we add/remove rowIds from this array. If it contains a `rowId` that row is expanded
  const [expandedRows, setExpandedRows] = useState([])
  const [sortState, setSortState] = useState({
    orderBy: 'email',
    order: 'asc',
  })

  useEffect(() => {
    dispatch(fetchPortalUsers())
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (portalUsers.data.length) {
      handleSort(sortState.orderBy, sortState.order, portalUsers.data)
    } else {
      setTableState({ rows: [] })
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalUsers])

  const isRowExpanded = rowId => expandedRows.indexOf(rowId) !== -1

  const deleteUser = externalId => {
    if (!externalId) {
      return
    }
    dispatch(deletePortalUser(externalId))
  }

  const handleSort = (dataKey, direction, data) => {
    const sortedRows = tableDataSort({
      dataKey,
      direction,
      data,
      columns,
    })

    setTableState({ rows: sortedRows })
    setSortState({ orderBy: dataKey, order: direction })
  }

  // adds or removes `rowId` from state `expandedRows` array
  const handleExpandCollapse = (e, rowId) => {
    e.preventDefault()
    let rows = []

    if (isRowExpanded(rowId)) {
      rows = pull([...expandedRows], rowId)
    } else {
      rows = [...expandedRows, rowId]
    }
    return setExpandedRows(rows)
  }

  if (portalUsers.isLoading) {
    return (
      <CenteredLayout>
        <Loader />
      </CenteredLayout>
    )
  }

  return (
    <Box className={classes.container} data-testid="portalSettings__manage-users">
      <TableContainer>
        <Table aria-label="Manage Users Table">
          <SortableTableHeader
            cols={columns}
            defaultOrderBy={sortState.orderBy}
            defaultOrder={sortState.order}
            onRequestSort={(dataKey, direction) => handleSort(dataKey, direction, tableState.rows)}
            classes={{ head: classes.tableHead, row: classes.headerRow }}
          />
          <TableBody>
            {tableState.rows.map((user, rowId) => {
              const isExpanded = isRowExpanded(rowId)
              const customFields = user.fields.map(field => field.key)
              // For the fields we're showing, do any have enough items to show a toggle icon?
              const isExpandable = customFields.length > VISIBLE_ITEMS_COUNT

              return (
                <TableRow key={user.externalId} className={classes.tableRow}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.firstName || EMPTY_CELL_TEXT}</TableCell>
                  <TableCell>{user.lastName || EMPTY_CELL_TEXT}</TableCell>
                  <ExpandableCell
                    data={customFields}
                    isExpanded={isExpanded}
                    formatter={val => toTitleCase(val.replace('_', ' '))}
                  />
                  {isExpandable ? (
                    <ExpandCell
                      onClick={e => handleExpandCollapse(e, rowId)}
                      label=""
                      className={classes.expandCell}
                      isExpanded={isExpanded}
                      classes={{
                        cellContents: classes.expandCellContents,
                        button: classes.expandButton,
                        tableCell: classes.tableCell,
                      }}
                    />
                  ) : (
                    <TableCell />
                  )}
                  <TableCell className={classes.buttonCell}>
                    <Button
                      onClick={e => deleteUser(user.externalId)}
                      variant="text"
                      component="span"
                      color="primary"
                      classes={{ text: classes.buttonText }}
                      data-testid="manage-users__delete-button"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {tableState.rows.length === 0 && (
        <Box m={2}>
          <Typography variant="body2" className={classes.emptyStateMessage} align="center">
            {NO_USERS_MESSAGE}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
