import { Box, IconButton, Theme, makeStyles } from '@material-ui/core'
import {
  DELAY_DAYS_TO_SHOW,
  STATUS_DELAY,
  STATUS_EARLY,
  TIMESTAMP_ACTUAL,
  TIMESTAMP_MESSAGES,
  TIMESTAMP_PLANNED,
  TIMESTAMP_PLANNED_NO_ACTUAL,
  TIMESTAMP_PREDICTED,
} from 'utils/milestone/MilestoneGroups/definitions'
import React, { useRef, useState } from 'react'

import InfoIcon from 'components/icons/InfoIcon'
import PopoverText from 'components/PopoverText'
import Reaction from 'components/ProductFeedback/Reaction'
import classnames from 'classnames'
import logger from 'utils/logger'
import { pluralizeWord } from 'utils/strUtils'

const LINE_HEIGHT = 1.4

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  labelDelay: {
    ...theme.typography.body2,
    color: theme.palette.orange[500],
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  labelDelayPredicted: {
    ...theme.typography.body2,
    color: theme.palette.purple[600],
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  iconButton: {
    borderRadius: theme.shape.borderRadiusSmall,
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.25),
  },
  iconButtonActive: {
    backgroundColor: `rgba(0, 0, 0, 0.04)`,
  },
  infoIcon: {
    color: theme.palette.grey[600],
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  tooltip: {
    fontSize: 13,
    opacity: 1,
    textTransform: 'none',
  },
  reactionRoot: {
    display: 'flex',
  },
  reactionButtonGroup: {
    marginLeft: theme.spacing(1),
  },
  feedbackPromptText: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

interface Props {
  classes?: { [key: string]: string }
  delay?: number | null
  delayType?:
    | typeof TIMESTAMP_ACTUAL
    | typeof TIMESTAMP_PLANNED
    | typeof TIMESTAMP_PLANNED_NO_ACTUAL
    | typeof TIMESTAMP_PREDICTED
  showIconOnly?: boolean
}

/**
 * Renders optional `delayMessage` (i.e., "Delay 11 days") with an info icon that triggers the delay popover.
 * If `showIconOnly === true` it renders only the info icon and popover.
 */
const DelayLabel = (props: Props) => {
  const classes = useStyles(props)
  const { delay, delayType, showIconOnly = false } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const anchorRef = useRef(null)

  const handleClose = () => setAnchorEl(null)

  const handleClick = () => {
    logger.notify('Delay label popup click')
    setAnchorEl(anchorRef.current)
  }

  // if `showIconOnly` then we don't show/need `delay`. Otherwise we need some validation for `delay`
  if (!showIconOnly && (!delay || isNaN(delay) || Math.abs(delay) < DELAY_DAYS_TO_SHOW)) {
    return null
  }

  const delayMessage = delayType && TIMESTAMP_MESSAGES[delayType]

  const getDelayText = (delay: number) => {
    const displayText = delay > 0 ? STATUS_DELAY : STATUS_EARLY
    return `${displayText} ${Math.abs(delay)} ${pluralizeWord('Day', Math.abs(delay) !== 1)}`
  }

  return (
    <Box
      component="span"
      className={classnames(
        delayType === TIMESTAMP_PREDICTED ? classes.labelDelayPredicted : classes.labelDelay,
        classes.root
      )}
    >
      {!showIconOnly && delay && getDelayText(delay)}
      {delayMessage && (
        <>
          <IconButton
            onClick={handleClick}
            ref={anchorRef}
            className={classnames(classes.iconButton, {
              [classes.iconButtonActive]: Boolean(anchorEl),
            })}
          >
            <InfoIcon className={classes.infoIcon} />
          </IconButton>

          {anchorEl && (
            <PopoverText
              anchorEl={anchorEl}
              bodyContent={delayMessage.message}
              footerContent={
                <Box display="flex" justifyContent="center">
                  <Reaction
                    classes={{
                      buttonGroup: classes.reactionButtonGroup,
                      reactionPrompt: classes.reactionRoot,
                      feedbackPromptText: classes.feedbackPromptText,
                    }}
                    componentUnderFeedback="Delay Label"
                    feedbackPromptText="Was this helpful?"
                    loggerLabel="Delay Label Reaction"
                  />
                </Box>
              }
              handleClose={handleClose}
              headerContent={<>{delayMessage.title}</>}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default DelayLabel
