import { TransportDetailInterface } from 'store/models/TransportDetail/interfaces'
import { TransportDetailState } from 'store/transportDetail/interfaces'
import { handleActions } from 'redux-actions'
import { types } from 'store/transportDetail/actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: TransportDetailState = {
  isFailed: false,
  isLoading: false,
  data: undefined,
}

const transportDetail = handleActions<TransportDetailState, any>(
  {
    [types.FETCH_TRANSPORT_DETAIL_START]: (state: TransportDetailState) => {
      return { ...state, isFailed: false, isLoading: true }
    },
    [types.FETCH_TRANSPORT_DETAIL_SUCCESS]: (
      state: TransportDetailState,
      action: { payload: TransportDetailInterface }
    ) => {
      return {
        ...state,
        data: action.payload,
        isFailed: false,
        isLoading: false,
      }
    },
    [types.FETCH_TRANSPORT_DETAIL_FAILURE]: (state: TransportDetailState) => {
      return { ...state, data: undefined, isFailed: true, isLoading: false }
    },
    [types.FETCH_TRANSPORT_DETAIL_RESET]: (state: TransportDetailState) => {
      return { ...state, data: undefined, isFailed: false, isLoading: false }
    },
  },
  initialState
)

export default transportDetail
