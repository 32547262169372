import { PREDICTION_STATUS_UNKNOWN, PREDICTION_STATUS_UNKNOWN_MESSAGE } from 'utils/milestones'
import { Tooltip, makeStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React from 'react'

const useStyles = makeStyles(theme => ({
  iconRoot: {
    verticalAlign: 'middle',
  },
  tooltip: {
    maxWidth: 200,
  },
  text: {
    fontSize: theme.typography.caption.fontSize,
  },
}))

const IdlePredictionDisclaimer = () => {
  const classes = useStyles()
  return (
    <Box
      color="info.dark"
      fontWeight="fontWeightBold"
      className={classes.text}
      data-testid="idle-prediction-disclaimer"
    >
      <>
        <Tooltip
          title={PREDICTION_STATUS_UNKNOWN_MESSAGE}
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          interactive
        >
          <span>
            <HelpOutlineIcon classes={{ root: classes.iconRoot }} fontSize="small" />
          </span>
        </Tooltip>{' '}
        {PREDICTION_STATUS_UNKNOWN}
      </>
    </Box>
  )
}

export default IdlePredictionDisclaimer
