import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Support = props => (
  <SvgIcon {...props} viewBox={'0 0 24 24'}>
    <path
      className="prefix__st0"
      d="M10.8 15c0-.7.1-1.3.3-1.8.2-.4.5-.9 1-1.4l1.2-1.3c.5-.6.8-1.2.8-1.9s-.2-1.2-.5-1.5c-.3-.4-.8-.6-1.5-.6-.6 0-1.1.2-1.5.5-.4.3-.6.8-.6 1.4H8.2c0-1 .4-1.8 1.1-2.5.7-.6 1.6-.9 2.8-.9 1.2 0 2.1.3 2.8 1 .7.6 1 1.5 1 2.6s-.5 2.2-1.5 3.2l-1 1c-.5.5-.7 1.2-.7 2.2h-1.9zm0 3c0-.3.1-.5.3-.7.2-.2.4-.3.8-.3s.6.1.8.3c.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.4.3-.8.3s-.6-.1-.8-.3c-.2-.2-.3-.4-.3-.7z"
    />
    <path
      className="prefix__st0"
      d="M12 1.2C18 1.2 22.8 6 22.8 12S18 22.8 12 22.8 1.2 18 1.2 12 6 1.2 12 1.2M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0z"
    />
  </SvgIcon>
)

export default Support
