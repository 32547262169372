const Facility = {
  cy: 'Container Yard',
  door: 'Door',
  ramp: 'Ramp',
  port: 'Port',
} as const

export type FacilityType = typeof Facility[keyof typeof Facility]

export default Facility
