import {
  EMPTY_DISPATCH,
  EMPTY_RETURN,
  ON_CARRIAGE,
  PORT_OF_DISCHARGE,
  PORT_OF_LOAD,
  PRE_CARRIAGE,
  TRANSSHIPMENT,
} from 'store/models/definitions/StageGroup'

const StageGroupLabel = {
  [EMPTY_DISPATCH]: 'Empty Dispatch',
  [PRE_CARRIAGE]: 'Pre-Carriage',
  [PORT_OF_LOAD]: 'Port of Load',
  [TRANSSHIPMENT]: 'Transshipment',
  [PORT_OF_DISCHARGE]: 'Port of Discharge',
  [ON_CARRIAGE]: 'On-Carriage',
  [EMPTY_RETURN]: 'Empty Return',
} as const

export type StageGroupLabelType = typeof StageGroupLabel[keyof typeof StageGroupLabel]

export default StageGroupLabel
