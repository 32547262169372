// Filter Group Icons
import {
  EntitiesIcon,
  LocationsIcon,
  MilestonesIcon,
  PredictionsIcon,
} from 'components/icons/FilterIcons'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { Base64 } from 'js-base64'
import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import { REF_TYPE_PARAM_NAME } from 'utils/querystring'
import { confirmSaga } from 'store/confirmation'
import { createAction } from 'redux-actions'
import find from 'lodash/find'
import get from 'lodash/get'
import logger from 'utils/logger'
import reject from 'lodash/reject'
import { removeQueryStringParam } from 'utils/urlBuilder'
import { rollupSelector } from 'store/boardUtils/selectors'
import { setQueryStringParam } from 'utils/urlBuilder'
import { types } from 'store/boardUtils/actions'

// Filter Groups
export const FILTER_GROUP_ENTITIES = 'Entities'
export const FILTER_GROUP_MILESTONES = 'Events'
export const FILTER_GROUP_PREDICTIONS = 'Predictions'
export const FILTER_GROUP_LOCATIONS = 'Locations'
export const FILTER_PARAM_NAME = 'filters'

export const FILTER_GROUPS = [
  {
    name: FILTER_GROUP_ENTITIES,
    icon: EntitiesIcon,
    description: 'Involved parties in the shipment',
  },
  {
    name: FILTER_GROUP_MILESTONES,
    icon: MilestonesIcon,
    description: 'Arrivals, departures, times',
  },
  {
    name: FILTER_GROUP_LOCATIONS,
    icon: LocationsIcon,
    description: 'Key locations in shipment route',
  },
  {
    name: FILTER_GROUP_PREDICTIONS,
    icon: PredictionsIcon,
    description: 'Predicted status of shipments',
  },
]

const MAX_QUERY_LEN = 2000

const initialState = {
  filters: [],
  isDirty: false,
  isMenuOpen: true,
  activeFilter: null,
  isEditingFilter: false,
  filterGroups: FILTER_GROUPS,
}

const filtersFromURL = (query, filterConfig) => {
  const filterToken = get(query, 'filters')
  const refType = get(query, REF_TYPE_PARAM_NAME)
  let decodedFilters = []
  if (filterToken) {
    decodedFilters = decodeFilters(filterToken)
  }
  const filters = decodedFilters.map(filter => {
    const matchingColumn = find(filterConfig, config => config.name === filter.name)
    return { ...filter, filterConfig: matchingColumn }
  })

  return {
    filters,
    [REF_TYPE_PARAM_NAME]: refType,
  }
}

export function encodeFilters(filtersAsArray) {
  // we use an external library that can gracefully handle non-Latin1 characters. e.g.,
  //    Base64.decode(Base64.encode("Gebr√ºder Weiss GmbH")) === "Gebr√ºder Weiss GmbH",
  // whereas btoa("Gebr√ºder Weiss GmbH") gives an error due to non-Latin1 characters (see #171546481).
  return Base64.encode(JSON.stringify(filtersAsArray))
}

export function decodeFilters(filterToken) {
  // analogue of encodeFilters() above.
  return JSON.parse(Base64.decode(filterToken))
}

export const getUpdatedFilterQuery = filters => {
  return filters.map(item => ({ name: item.name, value: item.value }))
}

export default function createFilterableModule(name, reducer, parentState, opts = {}) {
  const ADD_FILTER_START = `${name}_ADD_FILTER_START`
  const ADD_FILTER_SUCCESS = `${name}_ADD_FILTER_SUCCESS`
  const REMOVE_FILTER = `${name}_REMOVE_FILTER`
  const SET_FILTERS_FROM_URL = `${name}_SET_FILTERS_FROM_URL`
  const CLEAR_ALL_FILTERS = `${name}_CLEAR_ALL_FILTERS`
  const SET_ACTIVE_FILTER = `${name}_SET_ACTIVE_FILTER`
  const UNSET_ACTIVE_FILTER = `${name}_UNSET_ACTIVE_FILTER`
  const SET_FILTERS = `${name}_SET_FILTERS`

  const actionTypes = {
    ADD_FILTER: ADD_FILTER_SUCCESS,
    REMOVE_FILTER: REMOVE_FILTER,
    SET_FILTERS: SET_FILTERS,
    CLEAR_ALL_FILTERS: CLEAR_ALL_FILTERS,
    SET_FILTERS_FROM_URL: SET_FILTERS_FROM_URL,
    SET_ACTIVE_FILTER: SET_ACTIVE_FILTER,
    UNSET_ACTIVE_FILTER: UNSET_ACTIVE_FILTER,
  }

  const setFiltersFromURL = createAction(SET_FILTERS_FROM_URL, (urlQuery, filterConfigs) => ({
    urlQuery,
    filterConfigs,
  }))
  const addFilter = createAction(ADD_FILTER_START, (filterConfig, value) => ({
    name: filterConfig.name,
    value,
    filterConfig,
  }))
  const removeFilter = createAction(REMOVE_FILTER, name => name)
  const clearAllFilters = createAction(CLEAR_ALL_FILTERS)
  const setFilters = createAction(SET_FILTERS)
  const setActiveFilter = createAction(
    SET_ACTIVE_FILTER,
    filterConfig => filterConfig,
    (filterConfig, isEditing) => ({ isEditing })
  )
  const unsetActiveFilter = createAction(UNSET_ACTIVE_FILTER)

  const filtersSelector = state => get(state[name], 'filters', [])
  const isEditingFilterSelector = state => state[name].isEditingFilter

  const actions = {
    addFilter,
    setFilters,
    removeFilter,
    clearAllFilters,
    setFiltersFromURL,
    setActiveFilter,
    unsetActiveFilter,
  }

  // ACTION HANDLERS

  const actionHandlers = {
    [ADD_FILTER_SUCCESS]: (state, { payload }) => {
      const { filters } = state
      const nextFilters = reject(filters, f => f.name === payload.name)
      return {
        ...state,
        isMenuOpen: false,
        filters: [...nextFilters, payload],
        isDirty: true,
        isEditingFilter: false,
      }
    },
    [REMOVE_FILTER]: (state, { payload }) => {
      const { filters } = state
      const nextFilters = reject(filters, f => f.name === payload)
      return {
        ...state,
        filters: nextFilters,
        isDirty: true,
      }
    },
    [CLEAR_ALL_FILTERS]: state => {
      return {
        ...state,
        filters: [],
        name: 'Unsaved Filter',
        isDirty: true,
      }
    },
    [SET_FILTERS]: (state, { payload }) => {
      return {
        ...state,
        filters: payload,
      }
    },
    [SET_FILTERS_FROM_URL]: (state, { payload }) => {
      const { urlQuery, filterConfigs } = payload
      const filterData = filtersFromURL(urlQuery, filterConfigs)
      // If the saved filter has a `refType` we want to use it, if not fall back to what's in `state`
      const filterRefType = get(filterData, REF_TYPE_PARAM_NAME)
      const refType = filterRefType ? filterRefType : state.rollup

      return {
        ...state,
        filters: filterData.filters,
        rollup: refType,
      }
    },
    [SET_ACTIVE_FILTER]: (state, { payload, meta }) => {
      return {
        ...state,
        activeFilter: payload,
        isEditingFilter: meta.isEditing,
      }
    },
    [UNSET_ACTIVE_FILTER]: state => ({
      ...state,
      activeFilter: null,
      isMenuOpen: false,
    }),
  }

  const reducerWithFiltering = (
    state = { ...initialState, ...parentState },
    { type, payload, meta }
  ) => {
    const handler = actionHandlers[type]
    return handler
      ? handler(state, { type, payload, meta })
      : reducer(state, { type, payload, meta })
  }

  /**
   ********************** Sagas *********************
   */

  const upperCaseName = name.charAt(0).toUpperCase() + name.substr(1)

  function* updateFilterURLParams() {
    const filters = yield select(filtersSelector)
    const updatedQuery = getUpdatedFilterQuery(filters)
    const refType = yield select(rollupSelector)

    if (updatedQuery.length > 0) {
      const updatedQueryStr = encodeFilters(updatedQuery)
      if (updatedQueryStr.length < MAX_QUERY_LEN) {
        yield put({ type: types.SET_ROLLUP_START, payload: refType })
        setQueryStringParam(FILTER_PARAM_NAME, updatedQueryStr)
      }
    } else {
      // Remove only the `filter` param. If the desire is to remove all query string params use:
      // `yield browserHistory.push(pathname)`
      removeQueryStringParam(FILTER_PARAM_NAME)
    }
  }

  function* logEventAsync(getEvent, { payload }) {
    const { eventType, metadata } = getEvent(payload)
    yield logger.notify(eventType, metadata)
  }

  const addFilterEvent = payload => ({
    eventType: `${upperCaseName} Add Filter`,
    metadata: {
      name: get(payload, 'name'),
      value: get(payload, 'value'),
    },
  })

  const setFiltersEvent = payload => {
    let value = []
    if (payload && payload.length) {
      for (let i = 0; i < payload.length; i++) {
        let filter = payload[i]
        value.push({
          name: get(filter, 'name'),
          value: get(filter, 'value'),
        })
      }
    }
    return {
      eventType: `${upperCaseName} Set Filters`,
      metadata: value,
    }
  }

  const genericEvent = (eventName, key = null) => payload => {
    const value = key !== null ? payload[key] : payload
    return {
      eventType: `${upperCaseName} ${eventName}`,
      metadata: {
        value,
      },
    }
  }

  function* watchLogEvents() {
    yield all([
      takeEvery(ADD_FILTER_SUCCESS, logEventAsync, addFilterEvent),
      takeEvery(SET_FILTERS, logEventAsync, setFiltersEvent),
      takeEvery(CLEAR_ALL_FILTERS, logEventAsync, genericEvent('Clear All Filters')),
      takeEvery(REMOVE_FILTER, logEventAsync, genericEvent('Remove Filter')),
      takeEvery(SET_ACTIVE_FILTER, logEventAsync, genericEvent('Set Active Column Filter', 'name')),
    ])
  }

  function* addFilterAsync({ payload }) {
    const filters = yield select(filtersSelector)
    const isEditingFilter = yield select(isEditingFilterSelector)
    const newFilterName = get(payload, 'name')
    const title = get(payload, 'filterConfig.title')
    const refType = yield select(rollupSelector)
    setQueryStringParam(REF_TYPE_PARAM_NAME, refType)

    const filterExists = find(filters, filter => filter.name === newFilterName)
    if (filterExists && !isEditingFilter) {
      const confirmed = yield call(confirmSaga, {
        ...CONFIRMATION_MESSAGES.replaceFilter({ title }),
      })
      if (!confirmed) {
        return
      }
    }
    yield put({ type: ADD_FILTER_SUCCESS, payload })
  }

  function* watchAddFilter() {
    yield takeEvery(ADD_FILTER_START, addFilterAsync)
  }

  function* watchFilterUpdate() {
    yield all([
      takeEvery(ADD_FILTER_SUCCESS, updateFilterURLParams),
      takeEvery(REMOVE_FILTER, updateFilterURLParams),
      takeEvery(SET_FILTERS, updateFilterURLParams),
      takeEvery(CLEAR_ALL_FILTERS, updateFilterURLParams),
    ])
  }

  return {
    actions,
    actionTypes,
    reducer: reducerWithFiltering,
    sagas: [watchLogEvents(), watchAddFilter(), watchFilterUpdate()],
  }
}
