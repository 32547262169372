import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'

import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DraggableMenu from './DraggableMenu'
import PropTypes from 'prop-types'
import React from 'react'
import arrayMove from 'array-move'
import c from './content'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import { getRollupLabel } from 'utils/rollups'
import logger from 'utils/logger'
import styles from './styles'
import without from 'lodash/without'

class ColumnEditor extends React.Component {
  constructor(props) {
    super(props)

    const { hiddenColumns } = this.props

    this.state = {
      newOrder: null,
      newHidden: null,
      activeRollup: null,
      allColumnsSelected: hiddenColumns.length === 0,
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    defaultColumnOrder: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    defaultHiddenColumns: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    rollup: PropTypes.string.isRequired,
    isExternalUser: PropTypes.bool.isRequired,
  }

  static getDerivedStateFromProps(props, state) {
    // Reset modal on tab switch
    if (props.rollup !== state.activeRollup) {
      return {
        newOrder: null,
        newHidden: null,
        activeRollup: props.rollup,
      }
    }

    return null
  }

  handleResetToDefault = () => {
    const { defaultColumnOrder, defaultHiddenColumns, rollup, isExternalUser } = this.props

    this.setState({
      newOrder: defaultColumnOrder,
      newHidden: defaultHiddenColumns,
      allColumnsSelected: defaultHiddenColumns.length === 0,
    })

    const msg = `${rollup} | ${isExternalUser ? 'Portal ' : ''}Column Editor Reset`
    logger.notify(msg, { defaultColumnOrder, defaultHiddenColumns })
  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const { newOrder } = this.state
    const { columns } = this.props

    if (newOrder) {
      this.setState(({ newOrder }) => ({
        newOrder: arrayMove(newOrder, oldIndex, newIndex),
      }))
    } else {
      this.setState({ newOrder: arrayMove(cloneDeep(columns), oldIndex, newIndex) })
    }
  }

  handleToggle = toggledColumn => {
    const { newHidden } = this.state
    const { hiddenColumns } = this.props

    if (newHidden) {
      this.setState(({ newHidden }) => {
        const updatedHidden = newHidden.includes(toggledColumn.title)
          ? without(newHidden, toggledColumn.title)
          : concat(newHidden, toggledColumn.title)

        return {
          newHidden: updatedHidden,
          allColumnsSelected: updatedHidden.length === 0,
        }
      })
    } else {
      this.setState(() => {
        const updatedHidden = hiddenColumns.includes(toggledColumn.title)
          ? without(hiddenColumns, toggledColumn.title)
          : concat(hiddenColumns, toggledColumn.title)

        return {
          newHidden: updatedHidden,
          allColumnsSelected: updatedHidden.length === 0,
        }
      })
    }
  }

  handleToggleAll = () => {
    const { columns } = this.props
    const { newHidden } = this.state
    const allColumns = columns.map(column => column.title)
    const someHidden = newHidden && !!newHidden.length
    if (someHidden) {
      return this.setState({ newHidden: [], allColumnsSelected: true })
    }
    return this.setState({ newHidden: [...allColumns], allColumnsSelected: false })
  }

  handleSubmit = event => {
    event.preventDefault()

    const { newOrder, newHidden } = this.state
    const { onSubmit, hiddenColumns, columns, rollup, isExternalUser } = this.props

    const payload = {
      orderedColumns: newOrder ? newOrder : columns,
      hiddenColumns: newHidden ? newHidden : hiddenColumns,
    }

    const msg = `${rollup} | ${isExternalUser ? 'Portal ' : ''}Column Editor Submit`
    logger.notify(msg, payload)
    onSubmit(payload)
  }

  render() {
    const { newOrder, newHidden, allColumnsSelected } = this.state
    const { classes, open, onClose, rollup, hiddenColumns, columns } = this.props

    return (
      <Dialog
        aria-labelledby="share-dialog-title"
        aria-describedby="share-modal-description"
        open={open}
        classes={{ paper: classes.dialogModal }}
        onClose={onClose}
        fullWidth
        PaperProps={{
          component: 'form',
          onSubmit: this.handleSubmit,
        }}
      >
        <DialogTitle id="share-dialog-title" className={classes.dialogTitle} disableTypography>
          <Typography className={classes.header} variant="h6">
            {`${c.title} ${getRollupLabel(rollup)}`}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={classes.additionalContent}>
          <DialogContentText>{c.body}</DialogContentText>
          <Tooltip
            placement="right"
            title={allColumnsSelected ? c.hideAllColumns : c.unhideAllColumns}
          >
            <Checkbox
              color="primary"
              aria-label="Select"
              checked={allColumnsSelected}
              onChange={this.handleToggleAll}
              indeterminate={!allColumnsSelected}
            />
          </Tooltip>
        </div>
        <DialogContent className={classes.border}>
          <DraggableMenu
            onToggle={this.handleToggle}
            onSortEnd={this.handleSortEnd}
            columns={newOrder ? newOrder : columns}
            hiddenColumns={newHidden ? newHidden : hiddenColumns}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={this.handleResetToDefault} variant="text">
            {c.resetButton}
          </Button>
          <Button type="submit" className={classes.submitButton} color="primary">
            {c.submitButton}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ColumnEditor)
