import './index.css'

import { days, routeColors } from 'store/planning/utils/RouteComparison'
import { routeComparisonMetricsSelector, selectedRouteIdsSelector } from 'store/planning'

import CardOverlay from 'components/core/CardOverlay'
import LineChart from 'components/HighChart'
import PropTypes from 'prop-types'
import React from 'react'
import SplineLoader from 'components/ChartLoader/SplineLoader'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { formatMinimalDecimals } from 'utils/strUtils'
import { nullZones } from './util'
import { routeGraphMode } from 'store/planning/utils/RouteComparison'
import { tickLabelForMetric } from 'store/planning/utils/DateRange'
import tracker from 'utils/logger/tracker'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  splineLoader: {
    marginTop: theme.spacing(3),
  },
})

function ComparisonRoutePerformanceGraph({
  height,
  isLoading,
  metrics,
  selectedRouteIds,
  setHighchartsChart,
  theme,
  error,
  classes,
}) {
  /**
   * Early returning here prevents following prevents
   * various errors later, as data is not available.
   */
  if (isLoading || error) {
    return (
      <>
        <SplineLoader className={classes.splineLoader} height={height} isAnimated={isLoading} />
        {error && <CardOverlay>{error}</CardOverlay>}
      </>
    )
  }

  const NULL_COLOR = theme.palette.grey[300]

  const colors = routeColors(theme)
  const routeIds = [...selectedRouteIds].filter(routeId => routeId in metrics)
  const indexesToVariability = {}
  const series = []

  routeIds.forEach((routeId, routeIdx) => {
    const data = []
    metrics[routeId].durations.forEach((m, metricIdx) => {
      data.push(m.metricsMedian === undefined ? null : m.metricsMedian)
      indexesToVariability[[routeIdx, metricIdx]] =
        m.variability === undefined ? null : m.variability
    })
    series.push({
      id: routeId,
      data,
      connectNulls: true,
      marker: { symbol: 'circle', radius: 3 },
      color: colors[routeIdx],
      zoneAxis: 'x',
      zones: nullZones(data, NULL_COLOR),
      point: {
        events: {
          mouseOver: () => {
            tracker.planning.userHoversGraphPoint(routeGraphMode.COMPARISON)
          },
        },
      },
    })
  })

  const xAxis =
    selectedRouteIds.size === 0
      ? []
      : metrics[Object.keys(metrics)[0]].durations.map(m => tickLabelForMetric(m))

  const buildTooltip = points => {
    let html = '<table class="RouteCompareTooltip">'
    html += `<tr><th></th><th>Time</th><th>Variability</th></tr>`
    let rowHtml = ''
    points.forEach(point => {
      if (point.color === NULL_COLOR) {
        return
      }

      const routeIdx = routeIds.indexOf(point.series.options.id)
      const metricIdx = point.point.index
      const variability = indexesToVariability[[routeIdx, metricIdx]]

      rowHtml += `
        <tr>
            <td><div class="RouteRectangle" style="background-color: ${point.color}"></div></td>
            <td><b>${formatMinimalDecimals(point.y, 1)}</b> days</td>
            <td><b>${
              variability !== null ? formatMinimalDecimals(variability, 1) : 'N/A'
            }</b> ${days(variability)}</td>
        </tr>
      `
    })
    if (!rowHtml) {
      return false
    }
    html += rowHtml
    html += '</table>'
    return html
  }

  return (
    <LineChart
      options={{
        chart: {
          type: 'spline',
          height,
        },
        title: {
          text: 'Placeholder',
          style: { visibility: 'hidden' },
        },
        tooltip: {
          useHTML: true,
          shared: true,
          formatter: function () {
            return buildTooltip(this.points)
          },
        },
        series,
        xAxis: {
          categories: xAxis,
          title: {
            text: 'Duration',
          },
          crosshair: { width: 1, color: theme.palette.grey[200] },
        },
        yAxis: {
          title: {
            text: 'Days',
          },
          min: 0,
        },
        legend: {
          enabled: false,
        },
      }}
      immutable={true}
      callback={function () {
        setHighchartsChart(this)
      }}
    />
  )
}

ComparisonRoutePerformanceGraph.propTypes = {
  height: PropTypes.number,
  metrics: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedRouteIds: PropTypes.object.isRequired,
  theme: PropTypes.object,
  setHighchartsChart: PropTypes.func.isRequired,
}

ComparisonRoutePerformanceGraph.defaultProps = {
  height: 300,
}

const mapStateToProps = state => ({
  metrics: routeComparisonMetricsSelector(state),
  selectedRouteIds: selectedRouteIdsSelector(state),
})

const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true })
)(ComparisonRoutePerformanceGraph)
