import React from 'react'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  shadow: {
    boxShadow: '0px 5px 4px 0px rgb(210, 210, 210)',
    opacity: '1 !important',
  },
  shadowDiv: {
    height: theme.spacing(1) + 2,
    width: '100%',
    opacity: 0,
    transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
  },
})

function ScrollShadow({ classes, visible }) {
  return <div className={classnames(classes.shadowDiv, { [classes.shadow]: visible })} />
}

export default withStyles(styles)(ScrollShadow)
