import { Box, Paper, Theme, Typography, makeStyles } from '@material-ui/core'
import { ROLLUPS, ROLLUP_LINE_ITEM } from 'utils/rollups'
import React, { useEffect, useRef, useState } from 'react'
import { deselectShipments, selectShipments } from 'store/board/actions'
import {
  deselectedIdsSelector,
  selectIsExhaustiveSelector,
  selectedIdsSelector,
  shipmentsLoadingFromState,
  structureShipmentsSelector,
} from 'store/board/selectors'
import { useDispatch, useSelector } from 'react-redux'

import Checkbox from 'components/core/Checkbox'
import EntityPageHeader from 'components/EntityPageHeader'
import Link from 'components/core/Link'
import PropTypes from 'prop-types'
import ScrollShadow from 'components/ScrollShadow'
import { Shipment } from 'utils/shipmentObjs'
import Timeline from 'components/Timeline'
import TimelineCardSkeleton from 'components/TimelineCardSkeleton'
import { TransportDetailInterface } from 'store/models/TransportDetail/interfaces'
import { TripInterface } from 'store/models/Trip'
import { UiGroupCollectionInterface } from 'store/types/UiGroupInterface'
import classnames from 'classnames'
import get from 'lodash/get'
import { getShipmentDetailPath } from 'utils/rollups'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.white,
    boxShadow: '0 50vh 0 50vh #ebf0f3',
    height: '100%',
    overflow: 'none',
    padding: theme.spacing(0, 2),
    position: 'relative',
  },
  loaded: {
    background: '#ebf0f3',
    marginTop: theme.spacing(1),
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  selectAll: {
    marginRight: `-${theme.spacing(0.5)}px`,
  },
  row: {
    borderBottom: 0,
  },
  overviewContainer: {
    minWidth: 'auto',
  },
  overviewCell: {
    paddingRight: theme.spacing(1),
  },
  selector: {
    alignSelf: 'start',
    margin: theme.spacing(-1, 1, 0, 0),
  },
  pageHeader: {
    minWidth: 'auto',
  },
  showAll: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  card: {
    minWidth: 1000,
  },
  skeletonContainer: {
    padding: 0,
  },
  skeletonTabsWrapper: {
    border: 0,
  },
  skeletonContentWrapper: {
    marginTop: 0,
  },
  noResultsOverlay: {
    top: 140,
  },
}))

interface Props {
  classes: Record<string, string>
  className?: string
}

const TimelineView = (props: Props) => {
  const { className } = props
  const classes = useStyles(props.classes)
  const [isScrolled, setIsScrolled] = useState(false)
  const dispatch = useDispatch()
  const textfieldScrollRef = useRef<null | HTMLDivElement>(null)

  const shipments = useSelector(state => structureShipmentsSelector(state))
  const isLoading = useSelector(state => shipmentsLoadingFromState(state))
  const selectedIds = useSelector(state => selectedIdsSelector(state))
  const selectIsExhaustive = useSelector(state => selectIsExhaustiveSelector(state))
  const deselectedIds = useSelector(state => deselectedIdsSelector(state))

  // Scrolls the timeline container to top when `shipments` changes if not already at top
  useEffect(() => {
    if (textfieldScrollRef.current && textfieldScrollRef.current.scrollTop > 0) {
      textfieldScrollRef.current.scrollTop = 0
    }
  }, [shipments, textfieldScrollRef])

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement
    const scrollTop = target.scrollTop

    if (!isScrolled && scrollTop > 0) {
      setIsScrolled(true)
    } else if (isScrolled && scrollTop === 0) {
      setIsScrolled(false)
    }
  }

  const onCheck = (shipment: Shipment) => dispatch(selectShipments(shipment))
  const onUncheck = (shipment: Shipment) => dispatch(deselectShipments(shipment))

  // Line items require the hyphenated number to build a link: `"264474-1"`.
  // @todo at some point when we are standardized on `self` we should move this to a global routes helper
  const getEntityLink = (self: TripInterface) => {
    if (self.type === ROLLUP_LINE_ITEM) {
      return getShipmentDetailPath(self.type, self.number, self.externalId)
    }
    return getShipmentDetailPath(self.type, self.id, self.externalId)
  }

  const getCommentLink = (transportDetail: TransportDetailInterface) => {
    const selfNumber = transportDetail?.self.number
    const selfType = transportDetail?.self.type
    const externalId = transportDetail?.self.externalId
    return (
      (selfType && externalId && getShipmentDetailPath(selfType, selfNumber, externalId)) ?? null
    )
  }

  return (
    <>
      <ScrollShadow visible={isScrolled} />
      <div
        className={classnames(classes.root, className, {
          [classes.loaded]: !isLoading && shipments.length > 0,
        })}
        onScroll={onScroll}
        ref={textfieldScrollRef}
      >
        {(isLoading || (!isLoading && shipments.length === 0)) && (
          <>
            <TimelineCardSkeleton
              classes={{
                container: classes.skeletonContainer,
                contentWrapper: classes.skeletonContentWrapper,
                tabsWrapper: classes.skeletonTabsWrapper,
              }}
              isAnimated={isLoading}
              message={
                !isLoading ? (
                  <>
                    No results found.
                    <br />
                    Please adjust your filter criteria.
                  </>
                ) : null
              }
              rowCount={3}
              showExpandedView={true}
            />
          </>
        )}
        {!isLoading &&
          shipments.length > 0 &&
          shipments.map((shipment: Shipment, idx: number) => {
            const { transportDetail } = shipment.shipmentBoard
            const commentIconLink = getCommentLink(transportDetail)
            const { pageHeader, self } = transportDetail ?? {}
            const entityLink = getEntityLink(self)
            const rowId = get(shipment, 'ref_id')
            const checkboxSelected =
              (selectIsExhaustive && !deselectedIds.includes(rowId)) || selectedIds.includes(rowId)
            // We only display the first index of `groupedMilestones` here
            const firstTimeline = transportDetail
              ?.groupedMilestones[0] as UiGroupCollectionInterface

            // Similary, for the subheader we use the first timeline in `transportDetail.timelines`
            const headerData = transportDetail.timelines[0]?.timelineHeader
            const timelinesCount = transportDetail.timelines.length
            return (
              <Box
                mb={3}
                className={classes.card}
                key={`timelineview-${idx}`}
                data-testid="timeline-view__card"
              >
                <Paper elevation={4}>
                  <Box pl={1} pr={3} pb={3}>
                    <Box pt={2} display="flex">
                      <Checkbox
                        className={classes.selector}
                        onCheck={() => {
                          onCheck(shipment)
                        }}
                        onUncheck={() => {
                          onUncheck(shipment)
                        }}
                        onClick={e => e.stopPropagation()}
                        checked={checkboxSelected}
                        color="primary"
                      />
                      {pageHeader && self.externalId && (
                        <EntityPageHeader
                          classes={{
                            overviewContainer: classes.overviewContainer,
                            pageHeader: classes.pageHeader,
                            row: classes.row,
                          }}
                          commentIconLink={commentIconLink}
                          externalId={self.externalId}
                          isContainerPage={false}
                          isBoard={true}
                          isPublicShare={false}
                          pageHeaderData={pageHeader}
                          refTypeLabel={pageHeader?.refTypeLabel}
                          entityLink={entityLink}
                        />
                      )}
                    </Box>
                    <Box ml={2}>
                      {timelinesCount > 1 && (
                        <Box mb={3} mt={-2}>
                          <Typography
                            className={classes.showAll}
                            component="span"
                            variant="body1"
                          >{`Showing 1 of ${timelinesCount} timelines`}</Typography>
                          <Box display="inline" ml={1}>
                            <Link to={entityLink}>See all</Link>
                          </Box>
                        </Box>
                      )}
                      <Timeline
                        isPublicShare={false}
                        key={`timeline-${idx}`}
                        tripsCount={headerData?.trips.length ?? null}
                        groupedMilestones={firstTimeline}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )
          })}
      </div>
    </>
  )
}

TimelineView.propTypes = {
  className: PropTypes.string,
  rollup: PropTypes.oneOf(ROLLUPS),
}

export default TimelineView
