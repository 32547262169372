import React, { ReactNode } from 'react'

import { adminFeaturesSelector } from 'store/featureFlags'
import { isSuperuserFromState } from 'store/auth/user/selectors'
import { useSelector } from 'react-redux'

type AdminComponentProps = {
  children: ReactNode
}

const AdminComponent = ({ children }: AdminComponentProps) => {
  const adminFeaturesEnabled = useSelector<boolean>(adminFeaturesSelector)
  const isSuperuser = useSelector<boolean>(isSuperuserFromState)

  // To be valid JSX we need to return a fragment
  return isSuperuser && adminFeaturesEnabled ? <>{children}</> : <></>
}

export default AdminComponent
