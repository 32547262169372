import { DATE_FORMAT } from 'store/planning/utils/DateRange'
import { convertToJSONLogic } from 'utils/filterGroups'
import { httpMethods } from 'utils/api/client'
import humps from 'humps'
import logger from 'utils/logger'
import moment from 'moment'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchRoutes: (filters, groupBy, startTime, endTime, metricNames) => {
    try {
      const jsonLogic = filters.length > 0 ? convertToJSONLogic(filters) : {}
      const groupByParams = (groupBy || []).map(fld => humps.decamelize(fld))
      return api._call('post', '/routes', {
        groupBy: groupByParams,
        filters: jsonLogic,
        startTime,
        endTime,
        metricNames,
      })
    } catch (e) {
      logger.captureAPIException(e)
      logger.localLog(`Error calling API endpoint: ${e}`, 'error')
    }
  },
  fetchRouteSegments: ({ filters, startTime, endTime, metricNames }) => {
    try {
      if (endTime) {
        // Move the date forward one day so that the range is inclusive.
        endTime = moment(endTime).add(1, 'day').format(DATE_FORMAT)
      }

      const jsonLogic = filters.length > 0 ? convertToJSONLogic(filters) : {}
      return api._call('post', '/routes/segments', {
        route_filters: jsonLogic,
        startTime,
        endTime,
        metricNames,
      })
    } catch (e) {
      logger.captureAPIException(e)
      logger.localLog(`Error calling API endpoint: ${e}`, 'error')
    }
  },
  fetchMetricsForRoutes: (
    groupedRouteIds,
    { startTime, endTime, aggregation, aggregationLength, metricNames }
  ) => {
    try {
      // Move the date forward one day so that the range is inclusive.
      endTime = moment(endTime).add(1, 'day').format(DATE_FORMAT)

      const params = { startTime, endTime, routeGroups: groupedRouteIds }
      if (aggregation) {
        params.aggrUnit = aggregation
      }
      if (aggregationLength) {
        params.aggrLength = aggregationLength
      }
      if (metricNames) {
        params.metricNames = metricNames
      }
      return api._call('post', '/routes/metrics', params)
    } catch (e) {
      logger.captureAPIException(e)
      logger.localLog(`Error calling API endpoint: ${e}`, 'error')
    }
  },
  fetchCountMetricsForRoutes: (
    groupedRouteIds,
    { metricName, startTime, endTime, upperBound, lowerBound },
    aggregation,
    aggregationLength
  ) => {
    try {
      endTime = moment(endTime).add(1, 'day').format(DATE_FORMAT)

      const params = {
        startTime,
        endTime,
        lowerBound,
        upperBound,
        routeGroups: groupedRouteIds,
      }
      if (aggregation) {
        params.aggrUnit = aggregation
      }
      if (aggregationLength) {
        params.aggrLength = aggregationLength
      }
      return api._call('post', `/routes/metric_counts/${metricName}`, params)
    } catch (e) {
      logger.captureAPIException(e)
      logger.localLog(`Error calling API endpoint: ${e}`, 'error')
    }
  },
  fetchMetricsForSegments: (
    groupedSegmentIds,
    { startTime, endTime, aggregation, aggregationLength, metricNames }
  ) => {
    try {
      if (endTime) {
        // Move the date forward one day so that the range is inclusive.
        endTime = moment(endTime).add(1, 'day').format(DATE_FORMAT)
      }

      const params = {
        startTime,
        endTime,
        routeSegmentGroups: groupedSegmentIds,
      }
      if (aggregation) {
        params.aggrUnit = aggregation
      }
      if (aggregationLength) {
        params.aggrLength = aggregationLength
      }
      if (metricNames) {
        params.metricNames = metricNames
      }
      return api._call('post', '/routes/segments/metrics', params)
    } catch (e) {
      logger.captureAPIException(e)
      logger.localLog(`Error calling API endpoint: ${e}`, 'error')
    }
  },
}

export default api
