import { types } from './actions'

export const TENANT_ACTION_HANDLERS = {
  [types.TENANT_SWITCH_LOAD]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: true,
    },
  }),
  [types.TENANT_SWITCH_SUCCESS]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: false,
      id: action.payload,
    },
  }),
  [types.TENANT_ADD_LOAD]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: true,
    },
  }),
  [types.TENANT_ADD_SUCCESS]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: false,
    },
  }),
  [types.SOURCE_ADD_LOAD]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: true,
    },
  }),
  [types.SOURCE_ADD_SUCCESS]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: false,
    },
  }),
  [types.TENANT_LIST_LOAD]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: true,
    },
  }),
  [types.TENANT_LIST_SUCCESS]: (state, action) => ({
    ...state,
    tenant: {
      ...state.tenant,
      loading: false,
      all: action.payload,
    },
  }),
  [types.UPDATE_SUB_TENANTS]: (state, action) => {
    const { subTenants } = action.payload

    return {
      ...state,
      tenant: {
        ...state.tenant,
        subTenants,
        isParent: Boolean(subTenants.length),
      },
    }
  },
}
