import { ADMIN_ROUTE, LAB_ROUTE, PORTAL_SETTINGS_ROUTE } from 'utils/routes'
import { Checkbox, Divider, FormControlLabel, ListItemText, MenuItem } from '@material-ui/core'
import {
  adminFeaturesSelector,
  disableConsoleLogging,
  enableConsoleLogging,
  logToConsoleSelector,
  setAdminFeatures,
} from 'store/featureFlags'

import AdminComponent from 'components/AdminComponent'
import Link from 'components/core/Link'
import MESSAGES from 'store/notifications/messages'
import PropTypes from 'prop-types'
import React from 'react'
import { TENANT_PARAM } from 'components/layouts/AuthLayout'
import { connect } from 'react-redux'
import copy from 'copy-to-clipboard'
import { currentTenantFromState } from 'store/auth/user/selectors'
import { showNotification } from 'store/notifications'
import { switchTenant } from 'store/auth/tenant/actions'

const UserMenu = ({
  adminFeaturesEnabled,
  consoleLoggingEnabled,
  disableConsoleLogging,
  enableConsoleLogging,
  setAdminFeatures,
  currentTenant,
  showNotification,
  setShowSuperSwitch,
  handleClose,
}) => {
  const copyUrl = () => {
    let tenantizedUrl = window.location.href
    tenantizedUrl +=
      tenantizedUrl.indexOf('?') !== -1
        ? `&${TENANT_PARAM}=${currentTenant}`
        : `?${TENANT_PARAM}=${currentTenant}`
    copy(tenantizedUrl)
    showNotification(MESSAGES.tenantizedUrlCopy)
  }

  const handleAdminFeaturesChange = () => {
    setAdminFeatures(!adminFeaturesEnabled)
  }

  const handleConsoleLoggingChange = () => {
    if (consoleLoggingEnabled) disableConsoleLogging()
    else enableConsoleLogging()
  }

  const LinkComponent = React.forwardRef(() => (
    <MenuItem onClick={handleClose} component={Link} to={ADMIN_ROUTE.buildUrl()}>
      <ListItemText primary="Admin Page" />
    </MenuItem>
  ))

  const PortalSettingsLinkComponent = React.forwardRef(() => (
    <MenuItem onClick={handleClose} component={Link} to={PORTAL_SETTINGS_ROUTE.buildUrl()}>
      <ListItemText primary="Portal Settings" />
    </MenuItem>
  ))

  return (
    <>
      <Divider />
      <LinkComponent />
      <AdminComponent>
        <PortalSettingsLinkComponent />
      </AdminComponent>
      <MenuItem
        onClick={() => {
          setShowSuperSwitch(true)
        }}
      >
        <ListItemText primary="Switch to Super" secondary={`Current Tenant: ${currentTenant}`} />
      </MenuItem>
      <MenuItem
        onClick={event => {
          handleClose(event)
          copyUrl()
        }}
      >
        <ListItemText primary="Copy Tenantized URL" />
      </MenuItem>
      <Divider />
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={adminFeaturesEnabled}
              onChange={handleAdminFeaturesChange}
              value="adminFeatures"
              color="primary"
            />
          }
          label="Admin features"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={consoleLoggingEnabled}
              onChange={handleConsoleLoggingChange}
              value="adminFeatures"
              color="primary"
            />
          }
          label="Log events to console"
        />
      </MenuItem>
      <MenuItem
        key="lab"
        component={Link}
        to={LAB_ROUTE.buildUrl()}
        onClick={event => {
          handleClose(event)
        }}
      >
        <ListItemText primary="Route Trip Map" />
      </MenuItem>
    </>
  )
}

UserMenu.propTypes = {
  showNotification: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
}

const mapStateToProps = state => ({
  currentTenant: currentTenantFromState(state),
  adminFeaturesEnabled: adminFeaturesSelector(state),
  consoleLoggingEnabled: logToConsoleSelector(state),
})

const mapDispatchToProps = {
  switchTenant,
  showNotification,
  setAdminFeatures,
  enableConsoleLogging,
  disableConsoleLogging,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
