/* global __API_URL__ */
import { LOGIN_ROUTE } from 'utils/routes'
import axios from 'axios'
import browserHistory from 'utils/history'
import logger from 'utils/logger'
import querystring from 'querystring'

logger.localLog('API forwarding set to: ' + __API_URL__)

const instance = axios.create({
  baseURL: __API_URL__,
  // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
  xsrfCookieName: 'csrf_access_token',
  // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  xsrfHeaderName: 'X-CSRF-TOKEN',
  headers: { 'Cache-Control': 'no-cache' },
  paramsSerializer: params => {
    /**
     * Remove param keys with empty value
     */
    let cleanParams = {}
    for (let key in params) {
      // make sure params[key] is defined
      if (params[key] !== null && params[key] !== undefined) cleanParams[key] = params[key]
    }
    return querystring.stringify(cleanParams)
  },
})

instance.interceptors.request.use(function (config) {
  // use refresh csrf token for refreshing
  if (config.url.indexOf('/token/refresh') !== -1) {
    config.xsrfCookieName = 'csrf_refresh_token'
  }
  return config
})

const authAndPublicURLs = [
  '/login',
  '/logout',
  '/tenant',
  '/shared_shipments',
  '/oauth_status',
  '/public_feature_flags',
]

export const retryStatus = 401

// this was formerly for dealing with JWT tokens. we still want to log out if we get a 401 while refreshing
instance.interceptors.response.use(
  function (successfulResponse) {
    return successfulResponse
  },
  error => {
    if (!error.response) {
      return Promise.reject(error)
    }

    const errorResponse = error.response
    const originalRequest = error.config
    const requestURL = errorResponse.config.url

    for (let authRequestURL of authAndPublicURLs) {
      if (requestURL.indexOf(authRequestURL) !== -1) {
        return Promise.reject(error)
      }
    }

    if (requestURL.indexOf('refresh') !== -1) {
      logger.notify('Unexpected Auth Failure - Logging Out')
      // we want to avoid touching Redux state in this interceptor, so just force going to the login page and
      // clear Redux state
      if (window.location.href.indexOf(LOGIN_ROUTE.buildUrl()) === -1) {
        browserHistory.push(LOGIN_ROUTE.buildUrl())
        window.location.reload()
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }

    if (error.response.status === retryStatus && !originalRequest._retry) {
      originalRequest._retry = true
      return instance.post('/token/refresh').then(() => {
        // now that we have an access token, we can retry the original request
        return instance(originalRequest)
      })
    }

    return Promise.reject(error)
  }
)

export const httpMethods = {
  get: (url, body) => instance.get(url, { params: body }),
  post: (url, body) => instance.post(url, body),
  put: (url, body) => instance.put(url, body),
  delete: (url, body) => instance.delete(url, { data: body }),
}

export default instance
