/*
 * Organize Color Objects by spectrum
 * Organize codes by light -> dark
 */
const colors = {
  white: '#ffffff',
  red: {
    50: '#f8e0e1',
    200: '#f3c7c9',
    400: '#d31315',
  },
  purple: {
    100: '#f6f1ff',
    600: '#6D1F80',
  },
  orange: {
    50: '#ffe7d9',
    200: '#ffc9aa',
    400: '#fd5f00',
    500: '#e86929',
  },
  amber: {
    400: '#ffcf3d',
  },
  yellow: {
    25: '#fff7ed',
    50: '#fff1df',
    200: '#fddeb5',
    250: '#fff77f',
    300: '#edde00',
    400: '#f7b500',
    500: '#fd9f28',
  },
  green: {
    50: '#edf8ed',
    200: '#cef1ce',
    400: '#4ab653',
    425: '#64B432',
  },
  teal: {
    600: '#008e86',
  },
  blue: {
    25: '#eff7ff',
    50: '#e6eff8',
    200: '#ccdef1',
    300: '#80a9e5',
    400: '#5893d4',
    600: '#2970be',
    800: '#2e3e55',
  },
  custom: {
    grey25: '#f8f8f8',
  },
  grey: {
    50: '#f5f8fa',
    100: '#ebf0f3',
    200: '#e5eaee',
    300: '#c2cbcc',
    500: '#859198',
    600: '#616A70',
    800: '#343741',
    900: '#171d26',
    950: 'rgba(0,0,0,0.1)',
    // the following are specific to material ui create theme
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  black: '#000000',
} as const

export const BRAND_COLOR = '#00558B'

export const namedColors = {
  status: {
    delivered: '#64b432',
    discharged: '#64b432',
    intransit: '#008e86',
    pending: '#ffcf3d',
    unknown: '#616a70',
  },
} as const

export const datavisColors = {
  blue: {
    50: '#e6eff8',
    100: '#bee3f8',
    200: '#58B9CC',
    300: '#80a9e5',
    400: '#5893d4',
    500: '#32c5ff',
    600: '#2970BE',
    700: '#0A97CA',
    730: 'rgba(8, 151, 202, 0.3)', // [700] with alpha
  },
  green: {
    300: '#35ff00',
    400: '#4ab653',
    500: '#00ba43',
    600: '#4ab66e',
  },
  grey: {
    200: '#e5eaee',
    300: '#c2cbcc',
    400: '#b0b4ba',
  },
  orange: {
    400: '#fc791d',
    500: '#e86929',
  },
  pink: {
    400: '#e4117e',
    500: '#B40F65',
  },
  purple: {
    300: '#5d409b',
    400: '#b895f5',
    500: '#8400ff',
    600: '#6D1F80',
    800: '#320066',
    900: '#061861',
  },
  yellow: {
    300: '#edde00',
    400: '#f7b500',
    500: '#fd9f28',
  },
} as const

export default colors
