import { Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core'

import AdminButton from '../AdminButton'
import CloseIcon from '@material-ui/icons/Close'
import InviteForm from './InviteForm'
import InviteIcon from '@material-ui/icons/PersonAdd'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
  root: {},
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  dialogPaper: {
    minWidth: 700,
    minHeight: 500,
  },
})

class InviteUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      tenant: null,
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  handleCancel = () => {
    this.setState({
      open: false,
    })
  }

  openDialog = () => {
    this.setState({
      open: true,
    })
  }

  render() {
    const { open } = this.state
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <AdminButton onClick={this.openDialog} text={'Invite Users'} icon={<InviteIcon />} />
        <Dialog open={open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>
            Invite Users
            <CloseIcon className={classes.closeIcon} onClick={this.handleCancel} />
          </DialogTitle>
          <DialogContent>
            <InviteForm />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(InviteUser)
