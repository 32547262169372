import { List, ListItem, ListItemText, TextField, withStyles } from '@material-ui/core'
import { tenantListFromState, tenantLoadingFromState } from 'store/auth/tenant/selectors'

import CenteredLayout from 'components/layouts/CenteredLayout'
import { Loader } from 'components/Loader/Loader'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { currentTenantFromState } from 'store/auth/user/selectors'
import { impersonateUser } from 'store/auth/impersonate/actions'
import { listTenants } from 'store/auth/tenant/actions'

const styles = theme => ({
  container: {
    width: theme.spacing(40),
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(1),
    maxHeight: 300,
    overflow: 'auto',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    width: 320,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  flex: {
    display: 'flex',
  },
  selectTenant: {
    marginLeft: theme.spacing(3),
    fontSize: 18,
  },
})

class ListTenants extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTenant: props.defaultSelected,
      tenantSearch: '',
    }
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    listTenants: PropTypes.func.isRequired,
    defaultSelected: PropTypes.string,
  }

  static defaultProps = {
    defaultSelected: null,
  }

  componentDidMount() {
    this.props.listTenants()
  }

  handleTenantClick = tenant => () => {
    this.setState(
      {
        selectedTenant: tenant,
      },
      () => {
        this.props.tenantClick(tenant)
      }
    )
  }

  render() {
    const { selectedTenant, tenantSearch } = this.state
    const { classes, tenants, loading } = this.props
    return (
      <div>
        <TextField
          autoFocus={true}
          onChange={e => this.setState({ tenantSearch: e.target.value })}
          value={tenantSearch}
          className={classes.searchInput}
          placeholder={'Search for a tenant'}
          variant="filled"
        />
        <div className={classes.container}>
          {loading ? (
            <CenteredLayout>
              <Loader />
            </CenteredLayout>
          ) : (
            <List>
              {tenants
                .filter(item => item.name.includes(tenantSearch))
                .map((item, index) => {
                  const style = item.name === selectedTenant ? { backgroundColor: '#00558B' } : {}
                  return (
                    <ListItem
                      button
                      style={style}
                      onClick={this.handleTenantClick(item.name)}
                      key={index}
                      className={classes.listItem}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  )
                })}
            </List>
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  impersonateUser,
  listTenants,
}

const mapStateToProps = state => {
  return {
    tenants: tenantListFromState(state),
    loading: tenantLoadingFromState(state),
    currentTenant: currentTenantFromState(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListTenants))
