import { Box, Theme, makeStyles } from '@material-ui/core'
import {
  EMPTY_DISPATCH,
  EMPTY_RETURN,
  TIMELINE_STAGE_GROUPS,
} from 'utils/milestone/MilestoneGroups/definitions'
import UiGroupInterface, { UiGroupCollectionInterface } from 'store/types/UiGroupInterface'
import {
  journeyDetailsLoadingSelector,
  journeyDetailsSelector,
} from 'store/journeyDetails/selectors'

import React from 'react'
import Skeleton from 'components/Timeline/Skeleton'
// _Not_ `components/TimelineSegment` but eventually should be. Currently that file is molded for the entity page.
import TimelineSegment from 'pages/ContainerPage/Timeline/TimelineSegment'
import TripStage from 'store/models/definitions/TripStage'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'

const TABLE_MIN_WIDTH = 970

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(5),
    minWidth: TABLE_MIN_WIDTH,
    position: 'relative',
  },
  timeline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',

    '& > div': {
      flex: 1,
    },
  },
}))

const Timeline = () => {
  const classes = useStyles()

  const journeyDetails = useSelector(journeyDetailsSelector)
  const journeyDetailsLoading = useSelector(journeyDetailsLoadingSelector)

  const groupedMilestones: UiGroupCollectionInterface =
    (journeyDetails?.groupedMilestones && journeyDetails.groupedMilestones[0]) ?? []

  if (!groupedMilestones) {
    return null
  }

  // We need to know the first group with data
  const groupKeys = Object.keys(groupedMilestones).filter(
    group => group !== EMPTY_DISPATCH && group !== EMPTY_RETURN
  )
  const firstGroupWithData = find(groupKeys, key => !isEmpty(groupedMilestones[key]))

  // Gets the total group count, used on the timeline to show/hide vehicle names
  const groupCount = flatMap(groupedMilestones).reduce(
    (acc: number, val: any) => acc + Object.keys(val).length,
    0
  )

  if (journeyDetailsLoading) {
    return <Skeleton isAnimated={true} />
  }

  if (!journeyDetailsLoading && !journeyDetails) {
    return null
  }

  return (
    <Box mt={3} className={classes.container} data-testid="container_page__timeline">
      <Box className={classes.timeline}>
        {
          /**
           * We're looping four times here:
           * 1. Stages - pre-main, main, post-main
           * 2. UI Groups: Empty Dispatch, Pre-Carriage, etc. These are arrays of arrays `[ [unlocode] ]
           * 3. Unlocode groups - each unique location for each UI Group (there can be multiples of UI Groups).
           * 4. Milestones for each Unlocode group
           */
          // Stages
          Object.keys(TripStage).map(stage => {
            // UI Groups
            return TIMELINE_STAGE_GROUPS[stage]?.map(
              group =>
                // Unlocode groups
                groupedMilestones[group] &&
                Object.values(groupedMilestones[group]).map(
                  (unlocodeGroup: UiGroupInterface, idx) => {
                    // Milestones for this unlocode group
                    const locationMilestones = unlocodeGroup.milestones
                    return (
                      locationMilestones.length > 0 && (
                        <React.Fragment key={`unlocode-${idx}`}>
                          <TimelineSegment
                            group={unlocodeGroup}
                            groupCount={groupCount}
                            groupName={group}
                            locationZone={
                              locationMilestones ? locationMilestones[0].locationZone : null
                            }
                            milestones={locationMilestones}
                            isFirstGroup={firstGroupWithData === group}
                          />
                        </React.Fragment>
                      )
                    )
                  }
                )
            )
          })
        }
      </Box>
    </Box>
  )
}

export default React.memo(Timeline)
