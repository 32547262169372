import { getDefaultAndCustomizedFilters, viewsSelector } from 'store/boardUtils/selectors'

import ErrorBoundary from 'components/ErrorBoundary'
import PropTypes from 'prop-types'
import React from 'react'
import ShipmentFilterOverview from '../ShipmentFilterOverview'
import { connect } from 'react-redux'
import { convertFieldsToFilters } from 'utils/filterUtils'
import { createSelector } from 'reselect'
import { fetchViews } from 'store/boardUtils/actions'
import find from 'lodash/find'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  title: {
    color: '#66717e',
    fontSize: 22,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  viewTitle: {
    color: '#66717e',
    fontSize: 14,
    marginTop: 6,
    marginLeft: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
})

class FilteredAlertConfigSummary extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fetchViews: PropTypes.func.isRequired,
    filters: PropTypes.array,
    shipmentView: PropTypes.object,
  }

  componentDidMount() {
    const { shipmentView, fetchViews } = this.props

    if (!shipmentView) fetchViews()
  }

  render() {
    const { filters, shipmentView } = this.props

    // Used to trigger an ErrorBoundary reset on UI change
    const errorBoundaryKey = get(shipmentView, 'id')

    return (
      <div>
        <ErrorBoundary key={errorBoundaryKey}>
          <ShipmentFilterOverview filters={filters} />
        </ErrorBoundary>
      </div>
    )
  }
}

const mapDispatchToProps = {
  fetchViews,
}

const currentConfig = (state, props) => props.config
const findView = (views, currentConfig) => {
  return find(views, view => view.id === currentConfig.shipment_view_id)
}
const shipmentViewReselect = createSelector(viewsSelector, currentConfig, findView)

const mapStateToProps = (state, props) => {
  const shipmentView = shipmentViewReselect(state, props)
  const filters = convertFieldsToFilters(
    get(shipmentView, 'fields', []),
    getDefaultAndCustomizedFilters(state)
  )

  return {
    shipmentView,
    filters,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilteredAlertConfigSummary))
