import {
  addLineLayers,
  adjustViewport,
  getMarkers,
  newVesselTrips,
  vesselLocationsLoaded,
  vesselPathsLoaded,
} from './Utils'
import { allVesselPathsSelector, getVesselPath, vesselPathFromState } from 'store/api/vesselPath'

import { BaseControl } from 'react-map-gl'
import { MARKER_TYPE_VESSEL } from './Utils'
import PropTypes from 'prop-types'
import React from 'react'
import VesselNotification from 'components/Maps/VesselNotification'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'
import last from 'lodash/last'
import moment from 'moment'
import tinycolor from 'tinycolor2'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  invalidText: {
    fontSize: 12,
    color: theme.palette.grey[100],
  },
  invalidSummary: {
    fontSize: 12,
    color: theme.palette.grey[100],
  },
  invalidDataContainer: {
    float: 'right',
    clear: 'both',
    padding: '10px',
    backgroundColor: tinycolor('#f59948').setAlpha(0.9).toRgbString(),
    width: 270,
    marginBottom: 1,
  },
  latestUpdateContainer: {
    float: 'right',
    clear: 'both',
    padding: '10px',
    backgroundColor: tinycolor('#4c8dee').setAlpha(0.9).toRgbString(),
    width: 270,
    marginBottom: 1,
  },
  latestUpdateText: {
    color: 'white',
    fontSize: 12,
  },
  incompleteDataContainer: {
    float: 'right',
    clear: 'both',
    padding: '10px',
    backgroundColor: tinycolor('#2e3e55').setAlpha(0.8).toRgbString(),
    width: 270,
    marginBottom: 1,
  },
})

class VesselLocation extends BaseControl {
  constructor(props) {
    super(props)
    this.state = {
      layers: [],
    }
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    vesselTrips: PropTypes.array.isRequired,
    vesselLocations: PropTypes.object.isRequired,
    mapNode: PropTypes.object,
    focused: PropTypes.bool,
  }

  static defaultProps = {
    focused: true,
    maxZoom: 2.2,
  }

  updateLayerState = layers => {
    this.setState({
      layers,
    })
  }

  UNSAFE_componentWillMount() {
    const { vesselTrips, getVesselPath } = this.props
    for (let i = 0; i < vesselTrips.length; i++) {
      const { lloydsCode, startDate, endDate, live } = vesselTrips[i]
      const formattedStartDate = moment.isMoment(startDate) ? startDate.toISOString() : startDate
      let formattedEndDate = moment.isMoment(endDate) ? endDate.toISOString() : endDate
      if (live) {
        formattedEndDate = moment().toISOString() // set end date to today
      }
      if (lloydsCode) getVesselPath(lloydsCode, formattedStartDate, formattedEndDate) // TODO - add dates
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loaded) {
      const mapInitialLoad = nextProps.loaded !== this.props.loaded

      if (
        mapInitialLoad ||
        vesselPathsLoaded(this.props.vesselPaths, nextProps.vesselPaths) ||
        newVesselTrips(this.props.vesselPaths, nextProps.vesselPaths)
      ) {
        addLineLayers(nextProps, this.updateLayerState)()
        adjustViewport(nextProps)
      }

      if (
        mapInitialLoad ||
        vesselLocationsLoaded(this.props.vesselLocations, nextProps.vesselLocations)
      ) {
        adjustViewport(nextProps)
      }
    }
  }

  render() {
    const { vesselLocations, currVessel, vesselsWithNoData, vesselsWithMissingData } = this.props

    const markers = getMarkers(vesselLocations, MARKER_TYPE_VESSEL, this.props)

    return (
      <React.Fragment>
        {markers}
        <VesselNotification
          currVessel={currVessel}
          vesselsWithMissingData={vesselsWithMissingData}
          invalidVessels={vesselsWithNoData}
        />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  getVesselPath,
}

const vesselPathsSelector = state => allVesselPathsSelector(state)
const vesselTripsSelector = (state, props) => {
  return props.vesselTrips
}

const vesselSelector = (state, props) => {
  const vesselTrips = props.vesselTrips
  const vessels = vesselTrips.map(vesselTrip => {
    return { ...vesselTrip, path: vesselPathFromState(state, vesselTrip.lloydsCode) }
  })
  return vessels
}

function isVesselTripIncomplete(vesselTrip, path) {
  if (path.length) {
    const firstPoint = first(path)
    const lastPoint = last(path)
    const firstDate = moment(get(firstPoint, 'timestamp'))
    const lastDate = moment(get(lastPoint, 'timestamp'))
    const vesselTripStartDate = moment(vesselTrip.startDate)
    const vesselTripEndDate = moment(vesselTrip.endDate)
    if (
      !firstDate.isValid() ||
      !lastDate.isValid() ||
      !vesselTripStartDate.isValid() ||
      !vesselTripEndDate.isValid()
    )
      return { complete: false, missingStart: null, missingEnd: null }
    let missingStart = firstDate.diff(vesselTripStartDate, 'days')
    const missingEnd = vesselTripEndDate.diff(lastDate, 'days')
    // const complete = missingStart === 0 && missingEnd === 0
    const complete = missingEnd <= 0 // TODO - temp fix till we build up AIS data
    missingStart = 0 // TODO - temp fix till we build up AIS data
    return { complete, missingStart, missingEnd, firstDate, lastDate }
  }
  return { complete: false, missingStart: null, missingEnd: null, firstDate: null, lastDate: null }
}

function getVesselPoints(vesselTrips, paths) {
  let vessels = {}
  let vesselsWithNoData = []
  let vesselsWithMissingData = []
  for (let i = 0; i < vesselTrips.length; i++) {
    let vesselTrip = vesselTrips[i]

    const lloydsCode = vesselTrip.lloydsCode
    const nameIsValid = Boolean(vesselTrip.lloydsCode || vesselTrip.name)
    const pathObj = get(paths, vesselTrip.lloydsCode, {})
    const path = get(pathObj, 'path', [])
    const { complete, missingStart, missingEnd, firstDate, lastDate } = isVesselTripIncomplete(
      vesselTrip,
      path
    )

    // Current location is last lat/lon in path
    const currVesselLoc = last(path)
    if (currVesselLoc && vesselTrip.live) {
      if (!(lloydsCode in vessels))
        vessels[lloydsCode] = { ...vesselTrip, vesselLoc: currVesselLoc }
    } else {
      if (!currVesselLoc && !pathObj.loading && nameIsValid) {
        vesselsWithNoData.push(vesselTrip)
      }
    }
    if (currVesselLoc && !complete && !pathObj.loading && nameIsValid) {
      vesselsWithMissingData.push({ vesselTrip, missingStart, missingEnd, firstDate, lastDate })
    }
  }
  const currVessel = find(vessels, vessel => vessel.live)
  return { vessels, vesselsWithNoData, vesselsWithMissingData, currVessel }
}

function getVesselPaths(vesselTrips) {
  for (let i = 0; i < vesselTrips.length; i++) {
    let convertedData = []
    let { path } = vesselTrips[i]

    let currGroup = path[0]
    let conversion = 0

    for (let j = 1; j < path.length; j++) {
      let nextGroup = path[j]
      if (currGroup.lon < -170 && nextGroup.lon > 170) {
        conversion = -360
      } else if (currGroup.lon > 170 && nextGroup.lon < -170) {
        conversion = 360
      }
      nextGroup.lon += conversion

      convertedData.push({
        sourcePosition: [currGroup.lon, currGroup.lat],
        targetPosition: [nextGroup.lon, nextGroup.lat],
      })
      currGroup = nextGroup
    }
    vesselTrips[i].path = convertedData
  }
  return vesselTrips
}

const vesselPointsSelector = createSelector(
  vesselTripsSelector,
  vesselPathsSelector,
  getVesselPoints
)

const vesselPathSelector = createSelector(vesselSelector, getVesselPaths)

const mapStateToProps = (state, props) => {
  const { vessels, vesselsWithNoData, vesselsWithMissingData, currVessel } = vesselPointsSelector(
    state,
    props
  )
  const vesselPaths = vesselPathSelector(state, props)
  return {
    vesselLocations: vessels,
    currVessel,
    vesselsWithNoData,
    vesselPaths,
    vesselsWithMissingData,
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(VesselLocation)
