import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchPublicShare: ({ guid, version = null }) => {
    if (version) {
      return api._call('get', '/shared_shipments', { guid, version })
    }
    return api._call('get', '/shared_shipments', { guid })
  },
}

export default api
