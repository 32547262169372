import store from 'store'
import { types } from './actions'

// TODO converting this to typescript will require making an interface for
// the root auth reducer or restructuring the reducers because here the state
// parameter is actually the auth state and this reducer is modifying that
// state. So if you make a `LoginState` parameter here it won't work
export const LOGIN_ACTION_HANDLERS = {
  [types.LOGIN_LOAD]: state => ({
    ...state,
    login: {
      ...state.login,
      loading: true,
    },
  }),
  [types.LOGIN_SUCCESS]: state => ({
    ...state,
    login: {
      ...state.login,
      loggedIn: true,
      loading: false,
    },
  }),
  [types.LOGIN_FAILURE]: state => ({
    ...state,
    login: {
      ...state.login,
      loggedIn: false,
      loading: false,
    },
  }),
  [types.SET_LOGIN_ERROR_MESSAGE]: (state, action) => ({
    ...state,
    login: {
      ...state.login,
      errorMessage: action.payload.message,
    },
  }),
  [types.INCREMENT_CONSECUTIVE_FAILED_LOGINS]: state => {
    const newConsecutiveLogins = (state.login.consecutiveFailedLogins || 0) + 1
    store.set('consecutiveFailedLogins', newConsecutiveLogins)
    return {
      ...state,
      login: {
        ...state.login,
        consecutiveFailedLogins: newConsecutiveLogins,
      },
    }
  },
  [types.RESET_CONSECUTIVE_FAILED_LOGINS]: state => {
    store.set('consecutiveFailedLogins', 0)
    return {
      ...state,
      login: {
        ...state.login,
        consecutiveFailedLogins: 0,
      },
    }
  },
  [types.SET_INTENDED_USERNAME]: (state, action) => {
    return {
      ...state,
      login: {
        ...state.login,
        intendedUsername: action.payload.intendedUsername,
      },
    }
  },
  [types.SET_AUTH_STAGE]: (state, action) => {
    return {
      ...state,
      login: {
        ...state.login,
        authStage: action.payload.authStage,
      },
    }
  },
}
