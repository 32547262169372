import { COMMA, ENTER, SPACE } from 'utils/syntheticEvents'

export const SYNTHETIC_EVENTS = [ENTER, COMMA, SPACE]

export function splitOnDelimiters(string) {
  const delimiters = new RegExp(/(^\s*,)|(\n)|(,)|(,\s*$)/g)

  if (!/\S/.test(string)) {
    return []
  } else {
    return string
      .replace(delimiters, ' ')
      .split(' ')
      .filter(string => string !== '')
  }
}

export const getValidEmails = chipConfigs => {
  const validConfigs = chipConfigs.filter(configs => configs.valid)
  const emailInputs = validConfigs.map(validConfigs => validConfigs.label)
  const inputsWithoutDuplicates = emailInputs.filter(
    (val, index, self) => self.indexOf(val) === index
  )

  return inputsWithoutDuplicates
}

export const getSubjectLine = user => {
  if (user.name) {
    return `${user.name} shared shipments`
  } else if (user.email) {
    return `${user.email} shared shipments`
  } else {
    return ''
  }
}
