import CancelIcon from '@material-ui/icons/Cancel'
import { Autocomplete as MuiAutcomplete } from '@material-ui/lab'
import React from 'react'

AutocompleteBase.defaultProps = {
  ChipProps: { variant: 'outlined' },
  clearText: 'Clear Field',
  closeIcon: <CancelIcon />,
  loadingText: 'Searching...',
  noOptionsText: 'No results found.',
}

function AutocompleteBase({
  ChipProps,
  clearText,
  closeIcon,
  loadingText,
  noOptionsText,
  ...autocompleteProps
}) {
  return (
    <MuiAutcomplete
      {...autocompleteProps}
      ChipProps={ChipProps}
      clearText={clearText}
      closeIcon={closeIcon}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
    />
  )
}

export default AutocompleteBase
