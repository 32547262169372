import { LOCATION_TYPES, clearNotification, notificationStateSelector } from 'store/notifications'
import { useDispatch, useSelector } from 'react-redux'

import React from 'react'
import Snackbar from './Snackbar'
import { SnackbarOrigin } from '@material-ui/core'

function Notifications() {
  const dispatch = useDispatch()
  const { message, isPersistent, type, isOpen, location } = useSelector(notificationStateSelector)

  function getLocation(location: LOCATION_TYPES): SnackbarOrigin {
    switch (location) {
      case LOCATION_TYPES.TOP:
        return { vertical: 'top', horizontal: 'center' }
      case LOCATION_TYPES.BOTTOM_LEFT:
        return { vertical: 'bottom', horizontal: 'left' }
      default:
        return { vertical: 'top', horizontal: 'center' }
    }
  }

  return (
    <Snackbar
      isPersistent={isPersistent}
      anchorOrigin={getLocation(location)}
      message={message}
      onClose={() => dispatch(clearNotification())}
      open={isOpen}
      type={type}
    />
  )
}

export default Notifications
