import { types } from './actions'

export const PASSWORD_RESET_CONFIRMATION_ACTION_HANDLERS = {
  [types.PASSWORD_RESET_CONFIRMATION_LOAD]: (state, action) => ({
    ...state,
    passwordResetConfirm: {
      ...state.passwordResetConfirm,
      loading: true,
    },
  }),
  [types.PASSWORD_RESET_CONFIRMATION_SUCCESS]: (state, action) => ({
    ...state,
    passwordResetConfirm: {
      ...state.passwordResetConfirm,
      loading: false,
    },
    login: {
      ...state.login,
      loggedIn: true,
    },
  }),
}
