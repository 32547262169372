/**
 * Keep react-app-polyfills at top
 *
 * TODO:
 * investigate polyfills provided by react-app-polyfill to reduce dependency on CRA
 *
 *  */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
/* global __SEGMENT_WRITE_KEY__ */
import 'core-js/es/symbol'
import 'core-js/es/object'
import 'core-js/es/array'
import 'core-js/es/promise'
import 'core-js/es/string'
import 'core-js/es/object'
import 'core-js/features/array/from'
import 'core-js/features/number/is-finite'
import 'core-js/features/number/is-integer'
import 'core-js/features/reflect'
import 'core-js/features/symbol'
// The polyfills below are necessary for Redoc.ly IE11 support
import 'core-js/features/array/find'
import 'core-js/features/object/assign'
import 'core-js/features/string/ends-with'
import 'core-js/features/string/starts-with'
import 'core-js/es/map'
import 'unfetch/polyfill/index' // or any other fetch polyfill
import 'url-polyfill'
import 'intersection-observer'
import './styles/main.css'
import 'typeface-roboto'

import * as serviceWorker from './serviceWorker'

import { checkForImpersonators, persistIsImpersonatingToLocalStorage } from 'store/auth/impersonate'

import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './store/createStore'
import get from 'lodash/get'
import installSegment from 'utils/segment'
import logger from 'utils/logger'

installSegment(__SEGMENT_WRITE_KEY__)

const store = createStore()

const checkForExplicitLogging = () => {
  const state = store.getState()
  logger.logToConsole = get(state, 'featureFlags.logToConsole', false)
}

store.subscribe(persistIsImpersonatingToLocalStorage(store))
store.subscribe(checkForImpersonators(store))
store.subscribe(checkForExplicitLogging)

const App = require('./components/App').default

window.analytics.ready(function () {
  ReactDOM.render(<App store={store} />, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
