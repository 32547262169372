import React, { useState } from 'react'

import ListCell from 'components/core/ConfigurableTable/CellTypes/ConfigurableCell/ListCell'

export default function TruncatedListCell({ items, isRowHovered }) {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <ListCell
      onRowExpand={() => setIsExpanded(true)}
      onRowCollapse={() => setIsExpanded(false)}
      isRowExpanded={isExpanded}
      isRowHovered={isRowHovered}
      row={{ items }}
      column={{ getCellValue: row => row.items, style: { paddingLeft: 0, paddingTop: 0 } }}
      style={{ minWidth: null }}
    />
  )
}
