import get from 'lodash/get'
import map from 'lodash/map'
import reject from 'lodash/reject'
import { types } from 'store/board/actions'
import union from 'lodash/union'

export const MAX_NUM_SHIPMENT_ROWS = 10

const ACTION_HANDLERS = {
  [types.GET_INITIAL_REF_TYPE]: state => ({
    ...state,
    loading: true,
  }),
  [types.GET_SHIPMENTS_LOAD]: (state, { payload }) => ({
    ...state,
    loading: true,
    currentPage: payload.page,
  }),
  [types.GET_SHIPMENTS_LOAD_SUCCESS]: (state, { payload }) => {
    let newData = Object.assign({}, state.data, {
      [payload.page]: payload.data,
    })
    return {
      ...state,
      loading: false,
      data: newData,
    }
  },
  [types.GET_TRIP_TIMELINES_LOAD]: (state, { payload }) => ({
    ...state,
    currentPage: payload.page,
  }),
  [types.GET_TRIP_TIMELINES_SUCCESS]: (state, { payload }) => {
    // This follows the paging/caching of `GET_SHIPMENTS_LOAD_SUCCESS`
    let newData = Object.assign({}, state.tripTimelines, {
      [payload.page]: payload.data,
    })
    return {
      ...state,
      tripTimelines: newData,
    }
  },
  [types.SET_PAGE]: (state, { payload }) => ({ ...state, currentPage: payload }),
  [types.RESET_SHIPMENTS]: state => ({
    ...initialState,
    refTypeCounts: state.refTypeCounts,
    loading: true,
  }),
  [types.RESET_DATA]: state => ({ ...state, data: {} }),
  [types.GET_SHIPMENTS_COUNT_SUCCESS]: (state, { payload }) => {
    const totalCount = get(payload, 'count', 0)
    const totalPages = Math.ceil(totalCount / MAX_NUM_SHIPMENT_ROWS)
    return { ...state, totalPages, totalCount }
  },
  [types.SELECT_SHIPMENTS]: (state, { payload }) => {
    const ids = map(payload, 'ref_id')
    if (state.selectIsExhaustive) {
      return {
        ...state,
        deselected: state.deselected.filter(id => {
          return !ids.includes(id)
        }),
        selectedCount: state.selectedCount + 1,
      }
    }

    return {
      ...state,
      selected: union(state.selected, ids),
      selectIsExhaustive: false,
    }
  },
  [types.DESELECT_SHIPMENTS]: (state, { payload }) => {
    const ids = map(payload, 'ref_id')
    if (state.selectIsExhaustive) {
      return {
        ...state,
        deselected: union(state.deselected, ids),
        selectedCount: state.totalCount - (state.deselected.length + ids.length),
      }
    }

    return {
      ...state,
      deselected: [],
      selected: reject(state.selected, id => {
        return ids.includes(id)
      }),
      selectIsExhaustive: false,
    }
  },
  [types.DESELECT_ALL_SHIPMENTS]: state => ({
    ...state,
    selected: [],
    selectedCount: 0,
    deselected: [],
    selectIsExhaustive: false,
  }),
  [types.SELECT_ALL_SHIPMENTS]: state => ({
    ...state,
    selected: [],
    selectedCount: state.totalCount,
    deselected: [],
    selectIsExhaustive: true,
  }),
  [types.GET_REF_TYPE_COUNTS_LOAD]: state => ({ ...state, refTypeCounts: {} }),
  [types.GET_REF_TYPE_COUNTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    refTypeCounts: payload,
  }),
}

/**
 * - `totalCount` is just that, the total number returned from the API
 *
 * When `selectIsExhaustive` is `true`:
 * - `deselected` holds the `id`s of any unchecked rows
 * - `selectedCount` is `totalCount - deselected.length`
 *
 * When `selectIsExhaustive` is `false`:
 * - `selected` holds `id`s of any row(s) the user selects
 */
export const initialState = {
  currentPage: 0,
  data: {},
  deselected: [],
  loading: false,
  selected: [],
  selectedCount: 0,
  selectIsExhaustive: false,
  refTypeCounts: {},
  totalPages: 0,
  totalCount: 0,
  tripTimelines: {},
}

export function boardReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

export default boardReducer
