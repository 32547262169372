import React from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  noMatch: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(12),
  },
  noActiveShipment: {
    color: 'rgb(153, 153, 153)',
    fontSize: 25,
  },
  orangeText: {
    color: theme.brand.color,
  },
})

const NoShipmentInfo = ({ classes }) => (
  <div className={classes.noMatch}>
    <p className={classes.noActiveShipment}>NO ACTIVE OR RECENT SHIPMENT FOUND</p>
    <p>
      If you believe this is an error please contact{' '}
      <span className={classes.orangeText}>support@clearmetal.com</span>
    </p>
  </div>
)

export default withStyles(styles)(NoShipmentInfo)
