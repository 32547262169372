import humps from 'humps'

export interface TripInterface {
  externalId: string
  id: number
  number: string
  type: string
}

export default class Trip {
  constructor({ externalId, id, number, type }: TripInterface) {
    Object.assign(this, { externalId, id, number, type })
  }

  static of(payload: object) {
    const { externalId, id, number, type } = humps.camelizeKeys(payload) as TripInterface
    return new Trip({ externalId, id, number, type })
  }
}
