import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { IconButton as MuiIconButton, Tooltip, Typography, makeStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import ClearIcon from '@material-ui/icons/Cancel'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import MESSAGES from 'store/notifications/messages'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import copy from 'copy-to-clipboard'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  rootOverride: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    width: '100%',
    // @ThemeOverride prevent clash with global MUI theme
    '&:hover': {
      backgroundColor: theme.palette.grey.A100,
    },
  },
  labelOverride: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
    maxWidth: 175,
  },
  content: {
    width: '100%',
  },
  noClick: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}))

const IconButton = React.forwardRef(({ children, ...rest }, ref) => (
  <MuiIconButton size="small" component="span" tabIndex={0} ref={ref} {...rest}>
    {children}
  </MuiIconButton>
))

const FilterItem = ({ allowEdit, title, content, enableCopy, onRequestEdit, handleDelete }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onDelete = e => {
    e.stopPropagation()
    handleDelete()
  }

  const handleCopy = e => {
    e.stopPropagation()
    const res = copy(content)
    res
      ? dispatch(showNotification(MESSAGES.filterCopySuccess))
      : dispatch(showNotification(MESSAGES.filterCopyError, { type: MESSAGE_TYPES.ERROR }))
  }

  const rootClasses = classnames(classes.rootOverride, { [classes.noClick]: !allowEdit })

  return (
    <Button
      variant="contained"
      onClick={() => {
        if (allowEdit) {
          onRequestEdit()
        }
      }}
      disableRipple
      classes={{ root: rootClasses, label: classes.labelOverride }}
    >
      <div className={classes.headerWrapper}>
        <Typography
          align="left"
          variant="subtitle2"
          color="textSecondary"
          className={classes.title}
        >
          {title}
        </Typography>
        {enableCopy && (
          <Tooltip title="Copy">
            <IconButton size="small" onClick={handleCopy}>
              <FileCopyOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete filter">
          <IconButton onClick={onDelete} aria-label="Delete">
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </div>
      <Typography align="left" variant="body2" color="textSecondary" className={classes.content}>
        {content}
      </Typography>
    </Button>
  )
}

FilterItem.propTypes = {
  allowEdit: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  enableCopy: PropTypes.bool,
  onRequestEdit: PropTypes.func,
}

FilterItem.defaultProps = {
  title: '',
  content: '',
  onRequestEdit: () => {},
}

export default FilterItem
