import {
  CircularProgress,
  List,
  ListItem,
  Popover,
  Typography,
  withStyles,
} from '@material-ui/core'

import Button from 'components/core/Button'
import DownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'
import React from 'react'
import colors from 'components/core/Colors'
import get from 'lodash/get'

const MIN_WIDTH = 130
const styles = theme => ({
  root: {},
  container: {
    borderRadius: 3,
    border: `1px solid ${colors.grey[500]}`,
    display: 'flex',
    alignItems: 'center',
    height: 36,
    backgroundColor: colors.grey[50],
  },
  selectedLabel: {
    fontSize: 13,
    textTransform: 'none',
  },
  button: {
    minHeight: 'initial',
    height: 34,
    minWidth: MIN_WIDTH,
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -3,
  },
  listItem: {
    minWidth: MIN_WIDTH,
  },
  title: {
    marginBottom: 2,
    letterSpacing: 0.1,
    fontSize: 14,
  },
  progress: {
    color: 'black',
    height: '20px !important',
    width: '20px !important',
  },
})

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIdx: props.defaultIdx,
    }
  }

  static propTypes = {
    classes: PropTypes.object,
    items: PropTypes.array.isRequired,
    title: PropTypes.string,
    handleSelection: PropTypes.func.isRequired,
    defaultSelected: PropTypes.string,
    defaultIdx: PropTypes.number,
    labelKey: PropTypes.string,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    title: null,
    defaultSelected: null,
    labelKey: 'name',
    loading: false,
    defaultIdx: -1,
  }

  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleItemClick = (item, idx) => () => {
    this.setState({
      selectedIdx: idx,
    })
    this.handleClose()
    this.props.handleSelection(item, idx)
  }

  renderTitle = () => {
    const { classes, items, loading } = this.props

    if (items.length === 0 && loading) {
      return <CircularProgress className={classes.progress} />
    }
    const selected = this.getValue(items, this.state.selectedIdx)
    return (
      <Typography variant="body2" className={classes.selectedLabel}>
        {selected}
      </Typography>
    )
  }

  getValue = (items, idx) => {
    const { labelKey } = this.props
    const selected = get(items, `[${idx}].${labelKey}`, this.props.defaultSelected)
    return selected || 'Not selected'
  }

  render() {
    const { classes, items, title } = this.props
    const { anchorEl } = this.state

    return (
      <div className={classes.root}>
        {title && (
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
        )}
        <div className={classes.container}>
          <Button className={classes.button} onClick={this.handleClick}>
            <div className={classes.labelWrapper}>
              {this.renderTitle()}
              <DownIcon />
            </div>
          </Button>
        </div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={this.handleClose}
        >
          <List>
            {items.map((item, idx) => {
              return (
                <ListItem
                  button
                  dense
                  onClick={this.handleItemClick(item, idx)}
                  className={classes.listItem}
                  key={`sort-item-${idx}`}
                >
                  <Typography variant="body2">{this.getValue(items, idx)}</Typography>
                </ListItem>
              )
            })}
          </List>
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles)(Dropdown)
