import { MilestoneInterface } from 'store/models/Milestone'
import { getDateDiff } from 'utils/dateUtils'

/**
 * Gets the traced (actual) delay by diffing `tracedTime` and `baselinePlannedTime` of `lastExpectedUnloadMilestone` or `dischargeLocationMilestone`
 * @param milestone Milestone
 * @returns number | null
 */
const getTracedTimeDelay = (milestone: MilestoneInterface | null): number | null => {
  const dischargeAtDestTraced = milestone?.tracedTime ?? ''
  const dischargeAtDestBaseline = milestone?.baselinePlannedTime ?? ''

  return getDateDiff(dischargeAtDestTraced, dischargeAtDestBaseline, 'days')
}

export default getTracedTimeDelay
