import {
  ALERTS_REDUCER_KEY,
  alertConfigsReducer,
  watchGetAlertsConfigs,
} from 'store/api/alertConfigs'
import { API_KEYS_REDUCER_KEY, apiKeyReducer, watchApiKeysLoad } from 'store/api/manageApiKeys'
/**
 * If you create a new saga generator, it must be included below
 */
import {
  GET_VESSEL_PATH_REDUCER_KEY,
  getVesselPathReducer,
  watchGetVesselPath,
} from 'store/api/vesselPath'
import {
  TRIGGER_ALERT_REDUCER_KEY,
  triggerAlertReducer,
  watchTriggerAlertsLoad,
} from 'store/api/alertTrigger'
import { watchAcceptedTerms, watchFetchProfile, watchUpdateProfile } from 'store/profile/sagas'
import {
  watchBoardGetInitialRefType,
  watchBoardGetShipments,
  watchBoardSetPage,
  watchToRefetchEquipment,
} from 'store/board/sagas'
import {
  watchClearView,
  watchCreateView,
  watchDeleteView,
  watchFetchViews,
  watchImportReferences,
  watchInitialSetRollup,
  watchSelectView,
  watchSetFilteredList,
  watchSetRollup,
  watchShipmentReloadData,
  watchUpdateView,
} from 'store/boardUtils/sagas'
import { watchFetchCustomization, watchUpdateCustomization } from 'store/customization'

import { BOARD_REDUCER_KEY } from 'store/board/actions'
import { BOARD_UTILS_REDUCER_KEY } from 'store/boardUtils/actions'
import { CUSTOM_FIELDS_REDUCER_KEY } from 'store/customFields/actions'
import { OAUTH_STATUS_REDUCER_KEY } from 'store/oauthStatus/actions'
import { PROFILE_REDUCER_KEY } from 'store/profile/actions'
import { PUBLIC_FEATURE_FLAGS_REDUCER_KEY } from 'store/publicFeatureFlags/actions'
import { boardReducer } from 'store/board'
import boardUtilsReducer from 'store/boardUtils'
import customFields from 'store/customFields'
import oauthStatus from 'store/oauthStatus'
import profile from 'store/profile'
import publicFeatureFlags from 'store/publicFeatureFlags'
import { watchFetchCustomFields } from 'store/customFields/sagas'
import { watchFetchJourneyDetails } from 'store/journeyDetails/sagas'
import { watchFetchTransportDetail } from 'store/transportDetail/sagas'
import { watchFetchTripGroups } from 'store/tripGroups/sagas'
import { watchLoadOauthStatus } from 'store/oauthStatus/sagas'
import { watchLoadPublicFeatureFlags } from 'store/publicFeatureFlags/sagas'
import { watchLoadRequestInvitation } from 'store/requestInvitation/sagas'
import { watchUploadOrders } from 'store/orderUpload'

export const apiSagas = [
  ...boardUtilsReducer.sagas,
  watchGetVesselPath(),
  watchGetAlertsConfigs(),
  watchUpdateProfile(),
  watchFetchProfile(),
  watchAcceptedTerms(),
  watchUploadOrders(),
  watchFetchCustomization(),
  watchUpdateCustomization(),
  watchTriggerAlertsLoad(),
  watchBoardGetInitialRefType(),
  watchBoardGetShipments(),
  watchToRefetchEquipment(),
  watchBoardSetPage(),
  watchClearView(),
  watchCreateView(),
  watchDeleteView(),
  watchFetchJourneyDetails(),
  watchFetchViews(),
  watchImportReferences(),
  watchSelectView(),
  watchSetFilteredList(),
  watchInitialSetRollup(),
  watchSetRollup(),
  watchFetchTransportDetail(),
  watchShipmentReloadData(),
  watchUpdateView(),
  watchLoadPublicFeatureFlags(),
  watchLoadOauthStatus(),
  watchFetchTripGroups(),
  watchLoadRequestInvitation(),
  watchFetchCustomFields(),
  watchApiKeysLoad(),
]

/**
 * If you create a new reducer, it must be included below
 */
export const apiReducers = {}
apiReducers[CUSTOM_FIELDS_REDUCER_KEY] = customFields
apiReducers[GET_VESSEL_PATH_REDUCER_KEY] = getVesselPathReducer
apiReducers[ALERTS_REDUCER_KEY] = alertConfigsReducer
apiReducers[BOARD_REDUCER_KEY] = boardReducer
apiReducers[BOARD_UTILS_REDUCER_KEY] = boardUtilsReducer
apiReducers[TRIGGER_ALERT_REDUCER_KEY] = triggerAlertReducer
apiReducers[PROFILE_REDUCER_KEY] = profile
apiReducers[PUBLIC_FEATURE_FLAGS_REDUCER_KEY] = publicFeatureFlags
apiReducers[OAUTH_STATUS_REDUCER_KEY] = oauthStatus
apiReducers[API_KEYS_REDUCER_KEY] = apiKeyReducer
