import { default as MUITableCell, TableCellProps } from '@material-ui/core/TableCell'
import { Theme, makeStyles } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
  },
}))

const TableCell = (props: TableCellProps) => {
  const classes = useStyles(props)

  return <MUITableCell classes={classes} {...props} />
}

export default TableCell
