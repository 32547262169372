import logger from 'utils/logger'
import { routeGraphMode } from 'store/planning/utils/RouteComparison'
import startCase from 'lodash/startCase'

const planningNotify = msg => logger.notify(`Planning ${msg}`)
const dashboardNotify = msg => logger.notify(`Dashboard ${msg}`)

const makeFilterGroupTrackers = notifier => ({
  userSelectsFilter: (filter, isMultiple = false) =>
    notifier(`${isMultiple ? 'Multiple ' : ''}"${filter}" Set`),
  userClearsFilterGroup: () => notifier('Clear Filter'),
  userCreatesFilterGroup: () => notifier('Save Filter Confirm'),
  userUpdatesFilterGroup: () => notifier('Save Filter Update'),
  userSelectsFilterGroup: () => notifier('Saved Filter Select'),
})

const tracker = {
  planning: {
    userPaginatesTable: () => planningNotify('Table Paginate'),
    userSelectsTableRow: (checked, rowType) =>
      planningNotify(`${checked ? 'Select' : 'Unselect'} ${startCase(rowType)}`),
    userExpandsTableRow: expanded => planningNotify(`${expanded ? 'Expand' : 'Collapse'} Group`),
    userSwitchesChartMode: chartMode => planningNotify(`Select ${startCase(chartMode)}`),
    userHoversOverComparisonScorecard: () =>
      planningNotify(`${startCase(routeGraphMode.COMPARISON)} Scorecard Hover`),
    userHoversGraphPoint: chartMode => planningNotify(`${startCase(chartMode)} Hover`),
    ...makeFilterGroupTrackers(planningNotify),
  },
  dashboard: {
    ...makeFilterGroupTrackers(dashboardNotify),
    userHoversChart: chartName => dashboardNotify(`${chartName} Hover`),
    userSwitchesChartMode: chartName => dashboardNotify(`${chartName} Select`),
    userDownloadsChart: chartName => dashboardNotify(`${chartName} Download`),
  },
}

export default tracker
