import { SvgIcon, SvgIconProps, Theme, makeStyles } from '@material-ui/core'

import React from 'react'

// Default color, most likely to be used this way most or all the time, easy to override.
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.green[425],
  },
}))

const ProgressCompletedIcon = (props: SvgIconProps) => {
  const classes = useStyles(props)
  return (
    <SvgIcon classes={{ root: classes.root }} {...props}>
      <g fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z M15.88,8.29 L10,14.17 L8.12,12.29 C7.73,11.9 7.1,11.9 6.71,12.29 C6.32,12.68 6.32,13.31 6.71,13.7 L9.3,16.29 C9.69,16.68 10.32,16.68 10.71,16.29 L17.3,9.7 C17.69,9.31 17.69,8.68 17.3,8.29 C16.91,7.9 16.27,7.9 15.88,8.29 Z"
        />
      </g>
    </SvgIcon>
  )
}

export default ProgressCompletedIcon
