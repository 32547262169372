import { Box, Theme, Typography, makeStyles } from '@material-ui/core'

import React from 'react'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import logger from 'utils/logger'

const useStyles = makeStyles((theme: Theme) => ({
  feedbackPromptText: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'nowrap',
  },
  buttonGroup: {
    marginLeft: theme.spacing(4),
  },
  reactionButton: {
    background: theme.palette.white,
    borderColor: `${theme.palette.grey[300]} !important`, // MUI sets this to transparent otherwise

    '&.Mui-selected': {
      background: theme.palette.grey[100],
    },
  },
  icon: {
    color: theme.palette.grey[500],
    '.Mui-selected &': {
      color: theme.palette.grey[800],
    },
  },
  reactionPrompt: {},
}))

type Props = {
  classes?: { [key: string]: string }
  componentUnderFeedback: string
  entityId?: string
  feedbackPromptText?: React.ReactNode
  loggerLabel?: string
  refType?: string
}

/**
 * Isolates the "Some text here? thumbs up | thumbs down" part of the `ProductFeedback` component so it can standalone
 */
const Reaction = (props: Props) => {
  const {
    componentUnderFeedback,
    entityId,
    feedbackPromptText,
    loggerLabel = 'Entity Page Reaction',
    refType,
  } = props
  const classes = useStyles(props)
  const [reaction, setReaction] = React.useState<string | null>(null)

  const handleReaction = (e: React.MouseEvent<HTMLElement>, newReaction: string | null) => {
    const eventMsg = newReaction ?? `undo ${reaction}`
    setReaction(newReaction)
    logger.notify(loggerLabel, {
      reaction: eventMsg,
      entityId: entityId,
      component: componentUnderFeedback,
      ...(refType && { refType }),
    })
  }

  return (
    <>
      {feedbackPromptText && (
        <Box pt={1} className={classes.reactionPrompt}>
          <Typography variant="subtitle2" className={classes.feedbackPromptText}>
            {feedbackPromptText}
          </Typography>
        </Box>
      )}
      <ToggleButtonGroup
        value={reaction}
        exclusive
        onChange={handleReaction}
        aria-label="reaction"
        size="small"
        className={classes.buttonGroup}
        data-testid="product-feedback__reaction"
      >
        <ToggleButton value="thumbs up" aria-label="thumb up" className={classes.reactionButton}>
          <ThumbUpOutlinedIcon fontSize="small" className={classes.icon} />
        </ToggleButton>
        <ToggleButton
          value="thumbs down"
          aria-label="thumb downb"
          className={classes.reactionButton}
        >
          <ThumbDownOutlinedIcon fontSize="small" className={classes.icon} />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

export default Reaction
