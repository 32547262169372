import React, { useEffect, useState } from 'react'
import { loadingSelector, rowsSelector } from 'store/downloads'
import { useDispatch, useSelector } from 'react-redux'

import CenteredLayout from 'components/layouts/CenteredLayout'
import DownloadTable from './DownloadTable'
import Loader from 'components/Loader'
import PropTypes from 'prop-types'
import { clearNonPersistentNotification } from 'store/notifications'
import { fetchDownloads } from 'store/downloads/actions'
import { fromQueryString } from 'utils/urlBuilder'
import logger from 'utils/logger'
import orderBy from 'lodash/orderBy'

function DownloadPage({ location }) {
  const columns = [
    {
      id: 'name',
      label: 'File Name',
      sortable: false,
    },
    {
      id: 'entity',
      label: 'Type',
      sortable: true,
    },
    {
      id: 'count',
      label: 'Items Downloaded',
      sortable: false,
    },
    {
      id: 'createdAt',
      label: 'Date Created',
      sortable: true,
    },
    {
      id: 'downloadedOn',
      label: 'Last Download On',
      sortable: true,
    },
    {
      id: 'status',
      label: 'Status',
      sortable: false,
    },
  ]

  const dispatch = useDispatch()

  const isLoading = useSelector(loadingSelector)
  const rows = useSelector(rowsSelector)
  const [sortState, setSortState] = useState({
    // Track sort state for all columns as all sort arrows are shown
    directions: columns.map(col => ({ id: col.id, direction: 'desc' })),
    // Column sorted on
    order: 'createdAt',
  })
  const { source } = fromQueryString(location.search)

  function fetchRows() {
    dispatch(fetchDownloads())
  }

  useEffect(() => {
    fetchRows()
    // Clears toast on page load to close the toast they clicked on shipment board
    dispatch(clearNonPersistentNotification())
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (source) {
      logger.notify('Downloads Page Email Open', { source })
    }
  }, [source])

  const handleSortRequest = columnId => {
    logger.notify('Download Manager Sort', { columnId })
    setSortState({
      ...sortState,
      order: columnId,
      directions: sortState.directions.map(dir =>
        dir.id === columnId ? { ...dir, direction: dir.direction === 'desc' ? 'asc' : 'desc' } : dir
      ),
    })
  }

  const sortedRows = () => {
    return orderBy(
      rows,
      [sortState.order],
      [sortState.directions.find(dir => dir.id === sortState.order).direction]
    )
  }

  if (isLoading) {
    return (
      <CenteredLayout>
        <Loader />
      </CenteredLayout>
    )
  }

  return (
    <DownloadTable
      rows={sortedRows()}
      columns={columns}
      onSortRequest={handleSortRequest}
      sortOrder={sortState.order}
      sortDirections={sortState.directions}
      onRefresh={() => fetchRows()}
    />
  )
}

DownloadPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

export default DownloadPage
