import { BoardColumnInterface, BoardRowInterface } from 'store/board/interfaces'
import CommentsLinkButton, { LOGGER_MESSAGE_TABLE } from 'components/CommentsLinkButton'
import { getRefTypeLabel, getShipmentDetailPath } from 'utils/rollups'

import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import InternalUserComponent from 'components/InternalUserComponent'
import Link from 'components/core/Link'
import NotProvided from 'components/NotProvided'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  link: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

interface Props {
  className?: string
  column: BoardColumnInterface
  onContextMenu: () => void
  rollup: string
  row: BoardRowInterface
}

const IDCell = (props: Props) => {
  const { className, column, onContextMenu, row, rollup } = props
  const classes = useStyles()
  const isExternalUser = useSelector(isExternalUserSelector)
  const rowId = row?.id
  const externalId = row?.externalId
  // the `externalId` is optional for all but Container routes but no harm in including for all
  const link = getShipmentDetailPath(rollup, rowId, externalId)
  const label = getRefTypeLabel(rollup)
  let value = column.getCellValue(row)
  value = value ?? <NotProvided text="Not Provided" />

  return (
    <BaseCell
      className={className}
      column={column}
      onContextMenu={onContextMenu}
      rollup={rollup}
      row={row}
    >
      <div style={{ display: 'flex' }}>
        <Typography variant="body2">
          <Link
            className={classes.link}
            to={link}
            data-testid="board__entity-icon-link"
            onClick={() => {
              const msg = `${isExternalUser ? 'Portal ' : ''}Entity Link Click | Table View`
              logger.notify(msg, { entityId: rowId, entityType: label })
            }}
          >
            {value}
          </Link>
        </Typography>
        <InternalUserComponent>
          <CommentsLinkButton
            iconButtonProps={{ size: 'small', style: { paddingTop: 2 } }}
            link={link}
            externalId={row.externalId}
            loggerMessage={LOGGER_MESSAGE_TABLE}
          />
        </InternalUserComponent>
      </div>
    </BaseCell>
  )
}

export default React.memo(IDCell)
