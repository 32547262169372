/**
 * While we redo the board API endpoint we are getting legacy data (`modelsLegacy.js`) and new data (returned
 * in the legacy response under the `new_fields` key). This model will handle the `new_fields` for now until we
 * arrive at the final new API shape. We are doing the board updates very iteratively so we don't know what
 * the final shape will be. Doing this here will allow us to apply existing models and types to the new data.
 */
import { DATE_TIME_FORMAT, formatDateString } from 'utils/dateUtils'
import {
  DELIVERED,
  DISCHARGED,
  IN_TRANSIT,
  PENDING,
  UNKNOWN,
} from 'store/models/definitions/StatusLabel'
import Milestone, { MilestoneInterface } from 'store/models/Milestone'
import {
  TransportDetailHeaderInterface,
  TransportDetailInterface,
  TransportDetailTimelinesInterface,
} from 'store/models/TransportDetail/interfaces'
import TransportStatus, { TransportStatusType } from 'store/models/definitions/TransportationStatus'
import Trip, { TripInterface } from 'store/models/Trip'

import { StatusLabelType } from 'store/models/definitions/StatusLabel'
import { TransportDetail } from 'store/models/TransportDetail'
import humps from 'humps'

export interface NewFieldsInterface {
  actualDischarge: {
    time: string | null | undefined
  } | null
  lastExpectedMainUnloadMilestone: MilestoneInterface | null
  latestActualMainLoadMilestone: MilestoneInterface | null
  latestActualMilestone: MilestoneInterface | null
  predictedDischarge: {
    time: string | null | undefined
  } | null
  status: TransportStatusType
  statusLabel: StatusLabelType
  statusTrips: TripInterface[]
  transportDetail: TransportDetailInterface
  trips: TripInterface[]
}

export interface Props {
  header: TransportDetailHeaderInterface
  latestActualMainLoadMilestone: MilestoneInterface | null
  latestActualMilestone: MilestoneInterface | null
  lastExpectedMainUnloadMilestone: MilestoneInterface | null
  self: TripInterface
  status: keyof typeof TransportStatus
  statusTrips: TripInterface[]
  timelines: TransportDetailTimelinesInterface[]
  trips: TripInterface[]
}

export default class NewFields {
  constructor({
    actualDischarge,
    lastExpectedMainUnloadMilestone,
    latestActualMainLoadMilestone,
    latestActualMilestone,
    predictedDischarge,
    status,
    statusLabel,
    statusTrips,
    transportDetail,
    trips,
  }: NewFieldsInterface) {
    Object.assign(this, {
      actualDischarge,
      latestActualMainLoadMilestone,
      lastExpectedMainUnloadMilestone,
      latestActualMilestone,
      predictedDischarge,
      status,
      statusLabel,
      statusTrips,
      transportDetail,
      trips,
    })
  }

  /**
   * Converts API data to camelCase, applies models, adds properties and returns a new model object
   * @param payload object, snake-cased API data. We only do this on the top model (entry point) because subsequent
   * models will get camelCased data and can use our defined interfaces for `payload`
   */
  static of(payload: object) {
    const {
      header,
      latestActualMainLoadMilestone,
      latestActualMilestone,
      lastExpectedMainUnloadMilestone,
      self,
      status,
      statusTrips,
      timelines,
      trips,
    } = humps.camelizeKeys(payload) as Props

    const dischargeAtDestTraced = lastExpectedMainUnloadMilestone?.tracedTime
    const dischargeAtDestPrediction = lastExpectedMainUnloadMilestone?.predictedTime ?? null

    let statusLabel: StatusLabelType
    switch (TransportStatus[status]) {
      case TransportStatus.completed:
      case TransportStatus.DISCHARGED:
        statusLabel = DISCHARGED
        break
      case TransportStatus.delivered:
      case TransportStatus.DELIVERED:
        statusLabel = DELIVERED
        break
      case TransportStatus.in_progress:
      case TransportStatus.in_transit:
      case TransportStatus.STARTED:
        statusLabel = IN_TRANSIT
        break
      case TransportStatus.UNFINISHED_BUT_INACTIVE:
        statusLabel = UNKNOWN
        break
      case TransportStatus.no_data:
      case TransportStatus.not_started:
      case TransportStatus.NOT_STARTED:
        statusLabel = PENDING
        break
      case TransportStatus.out_for_delivery:
        statusLabel = UNKNOWN
        break
    }

    return new NewFields({
      actualDischarge: {
        time: dischargeAtDestTraced
          ? formatDateString(dischargeAtDestTraced, DATE_TIME_FORMAT)
          : null,
      },
      lastExpectedMainUnloadMilestone:
        lastExpectedMainUnloadMilestone &&
        (Milestone.of(lastExpectedMainUnloadMilestone) as MilestoneInterface),
      latestActualMainLoadMilestone:
        latestActualMainLoadMilestone &&
        (Milestone.of(latestActualMainLoadMilestone) as MilestoneInterface),
      latestActualMilestone:
        latestActualMilestone && (Milestone.of(latestActualMilestone) as MilestoneInterface),
      predictedDischarge: {
        time: dischargeAtDestPrediction
          ? formatDateString(dischargeAtDestPrediction, DATE_TIME_FORMAT)
          : null,
      },
      status: status && TransportStatus[status],
      statusLabel,
      statusTrips:
        statusTrips && (statusTrips.map((trip: TripInterface) => Trip.of(trip)) as TripInterface[]),
      transportDetail: TransportDetail.of({ self, header, timelines }) as TransportDetailInterface,
      trips: trips && (trips.map((trip: TripInterface) => Trip.of(trip)) as TripInterface[]),
    })
  }
}
