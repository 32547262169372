/**
 * The following are hard coded names of the Portal Alert names
 * - If these names change, ensure the DB is updated accordingly
 * - These names will appear in emails sent to the User
 * - Review Alert config endpoints for more information
 */
export const portalAlertNames = {
  VESSEL_HAS_DEPARTED_ORIGIN: 'Vessel has departed Origin',
  OCEAN_DELAY: 'Ocean Delay',
  STUCK_IN_TRANSSHIPMENT: 'Stuck in Transshipment',
  VESSEL_WILL_ARRIVE_AT_DESTINATION: 'Vessel will Arrive at Destination',
  VESSEL_ARRIVED_AT_DESTINATION: 'Vessel Arrived at Destination',
  DETENTION_TO_ORIGIN_PORT: 'Detention to Origin Port',
  DETENTION_FROM_DESTINATION_PORT: 'Detention from Destination Port',
  DEMURRAGE_AT_ORIGIN_PORT: 'Demurrage at Origin Port',
  DEMURRAGE_AT_DESTINATION_PORT: 'Demurrage at Destination Port',
} as const

export const PORTAL_VIEW_NAMES = Object.values(portalAlertNames)

const TIMING_VALUES = {
  BETWEEN: 'between',
  MORE: 'more',
  LESS: 'less',
} as const

type TimingValueType = typeof TIMING_VALUES[keyof typeof TIMING_VALUES]

export const apiConstants = {
  VESSEL_DEPART_ORIGIN: 'vessel_depart_origin',
  VESSEL_ARRIVE_TRANSSHIPMENT: 'vessel_arrive_transshipment',
  VESSEL_DISCHARGE_TRANSSHIPMENT: 'vessel_discharge_transshipment',
  LATEST_MILESTONE_TIME: 'latest_milestone_time',
  OCEAN_DELAY: 'ocean_delay',
  VESSEL_ARRIVE_DESTINATION: 'vessel_arrive_destination',
  PRED_DISCHARGE_TIME: 'pred_discharge_time',
  COMPLETED_MILESTONES: 'completed_milestones',
  MIN_ORIGIN_DETENTION_DAYS: 'min_origin_detention_days',
  MAX_ORIGIN_DETENTION_DAYS: 'max_origin_detention_days',
  MIN_DESTINATION_DETENTION_DAYS: 'min_destination_detention_days',
  MAX_DESTINATION_DETENTION_DAYS: 'max_destination_detention_days',
  MIN_ORIGIN_DEMURRAGE_DAYS: 'min_origin_demurrage_days',
  MAX_ORIGIN_DEMURRAGE_DAYS: 'max_origin_demurrage_days',
  MIN_DESTINATION_DEMURRAGE_DAYS: 'min_destination_demurrage_days',
  MAX_DESTINATION_DEMURRAGE_DAYS: 'max_destination_demurrage_days',
} as const

interface Inputs {
  input: string
  maxDaysValue: string
  minDaysValue: string
  timingValue: TimingValueType
}

interface Props {
  entityType: string
  alertConfigId: number | null
  input: string
  inputs: Inputs
}

/**
 * A helper function to get min/max days for detention and demurrage, based on "More than", "Less than", "Between"
 * @param inputs object
 * @returns object
 */
const getDayRanges = (inputs: Inputs): { minDays: number | null; maxDays: number | null } => {
  const timingValue = inputs.timingValue
  const { minDaysValue, maxDaysValue } = inputs
  let minDays = null
  let maxDays = null

  if (timingValue === TIMING_VALUES.BETWEEN) {
    minDays = Math.abs(parseInt(minDaysValue))
    maxDays = Math.abs(parseInt(maxDaysValue))
  } else {
    // `minDays` is the first field displayed, so for both `more than` and `less than` we only populate that field
    minDays = Math.abs(parseInt(minDaysValue))
  }

  return { minDays, maxDays }
}

/**
 * All of the following functions are called by `getPayloads`, which in turn is called by the `portalAlerts/sagas`
 * `submitForm` saga.
 * @param props
 * @returns object
 * ```
 * {
 *  name: ,
 *  alertConfigId: number, only populated when editing existing alerts, otherwise `null`
 *  payload: { ref_type: the selected entity type for the alerts }
 *  fields: array of `field` objects, one for each field that needs to be in the request.
 *    {
 *      key: string, maps to the snake-cased value used by the request/response
 *      urlParams: object, data used by the backend to construct alerts
 *      value: array of `value` objects
 *    }
 * }
 *
 * @todo rewrite. The _only_ property actually used by the backend is `urlParams`. Everything else is just echoed
 * back to the FE. It seems we should be able to do this in a cleaner and more obvious way, maybe even as simply
 * as passing and receiving only `urlParams` and
 */

export function getVesselHasDepartedOriginPayload(props: Props) {
  const { entityType, alertConfigId = null } = props
  return {
    name: portalAlertNames.VESSEL_HAS_DEPARTED_ORIGIN,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.COMPLETED_MILESTONES,
        urlParams: { tracedMilestones: apiConstants.VESSEL_DEPART_ORIGIN },
        // The `value` property is used for setting the form values when we get data back from the API. As such they
        // are not standardized because they need to represent data specific to the alert field. This is messy and
        // error prone, as mentioned above, could we get only the `urlParams` back and infer the values?
        value: [
          {
            label: 'Vessel depart origin port',
            type: 'Port of loading',
            value: apiConstants.VESSEL_DEPART_ORIGIN,
          },
        ],
      },
    ],
  }
}

export function getStuckInTransshipmentPayload(props: Props) {
  const { entityType, inputs, alertConfigId = null } = props
  const endDate = Math.abs(parseInt(inputs.input)) * -1
  return {
    name: portalAlertNames.STUCK_IN_TRANSSHIPMENT,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.COMPLETED_MILESTONES,
        urlParams: {
          tracedMilestones: `${apiConstants.VESSEL_ARRIVE_TRANSSHIPMENT}||${apiConstants.VESSEL_DISCHARGE_TRANSSHIPMENT}`,
        },
        value: [
          {
            label: 'Vessel arrive at transshipment port',
            type: 'Transshipment',
            value: apiConstants.VESSEL_ARRIVE_TRANSSHIPMENT,
          },
          {
            label: 'Container discharge for transshipment',
            type: 'Transshipment',
            value: apiConstants.VESSEL_DISCHARGE_TRANSSHIPMENT,
          },
        ],
      },
      {
        key: apiConstants.LATEST_MILESTONE_TIME,
        urlParams: { maxLatestMilestoneTime: endDate },
        value: {
          startDate: null,
          relativeType: 'past',
          endDate,
          type: 'relative',
        },
      },
    ],
  }
}

export function getOceanDelayPayload(props: Props) {
  const { entityType, inputs, alertConfigId = null } = props
  const upperBound = Math.abs(parseInt(inputs.input))
  return {
    name: portalAlertNames.OCEAN_DELAY,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.OCEAN_DELAY,
        urlParams: {
          min_ocean_delay: upperBound,
        },
        value: {
          lowerBound: upperBound,
          upperBound: null,
        },
      },
    ],
  }
}

export function getVesselWillArriveAtDestinationPayload(props: Props) {
  const { entityType, inputs, alertConfigId = null } = props
  const inputNumber = Math.abs(parseInt(inputs.input))
  return {
    name: portalAlertNames.VESSEL_WILL_ARRIVE_AT_DESTINATION,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.PRED_DISCHARGE_TIME,
        urlParams: { maxPredDischarge: inputNumber },
        value: { endDate: inputNumber, startDate: null, type: 'relative' },
      },
    ],
  }
}

export function getVesselArrivedAtDestinationPayload(props: Props) {
  const { entityType, alertConfigId = null } = props
  return {
    name: portalAlertNames.VESSEL_ARRIVED_AT_DESTINATION,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.COMPLETED_MILESTONES,
        urlParams: { tracedMilestones: apiConstants.VESSEL_ARRIVE_DESTINATION },
        value: [
          {
            label: 'Vessel arrive at dest. port',
            type: 'Port of discharge',
            value: apiConstants.VESSEL_ARRIVE_DESTINATION,
          },
        ],
      },
    ],
  }
}

export function getDetentionOriginPortPayload(props: Props) {
  const { entityType, inputs, alertConfigId = null } = props
  const { minDays = null, maxDays = null } = getDayRanges(inputs)
  const { timingValue } = inputs
  return {
    name: portalAlertNames.DETENTION_TO_ORIGIN_PORT,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.MIN_ORIGIN_DETENTION_DAYS,
        urlParams: { [apiConstants.MIN_ORIGIN_DETENTION_DAYS]: minDays },
        value: { [apiConstants.MIN_ORIGIN_DETENTION_DAYS]: minDays, timingValue },
      },
      {
        key: apiConstants.MAX_ORIGIN_DETENTION_DAYS,
        urlParams: { [apiConstants.MAX_ORIGIN_DETENTION_DAYS]: maxDays },
        value: { [apiConstants.MAX_ORIGIN_DETENTION_DAYS]: maxDays },
      },
    ],
  }
}

export function getDetentionDestinationPortPayload(props: Props) {
  const { entityType, inputs, alertConfigId = null } = props
  const { minDays, maxDays } = getDayRanges(inputs)
  const { timingValue } = inputs
  return {
    name: portalAlertNames.DETENTION_FROM_DESTINATION_PORT,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.MIN_DESTINATION_DETENTION_DAYS,
        urlParams: { [apiConstants.MIN_DESTINATION_DETENTION_DAYS]: minDays },
        value: { [apiConstants.MIN_DESTINATION_DETENTION_DAYS]: minDays, timingValue },
      },
      {
        key: apiConstants.MAX_DESTINATION_DETENTION_DAYS,
        urlParams: { [apiConstants.MAX_DESTINATION_DETENTION_DAYS]: maxDays },
        value: { [apiConstants.MAX_DESTINATION_DETENTION_DAYS]: maxDays },
      },
    ],
  }
}

export function getDemurrageOriginPortPayload(props: Props) {
  const { entityType, inputs, alertConfigId = null } = props
  const { minDays, maxDays } = getDayRanges(inputs)
  const { timingValue } = inputs
  return {
    name: portalAlertNames.DEMURRAGE_AT_ORIGIN_PORT,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.MIN_ORIGIN_DEMURRAGE_DAYS,
        urlParams: { [apiConstants.MIN_ORIGIN_DEMURRAGE_DAYS]: minDays },
        value: { [apiConstants.MIN_ORIGIN_DEMURRAGE_DAYS]: minDays, timingValue },
      },
      {
        key: apiConstants.MAX_ORIGIN_DEMURRAGE_DAYS,
        urlParams: { [apiConstants.MAX_ORIGIN_DEMURRAGE_DAYS]: maxDays },
        value: { [apiConstants.MAX_ORIGIN_DEMURRAGE_DAYS]: maxDays },
      },
    ],
  }
}

export function getDemurrageDestinationPortPayload(props: Props) {
  const { entityType, inputs, alertConfigId = null } = props
  const { minDays, maxDays } = getDayRanges(inputs)
  const { timingValue } = inputs
  return {
    name: portalAlertNames.DEMURRAGE_AT_DESTINATION_PORT,
    alertConfigId,
    payload: {
      ref_type: entityType,
    },
    fields: [
      {
        key: apiConstants.MIN_DESTINATION_DEMURRAGE_DAYS,
        urlParams: { [apiConstants.MIN_DESTINATION_DEMURRAGE_DAYS]: minDays },
        value: { [apiConstants.MIN_DESTINATION_DEMURRAGE_DAYS]: minDays, timingValue },
      },
      {
        key: apiConstants.MAX_DESTINATION_DEMURRAGE_DAYS,
        urlParams: { [apiConstants.MAX_DESTINATION_DEMURRAGE_DAYS]: maxDays },
        value: { [apiConstants.MAX_DESTINATION_DEMURRAGE_DAYS]: maxDays },
      },
    ],
  }
}
