export const TRANSPORT_DETAIL_REDUCER_KEY = 'transportDetail'

export const types = {
  FETCH_TRANSPORT_DETAIL_START: `${TRANSPORT_DETAIL_REDUCER_KEY}/FETCH_TRANSPORT_DETAIL_START`,
  FETCH_TRANSPORT_DETAIL_SUCCESS: `${TRANSPORT_DETAIL_REDUCER_KEY}/FETCH_TRANSPORT_DETAIL_SUCCESS`,
  FETCH_TRANSPORT_DETAIL_FAILURE: `${TRANSPORT_DETAIL_REDUCER_KEY}/FETCH_TRANSPORT_DETAIL_FAILURE`,
  FETCH_TRANSPORT_DETAIL_RESET: `${TRANSPORT_DETAIL_REDUCER_KEY}/FETCH_TRANSPORT_DETAIL_RESET`,
}

export const fetchTransportDetail = (externalId: string, refType: string) => ({
  type: types.FETCH_TRANSPORT_DETAIL_START,
  externalId,
  refType,
})

export const resetTransportDetail = () => ({
  type: types.FETCH_TRANSPORT_DETAIL_RESET,
})
