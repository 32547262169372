import React, { PropsWithChildren } from 'react'

interface ConditionalWrapperProps {
  /**
   * Condition controls whether the wrapper will be applied, or not
   */
  condition: boolean
  /**
   * wrapper is a callback, allowing the developer to control what wraps the children of ConditionalWrapper
   */
  wrapper: (children: React.ReactNode) => void
}

function ConditionalWrapper(props: PropsWithChildren<ConditionalWrapperProps>) {
  const { condition, wrapper, children } = props

  return <>{condition ? wrapper(children) : children}</>
}

export default ConditionalWrapper
