import { ROUTE_COMPARE_LIMIT, routeGraphMode } from 'store/planning/utils/RouteComparison'
import React, { useEffect, useRef, useState } from 'react'
import {
  allRoutesAggregatedMetricsSelector,
  isAggregatedGraphMetricsLoadingSelector,
  isComparisonGraphMetricsLoadingSelector,
  isRoutesMetricsLoadingSelector,
  routeGroupsWithMetricsSelector,
  selectedRouteIdsSelector,
} from 'store/planning'

import AggregatedGraphScorecards from 'pages/PlanningPage/AggregatedGraphScorecards'
import AggregatedRoutePerformanceGraph from './AggregatedRoutePerformanceGraph'
import CMPaper from 'components/CMPaper'
import ChartToggleDropdown from 'components/ChartToggleDropdown'
import ComparisonRoutePerformanceGraph from './ComparisonRoutePerformanceGraph'
import FilterDrawer from 'components/FilterDrawer'
import { Metric } from 'store/planning/utils/Metric'
import { PLANNING_DRAWER_KEY } from 'store/drawers'
import { PLANNING_FILTER_KEY } from 'store/filterGroups/actions'
import PropTypes from 'prop-types'
import RouteComparisonScorecards from './RouteComparisonScorecards'
import RoutesTable from 'pages/PlanningPage/RoutesTable'
import { connect } from 'react-redux'
import { drawersSelector } from 'store/drawers'
import { getRoutes } from 'store/planning/actions'
import isEmpty from 'lodash/isEmpty'
import tracker from 'utils/logger/tracker'
import { useHighchartsReflow } from 'utils/hooks'
import { withStyles } from '@material-ui/core'

const graphPadding = 10
const graphHeight = 325
const graphMinWidth = 600
const graphMaxWidth = 1000

export const PERFORMANCE_SECTION_HEIGHT = 470

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
  },
  performanceWrapper: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 5,
    minHeight: PERFORMANCE_SECTION_HEIGHT,
    position: 'relative',
  },
  routeDetails: {
    margin: 0,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[50],
    width: '100%',
  },
  graphWrapper: {
    // For some reason, we need to have a fixed width here. Otherwise,
    // highcharts will stretch its parent component and cause a horizontal
    // scrollbar to appear.
    width: 1,
    maxWidth: graphMaxWidth,
    minWidth: graphMinWidth,
    padding: graphPadding,
    paddingRight: 0,
    height: graphHeight + graphPadding * 8,
    flex: 2,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 21px`,
  },
  scoreCardWrapper: {
    flex: 1,
    height: graphHeight + graphPadding * 8,
    minWidth: 400,
    maxWidth: 600,
    overflowX: 'visible',
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
  },
  routeCompareDropdown: {
    paddingBottom: theme.spacing(2),
  },
  emptyStateContainer: {
    flex: 1,
    maxWidth: 1224,
    position: 'relative',
  },
  emptyStateDropdown: {
    left: 37,
    top: 25,
    position: 'absolute',
    minWidth: 275,
  },
})

const PlanningPage = function ({
  classes,
  getRoutes,
  graphData,
  hasRoutesToDisplay,
  aggregatedMetricsLoading,
  isGraphMetricsLoading,
  selectedRouteIds,
  drawerState,
  isRoutesMetricsLoading,
  comparisonMetricsLoading,
}) {
  useEffect(() => {
    getRoutes()
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Reflow the charts when the filter drawer is opened / closed and when the routes table
  // is finished loading, since it stretches the page.
  useHighchartsReflow([drawerState, isRoutesMetricsLoading])

  const [graphMode, setGraphMode] = useState(routeGraphMode.AGGREGATED)
  const [highchartsChart, setHighChartsChart] = useState(undefined)

  const isGraphDataEmpty = isEmpty(graphData) || graphData.durations.every(m => !Metric.isValid(m))

  const isAggregatedMode = graphMode === routeGraphMode.AGGREGATED
  const tooManyRoutesSelected = !isAggregatedMode && selectedRouteIds.size > ROUTE_COMPARE_LIMIT
  const isEmptyState = (isGraphDataEmpty && !isGraphMetricsLoading) || tooManyRoutesSelected

  const routeDetailsRef = useRef(null)
  return (
    <>
      <FilterDrawer
        drawerKey={PLANNING_DRAWER_KEY}
        filterKey={PLANNING_FILTER_KEY}
        tracker={tracker.planning}
      />
      <div className={classes.container}>
        <div className={classes.routeDetails} data-testid="planning__details" ref={routeDetailsRef}>
          <div className={classes.performanceWrapper}>
            <>
              <CMPaper className={classes.graphWrapper} data-testid="planning__boxplot">
                <div className={classes.routeCompareDropdown}>
                  <ChartToggleDropdown
                    items={[
                      {
                        label: 'Aggregated Route Performance',
                        value: routeGraphMode.AGGREGATED,
                      },
                      { label: 'Route Comparison View', value: routeGraphMode.COMPARISON },
                    ]}
                    labelKey={'label'}
                    defaultIdx={isAggregatedMode ? 0 : 1}
                    handleSelection={e => {
                      const graphMode = e.value
                      setGraphMode(graphMode)
                      tracker.planning.userSwitchesChartMode(graphMode)
                    }}
                  />
                </div>
                {isAggregatedMode ? (
                  <AggregatedRoutePerformanceGraph
                    height={graphHeight}
                    data={graphData.durations}
                    isLoading={aggregatedMetricsLoading}
                    error={
                      !aggregatedMetricsLoading &&
                      isGraphDataEmpty && (
                        <>
                          No results found.
                          <br />
                          Please adjust your filter criteria.
                        </>
                      )
                    }
                  />
                ) : (
                  <ComparisonRoutePerformanceGraph
                    height={graphHeight}
                    setHighchartsChart={setHighChartsChart}
                    isLoading={comparisonMetricsLoading}
                    error={
                      isEmptyState && (
                        <>
                          {isGraphDataEmpty ? `No results found.` : `Too many routes selected.`}
                          <br />
                          {isGraphDataEmpty
                            ? hasRoutesToDisplay
                              ? `Please select routes to see data.`
                              : `Please adjust your filter criteria.`
                            : `Please select up to ${ROUTE_COMPARE_LIMIT} routes max.`}
                        </>
                      )
                    }
                  />
                )}
              </CMPaper>
              <div className={classes.scoreCardWrapper} data-testid="planning__scorecard">
                {isAggregatedMode ? (
                  <AggregatedGraphScorecards
                    hasNoData={!aggregatedMetricsLoading && isGraphDataEmpty}
                  />
                ) : (
                  <RouteComparisonScorecards
                    chart={highchartsChart}
                    isLoading={comparisonMetricsLoading}
                    hasNoData={!comparisonMetricsLoading && isGraphDataEmpty}
                    maxData={tooManyRoutesSelected}
                  />
                )}
              </div>
            </>
          </div>
          <RoutesTable containerRef={routeDetailsRef} />
        </div>
      </div>
    </>
  )
}

PlanningPage.propTypes = {
  classes: PropTypes.object.isRequired,
  getRoutes: PropTypes.func.isRequired,
  graphData: PropTypes.object.isRequired,
  hasRoutesToDisplay: PropTypes.bool.isRequired,
  selectedRouteIds: PropTypes.object,
}

const mapDispatchToProps = {
  getRoutes,
}

const mapStateToProps = state => ({
  graphData: allRoutesAggregatedMetricsSelector(state),
  aggregatedMetricsLoading: isAggregatedGraphMetricsLoadingSelector(state),
  comparisonMetricsLoading: isComparisonGraphMetricsLoadingSelector(state),
  isGraphMetricsLoading:
    isAggregatedGraphMetricsLoadingSelector(state) ||
    isComparisonGraphMetricsLoadingSelector(state),
  hasRoutesToDisplay: !isEmpty(routeGroupsWithMetricsSelector(state)),
  selectedRouteIds: selectedRouteIdsSelector(state),
  drawerState: drawersSelector(state)[PLANNING_DRAWER_KEY],
  isRoutesMetricsLoading: isRoutesMetricsLoadingSelector(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlanningPage))
