import { BoardColumnInterface, BoardRowInterface } from 'store/board/interfaces'
import { DELIVERED, DISCHARGED, UNKNOWN } from 'store/models/definitions/StatusLabel'

import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import { LIST_VIEW } from 'store/boardUtils'
import { ROLLUP_CONTAINER } from 'utils/rollups'
import React from 'react'
import ShippingStatusLabel from 'components/ShippingStatusLabel'
import StatusLabel from 'components/StatusLabel'
import statusLabelVariant from 'store/models/utils/statusLabelVariant'
import { useSelector } from 'react-redux'
import { viewTypeSelector } from 'store/boardUtils/selectors'

interface Props {
  className?: string
  column: BoardColumnInterface
  row: BoardRowInterface
  isRowExpanded: boolean
  onContextMenu: () => void
  onRowExpand: () => {}
  onRowCollapse: () => {}
  rollup: string
  style?: object
}

const DelayCell = (props: Props) => {
  const {
    className,
    column,
    isRowExpanded,
    onContextMenu,
    onRowCollapse,
    onRowExpand,
    rollup,
    row,
    style = {},
  } = props
  const { statusLabel, statusTrips, trips } = row.shipmentBoard ?? {}
  const tableViewType = useSelector(viewTypeSelector)

  return (
    <BaseCell
      className={className}
      column={column}
      onContextMenu={onContextMenu}
      rollup={rollup}
      row={row}
      style={style}
    >
      {/* For now the timeline view uses the legacy labels */}
      {tableViewType === LIST_VIEW ? (
        <ShippingStatusLabel
          status={row.status as any}
          isExpanded={isRowExpanded}
          onExpand={onRowExpand}
          onCollapse={onRowCollapse}
          collapseTimeout={0} /*Disable collapse animation when in table mode*/
        />
      ) : (
        statusLabel && (
          <StatusLabel
            expandToContent={true}
            short={true}
            label={
              rollup !== ROLLUP_CONTAINER &&
              (statusLabel === DISCHARGED || statusLabel === DELIVERED)
                ? `${statusLabel} (${statusTrips.length} of ${trips.length})`
                : statusLabel
            }
            variant={statusLabelVariant(statusLabel)}
            statusPopoverToShow={statusLabel === UNKNOWN ? UNKNOWN : null}
          />
        )
      )}
    </BaseCell>
  )
}

export default React.memo(DelayCell)
