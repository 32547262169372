import { ETA } from 'utils/shipmentObjs'
import { TimeRange } from 'utils/sharedObjs'
import get from 'lodash/get'

const structureDeliveryTimes = deliveryTime => {
  const originalPlanned = new TimeRange(
    get(deliveryTime, 'old_planned'),
    get(deliveryTime, 'old_planned')
  )
  const traced = new TimeRange(get(deliveryTime, 'traced'), get(deliveryTime, 'traced'))
  const planned = new TimeRange(get(deliveryTime, 'planned'), get(deliveryTime, 'planned'))
  const predicted = new TimeRange(get(deliveryTime, 'predicted'), get(deliveryTime, 'predicted'))
  return new ETA(traced, planned, predicted, originalPlanned)
}

export default structureDeliveryTimes
