import React from 'react'
import { SvgIcon } from '@material-ui/core'

const DownloadIcon = ({ color = '#00558B', ...restProps }) => (
  <SvgIcon viewBox={'-6 0 24 24'} {...restProps}>
    <g fill={color} fillRule="nonzero">
      <path d="M6.368.267c.542 0 .988.45.988.995v8.875l3.441-3.466a.983.983 0 011.4 0 1 1 0 010 1.41L7.111 13.2c-.422.426-1.077.45-1.488 0L.54 8.081a1 1 0 010-1.41.976.976 0 011.393 0l3.447 3.472v-8.88c0-.552.446-.996.988-.996zM12.708 15.354a.997.997 0 01-.988.995H1.016a.997.997 0 01-.988-.995c0-.546.446-.995.988-.995H11.72c.542 0 .988.45.988.995z" />
    </g>
  </SvgIcon>
)

export default DownloadIcon
