import find from 'lodash/find'
import get from 'lodash/get'
import has from 'lodash/has'
import isFunction from 'lodash/isFunction'
import orderBy from 'lodash/orderBy'

// Given an array of column data
// 1. Check for an 'accessor' property on the column and get value using that
// 2. If no accessor, check for '#getCellValue' and call that to get the value
export const getCellValue = (columns, row, colName) => {
  const col = find(columns, { name: colName })

  if (!col) return undefined

  // If an accessor property is defined, just use that to get the value
  if (has(col, 'accessor')) return get(row, col.accessor, undefined)

  // If no accessor, check for getCellValue func and call that
  if (isFunction(col.getCellValue)) return col.getCellValue(row)

  return undefined
}

/**
 * Sorts an array of objects. for components that import `SortableTableHeader`. An `id` prop is required.
 * @param dataKey the column `id` to sort the table contents on
 * @param direction "asc" or "desc"
 * @param data array
 * @param columns array of objects
 * @param orderByProperty string property name in each row to do an `orderBy` on
 * The `dataAccessor` prop is optional. If not provided the function uses `dataKey` to sort on.
 * [
 *  { id: 'email', value: 'Email' },
 *  { id: 'name', value: 'Name', dataAccessor: item => get(item, 'originLocation.name')},
 * ]
 */
export const tableDataSort = ({
  dataKey = null,
  direction = 'asc',
  data = null,
  columns = null,
  orderByProperty = null,
}) => {
  if (!dataKey || !data || !columns) {
    return []
  }
  const sortByCol = columns.find(col => col.id === dataKey)
  const dataAccessor = get(sortByCol, 'dataAccessor', null)
  const sortedRows = orderBy(
    data,
    [row => (dataAccessor ? dataAccessor(row) : row[dataKey])],
    [direction]
  )
  if (orderByProperty) {
    sortedRows.forEach(group => {
      group[orderByProperty] = orderBy(
        group[orderByProperty],
        [row => (dataAccessor ? dataAccessor(row) : row[dataKey])],
        [direction]
      )
    })
  }
  return sortedRows
}
