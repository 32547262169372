import humps from 'humps'

const DEFAULT_NOTIF_FREQUENCY = ''
export const DEFAULT_NOTIF_TIME = '16:00'
export const DEFAULT_NOTIF_TIMEZONE = 'UTC'

export class PortalAlerts {
  constructor(alertConfigs, notifFrequency, notifTime, notifTimezone, refType) {
    this.alertConfigs = alertConfigs
    this.notifFrequency = notifFrequency
    this.notifTime = notifTime
    this.notifTimezone = notifTimezone
    this.refType = refType
  }

  static of(obj) {
    const camelized = humps.camelizeKeys(obj)
    const { alertConfigs, refType } = camelized
    let { notifTime, notifFrequency, notifTimezone } = camelized

    if (!notifFrequency) {
      notifFrequency = DEFAULT_NOTIF_FREQUENCY
    }

    // avoid react warnings about switching between controlled and uncontrolled inputs
    if (!notifTime) {
      notifTime = ''
    }

    if (!notifTimezone) {
      notifTimezone = ''
    }

    return new PortalAlerts(
      alertConfigs && alertConfigs.map(alert => PortalAlert.of(alert)),
      notifFrequency,
      notifTime,
      notifTimezone,
      refType
    )
  }
}

export class PortalAlert {
  constructor(alertConfigId, fields, name, payload) {
    this.alertConfigId = alertConfigId
    this.fields = fields || []
    this.name = name
    this.payload = payload
  }

  static of(obj) {
    const { alertConfigId, fields, name, payload } = humps.camelizeKeys(obj)
    return new PortalAlert(
      alertConfigId,
      fields && fields.map(field => Field.of(field)),
      name,
      payload
    )
  }
}

export class Field {
  constructor(key, urlParams, value) {
    this.key = key
    this.urlParams = urlParams
    this.value = value
  }

  static of(obj) {
    const { key, urlParams, value } = humps.camelizeKeys(obj)
    return new Field(key, urlParams, value)
  }
}
