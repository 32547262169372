import { MilestoneInterface } from 'store/models/Milestone'
import last from 'lodash/last'

/**
 * Takes an array of milestones and returns the last milestone where `milestone.traced` is not null
 * @param milestones
 */
export const getLastTracedMilestone = (milestones: MilestoneInterface[]) => {
  return last(milestones.filter(milestone => milestone.tracedTime)) as MilestoneInterface
}

export default getLastTracedMilestone
