import { Box, IconButton, Popover, PopoverOrigin, Theme, makeStyles } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    '& .MuiBox-root:focus': {
      outline: 'none',
    },
    '& li:focus': {
      outline: 'none',
    },
  },
  popoverPaper: {
    boxShadow: theme.shadows[10],
    width: 320,
  },
  popoverCloseButton: {
    padding: theme.spacing(1),
    position: 'absolute',
    right: 10,
    top: 5,
  },
  popoverHeader: {
    ...theme.typography.subtitle2,
    color: theme.palette.blue[800],
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(2, 2, 1, 2),
  },
  popoverBody: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  popoverFooter: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(2),
  },
}))

interface Props {
  anchorEl: HTMLElement | null
  bodyContent: React.ReactNode | null
  classes?: Record<string, string>
  footerContent: React.ReactNode | null
  handleClose: () => void
  headerContent: React.ReactNode | null
  transformOrigin?: PopoverOrigin | undefined
}

/**
 * A very simple popover that serves as a quasi tooltip, with a header, body and footer section.
 * It is very extensible with all classes available for override and content coming from the caller.
 */
const PopoverText = (props: Props) => {
  const classes = useStyles(props)
  const {
    anchorEl,
    bodyContent,
    footerContent,
    handleClose,
    headerContent,
    transformOrigin,
  } = props

  if (!bodyContent && !footerContent && !headerContent) {
    return null
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={
        transformOrigin ?? {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      // On the board this will prevent the board context menu from appearing when a right click occurs here
      onContextMenu={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      className={classes.popover}
      elevation={1}
      keepMounted
      onClose={handleClose}
      open={Boolean(anchorEl)}
      classes={{ paper: classes.popoverPaper }}
    >
      <IconButton className={classes.popoverCloseButton} onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
      {headerContent && <Box className={classes.popoverHeader}>{headerContent}</Box>}
      {bodyContent && <Box className={classes.popoverBody}>{bodyContent}</Box>}
      {footerContent && <Box className={classes.popoverFooter}>{footerContent}</Box>}
    </Popover>
  )
}

export default React.memo(PopoverText)
