import { LabelColorVariants } from 'components/StatusLabel/labelColors'
import React from 'react'
import StatusLabel from 'components/StatusLabel'
import mapTripStatus from 'utils/shipmentUtils/mapTripStatus'

type Status = {
  label: string
  color: LabelColorVariants
  details?: Array<string>
}

interface Props {
  collapseTimeout?: number
  isExpanded?: boolean
  onCollapse?: () => {}
  onExpand?: () => {}
  status: Status
}

const ShippingStatusLabel = (props: Props) => {
  const { status, ...rest } = props
  const mappedStatus: Status = mapTripStatus(status)

  return <StatusLabel label={mappedStatus.label} variant={mappedStatus.color} {...rest} />
}

export default ShippingStatusLabel
