import React from 'react'

const FiltersCollapsedIcon = ({ color = 'black', ...restProps }) => (
  <svg width={38} height={22} {...restProps}>
    <g fill="#859198" fillRule="evenodd">
      <path d="M3.784 16.993c.323-1.615 1.729-2.832 3.412-2.832 1.684 0 3.09 1.217 3.413 2.832h10.748c.385 0 .697.317.697.708 0 .391-.312.708-.697.708H10.61c-.323 1.615-1.73 2.832-3.413 2.832-1.683 0-3.089-1.217-3.412-2.832H.929a.703.703 0 0 1-.697-.708c0-.39.312-.708.697-.708h2.855zm3.412 2.832c-1.151 0-2.089-.953-2.089-2.124 0-1.171.938-2.124 2.09-2.124 1.151 0 2.089.953 2.089 2.124 0 1.171-.938 2.124-2.09 2.124zm4.48-9.912C12 8.297 13.407 7.08 15.09 7.08c1.684 0 3.09 1.217 3.413 2.833h2.855c.385 0 .697.317.697.708 0 .39-.312.708-.697.708h-2.855c-.323 1.615-1.73 2.832-3.413 2.832-1.683 0-3.09-1.217-3.412-2.832H.929a.703.703 0 0 1-.697-.708c0-.391.312-.708.697-.708h10.748zm3.413 2.832c-1.152 0-2.089-.953-2.089-2.124 0-1.171.937-2.124 2.09-2.124 1.151 0 2.089.953 2.089 2.124 0 1.17-.938 2.124-2.09 2.124zM3.784 2.832C4.107 1.217 5.513 0 7.196 0c1.684 0 3.09 1.217 3.413 2.832h10.748c.385 0 .697.317.697.708 0 .391-.312.708-.697.708H10.61c-.323 1.616-1.73 2.832-3.413 2.832-1.683 0-3.089-1.216-3.412-2.832H.929a.703.703 0 0 1-.697-.708c0-.39.312-.708.697-.708h2.855zm3.412 2.832c-1.151 0-2.089-.953-2.089-2.124 0-1.17.938-2.124 2.09-2.124 1.151 0 2.089.953 2.089 2.124 0 1.171-.938 2.124-2.09 2.124zM29.023 16.76l5.58-5.76-5.58-5.76c-.43-.411-.43-1.234 0-1.646.429-.411 1.18-.411 1.61 0l6.33 6.583c.43.412.43 1.234 0 1.646l-6.33 6.583c-.43.411-1.181.411-1.61 0-.43-.412-.43-1.132 0-1.646z" />
    </g>
  </svg>
)

export default FiltersCollapsedIcon
