import ArrowBackward from '@material-ui/icons/ArrowBack'
import Button from 'components/core/Button'
import React from 'react'
import classnames from 'classnames'
import { fromQueryString } from 'utils/urlBuilder'
import history from 'utils/history'
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    minHeight: '100%',
  },
  label: {
    textTransform: 'none',
    color: theme.palette.grey[500],
  },
  arrowIcon: {
    fontSize: 18,
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  noSource: {
    paddingTop: theme.spacing(4),
  },
  buttonRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))

type EntityPageBaseProps = {
  className?: string
  children: React.ReactNode
}

const EntityPageBase = (props: EntityPageBaseProps) => {
  const classes = useStyles()
  const { children, className } = props
  const location = useLocation()
  const query = fromQueryString(location.search)
  const source = query?.source

  const renderBackButton = () => {
    if (source) {
      return (
        <Button
          classes={{ label: classes.label, root: classes.buttonRoot }}
          onClick={history.goBack}
        >
          <ArrowBackward className={classes.arrowIcon} />
          Back
        </Button>
      )
    }
    return null
  }

  const containerClasses = classnames(classes.container, {
    [classes.noSource]: !source,
  })
  return (
    <div className={classnames(containerClasses, className)}>
      {renderBackButton()}
      {children}
    </div>
  )
}

export default EntityPageBase
