import get from 'lodash/get'
import { getLocalizedMoment } from 'utils/dateUtils'

const structureDischargeDates = dischargeTime => {
  const tz = get(dischargeTime, 'timezone')
  const planned =
    get(dischargeTime, 'planned') && tz
      ? getLocalizedMoment(get(dischargeTime, 'planned'), tz)
      : null
  const predicted =
    get(dischargeTime, 'predicted') && tz
      ? getLocalizedMoment(get(dischargeTime, 'predicted'), tz)
      : null
  const traced =
    get(dischargeTime, 'traced') && tz ? getLocalizedMoment(get(dischargeTime, 'traced'), tz) : null
  return {
    planned,
    predicted,
    traced,
  }
}

export default structureDischargeDates
