export const Metric = {
  of: (attrs = {}) => {
    let metric = {
      count: attrs.count,
      startDate: attrs.startDate,
      endDate: attrs.endDate,
      lowerQuartile: attrs.lowerQuartile,
      metricsMax: attrs.max,
      metricsMean: attrs.mean,
      metricsMedian: attrs.median,
      metricsMin: attrs.min,
      metricsStddev: attrs.stddev,
      routeId: attrs.routeId,
      upperQuartile: attrs.upperQuartile,
      percentile5: attrs.percentile5,
      percentile95: attrs.percentile95,
    }

    metric.variability =
      metric.upperQuartile !== undefined && metric.lowerQuartile !== undefined
        ? metric.upperQuartile - metric.lowerQuartile
        : undefined

    return metric
  },
  isValid: metric => {
    return Boolean(metric && metric.count) && metric.count > 0
  },
}

export const MetricNames = {
  DURATION: 'duration',
  DELAY: 'delay',
  ROLL: 'rolled',
  DWELL: 'dwell',
}
