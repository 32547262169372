import { ETDStatus } from 'utils/shipmentObjs'
import get from 'lodash/get'

const getETDStatuses = originDeparture => {
  const originalETD = get(originDeparture, 'originalPlanned.max', 'N/A')
  const updatedETD = get(originDeparture, 'planned.max', 'N/A')
  const predictedETD = get(originDeparture, 'predicted.max', 'N/A')
  const traced = get(originDeparture, 'traced.min', 'N/A')
  return new ETDStatus(originalETD, updatedETD, predictedETD, traced)
}

export default getETDStatuses
