import { Typography, makeStyles } from '@material-ui/core'
import { intendedUsernameFromState, loginErrorMessageFromState } from 'store/auth/login/selectors'
import { useDispatch, useSelector } from 'react-redux'

import AuthDialog from 'components/AuthDialog'
import { AuthTitle } from 'components/AuthTitle'
import CenteredLayout from 'components/layouts/CenteredLayout'
import PasswordLoginForm from './PasswordLoginForm'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { SSO_EMAIL_ROUTE } from 'utils/routes'
import { login } from 'store/auth/login/actions'
import { oauthStatusSelector } from 'store/oauthStatus/selectors'
import { setAuthStage } from 'store/auth/login/actions'
import { useQuery } from 'utils/hooks'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  newAuthTitle: {
    paddingTop: theme.spacing(3),
  },
  newAuthSubtitle: {
    color: theme.palette.grey[500],
  },
  loginErrorMessage: {
    marginTop: theme.spacing(3),
  },
}))

const PasswordLoginPage = () => {
  const dispatch = useDispatch()
  const oauthStatus = useSelector(oauthStatusSelector)
  const intendedUsername = useSelector(intendedUsernameFromState)
  const loginErrorMessage = useSelector(loginErrorMessageFromState)
  const classes = useStyles()
  const query = useQuery()
  const redirectUrl = query.get('redirect_url') || '/'

  // don't need to check isLoading here since oath status is loaded on another page
  // if there is not OAuth data, we should just redirect
  if (!oauthStatus.data) {
    dispatch(setAuthStage('email'))
    return <Redirect to={SSO_EMAIL_ROUTE.buildUrl()} />
  }

  const passwordLoginEmailDisabled = intendedUsername !== null && loginErrorMessage === null
  const onPasswordLoginSubmit = (params: { email: string; password: string }) => {
    dispatch(login(params.email, params.password, redirectUrl, false))
  }

  const displayErrorMessage = loginErrorMessage !== null

  return (
    <CenteredLayout className={classes.root}>
      <AuthDialog>
        <AuthTitle className={classes.newAuthTitle}>Sign In to Your Account</AuthTitle>
        {displayErrorMessage ? (
          <Typography color="error" variant="body2" className={classes.loginErrorMessage}>
            {loginErrorMessage}
          </Typography>
        ) : (
          <></>
        )}
        <PasswordLoginForm
          emailDisabled={passwordLoginEmailDisabled}
          onSubmit={onPasswordLoginSubmit}
          ssoLogin
        />
      </AuthDialog>
    </CenteredLayout>
  )
}

export default PasswordLoginPage
