import ApiRefType, { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import { Box, Tab, Tabs, Theme, makeStyles } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { customizationSelector, userLoadingFromState } from 'store/auth/user/selectors'
import { tripGroupsLoadingSelector, tripGroupsSelector } from 'store/tripGroups/selectors'

import AdditionalInfoTable from 'components/AdditionalInfoTable'
import ContainerTable from 'pages/EntityPage/EntityPageTabs/ContainerTable'
import JourneyMap from 'pages/EntityPage/EntityPageTabs/JourneyMap'
import ReferencesTable from 'components/ReferencesTable'
import TabSkeleton from 'pages/EntityPage/EntityPageTabs/TabSkeleton'
import TimelineView from 'pages/EntityPage/EntityPageTabs/TimelineView'
import { TransportDetailInterface } from 'store/models/TransportDetail/interfaces'
import { associatedReferencesSelector } from 'store/associatedReferences/selectors'
import classnames from 'classnames'
import { customFieldsSelector } from 'store/customFields/selectors'
import every from 'lodash/every'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import logger from 'utils/logger'
import store from 'store'
import { useSelector } from 'react-redux'

// Meaning when all tabs are visible
const MAX_TAB_COUNT = 5

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    width: `${100 / MAX_TAB_COUNT}%`, // equal space for each tab, makes this quasi responsive
  },
  tabs: {},
  tabsWrapper: {},
  tooltipTab: {
    minWidth: 225,
    '& button': {
      width: '100%',
    },
  },
  wrapper: {
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      maxWidth: 92,
    },
  },
  tabUnderline: {
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    marginTop: theme.spacing(6),
  },
  tabContentWrapper: {
    background: theme.palette.grey[100],
    boxShadow: `0 50vh 0 50vh ${theme.palette.grey[100]}`,
    height: '100%',
  },
  indicator: {
    height: 4,
  },
  transportDetail: {
    height: '100%',
  },
}))

interface TagInterface {
  key: string
  type: string
  values: string[]
}

interface Props {
  transportDetail: TransportDetailInterface
  refType: ApiRefTypeType
}

const EntityPageTabs = (props: Props) => {
  const classes = useStyles()
  const { transportDetail, refType } = props
  const [currentTab, setCurrentTab] = useState<number | null>(null)
  const [customFieldsHaveValues, setCustomFieldsHaveValues] = useState(false)
  const customization = useSelector(customizationSelector)
  const customFields = useSelector(customFieldsSelector)
  const isUserLoading = useSelector(userLoadingFromState)
  const associatedReferencesData = useSelector(associatedReferencesSelector)
  const tripGroups = useSelector(tripGroupsSelector)
  const isTripGroupsLoading = useSelector(tripGroupsLoadingSelector)

  const ACTIVE_TAB_KEY = 'entityTabs.activeTab'
  const entityId = transportDetail?.self.number

  // Everything that follows is for tab handling. Mostly all you have to care about is the `tabs` object below
  // because all of these vars are used to set properties for each tab

  // If they have custom fields does at least one have data? If not we disable Additional Info tab

  useEffect(() => {
    const customerTags = customization.customerTags ?? []
    const hasValues = customerTags.some((tag: TagInterface) => {
      const key = tag.key
      const matchingCustomField = find(customFields.data?.customFields, item => item.key === key)
      return matchingCustomField && matchingCustomField?.values
    })

    setCustomFieldsHaveValues(hasValues)
  }, [customFields, customization])

  const isBookingPage = refType === ApiRefType.ocean_booking_carrier_reference
  const hasContainerTrips = transportDetail?.header?.trips.length > 0
  const localStorageTab = store.get(ACTIVE_TAB_KEY)

  /**
   * The goal of this object is to keep all tab properties here instead of scattered around in variables.
   * idx: number. the id of the tab used by MUI. Updated on tab selection in `setCurrentTab` below
   * label: string. display text for tab
   * isDisabled: boolean. We have various types of logic to determine when a tab is disabled or not
   * shouldBeVisible: boolean. Can be used to check feature flags or other conditions
   *
   * A note about the `isDisabled` logic:
   * The test `something === undefined` is a simple way to see if data has been added to state, even if "empty",
   * because initial state is always `undefined`. We then add tests to check for the data each tab needs.
   */
  const tabs = useMemo(
    () => ({
      TIMELINE: {
        idx: 2,
        label: 'Timeline View',
        isDisabled: transportDetail === undefined || transportDetail.timelines.length === 0,
        shouldBeVisible: true,
      },
      MAP_VIEW: {
        idx: 3,
        label: 'Map View',
        isDisabled:
          (isBookingPage && !hasContainerTrips) ||
          transportDetail === undefined ||
          transportDetail.timelines.length === 0,
        shouldBeVisible: true,
      },
      CONTAINERS: {
        idx: 4,
        label: 'Containers',
        isDisabled:
          (isBookingPage && !hasContainerTrips) ||
          (!isTripGroupsLoading && tripGroups === undefined) ||
          !tripGroups,
        shouldBeVisible: true,
      },
      ASSOCIATED_REFERENCES: {
        idx: 5,
        label: 'Associated References',
        isDisabled:
          associatedReferencesData === undefined ||
          isEmpty(associatedReferencesData) ||
          // An "empty" response will be `{ orders: [], transportation: [], shipments: []}`. This test grabs the
          // three keys and reduces on the array lengths, so if they are all empty we end up with `0`
          Object.values(associatedReferencesData ?? {}).reduce(
            (acc: number, value: []) => acc + value.length,
            0
          ) === 0,
        shouldBeVisible: true, // Always `true` because we show legacy data if feature flag is `false`
      },
      ADDITIONAL_INFO: {
        idx: 6,
        label: 'Additional Info',
        isDisabled: !isUserLoading && !customFieldsHaveValues,
        shouldBeVisible: true,
      },
    }),
    [
      associatedReferencesData,
      customFieldsHaveValues,
      hasContainerTrips,
      isBookingPage,
      isTripGroupsLoading,
      isUserLoading,
      transportDetail,
      tripGroups,
    ]
  )

  useEffect(() => {
    // If they have a tab in `localStorage` we use it unless it's disabled or shouldn't be visible on this page
    if (typeof localStorageTab !== 'undefined') {
      const storedTab = find(tabs, tab => tab.idx === localStorageTab && tab.shouldBeVisible)
      if (storedTab && !storedTab.isDisabled) {
        setCurrentTab(storedTab.idx)
      }
    }

    // Otherwise we use the first non-disabled visible tab
    if (!currentTab) {
      const defaultTab = find(tabs, tab => !tab.isDisabled && tab.shouldBeVisible)
      if (defaultTab) {
        setCurrentTab(defaultTab.idx)
      }
    }
  }, [currentTab, tabs, localStorageTab])

  const handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    store.set(ACTIVE_TAB_KEY, selectedTab)
    setCurrentTab(selectedTab)

    const tab = find(tabs, tab => tab.idx === selectedTab)
    logger.notify('Entity Page Tab Click', {
      entity_type: refType,
      entity_id: entityId,
      tab_type: (tab && tab.label) || '',
    })
  }

  const journeyMapProps = {
    transportDetail,
  }

  const allTabsDisabled = every(tabs, tab => tab.isDisabled)

  return (
    <Box className={classes.tabsWrapper}>
      <Box px={6} className={classes.tabUnderline}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          data-testid="entity-detail_tabs"
          classes={{ root: classes.tabs, indicator: classes.indicator }}
        >
          {tabs.TIMELINE.shouldBeVisible && (
            <Tab
              value={tabs.TIMELINE.idx}
              label={tabs.TIMELINE.label}
              classes={{ root: classes.tab, wrapper: classes.wrapper }}
              disabled={tabs.TIMELINE.isDisabled}
            />
          )}
          <Tab
            disabled={tabs.MAP_VIEW.isDisabled}
            value={tabs.MAP_VIEW.idx}
            label={tabs.MAP_VIEW.label}
            classes={{ root: classes.tab, wrapper: classes.wrapper }}
          />
          <Tab
            disabled={tabs.CONTAINERS.isDisabled}
            value={tabs.CONTAINERS.idx}
            label={tabs.CONTAINERS.label}
            classes={{ root: classes.tab, wrapper: classes.wrapper }}
          />
          <Tab
            disabled={tabs.ASSOCIATED_REFERENCES.isDisabled}
            value={tabs.ASSOCIATED_REFERENCES.idx}
            label={tabs.ASSOCIATED_REFERENCES.label}
            classes={{ root: classes.tab, wrapper: classes.wrapper }}
          />
          <Tab
            value={tabs.ADDITIONAL_INFO.idx}
            label={tabs.ADDITIONAL_INFO.label}
            classes={{ root: classes.tab, wrapper: classes.wrapper }}
            disabled={tabs.ADDITIONAL_INFO.isDisabled}
          />
          )
        </Tabs>
      </Box>
      <Box
        pt={3}
        px={6}
        pb={12}
        className={classnames({
          [classes.tabContentWrapper]: currentTab !== tabs.ADDITIONAL_INFO.idx,
        })}
      >
        {allTabsDisabled ? (
          <TabSkeleton isAnimated={false} />
        ) : (
          <>
            {currentTab === tabs.MAP_VIEW.idx && (
              <Box mt={-3}>
                {/* Spreading props here is only to satisfy TS, not because of any problems here but because
                  of the craziness in `JourneyMap` where use `connect`, `Dimensions` and `withStyles`. Here,
                  TS complains that `transportDetail` "is not assignable to type 'IntrinsicAttributes" and then
                  a long run of types with `Omit` and `unknown` and `pick`. Spreading was a solution I saw on
                  SO and it works here and in `JourneyDetails`. 
              */}
                <JourneyMap {...journeyMapProps} />
              </Box>
            )}
            {currentTab === tabs.ASSOCIATED_REFERENCES.idx && (
              <>
                {associatedReferencesData && (
                  <ReferencesTable data={associatedReferencesData} refType={refType} />
                )}
              </>
            )}
            {currentTab === tabs.ADDITIONAL_INFO.idx && (
              <AdditionalInfoTable customization={customization} />
            )}
            {currentTab === tabs.CONTAINERS.idx && (
              <ContainerTable refType={refType} tripGroups={tripGroups} />
            )}
            {tabs.TIMELINE.shouldBeVisible && currentTab === tabs.TIMELINE.idx && (
              <TimelineView transportDetail={transportDetail} />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default EntityPageTabs
