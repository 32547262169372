export const RoutePath = (path: string) => {
  return {
    path,
    buildUrl(params: Record<string, string | null | undefined> = {}): string {
      return this.path
        .split('/')
        .map(part => {
          if (part.startsWith(':')) {
            const isOptional = part.endsWith('?')
            const key = isOptional ? part.slice(1, part.length - 1) : part.slice(1)
            const val = params[key]
            if (!val) {
              if (isOptional) {
                return undefined
              }
              throw new Error(`Expected param ${part} for path ${this.path}.`)
            }
            return encodeURIComponent(val)
          }
          return part
        })
        .filter(part => part !== undefined)
        .join('/')
    },
  }
}

export const VOC_URL = 'https://na12.voc.project44.com'
export const AUTH_BASE_PREFIX = '/auth/'
export const LOGIN_ROUTE = RoutePath('/auth/login/:extension?')
export const SSO_EMAIL_ROUTE = RoutePath('/auth/sso/email/:extension?')
export const SSO_PASSWORD_ROUTE = RoutePath('/auth/sso/password/')
export const SSO_CHOOSE_ROUTE = RoutePath('/auth/sso/choose/')
export const PASSWORD_RESET_ROUTE = RoutePath('/auth/password/reset')
export const PASSWORD_RESET_CONFIRM_ROUTE = RoutePath(
  '/auth/password/reset-confirmation/:email/:token'
)
export const REGISTER_ROUTE = RoutePath('/auth/register/:email/:token/:extension?')
export const REQUEST_INVITATION_ROUTE = RoutePath('/auth/invitation')

export const HOME_ROUTE = RoutePath('/')
export const ADMIN_ROUTE = RoutePath('/admin')
export const LAB_ROUTE = RoutePath('/lab')
export const SETTINGS_ROUTE = RoutePath('/settings')
export const BOARD_ROUTE = RoutePath('/board')
// Deprecated, replaced by `PLANNING_ROUTE`
export const LANES_ROUTE = RoutePath('/lanes')
export const PLANNING_ROUTE = RoutePath('/planning')
export const DOCS_ROUTE = RoutePath('/docs/:version?')
export const FOUR_OH_FOUR_ROUTE = RoutePath('/404')
export const DOWNLOADS_ROUTE = RoutePath('/downloads')
export const ALERTS_ROUTE = RoutePath('/alerts')
export const ALERT_DETAIL_ROUTE = RoutePath('/alert/:id')
export const SHARES_ROUTE = RoutePath('/shares')
export const PUBLIC_SHARE_ROUTE = RoutePath('/public/share/:token')
export const UPLOAD_ROUTE = RoutePath('/upload')
export const EXTERNAL_USER_PORTAL_ROUTE = RoutePath('/portal')
export const PORTAL_SETTINGS_ROUTE = RoutePath('/portal/settings')

// entity routes. "DEPRECATED_*" routes are from before the new schema. see #177291161
export const DEPRECATED_ORDER_ITEM_ROUTE = RoutePath('/order/:orderId/item/:itemId/:extension?')
export const ORDER_ITEM_ROUTE = RoutePath(
  '/order/:orderNumber/item/:itemNumber/id/:externalId/:extension?'
)

export const DEPRECATED_STOCK_TRANSFER_ORDER_ITEM_ROUTE = RoutePath(
  '/stock-transfer-order/:orderId/item/:itemId/:extension?'
)
export const STOCK_TRANSFER_ORDER_ITEM_ROUTE = RoutePath(
  '/stock-transfer-order/:orderNumber/item/:itemNumber/id/:externalId/:extension?'
)

export const DEPRECATED_SALES_ORDER_ITEM_ROUTE = RoutePath(
  '/sales-order/:orderId/item/:itemId/:extension?'
)
export const SALES_ORDER_ITEM_ROUTE = RoutePath(
  '/sales-order/:orderNumber/item/:itemNumber/id/:externalId/:extension?'
)

export const DEPRECATED_PURCHASE_ORDER_ITEM_ROUTE = RoutePath(
  '/purchase-order/:orderId/item/:itemId/:extension?'
)
export const PURCHASE_ORDER_ITEM_ROUTE = RoutePath(
  '/purchase-order/:orderNumber/item/:itemNumber/id/:externalId/:extension?'
)

export const DEPRECATED_SALES_ORDER_ROUTE = RoutePath('/sales-order/:id/:extension?')
export const SALES_ORDER_ROUTE = RoutePath('/sales-order/:number/id/:externalId/:extension?')

export const DEPRECATED_PURCHASE_ORDER_ROUTE = RoutePath('/purchase-order/:id/:extension?')
export const PURCHASE_ORDER_ROUTE = RoutePath('/purchase-order/:number/id/:externalId/:extension?')

export const DEPRECATED_STOCK_TRANSFER_ORDER_ROUTE = RoutePath(
  '/stock-transfer-order/:id/:extension?'
)
export const STOCK_TRANSFER_ORDER_ROUTE = RoutePath(
  '/stock-transfer-order/:number/id/:externalId/:extension?'
)

export const DEPRECATED_BOOKING_ROUTE = RoutePath('/booking/:id/:extension?')
export const BOOKING_ROUTE = RoutePath('/booking/:number/id/:externalId/:extension?')

export const DEPRECATED_BOL_ROUTE = RoutePath('/bill-of-lading/:id/:extension?')
export const BOL_ROUTE = RoutePath('/bill-of-lading/:number/id/:externalId/:extension?')

export const DEPRECATED_SHIPMENT_ROUTE = RoutePath('/shipment/:id/:extension?')
export const SHIPMENT_ROUTE = RoutePath('/shipment/:number/id/:externalId/:extension?')

export const DEPRECATED_CONTAINER_ROUTE = RoutePath('/container/:id/trip/:externalId?/:extension?')
export const CONTAINER_ROUTE = RoutePath('/container/:number/id/:externalId?/:extension?')
