import { ProfileInterface } from '../interfaces'
import humps from 'humps'

export default class Profile {
  constructor({
    acceptedTerms,
    email,
    firstName,
    lastName,
    location,
    team,
    tenant,
    title,
  }: ProfileInterface) {
    Object.assign(this, {
      acceptedTerms,
      email,
      firstName,
      lastName,
      location,
      team,
      tenant,
      title,
    })
  }

  static of(payload: ProfileInterface) {
    const {
      acceptedTerms,
      email,
      firstName,
      lastName,
      location,
      team,
      tenant,
      title,
    } = humps.camelizeKeys(payload) as ProfileInterface

    return new Profile({
      acceptedTerms,
      email,
      firstName,
      lastName,
      location,
      team,
      tenant,
      title,
    })
  }
}
