import tinycolor from 'tinycolor2'

// Theme is passed from material-ui withStyles
export default theme => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
    marginBottom: theme.spacing(0.25),
  },
  paneTitle: {
    letterSpacing: 0.2,
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: theme.spacing(1),
  },
  saveBtnWrapper: {
    height: 65,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  saveButton: {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.brand.color}`,
    margin: '0 auto',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.brand.color,
      color: theme.palette.common.white,
    },
  },
  disabledSaveButton: {
    borderColor: theme.palette.grey[200],
  },
  saveButtonText: {
    color: theme.palette.common.white,
  },
  disabledSaveButtonText: {
    color: tinycolor(theme.palette.common.lightBlack).lighten(5),
  },
  warning: {
    color: 'red',
    marginTop: theme.spacing(2),
  },
  save: {
    color: theme.brand.color,
  },
  disabledClearFiltersBtn: {
    display: 'none',
  },
  clearFiltersBtn: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0.5,
    margin: '10px 0',
    paddingRight: 20,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  clearIcon: {
    color: theme.palette.grey[500],
    fontSize: 16,
    marginLeft: theme.spacing(1),
  },
})
