import React, { useEffect } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { fetchShares, requestDeleteShare } from 'store/share/actions'
import { useDispatch, useSelector } from 'react-redux'

import CenteredLayout from 'components/layouts/CenteredLayout'
import ErrorLayout from 'components/layouts/ErrorLayout'
import Loader from 'components/Loader'
import MESSAGES from 'store/notifications/messages'
import Paper from '@material-ui/core/Paper'
import ShareListItem from './ShareListItem'
import { getRefTypeLabel } from 'utils/rollups'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import moment from 'moment'
import partition from 'lodash/partition'
import reverse from 'lodash/reverse'
import { sharesSelector } from 'store/share'
import { showNotification } from 'store/notifications'
import sortBy from 'lodash/sortBy'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: '#eff3f6',
  },
  main: {
    padding: theme.spacing(4),
    overflow: 'scroll',
    flex: 1,
  },
  mainContent: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryTitle: {
    ...theme.mixins.gutters(),
    fontSize: 18,
    color: '#66717e',
  },
  group: {
    marginBottom: theme.spacing(6),
  },
  groupLabel: {
    marginBottom: theme.spacing(3),
    color: '#66717e',
  },
  errorHeader: {
    color: theme.palette.blue[800],
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
  },
  errorHeaderText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  errorBody: {
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
}))

export default function SharedLinksPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const shares = useSelector(sharesSelector)

  useEffect(() => {
    dispatch(fetchShares())
  }, [dispatch])

  if (shares.isLoading) {
    return (
      <CenteredLayout>
        <Loader />
      </CenteredLayout>
    )
  } else if (shares.isFailed) {
    return (
      <>
        <ErrorLayout
          classes={{ body: classes.errorBody }}
          showDefaultFooter={false}
          headerText={
            <Typography variant="h5" color="primary" className={classes.errorHeaderText}>
              Sorry, something went wrong.
            </Typography>
          }
          body={
            <>
              <Typography variant="subtitle1" className={classes.errorHeader}>
                Why did this happen?
              </Typography>
              <Typography variant="body1">
                There was a connection or server issue.
                <br />
                Please refresh the page or try again later.
              </Typography>
            </>
          }
        />
      </>
    )
  }

  const [activeShares, inactiveShares] = partition(shares.data, s => s.active)

  const handleRevoke = item => {
    const refLabel = getRefTypeLabel(item.refType)
    let shareName = `${refLabel} #${item.refNumbers[0]}`
    if (item.name) {
      shareName = item.name
    } else if (item.refNumbers.length === 0) {
      shareName = 'Empty Group'
    } else if (item.refNumbers.length !== 1) {
      shareName = 'Unnamed Group'
    }

    dispatch(requestDeleteShare({ guid: item.guid, name: shareName }))
  }

  const groupByDate = group =>
    groupBy(reverse(sortBy(group, item => item.sharedAt)), item =>
      moment(item.sharedAt).format('D MMM YYYY')
    )

  const renderItemGroup = group => {
    if (isEmpty(group)) {
      return <Typography variant="subtitle1">No shared items to show.</Typography>
    }

    return map(group, (items, date) => (
      <div className={classes.group} key={`group-${date}`}>
        <Typography variant="body2" className={classes.groupLabel}>
          Shared on {date}
        </Typography>

        {items.map(item => (
          <ShareListItem
            item={item}
            key={item.guid}
            onRevoke={() => handleRevoke(item)}
            onCopiedLink={() => dispatch(showNotification(MESSAGES.sharedLinkCopy))}
          />
        ))}
      </div>
    ))
  }

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.header}>
          <Typography className={classes.summaryTitle} variant="h5">
            Live Statuses Shared by You
          </Typography>
        </div>
        <Paper className={classes.mainContent} elevation={2}>
          {renderItemGroup(groupByDate(activeShares))}
        </Paper>

        <div className={classes.header}>
          <Typography className={classes.summaryTitle} variant="h5">
            Previously Shared Statuses
          </Typography>
        </div>
        <Paper className={classes.mainContent} elevation={2}>
          {renderItemGroup(groupByDate(inactiveShares))}
        </Paper>
      </div>
    </div>
  )
}
