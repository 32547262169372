import { clearView, toggleImportDialog } from 'store/boardUtils/actions'
import { filtersSelector, getDefaultAndCustomizedFilters } from 'store/boardUtils/selectors'

import FilterPane from 'components/Filtering/FilterPane'
import boardUtilsReducer from 'store/boardUtils'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentFilterSelector as currentDefaultFilterSelector } from 'store/defaultFilters'
import { customizationSelector } from 'store/auth/user/selectors'
import { fromQueryString } from 'utils/urlBuilder'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
  return {
    isMenuOpen: state.boardUtils.isMenuOpen,
    filters: filtersSelector(state),
    activeFilter: state.boardUtils.activeFilter,
    filterGroups: state.boardUtils.filterGroups,
    filterConfigs: getDefaultAndCustomizedFilters(state),
    isLoadingFilterViews: state.boardUtils.isLoading,
    isSavingFilterView: state.boardUtils.isSaving,
    activeDefaultFilter: currentDefaultFilterSelector(state),
    urlQuery: fromQueryString(ownProps.location.search),
    customization: customizationSelector(state),
  }
}

const dispatchToProps = {
  toggleImportDialog,
  setActiveFilter: boardUtilsReducer.actions.setActiveFilter,
  unsetActiveFilter: boardUtilsReducer.actions.unsetActiveFilter,
  addFilter: boardUtilsReducer.actions.addFilter,
  removeFilter: boardUtilsReducer.actions.removeFilter,
  setFiltersFromURL: boardUtilsReducer.actions.setFiltersFromURL,
  clearFilters: clearView,
}

export default compose(withRouter, connect(mapStateToProps, dispatchToProps))(FilterPane)
