import * as Sentry from '@sentry/browser'

import { Typography, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import PropTypes from 'prop-types'
import React from 'react'
import RefreshIcon from '@material-ui/icons/Refresh'

const styles = theme => ({
  container: {
    marginTop: 24,
    width: '100%',
  },
  center: {
    textAlign: 'center',
  },
  error: {
    color: theme.palette.grey[500],
    marginBottom: 24,
  },
  button: {
    border: `1px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[500],
  },
})

class ErrorBoundary extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    variant: PropTypes.string,
  }

  static defaultProps = {
    variant: 'body2',
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  constructor(props) {
    super(props)
    this.state = { eventId: null }
  }

  reloadPage() {
    window.location.reload()
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { classes, variant } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <div className={classes.container}>
          <div className={classes.center}>
            <Typography variant={variant} className={classes.error}>
              <div>We've encountered a problem.</div>
              <div>Try reloading the page.</div>
            </Typography>
            <Button
              onClick={this.reloadPage}
              startIcon={<RefreshIcon />}
              className={classes.button}
            >
              Reload Page
            </Button>
          </div>
        </div>
      )
    }
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

export default withStyles(styles)(ErrorBoundary)
