import {
  CUSTOM_DATE_RANGE_ID,
  DATE_FORMAT,
  DATE_RANGES,
  START_OF_TIME,
  getDateRangeIndexById,
  getDateRangeObject,
} from 'store/planning/utils/DateRange'
import { Divider, withStyles } from '@material-ui/core'
import { FILTER_GROUPS_KEY, filterGroupActions } from 'store/filterGroups/actions'
import React, { useEffect, useState } from 'react'

import CarrierAutocomplete from './CarrierAutocomplete'
import DateRange from 'components/DateRangePicker'
import Drawer from 'components/core/Drawer'
import DrawerFooter from 'components/DrawerFooter'
import Dropdown from 'components/Dropdown'
import LocationAutocomplete from './LocationAutocomplete'
import PropTypes from 'prop-types'
import { RouteFilterGroupType } from 'store/planning/utils/routeFilterGroup'
import ViewActions from 'components/ViewList/ViewActions'
import ViewList from 'components/ViewList'
import { connect } from 'react-redux'
import cx from 'classnames'
import { drawersSelector } from 'store/drawers'
import { filterGroupSelectors } from 'store/filterGroups'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { toTitleCase } from 'utils/strUtils'
import { toggleDrawer } from 'store/drawers/actions'

const FILTER_CONTAINER_HEIGHT = '60vh'

export const FilterOptionsEnum = {
  ORIGIN: 'origin',
  DESTINATION: 'destination',
  TRANSSHIPMENT: 'transshipment',
  OPERATOR: 'operator',
}

const styles = theme => ({
  filterSpacing: {
    marginBottom: theme.spacing(2),
    marginLeft: 4,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  dateChooser: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  dateChooserContainer: {
    width: '100%',
  },
  dateChooserButton: {
    background: theme.palette.white,
    width: '100%',
  },
  locationChoosers: {
    marginBottom: theme.spacing(2),
  },
  filtersContainer: {
    marginRight: 6,
    height: FILTER_CONTAINER_HEIGHT,
    overflowY: 'auto',
  },
})

export function FilterDrawer({
  classes,
  currentFilterGroup,
  dateRange,
  dispatch,
  drawerKey,
  filterKey,
  isOpen,
  savedFilterGroups,
  tracker,
  availableFilterOptions,
}) {
  const [showCustomDateSelector, setShowCustomDateSelector] = useState(false)
  const actions = filterGroupActions[FILTER_GROUPS_KEY][filterKey]

  useEffect(() => {
    dispatch(actions.fetchFilterGroupsStart())
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO(nicole): remove legacy keys
  const filterConfigs = {
    DESTINATION: {
      filterKey: 'route_destination_zone',
      countryFilterKey: 'route_destination_country',
      legacyFilterKey: 'route_destination',
      label: 'To:',
      transformChipFn: toTitleCase,
      orGroup: 'destination',
    },
    ORIGIN: {
      filterKey: 'route_origin_zone',
      countryFilterKey: 'route_origin_country',
      legacyFilterKey: 'route_origin',
      label: 'From:',
      transformChipFn: toTitleCase,
      orGroup: 'origin',
    },
    OPERATOR: {
      filterKey: 'route_operator',
      label: 'By Carrier:',
    },
    TRANSSHIPMENT: {
      filterKey: 'route_transshipment_zone',
      countryFilterKey: 'route_transshipment_country',
      legacyFilterKey: 'route_transshipment',
      label: 'Via:',
      transformChipFn: toTitleCase,
      orGroup: 'transshipment',
    },
  }

  const handleLocationsSelect = filterConfig => selectedLocs => {
    let countries = []
    let zones = []

    selectedLocs.forEach(loc => {
      if (get(loc, 'data.type') === 'country') {
        countries.push({ key: loc.data.country_code, label: loc.label, data: loc.data })
      } else if (get(loc, 'data.type') === 'zone') {
        zones.push(loc)
      }
    })

    if (selectedLocs.length > 0) {
      if (countries.length > 0)
        dispatch(
          actions.applyFilter('in', filterConfig.countryFilterKey, countries, filterConfig.orGroup)
        )
      else dispatch(actions.removeFilter(filterConfig.countryFilterKey))

      if (zones.length > 0)
        dispatch(actions.applyFilter('in', filterConfig.filterKey, zones, filterConfig.orGroup))
      else dispatch(actions.removeFilter(filterConfig.filterKey))
      tracker.userSelectsFilter(filterConfig.label, selectedLocs.length > 1)
    } else {
      dispatch(actions.removeFilter(filterConfig.countryFilterKey))
      dispatch(actions.removeFilter(filterConfig.filterKey))
    }
  }

  const handleCarriersSelect = filterConfig => selectedCarriers => {
    const filterKey = filterConfig.filterKey

    // this is for the backward compatibility
    const formattedSelectedCarriers = selectedCarriers.map(c => {
      const scac = get(c, 'data.scac_code')
      if (scac) {
        // A newly-selected carrier.
        return { key: scac, label: c.label }
      }
      // A carrier that was already in the store.
      return c
    })

    if (selectedCarriers.length > 0) {
      dispatch(actions.applyFilter('in', filterKey, formattedSelectedCarriers))

      tracker.userSelectsFilter(filterConfig.label, formattedSelectedCarriers.length > 1)
    } else {
      dispatch(actions.removeFilter(filterKey))
    }
  }

  // Saves an edited view. See src/pages/BoardPage/Drawers/BoardDrawer/index.js for example
  const handleUpdateFilterGroupName = (view, newName) => {
    view.name = newName
    dispatch(actions.updateFilterGroupName(view))
  }

  // extract filters to initialize inputs
  const currentFilters = get(currentFilterGroup, 'params.filters', [])

  const getFilters = (filters, keys) => {
    return flatten(
      (filters || [])
        .filter(fieldFilter => keys.includes(fieldFilter.key))
        .map(filter => filter.val)
    )
  }
  const destLocationFilters = getFilters(currentFilters, [
    filterConfigs.DESTINATION.filterKey,
    filterConfigs.DESTINATION.legacyFilterKey,
    filterConfigs.DESTINATION.countryFilterKey,
  ])
  const originLocationFilters = getFilters(currentFilters, [
    filterConfigs.ORIGIN.filterKey,
    filterConfigs.ORIGIN.legacyFilterKey,
    filterConfigs.ORIGIN.countryFilterKey,
  ])
  const waypointLocationFilters = getFilters(currentFilters, [
    filterConfigs.TRANSSHIPMENT.filterKey,
    filterConfigs.TRANSSHIPMENT.legacyFilterKey,
    filterConfigs.TRANSSHIPMENT.countryFilterKey,
  ])
  const carrierFilters = getFilters(currentFilters, [filterConfigs.OPERATOR.filterKey])

  const maybeUpdateDateRange = newRange => {
    if (!isEqual(newRange, dateRange)) {
      dispatch(actions.updateDateRange(newRange))
    }
  }

  const maybeUpdateCurrentFilterGroup = selectedFilterGroup => {
    if (currentFilterGroup.id !== selectedFilterGroup.id) {
      dispatch(actions.selectFilterGroup(selectedFilterGroup))
      tracker.userSelectsFilterGroup()
    }
  }

  // We need the array index, not the `id` for the dropdown. If the custom date picker is showing
  // we're updating the index explicitly because we don't update the store selected date range
  // until/unless they actually pick a date. This allows Clear Filters to work correctly if they
  // clear before ever selecting any custom dates. Questionable approach but "custom" is an outlier.
  const dateRangeIndex = showCustomDateSelector
    ? getDateRangeIndexById(CUSTOM_DATE_RANGE_ID)
    : getDateRangeIndexById(dateRange.id)

  return (
    <>
      <Drawer isOpen={isOpen} onToggleDrawer={() => dispatch(toggleDrawer(drawerKey, !isOpen))}>
        <ViewList
          drawerKey={drawerKey}
          views={savedFilterGroups}
          selectView={maybeUpdateCurrentFilterGroup}
          deleteView={(id, name) => {
            dispatch(actions.deleteFilterGroup(id, name))
          }}
          currentView={currentFilterGroup}
          handleAlertClick={() => {}}
          handleUpdateView={handleUpdateFilterGroupName}
        />
        <Divider variant="middle" className={classes.divider} />
        <div className={cx(classes.dateChooser, classes.filterSpacing)}>
          <Dropdown
            items={DATE_RANGES}
            defaultIdx={dateRangeIndex}
            labelKey={'label'}
            handleSelection={selected => {
              if (selected.id === CUSTOM_DATE_RANGE_ID) {
                setShowCustomDateSelector(true)
              } else {
                const dateRangeObject = getDateRangeObject({
                  id: selected.id,
                  startDate: null,
                  endDate: null,
                })
                tracker.userSelectsFilter('Preset Timeframe')
                setShowCustomDateSelector(false)
                maybeUpdateDateRange(dateRangeObject)
              }
            }}
            classes={{
              container: classes.dateChooserContainer,
              button: classes.dateChooserButton,
            }}
            data-testid="planning-drawer__date-chooser"
          />
          {(showCustomDateSelector || dateRange.id === CUSTOM_DATE_RANGE_ID) && (
            <DateRange
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  const dateRangeObject = getDateRangeObject({
                    id: CUSTOM_DATE_RANGE_ID,
                    startDate: startDate.format(DATE_FORMAT),
                    endDate: endDate.format(DATE_FORMAT),
                  })
                  tracker.userSelectsFilter('Custom Timeframe')
                  maybeUpdateDateRange(dateRangeObject)
                }
              }}
              minDate={moment(START_OF_TIME)}
              maxDate={moment().endOf('day')}
              initialStartDate={dateRange.startDate ? moment(dateRange.startDate) : null}
              initialEndDate={dateRange.endDate ? moment(dateRange.endDate) : null}
            />
          )}
        </div>
        <Divider variant="middle" className={classes.divider} />
        <div className={classes.filtersContainer}>
          <div className={classes.locationChoosers}>
            {availableFilterOptions.includes(FilterOptionsEnum.ORIGIN) && (
              <LocationAutocomplete
                className={classes.filterSpacing}
                placeholder="Input countries or ports or Unlocodes"
                title={filterConfigs.ORIGIN.label}
                handleSelect={handleLocationsSelect(filterConfigs.ORIGIN)}
                lookupPath="/locations_aggregated/suggest"
                defaultValues={originLocationFilters}
                transformChipFn={filterConfigs.ORIGIN.transformChipFn}
              />
            )}
            {availableFilterOptions.includes(FilterOptionsEnum.DESTINATION) && (
              <LocationAutocomplete
                className={classes.filterSpacing}
                placeholder="Input countries or ports or Unlocodes"
                title={filterConfigs.DESTINATION.label}
                handleSelect={handleLocationsSelect(filterConfigs.DESTINATION)}
                lookupPath="/locations_aggregated/suggest"
                defaultValues={destLocationFilters}
                transformChipFn={filterConfigs.DESTINATION.transformChipFn}
              />
            )}
            {availableFilterOptions.includes(FilterOptionsEnum.TRANSSHIPMENT) && (
              <LocationAutocomplete
                className={classes.filterSpacing}
                placeholder="Input countries or ports or Unlocodes"
                title={filterConfigs.TRANSSHIPMENT.label}
                handleSelect={handleLocationsSelect(filterConfigs.TRANSSHIPMENT)}
                lookupPath="/locations_aggregated/suggest"
                data-testid="waypoint_location_input"
                defaultValues={waypointLocationFilters}
                transformChipFn={filterConfigs.TRANSSHIPMENT.transformChipFn}
              />
            )}
          </div>
          {availableFilterOptions.includes(FilterOptionsEnum.OPERATOR) && (
            <>
              <Divider variant="middle" className={classes.divider} />
              <CarrierAutocomplete
                className={classes.filterSpacing}
                placeholder="Input carrier names"
                title={filterConfigs.OPERATOR.label}
                handleSelect={handleCarriersSelect(filterConfigs.OPERATOR)}
                lookupPath="/carriers/suggest"
                suggestType="ocean_carrier"
                data-testid="carrier_input"
                defaultValues={carrierFilters}
                transformChipFn={filterConfigs.OPERATOR.transformChipFn}
              />
            </>
          )}
        </div>
        {/* TODO: totalCount and isAlertSupported might be able to be
            better handled with some more refactoring of the ViewActions components
            it is used only to warn about a filter that returns too many results
            which (currently) doesn't apply to planning */}
        <DrawerFooter>
          <ViewActions
            drawerKey={drawerKey}
            clearFilters={filterGroup => {
              dispatch(actions.clearFilterGroup(filterGroup))
              setShowCustomDateSelector(false)
              tracker.userClearsFilterGroup()
            }}
            createView={filterGroup => {
              dispatch(actions.createFilterGroup(filterGroup))
              tracker.userCreatesFilterGroup()
            }}
            currentView={currentFilterGroup}
            filters={savedFilterGroups}
            isAlertSupported={false}
            totalCount={0}
            updateView={filterGroup => {
              dispatch(actions.updateFilterGroup(filterGroup))
              tracker.userUpdatesFilterGroup()
            }}
          />
        </DrawerFooter>
      </Drawer>
    </>
  )
}

FilterDrawer.propTypes = {
  classes: PropTypes.object,
  dateRange: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  savedFilterGroups: PropTypes.arrayOf(RouteFilterGroupType),
  drawerKey: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  availableFilterOptions: PropTypes.arrayOf(PropTypes.string),
}

FilterDrawer.defaultProps = {
  savedFilterGroups: [],
  currentFilterGroup: {},
  availableFilterOptions: Object.values(FilterOptionsEnum),
}

const mapStateToProps = (state, ownProps) => {
  const selectors = filterGroupSelectors[ownProps.filterKey]
  return {
    isOpen: drawersSelector(state)[ownProps.drawerKey],
    currentFilterGroup: selectors.currentFilterGroupSelector(state),
    savedFilterGroups: selectors.savedFilterGroupsSelector(state),
    dateRange: selectors.dateRangeSelector(state),
  }
}

export default connect(mapStateToProps)(withStyles(styles)(FilterDrawer))
