import { SvgIcon, SvgIconProps, Theme, makeStyles } from '@material-ui/core'

import React from 'react'

// Default color, most likely to be used this way most or all the time, easy to override.
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.green[425],
  },
}))

const ProgressPartialCompleteIcon = (props: SvgIconProps) => {
  const classes = useStyles(props)
  return (
    <SvgIcon classes={{ root: classes.root }} {...props}>
      <g fillRule="evenodd" transform="translate(2 2)">
        <path
          fillRule="nonzero"
          d="M8.92841697,15 C8.72004637,15 8.52022632,14.9050947 8.3729083,14.7363012 L5.23000209,11.1363215 C5.03153796,10.9088339 4.95410377,10.5773493 5.02686824,10.2667347 C5.09963271,9.95612015 5.31154117,9.71356533 5.58276977,9.63043832 C5.85399837,9.54731132 6.14334103,9.6362411 6.34180516,9.86372868 L8.92841697,12.826512 L14.657935,6.26374898 C14.9649509,5.91208367 15.4627221,5.91208367 15.769738,6.263749 C16.076754,6.61541432 16.076754,7.18557646 15.7697381,7.5372418 L9.48392565,14.7372012 C9.33647908,14.9056647 9.13667486,15.0001875 8.92841697,15 L8.92841697,15 Z"
        />
        <path
          fillRule="nonzero"
          d="M9.88339574,1.99327227 C9.38604756,1.93550716 9,1.51283584 9,1 C9,0.44771525 9.4477238,0 10.0000191,0 L10.0000191,0 L9.9900189,0 C15.5201245,0 20.00021,4.48 20.00021,10 C20.00021,15.4264407 15.6707047,19.8478368 10.2702891,19.9961558 L10.0000191,20 L9.88339574,19.9932723 C9.38604756,19.9355072 9,19.5128358 9,19 C9,18.4477153 9.4477238,18 10.0000191,18 L10.0000191,18 C14.4201035,18 18.0001718,14.42 18.0001718,10 C18.0001718,5.58 14.4201035,2 10.0000191,2 Z"
        />
        <circle cx="1" cy="10" r="1" />
        <circle cx="2" cy="5" r="1" />
        <circle cx="5" cy="2" r="1" />
        <circle cx="2" cy="15" r="1" />
        <circle cx="5" cy="18" r="1" />
      </g>
    </SvgIcon>
  )
}

export default ProgressPartialCompleteIcon
