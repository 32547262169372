import { Paper, withStyles } from '@material-ui/core'

import React from 'react'

export const BORDER_RADIUS = 5
const styles = (theme: any) => ({
  root: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: BORDER_RADIUS,
    boxShadow: `0 1px 2px 0 ${theme.palette.grey[950]}`,
  },
})

const CMPaper = (props: any) => (
  <Paper {...props} elevation={2}>
    {props.children}
  </Paper>
)

export default withStyles(styles)(CMPaper)
