import {
  ROLLUP_BOLS,
  ROLLUP_BOOKINGS,
  ROLLUP_CONTAINER,
  ROLLUP_LINE_ITEM,
  ROLLUP_PURCHASE_ORDERS,
  ROLLUP_SALES_ORDERS,
  ROLLUP_SHIPMENT,
  ROLLUP_STOCK_TRANSFER_ORDERS,
} from 'utils/rollups'

export const ENTITY_TYPE_ORDER = 'orders'

const ApiRefType = {
  [ENTITY_TYPE_ORDER]: 'orders',
  [ROLLUP_BOLS]: 'bols',
  [ROLLUP_BOOKINGS]: 'bookings',
  /**
   * Note that currently the `associated_references` endpoint wants `trips` here, which is a problem because
   * we don't use `trips` anywhere else. For now the choice was made to have this be what the components need
   * and translate it in the store. All of this will be going away when we replace `refType` with `entityType`
   * and standardize all of the entity types and names with the backend.
   */
  [ROLLUP_CONTAINER]: 'container_number',
  [ROLLUP_LINE_ITEM]: 'line_items',
  [ROLLUP_PURCHASE_ORDERS]: 'orders',
  [ROLLUP_SALES_ORDERS]: 'orders',
  [ROLLUP_SHIPMENT]: 'shipments',
  [ROLLUP_STOCK_TRANSFER_ORDERS]: 'orders',
} as const

export type ApiRefTypeType = typeof ApiRefType[keyof typeof ApiRefType]

export default ApiRefType
