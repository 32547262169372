import Equipment, { EquipmentInterface } from 'store/models/Equipment'
import Exception, { ExceptionInterface } from 'store/models/Exception'
import LocationZone, { LocationZoneInterface } from 'store/models/LocationZone'
import MilestoneAction, { MilestoneActionType } from '../definitions/MilestoneAction'
import MilestoneStatus, { MilestoneStatusInterface } from 'store/models/MilestoneStatus'
import Segment, { SegmentInterface } from 'store/models/Segment'

import { LocationInterface } from 'store/models/Location'
import { TripInterface } from 'store/models/Trip'
import { getDateDiff } from 'utils/dateUtils'
import { getDateFromUtcString } from 'utils/dateUtils'
import humps from 'humps'

const DATE_FORMAT = 'D MMM YYYY HH:mm'

export interface MilestoneInterface {
  action: MilestoneActionType
  actualDelay: number | null
  actualTrips: TripInterface[] | []
  baselinePlannedTime: string | null
  equipment: EquipmentInterface | EquipmentInterface[]
  exceptions: ExceptionInterface[]
  location: LocationInterface | null
  locationZone: LocationZoneInterface | null
  onEquipment: EquipmentInterface | null
  plannedTime: string | null
  plannedDelay: number | null
  predictedDelay: number | null
  predictedTime: string | null
  stopSegment: SegmentInterface
  // optional while we wait for the API to add this property
  statuses: MilestoneStatusInterface[]
  tracedTime: string | null
}

export interface Props {
  action: MilestoneActionType
  actualTrips: TripInterface[] | []
  baselinePlannedTime: string | null
  equipment: EquipmentInterface | EquipmentInterface[]
  exceptions: ExceptionInterface[]
  location: LocationInterface | null
  locationZone: LocationZoneInterface | null
  onEquipment: EquipmentInterface | null
  plannedTime: string | null
  predictedTime: string | null
  stopSegment: SegmentInterface
  // optional while we wait for the API to add this property
  statuses: MilestoneStatusInterface[]
  tracedTime: string | null
}

export default class Milestone {
  constructor({
    action,
    actualDelay,
    actualTrips,
    baselinePlannedTime,
    equipment,
    exceptions,
    location,
    locationZone,
    onEquipment,
    plannedDelay,
    plannedTime,
    predictedDelay,
    predictedTime,
    stopSegment,
    statuses,
    tracedTime,
  }: MilestoneInterface) {
    const uiData = {
      action,
      actualDelay,
      actualTrips,
      baselinePlannedTime,
      equipment,
      exceptions,
      location,
      locationZone,
      onEquipment,
      plannedDelay,
      predictedDelay,
      plannedTime,
      predictedTime,
      stopSegment,
      statuses,
      tracedTime,
    } as MilestoneInterface

    Object.assign(this, uiData)
  }

  static of(props: Props) {
    const {
      action,
      actualTrips,
      baselinePlannedTime,
      equipment,
      exceptions,
      location,
      locationZone,
      onEquipment,
      plannedTime,
      predictedTime,
      stopSegment,
      statuses,
      tracedTime,
    } = humps.camelizeKeys(props) as Props

    const actualDelay =
      tracedTime && baselinePlannedTime
        ? getDateDiff(tracedTime, baselinePlannedTime, 'days')
        : null

    const predictedDelay =
      predictedTime && baselinePlannedTime
        ? getDateDiff(predictedTime, baselinePlannedTime, 'days')
        : null

    const plannedDelay =
      plannedTime && baselinePlannedTime
        ? getDateDiff(plannedTime, baselinePlannedTime, 'days')
        : null

    return new Milestone({
      action: action && MilestoneAction[action],
      actualDelay,
      actualTrips: actualTrips ?? [],
      baselinePlannedTime:
        baselinePlannedTime &&
        getDateFromUtcString({
          dateString: baselinePlannedTime,
          format: DATE_FORMAT,
          timezone: locationZone?.timezone,
        }),
      // We now get `equipment` as both a single object and an array of objects, should be temporary.
      equipment:
        equipment && Array.isArray(equipment)
          ? equipment.map((e: EquipmentInterface) => Equipment.of(e) as EquipmentInterface)
          : (Equipment.of(equipment) as EquipmentInterface),
      exceptions:
        exceptions &&
        exceptions.map(
          (exception: ExceptionInterface) => Exception.of(exception) as ExceptionInterface
        ),
      location,
      locationZone: locationZone && (LocationZone.of(locationZone) as LocationZoneInterface),
      onEquipment: onEquipment && (Equipment.of(onEquipment) as EquipmentInterface),
      plannedDelay,
      plannedTime:
        plannedTime &&
        getDateFromUtcString({
          dateString: plannedTime,
          format: DATE_FORMAT,
          timezone: locationZone?.timezone,
        }),
      predictedDelay,
      predictedTime:
        predictedTime &&
        getDateFromUtcString({
          dateString: predictedTime,
          format: DATE_FORMAT,
          timezone: locationZone?.timezone,
        }),
      stopSegment: stopSegment && (Segment.of(stopSegment) as SegmentInterface),
      statuses:
        statuses &&
        statuses.map(
          (status: MilestoneStatusInterface) =>
            MilestoneStatus.of(status) as MilestoneStatusInterface
        ),
      tracedTime:
        tracedTime &&
        getDateFromUtcString({
          dateString: tracedTime,
          format: DATE_FORMAT,
          timezone: locationZone?.timezone,
        }),
    })
  }
}
