import {
  EquipmentCategory,
  FacilityType,
  MilestoneStatus,
  TripContext,
  TripStage,
} from 'store/board/sagas/models'

import MilestoneAction from 'store/models/definitions/MilestoneAction'
import getFacilityTypeText from './getFacilityTypeText'
import getVehicleTypeText from './getVehicleTypeText'

/**
 * Returns the full milestone display name.
 * @returns {string}
 */
const getFullMilestoneName = (
  stage,
  action,
  vehicleCategory,
  facilityType,
  status = null,
  context = null
) => {
  // Status milestones directly map to a specific string
  if (status !== null) {
    switch (status) {
      case MilestoneStatus.AT_DELIVERY_LOCATION:
        return 'At final delivery location'
      case MilestoneStatus.AVAILABLE:
        return 'Available for delivery'
      case MilestoneStatus.OUT_FOR_DELIVERY:
        return 'Out for delivery'
      case MilestoneStatus.EMPTY_DISPATCH:
        return 'Empty equipment dispatch'
      case MilestoneStatus.EMPTY_RETURN:
        return 'Empty equipment return'
      default:
    }
  }
  // the action depends on the vehicleCategory -> equipment discharge from vessels but unload from trucks and trains
  let actionText
  let prepositionText
  switch (action) {
    case MilestoneAction.load:
      actionText = 'Load'
      prepositionText = 'on'
      break
    case MilestoneAction.unload:
      actionText = vehicleCategory === EquipmentCategory.VESSEL ? 'Discharge' : 'Unload'
      prepositionText = 'from'
      break
    case MilestoneAction.arrive:
      actionText = 'Arrive'
      prepositionText = 'on'
      break
    case MilestoneAction.depart:
      actionText = 'Depart'
      prepositionText = 'on'
      break
    default:
  }
  // location function depends on the stage and facility type
  let functionText
  switch (stage) {
    case TripStage.PRE_MAIN:
      functionText = 'origin'
      break
    case TripStage.MAIN:
      switch (facilityType?.toLowerCase()) {
        case FacilityType.DOOR:
          functionText = 'transload'
          break
        case FacilityType.RAIL_YARD:
          functionText = 'interchange'
          break
        case FacilityType.PORT:
          functionText = 'transshipment'
          break
        default:
          // this shouldn't happen
          functionText = ''
      }
      break
    case TripStage.POST_MAIN:
      functionText = 'destination'
      break
    default:
      functionText = ''
  }

  let contextText = null
  if (context === TripContext.EXPORT) {
    contextText = 'export'
  } else if (context === TripContext.IMPORT) {
    contextText = 'import'
  }

  // only add if `milestone.onEquipment` is not `null` and we have an `action`
  const equipmentCategory = getVehicleTypeText(vehicleCategory)
  const categoryText = equipmentCategory ? `${prepositionText} ${equipmentCategory}` : ''
  let milestoneText = actionText ? `${actionText} ${categoryText}` : ''

  // facility type may be unknown
  if (facilityType) {
    milestoneText += ` at ${functionText} ${getFacilityTypeText(facilityType)}`
  }
  // context is optional
  if (contextText) {
    milestoneText += ` for ${contextText}`
  }
  return milestoneText
}

export default getFullMilestoneName
