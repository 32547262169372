import { IconButton, Tooltip, withStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import SettingsDialog from '../SettingsDialog'
import SettingsIcon from 'components/icons/SettingsIcon'

const styles = theme => ({
  manageAlertButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 8,
    top: -13,
  },
})

class SettingsMenu extends React.Component {
  state = {
    showDialog: false,
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  handleClose = () => {
    this.setState({
      showDialog: false,
    })
  }

  render() {
    const { classes } = this.props
    const { showDialog } = this.state

    return (
      <span className={classes.manageAlertButton}>
        <Tooltip title="Alert Settings" placement="bottom">
          <span>
            <IconButton
              onClick={e => {
                this.setState({ showDialog: true })
              }}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <SettingsDialog open={showDialog} onClose={this.handleClose} />
      </span>
    )
  }
}

export default withStyles(styles)(SettingsMenu)
