import { createAction } from 'redux-actions'

export const SUGGEST_KEY = 'suggest'

export const types = {
  LOAD_CARRIERS_START: `${SUGGEST_KEY}/LOAD_CARRIERS_START`,
  LOAD_CARRIERS_SUCCESS: `${SUGGEST_KEY}/LOAD_CARRIERS_SUCCESS`,
  LOAD_SALES_ORDERS_START: `${SUGGEST_KEY}/LOAD_SALES_ORDERS_START`,
  LOAD_SALES_ORDERS_SUCCESS: `${SUGGEST_KEY}/LOAD_SALES_ORDERS_SUCCESS`,
  LOAD_PURCHASE_ORDERS_START: `${SUGGEST_KEY}/LOAD_PURCHASE_ORDERS_START`,
  LOAD_PURCHASE_ORDERS_SUCCESS: `${SUGGEST_KEY}/LOAD_PURCHASE_ORDERS_SUCCESS`,
  LOAD_STOCK_TRANSFER_ORDERS_START: `${SUGGEST_KEY}/LOAD_STOCK_TRANSFER_ORDERS_START`,
  LOAD_STOCK_TRANSFER_ORDERS_SUCCESS: `${SUGGEST_KEY}/LOAD_STOCK_TRANSFER_ORDERS_SUCCESS`,
  LOAD_BOLS_START: `${SUGGEST_KEY}/LOAD_BOLS_START`,
  LOAD_BOLS_SUCCESS: `${SUGGEST_KEY}/LOAD_BOLS_SUCCESS`,
  LOAD_BOOKINGS_START: `${SUGGEST_KEY}/LOAD_BOOKINGS_START`,
  LOAD_BOOKINGS_SUCCESS: `${SUGGEST_KEY}/LOAD_BOOKINGS_SUCCESS`,
  LOAD_LINE_ITEMS_START: `${SUGGEST_KEY}/LOAD_LINE_ITEMS_START`,
  LOAD_LINE_ITEMS_SUCCESS: `${SUGGEST_KEY}/LOAD_LINE_ITEMS_SUCCESS`,
  LOAD_CONTAINERS_START: `${SUGGEST_KEY}/LOAD_CONTAINERS_START`,
  LOAD_CONTAINERS_SUCCESS: `${SUGGEST_KEY}/LOAD_CONTAINERS_SUCCESS`,
  LOAD_SHIPMENTS_START: `${SUGGEST_KEY}/LOAD_SHIPMENTS_START`,
  LOAD_SHIPMENTS_SUCCESS: `${SUGGEST_KEY}/LOAD_SHIPMENTS_SUCCESS`,
  LOAD_EQUIPMENT_SUCCESS: `${SUGGEST_KEY}/LOAD_EQUIPMENT_SUCCESS`,
  LOAD_CUSTOM_SUGGEST_START: `${SUGGEST_KEY}/LOAD_CUSTOM_SUGGEST_START`,
  LOAD_CUSTOM_SUGGEST_SUCCESS: `${SUGGEST_KEY}/LOAD_CUSTOM_SUGGEST_SUCCESS`,
  SEARCH_CALL_START: `${SUGGEST_KEY}/SEARCH_CALL_START`,
  SEARCH_CALL_END: `${SUGGEST_KEY}/SEARCH_CALL_END`,
}
export const fetchCarriersSuggest = createAction(types.LOAD_CARRIERS_START)
export const fetchSalesOrdersSuggest = createAction(types.LOAD_SALES_ORDERS_START)
export const fetchPurchaseOrdersSuggest = createAction(types.LOAD_PURCHASE_ORDERS_START)
export const fetchStockTransferOrdersSuggest = createAction(types.LOAD_STOCK_TRANSFER_ORDERS_START)
export const fetchBolsSuggest = createAction(types.LOAD_BOLS_START)
export const fetchBookingsSuggest = createAction(types.LOAD_BOOKINGS_START)
export const fetchLineItemsSuggest = createAction(types.LOAD_LINE_ITEMS_START)
export const fetchContainersSuggest = createAction(types.LOAD_CONTAINERS_START)
export const fetchShipmentsSuggest = createAction(types.LOAD_SHIPMENTS_START)
export const fetchCustomSuggest = createAction(types.LOAD_CUSTOM_SUGGEST_START)
