import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  deleteDownload: ({ id }) => {
    return api._call('delete', '/download/manager', { downloadUuid: id })
  },
  fetchDownloads: () => {
    return api._call('get', '/download/manager')
  },
}

export default api
