import EquipmentActivity, {
  EquipmentActivityType,
} from 'store/models/definitions/EquipmentActivity'
import EquipmentCategory, {
  EquipmentCategoryType,
} from 'store/models/definitions/EquipmentCategory'
import Facility, { FacilityType } from 'store/models/definitions/Facility'

import MilestoneAction from 'store/models/definitions/MilestoneAction'
import TripStage from 'store/models/definitions/TripStage'
import { toTitleCase } from 'utils/strUtils'

export const TIMETYPE_ACTUAL = 'actual'
export const TIMETYPE_PREDICTION = 'prediction'

export interface Props {
  stage: string
  action: string
  equipmentActivity?: EquipmentActivityType
  equipmentCategory?: EquipmentCategoryType
  facilityType?: FacilityType
  type: 'actual' | 'prediction'
}

/**
 * Currently for Journey Details only. This function handles some specific rules that differ from
 * [`getFullMilestoneName()`](src/utils/shipmentUtils/getFullMilestoneName.js), so this will be called first
 * and if it returns `null` `getFullMilestoneName` will be called in the component. This is the order the rules
 * are applied in. I've left the return strings inline below because none of them are repeated and it seemed
 * easier to read the code.
 *
 * `arrive, vessel
 * `unload, vessel
 * `arrive, pre_main, truck, port
 * `arrive, post_main, stuffying_emptying
 * `arrive, truck
 * `depart, truck
 * `all other
 * @returns string | null
 * @see https://docs.google.com/presentation/d/157TDPHh6KE5vGjGh4nVbbx6rJocUyCSUyGQe_OhJcdw/edit?usp=sharing
 */
export const getTimelineMilestoneDisplayText = (props: Props): string | null => {
  const { type, stage, action, equipmentActivity, equipmentCategory, facilityType } = props

  if (!type) {
    return null
  }

  if (action === MilestoneAction.arrive && equipmentCategory === EquipmentCategory.vessel) {
    return type === TIMETYPE_ACTUAL ? 'Arrive' : 'Predicted Arrival'
  }
  if (action === MilestoneAction.unload && equipmentCategory === EquipmentCategory.vessel) {
    return type === TIMETYPE_ACTUAL ? 'Discharge' : 'Predicted Discharge'
  }
  if (
    action === MilestoneAction.arrive &&
    stage === TripStage.pre_main &&
    equipmentCategory === EquipmentCategory.truck &&
    facilityType === Facility.port
  ) {
    return type === TIMETYPE_ACTUAL ? 'Received' : null
  }
  if (
    action === MilestoneAction.arrive &&
    stage === TripStage.post_main &&
    equipmentActivity === EquipmentActivity.stuffing_emptying
  ) {
    return type === TIMETYPE_ACTUAL ? 'Delivered' : 'Predicted Delivery'
  }
  if (action === MilestoneAction.arrive && equipmentCategory === EquipmentCategory.truck) {
    return type === TIMETYPE_ACTUAL ? 'Drayage Arrive' : null
  }
  if (action === MilestoneAction.depart && equipmentCategory === EquipmentCategory.truck) {
    return type === TIMETYPE_ACTUAL ? 'Drayage Depart' : null
  }
  // otherwise return the `action` passed in
  return type === TIMETYPE_ACTUAL ? toTitleCase(action) : `Predicted ${action}`
}

export default getTimelineMilestoneDisplayText
