import validate from 'validate.js'

export function invalidInput(string) {
  return !/\S/.test(string)
}

export function getEmailValue(string) {
  // Add chip config for each split item
  const strings = string.split(/[ ,]+/)
  // And turn values into chips
  return strings.map(string => ({
    label: string,
    valid: validate({ from: string }, { from: { email: true } }) === undefined,
  }))
}

export function getValidEmailStrings(emailValue) {
  return emailValue.filter(v => v.valid).map(v => v.label)
}

export function isSubmittable(emailValue, filterValues) {
  const filterValueKeys = Object.keys(filterValues)
  const emails = getValidEmailStrings(emailValue)

  if (emails.length === 0 || filterValueKeys.length === 0) {
    return false
  } else if (filterValueKeys.some(key => filterValues[key].length > 0)) {
    return true
  } else {
    return false
  }
}

export function getFilterFields(filterValues, filterInputOptions) {
  function formatPayload(result, key) {
    const config = filterInputOptions.find(f => f.name === key)
    const value = filterValues[key]
    return [...result, { key, urlParams: config.filterData.getUrlParam(value), value }]
  }

  return Object.keys(filterValues).reduce(formatPayload, [])
}
