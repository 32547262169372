import { createAction } from 'redux-actions'
export const SHARE_REDUCER_KEY = 'share'

export const types = {
  CREATE_SHARE_START: `${SHARE_REDUCER_KEY}/CREATE_SHARE_START`,
  CREATE_SHARE_SUCCESS: `${SHARE_REDUCER_KEY}/CREATE_SHARE_SUCCESS`,
  CREATE_SHARE_ERROR: `${SHARE_REDUCER_KEY}/CREATE_SHARE_ERROR`,

  UPDATE_SHARE: `${SHARE_REDUCER_KEY}/UPDATE_SHARE`,
  DELETE_SHARE: `${SHARE_REDUCER_KEY}/DELETE_SHARE_START`,
  REQUEST_DELETE_SHARE: `${SHARE_REDUCER_KEY}/REQUEST_DELETE_SHARE`,

  FETCH_SHARES_START: `${SHARE_REDUCER_KEY}/FETCH_SHARES_START`,
  FETCH_SHARES_SUCCESS: `${SHARE_REDUCER_KEY}/FETCH_SHARES_SUCCESS`,
  FETCH_SHARES_ERROR: `${SHARE_REDUCER_KEY}/FETCH_SHARES_ERROR`,

  OPEN_SHARE_MODAL: `${SHARE_REDUCER_KEY}/OPEN_SHARE_MODAL`,
  CLOSE_SHARE_MODAL: `${SHARE_REDUCER_KEY}/CLOSE_SHARE_MODAL`,

  FETCH_ACTIVE_SHARE_START: `${SHARE_REDUCER_KEY}/FETCH_ACTIVE_SHARE_START`,
  FETCH_ACTIVE_SHARE_SUCCESS: `${SHARE_REDUCER_KEY}/FETCH_ACTIVE_SHARE_SUCCESS`,
  FETCH_ACTIVE_SHARE_ERROR: `${SHARE_REDUCER_KEY}/FETCH_ACTIVE_SHARE_ERROR`,

  CLEAR_ACTIVE_SHARE: `${SHARE_REDUCER_KEY}/CLEAR_ACTIVE_SHARE`,

  SEND_SHARE_EMAIL_START: `${SHARE_REDUCER_KEY}/SEND_SHARE_EMAIL_START`,
  SEND_SHARE_EMAIL_SUCCESS: `${SHARE_REDUCER_KEY}/SEND_SHARE_EMAIL_SUCCESS`,
  SEND_SHARE_EMAIL_ERROR: `${SHARE_REDUCER_KEY}/SEND_SHARE_EMAIL_ERROR`,
}

export const createShare = createAction(types.CREATE_SHARE_START)
export const updateShare = createAction(types.UPDATE_SHARE)
export const requestDeleteShare = createAction(types.REQUEST_DELETE_SHARE, ({ guid, name }) => ({
  guid,
  name,
}))
export const deleteShare = createAction(types.DELETE_SHARE, ({ guid, name }) => ({
  guid,
  name,
}))
export const fetchShares = createAction(types.FETCH_SHARES_START)
export const openShareModal = createAction(types.OPEN_SHARE_MODAL)
export const closeShareModal = createAction(types.CLOSE_SHARE_MODAL)
export const fetchActiveShare = createAction(types.FETCH_ACTIVE_SHARE_START)
export const clearActiveShare = createAction(types.CLEAR_ACTIVE_SHARE)
export const sendShareEmail = createAction(types.SEND_SHARE_EMAIL_START)
