import { TextField, Typography, withStyles } from '@material-ui/core'
import { inviteLoadingFromState, inviteResultFromState } from 'store/auth/invite/selectors'

import Button from 'components/core/Button'
import ListTenants from 'pages/Admin/AdminHomePage/ListTenants'
import { Loader } from 'components/Loader/Loader'
import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { inviteUsers } from 'store/auth/invite/actions'

const styles = theme => ({
  input: {
    marginBottom: theme.spacing(2),
    width: 500,
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.brand.color,
    color: 'white',
  },
  disabledBtn: {
    backgroundColor: 'rgb(200, 200, 200)',
  },
  tenantWarning: {
    color: 'orange',
  },
  tenantConfirmation: {
    color: 'red',
    fontWeight: 'bold',
  },
})

const InviteStatus = ({ successfulInvites, allEmails }) => {
  let failedInvites
  if (Array.isArray(successfulInvites) && successfulInvites.length > 0) {
    failedInvites = allEmails.filter(email => successfulInvites.indexOf(email) < 0)
  } else {
    failedInvites = allEmails // if there were no successes, then they all failed
  }
  return (
    <React.Fragment>
      {Array.isArray(successfulInvites) && successfulInvites.length > 0 ? (
        <Typography variant="body2">
          Successfully invited: {JSON.stringify(successfulInvites)}
        </Typography>
      ) : (
        <Typography variant="body2" />
      )}
      {Array.isArray(failedInvites) && failedInvites.length > 0 && (
        <Typography variant="body2" style={{ color: 'red' }}>
          Failed invites: {JSON.stringify(failedInvites)}
        </Typography>
      )}
    </React.Fragment>
  )
}

class InviteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tenant: props.tenant || '',
      rawEmails: '',
      parsedEmails: [],
      tenantConfirmation: '',
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { tenant, parsedEmails, tenantConfirmation } = this.state
    if (tenantConfirmation !== tenant) {
      alert('The tenant you entered manually does not match the tenant you selected')
    } else {
      this.props.inviteUsers(tenant, parsedEmails)
    }
  }

  handleTenantSelect = tenant => {
    this.setState({
      tenant,
    })
  }

  isValidEmail = email => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
    return re.test(String(email).toLowerCase())
  }

  handleEmailInputChange = e => {
    const rawEmails = e.target.value
    this.setState({
      rawEmails,
      parsedEmails: rawEmails
        .split(/[\n;,]/)
        .map(email => email.trim())
        .filter(email => this.isValidEmail(email)),
    })
  }

  renderConfirmation = () => {
    const { parsedEmails, tenant } = this.state
    const { classes } = this.props
    if (parsedEmails.length > 0 && tenant) {
      return (
        <div>
          <Typography variant="body2">
            You are about to invite these users to the <b>{tenant}</b> tenant.
          </Typography>
          <ul>
            {parsedEmails.map((item, idx) => {
              return <li key={`${item}-${idx}`}>{item}</li>
            })}
          </ul>
          <Typography className={classes.tenantConfirmation}>
            Please confirm the tenant you are inviting to before submitting
          </Typography>
          <TextField placeholder={tenant} onChange={this.handleTenantConfirmationChange} />
        </div>
      )
    }
  }

  handleTenantConfirmationChange = e => {
    this.setState({
      tenantConfirmation: e.target.value,
    })
  }

  render() {
    const { classes, inviteResult } = this.props
    const { tenant, rawEmails, parsedEmails } = this.state
    const isBtnDisabled = !tenant || parsedEmails.length === 0
    const inviteBtnClass = classnames(classes.submitBtn, {
      [classes.disabledBtn]: isBtnDisabled,
    })
    if (inviteResult) {
      return <InviteStatus successfulInvites={inviteResult} allEmails={parsedEmails} />
    }
    return (
      <div>
        <Typography className={classes.tenantWarning}>
          To perform this operation, you must be operating under the <b>super</b> tenant or the
          tenant you are inviting to (e.g. if you are inviting a user to tenant_xyz then you need to
          be operating under tenant_xyz). If you're not operating under either of these, then go to
          the Switch Tenant page.
        </Typography>
        <TextField
          onChange={this.handleEmailInputChange}
          multiline
          rows={3}
          rowsMax={3}
          value={rawEmails}
          className={classes.input}
          type="email"
          label={'List of comma separated emails'}
          name="email"
          required
        />
        <Typography variant="body2">Which tenant should these users be added to?</Typography>
        <ListTenants tenantClick={this.handleTenantSelect} />
        {this.renderConfirmation()}
        {!this.props.loading && (
          <Button disabled={isBtnDisabled} className={inviteBtnClass} onClick={this.handleSubmit}>
            Invite
          </Button>
        )}
        {this.props.loading && <Loader style={{ marginTop: 16 }} />}
      </div>
    )
  }
}

const mapDispatchToProps = {
  inviteUsers,
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: inviteLoadingFromState(state),
    inviteResult: inviteResultFromState(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InviteForm))
