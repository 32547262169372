import {
  EquipmentActivity,
  EquipmentCategory,
  FacilityType,
  MilestoneAction,
  TripStage,
} from 'store/board/sagas/models'

import Facility from 'store/models/definitions/Facility'

export const MILESTONE_NAMES = {
  empty_dispatch: 'Empty dispatch',
  received_by_ocean_carrier: 'Received by ocean carrier',
  out_for_delivery: 'Out for delivery',
  at_final_delivery_location: 'At final delivery location',
  empty_return: 'Empty return',
}

export interface Props {
  stage: string | null
  action: string | null
  equipmentActivity: typeof EquipmentActivity[keyof typeof EquipmentActivity] | null
  equipmentCategory?: typeof EquipmentCategory[keyof typeof EquipmentCategory] | null
  facilityType?: typeof FacilityType[keyof typeof FacilityType] | null
}

/**
 * Currently for Journey Details only. This function handles some specific rules that differ from
 * [`getFullMilestoneName()`](src/utils/shipmentUtils/getFullMilestoneName.js), so this will be called first
 * and if it returns `null` `getFullMilestoneName` will be called in the component. This should be temporary as
 * we move to new APIs but it's still less than idea. The use cases we are solving for here are:
 *
 * `pre_main, depart, dispatch_return :  "Empty dispatch"`
 * `pre_main, arrive, truck, facilityType=Port :  "Received by ocean carrier"`
 * `post_main, depart, stuffing_emptying :  "Out for delivery"`
 * `post_main, arrive, stuffing_emptying :  "At final delivery location"`
 * `post_main, arrive, dispatch_return :  "Empty return"`
 * @returns string | null
 */
export const getMilestoneDisplayText = (props: Props): string | null => {
  const { stage, action, equipmentActivity, equipmentCategory, facilityType } = props

  if (stage === TripStage.PRE_MAIN) {
    if (
      action === MilestoneAction.DEPART &&
      equipmentActivity === EquipmentActivity.DISPATCH_RETURN
    ) {
      return MILESTONE_NAMES.empty_dispatch
    }

    if (
      action === MilestoneAction.ARRIVE &&
      equipmentCategory === EquipmentCategory.TRUCK &&
      (facilityType === FacilityType.PORT || facilityType === Facility.port)
    ) {
      return MILESTONE_NAMES.received_by_ocean_carrier
    }
  } else if (stage === TripStage.POST_MAIN) {
    if (
      action === MilestoneAction.DEPART &&
      equipmentActivity === EquipmentActivity.STUFFING_EMPTYING
    ) {
      return MILESTONE_NAMES.out_for_delivery
    }

    if (
      action === MilestoneAction.ARRIVE &&
      equipmentActivity === EquipmentActivity.STUFFING_EMPTYING
    ) {
      return MILESTONE_NAMES.at_final_delivery_location
    }

    if (
      action === MilestoneAction.ARRIVE &&
      equipmentActivity === EquipmentActivity.DISPATCH_RETURN
    ) {
      return MILESTONE_NAMES.empty_return
    }
  }
  return null
}

export default getMilestoneDisplayText
