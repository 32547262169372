import { allRoutesMetricsSelector, isAllRoutesMetricsLoadingSelector } from 'store/planning'
import { formatDecimal, formatMinimalDecimals } from 'utils/strUtils'

import LanesScorecard from '../LanesScorecard'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  container: {
    flex: 1,
    margin: `0 ${theme.spacing(1)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
})

const AggregatedGraphScorecards = ({ classes, metrics, isMetricsLoading, hasNoData }) => {
  const durationMetric = get(metrics, 'durations[0]') || {}
  const rollMetric = get(metrics, 'rolls[0]') || {}

  const { metricsMedian, variability } = durationMetric

  const transitTimeScorecardData = {
    label: 'Transit Time Median',
    value: formatMinimalDecimals(metricsMedian || 0, 1),
    units: 'days',
  }
  const variabilityScorecardData = {
    label: 'Variability',
    value: formatMinimalDecimals(variability || 0, 1),
    units: 'days',
  }
  const rollScorecardData = !isEmpty(rollMetric) && {
    label: 'Roll Rate',
    value: formatDecimal(100 * rollMetric.percentage || 0, 1),
    units: '%',
    subLabelName: 'Total Rolls:',
    subLabelValue: rollMetric.rolledCount,
  }
  return (
    <div className={classes.container}>
      <LanesScorecard
        hasNoData={hasNoData}
        isLoading={isMetricsLoading}
        {...transitTimeScorecardData}
        key={'transitTime'}
      />
      <LanesScorecard
        hasNoData={hasNoData}
        isLoading={isMetricsLoading}
        {...variabilityScorecardData}
        key={'variability'}
      />
      <LanesScorecard
        hasNoData={hasNoData}
        isLoading={isMetricsLoading}
        {...rollScorecardData}
        key={'rolls'}
      />
    </div>
  )
}

AggregatedGraphScorecards.propTypes = {
  classes: PropTypes.object.isRequired,
  metrics: PropTypes.object.isRequired,
  isMetricsLoading: PropTypes.bool,
}

const mapDispatchToProps = {}

const mapStateToProps = state => ({
  metrics: allRoutesMetricsSelector(state),
  isMetricsLoading: isAllRoutesMetricsLoadingSelector(state),
})

const styledAggregatedGraphScorecards = withStyles(styles)(AggregatedGraphScorecards)
// for storybook
export { styledAggregatedGraphScorecards as AggregatedGraphScorecards }

export default connect(mapStateToProps, mapDispatchToProps)(styledAggregatedGraphScorecards)
