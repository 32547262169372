import { Box, ButtonBase, IconButton, makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'

import { EXCEPTION_ROLLED } from 'utils/milestone/MilestoneGroups/definitions'
import InfoIcon from 'components/icons/InfoIcon'
import PopoverText from 'components/PopoverText'
import Reaction from 'components/ProductFeedback/Reaction'
import classnames from 'classnames'
import { toTitleCase } from 'utils/strUtils'

const useStyles = makeStyles(theme => ({
  labelText: {
    whiteSpace: 'nowrap',
  },
  controlOverflow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonStyle: {
    lineHeight: '14px',
    cursor: 'default',
  },
  iconButton: {
    borderRadius: theme.shape.borderRadiusSmall,
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.25),
  },
  iconButtonActive: {
    backgroundColor: `rgba(0, 0, 0, 0.04)`,
  },
  infoIcon: {
    color: theme.palette.grey[600],
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  // Allows for override of width by calling component
  width: {},
  rootReset: {
    border: 'solid 1px',
    borderRadius: theme.shape.borderRadiusLarge,
    boxShadow: 'none',
    display: 'inline-flex',
    flexDirection: 'column',
    fontSize: theme.typography.caption.fontSize,
    height: theme.spacing(4),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    minWidth: 140,
  },
  expandToContent: {
    minWidth: 'auto',
    width: 'fit-content',
  },
  button: {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.yellow[25],
    color: `${theme.palette.grey[800]} !important`,
  },
  short: {
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.caption.fontSize,
    height: 20,
    lineHeight: 'normal',
    '& $icon': {
      top: 1,
    },
  },
  iconRoot: {},
  reactionRoot: {
    display: 'flex',
  },
  reactionButtonGroup: {
    marginLeft: theme.spacing(1),
  },
  feedbackPromptText: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

interface Props {
  className?: string
  classes?: { [key: string]: string }
  expandToContent?: boolean
  short?: boolean
}

function RolledStatusLabel(props: Props) {
  const classes = useStyles(props)
  const { className, expandToContent = false, short = false } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const anchorRef = useRef(null)
  const handleClose = () => setAnchorEl(null)
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(anchorRef.current)
  }

  return (
    <Box
      component="span"
      className={classnames([classes.width, className])}
      data-testid="shippingLabel__click-track"
      id="click-track"
    >
      <ButtonBase
        ref={anchorRef}
        disableRipple={true}
        className={classnames(classes.buttonStyle, {
          [classes.short]: short,
          [classes.expandToContent]: expandToContent,
        })}
        component={'div'}
        classes={{
          root: classnames(classes.rootReset, classes.width, classes.button),
        }}
      >
        <span className={classes.controlOverflow}>
          <span className={classes.labelText}>{toTitleCase(EXCEPTION_ROLLED)}</span>
          {
            <IconButton
              onClick={handleClick}
              ref={anchorRef}
              className={classnames(classes.iconButton, {
                [classes.iconButtonActive]: Boolean(anchorEl),
              })}
            >
              <InfoIcon className={classes.infoIcon} />
            </IconButton>
          }
        </span>
      </ButtonBase>
      {anchorEl && (
        <PopoverText
          anchorEl={anchorEl}
          bodyContent={
            <>
              The container loaded onto a different vessel than the one planned. Consequently, the
              ETA at the destination was pushed by more than 4 days.
            </>
          }
          footerContent={
            <Box display="flex" justifyContent="center">
              <Reaction
                classes={{
                  buttonGroup: classes.reactionButtonGroup,
                  reactionPrompt: classes.reactionRoot,
                  feedbackPromptText: classes.feedbackPromptText,
                }}
                componentUnderFeedback="Rolled"
                feedbackPromptText="Was this helpful?"
                loggerLabel="Outdated Prediction Reaction"
              />
            </Box>
          }
          handleClose={handleClose}
          headerContent={<>Rolled at Origin Port</>}
        />
      )}
    </Box>
  )
}

export default RolledStatusLabel
