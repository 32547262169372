import { isExternalUserSelector } from 'store/auth/user/selectors'
import { useSelector } from 'react-redux'

/**
 * A component for managing a gate in front of a component depending on whether or not
 * the current user is internal or external (aka a portal user).
 * @param alternateComponent The component to render if the user is external.
 * @param children The component to render if the user is internal.
 */
export default function InternalUserComponent({ alternateComponent = null, children }) {
  const isExternalUser = useSelector(isExternalUserSelector)
  if (!isExternalUser) {
    return children
  } else {
    return alternateComponent
  }
}
