import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CityAutocomplete from 'components/MultiSelectAutocomplete'
import DateRange from 'components//DateRangePicker'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import styles from './styles'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

class LatestMilestoneFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      warning: '',
      range: {},
      locations: [],
    }
  }

  handleClose = e => {
    e && e.preventDefault()
    this.props.onRequestClose()
  }

  handleSubmit = e => {
    const { onSubmit } = this.props
    const { selected, locations, range } = this.state
    if (selected.length || locations.length || Object.keys(range).length) {
      if (Object.keys(range).length) {
        if (!range.startDate || !range.endDate) {
          this.setState({
            warning: 'Please select a start date and an end date',
          })
          return
        }
      }
      e.preventDefault()
      onSubmit({ selected, locations, range })
      this.handleClose()
    } else {
      this.setState({
        warning: 'You have not selected a filter option',
      })
    }
  }

  handleChange = item => event => {
    let selected = this.state.selected
    if (event.target.checked) {
      selected.push(item)
    } else {
      const index = findIndex(selected, listItem => listItem.value === item.value)
      selected.splice(index, 1)
    }
    this.setState({
      selected,
    })
  }

  handleRangeChange = ({ startDate, endDate }) => {
    this.setState({
      range: { startDate, endDate },
    })
  }

  handleLocationChange = locations => {
    this.setState({
      locations: locations,
    })
  }

  renderOptions = () => {
    const { filterConfig, classes } = this.props
    const options = get(filterConfig, 'filterData.options')
    const { selected } = this.state
    let prevCategory
    return (
      <FormGroup classes={{ root: classes.formGroupRoot }}>
        {options.map((item, index) => {
          const itemSelected = findIndex(selected, listItem => listItem.value === item.value) !== -1
          let category

          if (prevCategory !== item.type) {
            category = (
              <React.Fragment>
                <Typography variant="body2" className={classes.category}>
                  {item.type}
                </Typography>
              </React.Fragment>
            )
          }
          prevCategory = item.type
          return (
            <React.Fragment key={index}>
              {category}
              <FormControlLabel
                classes={{ label: classes.label, root: classes.formControl }}
                control={
                  <Checkbox
                    color="primary"
                    checked={itemSelected}
                    onChange={this.handleChange(item)}
                    value={item.value}
                  />
                }
                label={item.label}
              />
            </React.Fragment>
          )
        })}
      </FormGroup>
    )
  }

  render() {
    const { classes } = this.props
    const { warning } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="body2" className={classes.title}>
          Date of Milestone
        </Typography>
        <div className={classes.dateWrapper}>
          <DateRange onChange={this.handleRangeChange} />
        </div>
        <Divider className={classes.divider} />
        <Typography variant="body2" className={classes.title}>
          Location of Milestone
        </Typography>
        <div className={classes.autocompleteWrapper}>
          <CityAutocomplete
            lookupPath={'/api/locations'}
            onChange={this.handleLocationChange}
            placeholder={'City'}
          />
        </div>
        <Divider className={classes.divider} />
        <Typography variant="body2" className={classes.title}>
          Milestones
        </Typography>
        <div className={classes.milestoneWrapper}>{this.renderOptions()}</div>
        {warning && (
          <Typography variant="body2" className={classes.warning}>
            {this.state.warning}
          </Typography>
        )}
        <Button className={classes.submitBtn} onClick={this.handleSubmit}>
          Apply
        </Button>
      </div>
    )
  }
}

export default compose(withRouter, withStyles(styles))(LatestMilestoneFilter)
