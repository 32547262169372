import { Box } from '@material-ui/core'
import React from 'react'

const MESSAGES = {
  createComment: tenant => ({
    title: `Creating a Comment in ${tenant}`,
    message: `Are you sure you want to post a comment in ${tenant}? This will be seen by all customers and users in this tenant.`,
  }),
  deleteComment: {
    title: 'Delete Comment',
    message: 'Are you sure you want to delete this comment?\nThis cannot be undone.',
    isDestructive: true,
  },
  deleteFilter: (filterName = null) => ({
    title: 'Delete Saved Filter',
    message: (
      <>
        You are about to delete {filterName ? <strong>{filterName}</strong> : 'this filter'}. Are
        you sure you want to delete this filter?
        <Box py={2}>This action cannot be undone.</Box>
      </>
    ),
    isDestructive: true,
    confirmText: 'Delete',
  }),
  deleteFilterWithAlert: (filterName = null) => ({
    title: 'Delete Saved Filter & Alert',
    message: (
      <>
        You are about to delete {filterName ? <strong>{filterName}</strong> : 'this filter'} and its
        associated alert. Are you sure you want to delete this filter and its associated alert?
        <Box py={2}>This action cannot be undone.</Box>
      </>
    ),
    isDestructive: true,
    confirmText: 'Delete',
  }),
  deleteAlertConfig: (filterName = null) => ({
    title: 'Delete Saved Alert',
    message: (
      <>
        You are about to delete {filterName ? <strong>{filterName}</strong> : 'this alert'}. Are you
        sure you want to delete this alert?
        <Box py={2}>This action cannot be undone.</Box>
      </>
    ),
    isDestructive: true,
    confirmText: 'Delete',
  }),
  deleteDownload: ({ fileName }) => ({
    title: 'Delete Download File',
    message: (
      <>
        You are about to delete{' '}
        <Box component="span" fontWeight="fontWeightMedium">
          {fileName}
        </Box>
        . Are you sure you want to delete this file?
        <Box py={2}>This action cannot be undone.</Box>
      </>
    ),
    isDestructive: true,
    confirmText: 'Delete',
  }),
  tenantInviteEmailInconsistent: ({ email, tenant }) => ({
    message: `You are inviting ${email} to tenant ${tenant}, but their email doesn't match any of the other emails in that tenant. Are you sure?`,
    isDestructive: true,
  }),
  replaceFilter: ({ title }) => ({
    title: 'Replace Filter',
    message: `You've already applied a filter for "${title}." Performing this action will clear your current filters and replace them.\n\nAre you sure you want to continue?`,
    confirmText: 'Continue',
  }),
  revokeToken: shareName => ({
    title: 'Revoke Shared Link',
    message: (
      <>
        You are about to revoke {shareName ? <strong>{shareName}</strong> : 'this link'}. Are you
        sure you want to revoke this link?
        <Box py={2}>This action cannot be undone.</Box>
      </>
    ),
    isDestructive: true,
    confirmText: 'Revoke',
  }),
  deletePortalUser: {
    title: 'Delete Portal User',
    message:
      'Performing this action will revoke their access and permissions to your portal. This cannot be undone.\n\nAre you sure you want to delete this portal user?',
    isDestructive: true,
  },
}

export default MESSAGES
