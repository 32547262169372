import BaseCell from './BaseCell'
import Button from 'components/core/Button'
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown'
import PropTypes from 'prop-types'
import React from 'react'
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  cellContents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'nowrap',
    color: '#00558B',
    cursor: 'pointer',
  },
  button: {
    minWidth: 42,
    height: 42,
    width: 42,
    margin: '8px 0 8px 16px',
    padding: 0,
    backgroundColor: theme.palette.white,
  },
  buttonLabel: {
    textTransform: 'none',
  },
  icon: {
    color: theme.palette.grey[500],
    fontSize: 24,
  },
  tableCell: {},
})

const ExpandCell = withStyles(styles)(({ classes, isExpanded, onClick, label }) => {
  return (
    <BaseCell classes={{ tableCell: classes.tableCell }}>
      <div className={classes.cellContents}>
        <div onClick={onClick} className={classes.label} data-testid="expand-cell__label">
          {label}
        </div>
        <Button
          onClick={onClick}
          classes={{ root: classes.button, label: classes.buttonLabel }}
          data-testid="expand-cell__button"
        >
          {isExpanded ? (
            <UpArrowIcon className={classes.icon} />
          ) : (
            <DownArrowIcon className={classes.icon} />
          )}
        </Button>
      </div>
    </BaseCell>
  )
})

ExpandCell.propTypes = {
  classes: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
}
export default ExpandCell
