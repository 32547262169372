import React, { PropsWithChildren } from 'react'
import { Typography, TypographyProps, makeStyles } from '@material-ui/core'

import ConditionalWrapper from './ConditionalWrapper'
import Link from 'components/core/Link'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  /**
   * The entire point of TableCellText is to lazily ensure elements align with active element
   * and also just allow a single point of control for text
   *  */
  root: {},
  verticalPadding: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
  },
  active: {
    fontWeight: theme.typography.fontWeightMedium,
    background: theme.palette.yellow[250],
    borderRadius: theme.shape.borderRadiusSmall,
    maxWidth: 'fit-content',
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  italic: {
    fontStyle: 'italic',
  },
}))

interface TableCellTextProps extends TypographyProps {
  href?: string
  active?: boolean
  className?: string
  verticalPadding?: boolean
  horizontalPadding?: boolean
  bold?: boolean
  italic?: boolean
  onClick?: () => void
}

function TableCellText(props: PropsWithChildren<TableCellTextProps>) {
  const classes = useStyles()
  const {
    active,
    bold = false,
    italic = false,
    children,
    className,
    horizontalPadding = true,
    href,
    onClick = () => {},
    verticalPadding = true,
    ...rest
  } = props

  return (
    <Typography
      className={cx(
        classes.root,
        {
          [classes.active]: active,
          [classes.bold]: bold,
          [classes.italic]: italic,
          [classes.verticalPadding]: verticalPadding,
          [classes.horizontalPadding]: horizontalPadding,
        },
        className
      )}
      {...rest}
    >
      <ConditionalWrapper
        condition={Boolean(href)}
        wrapper={children => (
          <Link onClick={() => onClick()} to={href}>
            {children}
          </Link>
        )}
      >
        {children}
      </ConditionalWrapper>
    </Typography>
  )
}

export default TableCellText
