export default theme => ({
  button: {
    minWidth: theme.spacing(5),
  },
  paperOverrides: {
    maxHeight: '80vh',
    overflow: 'scroll',
  },
  arrowIcon: {
    marginTop: -2,
    marginLeft: -2,
    color: theme.brand.grey,
  },
})
