import { Box, Paper, Theme, makeStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import P44LogoFullColor from 'assets/P44LogoFullColor'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    backgroundColor: theme.palette.grey[100],
    height: '100%',
    minHeight: '100vh',
    paddingTop: 100,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  container: {
    borderRadius: 14,
    width: 600,
  },
  logo: {
    height: 'auto',
    marginBottom: theme.spacing(3),
    width: 80,
  },
  p44Logo: {
    height: 42,
    marginBottom: theme.spacing(2),
    width: 120,
  },
  header: {
    textAlign: 'center',
  },
  headerText: {},
  body: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
  },
  footerButton: {
    margin: theme.spacing(0, 1, 0, 1),
  },
}))

interface Props {
  body?: React.ReactNode | null
  footer?: React.ReactNode | null
  headerText?: React.ReactNode | null
  showDefaultFooter?: boolean
}

const ErrorLayout = (props: Props) => {
  const classes = useStyles(props)
  const { body, footer, headerText, showDefaultFooter = true } = props

  return (
    <Box className={classes.page} display="flex" justifyContent="center">
      <Paper elevation={10} className={classes.container}>
        <Box pt={6} pb={3} px={6} className={classes.header}>
          <P44LogoFullColor className={classes.p44Logo} />
          {headerText && <Box className={classes.headerText}>{headerText}</Box>}
        </Box>
        {body && (
          <Box pt={2} pb={3} px={6} className={classes.body}>
            {body}
          </Box>
        )}
        {!showDefaultFooter && footer && (
          <Box my={2} className={classes.footer}>
            {footer}
          </Box>
        )}
        {showDefaultFooter && (
          <Box py={2} className={classes.footer}>
            <Button
              className={classes.footerButton}
              variant="textLightDefault"
              onClick={() => window.open('mailto:support@clearmetal.com', '_blank')}
            >
              Email Support
            </Button>
            <Button
              className={classes.footerButton}
              variant="textLightDefault"
              onClick={() => window.open('https://www.project44.com/', '_blank')}
            >
              Visit project44
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ErrorLayout
