import Comment from './Comment'
import PropTypes from 'prop-types'
import React from 'react'
import TodayDivider from './TodayDivider'
import moment from 'moment'

const Comments = ({ comments, editor }) => {
  const startOfDay = moment().startOf('day')
  const indexOfTodaysFirstComment = comments.findIndex(comment =>
    moment(comment.createdAt).isAfter(startOfDay)
  )

  return (
    <>
      {comments.reduce((commentList, comment, index) => {
        index === indexOfTodaysFirstComment && commentList.push(<TodayDivider key="divider" />)

        commentList.push(<Comment key={comment.externalId} editor={editor} {...comment} />)

        return commentList
      }, [])}
    </>
  )
}

Comments.propTypes = {
  editor: PropTypes.object,
  comments: PropTypes.array,
}

export default Comments
