import React, { useState } from 'react'
import { Tab, Tabs, makeStyles } from '@material-ui/core'

import AddUsers from './AddUsers'
import ManageUsers from './ManageUsers'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    width: '100%',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(2),
  },
}))

export default function PortalUsersPage() {
  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue)
  }
  return (
    <div className={classes.container} data-testid="portalSettings">
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: classes.tabs }}
      >
        <Tab label="Add Users" />
        <Tab label="Manage Users" />
      </Tabs>
      {tabIndex === 0 && <AddUsers />}
      {tabIndex === 1 && <ManageUsers />}
    </div>
  )
}
