import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import api from 'store/share/api'
import { closeShareModal } from '../actions'
import { confirmSaga } from 'store/confirmation'
import { deleteShare } from 'store/share/actions'
import { getRefIdsForSelectIsExhaustive } from 'store/board/sagas/api'
import humps from 'humps'
import logger from 'utils/logger'
import { selectIsExhaustiveSelector } from 'store/board/selectors'
import { types } from 'store/share/actions'

const getResponseData = resp => humps.camelizeKeys(resp.data)

export function* fetchSharesSaga() {
  try {
    const shares = getResponseData(yield call(api.fetchShares, { refType: null, refIds: null }))
    yield put({ type: types.FETCH_SHARES_SUCCESS, payload: shares })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_SHARES_ERROR })
  }
}

function* createShareHelper({ refType, refIds }) {
  const selectIsExhaustive = yield select(selectIsExhaustiveSelector)

  if (selectIsExhaustive) {
    refIds = yield call(getRefIdsForSelectIsExhaustive, { refType })
  }

  return getResponseData(yield call(api.createShare, { refType, refIds }))
}

export function* createShareSaga({ payload }) {
  const { refType, refIds } = payload
  try {
    const share = yield call(createShareHelper, { refType, refIds })
    yield put({ type: types.CREATE_SHARE_SUCCESS, payload: share })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.shareLinkError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
    yield put({ type: types.CREATE_SHARE_ERROR })
  }
}

export function* updateShareSaga({ payload }) {
  try {
    yield call(api.updateShare, payload)
    yield put(showNotification(NOTIFICATION_MESSAGES.shareUpdateSuccess))
    yield put({ type: types.FETCH_SHARES_START })
    yield call(fetchSharesSaga)
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.shareUpdateError, {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

// Seperates confirmation modal from delete Saga to avoid eagerly setting `loading: true`
export function* requestShareDeleteSaga({ payload }) {
  const confirmed = yield call(confirmSaga, CONFIRMATION_MESSAGES.revokeToken(payload.name))
  if (!confirmed) {
    return
  } else {
    yield put(deleteShare({ guid: payload.guid, name: payload.name }))
  }
}

export function* deleteShareSaga({ payload }) {
  try {
    yield call(api.deleteShare, payload)
    yield put(showNotification(NOTIFICATION_MESSAGES.shareRevokeSuccess(payload.name)))
    yield call(fetchSharesSaga)
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put(
      showNotification(NOTIFICATION_MESSAGES.shareRevokeError(payload.name), {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

export function* fetchActiveShareSaga({ payload }) {
  // Attempts to fetch a share matching the currently-selected shipments.
  try {
    const shares = getResponseData(yield call(api.fetchShares, payload))
    // Only include active shares
    const activeShares = shares.filter(s => s.active)
    yield put({
      type: types.FETCH_ACTIVE_SHARE_SUCCESS,
      payload: activeShares.length > 0 ? activeShares[0] : null,
    })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_ACTIVE_SHARE_ERROR })
  }
}

export function* sendShareEmailSaga({ payload }) {
  const { refType, refIds, emails, subject, body } = payload

  try {
    const share = yield call(createShareHelper, { refType, refIds })
    yield call(api.sendShareEmail, { guid: share.guid, emails, subject, text: body })
    yield put(closeShareModal())
    yield put({ type: types.SEND_SHARE_EMAIL_SUCCESS, payload: share })
    yield put(
      showNotification(NOTIFICATION_MESSAGES.shareEmailSuccess, { type: MESSAGE_TYPES.SUCCESS })
    )
  } catch (e) {
    yield put(
      showNotification(NOTIFICATION_MESSAGES.shareEmailError, { type: MESSAGE_TYPES.ERROR })
    )
    yield put({ type: types.SEND_SHARE_EMAIL_ERROR })
  }
}

export const sagas = [
  takeLatest(types.FETCH_SHARES_START, fetchSharesSaga),
  takeLatest(types.CREATE_SHARE_START, createShareSaga),
  takeLatest(types.UPDATE_SHARE, updateShareSaga),
  takeLatest(types.DELETE_SHARE, deleteShareSaga),
  takeLatest(types.REQUEST_DELETE_SHARE, requestShareDeleteSaga),
  takeLatest(types.FETCH_ACTIVE_SHARE_START, fetchActiveShareSaga),
  takeLatest(types.SEND_SHARE_EMAIL_START, sendShareEmailSaga),
]
