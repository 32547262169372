import React from 'react'
import { SvgIcon } from '@material-ui/core'

const DownloadsIcon = ({ ...props }) => (
  <SvgIcon viewBox={'0 0 24 29'} {...props}>
    <g fillRule="nonzero">
      <path d="M11.533 18.737h.934l4.422-4.405-.933-.928L12 17.344l-3.956-3.94-.933.928z" />
      <path d="M12.778 9.849H11v8h1.778z" />
      <path d="M17.778 21.404H7.111v1h10.667z" />
      <path d="M2.4 28.86h19.2c1.324 0 2.4-1.06 2.4-2.365V6.974l-.175-.419L17.83.645l-.425-.172H2.4C1.076.473 0 1.534 0 2.84v23.656c0 1.304 1.076 2.365 2.4 2.365zM22.8 7.22v19.276c0 .653-.539 1.182-1.2 1.182H2.4c-.662 0-1.2-.53-1.2-1.182V2.839c0-.652.538-1.183 1.2-1.183h14.756L22.8 7.219z" />
    </g>
  </SvgIcon>
)

export default DownloadsIcon
