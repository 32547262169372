import Milestone from 'store/models/Milestone'
import NewFields from './NewFields'
import findLast from 'lodash/findLast'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import moment from 'moment'

export const translations = {
  latestMilestone: timelines => {
    const latestMilestones = []
    timelines.forEach(timeline => {
      const milestones = get(timeline, 'milestones', [])
      const latestMs = findLast(milestones, item => get(item, 'time.traced') !== null)
      latestMilestones.push(latestMs)
    })

    const maxLatest = maxBy(latestMilestones, item => moment(get(item, 'time.traced')))
    const tracedTime = get(maxLatest, 'time.time')
    const timezone = get(maxLatest, 'time.timezone')
    const location = {
      name: get(maxLatest, 'location.name'),
      unlocode: get(maxLatest, 'location.unlocode'),
    }
    const type = get(maxLatest, 'type')

    return {
      tracedTime,
      timezone,
      location,
      type,
    }
  },
}

export const LegacyShipmentsModel = {
  of: ({
    containers = [],
    ctr_trip_ids = [],
    custom_fields = [],
    delivery_location = {},
    delivery_time = {},
    departure_time = {},
    discharge_location = {},
    discharge_time = {},
    external_id,
    haulage_type = {},
    id,
    load_location = {},
    max_ocean_delay,
    new_fields = {},
    ocean_carriers = [],
    receipt_location = {},
    ref_id,
    ref_type,
    references = {},
    status = {},
    is_idle = false,
    tenant = {},
    timelines = [],
    transshipment_locations = [],
  }) => {
    // Adding new properties that correspond to new API,
    // _not_ deleting any of the old for now.

    // until we camelize everything
    const externalId = external_id

    // `status` already has the other properties we need, just adding one
    status.delay_status = status.label

    const toNewApi = {
      carrier: ocean_carriers,
      deliveryMilestone: {
        location: {
          name: get(delivery_location, 'name'),
          unlocode: get(delivery_location, 'unlocode'),
        },
      },
      dischargeMilestone: {
        planned_time: get(discharge_time, 'planned'),
        predicted_time: get(discharge_time, 'predicted'),
        traced_time: get(discharge_time, 'traced'),
        timezone: get(discharge_time, 'timezone'),
        location: {
          name: get(discharge_location, 'name'),
          unlocode: get(discharge_location, 'unlocode'),
        },
      },
      latestMilestone: translations.latestMilestone(timelines),
      loadMilestone: {
        predicted: get(departure_time, 'predicted'),
        traced_time: get(departure_time, 'traced'),
        timezone: get(departure_time, 'timezone'),
        location: {
          name: get(load_location, 'name'),
          unlocode: get(load_location, 'unlocode'),
        },
      },
      primary_mode: null,
      receiptMilestone: {
        location: {
          name: get(receipt_location, 'name'),
          unlocode: get(receipt_location, 'unlocode'),
        },
      },
      shipmentBoard: {
        ...NewFields.of(new_fields),
      },
      transshipmentMilestones: {
        location: {},
        legacy_locations: transshipment_locations,
      },
      vehicles: get(timelines[0], 'milestones', []),
    }
    return {
      ctr_trip_ids,
      custom_fields,
      containers,
      delivery_location,
      delivery_time,
      departure_time,
      discharge_location,
      discharge_time,
      external_id,
      externalId,
      haulage_type,
      id,
      load_location,
      max_ocean_delay,
      ocean_carriers,
      receipt_location,
      ref_id,
      ref_type,
      references,
      status,
      isIdle: is_idle,
      tenant,
      // Legacy gets returned as always, we're dealing here only with the `new_fields` for each `milestone`
      timelines:
        timelines &&
        timelines.map(timeline => {
          timeline.milestones.map(ms => {
            ms.newFields = Milestone.of(ms.new_fields)
            delete ms.new_fields
            return ms
          })
          return timeline
        }),
      transshipment_locations,
      ...toNewApi,
    }
  },
}
