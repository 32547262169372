export default theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cell: {
    '&:nth-last-child(2)': {
      minWidth: '35%',
    },
  },
  linkButton: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  header: {
    fontSize: theme.typography.h5.fontSize,
  },
  rowHover: {
    '&:hover td:last-child button': {
      opacity: 1,
    },
  },
  hidden: {
    opacity: 0,
  },
})
