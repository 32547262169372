import { PORTAL_ALERTS_REDUCER_KEY, types } from './actions'

export { PORTAL_ALERTS_REDUCER_KEY }

export const initialState = {
  submitErrors: null,
  submitLoading: false,
  modalOpen: false,
}

const ACTION_HANDLERS = {
  [types.PORTAL_ALERTS_SET_SUBMIT_DISABLED]: (state, { payload }) => {
    return {
      ...state,
      submitDisabled: payload,
    }
  },
  [types.SUBMIT_PORTAL_ALERTS_START]: state => {
    return {
      ...state,
      submitLoading: true,
    }
  },
  [types.SUBMIT_PORTAL_ALERTS_SUCCESS]: state => {
    return {
      ...state,
      submitLoading: false,
    }
  },
  [types.SUBMIT_PORTAL_ALERTS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      submitLoading: false,
      submitErrors: { payload },
    }
  },
  [types.PORTAL_ALERTS_MODAL_OPEN]: state => {
    return {
      ...state,
      modalOpen: true,
    }
  },
  [types.PORTAL_ALERTS_MODAL_CLOSE]: state => {
    return {
      ...state,
      modalOpen: false,
    }
  },
}

export function portalAlertsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default portalAlertsReducer
