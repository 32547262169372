import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { createAction, handleActions } from 'redux-actions'

import MESSAGES from 'store/notifications/messages'
import client from 'utils/api/client'
import { getRefIdsForSelectIsExhaustive } from '../board/sagas/api'
import logger from 'utils/logger'
import { selectIsExhaustiveSelector } from '../board/selectors'

// Action Types
const EXPORT_LOAD = 'export/EXPORT_LOAD'
export const EXPORT_SUCCESS = 'export/EXPORT_SUCCESS'
const EXPORT_FAIL = 'export/EXPORT_FAIL'

// Action Creators
export const initiateExport = createAction(EXPORT_LOAD, (ids, refType, fileType, columnKeys) => ({
  ids,
  refType,
  fileType,
  columnKeys,
}))

// Initial State
const initState = {
  loading: false,
}

// Reducer
export default handleActions(
  {
    [EXPORT_LOAD]: state => {
      return { ...state, loading: true }
    },
    [EXPORT_SUCCESS]: state => {
      return { ...state, loading: false }
    },
    [EXPORT_FAIL]: state => {
      return { ...state, loading: false }
    },
  },
  initState
)

// Selectors
export const downloadLoadingStatusSelector = state => state.export.loading

export function* startExportAsync({ payload }) {
  const { refType, fileType, columnKeys } = payload
  let ids = payload.ids
  const selectIsExhaustive = yield select(selectIsExhaustiveSelector)

  try {
    if (selectIsExhaustive) {
      ids = yield call(getRefIdsForSelectIsExhaustive, { refType })
    }

    const data = {
      entity_ids: ids,
      ref_type: refType,
      file_type: fileType,
      columns: columnKeys, // This array will be rendered in order
    }
    yield call(client.post, '/download', data)
    yield put({ type: EXPORT_SUCCESS })
    yield put(showNotification(MESSAGES.exportSuccess(), { type: MESSAGE_TYPES.SUCCESS }))
  } catch (e) {
    logger.captureAPIException(e)
    yield put(showNotification(MESSAGES.exportError, { type: MESSAGE_TYPES.ERROR }))
    yield put({ type: EXPORT_FAIL })
  }
}

export function* watchExportStart() {
  yield takeLatest(EXPORT_LOAD, startExportAsync)
}

export const sagas = [watchExportStart()]
