import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'
import { deleteDownload, types } from '../actions'

import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import api from '../api'
import { confirmSaga } from 'store/confirmation'
import { fetchDownloads } from 'store/downloads/actions'
import logger from 'utils/logger'
import toRows from '../models/rows'

export function* fetchDownloadsSaga() {
  try {
    const response = yield call(api.fetchDownloads)
    yield put({ type: types.FETCH_DOWNLOAD_SUCCESS, payload: { rows: toRows(response.data) } })
  } catch (error) {
    logger.error(error)
    logger.captureAPIException(error)
    yield put({ type: types.FETCH_DOWNLOAD_FAILURE, payload: { error: error.message } })
  }
}

export function* requestDeleteSaga({ payload }) {
  const { fileName } = payload

  const confirmed = yield call(confirmSaga, {
    ...CONFIRMATION_MESSAGES.deleteDownload({ fileName }),
  })
  if (!confirmed) {
    return
  } else {
    logger.notify('Download Manager Delete')
    yield put(deleteDownload(payload))
  }
}

export function* deleteDownloadSaga({ payload }) {
  const { id, fileName } = payload

  try {
    yield call(api.deleteDownload, payload)
    yield put({ type: types.DELETE_DOWNLOAD_SUCCESS, payload })
    yield put(
      showNotification(NOTIFICATION_MESSAGES.deleteDownloadSuccess(fileName), {
        type: MESSAGE_TYPES.INFO,
      })
    )
    yield put(fetchDownloads({ disableLoading: true }))
  } catch (error) {
    logger.error(error)
    logger.captureAPIException(error)
    yield put({ type: types.DELETE_DOWNLOAD_FAILURE, payload: { id, error: error.message } })
    yield put(
      showNotification(NOTIFICATION_MESSAGES.deleteDownloadFailure(fileName), {
        type: MESSAGE_TYPES.ERROR,
      })
    )
  }
}

export const sagas = [
  takeLatest(types.FETCH_DOWNLOAD_START, fetchDownloadsSaga),
  takeLatest(types.DELETE_DOWNLOAD_START, deleteDownloadSaga),
  takeLatest(types.DELETE_DOWNLOAD_REQUEST, requestDeleteSaga),
]
