import { EquipmentCategory } from 'store/board/sagas/models'

export const vehicleCategoryText = {
  [EquipmentCategory.VESSEL]: 'vessel',
  [EquipmentCategory.BARGE]: 'barge',
  [EquipmentCategory.TRAIN]: 'rail',
  [EquipmentCategory.RAILCAR]: 'rail',
  [EquipmentCategory.TRUCK]: 'drayage',
  [EquipmentCategory.CHASSIS]: 'drayage',
  [EquipmentCategory.TRAILER]: 'drayage',
  [EquipmentCategory.AIR]: 'plane',
  [undefined]: 'vehicle',
}

/**
 * Returns the display text for vehicle types based on the Transportation Mode.
 *
 * @param vehicleCategory {TransportationMode}
 * @returns {string}
 */
const getVehicleTypeText = vehicleCategory => vehicleCategoryText[vehicleCategory]

export default getVehicleTypeText
