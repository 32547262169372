import Highcharts from 'components/HighChart/config'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import merge from 'lodash/merge'

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)

const defaultOptions = {
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
}

const HighChart = ({ options, immutable, callback }) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={merge({}, defaultOptions, options)}
      immutable={Boolean(immutable)}
      callback={callback}
    />
  )
}

export default HighChart
