import { MenuItem, MenuList, Paper, Popover, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import DownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import moment from 'moment'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  menuList: {
    maxHeight: 300,
    overflow: 'scroll',
  },
  paper: {},
  button: {
    border: '1px solid #aaaaaa',
    padding: '4px 16px',
  },
  buttonLabel: {
    textTransform: 'none',
    pointerEvents: 'none',
    color: '#65717d',
  },
  menuItem: {
    fontSize: 14,
    width: 55,
    height: 0,
  },
})

const HOURS = [
  '12:00 am',
  '1:00 am',
  '2:00 am',
  '3:00 am',
  '4:00 am',
  '6:00 am',
  '7:00 am',
  '8:00 am',
  '9:00 am',
  '10:00 am',
  '11:00 am',
  '12:00 pm',
  '1:00 pm',
  '2:00 pm',
  '3:00 pm',
  '4:00 pm',
  '5:00 pm',
  '6:00 pm',
  '7:00 pm',
  '8:00 pm',
  '9:00 pm',
  '10:00 pm',
  '11:00 pm',
]

class TimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue,
      open: false,
      anchorEl: null,
    }
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    defaultValue: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    onSelect: () => {},
    defaultValue: moment().hour(20).minute(0),
  }
  handleClose = event => {
    this.setState({ open: false })
  }

  handleOpen = event => {
    this.setState({
      open: true,
      anchorEl: event.target,
    })
  }

  handleHourSelect = hour => () => {
    const newTime = moment(hour, 'h:mm a')
    this.setState(
      {
        value: newTime,
        open: false,
      },
      () => {
        this.props.onSelect(newTime)
      }
    )
  }

  getFormattedHour = value => {
    if (value && value.isValid()) return value.format('h:mm a')
  }

  render() {
    const { classes, className } = this.props
    const { open, value, anchorEl } = this.state

    const selectedHour = this.getFormattedHour(value)

    return (
      <div className={classnames(classes.root, className)}>
        <Button
          classes={{ label: classes.buttonLabel, root: classes.button }}
          onClick={this.handleOpen}
        >
          {selectedHour}
          <DownIcon />
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorReference={'anchorEl'}
          onClose={this.handleClose}
          anchorPosition={{ top: 60, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper className={classes.paper} elevation={2}>
            <MenuList className={classes.menuList}>
              {HOURS.map(hour => {
                return (
                  <MenuItem
                    key={hour}
                    selected={selectedHour === hour}
                    onClick={this.handleHourSelect(hour)}
                    className={classes.menuItem}
                  >
                    {hour}
                  </MenuItem>
                )
              })}
            </MenuList>
          </Paper>
        </Popover>
      </div>
    )
  }
}

TimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TimePicker)
