import * as Yup from 'yup'

import { TextField, makeStyles } from '@material-ui/core'

import AuthActions from 'components/AuthActions'
import Button from 'components/core/Button'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import React from 'react'
import { isValidEmail } from 'utils/validate'
import { useFormHelper } from 'utils/hooks'

const useStyles = makeStyles(theme => ({
  authorizingIcon: {
    marginRight: theme.spacing(0.5),
  },
  form: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    // consistent width makes variable-length error text display consistently
    width: theme.spacing(45),
  },
  submitButton: {
    width: '100%',
  },
}))

interface Props {
  onSubmit: (params: { email: string }) => void
  authorizing: boolean
}

const EmailForm = (props: Props) => {
  const { onSubmit, authorizing } = props
  const classes = useStyles()
  const { handleSubmit, errors, touched, values, handleChange, handleBlur } = useFormHelper({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      email: '',
    },
    onSubmit: () => {
      const { email } = values
      onSubmit({ email })
    },
    // couldn't get submission to work with a validate: function here
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .test(
          'valid-email',
          'Please enter a valid email. Example: name@website.com',
          (value: string | null | undefined) => {
            return isValidEmail(value)
          }
        ),
    }),
  })

  const buttonText = authorizing ? (
    <>
      <RadioButtonUncheckedIcon className={classes.authorizingIcon} />
      Authorizing
    </>
  ) : (
    'Continue'
  )

  return (
    <form id="login-form" className={classes.form} onSubmit={handleSubmit}>
      <TextField
        disabled={authorizing}
        data-testid="login-form--email-input"
        error={Boolean(errors.email && touched.email)}
        fullWidth
        helperText={errors.email ? errors.email : 'Example: name@website.com'}
        id="email"
        label="Email"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        variant="filled"
      />
      <AuthActions>
        <Button
          disabled={authorizing}
          className={classes.submitButton}
          data-testid="login-form--submit"
          color="primary"
          variant="contained"
          id="submit-login"
          type="submit"
          size="large"
        >
          {buttonText}
        </Button>
      </AuthActions>
    </form>
  )
}

export default EmailForm
