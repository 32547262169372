import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ArrowRightIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M12,4 C12.5522847,4 13,4.44771525 13,5 L12.9995729,16.585 L17.1213203,12.4644661 C17.5118446,12.0739418 18.1450096,12.0739418 18.5355339,12.4644661 C18.9260582,12.8549904 18.9260582,13.4881554 18.5355339,13.8786797 L12.8786797,19.5355339 L12.787214,19.6167501 C12.6041472,19.850088 12.3195805,20 12,20 C11.6844087,20 11.4029626,19.8538073 11.2196888,19.625449 C11.1828367,19.5933038 11.1513607,19.5655742 11.1213203,19.5355339 L5.46446609,13.8786797 C5.0739418,13.4881554 5.0739418,12.8549904 5.46446609,12.4644661 C5.85499039,12.0739418 6.48815536,12.0739418 6.87867966,12.4644661 L10.9995729,16.586 L11,5 C11,4.44771525 11.4477153,4 12,4 Z"
        transform="rotate(-90 12 12)"
      />
    </SvgIcon>
  )
}

export default ArrowRightIcon
