export class AlertConfig {
  constructor(name, id, payload, notifTime, notifTimezone) {
    this.name = name
    this.id = id
    this.payload = payload
    this.notifTime = notifTime
    this.notifTimezone = notifTimezone
  }
}

export class Alert {
  constructor(
    configID,
    alertedAt,
    alertedTripIds,
    allTripIds,
    payload,
    reason,
    reference,
    referenceType,
    resolvedAt,
    shipTo,
    externalId
  ) {
    this.configID = configID
    this.alertedAt = alertedAt
    this.alertedTripIds = alertedTripIds
    this.allTripIds = allTripIds
    this.payload = payload
    this.reason = reason
    this.reference = reference
    this.referenceType = referenceType
    this.resolvedAt = resolvedAt
    this.shipTo = shipTo
    this.externalId = externalId
  }
}
