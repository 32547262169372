import CenteredLayout from 'components/layouts/CenteredLayout'
import Confirmation from 'components/Confirmation'
import { Loader } from 'components/Loader/Loader'
import Notifications from 'components/Notifications'
import PropTypes from 'prop-types'
import React from 'react'

class BaseLayout extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }

  static defaultProps = {
    loading: false,
  }

  render() {
    const { loading } = this.props

    if (loading) {
      return (
        <CenteredLayout>
          <Loader />
        </CenteredLayout>
      )
    } else {
      return (
        <React.Fragment>
          <Notifications />
          <Confirmation />
          {this.props.children}
        </React.Fragment>
      )
    }
  }
}

export default BaseLayout
