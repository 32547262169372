import { ETAStatus } from 'utils/shipmentObjs'
import get from 'lodash/get'

const getETAStatuses = destArrival => {
  const originalETA = get(destArrival, 'originalPlanned.max', 'N/A')
  const updatedETA = get(destArrival, 'planned.max', 'N/A')
  const predictedETA = get(destArrival, 'predicted.max', 'N/A')
  const traced = get(destArrival, 'traced.min', 'N/A')
  return new ETAStatus(originalETA, updatedETA, predictedETA, traced)
}

export default getETAStatuses
