export const CARRIER_ABBREVIATIONS = [
  '22AA',
  'ACL',
  'AERC',
  'AKMD',
  'ALM',
  'ALS',
  'AML',
  'ANL',
  'ANLC',
  'APL',
  'ARPZ',
  'BAYL',
  'BB',
  'BCL',
  'BNSF',
  'BOCT',
  'BRC',
  'CCNI',
  'CCNR',
  'CFNR',
  'CHHK',
  'CHNL',
  'CK',
  'CLAM',
  'CMA',
  'CGM',
  'CMQ',
  'CMR',
  'CNC',
  'CNCX',
  'COAU',
  'COEU',
  'CORP',
  'COSCON',
  'CPLB',
  'CPRC',
  'CPRS',
  'CSAV',
  'CSAV',
  'CSCL',
  'CSXT',
  'CVRC',
  'CW',
  'CWRY',
  'DAL',
  'DAL',
  'DGNO',
  'DGVR',
  'DRIR',
  'DVRU',
  'ECUI',
  'EDW',
  'EISU',
  'EPIR',
  'ESPN',
  'FCEN',
  'FEC',
  'FESCO',
  'FGLK',
  'FSRR',
  'FXE',
  'GDLK',
  'GFRR',
  'GLTR',
  'GOSU',
  'GSL',
  'GWF',
  'H-SUD',
  'HDS',
  'HESR',
  'HLUS',
  'HMM',
  'HOG',
  'IAIS',
  'ICL',
  'IHB',
  'KCS',
  'KCSM',
  'KCTL',
  'KFR',
  'KMTC',
  'KWE',
  'LDRR',
  'LSRC',
  'LVSW',
  'MCC',
  'MISC',
  'MNNR',
  'MOL',
  'MQT',
  'MRL',
  'MSC',
  'NAQA',
  'NBSR',
  'NCLL',
  'NCVA',
  'NDS',
  'NFTS',
  'NGST',
  'NLR',
  'NOPB',
  'NS',
  'NYK',
  'NYK',
  'OMID',
  'ONE',
  'ONT',
  'OOCL',
  'PAS',
  'PASU',
  'PDRR',
  'PGR',
  'PHSX',
  'PIL',
  'PIL',
  'PNWR',
  'PTRC',
  'QGRY',
  'RCL',
  'RJCD',
  'RJCK',
  'RVSC',
  'SAPT',
  'SCI',
  'SITC',
  'SITC',
  'SKOL',
  'SLGW',
  'SM',
  'SRY',
  'SSPH',
  'ST',
  'STPP',
  'STX',
  'SUAU',
  'TFVM',
  'TMBL',
  'TNER',
  'TPW',
  'TRRA',
  'TSL',
  'TXN',
  'TZPR',
  'UAL',
  'UASC',
  'UASC',
  'UP',
  'WEC',
  'WHL',
  'WLS',
  'WSOR',
  'WWL',
  'YML',
  'YRC',
  'ZIM',
]

export default CARRIER_ABBREVIATIONS
