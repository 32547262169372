import { Box, Tab, Tabs, Theme, makeStyles } from '@material-ui/core'
import { ROLLUP_CONTAINER, getShipmentDetailPath } from 'utils/rollups'
import React, { useEffect, useMemo, useState } from 'react'
import {
  journeyDetailsLoadingSelector,
  journeyDetailsSelector,
} from 'store/journeyDetails/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEmailActions, useOpenCommentsDrawerIfComments } from 'utils/hooks'

import AdminTripId from 'components/AdminTripId'
import { COMMENT_DRAWER_WIDTH } from 'components/CommentsDrawer'
import CommentsDrawer from 'components/CommentsDrawer'
import EntityPageBase from 'components/EntityPageBase'
import EntityPageHeader from 'components/EntityPageHeader'
import ErrorBoundary from 'components/ErrorBoundary'
import InternalUserComponent from 'components/InternalUserComponent'
import JourneyDetails from 'pages/ContainerPage/JourneyDetails'
import NoShipmentMsg from 'components/NoShipmentMsg'
import ReferencesTable from 'components/ReferencesTable'
import ShareComment from 'pages/ContainerPage/ShareComment'
import Skeleton from 'pages/ContainerPage/JourneyDetails/Skeleton'
import Timeline from 'pages/ContainerPage/Timeline'
import TripMap from 'components/TripMap'
import { associatedReferencesSelector } from 'store/associatedReferences/selectors'
import classnames from 'classnames'
import definitions from 'store/models/definitions'
import { fetchAssociatedReferences } from 'store/associatedReferences/actions'
import { fetchJourneyDetails } from 'store/journeyDetails/actions'
import find from 'lodash/find'
import { getIsCommentsDrawerOpen } from 'utils/routes/comments'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import { publicShareSelector } from 'store/publicShare'
import store from 'store'
import { useRouteMatch } from 'react-router-dom'

const ACTIVE_TAB_KEY = 'entityTabs.activeTab'

// Meaning when all tabs are visible
const MAX_TAB_COUNT = 3

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  pageContainer: {
    width: '100%',
  },
  tab: {
    width: `${100 / MAX_TAB_COUNT}%`, // equal space for each tab, makes this quasi responsive
  },
  wrapper: {
    lineHeight: 1.2,
  },
  container: {
    minWidth: 1100,
    position: 'relative',
    width: '100%',
  },
  commentShare: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  drawerOpen: {
    width: `calc(100% - ${COMMENT_DRAWER_WIDTH}px)`,
  },
  tabsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabWrapper: {
    paddingBottom: 100,
  },
  tabUnderline: {
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
  },
  tabContentWrapper: {
    background: theme.palette.grey[100],
    boxShadow: `0 50vh 0 50vh ${theme.palette.grey[100]}`,
    height: '100%',
  },
  indicator: {
    height: 4,
  },
  skeleton: {
    borderTop: `2px solid ${theme.palette.grey[300]}`,
  },
}))

interface MatchParams {
  extension?: string | undefined
  externalId: string
  number?: string
}

const ContainerPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const match = useRouteMatch<MatchParams>()
  const isCommentsDrawerOpen = getIsCommentsDrawerOpen(match)
  const isExternalUser = useSelector(isExternalUserSelector)
  const externalId = decodeURIComponent(match.params.externalId)
  const journeyDetails = useSelector(journeyDetailsSelector)
  const isPublicShare = useSelector(publicShareSelector)
  const containerNumber = decodeURIComponent(match.params.number ?? '')
  const journeyDetailsLoading = useSelector(journeyDetailsLoadingSelector)
  const associatedReferencesData = useSelector(associatedReferencesSelector)
  const localStorageTab = store.get(ACTIVE_TAB_KEY)
  const commentsDrawerOpen = getIsCommentsDrawerOpen(match)
  const pageHeader = journeyDetails?.pageHeader
  const selfNumber = journeyDetails?.self.number
  const selfType = journeyDetails?.self.type
  const selfId = journeyDetails?.self.id

  useOpenCommentsDrawerIfComments(externalId)

  // `tabs.idx` should be the same as `EntityPageTabs` if they both have those tabs.
  // For unique tabs to this page, like Journey Details, use an `idx` not on `EntityPageTabs`.
  // This page will likely be combined with`EntityPageTabs` at some point, making it all mute.
  const tabs = useMemo(
    () => ({
      MAP_VIEW: {
        idx: 3,
        label: 'Map View',
        isDisabled: false,
      },
      JOURNEY_DETAILS: {
        idx: 1,
        label: 'Journey Details',
        isDisabled: !journeyDetails || Object.keys(journeyDetails).length === 0,
      },
      ASSOCIATED_REFERENCES: {
        idx: 5,
        label: 'Associated References',
        isDisabled: !associatedReferencesData || Object.keys(associatedReferencesData).length === 0,
      },
    }),
    [associatedReferencesData, journeyDetails]
  )

  const getCurrentTab = () => {
    // If they have a tab in `localStorage` we use it unless it's disabled
    if (typeof localStorageTab !== 'undefined') {
      const storedTab = find(tabs, tab => tab.idx === localStorageTab)
      if (storedTab && !storedTab.isDisabled) {
        return storedTab.idx
      }
    }

    // Otherwise we use the first non-disabled tab
    const defaultTab = find(tabs, tab => !tab.isDisabled)
    return defaultTab?.idx
  }

  const defaultTab = getCurrentTab()
  const [currentTab, setCurrentTab] = useState(defaultTab)

  useEffect(() => {
    dispatch(
      fetchAssociatedReferences({
        refType: definitions.ApiRefType[ROLLUP_CONTAINER],
        externalId,
      })
    )
    setCurrentTab(defaultTab)
  }, [dispatch, defaultTab, externalId])

  const handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    store.set(ACTIVE_TAB_KEY, selectedTab)
    setCurrentTab(selectedTab)

    const tab = find(tabs, tab => tab.idx === selectedTab)
    logger.notify('Entity Page Tab Click', {
      entity_type: ROLLUP_CONTAINER,
      entity_id: journeyDetails?.header.equipment[0]?.number,
      tab_type: (tab && tab.label) || '',
    })
  }

  const commentIconLink =
    (selfType && externalId && getShipmentDetailPath(selfType, selfNumber, externalId)) ?? null

  useOpenCommentsDrawerIfComments(externalId)

  useEffect(() => {
    if (externalId) {
      dispatch(fetchJourneyDetails(externalId))
      const msg = `${isExternalUser ? 'Portal ' : ''}Entity Page View`
      logger.notify(msg, { entityId: containerNumber, entityType: 'Container' })
    }
  }, [containerNumber, dispatch, externalId, isExternalUser])

  useEmailActions()

  if (!journeyDetailsLoading && !selfNumber) {
    return <NoShipmentMsg />
  } else {
    return (
      <>
        <Box className={classes.pageContainer}>
          <EntityPageBase
            className={classnames(classes.container, { [classes.drawerOpen]: commentsDrawerOpen })}
          >
            <Box mb={6} px={6} data-testid="header-wrapper">
              <AdminTripId tripId={journeyDetails?.self.id} />
              <div className={classes.container}>
                {pageHeader && externalId && (
                  <EntityPageHeader
                    externalId={externalId}
                    isContainerPage={true}
                    isLoading={journeyDetailsLoading}
                    isPublicShare={isPublicShare}
                    pageHeaderData={pageHeader}
                    refTypeLabel={pageHeader?.refTypeLabel}
                    commentIconLink={commentIconLink}
                  />
                )}
                {externalId && (
                  <ShareComment
                    classes={{ commentShare: classes.commentShare }}
                    externalId={externalId}
                    refIds={selfId ? [selfId] : []}
                    shareModalRefType={ROLLUP_CONTAINER}
                  />
                )}
              </div>
            </Box>
            <Box px={6}>
              <Timeline />
            </Box>

            {journeyDetailsLoading ? (
              <Skeleton classes={{ root: classes.skeleton }} isAnimated={true} />
            ) : (
              <Box className={classes.tabsWrapper}>
                <Box px={6} className={classes.tabUnderline}>
                  <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{ indicator: classes.indicator }}
                    data-testid="entity-detail_tabs"
                  >
                    <Tab
                      classes={{ root: classes.tab, wrapper: classes.wrapper }}
                      disabled={tabs.JOURNEY_DETAILS.isDisabled}
                      value={tabs.JOURNEY_DETAILS.idx}
                      label={tabs.JOURNEY_DETAILS.label}
                    />
                    <Tab
                      classes={{ root: classes.tab, wrapper: classes.wrapper }}
                      disabled={tabs.MAP_VIEW.isDisabled}
                      value={tabs.MAP_VIEW.idx}
                      label={tabs.MAP_VIEW.label}
                    />
                    <Tab
                      classes={{ root: classes.tab, wrapper: classes.wrapper }}
                      disabled={tabs.ASSOCIATED_REFERENCES.isDisabled}
                      value={tabs.ASSOCIATED_REFERENCES.idx}
                      label={tabs.ASSOCIATED_REFERENCES.label}
                    />
                  </Tabs>
                </Box>
                <Box pt={3} className={classes.tabContentWrapper}>
                  {currentTab === tabs.MAP_VIEW.idx && (
                    <Box mt={-3} px={6} pb={12}>
                      <ErrorBoundary>
                        {journeyDetails && <TripMap journeyDetails={journeyDetails} />}
                      </ErrorBoundary>
                    </Box>
                  )}
                  {currentTab === tabs.JOURNEY_DETAILS.idx && <JourneyDetails />}
                  {currentTab === tabs.ASSOCIATED_REFERENCES.idx && (
                    <Box px={6} pb={12}>
                      {associatedReferencesData && (
                        <ReferencesTable
                          data={associatedReferencesData}
                          refType={ROLLUP_CONTAINER}
                          containerTripId={journeyDetails?.header.equipment[0]?.number || null}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </EntityPageBase>
          <InternalUserComponent>
            {isCommentsDrawerOpen && externalId && (
              <CommentsDrawer
                entityTitle={`Container: ${containerNumber}`}
                externalId={externalId}
              />
            )}
          </InternalUserComponent>
        </Box>
      </>
    )
  }
}

export default ContainerPage
