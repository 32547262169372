import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import api from '../api'
import browserHistory from 'utils/history'
import logger from 'utils/logger'
import loginApi from 'store/auth/login/api'
import { types } from '../actions'

export function* passwordResetConfirmationAsync(action) {
  try {
    const { email, password, pwdToken } = action.payload

    yield call(api.resetPasswordConfirm, {
      email,
      new_password: password,
      pwd_token: pwdToken,
    })
    yield call(loginApi.doLogin, { username: email, password })
    yield put({ type: types.PASSWORD_RESET_CONFIRMATION_SUCCESS })
    yield put(showNotification(MESSAGES.passwordResetSuccess, { type: MESSAGE_TYPES.SUCCESS }))
    yield browserHistory.push('/')
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error resetting password: ${e}`, 'error')
    yield put(
      showNotification(MESSAGES.passwordResetError, {
        type: MESSAGE_TYPES.WARNING,
      })
    )
  }
}

export function* watchPasswordResetConfirmation() {
  yield takeLatest(types.PASSWORD_RESET_CONFIRMATION_LOAD, passwordResetConfirmationAsync)
}
