import React from 'react'
import { SvgIcon } from '@material-ui/core'

const CommentIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M18,0 C19.05,0 19.9177686,0.82004132 19.9944872,1.85130541 L20,2 L20,14 C20,15.05 19.1799587,15.9177686 18.1486946,15.9944872 L18,16 L4,16 L0,20 L0.01,2 C0.01,0.95 0.82092975,0.0822314 1.85136551,0.00551277 L2,0 L18,0 Z M17,1.99997888 L3.00859105,1.99997888 C2.45650175,2.0004795 2.0088675,2.44788977 2.00809056,2.99997888 L2.002,15.169 L3.17157288,14 L17,14 C17.5522847,14 18,13.5522847 18,13 L18,2.99997888 C18,2.44769413 17.5522847,1.99997888 17,1.99997888 Z M4,7 L16,7 L16,9 L4,9 L4,7 Z M12,12 L4,12 L4,10 L12,10 L12,12 Z M16,6 L4,6 L4,4 L16,4 L16,6 Z"
        transform="translate(2 2)"
      />
    </SvgIcon>
  )
}

export default CommentIcon
