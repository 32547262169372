import './DateRangePicker.css'

import * as moment from 'moment'

import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import { DateRangePicker } from 'react-dates'

const DATE_FORMAT = 'D MMM YYYY'

const useStyles = makeStyles((theme: Theme) => ({
  rangeWrapper: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    ...theme.typography.body2,
    paddingBottom: 1,
    letterSpacing: 0.1,
  },
}))

type Date = moment.Moment | null
type FocusedInput = 'startDate' | 'endDate' | null

type DateRangeProps = {
  className?: string
  initialEndDate: Date
  initialStartDate: Date
  maxDate?: any
  minDate?: any
  onChange: (day: any) => undefined
  openDirection: 'down' | 'up' | undefined
  title?: string
}
const DateRange = (props: DateRangeProps) => {
  const classes = useStyles()
  const {
    className = '',
    initialEndDate,
    initialStartDate,
    maxDate,
    minDate,
    onChange,
    openDirection,
    title = '',
  } = props
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null)
  /**
   * Type `any` because MomentJS types are not up to date. If we use `<Date>`:
   * >  Property 'isoWeeksInISOWeekYear' is missing in type
   */
  const [startDate, setStartDate] = useState<any>(initialStartDate)
  const [endDate, setEndDate] = useState<any>(initialEndDate)

  const onDatesChange = ({ startDate, endDate }: any) => {
    onChange({ startDate, endDate })
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const onFocusChange = (focusedInput: FocusedInput) => setFocusedInput(focusedInput)

  return (
    <Box className={className}>
      <Typography variant="body2" className={classes.title}>
        {title}
      </Typography>
      <Box className={classes.rangeWrapper}>
        <DateRangePicker
          openDirection={openDirection}
          startDateId={'startDate'}
          startDatePlaceholderText={'Start Date'}
          endDateId={'endDate'}
          anchorDirection={'right'}
          endDatePlaceholderText={'End Date'}
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          small={true}
          numberOfMonths={2}
          daySize={30}
          displayFormat={DATE_FORMAT}
          hideKeyboardShortcutsPanel={true}
          initialVisibleMonth={
            maxDate && !Boolean(startDate) && !Boolean(endDate)
              ? () => maxDate.clone().subtract(1, 'month')
              : undefined
          }
          isOutsideRange={day =>
            (maxDate ? day.isAfter(maxDate) : false) || (minDate ? day.isBefore(minDate) : false)
          }
        />
      </Box>
    </Box>
  )
}

export default DateRange
