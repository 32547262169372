import {
  Collapse,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { Component } from 'react'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'
import ViewListItem from './ViewListItem'
import { ViewType } from './viewType'
import get from 'lodash/get'
import logger from 'utils/logger'
import orderBy from 'lodash/orderBy'
import styles from './styles'

class ViewList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentView: PropTypes.object,
    deleteView: PropTypes.func.isRequired,
    handleAlertClick: PropTypes.func.isRequired,
    handleUpdateView: PropTypes.func.isRequired,
    selectView: PropTypes.func.isRequired,
    views: PropTypes.arrayOf(ViewType),
  }

  state = {
    isSavedViewsExpanded: true,
    isViewMoreExpanded: false,
  }

  renderEmptyMessage() {
    const { classes } = this.props
    return (
      <Typography variant="body1" className={classes.emptyMessage}>
        Save those filters you use often. You can update and edit them at any time.
      </Typography>
    )
  }

  handleSelect = view => () => {
    const { selectView } = this.props
    logger.notify('Selected View', view)
    selectView(view)
  }

  handleDelete = (viewId, viewName) => e => {
    e.stopPropagation()
    const { deleteView } = this.props
    logger.notify('Deleted View', { viewId })
    deleteView(viewId, viewName)
  }

  handleUpdateView = (view, newName) => {
    const { handleUpdateView } = this.props
    logger.notify('View updated', { newName })
    handleUpdateView(view, newName)
  }

  handleViewMoreClick = () => {
    this.setState({
      isViewMoreExpanded: !this.state.isViewMoreExpanded,
    })
  }

  handleAlertClick = alertConfigIds => e => {
    e.stopPropagation()
    const { handleAlertClick } = this.props
    logger.notify('Alert click', { alertConfigIds })
    handleAlertClick(alertConfigIds)
  }

  viewNameExists = name => {
    const { views } = this.props
    return views.map(view => view.name).includes(name)
  }

  render() {
    const { views, classes, currentView } = this.props
    const { isSavedViewsExpanded, isViewMoreExpanded } = this.state
    // Order alpha, ignore case
    const orderedViews = orderBy(views, [view => view.name.toLowerCase()], ['asc'])

    const viewsList = orderedViews.map(view => {
      const viewAlertIds = get(view, 'alert_config_ids', [])
      return (
        <ViewListItem
          key={`view-list-item-${view.id}`}
          viewName={view.name}
          viewAlertIds={viewAlertIds}
          viewNameExists={this.viewNameExists}
          onSelect={this.handleSelect(view)}
          onDelete={this.handleDelete(view.id, view.name)}
          handleAlertClick={this.handleAlertClick(viewAlertIds)}
          handleUpdateView={newName => this.handleUpdateView(view, newName)}
          isActive={get(view, 'id') === get(currentView, 'id')}
        />
      )
    })

    const firstFour = viewsList.slice(0, 4)
    const remaining = viewsList.slice(4)
    return (
      <div className={classes.container} data-testid="view-list">
        <ExpansionPanel
          expanded={isSavedViewsExpanded}
          onChange={() => this.setState({ isSavedViewsExpanded: !isSavedViewsExpanded })}
          classes={{ root: classes.expansionRoot }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              root: classes.expansionSummaryRoot,
              content: classes.expansionSummaryContent,
            }}
          >
            <Typography variant="subtitle1">My Saved Filters</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.expansionDetailRoot }}>
            <div className={classes.expansionWrapper}>
              <List>{firstFour.length === 0 ? this.renderEmptyMessage() : firstFour}</List>

              {Boolean(remaining.length) && (
                <List
                  className={classes.collapseList}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItem
                    button
                    className={classes.collapseButton}
                    onClick={this.handleViewMoreClick}
                  >
                    <ListItemText
                      disableTypography
                      className={classes.collapseHeading}
                      primary={isViewMoreExpanded ? 'View less' : 'View more'}
                    />
                    {isViewMoreExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={isViewMoreExpanded} timeout="auto">
                    <List>{remaining}</List>
                  </Collapse>
                </List>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default withStyles(styles)(ViewList)
