import { AlertConfigInterface } from 'store/portalAlerts/interfaces'
import camelCase from 'lodash/camelCase'
import { portalAlertNames } from 'pages/PortalAlertModal/utils/'

type Alert = {
  name: string
}

const getUserAlerts = (alerts: AlertConfigInterface[]) => {
  const oceanDelayIndex = alerts
    ? alerts.findIndex((alert: Alert) => alert.name === portalAlertNames.OCEAN_DELAY)
    : -1
  const stuckInTransshipmentIndex = alerts
    ? alerts.findIndex((alert: Alert) => alert.name === portalAlertNames.STUCK_IN_TRANSSHIPMENT)
    : -1
  const vesselArrivedAtDestinationIndex = alerts
    ? alerts.findIndex(
        (alert: Alert) => alert.name === portalAlertNames.VESSEL_ARRIVED_AT_DESTINATION
      )
    : -1
  const vesselHasDepartedOriginIndex = alerts
    ? alerts.findIndex((alert: Alert) => alert.name === portalAlertNames.VESSEL_HAS_DEPARTED_ORIGIN)
    : -1
  const vesselWillArriveAtDestinationIndex = alerts
    ? alerts.findIndex(
        (alert: Alert) => alert.name === portalAlertNames.VESSEL_WILL_ARRIVE_AT_DESTINATION
      )
    : -1
  const detentionToOriginPortIndex = alerts
    ? alerts.findIndex((alert: Alert) => alert.name === portalAlertNames.DETENTION_TO_ORIGIN_PORT)
    : -1
  const detentionFromDestinationPortIndex = alerts
    ? alerts.findIndex(
        (alert: Alert) => alert.name === portalAlertNames.DETENTION_FROM_DESTINATION_PORT
      )
    : -1
  const demurrageAtOriginPortIndex = alerts
    ? alerts.findIndex((alert: Alert) => alert.name === portalAlertNames.DEMURRAGE_AT_ORIGIN_PORT)
    : -1
  const demurrageAtDestinationPortIndex = alerts
    ? alerts.findIndex(
        (alert: Alert) => alert.name === portalAlertNames.DEMURRAGE_AT_DESTINATION_PORT
      )
    : -1

  return {
    oceanDelayAlert: oceanDelayIndex !== -1 ? alerts[oceanDelayIndex] : null,
    oceanDelayKey: camelCase(portalAlertNames.OCEAN_DELAY),
    stuckInTransshipmentAlert:
      stuckInTransshipmentIndex !== -1 ? alerts[stuckInTransshipmentIndex] : null,
    stuckInTransshipmentKey: camelCase(portalAlertNames.STUCK_IN_TRANSSHIPMENT),
    vesselArrivedAtDestinationAlert:
      vesselArrivedAtDestinationIndex !== -1 ? alerts[vesselArrivedAtDestinationIndex] : null,
    vesselArrivedAtDestinationKey: camelCase(portalAlertNames.VESSEL_ARRIVED_AT_DESTINATION),
    vesselHasDepartedOriginAlert:
      vesselHasDepartedOriginIndex !== -1 ? alerts[vesselHasDepartedOriginIndex] : null,
    vesselHasDepartedOriginKey: camelCase(portalAlertNames.VESSEL_HAS_DEPARTED_ORIGIN),
    vesselWillArriveAtDestinationAlert:
      vesselWillArriveAtDestinationIndex !== -1 ? alerts[vesselWillArriveAtDestinationIndex] : null,
    vesselWillArriveAtDestinationKey: camelCase(portalAlertNames.VESSEL_WILL_ARRIVE_AT_DESTINATION),
    detentionToOriginPortAlert:
      detentionToOriginPortIndex !== -1 ? alerts[detentionToOriginPortIndex] : null,
    detentionToOriginPortKey: camelCase(portalAlertNames.DETENTION_TO_ORIGIN_PORT),
    detentionFromDestinationPortAlert:
      detentionFromDestinationPortIndex !== -1 ? alerts[detentionFromDestinationPortIndex] : null,
    detentionFromDestinationPortKey: camelCase(portalAlertNames.DETENTION_FROM_DESTINATION_PORT),
    demurrageAtOriginPortAlert:
      demurrageAtOriginPortIndex !== -1 ? alerts[demurrageAtOriginPortIndex] : null,
    demurrageAtOriginPortKey: camelCase(portalAlertNames.DEMURRAGE_AT_ORIGIN_PORT),
    demurrageAtDestinationPortAlert:
      demurrageAtDestinationPortIndex !== -1 ? alerts[demurrageAtDestinationPortIndex] : null,
    demurrageAtDestinationPortKey: camelCase(portalAlertNames.DEMURRAGE_AT_DESTINATION_PORT),
  }
}

export default getUserAlerts
