import { BOARD_UTILS_REDUCER_KEY } from 'store/boardUtils/actions'
import { buildCustomColumn } from 'store/board/utils/columns'
import { buildCustomFilter } from 'store/customShipmentFilters'
import { createSelector } from 'reselect'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import { getCustomerTags } from 'store/auth/user/selectors'
import { getDefaultColumnOrder } from 'store/boardUtils'
import { isParent } from 'store/auth/tenant/selectors'

export const isImportDialogOpen = state => state[BOARD_UTILS_REDUCER_KEY].isImportDialogOpen
export const createLoadingSelector = state => state[BOARD_UTILS_REDUCER_KEY].createLoading
export const updateLoadingSelector = state => state[BOARD_UTILS_REDUCER_KEY].updateLoading
export const viewsSelector = state => state[BOARD_UTILS_REDUCER_KEY].views
export const currentViewSelector = state => state[BOARD_UTILS_REDUCER_KEY].currentView
export const viewTypeSelector = state => state[BOARD_UTILS_REDUCER_KEY].viewType
export const sortStateSelector = state => state[BOARD_UTILS_REDUCER_KEY].sortState
export const rollupSelector = state => state[BOARD_UTILS_REDUCER_KEY].rollup

export const transportationStatusSelector = state =>
  state[BOARD_UTILS_REDUCER_KEY].transportationStatus

export const defaultImportDialogValuesSelector = state =>
  state[BOARD_UTILS_REDUCER_KEY].defaultImportDialogValues

export const shipmentColumnsSelector = state => {
  const isCurrentTenantParent = isParent(state)
  const columns = state[BOARD_UTILS_REDUCER_KEY].columns

  // Optimization check
  if (isCurrentTenantParent) return columns

  // Pluck columns for Business Reasons
  return filter(columns, col => {
    // Do not show Partner Column
    return col.key !== 'tenant'
  })
}

// filterData is a pre-configured mapping of table columns to default db fields
export const filterDataSelector = state => {
  const isCurrentTenantParent = isParent(state)
  const filters = state[BOARD_UTILS_REDUCER_KEY].filterData

  // Optimization check
  if (isCurrentTenantParent) return filters

  // Pluck columns for Business Reasons
  const filtered = filter(filters, filter => {
    // Do not show Partner Filter
    return filter.name !== 'tenant'
  })
  return filtered
}

export const filtersSelector = state => get(state[BOARD_UTILS_REDUCER_KEY], 'filters', [])

// Appends Tenant specific filters to default filters
export const getDefaultAndCustomizedFilters = createSelector(
  filterDataSelector,
  getCustomerTags,
  (filterData, customerTags) => {
    const tenantFilters = customerTags
      .map(requestedFilter => {
        return buildCustomFilter(requestedFilter)
      })
      .filter(f => f) // remove empty vals

    return [...filterData, ...tenantFilters]
  }
)

export const getCustomizedColumns = createSelector(
  shipmentColumnsSelector,
  getCustomerTags,
  (cols, customerTags) => {
    const customizedColumns = customerTags
      .map(requestedFilter => {
        return buildCustomColumn(requestedFilter)
      })
      .filter(f => f) // remove empty vals

    return [...cols, ...customizedColumns]
  }
)

export const getHideableColumns = createSelector(
  rollupSelector,
  getCustomizedColumns,
  (rollup, allCols) => {
    return allCols.filter(item => {
      const shouldRenderFcn = get(item, 'shouldRender', null)
      const shouldRender = shouldRenderFcn ? shouldRenderFcn(rollup) : true
      return !get(item, 'notHideable', false) && shouldRender
    })
  }
)

export const getFormattedColumnsSortedByDefault = state => {
  const formattedColumns = getHideableColumns(state).map(({ key, title }) => ({
    key,
    title,
  }))

  const shipmentColumns = shipmentColumnsSelector(state)
  const formattedColumnsSortedByDefault = []
  getDefaultColumnOrder(shipmentColumns).forEach(key => {
    const formattedColumn = find(formattedColumns, { key: key })
    if (formattedColumn) formattedColumnsSortedByDefault.push(formattedColumn)
  })

  return formattedColumnsSortedByDefault
}

export const getSortableColumns = createSelector(getCustomizedColumns, allCols =>
  allCols.filter(item => get(item, 'sort_param', false))
)

export const rollupColumnHiddenSelector = state =>
  state[BOARD_UTILS_REDUCER_KEY].columnCustomization[state[BOARD_UTILS_REDUCER_KEY].rollup]?.hidden

export const rollupColumnOrderSelector = state =>
  state[BOARD_UTILS_REDUCER_KEY].columnCustomization[state[BOARD_UTILS_REDUCER_KEY].rollup]?.order

export const getVisibleColumns = createSelector(
  rollupSelector,
  getCustomizedColumns,
  rollupColumnHiddenSelector,
  rollupColumnOrderSelector,
  (rollup, columns = [], hiddenTitles = [], columnOrder = []) => {
    let renderableColumns = []

    // Sort by order of appearance in `columnOrder`
    columnOrder.forEach(colKey => {
      const found = find(columns, { key: colKey })
      if (found !== undefined) renderableColumns.push(found)
    })

    // Make sure to include custom columns. Either these will be in `columnOrder` above already, or it is safe to append
    // them here. This was added as a fix for #169411678.
    for (let column of columns) {
      if (column.isCustomerTag && !find(renderableColumns, { key: column.key })) {
        renderableColumns.push(column)
      }
    }

    // Remove hidden
    renderableColumns = renderableColumns.filter(col => !hiddenTitles.includes(get(col, 'title')))

    // Remove Entity specific columns
    renderableColumns = renderableColumns.filter(col =>
      col.shouldRender ? col.shouldRender(rollup) : true
    )

    return renderableColumns
  }
)
