import { OAuthStatusInterface } from '../interfaces'
import humps from 'humps'

export default class OAuthStatus {
  constructor({ requestUri, usesSso, canUsePasswordLogin }: OAuthStatusInterface) {
    Object.assign(this, {
      requestUri,
      usesSso,
      canUsePasswordLogin,
    })
  }

  static of(payload: object) {
    const { requestUri, usesSso, canUsePasswordLogin } = humps.camelizeKeys(
      payload
    ) as OAuthStatusInterface

    return new OAuthStatus({
      requestUri,
      usesSso,
      canUsePasswordLogin,
    })
  }
}
