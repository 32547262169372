import { Box, Tooltip } from '@material-ui/core'
import { ROLLUPS, ROLLUPS_PORTAL } from 'utils/rollups'

import AlertIcon from 'components/icons/AlertIcon'
import BoardDrawer from './Drawer/BoardDrawer'
import Button from 'components/core/Button'
import CenteredLayout from 'components/layouts/CenteredLayout'
import ExportMenu from 'components/core/ConfigurableTable/TableControls/ExportMenu'
import HorizontalButtonGroupLite from 'components/core/HorizontalButtonGroupLite/index'
import ImportReferencesDialog from './Drawer/BoardDrawer/ImportReferencesDialog'
import InternalUserComponent from 'components/InternalUserComponent'
import { LIST_VIEW } from 'store/boardUtils'
import { Loader } from 'components/Loader/Loader'
import { MAX_SHARE_ENTRIES } from 'store/share'
import PortalAlertModal from 'pages/PortalAlertModal'
import PropTypes from 'prop-types'
import React from 'react'
import RefTypeSelector from './RefTypeSelector'
import ShareButton from 'components/ShareButton'
import ShareIcon from 'components/icons/ShareIcon'
import ShareModal from 'components/ShareModal'
import TableControls from './TableView/TableControls'
import TableView from 'pages/BoardPage/TableView'
import TimelineView from './TimelineView'
import ViewTabs from './ViewTabs'
import get from 'lodash/get'
import logger from 'utils/logger'
import styles from './styles'
import sum from 'lodash/sum'
import { withStyles } from '@material-ui/core'

export function getActionButtonTooltipText(isDisabled, isExternalUser, tooManyToShare) {
  const genericDisabledTooltipText = 'Please select at least one item on the table'

  let shareButtonTooltipText = 'Please select at least one item on the table'
  let downloadButtonTooltipText = 'Please select at least one item on the table'
  const alertButtonTooltipText = 'Create or edit your alerts'

  if (!isDisabled) {
    shareButtonTooltipText = 'Share selected items'
    downloadButtonTooltipText = 'Download selected items'
  } else {
    if (isExternalUser) {
      shareButtonTooltipText = genericDisabledTooltipText
      downloadButtonTooltipText = genericDisabledTooltipText
    }
  }
  if (tooManyToShare) {
    shareButtonTooltipText = `You can only share ${MAX_SHARE_ENTRIES} items at a time. Please adjust your selections.`
  }

  return { shareButtonTooltipText, downloadButtonTooltipText, alertButtonTooltipText }
}

class BoardPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    columnKeys: PropTypes.array.isRequired,
    currentView: PropTypes.object,
    downloadIsLoading: PropTypes.bool.isRequired,
    featureFlags: PropTypes.object.isRequired,
    filterConfigs: PropTypes.array,
    getInitialRefType: PropTypes.func.isRequired,
    getRefTypeCounts: PropTypes.func.isRequired,
    initiateExport: PropTypes.func.isRequired,
    isExternalUser: PropTypes.bool.isRequired,
    isImportDialogOpen: PropTypes.bool.isRequired,
    portalAlertsCloseModal: PropTypes.func.isRequired,
    portalAlertsModalOpen: PropTypes.bool.isRequired,
    portalAlertsOpenModal: PropTypes.func.isRequired,
    queryParams: PropTypes.object.isRequired,
    refTypeCounts: PropTypes.object.isRequired,
    rollup: PropTypes.oneOf(ROLLUPS).isRequired,
    rows: PropTypes.array.isRequired,
    selectIsExhaustive: PropTypes.bool.isRequired,
    selectedShipments: PropTypes.array.isRequired,
    setFiltersFromURL: PropTypes.func.isRequired,
    toggleImportDialog: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    viewType: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      dataFetched: false,
    }
  }

  componentDidMount() {
    const {
      queryParams,
      filterConfigs,
      getInitialRefType,
      setFiltersFromURL,
      rows,
      isExternalUser,
      getRefTypeCounts,
      refTypeCounts,
    } = this.props

    // portal logic
    if (isExternalUser) {
      // similar to the rows.length === 0 check below, we only need to fetch ref type counts once
      // if you remove/refactor this logic, make sure to avoid a regression of #172728973
      const refTypeCountsSet = refTypeCounts && Object.keys(refTypeCounts).length > 0
      if (!refTypeCountsSet) {
        getRefTypeCounts()
      }
      return
    }

    // non-portal logic
    if (rows.length === 0) {
      if (queryParams.filters) {
        setFiltersFromURL(queryParams, filterConfigs)
      } else {
        getInitialRefType()
      }
    }
  }

  getRollupsToRender = () => {
    const { isExternalUser, refTypeCounts } = this.props
    let rollups = ROLLUPS

    if (isExternalUser) {
      // For portal filter out ref types with no data
      rollups = ROLLUPS_PORTAL.filter(rollup => refTypeCounts[rollup] > 0)
    }
    return rollups
  }

  handleDownload = fileType => {
    const { rollup, selectedShipments, columnKeys, initiateExport } = this.props
    const ids = selectedShipments.map(shipment => shipment.ref_id)
    initiateExport(ids, rollup, fileType, columnKeys)
    logger.notify('Shipments Board Export Button Click', { fileType })
  }

  render() {
    const {
      classes,
      currentView,
      downloadIsLoading,
      isExternalUser,
      isImportDialogOpen,
      openShareModal,
      portalAlertsCloseModal,
      portalAlertsModalOpen,
      portalAlertsOpenModal,
      refTypeCounts,
      rollup,
      selectedCount,
      selectedShipments,
      selectIsExhaustive,
      toggleImportDialog,
      viewType,
    } = this.props

    const numSelected = selectIsExhaustive ? selectedCount : selectedShipments.length
    const isDisabled = numSelected === 0
    const tooManyToShare = numSelected > MAX_SHARE_ENTRIES

    // Portal users only, we interrupt the entire page rendering in case they have nothing to show
    if (isExternalUser) {
      if (Object.keys(refTypeCounts).length === 0) {
        // Counts are still loading.
        return (
          <CenteredLayout>
            <Loader />
          </CenteredLayout>
        )
      } else if (sum(Object.values(refTypeCounts)) === 0) {
        // User doesn't have anything shared with them.
        return <CenteredLayout>Looks like your portal is empty. :(</CenteredLayout>
      }
    }

    const {
      shareButtonTooltipText,
      downloadButtonTooltipText,
      alertButtonTooltipText,
    } = getActionButtonTooltipText(isDisabled, isExternalUser, tooManyToShare)

    return (
      <Box className={classes.container} data-testid="board-page">
        <InternalUserComponent>
          <BoardDrawer />
        </InternalUserComponent>
        <Box className={classes.main}>
          {/* for an explanation of why this is not a MUI ButtonGroup, see HorizontalButtonGroupLite/index.js */}
          <HorizontalButtonGroupLite
            aria-label="outlined primary button group"
            className={classes.buttonGroup}
            size="medium"
            data-testid="board__button-group"
          >
            {isExternalUser ? (
              <Tooltip title={alertButtonTooltipText}>
                <Button
                  variant="outlinedAlternateDark"
                  onClick={() => {
                    logger.notify('Portal Alert Config Open')
                    portalAlertsOpenModal()
                  }}
                  data-testid="portal-alert__button"
                  startIcon={<AlertIcon />}
                >
                  Alert Me
                </Button>
              </Tooltip>
            ) : (
              <ShareButton
                onClick={e => {
                  openShareModal()
                  logger.notify('Shipments Board Share Button Click')
                }}
                variant="outlinedAlternateDark"
                buttonText="Share"
                startIcon={<ShareIcon />}
                className={classes.margin}
                disabled={isDisabled || tooManyToShare}
                tooltipText={shareButtonTooltipText}
              />
            )}

            <ExportMenu
              isIconOnlyButton={false}
              className={classes.margin}
              onDownloadClick={fileType => this.handleDownload(fileType)}
              isLoading={downloadIsLoading}
              disabled={isDisabled}
              variant="outlinedAlternateDark"
              buttonText="Download"
              tooltipText={downloadButtonTooltipText}
            />
          </HorizontalButtonGroupLite>
          <Box className={classes.header}>
            <RefTypeSelector rollup={rollup} getRollupsToRender={this.getRollupsToRender} />
            <ViewTabs />
            <Box className={classes.tableControlWrapper} data-testid="board__table-controls">
              <TableControls onDownloadClick={fileType => this.handleDownload(fileType)} />
            </Box>
          </Box>
          {viewType === LIST_VIEW ? <TimelineView /> : <TableView />}
          <ImportReferencesDialog
            initialView={currentView}
            isOpen={isImportDialogOpen}
            onClose={toggleImportDialog}
          />
        </Box>
        <ShareModal
          shareModalRefType={rollup}
          refIds={selectedShipments.map(s => get(s, 'ref_id')).filter(id => id)}
        />
        {isExternalUser && (
          <PortalAlertModal
            open={portalAlertsModalOpen}
            onClose={() => {
              logger.notify('Portal Alert Config Close')
              portalAlertsCloseModal()
            }}
          />
        )}
      </Box>
    )
  }
}

export default withStyles(styles)(BoardPage)
