import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core'

const CenteredLayout = ({ classes, className, children, ...rest }) => (
  <section className={classnames([classes.centeredLayout, className])} {...rest}>
    {children}
  </section>
)

CenteredLayout.propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = theme => ({
  centeredLayout: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
})

export default withStyles(styles)(CenteredLayout)
