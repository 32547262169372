import { Typography, makeStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { formatVesselLabel } from 'utils/strUtils'

const useStyles = makeStyles(theme => ({
  invalidText: {
    color: theme.palette.grey[50],
    fontSize: 11,
    letterSpacing: 0.1,
  },
  vesselName: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12,
  },
}))

export default function UnavailableVessels({ invalidVessels }) {
  const classes = useStyles()
  const typeText = 'Vessel'
  return invalidVessels.length ? (
    <Typography variant="body2" className={classes.invalidText}>
      {invalidVessels.map((item, index) => {
        let vesselName = formatVesselLabel(item.name)
        vesselName = vesselName ? vesselName : item.lloydsCode
        vesselName = vesselName ? vesselName : ''
        const comma = index !== invalidVessels.length - 1 ? ', ' : ''
        return (
          <span
            className={classnames(classes.invalidText, classes.vesselName)}
            key={`unavailable-${index}`}
          >
            {vesselName}
            {comma}
          </span>
        )
      })}
      : vessel data missing
    </Typography>
  ) : (
    <Typography variant="body2" className={classes.invalidText}>
      {typeText} data missing
    </Typography>
  )
}

UnavailableVessels.propTypes = {
  invalidVessels: PropTypes.array.isRequired,
}

UnavailableVessels.defaultProps = {
  invalidVessels: [],
}
