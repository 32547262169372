import React, { useEffect, useRef } from 'react'
import { TextField, makeStyles } from '@material-ui/core'

import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
  },
  saveAsNew: {
    paddingLeft: 27,
  },
  saveNewForm: {
    marginBottom: theme.spacing(1),
  },
  warning: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.error.main,
  },
}))

const SaveFormTextfield = function ({
  autoFocus,
  inputValue,
  onEnter,
  saveAsNewChecked = false,
  updateInput,
  warning,
}) {
  const classes = useStyles()
  const inputRef = useRef(null)
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  const handleInputValueChange = e => {
    updateInput(e.target.value)
  }

  const handleTextFieldKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onEnter()
    }
  }

  return (
    <div className={saveAsNewChecked ? classes.saveAsNew : {}}>
      <div className={classes.saveNewForm}>
        <TextField
          variant="filled"
          ref={inputRef}
          autoFocus={autoFocus}
          onKeyDown={handleTextFieldKeyDown}
          id="save-textfield"
          onChange={handleInputValueChange}
          error={!!warning}
          value={inputValue}
          className={classes.input}
          type="text"
          label="Saved filter name"
          required
          helperText={warning}
        />
      </div>
    </div>
  )
}

SaveFormTextfield.propTypes = {
  autoFocus: PropTypes.bool.isRequired,
  inputValue: PropTypes.string,
  onEnter: PropTypes.func.isRequired,
  saveAsNewChecked: PropTypes.bool,
  updateInput: PropTypes.func.isRequired,
  warning: PropTypes.string,
}

export default SaveFormTextfield
