import React, { useEffect, useState } from 'react'
import { TableRow, Theme, makeStyles } from '@material-ui/core'

import GroupHeaderRow from 'pages/ContainerPage/JourneyDetails/JourneyDetailRow/GroupHeaderRow'
import GroupMilestoneRow from 'pages/ContainerPage/JourneyDetails/JourneyDetailRow/GroupMilestoneRow'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import { LocationZoneInterface } from 'store/models/LocationZone'
import UiGroupInterface from 'store/types/UiGroupInterface'
import groupLastTracedMilestoneIndex from 'utils/milestone/groupLastTracedMilestoneIndex'

const SPACER_ROW_HEIGHT = 15 // white space between groups

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  spacerRow: {
    height: SPACER_ROW_HEIGHT,
  },
}))

interface Props {
  allGroupsExpanded: boolean
  classes?: { [key: string]: string }
  expanded: boolean
  group: UiGroupInterface
  groupName: string
  locationZone: LocationZoneInterface | null
}

const JourneyDetailRow = (props: Props) => {
  const classes = useStyles(props)
  const { allGroupsExpanded, expanded, group, groupName, locationZone } = props
  const [groupIsExpanded, setGroupIsExpanded] = useState<boolean>(false)
  const milestones = group.milestones || []

  // We use this in `GroupMilestoneRow` to highlight (yellow bg) the name of the last traced milestone
  const lastTracedMilestoneIndex = groupLastTracedMilestoneIndex(group)

  useEffect(() => {
    setGroupIsExpanded(expanded)
  }, [allGroupsExpanded, expanded])

  return (
    <>
      <GroupHeaderRow
        classes={{ root: classes.root }}
        group={group}
        groupName={groupName}
        groupIsExpanded={groupIsExpanded}
        locationZone={locationZone}
        setGroupIsExpanded={setGroupIsExpanded}
      />
      {milestones?.map((milestone: GroupedMilestoneInterface, idx: number) => (
        <GroupMilestoneRow
          classes={{ root: classes.root }}
          expanded={expanded}
          groupIsExpanded={groupIsExpanded}
          isLastTraced={idx === lastTracedMilestoneIndex}
          key={`milestone-row-${idx}`}
          milestone={milestone}
          milestoneCount={milestones.length}
          milestoneIndex={idx}
        />
      ))}
      <TableRow className={classes.spacerRow}></TableRow>
    </>
  )
}

export default React.memo(JourneyDetailRow)
