import * as Yup from 'yup'

import { lowercase, minLength, numeric, specialChar, uppercase } from './validationUtils'

/**
 * Validations should match requirements list in Component
 */
const validationSchema = Yup.string()
  .label('Password')
  .min(minLength.charLength, minLength.errorMessage)
  .test(uppercase.testName, uppercase.errorMessage, uppercase.valid)
  .test(lowercase.testName, lowercase.errorMessage, lowercase.valid)
  .test(numeric.testName, numeric.errorMessage, numeric.valid)
  .test(specialChar.testName, specialChar.errorMessage, specialChar.valid)
  // eslint-disable-next-line no-template-curly-in-string
  .required('${path} is required')

export default validationSchema
