import { Theme, Typography, makeStyles } from '@material-ui/core'

import PaginatorControls from './PaginatorControls'
import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  verticalWrapper: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  horizontalWrapper: {
    display: 'flex',
  },
  pageInfo: {
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap',
  },
  pageInfoVertical: {
    marginTop: theme.spacing(0.5),
  },
  pageInfoHorizontal: {
    margin: `auto ${theme.spacing(1)}px`,
  },
}))

const VERTICAL = 'vertical'
const HORIZONTAL = 'horizontal'

interface Props {
  hidden?: boolean
  extraClasses?: string
  currentPage: number
  totalPages: number
  limit: number
  totalCount: number
  onNextClick: () => void
  onPrevClick: () => void
  variant: typeof VERTICAL | typeof HORIZONTAL
}

const Paginator = (props: Props) => {
  const classes = useStyles(props)
  const { variant = VERTICAL, currentPage, totalCount, extraClasses, limit } = props

  const calculatePageStats = (currentPage: number, limit: number, totalCount: number) => {
    const lowerBound = currentPage * limit
    const upperBound = Math.min(lowerBound + limit, totalCount)
    return { lowerBound, upperBound }
  }

  const { lowerBound, upperBound } = calculatePageStats(currentPage, limit, totalCount)

  return (
    <div
      className={classnames(
        classes.root,
        {
          [classes.verticalWrapper]: variant === VERTICAL,
          [classes.horizontalWrapper]: variant === HORIZONTAL,
        },
        extraClasses
      )}
      data-testid="paginator"
    >
      <Typography
        className={classnames(classes.pageInfo, {
          [classes.pageInfoVertical]: variant === VERTICAL,
          [classes.pageInfoHorizontal]: variant === HORIZONTAL,
        })}
        variant="caption"
      >
        {lowerBound + 1}-{upperBound} of {totalCount}
      </Typography>
      <PaginatorControls {...props} />
    </div>
  )
}

export default Paginator
