import { handleActions } from 'redux-actions'
import round from 'lodash/round'
import { types } from './actions'

// ------------------------------------
// Initial State
// ------------------------------------

export const initialState = {
  error: null,
  lanes: [],
  loading: false,
  rawResponse: {},
  totalContainerVolume: null,
}

// ------------------------------------
// Reducer
// ------------------------------------

const topLanes = handleActions(
  {
    [types.FETCH_TOP_LANES]: (state, { payload }) => {
      return { ...state, loading: true }
    },
    [types.FETCH_TOP_LANES_SUCCESS]: (state, { payload }) => {
      const { total_num_containers, lanes } = payload

      const formattedLanes = lanes.map((lane, i) => {
        const { from_port, to_port, num_containers, lane_time_median } = lane

        return {
          id: `${i}-${from_port.unlocode}-${to_port.unlocode}`,
          origin: from_port.name,
          originUnlocode: from_port.unlocode,
          destination: to_port.name,
          destinationUnlocode: to_port.unlocode,
          containerVolume: num_containers,
          containerVolumePercentage: round((num_containers / total_num_containers) * 100, 2),
          transitTimeMedian: lane_time_median,
        }
      })

      return { ...state, loading: false, lanes: formattedLanes, rawResponse: payload }
    },
    [types.FETCH_TOP_LANES_FAILURE]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        rawResponse: payload,
        error: {
          status: payload.status,
          statusText: payload.statusText,
          messages: payload.data.messages,
        },
      }
    },
  },
  initialState
)

export default topLanes

// ------------------------------------
// Selectors
// ------------------------------------

export const getError = state => state.topLanes.error
export const getLoading = state => state.topLanes.loading
export const getTableHeaders = () => {
  return [
    { id: 'originUnlocode', name: 'Origin Unlocode' },
    { id: 'origin', name: 'Origin' },
    { id: 'destinationUnlocode', name: 'Destination Unlocode' },
    { id: 'destination', name: 'Destination' },
    {
      id: 'transitTimeMedian',
      name: 'Transit Time Median (Days)',
      toolTip: 'Based on container journeys over the last 30 days',
    },
    { id: 'containerVolume', name: 'Container Volume' },
    { id: 'containerVolumePercentage', name: 'Volume as Percent of Total' },
  ]
}
export const getTableRows = state => state.topLanes.lanes
