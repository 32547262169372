import AsyncAutocomplete from 'components/AsyncAutocomplete'
import Button from '@material-ui/core/Button'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import get from 'lodash/get'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

class TextFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: props.value || '',
    }
  }

  handleClose = e => {
    e && e.preventDefault()
    this.props.onRequestClose()
  }

  onInputChange = inputValue => {
    this.setState({ inputValue })
  }

  handleSubmit = e => {
    const { onSubmit } = this.props
    e.preventDefault()
    onSubmit(this.state.inputValue)
    this.handleClose()
  }

  renderInput() {
    const { filterConfig, classes } = this.props
    const { inputValue } = this.state
    const placeholder = get(filterConfig, 'filterData.hintText')
    const lookupPath = get(filterConfig, 'filterData.lookupPath')
    const suggestType = get(filterConfig, 'filterData.suggestType')
    const getSuggestions = get(filterConfig, 'filterData.getSuggestions')

    if (lookupPath) {
      return (
        <AsyncAutocomplete
          defaultValue={inputValue}
          lookupPath={lookupPath}
          suggestType={suggestType}
          getSuggestions={getSuggestions}
          onChange={inputValue => this.setState({ inputValue })}
          placeholder={placeholder}
        />
      )
    }

    return (
      <TextField
        onChange={e => this.setState({ inputValue: e.target.value })}
        value={inputValue}
        className={classes.input}
        type="text"
        placeholder={placeholder}
        name={filterConfig.name}
        required
      />
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
          {this.renderInput()}
          <Button type="submit" className={classes.submitBtn}>
            Apply
          </Button>
        </form>
      </div>
    )
  }
}

export default withStyles(styles)(TextFilter)
