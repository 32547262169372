import sortFieldsToNew from './sortFieldsToNew'

// Converts legacy colon-delimited sort string and returns the supported JSONLogic format
const getSortJsonLogic = (val: string) => {
  // legacy: `pred_discharge:desc`
  const legacyVal = val.split(':')
  if (legacyVal.length !== 2) {
    return null
  }
  const sortField = sortFieldsToNew[legacyVal[0] as keyof typeof sortFieldsToNew]
  const sortAscending = legacyVal[1] === 'asc'
  return [{ expression: { var: sortField }, ascending: sortAscending }]
}

export default getSortJsonLogic
