import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import React from 'react'
import { UNIQUE_ZONES } from 'pages/AlertsPage/AlertSettings/TimezonePicker'
import { UserAlertsInterface } from 'store/portalAlerts/interfaces'
import ValidatedHourInput from '../ValidatedHourInput'
import isString from 'lodash/isString'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialogRoot: {
    padding: 0,
  },
  dialogActions: {
    margin: `${theme.spacing(4)}px -${theme.spacing(6)}px 0 -${theme.spacing(6)}px`,
    borderTop: `1px solid ${theme.palette.grey[100]}`,

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(6),
    },
  },
  filterTextfield: {
    marginLeft: theme.spacing(4),
    width: theme.spacing(27),
  },
  formControl: {
    flexBasis: 0,
    flexGrow: 1,
    minWidth: theme.spacing(25),
  },
  legendStep: {
    color: theme.palette.black,
  },
  narrowInput: {
    minWidth: theme.spacing(18),
    marginRight: theme.spacing(2),
  },
  labelCopy: {
    ...theme.typography.body1,
    color: theme.palette.grey[600],
    zIndex: 1, // without this the text is hidden when dev tools is open??
    fontWeight: theme.typography.fontWeightMedium,
  },
  italicsMarginAndGrey: {
    marginBottom: theme.spacing(1),
    ...theme.typography.body2,
    // TODO is there a palette.text object for this?
    color: theme.palette.grey[500],
    fontStyle: 'italic',
  },
  checkBoxes: {
    marginRight: theme.spacing(6),
  },
  note: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    width: theme.spacing(25),
    border: `2px solid ${theme.palette.warning.light}`,
  },
  selectWithoutLabel: {
    paddingTop: 18,
  },
}))

interface Props {
  values: UserAlertsInterface
  classes?: Record<string, string>
  errors: Record<string, any>
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void
  touched: Record<string, any>
}

const AlertFormStepThree = (props: Props) => {
  const { errors, handleChange, touched, values } = props
  const classes = useStyles(props)
  return (
    <>
      <Box mt={8}>
        <FormLabel className={classes.labelCopy} component="legend">
          <span className={classes.legendStep}>Step 3.</span> Choose the frequency of notification
          <Box mt={1}>
            <Typography variant="body2" className={classes.italicsMarginAndGrey} component="div">
              You will be emailed only if the alert has been triggered.
            </Typography>
          </Box>
        </FormLabel>
      </Box>
      <FormControl className={classes.formControl} variant="filled">
        <InputLabel>Frequency</InputLabel>
        <Select
          labelId="notifFrequency"
          onChange={handleChange}
          value={values.notifFrequency || ''}
          name="notifFrequency"
          data-testid="portal-alert-modal__notif-frequency-selector"
        >
          <MenuItem value="daily" key="timezone-daily">
            Once per day
          </MenuItem>
          <MenuItem value="instant" key="timezone-instant">
            Immediately
          </MenuItem>
        </Select>
        <FormHelperText error={touched.notifFrequency}>
          {errors.notifFrequency && errors.notifFrequency}
        </FormHelperText>
      </FormControl>
      {values.notifFrequency !== 'instant' && (
        <Box mt={2}>
          <FormControl
            variant="filled"
            error={Boolean(errors.notifTime && touched.notifTime)}
            className={classes.narrowInput}
          >
            <TextField
              value={values.notifTime}
              variant="filled"
              label="Time (24-hr Format)"
              InputProps={{
                inputComponent: ValidatedHourInput as any,
              }}
              onChange={handleChange}
              name="notifTime"
              data-testid="portal-alert-modal__notif-time-selector"
              error={Boolean(errors.notifTime && touched.notifTime)}
            />
            {errors.notifTime && isString(errors.notifTime) && (
              <FormHelperText>{errors.notifTime}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            className={classes.narrowInput}
            variant="filled"
            error={Boolean(errors.notifTimezone && touched.notifTimezone)}
          >
            <InputLabel>Time Zone</InputLabel>
            <Select
              labelId="notifTimezone"
              onChange={handleChange}
              value={values.notifTimezone || ''}
              name="notifTimezone"
              data-testid="portal-alert-modal__notif-timezone-selector"
            >
              {UNIQUE_ZONES.map((option, index) => {
                const formattedOption = option.replace('_', ' ')
                return (
                  <MenuItem value={option} key={index}>
                    {formattedOption}
                  </MenuItem>
                )
              })}
            </Select>
            {errors.notifTimezone && isString(errors.notifTimezone) && (
              <FormHelperText>{errors.notifTimezone}</FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
    </>
  )
}

export default AlertFormStepThree
