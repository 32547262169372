import CardOverlay from 'components/core/CardOverlay'
import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from 'components/Skeleton'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core'

const border = theme => `1px solid ${theme.palette.grey[100]}`

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: border(theme),
  },
  rightSection: {
    width: '100%',
    borderLeft: border(theme),
    borderRight: border(theme),
  },
  row: {
    display: 'flex',
    height: 100,
    borderTop: border(theme),
    '&:last-child': {
      borderBottom: border(theme),
    },
  },
  data: {
    marginLeft: 24,
    marginRight: 24,
  },
  mediumResponsive: {
    flex: 1,
  },
  longResponsive: {
    flex: 2,
  },
}))

function Row({ isAnimated }) {
  const classes = useStyles()
  const height = 16
  const shortWidth = 16
  const longWidth = 140

  return (
    <div className={classes.row}>
      <div className={classes.section}>
        <Skeleton
          className={classes.data}
          height={height}
          isAnimated={isAnimated}
          width={shortWidth}
        />
        <Skeleton
          className={classes.data}
          height={height}
          isAnimated={isAnimated}
          width={longWidth}
        />
        <Skeleton
          className={classes.data}
          height={height}
          isAnimated={isAnimated}
          width={longWidth}
        />
      </div>
      <div className={cx(classes.section, classes.rightSection)}>
        <Skeleton
          className={cx(classes.data, classes.longResponsive)}
          height={height}
          isAnimated={isAnimated}
        />
        <Skeleton
          className={cx(classes.data, classes.mediumResponsive)}
          height={height}
          isAnimated={isAnimated}
        />
        <Skeleton
          className={cx(classes.data, classes.mediumResponsive)}
          height={height}
          isAnimated={isAnimated}
        />
        <Skeleton
          className={cx(classes.data, classes.longResponsive)}
          height={height}
          isAnimated={isAnimated}
        />
        <Skeleton
          className={cx(classes.data, classes.longResponsive)}
          height={height}
          isAnimated={isAnimated}
        />
        <Skeleton
          className={cx(classes.data, classes.longResponsive)}
          height={height}
          isAnimated={isAnimated}
        />
      </div>
    </div>
  )
}

TableSkeleton.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.element]),
  rowCount: PropTypes.number,
  isAnimated: PropTypes.bool.isRequired,
  cardOverlayClasses: PropTypes.object,
}

TableSkeleton.defaultProps = {
  rowCount: 3,
  isAnimated: true,
  cardOverlayClasses: {},
}

export default function TableSkeleton({ message, rowCount, isAnimated, cardOverlayClasses }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        {[...Array(rowCount)].map((_, i) => (
          <Row isAnimated={isAnimated} key={i} />
        ))}
      </div>
      {message && <CardOverlay classes={cardOverlayClasses}>{message}</CardOverlay>}
    </div>
  )
}
