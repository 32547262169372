import { default as MUITableHead, TableHeadProps } from '@material-ui/core/TableHead'
import { Theme, makeStyles } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
}))

const TableHead = (props: TableHeadProps) => {
  const classes = useStyles(props)
  return <MUITableHead classes={classes} {...props} />
}

export default TableHead
