export default theme => ({
  clearFiltersBtn: {
    color: theme.palette.grey[500],
    display: 'inline-block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    margin: '0 4%',
    paddingBottom: 5,
    paddingTop: 5,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  filterActions: {
    display: 'flex',
  },
})
