import { AuthSubtitle, AuthTitle } from 'components/AuthTitle'
import { Box, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { SSO_CHOOSE_ROUTE, SSO_PASSWORD_ROUTE } from 'utils/routes'
import { authStageFromState, loginErrorMessageFromState } from 'store/auth/login/selectors'
import { setAuthStage, setIntendedUsername } from 'store/auth/login/actions'
import { useDispatch, useSelector } from 'react-redux'

import AuthDialog from 'components/AuthDialog'
import CenteredLayout from 'components/layouts/CenteredLayout'
import EmailForm from './EmailForm'
import { getIsPortalPage } from 'utils/routes/portal'
import { loadOauthStatus } from 'store/oauthStatus/actions'
import { makeStyles } from '@material-ui/core'
import oauthRedirectErrorMessages from 'pages/FlaggedLogin/LoginPage/oauthRedirectErrorMessages'
import { oauthStatusSelector } from 'store/oauthStatus/selectors'
import { useQuery } from 'utils/hooks'

// https://stackoverflow.com/a/48138690
interface MatchParams {
  extension?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  newAuthTitle: {
    paddingTop: theme.spacing(3),
  },
  newAuthSubtitle: {
    color: theme.palette.grey[500],
  },
  loginErrorMessage: {
    '& a': {
      color: 'inherit',
    },
  },
}))

const EmailPage = (props: Props) => {
  const { match } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const oauthStatus = useSelector(oauthStatusSelector)
  const loginErrorMessage = useSelector(loginErrorMessageFromState)
  const authStage = useSelector(authStageFromState)
  const query = useQuery()
  const onSubmitEmail = (params: { email: string }) => {
    dispatch(loadOauthStatus(params.email))
    dispatch(setIntendedUsername(params.email))
  }

  const isPortal = getIsPortalPage(match)
  const classes = useStyles()
  const oauthRedirectErrorCode = query.get('sso_error')
  const oauthRedirectErrorMessage = oauthRedirectErrorCode
    ? oauthRedirectErrorMessages[oauthRedirectErrorCode]
    : null
  // move on to choosing login method
  useEffect(() => {
    if (authStage === 'email' && oauthStatus.data && !loginErrorMessage) {
      // go to SSO page if uses sso. if not, log in with password
      if (oauthStatus.data?.usesSso) {
        dispatch(setAuthStage('sso'))
        history.push(SSO_CHOOSE_ROUTE.buildUrl())
      } else {
        dispatch(setAuthStage('password'))
        history.push(SSO_PASSWORD_ROUTE.buildUrl())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStage, oauthStatus, loginErrorMessage])

  const displayErrorMessage = loginErrorMessage !== null || oauthRedirectErrorMessage !== null
  return (
    <CenteredLayout className={classes.root}>
      <AuthDialog>
        <AuthTitle className={classes.newAuthTitle}>
          {isPortal ? 'Welcome to the project44 Portal!' : 'Welcome to project44!'}
        </AuthTitle>
        <AuthSubtitle className={classes.newAuthSubtitle}>
          Please enter your email and click Continue to verify your account.
        </AuthSubtitle>
        {displayErrorMessage ? (
          <Box mt={3}>
            <Typography color="error" variant="body2" className={classes.loginErrorMessage}>
              {loginErrorMessage || oauthRedirectErrorMessage}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <EmailForm onSubmit={onSubmitEmail} authorizing={oauthStatus.isLoading} />
      </AuthDialog>
    </CenteredLayout>
  )
}

export default EmailPage
