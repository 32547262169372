import getDischargeLocationMilestone from './getDischargeLocationMilestone'
import getLastTracedMilestone from './getLastTracedMilestone'
import getMilestoneDisplayText from './getMilestoneDisplayText'
import getOriginMilestone from './getOriginMilestone'
import getPlannedTimeDelay from './getPlannedTimeDelay'
import getTimelineMilestoneDisplayText from './getTimelineMilestoneDisplayText'
import getTracedTimeDelay from './getTracedTimeDelay'
import groupLastTracedMilestoneIndex from './groupLastTracedMilestoneIndex'

export default {
  getDischargeLocationMilestone,
  getLastTracedMilestone,
  getMilestoneDisplayText,
  getOriginMilestone,
  getPlannedTimeDelay,
  getTimelineMilestoneDisplayText,
  getTracedTimeDelay,
  groupLastTracedMilestoneIndex,
}
