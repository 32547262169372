import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchUserInfo: () => {
    return api._call('get', `/user/info`)
  },
  fetchAuthStatus: () => {
    return api._call('get', `/auth/status`)
  },
}

export default api
