import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import { Tenant } from './models'
import api from '../api'
import browserHistory from 'utils/history'
import humps from 'humps'
import logger from 'utils/logger'
import { login } from 'store/auth/login/actions'
import { logout } from 'store/auth/logout/actions'
import { toQueryString } from 'utils/urlBuilder'
import { types } from '../actions'

export function* switchTenantAsync(action) {
  try {
    const { username, tenant, password } = action.payload
    const email = username
    yield call(api.switchTenant, { email, tenant })
    yield put({ type: types.TENANT_SWITCH_SUCCESS })
    yield put(
      showNotification(MESSAGES.tenantSwitchSucccess, {
        type: MESSAGE_TYPES.INFO,
      })
    )
    const { pathname, query } = browserHistory.location
    const url = pathname + toQueryString(query)
    yield put(logout())
    yield put(login(email, password, url, true)) // page forceReload so that no data from previous tenant is cached
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error switching tenant: ${e}`, 'error')
    yield put(showNotification(MESSAGES.tenantSwitchError, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* addTenantAsync(action) {
  try {
    yield call(api.addTenant, humps.decamelizeKeys(action.payload))
    yield put({ type: types.TENANT_ADD_SUCCESS })
    yield put(
      showNotification(MESSAGES.tenantAddSuccess, {
        type: MESSAGE_TYPES.SUCCESS,
      })
    )
  } catch (e) {
    logger.captureAPIException(e)
    yield put(showNotification(MESSAGES.tenantAddError, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* addSourceAsync(action) {
  try {
    const source = action.payload
    yield call(api.addSource, { name: source })
    yield put({ type: types.SOURCE_ADD_SUCCESS })
    yield put(
      showNotification(MESSAGES.sourceAddSuccess, {
        type: MESSAGE_TYPES.SUCCESS,
      })
    )
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error adding source: ${e}`, 'error')
    yield put(showNotification(MESSAGES.sourceAddError, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* listTenantsAsync(action) {
  try {
    const response = yield call(api.fetchTenantList)
    const tenants = [...response.data].map(tenant => Tenant.of(tenant))
    yield put({ type: types.TENANT_LIST_SUCCESS, payload: tenants })
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error listing tenants: ${e}`, 'error')
    yield put(showNotification(MESSAGES.tenantLoadError, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* watchTenant() {
  yield all([
    takeLatest(types.TENANT_SWITCH_LOAD, switchTenantAsync),
    takeLatest(types.TENANT_LIST_LOAD, listTenantsAsync),
    takeLatest(types.TENANT_ADD_LOAD, addTenantAsync),
    takeLatest(types.SOURCE_ADD_LOAD, addSourceAsync),
  ])
}
