import './DateExactPicker.css'

import * as moment from 'moment'

import React, { useState } from 'react'
import { Theme, makeStyles } from '@material-ui/core'

import { SingleDatePicker } from 'react-dates'

const DATE_FORMAT = 'D MMM YYYY'

const useStyles = makeStyles((theme: Theme) => ({
  rangeWrapper: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

type Date = moment.Moment | null

type DateExactPickerProps = {
  defaultValue: Date
  onChange: (day: Date) => undefined
  openDirection: 'down' | 'up' | undefined
}

const DateExactPicker = (props: DateExactPickerProps) => {
  const { onChange, openDirection, defaultValue = null } = props
  const classes = useStyles()
  const [focused, setFocused] = useState<boolean>(false)
  /**
   * Type `any` because MomentJS types are not up to date. If we use `<Date>`:
   * >  Property 'isoWeeksInISOWeekYear' is missing in type
   */
  const [date, setDate] = useState<any>(defaultValue)

  const onDateChange = (date: any) => {
    onChange(date)
    setDate(date)
    setFocused(false)
  }

  const onFocusChange = ({ focused }: any) => setFocused(focused)

  return (
    <div className={classes.rangeWrapper}>
      <SingleDatePicker
        id="data-exact-picker"
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
        openDirection={openDirection}
        focused={focused}
        date={date}
        small={true}
        isOutsideRange={() => false}
        numberOfMonths={1}
        daySize={30}
        displayFormat={DATE_FORMAT}
        hideKeyboardShortcutsPanel={true}
      />
    </div>
  )
}

export default DateExactPicker
