import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const Project44LogoWhite = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="38 45 666 231">
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M73.06,111.01h8.05c13.95,0,20.92,6.82,20.92,20.46v71.51c0,13.49-6.98,20.24-20.92,20.24h-8.05
			c-7.51,0-12.26-2.99-14.26-8.97v40.7H38.11V111.01h20.69v8.97C60.8,114,65.55,111.01,73.06,111.01z M81.34,199.76v-65.3
			c0-2.76-0.46-4.56-1.38-5.41c-0.92-0.84-2.76-1.26-5.52-1.26h-8.05c-2.76,0-4.72,0.61-5.87,1.84c-1.14,1.23-1.72,3.3-1.72,6.21
			v62.54c0,2.9,0.58,4.98,1.72,6.21c1.15,1.23,3.11,1.84,5.87,1.84h8.05c2.76,0,4.6-0.42,5.52-1.26
			C80.88,204.33,81.34,202.52,81.34,199.76z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M150.54,110.78h4.6v17.94h-8.28c-9.65,0-14.48,5.67-14.48,17.02v77.49h-20.69V111.01h20.69v13.8
			c1.23-3.99,3.41-7.32,6.55-10C142.07,112.13,145.94,110.78,150.54,110.78z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M184.11,111.01h24.6c14.26,0,21.38,6.82,21.38,20.46v71.51c0,13.49-7.13,20.24-21.38,20.24h-24.6
			c-14.1,0-21.15-6.75-21.15-20.24v-71.51C162.96,117.83,170.01,111.01,184.11,111.01z M209.64,200v-65.76
			c0-2.76-0.46-4.6-1.38-5.52c-0.92-0.92-2.76-1.38-5.52-1.38h-12.65c-2.76,0-4.56,0.46-5.4,1.38c-0.85,0.92-1.27,2.76-1.27,5.52
			V200c0,2.76,0.42,4.6,1.27,5.52c0.84,0.92,2.64,1.38,5.4,1.38h12.65c2.76,0,4.6-0.46,5.52-1.38
			C209.18,204.6,209.64,202.76,209.64,200z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M240.68,231.96V111.01h20.69v123.7c0,13.48-7.12,20.24-21.38,20.24h-10.35v-16.32h4.14
			c2.76,0,4.6-0.46,5.52-1.39C240.21,236.32,240.68,234.56,240.68,231.96z M240.21,89.63V69.17h21.61v20.47H240.21z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M293.33,111.01h22.53c14.41,0,21.61,6.82,21.61,20.46v42.08h-45.3v26.67c0,2.61,0.46,4.37,1.38,5.29
			c0.92,0.92,2.76,1.38,5.52,1.38h10.58c2.76,0,4.6-0.46,5.52-1.38c0.92-0.92,1.38-2.76,1.38-5.52v-9.19h20.24v12.18
			c0,13.49-7.13,20.24-21.38,20.24h-22.07c-14.26,0-21.38-6.75-21.38-20.24v-71.51C271.94,117.83,279.07,111.01,293.33,111.01z
			 M292.18,158.38h24.83v-24.37c0-2.61-0.43-4.37-1.27-5.29c-0.85-0.92-2.64-1.38-5.4-1.38h-11.27c-2.76,0-4.6,0.46-5.52,1.38
			c-0.92,0.92-1.38,2.68-1.38,5.29V158.38z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M412.89,146.42h-20.46v-11.96c0-2.76-0.46-4.56-1.38-5.41c-0.92-0.84-2.76-1.26-5.52-1.26h-9.88
			c-2.76,0-4.6,0.42-5.52,1.26c-0.92,0.85-1.38,2.65-1.38,5.41v65.3c0,2.76,0.46,4.57,1.38,5.41c0.92,0.84,2.76,1.26,5.52,1.26h9.88
			c2.76,0,4.6-0.42,5.52-1.26c0.92-0.84,1.38-2.65,1.38-5.41v-11.95h20.46v15.17c0,13.49-7.21,20.24-21.61,20.24h-21.85
			c-14.25,0-21.38-6.75-21.38-20.24v-71.51c0-13.64,7.13-20.46,21.38-20.46h21.85c14.41,0,21.61,6.82,21.61,20.46V146.42z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M468.45,127.8h-15.41v71.05c0,2.75,0.42,4.56,1.27,5.4c0.84,0.84,2.64,1.26,5.41,1.26h8.74v17.71h-14.71
			c-14.41,0-21.61-6.75-21.61-20.24V127.8h-10.58v-16.79h10.58V91.93h20.92v19.08h15.41V127.8z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M667.75,79.41L667.75,79.41c-21.4-21.41-49.85-33.19-80.11-33.19c-30.27,0-58.73,11.79-80.13,33.19
			c-44.16,44.17-44.16,116.04,0,160.23h0.01c43.83,43.82,114.93,44.16,159.18,1.01c0.35-0.34,0.7-0.67,1.05-1.01
			c21.4-21.41,33.2-49.86,33.18-80.12C700.95,129.26,689.16,100.81,667.75,79.41z M583.18,191.08h-13.23v32.25H549.4v-32.25h-50.57
			v-19.52l30.54-79.75h21.02l-31.23,80.01h30.24v-31.22h20.55v31.22h13.23V191.08z M661.27,191.08v32.25h-20.56v-32.25h-50.54
			v-19.52l30.53-79.75h21.02l-31.25,80.01h30.24v-31.22h20.56v31.22h13.23v19.25H661.27z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default Project44LogoWhite
