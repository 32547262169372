import { AUTOCOMPLETE_KEY } from 'store/autocomplete/actions'

export function autocompleteInputSelector(state, filter, autocompleteKey) {
  const inputs = state[AUTOCOMPLETE_KEY][autocompleteKey].filterInputs
  return inputs[filter.name] ? inputs[filter.name] : ''
}

export function autocompleteValueSelector(state, filter, autocompleteKey) {
  const values = state[AUTOCOMPLETE_KEY][autocompleteKey].filterValues
  return values[filter.name] ? values[filter.name] : []
}
