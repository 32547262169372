import PropTypes from 'prop-types'
import React from 'react'
import { formatVesselLabel } from 'utils/strUtils'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  incompleteText: {
    color: theme.palette.grey[50],
    fontSize: 11,
    letterSpacing: 0.1,
  },
  vesselName: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12,
  },
}))

export default function IncompleteVessels({ vesselsWithMissingData }) {
  const classes = useStyles()

  return vesselsWithMissingData.map((item, index) => {
    const { vesselTrip, missingStart, missingEnd } = item
    let vesselName = formatVesselLabel(vesselTrip.name)
    if (!vesselName) vesselName = vesselTrip.lloydsCode
    if (!vesselName) vesselName = '[Vessel name N/A]'

    let andText = ''
    if (missingStart > 0 && missingEnd > 0) andText = ' and '
    return (
      <div className={classes.incompleteText} key={`incomplete-${index}`}>
        <span className={classes.vesselName}>{vesselName}: </span>
        {missingStart > 0 && (
          <span>AIS data not reported for first {missingStart} days of trip</span>
        )}
        {andText}
        {missingEnd > 0 && <span>AIS data not reported for last {missingEnd} days of trip</span>}
      </div>
    )
  })
}

IncompleteVessels.propTypes = {
  vesselsWithMissingData: PropTypes.array.isRequired,
}

IncompleteVessels.defaultProps = {
  vesselsWithMissingData: [],
}
