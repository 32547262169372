import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CheckboxBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckboxIcon from '@material-ui/icons/CheckBox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import styles from './styles'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

class MultiSelectFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: get(props, 'value', []),
      warning: '',
      allSelected: false,
    }
  }

  handleClose = e => {
    e && e.preventDefault()
    this.props.onRequestClose()
  }

  handleSubmit = e => {
    const { onSubmit } = this.props
    const { selected } = this.state
    if (selected.length) {
      e.preventDefault()
      onSubmit(selected)
      this.handleClose()
    } else {
      this.setState({
        warning: 'Please select at least one option',
      })
    }
  }

  handleChange = item => event => {
    let selected = this.state.selected.slice()
    let allSelected = this.state.allSelected
    if (event.target.checked) {
      selected.push(item)
    } else {
      const index = findIndex(selected, listItem => listItem.value === item.value)
      selected.splice(index, 1)
      allSelected = false
    }
    this.setState({
      selected,
      allSelected,
    })
  }

  handleAllSelect = event => {
    const { filterConfig } = this.props
    const options = get(filterConfig, 'filterData.options')
    if (event.target.checked) {
      const selected = options.slice() // copy selected
      this.setState({
        selected: selected,
        allSelected: true,
      })
    } else {
      this.setState({
        selected: [],
        allSelected: false,
      })
    }
  }

  render() {
    const { selected, allSelected, warning } = this.state
    const { filterConfig, classes } = this.props
    const options = get(filterConfig, 'filterData.options')
    let prevCategory

    // const { filterConfig, classes } = this.props
    // const { selected } = this.state

    const commonOptions = get(filterConfig, 'filterData.commonOptions', [])

    return (
      <div className={classes.container}>
        <div className={classes.formGroupRoot}>
          <FormControlLabel
            key={'all'}
            classes={{ label: classes.label, root: classes.formControl }}
            control={
              <Checkbox
                color="primary"
                checked={allSelected}
                onChange={this.handleAllSelect}
                value={'all'}
                icon={<CheckboxBlankIcon className={classes.checkIcon} />}
                checkedIcon={<CheckboxIcon className={classes.checkIcon} />}
              />
            }
            label={'Select all'}
          />
          <Typography variant="body2" className={classes.grandCategory} style={{ marginTop: 0 }}>
            Common Milestones
          </Typography>
          {commonOptions &&
            commonOptions.map((item, index) => {
              const itemSelected =
                findIndex(selected, listItem => listItem.value === item.value) !== -1
              return (
                <FormControlLabel
                  key={index}
                  classes={{ label: classes.label, root: classes.formControl }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={itemSelected}
                      onChange={this.handleChange(item)}
                      value={item.value}
                      icon={<CheckboxBlankIcon className={classes.checkIcon} />}
                      checkedIcon={<CheckboxIcon className={classes.checkIcon} />}
                    />
                  }
                  label={item.label}
                />
              )
            })}
          <Typography variant="body2" className={classes.grandCategory}>
            All Milestones
          </Typography>
          {options.map((item, index) => {
            const itemSelected =
              findIndex(selected, listItem => listItem.value === item.value) !== -1
            let category
            if (prevCategory !== item.type) {
              category = (
                <Typography variant="body2" className={classes.category}>
                  {item.type}
                </Typography>
              )
            }
            prevCategory = item.type
            return (
              <React.Fragment key={`option-${index}`}>
                {category}
                <FormControlLabel
                  key={index}
                  classes={{ label: classes.label, root: classes.formControl }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={itemSelected}
                      onChange={this.handleChange(item)}
                      value={item.value}
                      icon={<CheckboxBlankIcon className={classes.checkIcon} />}
                      checkedIcon={<CheckboxIcon className={classes.checkIcon} />}
                    />
                  }
                  label={item.label}
                />
              </React.Fragment>
            )
          })}
        </div>
        {warning && (
          <Typography variant="body2" className={classes.warning}>
            {this.state.warning}
          </Typography>
        )}
        <Button className={classes.submitBtn} onClick={this.handleSubmit}>
          Apply
        </Button>
      </div>
    )
  }
}

export default compose(withRouter, withStyles(styles))(MultiSelectFilter)
