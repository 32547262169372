import humps from 'humps'

export class Tenant {
  constructor(id, displayName, name, parentId, urlSubdomain) {
    this.id = id
    this.displayName = displayName
    this.name = name
    this.parentId = parentId
    this.urlSubdomain = urlSubdomain
  }

  static of(payload) {
    const { id, displayName, name, parentId, urlSubdomain } = humps.camelizeKeys(payload)

    return new Tenant(id, displayName, name, parentId, urlSubdomain)
  }
}
