import { Typography, withStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import styles from './styles'

export const AuthSubtitle = ({ children, className, ...rest }) => (
  <Typography variant="subtitle1" className={className} {...rest}>
    {children}
  </Typography>
)

const AuthTitleBase = ({ classes, className, children, ...rest }) => (
  <Typography variant="h6" className={classnames([classes.authTitle, className])} {...rest}>
    {children}
  </Typography>
)

AuthTitleBase.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const AuthTitle = withStyles(styles)(AuthTitleBase)
