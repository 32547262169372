import { RailMarker, VesselMarker } from 'components/Maps/Markers'

import { Marker } from 'react-map-gl'
import React from 'react'
import WebMercatorViewport from 'viewport-mercator-project'
import { findDOMNode } from 'react-dom'
import first from 'lodash/first'
import get from 'lodash/get'
import logger from 'utils/logger'
import tinycolor from 'tinycolor2'

const LINE_COLOR = '#2F3E54'
const LINE_WIDTH = 2

export const MARKER_TYPE_RAIL = 'rail'
export const MARKER_TYPE_VESSEL = 'vessel'

export const addLines = (lineData, id, props, live) => {
  const { map } = props

  let lineColor = live ? LINE_COLOR : tinycolor(LINE_COLOR).setAlpha(0.4).toRgbString()

  let layer = {
    id: id,
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: lineData.reduce((accumulator, item) => {
            accumulator = accumulator.concat([item.sourcePosition, item.targetPosition])
            return accumulator
          }, []),
        },
      },
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': LINE_WIDTH,
    },
  }
  map.addLayer(layer)
  return id
}

export const addLineLayers = (props, updateLayerState) => () => {
  const { vesselPaths, map } = props
  let layers = []

  for (let i = 0; i < vesselPaths.length; i++) {
    const vesselTrip = vesselPaths[i]
    const id = vesselTrip.lloydsCode
    if (id) {
      removeLayer(vesselTrip.lloydsCode, map)
      let newLayer = addLines(vesselTrip.path, vesselTrip.lloydsCode, props, vesselTrip.live)
      layers.push(newLayer)
    }
  }
  updateLayerState(layers)
}

export const adjustViewport = props => {
  if (props.focused) {
    const vesselLocations = props.vesselLocations
    const map = findDOMNode(props.mapNode)
    const mapEl = map.getBoundingClientRect()
    const viewport = new WebMercatorViewport({ width: mapEl.width, height: mapEl.height })
    let bounds = []
    for (let key in vesselLocations) {
      const item = vesselLocations[key]
      const lon = get(item, 'vesselLoc.lon')
      const lat = get(item, 'vesselLoc.lat')
      if (lon && lat) {
        bounds.push([Number(lon), Number(lat)])
      }
    }
    if (bounds.length > 0) {
      try {
        const furthest = getFurthestPoints(bounds, viewport)
        const newBounds = viewport.fitBounds(furthest, { padding: 70 })

        const newViewport = Object.assign({}, props.viewport, {
          zoom: Math.min(newBounds.zoom, props.maxZoom),
          longitude: newBounds.longitude,
          latitude: newBounds.latitude,
        })
        props.updateViewport(newViewport)
      } catch (e) {
        logger.log('Failed to update viewport', e)
      }
    }
  }
}

export const alterPoint = vesselLoc => {
  if (vesselLoc && vesselLoc.length) {
    return [vesselLoc[0] + 10, vesselLoc[1] + 10]
  }
}

export const datesDiffer = (oldDate, newDate) => {
  try {
    return oldDate.isValid() && newDate.isValid() && oldDate.diff(newDate) !== 0
  } catch (e) {
    return false
  }
}

export const distance = (lat1, lon1, lat2, lon2) => {
  let radlat1 = (Math.PI * lat1) / 180
  let radlat2 = (Math.PI * lat2) / 180
  let theta = lon1 - lon2
  let radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  return dist
}

export const getFurthestPoints = (allPoints, viewport) => {
  let maxDist = 0
  let bounds = [first(allPoints), alterPoint(first(allPoints))] // by default just set to first vesselLoc
  for (let i = 0; i < allPoints.length; i++) {
    let pair = allPoints[i]
    for (let j = 0; j < allPoints.length; j++) {
      let pair2 = allPoints[j]
      if (i !== j) {
        let newDist = distance(pair[1], pair[0], pair2[1], pair2[0])
        if (newDist > maxDist) {
          bounds = [pair, pair2]
          maxDist = newDist
        }
      }
    }
  }
  return bounds
}

export const getMarkers = (vesselLocations, markerType = MARKER_TYPE_VESSEL, props) => {
  const markers = []
  let index = 0
  for (let lloydsCode in vesselLocations) {
    const vesselObj = vesselLocations[lloydsCode]
    const { vesselLoc } = vesselObj

    const markerProps = (offset = 0) => ({
      ...vesselObj,
      ...{
        location: {
          lat: parseFloat(vesselLoc.lat) + offset,
          lon: parseFloat(vesselLoc.lon) + offset,
        },
      },
    })

    markers.push(
      <Marker key={'marker-' + index} longitude={vesselLoc.lon} latitude={vesselLoc.lat}>
        {markerType === MARKER_TYPE_RAIL ? (
          <RailMarker onClick={selectVessel(props, markerProps(0), MARKER_TYPE_RAIL)} />
        ) : (
          <VesselMarker onClick={selectVessel(props, markerProps(0), MARKER_TYPE_VESSEL)} />
        )}
      </Marker>
    )

    // a mapbox hack to get markers to show across dateline
    markers.push(
      <Marker
        key={'marker-copy-0-' + index}
        longitude={vesselLoc.lon + 360}
        latitude={vesselLoc.lat + 360}
      >
        {markerType === MARKER_TYPE_RAIL ? (
          <RailMarker onClick={selectVessel(props, markerProps(360), MARKER_TYPE_RAIL)} />
        ) : (
          <VesselMarker onClick={selectVessel(props, markerProps(360), MARKER_TYPE_VESSEL)} />
        )}
      </Marker>
    )

    // a mapbox hack to get markers to show across dateline
    markers.push(
      <Marker
        key={'marker-copy-1-' + index}
        longitude={vesselLoc.lon - 360}
        latitude={vesselLoc.lat - 360}
      >
        {markerType === MARKER_TYPE_RAIL ? (
          <RailMarker onClick={selectVessel(props, markerProps(-360), MARKER_TYPE_RAIL)} />
        ) : (
          <VesselMarker onClick={selectVessel(props, markerProps(-360), MARKER_TYPE_VESSEL)} />
        )}
      </Marker>
    )
    index += 1
  }
  return markers
}

export const newVesselTrips = (oldVesselPaths, newVesselPaths) => {
  if (oldVesselPaths.length !== newVesselPaths.length) return true
  for (let i = 0; i < oldVesselPaths.length; i++) {
    let oldVesselPath = oldVesselPaths[i]
    let newVesselPath = newVesselPaths[i]
    if (
      oldVesselPath.lloydsCode !== newVesselPath.lloydsCode ||
      datesDiffer(oldVesselPath.startDate, newVesselPath.startDate) ||
      datesDiffer(oldVesselPath.endDate, newVesselPath.endDate)
    ) {
      return true
    }
  }
  return false
}

export const removeLayer = (id, map) => {
  const currLayer = map.getLayer(id)
  if (currLayer) map.removeLayer(id)
  // remove the source if it exists
  const currSource = map.getSource(id)
  if (currSource) {
    map.removeSource(id)
  }
}

export const selectVessel = (props, vesselObj, equipmentType = MARKER_TYPE_VESSEL) => () => {
  const { onSelect } = props
  onSelect(vesselObj, equipmentType)
}

export const vesselLocationsLoaded = (prevLocs, nextLocs) => {
  return Object.keys(prevLocs).length !== Object.keys(nextLocs).length
}

export const vesselPathsLoaded = (prevVesselData, nextVesselData) => {
  if (prevVesselData.length !== nextVesselData.length) return true
  for (let i = 0; i < prevVesselData.length; i++) {
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
      lloydsCode: prevLloydsCode,
      path: oldPath,
    } = prevVesselData[i]
    const {
      startDate: nextStartDate,
      endDate: nextEndDate,
      lloydsCode: nextLloydsCode,
      path: newPath,
    } = nextVesselData[i]
    if (
      datesDiffer(prevStartDate, nextStartDate) ||
      datesDiffer(prevEndDate, nextEndDate) ||
      prevLloydsCode !== nextLloydsCode ||
      oldPath.length !== newPath.length
    ) {
      return true
    }
  }
  return false
}
