const TransportStatus = {
  completed: 'Completed',
  delivered: 'Delivered',
  in_progress: 'In Progress',
  in_transit: 'In Transit',
  not_started: 'Not Started',
  no_data: 'No Data',
  out_for_delivery: 'Out For Delivery',
  NOT_STARTED: 'No Data',
  STARTED: 'In Transit',
  DISCHARGED: 'Completed',
  DELIVERED: 'Delivered',
  UNFINISHED_BUT_INACTIVE: 'Unknown Status',
} as const

export type TransportStatusType = typeof TransportStatus[keyof typeof TransportStatus]

export default TransportStatus
