// Theme is passed from material-ui withStyles
export default theme => ({
  toolbar: {
    borderRadius: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0 0`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#66717E',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0.2,
    color: 'white',
    whiteSpace: 'nowrap',
    flex: 1,
  },
  closeButton: {
    color: 'white',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    width: 18,
    height: 18,
  },
  filterComponentPopover: {
    maxWidth: 350,
    minWidth: 280,
    minHeight: 200,
    overflow: 'visible',
    marginLeft: 100,
    borderRadius: 4,
  },
  filterComponentContent: {
    borderRadius: '0 0 4px 4px',
    border: `1px solid ${theme.brand.lightDivider}`,
    borderTop: 'none',
  },
  popover: {},
})
