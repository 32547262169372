import { BRAND_COLOR } from '../core/Colors'
import PropTypes from 'prop-types'
import React from 'react'
import Spinner from 'react-spinkit'

export const Loader = ({ style = {}, className = 'text-center', color = BRAND_COLOR }) => (
  <div style={style} className={className}>
    <Spinner name="line-scale" fadeIn="none" color={color} style={{ margin: 'auto' }} />
  </div>
)

Loader.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
  thickness: PropTypes.number,
  className: PropTypes.string,
}
