import { AuthSubtitle, AuthTitle } from 'components/AuthTitle'
import { loggedInFromState, loginLoadingFromState } from 'store/auth/login/selectors'

import AuthDialog from 'components/DeprecatedAuthDialog'
import CenteredLayout from 'components/layouts/CenteredLayout'
import React from 'react'
import ResetPasswordForm from './ResetPasswordForm'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { resetOauthStatus } from 'store/oauthStatus/actions'
import { sendResetPasswordEmail } from 'store/auth/passwordResetEmail/actions'
import { setAuthStage } from 'store/auth/login/actions'
import { setLoginErrorMessage } from 'store/auth/login/actions'
import { useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core'

function ResetPasswordPage({ loading, classes }) {
  const dispatch = useDispatch()
  const onSubmit = email => {
    dispatch(sendResetPasswordEmail(email))
  }

  const onBackToLogin = () => {
    // reset OAuth status so that we won't disable any fields or do any other state stuff
    dispatch(resetOauthStatus())
    dispatch(setAuthStage('email'))
    // clear login message since we're going back to initial email screen
    dispatch(setLoginErrorMessage(null))
  }

  return (
    <CenteredLayout className={classes.root}>
      <AuthDialog>
        <AuthTitle>Password Recovery</AuthTitle>
        <AuthSubtitle>Enter your email to reset your password</AuthSubtitle>
        <ResetPasswordForm onSubmit={onSubmit} onBackToLogin={onBackToLogin} loading={loading} />
      </AuthDialog>
    </CenteredLayout>
  )
}

const mapStateToProps = state => {
  return {
    loggedIn: loggedInFromState(state),
    loading: loginLoadingFromState(state),
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
})

export default compose(connect(mapStateToProps), withStyles(styles))(ResetPasswordPage)
