/* global __TINY_MCE_API_KEY__ */
/* global __CM_ENV__ */

import './index.css'

import {
  DATA_MENTION_ATTR,
  PADDING_INLINE_START,
  mentionStyle,
  moveCursorToEndOfEditor,
  objectToStyleString,
  userDisplayName,
} from '../utils'
import React, { useRef, useState } from 'react'

import { Editor } from '@tinymce/tinymce-react'
import MentionsMenuItem from './MentionsMenuItem'
import MentionsPortal from './MentionsPortal'
import client from 'utils/api/client'
import humps from 'humps'
import { isClearMetalEmail } from 'store/comments/utils'
import keyBy from 'lodash/keyBy'
import logger from 'utils/logger'
import { useTheme } from '@material-ui/core/styles'

const contentStyle = theme => `
  [${DATA_MENTION_ATTR}] { 
    ${objectToStyleString(mentionStyle(theme))} 
  }
  
  ol, ul {
    padding-inline-start: ${PADDING_INLINE_START};
  }
  
  /* Style the placeholder. We have to do this here because it's in an iframe. */
  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: rgba(0, 0, 0, 0.38) !important;
  }
`

export default function CommentEditor({ initialContent, setEditor, onEditorChange, height = 300 }) {
  const [query, setQuery] = useState('')
  const [users, setUsers] = useState([])
  const theme = useTheme()
  const usersCache = useRef(null)

  return (
    <>
      <Editor
        apiKey={__TINY_MCE_API_KEY__}
        initialValue={initialContent}
        onEditorChange={onEditorChange}
        init={{
          width: '100%',
          height: height,
          menubar: false,
          statusbar: false,
          branding: false,
          plugins: 'lists powerpaste mentions',
          toolbar: 'bold italic underline strikethrough numlist bullist',
          paste_as_text: true,
          powerpaste_block_drop: true,
          setup: editor => {
            setEditor(editor)
            editor.on('init', function () {
              // autofocus on load
              editor.execCommand('mceFocus')
              moveCursorToEndOfEditor(editor)
            })
            editor.on('ExecCommand', function (e) {
              // Fire user tracking events
              if (['bold', 'italic', 'underline', 'strikethrough'].includes(e.value)) {
                logger.notify(`Comment Editor Format ${e.value}`)
              } else if (e.command === 'InsertOrderedList') {
                logger.notify(`Comment Editor Format OrderedList`)
              } else if (e.command === 'InsertUnorderedList') {
                logger.notify(`Comment Editor Format UnorderedList`)
              }
            })
          },
          mentions_fetch: async (query, success) => {
            logger.notify('Comment Editor Mention Query', { term: query.term })
            const resp = await client.get(`/user/suggest?q=${query.term}`)
            let data = humps.camelizeKeys(resp.data)
            if (__CM_ENV__ === 'prod') {
              // If this is production, filter out clearmetal users.
              data = data.filter(u => !isClearMetalEmail(u.email))
            }
            usersCache.current = keyBy(data, 'externalId')
            success(data.map(u => ({ id: u.externalId, name: u.email })))
            setQuery(query.term)
            setUsers(data)
          },
          mentions_selector: `span[${DATA_MENTION_ATTR}]`,
          mentions_menu_complete: (editor, userInfo) => {
            if (!usersCache.current) {
              return
            }
            logger.notify('Comment Editor Mention Selected')
            const user = usersCache.current[userInfo.id]
            const span = editor.getDoc().createElement('span')
            span.setAttribute(DATA_MENTION_ATTR, userInfo.id)
            span.appendChild(editor.getDoc().createTextNode(userDisplayName(user)))
            return span
          },
          content_style: contentStyle(theme),
          placeholder: "Type @ to mention a teammate and they'll be notified.",
        }}
      />
      {users.map(user => (
        <MentionsPortal user={user} key={user.externalId}>
          <MentionsMenuItem user={user} query={query} />
        </MentionsPortal>
      ))}
    </>
  )
}
