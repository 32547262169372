import isMatch from './isMatch'
import mergeRoutes from './mergeRoutes'

export const compareFcn = (a, b) => a.milestones.length < b.milestones.length

const alignRoute = (route, allRoutes) => {
  const sortedRoutes = allRoutes.sort(compareFcn)
  for (let i = 0; i < sortedRoutes.length; i++) {
    let compareRoute = sortedRoutes[i]
    if (isMatch(route, compareRoute)) {
      mergeRoutes(route, compareRoute)
      break
    }
  }
}

export default alignRoute
