// Not going to be converted to TS since this is slated to be deleted
import { AuthSubtitle, AuthTitle } from 'components/AuthTitle'
import { loginErrorMessageFromState, loginLoadingFromState } from 'store/auth/login/selectors'

import AuthDialog from 'components/DeprecatedAuthDialog'
import CenteredLayout from 'components/layouts/CenteredLayout'
import PasswordLoginForm from 'pages/FlaggedLogin/PasswordLoginPage/PasswordLoginForm'
import PropTypes from 'prop-types'
import React from 'react'
import authStrings from 'pages/RegistrationPage/content'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { fromQueryString } from 'utils/urlBuilder'
import get from 'lodash/get'
import { getIsPortalPage } from 'utils/routes/portal'
import { login } from 'store/auth/login/actions'
import { withStyles } from '@material-ui/core'

function DeprecatedLoginPage({ classes, loading, login, loginErrorMessage, match, redirectUrl }) {
  const onSubmit = ({ email, password }) => {
    login(email, password, redirectUrl)
  }

  const isPortal = getIsPortalPage(match)

  return (
    <CenteredLayout className={classes.root}>
      <AuthDialog>
        <AuthTitle>Sign In</AuthTitle>
        <AuthSubtitle>{isPortal ? authStrings.portalSubtitle : authStrings.subTitle}</AuthSubtitle>
        <PasswordLoginForm
          onSubmit={onSubmit}
          loading={loading}
          loginErrorMessage={loginErrorMessage}
        />
      </AuthDialog>
    </CenteredLayout>
  )
}

DeprecatedLoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  loading: PropTypes.bool,
}

const mapDispatchToProps = {
  login,
}

const mapStateToProps = (state, ownProps) => {
  const query = fromQueryString(ownProps.location.search)
  return {
    loginErrorMessage: loginErrorMessageFromState(state),
    loading: loginLoadingFromState(state),
    redirectUrl: get(query, 'redirect', '/'),
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DeprecatedLoginPage)
