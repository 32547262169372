import { Box, ButtonBase, IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { GREY, LabelColorVariants } from 'components/StatusLabel/labelColors'
import React, { useRef, useState } from 'react'

import InfoIcon from 'components/icons/InfoIcon'
import { UNKNOWN } from 'store/models/definitions/StatusLabel'
import UnknownStatusPopover from 'components/StatusLabelPopovers/UnknownStatusPopover'
import cx from 'classnames'
import { getVariantStyles } from './utils'

const useStyles = makeStyles(theme => {
  const greyStyles = {
    greyButton: {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[500],
    },
  }

  const blueStyles = {
    blueButton: {
      borderColor: theme.palette.blue[200],
      backgroundColor: theme.palette.blue[50],
      color: theme.palette.blue[400],
    },
  }

  const blue25Styles = {
    blue25Button: {
      borderColor: theme.palette.grey[300],
      backgroundColor: `${theme.palette.blue[25]}`,
      color: theme.palette.grey[800],
    },
  }

  const greenStyles = {
    greenButton: {
      borderColor: theme.palette.green[200],
      backgroundColor: theme.palette.green[50],
      color: theme.palette.green[400],
    },
  }

  const yellowStyles = {
    yellowButton: {
      borderColor: theme.palette.yellow[200],
      backgroundColor: theme.palette.yellow[50],
      color: theme.palette.yellow[500],
    },
  }

  const yellow25Styles = {
    yellow25Button: {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.yellow[25],
      color: `${theme.palette.grey[800]} !important`,
    },
  }

  const grey2Styles = {
    grey2Button: {
      borderColor: `${theme.palette.grey[500]} !important`,
      backgroundColor: `${theme.palette.grey[200]} !important`,
      color: `${theme.palette.grey[800]} !important`,
    },
  }

  const yellow2Styles = {
    yellow2Button: {
      borderColor: `${theme.palette.grey[300]} !important`,
      backgroundColor: `${theme.palette.yellow[250]} !important`,
      color: `${theme.palette.grey[800]} !important`,
    },
  }

  const orangeStyles = {
    orangeButton: {
      borderColor: theme.palette.orange[200],
      backgroundColor: theme.palette.orange[50],
      color: theme.palette.orange[400],
    },
  }
  const purple100Styles = {
    purple100Button: {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.purple[100],
      color: theme.palette.grey[800],
    },
  }
  const redStyles = {
    redButton: {
      borderColor: theme.palette.red[200],
      backgroundColor: theme.palette.red[50],
      color: theme.palette.red[400],
    },
  }
  const grey50Styles = {
    grey50Button: {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
    },
  }

  const statusPendingStyles = {
    statusPendingButton: {
      backgroundColor: theme.palette.named.status.pending,
      borderColor: theme.palette.named.status.pending,
      color: theme.palette.text.primary,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }

  const statusIntransitStyles = {
    statusIntransitButton: {
      backgroundColor: theme.palette.named.status.intransit,
      borderColor: theme.palette.named.status.intransit,
      color: theme.palette.white,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }

  const statusDeliveredStyles = {
    statusDeliveredButton: {
      backgroundColor: theme.palette.named.status.discharged,
      borderColor: theme.palette.named.status.discharged,
      color: theme.palette.white,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }

  const statusDischargedStyles = {
    statusDischargedButton: {
      backgroundColor: theme.palette.named.status.delivered,
      borderColor: theme.palette.named.status.delivered,
      color: theme.palette.white,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }

  const statusUnknownStyles = {
    statusUnknownButton: {
      backgroundColor: theme.palette.named.status.unknown,
      borderColor: theme.palette.named.status.unknown,
      color: theme.palette.white,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }

  return {
    labelText: {
      whiteSpace: 'nowrap',
    },
    controlOverflow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buttonStyle: {
      lineHeight: '14px',
      cursor: 'default',
    },
    infoIcon: {
      color: theme.palette.grey[500],
      height: 14,
      marginLeft: theme.spacing(0.5),
      width: 14,
    },
    // Allows for override of width by calling component
    width: {},
    rootReset: {
      border: 'solid 1px',
      borderRadius: theme.shape.borderRadiusLarge,
      boxShadow: 'none',
      display: 'inline-flex',
      flexDirection: 'column',
      fontSize: theme.typography.caption.fontSize,
      height: theme.spacing(4),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      minWidth: 140,
    },
    expandToContent: {
      minWidth: 'auto',
      width: 'fit-content',
    },
    ...blueStyles,
    ...blue25Styles,
    ...yellow2Styles,
    ...yellow25Styles,
    ...greenStyles,
    ...greyStyles,
    ...grey2Styles,
    ...grey50Styles,
    ...orangeStyles,
    ...purple100Styles,
    ...redStyles,
    ...yellowStyles,
    ...yellow25Styles,
    ...statusDeliveredStyles,
    ...statusDischargedStyles,
    ...statusIntransitStyles,
    ...statusPendingStyles,
    ...statusUnknownStyles,
    short: {
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.caption.fontSize,
      height: 20,
      lineHeight: 'normal',
      '& $icon': {
        top: 1,
      },
    },
    // modeLabelButton is not spread due to a Mui type error throwing on `textTransform`
    modeLabelButton: {
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[800],
      textTransform: 'capitalize',
    },
    iconContainer: {
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.white,
      cursor: 'pointer',
      marginLeft: theme.spacing(0.5),
      padding: 1,
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.12)',
      },
    },
    iconContainerActive: {
      background: 'rgba(255, 255, 255, 0.12)',
    },
    shortLabelIcon: {
      fontSize: 'inherit',
    },
    iconRoot: {},
  }
})

// Used to determine if the `statusPopoverToShow` is valid. If you add more popovers, like
// `UnknownStatusPopover` used here, add a `StatusLabel` to this array.
const VALID_POPOVER_TYPES = [UNKNOWN] as const

interface Props {
  className?: string
  classes?: { [key: string]: string }
  expandToContent?: boolean
  label: React.ReactElement | string
  short?: boolean
  statusPopoverToShow?: typeof VALID_POPOVER_TYPES[number] | null
  tooltipText?: React.ReactNode | string | null
  variant?: LabelColorVariants
}

function StatusLabel(props: Props) {
  const classes = useStyles(props)
  const {
    className,
    expandToContent = false,
    label = 'N/A',
    short = false,
    statusPopoverToShow,
    tooltipText = null,
    variant = GREY,
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const popoverAnchorRef = useRef(null)
  const handleClose = () => setAnchorEl(null)
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(popoverAnchorRef.current)
  }

  // We want _either_ `tooltipText` _or_ `statusPopoverToShow` (or neither) but if we get both, `tooltipText` wins.
  const isValidPopover =
    !tooltipText && statusPopoverToShow && VALID_POPOVER_TYPES.includes(statusPopoverToShow)

  return (
    <div
      className={cx([classes.width, className])}
      id="click-track"
      data-testid="shippingLabel__click-track"
    >
      <Tooltip interactive title={tooltipText ?? ''}>
        <Box>
          <ButtonBase
            ref={statusPopoverToShow ? popoverAnchorRef : null}
            disableRipple={true}
            className={cx(classes.buttonStyle, {
              [classes.short]: short,
              [classes.expandToContent]: expandToContent,
            })}
            component={'div'}
            classes={{
              ...getVariantStyles(classes, variant),
            }}
          >
            <span className={classes.controlOverflow}>
              <span className={classes.labelText}>{label}</span>
              {tooltipText && <InfoIcon className={classes.infoIcon} />}
              {isValidPopover && (
                <IconButton
                  size="small"
                  onClick={handleClick}
                  className={cx(classes.iconContainer, {
                    [classes.iconContainerActive]: anchorEl,
                  })}
                >
                  <InfoIcon
                    className={short ? classes.shortLabelIcon : ''}
                    fontSize="small"
                    classes={{ root: classes.iconRoot }}
                  />
                </IconButton>
              )}
            </span>
          </ButtonBase>
          {isValidPopover && anchorEl && (
            <UnknownStatusPopover anchorEl={anchorEl} handleClose={handleClose} />
          )}
        </Box>
      </Tooltip>
    </div>
  )
}

export default StatusLabel
