import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  validateInvite: ({ token, email }) => {
    return api._call('get', '/user/public/validate_invite', { pwdToken: token, email })
  },
}

export default api
