import CommentsLinkButton, { LOGGER_SEARCH_RESULT } from 'components/CommentsLinkButton'
import {
  ENTITY_NAMES,
  ENTITY_TO_ROLLUP,
  formatSearchResults,
  removeRecentSearch,
  retrieveMostRecentSearches,
  saveRecentSearch,
} from './utils'
import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  fetchBolsSuggest,
  fetchBookingsSuggest,
  fetchContainersSuggest,
  fetchLineItemsSuggest,
  fetchPurchaseOrdersSuggest,
  fetchSalesOrdersSuggest,
  fetchShipmentsSuggest,
  fetchStockTransferOrdersSuggest,
} from 'store/suggest/actions'

import CancelIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import ResultTitle from './ResultTitle'
import SearchIcon from '@material-ui/icons/Search'
import { anyPendingCallsSelector } from 'store/suggest'
import { compose } from 'redux'
import { getShipmentDetailPath } from 'utils/rollups'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import omit from 'lodash/omit'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.white,
  },
  secondaryType: {
    color: theme.palette.grey[500],
  },
  listRoot: {
    maxHeight: '65vh',
    overflow: 'auto',
    '& li': {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      borderRight: `1px solid ${theme.palette.grey[100]}`,
    },
    '& li:last-child': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    '& li:first-child': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
  listItemRoot: {
    backgroundColor: theme.palette.white,
  },

  deleteRecentSearch: {
    color: theme.palette.grey[500],
    padding: 1,
    marginLeft: 5,
  },
}))

export const AutoComplete = ({ onInputKeyDownEscape, onSelect, searchResults, history }) => {
  const [dirty, setDirty] = useState(false)
  const classes = useStyles()
  const [hasError, setHasError] = useState(false)
  const [recentSearches, setRecentSearches] = useState(retrieveMostRecentSearches())
  const dispatch = useDispatch()
  const isExternalUser = useSelector(isExternalUserSelector)
  const anyPendingCalls = useSelector(anyPendingCallsSelector)

  const fetchSuggest = input => {
    dispatch(fetchBolsSuggest(input))
    dispatch(fetchContainersSuggest(input))
    dispatch(fetchLineItemsSuggest(input))
    dispatch(fetchBookingsSuggest(input))
    dispatch(fetchSalesOrdersSuggest(input))
    dispatch(fetchPurchaseOrdersSuggest(input))
    dispatch(fetchStockTransferOrdersSuggest(input))
    dispatch(fetchShipmentsSuggest(input))
  }

  // Cancel any pending requests by activating the saga with an empty
  // payload. This will cancel any active sagas since we are using takeLatest
  // and will set the state for each entity to an empty list.
  const clearAndCancelSuggest = () => fetchSuggest('')

  useEffect(() => {
    // Clear and cancel the suggests on mount and unmount.
    clearAndCancelSuggest()
    return clearAndCancelSuggest
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = (input, state) => {
    if (hasError) {
      setHasError(false)
    }

    if (!dirty) {
      setDirty(true)
    }

    // Don't call endpoints if something was selected
    if (!state.selectedItem) {
      fetchSuggest(input)
    }
  }

  const getResultUrl = result =>
    getShipmentDetailPath(ENTITY_TO_ROLLUP[result.type], result.text, result.externalId)

  const trackSelection = result => {
    let msg = result.isRecentSearch
      ? `${result.type} recent search searched`
      : `${result.type} searched`

    if (isExternalUser) {
      msg = `Portal ${msg}`
    }

    logger.notify(msg, { entity: result.text })
  }

  const handleClick = result => {
    onSelect(result)
    saveRecentSearch(omit(result, 'isRecentSearch'))
    trackSelection(result)
  }

  const handleItemSelect = result => {
    handleClick(result)
    history.push(getResultUrl(result))
  }

  const validateAutocomplete = inputValue => {
    if (inputValue.length > 0 && searchResults.length === 0 && !anyPendingCalls) {
      setHasError(true)
      logger.notify('Autocomplete Search Failed', { text: inputValue })
    }
  }

  function getHelperText(showRecentSearches, resultCount) {
    if (showRecentSearches) {
      return null
    } else if (anyPendingCalls || resultCount > 0) {
      return 'Use up and down arrow keys to navigate.'
    } else {
      return null
    }
  }

  return (
    <>
      <Downshift
        itemToString={item => (item ? item.text : '')}
        onInputValueChange={handleInputChange}
        defaultHighlightedIndex={0}
        onSelect={handleItemSelect}
      >
        {({ getInputProps, getItemProps, inputValue, highlightedIndex, reset }) => {
          const hasInput = inputValue.length > 0
          const showRecentSearches = !hasInput && recentSearches.length > 0
          const listItems = showRecentSearches
            ? recentSearches.map(search => ({ ...search, isRecentSearch: true }))
            : searchResults
          return (
            <div>
              <TextField
                autoFocus
                fullWidth
                placeholder="Type to search"
                variant="outlined"
                error={hasError}
                helperText={getHelperText(showRecentSearches, searchResults.length)}
                InputProps={{
                  classes: { root: classes.inputRoot },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        disabled={!hasInput}
                        onClick={e => {
                          reset(e)
                          clearAndCancelSuggest()
                        }}
                      >
                        {hasInput ? <CancelIcon fontSize="small" /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  ...getInputProps({
                    id: 'ttp-autocomplete',
                    onKeyDown: event => {
                      switch (event.key) {
                        case 'Enter':
                          // Prevent Downshift's default 'Enter' behavior.
                          event.nativeEvent.preventDownshiftDefault = true
                          validateAutocomplete(inputValue)
                          break
                        case 'Escape':
                          onInputKeyDownEscape()
                          break
                        default:
                          break
                      }
                    },
                  }),
                }}
              />
              <ResultTitle
                resultCount={searchResults.length}
                showRecentSearches={showRecentSearches}
                dirty={dirty}
                anyPendingCalls={anyPendingCalls}
              />
              <div data-testid="search__results-list">
                <List classes={{ root: classes.listRoot }}>
                  {listItems.map((result, index) => {
                    const { externalId, type, text } = result
                    const key = `search-${text}-${type}-${index}`
                    const entityName = ENTITY_NAMES[type]

                    return (
                      <ListItem
                        button
                        classes={{ root: classes.listItemRoot }}
                        key={key}
                        selected={index === highlightedIndex}
                        {...getItemProps({ item: result })}
                      >
                        <ListItemText>
                          {text}
                          {!showRecentSearches && ( // Only show icon for search results, not saved results.
                            <CommentsLinkButton
                              onClick={() => handleClick(result)}
                              link={getResultUrl(result)}
                              externalId={externalId}
                              messageType={LOGGER_SEARCH_RESULT}
                              iconButtonProps={{
                                'data-testid': 'search-drawer__comments-icon-link',
                                size: 'small',
                              }}
                            />
                          )}
                        </ListItemText>
                        <Typography classes={{ root: classes.secondaryType }} variant="caption">
                          {entityName}
                        </Typography>
                        <ListItemSecondaryAction>
                          {showRecentSearches && (
                            <IconButton
                              className={classes.deleteRecentSearch}
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                removeRecentSearch(result)
                                setRecentSearches(retrieveMostRecentSearches())
                              }}
                            >
                              <CloseIcon fontSize={'small'} />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })}
                </List>
              </div>
            </div>
          )
        }}
      </Downshift>
    </>
  )
}

AutoComplete.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func,
  onInputKeyDownEscape: PropTypes.func,
}

AutoComplete.defaultProps = {
  searchResults: [],
}

const mapStateToProps = state => ({
  ...formatSearchResults(state),
})

export default compose(withRouter, connect(mapStateToProps))(AutoComplete)
