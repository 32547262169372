import {
  PROGRESS_COMPLETED,
  PROGRESS_IN_PROGRESS,
  PROGRESS_IN_TRANSIT,
} from 'utils/milestone/MilestoneGroups/definitions'
import { Theme, makeStyles } from '@material-ui/core'

import InProgressIcon from 'components/icons/InProgressIcon'
import InTransitIcon from 'components/icons/InTransitIcon'
import ProgressCompletedIcon from 'components/icons/ProgressCompletedIcon'
import { ProgressType } from 'utils/milestone/MilestoneGroups/interfaces'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  statusIcon: {
    fontSize: 28,
    verticalAlign: 'middle',
  },
}))

interface Props {
  classes?: {
    [key: string]: string
    colorComplete: string
    colorInProgress: string
    colorInTransit: string
  }
  iconType: ProgressType
}

const StatusIcon = (props: Props) => {
  const classes = useStyles(props)
  const { iconType } = props
  return (
    <>
      {iconType === PROGRESS_COMPLETED && <ProgressCompletedIcon className={classes.statusIcon} />}
      {iconType === PROGRESS_IN_TRANSIT && <InTransitIcon className={classes.statusIcon} />}
      {iconType === PROGRESS_IN_PROGRESS && <InProgressIcon className={classes.statusIcon} />}
    </>
  )
}

export default StatusIcon
