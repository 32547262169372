import Button from '@material-ui/core/Button'
import DateExactPicker from 'components/DateExactPicker'
import DateRange from 'components//DateRangePicker'
import DetailRadio from 'components/DetailRadio'
import DetailRadioItem from 'components/DetailRadio/DetailRadioItem'
import NumberField from 'components/NumberField'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import moment from 'moment'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

const BASE_10 = 10

const resetState = {
  warning: null,
  lowerBound: null,
  upperBound: null,
  defaultRadioIdx: null,
  defaultLessThanRelative: null,
  defaultGreaterThanRelative: null,
  defaultLessThanDate: null,
  defaultGreaterThanDate: null,
}

export class DateFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState(this.props)
  }

  getInitialState = props => {
    const defaultVals = this.getDefaultRadioIdx(props)
    const defaultRadioIdx = get(defaultVals, 'defaultRadioIdx', 0)
    const defaultGreaterThanRelative = get(defaultVals, 'defaultGreaterThanRelative', null)
    const defaultLessThanRelative = get(defaultVals, 'defaultLessThanRelative', null)
    const defaultLessThanDate = get(defaultVals, 'defaultLessThanDate', null)
    const defaultGreaterThanDate = get(defaultVals, 'defaultGreaterThanDate', null)

    return {
      value: get(props, 'value.type', 'relative'),
      warning: null,
      lowerBound: get(props, 'value.startDate', null),
      upperBound: get(props, 'value.endDate', null),
      defaultRadioIdx,
      defaultLessThanRelative,
      defaultGreaterThanRelative,
      defaultLessThanDate,
      defaultGreaterThanDate,
    }
  }

  getDefaultRadioIdx = props => {
    // TODO - This is the most confusing logic ever.  Speaks for need to a different type of component
    const value = get(props, 'value')
    if (!value) return null
    const type = get(value, 'type')
    const relativeType = get(value, 'relativeType')
    const lowerBound = get(value, 'startDate', null)
    const upperBound = get(value, 'endDate', null)
    if (type === 'relative') {
      if (relativeType === 'past') {
        if (lowerBound === null && upperBound !== null)
          return {
            // greater than ("more than X days ago")
            defaultRadioIdx: 0,
            defaultLessThanRelative: null,
            defaultGreaterThanRelative: Math.abs(upperBound),
          }
        else if (lowerBound !== null && upperBound === null)
          return {
            // less than ("less than X days ago")
            defaultRadioIdx: 1,
            defaultLessThanRelative: Math.abs(lowerBound),
            defaultGreaterThanRelative: null,
          }
        else if (lowerBound !== null && upperBound !== null)
          return {
            // Between X and X days ago
            defaultRadioIdx: 2,
            defaultLessThanRelative: Math.abs(lowerBound),
            defaultGreaterThanRelative: Math.abs(upperBound),
          }
      } else {
        if (lowerBound !== null && upperBound === null)
          return {
            // greater than ("more than X days from now")
            defaultRadioIdx: 0,
            defaultLessThanRelative: null,
            defaultGreaterThanRelative: lowerBound,
          }
        else if (lowerBound === null && upperBound !== null)
          return {
            // less than ("less than X days from now")
            defaultRadioIdx: 1,
            defaultLessThanRelative: upperBound,
            defaultGreaterThanRelative: null,
          }
        else if (lowerBound !== null && upperBound !== null)
          return {
            // less than ("Between than X days from now")
            defaultRadioIdx: 2,
            defaultLessThanRelative: upperBound,
            defaultGreaterThanRelative: lowerBound,
          }
      }
    } else {
      if (lowerBound !== null && upperBound !== null && lowerBound === upperBound)
        // Dates are equal
        return {
          defaultRadioIdx: 3,
          defaultLessThanDate: moment(upperBound),
          defaultGreaterThanDate: moment(lowerBound),
        }
      else if (lowerBound === null && upperBound !== null) {
        // Before X date
        return {
          defaultRadioIdx: 4,
          defaultLessThanDate: moment(upperBound),
          defaultGreaterThanDate: null,
        }
      } else if (lowerBound !== null && upperBound === null) {
        // After X date
        return {
          defaultRadioIdx: 5,
          defaultLessThanDate: null,
          defaultGreaterThanDate: moment(lowerBound),
        }
      } else if (lowerBound !== null && upperBound !== null && lowerBound !== upperBound)
        // Between X and Y date
        return {
          defaultRadioIdx: 6,
          defaultLessThanDate: moment(upperBound),
          defaultGreaterThanDate: moment(lowerBound),
        }
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  handleRadioChange = value => {
    this.setState({ ...resetState, value })
  }

  handleClose = e => {
    e && e.preventDefault()
    this.props.onRequestClose()
  }

  handleSubmit = e => {
    const { onSubmit, filterConfig } = this.props
    const relativeType = get(filterConfig, 'filterData.relativeType')
    const { value, lowerBound, upperBound } = this.state
    if (!upperBound && !lowerBound) {
      this.setState({
        warning: 'Please select a valid range',
      })
    } else {
      let startDate = lowerBound
      let endDate = upperBound
      const val = { startDate, endDate, type: value, relativeType }
      e.preventDefault()
      onSubmit(val)
      this.handleClose()
    }
  }

  handleRangeChange = ({ startDate, endDate }) => {
    this.setState({
      upperBound: endDate,
      lowerBound: startDate,
    })
  }

  componentWillUnmount() {
    const initialState = this.getInitialState(this.props)
    this.setState(initialState) // reset everything
  }

  handleDayChange = day => {
    this.setState({
      upperBound: day,
      lowerBound: day,
    })
  }

  handleLessThan = e => {
    const { filterConfig } = this.props
    const relativeType = get(filterConfig, 'filterData.relativeType')
    if (relativeType === 'past') {
      // special case if you're talking about past dates
      this.setState({
        upperBound: null,
        lowerBound: -1 * parseInt(e.target.value, BASE_10),
      })
    } else {
      this.setState({
        upperBound: parseInt(e.target.value, BASE_10),
        lowerBound: null,
      })
    }
  }

  handleMoreThan = e => {
    const { filterConfig } = this.props
    const relativeType = get(filterConfig, 'filterData.relativeType')
    if (relativeType === 'past') {
      // special case if you're talking about past dates
      this.setState({
        upperBound: -1 * parseInt(e.target.value, BASE_10),
        lowerBound: null,
      })
    } else {
      this.setState({
        lowerBound: parseInt(e.target.value, BASE_10),
        upperBound: null,
      })
    }
  }

  updateNumberBounds = index => e => {
    const { filterConfig } = this.props

    const relativeType = get(filterConfig, 'filterData.relativeType')
    if (index === 0) {
      if (relativeType === 'past') {
        // special case if you're talking about past dates
        this.setState({
          upperBound: -1 * parseInt(e.target.value, BASE_10),
        })
      } else {
        this.setState({
          lowerBound: parseInt(e.target.value, BASE_10),
        })
      }
    } else {
      if (relativeType === 'past') {
        // special case if you're talking about past dates
        this.setState({
          lowerBound: -1 * parseInt(e.target.value, BASE_10),
        })
      } else {
        this.setState({
          upperBound: parseInt(e.target.value, BASE_10),
        })
      }
    }
  }

  updateDateBounds = index => day => {
    if (index === 0) {
      this.setState({
        lowerBound: day,
      })
    } else {
      this.setState({
        upperBound: day,
      })
    }
  }

  renderRelativeOptions = () => {
    const { classes, filterConfig } = this.props
    const { defaultLessThanRelative, defaultGreaterThanRelative } = this.state
    const qualifierText = get(filterConfig, 'filterData.relativeTextQualifier', 'days ago')

    return [
      <DetailRadioItem label={'More than'} value={'relative'} header={'RELATIVE'} key={'more-than'}>
        <div className={classes.dateWrapper}>
          <NumberField
            onChange={this.handleMoreThan}
            onEnter={this.handleSubmit}
            defaultValue={defaultGreaterThanRelative}
          />
          <span className={classes.helperText}>{qualifierText}</span>
        </div>
      </DetailRadioItem>,
      <DetailRadioItem label={'Less than'} value={'relative'} key={'less-than'}>
        <div className={classes.dateWrapper}>
          <NumberField
            onChange={this.handleLessThan}
            onEnter={this.handleSubmit}
            defaultValue={defaultLessThanRelative}
          />
          <span className={classes.helperText}>{qualifierText}</span>
        </div>
      </DetailRadioItem>,
      <DetailRadioItem label={'Between'} value={'relative'} key={'between-relative'}>
        <div className={classes.flexRow}>
          <NumberField
            onChange={this.updateNumberBounds(0)}
            onEnter={this.handleSubmit}
            defaultValue={defaultGreaterThanRelative}
          />
          <span className={classes.helperText}>and</span>
          <NumberField
            onChange={this.updateNumberBounds(1)}
            onEnter={this.handleSubmit}
            defaultValue={defaultLessThanRelative}
          />
          <span className={classes.helperText}>{qualifierText}</span>
        </div>
      </DetailRadioItem>,
    ]
  }

  renderAbsoluteOptions = () => {
    const { classes } = this.props
    const { defaultLessThanDate, defaultGreaterThanDate } = this.state

    const openDirection = 'up'

    return [
      <DetailRadioItem
        label={'Exactly'}
        value={'absolute'}
        header={'ABSOLUTE'}
        style={{ marginTop: 20 }}
        key={'exactly'}
      >
        <div className={classes.dateWrapper}>
          <DateExactPicker
            onChange={this.handleDayChange}
            openDirection={openDirection}
            defaultValue={defaultLessThanDate}
          />
        </div>
      </DetailRadioItem>,
      <DetailRadioItem label={'Before'} value={'absolute'} key={'before'}>
        <div className={classes.dateWrapper}>
          <DateExactPicker
            onChange={this.updateDateBounds(1)}
            openDirection={openDirection}
            defaultValue={defaultLessThanDate}
          />
        </div>
      </DetailRadioItem>,
      <DetailRadioItem label={'After'} value={'absolute'} key={'after'}>
        <div className={classes.dateWrapper}>
          <DateExactPicker
            onChange={this.updateDateBounds(0)}
            openDirection={openDirection}
            defaultValue={defaultGreaterThanDate}
          />
        </div>
      </DetailRadioItem>,
      <DetailRadioItem label={'Between'} value={'absolute'} key={'between-absolute'}>
        <div className={classes.dateWrapper}>
          <DateRange
            onChange={this.handleRangeChange}
            openDirection={openDirection}
            initialStartDate={defaultGreaterThanDate}
            initialEndDate={defaultLessThanDate}
          />
        </div>
      </DetailRadioItem>,
    ]
  }

  render() {
    const { classes } = this.props
    const { warning, defaultRadioIdx } = this.state

    return (
      <div className={classes.container}>
        <div className={classes.range}>
          <DetailRadio onChange={this.handleRadioChange} defaultSelectedIndex={defaultRadioIdx}>
            {this.renderRelativeOptions()}
            {this.renderAbsoluteOptions()}
          </DetailRadio>
        </div>
        {warning && (
          <Typography variant="body2" className={classes.warning}>
            {this.state.warning}
          </Typography>
        )}
        <Button className={classes.submitBtn} onClick={this.handleSubmit}>
          Apply
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(DateFilter)
