import { call, put, takeLatest } from 'redux-saga/effects'

import TripGroups from 'store/tripGroups/models'
import api from 'store/tripGroups/api'
import logger from 'utils/logger'
import { types } from 'store/tripGroups/actions'

export function* fetchTripGroupsAsync(action: { type: string; tripIds: string[] }) {
  try {
    const res = yield call(api.fetchTripGroups, action.tripIds)
    const data = (res?.data && TripGroups.of(res.data)) || {}
    yield put({ type: types.FETCH_TRIP_GROUPS_SUCCESS, payload: data })
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.FETCH_TRIP_GROUPS_FAILURE, payload: { error: err.message } })
  }
}

export function* watchFetchTripGroups() {
  yield takeLatest(types.FETCH_TRIP_GROUPS_START, fetchTripGroupsAsync)
}
