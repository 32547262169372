import { call, put, takeLatest } from 'redux-saga/effects'

import client from 'utils/api/client'
import logger from 'utils/logger'
import { types } from '../actions'

// ------------------------------------
// Sagas
// ------------------------------------
export function* fetchTopLanesAsync({ params }) {
  try {
    const res = yield call(client.get, '/lanes/summary', {
      params: {
        from_ms_type: 'vessel_load_origin',
        to_ms_type: 'vessel_discharge_destination',
      },
    })
    yield put({ type: types.FETCH_TOP_LANES_SUCCESS, payload: res.data })
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.FETCH_TOP_LANES_FAILURE, payload: err.response })
  }
}

// ------------------------------------
// Watchers
// ------------------------------------
export function* watchFetchTopLanesAsync() {
  yield takeLatest(types.FETCH_TOP_LANES, fetchTopLanesAsync)
}

export const sagas = [watchFetchTopLanesAsync()]
