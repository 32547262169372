import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import { DELIVERED, DISCHARGED, UNKNOWN } from 'store/models/definitions/StatusLabel'
import { TIMESTAMP_ACTUAL, TIMESTAMP_PREDICTED } from 'utils/milestone/MilestoneGroups/definitions'

import ArrowRightIcon from 'components/icons/ArrowRightIcon'
import { CONTAINER_ROUTE } from 'utils/routes'
import DelayLabel from 'components/DelayLabel'
import { EntityPageHeaderInterface } from 'store/models/EntityPageHeader'
import Link from 'components/core/Link'
import NotProvided from 'components/NotProvided'
import { ROLLUP_CONTAINER } from 'utils/rollups'
import React from 'react'
import StatusLabel from 'components/StatusLabel'
import classnames from 'classnames'
import logger from 'utils/logger'
import statusLabelVariant from 'store/models/utils/statusLabelVariant'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  borderRight: {},
  row: {},
  muted: {},
  noData: {},
  flexColumn: {},
  italic: {},
  item: {},
  dischargeCell: {},
  cell: {
    padding: '2px 25px 2px 20px',
  },
  cellBoard: {
    paddingRight: theme.spacing(1),
  },
  details: {
    minWidth: 300,
  },
  prediction: {
    background: theme.palette.purple[100],
    padding: '2px 5px',
    alignSelf: 'center',
    borderRadius: 4,
  },
  actualDischarge: {
    background: theme.palette.grey[50],
    padding: '2px 5px',
    alignSelf: 'center',
    borderRadius: 4,
  },
  delayDate: {
    fontWeight: 600,
  },
  arrow: {
    color: theme.palette.grey[600],
  },
  flexRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  borderRightCell: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    height: '100%',
    paddingRight: 0,
  },
  noLink: {
    color: theme.palette.text.primary,
  },
  rootReset: {
    minWidth: 'auto',
  },
}))

const ACTUAL_DISCHARGE_MESSAGE_CONTAINER = 'Actual Discharge'
const PREDICTED_DISCHARGE_MESSAGE_CONTAINER = 'project44 Predicted Discharge'
const ACTUAL_DISCHARGE_MESSAGE = 'Actual Discharge: Last Container'
const PREDICTED_DISCHARGE_MESSAGE = 'Predicted Discharge: Last Container'

interface Props {
  classes?: { [key: string]: string }
  className?: string
  isBoard?: boolean
  isContainerPage?: boolean
  isPublicShare?: boolean
  pageHeaderData: EntityPageHeaderInterface
  isSubheader?: boolean
}

/**
 * Right side of the `EntityPageHeader` component
 */
export const Status = (props: Props) => {
  const classes = useStyles(props)
  const {
    isBoard = false,
    isContainerPage = false,
    isPublicShare = false,
    isSubheader = false,
    pageHeaderData,
  } = props

  const {
    actualDischarge,
    delay,
    portOfDischarge,
    portOfLoad,
    predictedDischarge,
    recentActivity,
    statusLabel,
    statusTrips,
    trips = [],
  } = pageHeaderData

  const hasActualDischarge = Boolean(actualDischarge?.time)
  const hasPredictedDischarge = Boolean(predictedDischarge?.time)

  return (
    <Box
      ml={isBoard ? 2 : 5}
      className={classnames(classes.item, classes.details, {
        [classes.flexRow]: isSubheader || isBoard,
      })}
      data-testid="page-header__details"
    >
      <Box
        className={classnames(classes.row, classes.cell, {
          [classes.borderRightCell]: isSubheader || isBoard,
          [classes.cellBoard]: isBoard,
        })}
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
      >
        <StatusLabel
          short={isSubheader || isBoard}
          classes={{
            rootReset: classes.rootReset,
          }}
          label={
            !isContainerPage && (statusLabel === DISCHARGED || statusLabel === DELIVERED)
              ? `${statusLabel} (${statusTrips.length} of ${trips.length})`
              : statusLabel
          }
          variant={statusLabelVariant(statusLabel)}
          statusPopoverToShow={statusLabel === UNKNOWN ? UNKNOWN : null}
        />
        {recentActivity?.label && (
          <Box
            pl={2.5}
            pr={isBoard ? 0 : 3}
            className={classnames(classes.flexColumn, {
              [classes.borderRight]: isSubheader || isBoard,
            })}
          >
            <Typography variant="body2" className={classes.muted}>
              {!isSubheader && !isContainerPage ? (
                <>
                  Most Recent Activity
                  {!isBoard && recentActivity?.container && (
                    <>
                      : Container{' '}
                      {isPublicShare ? (
                        <span className={classes.noLink}>{recentActivity.container.number}</span>
                      ) : (
                        <Link
                          to={CONTAINER_ROUTE.buildUrl({
                            number: recentActivity.container.number,
                            externalId: recentActivity.container.externalId,
                          })}
                          onClick={() =>
                            logger.notify('Entity Page header', {
                              refType: ROLLUP_CONTAINER,
                              entityId: recentActivity.container?.externalId,
                            })
                          }
                        >
                          {recentActivity.container.number}
                        </Link>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>Recent Activity</>
              )}
            </Typography>
            <Typography variant="body2">
              {!isBoard && !isSubheader ? (
                <>
                  {recentActivity.time} - {recentActivity.label}
                </>
              ) : (
                recentActivity.label
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        className={classnames(classes.cell, {
          [classes.dischargeCell]: isSubheader,
          [classes.cellBoard]: isBoard,
        })}
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
      >
        {!isBoard && !isSubheader && (
          <>
            <Box className={classes.flexColumn} pr={2.5}>
              <Typography variant="body2" className={classes.muted}>
                Port of Load
              </Typography>
              {portOfLoad ? <Typography variant="body2">{portOfLoad}</Typography> : <NotProvided />}
            </Box>
            <Box className={classes.flexColumn} pr={2.5}>
              <ArrowRightIcon className={classes.arrow} fontSize="large" />
            </Box>
          </>
        )}
        <Box className={classes.flexColumn} pr={isSubheader ? 2.5 : 3}>
          <Typography variant="body2" className={classes.muted}>
            Port of Discharge
          </Typography>
          {portOfDischarge ? (
            <Typography variant="body2">{portOfDischarge}</Typography>
          ) : (
            <NotProvided />
          )}
        </Box>
        {(hasActualDischarge || hasPredictedDischarge) && (
          <Box
            className={classnames(classes.flexColumn, {
              [classes.prediction]: hasPredictedDischarge,
              [classes.actualDischarge]: hasActualDischarge,
            })}
          >
            <Typography variant="body2" className={classes.muted}>
              {isContainerPage ? (
                <>
                  {hasActualDischarge
                    ? ACTUAL_DISCHARGE_MESSAGE_CONTAINER
                    : PREDICTED_DISCHARGE_MESSAGE_CONTAINER}
                </>
              ) : (
                <>{hasActualDischarge ? ACTUAL_DISCHARGE_MESSAGE : PREDICTED_DISCHARGE_MESSAGE}</>
              )}
            </Typography>
            <Box>
              <Typography component="span" variant="body2" className={classes.delayDate}>
                {actualDischarge?.time ?? predictedDischarge?.time}
              </Typography>
              <DelayLabel
                delayType={hasActualDischarge ? TIMESTAMP_ACTUAL : TIMESTAMP_PREDICTED}
                delay={delay}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Status
