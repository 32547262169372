export const COMMENTS_URL_EXTENSION = 'comments'
export const getIsCommentsDrawerOpen = match => match.params.extension === COMMENTS_URL_EXTENSION

/*
 * Turns /sales-order/123123/comments into /sales-order/123123
 */
const pathWithoutComments = path => path.substring(0, path.indexOf(`/${COMMENTS_URL_EXTENSION}`))

/*
 * Turns /sales-order/123123 into /sales-order/123123/comments
 */
export const pathWithComments = path => `${path}/${COMMENTS_URL_EXTENSION}`

export const openCommentsDrawer = ({ history, location }) =>
  history.replace(location.pathname + `/${COMMENTS_URL_EXTENSION}` + (location.search || ''))

export const closeCommentsDrawer = ({ history, location }) =>
  history.replace(pathWithoutComments(location.pathname) + (location.search || ''))
