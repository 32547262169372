import CMPaper from 'components/CMPaper'
import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from 'components/Skeleton'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  block: {
    color: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 11,
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: 11,
    minHeight: 64,
  },
  valueBlock: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.body2.fontSize,
  },
  valueLabel: {
    flex: 1,
    position: 'relative',
    marginLeft: 6,
  },
  theValue: {
    /* can't use `value` key */
    flex: 2,
    textAlign: 'right',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.primary.main,
  },
  theRollValue: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  labelBlock: {
    flex: 1,
    paddingLeft: 10,
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'nowrap',
    position: 'relative',
  },
  subLabelName: {
    lineHeight: theme.typography.caption.lineHeight,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  subLabelValue: {
    lineHeight: theme.typography.caption.lineHeight,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: '1ch',
    color: '#00558B',
  },
})

const LanesScorecard = ({
  classes,
  isLoading,
  label,
  subLabelName,
  subLabelValue,
  units,
  value,
  theme,
  hasNoData,
}) => {
  const emptyState = isLoading || hasNoData

  return (
    <CMPaper className={classes.block}>
      <div className={classes.valueBlock}>
        <div className={classes.theValue}>
          {emptyState ? (
            <Skeleton isAnimated={isLoading} height={theme.typography.h4.fontSize} />
          ) : (
            value
          )}
        </div>
        <div className={classes.valueLabel}>
          {emptyState ? <Skeleton isAnimated={isLoading} width={55} /> : units}
        </div>
      </div>
      <div className={classes.labelBlock}>
        <div className={classes.label}>
          {emptyState ? <Skeleton isAnimated={isLoading} /> : label}
        </div>
        {Boolean(subLabelName) && (
          <div className={classes.subLabelContainer}>
            <div className={classes.subLabel}>
              {emptyState ? (
                <Skeleton isAnimated={isLoading} />
              ) : (
                <>
                  <span className={classes.subLabelName}>{subLabelName}</span>
                  <span className={classes.subLabelValue}>{subLabelValue}</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </CMPaper>
  )
}

LanesScorecard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  hasNoData: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  subLabelName: PropTypes.string,
  subLabelValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  units: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default withStyles(styles, { withTheme: true })(LanesScorecard)
