import { isValidInputString } from './getEffectiveTimeInputString'

const getPaddedInputString = (value: string) => {
  if (isValidInputString(value)) {
    const numbersAndColonOnly = value.replace(/[^:0-9]/g, '')
    const timeParts = numbersAndColonOnly.split(':')
    const hourNumberStr = timeParts[0]
    return `${hourNumberStr.padStart(2, '0')}:00`
  }
}

export default getPaddedInputString
