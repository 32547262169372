import { TextField, Theme, makeStyles } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  smallTextField: {
    width: 60,
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center',
    borderLeft: '1px solid rgb(148, 148, 148)',
    borderTop: '1px solid rgb(148, 148, 148)',
    borderRight: '1px solid rgb(148, 148, 148)',
  },
}))

type NumberFieldProps = {
  className?: string
  defaultValue?: string | number
  onChange: () => void
  onEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const NumberField = (props: NumberFieldProps) => {
  const { className, defaultValue, onChange, onEnter } = props
  const classes = useStyles()

  const handleTextFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        onEnter(e)
        break
      default:
        break
    }
  }

  const textFieldClassname = className ? className : classes.smallTextField

  return (
    <TextField
      type="number"
      onKeyDown={handleTextFieldKeyDown}
      onChange={onChange}
      InputProps={{ inputProps: { style: { textAlign: 'center', width: '100%' } } }}
      className={textFieldClassname}
      placeholder={''}
      defaultValue={defaultValue}
    />
  )
}

export default NumberField
