import { TableCell as MuiTableCell, makeStyles } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    // verticalAlign: 'text-top' could be used for some alignment cases, but it breaks for others
    padding: theme.spacing(1),
    borderBottom: 'none',
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  indent: {
    paddingLeft: theme.spacing(4),
  },
}))

interface TableCellProps {
  indent?: boolean
  className?: string
  disableBorder?: boolean
}

function TableCell(props: PropsWithChildren<TableCellProps>) {
  const classes = useStyles()
  const { children, disableBorder = false, className = '', indent = false } = props

  return (
    <MuiTableCell
      className={cx(
        classes.root,
        { [classes.indent]: indent, [classes.borderTop]: !disableBorder },
        className
      )}
    >
      {children}
    </MuiTableCell>
  )
}

export default TableCell
