import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'
import { createAction, handleActions } from 'redux-actions'

import MESSAGES from 'store/notifications/messages'
import client from 'utils/api/client'
import logger from 'utils/logger'

const FETCH_CUSTOMIZATION_START = 'customization/FETCH_CUSTOMIZATION_START'
const FETCH_CUSTOMIZATION_SUCCESS = 'customization/FETCH_CUSTOMIZATION_SUCCESS'
const FETCH_CUSTOMIZATION_FAILURE = 'customization/FETCH_CUSTOMIZATION_FAILURE'

const UPDATE_CUSTOMIZATION_START = 'customization/UPDATE_CUSTOMIZATION_START'
const UPDATE_CUSTOMIZATION_SUCCESS = 'customization/UPDATE_CUSTOMIZATION_SUCCESS'
const UPDATE_CUSTOMIZATION_FAILURE = 'customization/UPDATE_CUSTOMIZATION_FAILURE'

export const updateCustomization = createAction(
  UPDATE_CUSTOMIZATION_START,
  (tenant, customization) => ({
    tenant,
    customization,
  })
)
const handleUpdateSuccess = createAction(UPDATE_CUSTOMIZATION_SUCCESS)
const handleUpdateFailure = createAction(UPDATE_CUSTOMIZATION_FAILURE)

export const fetchCustomization = createAction(FETCH_CUSTOMIZATION_START)
const handleFetchSuccess = createAction(FETCH_CUSTOMIZATION_SUCCESS)
const handleFetchFailure = createAction(FETCH_CUSTOMIZATION_FAILURE)

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export default handleActions(
  {
    [fetchCustomization]: state => ({
      ...state,
      loading: true,
    }),
    [updateCustomization]: state => ({
      ...state,
      loading: true,
    }),
    [handleUpdateSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      data: payload,
    }),
    [handleFetchSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      data: payload,
    }),
    [handleUpdateFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [handleFetchFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
)

function* updateCustomizationAsync({ payload }) {
  const { tenant, customization: data } = payload
  try {
    const res = yield call(client.put, `/customization/tenant/${tenant}`, data)
    yield put(handleUpdateSuccess(res.data))
    yield put(
      showNotification(MESSAGES.tenantCustomizationSuccess, { type: MESSAGE_TYPES.SUCCESS })
    )
  } catch (err) {
    logger.captureAPIException(err)
    yield put(handleUpdateFailure(err.message))
    yield put(showNotification(err.message, { type: MESSAGE_TYPES.ERROR }))
  }
}

function* fetchCustomizationAsync({ payload }) {
  try {
    const res = yield call(client.get, `/customization/tenant/${payload}`)
    yield put(handleFetchSuccess(res.data))
  } catch (err) {
    logger.captureAPIException(err)
    yield put(handleFetchFailure(err.message))
    yield put(showNotification(err.message, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* watchFetchCustomization() {
  yield takeLatest(FETCH_CUSTOMIZATION_START, fetchCustomizationAsync)
}

export function* watchUpdateCustomization() {
  yield takeLatest(UPDATE_CUSTOMIZATION_START, updateCustomizationAsync)
}
