import { Typography, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import DetailRadio from 'components/DetailRadio'
import DetailRadioItem from 'components/DetailRadio/DetailRadioItem'
import NumberField from 'components/NumberField'
import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import styles from './styles'

const MODE_LESS = 'less'
const MODE_GREATER = 'greater'
const MODE_BETWEEN = 'between'

const MODES = [MODE_LESS, MODE_GREATER, MODE_BETWEEN]

export class RangeFilter extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filterConfig: PropTypes.object,
    onRequestClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.shape({ lowerBound: PropTypes.number, upperBound: PropTypes.number }),
  }

  constructor(props) {
    super(props)
    const { value } = props
    const lowerBound = get(value, 'lowerBound', null)
    const upperBound = get(value, 'upperBound', null)
    let mode = MODE_LESS
    if (lowerBound && !upperBound) {
      mode = MODE_GREATER
    } else if (upperBound && lowerBound) {
      mode = MODE_BETWEEN
    }
    this.state = {
      radioSelection: mode,
      lowerBound,
      upperBound,
      warning: null,
    }
  }

  handleClose = e => {
    e && e.preventDefault()
    this.props.onRequestClose()
  }

  getValue = () => {
    const { lowerBound, upperBound } = this.state
    return { lowerBound, upperBound }
  }

  handleSubmit = e => {
    const { onSubmit } = this.props
    if (e) e.preventDefault()
    const value = this.getValue()
    if (value.lowerBound === null && value.upperBound === null) {
      this.setState({
        warning: 'You must provide an input to apply this filter',
      })
    } else {
      onSubmit(value)
      this.handleClose()
    }
  }

  handleRadioChange = value => {
    this.setState({
      radioSelection: value,
    })
  }

  updateUpperBound = e => {
    this.setState({
      upperBound: parseInt(e.target.value),
      lowerBound: null,
    })
  }

  updateLowerBound = e => {
    this.setState({
      lowerBound: parseInt(e.target.value),
      upperBound: null,
    })
  }

  updateBounds = index => e => {
    if (index === 0) {
      this.setState({
        lowerBound: parseInt(e.target.value),
      })
    } else {
      this.setState({
        upperBound: parseInt(e.target.value),
      })
    }
  }

  render() {
    const { classes } = this.props
    const { warning, radioSelection, upperBound, lowerBound } = this.state
    return (
      <div className={classes.container}>
        <DetailRadio
          defaultSelectedIndex={indexOf(MODES, radioSelection)}
          onChange={this.handleRadioChange}
        >
          <DetailRadioItem
            label={<span className={classes.label}>Less than</span>}
            value={MODE_LESS}
          >
            <div className={classes.leftCenter}>
              <NumberField
                defaultValue={upperBound}
                onChange={this.updateUpperBound}
                onEnter={this.handleSubmit}
              />
              <span className={classes.helperText}>days</span>
            </div>
          </DetailRadioItem>
          <DetailRadioItem
            label={<span className={classes.label}>Greater than</span>}
            value={MODE_GREATER}
          >
            <div className={classes.leftCenter}>
              <NumberField
                defaultValue={lowerBound}
                onChange={this.updateLowerBound}
                onEnter={this.handleSubmit}
              />
              <span className={classes.helperText}>days</span>
            </div>
          </DetailRadioItem>
          <DetailRadioItem
            label={<span className={classes.label}>Between</span>}
            value={MODE_BETWEEN}
          >
            <div className={classes.flexRow}>
              <NumberField
                defaultValue={lowerBound}
                onChange={this.updateBounds(0)}
                onEnter={this.handleSubmit}
              />
              <span className={classes.helperText}>and</span>
              <NumberField
                defaultValue={upperBound}
                onChange={this.updateBounds(1)}
                onEnter={this.handleSubmit}
              />
              <span className={classes.helperText}>days</span>
            </div>
          </DetailRadioItem>
        </DetailRadio>
        <div className={classes.center}>
          {warning && (
            <Typography variant="body2" className={classes.warning}>
              {this.state.warning}
            </Typography>
          )}
          <Button className={classes.submitBtn} onClick={this.handleSubmit}>
            Apply
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(RangeFilter)
