import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import { MESSAGE_TYPES, showNotification } from 'store/notifications'

import Button from 'components/core/Button'
import MESSAGES from 'store/notifications/messages'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { currentTenantFromState } from 'store/auth/user/selectors'
import { switchTenant } from 'store/auth/tenant/actions'

const styles = theme => ({
  dialogPaper: {
    minWidth: 400,
    minHeight: 300,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  passwordConfirm: {
    marginTop: theme.spacing(3),
    maxWidth: 200,
  },
  nextTenant: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
  },
})

class SwitchTenantConfirmation extends React.Component {
  state = {
    password: '',
  }
  static propTypes = {
    currTenant: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    switchTenant: PropTypes.func,
    showNotification: PropTypes.func,
  }

  handleClose = () => {
    this.props.onClose()
  }

  switchTenantIfPasswordNotEmpty = () => {
    const { showNotification, email, tenant } = this.props
    const { password } = this.state

    if (!password) {
      // due to this being a modal, some password managers won't fill it properly, leading to the password field being
      // blank. see discussion for #172540581.
      showNotification(MESSAGES.tenantSwitchPasswordError, { type: MESSAGE_TYPES.ERROR })
    } else {
      this.props.switchTenant(tenant, email, password)
      this.handleClose()
    }
  }

  handleSubmit = () => {
    this.switchTenantIfPasswordNotEmpty()
  }

  handleTextFieldKeyDown = event => {
    if (event.key !== 'Enter') {
      return
    }

    // this preventDefault() call prevents the enter event from bubbling up and triggering an onClick event in the
    // SwitchTenant dialog that will trigger a failed tenant list API call
    event.preventDefault()

    this.switchTenantIfPasswordNotEmpty()
  }

  render() {
    const { classes, open, tenant, currTenant } = this.props
    const { password } = this.state
    return (
      <Dialog open={open} onClose={this.handleClose} classes={{ paper: classes.dialogPaper }}>
        <DialogContent className={classes.content}>
          <div>
            <Typography variant="body2">
              Switch tenant from {currTenant} to:{' '}
              <span className={classes.nextTenant}>{tenant}</span>
            </Typography>
            <Typography variant="body2" className={classes.passwordConfirm}>
              Please provide your password. (We will switch you to the new tenant, then log you out
              and back in.)
            </Typography>
            <TextField
              autoFocus={true}
              onChange={e => this.setState({ password: e.target.value })}
              value={password}
              className={classes.input}
              onKeyDown={this.handleTextFieldKeyDown}
              type="password"
              label={'password'}
              name="password"
              variant="filled"
              required
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit} color={'primary'} disabled={!password}>
            Confirm
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  switchTenant,
  showNotification,
}

const mapStateToProps = (state, props) => {
  return {
    currTenant: currentTenantFromState(state),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SwitchTenantConfirmation))
