import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom'

import React from 'react'
import { SSO_EMAIL_ROUTE } from 'utils/routes'

// https://stackoverflow.com/a/48138690
interface MatchParams {
  extension?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const LoginPage = (props: Props) => {
  const { match } = props
  const location = useLocation()
  // keep query parameters (such as sso_error/redirect)
  const redirectTo = {
    pathname: SSO_EMAIL_ROUTE.buildUrl({ extension: match.params.extension }),
    search: location.search,
  }

  return <Redirect to={redirectTo} />
}

export default LoginPage
