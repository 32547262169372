import {
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'

import Project44LogoWhite from 'assets/Project44LogoWhite'
import React from 'react'
import RefreshIcon from 'components/icons/RefreshIcon'
import classNames from 'classnames'
import { getMomentDateTimeFromRaw } from 'utils/dateUtils'
import logger from 'utils/logger'

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  overflow: {
    overflow: 'auto',
    paddingTop: 0,
  },
  drawerPaper: {
    backgroundColor: '#07456B', // p44 change
    height: '100%',
    padding: `${theme.spacing(2)}px 0`,
    width: 280,
  },
  drawerHeader: {
    borderTop: 'none',
    borderBottom: `1px solid ${theme.palette.grey[900]}`,
  },
  drawerFooter: {
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[900]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    '& span': {
      color: theme.palette.grey[300],
      lineHeight: '18px',
    },
  },
  shareLabel: {
    borderBottom: `1px solid ${theme.palette.grey[900]}`,
    color: theme.palette.white,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    paddingBottom: theme.spacing(1),
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: 51,
    width: 150,
  },
  contentUpdateLabel: {
    color: theme.palette.grey[200],
    fontWeight: theme.typography.fontWeightMedium,
  },
  contentUpdateTime: {
    color: theme.palette.grey[300],
    fontSize: 10,
    fontWeight: theme.typography.fontWeightMedium,
  },
  iconButton: {
    border: `1px solid rgba(255, 255, 255, 0.54)`,
    borderRadius: theme.shape.borderRadius,
    height: 30,
    width: 30,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .12)',
    },
  },
  refreshIcon: {
    color: theme.palette.white,
    height: 18,
    width: 18,
  },
  supportLink: {
    color: theme.palette.white,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const DATE_FORMAT = 'D MMM YYYY HH:mm'

type Props = {
  classes?: { [key: string]: string }
  items: React.ReactNode[]
}

const SharedLinksDrawer = (props: Props) => {
  const classes = useStyles(props)
  const { items } = props

  const refreshPage = () => {
    logger.notify('Share Page navigation refresh button click')
    window.location.reload()
  }

  return (
    <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
      <>
        <Box mb={1} mx={2} className={classes.drawerHeader}>
          <Link
            onClick={() => logger.notify('Share Page navigation project44 logo click')}
            href="https://www.project44.com/"
            target="_blank"
            title="Visit project44.com"
          >
            <Project44LogoWhite className={classes.logo} />
          </Link>
        </Box>
        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box mx={2}>
            <Typography className={classes.contentUpdateLabel} variant="caption">
              Content Updated Live
            </Typography>
            <Typography className={classes.contentUpdateTime}>
              Current data as of {getMomentDateTimeFromRaw(Date.now(), DATE_FORMAT)}
            </Typography>
          </Box>
          <Box mx={2}>
            <IconButton classes={{ root: classes.iconButton }} onClick={refreshPage}>
              <RefreshIcon className={classes.refreshIcon} />
            </IconButton>
          </Box>
        </Box>
        <Box mx={2}>
          <Typography className={classes.shareLabel}>Shared Links</Typography>
        </Box>
        <List
          className={classNames(classes.grow, classes.overflow)}
          data-testid="shared-links-drawer__list"
        >
          {items}
        </List>
        <Box className={classes.drawerFooter} pt={1} pb={1.5} mx={2}>
          <Typography variant="caption">
            All event predictions are estimates only, may not be based on complete or up-to-date
            information, and are not warranted as to accuracy.
          </Typography>
          <Box mt={2}>
            <Typography variant="caption">
              If you'd like to contact project44, please email us at{' '}
              <Link
                href="mailto:support@clearmetal.com"
                className={classes.supportLink}
                target="_blank"
              >
                support@clearmetal.com
              </Link>
              .
            </Typography>
          </Box>
        </Box>
      </>
    </Drawer>
  )
}

export default SharedLinksDrawer
