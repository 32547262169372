import {
  ALL_FILTER_KEYS,
  FILTER_GROUPS_KEY,
  makeFilterGroupTypes,
} from 'store/filterGroups/actions'

import { DEFAULT_SELECTED_DATE_RANGE } from 'store/planning/utils/DateRange'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import { handleActions } from 'redux-actions'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'

export { FILTER_GROUPS_KEY }

const initState = {
  savedFilterGroups: [],
  currentFilterGroup: {},
  selectedDateRange: DEFAULT_SELECTED_DATE_RANGE,
}

const initialState = fromPairs(ALL_FILTER_KEYS.map(filterKey => [filterKey, initState]))

const makeSelectors = filterKey => ({
  currentFilterGroupSelector: state => state[FILTER_GROUPS_KEY][filterKey].currentFilterGroup,
  dateRangeSelector: state => state[FILTER_GROUPS_KEY][filterKey].selectedDateRange,
  savedFilterGroupsSelector: state => state[FILTER_GROUPS_KEY][filterKey].savedFilterGroups,
})

export const filterGroupSelectors = fromPairs(
  ALL_FILTER_KEYS.map(filterKey => [filterKey, makeSelectors(filterKey)])
)

const handleApplyFilter = filterKey => (state, { payload }) => {
  const filterKeyState = state[filterKey]
  const currentFilters = get(filterKeyState, 'currentFilterGroup.params.filters', [])
  const newFilters = uniqBy([payload, ...currentFilters], item => item.key)

  return {
    ...state,
    [filterKey]: {
      ...filterKeyState,
      currentFilterGroup: {
        ...filterKeyState.currentFilterGroup,
        params: {
          ...get(filterKeyState, 'currentFilterGroup.params', {}),
          filters: newFilters,
        },
      },
    },
  }
}

const handleRemoveFilter = filterKey => (state, { payload }) => {
  const filterKeyState = state[filterKey]
  const currentFilters = get(filterKeyState, 'currentFilterGroup.params.filters', [])
  const newFilters = currentFilters.filter(item => item.key !== payload)
  return {
    ...state,
    [filterKey]: {
      ...filterKeyState,
      currentFilterGroup: {
        ...filterKeyState.currentFilterGroup,
        params: {
          ...get(filterKeyState, 'currentFilterGroup.params', {}),
          filters: newFilters,
        },
      },
    },
  }
}

const handleSelectFilterGroup = filterKey => (state, { payload }) => {
  const filterKeyState = state[filterKey]
  return {
    ...state,
    [filterKey]: {
      ...filterKeyState,
      currentFilterGroup: payload,
      selectedDateRange: isEmpty(payload.params.dateRange)
        ? filterKeyState.selectedDateRange
        : payload.params.dateRange,
    },
  }
}

const handleClearFilterGroup = filterKey => state => {
  return {
    ...state,
    [filterKey]: {
      ...state[filterKey],
      currentFilterGroup: {},
      selectedDateRange: DEFAULT_SELECTED_DATE_RANGE,
    },
  }
}

const handleFetchFilterGroupsSuccess = filterKey => (state, { payload }) => {
  return {
    ...state,
    [filterKey]: {
      ...state[filterKey],
      savedFilterGroups: payload,
    },
  }
}

const handleUpdateDateRange = filterKey => (state, { payload }) => {
  return {
    ...state,
    [filterKey]: {
      ...state[filterKey],
      selectedDateRange: payload,
    },
  }
}

const handleCreateFilterGroupSuccess = filterKey => (state, { payload }) => {
  return handleSelectFilterGroup(filterKey)(state, { payload })
}

const makeActions = filterKey => {
  const types = makeFilterGroupTypes(filterKey)
  return {
    [types.APPLY_FILTER]: handleApplyFilter(filterKey),
    [types.REMOVE_FILTER]: handleRemoveFilter(filterKey),
    [types.SELECT_FILTER_GROUP]: handleSelectFilterGroup(filterKey),
    [types.CLEAR_FILTER_GROUP]: handleClearFilterGroup(filterKey),
    [types.FETCH_FILTER_GROUPS_SUCCESS]: handleFetchFilterGroupsSuccess(filterKey),
    [types.UPDATE_DATE_RANGE]: handleUpdateDateRange(filterKey),
    [types.CREATE_FILTER_GROUP_SUCCESS]: handleCreateFilterGroupSuccess(filterKey),
  }
}

let actions = {}
ALL_FILTER_KEYS.forEach(filterKey => {
  actions = { ...actions, ...makeActions(filterKey) }
})

export default handleActions(actions, initialState)
