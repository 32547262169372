import { PublicFeatureFlagsInterface, PublicFeatureFlagsState } from './interfaces'

import { handleActions } from 'redux-actions'
import types from './actions'

const initialState: PublicFeatureFlagsState = {
  data: undefined,
  isFailed: false,
  isLoading: false,
}

const publicFeatureFlags = handleActions<PublicFeatureFlagsState, any>(
  {
    [types.PUBLIC_FEATURE_FLAGS_LOAD]: (state: PublicFeatureFlagsState) => {
      return { ...state, data: undefined, isLoading: true }
    },
    [types.PUBLIC_FEATURE_FLAGS_FAIL]: (state: PublicFeatureFlagsState) => {
      return { ...state, data: undefined, isLoading: false, isFailed: true }
    },
    [types.PUBLIC_FEATURE_FLAGS_SUCCESS]: (
      state: PublicFeatureFlagsState,
      action: { payload: PublicFeatureFlagsInterface }
    ) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isFailed: false,
      }
    },
  },
  initialState
)

export default publicFeatureFlags
