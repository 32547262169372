import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ColumnSelectorIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M20.8713203 3C21.4236051 3 21.8713203 3.44771525 21.8713203 4L21.8713203 19C21.8713203 19.5522847 21.4236051 20 20.8713203 20L12.3713203 20C11.8190356 20 11.3713203 19.5522847 11.3713203 19L11.3713203 4C11.3713203 3.44771525 11.8190356 3 12.3713203 3L20.8713203 3zM15.7463203 4.5L12.9963203 4.5 12.9963203 18.5 15.7463203 18.5 15.7463203 4.5zM20.2463203 4.5L17.4963203 4.5 17.4963203 18.5 20.2463203 18.5 20.2463203 4.5zM8.37132034 11C8.92360509 11 9.37132034 11.4477153 9.37132034 12L9.37132034 21C9.37132034 21.5522847 8.92360509 22 8.37132034 22L4.37132034 22C3.81903559 22 3.37132034 21.5522847 3.37132034 21L3.37132034 12C3.37132034 11.4477153 3.81903559 11 4.37132034 11L8.37132034 11zM7.74632034 12.5L4.99632034 12.5 4.99632034 20.5 7.74632034 20.5 7.74632034 12.5zM6.37132034 2C6.78553391 2 7.12132034 2.33578644 7.12132034 2.75L7.12033983 7.692 9.02297077 5.79009787C9.31586399 5.49720465 9.79073773 5.49720465 10.0836309 5.79009787 10.3765242 6.08299109 10.3765242 6.55786482 10.0836309 6.85075804L6.90165043 10.0327386 6.90165043 10.0327386C6.7951438 10.1392452 6.66457364 10.2070221 6.52744069 10.2360694L6.42363391 10.250593 6.42363391 10.250593 6.31900678 10.250593C6.1448561 10.23849 5.97412354 10.1658718 5.84099026 10.0327386L2.65900974 6.85075804C2.36611652 6.55786482 2.36611652 6.08299109 2.65900974 5.79009787 2.95190296 5.49720465 3.4267767 5.49720465 3.71966991 5.79009787L5.62033983 7.691 5.62132034 2.75C5.62132034 2.33578644 5.95710678 2 6.37132034 2z"
      />
    </SvgIcon>
  )
}

export default ColumnSelectorIcon
