import { SHARE_REDUCER_KEY, types } from './actions'

import { handleActions } from 'redux-actions'

export { SHARE_REDUCER_KEY }

export const MAX_SHARE_ENTRIES = 200

const initialState = {
  // Whether or not the share modal is showing.
  modalIsVisible: false,

  // The share currently being shown in the modal.
  activeShare: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },

  // The shares to show on the internal user's share page.
  shares: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
}

export const shareModalIsVisibleSelector = state => state[SHARE_REDUCER_KEY].modalIsVisible
export const activeShareSelector = state => state[SHARE_REDUCER_KEY].activeShare
export const sharesSelector = state => state[SHARE_REDUCER_KEY].shares
export const publicShareSelector = state => state[SHARE_REDUCER_KEY].publicShare

export default handleActions(
  {
    [types.OPEN_SHARE_MODAL]: state => ({ ...state, modalIsVisible: true }),
    [types.CLOSE_SHARE_MODAL]: state => ({ ...state, modalIsVisible: false }),

    [types.CREATE_SHARE_START]: state => ({
      ...state,
      activeShare: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.CREATE_SHARE_SUCCESS]: (state, { payload }) => ({
      ...state,
      activeShare: { isLoading: false, isFailed: false, data: payload },
    }),
    [types.CREATE_SHARE_ERROR]: state => ({
      ...state,
      activeShare: { isLoading: false, isFailed: true, data: undefined },
    }),

    [types.FETCH_ACTIVE_SHARE_START]: state => ({
      ...state,
      activeShare: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_ACTIVE_SHARE_SUCCESS]: (state, { payload }) => ({
      ...state,
      activeShare: { isLoading: false, isFailed: false, data: payload },
    }),
    [types.FETCH_ACTIVE_SHARE_ERROR]: state => ({
      ...state,
      activeShare: { isLoading: false, isFailed: true, data: undefined },
    }),

    [types.CLEAR_ACTIVE_SHARE]: state => ({
      ...state,
      activeShare: { isLoading: false, isFailed: false, data: undefined },
    }),

    [types.FETCH_SHARES_START]: state => ({
      ...state,
      shares: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_SHARES_SUCCESS]: (state, { payload }) => ({
      ...state,
      shares: { isLoading: false, isFailed: false, data: payload },
    }),
    [types.FETCH_SHARES_ERROR]: state => ({
      ...state,
      shares: { isLoading: false, isFailed: true, data: undefined },
    }),

    [types.SEND_SHARE_EMAIL_START]: state => ({
      ...state,
      activeShare: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.SEND_SHARE_EMAIL_SUCCESS]: (state, { payload }) => ({
      ...state,
      activeShare: { isLoading: false, isFailed: false, data: payload },
    }),
    [types.SEND_SHARE_EMAIL_ERROR]: state => ({
      ...state,
      activeShare: { isLoading: false, isFailed: true, data: undefined },
    }),

    [types.UPDATE_SHARE]: state => ({
      ...state,
      shares: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.REQUEST_DELETE_SHARE]: state => ({
      ...state,
    }),
    [types.DELETE_SHARE]: state => ({
      ...state,
      shares: { isLoading: true, isFailed: false, data: undefined },
    }),
  },
  initialState
)
