/* global __API_URL__ */
import * as React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { IconButton, Tooltip } from '@material-ui/core'
import {
  apiKeyLoadingSelector,
  apiKeysSelector,
  generateApiToken,
  getApiKeys,
} from 'store/api/manageApiKeys'

import AdminButton from './AdminButton'
import Button from 'components/core/Button'
import ContentCopyIcon from 'components/icons/ContentCopyIcon'
import KeyIcon from '@material-ui/icons/VpnKey'
import { Loader } from 'components/Loader/Loader'
import SettingsIcon from 'components/icons/SettingsIcon'
import SortableTableHeader from 'components/SortableTableHeader'
import buildUrl from 'utils/urlBuilder'
import { connect } from 'react-redux'
import copy from 'copy-to-clipboard'
import { currentTenantFromState } from 'store/auth/user/selectors'
import get from 'lodash/get'
import logger from 'utils/logger'
import moment from 'moment'
import reverse from 'lodash/reverse'
import { showNotification } from 'store/notifications'
import sortBy from 'lodash/sortBy'

const styles = theme => ({
  dialogContent: {
    minWidth: 1250,
    minHeight: 600,
  },
  root: {},
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(230, 230, 230)',
      cursor: 'pointer',
    },
  },
  textField: {
    width: 300,
    marginLeft: theme.spacing(2),
  },
  actionButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.brand.color,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(100, 100, 100)',
      cursor: 'pointer',
    },
  },
  actionButtonLabel: {
    color: 'white',
    textTransform: 'none',
  },
  generateApiToken: {
    float: 'right',
    clear: 'both',
    position: 'relative',
  },
})

const DATE_FORMAT = 'YYYY-MM-DD'

const cols = [
  { id: 'tenant', label: 'Tenant' },
  { id: 'token', label: 'API Token suffix' },
  { id: 'share_link', label: 'Copy Share Link' },
  { id: 'share_link_expiration', label: 'Share Link Expiration' },
]

class ManageApiKeys extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      orderBy: 'tenant',
      order: 'asc',
      selectedRow: null,
      searchTerm: null,
    }
  }

  componentDidMount() {
    this.props.getApiKeys(this.props.currentTenant)
  }

  handleSort = (orderBy, order) => {
    this.setState({
      orderBy,
      order,
    })
  }

  toggleDialog = () => {
    const newIsOpen = !this.state.isOpen
    this.setState({ isOpen: newIsOpen })
    if (newIsOpen) this.props.getApiKeys(this.props.currentTenant)
  }

  sortData = data => {
    const { orderBy, order } = this.state
    let sortedData = sortBy(data, item => item[orderBy])
    if (order === 'desc') sortedData = reverse(sortedData)
    return sortedData
  }

  renderTitle = () => {
    if (this.props && this.props.apiKeys) {
      const allTokens = this.props.apiKeys
      const { selectedRow } = this.state
      if (selectedRow) return get(selectedRow, 'email')
      return `${allTokens.length} API Tokens`
    }
    return ''
  }

  onCopiedToClipboard = () => {
    logger.notify('API Token - Copy Token')
    const showNotification = this.props.showNotification
    showNotification('API Token copied to clipboard')
  }

  onShareLinkCopiedToClipboard = () => {
    logger.notify('API Token - Copy Shared Link')
    const showNotification = this.props.showNotification
    showNotification('Share Link copied to clipboard')
  }

  generateApiToken = () => {
    this.props.generateApiToken(this.props.currentTenant)
  }

  showGenerateApiToken = () => {
    const { classes } = this.props
    return this.props.currentTenant === 'super' ? (
      ''
    ) : (
      <div>
        <Button
          size="medium"
          classes={{ root: classes.actionButton, label: classes.actionButtonLabel }}
          onClick={() => this.generateApiToken()}
        >
          <SettingsIcon className={classes.settingsIcon} />
          Generate API Token
        </Button>
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { isOpen } = this.state
    return (
      <div className={classes.root}>
        <AdminButton onClick={this.toggleDialog} text={'Manage API Tokens'} icon={<KeyIcon />} />
        <Dialog
          open={isOpen}
          onClose={this.toggleDialog}
          classes={{ paper: classes.dialogContent }}
        >
          <DialogTitle id="manage-api-keys">{this.renderTitle()}</DialogTitle>
          <DialogContent>{this.renderContent()}</DialogContent>
          <DialogActions>
            {this.showGenerateApiToken()}
            <Button onClick={this.toggleDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderContent = () => {
    const { classes } = this.props
    const sortedData = this.sortData(this.props.apiKeys)
    if (this.props.apiKeysLoading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }

    return (
      <div>
        <Table>
          <SortableTableHeader
            stickyHeader
            onRequestSort={this.handleSort}
            cols={cols}
            defaultOrderBy={this.state.orderBy}
            defaultOrder={this.state.order}
          />
          <TableBody>
            {sortedData.map((row, idx) => {
              return (
                <TableRow className={classes.tableRow}>
                  <TableCell style={row.style}>{row.tenant}</TableCell>
                  <TableCell style={row.style}>
                    <Tooltip title="Copy token to clipboard" placement="top">
                      <IconButton
                        onClick={() => {
                          copy(row.token)
                          this.onCopiedToClipboard()
                        }}
                        className={classes.iconButton}
                        aria-label="Copy token to Clipboard"
                      >
                        <ContentCopyIcon className={classes.copyIcon} />
                      </IconButton>
                    </Tooltip>
                    {/* Only the last 40 characters of the token will be shown. However, the user will be able to copy the full token to the clipboard */}
                    {row.token.substring(row.token.length - 40)}
                  </TableCell>
                  <TableCell style={row.style}>
                    <Tooltip title="Copy share link to clipboard" placement="top">
                      <IconButton
                        onClick={() => {
                          copy(buildUrl({ pathname: __API_URL__ + row.share_link }))
                          this.onShareLinkCopiedToClipboard()
                        }}
                        className={classes.iconButton}
                        aria-label="Copy share link to Clipboard"
                        disabled={
                          row.share_link_expiration &&
                          moment(row.share_link_expiration).isBefore(moment.now())
                        }
                      >
                        <ContentCopyIcon className={classes.copyIcon} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.share_link_expiration &&
                      moment(row.share_link_expiration).format(DATE_FORMAT)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentTenant: currentTenantFromState(state),
  apiKeys: apiKeysSelector(state),
  apiKeysLoading: apiKeyLoadingSelector(state),
})

const dispatchToProps = {
  getApiKeys,
  generateApiToken,
  showNotification,
}

export default connect(mapStateToProps, dispatchToProps)(withStyles(styles)(ManageApiKeys))
