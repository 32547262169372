import { SvgIcon, SvgIconProps, Theme, makeStyles } from '@material-ui/core'

import React from 'react'

// Default color, most likely to be used this way most or all the time, easy to override.
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.teal[600],
  },
}))

const InTransitIcon = (props: SvgIconProps) => {
  const classes = useStyles(props)
  return (
    <SvgIcon classes={{ root: classes.root }} {...props}>
      <g fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z"
        />
        <path
          fill="currentColor"
          d="M12,6 C12.5522847,6 13,6.44771525 13,7 L12.9993593,14.585 L15.5355339,12.0494744 C15.9260582,11.6589501 16.5592232,11.6589501 16.9497475,12.0494744 C17.3402718,12.4399987 17.3402718,13.0731637 16.9497475,13.463688 L12.715568,17.6985438 C12.656361,17.7591846 12.5895095,17.8123284 12.5165713,17.8564176 C12.5106192,17.859327 12.5056735,17.8622486 12.5007084,17.8651239 C12.4734923,17.8816253 12.4443674,17.8966979 12.4144525,17.9103392 C12.4045305,17.9141015 12.3948986,17.9182967 12.3852161,17.9223329 C12.3586978,17.9342064 12.3310349,17.9443748 12.30283,17.9533263 L12.3020068,17.9527444 L12.2918437,17.9558945 C12.2865578,17.957502 12.2812613,17.9590648 12.2759546,17.9605828 C12.2611848,17.9656868 12.245312,17.9698307 12.2292908,17.9735893 L12.1916263,17.9807934 C12.1806509,17.9829257 12.1696467,17.9848729 12.1586186,17.9866351 C12.1294133,17.9921678 12.0990064,17.9956356 12.0682175,17.9977098 C12.0620309,17.997326 12.0560331,17.9976785 12.0500332,17.9979772 C12.0336196,17.9995828 12.0168585,18 12,18 L11.9499668,17.9979772 C11.9439412,17.9976772 11.9379178,17.997323 11.9318974,17.9969145 L11.8833789,17.9932723 L11.8833789,17.9932723 L11.8395355,17.9863384 C11.8342074,17.9854768 11.8288848,17.984572 11.8235685,17.983624 L11.81307,17.9825585 C11.7811831,17.976529 11.7498314,17.9689857 11.7190925,17.9600061 C11.7126205,17.9572713 11.7052963,17.9550508 11.6979932,17.9527444 C11.6689651,17.9443748 11.6413022,17.9342064 11.6142428,17.9228825 C11.6085202,17.9197218 11.6022777,17.9170442 11.5960574,17.9143002 L11.5845664,17.9098912 C11.5169899,17.8789874 11.4534541,17.8407768 11.3949697,17.79627 C11.3921789,17.7934528 11.3885899,17.7906861 11.3850156,17.7878916 C11.3489856,17.7604006 11.3156464,17.7305141 11.284432,17.6985438 L7.05025253,13.463688 C6.65972824,13.0731637 6.65972824,12.4399987 7.05025253,12.0494744 C7.44077682,11.6589501 8.0739418,11.6589501 8.46446609,12.0494744 L10.9993593,14.585 L11,7 C11,6.44771525 11.4477153,6 12,6 Z"
          transform="rotate(-90 12 12)"
        />
      </g>
    </SvgIcon>
  )
}

export default InTransitIcon
