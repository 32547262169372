// Not going to be converted to TS since this is slated to be deleted
import { Paper, makeStyles } from '@material-ui/core'

import P44LogoFullColor from 'assets/P44LogoFullColor'
import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  authDialog: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: 'center',
  },
  p44Logo: {
    height: 42,
    width: 120,
  },
}))

function DeprecatedAuthDialog({ className, children }) {
  const classes = useStyles()

  return (
    <Paper className={cx([classes.authDialog, className])} elevation={2}>
      <P44LogoFullColor className={classes.p44Logo} />
      {children}
    </Paper>
  )
}

export default DeprecatedAuthDialog
