import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  activateUser: ({ username, pwdToken, firstName, lastName, password }) => {
    return api._call('put', '/user/public/activate', {
      email: username,
      pwdToken,
      firstName,
      lastName,
      password,
    })
  },
  login: ({ username, password }) => {
    return api._call('post', '/login', { username, password })
  },
}

export default api
