import { Box, Paper, Theme, makeStyles } from '@material-ui/core'

import CardOverlay from 'components/core/CardOverlay'
import CenteredLayout from 'components/layouts/CenteredLayout'
import React from 'react'
import TimelineHeaderSkeleton from 'components/Timeline/TimelineHeader/Skeleton'
import TimelineSkeleton from 'components/Timeline/Skeleton'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(5),
    position: 'relative',
    width: 1080, // necessary to not cut off skeletons, which are fixed width
  },
  skeleton: {
    position: 'relative',
  },
  centeredLayout: {
    position: 'initial',
  },
}))

interface Props {
  classes?: Record<string, string>
  isAnimated?: boolean
}

const TabSkeleton = (props: Props) => {
  const classes = useStyles(props)
  const { isAnimated = true } = props
  return (
    <Box className={classes.container}>
      {[1, 2].map((item: number) => (
        <Box mb={3} key={`timelineview-${item}`} className={classes.skeleton}>
          {item === 1 && (
            <CenteredLayout className={classes.centeredLayout}>
              <CardOverlay>
                <>When your transport data becomes available, you can view it here.</>
              </CardOverlay>
            </CenteredLayout>
          )}
          <Paper elevation={4}>
            <Box px={3} pb={2}>
              <Box mb={4}>
                <TimelineHeaderSkeleton isAnimated={isAnimated} />
                <TimelineSkeleton isAnimated={isAnimated} />
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
    </Box>
  )
}

export default TabSkeleton
