import ApiRefType, { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import { ROLLUP_SHIPMENT, getShipmentDetailPath } from 'utils/rollups'
import { TableRow, makeStyles } from '@material-ui/core'

import { CommonInterface } from 'store/associatedReferences/interfaces'
import React from 'react'
import TableCell from 'components/ReferencesTable/TableCell'
import TableCellText from 'components/ReferencesTable/TableCellText'
import logger from 'utils/logger'

const useStyles = makeStyles(theme => ({
  root: {},
}))

interface Props {
  classes?: { [key: string]: string }
  data: CommonInterface
  refType: ApiRefTypeType
  index: number
}

const ShipmentTable = (props: Props) => {
  const classes = useStyles(props)
  const { data, index, refType } = props

  return (
    <TableRow className={classes.root} key={data.number}>
      <TableCell disableBorder>
        <TableCellText
          active={refType === 'shipments' && index === 0}
          onClick={() => {
            logger.notify(`Reference Link Click`, {
              entityTypeFrom: refType,
              entityTypeTo: ROLLUP_SHIPMENT,
            })
          }}
          href={
            refType !== ApiRefType.shipment
              ? getShipmentDetailPath(ROLLUP_SHIPMENT, data.number, data.externalId)
              : undefined
          }
        >
          {data.number}
        </TableCellText>
      </TableCell>
    </TableRow>
  )
}

export default ShipmentTable
