import { DATE_TIME_FORMAT, formatDateString } from 'utils/dateUtils'
import {
  DELIVERED,
  DISCHARGED,
  IN_TRANSIT,
  PENDING,
  UNKNOWN,
} from 'store/models/definitions/StatusLabel'
import LocationZone, { LocationZoneInterface } from 'store/models/LocationZone'
import Milestone, { MilestoneInterface } from 'store/models/Milestone'
import TransportStatus, { TransportStatusType } from 'store/models/definitions/TransportationStatus'
import Trip, { TripInterface } from 'store/models/Trip'

import { CarrierInterface } from 'store/models/Carrier'
import { EquipmentInterface } from 'store/models/Equipment'
import MilestoneUtils from 'utils/milestone'
import { StatusLabelType } from 'store/models/definitions/StatusLabel'
import TransportDetailHeaderInterface from 'store/transportDetail/interfaces/TransportDetailHeaderInterface'
import getFullMilestoneName from 'utils/shipmentUtils/getFullMilestoneName'
import { getRefTypeLabel } from 'utils/rollups'

export interface EntityPageHeaderInterface {
  actualDischarge: {
    time: string | null | undefined
  } | null
  bookings: TripInterface[]
  bols: TripInterface[]
  carriers: string[]
  containerNumber: string | null
  containerType: string | null
  delay: number | null
  equipment: EquipmentInterface
  externalId: string | null
  portOfDischarge: string | undefined | null
  portOfLoad: string | undefined | null
  predictedDischarge: {
    time: string | null | undefined
  } | null
  recentActivity: {
    time: string | null
    label: string | null
    container: TripInterface | null
  }
  refTypeLabel: string
  status: TransportStatusType
  statusLabel: StatusLabelType
  statusTrips: TripInterface[]
  trips: TripInterface[]
}

export interface Props extends TransportDetailHeaderInterface {
  self?: TripInterface
}

export default class EntityPageHeader {
  constructor({
    actualDischarge,
    bookings,
    bols,
    carriers,
    containerNumber,
    containerType,
    delay,
    equipment,
    externalId,
    portOfDischarge,
    portOfLoad,
    predictedDischarge,
    recentActivity,
    refTypeLabel,
    status,
    statusLabel,
    statusTrips,
    trips,
  }: EntityPageHeaderInterface) {
    Object.assign(this, {
      actualDischarge,
      bookings,
      bols,
      carriers,
      containerNumber,
      containerType,
      delay,
      equipment,
      externalId,
      portOfDischarge,
      portOfLoad,
      predictedDischarge,
      recentActivity,
      refTypeLabel,
      status,
      statusLabel,
      statusTrips,
      trips,
    })
  }

  static of(props: Props) {
    const {
      bookings,
      bols,
      carriers,
      equipment,
      lastExpectedMainUnloadMilestone,
      latestActualMilestone,
      mainLoadZones,
      mainUnloadZones,
      self,
      status,
      statusTrips,
      trips,
    } = props

    const latestMilestone =
      latestActualMilestone && (Milestone.of(latestActualMilestone) as MilestoneInterface)
    const action = latestMilestone?.action ?? null
    const lastTracedTime = latestMilestone?.tracedTime
    const equipmentActivity = latestMilestone?.stopSegment?.equipmentActivity ?? null
    const equipmentCategory = latestMilestone?.onEquipment?.category ?? null
    const stage = latestMilestone?.stopSegment?.tripSegment?.stage ?? null
    const facilityType = latestMilestone?.stopSegment?.facilityType ?? null

    const lastExpectedUnloadMilestone =
      lastExpectedMainUnloadMilestone &&
      (Milestone.of(lastExpectedMainUnloadMilestone) as MilestoneInterface)
    const dischargeAtDestPrediction = lastExpectedUnloadMilestone?.predictedTime ?? null

    // Port of load
    const loadLocationZones = mainLoadZones.map((zone: LocationZoneInterface) =>
      LocationZone.of(zone)
    )
    const firstLoadLocationZone = loadLocationZones[0] as LocationZoneInterface
    const portOfLoad = firstLoadLocationZone?.name ?? null

    // Port of discharge
    const dischargeLocationZones = mainUnloadZones.map((zone: LocationZoneInterface) =>
      LocationZone.of(zone)
    )
    const firstUnloadLocationZone = dischargeLocationZones[0] as LocationZoneInterface
    const portOfDischarge = firstUnloadLocationZone?.name ?? null
    const dischargeAtDestTraced = lastExpectedUnloadMilestone?.tracedTime
    const { actualDelay = null, predictedDelay = null } = lastExpectedUnloadMilestone ?? {}

    const delay = dischargeAtDestTraced ? actualDelay : predictedDelay

    let statusLabel: StatusLabelType
    switch (TransportStatus[status]) {
      case TransportStatus.completed:
      case TransportStatus.DISCHARGED:
        statusLabel = DISCHARGED
        break
      case TransportStatus.delivered:
      case TransportStatus.DELIVERED:
        statusLabel = DELIVERED
        break
      case TransportStatus.in_progress:
      case TransportStatus.in_transit:
      case TransportStatus.STARTED:
        statusLabel = IN_TRANSIT
        break
      case TransportStatus.UNFINISHED_BUT_INACTIVE:
        statusLabel = UNKNOWN
        break
      case TransportStatus.no_data:
      case TransportStatus.not_started:
      case TransportStatus.NOT_STARTED:
        statusLabel = PENDING
        break
      case TransportStatus.out_for_delivery:
        statusLabel = UNKNOWN
        break
    }

    let lastTracedType = MilestoneUtils.getMilestoneDisplayText({
      stage,
      action,
      equipmentActivity,
      equipmentCategory,
      facilityType,
    })

    lastTracedType =
      lastTracedType ?? getFullMilestoneName(stage, action, equipmentCategory, facilityType)

    return new EntityPageHeader({
      actualDischarge: {
        time: dischargeAtDestTraced
          ? formatDateString(dischargeAtDestTraced, DATE_TIME_FORMAT)
          : null,
      },
      bookings:
        bookings && (bookings.map((booking: TripInterface) => Trip.of(booking)) as TripInterface[]),
      bols: bols && (bols.map((bol: TripInterface) => Trip.of(bol)) as TripInterface[]),
      carriers: carriers.map((carrier: CarrierInterface) => carrier.abbreviation),
      containerNumber: self?.number ?? null,
      containerType: self?.type ?? null,
      delay,
      equipment: equipment[0],
      externalId: self?.externalId ?? null,
      portOfDischarge,
      portOfLoad,
      predictedDischarge: {
        time: dischargeAtDestPrediction
          ? formatDateString(dischargeAtDestPrediction, DATE_TIME_FORMAT)
          : null,
      },
      recentActivity: {
        label: lastTracedType ?? null,
        time: lastTracedTime ? formatDateString(lastTracedTime, DATE_TIME_FORMAT) : null,
        container: ((latestMilestone?.actualTrips[0] as unknown) as TripInterface) ?? null,
      },
      refTypeLabel: self ? getRefTypeLabel(self.type) : null,
      status: status && TransportStatus[status],
      statusLabel,
      statusTrips:
        statusTrips && (statusTrips.map((trip: TripInterface) => Trip.of(trip)) as TripInterface[]),
      trips: trips && (trips.map((trip: TripInterface) => Trip.of(trip)) as TripInterface[]),
    })
  }
}
