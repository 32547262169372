import * as definitions from './definitions'

import { GroupRuleInterface } from './interfaces'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import UiGroupInterface from 'store/types/UiGroupInterface'
import filter from 'lodash/filter'
import some from 'lodash/some'

/**
 * An object with keys for each UI group that apply the business rules for each group. The rules are documented below.
 * All groups have three functions:
 * - `isValidGroup`: determines if a group is "valid" (will appear in UI)
 * - `getValidMilestones` filters milestones to return "valid" milestones for that group
 * - `isComplete`: applies rules to determine if a group's progress is "complete"
 */
export const groupRules: GroupRuleInterface = {
  [definitions.EMPTY_DISPATCH]: {
    /**
     * UI display: Empty Dispatch
     * Milestones passed in will be `stopSegment.tripSegment.stage === pre_main`
     * Rules:
     *  At least one milestone: stopSegment.equipmentActivity === definitions.ACTIVITY_DISPATCH_RETURN
     */
    isValidGroup: (locationMilestones: GroupedMilestoneInterface[]) =>
      some(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity === definitions.ACTIVITY_DISPATCH_RETURN
      ),

    // Only return `stopSegment.equipmentActivity === ACTIVITY_DISPATCH_RETURN`
    getValidMilestones: (locationMilestones: GroupedMilestoneInterface[]) =>
      filter(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity === definitions.ACTIVITY_DISPATCH_RETURN
      ),

    /**
     * Group is "complete" rules:
     * All milestones: m.predictedTime === null
     *  AND At least one milestone: m.action === definitions.ACTION_DEPART
     *  AND m.tracedTime
     * OR any milestone in any following group: m.tracedTime
     */
    isComplete: (locationGroup: UiGroupInterface) => {
      const milestones = locationGroup.milestones
      return (
        locationGroup.subsequentGroupHasTracedMilestone ||
        (!some(milestones, m => m.predictedTime) &&
          some(milestones, m => m.action === definitions.ACTION_DEPART && m.tracedTime))
      )
    },
  },
  [definitions.PRE_CARRIAGE]: {
    /**
     * UI display: Pre-Carriage
     * Milestones passed in will be `stopSegment.tripSegment.stage === pre_main`
     * Rules:
     *  All milestones: stopSegment.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
     *  All milestones: m.stopSegment.equipmentActivity !== definitions.CATEGORY_VESSEL
     */
    isValidGroup: (locationMilestones: GroupedMilestoneInterface[]) =>
      locationMilestones
        .filter(
          (m: any) => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
        )
        .filter((m: any) => m.onEquipment?.category === definitions.CATEGORY_VESSEL).length === 0,

    // Return all milestoners where `stopSegment.equipmentActivity !== ACTIVITY_DISPATCH_RETURN`
    getValidMilestones: (locationMilestones: GroupedMilestoneInterface[]) =>
      filter(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
      ),

    /**
     * Group is "complete" rules:
     * All milestones: m.predictedTime === null
     *  AND At least one milestone: m.action === definitions.ACTION_DEPARTAND m.tracedTime
     * OR any milestone in any following group: m.tracedTime
     */
    isComplete: (locationGroup: UiGroupInterface) => {
      const milestones = locationGroup.milestones
      return (
        locationGroup.subsequentGroupHasTracedMilestone ||
        (!some(milestones, m => m.predictedTime) &&
          some(milestones, m => m.action === definitions.ACTION_DEPART && m.tracedTime))
      )
    },
  },
  [definitions.PORT_OF_LOAD]: {
    /**
     * UI display: Port of Load
     * Milestones passed in will be `stopSegment.tripSegment.stage === pre_main`
     * Rules:
     *  All milestones: stopSegment.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
     *  At least one milestone: m.stopSegment.equipmentActivity === definitions.CATEGORY_VESSEL
     */
    isValidGroup: (locationMilestones: GroupedMilestoneInterface[]) =>
      locationMilestones
        .filter(
          (m: any) => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
        )
        .filter((m: any) => m.onEquipment?.category === definitions.CATEGORY_VESSEL).length > 0,

    // Return all milestoners where `stopSegment.equipmentActivity !== ACTIVITY_DISPATCH_RETURN`
    getValidMilestones: (locationMilestones: GroupedMilestoneInterface[]) =>
      filter(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
      ),

    /**
     * Group is "complete" rules:
     * All milestones: m.predictedTime === null
     *  AND At least one milestone: m.action === definitions.ACTION_DEPART AND m.tracedTime AND m.onEquipment?.category === definitions.CATEGORY_VESSEL
     * OR any milestone in any following group: m.tracedTime
     */
    isComplete: (locationGroup: UiGroupInterface) => {
      const milestones = locationGroup.milestones
      return (
        locationGroup.subsequentGroupHasTracedMilestone ||
        (!some(milestones, m => m.predictedTime) &&
          some(
            milestones,
            m =>
              m.action === definitions.ACTION_DEPART &&
              m.tracedTime &&
              m.onEquipment?.category === definitions.CATEGORY_VESSEL
          ))
      )
    },
  },
  [definitions.TRANSSHIPMENT]: {
    /**
     * UI display: Port of Discharge
     * Milestones passed in will be `stopSegment.tripSegment.stage === post_main`
     * Rules:
     * None
     */
    isValidGroup: (locationMilestones: GroupedMilestoneInterface[]) => true,

    // Return all milestoners where `stopSegment.equipmentActivity !== ACTIVITY_DISPATCH_RETURN`
    getValidMilestones: (locationMilestones: GroupedMilestoneInterface[]) =>
      filter(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
      ),

    /**
     * Group is "complete" rules:
     * All milestones: m.predictedTime === null
     *  AND At least one milestone: m.action === definitions.ACTION_DEPART AND m.tracedTime AND m.onEquipment?.category === definitions.CATEGORY_VESSEL
     * OR any milestone in any following group: m.tracedTime
     */
    isComplete: (locationGroup: UiGroupInterface) => {
      const milestones = locationGroup.milestones
      return (
        locationGroup.subsequentGroupHasTracedMilestone ||
        (!some(milestones, m => m.predictedTime) &&
          some(
            milestones,
            m =>
              m.action === definitions.ACTION_DEPART &&
              m.tracedTime &&
              m.onEquipment?.category === definitions.CATEGORY_VESSEL
          ))
      )
    },
  },
  [definitions.PORT_OF_DISCHARGE]: {
    /**
     * UI display: Port of Discharge
     * Milestones passed in will be `stopSegment.tripSegment.stage === post_main`
     * Rules:
     *  All milestones: stopSegment.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
     *  At least one milestone: m.stopSegment.equipmentActivity === definitions.CATEGORY_VESSEL
     */
    isValidGroup: (locationMilestones: GroupedMilestoneInterface[]) =>
      locationMilestones
        .filter(
          (m: any) => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
        )
        .filter((m: any) => m.onEquipment?.category === definitions.CATEGORY_VESSEL).length > 0,

    // Return all milestoners where `stopSegment.equipmentActivity !== ACTIVITY_DISPATCH_RETURN`
    getValidMilestones: (locationMilestones: GroupedMilestoneInterface[]) =>
      filter(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
      ),

    /**
     * Group is "complete" rules:
     * All milestones: m.predictedTime === null AND At least one milestone: m.action === definitions.ACTION_DEPART AND m.tracedTime
     * OR any milestone in any following group: m.tracedTime
     */
    isComplete: (locationGroup: UiGroupInterface) => {
      const milestones = locationGroup.milestones
      return (
        locationGroup.subsequentGroupHasTracedMilestone ||
        (!some(milestones, m => m.predictedTime) &&
          some(milestones, m => m.action === definitions.ACTION_DEPART && m.tracedTime))
      )
    },
  },
  [definitions.ON_CARRIAGE]: {
    /**
     * UI display: On-Carriage
     * Milestones passed in will be `stopSegment.tripSegment.stage === post_main`
     * Rules:
     *  All milestones: stopSegment.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
     *  All milestones: m.stopSegment.equipmentActivity !== definitions.CATEGORY_VESSEL
     */
    isValidGroup: (locationMilestones: GroupedMilestoneInterface[]) =>
      locationMilestones
        .filter(
          (m: any) => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
        )
        .filter((m: any) => m.onEquipment?.category === definitions.CATEGORY_VESSEL).length === 0,

    // Return all milestoners where `stopSegment.equipmentActivity !== ACTIVITY_DISPATCH_RETURN`
    getValidMilestones: (locationMilestones: GroupedMilestoneInterface[]) =>
      filter(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity !== definitions.ACTIVITY_DISPATCH_RETURN
      ),

    /**
     * Group is "complete" rules:
     * All milestones: m.predictedTime === null
     *  AND At least one milestone: m.tracedTime && m.action === definitions.ACTION_DEPART
     *   OR At least one milestone: m.tracedTime && m.action === ACTION_ARRIVE &&
     *     m.stopSegment.equipmentActivity === ACTIVITY_STUFFING
     */
    isComplete: (locationGroup: UiGroupInterface) => {
      const milestones = locationGroup.milestones
      return (
        locationGroup.subsequentGroupHasTracedMilestone ||
        (!some(milestones, m => m.predictedTime) &&
          some(
            milestones,
            m =>
              (m.tracedTime && m.action === definitions.ACTION_DEPART) ||
              (m.tracedTime &&
                m.action === definitions.ACTION_ARRIVE &&
                m.stopSegment?.equipmentActivity === definitions.ACTIVITY_STUFFING)
          ))
      )
    },
  },
  /**
   * UI display: Empty Return
   * Milestones passed in will be `stopSegment.tripSegment.stage === post_main`
   * Rules:
   *  At least one milestone: stopSegment.equipmentActivity === definitions.ACTIVITY_DISPATCH_RETURN
   */
  [definitions.EMPTY_RETURN]: {
    isValidGroup: (locationMilestones: GroupedMilestoneInterface[]) =>
      some(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity === definitions.ACTIVITY_DISPATCH_RETURN
      ),

    // Return all milestoners where `stopSegment.equipmentActivity === ACTIVITY_DISPATCH_RETURN`
    getValidMilestones: (locationMilestones: GroupedMilestoneInterface[]) =>
      filter(
        locationMilestones,
        m => m.stopSegment?.equipmentActivity === definitions.ACTIVITY_DISPATCH_RETURN
      ),

    /**
     * Group is "complete" rules:
     * All milestones: m.predictedTime === null
     *  AND At least one milestone: m.action === definitions.ACTION_DEPART AND m.tracedTime
     * OR any milestone in any following group: m.tracedTime
     */
    isComplete: (locationGroup: UiGroupInterface) => {
      const milestones = locationGroup.milestones
      return (
        locationGroup.subsequentGroupHasTracedMilestone ||
        (!some(milestones, m => m.predictedTime) &&
          some(milestones, m => m.action === definitions.ACTION_ARRIVE && m.tracedTime))
      )
    },
  },
}

export default groupRules
