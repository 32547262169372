import { default as MUITable, TableProps } from '@material-ui/core/Table'
import { Theme, makeStyles } from '@material-ui/core'

import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderColor: theme.palette.grey[200],
  },
}))

const Table = (props: TableProps) => {
  const classes = useStyles(props)
  const { className, ...rest } = props

  return <MUITable className={cx(className, classes.root)} {...rest} />
}

export default Table
