import { BOARD_UTILS_REDUCER_KEY, types } from 'store/boardUtils/actions'
import {
  EQUIPMENT_TYPE_KEY,
  ID_KEY,
  OCEAN_DELAY_KEY,
  PRIMARY_MODE_KEY,
} from 'store/board/utils/columns'

import { PICKER_MODES } from 'utils/filterUtils'
import { ROLLUPS } from 'utils/rollups'
import { TRANSPORTATION_STATUS_PARAM_NAME } from 'utils/querystring'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import createFilterableModule from 'store/filterFactory'
import { defaultShipmentFilters } from 'store/boardUtils/utils/shipmentFilters'
import find from 'lodash/find'
import get from 'lodash/get'
import { getQueryStringValue } from 'utils/urlBuilder'
import { handleActions } from 'redux-actions'
import reject from 'lodash/reject'
import shipmentColumns from 'store/board/utils/columns'
import store from 'store'
import without from 'lodash/without'

export const TABLE_VIEW = 'table'
export const LIST_VIEW = 'list'
export const VIEW_TYPE_KEY = 'boardUtils.viewType'

const HIDDEN_COLUMNS_KEY = 'columnsHidden'
const COLUMN_CUSTOMIZATION_KEY = 'boardUtils.columnCustomization'

// Translated to true/false/parameter-absent at API request time (`store/board/sagas/api.js`)
export const TransportationStatusOptions = {
  VIEW_ACTIVE_ONLY: 'ACTIVE_ONLY',
  VIEW_IDLE_AND_COMPLETED: 'IDLE_AND_COMPLETED',
  VIEW_ALL: 'VIEW_ALL',
}

const getFirstColumns = () => [ID_KEY, OCEAN_DELAY_KEY, PRIMARY_MODE_KEY, EQUIPMENT_TYPE_KEY]
export const getDefaultColumnOrder = () => getColumnsOrdered(shipmentColumns)
export const getDefaultColumnHidden = () => []

export const getColumnsOrdered = columns => {
  let orderedCols = getFirstColumns()

  columns.forEach(col => {
    if (!orderedCols.includes(col.key)) orderedCols.push(col.key)
  })
  return orderedCols
}

const configColumnCustomization = () => {
  const storedCustomization = store.get(COLUMN_CUSTOMIZATION_KEY)
  let columnCustomization = {}

  ROLLUPS.forEach(rollup => {
    columnCustomization[rollup] = {
      hidden: get(storedCustomization, `${rollup}.hidden`) || getDefaultColumnHidden(),
      order: get(storedCustomization, `${rollup}.order`) || getDefaultColumnOrder(),
    }
  })

  return columnCustomization
}

/**
 * Used to set initial state for `transportationStatus`.
 * Checks for and uses query string param first, then defaults to active only
 */
export const transportationStatusParamFirstState = () => {
  const param = getQueryStringValue(TRANSPORTATION_STATUS_PARAM_NAME)
  const activeParam =
    param && find(TransportationStatusOptions, option => option === param.toUpperCase())
  return activeParam ? activeParam : TransportationStatusOptions.VIEW_ACTIVE_ONLY
}

export const initialState = {
  sortState: {
    sort_param: 'latest_milestone_time',
    direction: 'desc',
  },
  viewType: store.get(VIEW_TYPE_KEY, LIST_VIEW),
  filterData: defaultShipmentFilters,
  hiddenColumnTitles: store.get(HIDDEN_COLUMNS_KEY) || [],
  columns: shipmentColumns,
  rollup: ROLLUPS[0],
  isImportDialogOpen: false,
  defaultImportDialogValues: {
    rollup: '',
    items: [],
  },
  isEditingView: false,
  transportationStatus: transportationStatusParamFirstState(),
  views: [],
  createLoading: false,
  updateLoading: false,
  currentView: null,
  dateRangeMode: PICKER_MODES.CUSTOM,
  columnCustomization: configColumnCustomization(),
}

const boardUtils = handleActions(
  {
    [types.SET_VIEW_TYPE]: (state, { payload }) => {
      store.set(VIEW_TYPE_KEY, payload)
      return { ...state, viewType: payload }
    },
    [types.SET_SORT_STATE]: (state, { payload }) => ({
      ...state,
      sortState: payload,
    }),
    [types.TRANSPORTATION_STATUS]: (state, { payload }) => ({
      ...state,
      transportationStatus: payload,
    }),
    [types.TOGGLE_COLUMN]: (state, { payload: { title } }) => {
      let newHiddenTitles = state.hiddenColumnTitles.includes(title)
        ? without(state.hiddenColumnTitles, title)
        : concat(state.hiddenColumnTitles, title)

      store.set(HIDDEN_COLUMNS_KEY, newHiddenTitles)
      return { ...state, hiddenColumnTitles: newHiddenTitles }
    },
    [types.TOGGLE_COLUMNS]: (state, { payload: { hiddenColumns } }) => {
      let columnCustomization = cloneDeep(state.columnCustomization)

      columnCustomization[state.rollup].hidden = hiddenColumns

      store.set(COLUMN_CUSTOMIZATION_KEY, columnCustomization)
      return {
        ...state,
        columnCustomization,
        hiddenColumnTitles: hiddenColumns,
      }
    },
    [types.UPDATE_COLUMN_ORDER]: (state, { payload: { orderedColumns } }) => {
      let columnCustomization = cloneDeep(state.columnCustomization)

      const columnsOrdered = getColumnsOrdered(orderedColumns)
      columnCustomization[state.rollup].order = columnsOrdered

      store.set(COLUMN_CUSTOMIZATION_KEY, columnCustomization)
      return { ...state, columnCustomization, columnsOrdered }
    },
    [types.TOGGLE_IMPORT_DIALOG]: (
      state,
      { payload = initialState.defaultImportDialogValues }
    ) => ({
      ...state,
      isImportDialogOpen: !state.isImportDialogOpen,
      defaultImportDialogValues: payload,
    }),
    [types.SET_ROLLUP_SUCCESS]: (state, { payload }) => ({
      ...state,
      rollup: payload,
    }),
    [types.SET_INITIAL_ROLLUP_SUCCESS]: (state, { payload }) => ({
      ...state,
      rollup: payload,
    }),
    [types.FETCH_VIEWS_SUCCESS]: (state, { payload }) => ({
      ...state,
      views: payload,
    }),
    [types.CREATE_VIEW_SUCCESS]: state => ({
      ...state,
      createLoading: false,
    }),
    [types.CREATE_VIEW_START]: state => ({
      ...state,
      createLoading: true,
    }),
    [types.UPDATE_VIEW_START]: state => ({
      ...state,
      updateLoading: true,
    }),
    [types.EDIT_VIEW]: (state, { payload }) => {
      const nextViews = [...state.views, payload]
      return { ...state, views: nextViews }
    },
    [types.UPDATE_VIEW_SUCCESS]: state => ({
      ...state,
      updateLoading: false,
    }),
    [types.DELETE_VIEW_SUCCESS]: (state, { payload }) => {
      const view = find(state.views, ['id', payload])
      const isCurrentView = view && view === state.currentView
      const nextViews = reject(state.views, ['id', payload])
      return {
        ...state,
        views: nextViews,
        currentView: isCurrentView ? null : state.currentView,
      }
    },
    [types.SELECT_VIEW_SUCCESS]: (state, { payload }) => ({
      ...state,
      currentView: payload,
    }),
    [types.CLEAR_VIEW_SUCCESS]: state => ({
      ...state,
      currentView: null,
    }),
    [types.SET_DATE_RANGE]: (state, { payload }) => {
      const { dialog } = state
      const filters = get(dialog, 'filters', {})
      const nextFilters = {
        ...filters,
        min_latest_milestone_time: payload.startDate,
        max_latest_milestone_time: payload.endDate,
      }
      const nextDialog = { ...dialog, filters: nextFilters }
      return { ...state, dialog: nextDialog }
    },
    [types.SET_DATE_RANGE_MODE]: (state, { payload }) => ({
      ...state,
      dateRangeMode: payload,
    }),
  },
  initialState
)

export const filterEnhancedReducer = createFilterableModule(
  BOARD_UTILS_REDUCER_KEY,
  boardUtils,
  initialState
)

export default filterEnhancedReducer
