import { Carrier } from 'utils/shipmentObjs'
import get from 'lodash/get'

const structureOceanCarriers = carriers => {
  return carriers.map(carrier => {
    const name = get(carrier, 'name')
    const scacCode = get(carrier, 'scac_code')
    return new Carrier(name, scacCode)
  })
}

export default structureOceanCarriers
