import { ButtonBase, Tooltip, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import FiltersCollapsedIcon from 'components/icons/FiltersCollapsed'
import FiltersExpandedIcon from 'components/icons/FiltersExpanded'
import get from 'lodash/get'

const useStyles = makeStyles(theme => ({
  toggleDrawerButton: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    padding: theme.spacing(1),
  },
}))

type DrawerToggleProps = {
  isOpen: boolean
  onClick: (isOpen: boolean) => void
  tooltipText: {
    open: string
    close: string
  }
}

const DrawerToggle = (props: DrawerToggleProps) => {
  const { isOpen, onClick, tooltipText } = props
  const classes = useStyles()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const onTooltipOpen = () => setIsTooltipOpen(true)
  const onTooltipClose = () => setIsTooltipOpen(false)

  const getText = () => {
    const openText = get(tooltipText, 'open')
    const closeText = get(tooltipText, 'close')
    const hasText = Boolean(openText) && Boolean(closeText)
    if (!hasText) return false
    return isOpen ? closeText : openText
  }

  const title = getText()

  return (
    <Tooltip
      title={title}
      onOpen={onTooltipOpen}
      onClose={onTooltipClose}
      open={Boolean(title) && isTooltipOpen}
    >
      <ButtonBase
        className={classes.toggleDrawerButton}
        disableRipple
        onClick={() => onClick(isOpen)}
      >
        {isOpen ? <FiltersExpandedIcon /> : <FiltersCollapsedIcon />}
      </ButtonBase>
    </Tooltip>
  )
}
export default DrawerToggle
