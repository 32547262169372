import { Typography, makeStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import TimeIcon from '@material-ui/icons/AccessTime'
import get from 'lodash/get'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.grey[50],
    marginRight: theme.spacing(1),
  },
  latestUpdateWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  latestUpdateText: {
    color: theme.palette.grey[50],
    fontSize: 11,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.1,
  },
}))

const FULL_DATE_FORMAT = 'D MMM YYYY h:mma'
const HOURS_IN_DAY = 24

export default function LatestUpdate({ currVessel }) {
  const classes = useStyles()

  const timeSince = date => {
    if (moment(date).isValid()) {
      const utcDate = moment(date).utc()
      const now = moment().utc()
      var duration = moment.duration(now.diff(utcDate))
      var diff = Math.floor(duration.asHours())
      let hoursOrDays
      if (diff > 0) {
        if (Math.abs(diff) > HOURS_IN_DAY) {
          hoursOrDays = 'days'
          diff = Math.round(now.hours(0).diff(utcDate.hours(0), 'hours') / HOURS_IN_DAY) // get diff in calendar days
        } else {
          hoursOrDays = 'hours'
        }
        return `${diff} ${hoursOrDays} ago`
      }
    }
  }

  const formatDate = date => {
    if (moment(date).isValid()) {
      return moment(date).utc().format(FULL_DATE_FORMAT) + ' UTC'
    }
    return 'N/A'
  }

  const hoursAgo = timeSince(get(currVessel, 'vesselLoc.timestamp'))
  const typeText = 'Vessel'

  return currVessel && get(currVessel, 'vesselLoc.timestamp') ? (
    <div className={classes.latestUpdateWrapper}>
      <TimeIcon className={classes.icon} />
      <Typography variant="body2" className={classes.latestUpdateText}>
        {`${typeText} data updated: ${formatDate(get(currVessel, 'vesselLoc.timestamp'))}`}
        <br />
        {hoursAgo && ` (${hoursAgo})`}
      </Typography>
    </div>
  ) : (
    <div style={{ display: 'none' }} />
  )
}

LatestUpdate.propTypes = {
  currVessel: PropTypes.object.isRequired,
}

LatestUpdate.defaultProps = {
  currVessel: {},
}
