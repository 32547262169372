import { JOURNEY_DETAILS_REDUCER_KEY } from 'store/journeyDetails/actions'
import { JourneyDetailsState } from 'store/journeyDetails/interfaces'

export const journeyDetailsSelector = (state: {
  [JOURNEY_DETAILS_REDUCER_KEY]: JourneyDetailsState
}) => state[JOURNEY_DETAILS_REDUCER_KEY].data

export const journeyDetailsLoadingSelector = (state: {
  [JOURNEY_DETAILS_REDUCER_KEY]: JourneyDetailsState
}) => state[JOURNEY_DETAILS_REDUCER_KEY].isLoading
