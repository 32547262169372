import * as Yup from 'yup'

import { IconButton, InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {
  intendedUsernameFromState,
  loginErrorMessageFromState,
  loginLoadingFromState,
} from 'store/auth/login/selectors'

import AuthActions from 'components/AuthActions'
import Button from 'components/core/Button'
import Link from 'components/core/Link'
import { Loader } from 'components/Loader/Loader'
import { PASSWORD_RESET_ROUTE } from 'utils/routes'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { isValidEmail } from 'utils/validate'
import { useFormHelper } from 'utils/hooks'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    // consistent width makes variable-length error text display consistently
    width: theme.spacing(45),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formActions: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  ssoFormActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    margin: 'auto',
  },
  wideButton: {
    width: '100%',
  },
  loginErrorMessage: {
    width: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  emailDisabled: boolean
  onSubmit: (params: { email: string; password: string }) => void
  ssoLogin?: boolean
}
const LoginForm = (props: Props) => {
  const { emailDisabled, onSubmit, ssoLogin } = props
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const intendedUsername = useSelector(intendedUsernameFromState)
  const loginErrorMessage = useSelector(loginErrorMessageFromState)
  const loading = useSelector(loginLoadingFromState)

  const { handleSubmit, errors, touched, values, handleChange, handleBlur } = useFormHelper({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      email: intendedUsername || '',
      password: '',
    },
    onSubmit: (params: { email: string; password: string }) =>
      onSubmit({ email: params.email, password: params.password }),
    // couldn't get submission to work with a validate: function here
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .test(
          'valid-email',
          'Please enter a valid email. Example: name@website.com',
          (value: string | null | undefined) => {
            return isValidEmail(value)
          }
        ),
      password: Yup.string().trim().required('Please enter your password'),
    }),
  })

  return (
    <form id="login-form" className={classes.form} onSubmit={handleSubmit}>
      <TextField
        disabled={emailDisabled}
        data-testid="login-form--email-input"
        error={Boolean(errors.email && touched.email)}
        fullWidth
        helperText={errors.email || (emailDisabled ? null : 'Example: name@website.com')}
        id="email"
        label="Email"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        variant="filled"
      />
      <TextField
        autoFocus={Boolean(values.email)}
        data-testid="login-form--password-input"
        error={Boolean(errors.password && touched.password)}
        fullWidth
        helperText={errors.password}
        id="password"
        InputProps={{
          type: showPassword ? 'text' : 'password',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Password"
        name="password"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.password}
        variant="filled"
      />
      {/* only show login error message here in deprecated UI */}
      {!ssoLogin && loginErrorMessage && (
        <Typography color="error" variant="body2" className={classes.loginErrorMessage}>
          {loginErrorMessage}
        </Typography>
      )}
      {!ssoLogin ? (
        <AuthActions className={classes.formActions}>
          {loading ? (
            <Loader className={classes.loader} />
          ) : (
            <>
              <Typography variant="subtitle2">
                <Link to={PASSWORD_RESET_ROUTE.buildUrl()}>Forgot password?</Link>
              </Typography>
              <Button
                data-testid="login-form--submit"
                color="primary"
                variant="contained"
                id="submit-login"
                type="submit"
              >
                Sign In
              </Button>
            </>
          )}
        </AuthActions>
      ) : (
        <>
          <AuthActions className={classes.ssoFormActions}>
            {loading ? (
              <Loader className={classes.loader} />
            ) : (
              <Button
                data-testid="login-form--submit"
                className={classes.wideButton}
                color="primary"
                variant="contained"
                id="submit-login"
                type="submit"
                size="large"
              >
                Sign In
              </Button>
            )}
          </AuthActions>
          <AuthActions className={classes.ssoFormActions}>
            <Typography variant="subtitle2">
              <Link to={PASSWORD_RESET_ROUTE.buildUrl()}>Forgot your password?</Link>
            </Typography>
          </AuthActions>
        </>
      )}
    </form>
  )
}

export default LoginForm
