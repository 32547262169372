import { ACTION_DEPART } from './definitions'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import last from 'lodash/last'

/**
 * Determines if the last milestone in an array of milestones has `action: 'depart'`
 * @param milestones array
 * @return boolean
 */
export const lastMilestoneIsDepart = (milestones: GroupedMilestoneInterface[]) => {
  return last<any>(milestones)?.action === ACTION_DEPART
}

export default lastMilestoneIsDepart
