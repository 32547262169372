import humps from 'humps'

/**
 * Builds and returns payload objects for the saga
 * Returns object:
 * {
 *  `postPayload` array of alert configs, only populated if checked alerts exist and _none_ of them has `alertId`, because that indicates a new/first-time `POST`. `postPayload` and `putPayload` are mutually exclusive.
 * `putPayload` array of alert configs, only populated if checked alerts exsit and _at least one_ of them has `alertId`, because that indicates this is an existing form being edited. Note that as long as there is one config with an `alertId` `PUT` will also take alert configs with no `alertId`. `postPayload` and `putPayload` are mutually exclusive
 *  `deletePayload` array of `alertId`s, populated with any unchecked alerts that have `alertId` because that indicates they were selected and now are being removed. A `DELETE` request can be made regardless of `PUT` or `POST`.
 * }
 */
export default function getPayloads({ rollup, alerts }) {
  let checkedWithAlertIdCount = 0

  // If we have _any_ id's we know we can only `PUT`, otherwise we will `POST`
  for (const alert in alerts) {
    if (alerts[alert].alertId) {
      checkedWithAlertIdCount++
    }
  }

  function getHasIds(alert) {
    return Boolean(alert.alertId)
  }

  function isDeletable(alert) {
    return !alert.checked && getHasIds(alert)
  }

  function isPostable(alert) {
    return alert.checked && !getHasIds(alert) && checkedWithAlertIdCount === 0
  }

  function isPutable(alert) {
    return alert.checked && checkedWithAlertIdCount > 0
  }

  let deletePayload = []
  let postPayload = []
  let putPayload = []

  Object.keys(alerts).forEach(alertKey => {
    const alert = alerts[alertKey]

    if (isDeletable(alert)) {
      deletePayload.push(alert.alertId)
    }

    if (isPostable(alert)) {
      postPayload = [
        ...postPayload,
        {
          ...alert.getApiPayload({
            rollup,
            input: alert.inputValue ?? '',
            inputs: {
              input: alert.inputValue ?? '',
              maxDaysValue: alert.maxDaysValue ?? '',
              minDaysValue: alert.minDaysValue ?? '',
              timingValue: alert.timingValue ?? '',
            },
          }),
        },
      ]
    }

    if (isPutable(alert)) {
      putPayload = [
        ...putPayload,
        alert.getApiPayload({
          rollup,
          input: alert.inputValue ?? '',
          alertConfigId: alert.alertId,
          inputs: {
            input: alert.inputValue ?? '',
            maxDaysValue: alert.maxDaysValue ?? '',
            minDaysValue: alert.minDaysValue ?? '',
            timingValue: alert.timingValue ?? '',
          },
        }),
      ]
    }
  })
  return {
    postPayload: humps.decamelizeKeys(postPayload),
    deletePayload,
    putPayload: humps.decamelizeKeys(putPayload),
  }
}
