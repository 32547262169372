import { Dialog, DialogContent, DialogTitle, TextField, withStyles } from '@material-ui/core'

import AddIcon from '@material-ui/icons/AddCircleOutline'
import AdminButton from './AdminButton'
import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { addSource } from 'store/auth/tenant/actions'
import classnames from 'classnames'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {},
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  contentRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogPaper: {
    minWidth: 700,
    minHeight: 500,
  },
  addButton: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.brand.color,
  },
  addLabel: {
    color: 'white',
  },
  disabled: {
    backgroundColor: 'grey',
  },
})

class AddSource extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      newSource: '',
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  handleCancel = () => {
    this.setState({
      open: false,
    })
  }

  openDialog = () => {
    this.setState({
      open: true,
    })
  }

  handleTextFieldChange = e => {
    this.setState({
      newSource: e.target.value,
    })
  }

  handleAddSource = () => {
    this.props.addSource(this.state.newSource)
  }

  render() {
    const { open, newSource } = this.state
    const { classes } = this.props
    const addClassnames = classnames(classes.addButton, {
      [classes.disabled]: newSource.length === 0,
    })
    return (
      <div className={classes.root}>
        <AdminButton onClick={this.openDialog} text={'Add Source'} icon={<AddIcon />} />
        <Dialog open={open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>
            Add Source
            <CloseIcon className={classes.closeIcon} onClick={this.handleCancel} />
          </DialogTitle>
          <DialogContent className={classes.contentRoot}>
            <div>
              <div>
                <TextField
                  value={newSource}
                  onChange={this.handleTextFieldChange}
                  label={'new source'}
                />
              </div>
              <div>
                <Button
                  disabled={newSource.length === 0}
                  classes={{ root: addClassnames, label: classes.addLabel }}
                  onClick={this.handleAddSource}
                >
                  Add Source
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const mapDispatchToProps = {
  addSource,
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddSource))
