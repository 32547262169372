import React, { PureComponent } from 'react'

import ErrorBoundary from 'components/ErrorBoundary'
import FilterList from 'components/Filtering/FilterList'
import FilterMenu from 'components/Filtering/FilterMenu'
import PropTypes from 'prop-types'
import styles from './styles'
import { withStyles } from '@material-ui/core'

class FilterPane extends PureComponent {
  state = {
    modalOpen: false,
    inputValue: '',
    warning: null,
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    filterGroups: PropTypes.array.isRequired,
    filterConfigs: PropTypes.array.isRequired,
    activeFilter: PropTypes.object,
    activeDefaultFilter: PropTypes.object,
    setActiveFilter: PropTypes.func.isRequired,
    unsetActiveFilter: PropTypes.func.isRequired,
    addFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    setFiltersFromURL: PropTypes.func.isRequired,
    urlQuery: PropTypes.object.isRequired,
    showImportButton: PropTypes.bool,
    showDefaultFilters: PropTypes.bool,
    toggleImportDialog: PropTypes.func,
    customization: PropTypes.object,
  }

  static defaultProps = {
    showImportButton: false,
    showDefaultFilters: false,
  }

  render() {
    const {
      filters,
      activeFilter,
      activeDefaultFilter,
      filterGroups,
      filterConfigs,
      removeFilter,
      setActiveFilter,
      unsetActiveFilter,
      addFilter,
      showSaved,
      showImportButton,
      showDefaultFilters,
      toggleImportDialog,
      customization,
    } = this.props

    return (
      <ErrorBoundary>
        <FilterMenu
          showSaved={showSaved}
          filters={filters}
          filterGroups={filterGroups}
          filterConfigs={filterConfigs}
          activeFilter={activeFilter}
          activeDefaultFilter={activeDefaultFilter}
          setActiveFilter={setActiveFilter}
          unsetActiveFilter={unsetActiveFilter}
          addFilter={addFilter}
          showImportButton={showImportButton}
          showDefaultFilters={showDefaultFilters}
          toggleImportDialog={toggleImportDialog}
          customization={customization}
        />
        <FilterList
          filters={filters}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          removeFilter={removeFilter}
          toggleImportDialog={toggleImportDialog}
        />
      </ErrorBoundary>
    )
  }
}

export default withStyles(styles)(FilterPane)
