import React, { useLayoutEffect } from 'react'

import { getEffectiveTimeInputString } from 'pages/PortalAlertModal/PortalAlertForm/utils/getEffectiveTimeInputString'
import getPaddedInputString from 'pages/PortalAlertModal/PortalAlertForm/utils/getPaddedInputString'

// see https://egghead.io/lessons/react-preserve-cursor-position-when-filtering-out-characters-from-a-react-input
// this gives you a callback of code to run after a rerender has taken place. in he case below, we are setting the
// input cursor after render
function useRunAfterUpdate() {
  // this will hold a reference to a callback to run after render
  const afterPaintRef = React.useRef<null | (() => void)>(null)
  // useLayoutEffect does not seem necessary (useLayout seems to also work), but since the component is limited to a
  // single input it should not make much difference either way. useLayoutEffect will be synchronous with DOM updates
  // but is otherwise identical to useEffect
  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current()
      afterPaintRef.current = null
    }
  })
  return (fn: () => void) => (afterPaintRef.current = fn)
}

interface OnChange {
  target: {
    name: string
    value: string
  }
}

interface RestProps {
  value: string
}

interface Props extends RestProps {
  inputRef: React.MutableRefObject<null>
  onChange: (props: OnChange) => void
  name: string
}

const ValidatedHourInput = (props: Props) => {
  const { inputRef, onChange, name, ...other } = props
  const runAfterUpdate = useRunAfterUpdate()
  return (
    <input
      {...other}
      ref={inputRef}
      onBlur={e => {
        const paddedString = getPaddedInputString(e.target.value)
        if (paddedString !== undefined) {
          onChange({
            target: {
              name: props.name,
              value: paddedString,
            },
          })
        }
      }}
      onChange={e => {
        const input = e.target
        const previousPosition = input.selectionStart
        const newValue = e.target.value
        const oldValue = other.value
        const inputString = getEffectiveTimeInputString(newValue, oldValue)
        // avoid moving cursor even if we didn't allow the input
        // as shown in https://egghead.io/lessons/react-preserve-cursor-position-when-filtering-out-characters-from-a-react-input
        runAfterUpdate(() => {
          input.selectionStart = previousPosition
          input.selectionEnd = previousPosition
        })

        // always send an update so we always set cursor position in our runAfterUpdate() above to keep cursor
        // from moving to the end of the line unintuitively
        const effectiveString = inputString !== undefined ? inputString : oldValue
        onChange({
          target: {
            name: props.name,
            value: effectiveString,
          },
        })
      }}
    />
  )
}

export default ValidatedHourInput
