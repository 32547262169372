import { Typography, makeStyles } from '@material-ui/core'
import {
  selectIsExhaustiveSelector,
  selectedShipments as selectedShipmentsSelector,
} from 'store/board/selectors'

import PropTypes from 'prop-types'
import React from 'react'
import { getRollupLabel } from 'utils/rollups'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  shipmentSummary: {
    color: theme.palette.blue[800],
    fontSize: 18,
  },
  selectExhaustiveButton: {
    textTransform: 'none',
    color: theme.palette.blue[400],
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: 10,
    marginBottom: 2,
  },
}))

const SelectedSummary = function ({ rollup, selectedCount, totalCount }) {
  const classes = useStyles()
  const selectIsExhaustive = useSelector(selectIsExhaustiveSelector)
  const selectedShipments = useSelector(selectedShipmentsSelector)
  const rollupLabel = getRollupLabel(rollup)

  return (
    <Typography
      variant="caption"
      className={classes.shipmentSummary}
      data-testid="board__selected_summary"
    >
      {selectedShipments.length > 0 || selectIsExhaustive ? (
        <span>
          {selectIsExhaustive ? selectedCount : selectedShipments.length} {rollupLabel} selected
        </span>
      ) : (
        <span data-testid="board__total-count">
          {totalCount} {rollupLabel} total
        </span>
      )}
    </Typography>
  )
}

SelectedSummary.propTypes = {
  rollup: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedCount: PropTypes.number.isRequired,
}

export default SelectedSummary
