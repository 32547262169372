import { API_DELIMITER, getDateDisplayVal } from 'utils/filterUtils'

import { FILTER_GROUP_ENTITIES } from 'store/boardUtils/utils/shipmentFilters'
import get from 'lodash/get'
import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD'
const DATE_TYPE = 'datetime'

export const EQUAL = 'eq'
export const GREATER_THAN_OR_EQUAL = 'gte'
export const LESS_THAN_OR_EQUAL = 'lte'
export const BETWEEN = 'between'

const getUrlParamFcn = filterSpec => {
  const key = get(filterSpec, 'key')
  const dataType = get(filterSpec, 'type')
  if (dataType !== DATE_TYPE) {
    return val => buildTagParam(key, EQUAL, val.map(item => item.label).join(API_DELIMITER))
  } else {
    return val => buildDateParam(key, val)
  }
}

const getDisplayValFcn = filterSpec => {
  const dataType = get(filterSpec, 'type')
  if (dataType !== DATE_TYPE) {
    return val => val.map(item => item.label).join(', ')
  } else {
    return getDateDisplayVal('days from now')
  }
}

export const buildCustomFilter = filterSpec => {
  const isFilterable = get(filterSpec, 'filterable', true)
  if (!isFilterable) return null

  const name = get(filterSpec, 'key')
  const title = get(filterSpec, 'title')
  const dataType = get(filterSpec, 'type')
  const filterType = dataType === DATE_TYPE ? 'daterange' : 'multitext'

  let filterConfig = {
    name,
    title,
    filterData: {
      isCustom: true,
      group: FILTER_GROUP_ENTITIES,
      type: filterType,
      hintText: title,
      getUrlParam: getUrlParamFcn(filterSpec),
      getDisplayVal: getDisplayValFcn(filterSpec),
    },
  }
  if (dataType === DATE_TYPE) {
    filterConfig['filterData']['relativeTextQualifier'] = 'from now'
  } else {
    filterConfig['filterData']['lookupPath'] = `/custom_fields/suggest/${name}`
  }
  return filterConfig
}

const buildTagParam = (key, op, val) => {
  return { 'tag[]': `${key}:${op}:${val}` }
}

const getOperator = (startDate, endDate, type) => {
  if (type === 'absolute') {
    const isStartDateValid = moment(startDate).isValid()
    const isEndDateValid = moment(endDate).isValid()
    if (isStartDateValid && isEndDateValid && startDate === endDate) return EQUAL
    else if (isStartDateValid && !isEndDateValid) return GREATER_THAN_OR_EQUAL
    else if (!isStartDateValid && isEndDateValid) return LESS_THAN_OR_EQUAL
    else if (isStartDateValid && isEndDateValid && startDate !== endDate) return BETWEEN
  } else {
    // distinguish between empty value and provided value of 0.
    // despite these variables being named 'date', they can actually be relative timedelta values, such as
    // 'between 0 and 3 days from now', in which case startDate will === 0 and endDate will === 3.
    // If integers were only ever passed in, we could replace these cumbersome expressions with Number.isInteger()
    // calls.
    const startDateNonEmpty = startDate !== null && startDate !== undefined && !isNaN(startDate)
    const endDateNonEmpty = endDate !== null && endDate !== undefined && !isNaN(endDate)
    if (startDateNonEmpty && !endDateNonEmpty) return GREATER_THAN_OR_EQUAL
    else if (!startDateNonEmpty && endDateNonEmpty) return LESS_THAN_OR_EQUAL
    else if (startDateNonEmpty && endDateNonEmpty && startDate !== endDate) return BETWEEN
  }
}

const getOperatorAndParam = (startDate, endDate, type) => {
  let operator = getOperator(startDate, endDate, type)

  let formattedStart = startDate
  let formattedEnd = endDate
  if (type === 'absolute') {
    // if operator == EQUAL, then change endDate = endDate + 1, and treat it like a BETWEEN operator
    if (operator === EQUAL) {
      endDate = moment(endDate).add(1, 'days')
      operator = BETWEEN
    }

    if (moment(startDate).isValid()) formattedStart = moment(startDate).format(DATE_FORMAT)
    if (moment(endDate).isValid()) formattedEnd = moment(endDate).format(DATE_FORMAT)
  }

  let dateParam
  if (operator === GREATER_THAN_OR_EQUAL) dateParam = formattedStart
  else if (operator === LESS_THAN_OR_EQUAL) dateParam = formattedEnd
  else if (operator === BETWEEN) dateParam = `${formattedStart}||${formattedEnd}`

  return { operator, dateParam }
}

export const buildDateParam = (param, val) => {
  let { startDate, endDate, type } = val
  const { operator, dateParam } = getOperatorAndParam(startDate, endDate, type)
  return { 'tag[]': `${param}:${operator}:${dateParam}` }
}
