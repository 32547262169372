import { NavigationControl } from 'react-map-gl'
import React from 'react'

// This overrides NavigationControl to work around https://github.com/uber/react-map-gl/issues/952
export default class NavigationControlOverride extends NavigationControl {
  _renderIcon() {
    return <span className="mapboxgl-ctrl-icon" aria-hidden="true" />
  }

  render() {
    const { className, showCompass, showZoom } = this.props

    return (
      <div className={`mapboxgl-ctrl mapboxgl-ctrl-group ${className}`} ref={this._containerRef}>
        {showZoom && this._renderButton('zoom-in', 'Zoom In', this._onZoomIn, this._renderIcon())}
        {showZoom &&
          this._renderButton('zoom-out', 'Zoom Out', this._onZoomOut, this._renderIcon())}
        {showCompass &&
          this._renderButton('compass', 'Reset North', this._onResetNorth, this._renderCompass())}
      </div>
    )
  }
}
