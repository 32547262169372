import { ProfileInterface } from '../interfaces'

export const PROFILE_REDUCER_KEY = 'profile'

export const types = {
  FETCH_PROFILE_START: `${PROFILE_REDUCER_KEY}/FETCH_PROFILE_START`,
  FETCH_PROFILE_SUCCESS: `${PROFILE_REDUCER_KEY}/FETCH_PROFILE_SUCCESS`,
  FETCH_PROFILE_ERROR: `${PROFILE_REDUCER_KEY}/FETCH_PROFILE_ERROR`,
  UPDATE_PROFILE_START: `${PROFILE_REDUCER_KEY}/UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${PROFILE_REDUCER_KEY}/UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${PROFILE_REDUCER_KEY}/UPDATE_PROFILE_ERROR`,
  UPDATE_ACCEPTED_TERMS_START: `${PROFILE_REDUCER_KEY}/UPDATE_ACCEPTED_TERMS_START`,
  UPDATE_ACCEPTED_TERMS_SUCCESS: `${PROFILE_REDUCER_KEY}/UPDATE_ACCEPTED_TERMS_SUCCESS`,
  UPDATE_ACCEPTED_TERMS_ERROR: `${PROFILE_REDUCER_KEY}/UPDATE_ACCEPTED_TERMS_ERROR`,
}

export const updateProfileAcceptedTerms = () => ({ type: types.UPDATE_ACCEPTED_TERMS_START })

export const submitProfile = (payload: ProfileInterface) => ({
  type: types.UPDATE_PROFILE_START,
  payload,
})

export const fetchProfile = () => ({ type: types.FETCH_PROFILE_START })
