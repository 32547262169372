import * as Yup from 'yup'

import { TextField, Theme, Typography, makeStyles } from '@material-ui/core'

import Button from '../../../components/core/Button'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import logger from '../../../utils/logger'
import { useDispatch } from 'react-redux'
import { useFormHelper } from '../../../utils/hooks'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1280,
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
    maxWidth: 500,
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 'auto',
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
  },
}))

type UserFormProps = {
  onSubmit: Function
  user: {
    firstName?: string
    lastName?: string
    title?: string
    team?: string
    location?: string
    email?: string
    tenant?: string
  }
}

const UserForm = ({ onSubmit, user = {} }: UserFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    touched,
    values,
    handleBlur,
    setFieldValue,
  } = useFormHelper({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      title: user.title || '',
      team: user.team || '',
      location: user.location || '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().trim().label('First Name').required('Required'),
      lastName: Yup.string().trim().label('Last Name').required('Required'),
      title: Yup.string().trim().label('Job Title').required('Required'),
    }),
    onSubmit: () => {
      logger.notify('Settings Profile Form Submit', {
        email: user.email,
        tenant: user.tenant,
      })
      const { firstName, lastName, title, team, location } = values
      dispatch(onSubmit({ firstName, lastName, title, team, location }))
    },
  })

  const trimAndSetValue = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setFieldValue(field, e.target.value.trimLeft())
  }

  return (
    <form onSubmit={handleSubmit} data-testid="registration-form">
      <div className={classes.root}>
        <Typography className={classes.sectionHeader} variant="subtitle1" color="textSecondary">
          Personal Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              InputLabelProps={{ required: true }}
              className={classes.textField}
              data-testid="user-form__firstName"
              error={Boolean(errors.firstName && touched.firstName)}
              helperText={errors.firstName}
              label="First Name"
              name="firstName"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => trimAndSetValue(e, 'firstName')}
              value={values.firstName}
              variant="filled"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              InputLabelProps={{ required: true }}
              className={classes.textField}
              data-testid="user-form__lastName"
              error={Boolean(errors.lastName && touched.lastName)}
              helperText={errors.lastName}
              label="Last Name"
              name="lastName"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => trimAndSetValue(e, 'lastName')}
              value={values.lastName}
              variant="filled"
            />
          </Grid>
          <Grid item md={4} xs={12} />
        </Grid>

        <Typography className={classes.sectionHeader} variant="subtitle1" color="textSecondary">
          Work Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              InputLabelProps={{ required: true }}
              className={classes.textField}
              data-testid="user-form__jobTitle"
              error={Boolean(errors.title && touched.title)}
              helperText={errors.title}
              label="Job Title"
              name="title"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => trimAndSetValue(e, 'title')}
              value={values.title}
              variant="filled"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              className={classes.textField}
              data-testid="user-form__team"
              error={Boolean(errors.team && touched.team)}
              helperText={errors.team}
              label="Team"
              name="team"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => trimAndSetValue(e, 'team')}
              value={values.team}
              variant="filled"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              className={classes.textField}
              data-testid="user-form__location"
              error={Boolean(errors.location && touched.location)}
              helperText={errors.location}
              label="Location"
              name="location"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => trimAndSetValue(e, 'location')}
              value={values.location}
              variant="filled"
            />
          </Grid>
        </Grid>
      </div>

      <Button
        className={classes.button}
        color="primary"
        data-testid="user-form__button"
        disabled={isSubmitting || !isValid}
        size="large"
        type="submit"
        variant="contained"
      >
        Update
      </Button>
    </form>
  )
}

export default UserForm
