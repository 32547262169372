import { createAction } from 'redux-actions'
export const BOARD_REDUCER_KEY = 'board'

export const types = {
  DESELECT_ALL_SHIPMENTS: `${BOARD_REDUCER_KEY}/DESELECT_ALL_SHIPMENTS`,
  DESELECT_SHIPMENTS: `${BOARD_REDUCER_KEY}/DESELECT_SHIPMENTS`,
  GET_INITIAL_REF_TYPE: `${BOARD_REDUCER_KEY}/GET_INITIAL_REF_TYPE`,
  GET_REF_TYPE_COUNTS_LOAD: `${BOARD_REDUCER_KEY}/GET_REF_TYPE_COUNTS_LOAD `,
  GET_REF_TYPE_COUNTS_SUCCESS: `${BOARD_REDUCER_KEY}/GET_REF_TYPE_COUNTS_SUCCESS`,
  GET_SHIPMENTS_LOAD: `${BOARD_REDUCER_KEY}/GET_SHIPMENTS_LOAD`,
  GET_SHIPMENTS_LOAD_SUCCESS: `${BOARD_REDUCER_KEY}/GET_SHIPMENTS_LOAD_SUCCESS`,
  GET_SHIPMENTS_COUNT_SUCCESS: `${BOARD_REDUCER_KEY}/GET_SHIPMENTS_COUNT_SUCCESS`,
  RESET_DATA: `${BOARD_REDUCER_KEY}/RESET_DATA`,
  RESET_SHIPMENTS: `${BOARD_REDUCER_KEY}/RESET_SHIPMENTS`,
  SELECT_ALL_SHIPMENTS: `${BOARD_REDUCER_KEY}/SELECT_ALL_SHIPMENTS`,
  SELECT_SHIPMENTS: `${BOARD_REDUCER_KEY}/SELECT_SHIPMENTS`,
  SET_PAGE: `${BOARD_REDUCER_KEY}/SET_PAGE`,
  GET_TRIP_TIMELINES_LOAD: `${BOARD_REDUCER_KEY}/GET_TRIP_TIMELINES_LOAD`,
  GET_TRIP_TIMELINES_SUCCESS: `${BOARD_REDUCER_KEY}/GET_TRIP_TIMELINES_SUCCESS`,
  GET_TRIP_TIMELINES_ERROR: `${BOARD_REDUCER_KEY}/GET_TRIP_TIMELINES_ERROR`,
}

export const deselectAllShipments = createAction(types.DESELECT_ALL_SHIPMENTS)
export const deselectShipments = createAction(types.DESELECT_SHIPMENTS)
export const getInitialRefType = createAction(types.GET_INITIAL_REF_TYPE)
export const getRefTypeCounts = createAction(types.GET_REF_TYPE_COUNTS_LOAD)
export const setPage = createAction(types.SET_PAGE, (page = 0) => page)
export const resetShipmentApiState = createAction(types.RESET_SHIPMENTS)
export const selectAllShipments = createAction(types.SELECT_ALL_SHIPMENTS)
export const selectShipments = createAction(types.SELECT_SHIPMENTS)

export const getShipments = (
  ids,
  refType,
  filters = {},
  page = 0,
  sortParams = {},
  transportationStatus = null
) => ({
  type: types.GET_SHIPMENTS_LOAD,
  payload: {
    ids,
    refType,
    filters,
    page,
    sortParams,
    transportationStatus,
  },
})

export const getTripTimelines = (
  ids,
  refType,
  filters = {},
  page = 0,
  sortParams = {},
  transportationStatus = null
) => ({
  type: types.GET_SHIPMENTS_LOAD,
  payload: {
    ids,
    refType,
    filters,
    page,
    sortParams,
    transportationStatus,
  },
})
