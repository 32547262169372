import React, { ReactNode } from 'react'

import { Link as MUILink } from '@material-ui/core'
import { Link as ReactRouterLink } from 'react-router-dom'

type LinkProps = {
  'data-testid'?: string
  children?: ReactNode
  className?: string
  classes?: object
  component?: string
  href?: string
  key?: string
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  style?: object
  target?: string
  to?: string
}

// const Button = React.forwardRef<HTMLButtonElement, Props>((props: any, ref) => {
const Link = React.forwardRef<HTMLButtonElement, LinkProps>((props: any, ref) => {
  const { children, ...rest } = props

  return (
    <MUILink component={ReactRouterLink} {...rest} ref={ref}>
      {children}
    </MUILink>
  )
})

export default Link
