import * as Yup from 'yup'

import { TextField, Typography, makeStyles } from '@material-ui/core'

import AuthActions from 'components/AuthActions'
import Button from 'components/core/Button'
import PasswordTextField from 'components/PasswordTextField'
import React from 'react'
import { useFormHelper } from 'utils/hooks'
import validationSchema from 'components/PasswordTextField/validationSchema'

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  authActions: {
    paddingTop: theme.spacing(2),
  },
  textfield: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  loader: {
    margin: 'auto',
  },
  agreementLink: {
    color: theme.palette.blue[400],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const RegistrationForm = ({ email, onSubmit }) => {
  const classes = useStyles()

  const {
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    touched,
    values,
    handleChange,
    handleBlur,
  } = useFormHelper({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: email,
      password: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .trim()
        // specifies field name within error message—otherwise returns 'firstName'
        .label('First Name')
        // eslint-disable-next-line no-template-curly-in-string
        .required('Required'),
      lastName: Yup.string()
        .trim()
        // specifies field name within error message—otherwise returns 'lastName'
        .label('Last Name')
        // eslint-disable-next-line no-template-curly-in-string
        .required('Required'),
      email: Yup.string()
        .trim()
        .label('Email')
        .email()
        // eslint-disable-next-line no-template-curly-in-string
        .required('Required'),
      password: validationSchema,
    }),
    onSubmit: onSubmit,
  })

  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="registration-form">
      <TextField
        className={classes.textfield}
        data-testid="registration-form__email"
        disabled
        error={errors.email && touched.email}
        helperText={errors.email}
        fullWidth
        label="Email"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        variant="filled"
      />
      <TextField
        className={classes.textfield}
        data-testid="registration-form__first-name"
        error={errors.firstName && touched.firstName}
        fullWidth
        helperText={errors.firstName}
        InputLabelProps={{ required: true }}
        label="First Name"
        name="firstName"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.firstName}
        variant="filled"
      />
      <TextField
        className={classes.textfield}
        data-testid="registration-form__last-name"
        error={errors.lastName && touched.lastName}
        fullWidth
        helperText={errors.lastName}
        InputLabelProps={{ required: true }}
        label="Last Name"
        name="lastName"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.lastName}
        variant="filled"
      />
      <PasswordTextField
        className={classes.textfield}
        data-testid="registration-form__password"
        error={errors.password && touched.password}
        helperText={errors.password}
        InputLabelProps={{ required: true }}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.password}
        fullWidth
      />
      <AuthActions className={classes.authActions}>
        <>
          <Button
            data-testid="registration-form__button"
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={isSubmitting || !isValid}
            size="large"
          >
            Register
          </Button>
          <Typography variant="body2" color="textSecondary">
            {`By clicking "Register" you agree to`}
            <br />
            <a
              className={classes.agreementLink}
              href="https://clearmetal.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              project44 Terms
            </a>{' '}
            and{' '}
            <a
              className={classes.agreementLink}
              href="https://clearmetal.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </Typography>
        </>
      </AuthActions>
    </form>
  )
}

export default RegistrationForm
