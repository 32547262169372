import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { authReducer } from 'store/auth/reducer'
import { watchAlertEmail } from 'store/auth/alertEmail'
import { watchConfigureUsers } from 'store/auth/configureUsers'
import { watchImpersonate } from 'store/auth/impersonate/sagas'
import { watchInvite } from 'store/auth/invite/sagas'
import { watchLogin } from 'store/auth/login/sagas'
import { watchLogout } from 'store/auth/logout/sagas'
import { watchPasswordResetConfirmation } from 'store/auth/passwordResetConfirmation/sagas'
import { watchPasswordResetEmail } from 'store/auth/passwordResetEmail/sagas'
import { watchRegister } from 'store/auth/register/sagas'
import { watchTenant } from 'store/auth/tenant/sagas'
import { watchTokenCheck } from 'store/auth/tokenCheck/sagas'
import { watchUserInfo } from 'store/auth/user/sagas'

export const authSagas = [
  watchLogin(),
  watchLogout(),
  watchRegister(),
  watchTokenCheck(),
  watchTenant(),
  watchInvite(),
  watchUserInfo(),
  watchPasswordResetEmail(),
  watchPasswordResetConfirmation(),
  watchImpersonate(),
  watchAlertEmail(),
  watchConfigureUsers(),
]

export const authReducers = {
  [AUTH_REDUCER_KEY]: authReducer,
}
