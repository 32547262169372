import includes from 'lodash/includes'
import logger from 'utils/logger'
import moment from 'moment-timezone'

export const DATE_FORMAT = 'D MMM YYYY'
export const DATE_TIME_FORMAT = 'D MMM YYYY HH:mm'

/**
   Add a 0 to the beginning only if necessary (converts "1" to "01", converts "11" to "11")
   */
function prependZeroIfNeeded(obj) {
  return ('0' + obj).slice(-2)
}

/**
 * Simple date formatter / re-formatter, does no transformations
 * @param string dateString any valid string that `moment` can handle
 * @param string dateFormat any valid `moment` date format string
 * @returns string
 */
export function formatDateString(dateString, dateFormat = DATE_FORMAT) {
  if (!dateString) {
    return null
  }

  const formatted = moment(dateString).format(dateFormat)
  return !formatted || formatted === 'Invalid date' ? null : formatted
}

/**
 * Formats a UTC timestamp string and optionally can apply a timezone.
 * @param {object} { dateString: <UTC timestamp>, `format`: <format string>, `timezone`: string }
 */
export function getDateFromUtcString({ dateString, format = DATE_FORMAT, timezone = null }) {
  if (!dateString) {
    return null
  }

  let formatted = moment(dateString)

  if (timezone) {
    formatted = formatted.tz(timezone)
  }
  formatted = formatted.format(format)

  return !formatted || formatted === 'Invalid date' ? null : formatted
}

export function getLocalizedMoment(timestamp, timezone) {
  // explicitly do not allow undefined.  Otherwise, moment will return today's date
  if (timestamp === undefined) return timestamp

  const instance = moment.parseZone(timestamp)
  if (timezone) instance.tz(timezone)
  return instance
}

export function getLocalizedNow(timezone) {
  const instance = moment()
  if (timezone) {
    instance.tz(timezone)
  }
  return instance
}

export function parseCustomFieldDate(times, format) {
  let parsedVals = []
  for (let i = 0; i < times.length; i++) {
    let currTimeStamp = times[i]
    let parsedVal = moment(currTimeStamp)
    if (parsedVal.isValid()) parsedVals.push(parsedVal.format(format))
  }
  return parsedVals.join(', ')
}

export function formatDate(momentObj) {
  const year = momentObj.year()
  const month = prependZeroIfNeeded(momentObj.month() + 1)
  const day = prependZeroIfNeeded(momentObj.date())
  return `${year}-${month}-${day}`
}

export function formatTime(momentObj, truncatedMinute) {
  const hour = prependZeroIfNeeded(momentObj.hour())
  if (!truncatedMinute) {
    const minute = prependZeroIfNeeded(momentObj.minute())
    return `${hour}:${minute}`
  } else {
    return `${hour}:00`
  }
}

export function getFormattedTimezone(timezone) {
  if (timezone) {
    return timezone.replace('_', ' ')
  }
  return ''
}

export function getFormattedMomentDateTime(momentObj, dateTimeFormat) {
  return momentObj && momentObj.isValid() ? momentObj.format(dateTimeFormat) : momentObj
}

/**
 * Takes in raw dateTime string, converts to local moment, then returns formated moment
 */
export function getMomentDateTimeFromRaw(dateTimeString, dateTimeFormat = DATE_FORMAT) {
  if (!dateTimeString) {
    return null
  }

  const momentDateTime = getLocalizedMoment(dateTimeString, moment.tz.guess())
  return getFormattedMomentDateTime(momentDateTime, dateTimeFormat)
}

/**
 * Determins if one date is between two other dates
 * @param {string} betweenDate The date we are checking
 * @param {string} startDate Start date for between
 * @param {string} endDate End data for between
 * @returns {boolean}
 */
export function isBetweenDate(betweenDate, startDate, endDate) {
  try {
    if (!betweenDate || !startDate || !endDate) {
      return false
    }

    return moment(betweenDate).isBetween(startDate, endDate)
  } catch (e) {
    logger.captureAppException(e)
    logger.localLog(`isBetweenDate: ${e}`, 'error')
    return false
  }
}

/**
 * Returns the different between two datetimes. We require both dates to already be `moment` formatted so that this
 * is truly just a diff calculator.
 * @param {string} initialDate must be already a `moment` formatted date
 * @param {string} dateToCompare must be already a `moment` formatted date
 * @param {string} measurement
 * @param {boolean} showDecimals
 * @returns {number | null}
 * @example
 * ```
 * const planned = getMomentDateTimeFromRaw(plannedTime, DATE_FORMAT)
 * const predicted = getMomentDateTimeFromRaw(predictedTime, DATE_FORMAT)
 * const diff = getDateDiff(planned, predicted, 'days')
 * ```
 */
export function getDateDiff(
  initialDate,
  dateToCompare,
  measurement = 'hours',
  showDecimals = false
) {
  if (
    !includes(['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'], measurement) ||
    !initialDate ||
    !dateToCompare
  ) {
    return null
  }

  try {
    // No date transforming going on, just wrapping with `moment` so we can `diff`
    const momentInitialDate = moment(initialDate)
    const momentCompareToDate = moment(dateToCompare)
    if (momentInitialDate.isValid() && momentCompareToDate.isValid()) {
      return momentInitialDate.diff(momentCompareToDate, measurement, showDecimals)
    }
    return null
  } catch (e) {
    logger.captureAppException(e)
    logger.localLog(`getDateDiffInHours: ${e}`, 'error')
    return null
  }
}

export default {
  formatDate,
  formatDateString,
  formatTime,
  getDateDiff,
  getDateFromUtcString,
  getFormattedMomentDateTime,
  getFormattedTimezone,
  getLocalizedMoment,
  getLocalizedNow,
  getMomentDateTimeFromRaw,
  isBetweenDate,
  parseCustomFieldDate,
}
