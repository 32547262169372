import { BoardColumnInterface, BoardRowInterface } from 'store/board/interfaces'

import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import NotProvided from 'components/NotProvided'
import React from 'react'
import Typography from '@material-ui/core/Typography'

interface Props {
  className?: string
  column: BoardColumnInterface
  onContextMenu: () => void
  rollup: string
  row: BoardRowInterface
}

const StandardCell = (props: Props) => {
  const { className, column, onContextMenu, rollup, row } = props
  let value = column.getCellValue(row) ?? <NotProvided text="Not Provided" />

  return (
    <BaseCell
      className={className}
      column={column}
      onContextMenu={onContextMenu}
      rollup={rollup}
      row={row}
    >
      <Typography variant="body2">{value}</Typography>
    </BaseCell>
  )
}

export default React.memo(StandardCell)
