export const TRIP_GROUPS_REDUCER_KEY = 'tripGroups'

export const types = {
  FETCH_TRIP_GROUPS_START: `${TRIP_GROUPS_REDUCER_KEY}/FETCH_TRIP_GROUPS_START`,
  FETCH_TRIP_GROUPS_SUCCESS: `${TRIP_GROUPS_REDUCER_KEY}/FETCH_TRIP_GROUPS_SUCCESS`,
  FETCH_TRIP_GROUPS_FAILURE: `${TRIP_GROUPS_REDUCER_KEY}/FETCH_TRIP_GROUPS_FAILURE`,
  FETCH_TRIP_GROUPS_RESET: `${TRIP_GROUPS_REDUCER_KEY}/FETCH_TRIP_GROUPS_RESET`,
}

export const fetchTripGroups = (tripIds: string[]) => ({
  type: types.FETCH_TRIP_GROUPS_START,
  tripIds,
})

export const resetTripGroups = () => ({
  type: types.FETCH_TRIP_GROUPS_RESET,
})
