import { Theme, makeStyles } from '@material-ui/core/styles'

import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import Checkbox from 'components/core/Checkbox'
import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: 22,
  },
  tableCellOverride: {
    width: '50px !important',
    minWidth: '50px !important',
    paddingRight: 0,
    paddingTop: 5,
    verticalAlign: 'top',
  },
}))

interface Props {
  className?: string
  handleCheck: () => void
  handleUncheck: () => void
  isSelected: boolean
}

const SelectionCell = (props: Props) => {
  const classes = useStyles()
  const { handleCheck, handleUncheck, isSelected, className } = props

  return (
    <BaseCell className={classnames(classes.tableCellOverride, className)}>
      <div className={classes.container}>
        <Checkbox
          color="primary"
          aria-label="Select"
          checked={isSelected}
          onCheck={handleCheck}
          onUncheck={handleUncheck}
        />
      </div>
    </BaseCell>
  )
}

export default React.memo(SelectionCell)
