import Milestone, { MilestoneInterface } from 'store/models/Milestone'
import Trip, { TripInterface } from 'store/models/Trip'

import getFullMilestoneName from 'utils/shipmentUtils/getFullMilestoneName'
import getMilestoneDisplayText from 'utils/milestone/getMilestoneDisplayText'
import humps from 'humps'

export interface TripsInterface {
  trip: TripInterface
  latestActualMilestone: MilestoneInterface
  currentMilestoneType: string | null
}

export interface TripGroupInterface {
  bookings: TripInterface[]
  trips: TripsInterface[]
}

export default class TripGroup {
  constructor({ bookings, trips }: TripGroupInterface) {
    Object.assign(this, { bookings, trips })
  }

  static of(payload: object) {
    const { bookings, trips } = humps.camelizeKeys(payload) as TripGroupInterface

    const getLatestMilestoneType = (latestActualMilestone: MilestoneInterface) => {
      const action = latestActualMilestone?.action ?? null
      const equipmentCategory = latestActualMilestone?.onEquipment?.category ?? null
      const equipmentActivity = latestActualMilestone?.stopSegment?.equipmentActivity ?? null
      const stage = latestActualMilestone?.stopSegment?.tripSegment?.stage ?? null
      const facilityType = latestActualMilestone?.stopSegment?.facilityType ?? null
      const milestoneDisplayText = getMilestoneDisplayText({
        stage,
        action,
        equipmentActivity,
        equipmentCategory,
        facilityType,
      })

      // We have fall-through logic here, if the first one doesn't return a value we call the second one.
      return (
        milestoneDisplayText ?? getFullMilestoneName(stage, action, equipmentCategory, facilityType)
      )
    }

    return new TripGroup({
      bookings:
        bookings && (bookings.map((booking: TripInterface) => Trip.of(booking)) as TripInterface[]),
      trips:
        trips &&
        trips.map((tripGroup: TripsInterface) => {
          return {
            trip: Trip.of(tripGroup.trip) as TripInterface,
            latestActualMilestone: Milestone.of(
              tripGroup.latestActualMilestone
            ) as MilestoneInterface,
            currentMilestoneType: getLatestMilestoneType(tripGroup.latestActualMilestone),
          }
        }),
    })
  }
}
