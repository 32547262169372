import { EXCEPTION_OCCURRED, EXCEPTION_ROLLED } from 'utils/milestone/MilestoneGroups/definitions'

import { ExceptionInterface } from 'store/models/Exception'
import some from 'lodash/some'

/**
 * Determines if any exception has a `exception.type === rolled"` and  `exception.occurred === "true"`
 * @param exceptions milestone.exceptions
 */
const hasRoll = (exceptions: ExceptionInterface[]) =>
  exceptions &&
  some(exceptions, e => e.exceptionType === EXCEPTION_ROLLED && e.occurred === EXCEPTION_OCCURRED)

export default hasRoll
