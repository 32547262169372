import { Box, IconButton, Theme, makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import {
  TIMESTAMP_CARRIER_ESTIMATE_ARRIVE,
  TIMESTAMP_CARRIER_ESTIMATE_LOAD,
  TIMESTAMP_CARRIER_ESTIMATE_UNLOAD,
  TIMESTAMP_MESSAGES,
} from 'utils/milestone/MilestoneGroups/definitions'

import InfoIcon from 'components/icons/InfoIcon'
import PopoverText from 'components/PopoverText'
import Reaction from 'components/ProductFeedback/Reaction'
import classnames from 'classnames'
import logger from 'utils/logger'

const LINE_HEIGHT = 1.4

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  labelDelay: {
    ...theme.typography.body2,
    color: theme.palette.orange[500],
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  labelDelayPredicted: {
    ...theme.typography.body2,
    color: theme.palette.purple[600],
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: LINE_HEIGHT,
  },
  iconButton: {
    borderRadius: theme.shape.borderRadiusSmall,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.25),
  },
  iconButtonActive: {
    backgroundColor: `rgba(0, 0, 0, 0.04)`,
  },
  infoIcon: {
    color: theme.palette.grey[600],
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  tooltip: {
    fontSize: 13,
    opacity: 1,
    textTransform: 'none',
  },
  reactionRoot: {
    display: 'flex',
  },
  reactionButtonGroup: {
    marginLeft: theme.spacing(1),
  },
  feedbackPromptText: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

interface Props {
  classes?: { [key: string]: string }
  estimateType?:
    | typeof TIMESTAMP_CARRIER_ESTIMATE_LOAD
    | typeof TIMESTAMP_CARRIER_ESTIMATE_UNLOAD
    | typeof TIMESTAMP_CARRIER_ESTIMATE_ARRIVE
}

const EstimateLabel = (props: Props) => {
  const classes = useStyles(props)
  const { estimateType } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const anchorRef = useRef(null)
  const handleClose = () => setAnchorEl(null)
  const handleClick = () => {
    logger.notify('Delay label popup click')
    setAnchorEl(anchorRef.current)
  }
  const estimateMessage = estimateType && TIMESTAMP_MESSAGES[estimateType]

  if (!estimateType || !estimateMessage) {
    return null
  }

  return (
    <Box component="span" className={classnames(classes.labelDelay, classes.root)}>
      {estimateMessage && (
        <>
          <IconButton
            onClick={handleClick}
            ref={anchorRef}
            className={classnames(classes.iconButton, {
              [classes.iconButtonActive]: Boolean(anchorEl),
            })}
          >
            <InfoIcon className={classes.infoIcon} />
          </IconButton>

          {anchorEl && (
            <PopoverText
              anchorEl={anchorEl}
              bodyContent={estimateMessage.message}
              footerContent={
                <Box display="flex" justifyContent="center">
                  <Reaction
                    classes={{
                      buttonGroup: classes.reactionButtonGroup,
                      reactionPrompt: classes.reactionRoot,
                      feedbackPromptText: classes.feedbackPromptText,
                    }}
                    componentUnderFeedback="Carrier Estimate Label"
                    feedbackPromptText="Was this helpful?"
                    loggerLabel="Carrier Estimate Label Reaction"
                  />
                </Box>
              }
              handleClose={handleClose}
              headerContent={<>{estimateMessage.title}</>}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default EstimateLabel
