import { CircularProgress, Drawer, IconButton, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  commentsStateSelector,
  editorInitialContentSelector,
  editorIsEnabledSelector,
} from 'store/comments'
import {
  createComment,
  fetchComments,
  setEditorInitialContent,
  setEditorIsEnabled,
} from 'store/comments/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import Button from 'components/core/Button'
import CenteredLayout from '../layouts/CenteredLayout'
import CloseIcon from '@material-ui/icons/Close'
import CommentEditor from './CommentEditor'
import Comments from './Comments'
import { DATA_MENTION_ATTR } from './utils'
import { closeCommentsDrawer } from 'utils/routes/comments'
import cx from 'classnames'
import logger from 'utils/logger'
import { openSupportChat } from 'store/support/actions'

const EDITOR_HEIGHT = 140
export const COMMENT_DRAWER_WIDTH = 344

const useStyles = makeStyles(theme => ({
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: COMMENT_DRAWER_WIDTH,
    padding: theme.spacing(2),
    height: '100%',
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentsList: {
    overflow: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
  sendButton: {
    position: 'absolute',
    right: 22,
    bottom: 21,
    zIndex: 1,
  },
  disabledEditor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 4,
    height: 48,
    padding: 8,
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  buttonLabel: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonRoot: {
    boxShadow: 'none',
  },
  title: {
    color: theme.palette.grey[800],
  },
  subtitle: {
    color: theme.palette.grey[500],
  },
  footer: {
    marginTop: 16,
    cursor: 'pointer',
    transition: 'height 150ms ease-in-out',
    height: 48,
  },
  enabledEditor: {
    height: EDITOR_HEIGHT,
  },
  commentsDrawerMessage: {
    color: theme.palette.grey[500],
  },
  supportLink: {
    cursor: 'pointer',
    color: theme.palette.blue[400],
  },
}))

function CommentsDrawer({ externalId, entityTitle }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    if (externalId) {
      logger.notify('Comments Drawer Load Comments', { externalId })
      dispatch(fetchComments(externalId))
    }

    return () => {
      // Close the editor and reset the initial content
      dispatch(setEditorIsEnabled(false))
      dispatch(setEditorInitialContent(null))
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalId])
  const editorIsEnabled = useSelector(editorIsEnabledSelector)
  const editorInitialContent = useSelector(editorInitialContentSelector)
  const [editor, setEditor] = useState(null)
  const { isLoading, isFailed, data: comments } = useSelector(commentsStateSelector)
  const classes = useStyles()
  const [hasContent, setHasContent] = useState(false)

  const onCreateComment = () => {
    const content = editor.getContent()
    if (!content) {
      return
    }
    const containsMention = content.includes(DATA_MENTION_ATTR)
    logger.notify('Comments Drawer Add Comment', { externalId, containsMention })
    dispatch(
      createComment({
        referencedEntityExternalId: externalId,
        content,
      })
    )
    editor.resetContent()
  }

  const latestCommentId = comments.length ? comments[comments.length - 1].externalId : null
  useEffect(() => {
    if (latestCommentId) {
      document.getElementById(latestCommentId).scrollIntoView()
    }
  }, [latestCommentId])

  const handleCloseDrawer = () => {
    logger.notify('Comments Drawer Close', { externalId })
    closeCommentsDrawer({ history, location })
  }

  // TODO: Maybe we can just use Dialog elements for title/body,etc
  return (
    <Drawer open={true} anchor="right" variant="permanent" data-testid="comment-drawer__root">
      <div className={classes.drawerContent}>
        <div className={classes.titleBlock}>
          <span>
            <Typography variant="h5" className={classes.title}>
              Comments
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {entityTitle}
            </Typography>
          </span>
          <span data-testid="comments-drawer__close-button">
            <IconButton onClick={handleCloseDrawer} className={classes.closeIcon}>
              <CloseIcon size="small" />
            </IconButton>
          </span>
        </div>
        {(() => {
          if (isFailed) {
            return (
              <CenteredLayout data-testid="comments-drawer__failed">
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.commentsDrawerMessage}
                >
                  Oops, something went wrong.
                </Typography>
              </CenteredLayout>
            )
          } else if (isLoading) {
            return (
              <CenteredLayout data-testid="comments-drawer__spinner">
                <CircularProgress />
              </CenteredLayout>
            )
          } else if (comments.length === 0) {
            return (
              <CenteredLayout data-testid="comment-drawer__empty">
                <Typography
                  align="center"
                  variant="subtitle1"
                  className={classes.commentsDrawerMessage}
                >
                  There aren't any comments yet. Start collaborating with your team in the field
                  below.
                  <br />
                  <br />
                  <strong>Do not comment here for support issues.</strong> For questions directed at
                  project44, contact{' '}
                  <span className={classes.supportLink} onClick={() => dispatch(openSupportChat())}>
                    project44 Support
                  </span>
                  .
                </Typography>
              </CenteredLayout>
            )
          } else {
            return (
              <div className={classes.commentsList}>
                <Comments comments={comments} editor={editor} />
              </div>
            )
          }
        })()}
        <div
          className={cx(classes.footer, { [classes.enabledEditor]: editorIsEnabled })}
          data-testid="comment-editor-container"
        >
          {editorIsEnabled ? (
            <CommentEditor
              height={EDITOR_HEIGHT}
              setEditor={setEditor}
              onEditorChange={() => setHasContent(Boolean(editor.getContent()))}
              initialContent={editorInitialContent}
            />
          ) : (
            <div
              data-testid="add-comment"
              className={classes.disabledEditor}
              onClick={() => {
                logger.notify('Comments Drawer Open Editor', { externalId })
                dispatch(setEditorIsEnabled(!editorIsEnabled))
              }}
            >
              <div>Send a message to your team</div>
              <Button
                variant="contained"
                disabled={true}
                size="small"
                classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
              >
                Send
              </Button>
            </div>
          )}
          {editorIsEnabled && (
            <div className={classes.sendButton}>
              <Button
                data-testid="send-comment-button"
                onClick={onCreateComment}
                variant="contained"
                color="primary"
                size="small"
                classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                disabled={!hasContent}
              >
                Send
              </Button>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default CommentsDrawer
