import { Divider, withStyles } from '@material-ui/core'

import React from 'react'
import { styles } from './styles'

const DrawerFooter = ({ children, classes }) => {
  return (
    <div className={classes.root}>
      <Divider variant="middle" className={classes.divider} />
      {children}
    </div>
  )
}

export default withStyles(styles)(DrawerFooter)
