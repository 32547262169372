import { JourneyDetailsState } from 'store/journeyDetails/interfaces'
import { TransportDetailInterface } from 'store/models/TransportDetail/interfaces'
import { handleActions } from 'redux-actions'
import { types } from 'store/journeyDetails/actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: JourneyDetailsState = {
  isFailed: false,
  isLoading: false,
  data: undefined,
}

const journeyDetails = handleActions<JourneyDetailsState, any>(
  {
    [types.FETCH_JOURNEY_DETAILS_START]: (state: JourneyDetailsState) => {
      return { ...state, isFailed: false, isLoading: true }
    },
    [types.FETCH_JOURNEY_DETAILS_SUCCESS]: (
      state: JourneyDetailsState,
      action: { payload: TransportDetailInterface }
    ) => {
      return {
        ...state,
        data: action.payload,
        isFailed: false,
        isLoading: false,
      }
    },
    [types.FETCH_JOURNEY_DETAILS_FAILURE]: (state: JourneyDetailsState) => {
      return { ...state, data: undefined, isFailed: true, isLoading: false }
    },
  },
  initialState
)

export default journeyDetails
