// This object serves as an API layer between the backend field names
// and the frontend.
//
// We expect the backend to send this:
// {
//   start_date: '2019-01-01',
//   end_date: '2019-01-02',
//   count: 10,
//   key_counts: { true: 2, false: 7, unknown: 1 },
// }

import get from 'lodash/get'

const computePercentage = roll => {
  const analyzedRollsCount = (roll.notRolledCount || 0) + (roll.rolledCount || 0)
  if (!analyzedRollsCount) {
    return null
  }
  const result = roll.rolledCount / analyzedRollsCount
  return isNaN(result) ? 0 : result
}

export const Roll = {
  of: (attrs = {}) => {
    const roll = { ...attrs }
    delete roll.count
    roll.rolledCount = get(roll, 'keyCounts.true', 0)
    roll.notRolledCount = get(roll, 'keyCounts.false', 0)
    roll.percentage = computePercentage(roll)
    return roll
  },
}
