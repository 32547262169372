const getAbbreviatedMilestoneDescription = (type, predicted = false) => {
  if (!type) return null
  const isDray = type.indexOf('dray') !== -1
  if (type.indexOf('unload') !== -1) {
    if (predicted) return isDray ? 'Predicted drayage unload' : 'Predicted unload'
    return isDray ? 'Drayage Unload' : 'Unload'
  }
  if (type.indexOf('load') !== -1) {
    if (predicted) return isDray ? 'Predicted drayage load' : 'Predicted load'
    return isDray ? 'Drayage Load' : 'Load'
  }
  if (type.indexOf('arrive') !== -1) {
    if (predicted) return isDray ? 'Predicted drayage arrival' : 'Predicted arrival'
    return isDray ? 'Drayage Arrive' : 'Arrive'
  }
  if (type.indexOf('discharge') !== -1) {
    if (predicted) return 'Predicted discharge'
    return 'Discharge'
  }
  if (type.indexOf('depart') !== -1) {
    if (predicted) return isDray ? 'Predicted drayage departure' : 'Predicted departure'
    return isDray ? 'Drayage Depart' : 'Depart'
  }
  if (type.indexOf('available') !== -1) {
    if (predicted) return 'Predicted available for pickup'
    return 'Available'
  }
  if (type.indexOf('receipt') !== -1) {
    if (predicted) return 'Predicted receipt'
    return 'Receipt'
  }
  if (type.indexOf('out_for_delivery') !== -1) {
    if (predicted) return 'Predicted out for delivery'
    return 'Out for delivery'
  }
  if (type.indexOf('at_delivery_location') !== -1) {
    if (predicted) return 'Predicted at delivery location'
    return 'At delivery location'
  }
  if (type.indexOf('generic_destination') !== -1) {
    if (predicted) return 'Predicted arrival'
    return 'Arrival'
  }
  return type
}

export default getAbbreviatedMilestoneDescription
