import { MenuItem, Popover, Typography } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import c from '../contents'

export default function ExportMenuPopover({ onDownloadClick, handleMenuClose, anchorEl }) {
  const handleCSVDownload = () => {
    handleMenuClose()
    onDownloadClick('csv')
  }

  const handleXLSXDownload = () => {
    handleMenuClose()
    onDownloadClick('xlsx')
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      data-testid="export-menu-popover"
    >
      <MenuItem onClick={handleCSVDownload}>
        <Typography variant="body2">{c.csvLabel}</Typography>
      </MenuItem>
      <MenuItem onClick={handleXLSXDownload}>
        <Typography variant="body2">{c.excelLabel}</Typography>
      </MenuItem>
    </Popover>
  )
}

ExportMenuPopover.propTypes = {
  onDownloadClick: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
}
