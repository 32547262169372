import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core'
import { TimeIcon, TimezoneIcon } from '../Icons'

import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import TimePicker from '../TimePicker'
import TimezonePicker from '../TimezonePicker'
import { alertConfigsSelector } from 'store/api/alertConfigs'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import first from 'lodash/first'
import moment from 'moment'
import { structureAlertConfig } from 'utils/alertUtils'
import { updateAlert } from 'store/api/alertConfigs'

const styles = theme => ({
  dialogPaper: {
    width: 600,
  },
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  title: {
    fontSize: 20,
    lineHeight: '1',
    color: '#393c3e',
  },
  timeIcon: {
    marginRight: theme.spacing(2.5),
    color: '#65717d',
  },
  timezoneIcon: {
    marginRight: theme.spacing(2.5),
    color: '#65717d',
  },
  timezoneIconHolder: {
    marginTop: 3,
  },
  timeIconHolder: {
    marginTop: 3,
  },
  timePickerWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  timezonePickerWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  timeText: {
    fontSize: 16,
    color: '#65717d',
  },
  timeZoneText: {
    fontSize: 16,
    color: '#65717d',
  },
  timePicker: {
    marginTop: theme.spacing(1),
  },
})

class SettingsDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      time: props.time,
      timezone: props.timezone,
    }
  }
  static defaultProps = {
    open: false,
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
  }

  handleTimezoneChange = timezone => {
    this.setState({
      timezone,
    })
  }

  handleTimeChange = time => {
    this.setState({
      time,
    })
  }

  formattedTime = time => {
    return time.format('HH:ss')
  }

  handleSave = () => {
    const { alertConfigs, updateAlert } = this.props
    let config = first(alertConfigs)
    config.notifTime = this.formattedTime(this.state.time)
    config.notifTimezone = this.state.timezone
    updateAlert(config)
    this.props.onClose()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update time + timezone once configs have loaded
    let nextState = {}
    if (nextProps.time !== this.state.time) {
      nextState['time'] = nextProps.time
    }
    if (nextProps.timezone !== this.state.timezone) {
      nextState['timezone'] = nextProps.timezone
    }
    if (Object.keys(nextState).length > 0) {
      this.setState(nextState)
    }
  }

  render() {
    const { classes, open } = this.props
    const { time, timezone } = this.state
    return (
      <Dialog open={open} onClose={this.props.onClose} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>
          <Typography variant="body2" className={classes.title}>
            When would you like to receive your alert emails?
            <CloseIcon className={classes.closeIcon} onClick={this.props.onClose} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.timePickerWrapper}>
            <TimeIcon className={classes.timeIcon} />
            <div>
              <Typography variant="body2" className={classes.timeText}>
                Time
              </Typography>
              <TimePicker
                defaultValue={time}
                onSelect={this.handleTimeChange}
                className={classes.timePicker}
              />
            </div>
          </div>
          <div className={classes.timezonePickerWrapper}>
            <TimezoneIcon className={classes.timezoneIcon} />
            <div>
              <Typography variant="body2" className={classes.timeZoneText}>
                Timezone
              </Typography>
              <TimezonePicker defaultValue={timezone} onSelect={this.handleTimezoneChange} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  updateAlert,
}

const getTimezoneAndTime = rawConfigs => {
  let config = first(rawConfigs)
  if (!config)
    return {
      time: null,
      timezone: null,
    }
  config = structureAlertConfig(config)
  const time = moment(config.notifTime, 'HH:ss')
  const timezone = config.notifTimezone
  return {
    time,
    timezone,
  }
}

const timeConfigSelector = createSelector(alertConfigsSelector, getTimezoneAndTime)

const mapStateToProps = state => {
  const { time, timezone } = timeConfigSelector(state)
  const rawConfigs = alertConfigsSelector(state)
  const alertConfigs = rawConfigs.map(rawConfig => structureAlertConfig(rawConfig))
  return {
    time,
    timezone,
    alertConfigs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsDialog))
