import { createAction } from 'redux-actions'
export const PUBLIC_SHARE_REDUCER_KEY = 'public_share'

export const types = {
  FETCH_PUBLIC_SHARE_START: `${PUBLIC_SHARE_REDUCER_KEY}/FETCH_PUBLIC_SHARE_START`,
  FETCH_PUBLIC_SHARE_SUCCESS: `${PUBLIC_SHARE_REDUCER_KEY}/FETCH_PUBLIC_SHARE_SUCCESS`,
  FETCH_PUBLIC_SHARE_ERROR: `${PUBLIC_SHARE_REDUCER_KEY}/FETCH_PUBLIC_SHARE_ERROR`,

  UPDATE_SELECTED_SHIPMENT: `${PUBLIC_SHARE_REDUCER_KEY}/UPDATE_SELECTED_SHIPMENT`,
}

export const fetchPublicShare = createAction(types.FETCH_PUBLIC_SHARE_START)
export const updatePublicShareSelectedShipment = createAction(types.UPDATE_SELECTED_SHIPMENT)
