import { TimeRange, TimeSummary } from 'utils/sharedObjs'

import get from 'lodash/get'

const structureTimeSummary = timeSummary => {
  const timezone = get(timeSummary, 'timezone')
  const time = new TimeRange(get(timeSummary, 'time'), get(timeSummary, 'time'))
  const traced = new TimeRange(get(timeSummary, 'traced'), get(timeSummary, 'traced'))
  const planned = new TimeRange(get(timeSummary, 'planned'), get(timeSummary, 'planned'))
  const predicted = new TimeRange(get(timeSummary, 'predicted'), get(timeSummary, 'predicted'))
  const oldPlanned = new TimeRange(get(timeSummary, 'old_planned'), get(timeSummary, 'old_planned'))
  return new TimeSummary(time, traced, planned, predicted, oldPlanned, timezone)
}

export default structureTimeSummary
