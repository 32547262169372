import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ShareIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M18,16.08 C17.24,16.08 16.56,16.38 16.04,16.85 L8.91,12.7 C8.96,12.47 9,12.24 9,12 C9,11.76 8.96,11.53 8.91,11.3 L15.96,7.19 C16.5,7.69 17.21,8 18,8 C19.66,8 21,6.66 21,5 C21,3.34 19.66,2 18,2 C16.34,2 15,3.34 15,5 C15,5.24 15.04,5.47 15.09,5.7 L8.04,9.81 C7.5,9.31 6.79,9 6,9 C4.34,9 3,10.34 3,12 C3,13.66 4.34,15 6,15 C6.79,15 7.5,14.69 8.04,14.19 L15.16,18.35 C15.11,18.56 15.08,18.78 15.08,19 C15.08,20.61 16.39,21.92 18,21.92 C19.61,21.92 20.92,20.61 20.92,19 C20.92,17.39 19.61,16.08 18,16.08 Z M18,4 C18.55,4 19,4.45 19,5 C19,5.55 18.55,6 18,6 C17.45,6 17,5.55 17,5 C17,4.45 17.45,4 18,4 Z M6,13 C5.45,13 5,12.55 5,12 C5,11.45 5.45,11 6,11 C6.55,11 7,11.45 7,12 C7,12.55 6.55,13 6,13 Z M18,20.02 C17.45,20.02 17,19.57 17,19.02 C17,18.47 17.45,18.02 18,18.02 C18.55,18.02 19,18.47 19,19.02 C19,19.57 18.55,20.02 18,20.02 Z" />
    </SvgIcon>
  )
}

export default ShareIcon
