import { ALL_FILTER_KEYS, makeFilterGroupTypes } from 'store/filterGroups/actions'
import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import CONFIRMATION_MESSAGES from 'store/confirmation/messages'
import NOTIFICATION_MESSAGES from 'store/notifications/messages'
import { RouteFilterGroup } from 'store/planning/utils/routeFilterGroup'
import api from 'store/api/filterGroups'
import { confirmSaga } from 'store/confirmation'
import { filterGroupSelectors } from 'store/filterGroups'
import get from 'lodash/get'
import humps from 'humps'
import logger from 'utils/logger'

const getResponseData = resp => humps.camelizeKeys(resp.data)

export const makeFilterGroupSagas = filterKey => {
  const types = makeFilterGroupTypes(filterKey)
  const selectors = filterGroupSelectors[filterKey]

  function* fetchFilterGroupsSaga() {
    try {
      const rawFilterGroups = getResponseData(
        yield call(api.fetchFilterGroups, { source: filterKey })
      )
      const filterGroups = rawFilterGroups.map(RouteFilterGroup.of)
      yield put({
        type: types.FETCH_FILTER_GROUPS_SUCCESS,
        payload: filterGroups,
      })
    } catch (e) {
      logger.error(e)
      logger.captureAPIException(e)
    }
  }

  function* createFilterGroupSaga(event) {
    try {
      const filterGroup = yield select(selectors.currentFilterGroupSelector)
      const dateRange = yield select(selectors.dateRangeSelector)
      const filters = get(filterGroup, 'params.filters', [])
      const groupBy = get(filterGroup, 'params.groupBy', [])
      const params = { name: event.payload, dateRange, filters, groupBy, source: filterKey }
      const newFilterGroup = getResponseData(yield call(api.createFilterGroup, params))
      yield put({ type: types.CREATE_FILTER_GROUP_SUCCESS, payload: newFilterGroup })
      yield put({
        type: types.FETCH_FILTER_GROUPS_START,
      })
      yield put(
        showNotification(NOTIFICATION_MESSAGES.createFilterSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
    } catch (e) {
      logger.error(e)
      logger.captureAPIException(e)
      yield put(
        showNotification(NOTIFICATION_MESSAGES.createFilterError, {
          type: MESSAGE_TYPES.ERROR,
        })
      )
    }
  }

  function* deleteFilterGroupSaga(event) {
    const { payload } = event
    const confirmed = yield call(confirmSaga, {
      ...CONFIRMATION_MESSAGES.deleteFilter(payload.name),
    })
    if (!confirmed) {
      return
    }

    try {
      yield call(api.deleteFilterGroup, payload.id)
      yield put({
        type: types.FETCH_FILTER_GROUPS_START,
      })
      const message = NOTIFICATION_MESSAGES.deleteFilterSuccess(payload.name)
      yield put(
        showNotification(message, {
          type: MESSAGE_TYPES.INFO,
        })
      )
    } catch (e) {
      logger.error(e)
      logger.captureAPIException(e)
      const message = NOTIFICATION_MESSAGES.deleteFilterError(payload.name)
      yield put(
        showNotification(message, {
          type: MESSAGE_TYPES.ERROR,
        })
      )
    }
  }

  function* updateFilterGroupSaga() {
    try {
      const filterGroup = yield select(selectors.currentFilterGroupSelector)
      const dateRange = yield select(selectors.dateRangeSelector)
      const filters = get(filterGroup, 'params.filters', [])
      const groupBy = get(filterGroup, 'params.groupBy', [])
      const params = {
        id: filterGroup.id,
        source: filterGroup.source,
        name: filterGroup.name,
        params: { dateRange, filters, groupBy },
      }
      yield call(api.updateFilterGroup, params)
      yield put({
        type: types.FETCH_FILTER_GROUPS_START,
      })
      yield put(
        showNotification(NOTIFICATION_MESSAGES.updateFilterSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
    } catch (e) {
      logger.error(e)
      logger.captureAPIException(e)
      yield put(
        showNotification(NOTIFICATION_MESSAGES.updateFilterError, {
          type: MESSAGE_TYPES.ERROR,
        })
      )
    }
  }

  function* updateFilterGroupNameSaga(event) {
    try {
      yield call(api.updateFilterGroup, event.payload)
      yield put({ type: types.FETCH_FILTER_GROUPS_START })
      yield put(
        showNotification(NOTIFICATION_MESSAGES.updateFilterSuccess, {
          type: MESSAGE_TYPES.SUCCESS,
        })
      )
    } catch (e) {
      logger.error(e)
      logger.captureAPIException(e)
      yield put(
        showNotification(NOTIFICATION_MESSAGES.updateFilterError, {
          type: MESSAGE_TYPES.ERROR,
        })
      )
    }
  }

  return {
    fetchFilterGroupsSaga,
    createFilterGroupSaga,
    deleteFilterGroupSaga,
    updateFilterGroupSaga,
    updateFilterGroupNameSaga,
  }
}

export const sagas = []
export const allFilterGroupEvents = {}

ALL_FILTER_KEYS.forEach(filterKey => {
  const filterGroupTypes = makeFilterGroupTypes(filterKey)
  const filterGroupSagas = makeFilterGroupSagas(filterKey)

  allFilterGroupEvents[filterKey] = [
    filterGroupTypes.APPLY_FILTER,
    filterGroupTypes.REMOVE_FILTER,
    filterGroupTypes.SELECT_FILTER_GROUP,
    filterGroupTypes.CLEAR_FILTER_GROUP,
    filterGroupTypes.UPDATE_DATE_RANGE,
  ]

  sagas.push(
    ...[
      takeLatest(
        filterGroupTypes.FETCH_FILTER_GROUPS_START,
        filterGroupSagas.fetchFilterGroupsSaga
      ),
      takeLatest(filterGroupTypes.CREATE_FILTER_GROUP, filterGroupSagas.createFilterGroupSaga),
      takeLatest(filterGroupTypes.DELETE_FILTER_GROUP, filterGroupSagas.deleteFilterGroupSaga),
      takeLatest(filterGroupTypes.UPDATE_FILTER_GROUP, filterGroupSagas.updateFilterGroupSaga),
      takeLatest(
        filterGroupTypes.UPDATE_FILTER_GROUP_NAME,
        filterGroupSagas.updateFilterGroupNameSaga
      ),
    ]
  )
})
