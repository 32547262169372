import React, { useEffect, useRef } from 'react'

import classnames from 'classnames'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  sticky: {
    position: 'sticky',
    top: -1,
    zIndex: 1,
    paddingTop: 'calc(1em + 1px)',
  },
})

// If/when this component needs to be more flexible this can be changed to a prop.
const showStuckOffset = 50

/*
 * Borrowed from: https://stackoverflow.com/a/57991537
 */
function Sticky({ children, classes, className, onStick }) {
  const ref = useRef()

  useEffect(() => {
    // This doesn't work in IE, though we have a polyfill to prevent the page from crashing.
    const observer = new IntersectionObserver(
      ([e]) => onStick(e.intersectionRect.top <= showStuckOffset),
      {
        threshold: [1],
      }
    )

    observer.observe(ref.current)

    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => observer.unobserve(ref.current)
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classnames(className, classes.sticky)} ref={ref}>
      {children}
    </div>
  )
}

export default withStyles(styles)(Sticky)
