import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import {
  TIMESTAMP_ACTUAL,
  TIMESTAMP_CARRIER_ESTIMATE_ARRIVE,
  TIMESTAMP_CARRIER_ESTIMATE_LOAD,
  TIMESTAMP_CARRIER_ESTIMATE_UNLOAD,
  TIMESTAMP_PLANNED,
  TIMESTAMP_PLANNED_NO_ACTUAL,
  TIMESTAMP_PREDICTED,
} from 'utils/milestone/MilestoneGroups/definitions'

import DelayLabel from 'components/DelayLabel'
import EstimateLabel from 'components/EstimateLabel'
import React from 'react'
import { getFormattedTimezone } from 'utils/dateUtils'

const useStyles = makeStyles((theme: Theme) => ({
  dateTime: {},
  label: {},
  timezone: {},
  delayLabelRoot: {
    marginLeft: 0,
  },
}))

interface Props {
  classes?: { [key: string]: string }
  dateTime: string | null
  delay?: number | null
  delayType?:
    | typeof TIMESTAMP_ACTUAL
    | typeof TIMESTAMP_PLANNED
    | typeof TIMESTAMP_PLANNED_NO_ACTUAL
    | typeof TIMESTAMP_PREDICTED
  estimateType?:
    | typeof TIMESTAMP_CARRIER_ESTIMATE_LOAD
    | typeof TIMESTAMP_CARRIER_ESTIMATE_UNLOAD
    | typeof TIMESTAMP_CARRIER_ESTIMATE_ARRIVE
  label: string
  timezone: string | null
}

const TimeBlock = (props: Props) => {
  const classes = useStyles(props)
  const { dateTime, delay, label, delayType, estimateType, timezone } = props

  return (
    <>
      <Typography className={classes.label} paragraph={false}>
        {label}
      </Typography>
      <Typography className={classes.dateTime} paragraph={false} display="block">
        <Box mr={1} display="inline" component="span">
          {dateTime}
          {Boolean(estimateType) && <EstimateLabel estimateType={estimateType} />}
        </Box>
        {Boolean(delay) && (
          <DelayLabel
            classes={{ root: classes.delayLabelRoot }}
            delayType={delayType}
            delay={delay}
          />
        )}
      </Typography>
      <Typography className={classes.timezone} variant="caption" paragraph={false}>
        {getFormattedTimezone(timezone)}
      </Typography>
    </>
  )
}

export default TimeBlock
