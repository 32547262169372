const TransportationMode = {
  water: 'water',
  barge: 'barge',
  rail: 'rail',
  road: 'road',
  air: 'air',
} as const

export type TransportationModeType = typeof TransportationMode[keyof typeof TransportationMode]

export default TransportationMode
