import {
  ACTION_ARRIVE,
  ACTION_LOAD,
  ACTION_UNLOAD,
  ACTIVITY_STUFFING,
  AVAILABLE_FOR_DELVERY,
  CATEGORY_VESSEL,
  DELAY_DAYS_TO_SHOW,
  GROUPS_WITH_STATUSES,
  ON_CARRIAGE,
  PORT_OF_DISCHARGE,
  PORT_OF_LOAD,
  STATUS_ACTUAL,
  STATUS_DELAY,
  STATUS_EARLY,
  STATUS_PREDICTED,
  TRANSSHIPMENT,
  UI_GROUPS,
} from './definitions'

import { GroupLabelInterface } from './interfaces'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import each from 'lodash/each'
import hasRoll from 'utils/milestone/MilestoneGroups/hasRoll'
import includes from 'lodash/includes'
import { pluralizeWord } from 'utils/strUtils'
import some from 'lodash/some'

/**
 * Delays and exceptions exist at the milestone level but the UI requires that we display them also at the group
 * level when the UI group is collapsed. This function assembles all group delays and determines if this group
 * has a `rolled` exception. It then builds the correct strings based on group and amount of delay and adds them to
 * an array for each time of delay, `predictedTimeDelays` and/or `tracedTimeDelay`
 * @param group any valid group
 * @param milestones for this group
 * @returns object
 * ```
 * [
 *  {
 *    predictedTimeDelays: [ 'Predicted Discharge: Delay 3 Days`, 'Predicted Delivery: Early 1 Day']
 *    tracedTimeDelays: : [ 'Actual Discharge: Delay 3 Days`, 'Actual Delivery: Early 1 Day'],
 *    hasRoll: true
 *  ...
 * ]
 */

export const getGroupStatuses = (
  group: keyof GroupLabelInterface,
  milestones: GroupedMilestoneInterface[]
) => {
  let groupStatuses: any = {
    hasRoll: false,
    predictedTimeDelays: [],
    tracedTimeDelays: [],
  }
  const textByGroup: { [key: string]: string } = {
    [ON_CARRIAGE]: 'Delivery',
    [PORT_OF_LOAD]: 'Load',
    [PORT_OF_DISCHARGE]: 'Discharge',
    [TRANSSHIPMENT]: 'Arrival',
  }

  // No-op if this isn't a group we show statuses for
  if (!includes(GROUPS_WITH_STATUSES, group)) {
    return groupStatuses
  }

  // Only four groups currently display delays at the group level. And for each group there is a specific type of
  // milestone we use to compute and show the display.
  const groupSupportsDelayStatus = (
    group: keyof typeof UI_GROUPS,
    milestone: GroupedMilestoneInterface
  ) => {
    switch (group) {
      case PORT_OF_LOAD:
        return (
          milestone.action === ACTION_LOAD && milestone.onEquipment?.category === CATEGORY_VESSEL
        )
      case PORT_OF_DISCHARGE:
        return (
          milestone.action === ACTION_UNLOAD && milestone.onEquipment?.category === CATEGORY_VESSEL
        )
      case ON_CARRIAGE:
        return (
          milestone.action === ACTION_ARRIVE &&
          milestone.stopSegment?.equipmentActivity === ACTIVITY_STUFFING
        )
      case TRANSSHIPMENT:
        return (
          milestone.action === ACTION_ARRIVE && milestone.onEquipment?.category === CATEGORY_VESSEL
        )
      default:
        return false
    }
  }

  each(milestones, milestone => {
    const predictedTime = milestone.predictedTime
    const tracedTime = milestone.tracedTime
    const predictedTimeDelay = milestone.predictedTimeDelay
    const tracedTimeDelay = milestone.tracedTimeDelay
    const { exceptions, statuses } = milestone

    // Looking for just one `true` so if we get it no need to look. We need to do this because `exceptions` is
    // an array of objects that can have different `exceptionType`s.
    groupStatuses.hasRoll = groupStatuses.hasRoll || hasRoll(exceptions)

    groupStatuses.availableForDelivery =
      groupStatuses.availableForDelivery ||
      some(statuses, s => s.statusType === AVAILABLE_FOR_DELVERY && s.tracedTime)

    // This checks if the current group supports adding delay statuses _and_ that we have the right milestone for it
    const shouldAddDelayStatus = groupSupportsDelayStatus(
      group as keyof typeof UI_GROUPS,
      milestone
    )

    if (!shouldAddDelayStatus) {
      return
    }

    let absDelay
    let earlyOrLateString = ''
    const groupString = textByGroup[group]

    // Construct the correct strings for the UI to display for predicted and actual (traced) time delays
    if (predictedTime) {
      let status = null
      if (predictedTimeDelay && Math.abs(predictedTimeDelay) >= DELAY_DAYS_TO_SHOW) {
        absDelay = Math.abs(predictedTimeDelay)
        earlyOrLateString = predictedTimeDelay > 0 ? STATUS_DELAY : STATUS_EARLY
        status = {
          delayText: `${earlyOrLateString} ${absDelay} ${pluralizeWord('Day', absDelay !== 1)}`,
          timestamp: milestone.predictedTime,
          type: `${STATUS_PREDICTED} ${groupString}`,
        }
      } else {
        status = {
          delayText: null,
          timestamp: milestone.predictedTime,
          type: `${STATUS_PREDICTED} ${groupString}`,
        }
      }
      if (status) {
        groupStatuses.predictedTimeDelays.push(status)
      }
    }

    if (tracedTime) {
      let status = null
      if (tracedTimeDelay && Math.abs(tracedTimeDelay) >= DELAY_DAYS_TO_SHOW) {
        absDelay = Math.abs(tracedTimeDelay)
        earlyOrLateString = tracedTimeDelay > 0 ? STATUS_DELAY : STATUS_EARLY
        status = {
          delayText: `${earlyOrLateString} ${absDelay} ${pluralizeWord('Day', absDelay !== 1)}`,
          timestamp: milestone.tracedTime,
          type: `${STATUS_ACTUAL} ${groupString}`,
        }
      } else {
        status = {
          delayText: null,
          timestamp: milestone.tracedTime,
          type: `${STATUS_ACTUAL} ${groupString}`,
        }
      }
      if (status) {
        groupStatuses.tracedTimeDelays.push(status)
      }
    }
  })

  return groupStatuses
}

export default getGroupStatuses
