/**
 * Here we maintain a list of LaunchDarkly FeatureFlags that are in use
 */
export const JSON_LOGIC = 'json-logic'
export const PLANNING_FEATURE = 'lanes-2019-10'
export const VOC_LINK = 'voc-link'
export const PLANNING_PAYWALL_PAGE_FEATURE = 'planning-page-paywall'
export const REGISTRATION_TYPEFORM = 'registration-typform'
export const USER_DASHBOARD_FEATURE = 'user-dashboard-2019-11'
export const BOARD_LOADING_EXPERIMENTS = 'board-loading-experiments'
