export const PRE_MAIN = 'pre_main'
export const MAIN = 'main'
export const POST_MAIN = 'post_main'

const TripStage = {
  [PRE_MAIN]: PRE_MAIN,
  [MAIN]: MAIN,
  [POST_MAIN]: POST_MAIN,
} as const

export type TripStageType = typeof TripStage[keyof typeof TripStage]

export default TripStage
