import React, { Component } from 'react'

import ActiveDefaultFilter from 'components/Filtering/ActiveDefaultFilter'
import ActiveFilterComponent from 'components/Filtering/ActiveFilterComponent'
import FilterOptions from 'components/Filtering/FilterOptions'
import PropTypes from 'prop-types'

class FilterMenu extends Component {
  static propTypes = {
    showImportButton: PropTypes.bool,
    toggleImportDialog: PropTypes.func,
    filters: PropTypes.array.isRequired,
    filterGroups: PropTypes.array.isRequired,
    filterConfigs: PropTypes.array.isRequired,
    activeFilter: PropTypes.object,
    activeDefaultFilter: PropTypes.object,
    setActiveFilter: PropTypes.func.isRequired,
    unsetActiveFilter: PropTypes.func.isRequired,
    addFilter: PropTypes.func.isRequired,
  }

  render() {
    const { activeFilter, activeDefaultFilter } = this.props

    let activeFilterComponent = null
    if (activeFilter) {
      activeFilterComponent = (
        <ActiveFilterComponent
          handleRequestClose={() => this.props.unsetActiveFilter()}
          filters={this.props.filters}
          activeFilter={this.props.activeFilter}
          addFilter={this.props.addFilter}
        />
      )
    } else if (activeDefaultFilter) {
      activeFilterComponent = (
        <ActiveDefaultFilter
          addFilter={this.props.addFilter}
          filterConfigs={this.props.filterConfigs}
          filters={this.props.filters}
        />
      )
    }
    return (
      <React.Fragment>
        {activeFilterComponent}
        <FilterOptions {...this.props} />
      </React.Fragment>
    )
  }
}

export default FilterMenu
