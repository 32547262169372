import EquipmentCategory, { EquipmentCategoryType } from '../definitions/EquipmentCategory'

import humps from 'humps'

const API_UNKNOWN = 'UNKNOWN'

export interface EquipmentInterface {
  category: EquipmentCategoryType
  id: number | null
  name?: string | null
  number: string | null
  mmsi?: string | null
  lloydsCode?: string | null
  type: {
    description: string
    name?: string
  }
}

export default class Equipment {
  constructor({ id, name, number, mmsi, lloydsCode, type, category }: EquipmentInterface) {
    Object.assign(this, { id, name, number, mmsi, lloydsCode, type, category })
  }

  static of(payload: object) {
    const { id, name, number, mmsi, lloydsCode, type, category } = humps.camelizeKeys(
      payload
    ) as EquipmentInterface
    return new Equipment({
      id,
      // The API is returning 'UNKNOWN" in some cases, this keeps us from having deal with that in the UI.
      name: name?.toUpperCase() === API_UNKNOWN ? null : name,
      number: number?.toUpperCase() === API_UNKNOWN ? null : number,
      mmsi: mmsi ?? null,
      lloydsCode: lloydsCode ?? null,
      type: type ?? null,
      category: category && EquipmentCategory[category],
    })
  }
}
