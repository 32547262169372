import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import Checkbox from 'components/core/Checkbox'
import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  expandCell: {
    width: '50px !important', // TODO: This doesn't seem to do anything?
    minWidth: '50px !important', // TODO: This doesn't seem to do anything?
    paddingRight: 0,
    verticalAlign: 'middle',
    paddingTop: 8,
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}))

SelectionHeaderCell.propTypes = {
  onCheck: PropTypes.func,
  onUncheck: PropTypes.func,
  checkboxChecked: PropTypes.bool,
  checkboxIndeterminate: PropTypes.bool,
  tooltipText: PropTypes.string,
}

function SelectionHeaderCell({
  onCheck,
  onUncheck,
  checkboxChecked,
  onIndeterminate,
  tooltipText,
  checkboxIndeterminate,
}) {
  const classes = useStyles()

  return (
    <BaseCell className={classes.expandCell}>
      <div className={classes.container}>
        <Tooltip title={tooltipText}>
          <span>
            <Checkbox
              color="primary"
              aria-label="Select"
              onCheck={onCheck}
              onUncheck={onUncheck}
              onIndeterminate={onIndeterminate}
              checked={checkboxChecked}
              indeterminate={checkboxIndeterminate}
            />
          </span>
        </Tooltip>
      </div>
    </BaseCell>
  )
}

// This wrapper is to optimize performance.
// TODO: refactor to React.memo

class SelectionHeaderOptimized extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const nextIsSelected = get(nextProps, 'checkboxChecked', null)
    const currIsSelected = get(this.props, 'checkboxChecked', null)

    // explicitly check against null in case a row.id is an integer that is 0
    if (nextIsSelected !== null && currIsSelected !== null) {
      if (nextIsSelected !== currIsSelected) return true
    }

    const nextIsIndeterminate = get(nextProps, 'checkboxIndeterminate', null)
    const currIsIndeterminate = get(this.props, 'checkboxIndeterminate', null)

    // explicitly check against null in case a row.id is an integer that is 0
    if (nextIsIndeterminate !== null && currIsIndeterminate !== null) {
      if (nextIsIndeterminate !== currIsIndeterminate) return true
    }
    return false
  }

  render() {
    return <SelectionHeaderCell {...this.props} />
  }
}

export default SelectionHeaderOptimized
