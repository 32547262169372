import { types } from './actions'

export const REGISTRATION_ACTION_HANDLERS = {
  [types.INCREMENT_FORM]: state => ({
    ...state,
    registration: {
      ...state.registration,
      formStep: state.registration.formStep + 1,
    },
  }),
  [types.REGISTER_LOAD]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      loading: true,
    },
  }),
  [types.REGISTER_SUCCESS]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      awaitingAuthCheck: false,
      loading: false,
    },
    login: {
      ...state.login,
      loggedIn: true,
    },
  }),
}
