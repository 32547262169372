import React from 'react'
import { SvgIcon } from '@material-ui/core'

const InfoIcon = props => {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M9,13 L11,13 L11,15 L9,15 L9,13 Z M9,5 L11,5 L11,11 L9,11 L9,5 Z M9.99,0 C4.47,0 0,4.48 0,10 C0,15.52 4.47,20 9.99,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 9.99,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 Z"
          transform="rotate(180 11 11)"
        />
      </g>
    </SvgIcon>
  )
}

export default InfoIcon
