import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'

export const types = {
  REGISTER_LOAD: `${AUTH_REDUCER_KEY}/register/REGISTER_LOAD`,
  REGISTER_SUCCESS: `${AUTH_REDUCER_KEY}/register/REGISTER_SUCCESS`,
  INCREMENT_FORM: `${AUTH_REDUCER_KEY}/register/INCREMENT_FORM`,
}

export const incrementForm = createAction(types.INCREMENT_FORM)

export const register = createAction(types.REGISTER_LOAD, payload => ({
  username: payload.email,
  firstName: payload.firstName,
  lastName: payload.lastName,
  password: payload.password,
  pwdToken: payload.token,
}))
