import * as Yup from 'yup'

import { Box, DialogActions, DialogContent, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import AlertFormStepOne from 'pages/PortalAlertModal/PortalAlertForm/AlertFormStepOne'
import AlertFormStepThree from 'pages/PortalAlertModal/PortalAlertForm/AlertFormStepThree'
import AlertFormStepTwo from 'pages/PortalAlertModal/PortalAlertForm/AlertFormStepTwo'
import Button from 'components/core/Button'
import { PortalAlertsInterface } from 'store/portalAlerts/interfaces'
import React from 'react'
import filter from 'lodash/filter'
import getInitialValues from 'pages/PortalAlertModal/PortalAlertForm/utils/getInitialValues'
import getUserAlerts from 'pages/PortalAlertModal/PortalAlertForm/utils/getUserAlerts'
import logger from 'utils/logger'
import { pluralizeWord } from 'utils/strUtils'
import { rollupSelector } from 'store/boardUtils/selectors'
import { submitForm } from 'store/portalAlerts/actions'
import { submittingSelector } from 'store/portalAlerts/selectors'
import { useFormik } from 'formik'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  dialogRoot: {
    padding: 0,
  },
  dialogActions: {
    margin: `${theme.spacing(4)}px -${theme.spacing(3)}px 0 -${theme.spacing(3)}px`,
    borderTop: `1px solid ${theme.palette.grey[100]}`,

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
  },
}))

interface Props {
  alerts: PortalAlertsInterface
}

const PortalAlertForm = (props: Props) => {
  const { alerts } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  const formSubmitting = useSelector(submittingSelector)
  // We set the rollup in this component to the same as the board _if_ we're not loading a saved alert
  const boardPageSelectedRollup = useSelector(rollupSelector)

  const {
    detentionToOriginPortKey,
    detentionFromDestinationPortKey,
    demurrageAtOriginPortKey,
    demurrageAtDestinationPortKey,
    oceanDelayKey,
    stuckInTransshipmentKey,
    vesselArrivedAtDestinationKey,
    vesselHasDepartedOriginKey,
    vesselWillArriveAtDestinationKey,
  } = getUserAlerts(alerts?.alertConfigs)

  const initialValues = getInitialValues(alerts, boardPageSelectedRollup)
  const savedAlertCount = filter(initialValues.alerts, alert => Boolean(alert.alertId)).length
  const isUpdateable = savedAlertCount > 0

  const {
    dirty,
    errors,
    handleChange,
    handleSubmit,
    setErrors,
    setTouched,
    setValues,
    touched,
    values,
  } = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      rollup: Yup.string().required(),
      notifFrequency: Yup.string().test('notifFrequency', 'Required', function (value) {
        if (
          !Object.keys(this.parent.alerts).some(alertKey => this.parent.alerts[alertKey].checked)
        ) {
          return true
        } else if (!value) {
          return this.createError({
            message: 'Required',
            path: 'notifFrequency',
          })
        } else {
          return true
        }
      }),
      notifTime: Yup.mixed()
        .label('Time (24-hr format)')
        .test('notifTime', 'Required', function (value) {
          if (
            !Object.keys(this.parent.alerts).some(alertKey => this.parent.alerts[alertKey].checked)
          ) {
            return true
          }
          if (this.parent.notifFrequency === 'instant') {
            return true
          }
          if (!value || typeof value !== 'string') {
            return this.createError({
              message: 'Required',
              path: 'notifTime',
            })
          }
          return true
        }),
      notifTimezone: Yup.mixed().test('notifTimezone', 'Required', function (value) {
        if (
          !Object.keys(this.parent.alerts).some(alertKey => this.parent.alerts[alertKey].checked)
        ) {
          return true
        }
        if (this.parent.notifFrequency === 'instant') {
          return true
        } else if (value === null || value === undefined) {
          return this.createError({
            message: 'Required',
            path: 'notifTimezone',
          })
        } else {
          return true
        }
      }),
      alerts: Yup.object().shape({
        [vesselHasDepartedOriginKey]: Yup.object().shape({
          checked: Yup.boolean(),
        }),
        [stuckInTransshipmentKey]: Yup.object().shape({
          checked: Yup.boolean(),
          inputValue: Yup.number()
            .moreThan(0)
            .integer()
            .label(initialValues.alerts[stuckInTransshipmentKey].inputLabel ?? '')
            .when('checked', {
              is: true,
              then: Yup.number().required(),
            }),
        }),
        [oceanDelayKey]: Yup.object().shape({
          checked: Yup.boolean(),
          inputValue: Yup.number()
            .moreThan(0)
            .integer()
            .label(initialValues.alerts[oceanDelayKey].inputLabel ?? '')
            .when('checked', {
              is: true,
              then: Yup.number().required(),
            }),
        }),
        [vesselWillArriveAtDestinationKey]: Yup.object().shape({
          checked: Yup.boolean(),
          inputValue: Yup.number()
            .moreThan(0)
            .integer()
            .label(initialValues.alerts[vesselWillArriveAtDestinationKey].inputLabel ?? '')
            .when('checked', {
              is: true,
              then: Yup.number().required(),
            }),
        }),
        [vesselArrivedAtDestinationKey]: Yup.object().shape({
          checked: Yup.boolean(),
        }),
        [detentionToOriginPortKey]: Yup.object().shape({
          checked: Yup.boolean(),
          minDaysValue: Yup.number()
            .moreThan(0)
            .integer()
            .label(initialValues.alerts[detentionToOriginPortKey].inputLabel ?? '')
            .when('checked', {
              is: true,
              then: Yup.number().required('Required'),
            }),
          maxDaysValue: Yup.number().when('timingValue', {
            is: timingValue => timingValue === 'between',
            then: Yup.number().required('Required').integer(),
          }),
          timingValue: Yup.string().when('checked', {
            is: true,
            then: Yup.string()
              .required('Required')
              .matches(/(more|less|between)/),
          }),
        }),
        [detentionFromDestinationPortKey]: Yup.object().shape({
          checked: Yup.boolean(),
          minDaysValue: Yup.number()
            .moreThan(0, 'Required')
            .integer()
            .label(initialValues.alerts[detentionFromDestinationPortKey].inputLabel ?? '')
            .when('checked', {
              is: true,
              then: Yup.number().required('Required'),
            }),
          maxDaysValue: Yup.number().when('timingValue', {
            is: timingValue => timingValue === 'between',
            then: Yup.number().required('Required').integer(),
          }),
          timingValue: Yup.string().when('checked', {
            is: true,
            then: Yup.string()
              .required('Required')
              .matches(/(more|less|between)/),
          }),
        }),
        [demurrageAtOriginPortKey]: Yup.object().shape({
          checked: Yup.boolean(),
          minDaysValue: Yup.number()
            .moreThan(0)
            .integer()
            .label(initialValues.alerts[demurrageAtOriginPortKey].inputLabel ?? '')
            .when('checked', {
              is: true,
              then: Yup.number().required('Required'),
            }),
          maxDaysValue: Yup.number().when('timingValue', {
            is: timingValue => timingValue === 'between',
            then: Yup.number().required('Required').integer(),
          }),
          timingValue: Yup.string().when('checked', {
            is: true,
            then: Yup.string()
              .required('Required')
              .matches(/(more|less|between)/),
          }),
        }),
        [demurrageAtDestinationPortKey]: Yup.object().shape({
          checked: Yup.boolean(),
          minDaysValue: Yup.number()
            .moreThan(0)
            .integer()
            .label(initialValues.alerts[demurrageAtDestinationPortKey].inputLabel ?? '')
            .when('checked', {
              is: true,
              then: Yup.number().required('Required'),
            }),
          maxDaysValue: Yup.number().when('timingValue', {
            is: timingValue => timingValue === 'between',
            then: Yup.number().required('Required').integer(),
          }),
          timingValue: Yup.string().when('checked', {
            is: true,
            then: Yup.string()
              .required('Required')
              .matches(/(more|less|between)/),
          }),
        }),
      }),
    }),
    onSubmit: (values, actions) => {
      isUpdateable
        ? logger.notify('Portal Alert Config Update')
        : logger.notify('Portal Alert Config Save')

      dispatch(submitForm(values))
    },
  })

  const handleClear = () => {
    logger.notify('Portal Alert Config Clear')

    let newState = { ...initialValues }
    newState.notifTime = ''
    newState.notifFrequency = ''
    newState.notifTimezone = ''
    newState.rollup = boardPageSelectedRollup
    Object.keys(newState.alerts).forEach(alertKey => {
      newState.alerts[alertKey].checked = false

      if (newState.alerts[alertKey].inputValue !== undefined) {
        newState.alerts[alertKey].inputValue = ''
      }
    })
    const rerunValidations = false
    setValues(newState, rerunValidations)
    setErrors({})
    setTouched({})
  }

  const isSomeSelected = (alerts: any) =>
    Object.keys(alerts).some(alertKey => alerts[alertKey].checked === true)

  const hasAlertIds = (alerts: any) =>
    Object.keys(alerts).some(alertKey => Boolean(alerts[alertKey].alertId))

  const formSubmittable = (alerts: any) => {
    return (
      ((dirty && isSomeSelected(alerts)) || hasAlertIds(alerts)) && Object.keys(errors).length === 0
    )
  }

  const showDeactivate = (alerts: any) => !isSomeSelected(alerts) && hasAlertIds(alerts)

  const clearEnabled = (alerts: any) =>
    dirty || isSomeSelected(alerts) || Object.keys(errors).length > 0

  const getSubmitButtonText = () => {
    if (showDeactivate(values.alerts)) {
      return 'Deactivate Alerts'
    }

    return formSubmitting
      ? 'Submitting...'
      : `${isUpdateable ? 'Update' : 'Save'} My ${pluralizeWord('Alert', savedAlertCount > 1)}`
  }

  return (
    <>
      <DialogContent className={classes.dialogRoot}>
        <form
          className={classes.form}
          id="portalAlert"
          onSubmit={handleSubmit}
          data-testid="portal-alert__modal__form"
        >
          <AlertFormStepOne
            errors={errors}
            handleChange={handleChange}
            touched={touched}
            values={values}
          />
          <Box flexBasis="0" flexGrow="1">
            <AlertFormStepTwo errors={errors} handleChange={handleChange} values={values} />
            <AlertFormStepThree
              errors={errors}
              handleChange={handleChange}
              touched={touched}
              values={values}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!showDeactivate(values.alerts) && (
          <Button
            disabled={!clearEnabled(values.alerts)}
            data-testid="portal-alert-modal__clear-button"
            onClick={handleClear}
            variant="text"
          >
            Clear
          </Button>
        )}
        <Button
          form="portalAlert"
          type="submit"
          variant="contained"
          color="primary"
          data-testid="portal-alert__modal__save_button"
          disabled={!formSubmittable(values.alerts) || formSubmitting}
        >
          {getSubmitButtonText()}
        </Button>
      </DialogActions>
    </>
  )
}

export default PortalAlertForm
