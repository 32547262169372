import { Box, Theme, Typography, makeStyles } from '@material-ui/core'

import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  availableForDelivery: {
    color: theme.palette.blue[600],
    fontSize: 13,
    fontWeight: theme.typography.fontWeightRegular,
  },
  withBackground: {
    background: theme.palette.blue[25],
    borderRadius: theme.shape.borderRadius,
    padding: '2px 4px',
    width: 'fit-content',
  },
}))

interface Props {
  availableForDeliveryDate?: string | null
  classes?: Record<string, string>
  withBackground?: boolean
}

const AvailableForDeliveryLabel = (props: Props) => {
  const classes = useStyles(props)
  const { availableForDeliveryDate, withBackground = true } = props

  if (!availableForDeliveryDate) {
    return null
  }

  return (
    <Box
      component="span"
      display="block"
      className={classnames(classes.root, {
        [classes.withBackground]: withBackground,
      })}
    >
      <Typography component="span" className={classes.availableForDelivery}>
        Available for Delivery: {availableForDeliveryDate}
      </Typography>
    </Box>
  )
}

export default AvailableForDeliveryLabel
