import { Typography, withStyles } from '@material-ui/core'
import { alertConfigsLoadingSelector, alertConfigsSelector } from 'store/api/alertConfigs'

import { ALERTS_ROUTE } from 'utils/routes'
import AlertsOverview from 'pages/AlertsPage/AlertsOverview'
import BackIcon from '@material-ui/icons/ArrowBack'
import Button from 'components/core/Button'
import FilteredAlertConfigSummary from './FilteredAlertConfigSummary'
import { Loader } from 'components/Loader/Loader'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import { selectAlert } from 'store/alerts'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  alertsOverviewTitle: {
    fontSize: 18,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    color: '#66717e',
  },
  backRoot: {
    marginTop: theme.spacing(1),
    color: 'grey',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  backLabel: {
    textTransform: 'none',
  },
  backIcon: {
    marginRight: theme.spacing(1),
    height: 20,
  },
  errorTextWrapper: {
    textAlign: 'center',
    marginTop: 200,
    padding: theme.spacing(2),
  },
  errorText: {
    fontSize: 18,
    color: theme.brand.color,
  },
  loader: {
    marginTop: 200,
    textAlign: 'center',
  },
  summaryWrapper: {
    marginTop: theme.spacing(2),
  },
  headerNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  alertTitle: {
    fontSize: 20,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
})

class EditAlert extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    alertConfigID: PropTypes.string,
    alertConfigsLoading: PropTypes.bool,
    alertFromQuery: PropTypes.object,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(nextProps, 'alertFromQuery.id') !== get(this.props, 'alertFromQuery.id')) {
      this.props.selectAlert(nextProps.alertFromQuery)
    }
    const { alertConfigsLoading, alertFromQuery } = nextProps
    if (!alertConfigsLoading && !alertFromQuery) {
      // redirect to alerts home page if alert not found
      this.props.history.push(ALERTS_ROUTE.buildUrl())
    }
  }

  componentDidMount() {
    const alertFromQuery = get(this.props, 'alertFromQuery')
    if (alertFromQuery && Object.keys(alertFromQuery).length && 'id' in alertFromQuery) {
      this.props.selectAlert(this.props.alertFromQuery)
    }
  }

  render() {
    const { classes, alertConfigID, alertConfigsLoading, alertFromQuery } = this.props
    if (alertConfigsLoading) {
      return <Loader className={classes.loader} />
    }

    if (!alertFromQuery) {
      return (
        <div className={classes.errorTextWrapper}>
          <Typography variant="body2" className={classes.errorText}>
            Hmm...we had trouble loading this alert
          </Typography>
        </div>
      )
    }
    return (
      <div>
        <div className={classes.headerNameWrapper}>
          <Typography variant="body2" className={classes.alertTitle}>
            {get(alertFromQuery, 'name')}
          </Typography>
        </div>
        <Button
          classes={{ root: classes.backRoot, label: classes.backLabel }}
          onClick={() => this.props.history.push(ALERTS_ROUTE.buildUrl())}
        >
          <BackIcon className={classes.backIcon} />
          Back to overview
        </Button>
        <div className={classes.summaryWrapper}>
          <FilteredAlertConfigSummary config={alertFromQuery} />
          <Typography variant="body2" className={classes.alertsOverviewTitle}>
            Alert Summary
          </Typography>
          <AlertsOverview configID={alertConfigID} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  selectAlert,
}

const mapStateToProps = (state, ownProps) => {
  const alertConfigsLoading = alertConfigsLoadingSelector(state)
  const alertConfigID = ownProps.match.params.id
  const alerts = alertConfigsSelector(state)
  const alertFromQuery = find(alerts, alert => alert.id === parseInt(alertConfigID)) || null

  return {
    alertConfigID,
    alertConfigsLoading,
    alertFromQuery,
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(EditAlert)
