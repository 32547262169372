import { GREEN, LABEL_COLORS, ORANGE, RED, YELLOW } from 'components/StatusLabel/labelColors'

import { DelayStatus } from 'store/board/sagas/models'

const mapStatusByDelay = (status, delayStatus, timeDelta) => {
  switch (delayStatus) {
    case DelayStatus.NO_DATA:
      return status
    case DelayStatus.EARLY:
      return {
        ...status,
        color: LABEL_COLORS[YELLOW],
        label: `${-Math.round(timeDelta)} Days Early`,
        details: null,
      }
    case DelayStatus.ON_SCHEDULE:
      return {
        ...status,
        color: LABEL_COLORS[GREEN],
        label: 'On Schedule',
      }
    case DelayStatus.DELAYED:
      return {
        ...status,
        color: LABEL_COLORS[ORANGE],
        label: `${Math.round(timeDelta)} Day Delay`,
      }
    case DelayStatus.MAJOR_DELAY:
      return {
        ...status,
        color: LABEL_COLORS[RED],
        label: '50+ Day Delay',
        details: null,
      }
    default:
      return status
  }
}

export default mapStatusByDelay
