import { DATE_FORMAT, formatDateString, getDateDiff } from 'utils/dateUtils'
import EntityPageHeader, { EntityPageHeaderInterface } from 'store/models/EntityPageHeader'
import {
  TransportDetailHeaderInterface,
  TransportDetailInterface,
  TransportDetailTimelinesInterface,
} from 'store/models/TransportDetail/interfaces'

import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import Milestone from 'store/models/Milestone'
import TransportStatus from 'store/models/definitions/TransportationStatus'
import { TripInterface } from 'store/models/Trip'
import UiGroupsInterface from 'store/types/UiGroupsInterface'
import differenceWith from 'lodash/differenceWith'
import getGroupedMilestones from 'utils/milestone/MilestoneGroups/getGroupedMilestones'
import getLastTracedMilestone from 'utils/milestone/getLastTracedMilestone'
import humps from 'humps'
import isEqual from 'lodash/isEqual'
import { isPredictionOutdated } from 'utils/shipmentUtils/getOutdatedPredictionStatus'

export class TransportDetail {
  constructor({ self, header, pageHeader, timelines = [] }: TransportDetailInterface) {
    // Extend `milestones` with properties needed for the UI
    timelines.forEach((timeline: TransportDetailTimelinesInterface) => {
      const lastTracedMilestone = getLastTracedMilestone(timeline.milestones)
      const lastTracedIndex = timeline.milestones.indexOf(lastTracedMilestone)
      const previousMilestoneIndex = lastTracedIndex - 1

      timeline.milestones.forEach((milestone: GroupedMilestoneInterface, idx: number) => {
        const {
          baselinePlannedTime,
          locationZone,
          plannedTime,
          predictedTime,
          statuses,
          tracedTime,
        } = milestone

        /**
         * Add properties to each milestone that determine if we show container counts and messages.
         * @see https://docs.google.com/document/d/1wGoFw02gjAFRq5oosMwrEyO3jHK1WPv91ukyuku9hRg/edit?usp=sharing
         */
        let isLastTracedAndIncomplete = false
        let isPreviousAndIncomplete = false
        let incompleteTrips: TripInterface[] = []
        const timelineStatus = timeline.header.status

        /**
         * Due to the backend not returning empty discharge milestones for the timelines we can get a case
         * where all containers are delivered (as shown in the timeline header) but then the
         * `isLastTracedAndIncomplete` and `isPreviousAndIncomplete` properties below will still evaluate to `true`.
         * The result is incomplete counts showing up in the timeline even though that is not correct. This property
         * simply checks if: status is "delivered" or "discharged" and `statusTrips.length === trips.length`.
         * If so, we use this below to short circuit any tests for "incomplete".
         */
        const allTripsDelivered =
          (timelineStatus.toLowerCase() === TransportStatus.DELIVERED.toLowerCase() ||
            timelineStatus.toLowerCase() === TransportStatus.DISCHARGED.toLowerCase()) &&
          timeline.header.statusTrips.length === timeline.header.trips.length

        if (!allTripsDelivered && idx === lastTracedIndex) {
          isLastTracedAndIncomplete =
            milestone.actualTrips.length > 0 &&
            milestone.actualTrips.length < timeline.header.trips.length
        }

        // If we have a lastTracedAndIncomplete we need a diff list of containers to display
        if (isLastTracedAndIncomplete) {
          incompleteTrips = differenceWith(timeline.header.trips, milestone.actualTrips, isEqual)
        }

        if (!allTripsDelivered && idx === previousMilestoneIndex) {
          isPreviousAndIncomplete =
            milestone.actualTrips.length > 0 &&
            milestone.actualTrips.length < timeline.header.trips.length
        }

        milestone.isPreviousAndIncomplete = isPreviousAndIncomplete
        milestone.isLastTracedAndIncomplete = isLastTracedAndIncomplete
        milestone.incompleteTrips = incompleteTrips

        milestone.predictedTimeDelay = getDateDiff(
          predictedTime as string,
          baselinePlannedTime as string,
          'days'
        ) as number | null

        milestone.tracedTimeDelay = getDateDiff(
          tracedTime as string,
          baselinePlannedTime as string,
          'days'
        ) as number | null

        milestone.plannedTimeDelay = getDateDiff(
          plannedTime as string,
          baselinePlannedTime as string,
          'days'
        ) as number | null

        // Adding timeline dates which just require a different format
        milestone.timelinePredictedTime = formatDateString(predictedTime, DATE_FORMAT)
        milestone.timelineTracedTime = formatDateString(tracedTime, DATE_FORMAT)
        milestone.timelinePlannedTime = formatDateString(plannedTime, DATE_FORMAT)

        const status = statuses[0]
        milestone.availableForDelivery = status?.tracedTime ?? null

        const predictionStatus = isPredictionOutdated(
          predictedTime as string,
          locationZone?.timezone as string
        )
        // Somewhat of a data fix, we can't have an outdated prediction if we have a traced time
        milestone.hasOutdatedPrediction = !milestone.tracedTime && predictionStatus
      })
    })

    // New property
    const groupedMilestones: UiGroupsInterface[] = timelines.map(
      (timeline: TransportDetailTimelinesInterface) => {
        return getGroupedMilestones(timeline.milestones)
      }
    )

    Object.assign(this, {
      header,
      pageHeader,
      self,
      timelines,
      groupedMilestones,
    })
  }

  /**
   * Converts API data to camelCase, applies models, adds properties and returns a new model object
   * @param payload object, snake-cased API data. We only do this on the top model (entry point) because subsequent
   * models will get camelCased data and can use our defined interfaces for `payload`
   */
  static of(payload: object) {
    const { self, header, timelines } = humps.camelizeKeys(payload) as any

    // We return `self` and `header` from the API response in case we need them.
    // `pageHeader` is a new construct that holds the data for the page header
    return new TransportDetail({
      self,
      header,
      pageHeader:
        header && self && (EntityPageHeader.of({ ...header, self }) as EntityPageHeaderInterface),
      timelines:
        timelines &&
        timelines.map((timeline: TransportDetailTimelinesInterface) => ({
          header: timeline.header,
          timelineHeader: EntityPageHeader.of(
            timeline.header as TransportDetailHeaderInterface
          ) as EntityPageHeaderInterface,
          milestones: timeline.milestones.map(
            (milestone: GroupedMilestoneInterface) =>
              Milestone.of(milestone) as GroupedMilestoneInterface
          ),
        })),
    })
  }
}
