import { PORTAL_USERS_REDUCER_KEY, types } from './actions'

export { PORTAL_USERS_REDUCER_KEY }

export const initialState = {
  users: {
    isLoading: false,
    isFailed: false,
    data: [],
  },
  form: {
    submitting: false,
    emailInput: '',
    emailValue: [],
    filterValues: {},
    selectedChipId: null,
  },
  filterInputOptions: [],
}

const ACTION_HANDLERS = {
  [types.FETCH_PORTAL_USERS_START]: state => {
    return {
      ...state,
      users: {
        ...state.users,
        isLoading: true,
        isFailed: false,
        data: [],
      },
    }
  },
  [types.FETCH_PORTAL_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    users: {
      ...state.users,
      isLoading: false,
      isFailed: false,
      data: payload,
    },
  }),
  [types.FETCH_PORTAL_USERS_ERROR]: state => ({
    ...state,
    users: {
      ...state.users,
      isLoading: false,
      isFailed: true,
      data: [],
    },
  }),
  [types.SET_SUBMITTING]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      submitting: payload,
    },
  }),
  [types.SET_EMAIL_INPUT]: (state, { payload }) => ({
    ...state,
    form: { ...state.form, emailInput: payload },
  }),
  [types.SET_EMAIL_VALUE]: (state, { payload }) => ({
    ...state,
    form: { ...state.form, emailValue: payload },
  }),
  [types.SET_SELECTED_CHIP_ID]: (state, { payload }) => ({
    ...state,
    form: { ...state.form, selectedChipId: payload },
  }),
  [types.SET_FILTER_INPUT_OPTIONS]: (state, { payload }) => ({
    ...state,
    filterInputOptions: payload,
  }),
  [types.SET_FILTER_VALUE]: (state, { payload }) => {
    const { filter, value } = payload

    return {
      ...state,
      form: {
        ...state.form,
        filterValues: { ...state.form.filterValues, [filter.name]: value },
      },
    }
  },
  [types.RESET_FORM]: state => ({
    ...state,
    form: { ...initialState.form },
  }),
}

export function portalUsersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default portalUsersReducer
