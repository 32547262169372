import { Box, Theme, Tooltip, Typography, makeStyles } from '@material-ui/core'
import {
  GROUP_LABELS,
  PROGRESS_COMPLETED,
  PROGRESS_IN_PROGRESS,
  PROGRESS_IN_TRANSIT,
} from 'utils/milestone/MilestoneGroups/definitions'
import {
  TIMETYPE_ACTUAL,
  TIMETYPE_PREDICTION,
  getTimelineMilestoneDisplayText,
} from 'utils/milestone/getTimelineMilestoneDisplayText'
import { formatCarrierLabel, toTitleCase } from 'utils/strUtils'

import DestinationIcon from 'components/icons/DestinationIcon'
import GroupedMilestoneInterface from 'utils/milestone/interfaces/GroupedMilestoneInterface'
import InProgressIcon from 'components/icons/InProgressIcon'
import InTransitIcon from 'components/icons/InTransitIcon'
import InfoIcon from 'components/icons/InfoIcon'
import { LocationZoneInterface } from 'store/models/LocationZone'
import OceanIcon from 'components/icons/OceanIcon'
import OutdatedPredictionDisclaimer from 'components/OutdatedPredictionDisclaimer'
import ProgressCompletedIcon from 'components/icons/ProgressCompletedIcon'
import RailIcon from 'components/icons/RailIcon'
import React from 'react'
import TruckIcon from 'components/icons/TruckIcon'
import UiGroupInterface from 'store/types/UiGroupInterface'
import classnames from 'classnames'
import groupLastTracedMilestoneIndex from 'utils/milestone/groupLastTracedMilestoneIndex'

const useStyles = makeStyles((theme: Theme) => ({
  statusIcon: {
    fontSize: 25,
    verticalAlign: 'middle',
  },
  statusIconContainer: {
    background: theme.palette.white,
    display: 'inline-block',
  },
  stage: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
  },
  location: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.2,
  },
  colorInTransit: {
    color: theme.palette.teal[600],
  },
  colorInProgress: {
    color: theme.palette.orange[500],
  },
  colorUnknown: {
    color: theme.palette.grey[300],
  },
  statusLabel: {
    marginRight: theme.spacing(2),
  },
  group: {
    position: 'relative',
    zIndex: 1,

    // The line connecting segments
    '&:before': {
      borderTop: `2px solid ${theme.palette.grey[500]}`,
      content: '""',
      width: '100%',
      height: 1,
      display: 'block',
      position: 'absolute',
      top: 14,
      left: 25,
      zIndex: -1,
    },

    // No line on last segment
    '&:last-child:before': {
      display: 'none',
    },
  },
  groupIncomplete: {
    // Overrides above with a dotted line for incomplete segments
    '&:before': {
      borderTop: `2px dotted ${theme.palette.grey[300]}`,
    },
  },
  timestamp: {
    color: theme.palette.grey[600],
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
  },
  dateTime: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
  },
  highlightedContainer: {
    background: theme.palette.purple[100],
    borderRadius: theme.shape.borderRadius,
    padding: '2px 4px 2px 0',
  },
  hasHighlightedContainer: {
    position: 'relative',
    // Extends the background color under the bullet
    '&:before': {
      background: theme.palette.purple[100],
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
      content: '""',
      position: 'absolute',
      height: '100%',
      width: 25,
      left: -18,
      top: 0,
      zIndex: -1,
    },
  },
  vehicleIcon: {
    color: theme.palette.grey[500],
    height: 'auto',
    width: 45,
  },
  vehicleName: {
    ...theme.typography.caption,
    color: theme.palette.grey[500],
    marginTop: -5,
  },
  vehicleContainer: {
    background: theme.palette.white,
    display: 'inline-block',
    paddingRight: 2,
    verticalAlign: 'text-top',
  },
  infoIcon: {
    color: theme.palette.grey[500],
    width: theme.spacing(2),
  },
  milestoneList: {
    color: theme.palette.grey[600],
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    margin: 0,
    paddingLeft: theme.spacing(2),

    '& > li': {
      marginBottom: 2,
      marginRight: theme.spacing(1),
      position: 'relative',
      zIndex: 1,
    },
  },
  count: {
    color: theme.palette.blue[300],
  },
  noBullet: {
    listStyle: 'none',
    marginLeft: -18,
  },
}))

const MAX_GROUPS_BEFORE_TOOLTIP = 6

interface Props {
  classes?: { [key: string]: string }
  group: UiGroupInterface
  groupCount: number
  groupName: string
  locationZone: LocationZoneInterface | null
  milestones: GroupedMilestoneInterface[]
  isFirstGroup: boolean
}

const TimelineSegment = (props: Props) => {
  const classes = useStyles(props)
  const { group, groupCount, groupName, locationZone, milestones, isFirstGroup } = props

  if (!group) {
    return null
  }

  const { groupProgress } = group
  const groupLabel: string = GROUP_LABELS[groupName]
  // If this returns NOT NULL we know this group has the "current" milestone. For our needs here that's all
  // we need to know to know for some conditional styling below.
  const lastTracedMilestoneIndex = groupLastTracedMilestoneIndex(group)
  // We don't show an icon if this is the first group/segment on the timeline
  const equipmentCategory = !isFirstGroup && milestones[0]?.onEquipment?.category
  const equipmentName = toTitleCase(milestones[0]?.onEquipment?.name)
  const hasNoProgress =
    groupProgress !== PROGRESS_COMPLETED &&
    groupProgress !== PROGRESS_IN_PROGRESS &&
    groupProgress !== PROGRESS_IN_TRANSIT

  const hideVesselNames = groupCount > MAX_GROUPS_BEFORE_TOOLTIP

  return (
    <>
      {equipmentCategory && (
        <Box
          className={classnames(classes.group, {
            [classes.groupIncomplete]:
              groupProgress !== PROGRESS_COMPLETED && groupProgress !== PROGRESS_IN_PROGRESS,
          })}
        >
          <Box>
            <Tooltip
              title={
                equipmentName && hideVesselNames
                  ? `Vessel: ${formatCarrierLabel(equipmentName)}`
                  : ''
              }
            >
              <span className={classes.vehicleContainer}>
                {equipmentCategory === 'train' && (
                  <RailIcon width={40} className={classes.vehicleIcon} />
                )}
                {equipmentCategory === 'truck' && (
                  <TruckIcon width={40} className={classes.vehicleIcon} />
                )}
                {equipmentCategory === 'vessel' && (
                  <OceanIcon width={45} className={classes.vehicleIcon} />
                )}
                {equipmentName && hideVesselNames && <InfoIcon className={classes.infoIcon} />}
              </span>
            </Tooltip>
            {equipmentName && !hideVesselNames && (
              <Typography className={classes.vehicleName} paragraph={false}>
                {formatCarrierLabel(equipmentName)}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      <Box
        className={classnames(classes.group, {
          [classes.groupIncomplete]: groupProgress !== PROGRESS_COMPLETED,
        })}
      >
        <Box className={classes.statusIconContainer}>
          {groupProgress === PROGRESS_COMPLETED && (
            <ProgressCompletedIcon className={classes.statusIcon} />
          )}
          {groupProgress === PROGRESS_IN_TRANSIT && (
            <InTransitIcon className={classes.statusIcon} />
          )}
          {groupProgress === PROGRESS_IN_PROGRESS && (
            <InProgressIcon className={classes.statusIcon} />
          )}
          {hasNoProgress && <DestinationIcon className={classes.statusIcon} />}
        </Box>
        <Box>
          <Box mb={1}>
            <Typography
              component="span"
              variant="caption"
              className={classnames(classes.stage, {
                [classes.colorInProgress]:
                  lastTracedMilestoneIndex !== null && groupProgress === PROGRESS_IN_PROGRESS,
                [classes.colorInTransit]: groupProgress === PROGRESS_IN_TRANSIT,
                [classes.colorUnknown]: hasNoProgress,
              })}
            >
              {groupLabel}
            </Typography>
            <Typography
              display="block"
              component="span"
              variant="caption"
              className={classes.location}
            >
              {locationZone?.name}
            </Typography>
          </Box>
        </Box>
        <Box>
          <ul className={classes.milestoneList} data-testid="container-page__milestone-list">
            {milestones?.map((milestone: GroupedMilestoneInterface, idx: number) => {
              const { timelineTracedTime, timelinePredictedTime } = milestone
              // we only display milestones if they have one of these times
              if (!timelineTracedTime && !timelinePredictedTime) {
                return null
              }
              const milestoneAction = milestone.action
              const stage = milestone.stopSegment?.tripSegment?.stage
              const facilityType = milestone.stopSegment?.facilityType

              const predictedType = getTimelineMilestoneDisplayText({
                type: TIMETYPE_PREDICTION,
                stage,
                action: milestoneAction,
                equipmentActivity: milestone.stopSegment.equipmentActivity,
                equipmentCategory: milestone.onEquipment?.category,
                facilityType,
              })

              const actualType = getTimelineMilestoneDisplayText({
                type: TIMETYPE_ACTUAL,
                stage,
                action: milestoneAction,
                equipmentActivity: milestone.stopSegment.equipmentActivity,
                equipmentCategory: milestone.onEquipment?.category,
                facilityType,
              })

              return (
                <li
                  key={idx}
                  className={timelinePredictedTime ? classes.hasHighlightedContainer : ''}
                >
                  <Typography component="span" className={classes.timestamp}>
                    <>
                      {timelineTracedTime && (
                        <Box>
                          {`${actualType}${' '}`}
                          <span className={classes.dateTime}>{timelineTracedTime}</span>
                        </Box>
                      )}
                      {timelinePredictedTime && (
                        <Box display="inline-block" className={classes.highlightedContainer}>
                          {`${predictedType}${' '}`}
                          <span className={classes.dateTime}>{timelinePredictedTime}</span>
                        </Box>
                      )}
                    </>
                  </Typography>
                </li>
              )
            })}
            {group.hasOutdatedPrediction && (
              <li className={classes.noBullet}>
                <Box mt={0.5}>
                  <OutdatedPredictionDisclaimer className={classes.statusLabel} />
                </Box>
              </li>
            )}
          </ul>
        </Box>
      </Box>
    </>
  )
}

export default TimelineSegment
