import React, { useCallback, useEffect, useState } from 'react'
import { closeSupportChat, openSupportChat } from 'store/support/actions'
import { currentTenantFromState, isUserApiLoading, userFromState } from 'store/auth/user/selectors'
import { getUserInfo, validateJWT } from 'store/auth/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { ADMIN_ROUTE } from 'utils/routes'
import AdminComponent from 'components/AdminComponent'
import BaseLayout from '../BaseLayout'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { SuperUserRedirect } from 'pages/Admin/SuperUserRedirect'
import SwitchTenantConfirmation from 'pages/Admin/AdminHomePage/SwitchTenantConfirmation'
import { fetchProfile } from 'store/profile/actions'
import { fromQueryString } from 'utils/urlBuilder'
import get from 'lodash/get'
import logger from 'utils/logger'
import { profileFromState } from 'store/profile/selectors'
import smartlookClient from 'smartlook-client'
import { updateProfileAcceptedTerms } from 'store/profile/actions'
import { updateUnreadMessageCount } from 'store/support/actions'
import { useLocation } from 'react-router-dom'

export const TENANT_PARAM = 'current_tenant'
const SUPER_TENANT = 'super'

interface Props {
  children: React.ReactNode
}

declare const window: any

const AuthLayout = (props: Props) => {
  const { children } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const currentTenant: string = useSelector(currentTenantFromState)
  const userApiLoading: boolean = useSelector(isUserApiLoading)
  const [changeTenantDialogOpen, setChangeTenantDialogOpen] = useState<boolean>(false)
  const user = useSelector(userFromState)
  const email = user.email
  const profile = useSelector(profileFromState)
  const isProfileLoaded = profile && !profile.loading
  const showAcceptTerms = isProfileLoaded && !profile.acceptedTerms

  const redirectSuperUser =
    currentTenant === SUPER_TENANT &&
    location.pathname !== ADMIN_ROUTE.buildUrl() &&
    !(window as any).__SUPER_TENANT_DEVELOPMENT__

  const identifyUser = useCallback(
    user => {
      const traits = {
        email: user.email,
        created: user.dateJoined,
        name: user.email,
        tenant: user.tenant,
        account_type: user.accountType,
        alignment: 'left',
        hide_default_launcher: true,
        horizontal_padding: 115,
        vertical_padding: 20,
      }

      logger.identify(user.email, traits, user.userHash)

      window.Intercom('onUnreadCountChange', (unreadCount: number) => {
        dispatch(updateUnreadMessageCount(unreadCount))
      })
      window.Intercom('onShow', () => {
        // Added to keep direct Intercom interactions in sync with Redux state
        dispatch(openSupportChat())
      })
      window.Intercom('onHide', () => {
        // Added to keep direct Intercom interactions in sync with Redux state
        dispatch(closeSupportChat())
      })
    },
    [dispatch]
  )

  const handleCancel = () => setChangeTenantDialogOpen(false)

  const tenantFromUrl = (location: any) => {
    const query = fromQueryString(location.search)
    return get(query, TENANT_PARAM, null)
  }

  useEffect(() => {
    if (user.userHash) {
      identifyUser(user)

      // Smartlook
      // Without `try...catch` the library `throw`s an exception and JS stops executing, meaning a blank page.
      try {
        if (process.env.NODE_ENV === 'production') {
          smartlookClient.identify(user.externalId, {
            accountType: user.accountType,
            externalId: user.externalId,
            tenant: user.tenant,
          })
        }
      } catch (e) {
        logger.log('Smartlook client is not initialized.', 'error')
      }
    } else {
      dispatch(getUserInfo())
    }
    dispatch(validateJWT())
    dispatch(fetchProfile())

    const urlTenant = tenantFromUrl(location)

    if (user.tenant && urlTenant && urlTenant !== user.tenant) {
      setChangeTenantDialogOpen(true)
    }
  }, [dispatch, identifyUser, location, user])

  return showAcceptTerms ? (
    <ReactTypeformEmbed
      popup={false}
      url={`https://diego380282.typeform.com/to/yDi5S4y8?email=${email}`}
      onSubmit={() => dispatch(updateProfileAcceptedTerms())}
    />
  ) : (
    <>
      <AdminComponent>
        <SwitchTenantConfirmation
          email={email}
          tenant={tenantFromUrl(location)}
          open={changeTenantDialogOpen}
          onClose={handleCancel}
        />
      </AdminComponent>
      {redirectSuperUser ? (
        <SuperUserRedirect />
      ) : (
        <>
          <BaseLayout loading={userApiLoading}>{children}</BaseLayout>
        </>
      )}
    </>
  )
}

export default AuthLayout
