import { ButtonProps, Button as MuiButton } from '@material-ui/core'

import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import omit from 'lodash/omit'
import tinycolor from 'tinycolor2'

const useStyles = makeStyles(theme => ({
  containedLightDefault: {
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.grey[600]).lighten(8).toString(),
    },
  },
  containedLightPrimary: {
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.primary.main).lighten(8).toString(),
    },
  },
  containedLightSecondary: {
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.secondary.main).lighten(8).toString(),
    },
  },
  textLightDefault: {
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.grey[600]).setAlpha(0.04).toRgbString(),
    },
  },
  textLightPrimary: {
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.primary.main).setAlpha(0.04).toRgbString(),
    },
  },
  textLightSecondary: {
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.secondary.main).setAlpha(0.04).toRgbString(),
    },
  },
  outlinedLightDefault: {
    borderColor: theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[600],
      backgroundColor: tinycolor(theme.palette.grey[600]).setAlpha(0.04).toRgbString(),
      borderColor: theme.palette.grey[600],
    },
  },
  outlinedLightPrimary: {
    borderColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: tinycolor(theme.palette.primary.main).setAlpha(0.04).toRgbString(),
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedLightSecondary: {
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.secondary.main).setAlpha(0.04).toRgbString(),
    },
  },
  outlinedAlternateDark: {
    borderColor: theme.palette.grey[300],
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: tinycolor(theme.palette.grey[300]).setAlpha(0.12).toRgbString(),
      borderColor: theme.palette.grey[300],
    },
  },
  outlinedAlternateLight: {
    borderColor: theme.palette.grey[300],
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: tinycolor(theme.palette.grey[300]).setAlpha(0.08).toRgbString(),
      borderColor: theme.palette.grey[300],
    },
  },
  outlinedLightGrey: {
    background: theme.palette.white,
    borderColor: tinycolor(theme.palette.grey[600]).setAlpha(0.7).toRgbString(),
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.grey[600]).setAlpha(0.04).toRgbString(),
    },
  },
  outlinedLightGreyPrimary: {
    background: theme.palette.white,
    borderColor: tinycolor(theme.palette.grey[600]).setAlpha(0.7).toRgbString(),
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.grey[600]).setAlpha(0.04).toRgbString(),
    },
  },
  outlinedLightGreySecondary: {
    background: theme.palette.white,
    borderColor: tinycolor(theme.palette.grey[600]).setAlpha(0.7).toRgbString(),
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.grey[600]).setAlpha(0.04).toRgbString(),
    },
  },
}))

type CMButtonProps = {
  [name: string]: {
    variant: string
    color: string
  }
}

const buttonProps: CMButtonProps = {
  containedLightDefault: {
    variant: 'contained',
    color: 'default',
  },
  containedLightPrimary: {
    variant: 'contained',
    color: 'primary',
  },
  containedLightSecondary: {
    variant: 'contained',
    color: 'secondary',
  },
  outlinedAlternateDark: {
    variant: 'outlined',
    color: 'primary',
  },
  outlinedAlternateLight: {
    variant: 'outlined',
    color: 'primary',
  },
  outlinedLightGrey: {
    variant: 'outlined',
    color: 'default',
  },
  outlinedLightGreyPrimary: {
    variant: 'outlined',
    color: 'primary',
  },
  outlinedLightGreySecondary: {
    variant: 'outlined',
    color: 'secondary',
  },
  textLightDefault: {
    variant: 'text',
    color: 'default',
  },
  textLightPrimary: {
    variant: 'text',
    color: 'primary',
  },
  textLightSecondary: {
    variant: 'text',
    color: 'secondary',
  },
  outlinedLightDefault: {
    variant: 'outlined',
    color: 'default',
  },
  outlinedLightPrimary: {
    variant: 'outlined',
    color: 'primary',
  },
  outlinedLightSecondary: {
    variant: 'outlined',
    color: 'secondary',
  },
}

const ALT_VARIANTS = Object.keys(buttonProps)

// Add our custom prop `buttonType`. Also overriding `variant` to avoid errors on our custom variants. Ideally
// we'd stop using `variant` for those and add a new prop like `customVariant` so we could allow MUI to
// continue to handle their built-in props and we'd deal with ours.
interface Props extends ButtonProps {
  buttonType?: 'button' | 'toggleButton'
  variant?: any
}

type Classes = { [key: string]: string }

// see https://reactjs.org/docs/forwarding-refs.html for explanation of forwardRef.
//
// this is a forward ref so that we can use this button to trigger modals correctly.
// if you remove this forwardRef() below and just make it a simple component the way it was initially,
// FilterOptions will break.
// `buttonType` takes `"button" | "toggleButton"`, allowing this to be used for both types
const Button = React.forwardRef<HTMLButtonElement, Props>((props: any, ref) => {
  const { className, variant, buttonType = 'button', ...rest } = props
  const ButtonToRender: any = buttonType === 'toggleButton' ? ToggleButton : MuiButton

  const classes: Classes = useStyles()
  if (ALT_VARIANTS.indexOf(variant) !== -1) {
    const variantProps = buttonProps[variant]
    const effectiveProps = {
      ...rest,
      ...variantProps,
    }
    const effectiveClassName = cx(classes[variant], className)

    return <ButtonToRender ref={ref} className={effectiveClassName} {...effectiveProps} />
  }
  // Avoids console warning
  const filteredProps = omit(props, 'buttonType')
  return <ButtonToRender ref={ref} {...filteredProps} />
})

export default Button
