import { SUGGEST_KEY, types } from './actions'

import { handleActions } from 'redux-actions'

export { SUGGEST_KEY }

export const initialState = {
  pendingCallCount: 0,
  bols: [],
  bookings: [],
  carriers: [],
  containers: [],
  custom: [],
  lineItems: [],
  salesOrders: [],
  purchaseOrders: [],
  stockTransferOrders: [],
  shipments: [],
}

export default handleActions(
  {
    [types.SEARCH_CALL_START]: state => ({
      ...state,
      pendingCallCount: state.pendingCallCount + 1,
    }),
    [types.SEARCH_CALL_END]: state => ({
      ...state,
      pendingCallCount: Math.max(state.pendingCallCount - 1, 0),
    }),
    [types.LOAD_CARRIERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      carriers: payload,
    }),
    [types.LOAD_SALES_ORDERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      salesOrders: payload,
    }),
    [types.LOAD_PURCHASE_ORDERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      purchaseOrders: payload,
    }),
    [types.LOAD_STOCK_TRANSFER_ORDERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      stockTransferOrders: payload,
    }),
    [types.LOAD_BOLS_SUCCESS]: (state, { payload }) => ({
      ...state,
      bols: payload,
    }),
    [types.LOAD_BOOKINGS_SUCCESS]: (state, { payload }) => ({
      ...state,
      bookings: payload,
    }),
    [types.LOAD_LINE_ITEMS_SUCCESS]: (state, { payload }) => ({
      ...state,
      lineItems: payload,
    }),
    [types.LOAD_CONTAINERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      containers: payload,
    }),
    [types.LOAD_SHIPMENTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      shipments: payload,
    }),
    [types.LOAD_CUSTOM_SUGGEST_SUCCESS]: (state, { payload }) => {
      // Custom endpoints need to be their own key under `custom`
      const key = payload.customKey
      const data = payload.data
      return {
        ...state,
        custom: {
          ...state.custom,
          [key]: data,
        },
      }
    },
  },
  initialState
)

export const anyPendingCallsSelector = state => {
  return state[SUGGEST_KEY].pendingCallCount > 0
}

export const carriersSuggestSelector = state => state[SUGGEST_KEY].carriers
export const salesOrdersSuggestSelector = state => state[SUGGEST_KEY].salesOrders
export const purchaseOrdersSuggestSelector = state => state[SUGGEST_KEY].purchaseOrders
export const stockTransferOrdersSuggestSelector = state => state[SUGGEST_KEY].stockTransferOrders
export const bolsSuggestSelector = state => state[SUGGEST_KEY].bols
export const bookingsSuggestSelector = state => state[SUGGEST_KEY].bookings
export const lineItemsSuggestSelector = state => state[SUGGEST_KEY].lineItems
export const containersSuggestSelector = state => state[SUGGEST_KEY].containers
export const shipmentsSuggestSelector = state => state[SUGGEST_KEY].shipments
export const customSuggestSelector = (state, customKey) => {
  return state[SUGGEST_KEY].custom[customKey]
}
