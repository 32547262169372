import { default as MUITableRow, TableRowProps } from '@material-ui/core/TableRow'
import { Theme, makeStyles } from '@material-ui/core'

import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  hover: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[50]} !important`,
    },
  },
}))

const TableRow = (props: TableRowProps) => {
  const { className, ...rest } = props
  const classes = useStyles(props)

  return (
    <MUITableRow
      className={cx([className, classes.root])}
      classes={{ hover: classes.hover }}
      {...rest}
    />
  )
}

export default TableRow
