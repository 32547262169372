import { TripGroupsInterface } from 'store/tripGroups/interfaces'
import { TripGroupsState } from 'store/tripGroups/interfaces'
import { handleActions } from 'redux-actions'
import { types } from 'store/tripGroups/actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: TripGroupsState = {
  isFailed: false,
  isLoading: false,
  data: undefined,
}

const tripGroups = handleActions<TripGroupsState, any>(
  {
    [types.FETCH_TRIP_GROUPS_START]: (state: TripGroupsState) => {
      return { ...state, isFailed: false, isLoading: true }
    },
    [types.FETCH_TRIP_GROUPS_SUCCESS]: (
      state: TripGroupsState,
      action: { payload: TripGroupsInterface }
    ) => {
      return {
        ...state,
        data: action.payload,
        isFailed: false,
        isLoading: false,
      }
    },
    [types.FETCH_TRIP_GROUPS_FAILURE]: (state: TripGroupsState) => {
      return { ...state, data: undefined, isFailed: true, isLoading: false }
    },
    [types.FETCH_TRIP_GROUPS_RESET]: (state: TripGroupsState) => {
      return { ...state, data: undefined, isFailed: false, isLoading: false }
    },
  },
  initialState
)

export default tripGroups
