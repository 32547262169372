import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Link,
  Typography,
} from '@material-ui/core/'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import { currentTenantFromState } from 'store/auth/user/selectors'
import { useSelector } from 'react-redux'

const Faq = () => {
  const currentTenant = useSelector(currentTenantFromState)
  const faqs = [
    {
      id: 'useage',
      defaultExpanded: true,
      question: 'Who can see these visualizations?',
      answer: (
        <>
          <ul>
            <li>
              <Box fontWeight="fontWeightBold">This is customer data.</Box>
            </li>
            <li>Use your best judgement.</li>
            <li>Grey area? Check in with a founder.</li>
            <li>
              <Box fontWeight="fontWeightBold">It's better to be safe than sorry.</Box>
            </li>
          </ul>
          <Box>
            If you would like to utilize this as an asset, please read "Sharing best practices"
          </Box>
        </>
      ),
    },
    {
      id: 'sharing',
      question: 'Sharing best practices',
      answer: (
        <>
          <Box>
            If you would like to show this off:
            <ol>
              <li>Take a screenshot (on an ultra-wide display for best results)</li>
              <li>Add a project44 watermark</li>
              <li>Read "Who can see these visualizations?"</li>
            </ol>
          </Box>
        </>
      ),
    },
    {
      id: 'access',
      question: 'Can customers see this page?',
      answer: (
        <>
          <Box fontWeight="fontWeightBold">No.</Box>
          <Box>This is only accessible by those with Admin Rights.</Box>
          <Box>
            If you would like to show this off, feel free to take a screenshot (on an ultra-wide
            display for best results) and add a project44 watermark.
          </Box>
        </>
      ),
    },
    {
      id: 'whose-data',
      question: 'Whose data is this?',
      answer: (
        <>
          <Box>Current Tenant:</Box>
          <Box fontWeight="fontWeightBold">{currentTenant}</Box>
        </>
      ),
    },
    {
      id: 'printing',
      question: 'Can we print a high resolution image?',
      answer: (
        <>
          <Box>I believe so. It will require some additional steps but should be possible.</Box>
          <Box>
            Contact <Link href="slack://user?team=T0HS3MC9H&id=UCDFNA4FL">@jmoyle</Link>.
          </Box>
        </>
      ),
    },
    {
      id: 'what-data',
      question: 'What data am I looking at?',
      answer: (
        <>
          This is a mapping of stops (the dots) and route segments (the lines) drawn{' '}
          <Link
            href="https://en.wikipedia.org/wiki/As_the_crow_flies"
            target="_blank"
            rel="noopener"
          >
            "As the crow flies"
          </Link>
          .
        </>
      ),
    },
    {
      id: 'customize',
      question: 'I dislike your choice of colours.',
      answer: (
        <>
          Great news! You can update the map and adjust data points by clicking the 'Edit' button.
        </>
      ),
    },
    {
      id: 'load-time',
      question: 'Why does it take so long to load?',
      answer: (
        <>
          It's a side project, and the queries are rather intense and rendering the map can take a
          hot second.
        </>
      ),
    },
    {
      id: 'who-made',
      question: 'Who made this?',
      answer: (
        <>
          <Box>This is a side project of Jacob M., Haofan S., and Tom A.</Box>
          <Box>We look forward to playing with more data visualization in the future.</Box>
        </>
      ),
    },
    {
      id: 'build-page',
      question: 'Can we build this page out?',
      answer: (
        <>
          <Box>
            Yes. For now this is a temporary page, we can build to it, remove it, whatever. It's
            built for internal employees.
          </Box>
          <Box>
            If you have suggestions or feedback, contact{' '}
            <Link href="slack://channel?team=T0HS3MC9H&id=CM04WGD0T">#fire-ants</Link> or{' '}
            <Link href="slack://user?team=T0HS3MC9H&id=UCDFNA4FL">@jmoyle</Link>.
          </Box>
        </>
      ),
    },
    {
      id: 'found-bug',
      question: 'I found a bug!',
      answer: (
        <>
          <Box>Well done!</Box>
          <Box>
            Report it to <Link href="slack://channel?team=T0HS3MC9H&id=CM04WGD0T">#fire-ants</Link>{' '}
            or <Link href="slack://user?team=T0HS3MC9H&id=UCDFNA4FL">@jmoyle</Link> and we'll handle
            it!
          </Box>
          <img
            src="https://media.giphy.com/media/xT9IgNPVbGsa0Wd8li/giphy.gif"
            alt="friendly bug"
          />
        </>
      ),
    },
  ]

  return faqs.map(({ id, question, answer, defaultExpanded = false }, index) => (
    <ExpansionPanel defaultExpanded={defaultExpanded} key={`${id}-${index}`}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography variant="subtitle2" component="div">
          {question}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography component="div">{answer}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ))
}

export default Faq
