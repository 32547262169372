import { Box, Collapse, Theme, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import Button from 'components/core/Button'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  expandButton: {
    marginLeft: -30,
  },
  item: {
    display: 'block',
  },
}))

/**
 * Takes in an array of strings and based on conditions adds expand/collapse to the items.
 * If the goal is to just get back a list of strings with expand/collapse it's as simple as:
 *
 * ```
 *  <TextListWithExpand items={someArray} collapseAfterCountOf={2} />
 * ```
 * That will return each `item` in a `span` tag and show only two items by default, with an expand button.
 *
 * If you want to render each item in a component or pass each item into a function in the calling component,
 * use `renderOutput`:
 *
 * ```
 *  <TextListWithExpand
 *    items={someArray}
 *    collapseAfterCountOf={2}
 *    renderOutput={item => <Button>{item}</Button>}
 *  />
 * ```
 */

interface Props {
  classes: { [key: string]: string }
  collapseAfterCountOf?: number
  items: any[]
  onClickHandler?: (args?: any) => void
  renderOutput?: (item: any) => React.ReactElement
}

const TextListWithExpand = (props: Props) => {
  const classes = useStyles(props)
  const { collapseAfterCountOf, items = [], onClickHandler = () => {}, renderOutput } = props
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  if (!items || !Array.isArray(items) || items.length === 0) {
    return null
  }

  const collapseCount = collapseAfterCountOf ?? items.length

  // If we have `renderOutput` we use it, otherwise we fall back to the default `<span>` list
  const returnDisplay = (item: string, idx: number) =>
    renderOutput ? (
      renderOutput(item)
    ) : (
      <span key={`item-${idx}`} className={classes.item}>
        {item}
      </span>
    )

  return (
    <Box className={classes.root} data-testid="text-with-list-expand">
      {/* Slice the first `collapseCount` items because these always display */}
      {items?.slice(0, collapseCount).map((item: string, idx: number) => {
        return returnDisplay(item, idx)
      })}
      {/* If we have more than `collapseCount` items we put them inside a collapsible element and add a button */}
      {items?.length > collapseCount && (
        <>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {items?.slice(collapseCount).map((item: string, idx: number) => {
              return returnDisplay(item, idx)
            })}
          </Collapse>
          <Button
            className={classes.expandButton}
            size="small"
            variant="text"
            onClick={() => {
              onClickHandler(isExpanded)
              setIsExpanded(!isExpanded)
            }}
          >
            {isExpanded ? (
              <>
                <ExpandLessIcon />
                Collapse
              </>
            ) : (
              <>
                <ExpandMoreIcon />
                Show all {items.length}
              </>
            )}
          </Button>
        </>
      )}
    </Box>
  )
}

export default TextListWithExpand
