import { Box, TableCell, makeStyles } from '@material-ui/core'
import { EMPTY_CELL_TEXT, VISIBLE_ITEMS_COUNT } from '../'

import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize,
  },
  isExpanded: {
    display: 'block',
  },
  isCollapsed: {
    display: 'none',
  },
  ellipsis: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: theme.spacing(2),
  },
}))

export default function ExpandableCell(props) {
  const noop = value => value
  const { data, isExpanded = false, formatter = noop } = props
  const classes = useStyles(props)

  const visibleItems = data.slice(0, VISIBLE_ITEMS_COUNT)
  const collapsedItems = data.slice(VISIBLE_ITEMS_COUNT)
  const ellipsis = collapsedItems.length > 0 ? '...' : ''
  const visibility = isExpanded ? 'hidden' : 'visible'

  // <span className={classes.ellipsis} style={{ visibility }}>
  //           {ellipsis}
  //         </span>
  return (
    <TableCell className={classes.root}>
      {visibleItems.length === 0
        ? EMPTY_CELL_TEXT
        : visibleItems.map((item, idx) => {
            if (idx !== VISIBLE_ITEMS_COUNT - 1) {
              return <Box key={item}>{formatter(item)}</Box>
            } else {
              return (
                <Box key={item}>
                  {formatter(item)}
                  <span className={classes.ellipsis} style={{ visibility }}>
                    {ellipsis}
                  </span>
                </Box>
              )
            }
          })}
      {collapsedItems.length !== 0 && (
        <Box
          className={classnames({
            [classes.isExpanded]: isExpanded,
            [classes.isCollapsed]: !isExpanded,
          })}
        >
          {collapsedItems.map(item => (
            <Box key={item}>{formatter(item)}</Box>
          ))}
        </Box>
      )}
    </TableCell>
  )
}

ExpandableCell.propTypes = {
  data: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  classes: PropTypes.object,
}
