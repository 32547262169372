import { PROGRESS_COMPLETED, PROGRESS_IN_PROGRESS, PROGRESS_IN_TRANSIT } from './definitions'

import UiGroupsInterface from 'store/types/UiGroupsInterface'
import anyMilestoneIsTraced from './anyMilestoneIsTraced'
import each from 'lodash/each'

/**
 * Sets each group's `groupProgress` property: Completed, In-transit or In Progress
 * - If a group already has `groupProgress: 'Comnpleted'` it will be a no-op for that group
 * - Otherwise we apply the rules for in-transit and in progress
 * @param groupedMilestones object
 * @return void
 */
export const setGroupProgress = (groupedMilestones: UiGroupsInterface) => {
  for (const group in groupedMilestones) {
    each(groupedMilestones[group], location => {
      // Nothing to do if we already know it's complete
      if (location.groupProgress !== PROGRESS_COMPLETED) {
        if (location.previousGroupComplete && !anyMilestoneIsTraced(location.milestones)) {
          return (location.groupProgress = PROGRESS_IN_TRANSIT)
        }
        if (anyMilestoneIsTraced(location.milestones)) {
          return (location.groupProgress = PROGRESS_IN_PROGRESS)
        }
        // No progress discernible
        location.groupProgress = ''
      }
    })
  }
}

export default setGroupProgress
