import { Metric, MetricNames } from 'store/planning/utils/Metric'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { DASHBOARD_FILTER_KEY } from 'store/filterGroups/actions'
import { Roll } from 'store/planning/utils/Roll'
import { allFilterGroupEvents } from 'store/filterGroups/sagas'
import { fetchFilteredGroupedRoutes } from 'store/planning/sagas/routesSagas'
import { filterGroupSelectors } from 'store/filterGroups'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import { getPopulatedDateRange } from 'store/planning/utils/DateRange'
import humps from 'humps'
import keyBy from 'lodash/keyBy'
import logger from 'utils/logger'
import routesApi from 'store/planning/sagas/api'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'
import { types } from 'store/userDashboard/actions'

const getResponseData = resp => humps.camelizeKeys(resp.data)

export const TOP_N = 5

const selectors = filterGroupSelectors[DASHBOARD_FILTER_KEY]

function* getCurrentFilters() {
  const filterGroup = yield select(selectors.currentFilterGroupSelector)
  return get(filterGroup, 'params.filters', [])
}

function* getCurrentDateRange() {
  const dateRange = yield select(selectors.dateRangeSelector)
  return getPopulatedDateRange(dateRange)
}

export function* fetchTopLanesByVolumeSaga() {
  try {
    const filters = yield getCurrentFilters()
    const groupBy = ['destLocation', 'originLocation']
    const { startTime, endTime } = yield getCurrentDateRange()
    const metricNames = [MetricNames.DURATION]
    const routeGroups = yield fetchFilteredGroupedRoutes(
      filters,
      groupBy,
      startTime,
      endTime,
      metricNames
    )

    if (routeGroups.length === 0) {
      yield put({
        type: types.FETCH_TOP_LANES_BY_VOLUME_SUCCESS,
        payload: null,
      })
      return
    }

    const groupedRouteIds = routeGroups.map(group => group.routeIds)
    const metrics = getResponseData(
      yield call(routesApi.fetchMetricsForRoutes, groupedRouteIds, {
        startTime,
        endTime,
        metricNames,
      })
    )

    const routeGroupsMap = keyBy(routeGroups, group => group.id)

    const sortFn = group => get(group, 'durations[0].count', 0) * -1
    const topRouteGroups = sortBy(metrics, sortFn).slice(0, TOP_N)

    const payload = topRouteGroups.map(group => {
      const routeGroup = routeGroupsMap[group.routeIds.join('-')]
      return {
        ...routeGroup,
        metrics: Metric.of(group.durations[0]),
      }
    })
    yield put({ type: types.FETCH_TOP_LANES_BY_VOLUME_SUCCESS, payload })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_TOP_LANES_BY_VOLUME_ERROR })
  }
}

export function* fetchVariableRoutesSaga() {
  try {
    const filters = yield getCurrentFilters()
    const groupBy = []
    const { startTime, endTime } = yield getCurrentDateRange()
    const metricNames = [MetricNames.DURATION]
    const routeGroups = yield fetchFilteredGroupedRoutes(
      filters,
      groupBy,
      startTime,
      endTime,
      metricNames
    )

    if (routeGroups.length === 0) {
      yield put({
        type: types.FETCH_VARIABLE_ROUTES_SUCCESS,
        payload: null,
      })
      return
    }

    const groupedRouteIds = flatten(routeGroups.map(group => group.routeIds)).map(routeId => [
      routeId,
    ])
    const metrics = getResponseData(
      yield call(routesApi.fetchMetricsForRoutes, groupedRouteIds, {
        startTime,
        endTime,
        metricNames,
      })
    ).map(routeMetric => ({
      routeId: routeMetric.routeIds[0],
      metrics: Metric.of(routeMetric.durations[0]),
    }))

    const routes = flatten(routeGroups.map(group => group.routes))
    const routesMap = keyBy(routes, r => r.routeId)

    const metricsSorted = sortBy(
      metrics.filter(routeMetric => routeMetric.metrics.variability > 0),
      routeMetric => routeMetric.metrics.variability
    )
    const leastVariableRoutes = metricsSorted.slice(0, TOP_N)
    const mostVariableRoutes = metricsSorted
      .slice(Math.max(0, metricsSorted.length - TOP_N), metricsSorted.length)
      .reverse()

    const makePayload = variableRoutes =>
      variableRoutes.map(routeMetric => {
        const route = routesMap[routeMetric.routeId]
        return { ...route, ...routeMetric }
      })

    const payload = {
      most: makePayload(mostVariableRoutes),
      least: makePayload(leastVariableRoutes),
    }

    yield put({ type: types.FETCH_VARIABLE_ROUTES_SUCCESS, payload })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_VARIABLE_ROUTES_ERROR })
  }
}

export function* fetchTotalContainerCountStatusSaga() {
  try {
    const filters = yield getCurrentFilters()
    const groupBy = []
    const dateRange = yield getCurrentDateRange()
    const metricNames = [MetricNames.DELAY]
    const routeGroups = yield fetchFilteredGroupedRoutes(
      filters,
      groupBy,
      dateRange.startTime,
      dateRange.endTime,
      metricNames
    )

    if (routeGroups.length === 0) {
      yield put({
        type: types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_SUCCESS,
        payload: null,
      })
      return
    }

    const groupedRouteIds = [flatten(routeGroups.map(group => group.routeIds))]

    const early = getResponseData(
      yield call(routesApi.fetchCountMetricsForRoutes, groupedRouteIds, {
        metricName: MetricNames.DELAY,
        upperBound: -1,
        ...dateRange,
      })
    )
    const onTime = getResponseData(
      yield call(routesApi.fetchCountMetricsForRoutes, groupedRouteIds, {
        metricName: MetricNames.DELAY,
        upperBound: 2,
        lowerBound: -1,
        ...dateRange,
      })
    )
    const delayed = getResponseData(
      yield call(routesApi.fetchCountMetricsForRoutes, groupedRouteIds, {
        metricName: MetricNames.DELAY,
        lowerBound: 2,
        ...dateRange,
      })
    )
    const metrics = {
      delayed,
      early,
      onTime,
    }

    const overallTotal = sum(
      Object.values(metrics).map(m => get(m, '[0].metricCounts[0].count', 0))
    )

    const rolledUpMetrics = {}
    Object.keys(metrics).forEach(key => {
      const count = get(metrics, `${key}[0].metricCounts[0].count`, 0)
      rolledUpMetrics[key] = { count, percentage: count / overallTotal }
    })

    yield put({ type: types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_SUCCESS, payload: rolledUpMetrics })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_ERROR })
  }
}

export function* fetchContainerTripPerformanceSaga() {
  try {
    const filters = yield getCurrentFilters()
    const groupBy = []
    const dateRange = yield getCurrentDateRange()
    const { startTime, endTime, aggregation, aggregationLength } = dateRange
    const metricNames = [MetricNames.DELAY]
    const routeGroups = yield fetchFilteredGroupedRoutes(
      filters,
      groupBy,
      startTime,
      endTime,
      metricNames
    )

    if (routeGroups.length === 0) {
      yield put({
        type: types.FETCH_CONTAINER_TRIP_PERFORMANCE_SUCCESS,
        payload: null,
      })
      return
    }

    const groupedRouteIds = [flatten(routeGroups.map(group => group.routeIds))]

    const early = getResponseData(
      yield call(
        routesApi.fetchCountMetricsForRoutes,
        groupedRouteIds,
        {
          metricName: MetricNames.DELAY,
          upperBound: -1,
          ...dateRange,
        },
        aggregation,
        aggregationLength
      )
    )
    const onTime = getResponseData(
      yield call(
        routesApi.fetchCountMetricsForRoutes,
        groupedRouteIds,
        {
          metricName: MetricNames.DELAY,
          upperBound: 2,
          lowerBound: -1,
          ...dateRange,
        },
        aggregation,
        aggregationLength
      )
    )
    const delayed = getResponseData(
      yield call(
        routesApi.fetchCountMetricsForRoutes,
        groupedRouteIds,
        {
          metricName: MetricNames.DELAY,
          lowerBound: 2,
          ...dateRange,
        },
        aggregation,
        aggregationLength
      )
    )
    const metrics = {
      delayed,
      early,
      onTime,
    }

    const rolledUpMetrics = {}
    Object.keys(metrics).forEach(key => {
      rolledUpMetrics[key] = get(metrics, `${key}[0].metricCounts`, [])
    })

    yield put({
      type: types.FETCH_CONTAINER_TRIP_PERFORMANCE_SUCCESS,
      payload: { ...rolledUpMetrics },
    })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_CONTAINER_TRIP_PERFORMANCE_ERROR })
  }
}

export function* fetchRollsOverTimeSaga() {
  try {
    const filters = yield getCurrentFilters()
    const groupBy = []
    const { startTime, endTime, aggregation, aggregationLength } = yield getCurrentDateRange()
    const metricNames = [MetricNames.ROLL]
    const routeGroups = yield fetchFilteredGroupedRoutes(filters, groupBy, startTime, endTime)

    if (routeGroups.length === 0) {
      yield put({
        type: types.FETCH_ROLLS_OVER_TIME_SUCCESS,
        payload: null,
      })
      return
    }

    const groupedRouteIds = [flatten(routeGroups.map(group => group.routeIds))]

    const metrics = getResponseData(
      yield call(routesApi.fetchMetricsForRoutes, groupedRouteIds, {
        startTime,
        endTime,
        metricNames,
        aggregation,
        aggregationLength,
      })
    )

    const rolls = metrics[0].rolls.map(Roll.of)

    const totalRolls = rolls.reduce((acc, item) => {
      return acc + item.rolledCount
    }, 0)
    const nonRolls = rolls.reduce((acc, item) => {
      return acc + item.notRolledCount
    }, 0)
    const totalContainers = totalRolls + nonRolls

    const rolledUpMetrics = {
      rolls,
      totalRolls,
      totalContainers,
    }

    yield put({
      type: types.FETCH_ROLLS_OVER_TIME_SUCCESS,
      payload: rolledUpMetrics,
    })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_ROLLS_OVER_TIME_ERROR })
  }
}

export function* fetchDwellTimesSaga() {
  try {
    const rawFilter = yield getCurrentFilters()
    const { startTime, endTime } = yield getCurrentDateRange()
    const metricNames = [MetricNames.DWELL]
    const filters = rawFilter.map(filter => {
      return {
        key: filter.key,
        operator: filter.operator,
        val: filter.val.map(filterValue => filterValue.key),
      }
    })
    const segments = getResponseData(
      yield call(routesApi.fetchRouteSegments, { filters, startTime, endTime, metricNames })
    )

    if (segments.length === 0) {
      yield put({
        type: types.FETCH_DWELL_TIMES_SUCCESS,
        payload: null,
      })
      return
    }

    const segmentIds = segments.map(s => [s.routeSegmentId])
    const metrics = getResponseData(
      yield call(routesApi.fetchMetricsForSegments, segmentIds, { startTime, endTime, metricNames })
    ).map(segmentMetric => ({
      routeSegmentId: segmentMetric.routeSegmentIds[0],
      metrics: Metric.of(segmentMetric.dwell[0]),
    }))

    // Keep the top `topN` results for each sorted array.
    const topN = 10
    const topAverage = sortBy(metrics, m => m.metrics.metricsMean * -1).slice(0, topN)
    const topVolume = sortBy(metrics, m => m.metrics.count * -1).slice(0, topN)
    const segmentMap = keyBy(segments, s => s.routeSegmentId)

    const makePayload = topMetrics =>
      topMetrics.map(segmentMetrics => {
        const segment = segmentMap[segmentMetrics.routeSegmentId]
        return { ...segment, ...segmentMetrics }
      })

    const payload = {
      topAverage: makePayload(topAverage),
      topVolume: makePayload(topVolume),
    }

    yield put({ type: types.FETCH_DWELL_TIMES_SUCCESS, payload })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_DWELL_TIMES_ERROR })
  }
}

function* retriggerAllCharts() {
  yield put({ type: types.FETCH_TOP_LANES_BY_VOLUME_START })
  yield put({ type: types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_START })
  yield put({ type: types.FETCH_VARIABLE_ROUTES_START })
  yield put({ type: types.FETCH_CONTAINER_TRIP_PERFORMANCE_START })
  yield put({ type: types.FETCH_ROLLS_OVER_TIME_START })
  yield put({ type: types.FETCH_DWELL_TIMES_START })
}

function* watchToRetriggerCharts() {
  const filterEvents = allFilterGroupEvents[DASHBOARD_FILTER_KEY]
  yield takeLatest(filterEvents, retriggerAllCharts)
}

export const sagas = [
  takeLatest(types.FETCH_CONTAINER_TRIP_PERFORMANCE_START, fetchContainerTripPerformanceSaga),
  takeLatest(types.FETCH_TOTAL_CONTAINER_COUNT_STATUS_START, fetchTotalContainerCountStatusSaga),
  takeLatest(types.FETCH_TOP_LANES_BY_VOLUME_START, fetchTopLanesByVolumeSaga),
  takeLatest(types.FETCH_VARIABLE_ROUTES_START, fetchVariableRoutesSaga),
  takeLatest(types.FETCH_ROLLS_OVER_TIME_START, fetchRollsOverTimeSaga),
  takeLatest(types.FETCH_DWELL_TIMES_START, fetchDwellTimesSaga),
  watchToRetriggerCharts(),
]
