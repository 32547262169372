import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Link from 'components/core/Link'
import NotProvided from 'components/NotProvided'
import PopoverList from 'components/PopoverList'
import { TripInterface } from 'store/models/Trip'
import { getShipmentDetailPath } from 'utils/rollups'
import logger from 'utils/logger'

const useStyles = makeStyles((theme: Theme) => ({
  expandButton: {
    borderRadius: 0,
    height: 19,
    padding: 0,
    position: 'relative',
  },
  expandIcon: {
    color: theme.palette.grey[500],
    fontSize: 22,
  },
  popoverHover: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)', // needs to match MUI's IconButton hover bg
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
    },
  },

  listItem: {
    minWidth: 215,
    paddingBottom: 2,
    paddingTop: 2,
  },
  total: {
    whiteSpace: 'nowrap',
  },
}))

interface Props {
  classes?: Record<string, string>
  isPublicShare?: boolean
  refNumbers: TripInterface[]
  rollup: string
}

const RefNumberList = (props: Props) => {
  const classes = useStyles(props)
  const { isPublicShare = false, refNumbers, rollup } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const listAnchorRef = useRef(null)
  const handleClose = () => setAnchorEl(null)
  const handleClick = () => setAnchorEl(listAnchorRef.current)

  if (refNumbers.length === 1) {
    return (
      <Typography variant="body2">
        {!isPublicShare ? (
          <Link
            to={getShipmentDetailPath(rollup, refNumbers[0].number, refNumbers[0].externalId)}
            onClick={() =>
              logger.notify('Entity Page subheader', {
                refType: rollup,
                entityId: refNumbers[0].externalId,
              })
            }
          >
            {refNumbers[0].number}
          </Link>
        ) : (
          <>{refNumbers[0].number}</>
        )}
      </Typography>
    )
  }

  // Multiple container numbers show count and have a popover
  if (refNumbers.length > 1) {
    return (
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          className={classes.popoverHover}
          onClick={handleClick}
        >
          <Box flexGrow="1">
            {refNumbers.length > 0 ? (
              <Typography variant="body2" ref={listAnchorRef} className={classes.total}>
                {refNumbers.length} Total
              </Typography>
            ) : (
              <NotProvided />
            )}
          </Box>
          {refNumbers.length > 0 && (
            <Box className={classes.expandButton} onClick={handleClick} id="listAnchorRef">
              {Boolean(anchorEl) ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </Box>
          )}
        </Box>

        <PopoverList
          anchorEl={anchorEl}
          clipboardData={refNumbers.map((trip: TripInterface) => trip.number).join(',')}
          handleClose={handleClose}
          isPublicShare={isPublicShare}
          listData={refNumbers.map((ref: TripInterface, idx: number) => (
            <Box className={classes.listItem} key={`container-number-${idx}`}>
              {!isPublicShare ? (
                <Link
                  to={getShipmentDetailPath(rollup, ref.number, ref.externalId)}
                  onClick={() =>
                    logger.notify('Entity Page header', {
                      refType: rollup,
                      entityId: ref.externalId,
                    })
                  }
                >
                  {ref.number}
                </Link>
              ) : (
                <>{ref.number}</>
              )}
            </Box>
          ))}
        />
      </Box>
    )
  }

  return <NotProvided />
}

export default RefNumberList
