import get from 'lodash/get'

const getDeliveryStatus = (routes, etaStatuses) => {
  if (routes.length > 1) return 'ETA unavailable' // 'trip has split'
  const eta = get(etaStatuses, 'predictedETA')
  if (eta) {
    return eta
  }
  return 'ETA Unavailable'
}

export default getDeliveryStatus
