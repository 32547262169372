import * as definitions from './definitions'

import { GroupedByUnlocodeMilestoneInterface } from './interfaces'
import UiGroupsInterface from 'store/types/UiGroupsInterface'
import anyMilestoneIsTraced from './anyMilestoneIsTraced'
import each from 'lodash/each'
import getGroupStatuses from './getGroupStatuses'
import groupBy from 'lodash/groupBy'
import groupRules from './groupRules'
import lastMilestoneIsDepart from './lastMilestoneIsDepart'
import setGroupIsComplete from './setGroupIsComplete'
import setGroupProgress from './setGroupProgress'
import setSubsequentGroupHasTracedMilestone from './setSubsequentGroupHasTracedMilestone'
import some from 'lodash/some'

/**
 * Constructs the Journey Detail objects for the UI
 * @param milestones array of milestones for this journey sorted by location (unlocode)
 * @returns object

 */
export const groupMilestonesForUi = (milestones: GroupedByUnlocodeMilestoneInterface) => {
  // This will hold our groups
  const groupedMilestones = {} as UiGroupsInterface
  let anyPreviousHasTraced = false

  // Loop over the stages (preMain: [], main: [], postMain: []) to get the groups in each stage
  each(definitions.STAGE_GROUPS, (groups, stage) => {
    // Loop over the groups in this stage (emptyDispatch, preCarriage, etc)
    each(groups, group => {
      // Create a key for this group
      groupedMilestones[group] = {}
      // Get the milestones only for our current stage
      const stageMilestones = milestones[stage]
      // Group by location (unlocode). This is because we can have multiples of the same groups, i.e.,
      // `Pre-Carriage` could appear twice in the UI if we have milestones from two locations
      const groupedByUnlocode = groupBy(stageMilestones, m => m.locationZone?.unlocode)

      // Now each location
      for (const unlocode in groupedByUnlocode) {
        const locationMilestones = groupedByUnlocode[unlocode]
        const isValidGroup = groupRules[group].isValidGroup(locationMilestones)

        if (isValidGroup) {
          const validMilestones = groupRules[group].getValidMilestones(locationMilestones)
          // Bring in any statuses on the individual milestones here at the group level for UI display.
          const groupStatuses = getGroupStatuses(group, validMilestones)
          // If any milestone has an outdated prediction we want to know at the group level as well
          const hasOutdatedPrediction = some(validMilestones, m => m.hasOutdatedPrediction)

          // We don't return anything for groups without valid milestones.
          // Most of these properties are necessary for various UI messaging/icons in the functions below.
          const hasTracedTime = anyMilestoneIsTraced(validMilestones)
          if (validMilestones.length > 0) {
            groupedMilestones[group][unlocode] = {
              anyPreviousHasTraced,
              groupStatuses,
              hasOutdatedPrediction,
              hasTracedTime,
              lastMilestoneIsDepart: lastMilestoneIsDepart(validMilestones),
              milestones: validMilestones,
            }

            // This persists the value of `anyPreviousHasTraced` for each loop.
            // This is a once-true keep-it-true variable so if it's already true leave it alone, otherwise
            // set it to this loop's `milestone.hasTracedTime` in case this one is `true`
            anyPreviousHasTraced = anyPreviousHasTraced || hasTracedTime
          }
        }
      }
    })
  })

  // Loops through all groups  and adds a property indicating if subsequent groups have a traced milestone
  setSubsequentGroupHasTracedMilestone(groupedMilestones)
  // Adds a `groupProgress: completed` property to any groups where we know enough to determine that
  setGroupIsComplete(groupedMilestones)
  // Finally, inspects each group and sets `groupProgress`. If it was already marked `completed` in the above line
  // this is basically a no-op. Otherwise it checks for two other possible progresses: In-transit and In-progress.
  // The function above needs to go before this because the rule for In-transit needs to know if the previous
  // group is "completed".
  setGroupProgress(groupedMilestones)

  return groupedMilestones
}

export default groupMilestonesForUi
