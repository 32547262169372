import createTheme from 'utils/createTheme'
const theme = createTheme()

const datavisPalette = theme.palette.datavis
const HighchartsTheme = {
  colors: [
    datavisPalette.yellow[400],
    '#00558B',
    '#00558B',
    datavisPalette.blue[400],
    datavisPalette.blue[100],
    theme.palette.error.main,
    datavisPalette.purple[400],
    datavisPalette.green[400],
    theme.palette.error.dark,
    datavisPalette.blue[400],
    datavisPalette.blue[100],
  ],
  chart: {
    backgroundColor: {},
    style: {
      fontFamily: theme.typography.fontFamily,
    },
  },
  title: {
    align: 'left',
    margin: 25,
    style: {
      ...theme.typography.h6,
    },
  },
  subtitle: {
    style: {},
  },
  xAxis: {
    gridLineColor: datavisPalette.blue[50],
    labels: {
      style: {
        ...theme.typography.body1,
        // LineHeight not working. FontSize * LineHeight is the typical automatic calculation
        lineHeight: theme.typography.body1.fontSize * theme.typography.body1.lineHeight,
        color: datavisPalette.grey[400],
      },
    },
    lineColor: datavisPalette.grey[400],
    // TODO: Figure out ticks
    // minorGridLineColor: '#505053',
    // tickColor: '#707073',
    // tickWidth: 1,
    // minorGridLineColor: '#505053',
    // tickColor: '#707073',
    title: {
      style: {
        ...theme.typography.body1,
        // LineHeight not working. FontSize * LineHeight is the typical automatic calculation
        lineHeight: theme.typography.body1.fontSize * theme.typography.body1.lineHeight,
        color: theme.palette.text.secondary,
      },
    },
  },
  yAxis: {
    gridLineColor: datavisPalette.blue[50],
    labels: {
      style: {
        ...theme.typography.body1,
        // LineHeight not working. FontSize * LineHeight is the typical automatic calculation
        lineHeight: theme.typography.body1.fontSize * theme.typography.body1.lineHeight,
        color: datavisPalette.grey[400],
      },
    },
    title: {
      style: {
        ...theme.typography.body1,
        // Does not accept float. FontSize * LineHeight is the typical automatic calculation
        lineHeight: theme.typography.body1.fontSize * theme.typography.body1.lineHeight,
        color: theme.palette.text.secondary,
      },
    },
  },
  tooltip: {
    outside: true,
    backgroundColor: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    borderColor: 'none',
    style: {
      ...theme.typography.body1,
      // Does not accept float. FontSize * LineHeight is the typical automatic calculation
      lineHeight: theme.typography.body1.fontSize * theme.typography.subtitle2.lineHeight,
    },
  },
  plotOptions: {
    series: {
      dataLabels: {},
      marker: {},
      pointWidth: 15,
      fillColor: datavisPalette.yellow[400],
      lineWidth: 2,
      medianColor: '#00558B',
      medianWidth: 4,
      stemColor: datavisPalette.blue[300],
      stemDashStyle: 'solid',
      stemWidth: 2,
      whiskerColor: datavisPalette.blue[300],
      whiskerLength: 15, // Length also accepts a string percentage
      whiskerWidth: 2,
    },
    boxplot: {},
    candlestick: {},
    errorbar: {},
  },
  legend: {},
  credits: { enabled: false },
  labels: {},
  drilldown: {},
  navigation: {},
  rangeSelector: {},
  navigator: {},
  scrollbar: {},
}

export default HighchartsTheme
