import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  invite: ({ tenant, email, force = false }) => {
    return api._call('post', '/user/invite', {
      tenant,
      email,
      force,
    })
  },
}

export default api
