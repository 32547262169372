import React, { useState } from 'react'
import { Tooltip, withStyles } from '@material-ui/core'
import { clearHoveredRoute, highlightHoveredRoute } from 'store/planning/utils/RouteComparison'
import { days, routeColors } from 'store/planning/utils/RouteComparison'
import { formatLocationLabel, formatMinimalDecimals } from 'utils/strUtils'
import { routeGroupSelector, routeMetricsSelector, selectedRouteIdsSelector } from 'store/planning'

import CMPaper from 'components/CMPaper'
import ScoreCardSkeleton from './ScoreCardSkeleton'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import cx from 'classnames'
import tracker from 'utils/logger/tracker'
import uniq from 'lodash/uniq'

const styles = theme => ({
  greyColor: {
    color: theme.palette.grey[500],
  },
  container: {
    width: '100%',
    color: theme.palette.grey[500],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    height: 50,
    padding: theme.spacing(1),
    '&:first-child': {
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  col: {
    flex: 1,
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  locationCol: {
    flex: 5,
    marginLeft: theme.spacing(2),
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  headerText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  location: {
    color: theme.palette.black,
  },
  to: {
    fontStyle: 'italic',
    margin: '0 5px 0 5px',
    color: theme.palette.grey[500],
  },
  viaLocations: {
    fontSize: 12,
    color: theme.palette.grey[500],
    fontStyle: 'italic',
  },
  metric: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  carrierTooltip: {
    marginTop: 6,
  },
  carrierTooltipLabel: {
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
  },
  dimmed: {
    opacity: 0.2,
    transition: 'opacity 0.5s',
  },
})

const LocationTooltip = withStyles(theme => ({
  tooltip: {
    color: theme.palette.black,
    backgroundColor: theme.palette.blue[50],
    border: '1px solid #32C5FF',
    fontSize: 12,
    borderRadius: 0,
    padding: theme.spacing(0.5),
  },
}))(Tooltip)

function Location({ route, classes }) {
  const uniqWaypoints = uniq(route.waypointLocations.map(loc => formatLocationLabel(loc.name)))
  const numWaypoints = uniqWaypoints.length
  const origName = formatLocationLabel(route.originLocation.name)
  const destName = formatLocationLabel(route.destLocation.name)
  const needsComma = idx => idx < numWaypoints - 1

  return (
    <LocationTooltip
      placement={'top-start'}
      title={
        <>
          <b>{origName}</b> <i>to</i> <b>{destName}</b>
          <div>
            {uniqWaypoints.map((name, idx) => (
              <span key={idx}>
                <i>via</i> <b>{name}</b>
                {needsComma(idx) ? ', ' : ''}
              </span>
            ))}
          </div>
          {route.carrier && route.carrier.abbreviation && (
            <div className={classes.carrierTooltip}>
              <span className={classes.carrierTooltipLabel}>Carrier:</span>{' '}
              <b>{route.carrier.abbreviation}</b>
            </div>
          )}
        </>
      }
    >
      <div className={cx(classes.locationCol, classes.ellipsis)}>
        <div className={classes.ellipsis}>
          <span className={classes.location}>{origName}</span>
          <span className={classes.to}>to</span>
          <span className={classes.location}>{destName}</span>
        </div>
        <div className={cx(classes.viaLocations, classes.ellipsis)}>
          {uniqWaypoints.map((name, idx) => (
            <span key={idx}>
              via <b>{name}</b>
              {needsComma(idx) ? ', ' : ''}
            </span>
          ))}
        </div>
      </div>
    </LocationTooltip>
  )
}

function Scorecard({ classes, route, metric, color, hoveredRouteId, setHoveredRouteId }) {
  const hovered = hoveredRouteId !== undefined ? hoveredRouteId === route.routeId : undefined
  return (
    <CMPaper
      className={cx(classes.row, { [classes.dimmed]: hovered === false })}
      style={{
        background: `linear-gradient(to right, ${color} 12px, ${color}, transparent 12px)`,
      }}
      onMouseEnter={() => setHoveredRouteId(route.routeId)}
    >
      <Location route={route} classes={classes} />
      <div className={classes.col}>
        <span className={classes.metric}>{formatMinimalDecimals(metric.metricsMedian, 1)}</span>{' '}
        <span className={classes.greyColor}>{days(metric.metricsMedian)}</span>
      </div>
      <div className={cx(classes.col)}>
        <span className={classes.metric}>{formatMinimalDecimals(metric.variability, 1)}</span>{' '}
        <span className={classes.greyColor}>{days(metric.variability)}</span>
      </div>
    </CMPaper>
  )
}

function RouteComparisonScorecards({
  chart,
  classes,
  metrics,
  routes,
  selectedRouteIds,
  theme,
  isLoading,
  hasNoData,
  maxData,
}) {
  const [hoveredRouteId, setHoveredRouteId] = useState(undefined)

  const routeIds = [...selectedRouteIds].filter(routeId => routeId in metrics)
  const colors = routeColors(theme)

  return (
    <div
      className={classes.container}
      onMouseLeave={() => {
        setHoveredRouteId(undefined)
        clearHoveredRoute(chart)
      }}
    >
      <div className={classes.row}>
        <div className={cx(classes.locationCol, classes.headerText)}>
          Routes ({maxData ? '0' : routeIds.length}):
        </div>
        <div className={cx(classes.col, classes.headerText)}>Time:</div>
        <div className={cx(classes.col, classes.headerText)}>Variability:</div>
      </div>
      {isLoading || hasNoData || maxData ? (
        <>
          <ScoreCardSkeleton isAnimated={isLoading} />
          <ScoreCardSkeleton isAnimated={isLoading} />
          <ScoreCardSkeleton isAnimated={isLoading} />
        </>
      ) : (
        <>
          {routeIds.map((routeId, idx) => {
            const route = routes[routeId]
            return (
              <Scorecard
                key={routeId}
                route={route}
                metric={metrics[routeId].durations[0]}
                classes={classes}
                color={colors[idx]}
                hoveredRouteId={hoveredRouteId}
                setHoveredRouteId={hoveredId => {
                  tracker.planning.userHoversOverComparisonScorecard()
                  setHoveredRouteId(hoveredId)
                  highlightHoveredRoute(chart, hoveredId)
                }}
              />
            )
          })}
        </>
      )}
    </div>
  )
}

const flattenRoutes = routeGroups => {
  const flattened = {}
  routeGroups.forEach(group => {
    group.routes.forEach(route => {
      flattened[route.routeId] = route
    })
  })
  return flattened
}

const routesSelector = createSelector(routeGroupSelector, flattenRoutes)

const mapDispatchToProps = {}

const mapStateToProps = state => ({
  routes: routesSelector(state),
  metrics: routeMetricsSelector(state),
  selectedRouteIds: selectedRouteIdsSelector(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RouteComparisonScorecards))
