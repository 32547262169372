import { BoardColumnInterface, BoardRowInterface } from 'store/board/interfaces'

import BaseCell from 'components/core/ConfigurableTable/CellTypes/BaseCell'
import Box from '@material-ui/core/Box'
import NotProvided from 'components/NotProvided'
import React from 'react'
import Typography from '@material-ui/core/Typography'

interface Props {
  className?: string
  column: BoardColumnInterface
  onContextMenu: () => void
  rollup: string
  row: BoardRowInterface
}

const DualCell = (props: Props) => {
  const { className, column, onContextMenu, rollup, row } = props
  let { header, detail, disclaimer } = column.getCellValue(row)
  const invalidHeader = !header
  const invalidDetail = !detail
  header = invalidHeader ? <NotProvided text="Not Provided" /> : header
  detail = invalidDetail ? <NotProvided text="Not Provided" /> : detail
  const showDetail = !invalidHeader && !column.hideDetail
  const showDisclaimer = !invalidHeader

  return (
    <BaseCell
      className={className}
      column={column}
      onContextMenu={onContextMenu}
      rollup={rollup}
      row={row}
    >
      <Typography variant="body2">{header}</Typography>
      {showDetail && (
        <>
          <Typography variant="caption" color="textSecondary">
            {detail}
          </Typography>
        </>
      )}
      {showDisclaimer && disclaimer && (
        <>
          <Typography variant="caption">
            <Box color="info.dark" fontWeight="bold">
              {disclaimer}
            </Box>
          </Typography>
        </>
      )}
    </BaseCell>
  )
}

export default React.memo(DualCell)
