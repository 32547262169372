import { Box, Theme, makeStyles } from '@material-ui/core'

import React from 'react'
import Skeleton from 'components/Skeleton'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 900,

    '& .MuiTypography-body2': {
      lineHeight: 1.4,
    },
    '& .MuiTypography-h6': {
      lineHeight: 1.3,
    },
  },
  item: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      '2px 3px 5px -4px rgba(0,0,0,0.2), 2px 3px 6px -2px rgba(0,0,0,0.14), 2px 2px 9px -10px rgba(0,0,0,0.12)',
  },
  row: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  cell: {
    // The value that guarantees both left and right have the same height with minimum content.
    // After that `flex` ensures they match heights.
    minHeight: 58,
    padding: '2px 25px 2px 20px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
  },
  overview: {
    minWidth: 220,
  },
  gutter: {
    marginTop: `${theme.spacing(0.5)}px !important`,
    marginBottom: `${theme.spacing(0.5)}px !important`,
  },
  details: {
    marginLeft: 30,
    minWidth: 300,
  },

  activityCell: {
    paddingLeft: 20,
    paddingRight: 24,
  },
}))

interface EntityPageHeaderSkeletonProps {
  isAnimated?: boolean
  classes?: Record<string, string>
}

/**
 * We take the basic DOM layout from the components we want add skeletons for because that gives us the correct layout.
 */
const EntityPageHeaderSkeleton = (props: EntityPageHeaderSkeletonProps) => {
  const { isAnimated = true } = props
  const classes = useStyles(props)
  const detailSectionHeight = 16

  return (
    // Container boxes are from `EntityPageHeader`
    <Box className={classes.root}>
      <Box data-testid="page-header" className={classes.root}>
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          {/* `Overview` */}
          <Box
            className={classnames(classes.item, classes.overview)}
            data-testid="page-header__overview"
            display="flex"
            flexDirection="column"
          >
            {/* Top row with ref type and number */}
            <Box className={classnames(classes.row, classes.cell, classes.flexColumn)}>
              <Skeleton
                className={classes.gutter}
                isAnimated={isAnimated}
                width="100px"
                height={detailSectionHeight}
              />
              <Skeleton
                className={classes.gutter}
                isAnimated={isAnimated}
                width="140px"
                height={detailSectionHeight}
              />
            </Box>
            {/* Second row with carrier and containers */}
            <Box display="flex" flexDirection="row" flexWrap="nowrap" flexGrow="1">
              <Box className={classnames(classes.cell, classes.flexColumn, classes.borderRight)}>
                {' '}
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="40px"
                  height={detailSectionHeight}
                />
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="62px"
                  height={detailSectionHeight}
                />
              </Box>
              <Box className={classnames(classes.cell, classes.flexColumn)} flexGrow="1">
                {' '}
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="62px"
                  height={detailSectionHeight}
                />
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="40px"
                  height={detailSectionHeight}
                />
              </Box>
            </Box>
          </Box>
          {/* `Status` */}
          <Box
            className={classnames(classes.item, classes.details)}
            data-testid="page-header__details"
          >
            <Box
              className={classnames(classes.row, classes.cell)}
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
            >
              <Skeleton
                className={classes.gutter}
                isAnimated={isAnimated}
                width="140px"
                height={40}
              />
              <Box className={classnames(classes.flexColumn, classes.activityCell)}>
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="222px"
                  height={detailSectionHeight}
                />
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="294px"
                  height={detailSectionHeight}
                />
              </Box>
            </Box>
            <Box
              className={classes.cell}
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
            >
              <Box className={classes.flexColumn} pr={2.5}>
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="83px"
                  height={detailSectionHeight}
                />
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="123px"
                  height={detailSectionHeight}
                />
              </Box>
              <Box className={classes.flexColumn} pr={3}>
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="127px"
                  height={detailSectionHeight}
                />
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="165px"
                  height={detailSectionHeight}
                />
              </Box>
              <Box>
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="200px"
                  height={detailSectionHeight}
                />
                <Skeleton
                  className={classes.gutter}
                  isAnimated={isAnimated}
                  width="200px"
                  height={detailSectionHeight}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EntityPageHeaderSkeleton
