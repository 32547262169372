import React from 'react'
import { SvgIcon } from '@material-ui/core'

const DragHandle = props => (
  <SvgIcon {...props} viewBox={'0 0 24 24'}>
    <g fill="#C2C8CC" fillRule="nonzero">
      <circle cx={2} cy={2} r={2} />
      <circle cx={11} cy={2} r={2} />
      <circle cx={2} cy={11} r={2} />
      <circle cx={11} cy={11} r={2} />
      <circle cx={2} cy={20} r={2} />
      <circle cx={11} cy={20} r={2} />
    </g>
  </SvgIcon>
)

export default DragHandle
