import { CircularProgress, IconButton, Typography, withStyles } from '@material-ui/core'
import {
  uploadOrders,
  uploadOrdersErrorSelector,
  uploadOrdersLoadingSelector,
  uploadOrdersSuccessSelector,
} from 'store/orderUpload'

import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { currentTenantFromState } from 'store/auth/user/selectors'
import logger from 'utils/logger'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
  },
  header: {
    alignSelf: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(8),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: 40,
    paddingTop: 100,
    background: '#2e3e55',
    borderRadius: 5,
    fontSize: 10,
    borderStyle: 'dotted',
    border: '3px solid white',
    textAlign: 'center',
    color: '#fff',
    cursor: 'pointer',
  },
  dropzone: {
    width: 500,
    height: 300,
  },
  mainText: {
    fontSize: 24,
    color: 'white',
  },
  dropzoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  uploadButton: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.brand.color,
  },
  disabledButton: {
    opacity: 0.7,
  },
  readyForUpload: {
    marginTop: theme.spacing(3),
  },
  buttonLabel: {
    textTransform: 'none',
  },
  enabledButtonLabel: {
    color: 'white',
  },
  errorText: {
    marginTop: theme.spacing(3),
    color: '#f01e23',
    fontSize: 22,
  },
  successText: {
    marginTop: theme.spacing(1),
    color: 'green',
    fontSize: 24,
  },
  rejectHeader: {
    width: 500,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
  },
  fileListItem: {
    width: 300,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    border: `2px solid ${theme.palette.grey[800]}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileName: {
    fontSize: 15,
  },
  flexCenter: {
    width: 800,
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  supportTeam: {
    borderRadius: 2,
    padding: 5,
    fontSize: 18,
    backgroundColor: theme.palette.grey[500],
    color: 'white',
    cursor: 'pointer',
  },
  loader: {
    marginTop: theme.spacing(2),
  },
})

const ACCEPTED_FILES = [
  '.csv', // CSV
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel Files 2007+
]

const MAX_SIZE = 3000000 // 3 MB

const BYTES_TO_MB = 1000000

const FileListItem = ({ file, classes, handleClearClick }) => {
  const mbSize = file.size / BYTES_TO_MB
  return (
    <div key={file.name} className={classes.fileListItem}>
      <Typography variant="body2" className={classes.fileName}>
        {file.name} - {mbSize} MB
      </Typography>
      <IconButton onClick={handleClearClick}>
        <CloseIcon />
      </IconButton>
    </div>
  )
}

class OrderUploadPage extends React.Component {
  constructor() {
    super()
    this.state = {
      accepted: [],
      rejected: [],
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentTenantFromState: PropTypes.string.isRequired,
  }

  onDrop = (accepted, rejected) => {
    this.setState({
      accepted,
      rejected,
    })
  }

  handleUpload = () => {
    this.state.accepted.forEach(file => {
      const reader = new FileReader()
      reader.onload = e => {
        let binary = ''
        const bytes = new Uint8Array(e.target.result)
        const length = bytes.byteLength
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        this.props.uploadOrder(file, binary)
        this.setState({
          accepted: [],
          rejected: [],
        })
      }
      reader.onabort = () => logger.allEnvLog('file reading was aborted')
      reader.onerror = () => logger.allEnvLog('file reading has failed')
      reader.readAsArrayBuffer(file)
    })
  }

  handleClearClick = () => {
    this.setState({
      accepted: [],
      rejected: [],
    })
  }

  openIntercom = () => {
    window.Intercom('show')
    logger.notify('User Initiated Chat', { initiatedFrom: 'order_upload' })
  }

  render() {
    const { classes, postRequestLoading, error, success, currentTenant } = this.props
    const acceptedFilesExist = this.state.accepted.length > 0
    const rejectedFilesExist = this.state.rejected.length > 0
    const uploadButtonDisabled = !acceptedFilesExist
    const buttonClasses = classnames(classes.uploadButton, {
      [classes.disabledButton]: uploadButtonDisabled,
    })
    const buttonLabelClasses = classnames(classes.buttonLabel, {
      [classes.enabledButtonLabel]: !uploadButtonDisabled,
    })
    return (
      <main className={classes.container}>
        <header className={classes.header}>
          <Typography variant="body2">Current Tenant:</Typography>
          <Typography variant="h2">{currentTenant}</Typography>
        </header>
        <Dropzone
          onDrop={this.onDrop}
          className={classes.dropzone}
          accept={ACCEPTED_FILES}
          maxSize={MAX_SIZE}
        >
          <div className={classes.overlay}>
            <Typography variant="body2" className={classes.mainText}>
              Drop your orders file here, or click to select a file to upload.
            </Typography>
          </div>
        </Dropzone>
        {error && !acceptedFilesExist && !rejectedFilesExist && (
          <Typography variant="body2" className={classes.errorText}>
            Error uploading file. Please contact our{' '}
            <span className={classes.supportTeam} onClick={this.openIntercom}>
              Support Team
            </span>{' '}
            for help
          </Typography>
        )}
        {success && !acceptedFilesExist && !rejectedFilesExist && (
          <Typography variant="body2" className={classes.successText}>
            Successfully uploaded orders
          </Typography>
        )}

        <Button
          disabled={!acceptedFilesExist}
          classes={{ root: buttonClasses, label: buttonLabelClasses }}
          onClick={this.handleUpload}
        >
          Upload File
        </Button>
        {postRequestLoading && (
          <div className={classes.flexCenter}>
            <div className={classes.loaderContainer}>
              <Typography variant="body2">Uploading... this can take up to 30 seconds</Typography>
              <CircularProgress className={classes.loader} />
            </div>
          </div>
        )}
        {acceptedFilesExist && (
          <div className={classes.readyForUpload}>
            <h2>File ready for upload</h2>
            {this.state.accepted.map((f, idx) => (
              <FileListItem
                key={`rejected-${idx}`}
                file={f}
                classes={classes}
                handleClearClick={this.handleClearClick}
              />
            ))}
          </div>
        )}
        {rejectedFilesExist && (
          <div className={classes.readyForUpload}>
            <Typography variant="body2" className={classes.rejectHeader}>
              {`We cannot accept the file listed below. Please make sure it's either a .csv or .xlsx file and that it is less than ${
                MAX_SIZE / BYTES_TO_MB
              } MB`}
            </Typography>
            {this.state.rejected.map((f, idx) => (
              <FileListItem
                key={`rejected-${idx}`}
                file={f}
                classes={classes}
                handleClearClick={this.handleClearClick}
              />
            ))}
          </div>
        )}
      </main>
    )
  }
}

const mapDispatchToProps = {
  uploadOrder: uploadOrders,
}

const mapStateToProps = state => {
  return {
    currentTenant: currentTenantFromState(state),
    error: uploadOrdersErrorSelector(state),
    postRequestLoading: uploadOrdersLoadingSelector(state),
    success: uploadOrdersSuccessSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderUploadPage))
