import { all, call, put, takeEvery } from 'redux-saga/effects'

import client from 'utils/api/client'
import { createAction } from 'redux-actions'
import logger from 'utils/logger'

// ------------------------------------
// Constants
// ------------------------------------
const API_KEYS_LOAD = 'api/api_keys/API_KEYS_LOAD'
const API_KEYS_ERROR = 'api/api_keys/API_KEYS_ERROR'
const API_KEYS_SUCCESS = 'api/api_keys/API_KEYS_SUCCESS'
const CREATE_API_KEY = 'api/api_keys/CREATE_API_KEY'

export const API_KEYS_REDUCER_KEY = 'api_keys'

// ------------------------------------
// Actions
// ------------------------------------
export const getApiKeys = createAction(API_KEYS_LOAD, tenant => tenant)
export const generateApiToken = createAction(CREATE_API_KEY, tenant => tenant)

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [API_KEYS_LOAD]: (state, action) => {
    return { ...state, isLoading: true }
  },
  [API_KEYS_ERROR]: (state, action) => {
    return { ...state, error: true, isLoading: false }
  },
  [API_KEYS_SUCCESS]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.data }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  error: false,
  isLoading: false,
}

export function apiKeyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Selectors
// ------------------------------------

export const apiKeyErrorSelector = state => state[API_KEYS_REDUCER_KEY].error
export const apiKeyLoadingSelector = state => state[API_KEYS_REDUCER_KEY].isLoading
export const apiKeysSelector = state => state[API_KEYS_REDUCER_KEY].data

// ------------------------------------
// Saga
// ------------------------------------
function* apiKeysAsync(action) {
  try {
    const tenant = action.payload
    let path = `/api_keys?tenant=${tenant}`
    if (tenant && tenant === 'super') {
      path = '/api_keys'
    }
    const response = yield call(client.get, path)
    yield put({ type: API_KEYS_SUCCESS, payload: response.data })
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: API_KEYS_ERROR })
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

function* generateApiKeyAsync(action) {
  try {
    const tenant = action.payload
    yield call(client.post, 'api_keys', { tenant: tenant })
    const response = yield call(client.get, `/api_keys?tenant=${tenant}`)
    yield put({ type: API_KEYS_SUCCESS, payload: response.data })
  } catch (e) {
    logger.captureAPIException(e)
    yield put({ type: API_KEYS_ERROR })
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
  }
}

export function* watchApiKeysLoad() {
  yield all([
    yield takeEvery(API_KEYS_LOAD, apiKeysAsync),
    yield takeEvery(CREATE_API_KEY, generateApiKeyAsync),
  ])
}
