import { ALERTS_DRAWER_KEY as DRAWER_KEY, drawersSelector } from 'store/drawers'
import React, { PureComponent } from 'react'

import AlertsList from './AlertsList'
import Drawer from 'components/core/Drawer'
import PropTypes from 'prop-types'
import { alertConfigsSelector } from 'store/api/alertConfigs'
import { connect } from 'react-redux'
import { getAlerts } from 'store/api/alertConfigs'
import { toggleDrawer } from 'store/drawers/actions'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  container: {
    height: '100%',
  },
  docked: {
    height: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px 0 16px',
    ...theme.mixins.toolbar,
    borderTop: 'none',
    borderBottom: `1px solid ${theme.brand.lightDivider}`,
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    alignSelf: 'center',
  },
  divider: {
    width: `calc(100% - ${theme.spacing(4)}px)`,
    marginLeft: theme.spacing(2),
    backgroundColor: '#2A3547',
    alignSelf: 'center',
  },
  hideText: {
    fontSize: 13,
    marginBottom: 1,
  },
  createAlertButton: {
    alignSelf: 'center',
    width: 120,
  },
  createAlertLabel: {
    textTransform: 'none',
    color: theme.brand.color,
  },
  invisible: {
    display: 'none',
  },
})

class AlertsDrawer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    activeSection: PropTypes.string,
    classes: PropTypes.object.isRequired,
  }

  state = {
    hasError: false,
  }

  componentDidMount() {
    this.props.getAlerts()
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { classes, isOpen, alertConfigs, toggleDrawer } = this.props
    if (alertConfigs.length === 0) {
      return null
    }
    return (
      <Drawer
        isOpen={isOpen}
        onToggleDrawer={() => toggleDrawer(DRAWER_KEY, !isOpen)}
        variant="permanent"
        classes={{ docked: classes.docked }}
      >
        <AlertsList />
      </Drawer>
    )
  }
}

const mapDispatchToProps = {
  getAlerts,
  toggleDrawer,
}

const mapStateToProps = state => {
  return {
    isOpen: drawersSelector(state)[DRAWER_KEY],
    alertConfigs: alertConfigsSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertsDrawer))
