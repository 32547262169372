import { COMMA, ENTER, SPACE } from 'utils/syntheticEvents'
import { CircularProgress, Typography, makeStyles } from '@material-ui/core'
import {
  PORTAL_USERS_AUTOCOMPLETE_KEY,
  clearAutocompleteFilters,
  setAutocompleteInput,
} from 'store/autocomplete/actions'
import React, { useEffect } from 'react'
import {
  createPortalUsers,
  resetForm,
  setEmailInput,
  setEmailValue,
  setFilterInputOptions,
  setSelectedChipId,
} from 'store/portalUsers/actions'
import {
  getEmailValue,
  getFilterFields,
  getValidEmailStrings,
  invalidInput,
  isSubmittable,
} from './utils'
import {
  portalAddUsersFormSelector,
  portalFilterInputOptionsSelector,
} from 'store/portalUsers/selectors'
import { useDispatch, useSelector } from 'react-redux'

import Autocomplete from 'components/Autocomplete'
import Button from 'components/core/Button'
import ChipInput from 'components/core/ChipInput'
import { buildCustomFilter } from 'store/customShipmentFilters'
import { customSuggestSelector } from 'store/suggest'
import cx from 'classnames'
import { fetchCustomSuggest } from 'store/suggest/actions'
import flatten from 'lodash/flatten' // IE11 does not support Array.flat()
import { getCustomerTags } from 'store/auth/user/selectors'
import { setFilterValue } from 'store/portalUsers/actions'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.spacing(100),
    paddingBottom: theme.spacing(50),
  },
  copy: {
    paddingTop: theme.spacing(1.5),
  },
  title: {
    color: theme.palette.blue[800],
  },
  input: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  clearFormButton: {
    alignSelf: 'flex-end',
  },
}))

function AddUsers() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const SYNTHETIC_EVENTS = [COMMA, ENTER, SPACE]

  const { submitting, emailInput, emailValue, selectedChipId, filterValues } = useSelector(
    portalAddUsersFormSelector
  )
  const filterInputOptions = useSelector(portalFilterInputOptionsSelector)
  const customerTags = useSelector(getCustomerTags) // AKA custom fields

  const hasEmailValue = emailValue.length > 0
  const hasFilterValues = Boolean(flatten(Object.values(filterValues)).length)
  const isFormResettable = !(hasEmailValue || hasFilterValues)
  const isFormSubmitable = isSubmittable(emailValue, filterValues)

  useEffect(() => {
    return () => {
      dispatch(clearAutocompleteFilters(PORTAL_USERS_AUTOCOMPLETE_KEY))
    }
  }, [dispatch])

  useEffect(() => {
    if (customerTags.length > 0) {
      const customFilters = customerTags.map(tag => buildCustomFilter(tag))
      dispatch(setFilterInputOptions(customFilters))
    }
  }, [customerTags, dispatch])

  function handleAddEmailValueChange(event) {
    event.preventDefault()
    const input = event.target.value

    // Return if string contains only spaces
    if (invalidInput(input)) {
      return
    } else {
      dispatch(setEmailValue([...emailValue, ...getEmailValue(input)]))
      dispatch(setEmailInput(''))
    }
  }

  function handleEmailInputChange(event) {
    const input = event.target.value
    // Only store non-delimeter input
    if (SYNTHETIC_EVENTS.includes(input)) {
      return
    } else {
      dispatch(setEmailInput(input))
    }
  }

  function handleChipDelete(index) {
    dispatch(setEmailValue(emailValue.filter((_, valIndex) => valIndex !== index)))
  }

  function handleEditChip(id, value) {
    dispatch(setEmailInput(value.label))
    handleChipDelete(id)
  }

  function handleChipSelect(id) {
    const selectedValue = emailValue[id]
    const isSecondClick = selectedChipId === id
    const isSelectionInvalid = !selectedValue.valid

    if (isSelectionInvalid || isSecondClick) {
      handleEditChip(id, selectedValue)
    } else {
      dispatch(setSelectedChipId(id))
    }
  }

  const handleAutocompleteChange = data => {
    const { filter, value } = data
    if (!value) {
      return
    }
    dispatch(setFilterValue({ value, filter }))
    dispatch(
      setAutocompleteInput({ value: '', filter, autocompleteKey: PORTAL_USERS_AUTOCOMPLETE_KEY })
    )
  }

  const clearForm = () => {
    dispatch(resetForm())
    dispatch(clearAutocompleteFilters(PORTAL_USERS_AUTOCOMPLETE_KEY))
  }

  return (
    <div className={classes.container}>
      <Button
        className={classes.clearFormButton}
        variant="text"
        color="default"
        disabled={isFormResettable}
        onClick={clearForm}
      >
        Clear Form
      </Button>
      <Typography className={cx(classes.copy, classes.title)} component="h1" variant="h6">
        Add Portal Users
      </Typography>
      <Typography className={classes.copy} color="textSecondary" gutterBottom>
        Portal users are trusted partners outside of your company. They’ll have permissioned access
        to your company’s data. Only Admins can add and remove portal users. To get started, enter
        one or more emails separated by commas below.
      </Typography>
      <form
        onSubmit={event => {
          event.preventDefault()
          dispatch(
            createPortalUsers({
              emails: getValidEmailStrings(emailValue),
              fields: getFilterFields(filterValues, filterInputOptions),
            })
          )
        }}
        id="addUsers"
        autoComplete="off"
      >
        {/* Email start */}
        <ChipInput
          className={classes.input}
          chipConfigs={emailValue}
          newChipSyntheticKeyCodes={SYNTHETIC_EVENTS}
          onAddRequest={handleAddEmailValueChange}
          onBlur={handleAddEmailValueChange}
          onChange={handleEmailInputChange}
          onChipSelect={handleChipSelect}
          onDeleteRequest={handleChipDelete}
          placeholder="Email(s)"
          value={emailInput}
          InputProps={{ fullWidth: true }}
        />
        {/* Email end */}
        {/* Custom filter start */}
        <Typography className={cx(classes.copy, classes.title)} component="h2" variant="h6">
          Select the Data They’ll See
        </Typography>
        <Typography className={classes.copy} color="textSecondary" gutterBottom>
          Select at least one option from any of the fields below. Portal users will be able to see
          shipments matching the options you choose here. Empty fields will not be visible to portal
          users.
        </Typography>
        {filterInputOptions.map((filter, index) => (
          <React.Fragment key={`${filter.name}-${index}`}>
            <Autocomplete
              fetchDataAction={filterInput => {
                return fetchCustomSuggest({
                  lookupPath: filter.filterData.lookupPath,
                  q: filterInput,
                  customKey: filter.name,
                })
              }}
              fetchDataSelector={state => customSuggestSelector(state, filter.name)}
              freeSolo={false}
              autocompleteKey={PORTAL_USERS_AUTOCOMPLETE_KEY}
              filter={filter}
              className={classes.input}
              onAutocompleteChange={data => handleAutocompleteChange(data)}
            />
          </React.Fragment>
        ))}
        {/* Custom filter end */}
        <Button
          className={classes.submitButton}
          form="addUsers"
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isFormSubmitable || submitting}
          endIcon={submitting && <CircularProgress color="inherit" size={20} />}
        >
          {submitting ? 'Submitting' : 'Add to Portal'}
        </Button>
      </form>
    </div>
  )
}

export default AddUsers
