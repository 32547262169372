import { Metric, MetricNames } from '../utils/Metric'
import { call, put, select } from 'redux-saga/effects'
import {
  flattenRouteGroupIds,
  routeComparisonMetricsSelector,
  routeGroupSelector,
  selectedRouteIdsSelector,
} from 'store/planning'

import { PLANNING_FILTER_KEY } from 'store/filterGroups/actions'
import { ROUTE_COMPARE_LIMIT } from '../utils/RouteComparison'
import { Roll } from '../utils/Roll'
import { RouteGroup } from '../utils/RouteGroup'
import api from './api'
import { filterGroupSelectors } from 'store/filterGroups'
import get from 'lodash/get'
import { getPopulatedDateRange } from '../utils/DateRange'
import humps from 'humps'
import isEmpty from 'lodash/isEmpty'
import logger from 'utils/logger'
import { types } from 'store/planning/actions'

const getResponseData = resp => humps.camelizeKeys(resp.data)

const selectors = filterGroupSelectors[PLANNING_FILTER_KEY]

function* makePopulatedDateRange() {
  const dateRange = yield select(selectors.dateRangeSelector)
  return getPopulatedDateRange(dateRange)
}

const isMetricsEmpty = metrics => !metrics || (isEmpty(metrics.durations) && isEmpty(metrics.rolls))

const transformMetrics = metrics => ({
  durations: metrics.durations.map(Metric.of),
  rolls: metrics.rolls.map(Roll.of),
})

function transformGroupedMetrics(routeMetricsData) {
  return routeMetricsData.reduce((memo, groupMetrics) => {
    if (!isMetricsEmpty(groupMetrics)) {
      const key = groupMetrics.routeIds.join('-')
      memo[key] = transformMetrics(groupMetrics)
    }
    return memo
  }, {})
}

function* fetchFilteredGroupedRoutes(filters, groupByFields, startTime, endTime, metricNames) {
  const queryFilters = filters.map(filter => {
    return {
      key: filter.key,
      operator: filter.operator,
      val: filter.val.map(filterValue => filterValue.key),
      orGroup: filter.orGroup ? filter.orGroup : undefined,
    }
  })
  return getResponseData(
    yield call(api.fetchRoutes, queryFilters, groupByFields, startTime, endTime, metricNames)
  ).map(RouteGroup.of)
}

// Load all routes
function* fetchRoutes() {
  const filterGroup = yield select(selectors.currentFilterGroupSelector)
  const { startTime, endTime } = yield makePopulatedDateRange()
  const metricNames = [MetricNames.DURATION]

  try {
    const routesData = yield fetchFilteredGroupedRoutes(
      get(filterGroup, 'params.filters', []),
      ['originLocation', 'destLocation'],
      startTime,
      endTime,
      metricNames
    )
    yield put({
      type: types.FETCH_ROUTES_SUCCESS,
      payload: routesData,
    })
    return routesData
  } catch (e) {
    yield put({
      type: types.FETCH_ROUTES_ERROR,
    })
  }
}

function* _fetchRoutesMetrics(groupedRouteIds) {
  const { startTime, endTime } = yield makePopulatedDateRange()

  if (isEmpty(groupedRouteIds)) {
    return []
  }

  const metricNames = [MetricNames.DURATION, MetricNames.ROLL]
  const routeMetricsData = getResponseData(
    yield call(api.fetchMetricsForRoutes, groupedRouteIds, { startTime, endTime, metricNames })
  )
  return transformGroupedMetrics(routeMetricsData)
}

function* fetchMetricsForAllSelectedRoutes() {
  try {
    yield put({ type: types.FETCH_ALL_ROUTE_METRICS_START })
    const routeIds = yield select(selectedRouteIdsSelector)
    if (!routeIds.size) {
      yield put({ type: types.FETCH_ALL_ROUTE_METRICS_SUCCESS, payload: {} })
      return
    }

    const { startTime, endTime } = yield makePopulatedDateRange()

    const metricNames = [MetricNames.DURATION, MetricNames.ROLL]

    const routeMetricsResponse = getResponseData(
      yield call(api.fetchMetricsForRoutes, [[...routeIds]], { startTime, endTime, metricNames })
    )
    const routeMetrics = routeMetricsResponse[0]
    const payload = transformMetrics(routeMetrics)
    yield put({
      type: types.FETCH_ALL_ROUTE_METRICS_SUCCESS,
      payload,
    })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_ALL_ROUTE_METRICS_ERROR })
  }
}

function* fetchAggregatedMetricsForAllSelectedRoutes() {
  yield put({ type: types.FETCH_ALL_ROUTE_AGGREGATED_METRICS_START })

  try {
    const routeIds = yield select(selectedRouteIdsSelector)
    if (!routeIds.size) {
      yield put({ type: types.FETCH_ALL_ROUTE_AGGREGATED_METRICS_SUCCESS, payload: {} })
      return
    }

    const { startTime, endTime, aggregation, aggregationLength } = yield makePopulatedDateRange()

    const metricNames = [MetricNames.DURATION]

    const aggregatedLaneMetricsData = getResponseData(
      yield call(api.fetchMetricsForRoutes, [[...routeIds]], {
        startTime,
        endTime,
        aggregation,
        aggregationLength,
        metricNames,
      })
    )

    const payload = transformMetrics(aggregatedLaneMetricsData[0])
    yield put({
      type: types.FETCH_ALL_ROUTE_AGGREGATED_METRICS_SUCCESS,
      payload,
    })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_ALL_ROUTE_AGGREGATED_METRICS_ERROR })
  }
}

function* fetchRouteComparisonMetricsOnSelectRouteId() {
  const selectedRouteIds = yield select(selectedRouteIdsSelector)
  const currentMetrics = yield select(routeComparisonMetricsSelector)

  // Don't reload if all the selected metrics are already in the
  // store. Note that we don't pop off any extra metrics; the
  // graph itself is responsible for only displaying the selected metrics.
  // This makes it fast to deselect/reselect routes and immediately update
  // the graph.
  if (![...selectedRouteIds].every(routeId => routeId in currentMetrics)) {
    yield put({ type: types.FETCH_ROUTE_METRICS_COMPARISON_START })
    const selectedRouteIds = yield select(selectedRouteIdsSelector)
    if (selectedRouteIds.size === 0 || selectedRouteIds.size > ROUTE_COMPARE_LIMIT) {
      yield put({ type: types.FETCH_ROUTE_METRICS_COMPARISON_ERROR })
      return
    }

    try {
      const { startTime, endTime, aggregation } = yield makePopulatedDateRange()
      const metricNames = [MetricNames.DURATION]
      const routeMetrics = getResponseData(
        yield call(
          api.fetchMetricsForRoutes,
          [...selectedRouteIds].map(id => [id]),
          {
            startTime,
            endTime,
            aggregation,
            metricNames,
          }
        )
      )
      const payload = transformGroupedMetrics(routeMetrics)
      yield put({ type: types.FETCH_ROUTE_METRICS_COMPARISON_SUCCESS, payload })
    } catch (e) {
      logger.error(e)
      logger.captureAPIException(e)
      yield put({ type: types.FETCH_ROUTE_METRICS_COMPARISON_ERROR })
    }
  } else {
    yield put({ type: types.FETCH_ROUTE_METRICS_COMPARISON_ERROR })
  }
}

export const populateWithMetrics = (routeGroups, metrics) => {
  const groups = []
  routeGroups.forEach(routeGroup => {
    const groupMetrics = metrics[routeGroup.id]

    // Each route/routeGroupMetric contains 1 duration/roll which represents
    // that metric rolled up over the query timeframe  That's why picking off
    // the first one here (and below) is sufficient.
    const routes = []
    routeGroup.routes.forEach(route => {
      const routeMetrics = metrics[route.routeId]
      const durationMetric = get(routeMetrics, 'durations[0]', {})
      const rollMetric = get(routeMetrics, 'rolls[0]', {})
      if (Metric.isValid(durationMetric)) {
        routes.push({ ...route, metrics: { duration: durationMetric, roll: rollMetric } })
      }
    })
    const groupDurationMetric = get(groupMetrics, 'durations[0]', {})
    const groupRollMetric = get(groupMetrics, 'rolls[0]', {})
    if (routes.length > 0) {
      groups.push({
        ...routeGroup,
        routes,
        metrics: { duration: groupDurationMetric, roll: groupRollMetric },
      })
    }
  })
  return groups
}

function* fetchMetricsForRouteGroups() {
  try {
    const rawRouteGroups = yield select(routeGroupSelector)
    const routeIds = flattenRouteGroupIds(rawRouteGroups).map(id => [id])
    const groupedRouteIds = rawRouteGroups.map(group => group.routes.map(route => route.routeId))
    const individualMetrics = yield _fetchRoutesMetrics(routeIds)
    const groupMetrics = yield _fetchRoutesMetrics(groupedRouteIds)
    const metrics = { ...individualMetrics, ...groupMetrics }
    const routeGroups = populateWithMetrics(rawRouteGroups, metrics)
    yield put({ type: types.FETCH_ROUTES_WITH_METRICS_SUCCESS, payload: { routeGroups, metrics } })
  } catch (e) {
    logger.error(e)
    logger.captureAPIException(e)
    yield put({ type: types.FETCH_ROUTES_WITH_METRICS_ERROR })
  }
}

export {
  fetchRoutes,
  fetchFilteredGroupedRoutes,
  fetchAggregatedMetricsForAllSelectedRoutes,
  fetchMetricsForAllSelectedRoutes,
  fetchRouteComparisonMetricsOnSelectRouteId,
  fetchMetricsForRouteGroups,
}
