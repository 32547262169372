import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core'
import { fetchCustomization, updateCustomization } from 'store/customization'

import AdminButton from './AdminButton'
import Button from 'components/core/Button'
import CustomizationIcon from '@material-ui/icons/SettingsOutlined'
import ListTenants from './ListTenants'
import React from 'react'
import { connect } from 'react-redux'
import { currentTenantFromState } from 'store/auth/user/selectors'
import lazyImport from 'utils/lazyImport'
import lazyLoadWithSuspense from 'utils/lazyLoadWithSuspense'

// load react-json-view lazily in order to move ~150 KB uncompressed JS out of the main bundle
const getJSONEditor = lazyImport(() => import('react-json-view'))
const LazyJSONEditor = lazyLoadWithSuspense(getJSONEditor)

const styles = theme => ({
  root: {},
  form: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
})

const TenantChooser = ({ onSelect }) => {
  return <ListTenants tenantClick={onSelect} />
}

class Customization extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedTenant: !props.superTenant ? props.currentTenant : null, // only set selectedTenant if user is not acting as "super"
      currentValue: props.customizationData || {},
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.customizationData !== prevProps.customizationData) {
      this.setState({ currentValue: this.props.customizationData })
    }
  }

  componentDidMount() {
    if (this.state.selectedTenant) {
      this.props.fetchCustomization(this.state.selectedTenant)
    }
  }

  toggleDialog = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleSubmit = () => {
    this.props.updateCustomization(this.state.selectedTenant, this.state.currentValue)
  }

  handleChange = e => {
    this.setState({ currentValue: e.updated_src })
  }

  handleTenantSelect = tenant => {
    this.setState({
      selectedTenant: tenant,
    })
    this.props.fetchCustomization(tenant)
  }

  renderCustomization = () => {
    const { isLoading } = this.props
    const { currentValue, selectedTenant } = this.state
    if (isLoading) {
      return <Typography variant="body2">Loading Customization...</Typography>
    } else if (selectedTenant) {
      return (
        <LazyJSONEditor
          name={null}
          theme="monokai"
          src={currentValue}
          onEdit={this.handleChange}
          onAdd={this.handleChange}
          onDelete={this.handleChange}
        />
      )
    }
  }

  renderTenant = () => {
    const { selectedTenant } = this.state
    const { superTenant } = this.props
    if (selectedTenant) {
      return (
        <React.Fragment>
          <Typography variant="body2">
            Tenant customization for a tenant: <strong>{selectedTenant}</strong>.
          </Typography>
          {!superTenant && (
            <Typography variant="body2" style={{ color: 'red' }}>
              You must be acting under the <b>super</b> tenant to update any tenant's customization
            </Typography>
          )}
        </React.Fragment>
      )
    } else {
      return <Typography variant="body2">Please select a tenant</Typography>
    }
  }

  render() {
    const { classes, superTenant } = this.props
    const { isOpen, selectedTenant } = this.state
    return (
      <div className={classes.root}>
        <AdminButton
          onClick={this.toggleDialog}
          text={'Tenant Customization'}
          icon={<CustomizationIcon />}
        />
        <Dialog open={isOpen} onClose={this.toggleDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Tenant Customization</DialogTitle>
          <DialogContent>
            {this.renderTenant()}
            <div className={classes.form}>
              {superTenant && <TenantChooser onSelect={this.handleTenantSelect} />}
              {this.renderCustomization()}
            </div>
          </DialogContent>
          <DialogActions>
            {selectedTenant && superTenant && (
              <React.Fragment>
                <Button onClick={this.toggleDialog}>Cancel</Button>
                <Button onClick={this.handleSubmit} variant="outlined" color="primary">
                  Save Customization
                </Button>
              </React.Fragment>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const currentTenant = currentTenantFromState(state)
  return {
    customizationData: state.customization.data,
    isLoading: state.customization.loading,
    error: state.customization.error,
    currentTenant,
    superTenant: currentTenant === 'super',
  }
}

const dispatchToProps = {
  fetchCustomization,
  updateCustomization,
}

export default connect(mapStateToProps, dispatchToProps)(withStyles(styles)(Customization))
