import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { types } from './actions'

export { AUTH_REDUCER_KEY }

export const USER_ACTION_HANDLERS = {
  [types.USER_LOAD]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      loading: true,
    },
  }),
  [types.USER_FAILURE]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      awaitingAuthCheck: false,
      loading: false,
    },
  }),
  [types.USER_SUCCESS]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      loading: false,
      awaitingAuthCheck: false,
      info: action.payload,
    },
  }),
  [types.VALIDATE_JWT_LOAD]: (state, action) => ({
    ...state,
    jwt: {
      ...state.jwt,
      loading: true,
      valid: false,
    },
  }),
  [types.VALIDATE_JWT_SUCCESS]: (state, action) => ({
    ...state,
    jwt: {
      ...state.jwt,
      loading: false,
      valid: true,
    },
  }),
  [types.VALIDATE_JWT_FAILURE]: (state, action) => ({
    ...state,
    jwt: {
      ...state.jwt,
      loading: false,
      valid: false,
    },
  }),
}
