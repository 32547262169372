import P44Logo from 'assets/p44-logo-full-color.png'
import get from 'lodash/get'
import tracker from 'utils/logger/tracker'

let image

export const getHighChartAxisTitleDefaults = theme => ({
  style: {
    ...theme.typography.caption,
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightBold,
  },
})

export const getHighChartDefaults = theme => ({
  chart: {
    height: 'inherit',
    backgroundColor: theme.palette.white,
    events: {
      load: function () {
        if (this.options.chart.forExport) {
          this.renderer.image(P44Logo, 0, 375, '10%', '6%').add()
          let chartTitle = get(this, 'userOptions.title.text')
          if (chartTitle.startsWith('Completed Container Trip Performance:')) {
            // Strip off the <span> that's in the container trip performance title.
            chartTitle = 'Completed Container Trip Performance'
          }
          tracker.dashboard.userDownloadsChart(chartTitle)
        }
      },
      beforePrint: function () {
        this.prePrintWasHidden = this.title.styles.visibility === 'hidden'
        this.setTitle({
          style: {
            visibility: 'visible',
          },
        })
        image = this.renderer.image(P44Logo, 0, 375, '10%', '6%').add()
        this.print()
      },
      afterPrint: function () {
        this.setTitle({
          style: {
            visibility: this.prePrintWasHidden ? 'hidden' : 'visible',
          },
        })
        image.element.remove()
      },
    },
  },
  tooltip: {
    backgroundColor: theme.palette.grey[100],
    shadow: false,
    borderColor: null,
    borderRadius: 0,
    borderWidth: 1,
    style: {
      color: theme.palette.grey[800],
      fontSize: theme.typography.body2.fontSize,
      opacity: 0.9,
      wordWrap: 'break-word',
      width: 250,
    },
    useHTML: true,
  },
  plotOptions: {
    series: {
      dataLabels: {
        style: {
          color: theme.palette.grey[800],
          fontWeight: theme.typography.fontWeightBold,
          fontSize: theme.typography.body2.fontSize,
        },
      },
      events: {
        legendItemClick: function () {
          return false
        },
        mouseOver: function () {
          let chartTitle = get(this, 'chart.title.textStr')
          if (chartTitle.startsWith('Completed Container Trip Performance:')) {
            // Strip off the <span> that's in the container trip performance title.
            chartTitle = 'Completed Container Trip Performance'
          }
          tracker.dashboard.userHoversChart(chartTitle)
        },
      },
    },
  },
  title: {
    style: {
      color: theme.palette.grey[800],
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  yAxis: {
    title: {
      ...getHighChartAxisTitleDefaults(theme),
    },
  },
  xAxis: {
    title: {
      ...getHighChartAxisTitleDefaults(theme),
    },
    labels: {
      style: {
        color: theme.palette.grey[400],
        fontSize: theme.typography.body1.fontSize,
      },
      useHTML: true,
    },
  },
  exporting: {
    chartOptions: {
      title: {
        style: {
          visibility: 'visible',
        },
      },
    },
    buttons: {
      contextButton: {
        enabled: true,
        symbolStroke: theme.palette.grey[500],
        menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF'],
      },
    },
  },
})

const getWholeAndRemainder = num => {
  const whole = Math.floor(num)
  return [whole, num - whole]
}

const makeUnitStr = (num, unit, fullLabel) => {
  const label = (() => {
    switch (unit) {
      case 'days':
        return fullLabel ? (num === 1 ? ' day' : ' days') : 'd'
      case 'hours':
        return fullLabel ? (num === 1 ? ' hour' : ' hours') : 'h'
      case 'minutes':
        return fullLabel ? (num === 1 ? ' minute' : ' minutes') : 'm'
      default:
        return ''
    }
  })()
  return `${num}${label}`
}

export const dayHourMinuteLabel = (variability, fullLabel = false) => {
  const [numDays, dayRemainder] = getWholeAndRemainder(variability)
  const [numHours, hourRemainder] = getWholeAndRemainder(dayRemainder * 24)
  const numMinutes = getWholeAndRemainder(hourRemainder * 60)[0]

  const dayStr = makeUnitStr(numDays, 'days', fullLabel)
  const hourStr = makeUnitStr(numHours, 'hours', fullLabel)
  const minuteStr = makeUnitStr(numMinutes, 'minutes', fullLabel)

  if (numDays > 0) {
    if (numHours === 0) {
      return dayStr
    }
    return `${dayStr}, ${hourStr}`
  }

  if (numHours > 0) {
    if (numMinutes === 0) {
      return hourStr
    }
    return `${hourStr}, ${minuteStr}`
  }

  return minuteStr
}
