import { AuthSubtitle, AuthTitle } from 'components/AuthTitle'
import React, { useEffect, useState } from 'react'
import {
  isTokenValidFromState,
  tokenValidationLoadingFromState,
} from 'store/auth/tokenCheck/selectors'
import {
  registerLoadingFromState,
  registrationFormStepSelector,
} from 'store/auth/register/selectors'
import { useDispatch, useSelector } from 'react-redux'

import AuthDialog from 'components/DeprecatedAuthDialog'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { HOME_ROUTE } from 'utils/routes'
import { Loader } from 'components/Loader/Loader'
import PropTypes from 'prop-types'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import RegistrationForm from './RegistrationForm'
import RequestInvitationPage from 'pages/FlaggedLogin/LoginPage/RequestInvitationPage'
import authStrings from './content'
import browserHistory from 'utils/history'
import { checkToken as checkRegistrationToken } from 'store/auth/tokenCheck/actions'
import get from 'lodash/get'
import { getIsPortalPage } from 'utils/routes/portal'
import logger from 'utils/logger'
import { makeStyles } from '@material-ui/core'
import { register } from 'store/auth/register/actions'

const useStyles = makeStyles(theme => ({
  layout: {
    backgroundColor: theme.palette.grey[100],
    height: 'auto',
    minHeight: '100%',
  },
  authTitle: {
    paddingTop: 0,
  },
  expiredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '100%',
    paddingBottom: '25%',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  registrationDialog: {
    width: theme.spacing(63),
  },
  questionnaireWrapper: {
    display: 'block',
    position: 'relative',
    // Typeform "display: block" requires specific width/height styles
    width: theme.spacing(120),
    minHeight: theme.spacing(75),
  },
}))

RegistrationPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      email: PropTypes.string,
      token: PropTypes.string,
    }),
  }),
}

function RegistrationPage({ match }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const email = get(match, 'params.email')
  const isPortal = getIsPortalPage(match)
  const registrationLoading = useSelector(registerLoadingFromState)
  const token = get(match, 'params.token')
  const formStep = useSelector(registrationFormStepSelector)
  const tokenCheckLoading = useSelector(tokenValidationLoadingFromState)
  const validToken = useSelector(isTokenValidFromState)
  const [formData, setFormData] = useState({})
  const formSteps = {
    requiredInformation: 0,
    cxSurvey: 1,
  }

  useEffect(() => {
    dispatch(checkRegistrationToken(token, email))
  }, [token, email, dispatch])

  if (tokenCheckLoading || registrationLoading) {
    return (
      <CenteredLayout>
        <Loader />
      </CenteredLayout>
    )
  } else if (!validToken) {
    // To access the Registration form, this conditional must be false.
    // See route file for the most up to date path.
    return (
      <RequestInvitationPage
        authTitle="Expired Registration Link"
        authSubtitle="Your registration link has expired. Please enter your email and click Request New Link."
        submitButtonTitle="Request New Link"
      />
    )
  } else {
    return (
      <CenteredLayout className={classes.layout}>
        {formStep === formSteps.requiredInformation && (
          <AuthDialog className={classes.registrationDialog}>
            <AuthTitle classes={{ authTitle: classes.authTitle }}>{authStrings.title}</AuthTitle>
            <AuthSubtitle>
              {isPortal ? authStrings.portalSubtitle : authStrings.subTitle}
            </AuthSubtitle>
            <RegistrationForm
              email={email}
              onSubmit={data => {
                const msg = `${getIsPortalPage(match) ? 'Portal ' : ''}User Registration`
                logger.notify(msg, { email })

                dispatch(register({ ...data, email, token }))
                setFormData({ ...formData, ...data })
              }}
            />
          </AuthDialog>
        )}
        {formStep === formSteps.cxSurvey && (
          <AuthDialog>
            <AuthTitle>Tell us more about your role</AuthTitle>
            <div className={classes.questionnaireWrapper}>
              <ReactTypeformEmbed
                popup={false}
                url={`https://diego380282.typeform.com/to/SgzwXwpI?first_name=${formData.firstName}&last_name=${formData.lastName}`}
                onSubmit={event => {
                  setTimeout(() => browserHistory.push(HOME_ROUTE.path), 5000)
                }}
              />
            </div>
          </AuthDialog>
        )}
      </CenteredLayout>
    )
  }
}

export default RegistrationPage
