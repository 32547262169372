import { ETA } from 'utils/shipmentObjs'
import { TimeRange } from 'utils/sharedObjs'
import get from 'lodash/get'

const structureDepartureTimes = departureTime => {
  const originalPlanned = new TimeRange(
    get(departureTime, 'old_planned'),
    get(departureTime, 'old_planned')
  )
  const traced = new TimeRange(get(departureTime, 'traced'), get(departureTime, 'traced'))
  const planned = new TimeRange(get(departureTime, 'planned'), get(departureTime, 'planned'))
  const predicted = new TimeRange(get(departureTime, 'predicted'), get(departureTime, 'predicted'))
  return new ETA(traced, predicted, planned, originalPlanned)
}

export default structureDepartureTimes
