import orderRefTypeToNew from './orderRefTypeToNew'

// Takes the legacy filter `refType` and returns a supported JSONLogic `refType`
const getOrderTypeFromRefType = (refType: string) => {
  if (refType in orderRefTypeToNew) {
    return {
      operator: '==',
      key: 'order.type',
      val: orderRefTypeToNew[refType as keyof typeof orderRefTypeToNew],
    }
  }
  return null
}

export default getOrderTypeFromRefType
