import { call, put, takeLatest } from 'redux-saga/effects'

import { TransportDetail } from 'store/models/TransportDetail'
import api from 'store/transportDetail/api'
import logger from 'utils/logger'
import { types } from 'store/transportDetail/actions'

export function* fetchTransportDetailAsync(action: {
  type: string
  externalId: string
  refType: string
}) {
  try {
    const res = yield call(api.fetchTransportDetail, action.externalId, action.refType)
    const data = (res?.data && TransportDetail.of(res.data)) || {}
    yield put({ type: types.FETCH_TRANSPORT_DETAIL_SUCCESS, payload: data })
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.FETCH_TRANSPORT_DETAIL_FAILURE, payload: { error: err.message } })
  }
}

export function* watchFetchTransportDetail() {
  yield takeLatest(types.FETCH_TRANSPORT_DETAIL_START, fetchTransportDetailAsync)
}
