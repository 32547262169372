import { Table, TableBody, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles(theme => ({
  keyCell: {
    border: 'none',
    paddingLeft: 0,
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    width: 250,
  },
  valueCell: {
    border: 'none',
  },
}))

type KeyValueTableProps = {
  customStyle?: object
  rows: { [key: string]: JSX.Element }
}

/**
 * A simple table that takes an object, loops over all `keys` and returns a table
 * with rows of `key  value` content.
 */
const KeyValueTable = ({ customStyle, rows }: KeyValueTableProps) => {
  const classes = useStyles()
  return (
    <Table>
      <TableBody>
        {Object.keys(rows).map((key, index) => (
          <TableRow key={'row-' + index}>
            <TableCell className={classes.keyCell}>
              <Typography variant="body2" color="textSecondary">
                {key}
              </Typography>
            </TableCell>
            <TableCell className={classes.valueCell} style={customStyle}>
              <Typography component="span" variant="body2">
                {rows[key]}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default KeyValueTable
