import { DOWNLOADS_REDUCER_KEY, types } from './actions'

import { handleActions } from 'redux-actions'

export { DOWNLOADS_REDUCER_KEY }

export const initialState = {
  loading: true,
  error: null,
  rows: {
    data: [],
    /** errors
     * Individual rows can be deleted. This holds errors by ID
     * */
    errors: {},
    /** deleted
     * Used to track successful/failed deleted rows to control exit/enter animation
     * */
    deleted: {},
  },
}

export default handleActions(
  {
    [types.FETCH_DOWNLOAD_START]: (state, { payload: { disableLoading } }) => {
      return {
        ...state,
        /** loading
         * Used to render a spinner, we do not want always want to show the spinner
         */
        loading: disableLoading ? false : true,
      }
    },
    [types.FETCH_DOWNLOAD_SUCCESS]: (state, { payload: { rows } }) => {
      return {
        ...state,
        rows: {
          ...state.rows,
          data: rows,
        },
        loading: false,
      }
    },
    [types.FETCH_DOWNLOAD_FAILURE]: (state, { payload: { error, id } }) => {
      return {
        ...state,
        loading: false,
        error: error,
      }
    },
    [types.DELETE_DOWNLOAD_REQUEST]: state => state,
    [types.DELETE_DOWNLOAD_START]: (state, { payload: { id } }) => {
      return {
        ...state,
        // Eager set delete to true to make UI feel faster
        rows: {
          ...state.rows,
          deleted: { ...state.deletedRows, [id]: true },
        },
      }
    },
    [types.DELETE_DOWNLOAD_SUCCESS]: state => state,
    [types.DELETE_DOWNLOAD_FAILURE]: (state, { payload: { id, error } }) => {
      return {
        ...state,
        rows: {
          ...state.rows,
          deleted: { ...state.rows.deleted, [id]: false },
          errors: { ...state.rows.errors, [id]: error },
        },
      }
    },
  },
  initialState
)

export const deletedRowsSelector = state => state[DOWNLOADS_REDUCER_KEY].rows.deleted
export const loadingSelector = state => state[DOWNLOADS_REDUCER_KEY].loading
export const rowsSelector = state => state[DOWNLOADS_REDUCER_KEY].rows.data
export const deleteErrorsSelector = state => state[DOWNLOADS_REDUCER_KEY].rows.errors
