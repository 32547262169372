import * as windowHandler from 'utils/window'

import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TableTitle,
  TableToolbar,
} from 'components/core/SimpleTableDeprecated'
import { useDispatch, useSelector } from 'react-redux'

import CancelIcon from '@material-ui/icons/Cancel'
import CollapsibleCell from './CollapsibleCell'
import DownloadIcon from 'components/icons/DownloadIcon'
import Link from 'components/core/Link'
import PropTypes from 'prop-types'
import RefreshIcon from '@material-ui/icons/Refresh'
import TableSkeleton from 'components/TableSkeleton'
import client from 'utils/api/client'
import { compose } from 'redux'
import { deletedRowsSelector } from 'store/downloads'
import { fromQueryString } from 'utils/urlBuilder'
import get from 'lodash/get'
import { getMomentDateTimeFromRaw } from 'utils/dateUtils'
import logger from 'utils/logger'
import { requestDeleteDownload } from 'store/downloads/actions'
import styles from './styles'
import { withRouter } from 'react-router-dom'

function DownloadTable({
  sortDirections,
  sortOrder,
  columns,
  rows,
  classes,
  onSortRequest,
  onRefresh,
  location,
}) {
  const dispatch = useDispatch()
  const deletedRows = useSelector(deletedRowsSelector)

  function getIsDeleted(id) {
    return get(deletedRows, id, false)
  }

  const getInProgressText = row => {
    if (row.percentComplete !== null) {
      return (
        <span>
          <span>
            {row.statusLabel} ({Math.round(row.percentComplete * 100)}%)
          </span>
          <IconButton onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        </span>
      )
    } else {
      return <span>{row.statusLabel}</span>
    }
  }

  const downloadFile = (fileId, source) => {
    logger.notify('File Download', { fileId, source })
    client.get(`/download/file/${fileId}`).then(response => {
      windowHandler.setHref(response.data.location)
    })
  }

  const { fileId } = fromQueryString(location.search)
  useEffect(() => {
    if (fileId) {
      // If fileId is in the query params, we were
      // sent here from the download email.
      downloadFile(fileId, 'download-email')
    }
    // TODO: Remove disabled hook rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <TableToolbar>
        <TableTitle>
          <Typography className={classes.header}>Download Manager</Typography>
        </TableTitle>
      </TableToolbar>

      <Table data-testid="download-page__table">
        <TableHead>
          <TableRow>
            {columns.map(({ id, label, sortable }, index) => {
              const { direction } = sortDirections.find(direction => direction.id === id)

              return (
                <TableCell
                  data-testid="download-page__table-column"
                  sortDirection={sortOrder === id ? direction : false}
                  key={`${id}-${index}`}
                >
                  {sortable ? (
                    <TableSortLabel
                      active={sortOrder === id}
                      direction={direction}
                      onClick={() => onSortRequest(id)}
                    >
                      {label}
                    </TableSortLabel>
                  ) : (
                    label
                  )}
                </TableCell>
              )
            })}
            <TableCell data-testid="download-page__delete-column" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            const isDeleted = getIsDeleted(row.id)

            return (
              <TableRow
                className={classes.rowHover}
                data-testid="download-page__table-row"
                hover
                key={`${row.id}-tableRow`}
              >
                <CollapsibleCell
                  collapseIn={!isDeleted}
                  className={classes.cell}
                  key={`name-${row.id}`}
                >
                  {row.status !== 'ready' && row.fileName}
                  {row.status === 'ready' && (
                    <Link
                      component="button"
                      onClick={() => downloadFile(row.id, 'download-table')}
                      classes={{
                        root: classes.linkButton,
                      }}
                    >
                      {row.fileName}
                    </Link>
                  )}
                </CollapsibleCell>
                <CollapsibleCell
                  collapseIn={!isDeleted}
                  className={classes.cell}
                  key={`type-${row.id}`}
                >
                  {row.entity}
                </CollapsibleCell>
                <CollapsibleCell
                  collapseIn={!isDeleted}
                  className={classes.cell}
                  key={`count-${row.id}`}
                >
                  {row.count}
                </CollapsibleCell>
                <CollapsibleCell
                  collapseIn={!isDeleted}
                  className={classes.cell}
                  key={`createdAt-${row.id}`}
                >
                  {row.createdAt
                    ? getMomentDateTimeFromRaw(row.createdAt, 'DD MMM YYYY, H:mm')
                    : row.createdAt}
                </CollapsibleCell>
                <CollapsibleCell
                  collapseIn={!isDeleted}
                  className={classes.cell}
                  key={`downloadedOn-${row.id}`}
                >
                  {row.downloadedOn
                    ? getMomentDateTimeFromRaw(row.downloadedOn, 'DD MMM YYYY, H:mm')
                    : row.downloadedOn}
                </CollapsibleCell>
                <CollapsibleCell
                  collapseIn={!isDeleted}
                  className={classes.cell}
                  key={`status-${row.id}`}
                >
                  {row.status === 'in_progress' && (
                    <Typography variant="body2" color="textSecondary">
                      {getInProgressText(row)}
                    </Typography>
                  )}
                  {row.status === 'error' && (
                    <Typography variant="body2" color="error">
                      {row.statusLabel}
                    </Typography>
                  )}
                  {row.status === 'ready' && (
                    <Tooltip title="Download">
                      <IconButton
                        target="_blank"
                        rel="noopener"
                        aria-label="download file"
                        size="medium"
                        onClick={() => downloadFile(row.id, 'download-table')}
                        color="primary"
                      >
                        <DownloadIcon color="inherit" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
                </CollapsibleCell>
                <CollapsibleCell
                  collapseIn={!isDeleted}
                  className={classes.cell}
                  key={`delete-${row.id}`}
                >
                  <Tooltip title="Delete">
                    <IconButton
                      className={classes.hidden}
                      onClick={() =>
                        dispatch(requestDeleteDownload({ id: row.id, fileName: row.fileName }))
                      }
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </CollapsibleCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      {rows.length === 0 && <TableSkeleton isAnimated={false} message="No downloads to display" />}
    </div>
  )
}

DownloadTable.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  sortOrder: PropTypes.string.isRequired,
  sortDirections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
    })
  ).isRequired,
  onSortRequest: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.integer,
      createdAt: PropTypes.string.isRequired,
      downloadedOn: PropTypes.string,
      entity: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired.isRequired,
      percentComplete: PropTypes.float,
      status: PropTypes.oneOf(['in_progress', 'error', 'ready']),
      statusLabel: PropTypes.string.isRequired,
    })
  ),
}

export default compose(withRouter, withStyles(styles))(DownloadTable)
