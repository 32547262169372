import { httpMethods } from 'utils/api/client'
import humps from 'humps'

type HttpMethod = 'get' | 'put'

const api = {
  _call: (method: HttpMethod, endpoint: string, params?: any) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchCustomFields: (entityType: string, externalId: string) => {
    return api._call('get', `/internal/v1/${entityType}/${externalId}/custom_fields`)
  },
}

export default api
