import { BAR_POINT_WIDTH, makeTooltip } from './utils'

import HighChart from 'components/HighChart'
import React from 'react'
import { formatLocationLabel } from 'utils/strUtils'
import { getHighChartDefaults } from '../utils/highCharts'
import merge from 'lodash/merge'

export function TopAverageDwellTimes({ data, theme }) {
  const categories = data.map(s => formatLocationLabel(s.endLocation.name))
  const series = data.map(s => s.metrics.metricsMean)

  const highChartDefaultOptions = getHighChartDefaults(theme)
  const highChartOptions = {
    chart: {
      type: 'column',
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        pointWidth: BAR_POINT_WIDTH,
      },
    },
    tooltip: {
      formatter: function () {
        return makeTooltip(data[this.point.index])
      },
      borderColor: theme.palette.black,
    },
    title: {
      text: 'Top Average Dwell Times',
      style: { visibility: 'hidden' },
    },
    xAxis: {
      categories,
      labels: {
        rotation: -45,
      },
      title: {
        text: 'Location',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Average Dwell Time (days)',
      },
    },
    series: [{ data: series, color: theme.palette.datavis.blue[200] }],
  }

  return (
    <div data-testid="user-dashboard__top-average-dwell-times">
      <HighChart options={merge({}, highChartDefaultOptions, highChartOptions)} />
    </div>
  )
}
