import { SvgIcon, SvgIconProps, Theme, makeStyles } from '@material-ui/core'

import React from 'react'

// Default color, most likely to be used this way most or all the time, easy to override.
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[300],
  },
}))

const DestinationIcon = (props: SvgIconProps) => {
  const classes = useStyles(props)
  return (
    <SvgIcon classes={{ root: classes.root }} {...props}>
      <g transform="translate(2 2)">
        <path d="M10,0.104166667 C4.54166667,0.104166667 0.104166667,4.54166667 0.104166667,10 C0.104166667,15.4583333 4.54166667,19.8958333 10,19.8958333 C15.4583333,19.8958333 19.8958333,15.4583333 19.8958333,10 C19.8958333,4.54166667 15.4583333,0.104166667 10,0.104166667 Z M10,17.9166667 C5.64583333,17.9166667 2.08333333,14.375 2.08333333,10 C2.08333333,5.625 5.625,2.08333333 10,2.08333333 C14.375,2.08333333 17.9166667,5.625 17.9166667,10 C17.9166667,14.375 14.3541667,17.9166667 10,17.9166667 Z" />
        <path d="M10,3.14583333 C6.22916667,3.14583333 3.14583333,6.22916667 3.14583333,10 C3.14583333,13.7708333 6.22916667,16.8541667 10,16.8541667 C13.7708333,16.8541667 16.8541667,13.7708333 16.8541667,10 C16.8541667,6.22916667 13.7708333,3.14583333 10,3.14583333 Z M10,15.7291667 C6.83333333,15.7291667 4.27083333,13.1458333 4.27083333,10 C4.27083333,6.85416667 6.83333333,4.27083333 10,4.27083333 C13.1666667,4.27083333 15.7291667,6.83333333 15.7291667,10 C15.7291667,13.1666667 13.1666667,15.7291667 10,15.7291667 Z" />
      </g>
    </SvgIcon>
  )
}

export default DestinationIcon
