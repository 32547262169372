import { PROGRESS_COMPLETED } from './definitions'
import UiGroupsInterface from 'store/types/UiGroupsInterface'
import each from 'lodash/each'
import groupRules from './groupRules'

/**
 * Loops over all location groups and:
 * - If we know enough to determine this group is "complete" we set a property
 * - As we loop, we add a property to location[i+1] that location[i] is completed, if true
 * We need these properties to subsequently set each group's progress
 * @param milestones array
 * @return boolean
 */
export const setGroupIsComplete = (groupedMilestones: UiGroupsInterface) => {
  let previousGroupComplete = false
  for (const group in groupedMilestones) {
    each(groupedMilestones[group], location => {
      // Allowing because I'm not sure how I'd do this without calling a function here? since I need `location`?
      /*eslint no-loop-func: 0*/
      const groupCompleted = groupRules[group].isComplete(location)
      location.previousGroupComplete = previousGroupComplete

      if (groupCompleted) {
        location.groupProgress = PROGRESS_COMPLETED
      }
      // Store this value to populate the next group
      previousGroupComplete = groupCompleted
    })
  }
}

export default setGroupIsComplete
