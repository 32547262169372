import { TripTimeline } from 'utils/sharedObjs'
import alignRoutes from './alignRoutes'
import get from 'lodash/get'
import structureRouteSegment from './structureRouteSegment'

const structureRoutes = routes => {
  let structuredRoutes = []
  for (let i = 0; i < routes.length; i++) {
    let route = routes[i]
    const tripIds = get(route, 'ctr_trip_ids')
    // This gives us the index of the latest traced segment
    const latestTracedIdx = get(route, 'latest_traced_index')
    const rawSegments = get(route, 'segments', [])
    const milestones = get(route, 'milestones', [])
    const references = get(route, 'references', [])
    let timelineItems = []

    for (let j = 0; j < rawSegments.length; j++) {
      let rawMilestone = rawSegments[j]
      // We track this segment's index out of all segments for use in comparing against latest traced index
      const segmentIndex = j
      const structuredSegment = structureRouteSegment(
        rawMilestone,
        tripIds,
        milestones,
        segmentIndex
      )
      timelineItems.push(structuredSegment)
    }
    // milestones = milestones.filter(milestone => milestone.type !== 'vessel')
    structuredRoutes.push(new TripTimeline(timelineItems, tripIds, latestTracedIdx, references))
  }
  alignRoutes(structuredRoutes)
  return structuredRoutes
}

export default structureRoutes
