import {
  AVAILABLE_FOR_DELVERY,
  TIMESTAMP_ACTUAL,
  TIMESTAMP_PLANNED,
  TIMESTAMP_PREDICTED,
} from 'utils/milestone/MilestoneGroups/definitions'
import {
  REF_TYPE_LABELS_ABBREVIATED,
  ROLLUP_BOLS,
  ROLLUP_BOOKINGS,
  ROLLUP_CONTAINER,
  ROLLUP_LINE_ITEM,
  ROLLUP_LINE_ITEMS,
  ROLLUP_PURCHASE_ORDERS,
  ROLLUP_PURCHASE_ORDER_NUMBERS,
  ROLLUP_SALES_ORDERS,
  ROLLUP_SALES_ORDER_NUMBERS,
  ROLLUP_SHIPMENT,
  ROLLUP_SHIPMENT_NUMBERS,
  ROLLUP_STOCK_TRANSFER_ORDERS,
  ROLLUP_STOCK_TRANSFER_ORDER_NUMBERS,
  getRefTypeLabel,
  getShipmentDetailPath,
} from 'utils/rollups'
import { getLocalizedMoment, parseCustomFieldDate } from 'utils/dateUtils'

import AvailableForDeliveryLabel from 'components/AvailableForDeliveryLabel'
import DelayLabel from 'components/DelayLabel'
import IdlePredictionDisclaimer from 'components/IdlePredictionDisclaimer'
import MilestoneUtils from 'utils/milestone'
import OutdatedPredictionDisclaimer from 'components/OutdatedPredictionDisclaimer'
import React from 'react'
import find from 'lodash/find'
import { formatLocationLabel } from 'utils/strUtils'
import get from 'lodash/get'
import { getFormattedTimezone } from 'utils/dateUtils'
import getFullMilestoneName from 'utils/shipmentUtils/getFullMilestoneName'
import { isDemo } from 'utils/browsers'
import { isPredictedMilestoneOutdated } from 'utils/shipmentUtils/getOutdatedPredictionStatus'

const DATE_FORMAT = 'DD MMM YYYY HH:mm'
const DATE_TYPE = 'datetime'

export const cellTypes = {
  STANDARD: 'standard',
  DELAY: 'delay',
  DUAL: 'dual',
  LIST: 'list',
  ID: 'id',
}

export const OCEAN_DELAY_KEY = 'ocean_delay'
export const ID_KEY = 'id'
export const PRIMARY_MODE_KEY = 'primary_mode'
export const EQUIPMENT_TYPE_KEY = 'equipment_type'
const CONTAINER_TYPE_KEY = 'container_type'
const LATEST_MILESTONE_TYPE_KEY = 'latest_milestone_type'
const LATEST_MILESTONE_TIME_KEY = 'latest_milestone_time'
const LATEST_MILESTONE_LOCATION_KEY = 'latest_milestone_location'
const PRED_LOAD_KEY = 'pred_load'
const ACTUAL_LOAD_TIME_KEY = 'actual_load_time'
const PLANNED_DISCHARGE_TIME_KEY = 'planned_discharge_time'
const PRED_DISCHARGE_KEY = 'pred_discharge'
const ACTUAL_DISCHARGE_TIME_KEY = 'actual_discharge_time'
const OCEAN_CARRIER_KEY = 'ocean_carrier'
const LOAD_LOCATION_KEY = 'load_location'
const RECEIPT_LOCATION_KEY = 'receipt_location'
const DISCHARGE_LOCATION_KEY = 'discharge_location'
const DELIVERY_LOCATION_KEY = 'delivery_location'
const VESSELS_KEY = 'vessels'
const CONTAINER_COUNT_KEY = 'container_count'
const TRANSSHIPMENT_LOCATIONS_KEY = 'transshipment_locations'
const LINE_ITEM_NUMBER_KEY = 'line_item_number'
const BOOKING_NUMBERS_KEY = 'booking_numbers'
const BOL_NUMBERS_KEY = 'bol_numbers'
const TENANT_KEY = 'tenant'

// This function is for the new fields that will eventually become the new API.
// There's no need to format datetime here because the model has already done that
const getDateCell = (dateTime, timezone, delayLabelProps = null) => {
  return {
    header: (
      <>
        {dateTime}
        {delayLabelProps && <DelayLabel {...delayLabelProps} />}
      </>
    ),
    detail: dateTime && getFormattedTimezone(timezone),
  }
}

// Legacy function for date cells
const getDateCellDeprecated = (dateObj, type, delayLabelProps = null) => {
  const time = get(dateObj, type)
  let tz = get(dateObj, 'timezone', null)
  const localTime = getLocalizedMoment(time, tz)
  const formattedDate = localTime && localTime.isValid() ? localTime.format(DATE_FORMAT) : null

  return {
    header: (
      <>
        {formattedDate}
        {delayLabelProps && <DelayLabel {...delayLabelProps} />}
      </>
    ),
    detail: formattedDate && getFormattedTimezone(tz),
  }
}

const getCellValFcn = filterSpec => {
  const key = get(filterSpec, 'key')
  const dataType = get(filterSpec, 'type')
  if (dataType !== DATE_TYPE) {
    return item => {
      const customFields = get(item, 'custom_fields', [])
      const matchingItem = find(customFields, item => item.key === key)
      const values = get(matchingItem, 'values', [])

      return values
    }
  } else {
    return item => {
      const customFields = get(item, 'custom_fields', [])
      const matchingItem = find(customFields, item => item.key === key)
      const values = get(matchingItem, 'values', [])
      const parsedFieldDate = parseCustomFieldDate(values, DATE_FORMAT)

      /**
       * Wrapping response in an array to make
       * `data_type: cellTypes.LIST` work
       **/
      return parsedFieldDate ? [parsedFieldDate] : []
    }
  }
}
export const buildCustomColumn = filterSpec => {
  const isDisplayed = get(filterSpec, 'display_on_table', true)
  if (!isDisplayed) return null
  const key = get(filterSpec, 'key')
  const title = get(filterSpec, 'title')

  return {
    key,
    title,
    data_type: cellTypes.LIST,
    isCustomerTag: true,
    getCellValue: getCellValFcn(filterSpec),
  }
}

// see end of file for column order, doesn't matter here in the definition
const COLUMNS = {
  OceanDelay: {
    style: {
      minWidth: 70,
      paddingRight: 16,
    },
    data_type: cellTypes.DELAY,
    title: 'Status',
    notHideable: true,
    key: OCEAN_DELAY_KEY,
    getCellValue: item => get(item, 'max_ocean_delay'),
  },
  Id: {
    style: {
      minWidth: 70,
      paddingRight: 24,
      wordBreak: 'break-word',
    },
    data_type: cellTypes.ID,
    getTitle: rollup => {
      if (rollup === ROLLUP_STOCK_TRANSFER_ORDERS) {
        return REF_TYPE_LABELS_ABBREVIATED[ROLLUP_STOCK_TRANSFER_ORDERS]
      }
      return getRefTypeLabel(rollup)
    },
    notHideable: true,
    title: 'ID',
    key: ID_KEY,
    getCellValue: item => get(item, 'id'),
  },
  ContainerType: {
    data_type: cellTypes.STANDARD,
    notHideable: false,
    title: 'Equipment Type',
    key: CONTAINER_TYPE_KEY,
    shouldRender: rollup => rollup === ROLLUP_CONTAINER,
    getCellValue: item => get(item, 'containers[0].type'),
  },
  LatestMilestoneType: {
    data_type: cellTypes.DUAL,
    // we only want to show header and disclaimer. this is the only cell that uses this property
    // (it was added so we can use DualCell to show just a header and a disclaimer)
    hideDetail: true,
    title: 'Current Milestone Type',
    key: LATEST_MILESTONE_TYPE_KEY,
    getCellValue: item => {
      const { latestActualMilestone } = item.shipmentBoard ?? {}
      const { action, statuses } = latestActualMilestone ?? {}
      const availableForDelivery = find(
        statuses,
        status => status.statusType === AVAILABLE_FOR_DELVERY && status.tracedTime
      )
      const availableForDeliveryDate = availableForDelivery?.tracedTime ?? null
      const { category: equipmentCategory } = latestActualMilestone?.onEquipment ?? {}
      const { stage } = latestActualMilestone?.stopSegment?.tripSegment ?? {}
      const { equipmentActivity, facilityType } = latestActualMilestone?.stopSegment ?? {}

      let lastTracedType = MilestoneUtils.getMilestoneDisplayText({
        stage,
        action,
        equipmentActivity,
        equipmentCategory,
        facilityType,
      })

      lastTracedType =
        lastTracedType ?? getFullMilestoneName(stage, action, equipmentCategory, facilityType)

      const isIdle = get(item, 'isIdle')
      return {
        header: (
          <>
            {lastTracedType}
            <AvailableForDeliveryLabel
              availableForDeliveryDate={availableForDeliveryDate}
              withBackground={true}
            />
          </>
        ),
        disclaimer: isIdle ? <IdlePredictionDisclaimer /> : null,
      }
    },
  },
  LatestMilestoneTime: {
    data_type: cellTypes.DUAL,
    title: 'Current Milestone Time',
    sort_param: LATEST_MILESTONE_TIME_KEY,
    key: LATEST_MILESTONE_TIME_KEY,
    getCellValue: item => {
      const { actualDelay, tracedTime } = item.shipmentBoard.latestActualMilestone ?? {}
      const { timezone } = item.shipmentBoard.latestActualMilestone?.locationZone ?? {}

      const delayLabelProps = actualDelay
        ? {
            delayType: TIMESTAMP_ACTUAL,
            delay: actualDelay,
          }
        : null

      return getDateCell(tracedTime, timezone, delayLabelProps)
    },
  },
  LatestMilestoneLocation: {
    data_type: cellTypes.DUAL,
    title: 'Current Milestone Location',
    key: LATEST_MILESTONE_LOCATION_KEY,
    getCellValue: item => {
      return {
        header: formatLocationLabel(get(item, 'latestMilestone.location.name')),
        detail: get(item, 'latestMilestone.location.unlocode'),
      }
    },
  },
  PredLoad: {
    data_type: cellTypes.DUAL,
    title: 'Predicted Load',
    sort_param: PRED_LOAD_KEY,
    key: PRED_LOAD_KEY,

    // TODO - should be load_time once API is updated
    getCellValue: item => getDateCellDeprecated(get(item, 'loadMilestone'), 'predicted'),
  },
  ActualLoadTime: {
    data_type: cellTypes.DUAL,
    key: ACTUAL_LOAD_TIME_KEY,
    title: 'Actual Load',
    getCellValue: item => {
      const { actualDelay, tracedTime } = item.shipmentBoard.latestActualMainLoadMilestone ?? {}
      const { timezone } = item.shipmentBoard.latestActualMainLoadMilestone?.locationZone ?? {}

      const delayLabelProps = actualDelay
        ? {
            delayType: TIMESTAMP_ACTUAL,
            delay: actualDelay,
          }
        : null
      return getDateCell(tracedTime, timezone, delayLabelProps)
    },
  },
  PlannedDischargeTime: {
    data_type: cellTypes.DUAL,
    key: PLANNED_DISCHARGE_TIME_KEY,
    title: 'Carrier Planned Discharge',
    getCellValue: item => {
      const { plannedDelay, plannedTime } = item.shipmentBoard.lastExpectedMainUnloadMilestone ?? {}
      const { timezone } = item.shipmentBoard.lastExpectedMainUnloadMilestone?.locationZone ?? {}
      const delayLabelProps = plannedDelay
        ? {
            delayType: TIMESTAMP_PLANNED,
            delay: plannedDelay,
          }
        : null
      return getDateCell(plannedTime, timezone, delayLabelProps)
    },
  },
  PredDischarge: {
    data_type: cellTypes.DUAL,
    title: 'Predicted Discharge',
    sort_param: PRED_DISCHARGE_KEY,
    key: PRED_DISCHARGE_KEY,
    getCellValue: item => {
      const { predictedDelay, predictedTime } =
        item.shipmentBoard.lastExpectedMainUnloadMilestone ?? {}
      const { timezone } = item.shipmentBoard.lastExpectedMainUnloadMilestone?.locationZone ?? {}
      const { lastExpectedMainUnloadMilestone } = item.shipmentBoard ?? {}
      const isPredictionOutdated = isPredictedMilestoneOutdated(lastExpectedMainUnloadMilestone)

      const delayLabelProps = predictedDelay
        ? {
            delayType: TIMESTAMP_PREDICTED,
            delay: predictedDelay,
          }
        : null
      const cell = getDateCell(predictedTime, timezone, delayLabelProps)

      if (!isPredictionOutdated || isDemo()) {
        return cell
      }

      return {
        ...cell,
        disclaimer: <OutdatedPredictionDisclaimer />,
      }
    },
  },
  ActualDischargeTime: {
    data_type: cellTypes.DUAL,
    key: ACTUAL_DISCHARGE_TIME_KEY,
    title: 'Actual Discharge',
    getCellValue: item => {
      const { actualDelay, tracedTime } = item.shipmentBoard.lastExpectedMainUnloadMilestone ?? {}
      const { timezone } = item.shipmentBoard.lastExpectedMainUnloadMilestone?.locationZone ?? {}

      const delayLabelProps = actualDelay
        ? {
            delayType: TIMESTAMP_ACTUAL,
            delay: actualDelay,
          }
        : null
      return getDateCell(tracedTime, timezone, delayLabelProps)
    },
  },
  OceanCarrier: {
    data_type: cellTypes.LIST,
    title: 'Ocean Carrier',
    key: OCEAN_CARRIER_KEY,
    getCellValue: item => {
      let carriers = get(item, 'carrier', [])
      // Legacy [{}], that can have several carriers vs new APIs {}, that only has one
      // For now we want an array
      carriers = Array.isArray(carriers) ? carriers : [carriers]
      return carriers.map(item => get(item, 'name'))
    },
  },
  LoadLocation: {
    data_type: cellTypes.DUAL,
    title: 'Load Location',
    key: LOAD_LOCATION_KEY,
    getCellValue: item => ({
      header: formatLocationLabel(get(item, 'loadMilestone.location.name')),
      detail: get(item, 'loadMilestone.location.unlocode'),
    }),
  },
  ReceiptLocation: {
    data_type: cellTypes.DUAL,
    title: 'Receipt Location',
    key: RECEIPT_LOCATION_KEY,
    getCellValue: item => ({
      header: formatLocationLabel(get(item, 'receiptMilestone.location.name')),
      detail: get(item, 'receiptMilestone.location.unlocode'),
    }),
  },
  DischargeLocation: {
    data_type: cellTypes.DUAL,
    title: 'Discharge Location',
    key: DISCHARGE_LOCATION_KEY,
    getCellValue: item => ({
      header: formatLocationLabel(get(item, 'dischargeMilestone.location.name')),
      detail: get(item, 'dischargeMilestone.location.unlocode'),
    }),
  },
  DeliveryLocation: {
    data_type: cellTypes.DUAL,
    title: 'Delivery Location',
    key: DELIVERY_LOCATION_KEY,
    getCellValue: item => ({
      header: formatLocationLabel(get(item, 'deliveryMilestone.location.name')),
      detail: get(item, 'deliveryMilestone.location.unlocode'),
    }),
  },
  Vessels: {
    style: {
      minWidth: 180,
    },
    data_type: cellTypes.LIST,
    title: 'Vehicles',
    key: VESSELS_KEY,
    getCellValue: item => {
      const vehicleNames = new Set()
      const vehicles = get(item, 'vehicles', [])
      vehicles.forEach(vehicle => {
        // for new APIs each vehicle will have a `name` property
        let vehicleName = get(vehicle, 'name', null)
        // for legacy `vehicle` is a `milestone` that has a `vessel` object with a `name` property
        vehicleName = vehicleName ? vehicleName : get(vehicle, 'vessel.name', null)
        if (vehicleName) vehicleNames.add(vehicleName)
      })
      return Array.from(vehicleNames)
    },
  },
  ContainerCount: {
    data_type: cellTypes.STANDARD,
    key: CONTAINER_COUNT_KEY,
    title: 'Container Count',
    shouldRender: rollup => rollup !== ROLLUP_CONTAINER,
    getCellValue: item => get(item, 'ctr_trip_ids', []).length,
  },
  TransshipmentLocations: {
    data_type: cellTypes.LIST,
    key: TRANSSHIPMENT_LOCATIONS_KEY,
    title: 'Transshipment Locations',
    getCellValue: item => {
      let transshipmentLocations
      if (get(item, 'transshipmentMilestones.legacy_locations', false)) {
        // legacy is an array so we've mapped it to a custom property
        transshipmentLocations = get(item, 'transshipmentMilestones.legacy_locations', [])
      } else {
        // new API is single object so we insert it into an array
        transshipmentLocations = get(item, 'transshipmentMilestones.location', [])
        transshipmentLocations = [transshipmentLocations]
      }
      // because the UI is expecting an array
      return transshipmentLocations.map(location => formatLocationLabel(get(location, 'name')))
    },
  },
  SalesOrderNumber: {
    data_type: cellTypes.LIST,
    title: 'Sales Orders',
    key: ROLLUP_SALES_ORDERS,
    shouldRender: rollup => rollup !== ROLLUP_SALES_ORDERS,
    getCellValue: item => get(item, `references.${ROLLUP_SALES_ORDER_NUMBERS}`, []),
    getUrl: id => getShipmentDetailPath(ROLLUP_SALES_ORDERS, id),
  },
  PurchaseOrderNumber: {
    data_type: cellTypes.LIST,
    title: 'Purchase Orders',
    key: ROLLUP_PURCHASE_ORDERS,
    shouldRender: rollup => rollup !== ROLLUP_PURCHASE_ORDERS,
    getCellValue: item => get(item, `references.${ROLLUP_PURCHASE_ORDER_NUMBERS}`, []),
    getUrl: id => getShipmentDetailPath(ROLLUP_PURCHASE_ORDERS, id),
  },
  StockTransferOrderNumber: {
    data_type: cellTypes.LIST,
    title: 'Stock Transfer Orders',
    key: ROLLUP_STOCK_TRANSFER_ORDERS,
    shouldRender: rollup => rollup !== ROLLUP_STOCK_TRANSFER_ORDERS,
    getCellValue: item => get(item, `references.${ROLLUP_STOCK_TRANSFER_ORDER_NUMBERS}`, []),
    getUrl: id => getShipmentDetailPath(ROLLUP_STOCK_TRANSFER_ORDERS, id),
  },
  LineItemNumber: {
    data_type: cellTypes.LIST,
    title: 'Line Items',
    key: LINE_ITEM_NUMBER_KEY,
    shouldRender: rollup => rollup !== ROLLUP_LINE_ITEM,
    getCellValue: item => get(item, `references.${ROLLUP_LINE_ITEMS}`, []),
    getUrl: id => getShipmentDetailPath(ROLLUP_LINE_ITEM, id),
  },
  BookingNumbers: {
    data_type: cellTypes.LIST,
    key: BOOKING_NUMBERS_KEY,
    title: 'Bookings',
    shouldRender: rollup => rollup !== ROLLUP_BOOKINGS,
    // TODO make this key the same as ROLLUP_BOOKING_NUMBERS
    getCellValue: item => get(item, 'references.ocean_bookings', []),
    getUrl: id => getShipmentDetailPath(ROLLUP_BOOKINGS, id),
  },
  BillsOfLading: {
    data_type: cellTypes.LIST,
    key: BOL_NUMBERS_KEY,
    title: 'Bills of Lading',
    shouldRender: rollup => rollup !== ROLLUP_BOLS,
    // TODO make this key the same as ROLLUP_BOL_NUMBERS
    getCellValue: item => get(item, 'references.bols', []),
    getUrl: id => getShipmentDetailPath(ROLLUP_BOLS, id),
  },
  Shipments: {
    data_type: cellTypes.LIST,
    key: ROLLUP_SHIPMENT_NUMBERS,
    title: 'Shipments',
    shouldRender: rollup => rollup !== ROLLUP_SHIPMENT,
    getCellValue: item => get(item, `references.${ROLLUP_SHIPMENT_NUMBERS}`, []),
    getUrl: id => getShipmentDetailPath(ROLLUP_SHIPMENT, id),
  },
  TenantKey: {
    style: {
      minWidth: 180,
    },
    data_type: cellTypes.STANDARD,
    title: 'Partner',
    key: TENANT_KEY,
    getCellValue: item =>
      // TODO:
      // Delete string check after API has been updated to return an object - ETA 03/08/19
      // https://clearmetal.atlassian.net/browse/CM-1135
      typeof item.tenant === 'string' ? get(item, 'tenant') : get(item, 'tenant.display_name'),
  },
}

export const columns = [
  COLUMNS.Id,
  COLUMNS.OceanDelay,
  COLUMNS.ContainerType,
  COLUMNS.LatestMilestoneType,
  COLUMNS.LatestMilestoneTime,
  COLUMNS.LatestMilestoneLocation,
  COLUMNS.PredLoad,
  COLUMNS.ActualLoadTime,
  COLUMNS.PlannedDischargeTime,
  COLUMNS.PredDischarge,
  COLUMNS.ActualDischargeTime,
  COLUMNS.OceanCarrier,
  COLUMNS.LoadLocation,
  COLUMNS.ReceiptLocation,
  COLUMNS.DischargeLocation,
  COLUMNS.DeliveryLocation,
  COLUMNS.Vessels,
  COLUMNS.ContainerCount,
  COLUMNS.TransshipmentLocations,
  COLUMNS.SalesOrderNumber,
  COLUMNS.PurchaseOrderNumber,
  COLUMNS.StockTransferOrderNumber,
  COLUMNS.LineItemNumber,
  COLUMNS.BookingNumbers,
  COLUMNS.BillsOfLading,
  COLUMNS.Shipments,
  COLUMNS.TenantKey,
]

export default columns
