import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = props =>
  makeStyles(theme => ({
    root: {
      width: props.width,
      height: props.height,
    },
    borderRadiusSmall: {
      borderRadius: theme.shape.borderRadiusSmall,
    },
    borderRadiusMedium: {
      borderRadius: theme.shape.borderRadius,
    },
    borderRadiusLarge: {
      borderRadius: theme.shape.borderRadiusLarge,
    },
    borderRadiusCircular: {
      borderRadius: theme.shape.borderRadiusCircular,
    },
    backgroundColor: {
      backgroundColor: theme.palette.grey[100],
      background: `linear-gradient(to right, ${theme.palette.grey[100]} 8%, #f8f8f8 18%, ${theme.palette.grey[100]} 33%)`,
      backgroundSize: '800px 104px',
      position: 'relative',
      display: 'flex',
    },
    animation: {
      animationDelay: props.animationDelay,
      animationDuration: '3s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: `$shimmer`,
      animationTimingFunction: 'linear',
    },
    '@keyframes shimmer': {
      '0%': {
        backgroundPosition: '-800px 0',
      },
      '100%': {
        backgroundPosition: '800px 0',
      },
    },
    static: {
      background: theme.palette.grey[100],
    },
    gutter: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }))

Skeleton.defaultProps = {
  width: 'auto',
  height: 20,
  animationDelay: '250ms',
  borderRadius: 'medium',
  isAnimated: true,
  gutter: true,
}

Skeleton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationDelay: PropTypes.string,
  borderRadius: PropTypes.oneOf(['none', 'small', 'medium', 'large', 'circular']),
  isAnimated: PropTypes.bool,
  gutter: PropTypes.bool,
}

function Skeleton({
  isAnimated,
  width,
  height,
  animationDelay,
  borderRadius,
  gutter,
  className = '',
}) {
  const classes = useStyles({
    width: width,
    height: height,
    animationDelay,
  })()

  return (
    <div
      data-testid="skeleton"
      className={cx(
        classes.backgroundColor,
        classes.root,
        {
          [classes.borderRadiusSmall]: borderRadius === 'small',
          [classes.borderRadiusMedium]: borderRadius === 'medium',
          [classes.borderRadiusLarge]: borderRadius === 'large',
          [classes.borderRadiusCircular]: borderRadius === 'circular',
          [classes.animation]: isAnimated,
          [classes.static]: !isAnimated,
          [classes.gutter]: gutter,
        },
        className
      )}
    />
  )
}

export default Skeleton
