import React, { Component } from 'react'

import FilterItem from 'components/Filtering/FilterItem'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styles from './styles'
import { withStyles } from '@material-ui/core'

class FilterList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
        filterConfig: PropTypes.shape({
          name: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          filterData: PropTypes.shape({
            getDisplayVal: PropTypes.func.isRequired,
            type: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      })
    ).isRequired,
    removeFilter: PropTypes.func.isRequired,
    setActiveFilter: PropTypes.func.isRequired,
    toggleImportDialog: PropTypes.func.isRequired,
  }

  handleEdit = filter => () => {
    const { setActiveFilter, toggleImportDialog } = this.props
    const filterType = get(filter, 'filterConfig.filterData.type')
    if (filterType === 'dialog')
      return toggleImportDialog({ rollup: filter.name, items: filter.value })
    setActiveFilter(filter.filterConfig, true)
  }

  render() {
    const { filters, removeFilter, classes } = this.props

    const list = filters.map((f, index) => {
      const allowEdit = get(f, 'filterConfig.filterData.allowEdit', true)
      let displayVal = 'Error Rendering Filter'
      if (get(f, 'filterConfig.filterData.getDisplayVal')) {
        const getDisplayVal = get(f, 'filterConfig.filterData.getDisplayVal', val => val)
        displayVal = getDisplayVal(f.value)
      }

      return (
        <FilterItem
          key={`filter-item-${index}-${String(f.name)}`}
          title={get(f, 'filterConfig.title', 'Error Rendering Filter')}
          content={displayVal}
          enableCopy={get(f, 'filterConfig.filterData.type', 'undefined') === 'dialog'}
          onRequestEdit={this.handleEdit(f)}
          allowEdit={allowEdit}
          handleDelete={() => removeFilter(f.name)}
        />
      )
    })

    return (
      <div className={classes.container}>
        {list.length > 0 && <div className={classes.list}>{list}</div>}
      </div>
    )
  }
}

export default withStyles(styles)(FilterList)
