import { all, call, put, takeEvery } from 'redux-saga/effects'

import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import client from 'utils/api/client'
import { createAction } from 'redux-actions'
import humps from 'humps'
import logger from 'utils/logger'

// ------------------------------------
// Constants
// ------------------------------------
const ALL_USERS_LOAD = 'auth/configureUsers/ALL_USERS_LOAD'
const ALL_USERS_SUCCESS = 'auth/configureUsers/ALL_USERS_SUCCESS'
const UPDATE_USER_LOAD = 'auth/configureUsers/UPDATE_USER_LOAD'
const UPDATE_USER_SUCCESS = 'auth/configureUsers/UPDATE_USER_SUCCESS'

// ------------------------------------
// Actions
// ------------------------------------
export const getAllUsers = createAction(ALL_USERS_LOAD)
export const updateUser = createAction(UPDATE_USER_LOAD, user => user)

// ------------------------------------
// Action Handlers
// ------------------------------------

export const CONFIGURE_USERS_ACTION_HANDLERS = {
  [ALL_USERS_LOAD]: (state, action) => ({
    ...state,
    allUsers: {
      ...state.allUsers,
      loading: true,
    },
  }),
  [ALL_USERS_SUCCESS]: (state, action) => ({
    ...state,
    allUsers: {
      ...state.allUsers,
      data: action.payload,
      loading: false,
    },
  }),
}

// ------------------------------------
// Selectors
// ------------------------------------

export const allUsersLoadingSelector = state => state[AUTH_REDUCER_KEY].allUsers.loading
export const allUsersSelector = state => state[AUTH_REDUCER_KEY].allUsers.data

// ------------------------------------
// Saga
// ------------------------------------
function* getAllUsersAsync() {
  try {
    const response = yield call(client.get, '/user')
    yield put({ type: ALL_USERS_SUCCESS, payload: humps.camelizeKeys(response.data) })
  } catch (e) {
    logger.captureAPIException(e)
    logger.error(e)
  }
}
function* updateUserAsync(action) {
  try {
    const data = action.payload
    yield call(client.put, '/user', humps.decamelizeKeys(data))
    yield put({ type: UPDATE_USER_SUCCESS })
    yield put({ type: ALL_USERS_LOAD }) // reload all users
  } catch (e) {
    logger.captureAPIException(e)
    logger.error(e)
  }
}

export function* watchConfigureUsers() {
  yield all([
    takeEvery(ALL_USERS_LOAD, getAllUsersAsync),
    takeEvery(UPDATE_USER_LOAD, updateUserAsync),
  ])
}
