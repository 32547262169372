import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, takeLatest } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import api from '../api'
import browserHistory from 'utils/history'
import logger from 'utils/logger'
import { types } from '../actions'

export function* impersonateAsync(action) {
  try {
    const { email, tenant } = action.payload
    yield call(api.userImpersonate, { email, tenant })
    yield put({ type: types.IMPERSONATE_SUCCESS })
    yield put(
      showNotification(MESSAGES.impersonateUserSuccess(email), { type: MESSAGE_TYPES.SUCCESS })
    )
    logger.notify('Began Impersonating User', { user: email, tenant })
    yield browserHistory.push('/')
    // we could call yield put(getUserInfo()) here alternatively
    window.location.reload()
  } catch (e) {
    logger.captureAPIException(e)
    logger.localLog(`Error calling API endpoint: ${e}`, 'error')
    yield put(showNotification(MESSAGES.impersonateUserError, { type: MESSAGE_TYPES.ERROR }))
    yield put({ type: types.IMPERSONATE_FAILURE })
  }
}

export function* watchImpersonate() {
  yield takeLatest(types.IMPERSONATE_LOAD, impersonateAsync)
}
