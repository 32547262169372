import { AxiosError, AxiosResponse } from 'axios'
import { Dialog, DialogTitle, IconButton, Theme, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import { Loader } from 'components/Loader/Loader'
import PortalAlertForm from 'pages/PortalAlertModal/PortalAlertForm'
import { PortalAlerts } from 'store/portalAlerts/sagas/models'
import { PortalAlertsInterface } from 'store/portalAlerts/interfaces'
import api from 'store/portalAlerts/api'
import logger from 'utils/logger'

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: 'initial',
    maxHeight: '98vh',
    minHeight: 450,
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
    width: 986, // hardcoded to guarantee layout of form elements
  },
  dialogTitle: {
    display: 'flex',
    padding: 0,
    position: 'relative',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: `-${theme.spacing(2)}px`,
    top: `-${theme.spacing(4)}px`,
  },
}))

interface Props {
  onClose: () => void
  open: boolean
}

const PortalAlertModal = ({ open = false, onClose }: Props) => {
  const classes = useStyles()

  const [formLoading, setFormLoading] = useState(false)
  const [apiResponse, setApiResponse] = useState<null | PortalAlertsInterface>(null)

  useEffect(() => {
    if (open) {
      setFormLoading(true)
      setApiResponse(null)
      api
        .getPortalAlertConfig()
        .then((response: AxiosResponse) => {
          setApiResponse(PortalAlerts.of(response.data))
          setFormLoading(false)
        })
        .catch((error: AxiosError) => {
          logger.error(error)
          logger.captureAPIException(error)
          setApiResponse(null)
        })
    }
  }, [open])

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      onClose={onClose}
      aria-labelledby="portal-alert-modal"
      data-testid="portal-alert__modal"
      open={open}
    >
      <DialogTitle id="share-dialog-title" className={classes.dialogTitle} disableTypography>
        <Typography className={classes.header} variant="h6">
          Setting Up Your Alert
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!apiResponse || formLoading ? <Loader /> : <PortalAlertForm alerts={apiResponse} />}
    </Dialog>
  )
}

export default PortalAlertModal
