import * as React from 'react'

import {
  ROLLUP_BOLS,
  ROLLUP_BOOKINGS,
  ROLLUP_LINE_ITEM,
  ROLLUP_PURCHASE_ORDERS,
  ROLLUP_SALES_ORDERS,
  ROLLUP_SHIPMENT,
  ROLLUP_STOCK_TRANSFER_ORDERS,
} from 'utils/rollups'

import ApiRefType from 'store/models/definitions/ApiRefType'
import EntityPage from 'pages/EntityPage'

export function BookingPage() {
  return (
    <EntityPage
      shareModalRefType={ROLLUP_BOOKINGS}
      refType={ApiRefType.ocean_booking_carrier_reference}
      hideBooking={true}
    />
  )
}

export function SalesOrderPage() {
  return (
    <EntityPage shareModalRefType={ROLLUP_SALES_ORDERS} refType={ApiRefType.sales_order_number} />
  )
}

export function PurchaseOrderPage() {
  return (
    <EntityPage
      shareModalRefType={ROLLUP_PURCHASE_ORDERS}
      refType={ApiRefType.purchase_order_number}
    />
  )
}

export function StockTransferOrderPage() {
  return (
    <EntityPage
      shareModalRefType={ROLLUP_STOCK_TRANSFER_ORDERS}
      refType={ApiRefType.stock_transfer_order_number}
    />
  )
}

export function BolPage() {
  return <EntityPage shareModalRefType={ROLLUP_BOLS} refType={ApiRefType.bill_of_lading_number} />
}

export function LineItemPage() {
  return <EntityPage shareModalRefType={ROLLUP_LINE_ITEM} refType={ApiRefType.line_item_number} />
}

export function ShipmentPage() {
  return <EntityPage shareModalRefType={ROLLUP_SHIPMENT} refType={ApiRefType.shipment} />
}
