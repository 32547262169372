import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import client from 'utils/api/client'
import { types as commentTypes } from 'store/comments/actions'
import humps from 'humps'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import { types } from 'store/suggest/actions'

/****
 * This saga is used for suggestion lists that are typically
 * triggered by user typing in an autocomplete.
 * `allowEmptyQuery`, boolean, allows endpoints to fetch data on page load before any typing
 * `fetchComments`, boolean, will trigger fetch comments
 */
export function* fetchSuggestSaga({
  path,
  params,
  successAction,
  allowEmptyQuery = false,
  fetchComments = false,
  isCustom = false,
  customKey = null,
}) {
  // If `params` is an empty string, wipe the state.
  // We do this outside of the delay() below to ensure
  // the state is always wiped in this scenario.
  if (!params.q && !allowEmptyQuery) {
    yield put({ type: successAction, payload: [] })
    return
  }

  yield put({ type: types.SEARCH_CALL_START })

  try {
    /* To avoid hammering the server, we add this delay + takeLatest
     * (specified in the way the caller hooks this saga up) to
     * effectively debounce the response so that only the last
     * dispatched trigger event in the past 200 ms (or whatever the
     * delay is) will actually trigger an api call
     *
     * Following the pattern as outlined here:
     * https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md
     */
    yield delay(200)
    const response = yield call(client.get, path, {
      params: humps.decamelizeKeys(params),
    })
    const payload = humps.camelizeKeys(response.data)

    if (isCustom) {
      yield put({ type: successAction, payload: { customKey, data: payload } })
    } else {
      yield put({ type: successAction, payload })
    }

    if (fetchComments) {
      yield call(fetchCommentSummaries, payload)
    }
  } catch (e) {
    logger.captureAPIException(e)
    logger.error(`Error calling API endpoint ${path}:`)
    logger.error(e)
  } finally {
    yield put({ type: types.SEARCH_CALL_END })
  }
}

// util, just here to keep this out of `fetchSuggestSaga`
function* fetchCommentSummaries(payload) {
  const externalIds = payload.map(suggestion => suggestion.externalId)
  const isExternalUser = yield select(isExternalUserSelector)
  if (!isExternalUser) {
    yield put({ type: commentTypes.FETCH_COMMENT_SUMMARIES_START, externalIds })
  }
}

// All suggestion calls must call fetchSuggestSaga() in order to track pending calls correctly!
// If this is not possible, make sure to fire SEARCH_CALL_START and SEARCH_CALL_END events for your suggestion call.

function* fetchSalesOrdersSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/sales_order_number',
    params: { q: payload },
    successAction: types.LOAD_SALES_ORDERS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchPurchaseOrdersSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/purchase_order_number',
    params: { q: payload },
    successAction: types.LOAD_PURCHASE_ORDERS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchStockTransferOrdersSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/stock_transfer_order_number',
    params: { q: payload },
    successAction: types.LOAD_STOCK_TRANSFER_ORDERS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchBolsSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/bill_of_lading_number',
    params: { q: payload },
    successAction: types.LOAD_BOLS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchBookingsSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/ocean_booking_carrier_reference',
    params: { q: payload },
    successAction: types.LOAD_BOOKINGS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchLineItemsSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/line_item_number',
    params: { q: payload },
    successAction: types.LOAD_LINE_ITEMS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchContainersSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/container_number',
    params: { q: payload },
    successAction: types.LOAD_CONTAINERS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchShipmentsSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: '/shipments/suggest/shipment',
    params: { q: payload },
    successAction: types.LOAD_SHIPMENTS_SUCCESS,
    allowEmptyQuery: false,
    fetchComments: true,
  })
}

function* fetchCarriersSuggestSaga({ payload }) {
  yield fetchSuggestSaga({
    path: 'carriers/suggest',
    params: { q: payload, suggest_type: 'railroad' },
    successAction: types.LOAD_CARRIERS_SUCCESS,
    allowEmptyQuery: true,
    fetchComments: false,
  })
}
export function* fetchCustomSuggestSaga({ payload }) {
  const { lookupPath, q, customKey } = payload
  if (!lookupPath || !customKey) {
    return
  }
  yield fetchSuggestSaga({
    path: lookupPath,
    params: { q },
    successAction: types.LOAD_CUSTOM_SUGGEST_SUCCESS,
    allowEmptyQuery: true,
    fetchComments: false,
    isCustom: true,
    customKey,
  })
}

// All suggestion calls must call fetchSuggestSaga() in order to track pending calls correctly!
// If this is not possible, make sure to fire SEARCH_CALL_START and SEARCH_CALL_END events for your suggestion call.
//
// (Comment repeated from above in case editing occurs at this part of the file and the comment above is not seen)

export const sagas = [
  takeLatest(types.LOAD_SALES_ORDERS_START, fetchSalesOrdersSuggestSaga),
  takeLatest(types.LOAD_PURCHASE_ORDERS_START, fetchPurchaseOrdersSuggestSaga),
  takeLatest(types.LOAD_STOCK_TRANSFER_ORDERS_START, fetchStockTransferOrdersSuggestSaga),
  takeLatest(types.LOAD_BOLS_START, fetchBolsSuggestSaga),
  takeLatest(types.LOAD_BOOKINGS_START, fetchBookingsSuggestSaga),
  takeLatest(types.LOAD_LINE_ITEMS_START, fetchLineItemsSuggestSaga),
  takeLatest(types.LOAD_CONTAINERS_START, fetchContainersSuggestSaga),
  takeLatest(types.LOAD_SHIPMENTS_START, fetchShipmentsSuggestSaga),
  takeLatest(types.LOAD_CARRIERS_START, fetchCarriersSuggestSaga),
  takeEvery(types.LOAD_CUSTOM_SUGGEST_START, fetchCustomSuggestSaga),
]
