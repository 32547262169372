import { lazy } from 'react'

/* imports a library lazily. 'name' can be a named export. omit the name parameter to get the default export.

so the following static imports:

  import Widget from 'widget'
  import { specialWidget } from 'widget-extra'

done lazily should be:

  const widget = lazyImport('widget')
  const specialWidget = lazyImport('widget-extra', 'specialWidget')
 */
const lazyImport = (resolver, name = 'default') => {
  return lazy(async () => {
    const resolved = await resolver()
    return { default: resolved[name] }
  })
}

export default lazyImport
