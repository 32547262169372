import { BOARD_REDUCER_KEY } from 'store/board/actions'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import structureShipment from 'utils/shipmentUtils/structureShipment'

export const shipmentsLoadingFromState = state => state[BOARD_REDUCER_KEY].loading
export const shipmentsTotalPagesSelector = state => state[BOARD_REDUCER_KEY].totalPages
export const shipmentsTotalCountSelector = state => state[BOARD_REDUCER_KEY].totalCount
export const shipmentsSelectedCountSelector = state => state[BOARD_REDUCER_KEY].selectedCount
export const currentPageSelector = state => state[BOARD_REDUCER_KEY].currentPage
export const refTypeCountsSelector = state => state[BOARD_REDUCER_KEY].refTypeCounts
export const shipmentsForCurrentPageSelector = state => {
  const currentPage = state[BOARD_REDUCER_KEY].currentPage
  return get(state[BOARD_REDUCER_KEY].data, currentPage, [])
}

export const loadedPagesSelector = state => {
  let data = state[BOARD_REDUCER_KEY].data
  let pages = []
  for (let key in data) {
    pages.push(parseInt(key))
  }
  return pages
}

/**
 * This function and `structureShipmentsSelector` below create our `structuredShipment`.
 * Moving this here instead of in the component.
 */
const structureAllShipments = shipments => {
  let structuredShipments = []
  for (let i = 0; i < shipments.length; i++) {
    let rawShipment = shipments[i]
    let shipment = structureShipment(rawShipment)
    structuredShipments.push(shipment)
  }
  return structuredShipments
}

export const structureShipmentsSelector = createSelector(
  shipmentsForCurrentPageSelector,
  structureAllShipments
)

export const getTripMilestonesFromState = (state, tripId) => {
  const page = state[BOARD_REDUCER_KEY].currentPage
  const currentData = get(state[BOARD_REDUCER_KEY].tripTimelines, page, [])
  return get(currentData, tripId, [])
}

export const getShipmentFromState = (state, id) => {
  return get(state[BOARD_REDUCER_KEY].data, id, {})
}

export const selectIsExhaustiveSelector = state =>
  get(state[BOARD_REDUCER_KEY], 'selectIsExhaustive', false)

export const structureSortParams = sortParams => {
  /**
   * sortParams: array of objects of type {direction: 'desc', sort_param: 'pred_delay'}
   */
  if (Object.keys(sortParams).length === 0) return {}
  const value = `${sortParams.sort_param}:${sortParams.direction}`
  return { sort: value }
}

export const selectedShipments = state => {
  const paginatedShipments = state[BOARD_REDUCER_KEY].data
  const selectedShipmentIds = state[BOARD_REDUCER_KEY].selected

  // Looks for shipments within each page with a selected id
  return reduce(
    paginatedShipments,
    (results, shipments, _) => {
      shipments.filter(shipment => {
        return selectedShipmentIds.includes(get(shipment, 'ref_id')) ? results.push(shipment) : null
      })
      return results
    },
    []
  )
}

export const selectedIdsSelector = state => state[BOARD_REDUCER_KEY].selected
export const deselectedIdsSelector = state => state[BOARD_REDUCER_KEY].deselected

export const isSelected = (state, shipment) =>
  state[BOARD_REDUCER_KEY].selected.includes(get(shipment, 'ref_id'))
