import React from 'react'

const FiltersExpandedIcon = ({ color = '#859198', ...restProps }) => (
  <svg width={38} height={22} {...restProps}>
    <g fill={color} fillRule="evenodd">
      <path d="M33.502 5.007c-.323 1.615-1.73 2.832-3.413 2.832-1.683 0-3.09-1.217-3.412-2.832H15.929a.703.703 0 0 1-.697-.708c0-.391.312-.708.697-.708h10.748C27 1.976 28.406.759 30.089.759c1.684 0 3.09 1.217 3.413 2.832h2.855c.385 0 .697.317.697.708 0 .39-.312.708-.697.708h-2.855zm-3.413-2.832c1.152 0 2.09.953 2.09 2.124 0 1.171-.938 2.124-2.09 2.124S28 5.47 28 4.299c0-1.171.937-2.124 2.09-2.124zm-4.48 9.912c-.323 1.616-1.73 2.833-3.413 2.833-1.683 0-3.089-1.217-3.412-2.833h-2.855a.703.703 0 0 1-.697-.708c0-.39.312-.708.697-.708h2.855c.323-1.615 1.729-2.832 3.412-2.832 1.684 0 3.09 1.217 3.413 2.832h10.748c.385 0 .697.317.697.708 0 .391-.312.708-.697.708H25.61zm-3.413-2.832c1.152 0 2.09.953 2.09 2.124 0 1.171-.938 2.124-2.09 2.124-1.151 0-2.089-.953-2.089-2.124 0-1.17.938-2.124 2.09-2.124zm11.306 9.913C33.179 20.783 31.772 22 30.089 22c-1.683 0-3.09-1.217-3.412-2.832H15.929a.703.703 0 0 1-.697-.708c0-.391.312-.708.697-.708h10.748c.323-1.616 1.729-2.832 3.412-2.832 1.684 0 3.09 1.216 3.413 2.832h2.855c.385 0 .697.317.697.708 0 .39-.312.708-.697.708h-2.855zm-3.413-2.832c1.152 0 2.09.953 2.09 2.124 0 1.17-.938 2.124-2.09 2.124S28 19.63 28 18.46c0-1.171.937-2.124 2.09-2.124zM8.263 5.24L2.683 11l5.58 5.76c.43.411.43 1.234 0 1.646-.43.411-1.18.411-1.61 0L.322 11.823c-.43-.412-.43-1.234 0-1.646l6.331-6.583c.43-.411 1.18-.411 1.61 0 .43.412.43 1.132 0 1.646z" />
    </g>
  </svg>
)

export default FiltersExpandedIcon
