import { useSelector } from 'react-redux'
import { userFeatureFlagsSelector } from 'store/auth/user/selectors'

/**
 * A component for managing a gate in front of a component depending on the value of
 * a Launch Darkly flag.
 * @param flag An object with a name (the name of the flag in Launch Darkly) and a default value.
 * @param alternateComponent A component to render instead if the component should not
 * be shown.
 * @param children The child component to render if `featureFlags[flag]` returns true. Defaults to `null`
 * because if passed as `undefined` it throws an error.
 */
export default function FeatureFlagComponent({ flag, children = null, alternateComponent = null }) {
  const featureFlags = useSelector(userFeatureFlagsSelector)
  if (featureFlags[flag]) {
    return children
  } else {
    return alternateComponent
  }
}
