import { createAction } from 'redux-actions'

// ------------------------------------
// Types
// ------------------------------------

export const FETCH_TOP_LANES = 'FETCH_TOP_LANES'
export const FETCH_TOP_LANES_SUCCESS = 'FETCH_TOP_LANES_SUCCESS'
export const FETCH_TOP_LANES_FAILURE = 'FETCH_TOP_LANES_FAILURE'

export const types = {
  FETCH_TOP_LANES,
  FETCH_TOP_LANES_SUCCESS,
  FETCH_TOP_LANES_FAILURE,
}

// ------------------------------------
// Actions
// ------------------------------------

export const fetchTopLanes = createAction(types.FETCH_TOP_LANES)
