import { PayloadInterface } from 'store/associatedReferences/interfaces'

export const ASSOCIATED_REFERENCES_KEY = 'associatedReferences'

export const types = {
  FETCH_ASSOCIATED_REFERENCES_START: `${ASSOCIATED_REFERENCES_KEY}/FETCH_ASSOCIATED_REFERENCES_START`,
  FETCH_ASSOCIATED_REFERENCES_SUCCESS: `${ASSOCIATED_REFERENCES_KEY}/FETCH_ASSOCIATED_REFERENCES_SUCCESS`,
  FETCH_ASSOCIATED_REFERENCES_FAILURE: `${ASSOCIATED_REFERENCES_KEY}/FETCH_ASSOCIATED_REFERENCES_FAILURE`,
  FETCH_ASSOCIATED_REFERENCES_RESET_START: `${ASSOCIATED_REFERENCES_KEY}/FETCH_ASSOCIATED_REFERENCES_RESET_START`,
}

export const fetchAssociatedReferences = (payload: PayloadInterface) => {
  return {
    type: types.FETCH_ASSOCIATED_REFERENCES_START,
    payload,
  }
}

export const resetAssociatedReferences = () => ({
  type: types.FETCH_ASSOCIATED_REFERENCES_RESET_START,
})
