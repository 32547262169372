import { all, select, takeEvery } from 'redux-saga/effects'
import { createAction, handleActions } from 'redux-actions'

import get from 'lodash/get'
import logger from 'utils/logger'

const REDUCER_KEY = 'defaultFilters'

const SELECT_INDEX = 'defaultFilters/SELECT_INDEX'
const UNSELECT_INDEX = 'defaultFilters/UNSELECT_INDEX'

export const selectIdx = createAction(SELECT_INDEX)
export const unselectDefaultFilter = createAction(UNSELECT_INDEX)

export const defaultFiltersSelector = state => state[REDUCER_KEY].filters
export const configSelector = state => state[REDUCER_KEY].config
export const currentFilterSelector = state => {
  const idx = state[REDUCER_KEY].selectedIdx
  return idx >= 0 ? state[REDUCER_KEY].filters[idx] : null
}

const initialState = {
  selectedIdx: -1,
  dialogIsOpen: false,
  config: null,
  filters: [
    {
      id: 'stuck_transshipment',
      name: 'Stuck in transshipment',
      title: 'Shipment has been stuck at transshipment port for',
      requiresConfiguration: true,
      description:
        'This set of filters identifies shipments that have been stuck at their transshipments for a certain amount of time (which you can configure below). ' +
        ' The "Current Milestone Type" filter specifies that the shipment most recently arrived at it\'s transshipment port or it discharged at it\'s transshipment port.' +
        ' The "Current Milestone Time" filter specifies that we have not received a new event for the shipment for a certain number of days.',
      getKeyValues: config => {
        let lowerBound = get(config, 'lowerBound')
        let upperBound = get(config, 'upperBound')
        if (Number.isInteger(lowerBound)) lowerBound = -1 * Math.abs(lowerBound)
        if (Number.isInteger(upperBound)) upperBound = -1 * Math.abs(upperBound)
        return [
          {
            key: 'latest_milestone_type',
            value: [
              {
                label: 'Vessel arrive at transshipment port',
                type: 'Transshipment',
                value: 'vessel_arrive_transshipment',
              },
              {
                label: 'Container discharge for transshipment',
                type: 'Transshipment',
                value: 'vessel_discharge_transshipment',
              },
            ],
          },
          {
            key: 'latest_milestone_time',
            value: {
              startDate: upperBound,
              type: 'relative',
              relativeType: 'past',
              endDate: lowerBound,
            },
          },
        ]
      },
    },
    {
      id: 'departed_origin',
      name: 'Departed origin port',
      hint:
        'If you want to find shipments that have departed from a specific port, click apply and then try adding an additional filter for the discharge location',
      description: 'This filter identifies shipments that have departed from their origin port.',
      requiresConfiguration: false,
      getKeyValues: () => {
        return [
          {
            key: 'latest_milestone_type',
            value: [
              {
                label: 'Vessel depart origin port',
                type: 'Port of loading',
                value: 'vessel_depart_origin',
              },
            ],
          },
        ]
      },
    },
    {
      id: 'arrived_destination',
      name: 'Arrived at final port',
      hint:
        'If you want to find shipments that have arrived at a specific port, click apply and then try adding an additional filter for the discharge location',
      description: 'This filter identifies shipments that have arrived at their final port.',
      requiresConfiguration: false,
      getKeyValues: () => {
        return [
          {
            key: 'latest_milestone_type',
            value: [
              {
                label: 'Vessel arrive at dest. port',
                type: 'Port of discharge',
                value: 'vessel_arrive_destination',
              },
            ],
          },
        ]
      },
    },
    {
      id: 'discharge_destination',
      name: 'Discharged at final port',
      hint:
        'If you want to find shipments that have discharged at a specific port, click apply and then try adding an additional filter for the discharge location',
      description: 'This filter identifies shipments that have discharged at their final port.',
      requiresConfiguration: false,
      getKeyValues: () => {
        return [
          {
            key: 'latest_milestone_type',
            value: [
              {
                label: 'Container discharge at dest. port',
                type: 'Port of discharge',
                value: 'vessel_discharge_destination',
              },
            ],
          },
        ]
      },
    },
    {
      id: 'delayed',
      name: 'Delayed shipments',
      title: 'project44 predicts shipment delay is',
      description: 'This filter identifies shipments that project44 predicts will be delayed!',
      requiresConfiguration: true,
      getKeyValues: config => {
        const lowerBound = get(config, 'lowerBound')
        const upperBound = get(config, 'upperBound')
        return [
          {
            key: 'ocean_delay',
            value: { lowerBound, upperBound, type: 'relative' },
          },
        ]
      },
    },
    {
      id: 'predicted_discharge',
      name: 'Predicted discharge at final port',
      title: 'project44 predicts shipment will discharge at final port in',
      description:
        'This filter identifies shipments that project44 predicts will discharge at its final port within a certain amount of time (which you can configure below)',
      hint:
        'If you want to find shipments that will arrive at a specific port, click apply and then try adding an addition filter for the discharge location',
      requiresConfiguration: true,
      getKeyValues: config => {
        const lowerBound = get(config, 'lowerBound')
        const upperBound = get(config, 'upperBound')
        return [
          {
            key: 'pred_discharge_time',
            value: { startDate: lowerBound, endDate: upperBound, type: 'relative' },
          },
        ]
      },
    },
    {
      id: 'predicted_available',
      name: 'Predicted available for pickup',
      title: 'project44 predicts shipment will be available for pickup at final port in',
      description:
        'This filter identifies shipments that project44 predicts will be available for pickup within a certain amount of time (which you can configure below)',
      requiresConfiguration: true,
      getKeyValues: config => {
        const lowerBound = get(config, 'lowerBound')
        const upperBound = get(config, 'upperBound')
        return [
          {
            key: 'pred_available_time',
            value: { startDate: lowerBound, endDate: upperBound, type: 'relative' },
          },
        ]
      },
    },
  ],
}

function* logEventAsync({ payload: idx }) {
  const filters = yield select(defaultFiltersSelector)
  const defaultFilter = idx >= 0 && idx < filters.length ? filters[idx] : {}
  const defaultName = get(defaultFilter, 'name', 'N/A')
  logger.notify('Default Filter Selected', { name: defaultName })
}

function* watchLogEvents() {
  yield all([takeEvery(SELECT_INDEX, logEventAsync)])
}

export const sagas = [watchLogEvents()]

export default handleActions(
  {
    [SELECT_INDEX]: (state, { payload }) => ({
      ...state,
      selectedIdx: payload,
    }),
    [UNSELECT_INDEX]: (state, { payload }) => ({
      ...state,
      selectedIdx: -1,
    }),
  },
  initialState
)
