import { Dialog, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core'
import { currentTenantFromState, emailFromState } from 'store/auth/user/selectors'
import { listTenants, switchTenant } from 'store/auth/tenant/actions'
import { tenantListFromState, tenantLoadingFromState } from 'store/auth/tenant/selectors'

import AdminButton from './AdminButton'
import Button from 'components/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import ListTenants from './ListTenants'
import PropTypes from 'prop-types'
import React from 'react'
import SwitchTenantConfirmation from 'pages/Admin/AdminHomePage/SwitchTenantConfirmation'
import SwitchTenantIcon from '@material-ui/icons/Repeat'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {},
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  dialogPaper: {
    minWidth: 700,
    minHeight: 500,
  },
  currentTenant: {
    fontSize: 25,
    color: theme.brand.color,
  },
  confirm: {
    color: 'red',
    fontSize: 20,
  },
  switchButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.brand.color,
  },
  switchLabel: {
    color: 'white',
  },
  flex: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
})

class SwitchTenant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      tenant: null,
      confirmOpen: false,
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  handleCancel = () => {
    this.setState({
      open: false,
      tenant: null,
      confirmOpen: false,
    })
  }

  openDialog = () => {
    this.setState({
      open: true,
    })
  }

  handleTenantClick = tenant => {
    this.setState({
      tenant,
      open: false,
      confirmOpen: true,
    })
  }
  handleSwitch = () => {
    const { email } = this.props
    const { tenant } = this.state
    this.props.switchTenant(tenant, email)
  }

  renderTenantConfirmation = () => {
    const { classes, currentTenant } = this.props
    const { tenant } = this.state
    return (
      <div className={classes.flex}>
        <Typography variant="body2" className={classes.confirm}>
          Switch from {currentTenant} to {tenant}
        </Typography>
        <Button
          classes={{ root: classes.switchButton, label: classes.switchLabel }}
          onClick={this.handleSwitch}
        >
          Switch
        </Button>
      </div>
    )
  }

  render() {
    const { open, tenant, confirmOpen } = this.state
    const { classes, currentTenant, email } = this.props
    return (
      <div className={classes.root}>
        <AdminButton onClick={this.openDialog} text={'Switch Tenant'} icon={<SwitchTenantIcon />} />
        <Dialog open={open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>
            Switch Tenant
            <CloseIcon className={classes.closeIcon} onClick={this.handleCancel} />
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" className={classes.currentTenant}>
              Current tenant: {currentTenant}
            </Typography>
            <ListTenants tenantClick={this.handleTenantClick} />
          </DialogContent>
        </Dialog>
        {tenant && (
          <SwitchTenantConfirmation
            email={email}
            tenant={tenant}
            open={confirmOpen}
            onClose={this.handleCancel}
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = {
  listTenants,
  switchTenant,
}

const mapStateToProps = state => {
  return {
    tenants: tenantListFromState(state),
    loading: tenantLoadingFromState(state),
    currentTenant: currentTenantFromState(state),
    email: emailFromState(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SwitchTenant))
