import { Box, Tooltip, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import Dropdown from 'components/Dropdown'
import InfoIcon from 'components/icons/deprecated/Info'
import PropTypes from 'prop-types'
import React from 'react'
import { TRANSPORTATION_STATUS_PARAM_NAME } from 'utils/querystring'
import { TransportationStatusOptions } from 'store/boardUtils'
import logger from 'utils/logger'
import { setQueryStringParam } from 'utils/urlBuilder'
import { setTransportationStatus } from 'store/boardUtils/actions'
import { transportationStatusSelector } from 'store/boardUtils/selectors'

const DROPDOWN_ITEMS = [
  {
    name: 'View Active Shipments Only',
    value: TransportationStatusOptions.VIEW_ACTIVE_ONLY,
  },
  {
    name: 'View Idle and Completed Shipments Only',
    value: TransportationStatusOptions.VIEW_IDLE_AND_COMPLETED,
  },
  {
    name: 'View All Shipments (Active, Idle, and Completed)',
    value: TransportationStatusOptions.VIEW_ALL,
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: `5px 0 20px 9px`,
  },
  showActiveDropdown: {
    marginRight: 5,
  },
  showActiveButton: {
    width: 235,
    background: theme.palette.white,
  },
  showActiveIcon: {
    color: theme.palette.grey[400],
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

export default function TransportationStatusDropdown(props) {
  const { dataTestId } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const transportationStatus = useSelector(transportationStatusSelector)

  const getSelectedIndex = () => {
    const optionsArray = DROPDOWN_ITEMS.map(item => item.value)
    const selectedOption = optionsArray.indexOf(transportationStatus)
    return selectedOption !== -1 ? selectedOption : 0
  }

  const handleSelection = el => {
    if (transportationStatus !== el.value) {
      dispatch(setTransportationStatus(el.value))
      // Set the query string param.
      setQueryStringParam(TRANSPORTATION_STATUS_PARAM_NAME, el.value.toLowerCase())
      logger.notify('Active Only Dropdown Selection', { selection: el.value.description })
    }
  }

  return (
    <div className={classes.root} data-testid={dataTestId}>
      <Dropdown
        classes={{ root: classes.showActiveDropdown, button: classes.showActiveButton }}
        items={DROPDOWN_ITEMS}
        defaultIdx={getSelectedIndex()}
        handleSelection={el => handleSelection(el)}
        handleOpen={() => {
          logger.notify('Active Only Dropdown Open')
        }}
      />
      <Tooltip
        title={
          <Box className={classes.title}>
            <Box>
              <strong>Active</strong>: Shipment is in transit and recent activity has been reported
              by the carrier.
            </Box>
            <Box mt={1}>
              <strong>Idle</strong>: Shipment is in transit but no recent activity has been reported
              by the carrier.
            </Box>
            <Box mt={1}>
              <strong>Completed</strong>: Shipment has reached its final destination and empty
              containers or equipment have been returned.
            </Box>
          </Box>
        }
      >
        <span>
          <InfoIcon className={classes.showActiveIcon} fontSize="small" />
        </span>
      </Tooltip>
    </div>
  )
}

TransportationStatusDropdown.propTypes = {
  classes: PropTypes.object,
  dataTestId: PropTypes.string,
}
