import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  createShare: ({ refType, refIds }) => {
    return api._call('post', '/share', { refType, refIds })
  },
  updateShare: ({ guid, name }) => {
    return api._call('put', '/share', { guid, name })
  },
  deleteShare: ({ guid }) => {
    return api._call('delete', '/share', { guid })
  },
  fetchShares: ({ refType, refIds }) => {
    return api._call('get', '/share', { refType, refIds })
  },
  sendShareEmail: ({ guid, emails, subject, text }) => {
    return api._call('post', '/share/email', { guid, emails, subject, text })
  },
}

export default api
