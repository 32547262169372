/* global __SENTRY_DSN__ */
/* global __LOCAL_DEV__ */
/* global __REDUX_LOGGER__ */
import * as Sentry from '@sentry/browser'

import { applyMiddleware, compose, createStore as createReduxStore } from 'redux'
import { currentTenantFromState, emailFromState } from 'store/auth/user/selectors'

import { all } from 'redux-saga/effects'
import { apiSagas } from 'store/api'
import { authSagas } from 'store/auth'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import createSentryMiddleware from 'redux-sentry-middleware'
import logger from 'utils/logger'
import makeRootReducer from 'store/reducers'
import { storeSagas } from 'store/storeSagas'
import thunk from 'redux-thunk'

const sagaMiddlewareOnError = (err, sagaStack) => {
  logger.error({
    source: 'sagaMiddlewareOnError error',
    err,
    sagaStack,
  })
  return logger.captureAppException('sagaMiddlewareOnError error', {
    err,
    sagaStack,
  })
}
Sentry.init({
  dsn: __SENTRY_DSN__,
})

function* rootSaga() {
  yield all([...apiSagas, ...authSagas, ...storeSagas])
}

const createStore = (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const sagaMiddleware = createSagaMiddleware({ onError: sagaMiddlewareOnError })
  const sentryMiddleware = createSentryMiddleware(Sentry, {
    getUserContext: state => {
      return {
        user: emailFromState(state),
        client: currentTenantFromState(state),
      }
    },
  })
  const middleware = [thunk, sagaMiddleware, sentryMiddleware]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []
  let composeEnhancers = compose

  if (__LOCAL_DEV__) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      if (__REDUX_LOGGER__) {
        const reduxLogger = createLogger({
          duration: true,
          level: 'info',
        })
        middleware.push(reduxLogger)
      }
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  )

  sagaMiddleware.run(rootSaga)

  store.asyncReducers = {}

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}

export default createStore
