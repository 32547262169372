import { types } from './actions'

export const PASSWORD_RESET_EMAIL_ACTION_HANDLERS = {
  [types.PASSWORD_RESET_EMAIL_LOAD]: (state, action) => ({
    ...state,
    passwordResetEmail: {
      ...state.passwordResetEmail,
      loading: true,
    },
  }),
  [types.PASSWORD_RESET_EMAIL_SUCCESS]: (state, action) => ({
    ...state,
    passwordResetEmail: {
      ...state.passwordResetEmail,
      loading: false,
    },
  }),
}
