import { Chip, TextField, makeStyles } from '@material-ui/core'
import { DELETE, EMPTY, ENTER } from 'utils/syntheticEvents'

import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  chipPadding: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  inputRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(2),
  },
  input: {
    paddingTop: theme.spacing(2),
    paddingBottom: '0',
    minWidth: '50%',
    width: '30%',
  },
  adornmentRoot: {
    marginTop: '0 !important',
  },
  chipError: {
    color: theme.palette.red[400],
  },
}))

ChipInput.propTypes = {
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  onAddRequest: PropTypes.func.isRequired,
  onDeleteRequest: PropTypes.func.isRequired,
  chipConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      valid: PropTypes.bool.isRequired,
    })
  ),
  newChipSyntheticKeyCodes: PropTypes.arrayOf(PropTypes.string),
  onChipSelect: PropTypes.func.isRequired,
  ChipProps: PropTypes.object,
  InputProps: PropTypes.shape({
    fullWidth: PropTypes.bool,
  }),
  endAdornment: PropTypes.node,
}

ChipInput.defaultProps = {
  newChipSyntheticKeyCodes: [ENTER],
  required: false,
  InputProps: {
    fullWidth: false,
  },
}

function ChipInput({
  chipConfigs,
  onDeleteRequest,
  className,
  required,
  onChipSelect,
  onAddRequest,
  newChipSyntheticKeyCodes,
  onBlur,
  helperText,
  error,
  ChipProps,
  InputProps,
  endAdornment,
  ...rest
}) {
  const classes = useStyles()

  function handleChipUpdate(e) {
    const value = e.target.value

    // Delete Chip
    if (e.key === DELETE && value === EMPTY) {
      onDeleteRequest(chipConfigs.length - 1, e)
    }

    // Add Chip
    if (newChipSyntheticKeyCodes.includes(e.key) && value !== EMPTY) {
      onAddRequest(e)
    }
  }

  return (
    <TextField
      variant="filled"
      className={cx(className, { [classes.fullWidth]: InputProps.fullWidth })}
      required={required && chipConfigs.length === 0}
      onKeyDown={handleChipUpdate}
      InputProps={{
        onBlur: onBlur,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
        startAdornment: chipConfigs.map((config, index) => (
          <div key={index} className={classes.chipPadding}>
            <Chip
              variant="outlined"
              classes={{ root: cx({ [classes.chipError]: !config.valid }) }}
              key={index}
              onClick={e => onChipSelect(index, e)}
              onDelete={e => onDeleteRequest(index, e)}
              clickable={Boolean(onChipSelect)}
              label={config.label}
              avatar={config.icon}
              {...ChipProps}
            />
          </div>
        )),
        endAdornment: endAdornment,
      }}
      error={error}
      helperText={helperText}
      {...rest}
    />
  )
}

export default ChipInput
