import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  authActions: {
    paddingTop: theme.spacing(3),
    width: '100%',
  },
}))

interface Props {
  className?: string
  children: React.ReactNode
}

const AuthActions = (props: Props) => {
  const { className, children } = props
  const classes = useStyles()

  return <span className={classnames([classes.authActions, className])}>{children}</span>
}

export default AuthActions
