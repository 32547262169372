import { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import { TagInterface } from 'utils/jsonLogic/utils/legacyBetweenToJsonLogic'

export interface FilterInterface extends Omit<TagInterface, 'value'> {
  entityType: ApiRefTypeType
  filterRefType: 'shipment' | 'order' | 'line_item'
  // Has to account for all possible JsonLogic returns
  value:
    | string
    | string[]
    | boolean
    | number
    | { [key: string]: [Record<string, string>] | undefined }
    | {
        '+': (
          | { $utc_now: never[]; $timedelta?: undefined }
          | { $utc_now?: undefined; $timedelta: (string | number)[][] }
        )[]
      }
    | undefined
}

/**
 * Takes in an array of filters for custom tags and returns JsonLogic using `$subquery` to match the backend.
 * @param filters array of filter objects
 * @returns JsonLogic object
 * @example response:
 * ```
 * {
 * '$subquery': [
 *    [ 'entity_type', 'shipments' ],
 *    [
 *      'filter',
 *      {
 *        in: [
 *          { var: 'order.other.csr_name' },
 *          [ 'AFERNAND', 'AILEEN LIANG', 'LBONILLA' ]
 *       ]
 *      }
 *    ]
 *  ]
 * }
 */
const getCustomTagJsonLogic = (filters: FilterInterface[]) => {
  let jsonLogic: {}[] = []
  filters.forEach(filter => {
    const filterJsonLogic = ['filter', { [filter.operator]: [{ var: filter.key }, filter.value] }]
    jsonLogic.push({
      [filter.operator]: [
        { var: `${filter.filterRefType}.internal_id` },
        { $subquery: [['entity_type', filter.entityType], filterJsonLogic] },
      ],
    })
  })

  return {
    or: jsonLogic,
  }
}

export default getCustomTagJsonLogic
