import { httpMethods } from 'utils/api/client'
import humps from 'humps'

const api = {
  _call: (method, endpoint, params) => {
    const args = params ? humps.decamelizeKeys(params) : null
    return httpMethods[method](endpoint, args)
  },
  fetchPortalUsers: () => {
    return api._call('get', '/portal/users')
  },
  createPortalUser: payload => {
    return api._call('post', `/portal/users`, payload)
  },
  deletePortalUser: ({ externalId }) => {
    return api._call('delete', '/portal/users', { externalId })
  },
}

export default api
