import HighCharts from 'components/HighChart/config'
import { useEffect } from 'react'

export const useHighchartsReflow = deps => {
  useEffect(() => {
    setTimeout(() => {
      HighCharts.charts.forEach(chart => chart && chart.reflow())
    }, 300)
  }, [deps])
}
