import get from 'lodash/get'
import logger from 'utils/logger'
import store from 'store'
import { types } from './actions'

export const IMPERSONATE_ACTION_HANDLERS = {
  [types.IMPERSONATE_LOAD]: (state, action) => ({
    ...state,
    login: {
      ...state.login,
      loading: action.payload,
    },
    impersonate: {
      ...state.impersonate,
      loading: action.payload,
    },
  }),
  [types.IMPERSONATION_STOPPED]: (state, action) => ({
    ...state,
    impersonate: {
      ...state.impersonate,
      isImpersonating: false,
    },
  }),
  [types.IMPERSONATE_SUCCESS]: (state, action) => ({
    ...state,
    login: {
      ...state.login,
      loggedIn: true,
      loading: false,
    },
    impersonate: {
      ...state.impersonate,
      isImpersonating: true,
      loading: false,
    },
  }),
  [types.IMPERSONATE_FAILURE]: (state, action) => ({
    ...state,
    login: {
      ...state.login,
      loading: false,
    },
    impersonate: {
      ...state.impersonate,
      loading: false,
    },
  }),
}

// store isImpersonating in localStorage so that refreshing after impersonating someone will not cause Mixpanel events
// to be logged. this makes it so we save this value to localStorage without making Redux reducers impure
export const persistIsImpersonatingToLocalStorage = reduxStore => {
  return () => {
    const state = reduxStore.getState()
    const isImpersonating = get(state, 'auth.impersonate.isImpersonating', false)
    store.set('isImpersonating', isImpersonating)
  }
}

export const checkForImpersonators = reduxStore => {
  return () => {
    const state = reduxStore.getState()
    // use Redux state in case this is called before persistIsImpersonatingToLocalStorage() above
    logger.isImpersonating =
      get(state, 'auth.impersonate.isImpersonating', false) || Boolean(store.get('isImpersonating'))
  }
}
