import {
  CONSECUTIVE_FAILED_LOGINS_SHOW_BLOCKED,
  CONSECUTIVE_FAILED_LOGINS_SHOW_WARNING,
} from 'store/auth/constants'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import api from '../api'
import browserHistory from 'utils/history'
import { consecutiveFailedLoginsFromState } from 'store/auth/login/selectors'
import get from 'lodash/get'
import { impersonationStopped } from 'store/auth/impersonate/actions'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import { setLoginErrorMessage } from 'store/auth/login/actions'
import { types } from '../actions'
import { userInfoAsync } from 'store/auth/user/sagas'

// No model here because we get and use one property, `access_token`,
// and it is never passed to the UI.
export function* loginAsync(action) {
  const { username, password, forceReload, redirect } = action.payload
  try {
    yield call(api.doLogin, { username, password })
    yield put(impersonationStopped())
    yield call(userInfoAsync)
    yield put({ type: types.LOGIN_SUCCESS })
    yield put(setLoginErrorMessage(null))
    yield put({ type: types.RESET_CONSECUTIVE_FAILED_LOGINS })
    const isExternalUser = yield select(isExternalUserSelector)
    const msg = isExternalUser ? 'Portal User Login' : 'User Login'
    logger.notify(msg, { email: username })

    if (redirect) {
      yield browserHistory.push(redirect)
    }
    if (forceReload) {
      window.location.reload() // reload page so that no previous redux state is present in new session
    }
  } catch (e) {
    const status = get(e, 'response.status')
    let msg = MESSAGES.loginCredentialsError

    if (status === 401) {
      yield put({ type: types.INCREMENT_CONSECUTIVE_FAILED_LOGINS })
      const currentConsecutiveFailedLogins = yield select(consecutiveFailedLoginsFromState)
      if (
        currentConsecutiveFailedLogins >= CONSECUTIVE_FAILED_LOGINS_SHOW_WARNING &&
        currentConsecutiveFailedLogins < CONSECUTIVE_FAILED_LOGINS_SHOW_BLOCKED
      ) {
        msg = MESSAGES.multipleConsecutiveFailedLogins
      } else if (currentConsecutiveFailedLogins >= CONSECUTIVE_FAILED_LOGINS_SHOW_BLOCKED) {
        msg = MESSAGES.maxConsecutiveFailedLogins
      }
    } else if (status === 403) {
      msg = MESSAGES.loginActivationError
    } else if (status === 429) {
      msg = MESSAGES.maxConsecutiveFailedLogins
    }

    yield put(setLoginErrorMessage(msg))
    logger.notify('Login Failed', { error: e.message, user: username })
    yield put({ type: types.LOGIN_FAILURE })
  }
}

export function* watchLogin() {
  yield takeEvery(types.LOGIN_LOAD, loginAsync)
}
