import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useState } from 'react'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { passwordValidations } from './validationUtils'

const useStyles = makeStyles(theme => ({
  requirement: {
    textAlign: 'initial',
  },
  requirements: {
    marginTop: theme.spacing(2),
    textAlign: 'initial',
    alignSelf: 'flex-start',
  },
  requirementList: {
    marginTop: theme.spacing(1),
    paddingInlineStart: `${theme.spacing(2)}px`,
  },
}))

function PasswordTextField({
  className,
  error,
  helperText,
  label = 'Password',
  margin = 'normal',
  name = 'password',
  onBlur,
  onChange,
  value,
  variant = 'filled',
  ...rest
}) {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <TextField
        error={error}
        className={className}
        helperText={helperText}
        label={label}
        margin={margin}
        name={name}
        InputProps={{
          type: showPassword ? 'text' : 'password',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onBlur={onBlur}
        onChange={onChange}
        variant={variant}
        value={value}
        {...rest}
      />
      <Box>
        <Typography variant="body2" component="span" className={classes.requirements}>
          project44 wants to keep your data and account information safe.
          <br />
          Please use a strong password with the following requirements:
          <ul className={classes.requirementList}>
            {passwordValidations.map(({ helperText }, index) => (
              <li key={index} className={classes.requirement}>
                {helperText}
              </li>
            ))}
          </ul>
        </Typography>
      </Box>
    </>
  )
}

export default PasswordTextField
