import humps from 'humps'
import { toTitleCase } from 'utils/strUtils'

export interface LocationZoneInterface {
  country: string
  countryCode: string
  externalId: string
  id: number
  lat: number
  lon: number
  name: string
  timezone: string | null
  unlocode: string
}

export default class LocationZone {
  constructor({
    country,
    countryCode,
    externalId,
    id,
    lat,
    lon,
    name,
    timezone,
    unlocode,
  }: LocationZoneInterface) {
    Object.assign(this, {
      country,
      countryCode,
      externalId,
      id,
      lat,
      lon,
      name,
      timezone,
      unlocode,
    })
  }

  static of(payload: object) {
    const {
      country,
      countryCode,
      externalId,
      id,
      lat,
      lon,
      name,
      timezone,
      unlocode,
    } = humps.camelizeKeys(payload) as LocationZoneInterface
    return new LocationZone({
      country,
      countryCode,
      externalId,
      id,
      lat,
      lon,
      name: name && toTitleCase(name),
      timezone,
      unlocode,
    })
  }
}
