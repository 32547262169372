import { Box, Paper, Theme, makeStyles } from '@material-ui/core'

import CardOverlay from 'components/core/CardOverlay'
import React from 'react'
import Skeleton from 'components/Timeline/Skeleton'
import SkeletonRow from 'components/Timeline/TimelineHeader/Skeleton'

const TABLE_MIN_WIDTH = 1100
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(5),
    minWidth: TABLE_MIN_WIDTH,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    position: 'relative',
  },
  tabsWrapper: {
    borderTop: `2px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    background: theme.palette.grey[100],
    boxShadow: `0 50vh 0 50vh ${theme.palette.grey[100]}`,
    height: '100%',
    marginTop: theme.spacing(3),
  },
  cardContainer: {
    padding: theme.spacing(0, 2, 1, 1),
  },
  overlay: {
    top: 130,
  },
}))

interface Props {
  classes?: Record<string, string>
  isAnimated?: boolean
  message?: string | React.ReactNode
  rowCount?: number
  showExpandedView?: boolean
}

/**
 * A skeleton loader for the board timeline and entity page timeline cards. Includes the entity subheader and timeline.
 * `showExpandedView` includes a checkbox and an additional cell, currently used for board Timeline View
 */
const TimelineCardSkeleton = (props: Props) => {
  const { isAnimated = true, rowCount = 2, message, showExpandedView = false } = props
  const classes = useStyles(props)

  const rowsToRender = isNaN(rowCount) ? 2 : rowCount

  return (
    <Box className={classes.contentWrapper}>
      {/* Will show a single overlay near the top no matter how many rows are rendered */}
      {message && (
        <CardOverlay classes={{ root: classes.overlay }}>
          <>{message}</>
        </CardOverlay>
      )}
      <Box className={classes.tabsWrapper}>
        <Box className={classes.container}>
          <Box display="flex" flexDirection="column">
            {[...Array(rowsToRender)].map((val: number, idx: number) => (
              <Box pt={3} key={idx}>
                <Paper elevation={4}>
                  <Box className={classes.cardContainer}>
                    <Box mb={1}>
                      <SkeletonRow isAnimated={isAnimated} showExpandedView={showExpandedView} />
                      <Skeleton isAnimated={isAnimated} />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TimelineCardSkeleton
