import { AUTH_REDUCER_KEY } from 'store/auth/constants'
import { createAction } from 'redux-actions'

export const types = {
  TENANT_SWITCH_LOAD: `${AUTH_REDUCER_KEY}tenant/TENANT_SWITCH_LOAD`,
  TENANT_SWITCH_SUCCESS: `${AUTH_REDUCER_KEY}tenant/TENANT_SWITCH_SUCCESS`,
  TENANT_ADD_LOAD: `${AUTH_REDUCER_KEY}tenant/TENANT_ADD_LOAD`,
  TENANT_ADD_SUCCESS: `${AUTH_REDUCER_KEY}tenant/TENANT_ADD_SUCCESS`,
  SOURCE_ADD_LOAD: `${AUTH_REDUCER_KEY}tenant/SOURCE_ADD_LOAD`,
  SOURCE_ADD_SUCCESS: `${AUTH_REDUCER_KEY}tenant/SOURCE_ADD_SUCCESS`,
  TENANT_LIST_LOAD: `${AUTH_REDUCER_KEY}tenant/TENANT_LIST_LOAD`,
  TENANT_LIST_SUCCESS: `${AUTH_REDUCER_KEY}tenant/TENANT_LIST_SUCCESS`,
  UPDATE_SUB_TENANTS: `${AUTH_REDUCER_KEY}UPDATE_SUB_TENANTS`,
}

export const switchTenant = createAction(
  types.TENANT_SWITCH_LOAD,
  (tenant, username, password) => ({
    username,
    tenant,
    password,
  })
)

export const addTenant = createAction(types.TENANT_ADD_LOAD, (name, displayName) => ({
  name,
  displayName,
}))

export const addSource = createAction(types.SOURCE_ADD_LOAD, source => source)

export const listTenants = createAction(types.TENANT_LIST_LOAD)

export const updateSubTenants = createAction(types.UPDATE_SUB_TENANTS)
