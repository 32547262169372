const toRows = apiResponse =>
  apiResponse.map(
    ({
      created_at,
      download_uuid,
      entity_type,
      file_name,
      last_download_on,
      num_entities,
      percent_complete,
      status_name,
      status,
    }) => {
      return {
        count: num_entities,
        createdAt: created_at,
        downloadedOn: last_download_on,
        entity: entity_type,
        fileName: file_name,
        id: download_uuid,
        percentComplete: percent_complete,
        status: status,
        statusLabel: status_name,
      }
    }
  )

export default toRows
