import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useState } from 'react'

import Button from 'components/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import classnames from 'classnames'
import { updateShare } from 'store/share/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  inline: {
    display: 'inline-block',
  },
  iconButton: {
    fontSize: 18,
  },
  saveButton: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
}))

export default function RenameShareButton({ item }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(item.name || undefined)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={'Edit Group Name'}>
        <IconButton className={classnames(classes.inline, classes.iconButton)} onClick={onOpen}>
          <EditIcon fontSize={'inherit'} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Group Name</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              label={'Name'}
              value={name}
              onChange={e => setName(e.target.value)}
              onClearInput={() => setName(undefined)}
              variant="filled"
            />
          </div>
          <Button
            className={classes.saveButton}
            onClick={() => {
              dispatch(updateShare({ guid: item.guid, name }))
              onClose()
            }}
            color={'primary'}
            variant={'contained'}
          >
            Save
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
