import { Location } from 'utils/shipmentObjs'
import get from 'lodash/get'

const structureLocation = location => {
  const name = get(location, 'name')
  const unlocode = get(location, 'unlocode')
  const timezone = get(location, 'timezone')
  const lat = get(location, 'lat')
  const lon = get(location, 'lon')
  return new Location(name, unlocode, timezone, lat, lon)
}

export default structureLocation
