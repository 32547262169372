import { Box, Theme, makeStyles } from '@material-ui/core'

import CardOverlay from 'components/core/CardOverlay'
import React from 'react'
import Skeleton from 'components/Skeleton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  line: {
    flex: 1,
    marginTop: 20,
    marginLeft: 4,
    marginRight: 4,
  },
  timeline: {
    '& > div': {
      display: 'flex',
      flex: 3,
    },
    // Fix the last segment width so that they always line up when multiple timelines
    '& > div:last-child': {
      flex: 1,
    },
  },
  milestoneList: {
    flexDirection: 'column',
  },
  smallGutter: {
    marginBottom: 4,
    marginTop: 0,
  },
}))

interface Props {
  isAnimated: boolean
}

function SkeletonRow(props: Props) {
  const { isAnimated } = props
  const classes = useStyles()

  const lowerCircleSize = 24
  const lineHeight = 4

  return (
    <Box mt={1} mx={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={classes.timeline}
      >
        <Box>
          <Skeleton
            borderRadius="circular"
            height={lowerCircleSize}
            isAnimated={isAnimated}
            width={lowerCircleSize}
          />
          <Skeleton
            className={classes.line}
            gutter={false}
            height={lineHeight}
            isAnimated={isAnimated}
          />
        </Box>

        <Box>
          <Skeleton
            borderRadius="circular"
            height={lowerCircleSize}
            isAnimated={isAnimated}
            width={lowerCircleSize}
          />
          <Skeleton
            className={classes.line}
            gutter={false}
            height={lineHeight}
            isAnimated={isAnimated}
          />
        </Box>

        <Box>
          <Skeleton
            borderRadius="circular"
            height={lowerCircleSize}
            isAnimated={isAnimated}
            width={lowerCircleSize}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={classes.timeline}
      >
        <Box className={classes.milestoneList}>
          <Skeleton
            isAnimated={isAnimated}
            className={classes.smallGutter}
            gutter={false}
            width="80px"
          />
          <Skeleton
            isAnimated={isAnimated}
            className={classes.smallGutter}
            gutter={false}
            width="120px"
          />
        </Box>
        <Box className={classes.milestoneList}>
          <Skeleton
            isAnimated={isAnimated}
            className={classes.smallGutter}
            gutter={false}
            width="80px"
          />
          <Skeleton
            isAnimated={isAnimated}
            className={classes.smallGutter}
            gutter={false}
            width="120px"
          />
        </Box>
        <Box className={classes.milestoneList}>
          <Skeleton
            isAnimated={isAnimated}
            className={classes.smallGutter}
            gutter={false}
            width="80px"
          />
          <Skeleton
            isAnimated={isAnimated}
            className={classes.smallGutter}
            gutter={false}
            width="120px"
          />
        </Box>
      </Box>
    </Box>
  )
}

interface TimelineSkeletonProps {
  message?: string | React.ReactNode
  rowCount?: number
  isAnimated?: boolean
  cardOverlayClasses?: Record<string, string>
}

export default function TimelineSkeleton(props: TimelineSkeletonProps) {
  const { message = '', rowCount = 1, isAnimated = true, cardOverlayClasses = {} } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        {[...Array(rowCount)].map((_, i) => (
          <SkeletonRow isAnimated={isAnimated} key={i} />
        ))}
      </div>
      {message && (
        <CardOverlay classes={cardOverlayClasses}>
          <>{message}</>
        </CardOverlay>
      )}
    </div>
  )
}
