import React, { useState } from 'react'

type SelectedIndex = number
type Value = string

type DetailRadioProps = {
  children: React.ReactNode
  defaultSelectedIndex: SelectedIndex
  onChange: (value: Value) => void
}

const DetailRadio = (props: DetailRadioProps) => {
  const { children, defaultSelectedIndex = 0, onChange } = props
  const [selectedIndex, setSelectedIndex] = useState<SelectedIndex>(defaultSelectedIndex)

  const handleSelect = (index: SelectedIndex) => (value: Value) => {
    if (onChange) {
      onChange(value)
    }
    setSelectedIndex(index)
  }

  const childrenWithProps = React.Children.toArray(children).map((child, index) => {
    return React.cloneElement(child as React.ReactElement<any>, {
      checked: selectedIndex === index,
      onSelected: handleSelect(index),
    })
  })

  return <div className="radio-container">{childrenWithProps}</div>
}

export default DetailRadio
