import { Card, PropTypes, Theme, Typography, makeStyles } from '@material-ui/core'

import React from 'react'

const DEFAULT_WIDTH = 400
const DEFAULT_ALIGNMENT = 'center'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[500],
    border: `1px solid ${theme.palette.grey[800]}`,
    boxShadow: 'none',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 3,
  },
  cardContent: {
    color: theme.palette.white,
    padding: 16,
  },
}))

interface Props {
  align?: PropTypes.Alignment
  children: React.ReactElement
  classes?: Record<string, string>
  width?: number
}

const CardOverlay = (props: Props) => {
  const classes = useStyles(props)
  const { width = DEFAULT_WIDTH, align = DEFAULT_ALIGNMENT, children } = props

  return (
    <Card className={classes.root} style={{ width }}>
      <Typography variant="h6" className={classes.cardContent} align={align}>
        {children}
      </Typography>
    </Card>
  )
}

export default CardOverlay
