import findIndex from 'lodash/findIndex'

const isMatch = (route, compareRoute) => {
  const milestoneMatches = []
  for (let i = 0; i < route.milestones.length; i++) {
    let milestone = route.milestones[i]
    if (milestone.type === 'vessel') continue // ignore vessel milestones
    const matchingIdx = findIndex(
      compareRoute.milestones,
      otherMilestone => otherMilestone.id === milestone.id
    )
    if (matchingIdx === -1) {
      //if no match
      return false
    } else {
      milestoneMatches.push(matchingIdx)
    }
  }

  // check that milestone matches is increasing
  for (let i = 0; i < milestoneMatches.length; i++) {
    let idx = milestoneMatches[i]
    for (let j = i; j < milestoneMatches.length; j++) {
      let otherIdx = milestoneMatches[j]
      if (otherIdx < idx) return false
    }
  }

  return true
}

export default isMatch
