import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import momenttz from 'moment-timezone'
import { withStyles } from '@material-ui/core'

const TIME_FORMAT = 'HH:mm ss'
const DATE_FORMAT = 'D MMM YYYY'

const styles = theme => ({
  popupContent: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(2),
  },
  popupRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    height: 18,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  popupLocationText: {
    fontSize: 12,
    letterSpacing: 0.2,
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  locationWrapper: {
    whiteSpace: 'nowrap',
  },
  locationTitle: {
    color: theme.palette.grey[300],
  },
  popupHeader: {
    letterSpacing: 0.2,
    marginBottom: theme.spacing(1),
  },
  popupContainerWrapper: {
    minHeight: 110,
    whiteSpacing: 'nowrap',
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    paddingLeft: theme.spacing(1),
    maxHeight: 100,
    overflow: 'scroll',
  },
  title: {
    letterSpacing: 0.2,
    fontSize: 16,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  containerHeaderText: {
    textAlign: 'center',
    color: theme.palette.grey[300],
  },
})

class LocationPopupDetail extends React.Component {
  state = {
    localTime: null,
    localDate: null,
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    popupData: PropTypes.object.isRequired,
  }

  tick = () => {
    const { popupData } = this.props
    const localTime = momenttz.tz(get(popupData, 'location.timezone')).format(TIME_FORMAT)
    this.setState({
      localTime,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(nextProps, 'location.timezone') !== get(this.props, 'location.timezone')) {
      const { popupData } = nextProps
      const localDate = momenttz.tz(get(popupData, 'location.timezone')).format(DATE_FORMAT)
      this.setState({
        localDate,
      })
    }
  }

  componentDidMount() {
    const { popupData } = this.props
    const localTime = momenttz.tz(get(popupData, 'location.timezone')).format(TIME_FORMAT)
    const localDate = momenttz.tz(get(popupData, 'location.timezone')).format(DATE_FORMAT)
    this.setState(
      {
        localTime,
        localDate,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000)
      }
    )
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    const { classes, popupData, onClose } = this.props
    const location = get(popupData, 'location')
    const { localTime, localDate } = this.state

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{location.name}</div>
          <CloseIcon onClick={onClose} style={{ color: 'rgb(202, 210, 211)' }} />
        </div>
        <div className={classes.popupContent}>
          <div className={classes.locationWrapper}>
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Unlocode: </span>
              {location.unlocode}
            </div>
            {localDate && (
              <div className={classes.popupLocationText}>
                <span className={classes.locationTitle}>Local Date: </span>
                {localDate}
              </div>
            )}
            {localTime && (
              <div className={classes.popupLocationText}>
                <span className={classes.locationTitle}>Local Time: </span>
                {localTime}
              </div>
            )}
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Latitude: </span>
              {location.lat}
            </div>
            <div className={classes.popupLocationText}>
              <span className={classes.locationTitle}>Longitude: </span>
              {location.lon}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(LocationPopupDetail)
