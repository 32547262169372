import { Theme, makeStyles } from '@material-ui/core/styles'

import Collapse from '@material-ui/core/Collapse'
import InternalUserComponent from 'components/InternalUserComponent'
import Link from 'components/core/Link'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import logger from 'utils/logger'

const useStyles = makeStyles((theme: Theme) => ({
  ellipsis: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: theme.spacing(2),
  },
  title: {},
}))

interface Props {
  items: []
  isRowExpanded: boolean
  maxItemsToBeDisplayed: number
  timeout: number
  getUrl: (value: string) => string | undefined
  title: string
}

const ItemsList = (props: Props) => {
  const { items, isRowExpanded, maxItemsToBeDisplayed, timeout, getUrl, title } = props
  const classes = useStyles()

  const loggerTitle = `Shipments Board | ${title} Clicked`
  const itemsDisplayedByDefault = items.slice(0, maxItemsToBeDisplayed)
  let remainingItems: any = []

  if (items.length > maxItemsToBeDisplayed) {
    remainingItems = items.slice(maxItemsToBeDisplayed)
  }

  const defaultNodes = itemsDisplayedByDefault.map((value, idx) => {
    const lastItem = idx === maxItemsToBeDisplayed - 1
    const ellipsis = lastItem && remainingItems.length > 0 ? '...' : ''
    const visibility = isRowExpanded ? 'hidden' : 'visible'
    return (
      <Typography variant="body2" key={idx} className={classes.title}>
        <InternalUserComponent alternateComponent={value || null}>
          {getUrl ? (
            <Link onClick={() => logger.notify(loggerTitle)} to={getUrl(value)}>
              {value}
            </Link>
          ) : (
            value || null
          )}
        </InternalUserComponent>
        <span className={classes.ellipsis} style={{ visibility }}>
          {ellipsis}
        </span>
      </Typography>
    )
  })

  const remainingNodes = remainingItems.map((value: string, idx: number) => {
    return (
      <Typography variant="body2" key={idx} className={classes.title}>
        <InternalUserComponent alternateComponent={value || null}>
          {getUrl ? (
            <Link onClick={() => logger.notify(loggerTitle)} to={getUrl(value)}>
              {value}
            </Link>
          ) : (
            value || null
          )}
        </InternalUserComponent>
      </Typography>
    )
  })

  return (
    <div style={{ marginBottom: -4 }}>
      {defaultNodes}
      <Collapse in={isRowExpanded} timeout={timeout}>
        {remainingNodes}
      </Collapse>
    </div>
  )
}

export default React.memo(ItemsList)
