import { Box, Paper, Theme, makeStyles } from '@material-ui/core'
import { ROLLUP_BOOKINGS, getRefType } from 'utils/rollups'

import React from 'react'
import Timeline from 'components/Timeline'
import TimelineHeader from 'components/Timeline/TimelineHeader'
import { TransportDetailInterface } from 'store/models/TransportDetail/interfaces'
import { UiGroupCollectionInterface } from 'store/types/UiGroupInterface'

const TABLE_MIN_WIDTH = 970

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(5),
    minWidth: TABLE_MIN_WIDTH,
    position: 'relative',
  },
}))

type GroupedTimelineType = UiGroupCollectionInterface

interface Props {
  isPublicShare?: boolean
  transportDetail: TransportDetailInterface | null | undefined
}

const TimelineView = (props: Props) => {
  const classes = useStyles(props)
  const { isPublicShare = false, transportDetail } = props
  if (!transportDetail) {
    return null
  }
  const isBookingPage = getRefType(transportDetail.self.type) === ROLLUP_BOOKINGS

  return (
    <Box className={classes.container} data-testid="entity-page__timeline-view">
      {transportDetail?.groupedMilestones?.map(
        (groupedTimeline: GroupedTimelineType, idx: number) => {
          const headerData = transportDetail.timelines[idx]?.timelineHeader
          return (
            <Box mb={3} key={`timelineview-${idx}`}>
              <Paper elevation={4}>
                <Box px={3} pb={2}>
                  <Box mb={4}>
                    {headerData && (
                      <TimelineHeader
                        timelinesCount={transportDetail.timelines.length}
                        headerData={headerData}
                        isPublicShare={isPublicShare}
                        isBookingPage={isBookingPage}
                      />
                    )}
                    <Timeline
                      isPublicShare={isPublicShare}
                      key={`timeline-${idx}`}
                      tripsCount={headerData?.trips.length ?? null}
                      groupedMilestones={groupedTimeline}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
          )
        }
      )}
    </Box>
  )
}

export default TimelineView
