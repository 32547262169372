import { VesselTrip } from 'utils/sharedObjs'
import get from 'lodash/get'
import moment from 'moment-timezone'

const getVesselTrips = routes => {
  let vesselTrips = []
  for (let i = 0; i < routes.length; i++) {
    let route = routes[i]
    let vesselItems = route.milestones.filter(item => item.type === 'vessel')
    for (let j = 0; j < vesselItems.length; j++) {
      let vesselItem = vesselItems[j]
      const startDate = moment.parseZone(get(vesselItem, 'fromTime.traced.min'))
      let endDate = moment.parseZone(get(vesselItem, 'untilTime.traced.min'))
      const lloydsCode = get(vesselItem, 'details.lloydsCode')
      const name = get(vesselItem, 'details.name')
      const containerTripIds = get(vesselItem, 'containerTripIds')
      const live = !moment.parseZone(endDate).isValid() // not a valid end date means trip is still going

      // NOTE - Have to round down to nearest minute below.  Otherwise, when this recalculates during a rerender,
      // VesselLocation determines whether to rerender the lines, and sees that one of the vesselTrips endDate
      // differs by N milliseconds (because we are setting endDate to the exact current date)
      if (live) endDate = moment().startOf('minute')
      vesselTrips.push(new VesselTrip(startDate, endDate, lloydsCode, name, live, containerTripIds))
    }
  }
  return vesselTrips
}

export default getVesselTrips
