import {
  ROLLUP_BOLS,
  ROLLUP_BOOKINGS,
  ROLLUP_CONTAINER,
  ROLLUP_LINE_ITEM,
  ROLLUP_PURCHASE_ORDERS,
  ROLLUP_SALES_ORDERS,
  ROLLUP_SHIPMENT,
  ROLLUP_STOCK_TRANSFER_ORDERS,
} from 'utils/rollups'
import {
  bolsSuggestSelector,
  bookingsSuggestSelector,
  containersSuggestSelector,
  lineItemsSuggestSelector,
  purchaseOrdersSuggestSelector,
  salesOrdersSuggestSelector,
  shipmentsSuggestSelector,
  stockTransferOrdersSuggestSelector,
} from 'store/suggest'

import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import sortBy from 'lodash/sortBy'
import store from 'store'
import take from 'lodash/take'
import zip from 'lodash/zip'

// Entity Types
export const CONTAINER = 'container'
export const BOL = 'bol'
export const BOOKING = 'booking'
export const ORDER = 'order' // @todo no longer supported, here just for labelling recent searches. Delete eventually.
export const SALES_ORDER = 'sales_order'
export const PURCHASE_ORDER = 'purchase_order'
export const STOCK_TRANSFER_ORDER = 'stock_transfer_order'
export const LINE_ITEM = 'line_item'
export const SHIPMENT = 'shipment'

export const ENTITY_NAMES = {
  [CONTAINER]: 'Container',
  [BOL]: 'Bill of Lading',
  [BOOKING]: 'Booking',
  [ORDER]: 'Order', // @todo no longer supported, here just for labelling recent searches. Delete eventually.
  [SALES_ORDER]: 'SO',
  [PURCHASE_ORDER]: 'PO',
  [STOCK_TRANSFER_ORDER]: 'STO',
  [LINE_ITEM]: 'Line Item',
  [SHIPMENT]: 'Shipment',
}

export const ENTITY_TO_ROLLUP = {
  [CONTAINER]: ROLLUP_CONTAINER,
  [BOL]: ROLLUP_BOLS,
  [BOOKING]: ROLLUP_BOOKINGS,
  [SALES_ORDER]: ROLLUP_SALES_ORDERS,
  [PURCHASE_ORDER]: ROLLUP_PURCHASE_ORDERS,
  [STOCK_TRANSFER_ORDER]: ROLLUP_STOCK_TRANSFER_ORDERS,
  [LINE_ITEM]: ROLLUP_LINE_ITEM,
  [SHIPMENT]: ROLLUP_SHIPMENT,
}

export const formatSearchResults = state => {
  const completers = [
    { fn: salesOrdersSuggestSelector, type: SALES_ORDER },
    { fn: purchaseOrdersSuggestSelector, type: PURCHASE_ORDER },
    { fn: stockTransferOrdersSuggestSelector, type: STOCK_TRANSFER_ORDER },
    { fn: lineItemsSuggestSelector, type: LINE_ITEM },
    { fn: containersSuggestSelector, type: CONTAINER },
    { fn: bolsSuggestSelector, type: BOL },
    { fn: bookingsSuggestSelector, type: BOOKING },
    { fn: shipmentsSuggestSelector, type: SHIPMENT },
  ]

  const results = []
  completers.forEach(completer => {
    const data = completer.fn(state).map(result => ({
      externalId: result.externalId,
      text: result.label,
      type: completer.type,
    }))

    results.push(data)
  })

  return { searchResults: keepTopNRoundRobin(results, 14) }
}

/*
 * Keeps the top `n` entries in `results`, where entries are taken
 * round-robin style from the items in each of the arrays in `results`.
 * @param results An array of arrays.
 * @param n The number of entries to keep from `results`.
 */
export const keepTopNRoundRobin = (results, n) =>
  sortBy(take(compact(flatten(zip(...results))), n), ['type', 'text'])

const RECENT_SEARCHES_KEY = 'recentSearches'
export const RECENT_SEARCHES_LIMIT = 10

const makeSearchKey = search => `${search.type}-${search.text}`

export const retrieveMostRecentSearches = () => {
  return store.get(RECENT_SEARCHES_KEY) || []
}

export const saveRecentSearch = search => {
  let oldMostRecent = retrieveMostRecentSearches()
  const oldKeys = oldMostRecent.map(makeSearchKey)
  const searchKey = makeSearchKey(search)

  if (oldKeys.includes(searchKey)) {
    // This search is already in the list. Remove it here; it will be moved
    // to the top when we build the new search list below.
    oldMostRecent = oldMostRecent.filter(s => makeSearchKey(s) !== searchKey)
  } else {
    // We're adding a new search. If we already have RECENT_SEARCHES_LIMIT
    // searches, pop off the oldest search.
    if (oldMostRecent.length === RECENT_SEARCHES_LIMIT) {
      oldMostRecent.pop()
    }
  }

  const newMostRecent = [search, ...oldMostRecent]
  store.set(RECENT_SEARCHES_KEY, newMostRecent)
}

export const removeRecentSearch = search => {
  const searchKey = makeSearchKey(search)
  const oldMostRecent = retrieveMostRecentSearches()
  const newMostRecent = oldMostRecent.filter(s => makeSearchKey(s) !== searchKey)
  store.set(RECENT_SEARCHES_KEY, newMostRecent)
}
