import {
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core'
import { alertConfigsSelector, deleteAlert, getAlerts } from 'store/api/alertConfigs'

import { ALERT_DETAIL_ROUTE } from 'utils/routes'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import React from 'react'
import SettingsMenu from 'pages/AlertsPage/AlertSettings'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { structureAlertConfig } from 'utils/alertUtils'
import { withRouter } from 'react-router-dom'

const styles = theme => {
  return {
    heading: {
      letterSpacing: 0.2,
      fontSize: 13,
      fontWeight: theme.typography.fontWeightMedium,
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      position: 'relative',
    },
    savedAlertText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: theme.spacing(25),
      marginLeft: theme.spacing(1),
    },
    deleteButton: {
      padding: '3px 4px',
      minWidth: 32,
      minHeight: 32,
      fontSize: theme.typography.pxToRem(16),
    },
    listItemMain: {
      height: 40,
      paddingLeft: 14,
    },
    savedAlertTextRoot: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    alertsList: {
      maxHeight: 'calc(100vh - 108px)',
      overflowY: 'auto',
    },
  }
}

class AlertListItem extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
  }

  state = {
    isHovering: false,
  }
  toggleHover = () => {
    this.setState({ isHovering: !this.state.isHovering })
  }

  render() {
    const { classes, item, onClick, onDeleteClick } = this.props
    const { isHovering } = this.state
    return (
      <ListItem
        button
        dense
        onClick={onClick}
        className={classes.listItemMain}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        <ListItemText
          primary={item.name}
          classes={{ primary: classes.savedAlertText, root: classes.savedAlertTextRoot }}
        />
        <Fade in={isHovering}>
          <IconButton disableRipple={true} onClick={onDeleteClick} className={classes.deleteButton}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Fade>
      </ListItem>
    )
  }
}

AlertListItem = withStyles(styles)(AlertListItem)

class AlertsList extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.getAlerts()
  }

  handleClick = item => () => {
    this.props.history.push(ALERT_DETAIL_ROUTE.buildUrl({ id: item.id }))
  }

  onDeleteClick = alert => e => {
    const { id, name } = alert
    this.props.deleteAlert(id, name)
    e.stopPropagation()
  }

  render() {
    const { classes, alertConfigs } = this.props
    const alertsList = alertConfigs.map((item, idx) => {
      return (
        <AlertListItem
          classes={classes}
          key={`view-list-item-${idx}`}
          item={item}
          onClick={this.handleClick(item)}
          onDeleteClick={this.onDeleteClick(item)}
        />
      )
    })

    return (
      <React.Fragment>
        <Typography variant="body2" className={classes.heading}>
          Saved Alerts
          <SettingsMenu />
        </Typography>
        <div>
          <List className={classes.alertsList}>{alertsList}</List>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  getAlerts,
  deleteAlert,
}

function structureAlertConfigs(rawAlerts) {
  return rawAlerts.map(rawAlert => structureAlertConfig(rawAlert))
}

const structuredAlertConfigsSelector = createSelector(alertConfigsSelector, structureAlertConfigs)

const mapStateToProps = state => {
  const alertConfigs = structuredAlertConfigsSelector(state)
  return {
    alertConfigs,
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AlertsList)
