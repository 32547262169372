import { Radio, Theme, makeStyles } from '@material-ui/core'

import RadioChecked from '@material-ui/icons/RadioButtonChecked'
import RadioUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  selectionRow: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  radioTitle: {
    cursor: 'pointer',
    fontSize: 14,
    color: theme.palette.grey[900],
  },
  checkedIcon: {
    color: theme.palette.grey[600],
    width: 20,
    height: 20,
  },
  uncheckedIcon: {
    color: theme.palette.grey[600],
    width: 20,
    height: 20,
  },
  header: {
    marginLeft: theme.spacing(1),
    marginBottom: 2,
    fontSize: 13,
    letterSpacing: 0.2,
    color: theme.palette.grey[600],
  },
}))

// Should be an enum or piped strings but all of these are passed as hardcoded strings currently
type Value = string | null

type DetailRadioItemProps = {
  children: React.ReactNode
  value: Value
  label: React.ReactNode
  checked: boolean
  header?: string
  style?: { [key: string]: string }
  onSelected: (value: Value) => undefined
}

const DetailRadioItem = (props: DetailRadioItemProps) => {
  const classes = useStyles()
  const { children, label, value, checked, header = null, onSelected, style = {} } = props

  const handleRadioChange = (value: Value) => () => onSelected(value)

  return (
    <div className="radio-row" style={style}>
      {header && <div className={classes.header}>{header}</div>}
      <div className={classes.selectionRow}>
        <Radio
          value={value}
          onChange={handleRadioChange(value)}
          checked={checked}
          icon={<RadioUnchecked className={classes.uncheckedIcon} />}
          checkedIcon={<RadioChecked className={classes.checkedIcon} />}
        />
        <div className={classes.radioTitle} onClick={handleRadioChange(value)}>
          {label}
        </div>
      </div>
      {checked && children}
    </div>
  )
}

export default DetailRadioItem
