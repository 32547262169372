import { Drawer as MUIDrawer, Theme, makeStyles } from '@material-ui/core'

import DrawerToggle from 'components//DrawerToggle'
import React from 'react'
import cx from 'classnames'

const SIDEBAR_MIN_WIDTH = 55
export const SIDEBAR_MAX_WIDTH = 278

const useStyles = makeStyles((theme: Theme) => ({
  docked: {
    height: '100%',
  },
  drawerPaper: {
    position: 'relative',
  },
  drawerPaperOpen: {
    zIndex: 2,
    overflow: 'visible',
    paddingBottom: 80,
  },
  drawerPaperClosed: {
    zIndex: 0,
    overflowX: 'hidden',
    boxShadow: 'none',
  },
  toggleButton: {},
  toggleButtonClosed: {},
}))

interface Props {
  closedWidth?: number
  isOpen: boolean
  onToggleDrawer: () => void
  openWidth?: number
  children?: React.ReactElement
}

const Drawer = (props: Props) => {
  const classes = useStyles()
  const {
    isOpen = true,
    onToggleDrawer,
    openWidth = SIDEBAR_MAX_WIDTH,
    closedWidth = SIDEBAR_MIN_WIDTH,
    children,
  } = props

  const drawerClasses = {
    docked: classes.docked,
    paper: cx(classes.drawerPaper, {
      [classes.drawerPaperClosed]: !isOpen,
      [classes.drawerPaperOpen]: isOpen,
    }),
  }

  return (
    <MUIDrawer
      variant="permanent"
      classes={drawerClasses}
      style={{
        width: isOpen ? openWidth : closedWidth,
      }}
      data-testid="drawer"
    >
      <div className={cx(classes.toggleButton, { [classes.toggleButtonClosed]: !isOpen })}>
        <DrawerToggle
          onClick={onToggleDrawer}
          isOpen={isOpen}
          tooltipText={{ open: 'Show Filters', close: 'Hide Filters' }}
        />
      </div>
      {isOpen ? children : null}
    </MUIDrawer>
  )
}

export default Drawer
