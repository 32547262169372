import { formatMinimalDecimals } from 'utils/strUtils'

export const BAR_POINT_WIDTH = 25

export const makeTooltip = segment => {
  const rowStyle = `padding-top: 5px;`
  const numContainers = segment.metrics.count.toLocaleString()
  const avgDwellTime = formatMinimalDecimals(segment.metrics.metricsMean, 1)
  return `
    <div style="font-size: 14px;">
      <div style="${rowStyle}">Avg Dwell Time: <b>${avgDwellTime} days</b></div>
      <div style="${rowStyle}">Volume: <b>${numContainers} container${
    numContainers === 1 ? '' : 's'
  }</b></div>
    </div>
  `
}
