// Theme is passed from material-ui withStyles
export default theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    overflowY: 'auto',
    maxHeight: 'inherit',
  },
  toolbar: {
    backgroundColor: theme.palette.common.lightBlack,
    flex: 1,
    display: 'flex',
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  form: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    width: '100%',
  },
  formGroupRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    maxHeight: 450,
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  submitBtn: {
    color: '#66717E',
    border: '1px solid #66717E',
    width: '90%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#66717E',
      color: theme.palette.common.white,
    },
    margin: 'auto',
  },
  warning: {
    color: 'orange',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: 11,
    marginTop: 2,
  },
  formControl: {
    marginTop: -14,
  },
  selectAllFormControl: {
    marginLeft: 1,
    marginBottom: theme.spacing(1),
  },
  selectAllLabel: {
    fontSize: 11,
    marginTop: 2,
    marginLeft: 10,
  },
  category: {
    width: '100%',
    color: '#66717E',
    paddingLeft: theme.spacing(0.5),
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14,
    marginBottom: 10,
    marginTop: theme.spacing(1),
    letterSpacing: 0.4,
  },
  grandCategory: {
    width: '100%',
    color: '#66717E',
    borderBottom: '1px solid #66717E',
    paddingLeft: theme.spacing(0.5),
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14,
    marginBottom: 20,
    marginTop: theme.spacing(1),
    letterSpacing: 0.4,
  },
  checkIcon: {
    width: 18,
    height: 18,
  },
})
