import {
  DATA_MENTION_ATTR,
  PADDING_INLINE_START,
  mentionStyle,
  moveCursorToEndOfEditor,
  objectToStyleString,
  userDisplayName,
} from '../../utils'
import { IconButton, Paper, Tooltip, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { setEditorInitialContent, setEditorIsEnabled } from 'store/comments/actions'
import { useDispatch, useSelector } from 'react-redux'

import CancelIcon from '@material-ui/icons/Cancel'
import PropTypes from 'prop-types'
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded'
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded'
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded'
import cx from 'classnames'
import { deleteComment } from 'store/comments/actions'
import get from 'lodash/get'
import { isClearMetalEmail } from 'store/comments/utils'
import logger from 'utils/logger'
import moment from 'moment'
import { useTheme } from '@material-ui/core/styles'
import { userFromState } from 'store/auth/user/selectors'

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(33),
    margin: theme.spacing(1, 0, 1, 0),
  },
  alignRight: {
    marginLeft: 'auto',
  },

  inbound: {
    borderBottomLeftRadius: 0,
  },
  outbound: {
    borderBottomRightRadius: 0,
  },
  white: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  blue: {
    backgroundColor: theme.palette.blue[50],
    border: `1px solid ${theme.palette.blue[200]}`,
  },
  grey: {
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[200],
  },
  orange: {
    backgroundColor: theme.palette.yellow[50],
    border: `1px solid ${theme.palette.yellow[200]}`,
  },
  username: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  comment: {
    padding: theme.spacing(1, 1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.grey[800],
    wordWrap: 'break-word',
  },
  subContent: {
    display: 'flex',
    alignItems: 'baseline',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  actionButton: {
    padding: 7,
  },
  actionIcon: {
    fontSize: theme.typography.body1.fontSize,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerActions: {
    display: 'flex',
    marginTop: '-5px',
    marginRight: '-10px',
  },
  content: {
    marginTop: theme.spacing(0.5),
    '& p': {
      margin: 0,
    },
    '& a': {
      ...theme.overrides.MuiLink.root,
    },
    '& ol, ul': {
      paddingInlineStart: PADDING_INLINE_START,
    },
  },
  disclaimer: {
    paddingTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightLight,
  },
  feedback: {
    marginLeft: 8,
  },
  feedbackButton: {
    padding: 7,
    marginBottom: 1,
  },
  feedbackThanks: {
    padding: 6,
    display: 'inline-block',
  },
}))

const formatDate = d => moment(d).format('D MMM YYYY, h:mma')

const isMention = node =>
  node.type === 'tag' &&
  node.name === 'span' &&
  get(node, `attribs.${DATA_MENTION_ATTR}`, undefined)

const Comment = ({
  content,
  createdAt,
  deletedAt,
  editedAt,
  editor,
  externalId,
  referencedEntityExternalId,
  userEmail,
  userExternalId,
  userFirstName,
  userLastName,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const currentUser = useSelector(userFromState)
  const [feedbackIsSubmitted, setFeedbackIsSubmitted] = useState(false)

  const handleDelete = () => {
    logger.notify('Comments Drawer Delete Comment', { externalId })
    dispatch(
      deleteComment({
        referencedEntityExternalId,
        commentId: externalId,
      })
    )
  }

  const handleReplyTo = () => {
    logger.notify('Comments Drawer Reply To', { externalId, userEmail })
    const html = `<span ${DATA_MENTION_ATTR}="${userExternalId}">${username}</span><span> </span><br />`
    if (editor) {
      editor.execCommand('mceInsertRawHTML', false, html)
      moveCursorToEndOfEditor(editor)
    } else {
      dispatch(setEditorInitialContent(html))
      dispatch(setEditorIsEnabled(true))
    }
  }

  const handleAlertCommentFeedback = feedback => {
    logger.notify(`Comments Drawer Alert Comment Feedback`, { feedback, externalId })
    setFeedbackIsSubmitted(true)
  }

  const isAuthor = currentUser && currentUser.externalId === userExternalId
  const isEdited = Boolean(editedAt)
  const isDeleted = Boolean(deletedAt)
  const isClearMetalAlertComment = userEmail.startsWith('noreply+') && isClearMetalEmail(userEmail)

  const username = userDisplayName({
    email: userEmail,
    firstName: userFirstName,
    lastName: userLastName,
  })
  const formattedCreatedAt = formatDate(createdAt)
  const formattedEditedAt = formatDate(editedAt)

  const transform = (node, index) => {
    if (isMention(node)) {
      node.attribs.style = objectToStyleString(mentionStyle(theme))
      return convertNodeToElement(node, index, transform)
    } else {
      // Returning undefined to ReactHtmlParser will result in default behavior
      return undefined
    }
  }
  const comment = ReactHtmlParser(content, {
    transform,
  })

  const rootClasses = cx(classes.root, {
    [classes.alignRight]: isAuthor,
  })
  const bubbleClasses = cx(classes.comment, {
    [classes.inbound]: !isAuthor,
    [classes.outbound]: isAuthor,
    [classes.blue]: isAuthor,
    [classes.grey]: isDeleted,
    [classes.white]: !isAuthor,
    [classes.orange]: isClearMetalAlertComment,
  })

  if (isDeleted) {
    const deleteMessage = `Comment deleted by ${username}`
    return (
      <div data-testid="comments-drawer__comment_deleted" className={rootClasses} id={externalId}>
        <Paper elevation={0} className={bubbleClasses}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.username}
            title={deleteMessage}
          >
            {deleteMessage}
          </Typography>
        </Paper>
      </div>
    )
  }

  return (
    <div data-testid="comments-drawer__comment" className={rootClasses} id={externalId}>
      <Paper elevation={0} className={bubbleClasses}>
        <span className={classes.header}>
          <Typography variant="body2" color="primary" className={classes.username} title={username}>
            {username}
          </Typography>
          <span className={classes.headerActions}>
            {(() => {
              if (isAuthor) {
                return (
                  <Tooltip title="Delete" placement="bottom">
                    <span>
                      <IconButton
                        onClick={handleDelete}
                        className={classes.actionButton}
                        data-testid="comment__delete-button"
                      >
                        <CancelIcon color="action" className={classes.actionIcon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )
              } else if (isClearMetalAlertComment) {
                // TODO(nicole) add share button
                return null
              } else {
                return (
                  <Tooltip title={`Reply to ${username}`} placement="bottom">
                    <span>
                      <IconButton
                        onClick={handleReplyTo}
                        className={classes.actionButton}
                        data-testid="comment__reply-button"
                      >
                        <ReplyRoundedIcon color="action" className={classes.actionIcon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )
              }
            })()}
          </span>
        </span>
        <Typography variant="body1" className={classes.content} component="div">
          {comment}
        </Typography>
        {isClearMetalAlertComment && (
          <>
            <Typography variant="caption" component="p" className={classes.disclaimer}>
              This is an automated message. Replies will only be seen by your teammates.
            </Typography>
            <Typography variant="caption" component="p" className={classes.disclaimer}>
              Was this alert helpful?
              <span className={classes.feedback}>
                {feedbackIsSubmitted ? (
                  <div className={classes.feedbackThanks}>
                    Thanks!{' '}
                    <span role="img" aria-label="thumbs-up">
                      👍
                    </span>
                  </div>
                ) : (
                  <>
                    <Tooltip title="Yes" placement="bottom">
                      <span>
                        <IconButton
                          onClick={() => handleAlertCommentFeedback('positive')}
                          className={classes.feedbackButton}
                          data-testid="comment__feedback-positive-button"
                          disabled={feedbackIsSubmitted}
                        >
                          <ThumbUpRoundedIcon color="action" className={classes.actionIcon} />
                        </IconButton>
                      </span>
                    </Tooltip>{' '}
                    <Tooltip title="No" placement="bottom">
                      <span>
                        <IconButton
                          onClick={() => handleAlertCommentFeedback('negative')}
                          className={classes.feedbackButton}
                          data-testid="comment__feedback-negative-button"
                          disabled={feedbackIsSubmitted}
                        >
                          <ThumbDownRoundedIcon color="action" className={classes.actionIcon} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
              </span>
            </Typography>
          </>
        )}
      </Paper>
      <Typography variant="caption" color="textSecondary">
        {`${formattedCreatedAt}`} {isEdited && <span>{`(Edited ${formattedEditedAt})`}</span>}
      </Typography>
      <Typography variant="caption" color="textSecondary" className={classes.subContent} />
    </div>
  )
}

Comment.propTypes = {
  content: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  deletedAt: PropTypes.string,
  editedAt: PropTypes.string,
  editor: PropTypes.object,
  externalId: PropTypes.string.isRequired,
  referencedEntityExternalId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userExternalId: PropTypes.string.isRequired,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
}

export default Comment
