import { Box, MenuItem, Select, Typography, makeStyles } from '@material-ui/core'
import { ROLLUPS, getRollupLabel } from 'utils/rollups'
import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { REF_TYPE_PARAM_NAME } from 'utils/querystring'
import logger from 'utils/logger'
import { setPage } from 'store/board/actions'
import { setQueryStringParam } from 'utils/urlBuilder'
import { setRollup } from 'store/boardUtils/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  select: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
  selectRoot: {
    paddingTop: 12,
    minWidth: 150,
  },
  menuList: {
    minWidth: 195,
    '& li': {
      fontSize: theme.typography.subtitle2.fontSize,
      '& .Mui-selected': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
}))

const RefTypeSelector = function ({ getRollupsToRender, rollup }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [selected, setSelected] = useState(rollup)

  useEffect(() => {
    setSelected(rollup)
  }, [rollup])

  const handleRollupChange = e => {
    const rollup = e.target.value
    dispatch(setPage(0))
    logger.notify(`Shipments Set Rollup ${rollup}`)
    dispatch(setRollup(rollup))
    // Set the query string param.
    setQueryStringParam(REF_TYPE_PARAM_NAME, rollup)
    setSelected(rollup)
  }

  return (
    <Box>
      <Typography variant="h6" component="span">
        You are monitoring
      </Typography>
      <Select
        MenuProps={{
          MenuListProps: {
            classes: { root: classes.menuList },
          },
        }}
        autoWidth={true}
        classes={{ root: classes.selectRoot }}
        className={classes.select}
        data-testid="board-page__ref-type-selector"
        onChange={handleRollupChange}
        value={selected}
        variant="filled"
      >
        {getRollupsToRender().map(rollup => {
          return (
            <MenuItem value={rollup} key={rollup}>
              {getRollupLabel(rollup)}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}

RefTypeSelector.propTypes = {
  getRollupsToRender: PropTypes.func.isRequired,
  rollup: PropTypes.oneOf(ROLLUPS).isRequired,
}

export default RefTypeSelector
