import Button from 'components/core/Button'
import React from 'react'
import ShareIcon from 'components/icons/Share'
import { Tooltip } from '@material-ui/core'

const ShareButton = function ({
  className,
  disabled,
  isIconOnlyButton = true,
  onClick,
  shared = false,
  tooltipText,
  buttonText = '',
  ...buttonProps
}) {
  // Extending React.Component and moving `onClick()` into this class function resolved JS reflow violations
  const handleClickRequest = e => {
    onClick()
  }

  const { startIcon: StartIcon, variant } = buttonProps

  const buttonStartIcon = !isIconOnlyButton && StartIcon ? <StartIcon /> : <ShareIcon />

  const buttonElement = (
    <Button
      onClick={handleClickRequest}
      disabled={disabled}
      className={className}
      {...buttonProps}
      variant={variant || 'text'}
      startIcon={buttonStartIcon}
      data-testid="shareButton"
    >
      <span>{buttonText}</span>
    </Button>
  )

  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <span>{buttonElement}</span>
    </Tooltip>
  ) : (
    buttonElement
  )
}

export default ShareButton
