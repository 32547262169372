import {
  BLUE,
  BLUE25,
  GREEN,
  GREY,
  GREY2,
  GREY50,
  LabelColorVariants,
  MODE,
  ORANGE,
  PURPLE100,
  RED,
  STATUS_DELIVERED,
  STATUS_DISCHARGED,
  STATUS_INTRANSIT,
  STATUS_PENDING,
  STATUS_UNKNOWN,
  YELLOW,
  YELLOW2,
  YELLOW25,
} from 'components/StatusLabel/labelColors'

import cx from 'classnames'

type Classes = {
  blue25Button: string
  blueButton: string
  greenButton: string
  grey2Button: string
  grey50Button: string
  greyButton: string
  modeLabelButton: string
  orangeButton: string
  purple100Button: string
  redButton: string
  rootReset: string
  width: string
  yellow25Button: string
  yellow2Button: string
  yellowButton: string
  statusDeliveredButton: string
  statusDischargedButton: string
  statusIntransitButton: string
  statusPendingButton: string
  statusUnknownButton: string
}

export const getVariantStyles = (classes: Classes, variant: LabelColorVariants) => {
  const rootBase = [classes.rootReset, classes.width]
  switch (variant) {
    case MODE:
      return {
        root: cx([...rootBase, classes.modeLabelButton]),
      }
    case GREY:
      return {
        root: cx([...rootBase, classes.greyButton]),
      }
    case BLUE:
      return {
        root: cx([...rootBase, classes.blueButton]),
      }
    case BLUE25:
      return {
        root: cx([...rootBase, classes.blue25Button]),
      }
    case GREEN:
      return {
        root: cx([...rootBase, classes.greenButton]),
      }
    case YELLOW:
      return {
        root: cx([...rootBase, classes.yellowButton]),
      }
    case ORANGE:
      return {
        root: cx([...rootBase, classes.orangeButton]),
      }
    case PURPLE100:
      return {
        root: cx([...rootBase, classes.purple100Button]),
      }
    case RED:
      return {
        root: cx([...rootBase, classes.redButton]),
      }
    case GREY2:
      return {
        root: cx([...rootBase, classes.grey2Button]),
      }
    case GREY50:
      return {
        root: cx([...rootBase, classes.grey50Button]),
      }
    case YELLOW2:
      return {
        root: cx([...rootBase, classes.yellow2Button]),
      }
    case YELLOW25:
      return {
        root: cx([...rootBase, classes.yellow25Button]),
      }
    case STATUS_DELIVERED:
      return {
        root: cx([...rootBase, classes.statusDeliveredButton]),
      }
    case STATUS_DISCHARGED:
      return {
        root: cx([...rootBase, classes.statusDischargedButton]),
      }
    case STATUS_INTRANSIT:
      return {
        root: cx([...rootBase, classes.statusIntransitButton]),
      }
    case STATUS_PENDING:
      return {
        root: cx([...rootBase, classes.statusPendingButton]),
      }
    case STATUS_UNKNOWN:
      return {
        root: cx([...rootBase, classes.statusUnknownButton]),
      }

    default:
      return {
        root: cx([...rootBase, classes.greyButton]),
      }
  }
}
