import { MESSAGE_TYPES, showNotification } from 'store/notifications'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import MESSAGES from 'store/notifications/messages'
import Profile from 'store/profile/models'
import { ProfileInterface } from 'store/profile/interfaces'
import api from 'store/profile/api'
import logger from 'utils/logger'
import { types } from 'store/profile/actions'
import { userFromState } from 'store/auth/user/selectors'

export function* updateProfileAsync(action: { type: string; payload: ProfileInterface }) {
  try {
    const res = yield call(api.updateProfile, action.payload)
    const data = (res?.data && Profile.of(res.data)) || {}
    yield put({ type: types.UPDATE_PROFILE_SUCCESS, payload: data })
    yield put(showNotification(MESSAGES.profileSaveSuccess, { type: MESSAGE_TYPES.SUCCESS }))
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.UPDATE_PROFILE_ERROR, payload: { error: err.message } })
    yield put(showNotification(MESSAGES.profileSaveError, { type: MESSAGE_TYPES.ERROR }))
  }
}

export function* fetchProfileAsync() {
  try {
    const res = yield call(api.fetchProfile)
    const data = (res?.data && Profile.of(res.data)) || {}
    yield put({ type: types.FETCH_PROFILE_SUCCESS, payload: data })
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.FETCH_PROFILE_ERROR, payload: { error: err.message } })
    yield put(showNotification(MESSAGES.profileRetrieveError, { type: MESSAGE_TYPES.ERROR }))
  }
}

/**
 * This saga is basically the same as `updateProfileAsync` above but we explicitly set `accepted_terms` to `true`. This
 * is in part needed because the form is in an iframe, but also so we can refresh the browser on success.
 * - Gets the current profile from state because we don't intercept until the login succeeded
 * - Builds the correct user object, with `acceptedTerms: true` and passes it to `api.updateProfile`
 * - Dispatch a success action (no reducer needed because of the next step)
 * - We reload to let state be set in the normal page load way
 **/
export function* updateAcceptedTermsAsync(action: { type: string }) {
  try {
    const profile = yield select(userFromState)
    const { email, tenant } = profile
    yield call(api.updateAcceptedTerms)
    yield put({ type: types.UPDATE_ACCEPTED_TERMS_SUCCESS })
    logger.notify('Terms and Privacy Accepted', {
      email,
      tenant,
    })
    setTimeout(() => window.location.reload(), 2000)
  } catch (err) {
    logger.captureAPIException(err)
    yield put({ type: types.UPDATE_ACCEPTED_TERMS_ERROR, payload: { error: err.message } })
  }
}

export function* watchUpdateProfile() {
  yield takeLatest(types.UPDATE_PROFILE_START, updateProfileAsync)
}

export function* watchFetchProfile() {
  yield takeLatest(types.FETCH_PROFILE_START, fetchProfileAsync)
}

export function* watchAcceptedTerms() {
  yield takeLatest(types.UPDATE_ACCEPTED_TERMS_START, updateAcceptedTermsAsync)
}
