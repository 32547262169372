import { PUBLIC_SHARE_REDUCER_KEY } from './actions'
import { handleActions } from 'redux-actions'
import { types } from './actions'
export { PUBLIC_SHARE_REDUCER_KEY }

const initialState = {
  publicShare: {
    isLoading: false,
    isFailed: false,
    data: undefined,
  },
  selectedShipment: undefined,
}

export const publicShareSelector = state => state[PUBLIC_SHARE_REDUCER_KEY].publicShare
export const publicShareSelectedShipmentSelector = state =>
  state[PUBLIC_SHARE_REDUCER_KEY].selectedShipment

export default handleActions(
  {
    [types.FETCH_PUBLIC_SHARE_START]: state => ({
      ...state,
      publicShare: { isLoading: true, isFailed: false, data: undefined },
    }),
    [types.FETCH_PUBLIC_SHARE_SUCCESS]: (state, { payload }) => ({
      ...state,
      publicShare: { isLoading: false, isFailed: false, data: payload },
    }),
    [types.FETCH_PUBLIC_SHARE_ERROR]: state => ({
      ...state,
      publicShare: { isLoading: false, isFailed: true, data: undefined },
    }),

    [types.UPDATE_SELECTED_SHIPMENT]: (state, { payload }) => ({
      ...state,
      selectedShipment: payload,
    }),
  },
  initialState
)
